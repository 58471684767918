import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';
export const ics = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'ICS'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-zeek*'],
        must: [
          {
            match_phrase: {
              tags: 'ics'
            }
          }
        ],
        must_not: [
          {
            match_phrase: {
              logName: 'ecat_arp_info'
            }
          }
        ],
        queryType: ['previousCount', 'count', 'sparkline']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          hideFilters: true,
          title: 'Events',
          helpInfo: 'Total number of ICS events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['cardinality'],
        overriddenIndex: ['leargas-zeek*'],
        must: [
          {
            match_phrase: {
              tags: 'ics'
            }
          }
        ],
        aggregate: {
          aggs: {
            1: {
              cardinality: {
                field: 'serial_number.keyword'
              }
            }
          },
          size: 0
        },
        must_not: [
          {
            match_phrase: {
              logName: 'ecat_arp_info'
            }
          }
        ]
      },
      children: {
        component: 'MetricPlain',
        props: {
          title: 'Devices',
          helpInfo: 'Total number of ICS devices matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['cardinality'],
        overriddenIndex: ['leargas-zeek*'],
        must: [
          {
            match_phrase: {
              tags: 'ics'
            }
          }
        ],
        aggregate: {
          aggs: {
            1: {
              cardinality: {
                field: 'vendor_name.keyword'
              }
            }
          },
          size: 0
        },
        must_not: [
          {
            match_phrase: {
              logName: 'ecat_arp_info'
            }
          }
        ]
      },
      children: {
        component: 'MetricPlain',
        props: {
          title: 'Vendors',
          helpInfo:
            'Total number of ICS device vendors matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-zeek*'],
        must: [
          {
            match_phrase: {
              tags: 'ics'
            }
          }
        ],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'logName.keyword',
                order: {
                  _count: 'desc'
                },
                size: 100
              }
            }
          },
          size: 0
        },
        must_not: [
          {
            match_phrase: {
              logName: 'ecat_arp_info'
            }
          }
        ]
      },
      children: {
        component: 'PieChart',
        props: {
          header: 'ICS Protocols',
          helpInfo: 'ICS protocols observed in events by count',
          field: 'logName'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['table'],
        overriddenIndex: ['leargas-zeek*'],
        must: [
          {
            match_phrase: {
              tags: 'ics'
            }
          }
        ],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'vendor_name.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'product_name.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 50
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'serial_number.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 5
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        must_not: [
          {
            match_phrase: {
              logName: 'ecat_arp_info'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 5,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Device Details',
            overlayText:
              'All ICS devices by vendor, product, and serial number, where available',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-zeek*'],
        must: [
          {
            match_phrase: {
              tags: 'ics'
            }
          }
        ],
        must_not: [
          {
            match_phrase: {
              logName: 'ecat_arp_info'
            }
          }
        ],
        queryType: ['histogram']
      },
      children: {
        component: 'Stat',
        props: {
          header: 'Event Timeline',
          helpInfo:
            'Timeline histogram of all ICS events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['map'],
        overriddenIndex: ['leargas-zeek*'],
        must: [
          {
            match_phrase: {
              tags: 'ics'
            }
          }
        ],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'id.resp_h_geoip.country_code2.keyword',
                order: {
                  _count: 'desc'
                },
                size: 350
              }
            }
          }
        },
        must_not: [
          {
            match_phrase: {
              logName: 'ecat_arp_info'
            }
          }
        ]
      },
      children: {
        component: 'Map',
        props: {
          title: 'Event Source Locations',
          field: 'source_geoip.country_name',
          helpInfo: 'Source countries seen in ICS events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['sankey'],
        overriddenIndex: ['leargas-zeek*'],
        must: [
          {
            match_phrase: {
              tags: 'ics'
            }
          }
        ],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'id.orig_h.keyword',
                order: {
                  _count: 'desc'
                },
                size: 10
              },
              aggs: {
                3: {
                  terms: {
                    field: 'id.resp_h.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5
                  }
                }
              }
            }
          },
          size: 0
        },
        must_not: [
          {
            match_phrase: {
              logName: 'ecat_arp_info'
            }
          }
        ]
      },
      children: {
        component: 'SankeyBasic',
        props: {
          filterFields: {
            source: 'id.orig_h',
            target: 'id.resp_h'
          },
          direction: 'ltr',
          title: 'Events Flow',
          helpInfo: 'Top 10 source IPs and top 5 destination IPs by count',
          minHeight: '400px'
        }
      }
    }
  ];
};
export default ics;
