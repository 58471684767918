import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useBookmarks from 'hooks/admin-contexts/useBookmarks';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Modal } from 'react-bootstrap';
import BookmarksDeleteForm from '../forms/BookmarksDeleteForm';
/**
 * bookmarks delete modal
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ModalBookmarkDelete = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    bookmarks: { deleteBookmarkModal },
    setBookmarks
  } = useBookmarks();
  const { data } = deleteBookmarkModal;

  // /**
  //  * bookmarks delete modal
  //  * @param {Event} e
  //  * @async
  //  * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
  //  * @version 0.1.0-beta.2
  //  * @since 0.1.0-beta.2
  //  */
  // const handleSubmit = async e => {
  //   e.preventDefault();
  //   let { data } = deleteBookmarkModal;

  //   try {
  //     setBookmarks(prevState => ({
  //       ...prevState,
  //       loading: true
  //     }));
  //     let res = await LeargasAPI.AppBookmarks.deleteBookmark(data?._id, true);
  //     if (res instanceof Error) {
  //       throw res;
  //     }
  //     const { message } = res.data;
  //     toast.success(message);
  //     setBookmarks(prevState => ({
  //       ...prevState,
  //       fetch: true,
  //       loading: false,
  //       deleteBookmarkModal: { open: false, data: {} }
  //     }));
  //   }  catch (error) {
  // if (error.message.includes('Network Error')) {
  //   console.error('Network Error occurred.');
  // }
  // console.error(err); //
  //     if (error?.response?.data?.message) {
  //       toast.error(error?.response?.data?.message);
  //     } else {
  //       toast.error('Something went wrong!');
  //     }
  //     setBookmarks(prevState => ({
  //       ...prevState,
  //       fetch: true,
  //       deleteBookmarkModal: { open: false, data: {} },
  //       loading: false
  //     }));
  //   }
  // };

  return (
    <Modal
      centered
      size='lg'
      animation={false}
      backdrop='static'
      keyboard={false}
      show={deleteBookmarkModal?.open}
      onHide={() =>
        setBookmarks(prevState => ({
          ...prevState,
          deleteBookmarkModal: { open: false, data: {} }
        }))
      }
      aria-labelledby='modalBookmarkAdd'>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Delete Bookmark: {data?.name}
          <AdvancedPopover
            containerId='resetConfirmModal'
            placement='top'
            popoverText='Delete Bookmark'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
      </Modal.Header>

      <Modal.Body className='p-2'>
        {' '}
        <BookmarksDeleteForm />
      </Modal.Body>
    </Modal>
  );
};

export default ModalBookmarkDelete;
