import React from 'react';
import PropTypes from 'prop-types';
import ArticlesProvider from '../../../../../../../context/admin-contexts/ArticlesProvider';
import ArticleCategoriesLayout from './ArticleCategoriesLayout';
import ArticleCategoriesAddModal from './components/modals/ArticleCategoriesAddModal';
import ArticleCategoriesEditModal from './components/modals/ArticleCategoriesEditModal';
import ArticleCategoriesDeleteModal from './components/modals/ArticleCategoriesDeleteModal';

/**
 *
 * @param { props }
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticlesCategoriesWrapper = props => {
  return (
    <ArticlesProvider>
      {props.children}
      <ArticleCategoriesLayout />

      <ArticleCategoriesAddModal />
      <ArticleCategoriesEditModal />
      <ArticleCategoriesDeleteModal />
    </ArticlesProvider>
  );
};

ArticlesCategoriesWrapper.propTypes = { children: PropTypes.node };

export default ArticlesCategoriesWrapper;
