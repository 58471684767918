import { makeRequest } from 'api/connection/requestAction';
class Dashboards {
  constructor(route) {
    this.route = route;
  }

  async createDashboard(dashboardData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.createDashboard.method,
        this.route.createDashboard.path,
        dashboardData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async getDashboards(axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getDashboards.method,
        this.route.getDashboards.path
      );
      let dashboards = res?.data?.data;
      if (res instanceof Error) throw res;
      return dashboards;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async getDashboard(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getDashboard.method,
        `${this.route.getDashboard.path}/${id}`
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async updateDashboard(id, dashboardData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateDashboard.method,
        `${this.route.updateDashboard.path}/${id}`,
        dashboardData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async deleteDashboard(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.deleteDashboard.method,
        `${this.route.deleteDashboard.path}/${id}`
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default Dashboards;
