import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValid, parse } from 'date-fns';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

/**
 *
 * @returns {JSX.Element} The rendered button component
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version   0.1.0-beta.5
 * @since   0.1.0-beta.5
 */
const DateTimePickerInput = forwardRef(
  (
    { value, onChangeFunc, setSignalEnter, setMainInput, onClick, ...rest },
    ref
  ) => {
    const [inputValue, setInputValue] = useState('');

    // Synchronize the displayed value with the passed prop
    useEffect(() => {
      setInputValue(value);
    }, [value]);

    // Handles manual date input, validates, and updates the parent state
    const handleInputChange = value => {
      const parsedDate = parse(value, 'yyyy-MM-dd HH:mm:ss', new Date());

      if (isValid(parsedDate)) {
        onChangeFunc(parsedDate); // Set the selected date
        setSignalEnter(true); // Trigger the Enter key signal
      } else {
        toast.error('Please enter a valid date');
      }
    };

    // Listens for the Enter key to trigger the date validation
    const handleKeyDown = event => {
      if (event.key === 'Enter') handleInputChange(event.target.value);
    };

    // Updates the local input value and triggers external change handlers
    const dateChange = event => {
      setInputValue(event.target.value);
      setMainInput(event.target.value);
    };

    return (
      <>
        <Form.Control
          size='sm'
          ref={ref}
          onClick={onClick}
          value={inputValue}
          onKeyDown={handleKeyDown}
          onChange={dateChange}
          className='w-100 ps-4 fs--1 mb-0'
        />
        <FontAwesomeIcon
          icon='arrows-up-to-line'
          className='position-absolute top-50 translate-middle-y ms-2'
          transform={`shrink-3 rotate-${rest?.iconRotation}`}
        />
      </>
    );
  }
);

DateTimePickerInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  iconRotation: PropTypes.number,
  onChangeFunc: PropTypes.func,
  setSignalEnter: PropTypes.func,
  setMainInput: PropTypes.func
};

export default DateTimePickerInput;
