import OrganizationsProvider from 'context/admin-contexts/OrganizationsProvider';
import StatusesProvider from 'context/admin-contexts/StatusesProvider';
import UsersProvider from 'context/admin-contexts/UsersProvider';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import StatusToggleForm from './components/modals/StatusesToggleModal';
import Statuses from './components/Statuses';

/**
 * Statuses which are used in layout section of route
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const SystemReportingStatusesPage = () => {
  return (
    <>
      <WidgetSectionTitle title='Reporting Management' className='mb-2' />
      <UsersProvider>
        <OrganizationsProvider>
          <StatusesProvider>
            <Row className='g-3 mb-3'>
              <Col sm={12}>
                <Statuses />
              </Col>
            </Row>
            <StatusToggleForm />
          </StatusesProvider>
        </OrganizationsProvider>
      </UsersProvider>
    </>
  );
};

export default SystemReportingStatusesPage;
