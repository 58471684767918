import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

const sentinelOneAgents = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'SentinelOne Agents Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['count'],
        overriddenIndex: ['leargas-sentinel-one-agents'],
        must: [
          {
            match_all: {}
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: false,
          hideBadge: true,
          hideChart: true,
          title: 'Agents',
          helpInfo: 'Total number of SentinelOne agents'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['count'],
        overriddenIndex: ['leargas-sentinel-one-agents'],
        must: [
          {
            match: {
              isActive: true
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: false,
          hideBadge: true,
          hideChart: true,
          title: 'Active Agents',
          helpInfo: 'Total number of active SentinelOne agents'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['count'],
        overriddenIndex: ['leargas-sentinel-one-agents'],
        must: [
          {
            match: {
              infected: true
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: false,
          hideBadge: true,
          hideChart: true,
          title: 'Agents Infected',
          helpInfo: 'Total number of infected systems'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['count'],
        overriddenIndex: ['leargas-sentinel-one-agents'],
        must: [
          {
            match_all: {}
          }
        ],
        must_not: [
          {
            match_phrase: {
              isUpToDate: true
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: false,
          hideBadge: true,
          hideChart: true,
          title: 'Outdated Agents',
          helpInfo: 'Total number of outdated SentinelOne agents'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-sentinel-one-agents'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'osName.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Operating Systems',
          field: 'osName',
          helpInfo:
            'Operating systems and versions reported by SentinelOne agents'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-sentinel-one-agents'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'agentVersion.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          field: 'agentVersion',
          header: 'Agent Version',
          helpInfo: 'SentinelOne agent versions across all systems'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-sentinel-one-agents'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'machineType.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Machine Types',
          field: 'machineType',
          helpInfo: 'Types of systems hardware reported by SentinelOne'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-sentinel-one-agents'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'scanStatus.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Scan Statuses',
          field: 'scanStatus',
          helpInfo: 'Current states of all SentinelOne system scans'
        }
      }
    }
  ];
};

export default sentinelOneAgents;
