import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';

/**
 * ReportsTable component renders a table with sorting and filtering capabilities.
 *
 * @param {Object} props - The properties object.
 * @param {Function} props.getTableProps - Function to get table properties.
 * @param {Array} props.headers - Array of header objects for the table.
 * @param {Array} props.page - Array of rows to be displayed on the current page.
 * @param {Function} props.prepareRow - Function to prepare a row for rendering.
 * @param {string} props.headerClassName - CSS class name for the table header.
 * @param {Object} props.tableProps - Additional properties for the table.
 *
 * @returns {JSX.Element} The rendered ReportsTable component.
 */
const ReportsTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  tableProps
}) => {
  return (
    <div className='table-responsive '>
      <Table
        responsive
        size='sm'
        {...getTableProps(tableProps)}
        className='simplebar-scroll-content'
        data-simplebar>
        <thead className={headerClassName}>
          <tr>
            {headers.map((column, index) => (
              <th key={index}>
                <div
                  {...column.getHeaderProps(
                    column.getSortByToggleProps(column.headerProps)
                  )}>
                  {column.render('Header')}

                  {column.canSort ? (
                    column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className='sort desc' />
                      ) : (
                        <span className='sort asc' />
                      )
                    ) : (
                      <span className='sort' />
                    )
                  ) : null}
                </div>
                <div>
                  {column.canFilter && column?.Filter
                    ? column?.render('Filter')
                    : null}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {page.length > 0 ? (
            page.map((row, i) => {
              prepareRow(row);
              let rowClassName = row.original?.isDeleted
                ? 'soft-danger'
                : 'transparent';
              return (
                <tr key={i} className={rowClassName} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        {...cell.getCellProps(cell.column.cellProps)}
                        style={
                          i === page.length - 1
                            ? {
                                border: 'none',
                                backgroundColor: `${getColor(rowClassName)}`
                              }
                            : { backgroundColor: `${getColor(rowClassName)}` }
                        }>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={headers.length} className='text-center'>
                {'No data available'}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

ReportsTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  data: PropTypes.array,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func
};

export default ReportsTable;
