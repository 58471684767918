import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import Flex from 'components/common/Flex';
import { Image, Placeholder, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import routes from 'config/routeSettings';

/**
 * @param {object} props
 * @param {Object} props.article
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticlesGridView = ({ article }) => {
  const { thumbnail, slug } = article;

  const showImage = article.thumbnail || (
    <Placeholder className='fs--2 cursor-default  rounded-3 testRatio position-relative'>
      <Flex
        justifyContent='center'
        alignItems='center'
        className='text-light position-absolute all-0'>
        No Image
      </Flex>
    </Placeholder>
  );

  return (
    <Col
      sm={6}
      md={3}
      xxl={2}
      className={`  gap-2 d-flex position-relative  align-items-start flex-column rounded-3 mb-4   `}>
      <Col sm={12}>
        <Link to={[routes.ARTICLES, slug].join('/')}>
          {thumbnail ? (
            <Image width={'100%'} src={thumbnail} className='rounded-3' />
          ) : (
            showImage
          )}
        </Link>
      </Col>
      <Col sm={12} className='d-flex flex-column justify-content-around '>
        <Link
          to={[routes.ARTICLES, slug].join('/')}
          className='text-decoration-none'>
          {article.title}
        </Link>

        <Flex
          direction='row mt-1  '
          justifyContent={'between'}
          style={{ bottom: '1vw' }}>
          <span className='text-muted fs--2 me-3'>
            {article?.createdBy?.profile?.fullName}
          </span>
          <span className='text-muted fs--2'>
            {' '}
            <Moment fromNow className='align-middle'>
              {article.createdAt}
            </Moment>
          </span>
        </Flex>
      </Col>
    </Col>
  );
};

ArticlesGridView.propTypes = {
  article: PropTypes.object
};
export default ArticlesGridView;
