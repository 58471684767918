import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import useESGlobalFilters from 'hooks/useGlobalFilters';
import Flex from '../../Flex';
import FilterBadge from './FilterBadge';
// import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  invertOperator,
  removeField,
  setInsideFiltersModal,
  togglePin,
  toggletempDisable
} from 'components/dashboards/Artifacts/Dynamic/dataFetchUtils/RefinedUtils';
import useApplication from 'hooks/useApplication';

// import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';
import AdvancedPopover from 'components/common/Popover';
import { Badge, Button } from 'react-bootstrap';
import NewFilterModal from './Modals/AddFilterModal';
import EditFilterModal from './Modals/EditFilterModal';

/**
 *
 * @returns {JSX.Element} The rendered button component
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const Filters = () => {
  const [showNewFilterModal, setShowNewFilterModal] = useState(false);
  const [localRules, setLocalRules] = useState([]);

  const {
    esGlobalFilters: { query, querySearchText, query_string },
    setESGlobalFilters
  } = useESGlobalFilters();
  const { updateGlobalFilters } = useApplication();

  const tempDisable = index => {
    let oldRules = [...JSON.parse(JSON.stringify(localRules))];
    let length = oldRules.length;
    let newRules = [];

    if (length > 0) {
      newRules = toggletempDisable(oldRules, index);
      const newQuery = { ...JSON.parse(JSON.stringify(query)) };

      newQuery.rules = newRules;
      setESGlobalFilters(prevState => {
        return {
          ...prevState,
          query: newQuery,
          stagedQuery: newQuery
        };
      });

      setInsideFiltersModal(
        newQuery,

        setESGlobalFilters,
        updateGlobalFilters
      );
    }
  };
  const togglePinFilters = index => {
    let oldRules = [...JSON.parse(JSON.stringify(localRules))];
    let length = oldRules.length;
    let newRules = [];

    if (length > 0) {
      newRules = togglePin(oldRules, index);
      const newQuery = { ...JSON.parse(JSON.stringify(query)) };

      newQuery.rules = newRules;
      setESGlobalFilters(prevState => {
        return {
          ...prevState,
          query: newQuery,
          stagedQuery: newQuery
        };
      });

      setInsideFiltersModal(
        newQuery,

        setESGlobalFilters,
        updateGlobalFilters
      );
    }
  };
  const toggleIncludeExcludeResults = index => {
    const rules = [...JSON.parse(JSON.stringify(localRules))];

    const invertedOperator = invertOperator(rules[index].operator);
    rules[index].operator = invertedOperator;
    const newQuery = { ...JSON.parse(JSON.stringify(query)) };

    newQuery.rules = rules;

    setESGlobalFilters(prevState => {
      return {
        ...prevState,
        query: newQuery,
        stagedQuery: newQuery
      };
    });

    setInsideFiltersModal(
      newQuery,

      setESGlobalFilters,
      updateGlobalFilters
    );
  };
  const removeFilter = index => {
    let oldRules = [...JSON.parse(JSON.stringify(localRules))];
    let length = oldRules.length;
    let newRules = [];

    if (length > 0) {
      newRules = removeField(oldRules, index);
      const newQuery = { ...JSON.parse(JSON.stringify(query)) };
      newQuery.rules = newRules;
      setESGlobalFilters(prevState => {
        return {
          ...prevState,
          query: newQuery,
          stagedQuery: newQuery
        };
      });

      setInsideFiltersModal(
        newQuery,

        setESGlobalFilters,
        updateGlobalFilters
      );
    }
  };

  useEffect(() => {
    let dupQuery = JSON.parse(JSON.stringify(query));

    if (dupQuery?.rules?.includes('or') || dupQuery?.rules?.includes('and')) {
      let mainArray = dupQuery?.rules?.filter(
        item => item !== 'and' && item !== 'or'
      );

      setLocalRules(mainArray);
    } else {
      setLocalRules(dupQuery?.rules);
    }

    // setFilterCount(mainArray ? mainArray?.length : null);
  }, [query]);

  return (
    <>
      <Flex direction='column'>
        <Flex
          direction='row'
          wrap='wrap'
          alignContent='center'
          alignItems='center'
          justifyContent='start'>
          {localRules.length === 0 &&
          querySearchText === '' &&
          query_string === null ? (
            <Badge className='fs--1 text-700 fw-normal ps-0 py-1 bg-transparent d-flex align-items-center'>
              <span style={{ marginTop: '3px' }}>No filters are applied</span>
            </Badge>
          ) : null}
          {querySearchText !== '' && query_string !== null && (
            <FilterBadge
              accessor={'query_string'}
              value={querySearchText}
              setESGlobalFilters={setESGlobalFilters}
            />
          )}
          {localRules?.map((item, index) => {
            return (
              <FilterBadge
                key={index}
                index={index}
                operator={item.operator}
                accessor={item.field}
                value={item.value}
                item={item}
                removeFilter={removeFilter}
                tempDisable={tempDisable}
                togglePinFilters={togglePinFilters}
                toggleIncludeExcludeResults={toggleIncludeExcludeResults}
              />
            );
          })}
          <AdvancedPopover
            placement='top'
            popoverText={'Add a new filter'}
            showArrow={true}>
            <Button
              className={
                'p-0 text-decoration-none fs--2 text-400 hover-100 border-0 mb-0'
              }
              onClick={() => setShowNewFilterModal(true)}
              variant='outline-pastel-green-dark'
              style={{ marginBottom: '3px' }}>
              <FontAwesomeIcon icon='plus' width={16} height={16} />
            </Button>
          </AdvancedPopover>
        </Flex>
      </Flex>
      <NewFilterModal
        showNewFilterModal={showNewFilterModal}
        localRules={localRules}
        setShowNewFilterModal={setShowNewFilterModal}
      />
      <EditFilterModal localRules={localRules} />
      {/* <EditFilterModal localRules={localRules} /> */}
    </>
  );
};

export default Filters;
