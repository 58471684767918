import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import { useApplication } from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, ListGroup, Modal } from 'react-bootstrap';
const ModalPreview = ({
  previewItem,
  showPreviewModal,
  setShowPreviewModal
}) => {
  const {
    application: { isDark }
  } = useApplication();
  return (
    <Modal
      show={showPreviewModal}
      onHide={() => setShowPreviewModal(false)}
      size='lg'
      aria-labelledby='previewModal'
      centered>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Node Details
          <AdvancedPopover
            containerId='resetConfirmModal'
            placement='top'
            popoverText='Node Details'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
      </Modal.Header>

      <Modal.Body className='p-2'>
        <ListGroup as='ul' variant='flush'>
          {Object.entries(previewItem)
            .sort()
            .map(([key, value], index) => {
              return (
                <ListGroup.Item as='li' className='fs--1' key={index}>
                  <div className='fw-bold'>{key.toString()}</div>
                  {typeof value == 'object' ? (
                    <pre className='mb-0'>
                      {JSON.stringify(value, undefined, 2)}
                    </pre>
                  ) : (
                    <pre className='mb-0'>{value}</pre>
                  )}
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setShowPreviewModal(false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalPreview.propTypes = {
  previewItem: PropTypes.object,
  showPreviewModal: PropTypes.bool,
  setShowPreviewModal: PropTypes.func
};

export default ModalPreview;
