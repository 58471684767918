import React from 'react';
import PropTypes from 'prop-types';
import ProductsProvider from '../../../../../../context/admin-contexts/ProductsProvider';
import IndicesProvider from 'context/admin-contexts/IndicesProvider';
import ProductsLayout from './ProductsLayout';
import ModalProductAdd from './components/modals/ProductsAddModal';
import ModalProductEdit from './components/modals/ProductsEditModal';
import ModalProductDelete from './components/modals/ProductsDeleteModal';
import ModalProductRestore from './components/modals/ProductsRestoreModal';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ProductsWrapper = props => {
  return (
    <IndicesProvider>
      <ProductsProvider>
        {props.children}
        <ProductsLayout />
        <ModalProductAdd />
        <ModalProductEdit />
        <ModalProductRestore />
        <ModalProductDelete />
      </ProductsProvider>
    </IndicesProvider>
  );
};

ProductsWrapper.propTypes = { children: PropTypes.node };

export default ProductsWrapper;
