import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import DeleteDashboardForm from '../forms/DeleteDashboardForm';

const DeleteDashboardModal = ({
  showDeleteDashboardModal,
  setShowDeleteDashboardModal
}) => {
  const {
    application: { isDark }
  } = useApplication();

  return (
    <>
      <Modal
        backdrop='static'
        keyboard={false}
        show={showDeleteDashboardModal.open}
        onHide={() =>
          setShowDeleteDashboardModal({ open: false, id: '', fetch: false })
        }
        size='lg'
        aria-labelledby='newDashboardModal'
        centered>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Add a dashboard
            <AdvancedPopover
              containerId='resetConfirmModal'
              placement='top'
              popoverText='Add a dashboard'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
        </Modal.Header>

        <Modal.Body className='p-2'>
          <DeleteDashboardForm
            showDeleteDashboardModal={showDeleteDashboardModal}
            setShowDeleteDashboardModal={setShowDeleteDashboardModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

DeleteDashboardModal.propTypes = {
  showDeleteDashboardModal: PropTypes.object,
  setShowDeleteDashboardModal: PropTypes.func
};

export default DeleteDashboardModal;
