import React from 'react';
import ReleaseAddButton from './ReleaseAddButton';
import Releases from './Releases';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useReleases from 'hooks/admin-contexts/useReleases';

/**
 * Renders the ReleasesLayout component
 *
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ReleasesLayout = () => {
  const { loading: componentLoading } = useReleases();

  return (
    <>
      {componentLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <ReleaseAddButton />
          <Releases />
        </>
      )}
    </>
  );
};

export default ReleasesLayout;
