import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import routes from 'config/routeSettings';
import { getUserStatusSlug } from 'helpers/utils';
import useAuthentication from 'hooks/useAuthentication';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row
} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

/**
 * Renders a banner component for the user's profile.
 *
 * @component
 * @param {Object} user - The user object containing profile information.
 * @returns {JSX.Element} The rendered Banner component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const Banner = ({ user }) => {
  const {
    authentication: { roles, emails }
  } = useAuthentication();
  let {
    profile: { fullName, media: { avatar, banner } = {} } = {},
    lastSeenAt
  } = user;
  const location = useLocation();
  const status = getUserStatusSlug(lastSeenAt);

  return (
    <Card className='mb-3 shadow-none border'>
      <Background
        image={banner || process.env.REACT_APP_DEFAULT_USER_BANNER_IMG}
        className='rounded-3 rounded-bottom-0 position-relative'
        style={{ height: '250px' }}
      />
      <Card.Header className='py-0' style={{ marginTop: '-145px' }}>
        <Avatar
          size='5xl'
          className={`${status !== 'offline' && `status-${status}`}`}
          src={avatar || process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG}
          mediaClass='img-thumbnail shadow-none'
        />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col sm={12}>
            <Flex direction='row' justifyContent='between' alignItems='center'>
              <Flex direction='row' justifyContent='start'>
                <Flex
                  direction='column'
                  justifyContent='between'
                  className='me-3'>
                  <h4 className='fs-2'>
                    {fullName}
                    {roles.some(role =>
                      ['super-admin', 'admin'].includes(role.slug)
                    ) && (
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Popover>
                            <Popover.Body className='text-center p-1 fs--1 text-700'>
                              Léargas Administrator
                            </Popover.Body>
                          </Popover>
                        }>
                        <span className='text-primary fs-1 ms-1'>
                          <FontAwesomeIcon
                            icon={['fas', 'clover']}
                            transform='rotate-45'
                            className='text-primary ms-1'
                          />
                        </span>
                      </OverlayTrigger>
                    )}
                  </h4>
                  <h4 className='fs-0 fw-normal'>
                    {emails.find(email => email.isPrimary === true).address}
                  </h4>
                </Flex>
              </Flex>
              <Flex>
                {location.pathname !== routes.ACCOUNT_PROFILE_EDIT ? (
                  <Button
                    size='sm'
                    as={Link}
                    to={routes.ACCOUNT_PROFILE_EDIT}
                    className='ms-2 align-items-center d-flex'>
                    <FontAwesomeIcon icon={['fas', 'pen']} className='me-1' />
                    Edit Profile
                  </Button>
                ) : (
                  <Button
                    size='sm'
                    as={Link}
                    to={routes.ACCOUNT_PROFILE}
                    className='ms-2 align-items-center d-flex'>
                    <FontAwesomeIcon icon={['fas', 'eye']} className='me-1' />
                    View Profile
                  </Button>
                )}
                {location.pathname !== routes.ACCOUNT_SETTINGS ? (
                  <Button
                    size='sm'
                    as={Link}
                    to={routes.ACCOUNT_PROFILE_EDIT}
                    className='ms-2 px-2 align-items-center d-flex'>
                    <FontAwesomeIcon icon={['fas', 'cog']} />
                  </Button>
                ) : null}
              </Flex>
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

Banner.propTypes = {
  user: PropTypes.object.isRequired
};

export default Banner;
