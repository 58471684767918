/**
 * A dictionary mapping ISO country codes to their full country names.
 *
 * These are based on the ISO 3166-1 alpha-2 country codes.
 * Names are intended to align with the official ISO 3166-1 country names.
 */
export const mapISOToCountryName = {
  AF: 'Afghanistan',
  AX: 'Aland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  // CD: 'Congo, Democratic Republic of the',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Cote d'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See (Vatican City State)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran, Islamic Republic of',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea, Democratic People's Republic of",
  KR: 'Korea, Republic of',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia, the former Yugoslav Republic of',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States of',
  MD: 'Moldova, Republic of',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Territory, Occupied',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  // RU: 'Russian Federation',
  RU: 'Russia',
  RW: 'Rwanda',
  BL: 'Saint Barthelemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan, Province of China',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UM: 'United States Minor Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela, Bolivarian Republic of',
  VN: 'Viet Nam',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, U.S.',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe'
};

/**
 * Converts the country name based on the country code.
 *
 * @param {string} code - The ISO 3166-1 alpha-2 country code.
 * @returns {string | false} The country name or false if the code is not found.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.4
 * @version 0.1.0-beta.4
 */
export function convertISOToCountryName(code) {
  const countryCode = code.toUpperCase();
  return mapISOToCountryName[countryCode];
}

/**
 * Converts the country code based on the country name.
 *
 * @param {string} code - The ISO 3166-1 alpha-2 country code.
 * @returns {string | false} The country name or false if the code is not found.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.4
 * @version 0.1.0-beta.4
 */
const mapCountryNameToISO = Object.keys(mapISOToCountryName).reduce(
  (acc, isoCode) => {
    const countryName = mapISOToCountryName[isoCode];
    acc[countryName.toUpperCase()] = isoCode;
    return acc;
  },
  {}
);

export function convertCountryNameToISO(countryName) {
  const countryNameUpper = String(countryName).toUpperCase();
  return mapCountryNameToISO[countryNameUpper];
}

/**
 * Converts a given language code to a locale code.
 *
 * Maps a two-letter ISO language code to a locale string.
 * Includes both language and regional dialect.
 * locale for English (United States) if language code is not recognized.
 *
 * @param languageCode - A two-letter ISO language code.
 * @returns The corresponding locale string. Default is "en-US".
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.4
 * @version 0.1.0-beta.4
 */
export function convertLanguageToLocale(languageCode) {
  return mapLanguageToLocale[languageCode] || 'en-US';
}

/**
 * A dictionary mapping ISO language codes to their full locale specification.
 *
 * The locale includes both the language and regional dialect (country).
 */
export const mapLanguageToLocale = {
  af: 'af-ZA', // Afrikaans (South Africa)
  am: 'am-ET', // Amharic (Ethiopia)
  ar: 'ar-SA', // Arabic (Saudi Arabia)
  az: 'az-AZ', // Azerbaijani (Azerbaijan)
  be: 'be-BY', // Belarusian (Belarus)
  bg: 'bg-BG', // Bulgarian (Bulgaria)
  bn: 'bn-BD', // Bengali (Bangladesh)
  bs: 'bs-BA', // Bosnian (Bosnia and Herzegovina)
  ca: 'ca-ES', // Catalan (Spain)
  cs: 'cs-CZ', // Czech (Czech Republic)
  da: 'da-DK', // Danish (Denmark)
  de: 'de-DE', // German (Germany)
  el: 'el-GR', // Greek (Greece)
  'en-GB': 'en-GB', // English (United Kingdom)
  en: 'en-US', // English (United States)
  'es-419': 'es-419', // Spanish (Latin America)
  es: 'es-ES', // Spanish (Spain)
  et: 'et-EE', // Estonian (Estonia)
  eu: 'eu-ES', // Basque (Spain)
  fa: 'fa-IR', // Persian (Iran)
  fi: 'fi-FI', // Finnish (Finland)
  fil: 'fil-PH', // Filipino (Philippines)
  'fr-CA': 'fr-CA', // French (Canada)
  fr: 'fr-FR', // French (France)
  ga: 'ga-IE', // Irish (Ireland)
  gl: 'gl-ES', // Galician (Spain)
  gu: 'gu-IN', // Gujarati (India)
  he: 'he-IL', // Hebrew (Israel), modern code
  hi: 'hi-IN', // Hindi (India)
  hr: 'hr-HR', // Croatian (Croatia)
  hu: 'hu-HU', // Hungarian (Hungary)
  hy: 'hy-AM', // Armenian (Armenia)
  id: 'id-ID', // Indonesian (Indonesia)
  in: 'id-ID', // Legacy support for Indonesian (same locale as "id")
  is: 'is-IS', // Icelandic (Iceland)
  it: 'it-IT', // Italian (Italy)
  iw: 'he-IL', // Hebrew (Israel), legacy
  ja: 'ja-JP', // Japanese (Japan)
  ka: 'ka-GE', // Georgian (Georgia)
  kk: 'kk-KZ', // Kazakh (Kazakhstan)
  km: 'km-KH', // Khmer (Cambodia)
  kn: 'kn-IN', // Kannada (India)
  ko: 'ko-KR', // Korean (South Korea)
  lo: 'lo-LA', // Lao (Laos)
  lt: 'lt-LT', // Lithuanian (Lithuania)
  lv: 'lv-LV', // Latvian (Latvia)
  mk: 'mk-MK', // Macedonian (North Macedonia)
  ml: 'ml-IN', // Malayalam (India)
  mn: 'mn-MN', // Mongolian (Mongolia)
  mr: 'mr-IN', // Marathi (India)
  ms: 'ms-MY', // Malay (Malaysia)
  mt: 'mt-MT', // Maltese (Malta)
  my: 'my-MM', // Burmese (Myanmar)
  ne: 'ne-NP', // Nepali (Nepal)
  nl: 'nl-NL', // Dutch (Netherlands)
  no: 'no-NO', // Norwegian (Norway)
  pa: 'pa-IN', // Punjabi (India)
  pl: 'pl-PL', // Polish (Poland)
  'pt-PT': 'pt-PT', // Portuguese (Portugal)
  pt: 'pt-BR', // Portuguese (Brazil)
  ro: 'ro-RO', // Romanian (Romania)
  ru: 'ru-RU', // Russian (Russia)
  si: 'si-LK', // Sinhala (Sri Lanka)
  sk: 'sk-SK', // Slovak (Slovakia)
  sl: 'sl-SI', // Slovenian (Slovenia)
  sq: 'sq-AL', // Albanian (Albania)
  'sr-Latn': 'sr-RS', // Serbian (Serbia, Latin script)
  sr: 'sr-RS', // Serbian (Serbia, Cyrillic script)
  sv: 'sv-SE', // Swedish (Sweden)
  sw: 'sw-KE', // Swahili (Kenya)
  ta: 'ta-IN', // Tamil (India)
  te: 'te-IN', // Telugu (India)
  th: 'th-TH', // Thai (Thailand)
  tl: 'tl-PH', // Tagalog (Philippines)
  tr: 'tr-TR', // Turkish (Turkey)
  uk: 'uk-UA', // Ukrainian (Ukraine)
  ur: 'ur-PK', // Urdu (Pakistan)
  uz: 'uz-UZ', // Uzbek (Uzbekistan)
  vi: 'vi-VN', // Vietnamese (Vietnam)
  'zh-CN': 'zh-CN', // Chinese Simplified (Mainland China)
  'zh-HK': 'zh-HK', // Chinese Traditional (Hong Kong)
  'zh-SG': 'zh-CN', // Chinese Simplified (Singapore)
  'zh-TW': 'zh-TW', // Chinese Traditional (Taiwan)
  zh: 'zh-CN', // Chinese (China)
  zu: 'zu-ZA' // Zulu (South Africa)
};

/**
 * Converts a language code to its language name with regional dialect.
 *
 * Defaults to "English (United States)" if code doesn't have a name.
 *
 * @param languageCode - A language code, e.g., "en" for English.
 * @returns The full name of the language, including regional dialect.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.4
 * @version 0.1.0-beta.4
 */
export function convertLanguageToLanguageName(languageCode) {
  return mapLanguageToLanguageName[languageCode] || 'English (United States)';
}

/**
 * A mapping of language codes to their names, including regional dialects.
 *
 * @type {Record<string, string>}
 */
export const mapLanguageToLanguageName = {
  af: 'Afrikaans (South Africa)',
  am: 'Amharic (Ethiopia)',
  ar: 'Arabic (Saudi Arabia)',
  az: 'Azerbaijani (Azerbaijan)',
  be: 'Belarusian (Belarus)',
  bg: 'Bulgarian (Bulgaria)',
  bn: 'Bengali (Bangladesh)',
  bs: 'Bosnian (Bosnia and Herzegovina)',
  ca: 'Catalan (Spain)',
  cs: 'Czech (Czech Republic)',
  da: 'Danish (Denmark)',
  de: 'German (Germany)',
  el: 'Greek (Greece)',
  'en-GB': 'English (United Kingdom)',
  en: 'English (United States)',
  'es-419': 'Spanish (Latin America)',
  es: 'Spanish (Spain)',
  et: 'Estonian (Estonia)',
  eu: 'Basque (Spain)',
  fa: 'Persian (Iran)',
  fi: 'Finnish (Finland)',
  fil: 'Filipino (Philippines)',
  'fr-CA': 'French (Canada)',
  fr: 'French (France)',
  ga: 'Irish (Ireland)',
  gl: 'Galician (Spain)',
  gu: 'Gujarati (India)',
  he: 'Hebrew',
  hi: 'Hindi (India)',
  hr: 'Croatian (Croatia)',
  hu: 'Hungarian (Hungary)',
  hy: 'Armenian (Armenia)',
  id: 'Indonesian (Indonesia)',
  in: 'Indonesian (Indonesia)',
  is: 'Icelandic (Iceland)',
  it: 'Italian (Italy)',
  iw: 'Hebrew',
  ja: 'Japanese (Japan)',
  ka: 'Georgian (Georgia)',
  kk: 'Kazakh (Kazakhstan)',
  km: 'Khmer (Cambodia)',
  kn: 'Kannada (India)',
  ko: 'Korean (South Korea)',
  lo: 'Lao (Laos)',
  lt: 'Lithuanian (Lithuania)',
  lv: 'Latvian (Latvia)',
  mk: 'Macedonian (North Macedonia)',
  ml: 'Malayalam (India)',
  mn: 'Mongolian (Mongolia)',
  mr: 'Marathi (India)',
  ms: 'Malay (Malaysia)',
  mt: 'Maltese (Malta)',
  my: 'Burmese (Myanmar)',
  ne: 'Nepali (Nepal)',
  nl: 'Dutch (Netherlands)',
  no: 'Norwegian (Norway)',
  pa: 'Punjabi (India)',
  pl: 'Polish (Poland)',
  'pt-PT': 'Portuguese (Portugal)',
  pt: 'Portuguese (Brazil)',
  ro: 'Romanian (Romania)',
  ru: 'Russian (Russia)',
  si: 'Sinhala (Sri Lanka)',
  sk: 'Slovak (Slovakia)',
  sl: 'Slovenian (Slovenia)',
  sq: 'Albanian (Albania)',
  'sr-Latn': 'Serbian (Serbia, Latin script)',
  sr: 'Serbian (Serbia, Cyrillic script)',
  sv: 'Swedish (Sweden)',
  sw: 'Swahili (Kenya)',
  ta: 'Tamil (India)',
  te: 'Telugu (India)',
  th: 'Thai (Thailand)',
  tl: 'Tagalog (Philippines)',
  tr: 'Turkish (Turkey)',
  uk: 'Ukrainian (Ukraine)',
  ur: 'Urdu (Pakistan)',
  uz: 'Uzbek (Uzbekistan)',
  vi: 'Vietnamese (Vietnam)',
  'zh-CN': 'Chinese Simplified (Mainland China)',
  'zh-HK': 'Chinese Traditional (Hong Kong)',
  'zh-SG': 'Chinese Simplified (Singapore)',
  'zh-TW': 'Chinese Traditional (Taiwan)',
  zh: 'Chinese (China)',
  zu: 'Zulu (South Africa)'
};

/**
 * Converts a language code to its name, in that language.
 *
 * Defaults to "English (United States)" if a code doesn't have name.
 *
 * @param languageCode - A language code, e.g., "en" for English.
 * @returns The full name of the language, including regional dialect.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.4
 * @version 0.1.0-beta.4
 */
export function convertLanguageToLanguageNameNative(languageCode) {
  return (
    mapLanguageToLanguageNameNative[languageCode] || 'English (United States)'
  );
}

/**
 * A mapping of language codes to their full language names, in that language.
 *
 * @type {Record<string, string>}
 */
export const mapLanguageToLanguageNameNative = {
  af: 'Afrikaans',
  am: 'አማርኛ',
  ar: 'العربية',
  az: 'Azərbaycanca',
  be: 'Беларуская',
  bg: 'български',
  bn: 'বাংলা',
  bs: 'Bosanski',
  ca: 'Català',
  cs: 'Čeština',
  da: 'Dansk',
  de: 'Deutsch',
  el: 'Ελληνικά',
  'en-GB': 'English (United Kingdom)',
  en: 'English (United States)',
  'es-419': 'Español (Latinoamérica)',
  es: 'Español (España)',
  et: 'Eesti',
  eu: 'Euskara',
  fa: 'فارسی',
  fi: 'Suomi',
  fil: 'Filipino',
  'fr-CA': 'Français (Canada)',
  fr: 'Français (France)',
  ga: 'Gaeilge',
  gl: 'Galego',
  gu: 'ગુજરાતી',
  he: 'עברית',
  hi: 'हिन्दी',
  hr: 'Hrvatski',
  hu: 'Magyar',
  hy: 'Հայերեն',
  id: 'Bahasa Indonesia',
  in: 'Bahasa Indonesia',
  is: 'Íslenska',
  it: 'Italiano',
  iw: 'עברית',
  ja: '日本語',
  ka: 'ქართული',
  kk: 'Қазақ',
  km: 'ភាសាខ្មែរ',
  kn: 'ಕನ್ನಡ',
  ko: '한국어',
  lo: 'ລາວ',
  lt: 'Lietuvių',
  lv: 'Latviešu',
  mk: 'Македонски',
  ml: 'മലയാളം',
  mn: 'Монгол',
  mr: 'मराठी',
  ms: 'Bahasa Melayu',
  mt: 'Malti',
  my: 'မြန်မာ',
  ne: 'नेपाली',
  nl: 'Nederlands',
  no: 'Norsk',
  pa: 'ਪੰਜਾਬੀ',
  pl: 'Polski',
  'pt-PT': 'Português (Portugal)',
  pt: 'Português (Brasil)',
  ro: 'Română',
  ru: 'Русский',
  si: 'සිංහල',
  sk: 'Slovenčina',
  sl: 'Slovenščina',
  sq: 'Shqip',
  'sr-Latn': 'Srpski (latinica)',
  sr: 'Српски (ћирилица)',
  sv: 'Svenska',
  sw: 'Kiswahili',
  ta: 'தமிழ்',
  te: 'తెలుగు',
  th: 'ไทย',
  tl: 'Tagalog',
  tr: 'Türkçe',
  uk: 'Українська',
  ur: 'اردو',
  uz: "O'zbek",
  vi: 'Tiếng Việt',
  'zh-CN': '中文（简体）',
  'zh-HK': '中文（繁體，香港）',
  'zh-SG': '中文（简体，新加坡）',
  'zh-TW': '中文（繁體，台灣）',
  zh: '中文',
  zu: 'isiZulu'
};

/**
 * Converts a locale to an associated time zone string identifier.
 *
 * Returns the IANA time zone identifier corresponding to the given locale.
 * If no specific match is found, it defaults to "America/New_York".
 *
 * @notice This is an approximation and may not be accurate for all locales.
 * Only use this function as a fallback when no other information is available.
 *
 * @param locale - The locale code for which to retrieve the time zone.
 * @returns The IANA time zone identifier corresponding to the locale, or "America/New_York" if no match is found.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.4
 * @version 0.1.0-beta.4
 */
export function convertLocaleToTimezone(locale) {
  return mapLocaleToTimezone[locale] || 'America/New_York';
}

/**
 * A mapping of locale codes to IANA time zone identifiers.
 *
 * @type {Record<string, string>}
 */
export const mapLocaleToTimezone = {
  'af-ZA': 'Africa/Johannesburg',
  'ar-SA': 'Asia/Riyadh',
  'az-AZ': 'Asia/Baku',
  'be-BY': 'Europe/Minsk',
  'bg-BG': 'Europe/Sofia',
  'bn-BD': 'Asia/Dhaka',
  'bs-BA': 'Europe/Sarajevo',
  'ca-ES': 'Europe/Madrid',
  'cs-CZ': 'Europe/Prague',
  'da-DK': 'Europe/Copenhagen',
  'de-AT': 'Europe/Vienna',
  'de-CH': 'Europe/Zurich',
  'de-DE': 'Europe/Berlin',
  'el-GR': 'Europe/Athens',
  'en-AU': 'Australia/Sydney',
  'en-CA': 'America/Toronto',
  'en-IE': 'Europe/Dublin',
  'en-IN': 'Asia/Kolkata',
  'en-NZ': 'Pacific/Auckland',
  'en-US': 'America/New_York',
  'en-ZA': 'Africa/Johannesburg',
  'es-AR': 'America/Buenos_Aires',
  'es-CO': 'America/Bogota',
  'es-ES': 'Europe/Madrid',
  'es-MX': 'America/Mexico_City',
  'es-US': 'America/New_York',
  'et-EE': 'Europe/Tallinn',
  'eu-ES': 'Europe/Madrid',
  'fa-IR': 'Asia/Tehran',
  'fi-FI': 'Europe/Helsinki',
  'fr-BE': 'Europe/Brussels',
  'fr-CH': 'Europe/Zurich',
  'fr-FR': 'Europe/Paris',
  'ga-IE': 'Europe/Dublin',
  'gu-IN': 'Asia/Kolkata',
  'he-IL': 'Asia/Jerusalem',
  'hi-IN': 'Asia/Kolkata',
  'hr-HR': 'Europe/Zagreb',
  'hu-HU': 'Europe/Budapest',
  'hy-AM': 'Asia/Yerevan',
  'id-ID': 'Asia/Jakarta',
  'is-IS': 'Atlantic/Reykjavik',
  'it-IT': 'Europe/Rome',
  'ja-JP': 'Asia/Tokyo',
  'ja-KR': 'Asia/Seoul',
  'ka-GE': 'Asia/Tbilisi',
  'kk-KZ': 'Asia/Almaty',
  'kn-IN': 'Asia/Kolkata',
  'ko-KR': 'Asia/Seoul',
  'lt-LT': 'Europe/Vilnius',
  'lv-LV': 'Europe/Riga',
  'mk-MK': 'Europe/Skopje',
  'mn-MN': 'Asia/Ulaanbaatar',
  'mr-IN': 'Asia/Kolkata',
  'ms-MY': 'Asia/Kuala_Lumpur',
  'mt-MT': 'Europe/Malta',
  'ne-NP': 'Asia/Kathmandu',
  'nl-BE': 'Europe/Brussels',
  'nl-NL': 'Europe/Amsterdam',
  'no-NO': 'Europe/Oslo',
  'pa-IN': 'Asia/Kolkata',
  'pl-PL': 'Europe/Warsaw',
  'pt-AO': 'Africa/Luanda',
  'pt-BR': 'America/Sao_Paulo',
  'pt-MZ': 'Africa/Maputo',
  'ro-RO': 'Europe/Bucharest',
  'ru-RU': 'Europe/Moscow',
  'ru-UA': 'Europe/Kiev',
  'sk-SK': 'Europe/Bratislava',
  'sl-SI': 'Europe/Ljubljana',
  'sq-AL': 'Europe/Tirane',
  'sr-RS': 'Europe/Belgrade',
  'sv-SE': 'Europe/Stockholm',
  'sw-KE': 'Africa/Nairobi',
  'ta-IN': 'Asia/Kolkata',
  'te-IN': 'Asia/Kolkata',
  'th-TH': 'Asia/Bangkok',
  'tl-PH': 'Asia/Manila',
  'tr-TR': 'Europe/Istanbul',
  'uk-UA': 'Europe/Kiev',
  'ur-PK': 'Asia/Karachi',
  'uz-UZ': 'Asia/Tashkent',
  'vi-VN': 'Asia/Ho_Chi_Minh',
  'zh-CN': 'Asia/Shanghai',
  'zh-MO': 'Asia/Macau'
};

/**
 * Retrieves the currency code associated with a given locale.
 *
 * Converts a locale code to a currency code.
 * If the locale is not found in the mapping, it defaults to "USD" (U.S. Dollar)
 *
 * @param locale - The locale for which to retrieve the currency code.
 * @returns The currency code associated with the locale, or "USD" as a default.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.4
 * @version 0.1.0-beta.4
 */
export function convertLocaleToCurrency(locale) {
  return mapLocaleToCurrency[locale] || 'USD';
}

/**
 * A mapping of locale codes to their respective currency codes.
 *
 * @type {Record<string, string>}
 */
const mapLocaleToCurrency = {
  'af-ZA': 'ZAR',
  'sq-AL': 'ALL',
  'ar-SA': 'SAR',
  'hy-AM': 'AMD',
  'az-AZ': 'AZN',
  'eu-ES': 'EUR',
  'be-BY': 'BYN',
  'bn-BD': 'BDT',
  'bs-BA': 'BAM',
  'bg-BG': 'BGN',
  'ca-ES': 'EUR',
  'zh-CN': 'CNY',
  'hr-HR': 'HRK',
  'cs-CZ': 'CZK',
  'da-DK': 'DKK',
  'nl-NL': 'EUR',
  'en-US': 'USD',
  'et-EE': 'EUR',
  'fi-FI': 'EUR',
  'fr-FR': 'EUR',
  'ka-GE': 'GEL',
  'de-DE': 'EUR',
  'el-GR': 'EUR',
  'gu-IN': 'INR',
  'he-IL': 'ILS',
  'hi-IN': 'INR',
  'hu-HU': 'HUF',
  'is-IS': 'ISK',
  'id-ID': 'IDR',
  'ga-IE': 'EUR',
  'it-IT': 'EUR',
  'ja-JP': 'JPY',
  'kn-IN': 'INR',
  'kk-KZ': 'KZT',
  'ko-KR': 'KRW',
  'lv-LV': 'EUR',
  'lt-LT': 'EUR',
  'mk-MK': 'MKD',
  'ms-MY': 'MYR',
  'mt-MT': 'EUR',
  'mr-IN': 'INR',
  'mn-MN': 'MNT',
  'ne-NP': 'NPR',
  'no-NO': 'NOK',
  'fa-IR': 'IRR',
  'pl-PL': 'PLN',
  'pt-BR': 'BRL',
  'pa-IN': 'INR',
  'ro-RO': 'RON',
  'ru-RU': 'RUB',
  'sr-RS': 'RSD',
  'sk-SK': 'EUR',
  'sl-SI': 'EUR',
  'es-ES': 'EUR',
  'sw-KE': 'KES',
  'sv-SE': 'SEK',
  'tl-PH': 'PHP',
  'ta-IN': 'INR',
  'te-IN': 'INR',
  'th-TH': 'THB',
  'tr-TR': 'TRY',
  'uk-UA': 'UAH',
  'ur-PK': 'PKR',
  'uz-UZ': 'UZS',
  'vi-VN': 'VND'
};

// Map of language codes to their corresponding directionality
const RTL_LANGUAGES = ['ar', 'he', 'iw', 'fa', 'ur'];

export const isRtl = language => {
  return RTL_LANGUAGES.includes(language);
};
