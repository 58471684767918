import React from 'react';
import PropTypes from 'prop-types';
import TeamsProvider from '../../../../../../context/admin-contexts/TeamsProvider';
import UsersProvider from '../../../../../../context/admin-contexts/UsersProvider';
import OrganizationProvider from 'context/admin-contexts/OrganizationsProvider';
import TeamsLayout from './TeamsLayout';
import ModalTeamAdd from './components/modals/TeamsAddModal';
import ModalTeamEdit from './components/modals/TeamsEditModal';
import ModalTeamDelete from './components/modals/TeamsDeleteModal';
import TeamsRestoreModal from './components/modals/TeamsRestoreModal';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const TeamsWrapper = props => {
  return (
    <OrganizationProvider>
      <UsersProvider>
        <TeamsProvider>
          {props.children}
          <TeamsLayout />
          <ModalTeamAdd />
          <ModalTeamEdit />
          <ModalTeamDelete />
          <TeamsRestoreModal />
        </TeamsProvider>
      </UsersProvider>
    </OrganizationProvider>
  );
};

TeamsWrapper.propTypes = { children: PropTypes.node };

export default TeamsWrapper;
