import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useSavedSearches from 'hooks/user-contexts/useUserSavedSearches';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

/**
 * Renders the SavedSearchesDeleteForm component
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const SavedSearchesDeleteForm = () => {
  const {
    savedSearches: {
      deleteSavedSearchesModal: { defaultData }
    },
    setSavedSearches
  } = useSavedSearches();
  const { axiosPrivate } = useAxiosPrivate();

  const [loading, setLoading] = useState(false);

  /**
   * Handle the submit of the savedSearches delete form
   *
   * @param {Event} e - The event object
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const handleSubmit = async e => {
    e.preventDefault();

    try {
      setLoading(true);
      let res = await LeargasAPI.AppSavedSearches.deleteSavedSearches(
        defaultData?._id,

        axiosPrivate
      );
      if (res instanceof Error) {
        throw res;
      }
      const { message } = res.data;
      toast.success(message);
      setLoading(false);
      setSavedSearches(prevState => ({
        ...prevState,
        fetch: true,

        deleteSavedSearchesModal: { open: false, defaultData: {} }
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
      setSavedSearches(prevState => ({
        ...prevState,
        fetch: true,
        deleteSavedSearchesModal: { open: false, defaultData: {} }
      }));
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='g-3 mb-3'>
        <p className='mb-1'>
          Are you sure you want to delete the this saved search?
        </p>
        <p className='fw-bold'>{String(defaultData?.name).toLowerCase()}</p>
        <Col sm={12}></Col>
      </Row>

      <Flex justifyContent='end'>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() => {
            setSavedSearches(prevState => ({
              ...prevState,
              deleteSavedSearchesModal: { open: false, defaultData: {} }
            }));
          }}>
          Cancel
        </Button>

        <Button variant='danger' size='sm' type='submit' className='d-flex'>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Deleting' : 'Delete'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

export default SavedSearchesDeleteForm;
