import 'bootstrap/dist/js/bootstrap.js';
import MainRouter from 'main/MainRouter';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';

/**
 * Main component.
 *
 * @returns {JSX.Element} The rendered Main component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
const Main = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <MainRouter />
    </Router>
  );
};

export default Main;
