import Flex from 'components/common/Flex';
import AdvancedTable from 'components/common/table/Table';
import AdvancedTableFooter from 'components/common/table/TableFooter';
import AdvancedTablePagination from 'components/common/table/TablePagination';
import AdvancedTableSettingsModal from 'components/common/table/TableSettingsModal';
import AdvancedTableWrapper from 'components/common/table/TableWrapper';
import ActiveShards from 'components/dashboards/System/SearchCluster/Health/ActiveShards';
import IndicesCount from 'components/dashboards/System/SearchCluster/Health/IndicesCount';
import { capitalizeWords } from 'helpers/utils';
import { useCluster } from 'hooks/admin-contexts/useCluster';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import ModalPreview from './ModalPreview';
import TableFilters from './TableFilters';

const ElasticsearchIndices = () => {
  const {
    cluster: {
      clusterHealth,
      clusterIndices: { indices }
    }
  } = useCluster();
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState('');
  const [settingsModalShow, setSettingsModalShow] = useState(false);
  const [showHidden] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([
    'name',
    'health',
    'primariesCount',
    'replicasCount',
    'primaries.docs.count',
    'primaries.store.size_in_bytes',
    'actions'
  ]);
  const [indexes, setIndexes] = useState([]);
  const [columns, setColumns] = useState([]);
  const [total, setTotal] = useState(0);
  const [previewItem, setPreviewItem] = useState({});
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const columnDefaults = [
    {
      accessor: 'name',
      Header: 'Name',
      sortType: 'string',
      noTruncate: true,
      cellProps: {
        className: 'align-middle fs--2 text-700 text-nowrap'
      },
      headerProps: {
        className: 'fs--2 text-nowrap'
      }
    }
  ];

  const columnActions = [
    {
      accessor: 'actions',
      Header: '',
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <Button
            variant='falcon-light'
            size='sm'
            onClick={() => {
              setPreviewItem(id);
              setShowPreviewModal(true);
            }}>
            <BsSearch />
          </Button>
        );
      },
      cellProps: {
        className: 'text-end actions-cell',
        style: {
          width: '42px'
        }
      },
      hideDisplaySettings: true,
      disableSortBy: true
    }
  ];

  useEffect(() => {
    if (indices) {
      const indicesKeys = Object.keys(indices);
      const indicesArray = Object.values(indices);
      for (var i = 0; i < indicesArray.length; i++) {
        indicesArray[i].name = indicesKeys[i];
        indicesArray[i].primariesCount =
          clusterHealth.indices[indicesArray[i].name].active_primary_shards;
        indicesArray[i].replicasCount =
          clusterHealth.indices[indicesArray[i].name].number_of_replicas;
        indicesArray[i].health =
          clusterHealth.indices[indicesArray[i].name].status
            .charAt(0)
            .toUpperCase() +
          clusterHealth.indices[indicesArray[i].name].status.slice(1);
      }
      setIndexes(
        indicesArray
          .filter(
            indice => !showHidden && indice.name.startsWith('.') === false
          )
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
      );
      setTotal(indices.length);
      let newCols = [];
      if (columnDefaults) setColumns(columnDefaults);
      Object.values(indices).map(item => {
        Object.keys(item).map(key => {
          const index = newCols.findIndex(object => object.accessor === key);
          if (index === -1) {
            newCols.push({
              accessor: key,
              Header: capitalizeWords(key.replaceAll('_', ' ')),
              Cell: rowData => {
                const { value } = rowData.cell;
                return value !== undefined ? (
                  String(value)
                ) : (
                  <span className='text-300'>{'N/A'}</span>
                );
              },
              cellProps: {
                className: 'align-middle fs--2 text-700 text-nowrap'
              },
              headerProps: {
                className: 'fs--2 text-nowrap'
              }
            });
          }
        });
      });
      setColumns([
        ...columnDefaults,
        ...newCols
          .filter(item => {
            return columnDefaults.findIndex(
              object => object.accessor === item.accessor
            );
          })
          .sort((a, b) =>
            a.accessor > b.accessor ? 1 : b.accessor > a.accessor ? -1 : 0
          ),
        ...columnActions
      ]);
      setLoading(false);
    }
  }, [indices]);

  return (
    <>
      <Row className='g-3 mb-3'>
        <Col sm={4}>
          <IndicesCount />
        </Col>
        <Col sm={4}>
          <ActiveShards />
        </Col>
      </Row>
      <Row className='g-3 mb-3'>
        <Col md={12}>
          <Card>
            <Tab.Container id='audience-tab' defaultActiveKey='indices'>
              <Card.Header
                as={Flex}
                justifyContent='between'
                alignItems='center'
                className='ps-0 py-0 border-bottom'>
                <Nav
                  as='ul'
                  className='nav-tabs border-0 flex-nowrap chart-tab tab-active-caret'>
                  <Nav.Item as='li'>
                    <Nav.Link
                      className='mb-0 py-3 cursor-pointer'
                      eventKey='indices'>
                      {'Indices'}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as='li'>
                    <Nav.Link
                      className='mb-0 py-3 cursor-pointer'
                      eventKey='shards'>
                      {'Shards'}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Card.Body className='pt-0' style={{ minHeight: '100px' }}>
                {loading ? (
                  <div className='position-absolute d-flex justify-content-center align-items-center pt-4 w-100 navbar-glass-loader'>
                    <Spinner animation='border' />
                  </div>
                ) : (
                  <Tab.Content>
                    <Tab.Pane unmountOnExit eventKey='indices'>
                      <AdvancedTableWrapper
                        columns={columns}
                        data={indexes}
                        sortable
                        pagination
                        perPage={100}>
                        <TableFilters
                          table
                          dataTypeLabel='Index'
                          dataTypeLabelPlural='Indices'
                          globalFilter={globalFilter}
                          setGlobalFilter={setGlobalFilter}
                          hideNewEntryToggle
                          hideFiltersToggle
                          settingsModalShow={settingsModalShow}
                          setSettingsModalShow={setSettingsModalShow}
                        />
                        <AdvancedTableSettingsModal
                          columns={columns}
                          settingsModalShow={settingsModalShow}
                          setSettingsModalShow={setSettingsModalShow}
                          visibleColumns={visibleColumns}
                          setVisibleColumns={setVisibleColumns}
                        />
                        <ModalPreview
                          previewItem={previewItem}
                          showPreviewModal={showPreviewModal}
                          setShowPreviewModal={setShowPreviewModal}
                        />
                        <AdvancedTable
                          table
                          visibleColumns={visibleColumns}
                          showPreviewModal={showPreviewModal}
                          setShowPreviewModal={setShowPreviewModal}
                        />
                        <div className='mt-3 d-flex justify-content-between'>
                          <AdvancedTableFooter
                            rowCount={total}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection={100}
                            rowsPerPageOptions={[100, 250, 500]}
                          />
                          <AdvancedTablePagination table />
                        </div>
                      </AdvancedTableWrapper>
                    </Tab.Pane>
                    <Tab.Pane unmountOnExit eventKey='users'>
                      Test
                    </Tab.Pane>
                  </Tab.Content>
                )}
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ElasticsearchIndices;
