import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import useRoles from 'hooks/admin-contexts/useRoles';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Modal } from 'react-bootstrap';
import RolesAddForm from '../forms/RolesAddForm';
/**
 * role add Modal
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ModalRoleAdd = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    roles: { addRoleModal },
    setRoles
  } = useRoles();

  return (
    <Modal
      centered
      size={addRoleModal.fullScreen ? 'xl' : 'lg'}
      animation={false}
      backdrop='static'
      keyboard={false}
      show={addRoleModal?.open}
      onHide={() =>
        setRoles(prevState => ({
          ...prevState,
          addRoleModal: { open: false }
        }))
      }
      aria-labelledby='modalRoleAdd'
      contentClassName={
        addRoleModal.fullScreen &&
        'border-0 border-top-left-radius-0 border-top-right-radius-0 h-100'
      }
      dialogClassName={
        addRoleModal.fullScreen &&
        'position-absolute all-0 fullscreen-modal-dialog scrollbar overflow-x-hidden'
      }>
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Add a role
            <AdvancedPopover
              containerId='resetConfirmModal'
              placement='top'
              popoverText='Add a role'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
          <div
            className='d-flex justify-content-end position-absolute right-0 '
            style={{ right: '3rem' }}>
            <FontAwesomeIcon
              icon={
                addRoleModal.fullScreen
                  ? 'down-left-and-up-right-to-center'
                  : 'up-right-and-down-left-from-center'
              }
              className='me-2 cursor-pointer'
              style={{
                color: isDark ? 'white' : 'black',
                opacity: 0.8
              }}
              onClick={() => {
                setRoles(prevState => ({
                  ...prevState,
                  addRoleModal: {
                    open: true,
                    fullScreen: !addRoleModal.fullScreen
                  }
                }));
              }}
            />
          </div>
        </Modal.Header>

        <Modal.Body className='p-2'>
          <RolesAddForm />
        </Modal.Body>
      </Flex>
    </Modal>
  );
};

export default ModalRoleAdd;
