import classNames from 'classnames';
import Flex from 'components/common/Flex';
import SimpleSpinner from 'components/utilities/AppSpinner/smallSpinner';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Object representing different position styles for a loading indicator overlay.
 *
 * @typedef {Object} PositionStyles
 * @property {Object} center - Center position style.
 * @property {string} center.justifyContent - Justify content value for center position.
 * @property {string} center.alignItems - Align items value for center position.
 * @property {Object} top - Top position style.
 * @property {string} top.justifyContent - Justify content value for top position.
 * @property {string} top.alignItems - Align items value for top position.
 * @property {Object} top-right - Top right position style.
 * @property {string} top-right.justifyContent - Justify content value for top right position.
 * @property {string} top-right.alignItems - Align items value for top right position.
 * @property {Object} right - Right position style.
 * @property {string} right.justifyContent - Justify content value for right position.
 * @property {string} right.alignItems - Align items value for right position.
 * @property {Object} bottom-right - Bottom right position style.
 * @property {string} bottom-right.justifyContent - Justify content value for bottom right position.
 * @property {string} bottom-right.alignItems - Align items value for bottom right position.
 * @property {Object} bottom - Bottom position style.
 * @property {string} bottom.justifyContent - Justify content value for bottom position.
 * @property {string} bottom.alignItems - Align items value for bottom position.
 * @property {Object} bottom-left - Bottom left position style.
 * @property {string} bottom-left.justifyContent - Justify content value for bottom left position.
 * @property {string} bottom-left.alignItems - Align items value for bottom left position.
 * @property {Object} left - Left position style.
 * @property {string} left.justifyContent - Justify content value for left position.
 * @property {string} left.alignItems - Align items value for left position.
 * @property {Object} top-left - Top left position style.
 * @property {string} top-left.justifyContent - Justify content value for top left position.
 * @property {string} top-left.alignItems - Align items value for top left position.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const positionStyles = {
  center: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  top: {
    justifyContent: 'center',
    alignItems: 'flex-start pt-5'
  },
  'top-right': {
    justifyContent: 'flex-end',
    alignItems: 'flex-start'
  },
  right: {
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  'bottom-right': {
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  bottom: {
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  'bottom-left': {
    justifyContent: 'flex-start',
    alignItems: 'flex-end'
  },
  left: {
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  'top-left': {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  }
};

/**
 * LoadingIndicatorOverlay component.
 *
 * @component
 * @param {string} backgroundClass - The background class for the overlay
 * @param {boolean} loading - Indicates whether the loading indicator should be displayed
 * @param {string} position - The position of the overlay. Available options are 'center', 'top', 'top-right', 'right', 'bottom-right', 'bottom', 'bottom-left', 'left', 'top-left'. Defaults to 'center'
 * @param {object} rest - Additional props to be spread on the component
 * @returns {JSX.Element|null} The LoadingIndicatorOverlay component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const LoadingIndicatorOverlay = ({
  backgroundClass = 'bg-card-spinner',
  loading,
  position = 'center',
  semiTransparent = true,
  ...rest
}) => {
  if (!loading) {
    return null;
  }

  // Select the correct style based on position, defaulting to center
  const style = positionStyles[position] || positionStyles.center;

  return (
    <Flex
      direction='row'
      justifyContent={style.justifyContent}
      alignItems={style.alignItems}
      className={classNames(
        'position-absolute all-0',
        backgroundClass,
        semiTransparent && 'opacity-75'
      )}
      style={{
        zIndex: 1050,
        pointerEvents: loading ? 'auto' : 'none',
        display: loading ? 'flex' : 'none',
        ...rest.style
      }}>
      <SimpleSpinner size='sm' />
    </Flex>
  );
};

LoadingIndicatorOverlay.propTypes = {
  backgroundClass: PropTypes.string,
  loading: PropTypes.bool,
  position: PropTypes.oneOf([
    'center',
    'top',
    'top-right',
    'right',
    'bottom-right',
    'bottom',
    'bottom-left',
    'left',
    'top-left'
  ]),
  semiTransparent: PropTypes.bool
};

export default LoadingIndicatorOverlay;
