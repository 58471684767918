import React from 'react';
import Jobs from './components/Jobs';
import { Col, Row } from 'react-bootstrap';

/**
 * Jobs Layout
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const JobsLayout = () => {
  return (
    <>
      <Row className='g-3 mb-3'>
        <Col sm={12}>
          <Jobs />
        </Col>
      </Row>
    </>
  );
};

export default JobsLayout;
