import { dynamicDashboardRoutes } from 'components/dashboards/Artifacts/Dynamic/routes';
import { fetchIndices } from './fetch';

/**
 * Initializes the indices and sets the loading state.
 *
 * @param {function} setState - The function to set the state of the indices loading
 * @param {AbortController} controller - The abort controller used to cancel the fetch request
 * @param {function} makeRequest - The function to make the fetch request
 * @returns {Promise<Array>} - A promise that resolves to an array of indices
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const initializeIndices = async (setState, controller, makeRequest) => {
  setState('indicesLoading', true);

  try {
    const indices = await fetchIndices(makeRequest, controller.signal);

    if (indices && indices.length > 0) {
      setState('indicesLoading', false);
      return indices;
    } else {
      // console.warn('No indices returned from fetch.');
      setState('indicesLoading', false);
      return [];
    }
  } catch (error) {
    console.error('Error during indices initialization:', error);
    setState('indicesLoading', false);
  }
};

/**
 * Initializes the selected index based on the provided parameters.
 *
 * @param {function} setState - The function used to set the state
 * @param {Array} indices - The array of indices
 * @param {string} pathname - The pathname to match with the route
 * @returns {void}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const initializeSelectedIndex = (setState, indices, pathname) => {
  const route = dynamicDashboardRoutes.find(route => route.path === pathname);
  if (route) {
    const defaultIndex =
      indices.find(index => index.alias === route?.indicesSelected[0]) ||
      indices[0];
    const defaultFilters =
      route?.filtersDefault && route.filtersDefault.length > 0
        ? route.filtersDefault
        : [];
    setState('filtersDefault', defaultFilters);
    // Set state only if different to prevent repeated updates
    const newIndexSelected =
      defaultIndex !== undefined ? defaultIndex : indices[0];
    setState('indexSelected', newIndexSelected);
  } else {
    console.warn(
      'No route match or indices available. Cannot select default index.'
    );
  }
};

/**
 * Initializes the time range based on the current route.
 *
 * @param {Function} setState - The function to set the state
 * @param {string} interval - The current interval
 * @param {object} timeRange - The current time range
 * @param {string} pathname - The current pathname
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const initializeTimeRange = (
  setState,
  interval,
  timeRange,
  pathname
) => {
  const routeTimeRange = dynamicDashboardRoutes.find(
    route => route.path === pathname
  )?.timeRange;

  if (routeTimeRange) {
    const newInterval = routeTimeRange.getInterval();
    const newTimeRange = routeTimeRange.getTimeRange();

    if (interval !== newInterval) {
      setState('interval', newInterval);
    }
    if (JSON.stringify(timeRange) !== JSON.stringify(newTimeRange)) {
      setState('timeRange', newTimeRange);
      setState('timeRangeSelected', routeTimeRange);
    }
  }
};
