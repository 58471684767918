import LeargasAPI from 'api';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

export const organizationsSettings = {
  data: [],
  DeletedOrgs: {
    data: false
  },
  fetch: false,
  loading: false,
  orgAddModal: {
    fullScreen: false,
    open: false
  },
  orgDeleteModal: {
    data: {},
    open: false
  },
  orgEditModal: {
    data: {},
    fullScreen: false,
    open: false
  },
  orgRestoreModal: {
    data: {},
    open: false
  },
  requiredOrganizations: {
    organizations: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    },
    organizationsNotes: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    organizations: {
      delete: ['organizations:delete'],
      read: ['organizations:read'],
      write: ['organizations:write']
    },
    organizationsNotes: {
      delete: ['organizationsNotes:delete'],
      read: ['organizationsNotes:read'],
      write: ['organizationsNotes:write']
    }
  },
  showDeleted: false
};

export const OrganizationsContext = createContext();

const OrganizationsProvider = props => {
  const [organizations, setOrganizations] = useState(organizationsSettings);
  const [loading, setLoading] = useState(true);

  const { axiosPrivate } = useAxiosPrivate();

  const getAppOrganizations = async () => {
    try {
      let res = await LeargasAPI.Organizations.getOrgs(axiosPrivate);
      if (res instanceof Error) throw res;
      setOrganizations(prevState => ({
        ...prevState,
        data: res
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setOrganizations(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setOrganizations(prevState => ({
        ...prevState,
        fetch: false
      }));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (organizations.fetch) {
      getAppOrganizations();
    }
  }, [organizations.fetch]);

  useEffect(() => {
    getAppOrganizations();
  }, []);

  return (
    <OrganizationsContext.Provider
      value={{
        organizations,
        setOrganizations,
        loading
      }}>
      {props.children}
    </OrganizationsContext.Provider>
  );
};

OrganizationsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default OrganizationsProvider;
