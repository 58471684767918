import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
const { FaRegEnvelope, FaRegCheckCircle, FaLock } = require('react-icons/fa');

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ProfileBannerHeader = ({ avatar, user, className }) => {
  return (
    <Card.Header
      className={`${classNames(className)} p-4`}
      style={{ marginTop: '-175px' }}>
      <div className='border-bottom pb-3'>
        <Flex direction='column' justofyContent='start' alignItems='middle'>
          <Avatar
            size='5xl'
            className={`mb-3 `}
            src={avatar || process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG}
            mediaClass='img-thumbnail shadow-sm'
          />
          <Row>
            <Col lg={8}>
              <h4 className='mb-1'>{user?.profile?.fullName}</h4>
              {user?.emails && user?.emails[0]?.address && (
                <p className='text-500 mb-2 '>
                  <a
                    href={`mailto:${
                      user?.emails &&
                      user?.emails[0]?.address &&
                      user?.emails[0]?.address
                    }`}
                    className='d-flex align-items-center text-decoration-none'>
                    <FaRegEnvelope /> &nbsp;
                    {user?.emails &&
                      user?.emails[0]?.address &&
                      user?.emails[0]?.address.substring(
                        50,
                        user?.emails[0]?.address.indexOf('@')
                      )}
                  </a>
                </p>
              )}

              <p className='d-flex align-items-center text-decoration-none mb-2  justify-content-start'>
                <span
                  className={` ${
                    user?.security?.multifactor?.isEnabled
                      ? 'text-success '
                      : ' text-danger '
                  }`}>
                  {user?.security?.multifactor?.isEnabled && (
                    <FaRegCheckCircle />
                  )}
                  &nbsp;MfaEnabled
                </span>
                &nbsp; &nbsp;
                <span
                  className={` ${
                    user?.security?.multifactor?.app?.isEnabled
                      ? 'text-success '
                      : 'text-warning '
                  }`}>
                  {user?.security?.multifactor?.app?.isEnabled && (
                    <FaRegCheckCircle />
                  )}
                  &nbsp;MfaVerified
                </span>
              </p>
              {user?.locked?.isLocked && (
                <p className='d-flex align-items-end text-decoration-none mb-0'>
                  <span className={` text-dark`}>
                    {user?.locked && <FaLock />}&nbsp;Userlocked
                  </span>
                </p>
              )}
              <div className='border-dashed-bottom my-4 d-lg-none' />
            </Col>
          </Row>
        </Flex>
      </div>
    </Card.Header>
  );
};

const ProfileBannerBody = ({ user }) => {
  return <Card.Body user={user} />;
};

const ProfileBanner = ({ user }) => {
  return (
    <Card className='shadow-none'>
      <Background
        className='rounded-3 rounded-bottom-0 position-relative'
        image={
          user?.profile?.media?.banner ||
          process.env.REACT_APP_DEFAULT_USER_BANNER_IMG
        }
        style={{ height: '250px' }}
      />
      <ProfileBannerHeader user={user} />
      <ProfileBannerBody user={user} />
    </Card>
  );
};

ProfileBannerHeader.propTypes = {
  avatar: PropTypes.string,
  className: PropTypes.string,
  user: PropTypes.object
};

ProfileBannerBody.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object
};

ProfileBanner.propTypes = {
  user: PropTypes.object
};

export default ProfileBanner;
