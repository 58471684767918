import OrgProfileBanner from 'components/common/profile-cards/OrganizationProfileCard/Profile';
import useApplication from 'hooks/useApplication';
import useOrganizations from 'hooks/user-contexts/useUserOrganizations';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

/**
 * list of org cards
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Organizations = () => {
  const {
    organizations: { data, loading }
  } = useOrganizations();

  const {
    application: { isPrivacyMode }
  } = useApplication();

  return (
    <>
      <Row className='g-2 mb-2'>
        {!loading &&
          data
            ?.sort((a, b) =>
              isPrivacyMode
                ? a?.legacy?.clientNumber - b?.legacy?.clientNumber
                : a?.name.localeCompare(b?.name)
            )
            .map((org, index) => {
              return (
                <Col sm={6} md={4} xxl={3} key={index}>
                  <Card className='h-100 shadow-none border'>
                    <OrgProfileBanner
                      org={org}
                      showManagedBy={true}
                      linkManagedBy={false}
                    />
                  </Card>
                </Col>
              );
            })}
        {/* <Col sm={6} md={4} xxl={3}>
          <AddButton
            onClick={() =>
              setOrganizations(prevState => ({
                ...prevState,
                orgAddModal: { open: true }
              }))
            }
          />
        </Col> */}
      </Row>
    </>
  );
};

export default Organizations;
