import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useTeams from 'hooks/admin-contexts/useTeams';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Modal } from 'react-bootstrap';
import FormTeamRestore from '../forms/TeamsRestoreForm';
/**
 * teams restore modal
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ModalTeamRestore = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    teams: { restoreTeamModal },
    setTeams
  } = useTeams();

  return (
    <Modal
      centered
      size='lg'
      animation={false}
      backdrop='static'
      keyboard={false}
      show={restoreTeamModal?.open}
      onHide={() =>
        setTeams(prevState => ({
          ...prevState,
          restoreTeamModal: { open: false, data: {} }
        }))
      }
      aria-labelledby='modalTeamAdd'>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Restore Team: {restoreTeamModal?.data?.name}
          <AdvancedPopover
            containerId='resetConfirmModal'
            placement='top'
            popoverText='Restore Team'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
      </Modal.Header>

      <Modal.Body className='p-2'>
        <FormTeamRestore />
      </Modal.Body>
    </Modal>
  );
};

export default ModalTeamRestore;
