import React from 'react';
// import AdvancedTab from './advanced/AdvancedTab';
import FiltersTab from './filters/FiltersTab';
import useApplication from 'hooks/useApplication';
import ScopeTab from './scope/ScopeTab';
import { Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTriggerPopover } from 'components/visualizations/OverlayTriggerPopOver';

/**
 * Renders the QueryFiltersTabs component
 * This component displays a set of tabs for filtering and querying data
 *
 * @returns {JSX.Element} The rendered QueryFiltersTabs component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const QueryFiltersTabs = () => {
  const {
    application: { lastTabOpen },
    setApplication
  } = useApplication();

  return (
    <Tabs
      defaultActiveKey={lastTabOpen}
      id='uncontrolled-tab-example'
      className='fw-light bg-light'
      onSelect={tab => setApplication('lastTabOpen', tab)}>
      <Tab
        eventKey='Filters'
        title={
          <OverlayTriggerPopover PopoverText='Limit returned data'>
            <span>
              <FontAwesomeIcon
                icon={['fas', 'filter']}
                transform='grow-2 right-3'
                className='me-1'
              />{' '}
              Filters
            </span>
          </OverlayTriggerPopover>
        }
        className='border-none'
        tabClassName='fw-normal fs--2 fs-sm--1 fs-md--1 fs-lg--1 fs-xl--1 fs-xxl--1 outline-none'>
        <FiltersTab />
      </Tab>
      {/* <Tab
        eventKey='Querybuilder'
        title={
          <OverlayTrigger placement='top'   overlay={<Popover>
                <Popover.Body className='text-center p-1 fs--1 text-700'>Craft advanced queries</Popover.Body>
              </Popover>
}>
            <span>
              <FontAwesomeIcon icon={['fas', 'sliders']} transform='grow-2 right-3' className='me-1' /> Advanced
            </span>
          </OverlayTrigger>
        }
        className='border-none'
        tabClassName='fw-normal fs--2 fs-sm--1 fs-md--1 fs-lg--1 fs-xl--1 fs-xxl--1 outline-none'>
        <AdvancedTab />
      </Tab> */}
      <Tab
        eventKey='Scope'
        title={
          <OverlayTriggerPopover PopoverText='Choose which entities to return data for'>
            <span>
              <FontAwesomeIcon
                icon={['fas', 'users-viewfinder']}
                transform='grow-2 right-3'
                className='me-1'
              />{' '}
              Scope
            </span>
          </OverlayTriggerPopover>
        }
        className='border-none'
        tabClassName='fw-normal fs--2 fs-sm--1 fs-md--1 fs-lg--1 fs-xl--1 fs-xxl--1 outline-none'>
        <ScopeTab />
      </Tab>
    </Tabs>
  );
};

export default QueryFiltersTabs;
