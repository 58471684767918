import Flex from 'components/common/Flex';
import React from 'react';
import useArticles from 'hooks/admin-contexts/useArticles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ViewChangeButton = () => {
  const {
    articles: { viewType },
    setArticles
  } = useArticles();

  return (
    <Flex
      justifyContent='end'
      className=' position-absolute right-0   '
      style={{ right: '3rem', top: '2rem' }}>
      <FontAwesomeIcon
        icon={viewType ? 'grip' : 'list'}
        className='me-1 grid'
        onClick={() => {
          setArticles(prevState => ({
            ...prevState,
            viewType: !viewType
          }));
        }}
      />
    </Flex>
  );
};

export default ViewChangeButton;
