import React from 'react';
import SubscriptionLevels from './components/SubscriptionLevels';
import { Col, Row } from 'react-bootstrap';

/**
 * Renders the SubscriptionLevelsLayout component
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const SubscriptionLevelsLayout = () => {
  return (
    <>
      <Row className='g-3 mb-3'>
        <Col sm={12}>
          <SubscriptionLevels />
        </Col>
      </Row>
    </>
  );
};

export default SubscriptionLevelsLayout;
