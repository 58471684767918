import { apiRoutes } from 'config/routeSettings';

/**
 * @description article API
 * @summary Provides methods for interacting with the article API
 * @method createUser Create a new user
 * @method getArticle Get a list of all article
 * @method getUser Get a single user
 * @method updateUser Update a user
 * @method deleteUser Delete a user
 * @method lockUser Lock a user
 * @method unlockUser Unlock a user
 */
class ArticlesAPI {
  /**
   * @description Create a new user
   * @param {Object} userData User data
   * @returns {Mixed} User data if true, error object if false
   */
  async createAppArticle(AppArticle, axiosPrivate) {
    try {
      const res = await axiosPrivate.post(
        apiRoutes.ARTICLES,
        JSON.stringify(AppArticle)
      );
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Get a list of all article
   * @param {Boolean} showDeleted Show deleted article
   * @returns {Mixed} User data if true, error object if false
   */
  async getAppArticles(axiosPrivate) {
    try {
      const res = await axiosPrivate.get(apiRoutes.ARTICLES);
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Get a specific user
   * @param {String} id User ID
   * @returns {Mixed} User data if true, error object if false
   */
  async getAppArticle(id, axiosPrivate) {
    try {
      const res = await axiosPrivate.get(`${apiRoutes.ARTICLES}/${id}`);
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Update a user by ID
   * @param {String} userData User data
   * @returns {Mixed} User data if true, error object if false
   */
  async updateAppArticle(id, AppArticle, axiosPrivate) {
    try {
      const res = await axiosPrivate.put(
        `${apiRoutes.ARTICLES}/${id}`,
        JSON.stringify(AppArticle)
      );
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Delete or restore a user by ID
   * @param {String} id User ID
   * @param {Boolean} deleting True if deleting, false if restoring
   * @returns {Mixed} User data if true, error object if false
   */
  async deleteAppArticle(id, axiosPrivate) {
    try {
      const res = await axiosPrivate.delete(`${apiRoutes.ARTICLES}/${id}`);
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  ////
  /**
   * @description Create a new user
   * @param {Object} userData User data
   * @returns {Mixed} User data if true, error object if false
   */
  async createAppArticleCategory(AppArticleCategory, axiosPrivate) {
    try {
      const res = await axiosPrivate.post(
        apiRoutes.ARTICLES_CATEGORIES,
        JSON.stringify(AppArticleCategory)
      );
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Get a list of all article
   * @param {Boolean} showDeleted Show deleted article
   * @returns {Mixed} User data if true, error object if false
   */
  async getAppArticleCategories(axiosPrivate) {
    try {
      const res = await axiosPrivate.get(apiRoutes.ARTICLES_CATEGORIES);
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Get a specific user
   * @param {String} id User ID
   * @returns {Mixed} User data if true, error object if false
   */
  async getAppArticleCategory(id, axiosPrivate) {
    try {
      const res = await axiosPrivate.get(
        `${apiRoutes.ARTICLES_CATEGORIES}/${id}`
      );
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Update a user by ID
   * @param {String} userData User data
   * @returns {Mixed} User data if true, error object if false
   */
  async updateAppArticleCategory(id, AppArticleCategory, axiosPrivate) {
    try {
      const res = await axiosPrivate.put(
        `${apiRoutes.ARTICLES_CATEGORIES}/${id}`,
        JSON.stringify(AppArticleCategory)
      );
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Delete or restore a user by ID
   * @param {String} id User ID
   * @param {Boolean} deleting True if deleting, false if restoring
   * @returns {Mixed} User data if true, error object if false
   */
  async deleteAppArticleCategory(id, axiosPrivate) {
    try {
      const res = await axiosPrivate.delete(
        `${apiRoutes.ARTICLES_CATEGORIES}/${id}`
      );
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Create a new user
   * @param {Object} userData User data
   * @returns {Mixed} User data if true, error object if false
   */
  async createAppArticleTag(AppArticleTag, axiosPrivate) {
    try {
      const res = await axiosPrivate.post(
        apiRoutes.ARTICLES_TAGS,
        JSON.stringify(AppArticleTag)
      );
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Get a list of all article
   * @param {Boolean} showDeleted Show deleted article
   * @returns {Mixed} User data if true, error object if false
   */
  async getAppArticleTags(axiosPrivate) {
    try {
      const res = await axiosPrivate.get(apiRoutes.ARTICLES_TAGS);
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Get a specific user
   * @param {String} id User ID
   * @returns {Mixed} User data if true, error object if false
   */
  async getAppArticleTag(id, axiosPrivate) {
    try {
      const res = await axiosPrivate.get(`${apiRoutes.ARTICLES_TAGS}/${id}`);
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Update a user by ID
   * @param {String} userData User data
   * @returns {Mixed} User data if true, error object if false
   */
  async updateAppArticleTag(id, AppArticleTag, axiosPrivate) {
    try {
      const res = await axiosPrivate.put(
        `${apiRoutes.ARTICLES_TAGS}/${id}`,
        JSON.stringify(AppArticleTag)
      );
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Delete or restore a user by ID
   * @param {String} id User ID
   * @param {Boolean} deleting True if deleting, false if restoring
   * @returns {Mixed} User data if true, error object if false
   */
  async deleteAppArticleTag(id, axiosPrivate) {
    try {
      const res = await axiosPrivate.delete(`${apiRoutes.ARTICLES_TAGS}/${id}`);
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default ArticlesAPI;
