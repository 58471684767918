import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';
import routes from 'config/routeSettings';

export const network = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'Network Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, lg: 4, xxl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*zeek*', 'leargas*suricata*'],
        queryType: ['previousCount', 'count', 'sparkline']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          chartWidth: '50%',
          title: 'All Network Artifacts',
          helpInfo:
            'Total number of network event artifacts analyzed by our network threat detection protocols'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, lg: 4, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-zeek'],
        isInitialized: 'isInitialized',
        must: [
          {
            match_phrase: {
              path: '/usr/local/zeek/logs/current/intel.log'
            }
          }
        ],
        must_not: [
          {
            match_phrase: {
              sources: 'CPS-VS'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          title: 'Threat Intel Hits',
          helpInfo:
            'Total number of behavioral event artifacts matching known threat intelligence indicators',
          linkTo: routes.ARTIFACTS_NETWORK_THREATINTEL,
          linkTooltip: 'Go to the network threat intelligence dashboard'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, lg: 4, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-file-extraction']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Files Scanned',
          helpInfo:
            'Total number of network-transferred files extracted and scanned for malicious content',
          linkTo: routes.ARTIFACTS_NETWORK_FILEEXTRACTION,
          linkTooltip: 'Go to the network file extraction dashboard'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, lg: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*zeek*'],
        queryType: ['previousCount', 'count', 'sparkline']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          title: 'Behavioral Events',
          helpInfo:
            'Total number of network event artifacts analyzed using our behavioral-based network threat detection protocols',
          linkTo: routes.ARTIFACTS_NETWORK_BEHAVIORS,
          linkTooltip: 'Go to the behavioral network artifacts dashboard'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, lg: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*zeek*alert*'],
        queryType: ['previousCount', 'count', 'sparkline']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Behavioral Alerts',
          helpInfo:
            'Total number of alerts generated by our behavioral-based network threat detection protocols',
          linkTo: routes.ARTIFACTS_NETWORK_BEHAVIORS,
          linkTooltip: 'Go to the behavioral network artifacts dashboard'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, lg: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*suricata*'],
        queryType: ['previousCount', 'count', 'sparkline']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Signature Events',
          helpInfo:
            'Total number of network event artifacts analyzed using our signature-based network threat detection protocols',
          linkTo: routes.ARTIFACTS_NETWORK_SIGNATURES,
          linkTooltip: 'Go to the signature network artifacts dashboard'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, lg: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*suricata*alert*'],
        queryType: ['previousCount', 'count', 'sparkline']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Signature Alerts',
          helpInfo:
            'Total number of alerts generated by our signature-based network threat detection protocols',
          linkTo: routes.ARTIFACTS_NETWORK_SIGNATURES,
          linkTooltip: 'Go to the signature network artifacts dashboard'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['histogram'],
        overriddenIndex: ['leargas-zeek']
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Event Timeline',
          helpInfo:
            'Timeline histogram of all behavioral network events matching the search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['map'],
        overriddenIndex: ['leargas*zeek*', 'leargas*suricata*'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'source_geoip.country_name.keyword',
                order: {
                  _count: 'desc'
                },
                exclude: [''],
                size: 100
              }
            }
          }
        }
      },
      children: {
        component: 'GeoWorldToggleMapBar',
        props: {
          className: '',
          title: 'Events By Country',
          field: 'source_geoip.country_name',
          helpInfo:
            'Source countries seen in network event artifacts analyzed by our network threat detection protocols'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, xxl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*zeek*alert*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'note.keyword',
                order: {
                  _key: 'desc'
                },
                size: 5
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'ListAlerts',
        props: {
          className: '',
          title: 'Top 5 Behaviors',
          helpInfo:
            'Top 5 behavioral detection alerts by total count of events',
          linkDescription: 'View all behavioral alerts',
          fieldName: 'note',
          linkTo: routes.ARTIFACTS_NETWORK_BEHAVIORS
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, xxl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*suricata*alert*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'alert.signature.keyword',
                order: {
                  _key: 'desc'
                },
                size: 5
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'ListAlerts',
        props: {
          className: '',
          title: 'Top 5 Signatures',
          linkDescription: 'View all signature alerts',
          helpInfo: 'Top 5 signature detection alerts by total count of events',
          fieldName: 'alert.signature',
          linkTo: routes.ARTIFACTS_NETWORK_SIGNATURES
        }
      }
    },

    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, lg: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas*zeek*', 'leargas*suricata*'],
        isInitialized: 'isInitialized',
        must_not: [
          {
            match: {
              'source_geoip.country_code2.keyword': 'US'
            }
          }
        ],
        filter: [
          {
            bool: {
              should: [
                {
                  exists: {
                    field: 'source_geoip.country_code2.keyword'
                  }
                },
                {
                  exists: {
                    field: 'destination_geoip.country_code2.keyword'
                  }
                }
              ],
              minimum_should_match: 1
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Non-US Events',
          helpInfo:
            'Total count of network event artifacts related to potentially non-US hosts'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, lg: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*zeek*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              tags: 'ics'
            }
          }
        ],
        must_not: [
          {
            match_phrase: {
              logName: 'ecat_arp_info'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'ICS Events',
          helpInfo:
            'Total number of ICS network event artifacts analyzed using our behavioral-based network threat detection protocols',
          linkTo: routes.ARTIFACTS_NETWORK_ICS,
          linkTooltip: 'Go to the ICS network artifacts dashboard'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, lg: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*suricata*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        filter: [
          {
            match: {
              'event_type.keyword': 'smb'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'SMB Events',
          helpInfo:
            'Total number of SMB network event artifacts analyzed using our signature-based network threat detection protocols',
          linkTo: routes.ARTIFACTS_NETWORK_SMB,
          linkTooltip: 'Go to the SMB network artifacts dashboard'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, lg: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*zeek*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        filter: [
          {
            match: {
              'path.keyword': '/usr/local/zeek/logs/current/dce_rpc.log'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'RPC Events',
          helpInfo:
            'Total number of RPC network event artifacts analyzed using our signature-based network threat detection protocols',
          linkTo: routes.ARTIFACTS_NETWORK_RPC,
          linkTooltip: 'Go to the RPC network artifacts dashboard'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mt-2 mb-1',
        title: 'Network Flow Analysis By Host'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['sankey'],
        overriddenIndex: ['leargas*zeek*'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'id.orig_h.keyword',
                order: {
                  _count: 'desc'
                },
                size: 5
              },
              aggs: {
                3: {
                  terms: {
                    field: 'id.resp_h.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 10
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_all: {}
          },
          {
            match_phrase: {
              local_orig: false
            }
          },
          {
            match_phrase: {
              local_resp: true
            }
          }
        ]
      },
      children: {
        component: 'SankeyBasic',
        props: {
          filterFields: {
            source: 'id.orig_h',
            target: 'id.resp_h'
          },
          className: '',
          direction: 'ltr',
          title: 'Ingress Artifacts By Count',
          helpInfo:
            'Hosts with the most ingress network events and their destinations'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['sankey'],
        overriddenIndex: ['leargas*zeek*'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'id.orig_h.keyword',
                order: {
                  _count: 'desc'
                },
                size: 10
              },
              aggs: {
                3: {
                  terms: {
                    field: 'id.resp_h.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_all: {}
          },
          {
            match_phrase: {
              local_orig: true
            }
          },
          {
            match_phrase: {
              local_resp: true
            }
          }
        ]
      },
      children: {
        component: 'SankeyBasic',
        props: {
          filterFields: {
            source: 'id.orig_h',
            target: 'id.resp_h'
          },
          className: '',
          direction: 'ltr',
          title: 'Internal Artifacts By Count',
          helpInfo:
            'Hosts with the most internal network events and their destinations'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['sankey'],
        overriddenIndex: ['leargas*zeek*'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'id.orig_h.keyword',
                order: {
                  _count: 'desc'
                },
                size: 10
              },
              aggs: {
                3: {
                  terms: {
                    field: 'id.resp_h.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_all: {}
          },
          {
            match_phrase: {
              local_orig: true
            }
          },
          {
            match_phrase: {
              local_resp: false
            }
          }
        ]
      },
      children: {
        component: 'SankeyBasic',
        props: {
          filterFields: {
            source: 'id.orig_h',
            target: 'id.resp_h'
          },
          className: '',
          direction: 'ltr',
          title: 'Egress Artifacts By Count',
          helpInfo:
            'Hosts with the most egress network events and their destinations'
        }
      }
    }
  ];
};

export default network;
