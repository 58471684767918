import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';
const behaviors = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'Network Behaviors Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-zeek*']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          title: 'Flows',
          helpInfo:
            'Total number of network events processed by our behavioral detection engine'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-zeek*'],
        must: [
          {
            exists: {
              field: 'note'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          title: 'Alert Behaviors',
          helpInfo:
            'Alerts for events that match behavioral patterns known to be associated with malicious activity'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-zeek*'],
        must: [{ match_all: {} }],
        filter: [
          {
            bool: {
              filter: [
                {
                  bool: {
                    should: [{ match: { 'id.resp_p': 3389 } }]
                  }
                },
                {
                  bool: {
                    filter: [
                      {
                        bool: {
                          should: [{ match: { local_orig: false } }]
                        }
                      },
                      {
                        bool: {
                          filter: [
                            {
                              bool: {
                                should: [{ match: { local_resp: true } }]
                              }
                            },
                            {
                              bool: {
                                must_not: {
                                  bool: {
                                    should: [
                                      {
                                        query_string: {
                                          fields: ['id.orig_h'],
                                          query: '\\1\\6\\9\\.\\2\\5\\4*'
                                        }
                                      }
                                    ]
                                  }
                                }
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          title: 'Inbound RDP',
          helpInfo:
            'Connections to a local device or server from a remote device using the Remote Desktop Protocol (RDP)'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-zeek*'],
        must: [{ match_all: {} }],
        filter: [
          {
            bool: {
              filter: [
                {
                  bool: {
                    should: [{ match: { 'id.resp_p': 3389 } }]
                  }
                },
                {
                  bool: {
                    filter: [
                      {
                        bool: {
                          should: [{ match: { local_orig: true } }]
                        }
                      },
                      {
                        bool: {
                          should: [{ match: { local_resp: false } }]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          title: 'Outbound RDP',
          helpInfo:
            'Connections to a remote device or server from a local device using the Remote Desktop Protocol (RDP)'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-zeek*'],
        must: [{ match_all: {} }],
        filter: [
          {
            bool: {
              should: [
                {
                  bool: {
                    filter: [
                      {
                        bool: {
                          must_not: {
                            bool: {
                              should: [
                                {
                                  query_string: {
                                    fields: ['id.orig_h'],
                                    query: '\\1\\6\\9\\.\\2\\5\\4*'
                                  }
                                }
                              ]
                            }
                          }
                        }
                      },
                      {
                        bool: {
                          filter: [
                            {
                              bool: {
                                should: [
                                  { match_phrase: { local_orig: 'false' } }
                                ]
                              }
                            },
                            {
                              bool: {
                                should: [
                                  { match_phrase: { local_resp: 'true' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  bool: {
                    filter: [
                      {
                        bool: {
                          should: [{ match_phrase: { local_orig: 'true' } }]
                        }
                      },
                      {
                        bool: {
                          should: [{ match_phrase: { local_resp: 'false' } }]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            match_phrase: { 'id.resp_p': '445' }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          title: 'Non Local SMB',
          helpInfo:
            'Access requests to files and resources over a network from a remote location'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-zeek*'],
        must: [{ match_all: {} }],
        filter: [
          {
            match_phrase: {
              'id.resp_p': '22'
            }
          },
          {
            match_phrase: {
              local_orig: false
            }
          },
          {
            match_phrase: {
              local_resp: true
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          title: 'Inbound SSH',
          helpInfo:
            'Remote shell access requests to a device or server initiated from the outside the network'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['histogram'],
        overriddenIndex: ['leargas-zeek*']
      },
      children: {
        component: 'Stat',
        props: {
          header: 'Event Timeline',
          helpInfo:
            'Timeline histogram of all behavioral network events matching the search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['map'],
        overriddenIndex: ['leargas-zeek*'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'id.orig_h_geoip.country_name.keyword',
                order: { _count: 'desc' },
                exclude: [''],
                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Map',
        props: {
          title: 'Event Source Countries',
          field: 'id.orig_h_geoip.country_name',
          helpInfo:
            'Heatmap of event source countries for all behavioral network events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, xxl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-zeek*'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'note.keyword',
                order: { _count: 'desc' },
                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          header: 'Alert Behaviors',
          field: 'note',
          helpInfo:
            'Alerts for events that match behavioral patterns known to be associated with malicious activity'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, xxl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-zeek*'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'name.keyword',
                order: { _count: 'desc' },
                size: 50
              }
            }
          },
          size: 0
        },
        must: [
          {
            query_string: {
              query: 'agent',
              analyze_wildcard: true,
              time_zone: 'America/New_York'
            }
          }
        ],
        filter: [
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    path: '/usr/local/zeek/logs/current/software.log'
                  }
                }
              ]
            }
          }
        ]
      },
      children: {
        component: 'PieChart',
        props: {
          header: 'User Agents',
          field: 'name',
          helpInfo: 'User agents observed in network traffic'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['table'],
        overriddenIndex: ['leargas-zeek*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'note.keyword',
                order: { _count: 'desc' },
                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'id.orig_h.keyword',
                    order: { _count: 'desc' },
                    size: 50
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'id.resp_h.keyword',
                        order: { _count: 'desc' },
                        size: 50
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Alert Behaviors by Source & Destination'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, xxl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['table'],
        overriddenIndex: ['leargas-zeek*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'id.orig_h_geoip.country_name.keyword',
                order: { _count: 'desc' },
                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'id.orig_h.keyword',
                    order: { _count: 'desc' },
                    size: 50
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'id.resp_h.keyword',
                        order: { _count: 'desc' },
                        size: 50
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [{ match_all: {} }],
        filter: [
          {
            bool: {
              filter: [
                {
                  bool: {
                    should: [{ match: { 'id.resp_p': 3389 } }]
                  }
                },
                {
                  bool: {
                    filter: [
                      {
                        bool: {
                          should: [{ match: { local_orig: false } }]
                        }
                      },
                      {
                        bool: {
                          filter: [
                            {
                              bool: {
                                should: [{ match: { local_resp: true } }]
                              }
                            },
                            {
                              bool: {
                                must_not: {
                                  bool: {
                                    should: [
                                      {
                                        query_string: {
                                          fields: ['id.orig_h'],
                                          query: '\\1\\6\\9\\.\\2\\5\\4*'
                                        }
                                      }
                                    ]
                                  }
                                }
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Inbound RDP'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, xxl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['table'],
        overriddenIndex: ['leargas-zeek*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'id.orig_h_geoip.country_name.keyword',
                order: { _count: 'desc' },
                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'id.orig_h.keyword',
                    order: { _count: 'desc' },
                    size: 50
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'id.resp_h.keyword',
                        order: { _count: 'desc' },
                        size: 50
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [{ match_all: {} }],
        filter: [
          {
            match_phrase: { 'id.resp_p': '22' }
          },
          {
            match_phrase: { local_orig: false }
          },
          {
            match_phrase: { local_resp: true }
          },
          {
            match_phrase: { path: '/usr/local/zeek/logs/current/conn.log' }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Inbound SSH'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, lg: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['table'],
        overriddenIndex: ['leargas-zeek*'],
        must: {
          exists: {
            field: 'note'
          }
        },
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'id.orig_h.keyword',
                order: { _count: 'desc' },
                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Alert Sources',
            overlayText:
              'Sources for all alerts produced by our network behavioral detection engine',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, lg: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['table'],
        overriddenIndex: ['leargas-zeek*'],
        must: {
          exists: {
            field: 'note'
          }
        },
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'id.resp_h.keyword',
                order: { _count: 'desc' },
                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Alert Destinations',
            overlayText:
              'Destinations for all alerts produced by our network behavioral detection engine',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, lg: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['table'],
        overriddenIndex: ['leargas-zeek*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'operation.keyword',
                order: { _count: 'desc' },
                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'RPC Operations',
            overlayText:
              'RPC operations and event counts matching the current scope and query',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, lg: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['table'],
        overriddenIndex: ['leargas-zeek*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'certificate.subject.keyword',
                order: { _count: 'desc' },
                size: 50
              }
            }
          },
          size: 0
        },
        must: [{ match_all: {} }],
        filter: [
          {
            multi_match: {
              type: 'best_fields',
              query: 'Encrypt',
              lenient: true
            }
          },
          {
            match_phrase: { 'certificate.issuer': "CN=R3,O=Let's Encrypt,C=US" }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: `Visited Let's Encrypt Domains`
          }
        }
      }
    }
  ];
};
export default behaviors;
