import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Renders the ShowDeletedButton component
 *
 * @param {object} props
 * @param {boolean} props.showDeleted
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ShowDeletedButton = ({ showDeleted, ...rest }) => {
  return (
    <Button
      {...rest}
      size='sm'
      variant='link'
      className={`position-relative cursor-pointer icon-item icon-item-sm rounded-circle border border-${
        showDeleted ? 'primary' : 'secondary'
      } shadow-none me-2`}>
      <FontAwesomeIcon
        icon='trash-can'
        className={`text-${showDeleted ? 'primary' : 'secondary'}`}
      />
    </Button>
  );
};

ShowDeletedButton.propTypes = {
  showDeleted: PropTypes.bool
};

export default ShowDeletedButton;
