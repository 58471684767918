import { filtersOperators } from 'context/FiltersProvider/settings';

/**
 * Builds a search query based on the provided state and scopes.
 *
 * @param {Object} state - The state object
 * @param {Array} scopes - The array of scopes to filter and map
 * @returns {Object} - The built search query object
 *
 */
export const buildSearchQuery = (state, scopes) => {
  const mustQueries = [];
  const mustNotQueries = [];
  const filterQueries = [];
  const shouldQueriesForMust = [];
  const outerShouldQueries = [];

  const disabledFields = state.fields
    .filter(field => !field.enabled)
    .map(field => field.name);

  const excludedFieldName = state.excludeFieldFilters;

  state.filters.forEach(filter => {
    if (!filter.enabled) return;

    const { field, value, operator, inclusion } = filter;
    const fieldName = field?.accessor;
    const fieldType = field?.type;

    // Skip filters related to the excluded field name
    if (fieldName === excludedFieldName) return;

    const operatorDef = filtersOperators[operator];

    // if the value is an array of numbers, sort it
    if (Array.isArray(value) && value.every(val => typeof val === 'number')) {
      value.sort((a, b) => a - b);
    }

    // Generate query only if operator is valid and compatible
    if (operatorDef && operatorDef.isCompatibleWithType(fieldType, value)) {
      const esQuery = operatorDef.generateQuery({
        fieldName,
        fieldValue: value,
        fieldType,
        availableFields: state.fieldsPresent || ['_all']
      });

      if (inclusion) {
        if (operator === 'multiMatch') {
          filterQueries.push(esQuery);
        } else if (
          esQuery?.bool &&
          (operatorDef.isMulti ||
            operator === 'isLike' ||
            operator === 'isNotLike' ||
            operator === 'isOneOf')
        ) {
          shouldQueriesForMust.push(esQuery);
        } else {
          mustQueries.push(esQuery);
        }
      } else if (!inclusion && operator !== 'multiMatch') {
        mustNotQueries.push(esQuery);
      }
    }
  });

  const selectedScopes = scopes
    ?.map(
      scope =>
        scope?.legacy?.clientId && {
          term: { 'clientId.keyword': scope.legacy.clientId }
        }
    )
    .filter(Boolean);

  outerShouldQueries.push(...selectedScopes);

  const { from, to } = state.timeRange;
  const interval =
    state.timeRangeSelected.unit === 'custom'
      ? state.timeRangeSelected.getInterval(from, to)
      : state.timeRangeSelected.getInterval();

  const resolvedMustQueries = [
    ...mustQueries,
    ...(shouldQueriesForMust.length > 0 ? shouldQueriesForMust : []),
    ...state.filtersDefault
  ];

  return {
    index: state.indexSelected.pattern,
    stored_fields: ['*'],
    from: 0,
    size: state.pagination.pageSize,
    sort: [
      {
        [state.sorting.sortField]: {
          order: state.sorting.sortOrder || 'desc'
        }
      }
    ],
    scroll: '5m',
    aggs: {
      histogram: {
        date_histogram: {
          field: '@timestamp',
          fixed_interval: interval,
          min_doc_count: 0,
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          extended_bounds: { min: from, max: to }
        }
      }
    },
    query: {
      bool: {
        filter: [
          {
            range: {
              '@timestamp': {
                gte: from,
                lte: to,
                time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                format: 'strict_date_optional_time'
              }
            }
          },
          ...filterQueries
        ],
        must:
          resolvedMustQueries && resolvedMustQueries.length > 0
            ? resolvedMustQueries
            : [{ match_all: {} }],
        must_not: mustNotQueries,
        should: outerShouldQueries,
        minimum_should_match: outerShouldQueries.length > 0 ? 1 : 0
      }
    },
    _source_excludes: disabledFields
  };
};
