// import SavedSearchesProvider from 'context/admin-contexts/SavedSearchProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import useApplication from 'hooks/useApplication';
import React, { useRef } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import SavedSearchesList from './SavedSearchList';

/**
 * Renders the SavedSearchesWrapper component
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const SavedSearchesModal = () => {
  const modalRef = useRef(null);

  const {
    application: { isDark, showSavedSearchListModal },
    setApplication
  } = useApplication();

  return (
    <Modal
      aria-labelledby='savedSearchListModal'
      contentClassName='shadow-none'
      keyboard={true}
      onHide={() => setApplication('showSavedSearchListModal', false)}
      ref={modalRef}
      show={showSavedSearchListModal}
      dialogClassName='my-6'
      size='lg'>
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex align-items-center fs-0 fw-normal position-relative'>
            Open a Saved Search{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Your saved searches are displayed here for easy access.'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
        </Modal.Header>

        <Modal.Body className='p-2'>
          <SavedSearchesList />
        </Modal.Body>
        <Modal.Footer className='p-2'>
          <Row className='g-1 m-0 p-0 w-100'>
            <Col>
              <Flex direction='row' justifyContent='end'>
                <Button
                  size='sm'
                  variant='secondary'
                  className='me-2'
                  onClick={() =>
                    setApplication('showSavedSearchListModal', false)
                  }>
                  Cancel
                </Button>
              </Flex>
            </Col>
          </Row>
        </Modal.Footer>
      </Flex>
    </Modal>
  );
};

export default SavedSearchesModal;
