import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
/**
 *
 * @returns {JSX.Element} The rendered button component
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const ResetModal = ({ showResetModal, setShowResetModal, reset }) => {
  const {
    application: { isDark }
  } = useApplication();

  //   const {
  //     esGlobalFilters: { globalFiltersReset },
  //     setESGlobalFilters
  //   } = useESGlobalFilters();

  return (
    <Modal
      aria-labelledby='deleteConfirmModal'
      size='lg'
      backdrop='static'
      //   keyboard={false}
      show={showResetModal}
      onHide={() => setShowResetModal(false)}
      centered>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Reset Confirm
          <AdvancedPopover
            containerId='resetConfirmModal'
            placement='top'
            popoverText='Reset Confirm'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
      </Modal.Header>
      {/* setESGlobalFilters({...globalFiltersReset}) */}
      <Modal.Body className='p-2'>
        <Form
          onSubmit={e => {
            e.preventDefault();
            reset();
            setShowResetModal(false);
          }}>
          <Row className='g-3 mb-3'>
            <Col sm={12}>
              <p className='fs--1'>
                Are you sure you want to reset? This will remove all selected
                filters!
              </p>
            </Col>
          </Row>

          <Flex justifyContent='end'>
            <Button type='submit' variant='success' className='me-2'>
              Yes
            </Button>

            <Button
              variant='secondary'
              onClick={() => {
                setShowResetModal(false);
              }}>
              No
            </Button>
          </Flex>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

ResetModal.propTypes = {
  showResetModal: PropTypes.bool,
  setShowResetModal: PropTypes.func,
  reset: PropTypes.func
};

export default ResetModal;
