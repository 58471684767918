import React from 'react';
import Scope from './Scope';
import { Card } from 'react-bootstrap';

/**
 * Renders the ScopeTab component
 * @returns {JSX.Element} The rendered ScopeTab component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ScopeTab = () => {
  return (
    <Card className='shadow-none bg-transparent mb-0'>
      <Card.Body className='pt-3 pb-3 mb-0'>
        <Scope />
      </Card.Body>
    </Card>
  );
};

export default ScopeTab;
