import React from 'react';
import DataLayout from './DataLayout';

/**
 * Renders the DataSettingsPage component
 *
 * @returns {JSX.Element} The rendered DataSettingsPage component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const DataSettingsPage = () => {
  return (
    <>
      <DataLayout />
    </>
  );
};

export default DataSettingsPage;
