import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

const AdvancedTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,

  rowClassName,
  visibleColumns,
  tableProps
}) => {
  return (
    <div
      className='position-relative'
      style={{ minHeight: '200px', overflowY: 'visible' }}>
      <SimpleBar
        className='h-100 pe-3'
        forceVisible='y'
        autoHide={true}
        style={{
          minHeight: '300px',
          width: '100%'
        }}>
        <Table size='sm' {...getTableProps(tableProps)}>
          <thead>
            <tr>
              {headers
                .filter(function (item) {
                  return (
                    visibleColumns?.includes(item.id) || item.id === 'selection'
                  );
                })
                .map((column, index) => (
                  <th
                    key={index}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps(column.headerProps)
                    )}
                    style={{
                      textAlign: index === headers.length - 1 ? 'right' : 'left'
                    }}>
                    {column.render('Header').length > 32
                      ? column.render('Header')
                      : column.render('Header')}
                    {/* .slice(0, 32) + '...' */}
                    {column.canSort ? (
                      column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className='sort desc' />
                        ) : (
                          <span className='sort asc' />
                        )
                      ) : (
                        <span className='sort' />
                      )
                    ) : (
                      ''
                    )}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i}
                  className={[
                    row.original.deleted
                      ? 'bg-soft-danger'
                      : row.original.locked
                      ? 'bg-soft-warning'
                      : '',
                    rowClassName
                  ].join('')}
                  {...row.getRowProps()}>
                  {row.cells
                    .filter(function (item) {
                      return visibleColumns?.includes(item.column.id);
                    })
                    .map((cell, index) => {
                      return (
                        <td
                          key={index}
                          {...cell.getCellProps(cell.column.cellProps)}
                          style={{
                            textAlign:
                              index ===
                              row.cells.filter(function (item) {
                                return visibleColumns?.includes(item.column.id);
                              }).length -
                                1
                                ? 'right'
                                : 'left'
                          }}>
                          {cell.column?.id === 'selection' ||
                          cell.column?.id === 'leargas_actions' ||
                          cell.column?.hasRender ? (
                            <span className='fs--2 text-nowrap'>
                              {cell.render('Cell')}
                            </span>
                          ) : cell?.value ? (
                            <span className='fs--2 text-nowrap'>
                              {typeof cell.value === 'object' &&
                              JSON.stringify(cell.value, undefined, 2).length >
                                32 &&
                              !cell.column.noTruncate
                                ? JSON.stringify(cell.value, undefined, 2)
                                : typeof cell.value === 'number' ||
                                  (typeof cell.value === 'string' &&
                                    !isNaN(Number(cell.value)))
                                ? Number(cell.value).toLocaleString()
                                : JSON.stringify(cell.value, undefined, 2)
                                    .length > 32 && !cell.column.noTruncate
                                ? String(cell.value)
                                : String(cell.value)}
                            </span>
                          ) : (
                            <span className='fs--1 text-300'>{'N/A'}</span>
                          )}
                          {/* .slice(0, 32) + '...' */}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </SimpleBar>
    </div>
  );
};
AdvancedTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  visibleColumns: PropTypes.array
};

export default AdvancedTable;
