import React from 'react';
import FieldsLayout from './FieldsLayout';

/**
 * Renders the Fields component
 * @returns {JSX.Element} The rendered Fields component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const FieldsPage = () => {
  return (
    <>
      <FieldsLayout />
    </>
  );
};

export default FieldsPage;
