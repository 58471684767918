import { makeRequest } from 'api/connection/requestAction';

class Organizations {
  constructor(route) {
    this.route = route;
  }

  async createAccOrg(userData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.createAccOrg.method,
        this.route.createAccOrg.path,
        userData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async removePendingUser(userData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.removePendingUser.method,
        this.route.removePendingUser.path,
        userData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async removeUserFromOrg(userData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.removeUserFromOrganization.method,
        this.route.removeUserFromOrganization.path,
        userData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async getPendingInvitations(data, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getPendingInvitations.method,
        this.route.getPendingInvitations.path,
        data
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async inviteUserToOrg(userData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.inviteUserToOrganization.method,
        this.route.inviteUserToOrganization.path,
        userData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async deleteAccOrgs(ids, deleting, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.deleteAccOrg.method,
        this.route.deleteAccOrg.path,
        {
          id: ids,
          deleting
        }
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async updateAccOrg(orgData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateAccOrg.method,
        this.route.updateAccOrg.path,
        orgData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async getAccOrganization(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getAccOrganization.method,
        `${this.route.getAccOrganization.path}/${id}`
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async getAccOrgs(axiosPrivate, showDeleted = false) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getAccOrgs.method,
        this.route.getAccOrgs.path
      );
      if (res instanceof Error) throw res;
      let orgs = [];
      res?.data?.data?.map(org => {
        if ((showDeleted && org.deleted) || !org.deleted) {
          orgs.push(org);
        }
      });
      return orgs;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default Organizations;
