import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useReleases from 'hooks/admin-contexts/useReleases';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, FormText } from 'react-bootstrap';
import { toast } from 'react-toastify';
/**
 * Renders the FormReleaseNoteAdd component
 *
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const FormReleaseNoteAdd = () => {
  const { addReleaseNoteModal, setReleases } = useReleases();
  const { axiosPrivate } = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    release: '',
    title: '',
    description: '',
    type: '',
    visible: false
  });

  const focusRef = useRef(null);

  /**
   * Handle updating form data
   * @param {Event} e The event object
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.2
   * @version 0.1.0-beta.2
   */

  const handleFormData = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /**
   * Handle submitting the form
   * @param {Event} e The event object
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.2
   * @version 0.1.0-beta.2
   */
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await LeargasAPI.Releases.createAppReleaseNote(
        formData,
        axiosPrivate
      );
      if (res instanceof Error) throw res;
      setReleases(prevState => ({
        ...prevState,
        addReleaseNoteModal: { open: false },
        fetch: true
      }));
      toast.success(res?.message);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setReleases(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setLoading(false);
    }
  };

  /**
   * Set focus to the tag input on mount and set the release id in the form data when the modal opens
   */
  useEffect(() => {
    if (addReleaseNoteModal?.open) {
      focusRef.current.focus();
    }
    if (addReleaseNoteModal?.data) {
      setFormData(prevState => ({
        ...prevState,
        release: addReleaseNoteModal.data?.id
      }));
    }
  }, [addReleaseNoteModal]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId='formReleaseAdd' className='mb-3 required'>
        <Form.Label className='d-flex  fs--1 fw-normal'>
          Title{' '}
          <AdvancedPopover
            containerId='newFilterModal'
            placement='top'
            popoverText=' Title should be a short summary of the change. E.g Fixed user profile
          page'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 text-400 fs--1 cursor-pointer'
            />
          </AdvancedPopover>
        </Form.Label>
        <Form.Control
          ref={focusRef}
          name='title'
          size='sm'
          type='text'
          onChange={handleFormData}
          autoComplete='off'
          required
        />{' '}
        <FormText className='text-muted'></FormText>
      </Form.Group>

      <Form.Group controlId='formReleaseAdd' className='mb-3 required'>
        <Form.Label className='d-flex  fs--1 fw-normal'>
          Description{' '}
          <AdvancedPopover
            containerId='newFilterModal'
            placement='top'
            popoverText='Add a detailed description of what changed so users know what to
          expect.'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 text-400 fs--1 cursor-pointer'
            />
          </AdvancedPopover>
        </Form.Label>
        <Form.Control
          name='description'
          size='sm'
          as='textarea'
          rows={3}
          onChange={handleFormData}
          required
        />{' '}
        <FormText className='text-muted'></FormText>
      </Form.Group>

      <Form.Group controlId='formReleaseAdd' className='mb-3 required'>
        <Form.Label className='d-flex  fs--1 fw-normal'>
          Change Type{' '}
          <AdvancedPopover
            containerId='newFilterModal'
            placement='top'
            popoverText='change type'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 text-400 fs--1 cursor-pointer'
            />
          </AdvancedPopover>
        </Form.Label>
        <Form.Select name='type' size='sm' onChange={handleFormData} required>
          <option value=''>-</option>
          <option value='added'>Added</option>
          <option value='changed'>Changed</option>
          <option value='deprecated'>Deprecated</option>
          <option value='removed'>Removed</option>
          <option value='fixed'>Fixed</option>
          <option value='security'>Security</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className='mb-3' controlId='formGroupName'>
        <Flex alignItems='center'>
          <Form.Check
            name='visible'
            inline
            type='switch'
            className='me-2'
            checked={formData?.visible}
            onChange={() =>
              setFormData(prevState => ({
                ...prevState,
                visible: !formData.visible
              }))
            }
          />

          <Form.Label className='d-flex mb-0 fs--1 fw-normal'>
            Visible{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Toggle on to make this release note visible to all users.'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
        </Flex>
      </Form.Group>

      <Flex justifyContent='end' className='mt-3'>
        <Button
          variant='outline-secondary'
          className='me-2'
          size='sm'
          onClick={() =>
            setReleases(prevState => ({
              ...prevState,
              addReleaseNoteModal: { open: false, data: null }
            }))
          }>
          Cancel
        </Button>

        <Button
          size='sm'
          variant={loading ? 'outline-secondary' : 'success'}
          disabled={
            loading ||
            !formData.title ||
            !formData.description ||
            !formData.type
          }
          type='submit'>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Saving' : 'Save'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

export default FormReleaseNoteAdd;
