import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import useApplication from 'hooks/useApplication';
import useAuthentication from 'hooks/useAuthentication';
import React, { useEffect, useRef, useState } from 'react';
import { Button, DropdownMenu, Overlay, Popover } from 'react-bootstrap';
import AdvancedPopover from '../Popover';
import ScopesToggleList from './ScopesToggleList';

/**
 * ScopesSelectorButton component.
 *
 * This component displays a button that allows the user to select their current scope.
 *
 * @returns {JSX.Element|null} The rendered ScopesSelectorButton component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const ScopesSelectorButton = () => {
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);

  const {
    application: { isPrivacyMode }
  } = useApplication();

  const {
    authentication: { scopes, scopesSelected }
  } = useAuthentication();

  const [show, setShow] = useState(false);

  /**
   * Toggles the show state.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleToggle = () => {
    setShow(!show);
  };

  /**
   * Handles the click outside event.
   *
   * @param {Event} e - The click event
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleClickOutside = e => {
    // If the click is outside of the trigger and the dropdown
    if (
      triggerRef.current &&
      !triggerRef.current.contains(e.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      // Close the dropdown
      setShow(false);
    }
  };

  /**
   * Adds an event listener to the document to handle clicks outside of the dropdown.
   * Removes the event listener when the component is unmounted.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    // Add an event listener to the document to handle clicks outside of the dropdown
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Remove the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /**
   * Generates a popover element for the scope selector.
   *
   * @returns {JSX.Element} The popover element
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const popover = (
    <Popover id='scopeSelectorPopover' style={{ minWidth: 'fit-content' }}>
      <div
        ref={dropdownRef}
        className='w-100 p-0 d-flex flex-column align-items-start'>
        <DropdownMenu show={true} className='position-relative border-0'>
          <ScopesToggleList setShow={setShow} />
        </DropdownMenu>
      </div>
    </Popover>
  );

  /**
   * Returns null if there are no scopes or if there is only one scope.
   * The user should not be able to change their scope if there is only one available.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  if (!scopes || scopes.length < 2 || !scopesSelected) {
    return null;
  }

  return (
    <div style={{ zIndex: 2 }}>
      <Flex justifyContent='center' className='scopes-select-button'>
        <div className='d-inline-flex align-items-center position-relative'>
          <Overlay
            show={show}
            target={triggerRef.current}
            placement='bottom'
            containerPadding={20}
            rootClose={true}
            onHide={() => setShow(false)}>
            {popover}
          </Overlay>

          <AdvancedPopover
            placement='bottom'
            showArrow={true}
            popoverText='Select your scope'>
            <Button
              ref={triggerRef}
              id='scopeSelectorDropdown'
              onClick={handleToggle}
              size='sm'
              tabIndex='3'
              className='border-0 border-none new-custom-dropdown-at-panel px-0 bg-transparent text-700 hover-900'
              variant='outline-transparent'>
              <Flex
                justifyContent='start'
                alignItems='center'
                className='text-nowrap'
                style={{ width: '125px', marginTop: '2px', zIndex: 999999 }}>
                <Flex
                  direction='column'
                  justifyContent='start'
                  alignItems='center'
                  className='text-truncate'>
                  <Flex direction='column' className='w-100 position-relative'>
                    <p className='fs--2 fw-semi-bold mb-0 d-block text-truncate'>
                      <FontAwesomeIcon
                        icon={['fas', 'chevron-right']}
                        className='text-700 fs--2 me-2'
                      />
                      {isPrivacyMode && scopesSelected
                        ? scopesSelected?.sort((a, b) =>
                            isPrivacyMode
                              ? a?.legacy?.clientNumber -
                                b?.legacy?.clientNumber
                              : a?.name.localeCompare(b?.name)
                          )[0]?.legacy?.clientTag
                        : scopesSelected &&
                          scopesSelected?.sort((a, b) =>
                            isPrivacyMode
                              ? a?.legacy?.clientNumber -
                                b?.legacy?.clientNumber
                              : a?.name.localeCompare(b?.name)
                          )[0]?.name}
                      {scopesSelected.length === 0 && (
                        <span>
                          <FontAwesomeIcon
                            className='me-1 text-warning'
                            icon={['fas', 'warning']}
                          />
                          No Scopes Selected
                        </span>
                      )}
                    </p>
                  </Flex>
                </Flex>
                <Flex>
                  <p className='fs--2 fw-semi-bold mb-0 ps-2'>
                    {scopesSelected.filter(item => item.active).length > 1
                      ? `+${
                          scopesSelected.filter(item => item.active).length - 1
                        } more`
                      : ''}
                  </p>
                </Flex>
              </Flex>
            </Button>
          </AdvancedPopover>
        </div>
      </Flex>
    </div>
  );
};

export default ScopesSelectorButton;
