import 'helpers/initFA';
import React from 'react';
import { createRoot } from 'react-dom/client';
import Main from './main/Main';
import MainProvider from './main/MainProvider';

/**
 * Represents the container element.
 *
 * @type {HTMLElement}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
const container = document.getElementById('main');

/**
 * Creates a root element in the specified container.
 *
 * @param {HTMLElement} container - The container element to create the root in
 * @returns {HTMLElement} The created root element
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <MainProvider>
      <Main />
    </MainProvider>
  </React.StrictMode>
);
