import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

const fileExtraction = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'File Extraction Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-file-extraction']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Scans',
          helpInfo: 'Total number of scans performed'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['cardinality'],
        overriddenIndex: ['leargas-file-extraction'],
        aggregate: {
          aggs: {
            1: {
              cardinality: {
                field: 'request.attributes.filename.keyword'
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'MetricPlain',
        props: {
          className: '',
          title: 'Unique Files',
          helpInfo: 'Total number of unique files scanned'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-file-extraction'],
        queryType: ['histogram']
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Scan Timeline',
          helpInfo:
            'Timeline histogram of all scans matching the search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-file-extraction'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'file.scanners.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Scanners',
          field: 'file.scanners',
          helpInfo: 'Scan types used for detecting malicious files'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-file-extraction'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'file.flavors.mime.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'MIME Type',
          field: 'file.flavors.mime',
          helpInfo: 'Labels that assist in identifying file formats'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-file-extraction'],
        name: 'Sources',
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'scan.hash.md5.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'scan.hash.sha256.keyword',
                    order: {
                      _count: 'desc'
                    },

                    size: 50
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Strelka - File Hashes'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-file-extraction'],
        name: 'Sources',
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'scan.pe.file_info.company_name.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'scan.pe.file_info.original_filename.keyword',
                    order: {
                      _count: 'desc'
                    },

                    size: 50
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Strelka - Original File Names'
            // overlayText: ''
            // fontAwesomeProps: { icon: ['far', 'question-circle'], transform: 'shrink-1', className: 'ms-1 text-400' }
          }
        }
      }
    }
  ];
};

export default fileExtraction;
