import React from 'react';
import routes from 'config/routeSettings';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import NavigationCard from 'components/common/NavigationCard';
import { Row } from 'react-bootstrap';
import { systemRoutes } from 'routes/routes.js';

/**
 * Renders the DisplayLayout component
 *
 * @returns {JSX.Element} The rendered DisplayLayout component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const DisplayLayout = () => {
  const menuItems = systemRoutes.children
    .find(route => route.to === routes.SYSTEM_APP)
    .children.find(route => route.to === routes.SYSTEM_APP_SETTINGS)
    .children.find(route => route.to === routes.SYSTEM_APP_SETTINGS_DISPLAY);

  return (
    <>
      <WidgetSectionTitle title='Display' transform='down-3' className='mb-2' />
      <Row className='g-2'>
        {menuItems.children.map((route, index) => {
          return <NavigationCard route={route} key={index} index={index} />;
        })}
      </Row>
    </>
  );
};

export default DisplayLayout;
