/**
 * Makes an HTTP request using the specified parameters.
 *
 * @param {string} method - The HTTP method to use for the request.
 * @param {string} route - The URL route to send the request to.
 * @param {Object|null} data - The data to send with the request. Default: null
 * @param {Object|null} queryParams - The query params to include. Default: null
 * @param {string|null} responseType - The expected response type. Default: null
 * @returns {Promise<any>} - Resolves response data or rejects with an error.
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @since 0.1.0-beta.4
 * @version 0.1.0-beta.4
 */
export const makeRequest = async (
  axiosPrivate,
  method,
  route,
  data = null,
  queryParams = null,
  responseType = null
) => {
  const config = {
    method: method,
    url: route
  };

  if (responseType !== null) {
    config['responseType'] = responseType;
  }

  if (queryParams) {
    config.params = queryParams;
  }

  if (data) {
    config.data = JSON.stringify(data);
  }

  try {
    const res = await axiosPrivate.request(config);
    return res;
  } catch (error) {
    if (error.message.includes('Network Error')) {
      console.error('Network Error occurred.');
    }
    return error;
  }
};

/**
 * Makes a file request to the specified route.
 *
 * @param {string} route - The route to make the request to.
 * @returns {Promise<any>} - Resolves response data or rejects with an error.
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @since 0.1.0-beta.4
 * @version 0.1.0-beta.4
 */
export const makeFileRequest = async (axiosPrivate, route) => {
  try {
    const res = await axiosPrivate.get(route, {
      responseType: 'blob'
    });

    if (res instanceof Error) throw res;
    return res;
  } catch (error) {
    if (error.message.includes('Network Error')) {
      console.error('Network Error occurred.');
    }
    return error;
  }
};
