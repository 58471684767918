import { ReportsContext } from 'context/user-contexts/UserReportsProvider';
import { useContext, useMemo } from 'react';

/**
 * Custom hook to access the user reports context.
 *
 * This hook uses the `useContext` hook to get the current value of the `ReportsContext`
 * and memoizes it using the `useMemo` hook to optimize performance.
 *
 * @returns {Object} The current value of the `ReportsContext`.
 */
export const useUserReports = () => {
  const context = useContext(ReportsContext);
  return useMemo(() => context, [context]);
};

export default useUserReports;
