import React from 'react';
// import CreateTicket from './createTicket';
// import Followers from './followers/Followers';
import OverviewLayout from './OverviewLayout';

/**
 * Renders the Overview page component
 *
 * @returns {JSX.Element} The rendered Overview component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const Overview = () => {
  return (
    <>
      <OverviewLayout />
    </>
  );
};

export default Overview;
