import SearchFiltersPanel from 'components/search-filters/SearchFiltersPanel';
import useExplore from 'hooks/useExplore';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import ColWithDragAndDropItems from './dashboardsDragAndDrop/ColWithDragAndDropItems';
import { allDashboards } from './data/allDashboards';

const DynamicDashLayout = ({ dashLayoutName }) => {
  const location = useLocation();

  const {
    state: { update }
  } = useExplore();

  const rows = useMemo(() => {
    // Reset rows before updating them
    let updatedRows = [];

    try {
      let dashboard = allDashboards[dashLayoutName];

      if (dashboard) {
        updatedRows = dashboard();
      }
    } catch (error) {
      console.error('Error loading dashboard:', error);
    }

    return updatedRows;
  }, [dashLayoutName, update]);

  return (
    <div key={location.pathname}>
      <SearchFiltersPanel />

      <>
        {/* <DndProvider backend={HTML5Backend}> */}
        <Row className='g-1 mb-1'>
          <ColWithDragAndDropItems items={rows} />
        </Row>
        {/* </DndProvider> */}
      </>
    </div>
  );
};

DynamicDashLayout.propTypes = {
  showQuery: PropTypes.bool,
  setShowQuery: PropTypes.func,
  injectedPreset: PropTypes.string,
  allowedLimit: PropTypes.number,
  checkerIndices: PropTypes.array,
  indices: PropTypes.array,
  dashLayoutName: PropTypes.string
};

export default DynamicDashLayout;
