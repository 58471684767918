import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import LoadingIndicatorOverlay from 'components/visualizations/LoadingIndicatorOverlay';
import { useSearchFilters } from 'context/FiltersProvider';
import useApplication from 'hooks/useApplication';
import useExplore from 'hooks/useExplore';
// import useUnsavedChanges from 'hooks/useUnsavedChanges';
import useAuthentication from 'hooks/useAuthentication';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import FieldSelect from './FieldSelect';
import OperatorSelect from './OperatorSelect';
import TermSelect from './TermSelect';
import Tips from './Tips';

/**
 * The AddFilterModal component.
 *
 * This component renders a modal for adding a search filter.
 * It provides options for selecting a field, operator, and search term.
 * The user can also choose to use a custom label for the filter.
 *
 * @returns {JSX.Element} The rendered AddFilterModal component.
 */
const AddFilterModal = () => {
  const fieldRef = useRef(null);
  const modalRef = useRef(null);
  const operatorFieldRef = useRef(null);
  const termFieldRef = useRef(null);
  const applyButtonRef = useRef(null);

  const {
    application: { isDark }
  } = useApplication();

  const {
    authentication: { scopesSelected }
  } = useAuthentication();

  // const { setHasUnsavedChanges, promptOnLeave } = useUnsavedChanges();

  const { state, setState, fetchSuggestions, makeRequest, buildSearchQuery } =
    useExplore();

  const {
    searchFilters: { filters, modalFilterAdd, operators },
    setSearchFilters
  } = useSearchFilters();

  const [loading, setLoading] = useState(true);
  const [fieldsList, setFieldsList] = useState([]);
  const [operatorsList, setOperatorsList] = useState([]);
  const [field, setField] = useState(null);
  const [operator, setOperator] = useState(null);
  const [termSelected, setTermSelected] = useState(null);
  const [termsLoading, setTermsLoading] = useState(false);
  const [termOptions, setTermOptions] = useState([]);
  const [term, setTerm] = useState(null);
  const [pinned, setPinned] = useState(false);
  const [useCustomLabel, setUseCustomLabel] = useState(false);
  const [customLabel, setCustomLabel] = useState('');

  /**
   * Resets the state of the AddFilterModal component.
   */
  const handleReset = () => {
    setState('excludeFieldFilters', null);
    setField(null);
    setOperator(null);
    setTerm(null);
    setTermSelected(null);
    setPinned(false);
    setCustomLabel('');
    setSearchFilters(prev => ({
      ...prev,
      modalFilterAdd: {
        ...prev.modalFilterAdd,
        data: {
          ...prev.modalFilterAdd.data,
          ...prev.modalFilterAdd.defaultData
        },
        open: false
      }
    }));
  };

  /**
   * Handles the cancel action for the AddFilterModal component.
   */
  const handleCancel = () => {
    // promptOnLeave(() => {
    handleReset();
    // });
  };

  /**
   * Handles field change events.
   */
  const handleFieldChange = value => {
    if (!value) {
      setField(null);
      setTermOptions([]);
      setOperator(null);
      return;
    }

    setField(value);
    setTermOptions([]);
    setOperator(null);

    setSearchFilters(prev => ({
      ...prev,
      modalFilterAdd: {
        ...prev.modalFilterAdd,
        data: {
          ...prev.modalFilterAdd.data,
          field: state.fields.find(field => field._id === value.value)
        }
      }
    }));
  };

  /**
   * Handles operator change events.
   */
  const handleOperatorChange = value => {
    if (!value) {
      setOperator(null);
      setSearchFilters(prev => ({
        ...prev,
        modalFilterAdd: {
          ...prev.modalFilterAdd,
          data: {
            ...prev.modalFilterAdd.data,
            operator: null
          }
        }
      }));
      return;
    }

    setOperator(value);

    setSearchFilters(prev => ({
      ...prev,
      modalFilterAdd: {
        ...prev.modalFilterAdd,
        data: {
          ...prev.modalFilterAdd.data,
          operator: value.value
        }
      }
    }));

    if (!operators[value.value]?.requiresValue) {
      applyButtonRef.current.focus();
    }
  };

  /**
   * Handles term change events.
   */
  const handleTermChange = value => {
    const valueForState = Array.isArray(value)
      ? value.map(v => v.value)
      : value?.value;

    setTermSelected(value);
    setTerm(value);

    setSearchFilters(prev => ({
      ...prev,
      modalFilterAdd: {
        ...prev.modalFilterAdd,
        data: {
          ...prev.modalFilterAdd.data,
          value: valueForState
        }
      }
    }));

    applyButtonRef.current.disabled = !value;
  };

  /**
   * Toggle pin state handler.
   */
  const handleTogglePinned = e => {
    setPinned(e.target.checked);

    setSearchFilters(prev => ({
      ...prev,
      modalFilterAdd: {
        ...prev.modalFilterAdd,
        data: {
          ...prev.modalFilterAdd.data,
          pinned: e.target.checked
        }
      }
    }));
  };

  /**
   * Toggle custom label state handler.
   */
  const handleToggleUseCustomLabel = e => {
    setUseCustomLabel(e.target.checked);

    setSearchFilters(prev => ({
      ...prev,
      modalFilterAdd: {
        ...prev.modalFilterAdd,
        data: {
          ...prev.modalFilterAdd.data,
          customLabel: '',
          useCustomLabel: e.target.checked
        }
      }
    }));
  };

  /**
   * Fetch field options.
   */
  const handleGetFieldOptions = async value => {
    const pattern = new RegExp(value, 'i');
    const filteredFields = state.fields.filter(field =>
      pattern.test(field.name)
    );

    const fieldOptions = filteredFields.map(field => ({
      label: field.name,
      value: field._id
    }));

    return fieldOptions;
  };

  /**
   * Fetch operator options.
   */
  const handleGetOperatorOptions = async value => {
    const pattern = new RegExp(value, 'i');
    const filteredOperators = operatorsList.filter(operator =>
      pattern.test(operator.label)
    );

    const operatorOptions = filteredOperators.map(operator => ({
      label: operator.label,
      value: operator._id
    }));

    return operatorOptions;
  };

  /**
   * Fetch term options.
   */
  const handleGetTermOptions = async value => {
    if (value === null) return [];
    const suggestField = modalFilterAdd.data.field;
    const updatedQuery = buildSearchQuery(state, scopesSelected);

    const query = {
      ...updatedQuery.query,
      bool: {
        ...updatedQuery.query.bool,
        filter: [
          ...updatedQuery.query.bool.filter,
          {
            wildcard: {
              [suggestField.type === 'string'
                ? `${suggestField.name}.keyword`
                : suggestField.name]: {
                value: `*${value}*`
              }
            }
          }
        ]
      }
    };

    const suggestions = await fetchSuggestions(
      suggestField.type === 'string'
        ? `${suggestField.name}.keyword`
        : suggestField.name,
      state.indexSelected?.pattern || state.indexSelected.alias,
      query,
      makeRequest
    );

    return suggestions;
  };

  /**
   * Add a new filter.
   */
  const handleAddFilter = () => {
    try {
      const formData = {
        ...modalFilterAdd.data,
        id: uuid()
      };
      const newFilters = [...filters, formData];

      setSearchFilters(prev => ({
        ...prev,
        filters: newFilters,
        modalFilterAdd: {
          ...prev.modalFilterAdd,
          data: {
            ...prev.modalFilterAdd.data,
            value: null
          },
          open: false
        }
      }));
      handleReset();
    } catch (error) {
      console.error(error);
      return;
    }

    handleReset();
    setSearchFilters(prev => ({
      ...prev,
      modalFilterAdd: {
        ...prev.modalFilterAdd,
        open: false
      }
    }));
  };

  /**
   * Determine compatible operators when field changes.
   */
  useEffect(() => {
    if (!field || !state.fields || state.fields.length === 0) return;

    const selectedField = state.fields.find(f => f._id === field.value);
    let compatibleOperators = [];
    if (selectedField) {
      compatibleOperators = Object.keys(operators)
        .map(operatorKey => {
          if (
            operators[operatorKey].isCompatibleWithType(selectedField?.type)
          ) {
            return { label: operators[operatorKey].label, value: operatorKey };
          }
          return null;
        })
        .filter(operator => operator !== null);
    } else {
      compatibleOperators = Object.keys(operators).map(operatorKey => {
        return { label: operators[operatorKey].label, value: operatorKey };
      });
    }
    setOperatorsList(compatibleOperators);
  }, [field, operators, modalFilterAdd.open]);

  /**
   * Set initial field value when the modal opens or field list changes.
   */
  useEffect(() => {
    if (!state.fields || state.fields.length === 0) return;

    const fieldsList = state.fields.map(field => {
      return { label: field.name, value: field._id };
    });
    setFieldsList(fieldsList);
  }, [state.fields, modalFilterAdd.open]);

  useEffect(() => {
    // When the field changes, update the term options
    if (term && modalFilterAdd.data.field && !loading) {
      setTermsLoading(true);
      const suggestField = modalFilterAdd.data.field;
      const updatedQuery = buildSearchQuery(state, scopesSelected);

      const query = {
        ...updatedQuery.query,
        bool: {
          ...updatedQuery.query.bool,
          filter: [
            ...updatedQuery.query.bool.filter,
            {
              wildcard: {
                [suggestField.type === 'string'
                  ? `${suggestField.name}.keyword`
                  : suggestField.name]: {
                  value: `*${term}*`
                }
              }
            }
          ]
        }
      };

      fetchSuggestions(
        suggestField.type === 'string'
          ? `${suggestField.name}.keyword`
          : suggestField.name,
        state.indexSelected?.pattern || state.indexSelected.alias,
        query,
        makeRequest
      ).then(suggestions => {
        setTermOptions(suggestions);
        setTermsLoading(false);
      });
    }
  }, [field]);

  /**
   * Track custom label changes.
   */
  useEffect(() => {
    setSearchFilters(prev => ({
      ...prev,
      modalFilterAdd: {
        ...prev.modalFilterAdd,
        data: {
          ...prev.modalFilterAdd.data,
          customLabel
        }
      }
    }));
  }, [customLabel]);

  /**
   * Detect unsaved changes.
   */
  // useEffect(() => {
  //   if (modalFilterAdd.data?.value || modalFilterAdd.data?.customLabel) {
  //     setHasUnsavedChanges(true);
  //   } else {
  //     setHasUnsavedChanges(false);
  //   }
  // }, [modalFilterAdd.data?.value, modalFilterAdd.data?.customLabel]);

  /**
   * Focus input when modal opens.
   */
  useEffect(() => {
    if (modalFilterAdd?.data?.field && modalFilterAdd?.data?.operator) {
      setField({
        label: modalFilterAdd?.data?.field?.name,
        value: modalFilterAdd?.data?.field?._id
      });
      handleOperatorChange({
        label: operators[modalFilterAdd?.data?.operator].label,
        value: modalFilterAdd?.data?.operator
      });

      if (operatorFieldRef.current) {
        operatorFieldRef.current.blur();
      }
      if (!operators[modalFilterAdd?.data?.operator]?.requiresValue) {
        applyButtonRef.current.focus();
      }
    } else {
      if (fieldRef.current) {
        fieldRef.current.focus();
      }
    }
  }, [modalFilterAdd.open]);

  useEffect(() => {
    if (state.fields && state.fields.length > 0) {
      setLoading(false);
    } else if (operators && Object.keys(operators).length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [state.fields, operators]);

  /**
   * Clear state when index changes.
   */
  useEffect(() => {
    handleReset();
  }, [state.indexSelected]);

  return (
    <Modal
      aria-labelledby='searchFiltersModalAddNewFilter'
      centered
      contentClassName='shadow-none'
      keyboard={true}
      onHide={handleCancel}
      ref={modalRef}
      show={modalFilterAdd.open}
      size='lg'>
      <Flex
        className='card border border-card shadow-none'
        direction='column'
        justifyContent='between'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex mb-1 fs-0 fw-normal position-relative align-items-center'>
            Add a filter{' '}
            <AdvancedPopover
              containerId='searchFiltersModalAddNewFilter'
              placement='top'
              popoverText='Add a new filter to the search.'
              showArrow={true}>
              <FontAwesomeIcon
                className='ms-1 fs--1 cursor-pointer text-400'
                icon={['far', 'question-circle']}
              />
            </AdvancedPopover>
          </h5>
        </Modal.Header>

        <Modal.Body className='p-2 position-relative'>
          <LoadingIndicatorOverlay loading={loading || state.fieldsLoading} />
          <Row className='g-2 mb-2'>
            <Col sm={field ? 9 : 12}>
              <FormGroup>
                <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
                  Field{' '}
                  <AdvancedPopover
                    containerId='searchFiltersModalAddNewFilter'
                    placement='top'
                    popoverText='Select the field to use for the filter.'
                    showArrow={true}>
                    <FontAwesomeIcon
                      className='ms-1 text-400 fs--1 cursor-pointer'
                      icon={['far', 'question-circle']}
                    />
                  </AdvancedPopover>
                </Form.Label>
                <FieldSelect
                  onChange={handleFieldChange}
                  getOptions={handleGetFieldOptions}
                  options={fieldsList}
                  totalOptions={state.fields.length}
                  ref={fieldRef}
                  size='sm'
                  value={field}
                />
              </FormGroup>
            </Col>

            {field && (
              <Col sm={3}>
                <FormGroup>
                  <Form.Label
                    className={'d-flex fs--1 fw-normal align-items-center'}>
                    Operator{' '}
                    <AdvancedPopover
                      containerId='searchFiltersModalAddNewFilter'
                      placement='top'
                      popoverText='Choose an operator to specify how the filter should behave.'
                      showArrow={true}>
                      <FontAwesomeIcon
                        className='ms-1 text-400 fs--1 cursor-pointer'
                        icon={['far', 'question-circle']}
                      />
                    </AdvancedPopover>
                  </Form.Label>
                  <OperatorSelect
                    onChange={handleOperatorChange}
                    getOptions={handleGetOperatorOptions}
                    options={operatorsList}
                    ref={operatorFieldRef}
                    autofocus={operator === null}
                    size='sm'
                    value={operator}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            <Col>
              {field &&
                operator &&
                operators[operator.value]?.requiresValue && (
                  <Row className='mb-3'>
                    <Col>
                      <FormGroup>
                        <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
                          Search Term{' '}
                          <AdvancedPopover
                            containerId='searchFiltersModalAddNewFilter'
                            placement='top'
                            popoverText='Define the search term for the new filter using the input field below.'
                            showArrow={true}>
                            <FontAwesomeIcon
                              className='ms-1 text-400 fs--1 cursor-pointer'
                              icon={['far', 'question-circle']}
                            />
                          </AdvancedPopover>
                        </Form.Label>
                        <TermSelect
                          ref={termFieldRef}
                          isMulti={operators[operator.value]?.isMulti}
                          isLoading={termsLoading}
                          onChange={handleTermChange}
                          options={termOptions}
                          operatorMultiOptionLimit={
                            operators[operator.value]?.maxValues || 100
                          }
                          getOptions={handleGetTermOptions}
                          value={termSelected}
                          className='fs--1'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}

              <Row className='mb-2'>
                <Col>
                  <FormGroup
                    className='my-0 fs--1 d-flex'
                    id='filterPinnedSwitchGroup'>
                    <Form.Check
                      checked={pinned}
                      color='pastel-green'
                      id='filterPinnedSwitch'
                      name='pinned'
                      onChange={handleTogglePinned}
                      type='switch'
                    />
                    <Form.Label
                      className='text-700 fs--1 d-flex align-items-center fw-normal mb-0'
                      htmlFor='filterPinnedSwitch'>
                      Pin this filter?{' '}
                      <AdvancedPopover
                        containerId='filterPinnedSwitchGroup'
                        popoverText='If set, the filter will be pinned to the top of the list and will persist when moving to another dashboard or index, as long as the target index has the same field.'>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>
                  </FormGroup>
                </Col>
              </Row>

              <Row className='mb-2'>
                <Col>
                  <FormGroup
                    className='my-0 fs--1 d-flex'
                    id='customLabelSwitchGroup'>
                    <Form.Check
                      checked={useCustomLabel}
                      color='pastel-green'
                      id='customLabelSwitch'
                      name='useCustomLabel'
                      onChange={handleToggleUseCustomLabel}
                      type='switch'
                    />
                    <Form.Label
                      className='text-700 fs--1 d-flex align-items-center fw-normal mb-0'
                      htmlFor='customLabelSwitch'>
                      Use a custom label for this filter?{' '}
                      <AdvancedPopover
                        containerId='customLabelSwitchGroup'
                        popoverText='If set, the filter will use a custom label instead of the field name.'>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>
                  </FormGroup>

                  {useCustomLabel && (
                    <FormGroup className='mt-2'>
                      <Form.Control
                        autoComplete='off'
                        className='fs--1 ps-2 py-2'
                        name='customLabel'
                        onChange={e => setCustomLabel(e.target.value)}
                        value={customLabel}
                      />
                    </FormGroup>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className='p-2'>
          <Row className='g-1 m-0 p-0 w-100'>
            <Col xs={12} lg={9} className='p-0 m-0'>
              <div className='text-400'>
                <Tips />
              </div>
            </Col>

            <Col xs={12} lg={3} className='p-0'>
              <Flex direction='row' justifyContent='end'>
                <Button
                  className='me-2'
                  onClick={handleCancel}
                  size='sm'
                  variant='secondary'>
                  Cancel
                </Button>
                <Button
                  ref={applyButtonRef}
                  onClick={handleAddFilter}
                  size='sm'
                  variant='success'
                  disabled={operator?.requiresValue && !term}
                  className='btn-primary'>
                  Add filter
                </Button>
              </Flex>
            </Col>
          </Row>
        </Modal.Footer>
      </Flex>
    </Modal>
  );
};

export default AddFilterModal;
