import React from 'react';
const { default: CardDropdown } = require('components/common/CardDropdown');
const { default: Flex } = require('components/common/Flex');
const { Dropdown } = require('react-bootstrap');

export const columnActions = () => {
  return [
    {
      accessor: 'leargas_actions',
      Header: '',
      Cell: () => {
        return (
          <Flex justifyContent='around' alignContent='middle'>
            <CardDropdown>
              <div className='py-2'>
                <Dropdown.Item className='text-danger'>
                  Delete Route
                </Dropdown.Item>
                <Dropdown.Item>Edit Route</Dropdown.Item>
              </div>
            </CardDropdown>
          </Flex>
        );
      },
      cellProps: {
        className: 'text-end text-400 actions-cell',
        style: {
          width: '42px'
        }
      },
      hideDisplaySettings: true,
      disableSortBy: true
    }
  ];
};
