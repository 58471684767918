import { StaticQueryIntegration } from 'components/dashboards/Artifacts/Dynamic/StaticQueryIntegration';
import GeneralReports from 'components/dashboards/Reports/GeneralReportView/index.js';
import HistogramBarsLegend from 'components/visualizations/histogram/HistogramBarsLegend';
import ListAlerts from 'components/visualizations/list/ListAlertNoFilters.js';
import MapCardBarChartPercentages from 'components/visualizations/map/MapCardBarChartPercentages';
import TableSimple from 'components/visualizations/table/TableSimple';
import routes from 'config/routeSettings';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import GreetingCard from './greetings/GreetingCard.js';
import SavedSearches from './SavedSearchesList';

/**
 * Renders the OverviewLayout component
 *
 * @param {Object} props - The component props
 * @param {Array} props.checkerIndices - The checker indexes
 * @param {number} props.allowedLimit - The allowed limit
 * @param {string} props.injectedPreset - The injected preset
 * @returns {JSX.Element} The rendered component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const OverviewLayout = () => {
  return (
    <>
      <Row className='g-1 mb-1'>
        <Col xs={12} xl={9} xxl={10}>
          <Row className='g-1 mb-1'>
            <Col sm={12}>
              <Row className='g-1 h-100'>
                <Col>
                  <GreetingCard
                    data={{ title: 'Total Artifacts' }}
                    helpInfo='Total number of artifacts from all sources within the given time range'
                    linkTo={routes.ARTIFACTS_EXPLORE}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={6} xxl={3}>
              <StaticQueryIntegration
                overriddenIndex={['leargas-stats-flows']}
                queryType={['GeneralQuery']}
                aggregate={{
                  aggs: {
                    2: {
                      terms: {
                        field: 'dataType.keyword',
                        order: {
                          1: 'desc'
                        },
                        size: 50
                      },
                      aggs: {
                        1: {
                          sum: {
                            field: 'docCount'
                          }
                        }
                      }
                    }
                  },
                  size: 0
                }}
                must_not={[
                  {
                    match: {
                      dataType: 'z-alert'
                    }
                  },
                  {
                    match: {
                      dataType: 's-alert'
                    }
                  },
                  {
                    match: {
                      dataType: 'zeek'
                    }
                  },
                  {
                    match: {
                      dataType: 'suricata'
                    }
                  },
                  {
                    match: {
                      dataType: 'syslog'
                    }
                  },
                  {
                    match: {
                      dataType: 'winlogbeat'
                    }
                  },
                  {
                    match: {
                      dataType: 'netflow'
                    }
                  }
                ]}>
                <TableSimple
                  cardTitle='Cloud Artifacts by Provider'
                  footerLink
                />
              </StaticQueryIntegration>
            </Col>
            <Col md={6} xxl={3}>
              <StaticQueryIntegration
                overriddenIndex={['leargas-stats-flows']}
                queryType={['GeneralQuery']}
                aggregate={{
                  aggs: {
                    2: {
                      terms: {
                        field: 'dataType.keyword',
                        order: {
                          1: 'desc'
                        },
                        size: 50
                      },
                      aggs: {
                        1: {
                          sum: {
                            field: 'docCount'
                          }
                        }
                      }
                    }
                  },
                  size: 0
                }}
                must_not={[
                  {
                    match: {
                      dataType: 'netflow'
                    }
                  },
                  {
                    match: {
                      dataType: 'azure'
                    }
                  },
                  {
                    match: {
                      dataType: 'cloudtrail'
                    }
                  },
                  {
                    match: {
                      dataType: 'meraki'
                    }
                  },
                  {
                    match: {
                      dataType: 'okta'
                    }
                  },
                  {
                    match: {
                      dataType: 'sentinelone'
                    }
                  },
                  {
                    match: {
                      dataType: 'duo'
                    }
                  },
                  {
                    match: {
                      dataType: 'google'
                    }
                  },
                  {
                    match: {
                      dataType: 'o365'
                    }
                  },
                  {
                    match: {
                      dataType: 'box'
                    }
                  }
                ]}>
                <TableSimple cardTitle='Other Artifacts' />
              </StaticQueryIntegration>
            </Col>
            <Col sm={12} xxl={6}>
              <StaticQueryIntegration
                overriddenIndex={['leargas-zeek']}
                queryType={['HistogramBarsLegend']}>
                <HistogramBarsLegend
                  title={'Threat Intelligence Hits By Source'}
                />
              </StaticQueryIntegration>
            </Col>
          </Row>
          <Row className='g-1 mb-1'>
            <Col md={6} xxl={3}>
              <StaticQueryIntegration
                overriddenIndex={['leargas-zeek-alert']}
                queryType={['table']}
                tableQuery={{
                  aggs: {
                    2: {
                      terms: {
                        field: 'note.keyword',
                        order: {
                          _key: 'desc'
                        },

                        size: 10
                      }
                    }
                  },
                  size: 0
                }}>
                <ListAlerts
                  title='Top Network Behaviors'
                  fieldName='note'
                  hideFilterMenu={true}
                  linkTo={routes.ARTIFACTS_NETWORK_BEHAVIORS}
                  linkLabel='View All Behaviors'
                />
              </StaticQueryIntegration>
            </Col>
            <Col md={6} xxl={3}>
              <StaticQueryIntegration
                overriddenIndex={['leargas-suricata-alert']}
                queryType={['table']}
                tableQuery={{
                  aggs: {
                    2: {
                      terms: {
                        field: 'alert.signature.keyword',
                        order: {
                          _key: 'desc'
                        },

                        size: 10
                      }
                    }
                  },
                  size: 0
                }}>
                <ListAlerts
                  title='Top Network Signatures'
                  fieldName='alert.signature'
                  hideFilterMenu={true}
                  linkTo={routes.ARTIFACTS_NETWORK_SIGNATURES}
                  linkLabel='View All Signatures'
                />
              </StaticQueryIntegration>
            </Col>
            <Col xxl={6}>
              <StaticQueryIntegration
                queryType={['map']}
                overriddenIndex={['leargas-zeek', 'leargas-suricata']}
                aggregate={{
                  aggs: {
                    2: {
                      terms: {
                        field: 'source_geoip.country_name.keyword',
                        order: {
                          _count: 'desc'
                        },
                        exclude: [''],
                        size: 200
                      }
                    }
                  }
                }}>
                <MapCardBarChartPercentages helpInfo='Top event source countries seen across all data types' />
              </StaticQueryIntegration>
            </Col>
          </Row>
        </Col>
        <Col xs={12} xl={3} xxl={2}>
          <Row className='g-1'>
            <Col xs={12}>
              <SavedSearches />
            </Col>
            <Col xs={12}>
              <GeneralReports />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

// OverviewLayout.propTypes = {
//   checkerIndices: PropTypes.array,
//   allowedLimit: PropTypes.number,
//   injectedPreset: PropTypes.string
// };

export default OverviewLayout;
