import { routes } from 'config/routeSettings';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthentication from './useAuthentication';

/**
 * Handle enforcing MFA app setup and session verification
 *
 * @returns {void} Nothing
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 *
 * @example
 * import useMultiFactorApp from 'hooks/useMultiFactorApp';
 * useMultiFactorApp();
 */
export const useMultiFactorApp = () => {
  const {
    authentication: {
      id = null,
      security: { multifactor: { app: { isEnabled } = {} } = {} } = {},
      session: { device: { isVerified } = {} } = {}
    }
  } = useAuthentication();

  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Handles the navigation logic based on the user's MFA setup and verification status
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const handleNavigation = () => {
    // If the user is logged in and their session was not intercepted
    if (id) {
      if (!isEnabled) {
        // If the user has not setup MFA, redirect them to the MFA app setup page
        if (!location.pathname.startsWith(routes.MFA_APP_SETUP)) {
          navigate(routes.MFA_APP_SETUP, {
            state: { from: location.pathname }
          });
        }
      } else if (!isVerified) {
        // If the user has not verified their MFA app, redirect them to the MFA app verification page
        if (!location.pathname.startsWith(routes.MFA_APP_VERIFY)) {
          navigate(routes.MFA_APP_VERIFY, {
            state: { from: location.pathname }
          });
        }
      } else {
        // Otherwise, redirect them to the page they were trying to access initially or the explore page
        const from = location.state?.from || routes.OVERVIEW;
        if (
          location.pathname.startsWith(routes.MFA_APP_SETUP) ||
          location.pathname.startsWith(routes.MFA_APP_VERIFY)
        ) {
          navigate(from, { replace: true });
        }
      }
    }
  };

  /**
   * Handle MFA verification on initial load and authentication change
   */
  useEffect(() => {
    handleNavigation();
  }, [id, isEnabled, isVerified, location.pathname]);
};

export default useMultiFactorApp;
