import { useContext } from 'react';
import { JobsContext } from 'context/admin-contexts/JobsProvider';

/**
 * Custom hook that returns the JobsContext
 * @returns {JobsContext} The JobsContext object
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
export const useJobs = () => {
  return useContext(JobsContext);
};

export default useJobs;
