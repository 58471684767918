import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useUsers from 'hooks/admin-contexts/useUsers';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import useAxiosPrivate from 'hooks/useAxiosPrivate';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const UnlockUserForm = () => {
  const {
    users: {
      showUnlockUserModal: { defaultData }
    },
    setUsers
  } = useUsers();

  const { axiosPrivate } = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  /**
   * Handle unlock a user
   * @param {Event} e
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const handleSubmit = async e => {
    e.preventDefault();

    try {
      setLoading(true);
      const res = await LeargasAPI.Users.unlockUser(
        defaultData?._id,
        axiosPrivate
      );
      if (res instanceof Error) throw res;
      const { message } = res;
      if (message) {
        setLoading(false);
        setUsers(prevState => ({
          ...prevState,
          showUnlockUserModal: { open: false },
          fetch: true
        }));
        toast.success(message);
      }
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);
      setUsers(prevState => ({
        ...prevState,
        showUnlockUserModal: { open: false },
        fetch: false
      }));
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='g-3 mb-3'>
        <Col sm={12}>
          <p>
            Are you sure you want to unlock {defaultData?.profile?.name?.first}
            's account?
          </p>
        </Col>
      </Row>

      <Flex justifyContent='end'>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() =>
            setUsers(prevState => ({
              ...prevState,
              showUnlockUserModal: { open: false },
              fetch: false
            }))
          }>
          Cancel
        </Button>

        <Button variant='success' size='sm' type='submit'>
          <>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <ButtonSpinner spinning={loading} />
              <span>{loading ? 'Unlocking' : 'Unlock account'}</span>
            </Flex>
          </>
        </Button>
      </Flex>
    </Form>
  );
};

export default UnlockUserForm;
