import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';
import { setFullControlQuery } from 'components/dashboards/Artifacts/Dynamic/dataFetchUtils/RefinedUtils';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import {
  presetIntervals,
  presetRanges,
  showUpdateButtonOn
} from 'context/ESGlobalFiltersProvider';
import useApplication from 'hooks/useApplication';
import useESGlobalFilters from 'hooks/useGlobalFilters';
import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  SplitButton
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Flex from '../Flex';
import ResetModal from './confirmModals/confirmResetModal';

/**
 * UpdateControls component is responsible for managing the update functionality and reset functionality of global filters
 * It allows users to update the filters and reset them to their initial state
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const UpdateControls = () => {
  const {
    esGlobalFilters: {
      loading,
      preset,
      gte,
      lte,
      stagedQuery,
      injectedPreset,
      dInterval,
      intervalTime
    },
    setESGlobalFilters
  } = useESGlobalFilters();
  const { setApplication, updateGlobalFilters } = useApplication();

  const [showResetModal, setShowResetModal] = useState(false);
  const location = useLocation();

  /**
   * Resets the state of the component to its initial values.
   */
  const onReset = () => {
    setESGlobalFilters(prevState => ({
      ...prevState,
      fullControlQuery: '',
      highlightedGlobalIndex: null,
      query: {
        id: QbUtils.uuid(),
        rules: []
      },
      querySearchText: '',
      query_string: null,
      stagedQuery: {
        id: QbUtils.uuid(),
        rules: []
      },
      preset: injectedPreset || 'last_1_hour',
      injectedPreset: injectedPreset || 'last_1_hour',

      update: true
    }));
    updateGlobalFilters({
      searchtext: ''
    });
    setApplication('showGlobalFilters', false);
  };

  /**
   * Sets the refresh interval for the query filters
   * @param {string|number} interval - The refresh interval. Can be a number representing seconds or the strings 'start' or 'stop'
   */
  const setRefreshInterval = interval => {
    if (interval !== 'start' && interval !== 'stop') {
      const seconds = Number(interval);

      setFullControlQuery(
        stagedQuery,

        setESGlobalFilters
      );

      setESGlobalFilters(prevState => ({
        ...prevState,
        query: stagedQuery,
        intervalTime: seconds,
        update: true,
        highlightedGlobalIndex: null
      }));
      updateGlobalFilters({
        gte: gte,
        lte: lte,
        preset: preset
      });
      setApplication('showGlobalFilters', false);
    } else {
      if (interval === 'start') {
        setESGlobalFilters(prevState => ({
          ...prevState,
          dInterval: true,
          query: stagedQuery,
          intervalTime: intervalTime,
          update: true,
          highlightedGlobalIndex: null
        }));
        setApplication('showGlobalFilters', false);
      } else if (interval === 'stop') {
        setESGlobalFilters(prevState => ({
          ...prevState,
          dInterval: false,
          loading: false,
          intervalTime: 30,

          update: false,
          highlightedGlobalIndex: null
        }));
        setApplication('showGlobalFilters', false);
      }
    }
  };

  return showUpdateButtonOn.includes(location.pathname) ? (
    <Col md={12} className='pt-0 mt-0 text-end'>
      <Flex
        direction='column'
        justifyContent='end'
        alignItems='end'
        className='h-100'>
        <div className='dropdown-wrap m-0 mt-2 mb-2'>
          <Button
            size='sm'
            variant='secondary'
            className='fs--1 me-2 border-1'
            onClick={() => setShowResetModal(true)}
            style={{ paddingTop: '4px' }}>
            <FontAwesomeIcon icon='xmark' transform='grow-3' /> Reset
          </Button>
          {preset && !presetRanges[preset]?.allowRefresh ? (
            <Button
              variant={'primary'}
              title={'Update'}
              size='sm'
              onClick={() => {
                setRefreshInterval(intervalTime);
              }}>
              <Flex alignItems='center'>
                <FontAwesomeIcon
                  icon={['fas', 'play']}
                  className='me-1'
                  transform='up-1'
                />
                <span className='me-3'>Update</span>
                <span
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    transform: 'scale(0.75)'
                  }}
                  className={`${loading ? '' : 'd-none'}`}>
                  <ButtonSpinner spinning={loading} />
                </span>
              </Flex>
            </Button>
          ) : (
            <SplitButton
              as={ButtonGroup}
              variant={'primary'}
              disabled={loading}
              title={
                <Flex justifyContent={'center'} alignItems={'center'}>
                  <FontAwesomeIcon
                    icon={['fas', 'play']}
                    className='me-1'
                    transform='up-1'
                  />
                  <span>{loading ? 'Updating' : 'Update'}</span>
                </Flex>
              }
              size='sm'
              onClick={() => {
                setRefreshInterval(intervalTime);
              }}
              onSelect={setRefreshInterval}
              drop='down'
              align={{ xs: 'start' }}
              className='p-0'>
              <Dropdown.Header className='mt-0'>Auto Refresh</Dropdown.Header>
              {Object.values(presetIntervals).map((item, i) => {
                const {
                  label,
                  interval: { seconds }
                } = item;
                return (
                  <Dropdown.Item
                    key={i}
                    eventKey={seconds}
                    active={
                      intervalTime * 1000 === seconds * 1000 ? true : false
                    }>
                    {label}
                  </Dropdown.Item>
                );
              })}
              <Dropdown.Divider />
              <Dropdown.Item
                eventKey={dInterval ? 'stop' : 'start'}
                active={false}
                className='mb-2'>
                {dInterval ? 'Stop' : 'Start'}
              </Dropdown.Item>
            </SplitButton>
          )}
        </div>
      </Flex>
      <ResetModal
        showResetModal={showResetModal}
        setShowResetModal={setShowResetModal}
        reset={onReset}
      />
    </Col>
  ) : null;
};

export default UpdateControls;
