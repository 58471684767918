import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { helpModalItems } from 'config/helpModalSettings';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ReportsItem = ({ item }) => {
  return (
    <Col md={12} lg={6}>
      <Flex
        alignItems='center'
        className='px-4 py-2 bg-light rounded-3 border position-relative hover-primary hover-bg-600 cursor-pointer'>
        <FontAwesomeIcon
          icon={item.icon}
          transform='grow-15'
          className='me-2'
        />
        <div className='ms-3 my-x1'>
          <h5 className='fs-0 fw-semi-bold mb-2'>
            <Link
              to='#!'
              className='text-900 text-decoration-none stretched-link'>
              {item.title}
            </Link>
          </h5>
          <h6 className='fs--1 mb-0 text-600'>{item.description}</h6>
        </div>
      </Flex>
    </Col>
  );
};

ReportsItem.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })
};

const HelpModal = () => {
  const {
    application: { isDark, showHelpModal },
    setApplication
  } = useApplication();

  useEffect(() => {
    if (showHelpModal) {
      setApplication('showHelpModal', false);
    }
  }, []);

  return (
    <Modal
      size='lg'
      show={showHelpModal}
      onHide={() => setApplication('showHelpModal', false)}
      backdrop='static'
      centered>
      <Modal.Header closeButton closeVariant={isDark ? 'white' : null}>
        <Modal.Title>Help Shortcuts</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-2'>
        <h5 className='fs-0 mb-3'>Information & Resources</h5>
        <Row className='g-3'>
          {helpModalItems.analysis.map((item, index) => (
            <ReportsItem key={index} item={item} />
          ))}
        </Row>
        <h5 className='fs-0 mb-3 mt-4'>Get In Touch</h5>
        <Row className='g-3'>
          {helpModalItems.customerSatisfaction.map((item, index) => (
            <ReportsItem key={index} item={item} />
          ))}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default HelpModal;
