import NavigationCard from 'components/common/NavigationCard';
import React from 'react';
import { Row } from 'react-bootstrap';
import { artifactRoutes } from 'routes/routes';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';

/**
 * Renders the Artifacts page
 *
 * @returns {JSX.Element} The rendered Application page
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.2
 */
const ArtifactsPage = () => {
  const menuItems = artifactRoutes;

  return (
    <>
      <WidgetSectionTitle title='Discover Your Artifacts' className='mb-2' />
      <Row className='g-1 mb-1'>
        {menuItems.children.map((route, index) => (
          <NavigationCard route={route} key={index} index={index} />
        ))}
      </Row>
    </>
  );
};

export default ArtifactsPage;
