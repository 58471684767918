import useProducts from 'hooks/admin-contexts/useProducts';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Modal } from 'react-bootstrap';
import FormProductRestore from '../forms/ProductsRestoreForm';
// import LeargasAPI from 'api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
// import Flex from 'components/common/Flex';
// import { toast } from 'react-toastify';
// import { Button, Col, Form, Row } from 'react-bootstrap';

/**
 * products restore modal
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ModalProductRestore = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    products: { restoreProductModal },
    setProducts
  } = useProducts();
  const { data } = restoreProductModal;

  return (
    <Modal
      centered
      size='lg'
      animation={false}
      backdrop='static'
      keyboard={false}
      show={restoreProductModal?.open}
      onHide={() =>
        setProducts(prevState => ({
          ...prevState,
          restoreProductModal: { open: false, data: {} }
        }))
      }
      aria-labelledby='modalProductAdd'>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Restore Product: {data?.name}
          <AdvancedPopover
            containerId='resetConfirmModal'
            placement='top'
            popoverText='Restore product'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
      </Modal.Header>
      <Modal.Body className='p-2'>
        <FormProductRestore />
      </Modal.Body>
    </Modal>
  );
};

export default ModalProductRestore;
