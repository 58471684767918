import Flex from 'components/common/Flex';
import FieldBadge from 'components/dashboards/Artifacts/Explore/Sidebar/FieldList/FieldBadge';
import useExplore from 'hooks/useExplore';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Renders the SourceField component.
 *
 * @param {Object} props - The component props
 * @param {any} props.sourceField - The source field
 * @param {number} [props.valueCount=72] - The maximum number of values to display
 * @param {boolean} [props.coloredByType=true] - Determines if the values should be colored based on their type
 * @returns {JSX.Element} The rendered SourceField component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const SourceField = ({
  sourceField,
  valueCount = 48,
  coloredByType = true
}) => {
  const {
    state: { fields }
  } = useExplore();

  /**
   * Truncates a value to a specified length.
   *
   * @param {any} value - The value to be truncated
   * @param {number} [length=24] - The maximum length of the truncated value
   * @returns {string} The truncated value
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const truncateValue = (value, length = 24) => {
    // Truncate to the specified length
    return String(value).length > length
      ? String(value).substring(0, length) + '...'
      : String(value);
  };

  /**
   * Prepares the given value for display.
   *
   * @param {*} value - The value to be prepared
   * @returns {string} - The prepared value
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const prepareValue = value => {
    let preparedValue;

    if (value === 'null' || typeof value === 'undefined') {
      preparedValue = 'null';
    } else if (typeof value === 'string') {
      preparedValue = value;
    } else if (Array.isArray(value)) {
      if (value.length === 0) {
        return false;
      } else if (typeof value[0] === 'object') {
        preparedValue = JSON.stringify(value.map(val => val));
      } else {
        preparedValue = value.join(', ');
      }
    } else if (typeof value === 'object') {
      preparedValue = JSON.stringify(value);
    } else {
      preparedValue = String(value);
    }

    return preparedValue;
  };

  // If the sourceField is object, we can show the values for the selected fields.
  if (typeof sourceField === 'object') {
    return (
      <Flex
        justifyContent='start'
        alignContent='center'
        alignItems='center'
        className='flex-wrap explore-source-field-container'>
        <>
          {Object.entries(sourceField)
            .filter(([key]) => key !== '@timestamp')
            .slice(0, valueCount)
            .map(([key, value], fieldIndex) =>
              prepareValue(truncateValue(value)) ? (
                <span key={fieldIndex} className='m-0 p-0 fs--2 pb-1'>
                  <FieldBadge
                    type={
                      (coloredByType &&
                        fields.find(field => field.name === key)?.type) ||
                      'default'
                    }
                    showIcon={false}
                    popoverText={prepareValue(truncateValue(value, 200))}>
                    <span className='fs--2 fw-normal text-700'>
                      {prepareValue(truncateValue(value))}
                    </span>
                  </FieldBadge>
                </span>
              ) : null
            )}
          {Object.keys(sourceField).length > valueCount && (
            <span className='m-0 p-0 pb-1 ms-1 fs--2'>
              + {Object.keys(sourceField).length - valueCount} more
            </span>
          )}
        </>
      </Flex>
    );
  }

  return <></>;
};

SourceField.propTypes = {
  coloredByType: PropTypes.bool,
  sourceField: PropTypes.object,
  valueCount: PropTypes.number
};

export default SourceField;
