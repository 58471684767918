import classNames from 'classnames';
import Notification from 'components/account/Notifications/Notification';
import Flex from 'components/common/Flex';
import { routes } from 'config/routeSettings';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ActivityLog = ({ activities, lastSeenAt, idto, ...rest }) => {
  return (
    <Card {...rest}>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between'>
          <h5 className='mb-1 mb-md-0'>Activity log</h5>
        </Flex>
      </Card.Header>
      <Card.Body className=''>
        {activities.map((activity, index) => {
          return (
            <>
              {index < 50 && (
                <Notification
                  {...activity}
                  lastSeenAt={lastSeenAt && lastSeenAt}
                  key={activity.id}
                  className={classNames(
                    'border-x-0 border-bottom-0 border-300',
                    index + 1 === activities.length
                      ? 'rounded-top-0'
                      : 'rounded-0'
                  )}
                />
              )}
              {index === activities.length - 1 && (
                <div className='card-footer text-center border-top'>
                  <Link
                    className='card-link d-block'
                    to={`${routes.SYSTEM_APP_ACTIVITY_LOGS}?id=${idto}`}>
                    View all
                  </Link>
                </div>
              )}
            </>
          );
        })}
        {activities && activities.length === 0 && (
          <Flex justifyContent='between'>
            <p className='mb-1 mb-md-0'>No Recent Activaty was found!</p>
          </Flex>
        )}
      </Card.Body>
    </Card>
  );
};

ActivityLog.propTypes = {
  activities: PropTypes.array.isRequired,
  lastSeenAt: PropTypes.string,
  idto: PropTypes.string
};

export default ActivityLog;
