import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'react-bootstrap';

const CardDropdown = ({
  btnRevealClass,
  drop,
  children,
  icon = 'ellipsis-h',
  ...rest
}) => {
  const {
    application: { isRTL }
  } = useApplication();

  return (
    <Dropdown
      {...rest}
      className='font-sans-serif btn-reveal-trigger'
      align={isRTL ? 'start' : 'end'}
      drop={drop}
      style={{ position: '' }}>
      <Dropdown.Toggle
        variant='link'
        size='sm'
        data-boundary='viewport'
        className={classNames('text-600 p-0', {
          [btnRevealClass]: btnRevealClass,
          'btn-reveal': !btnRevealClass
        })}
        style={{
          height: 'auto',
          width: '1.5rem',
          padding: '0',
          lineHeight: '1.25rem'
        }}>
        <FontAwesomeIcon icon={icon} className=' fs--2' />
      </Dropdown.Toggle>
      <Dropdown.Menu className='border py-0'>
        {children}
        {!children && (
          <div className='py-2'>
            <Dropdown.Item>View</Dropdown.Item>
            <Dropdown.Item>Export</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className='text-danger'>Remove</Dropdown.Item>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

CardDropdown.propTypes = {
  btnRevealClass: PropTypes.string,
  drop: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string
};

export default CardDropdown;
