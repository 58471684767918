import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import useOrganizations from 'hooks/admin-contexts/useOrganizations';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Modal } from 'react-bootstrap';
import EditOrganizationForm from '../forms/OrganizationsEditForm';
/**
 * Modal to Edit an organizations
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ModalOrganizationEdit = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    organizations: { orgEditModal },
    setOrganizations
  } = useOrganizations();

  return (
    <Modal
      centered
      size='lg'
      animation={false}
      backdrop='static'
      keyboard={false}
      show={orgEditModal?.open}
      onHide={() =>
        setOrganizations(prevState => ({
          ...prevState,
          orgEditModal: { open: false, data: {} }
        }))
      }
      aria-labelledby='modalOrganizationAdd'
      contentClassName={
        orgEditModal?.fullScreen &&
        'border-0 border-top-left-radius-0 border-top-right-radius-0 h-100'
      }
      dialogClassName={
        orgEditModal?.fullScreen &&
        'position-absolute all-0 fullscreen-modal-dialog scrollbar overflow-x-hidden'
      }>
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Edit Organization: {orgEditModal?.data?.name}{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Edit Organization'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
          <div
            className='d-flex justify-content-end position-absolute right-0 '
            style={{ right: '3rem' }}>
            <FontAwesomeIcon
              icon={
                orgEditModal?.fullScreen
                  ? 'down-left-and-up-right-to-center'
                  : 'up-right-and-down-left-from-center'
              }
              className='me-2 cursor-pointer'
              style={{
                color: isDark ? 'white' : 'black',
                opacity: 0.8
              }}
              onClick={() => {
                setOrganizations(prevState => ({
                  ...prevState,
                  orgEditModal: {
                    open: true,
                    fullScreen: !orgEditModal?.fullScreen,
                    data: orgEditModal?.data
                  }
                }));
              }}
            />
          </div>
        </Modal.Header>

        <Modal.Body className='p-2'>
          <EditOrganizationForm />
        </Modal.Body>
      </Flex>
    </Modal>
  );
};

export default ModalOrganizationEdit;
