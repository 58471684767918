import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useExplore from 'hooks/useExplore';
import React from 'react';
import { Button } from 'react-bootstrap';

/**
 * Renders a refresh button component for the data navigator
 * @returns {JSX.Element} The refresh button component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.4
 */
const RefreshButton = () => {
  const {
    state: { update, timeRangeSelected },
    setState
  } = useExplore();

  const updateDash = () => {
    if (
      timeRangeSelected?.unit !== 'custom' &&
      timeRangeSelected?.unit !== 'DSLR'
    ) {
      const { from, to } = timeRangeSelected.getTimeRange();
      setState('timeRange', { from, to });

      // Set the interval for the selected time range
      const interval = timeRangeSelected.getInterval();
      setState('interval', interval);
    } else {
      setState('update', true);
    }
  };
  return (
    <AdvancedPopover
      placement='bottom'
      showArrow={true}
      popoverText='Refresh the current search'>
      <Button
        onClick={() => {
          updateDash();
        }}
        variant='link'
        className='bg-transparent  px-2 text-700 hover-900'>
        <FontAwesomeIcon
          icon='refresh'
          className=''
          transform='shrink-2'
          spin={update}
        />
      </Button>
    </AdvancedPopover>
  );
};

export default RefreshButton;
