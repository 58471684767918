///
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import OrgProfileBanner from 'components/common/profile-cards/OrganizationProfileCard/Profile';
import UserProfileBanner from 'components/common/profile-cards/UserProfileCard/Profile';
import routes from 'config/routeSettings';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Button, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const { default: CardDropdown } = require('components/common/CardDropdown');
const { default: Flex } = require('components/common/Flex');

/**
 * @param {Function} setOrganizations
 * @param {Boolean} isPrivacyMode
 * @returns {Array}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const getOrganizationColumns = setOrganizations => {
  const columns = [
    {
      accessor: 'name',
      Header: 'Organization',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap'
      },
      Cell: rowData => {
        const { application: { isPrivacyMode } = {} } = useApplication();
        const {
          name,
          id,
          profile: { media: { avatar } = {} } = {},
          legacy
        } = rowData.row.original;
        return (
          <Flex direction='row' alignItems='center'>
            <OverlayTrigger
              placement='top'
              trigger={['click']}
              rootClose={true}
              delay={{ show: 0 }}
              popperConfig={{
                modifiers: [
                  {
                    name: 'offset',
                    enabled: true,
                    options: {
                      offset: [120, 8]
                    }
                  }
                ]
              }}
              overlay={
                <Popover
                  style={{
                    minWidth: '320px',
                    margin: 0
                  }}
                  id={`popover-basic-${id}`}>
                  <Popover.Body className='p-0'>
                    <OrgProfileBanner org={rowData?.row?.original} />
                  </Popover.Body>
                </Popover>
              }>
              {({ ref, ...triggerHandler }) => (
                <Flex
                  direction='row'
                  alignItems='center'
                  {...triggerHandler}
                  className='cursor-pointer'>
                  <div
                    ref={ref}
                    className={`d-inline-flex align-items-center me-2`}
                    style={{ whiteSpace: 'nowrap' }}>
                    <Avatar
                      size='2xl'
                      src={
                        avatar || process.env.REACT_APP_DEFAULT_ORG_AVATAR_IMG
                      }
                    />
                  </div>
                  <span className='fs--1 text-700'>
                    {isPrivacyMode ? legacy?.clientTag : name}{' '}
                    {id === '648371d0a87d7ad0a0367cfa' ? (
                      <FontAwesomeIcon
                        icon={['fas', 'clover']}
                        transform='rotate-45'
                        className='text-primary fs--1'
                      />
                    ) : null}
                  </span>
                </Flex>
              )}
            </OverlayTrigger>
          </Flex>
        );
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap text-center'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap text-center'
      },
      Cell: rowData => {
        const { active } = rowData.row.original;
        return (
          <OverlayTrigger
            placement='top'
            overlay={
              <Popover>
                <Popover.Body className='text-center p-1 fs--1 text-700'>
                  {active ? 'Active' : 'Inactive'}
                </Popover.Body>
              </Popover>
            }>
            <span>
              <FontAwesomeIcon
                icon={active ? 'check-circle' : ['fas', 'ban']}
                className={`text-${active ? 'success' : 'danger'} fs--1`}
              />
            </span>
          </OverlayTrigger>
        );
      }
    },
    {
      accessor: 'parent',
      Header: 'Parent',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-center align-middle text-nowrap'
      },
      Cell: rowData => {
        const { parent } = rowData.row.original;
        const [show, setShow] = React.useState(false);
        return (
          <Flex direction='row' justifyContent='center' alignItems='center'>
            {parent ? (
              <OverlayTrigger
                placement='top'
                trigger={['click']}
                show={show}
                onToggle={() => {
                  setShow(prevState => {
                    return !prevState;
                  });
                }}
                delay={{ show: 1000, hide: 0 }}
                rootClose={true}
                popperConfig={{
                  modifiers: [
                    {
                      name: 'offset',
                      enabled: true,
                      options: {
                        offset: [120, 8]
                      }
                    }
                  ]
                }}
                overlay={
                  <Popover
                    style={{
                      minWidth: '320px',
                      margin: 0
                    }}
                    id={`popover-basic-${rowData?.row?.original?._id}`}>
                    <Popover.Body className='p-0'>
                      <OrgProfileBanner org={parent} mainProfile={false} />
                    </Popover.Body>
                  </Popover>
                }>
                {({ ref, ...triggerHandler }) => (
                  <div
                    {...triggerHandler}
                    className='d-inline-flex align-items-center cursor-pointer'>
                    <Button
                      ref={ref}
                      className={`avatar-popover d-inline-flex align-items-center p-0 me-2 bg-transparent border-0 shadow-none`}
                      style={{ whiteSpace: 'nowrap' }}>
                      <Avatar
                        size='l'
                        src={
                          parent?.profile?.media?.avatar ||
                          process.env.REACT_APP_DEFAULT_ORG_AVATAR_IMG
                        }
                      />
                    </Button>
                  </div>
                )}
              </OverlayTrigger>
            ) : (
              <span className='text-300'>—</span>
            )}
          </Flex>
        );
      }
    },
    {
      accessor: 'primaryOwner',
      Header: 'Owner',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-center align-middle text-nowrap'
      },
      Cell: rowData => {
        const [show, setShow] = React.useState(false);
        const { primaryOwner } = rowData.row.original;
        return (
          <Flex direction='row' justifyContent='center' alignItems='center'>
            {primaryOwner ? (
              <OverlayTrigger
                placement='top'
                trigger={['click']}
                show={show}
                onToggle={() => {
                  setShow(prevState => {
                    return !prevState;
                  });
                }}
                delay={{ show: 1000, hide: 0 }}
                rootClose={true}
                popperConfig={{
                  modifiers: [
                    {
                      name: 'offset',
                      enabled: true,
                      options: {
                        offset: [120, 8]
                      }
                    }
                  ]
                }}
                overlay={
                  <Popover
                    style={{
                      minWidth: '320px',
                      margin: 0
                    }}
                    id={`popover-basic-${rowData?.row?.original?._id}`}>
                    <Popover.Body className='p-0'>
                      <UserProfileBanner
                        user={primaryOwner}
                        mainProfile={false}
                      />
                    </Popover.Body>
                  </Popover>
                }>
                {({ ref, ...triggerHandler }) => (
                  <div
                    {...triggerHandler}
                    className='d-inline-flex align-items-center cursor-pointer'>
                    <Button
                      ref={ref}
                      className={`avatar-popover d-inline-flex align-items-center p-0 me-2 bg-transparent border-0 shadow-none`}
                      style={{ whiteSpace: 'nowrap' }}>
                      <Avatar
                        size='l'
                        src={
                          primaryOwner?.profile?.media?.avatar ||
                          process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG
                        }
                      />
                    </Button>
                  </div>
                )}
              </OverlayTrigger>
            ) : (
              <span className='text-300'>—</span>
            )}
          </Flex>
        );
      }
    },
    {
      accessor: 'owners',
      Header: 'Co-Owners',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-center align-middle text-nowrap'
      },
      Cell: rowData => {
        const { owners } = rowData.row.original;
        let displayedOwners = owners?.slice(0, 3);
        let remainingOwners = owners?.length - 3 || 0;
        const [show, setShow] = React.useState(
          displayedOwners.map(user => {
            return {
              id: user.id,
              show: false
            };
          })
        );
        return (
          <Flex direction='row' justifyContent='center' alignItems='center'>
            {owners.length > 0 ? (
              <>
                {displayedOwners?.map(owner => (
                  <OverlayTrigger
                    key={owner.id}
                    placement='top'
                    trigger={['click']}
                    show={show.find(u => u.id === owner.id)?.show}
                    onToggle={nextShow => {
                      setShow(prevState => {
                        return prevState.map(u => {
                          if (u.id === owner.id) {
                            return {
                              ...u,
                              show: nextShow
                            };
                          } else {
                            return u;
                          }
                        });
                      });
                    }}
                    delay={{ show: 1000, hide: 0 }}
                    rootClose={true}
                    popperConfig={{
                      modifiers: [
                        {
                          name: 'offset',
                          enabled: true,
                          options: {
                            offset: [120, 8]
                          }
                        }
                      ]
                    }}
                    overlay={
                      <Popover
                        style={{
                          minWidth: '320px',
                          margin: 0
                        }}
                        id={`popover-basic-${owner.id}`}>
                        <Popover.Body className='p-0'>
                          <UserProfileBanner user={owner} mainProfile={false} />
                        </Popover.Body>
                      </Popover>
                    }>
                    {({ ref, ...triggerHandler }) => (
                      <div
                        {...triggerHandler}
                        className='d-inline-flex align-items-center cursor-pointer'>
                        <Button
                          ref={ref}
                          className={`avatar-popover d-inline-flex align-items-center p-0 me-2 bg-transparent border-0 shadow-none`}
                          style={{ whiteSpace: 'nowrap' }}>
                          <Avatar
                            size='l'
                            src={
                              owner?.profile?.media?.avatar ||
                              process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </OverlayTrigger>
                ))}
                {remainingOwners > 0 && (
                  <span className='ms-0'>
                    <small>+ {remainingOwners} more</small>
                  </span>
                )}
              </>
            ) : (
              <span className='text-300'>—</span>
            )}
          </Flex>
        );
      }
    },
    {
      accessor: 'users',
      Header: 'Users',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-center align-middle text-nowrap'
      },
      Cell: rowData => {
        const { users } = rowData.row.original;
        const [show, setShow] = React.useState(
          users.map(user => {
            return {
              id: user.id,
              show: false
            };
          })
        );
        let displayedUsers = users?.slice(0, 3);
        let remainingUsers = users?.length - 3;
        return (
          <Flex direction='row' justifyContent='center' alignItems='center'>
            {users.length > 0 ? (
              <>
                {displayedUsers?.map(user => (
                  <OverlayTrigger
                    key={user.id}
                    placement='top'
                    trigger={['click']}
                    show={show.find(u => u.id === user.id)?.show}
                    onToggle={nextShow => {
                      setShow(prevState => {
                        return prevState.map(u => {
                          if (u.id === user.id) {
                            return {
                              ...u,
                              show: nextShow
                            };
                          } else {
                            return u;
                          }
                        });
                      });
                    }}
                    delay={{ show: 1000, hide: 0 }}
                    rootClose={true}
                    popperConfig={{
                      modifiers: [
                        {
                          name: 'offset',
                          enabled: true,
                          options: {
                            offset: [120, 8]
                          }
                        }
                      ]
                    }}
                    overlay={
                      <Popover
                        style={{
                          minWidth: '320px',
                          margin: 0
                        }}
                        id={`popover-basic-${user.id}`}>
                        <Popover.Body className='p-0'>
                          <UserProfileBanner user={user} mainProfile={false} />
                        </Popover.Body>
                      </Popover>
                    }>
                    {({ ref, ...triggerHandler }) => (
                      <div
                        {...triggerHandler}
                        className='d-inline-flex align-items-center cursor-pointer'>
                        <Button
                          ref={ref}
                          className={`avatar-popover d-inline-flex align-items-center p-0 me-2 bg-transparent border-0 shadow-none`}
                          style={{ whiteSpace: 'nowrap' }}>
                          <Avatar
                            size='l'
                            src={
                              user?.profile?.media?.avatar ||
                              process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </OverlayTrigger>
                ))}
                {remainingUsers > 0 && (
                  <span className='ms-0'>
                    <small>+ {remainingUsers} more</small>
                  </span>
                )}
              </>
            ) : (
              <span className='text-300'>—</span>
            )}
          </Flex>
        );
      }
    },
    {
      accessor: 'subscription.isActive',
      sortType: 'basic',
      Header: 'Subscription',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 align-middle text-nowrap text-center'
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle text-center'
      },
      Cell: rowData => {
        let { subscription } = rowData.row.original;
        return (
          <>
            {subscription ? (
              <OverlayTrigger
                placement='top'
                overlay={
                  <Popover>
                    <Popover.Body className='text-center p-1 fs--1 text-700'>
                      Subscription Active
                    </Popover.Body>
                  </Popover>
                }>
                <span>
                  <FontAwesomeIcon
                    icon='check-circle'
                    className='text-success fs--1'
                  />
                </span>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement='top'
                overlay={
                  <Popover>
                    <Popover.Body className='text-center p-1 fs--1 text-700'>
                      Subscription Inactive
                    </Popover.Body>
                  </Popover>
                }>
                <span>
                  <FontAwesomeIcon
                    icon={['fas', 'warning']}
                    className='text-secondary fs--1'
                  />
                </span>
              </OverlayTrigger>
            )}
          </>
        );
      }
    },
    {
      accessor: 'leargas_actions',
      Header: '',
      Cell: rowData => {
        let { isDeleted } = rowData.row.original;
        return (
          <Flex
            justifyContent='end'
            alignContent='middle'
            className='table-dropdown-columns'>
            <CardDropdown>
              <div className='py-2'>
                <Dropdown.Item
                  as={Link}
                  to={`${routes.ORGANIZATIONS}/${rowData?.row?.original?.id}`}
                  className='text-decoration-none text-900'>
                  <FontAwesomeIcon icon='id-card' className='me-2' />
                  View Profile
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    setOrganizations(prevState => ({
                      ...prevState,
                      orgEditModal: { open: true, data: rowData.row.original }
                    }))
                  }>
                  <FontAwesomeIcon icon='pencil' className='me-2' />
                  Edit Details
                </Dropdown.Item>
                <div className='dropdown-divider'></div>
                <Dropdown.Item
                  className={`${!isDeleted ? 'text-danger' : 'text-warning'}`}
                  onClick={() => {
                    isDeleted
                      ? setOrganizations(prevState => ({
                          ...prevState,
                          orgRestoreModal: {
                            open: true,
                            data: rowData.row.original
                          }
                        }))
                      : setOrganizations(prevState => ({
                          ...prevState,
                          orgDeleteModal: {
                            open: true,
                            data: rowData.row.original
                          }
                        }));
                  }}>
                  <FontAwesomeIcon
                    icon={isDeleted ? 'trash-restore-alt' : 'trash'}
                    className='me-2'
                  />
                  {isDeleted ? 'Restore' : 'Delete'}
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </Flex>
        );
      },
      cellProps: {
        className: 'text-end text-400 actions-cell',
        style: {
          width: '42px'
        }
      },
      hideDisplaySettings: true,
      disableSortBy: true
    }
  ];

  return columns;
};
