import AccountProvider from 'context/AccountProvider';
import ApplicationProvider from 'context/ApplicationProvider';
import AuthenticationProvider from 'context/AuthenticationProvider';
import { AxiosProvider } from 'context/AxiosProvider';
import ESGlobalFiltersProvider from 'context/ESGlobalFiltersProvider';
import { UnsavedChangesProvider } from 'context/UnsavedChangesProvider';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * MainProvider component.
 *
 * @component
 * @param {Object} props - The component props
 * @param {ReactNode} props.children - The child components
 * @returns {ReactNode} The rendered component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
const MainProvider = ({ children }) => {
  return (
    <ApplicationProvider>
      <AuthenticationProvider>
        <AxiosProvider>
          <AccountProvider>
            <UnsavedChangesProvider>
              <ESGlobalFiltersProvider>{children}</ESGlobalFiltersProvider>
            </UnsavedChangesProvider>
          </AccountProvider>
        </AxiosProvider>
      </AuthenticationProvider>
    </ApplicationProvider>
  );
};

MainProvider.propTypes = {
  children: PropTypes.node
};

export default MainProvider;
