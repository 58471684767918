import LeargasAPI from 'api';
import CreatableMultiselect from 'components/common/CreatableMultiselect';
import Flex from 'components/common/Flex';
import TinymceEditor from 'components/common/TinymceEditor';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DatePickerInput } from 'components/common/datepicker';
import useArticles from 'hooks/admin-contexts/useArticles';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

/**
 * @param {Boolean} hasLabel
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticleAddForm = hasLabel => {
  const {
    articles: { tags, categories },
    setArticles
  } = useArticles();
  const { register, handleSubmit, setValue } = useForm();

  const { axiosPrivate } = useAxiosPrivate();
  const [selfControlData, setSelfControlData] = useState({
    tags: [],
    tagCreationLoader: false,
    publishedAt: ''
  });
  const [loading, setLoading] = useState(false);

  /**
   *
   * @param {Object} data
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const onSubmitData = async data => {
    let newData = { ...data };
    let { publishedAt } = selfControlData;
    if (selfControlData.tags)
      newData['tags'] = selfControlData?.tags?.map(tag => tag?.value);
    if (selfControlData.publishedAt) newData['publishedAt'] = publishedAt;
    try {
      setLoading(true);
      let res = await LeargasAPI.AppArticles.createAppArticle(
        newData,
        axiosPrivate
      );
      if (res instanceof Error) throw res;
      const { message } = res;
      toast.success(message);
      setLoading(false);
      setArticles(prevState => ({
        ...prevState,
        fetch: true,

        addArticleModal: { open: false }
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
      setLoading(false);
    }
  };

  /**
   *
   * @param {Array} tagsIn
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const handleTagsChange = tagsIn => {
    if (tagsIn?.length > 0) {
      setSelfControlData(prevState => ({
        ...prevState,
        tags: tagsIn
      }));
    } else {
      setSelfControlData(prevState => ({
        ...prevState,
        tags: []
      }));
    }
  };

  /**
   *
   * @param {string} param
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const createTagSelect = async param => {
    try {
      setSelfControlData(prevState => ({
        ...prevState,
        tagCreationLoader: true
      }));
      let res = await LeargasAPI.AppArticles.createAppArticleTag(
        {
          tag: param
        },
        axiosPrivate
      );
      if (res instanceof Error) throw res;

      let newTags = [...selfControlData.tags];
      let { id, tag } = res.data;
      newTags.push({
        value: id,
        label: tag
      });
      setSelfControlData(prevState => ({
        ...prevState,
        tagCreationLoader: false,
        tags: newTags
      }));
      setArticles(prevState => ({
        ...prevState,
        fetch: true
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
      setSelfControlData(prevState => ({
        ...prevState,
        tagCreationLoader: false
      }));
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmitData)}>
      <Row>
        <Form.Group as={Col} sm={12} className='required mb-3'>
          <Form.Label>Title</Form.Label>
          <Form.Control
            placeholder={'title'}
            {...register('title')}
            type='text'
            autoComplete='off'
            className='fs--1'
            style={{ minHeight: '36px' }}
            required
          />
        </Form.Group>
      </Row>
      <Row className='g-2 mb-3'>
        <Form.Group as={Col} sm={9}>
          {hasLabel && <Form.Label className='mb-0'>Tags</Form.Label>}
          <CreatableMultiselect
            className='fs--1'
            isMulti
            isClearable
            isSearchable
            placeholder='Please select'
            options={tags.map(tag => ({
              value: tag.id,
              label: tag.tag
            }))}
            onCreateOption={createTagSelect}
            isDisabled={selfControlData.tagCreationLoader}
            value={selfControlData.tags}
            isLoading={selfControlData.tagCreationLoader}
            onChange={handleTagsChange}
            style={{ minHeight: '36px' }}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          sm={3}
          className='mb-0 d-flex  align-items-end'
          style={{ alignItems: 'end' }}>
          <Flex alignContent='center' justifyContent='center'>
            <Form.Check
              type='switch'
              {...register('featured')}
              name='featured'
            />
            <Form.Label>Featured</Form.Label>
          </Flex>
        </Form.Group>
        <Form.Group as={Col} sm={4} className='required'>
          <Form.Label>Category</Form.Label>
          <Form.Select
            name='primaryOwner'
            size='sm'
            {...register('category')}
            className='fs--1'
            required
            style={{ minHeight: '36px' }}>
            <option value=''>Please select</option>
            {categories.map((category, index) => {
              return (
                <option key={index} value={category.id}>
                  {category.title}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} sm={4}>
          <Form.Label>Status</Form.Label>
          <Form.Select
            name='primaryOwner'
            {...register('status')}
            size='sm'
            className='fs--1'
            style={{ minHeight: '36px' }}>
            <option value=''>Please select</option>
            <option value={'draft'}>Draft</option>
            <option value={'published'}>Published</option>
            <option value={'archived'}>Archived</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} sm={4}>
          <Form.Label className='fs--1'>Publish Date</Form.Label>
          <DatePickerInput
            onChange={date =>
              setSelfControlData(prevState => ({
                ...prevState,
                publishedAt: date
              }))
            }
            selfControlData={selfControlData}
          />
        </Form.Group>
      </Row>
      <div className='border border-0 border-200'>
        <Form.Group as={Col} sm={12} className='required'>
          <Form.Label className='mb-0'>Content</Form.Label>
          <TinymceEditor
            handleChange={newValue => setValue('content', newValue)}
          />
        </Form.Group>
      </div>

      <Flex justifyContent='end' className={'mt-3'}>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() =>
            setArticles(prevState => ({
              ...prevState,
              addArticleModal: { open: false }
            }))
          }>
          Cancel
        </Button>

        <Button variant='success' type='submit' size='sm' className='d-flex'>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Submitting' : 'Submit'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

ArticleAddForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default ArticleAddForm;
