/* eslint-disable react/prop-types */
import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {
  useExpanded,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import IndeterminateCheckbox from './IndeterminateCheckbox';

/**
 * A checkbox component that supports indeterminate state
 *
 * @param {Object} props - The properties for the IndeterminateCheckbox component
 * @param {boolean} props.indeterminate - Whether the checkbox should be in indeterminate state
 * @param {React.Ref} props.ref - The ref object to be forwarded to the checkbox input element
 * @returns {JSX.Element} The rendered IndeterminateCheckbox component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

/**
 * Renders the AlertsTableWrapper component
 * @param {object} props
 * @param {object} props.children
 * @param {Array} props.columns
 * @param {Array} props.data
 * @param {boolean} props.sortable
 * @param {boolean} props.selection
 * @param {Number} props.selectionColumnWidth
 * @param {Boolean} props.pagination
 * @param {Number} props.perPage
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const AlertsTableWrapper = ({
  children,
  columns,
  data,
  sortable,
  selection,
  selectionHidden = false,
  selectionColumnWidth,
  pagination,
  perPage = 10
}) => {
  const memoizedColumns = useMemo(() => {
    return columns;
  }, [columns]);
  const memoizedData = useMemo(() => {
    return data;
  }, [data]);
  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    pageCount,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
    setGlobalFilter
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetSelectedRows: false,
      autoResetGlobalFilter: false,
      autoResetPage: false,
      disableSortBy: !sortable,
      initialState: { pageSize: pagination ? perPage : data?.length }
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    hooks => {
      if (selection) {
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox
                selectionHidden={selectionHidden}
                {...getToggleAllRowsSelectedProps()}
              />
            ),
            headerProps: {
              style: {
                maxWidth: selectionColumnWidth
              }
            },
            cellProps: {
              style: {
                maxWidth: selectionColumnWidth
              }
            },
            Cell: ({ row }) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}>
                {/* Checkbox for row selection */}
                <IndeterminateCheckbox
                  selectionHidden={selectionHidden}
                  {...row.getToggleRowSelectedProps()}
                />

                {/* Chevron for row expansion */}
                <FontAwesomeIcon
                  icon={row.isExpanded ? faChevronDown : faChevronRight}
                  className='cursor-pointer mt-2' // Adjust margin for spacing
                  onClick={() => row.toggleRowExpanded()}
                  transform={'shrink-2 up-5 fs--2'}
                  width={12}
                />
              </div>
            )
          },
          ...columns
        ]);
      }
    }
  );

  const recursiveMap = children => {
    return React.Children.map(children, child => {
      if (child.props?.children) {
        return React.cloneElement(child, {
          children: recursiveMap(child.props.children)
        });
      } else {
        if (child.props?.table) {
          return React.cloneElement(child, {
            ...child.props,
            getTableProps,
            headers,
            page,
            prepareRow,
            canPreviousPage,
            canNextPage,
            nextPage,
            previousPage,
            gotoPage,
            pageCount,
            pageIndex,
            selectedRowIds,
            pageSize,
            setPageSize,
            globalFilter,
            setGlobalFilter
          });
        } else {
          return child;
        }
      }
    });
  };

  return <>{recursiveMap(children)}</>;
};

AlertsTableWrapper.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.array,
  data: PropTypes.array,
  sortable: PropTypes.bool,
  selection: PropTypes.bool,
  selectionColumnWidth: PropTypes.number,
  pagination: PropTypes.bool,
  perPage: PropTypes.number
};

export default AlertsTableWrapper;
