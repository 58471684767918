import { axiosPrivate, axiosPublic } from 'api/connection/axios';

/**
 * @description Account Auth API
 * @summary Provides methods for authenticating a user's account and managing their session
 * @method login Login to a user's account
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
class AccountAuth {
  constructor(route) {
    this.paths = route;
  }

  /**
   * @description Log in to a user's account
   * @param {object} formData - The form data to submit
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * AccountAuth.userSessionLogin({
   *   emailAddress: '',
   *   password: ''
   * });
   */
  async userSessionLogin(request) {
    try {
      return await axiosPublic.instance.post(
        this.paths.login.path,
        JSON.stringify(request.body)
      );
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Lock the current user's session
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * AccountAuth.userSessionLock();
   */
  async userSessionLock(axiosPrivate) {
    try {
      return await axiosPrivate.get(this.paths.lock.path);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Unlock the current user's session
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * AccountAuth.userSessionUnlock();
   */
  async userSessionUnlock(request, axiosPrivate) {
    try {
      return await axiosPrivate.post(
        this.paths.unlock.path,
        JSON.stringify(request.body)
      );
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Verify the current user's multifactor authentication code
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * AccountAuth.userSessionVerify({ body: { code: '' } });
   */
  async userSessionVerify(request, axiosPrivate) {
    try {
      return await axiosPrivate.post(
        this.paths.verify.path,
        JSON.stringify(request.body)
      );
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Refresh the current user's access token and session
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * AccountAuth.refresh();
   */
  async refresh() {
    try {
      const res = await axiosPrivate.instance.get(this.paths.refresh.path);
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Log out of the current user's session
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * AccountAuth.logout();
   */
  async logout(axiosPrivate) {
    try {
      return await axiosPrivate.get(this.paths.logout.path);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default AccountAuth;
