import Flex from 'components/common/Flex';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useJobs from 'hooks/admin-contexts/useJobs';
import useApplication from 'hooks/useApplication';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useEffect, useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { getJobColumns } from './Columns/ColumnsDefaults';
import JobsTable from './JobsTable';
import JobsTableFilters from './JobsTableFilters';
import JobsTableFooter from './JobsTableFooter';
import JobsTablePagination from './JobsTablePagination';
import JobsTableWrapper from './JobsTableWrapper';

/**
 * Jobs list component
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const Jobs = () => {
  const {
    application: { isPrivacyMode }
  } = useApplication();

  const {
    jobs: { data, loading },
    loading: componentLoading
  } = useJobs();

  const [globalFilter, setGlobalFilter] = useState('');
  const [columns, setColumns] = useState([]);
  const { axiosPrivate } = useAxiosPrivate();
  /**
   * Set columns on privacy mode change
   */
  useEffect(() => {
    setColumns(getJobColumns(isPrivacyMode, axiosPrivate));
  }, [isPrivacyMode]);

  /**
   * Set columns on mount
   */
  useEffect(() => {
    setColumns(getJobColumns(isPrivacyMode, axiosPrivate));
  }, []);

  return (
    <Card className='shadow-none border'>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          <span className='d-block'>Jobs</span>
        </Flex>
      </Card.Header>

      <Card.Body className='position-relative'>
        {componentLoading ? (
          <LoadingSpinner grow='10' />
        ) : (
          <Tabs
            defaultActiveKey='allRecurring'
            id='uncontrolled-tab-example'
            className='border-0 py-1'>
            <Tab
              eventKey='allRecurring'
              title='All Recurring'
              className='border-none mt-0 py-0 px-0'
              tabClassName='fs--1 fw-normal ps-0 pe-0 me-3'>
              <JobsTableWrapper
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columns={columns}
                data={data.filter(job => job?.repeatInterval)}
                sortable
                loading={loading}
                pagination
                perPage={25}>
                <JobsTableFilters
                  table
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                <JobsTable table columns={columns} />
                <div className='mt-3 d-flex justify-content-between'>
                  <JobsTableFooter
                    table
                    rowInfo
                    data={data.filter(job => job?.repeatInterval)}
                    rowCount={data.filter(job => job?.repeatInterval)?.length}
                  />
                  <JobsTablePagination table />
                </div>
              </JobsTableWrapper>
            </Tab>
            <Tab
              eventKey='cleanup'
              title='Cleanup'
              className='border-none mt-0 py-0 px-0'
              tabClassName='fs--1 fw-normal ps-0 pe-0 me-3'>
              <JobsTableWrapper
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columns={columns}
                data={data.filter(job => job?.category === 'cleanup')}
                sortable
                loading={loading}
                pagination
                perPage={25}>
                <JobsTableFilters
                  table
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                <JobsTable table columns={columns} />
                <div className='mt-3 d-flex justify-content-between'>
                  <JobsTableFooter
                    table
                    rowInfo
                    data={data.filter(job => job?.category === 'cleanup')}
                    rowCount={
                      data.filter(job => job?.category === 'cleanup')?.length
                    }
                  />
                  <JobsTablePagination table />
                </div>
              </JobsTableWrapper>
            </Tab>
            <Tab
              eventKey='email'
              title='Emails'
              className='border-none mt-0 py-0 px-0'
              tabClassName='fs--1 fw-normal ps-0 pe-0 me-3'>
              <JobsTableWrapper
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columns={columns}
                data={data.filter(job => job?.category === 'email')}
                sortable
                loading={loading}
                pagination
                perPage={25}>
                <JobsTableFilters
                  table
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                <JobsTable table columns={columns} />
                <div className='mt-3 d-flex justify-content-between'>
                  <JobsTableFooter
                    table
                    rowInfo
                    data={data.filter(job => job?.category === 'email')}
                    rowCount={
                      data.filter(job => job?.category === 'email')?.length
                    }
                  />
                  <JobsTablePagination table />
                </div>
              </JobsTableWrapper>
            </Tab>
            <Tab
              eventKey='report'
              title='Reports'
              className='border-none mt-0 py-0 px-0'
              tabClassName='fs--1 fw-normal ps-0 pe-0 me-3'>
              <JobsTableWrapper
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columns={columns}
                data={data.filter(job => job?.category === 'report')}
                sortable
                loading={loading}
                pagination
                perPage={25}>
                <JobsTableFilters
                  table
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                <JobsTable table columns={columns} />
                <div className='mt-3 d-flex justify-content-between'>
                  <JobsTableFooter
                    table
                    rowInfo
                    data={data.filter(job => job?.category === 'report')}
                    rowCount={
                      data.filter(job => job?.category === 'report')?.length
                    }
                  />
                  <JobsTablePagination table />
                </div>
              </JobsTableWrapper>
            </Tab>
            <Tab
              eventKey='ingest'
              title='Ingest'
              className='border-none mt-0 py-0 px-0'
              tabClassName='fs--1 fw-normal ps-0 pe-0 me-3'>
              <Tabs
                defaultActiveKey='agents'
                id='uncontrolled-tab-example'
                className='border-0 py-1'>
                <Tab
                  eventKey='agents'
                  title='Agents'
                  className='border-none mt-0 py-0 px-0'
                  tabClassName='fs--1 fw-normal ps-0 pt-0 pe-0 me-3'>
                  <JobsTableWrapper
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    columns={columns}
                    data={data.filter(
                      job =>
                        job?.category === 'ingest' &&
                        job?.subcategory === 'agents'
                    )}
                    sortable
                    loading={loading}
                    pagination
                    perPage={25}>
                    <JobsTableFilters
                      table
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                    <JobsTable table columns={columns} />
                    <div className='mt-3 d-flex justify-content-between'>
                      <JobsTableFooter
                        table
                        rowInfo
                        data={data.filter(
                          job =>
                            job?.category === 'ingest' &&
                            job?.subcategory === 'agents'
                        )}
                        rowCount={
                          data.filter(
                            job =>
                              job?.category === 'ingest' &&
                              job?.subcategory === 'agents'
                          )?.length
                        }
                      />
                      <JobsTablePagination table />
                    </div>
                  </JobsTableWrapper>
                </Tab>
                <Tab
                  eventKey='conductors'
                  title='Conductors'
                  className='border-none mt-0 py-0 px-0'
                  tabClassName='fs--1 fw-normal ps-0 pt-0 pe-0 me-3'>
                  <JobsTableWrapper
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    columns={columns}
                    data={data.filter(
                      job =>
                        job?.category === 'ingest' &&
                        job?.subcategory === 'conductors'
                    )}
                    sortable
                    loading={loading}
                    pagination
                    perPage={25}>
                    <JobsTableFilters
                      table
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                    <JobsTable table columns={columns} />
                    <div className='mt-3 d-flex justify-content-between'>
                      <JobsTableFooter
                        table
                        rowInfo
                        data={data.filter(
                          job =>
                            job?.category === 'ingest' &&
                            job?.subcategory === 'conductors'
                        )}
                        rowCount={
                          data.filter(
                            job =>
                              job?.category === 'ingest' &&
                              job?.subcategory === 'conductors'
                          )?.length
                        }
                      />
                      <JobsTablePagination table />
                    </div>
                  </JobsTableWrapper>
                </Tab>
                <Tab
                  eventKey='other'
                  title='Other'
                  className='border-none mt-0 py-0 px-0'
                  tabClassName='fs--1 fw-normal ps-0 pt-0 pe-0 me-3'>
                  <JobsTableWrapper
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    columns={columns}
                    data={data.filter(
                      job =>
                        job?.category === 'ingest' &&
                        job?.subcategory !== 'agents' &&
                        job?.subcategory !== 'conductors'
                    )}
                    sortable
                    loading={loading}
                    pagination
                    perPage={25}>
                    <JobsTableFilters
                      table
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                    <JobsTable table columns={columns} />
                    <div className='mt-3 d-flex justify-content-between'>
                      <JobsTableFooter
                        table
                        rowInfo
                        data={data.filter(
                          job =>
                            job?.category === 'ingest' &&
                            job?.subcategory !== 'agents' &&
                            job?.subcategory !== 'conductors'
                        )}
                        rowCount={
                          data.filter(
                            job =>
                              job?.category === 'ingest' &&
                              job?.subcategory !== 'agents' &&
                              job?.subcategory !== 'conductors'
                          )?.length
                        }
                      />
                      <JobsTablePagination table />
                    </div>
                  </JobsTableWrapper>
                </Tab>
              </Tabs>
            </Tab>
            <Tab
              eventKey='monitor'
              title='Monitors'
              className='border-none mt-0 py-0 px-0'
              tabClassName='fs--1 fw-normal ps-0 pe-0 me-3'>
              <JobsTableWrapper
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columns={columns}
                data={data.filter(job => job?.category === 'monitor')}
                sortable
                loading={loading}
                pagination
                perPage={25}>
                <JobsTableFilters
                  table
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                <JobsTable table columns={columns} />
                <div className='mt-3 d-flex justify-content-between'>
                  <JobsTableFooter
                    table
                    rowInfo
                    data={data.filter(job => job?.category === 'monitor')}
                    rowCount={
                      data.filter(job => job?.category === 'monitor')?.length
                    }
                  />
                  <JobsTablePagination table />
                </div>
              </JobsTableWrapper>
            </Tab>
            <Tab
              eventKey='notification'
              title='Notifications'
              className='border-none mt-0 py-0 px-0'
              tabClassName='fs--1 fw-normal ps-0 pe-0 me-3'>
              <JobsTableWrapper
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columns={columns}
                data={data.filter(job => job?.category === 'notification')}
                sortable
                loading={loading}
                pagination
                perPage={25}>
                <JobsTableFilters
                  table
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                <JobsTable table columns={columns} />
                <div className='mt-3 d-flex justify-content-between'>
                  <JobsTableFooter
                    table
                    rowInfo
                    data={data.filter(job => job?.category === 'notification')}
                    rowCount={
                      data.filter(job => job?.category === 'notification')
                        ?.length
                    }
                  />
                  <JobsTablePagination table />
                </div>
              </JobsTableWrapper>
            </Tab>
            <Tab
              eventKey='sync'
              title='Syncs'
              className='border-none mt-0 py-0 px-0'
              tabClassName='fs--1 fw-normal ps-0 pe-0 me-3'>
              <JobsTableWrapper
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columns={columns}
                data={data.filter(job => job?.category === 'sync')}
                sortable
                loading={loading}
                pagination
                perPage={25}>
                <JobsTableFilters
                  table
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                <JobsTable table columns={columns} />
                <div className='mt-3 d-flex justify-content-between'>
                  <JobsTableFooter
                    table
                    rowInfo
                    data={data.filter(job => job?.category === 'sync')}
                    rowCount={
                      data.filter(job => job?.category === 'sync')?.length
                    }
                  />
                  <JobsTablePagination table />
                </div>
              </JobsTableWrapper>
            </Tab>
          </Tabs>
        )}
      </Card.Body>
    </Card>
  );
};

export default Jobs;
