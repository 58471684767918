import PropTypes from 'prop-types';
import React from 'react';
import OrganizationsProvider from '../../../context/user-contexts/UserOrganizationsProvider';
import OrganizationsLayout from './OrganizationsLayout';

/**
 * Organizations wrapper
 *  @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const OrganizationsWrapper = props => {
  return (
    <OrganizationsProvider>
      {props.children}
      <OrganizationsLayout />
      {/* <ModalOrganizationAdd /> */}
    </OrganizationsProvider>
  );
};

OrganizationsWrapper.propTypes = { children: PropTypes.node };

export default OrganizationsWrapper;
