import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import JobsTableSearchBox from './JobsTableSearchBox';

/**
 * Renders the JobsTableFilters component
 *
 * @param {Object} props - The component props
 * @param {string} props.globalFilter - The global filter value
 * @param {function} props.setGlobalFilter - The function to set the global filter value
 * @returns {JSX.Element} The rendered JobsTableFilters component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const JobsTableFilters = ({ globalFilter, setGlobalFilter }) => {
  return (
    <>
      <Flex>
        <JobsTableSearchBox
          table
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Flex>
    </>
  );
};

JobsTableFilters.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func
};

export default JobsTableFilters;
