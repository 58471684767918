import { useContext } from 'react';
import { ESGlobalFiltersContext } from 'context/ESGlobalFiltersProvider';

/**
 * Custom hook that returns the global filters from the ESGlobalFiltersContext
 *
 * @returns {Object} The global filters object
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
export const useESGlobalFilters = () => {
  return useContext(ESGlobalFiltersContext);
};

export default useESGlobalFilters;
