import LeargasAPI from 'api';
import { releasesSettings } from 'config/releasesSettings';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

/**
 * Create ReleasesContext
 * @type {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const ReleasesContext = createContext();

/**
 * Create ReleasesProvider
 * @param props
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 *  <ReleasesProvider>
 *   {children}
 * </ReleasesProvider>
 * )
 */

const ReleasesProvider = props => {
  const [releases, setReleases] = useState(releasesSettings);
  const [loading, setLoading] = useState(true);
  const { axiosPrivate } = useAxiosPrivate();

  /**
   * Get all application releases
   *
   * @returns {Promise<void>}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  const getAppReleases = async () => {
    try {
      setReleases(prevState => ({
        ...prevState,
        loading: true
      }));
      const res = await LeargasAPI.Releases.getAppReleases(axiosPrivate);
      if (res instanceof Error) throw res;
      setReleases(prevState => ({
        ...prevState,
        releases: res?.data
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setReleases(prevState => ({
        ...prevState,
        loading: false,
        fetch: false
      }));
    } finally {
      setReleases(prevState => ({
        ...prevState,
        loading: false,
        fetch: false
      }));
      setLoading(false);
    }
  };

  /**
   * Get all application releases on fetch state change
   */
  useEffect(() => {
    if (!releases.loading && releases.fetch) getAppReleases();
  }, [releases.fetch]);

  /**
   * Get all application releases on component mount
   */
  useEffect(() => {
    getAppReleases();
  }, []);

  return (
    <ReleasesContext.Provider
      value={{
        ...releases,
        setReleases,
        loading
      }}>
      {props.children}
    </ReleasesContext.Provider>
  );
};

ReleasesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ReleasesProvider;
