import { makeRequest } from 'api/connection/requestAction';

class Indices {
  constructor(route) {
    this.route = route;
  }

  async getIndices(axiosPrivate, enabledOnly = false) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getIndices.method,
        this.route.getIndices.path + (enabledOnly ? '?enabledOnly=true' : '')
      );
      if (res instanceof Error) throw res;
      return res?.data?.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async updateIndices(data, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateIndices.method,
        this.route.updateIndices.path,
        data
      );
      if (res instanceof Error) throw res;
      return res?.data?.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default Indices;
