import classNames from 'classnames';
import LoadingIndicatorOverlay from 'components/visualizations/LoadingIndicatorOverlay';
import useExplore from 'hooks/useExplore';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Flex from '../Flex';
import ExploreTableBase from './ExploreTableBase';
import ExploreTableFooter from './ExploreTableFooter';
import ExploreTableWrapper from './ExploreTableWrapper';

/**
 * ExploreTable component.
 *
 * This component is a wrapper for the ExploreTable component set.
 * It provides a table view for the Explore layout.
 *
 * @component
 * @param {string} className - CSS class name for the component
 * @returns {JSX.Element} - The rendered ExploreTable component
 *
 * @since 0.1.0-beta.5
 */
const ExploreTable = ({ className }) => {
  const {
    state: {
      fieldsSelected = [],
      fieldsSelectedFallback = [],
      documents = [],
      documentsLoading,
      documentsScrollLoading,
      documentsTotal,
      pagination: { pageIndex, pageSize }
    },
    setState
  } = useExplore();

  const [tableFields, setTableFields] = useState([]);

  /**
   * Updates the table fields when the selected fields change.
   * If the selected fields are fewer than the fallback fields, the fallback fields are used.
   */
  useEffect(() => {
    if (fieldsSelected.length <= fieldsSelectedFallback.length) {
      setTableFields(fieldsSelectedFallback);
    } else {
      setTableFields(
        fieldsSelected.filter(field => field?.id && field.id !== '_source')
      );
    }
  }, [fieldsSelected, fieldsSelectedFallback]);

  /**
   * Renders the footer of the ExploreTable component.
   */
  const Footer = ({ pageSize, documentsScrollLoading, documentsTotal }) => (
    <Flex direction='row' justifyContent='between'>
      <ExploreTableFooter
        table
        pageSize={pageSize}
        loading={documentsScrollLoading}
        documentsTotal={documentsTotal}
        documentsShowing={documents.length}
        totalPages={Math.ceil(documentsTotal / pageSize)}
        rowsPerPageOptions={[50, 100, 250]}
        rowsPerPageSelection
      />
    </Flex>
  );

  Footer.propTypes = {
    pageSize: PropTypes.number,
    documentsScrollLoading: PropTypes.bool,
    documentsTotal: PropTypes.number
  };

  return (
    <Card className={classNames('p-2 shadow-none border', className)}>
      <Card.Body className='p-0 position-relative'>
        <LoadingIndicatorOverlay loading={documentsLoading} position='top' />
        <ExploreTableWrapper
          columns={tableFields}
          currentPage={pageIndex}
          data={documents}
          perPage={pageSize}
          selection={true}
          sortable={true}
          total={documentsTotal}>
          <Footer
            pageSize={pageSize}
            documentsScrollLoading={documentsScrollLoading}
            documentsTotal={documentsTotal}
          />
          <ExploreTableBase
            table
            page={documents} // Pass documents correctly here
            setExploreState={setState}
            tableProps={{
              className: 'my-2 border-top',
              style: { minHeight: '100px' }
            }}
          />
          <Footer
            pageSize={pageSize}
            documentsScrollLoading={documentsScrollLoading}
            documentsTotal={documentsTotal}
          />
        </ExploreTableWrapper>
      </Card.Body>
    </Card>
  );
};

ExploreTable.propTypes = {
  className: PropTypes.string
};

export default ExploreTable;
