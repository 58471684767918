import FalconCardHeader from 'components/common/FalconCardHeader';
import useAccount from 'hooks/useAccount';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';

const ProfileSettings = () => {
  const { account } = useAccount();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    jobTitle: '',
    bio: ''
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  useEffect(() => {
    setFormData({
      firstName: account?.name?.first || '',
      lastName: account?.name?.last || '',
      email: account?.profile?.email || '',
      phone: account?.profile?.phone || '',
      jobTitle: account?.profile?.jobTitle || '',
      bio: account?.profile?.bio || ''
    });
  }, [account]);

  return (
    <Card className='shadow-none border'>
      <FalconCardHeader title='Basic Information' />
      <Card.Body className='bg-light'>
        <Form onSubmit={handleSubmit}>
          <Row className='mb-3 g-3'>
            <Form.Group as={Col} lg={6} controlId='firstName'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='First Name'
                value={formData.firstName}
                name='firstName'
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId='lastName'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Last Name'
                value={formData.lastName}
                name='lastName'
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className='mb-3 g-3'>
            <Form.Group as={Col} lg={6} controlId='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Email'
                value={formData.email}
                name='email'
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId='phone'>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type='text'
                placeholder='Phone'
                value={formData.phone}
                name='phone'
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Form.Group className='mb-3' controlId='jobTitle'>
            <Form.Label>Job Title</Form.Label>
            <Form.Control
              type='text'
              placeholder='Job title'
              value={formData.jobTitle}
              name='jobTitle'
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className='mb-3' controlId='bio'>
            <Form.Label>Bio</Form.Label>
            <Form.Control
              as='textarea'
              rows={13}
              placeholder='Bio'
              value={formData.bio}
              name='bio'
              onChange={handleChange}
            />
          </Form.Group>
          <div className='text-end'>
            <Button variant='primary' type='submit'>
              Update
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ProfileSettings;
