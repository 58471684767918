import React from 'react';
import DashboardsLayout from './DashboardsLayout';

/**
 * Renders the Dashboards component
 *
 * @returns {JSX.Element} The rendered breadcrumb component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Dashboards = () => {
  return (
    <>
      <DashboardsLayout />
    </>
  );
};

export default Dashboards;
