import { useContext } from 'react';
import { UsersContext } from 'context/admin-contexts/UsersProvider.js';

/**
 * Custom hook to use the UsersContext
 * @returns {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const useUsers = () => {
  return useContext(UsersContext);
};

export default useUsers;
