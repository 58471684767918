import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import React, { useEffect, useState } from 'react';
// import MultiSelect from 'components/common/MultiSelect';
import useAlerts from 'hooks/admin-contexts/useAlerts';
import useUsers from 'hooks/admin-contexts/useUsers';
// import useOrganizations from 'hooks/admin-contexts/useOrganizations';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import {
  Button,
  Col,
  Form,
  FormText,
  Modal,
  Row,
  Tab,
  Tabs
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

/**
 * alert edit form
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const AlertEditForm = () => {
  const {
    users: { data }
  } = useUsers();
  const {
    alerts: {
      editAlertModal: { defaultData }
    },
    setAlerts
  } = useAlerts();
  const { axiosPrivate } = useAxiosPrivate();
  // const { organizations } = useOrganizations();
  const {
    handleSubmit,

    formState: { errors },
    setValue,
    watch,
    reset,
    register
  } = useForm();
  const { name, description, organization } = watch();
  const [loading, setLoading] = useState(false);

  /**
   * @description form for user creation
   * @param {Object} data
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const onSubmit = async data => {
    try {
      setLoading(true);
      let newData = data;

      newData['users'] = data?.users?.map(item => item.value);
      newData['leads'] = data?.leads?.map(item => item.value);

      const res = await LeargasAPI.AppAlerts.updateAlert(
        defaultData?._id,
        data,
        axiosPrivate
      );
      if (res instanceof Error) throw res;

      const { message } = res.data;
      setLoading(false);
      setAlerts(prevState => ({
        ...prevState,
        editAlertModal: { open: false },
        fetch: true
      }));
      toast.success(message);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);
      setAlerts(prevState => ({
        ...prevState,
        editAlertModal: { open: false },
        fetch: false
      }));
      if (error?.data?.response?.data?.message) {
        toast.error(error?.data?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  useEffect(() => {
    // When the organization changes, reevaluate and filter the selected users for alert members and alert leads
    const filteredUsers = data
      ?.filter(user =>
        user?.organizations?.some(org => org.id === organization)
      )
      ?.map(user => ({
        value: user.id,
        label: user.profile.fullName
      }));
    // Update the selected users for alert members
    setValue('users', prevUsers => {
      return prevUsers.filter(user =>
        filteredUsers.some(filteredUser => filteredUser.value === user.value)
      );
    });
    // Update the selected users for alert leads
    setValue('leads', prevLeads => {
      return prevLeads.filter(lead =>
        filteredUsers.some(filteredLead => filteredLead.value === lead.value)
      );
    });
  }, [organization]);

  /**
   * default data set
   */
  useEffect(() => {
    if (defaultData) {
      reset({
        name: defaultData?.name,
        description: defaultData?.description,
        leads: defaultData.leads.map(user => ({
          value: user?.id,
          label: user?.profile?.fullName
        })),
        users: defaultData.users.map(user => ({
          value: user?.id,
          label: user?.profile?.fullName
        })),
        organization: defaultData?.organization
      });
    }
  }, [defaultData, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Tabs defaultActiveKey='identity' id='uncontrolled-tab-example'>
        <Tab
          eventKey='identity'
          title='Identity'
          className='border-none p-3'
          tabClassName='fw-normal'>
          <Row className='g-3 mb-3'>
            {/* <Col sm={12} className='required'>
              <Form.Group>
                <Form.Label>Organization</Form.Label>
                <Form.Select {...register('organization')} className='fs--1' style={{ minHeight: '36px' }}>
                  <option>Please select</option>
                  {organizations?.data?.map((org, index) => (
                    <option key={index} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </Form.Select>
                <FormText className='text-muted'>The organization this alert belongs to.</FormText>
              </Form.Group>
            </Col> */}

            <Col sm={12} className='required'>
              <Form.Label>Alert Name</Form.Label>
              <Form.Control
                {...register('name', { required: 'Alert Name is required' })}
                type='text'
                autoComplete='off'
                className={`fs--1 ${errors.name ? 'is-invalid' : ''}`}
              />
              <code>{errors.name?.message}</code>
              <FormText className='text-muted'>
                Alert name must be unique within the organization it belongs to.
              </FormText>
            </Col>

            <Col sm={12}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                {...register('description')}
                size='sm'
                autoComplete='off'
                className={`fs--1 ${errors.description ? 'is-invalid' : ''}`}
                style={{ minHeight: '36px' }}
              />
              <code>{errors.description?.message}</code>
              <FormText className='text-muted'>
                A short description of the alert, it's purpose and function.
              </FormText>
            </Col>
            {/* <Col sm={12}>
              <Form.Label>Query</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                {...register('Query')}
                size='sm'
                autoComplete='off'
                className={`fs--1 ${errors.description ? 'is-invalid' : ''}`}
                style={{ minHeight: '36px' }}
              />
              <code>{errors.Query?.message}</code>
              <FormText className='text-muted'>Query that will run on to provide the desired dashbaord.</FormText>
            </Col> */}
          </Row>
        </Tab>

        {/* <Tab eventKey='access' title='Access' className='border-none p-3' tabClassName='fw-normal'>
          <Row className='g-3 mb-3'>
            <Col sm={12}>
              <Form.Label>Alert Members</Form.Label>
              <Controller
                name='users'
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <MultiSelect
                    className='fs--1'
                    isMulti
                    isClearable
                    isSearchable
                    placeholder='Select Users'
                    options={data
                      ?.filter(user => user?.organizations?.some(org => org.id === organization))
                      ?.map(user => ({
                        value: user.id,
                        label: user.profile.fullName
                      }))}
                    onChange={selectedOptions => {
                      field.onChange(selectedOptions);
                      setValue('users', selectedOptions);
                    }}
                    value={field.value}
                    style={{ minHeight: '36px' }}
                  />
                )}
              />
              <code>{errors.users?.message}</code>
            </Col>

            <Col sm={12}>
              <Form.Label>Alert Leads</Form.Label>
              <Controller
                name='leads'
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <MultiSelect
                    className='fs--1'
                    isMulti
                    isClearable
                    isSearchable
                    placeholder='Select alert Leads'
                    options={data
                      ?.filter(user => user?.organizations?.some(org => org.id === organization))
                      ?.map(user => ({
                        value: user.id,
                        label: user.profile.fullName
                      }))}
                    onChange={selectedOptions => {
                      field.onChange(selectedOptions);
                      setValue('leads', selectedOptions);
                    }}
                    value={field.value}
                    style={{ minHeight: '36px' }}
                  />
                )}
              />
              {/* <Controller
                name='lead'
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <Form.Control as='select' {...field} className='fs--1' style={{ minHeight: '36px' }}>
                    <option>Select Alert Leads</option>;
                    {data?.map(user => {
                      return <option value={user._id}>{user?.profile?.fullName}</option>;
                    })}
                  </Form.Control>
                )}
              /> */}
        {/* <code>{errors.lead?.message}</code>
            </Col>
          </Row>
        </Tab> */}
      </Tabs>
      <Modal.Footer className='border-top-0 pb-2'>
        <Flex justifyContent='end'>
          <Button
            variant='secondary'
            className='me-2'
            onClick={() =>
              setAlerts(prevState => ({
                ...prevState,
                editAlertModal: { open: false, data: {}, formData: {} }
              }))
            }>
            Cancel
          </Button>

          <Button
            variant='success'
            type='submit'
            disabled={!name || !description}
            className='d-flex'>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <ButtonSpinner spinning={loading} />
              <span>{loading ? 'Saving' : 'Submit'}</span>
            </Flex>
          </Button>
        </Flex>
      </Modal.Footer>
    </Form>
  );
};

export default AlertEditForm;
