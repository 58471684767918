import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { Nav } from 'react-bootstrap';

const TopNavRightSideNavItem = ({ children }) => {
  const {
    application: { isDark, isPrivacyMode },
    setApplication
  } = useApplication();

  return (
    <Nav
      navbar
      className='navbar-nav-icons ms-auto flex-row align-items-center pt-1 pt-md-0'
      as='ul'>
      <Nav.Item as={'li'}>
        <Nav.Link
          className='ps-0 pe-0 pe-md-1 mt-1 me-1'
          onClick={() => setApplication('isDark', !isDark)}>
          <AdvancedPopover
            placement='bottom'
            className='position-relative'
            popoverText={`${isDark ? 'Light' : 'Dark'} Mode`}
            showArrow={true}>
            <div
              className='theme-control-toggle-label d-flex flex-center theme-mode-toggle'
              style={{ width: '1.75em' }}>
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className='fs-xs--1 fs-0 text-700 hover-900'
              />
            </div>
          </AdvancedPopover>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as={'li'}>
        <Nav.Link
          className='ps-0 pe-0 pe-md-1 mt-1 me-1'
          onClick={() => setApplication('isPrivacyMode', !isPrivacyMode)}>
          <AdvancedPopover
            placement='bottom'
            className='position-relative'
            popoverText={` Privacy Mode: ${isPrivacyMode ? 'On' : 'Off'}`}
            showArrow={true}>
            <div
              className='theme-control-toggle-label d-flex flex-center privacy-mode-toggle'
              style={{ width: '1.75em' }}>
              <FontAwesomeIcon
                icon={isPrivacyMode ? 'eye-slash' : 'eye'}
                className='fs-xs--1 fs-0 text-700 hover-900'
              />
            </div>
          </AdvancedPopover>
        </Nav.Link>
      </Nav.Item>
      {children}
      <ProfileDropdown />
    </Nav>
  );
};

TopNavRightSideNavItem.propTypes = {
  children: PropTypes.node
};

export default TopNavRightSideNavItem;
