import LeargasAPI from 'api';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

export const resourcesSettings = {
  addResourceModal: {
    fullScreen: false,
    open: false
  },
  data: [],
  deleteResourceModal: {
    data: {},
    open: false
  },
  editResourceModal: {
    data: {},
    fullScreen: false,
    open: false
  },
  Executors: ['role', 'team', 'user'],
  fetch: false,
  loading: false,
  Operations: ['read', 'write', 'delete'],
  requiredResources: {
    resources: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    },
    resourcesNotes: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    resources: {
      delete: ['resources:delete'],
      read: ['resources:read'],
      write: ['resources:write']
    },
    resourcesNotes: {
      delete: ['resourcesNotes:delete'],
      read: ['resourcesNotes:read'],
      write: ['resourcesNotes:write']
    }
  },
  showDeleted: false
};

/**
 * Create ResourcesContext
 * @type {React.Context<{}>}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const ResourcesContext = createContext();

/**
 * Create ResourcesProvider
 * @param props
 * @returns {JSX.Element}
 * @constructor
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 *  <ResourcesProvider>
 *   {children}
 * </ResourcesProvider>
 * )
 */

const ResourcesProvider = props => {
  const [resources, setResources] = useState(resourcesSettings);
  const [loading, setLoading] = useState(true);

  const { axiosPrivate } = useAxiosPrivate();

  /**
   * Get all application resources
   * @returns {Promise<void>}
   * @async
   * @function
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  const getAppResources = async () => {
    try {
      let res = await LeargasAPI.Resources.getAppResources(axiosPrivate);
      if (res instanceof Error) throw res;

      setResources(prevState => ({
        ...prevState,
        data: res?.data?.data
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setResources(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setResources(prevState => ({
        ...prevState,
        fetch: false
      }));
      setLoading(false);
    }
  };

  /**
   * Get all application resources on fetch state change
   */
  useEffect(() => {
    if (resources.fetch) {
      getAppResources();
    }
  }, [resources.fetch]);

  /**
   * Get all application resources on component mount
   */
  useEffect(() => {
    getAppResources();
  }, []);

  return (
    <ResourcesContext.Provider
      value={{
        resources,
        setResources,
        loading
      }}>
      {props.children}
    </ResourcesContext.Provider>
  );
};

ResourcesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ResourcesProvider;
