import React, { useState } from 'react';
import useApplication from 'hooks/useApplication';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import Flex from '../../Flex';
import { Col, Form, Row } from 'react-bootstrap';
import { useESGlobalFilters } from 'hooks/useGlobalFilters';

/**
 * Renders the Scope toggle component
 *
 * @returns {JSX.Element} The rendered Scope toggle component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Scope = () => {
  const {
    setESGlobalFilters,
    esGlobalFilters: { scopes, scopeAllToggle }
  } = useESGlobalFilters();

  const {
    application: { isPrivacyMode }
  } = useApplication();

  const [scopesLoader] = useState(false);

  /**
   * Updates the checked value of an organization in the scopes array and sets the updated scopes in the ESGlobalFilters state
   *
   * @param {boolean} value - The new checked value for the organization
   * @param {number} index - The index of the organization in the scopes array
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const changeOrgsCheck = (value, index) => {
    let newOrgs = JSON.parse(JSON.stringify(scopes));
    newOrgs[index]['checked'] = value;

    setESGlobalFilters(prevState => {
      return {
        ...prevState,
        scopes: newOrgs
      };
    });
  };

  /**
   * Toggles the value of a boolean property in each object of an array
   *
   * @param {Array} array - The array of objects
   * @param {string} propertyName - The name of the boolean property to toggle
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const toggleProperty = (array, propertyName) => {
    for (let i = 0; i < array.length; i++) {
      if (
        propertyName in array[i] &&
        typeof array[i][propertyName] === 'boolean'
      ) {
        array[i][propertyName] = !array[i][propertyName];
      }
    }

    setESGlobalFilters(prevState => {
      return {
        ...prevState,
        scopes: array,
        scopeAllToggle: !scopeAllToggle
      };
    });
  };

  return (
    <>
      <Flex direction='column'>
        <div
          className={`position-absolute all-0 align-items-center justify-content-around bg-card-spinner ${
            scopesLoader ? 'd-flex' : 'd-none'
          } ${scopesLoader && 'bg-card-spinner-refresh'}`}
          style={{ margin: '0px' }}></div>
        {scopesLoader ? (
          <span
            className='fw-light'
            style={{ marginRight: '20px', marginTop: '20px' }}>
            <LoadingSpinner />
          </span>
        ) : (
          <Row className='position-relative'>
            <Col md={12} className='mb-1'>
              <Flex
                justifyContent='start'
                alignItems='center'
                className='text-nowrap'>
                <Form.Check
                  inline
                  size='sm'
                  type='switch'
                  className='me-0 fs--1'
                  defaultChecked={scopeAllToggle}
                  checked={true}
                  onChange={() => toggleProperty(scopes, 'checked')}
                />
                <Form.Label className='fw-normal mb-0 fs--1'>
                  Toggle all
                </Form.Label>
              </Flex>
            </Col>
            {scopes
              ?.sort((a, b) =>
                isPrivacyMode
                  ? a?.legacy?.clientNumber - b?.legacy?.clientNumber
                  : a?.name.localeCompare(b?.name)
              )
              .map((item, index) => {
                return (
                  <Col
                    md={isPrivacyMode ? 3 : 6}
                    xxl={isPrivacyMode ? 3 : 4}
                    key={index}
                    className='mb-1'>
                    <Flex
                      justifyContent='start'
                      alignItems='center'
                      className='text-nowrap'>
                      <Form.Check
                        inline
                        size='sm'
                        type='switch'
                        className='me-0 fs--1'
                        checked={item.checked}
                        onChange={e => changeOrgsCheck(e.target.checked, index)}
                      />
                      <Form.Label className='fw-normal mb-0 fs--1'>
                        {isPrivacyMode
                          ? item?.legacy?.clientTag
                            ? item?.legacy?.clientTag
                            : ''
                          : item.name}
                      </Form.Label>
                    </Flex>
                  </Col>
                );
              })}
          </Row>
        )}
      </Flex>
    </>
  );
};

export default Scope;
