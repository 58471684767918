import { makeRequest } from 'api/connection/requestAction';

class Alerts {
  constructor(route) {
    this.route = route;
  }

  async createAccOrg(userData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.createAccOrg.method,
        this.route.createAccOrg.path,
        userData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async removePendingUser(userData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.removePendingUser.method,
        this.route.removePendingUser.path,
        userData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async removeUserFromOrg(userData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.removeUserFromAlert.method,
        this.route.removeUserFromAlert.path,
        userData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async getPendingInvitations(data, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getPendingInvitations.method,
        this.route.getPendingInvitations.path,
        data
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async inviteUserToOrg(userData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.inviteUserToAlert.method,
        this.route.inviteUserToAlert.path,
        userData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async deleteAccAlerts(ids, deleting, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.deleteAccOrg.method,
        this.route.deleteAccOrg.path,
        {
          id: ids,
          deleting
        }
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async updateAccOrg(orgData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateAccOrg.method,
        this.route.updateAccOrg.path,
        orgData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async getAccAlert(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getAccAlert.method,
        `${this.route.getAccAlert.path}/${id}`
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async getAccAlerts(axiosPrivate, showDeleted = false) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getAccAlerts.method,
        this.route.getAccAlerts.path
      );
      if (res instanceof Error) throw res;
      let alerts = [];
      res?.data?.data?.map(org => {
        if ((showDeleted && org.deleted) || !org.deleted) {
          alerts.push(org);
        }
      });
      return alerts;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default Alerts;
