import Flex from 'components/common/Flex';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

export const UnsavedChangesContext = createContext();

/**
 * UnsavedChangesProvider component.
 *
 * @component
 * @param {Object} props - The component props
 * @param {ReactNode} props.children - The child components
 * @returns {ReactNode} The rendered UnsavedChangesProvider component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const UnsavedChangesProvider = ({ children }) => {
  const modalRef = useRef(null);
  const pendingNavigation = useRef(null);

  const {
    application: { isDark }
  } = useApplication();

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // Default should be false
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  /**
   * Prompt the user before leaving the page.
   *
   * @param {Function} nextAction - The action to be performed after prompting the user
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const promptOnLeave = useCallback(
    nextAction => {
      if (hasUnsavedChanges) {
        setShowUnsavedChangesModal(true); // Show modal
        pendingNavigation.current = nextAction; // Store the action
      } else if (nextAction) {
        nextAction(); // No unsaved changes, immediately proceed
      }
    },
    [hasUnsavedChanges]
  );

  /**
   * Handles the confirmation to leave the current page.
   *
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleConfirmLeave = () => {
    setShowUnsavedChangesModal(false);
    if (pendingNavigation.current) {
      pendingNavigation.current(); // Perform the stored navigation action
      pendingNavigation.current = null;
    }
  };

  return (
    <UnsavedChangesContext.Provider
      value={{
        hasUnsavedChanges,
        promptOnLeave,
        setHasUnsavedChanges
      }}>
      {children}
      <Modal
        ref={modalRef}
        aria-labelledby='unsavedChangesModal'
        centered
        keyboard={true}
        onHide={() => setShowUnsavedChangesModal(false)}
        show={showUnsavedChangesModal}
        size='md'
        contentClassName='shadow-none'
        backdropClassName='unsaved-changes-modal-backdrop'
        className='unsaved-changes-modal'>
        <Flex
          direction='column'
          justifyContent='between'
          className='card border border-card shadow-none'>
          <Modal.Header
            className='p-2'
            closeButton
            closeVariant={isDark ? 'white' : undefined}>
            <Modal.Title>
              <h5 className='d-flex mb-1 fs-0 fw-normal position-relative text-900'>
                You Have Unsaved Changes
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='p-2'>
            <p className='fs--1 fw-normal text-900'>
              If you proceed you will lose any unsaved changes. Do you want to
              discard your changes and continue?
            </p>
          </Modal.Body>
          <Modal.Footer className='p-2'>
            <Button
              size='sm'
              type='button'
              variant='secondary'
              className='me-2'
              onClick={() => setShowUnsavedChangesModal(false)}>
              Continue Editing
            </Button>
            <Button
              size='sm'
              type='button'
              variant='pastel-yellow'
              onClick={handleConfirmLeave}>
              Discard Changes
            </Button>
          </Modal.Footer>
        </Flex>
      </Modal>
    </UnsavedChangesContext.Provider>
  );
};

UnsavedChangesProvider.propTypes = {
  children: PropTypes.node.isRequired
};
