import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';
import { useApplication } from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect } from 'react';

echarts.use([
  LineChart,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  CanvasRenderer
]);

const getOption = ({ xAxis, legend, series, isDark }) => ({
  darkMode: isDark,
  legend: {
    data: legend,
    textStyle: {
      color: getColor('gray-900'),
      fontWeight: 400
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#6a7985'
      }
    }
  },
  toolbox: {
    feature: {
      saveAsImage: {
        title: 'Save',
        type: 'png',
        name: `cluster-health-${new Date().getTime()}`
      }
    },
    iconStyle: {
      borderColor: getColor('gray-900')
    },
    emphasis: {
      iconStyle: {
        borderColor: getColor('primary')
      }
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: xAxis,
    axisLabel: {
      show: true,
      interval: 12,
      color: isDark ? 'var(--falcon-gray-400' : 'var(--falcon-gray-600)',
      fontWeight: 400,
      fontSize: 10,
      margin: 10
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: rgbaColor(getColor('gray-400'), 0.5)
      }
    },
    axisTick: {
      show: true,
      alignWithLabel: true,
      lineStyle: {
        color: getColor('gray-400')
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: rgbaColor(getColor('gray-400'), 0.5)
      }
    }
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      show: true,
      color: getColor('gray-900'),
      fontWeight: 400,
      fontSize: 10,
      margin: 10
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: rgbaColor(getColor('gray-400'), 0.5)
      }
    },
    axisTick: {
      show: true,
      alignWithLabel: true,
      lineStyle: {
        color: rgbaColor(getColor('gray-400'), 0.5)
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: rgbaColor(getColor('gray-400'), 0.5)
      }
    }
  },
  series: series,
  grid: { right: 30, left: 50, bottom: '25', top: 60 }
});

const HeroUnitChart = forwardRef(({ xAxis, legend, series, style }, ref) => {
  const {
    application: { isDark }
  } = useApplication();

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    // @ts-ignore
    const chart = ref.current.getEchartsInstance();
    if (!chart) {
      return;
    }

    chart.setOption(
      {
        legend: {
          data: legend
        },
        series: series
      },
      {
        lazyUpdate: false
      }
    );
  }, [ref]);

  return (
    <ReactEChartsCore
      echarts={echarts}
      ref={ref}
      option={getOption({ xAxis, legend, series, isDark })}
      style={style}
    />
  );
});

HeroUnitChart.propTypes = {
  xAxis: PropTypes.array.isRequired,
  legend: PropTypes.array.isRequired,
  series: PropTypes.array.isRequired,
  style: PropTypes.object
};

export default HeroUnitChart;
