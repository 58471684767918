import React from 'react';
import PropTypes from 'prop-types';
import useReleases from 'hooks/admin-contexts/useReleases';
import useAuthentication from 'hooks/useAuthentication';
import { Button, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const allowedRoles = ['super-admin', 'admin'];

/**
 * Render the ReleaseNotesAddButton component
 *
 * @param {object} props Component properties
 * @param {string} props.releaseId The release ID to add a release note to
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ReleaseNotesAddButton = ({ releaseId }) => {
  const { setReleases } = useReleases();

  const {
    authentication: { roles }
  } = useAuthentication();

  return (
    <>
      {roles.some(role => allowedRoles.includes(role.slug)) && (
        <Col xs={12} sm={6} lg={4} xl={3} xxl={2} style={{ marginTop: '16px' }}>
          <Button
            size='sm'
            variant='link'
            className='position-relative me-2 px-1 z-index-1 border border-primary shadow-none text-decoration-none cursor-pointer icon-item icon-item-sm rounded-circle'
            onClick={() =>
              setReleases(prevState => ({
                ...prevState,
                addReleaseNoteModal: { open: true, data: { id: releaseId } }
              }))
            }>
            <FontAwesomeIcon icon='plus' className='text-primary' />
            <span className='position-absolute start-100 ms-2 text-primary text-nowrap fs--1'>
              Add a release note
            </span>
          </Button>
        </Col>
      )}
    </>
  );
};

ReleaseNotesAddButton.propTypes = {
  releaseId: PropTypes.string.isRequired
};

export default ReleaseNotesAddButton;
