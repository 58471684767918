// import MultiSelect from 'components/common/MultiSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import { dynamicDashboardRoutes } from 'components/dashboards/Artifacts/Dynamic/routes';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import routes from 'config/routeSettings';
import { useSearchFilters } from 'context/FiltersProvider';
import useSavedSearches from 'hooks/admin-contexts/useSavedSearches';
import useApplication from 'hooks/useApplication';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useExplore from 'hooks/useExplore';
import React, { useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 * Renders the SaveSearchModal component
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const SaveSearchModal = () => {
  const modalRef = useRef(null);

  const { axiosPrivate } = useAxiosPrivate();

  const {
    application: { isDark, showAddSavedSearchModal },
    setApplication
  } = useApplication();

  const {
    state: { indexSelected, fieldsSelected }
  } = useExplore();

  const {
    searchFilters: { filters }
  } = useSearchFilters();

  const { setSavedSearches } = useSavedSearches();

  const {
    handleSubmit,
    formState: { errors },
    watch,
    register
  } = useForm();

  const { name } = watch();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const loadDashboard = savedSearch => {
    setApplication('showSavedSearchListModal', false);

    let dashboard = dynamicDashboardRoutes.find(
      item => item.slug === savedSearch._dashboard
    );
    navigate(
      `${dashboard ? dashboard.path : routes.ARTIFACTS_EXPLORE}?savedSearchId=${
        savedSearch.id
      }&nonce=${new Date().getTime()}`
    );
  };

  /**
   * Handle the submit of the savedSearches add form
   *
   * @param {Object} data
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const onSubmit = async data => {
    try {
      setLoading(true);
      let newData = data;

      newData['filters'] = filters;
      newData['fields'] = fieldsSelected
        .filter(item => item._id)
        .map(item => item._id);
      newData['indices'] = [`${indexSelected._id}`];
      newData['_dashboard'] = dynamicDashboardRoutes.find(
        item => item.path === location.pathname
      ).slug;

      const res = await LeargasAPI.AppSavedSearches.createSavedSearches(
        newData,
        axiosPrivate
      );
      if (res instanceof Error) throw res;

      const { message } = res.data;
      setLoading(false);

      toast.success(message);
      setApplication('showAddSavedSearchModal', false);
      loadDashboard(res?.data?.data);
      setSavedSearches(prevState => ({
        ...prevState,
        fetch: true
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  return (
    <Modal
      aria-labelledby='addSavedSearchModal'
      centered
      contentClassName='shadow-none'
      keyboard={true}
      onHide={() => setApplication('showAddSavedSearchModal', false)}
      ref={modalRef}
      show={showAddSavedSearchModal}
      size='lg'>
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            className='p-2'
            closeButton
            closeVariant={isDark ? 'white' : undefined}>
            <h5 className='d-flex align-items-center fs-0 fw-normal position-relative'>
              Save Search
              <AdvancedPopover
                containerId='newFilterModal'
                placement='top'
                popoverText='Save the current search'
                showArrow={true}>
                <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  className='ms-1 fs--1 cursor-pointer text-400'
                />
              </AdvancedPopover>
            </h5>
          </Modal.Header>

          <Modal.Body className='p-2'>
            <Row className='g-3 mb-2'>
              <Col>
                <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
                  Name <span className='text-danger ms-1'>*</span>
                  <AdvancedPopover
                    containerId='newFilterModal'
                    placement='top'
                    popoverText='The name of the saved search.'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  {...register('name', {
                    required: 'Query Name is required'
                  })}
                  type='text'
                  autoComplete='off'
                  className={` ${errors.name ? 'is-invalid' : ''}`}
                />
                <code>{errors.name?.message}</code>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
                  Description{' '}
                  <AdvancedPopover
                    containerId='newFilterModal'
                    placement='top'
                    popoverText='A description for the saved search and what its purpose is.'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  {...register('description')}
                  type='text'
                  as='textarea'
                  rows={3}
                  autoComplete='off'
                  className={` ${errors.name ? 'is-invalid' : ''}`}
                />
                <code>{errors.name?.message}</code>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className='p-2'>
            <Row className='g-1 m-0 p-0 w-100'>
              <Col xs={12} className='p-0'>
                <Flex direction='row' justifyContent='end'>
                  <Button
                    size='sm'
                    variant='secondary'
                    className='me-2'
                    onClick={() =>
                      setApplication('showAddSavedSearchModal', false)
                    }>
                    Cancel
                  </Button>
                  <Button
                    variant='success'
                    type='submit'
                    size='sm'
                    disabled={!name}
                    className='d-flex'>
                    <Flex justifyContent={'center'} alignItems={'center'}>
                      <ButtonSpinner spinning={loading} />
                      <span>{loading ? 'Saving' : 'Save'}</span>
                    </Flex>
                  </Button>
                </Flex>
              </Col>
            </Row>
          </Modal.Footer>
        </Form>
      </Flex>
    </Modal>
  );
};

export default SaveSearchModal;
