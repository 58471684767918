/* eslint-disable react/prop-types */
import IndeterminateCheckbox from 'components/common/IndeterminateCheckbox';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';

/**
 * ReportsTableWrapper is a memoized React component that wraps a table with various features such as sorting, pagination, selection, and filtering.
 * It uses the `useTable` hook from `react-table` to manage table state and behavior.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The child components to be rendered inside the wrapper.
 * @param {Array} props.columns - The column definitions for the table.
 * @param {Array} props.data - The data to be displayed in the table.
 * @param {boolean} props.sortable - Whether the table columns are sortable.
 * @param {boolean} props.selection - Whether row selection is enabled.
 * @param {number} props.selectionColumnWidth - The width of the selection column.
 * @param {boolean} props.pagination - Whether pagination is enabled.
 * @param {number} [props.perPage=10] - The number of rows per page when pagination is enabled.
 *
 * @returns {React.Element} The rendered component.
 */
const ReportsTableWrapper = React.memo(
  ({
    children,
    columns,
    data,
    sortable,
    selection,
    selectionColumnWidth,
    pagination,
    perPage = 10
  }) => {
    const memoizedColumns = useMemo(() => columns, [columns]);
    const memoizedData = useMemo(() => data, [data]);

    /**
     * Custom hook to create a table with various functionalities such as filtering, sorting, pagination, and row selection.
     *
     * @param {Object} options - Configuration options for the table.
     * @param {Array} options.columns - Array of column definitions.
     * @param {Array} options.data - Array of data to be displayed in the table.
     * @param {boolean} [options.autoResetFilters=false] - Whether to automatically reset filters when data changes.
     * @param {boolean} [options.autoResetSortBy=false] - Whether to automatically reset sorting when data changes.
     * @param {boolean} [options.autoResetSelectedRows=false] - Whether to automatically reset selected rows when data changes.
     * @param {boolean} [options.autoResetGlobalFilter=false] - Whether to automatically reset global filter when data changes.
     * @param {boolean} [options.autoResetPage=false] - Whether to automatically reset page when data changes.
     * @param {boolean} [options.disableSortBy=false] - Whether to disable sorting functionality.
     * @param {Object} [options.initialState] - Initial state of the table.
     * @param {number} [options.initialState.pageSize] - Initial page size for pagination.
     * @param {boolean} [options.sortable=true] - Whether the table columns are sortable.
     * @param {boolean} [options.pagination=true] - Whether the table should have pagination.
     * @param {number} [options.perPage=10] - Number of rows per page for pagination.
     * @param {boolean} [options.selection=false] - Whether row selection is enabled.
     * @param {number} [options.selectionColumnWidth=50] - Width of the selection column.
     *
     * @returns {Object} Table instance with various properties and methods.
     * @returns {Function} getTableProps - Function to get props for the table.
     * @returns {Array} headers - Array of header column definitions.
     * @returns {Array} page - Array of rows for the current page.
     * @returns {Function} prepareRow - Function to prepare a row for rendering.
     * @returns {boolean} canPreviousPage - Whether there is a previous page.
     * @returns {boolean} canNextPage - Whether there is a next page.
     * @returns {Function} nextPage - Function to go to the next page.
     * @returns {Function} previousPage - Function to go to the previous page.
     * @returns {Function} setPageSize - Function to set the page size.
     * @returns {Function} gotoPage - Function to go to a specific page.
     * @returns {number} pageCount - Total number of pages.
     * @returns {Object} state - State of the table.
     * @returns {number} state.pageIndex - Current page index.
     * @returns {number} state.pageSize - Current page size.
     * @returns {Object} state.selectedRowIds - Object containing selected row IDs.
     * @returns {string} state.globalFilter - Current global filter value.
     * @returns {Function} setGlobalFilter - Function to set the global filter.
     */
    const {
      getTableProps,
      headers,
      page,
      prepareRow,
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      setPageSize,
      gotoPage,
      pageCount,
      state: { pageIndex, pageSize, selectedRowIds, globalFilter },
      setGlobalFilter
    } = useTable(
      {
        columns: memoizedColumns,
        data: memoizedData,
        autoResetFilters: false,
        autoResetSortBy: false,
        autoResetSelectedRows: false,
        autoResetGlobalFilter: false,
        autoResetPage: false,
        disableSortBy: !sortable,
        initialState: { pageSize: pagination ? perPage : data?.length }
      },
      useGlobalFilter,
      useFilters,
      useSortBy,
      usePagination,
      useRowSelect,
      hooks => {
        if (selection) {
          hooks.visibleColumns.push(columns => [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              ),
              headerProps: {
                style: {
                  maxWidth: selectionColumnWidth
                }
              },
              cellProps: {
                style: {
                  maxWidth: selectionColumnWidth
                }
              },
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              )
            },
            ...columns
          ]);
        }
      }
    );

    /**
     * Recursively maps over React children elements and clones them with additional props.
     *
     * - If a child element has children, it recursively maps over those children.
     * - If a child element has a `table` prop, it clones the element with additional table-related props.
     * - Otherwise, it returns the child element as is.
     *
     * @param {React.ReactNode} children - The children elements to be recursively mapped.
     * @returns {React.ReactNode} - The new children elements with additional props.
     */
    const recursiveMap = useCallback(
      children => {
        return React.Children.map(children, child => {
          if (React.isValidElement(child) && child.props?.children) {
            return React.cloneElement(child, {
              children: recursiveMap(child.props.children)
            });
          } else if (React.isValidElement(child) && child.props?.table) {
            return React.cloneElement(child, {
              ...child.props,
              getTableProps,
              headers,
              page,
              data: memoizedData,
              prepareRow,
              canPreviousPage,
              canNextPage,
              nextPage,
              previousPage,
              gotoPage,
              pageCount,
              pageIndex,
              selectedRowIds,
              pageSize,
              setPageSize,
              globalFilter,
              setGlobalFilter
            });
          } else {
            return child;
          }
        });
      },
      [
        getTableProps,
        headers,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        gotoPage,
        pageCount,
        pageIndex,
        selectedRowIds,
        pageSize,
        setPageSize,
        globalFilter,
        setGlobalFilter
      ]
    );

    return <>{recursiveMap(children)}</>;
  }
);

ReportsTableWrapper.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.array,
  data: PropTypes.array,
  sortable: PropTypes.bool,
  selection: PropTypes.bool,
  selectionColumnWidth: PropTypes.number,
  pagination: PropTypes.bool,
  perPage: PropTypes.number
};

export default ReportsTableWrapper;
