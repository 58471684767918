import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

export const rpc = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'RPC'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*zeek*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              path: '/usr/local/zeek/logs/current/dce_rpc.log'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Flows',
          helpInfo: 'Total number of RPC events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*zeek*'],
        queryType: ['histogram'],
        must: [
          {
            match_phrase: {
              path: '/usr/local/zeek/logs/current/dce_rpc.log'
            }
          }
        ]
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Event Timeline',
          helpInfo:
            'Timeline histogram of all RPC events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas*zeek*'],
        must: [
          {
            match_phrase: {
              path: '/usr/local/zeek/logs/current/dce_rpc.log'
            }
          }
        ],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'operation.keyword',
                order: {
                  _count: 'desc'
                },

                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'RPC Operations',
            overlayText: 'Actions by user and domain',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas*zeek*'],
        must: [
          {
            match_phrase: {
              path: '/usr/local/zeek/logs/current/dce_rpc.log'
            }
          }
        ],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'id.orig_h.keyword',
                order: {
                  _count: 'desc'
                },

                size: 5000
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Source',
            overlayText: 'Actions by user and domain',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas*zeek*'],
        must: [
          {
            match_phrase: {
              path: '/usr/local/zeek/logs/current/dce_rpc.log'
            }
          }
        ],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'id.resp_h.keyword',
                order: {
                  _count: 'desc'
                },

                size: 5000
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: ' Destination',
            overlayText: 'Actions by user and domain',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    }
  ];
};

export default rpc;
