import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingIndicatorOverlay from 'components/visualizations/LoadingIndicatorOverlay';
import useApplication from 'hooks/useApplication';
import useExplore from 'hooks/useExplore';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Offcanvas, Table } from 'react-bootstrap';
import WorldMap from './WorldMap';

const renderObject = obj => {
  if (!obj || typeof obj !== 'object') return null;

  return (
    <dl className='text-start'>
      {Object.entries(obj)
        .filter(
          ([, value]) => value !== null && value !== undefined && value !== ''
        )
        .map(([key, value], index) => (
          <React.Fragment key={key + index}>
            <dt className='fw-bold'>{key.replace(/_/g, ' ').toUpperCase()}:</dt>
            <dd className='ms-3'>
              {Array.isArray(value)
                ? value.join(', ')
                : typeof value === 'object'
                ? renderObject(value)
                : isDateField(key, value)
                ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss.SSS')
                : value || 'N/A'}
            </dd>
          </React.Fragment>
        ))}
    </dl>
  );
};

const createContent = (data, keyReplacements) => {
  if (!data || typeof data !== 'object') return null;

  const getDisplayKey = key => {
    return keyReplacements[key] || key.replace(/_/g, ' ').toUpperCase();
  };

  return (
    <div>
      {Object.entries(data)
        .filter(
          ([, value]) => value !== null && value !== undefined && value !== ''
        )
        .map(([key, value], index) => (
          <div key={index} className='d-flex py-1 border-bottom'>
            <span className='w-25 pe-2 fs--1'>{getDisplayKey(key)}</span>
            <span className='w-75 fs--1'>
              {Array.isArray(value)
                ? value.join(', ')
                : typeof value === 'object'
                ? renderObject(value)
                : isDateField(key, value)
                ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss.SSS')
                : value || 'N/A'}
            </span>
          </div>
        ))}
    </div>
  );
};

const isDateField = (key, value) => {
  const dateKeys = ['date'];
  return (
    dateKeys.some(dateKey => key.toLowerCase().includes(dateKey)) &&
    !isNaN(value)
  );
};

const NoDataMessage = ({ message }) => (
  <div className='text-center py-4'>
    <p className='fs--1 text-700 mb-0'>
      {message || 'No data available for this indicator'}
    </p>
  </div>
);

const HostTabContent = ({ data }) => {
  if (!data || Object.keys(data).length === 0) {
    return <NoDataMessage message='No host information available' />;
  }
  const keyReplacements = {
    as: 'AS',
    isp: 'ISP',
    lat: 'Latitude',
    lon: 'Longitude',
    org: 'Organization',
    city: 'City',
    country: 'Country',
    region: 'Region',
    regionName: 'Region Name',
    zip: 'Zip Code',
    timezone: 'Timezone',
    query: 'Query',
    status: 'Status',
    countryCode: 'Country Code'
  };
  return (
    <>
      <h6 className='mt-2 fs-1'>Host Information</h6>
      {createContent(data, keyReplacements)}
    </>
  );
};

const ShodanTabContent = ({ data }) => {
  // Check for Shodan's specific "no data" response structure
  if (
    !data ||
    Object.keys(data).length === 0 ||
    (data.error && data.error === 'Not Found in Shodan')
  ) {
    return <NoDataMessage message='No Shodan data available' />;
  }
  const [showModal, setShowModal] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);

  const keyReplacements = {
    asn: 'ASN',
    isp: 'ISP',
    CITY: 'City',
    org: 'Organization',
    country_name: 'Country',
    ip_str: 'IP Address',
    latitude: 'Latitude',
    longitude: 'Longitude',
    last_update: 'Last Update',
    os: 'Operating System',
    ports: 'Open Ports',
    port: 'Port',
    transport: 'Transport',
    region_code: 'Region Code',
    tags: 'Tags',
    domains: 'Domains',
    hostnames: 'Hostnames',
    country_code: 'Country Code',
    city: 'City',
    location: 'Location',
    opts: 'Options',
    timestamp: 'Timestamp',
    hash: 'Hash',
    data: 'Data',
    SHODAN: 'Shodan'
  };

  // Extract top-level Shodan data excluding the 'data' property
  const { data: serviceData, ...rest } = data || {};

  const importantKeys = ['ip_str', 'port', 'transport'];

  const handleShowModal = entry => {
    setSelectedEntry(entry);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEntry(null);
  };

  // Recursively sort selectedEntry object by key when changed
  useEffect(() => {
    if (selectedEntry) {
      const sortObjectRecursively = obj => {
        if (typeof obj !== 'object' || obj === null) return obj;
        return Object.keys(obj)
          .sort()
          .reduce((acc, key) => {
            acc[key] = sortObjectRecursively(obj[key]);
            return acc;
          }, {});
      };

      const sortedEntry = sortObjectRecursively(selectedEntry);
      setSelectedEntry(sortedEntry);
    }
  }, [selectedEntry]);

  return (
    <div>
      <h6 className='mt-2 fs-1'>Host Information</h6>
      {createContent(rest, keyReplacements)}
      {Array.isArray(serviceData) && (
        <>
          <h6 className='mt-3 fs-1'>Host Services</h6>
          <Table responsive hover>
            <thead>
              <tr>
                {importantKeys.map((key, idx) => (
                  <th key={idx} className='fs--1 fw-normal text-700 py-1'>
                    {keyReplacements[key] || key}
                  </th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {serviceData.map((entry, idx1) => (
                <tr key={idx1}>
                  {importantKeys.map((key, idx2) => (
                    <td key={idx2} className='fs--1 text-700 py-1'>
                      {Array.isArray(entry[key])
                        ? entry[key].join(', ')
                        : typeof entry[key] === 'object'
                        ? renderObject(entry[key])
                        : entry[key] || 'N/A'}
                    </td>
                  ))}
                  <td className='text-end py-1 pe-0'>
                    <Button
                      size='sm'
                      variant='outline-primary'
                      onClick={() => handleShowModal(entry)}>
                      Details
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      <Modal show={showModal} onHide={handleCloseModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Service Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEntry && createContent(selectedEntry, keyReplacements)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const AbuseIPDBTabContent = ({ data }) => {
  if (!data?.data || Object.keys(data.data).length === 0) {
    return <NoDataMessage message='No AbuseIPDB data available' />;
  }
  const keyReplacements = {
    ipAddress: 'IP Address',
    isPublic: 'Is Public',
    ipVersion: 'IP Version',
    isWhitelisted: 'Is Whitelisted',
    abuseConfidenceScore: 'Abuse Confidence Score',
    countryCode: 'Country Code',
    usageType: 'Usage Type',
    isp: 'ISP',
    domain: 'Domain',
    hostnames: 'Hostnames',
    isTor: 'Is Tor',
    totalReports: 'Total Reports',
    numDistinctUsers: 'Distinct Users',
    lastReportedAt: 'Last Reported At'
  };

  return (
    <>
      <h6 className='mt-2 fs-1'>Host Information</h6>
      {createContent(data.data, keyReplacements)}
    </>
  );
};

const VirusTotalTabContent = ({ data }) => {
  if (
    !data?.data?.attributes ||
    Object.keys(data.data.attributes).length === 0
  ) {
    return <NoDataMessage message='No VirusTotal data available' />;
  }
  const [showModal, setShowModal] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  const keyReplacements = {
    total_votes: 'Total Votes',
    asn: 'ASN',
    network: 'Network',
    as_owner: 'AS Owner',
    reputation: 'Reputation',
    last_analysis_stats: 'Last Analysis Stats',
    malicious: 'Malicious',
    suspicious: 'Suspicious',
    harmless: 'Harmless',
    timeout: 'Timeout',
    undetected: 'Undetected',
    last_modification_date: 'Last Modification Date',
    whois_date: 'WHOIS Date',
    regional_internet_registry: 'Regional Internet Registry',
    continent: 'Continent',
    whois: 'WHOIS',
    tags: 'Tags',
    country: 'Country',
    last_analysis_date: 'Last Analysis Date',
    last_https_certificate_date: 'Last HTTPS Certificate Date'
  };

  if (!data || typeof data !== 'object' || !data.data) return null;

  const { attributes } = data.data;
  const {
    last_analysis_results,
    last_analysis_stats,
    total_votes,
    last_https_certificate,
    whois,
    ...baseAttributes
  } = attributes;

  const sortedBaseAttributes = Object.keys(baseAttributes)
    .sort()
    .reduce((acc, key) => {
      acc[key] = baseAttributes[key];
      return acc;
    }, {});

  const sortedTotalVotes = Object.keys(total_votes)
    .sort()
    .reduce((acc, key) => {
      acc[key] = total_votes[key];
      return acc;
    }, {});

  const sortedLastAnalysisStats = Object.keys(last_analysis_stats)
    .sort()
    .reduce((acc, key) => {
      acc[key] = last_analysis_stats[key];
      return acc;
    }, {});

  const sortedLastAnalysisResults = Object.entries(last_analysis_results).sort(
    ([a], [b]) => a.localeCompare(b)
  );

  const handleShowModal = certificate => {
    setSelectedCertificate(certificate);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCertificate(null);
  };

  return (
    <div>
      <h4 className='mt-2 fs-1'>Host Information</h4>
      {createContent(sortedBaseAttributes, keyReplacements)}

      <h4 className='mt-3 fs-1'>Total Votes</h4>
      <Table responsive>
        <thead>
          <tr>
            {Object.keys(sortedTotalVotes).map((key, idx) => (
              <th
                key={idx}
                className='text-center fs--1 fw-normal py-1 text-700'>
                {keyReplacements[key] || key}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {Object.values(sortedTotalVotes).map((value, idx) => (
              <td key={idx} className='text-center fs--1 py-1 text-700'>
                {value}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>

      <h4 className='mt-3 fs-1'>Last Analysis Summary</h4>
      <Table responsive>
        <thead>
          <tr>
            {Object.keys(sortedLastAnalysisStats).map((key, idx) => (
              <th
                key={idx}
                className='text-center fs--1 fw-normal py-1 text-700'>
                {keyReplacements[key] || key}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {Object.values(sortedLastAnalysisStats).map((value, idx) => (
              <td key={idx} className='text-center fs--1 py-1 text-700'>
                {value}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>

      <h6 className='mt-3 fs-1'>Last Analysis Details</h6>
      <Table responsive>
        <thead>
          <tr>
            <th className='fs--1 fw-normal py-1 text-700'>Engine</th>
            <th className='text-center fs--1 fw-normal py-1 text-700'>
              Category
            </th>
            <th className='text-center fs--1 fw-normal py-1 text-700'>
              Result
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedLastAnalysisResults.map(([, details], idx) => (
            <tr key={idx}>
              <td className='fs--1 py-1 text-700'>{details.engine_name}</td>
              <td className='text-center fs--1 py-1 text-700'>
                {details.category}
              </td>
              <td className='text-center fs--1 py-1 text-700'>
                {details.result}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {last_https_certificate && (
        <>
          <h4 className='mt-4 fs-1'>Last HTTPS Certificate</h4>
          <div className='d-flex py-1 border-bottom'>
            <span className='w-25 pe-2 fs--1'>Subject</span>
            <span className='w-75 fs--1'>
              {last_https_certificate.subject.CN || 'N/A'}
            </span>
          </div>
          <div className='d-flex py-1 border-bottom'>
            <span className='w-25 pe-2 fs--1'>Issuer</span>
            <span className='w-75 fs--1'>
              {last_https_certificate.issuer.CN || 'N/A'}
            </span>
          </div>
          <div className='d-flex py-1 border-bottom'>
            <span className='w-25 pe-2 fs--1'>Valid From</span>
            <span className='w-75 fs--1'>
              {new Date(
                last_https_certificate.validity.not_before
              ).toLocaleString() || 'N/A'}
            </span>
          </div>
          <div className='d-flex py-1 border-bottom'>
            <span className='w-25 pe-2 fs--1'>Valid To</span>
            <span className='w-75 fs--1'>
              {new Date(
                last_https_certificate.validity.not_after
              ).toLocaleString() || 'N/A'}
            </span>
          </div>
          <div className='text-end py-1'>
            <Button
              size='sm'
              variant='outline-primary'
              onClick={() => handleShowModal(last_https_certificate)}>
              Details
            </Button>
          </div>

          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Certificate Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedCertificate && renderObject(selectedCertificate)}
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}

      {whois && (
        <>
          <h4 className='mt-4 fs-1'>WHOIS Information</h4>
          {createContent(whois, keyReplacements)}
        </>
      )}
    </div>
  );
};

const WHOISTabContent = ({ data }) => {
  if (!data || Object.keys(data).length === 0) {
    return <NoDataMessage message='No WHOIS data available' />;
  }
  const keyReplacements = {
    domain_name: 'Domain Name',
    registrar: 'Registrar',
    updated_date: 'Updated Date',
    creation_date: 'Creation Date',
    expiration_date: 'Expiration Date',
    name_servers: 'Name Servers',
    status: 'Status',
    org: 'Organization',
    country: 'Country'
  };

  return createContent(data, keyReplacements);
};

const IPThreatIntelOffcanvas = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    state: {
      auroraDataResponse = {},
      auroraDataError,
      auroraDataLoading,
      auroraDataModalShow,
      auroraDataSelectedValue = ''
    },
    setState
  } = useExplore();

  const handleClose = () => {
    setState('auroraDataLoading', false);
    setState('auroraDataModalShow', false);
    setState('auroraDataResponse', {});
    setState('auroraDataSelectedValue', '');
  };

  const ipData = auroraDataResponse['IP-API'] || {};
  const lat = parseFloat(ipData.lat);
  const lon = parseFloat(ipData.lon);
  const hasValidCoords = !isNaN(lat) && !isNaN(lon);

  const tabOrder = [
    { key: 'IP-API', label: 'Host' },
    { key: 'AbuseIPDB', label: 'Abuse IPDB' },
    { key: 'Shodan', label: 'Shodan' },
    { key: 'VirusTotal', label: 'VirusTotal' },
    { key: 'WHOIS', label: 'WHOIS' }
  ];

  const [activeTab, setActiveTab] = useState('IP-API');
  const offcanvasBodyStyle = {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 200px)' // Adjust based on your header height
  };

  const renderTabContent = () => {
    if (auroraDataLoading) {
      return null;
    }
    if (auroraDataError) {
      return <NoDataMessage message='An error occurred while fetching data' />;
    }

    switch (activeTab) {
      case 'IP-API':
        return <HostTabContent data={auroraDataResponse['IP-API']} />;
      case 'AbuseIPDB':
        return <AbuseIPDBTabContent data={auroraDataResponse['AbuseIPDB']} />;
      case 'Shodan':
        return <ShodanTabContent data={auroraDataResponse['Shodan']} />;
      case 'VirusTotal':
        return <VirusTotalTabContent data={auroraDataResponse['VirusTotal']} />;
      case 'WHOIS':
        return <WHOISTabContent data={auroraDataResponse['WHOIS']} />;
      default:
        return <NoDataMessage />;
    }
  };

  return (
    <Offcanvas
      show={auroraDataModalShow}
      onHide={handleClose}
      placement='end'
      className='border-left border-card offcanvas-responsive'>
      <div className='position-relative'>
        <Offcanvas.Header
          className='pt-2 px-1 pb-0 border-bottom'
          closeButton
          closeVariant={isDark ? 'white' : 'black'}>
          <div className='w-100'>
            <Offcanvas.Title as='div' className='px-1'>
              <h4 className='text-900'>
                <div className='d-flex'>
                  <FontAwesomeIcon
                    icon={faCrosshairs}
                    className='me-2 fs-2 text-900'
                    transform='down-1'
                  />
                  <div className='d-flex flex-column'>
                    {auroraDataLoading ? (
                      <span>
                        Fetching details for: <br />
                      </span>
                    ) : auroraDataError ? (
                      <span>
                        Error retrieving details for: <br />
                      </span>
                    ) : (
                      <span>
                        Aurora Threat Intelligence <br />
                      </span>
                    )}
                    <code className='fs-0'>{auroraDataSelectedValue}</code>
                  </div>
                </div>
              </h4>
            </Offcanvas.Title>
          </div>
        </Offcanvas.Header>
        <div className='border-top border-bottom'>
          <WorldMap
            points={hasValidCoords ? [[lon, lat]] : []}
            indicator={auroraDataSelectedValue}
            locationInfo={`${ipData.city || ''}, ${ipData.regionName || ''} ${
              ipData.zip || ''
            }\n${ipData.country || ''}`}
            asnInfo={ipData.as || ''}
          />
        </div>
        <div className='d-flex justify-content-between pt-1 px-1'>
          {tabOrder.map(({ key, label }) => (
            <Button
              size='sm'
              key={key}
              variant='outline-primary'
              className={`btn btn-link text-decoration-none hover-1100 mb-0 ${
                activeTab === key ? 'text-primary' : 'text-secondary'
              }`}
              onClick={() => setActiveTab(key)}>
              {label}
            </Button>
          ))}
        </div>

        <Offcanvas.Body
          style={offcanvasBodyStyle}
          className='scrollbar pb-10 px-3 pt-2 position-relative'>
          {renderTabContent()}
          <LoadingIndicatorOverlay
            loading={auroraDataLoading}
            position='top'
            semiTransparent={true}
            style={{ zIndex: 1 }}
          />
        </Offcanvas.Body>
      </div>
    </Offcanvas>
  );
};

NoDataMessage.propTypes = {
  message: PropTypes.string
};

HostTabContent.propTypes =
  ShodanTabContent.propTypes =
  AbuseIPDBTabContent.propTypes =
  VirusTotalTabContent.propTypes =
  WHOISTabContent.propTypes =
    {
      data: PropTypes.object
    };

export default IPThreatIntelOffcanvas;
