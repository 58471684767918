import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import AppliancesWrapper from './AppliancesWrapper';

/**
 * Main appliances component
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const Appliances = () => {
  return (
    <>
      <WidgetSectionTitle title='Appliance Management' className='mb-2' />
      <AppliancesWrapper />
    </>
  );
};

export default Appliances;
