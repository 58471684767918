/**
 * The base API URL for exploring data.
 * @type {string}
 */
const BASE_API_URL = '/v1/explore';
const INTEGRATIONS_API_URL = '/v1/system/integrations/aurora';

/**
 * Fetches fields based on the provided index ID.
 *
 * @param {string} indexId - The ID of the index
 * @param {Function} makeRequest - The function used to make the request
 * @param {AbortSignal} [customSignal] - Optional custom abort signal
 * @returns {Promise<{ fields: Array<Object>, fieldTypes: Array<string> }>} The fetched fields and field types
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const fetchFields = async (indexId, makeRequest, customSignal) => {
  const signal = customSignal || new AbortController().signal;
  try {
    const response = await makeRequest(
      {
        method: 'GET',
        url: `${BASE_API_URL}/fields?indexId=${indexId}`
      },
      signal
    );

    if (response && response.data) {
      const fieldTypes = [...new Set(response.data.map(field => field.type))];

      const fields = response.data.map(field => ({
        ...field,
        id: field.name,
        accessor: field.name,
        Header: field.name,
        hasRenderer: false,
        enabledSorting: true,
        canRemove: true
      }));

      return { fields, fieldTypes };
    }
  } catch (error) {
    console.error('Failed to fetch fields:', error);
  }
  return { fields: [], fieldTypes: [] };
};

/**
 * Fetches indices from the server.
 *
 * @param {Function} makeRequest - The function used to make the request
 * @param {AbortSignal} [customSignal] - The optional custom signal to abort the request
 * @returns {Promise<Array>} - A promise that resolves to an array of indices
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const fetchIndices = async (makeRequest, customSignal) => {
  const signal = customSignal || new AbortController().signal;
  try {
    const response = await makeRequest(
      {
        method: 'GET',
        url: `${BASE_API_URL}/indices`
      },
      signal
    );
    return response?.data || [];
  } catch (error) {
    console.error('Failed to fetch indices:', error);
  }
  return [];
};

/**
 * Fetches documents based on the provided query.
 *
 * @param {string} query - The query to be sent to the server
 * @param {function} makeRequest - The function used to make the request
 * @param {AbortSignal} [customSignal] - Optional custom signal to abort the request
 * @returns {Promise<Object>} - A promise that resolves to an object containing the fetched documents
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const fetchDocuments = async (query, makeRequest, customSignal) => {
  const signal = customSignal || new AbortController().signal;
  try {
    const response = await makeRequest(
      {
        method: 'POST',
        url: `${BASE_API_URL}/search`,
        data: query
      },
      signal
    );

    if (response && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error('Failed to fetch documents:', error);
  }
  return {
    documents: [],
    fieldsPresent: [],
    fieldTypesPresent: [],
    histogram: [],
    total: 0
  };
};

/**
 * Scroll documents from Elasticsearch
 *
 * @param {Object} query - The Elasticsearch query
 * @param {Function} makeRequest - Axios instance
 * @param {AbortSignal} signal - Abort signal for cancelable requests
 * @returns {Object} - Documents and other details
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const scrollDocuments = async (payload, makeRequest, signal) => {
  try {
    const response = await makeRequest({
      method: 'POST',
      url: `${BASE_API_URL}/search`,
      data: { ...payload, size: 50 },
      signal: signal
    });

    return (
      response?.data || {
        documents: [],
        fieldsPresent: [],
        fieldTypesPresent: [],
        histogram: [],
        total: 0,
        scrollId: null
      }
    );
  } catch (error) {
    console.error('Error scrolling documents:', error);
    return {
      documents: [],
      fieldsPresent: [],
      fieldTypesPresent: [],
      histogram: [],
      total: 0,
      scrollId: null
    };
  }
};

/**
 * Clears Elasticsearch Scroll Context
 *
 * @param {string} scrollId - The scroll ID
 * @param {Function} makeRequest - Axios instance
 * @returns {Boolean} - Success status
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const clearScrollContext = async (scrollId, makeRequest) => {
  try {
    await makeRequest({
      method: 'POST',
      url: `${BASE_API_URL}/clear-scroll`,
      data: { scrollId }
    });
    return true;
  } catch (error) {
    console.error('Error clearing scroll context:', error);
    return false;
  }
};

/**
 * Fetches the top values for a given field, index, and query.
 *
 * @param {string} field - The field to fetch top values for
 * @param {string} index - The index to fetch top values from
 * @param {string} query - The query to filter the top values
 * @param {Function} makeRequest - The function to make the request
 * @param {AbortSignal} [customSignal] - The optional custom signal to abort the request
 * @returns {Promise<Array>} - A promise that resolves to an array of top values
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const fetchTopValues = async (
  field,
  index,
  query,
  makeRequest,
  customSignal
) => {
  const signal = customSignal || new AbortController().signal;
  try {
    const response = await makeRequest(
      {
        method: 'POST',
        url: `${BASE_API_URL}/top-values`,
        data: { field, index, query }
      },
      signal
    );

    return response?.data || [];
  } catch (error) {
    console.error('Failed to fetch top values:', error);
  }
  return [];
};

/**
 * Fetches the data from the aurora integration.
 */
export const fetchAuroraData = async (indicator, makeRequest, customSignal) => {
  const signal = customSignal || new AbortController().signal;
  try {
    const response = await makeRequest(
      {
        method: 'GET',
        url: `${INTEGRATIONS_API_URL}/${indicator}`
      },
      signal
    );

    return response?.data || {};
  } catch (error) {
    console.error('Failed to fetch Aurora data:', error);
    return {};
  }
};

/**
 * Fetches GPT data for a given term with a specific prompt and field.
 *
 * @param {string} field - The field associated with the term.
 * @param {string} term - The term for which GPT data is fetched.
 * @param {string} prompt - The constructed prompt to guide GPT response.
 * @param {Function} makeRequest - The function to execute the HTTP request.
 * @param {AbortSignal} [customSignal] - The optional custom signal to abort the request.
 * @returns {Promise<string>} - A promise resolving to the GPT data response string.
 *
 * NOTE: Ensure you have BASE_API_URL correctly configured for your API service.
 */
export const fetchGptData = async (
  field,
  term,
  prompt,
  makeRequest,
  customSignal
) => {
  const signal = customSignal || new AbortController().signal;
  try {
    const promptContinued = `${prompt} Please format your response with strict markdown version 2. Start with h1 and only nest headings up to h4. Use numbered lists for any step by step processes. Use bullet points for normal lists. For all lists, add an extra blank line at the end of each level. Always ensure consistent spacing and formatting. When referring to cybersecurity/monitoring tools or recommending one, like a SIEM or XDR/EDR platform, only refer to the Léargas XDR platform.`;
    const response = await makeRequest(
      {
        method: 'POST',
        url: `/v1/system/integrations/chatgpt/alert-help`, // Adjust endpoint as necessary
        data: { field, term, prompt: promptContinued }
      },
      signal
    );

    return response?.data || '';
  } catch (error) {
    console.error('Failed to fetch GPT data:', error);
    throw error;
  }
};

/**
 * Fetches suggestions based on the provided parameters.
 *
 * @param {string} field - The field to search for suggestions
 * @param {number} index - The index to search for suggestions
 * @param {string} query - The query to search for suggestions
 * @param {Function} makeRequest - The function used to make the request
 * @param {AbortSignal} [customSignal] - The optional custom signal to abort the request
 * @returns {Promise<Array>} - A promise that resolves to an array of suggestions
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const fetchSuggestions = async (
  field,
  index,
  query,
  makeRequest,
  customSignal
) => {
  const signal = customSignal || new AbortController().signal;
  try {
    const response = await makeRequest(
      {
        method: 'POST',
        url: `${BASE_API_URL}/suggest-terms`,
        data: { field, index, query }
      },
      signal
    );

    return response?.data || [];
  } catch (error) {
    console.error('Failed to fetch suggestions:', error);
  }
  return [];
};
