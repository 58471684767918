import { axiosPrivate } from 'api/connection/axios';
import { useEffect } from 'react';
import { useAuthentication } from './useAuthentication';
import { useRefreshToken } from './useRefreshToken';

/**
 * Custom hook for making private axios requests with authentication
 * It adds an access token to the request header if it exists, and handles token refreshing and retrying failed requests
 *
 * @returns {AxiosInstance} The axios instance with interceptors for authentication
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
export const useAxiosPrivate = () => {
  const { refresh } = useRefreshToken();
  const { authentication } = useAuthentication();

  /**
   * Adds interceptors to the axios instance when the authentication object changes
   */
  useEffect(() => {
    /**
     * Axios interceptor for handling requests.
     *
     * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
     * @since 0.1.0-beta.1
     * @version 0.1.0-beta.1
     */
    const requestIntercept = axiosPrivate.instance.interceptors.request.use(
      config => {
        if (!config.headers['Authorization']) {
          config.headers[
            'Authorization'
          ] = `Bearer ${authentication?.accessToken}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    /**
     * Interceptor for handling responses in axiosPrivate instance.
     * If a 403 error is encountered and the previous request was not sent,
     * it refreshes the access token and retries the previous request with the updated token.
     * @param {Object} response - The response object.
     * @returns {Object} - The response object or a rejected promise if an error occurs.
     *
     * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
     * @since 0.1.0-beta.1
     * @version 0.1.0-beta.1
     */
    const responseIntercept = axiosPrivate.instance.interceptors.response.use(
      response => response,
      async error => {
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const {
            authentication: { accessToken }
          } = await refresh();
          prevRequest.headers['Authorization'] = `Bearer ${accessToken}`;
          return axiosPrivate.instance(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    /**
     * Remove the interceptors when component unmounts
     */
    return () => {
      axiosPrivate.instance.interceptors.request.eject(requestIntercept);
      axiosPrivate.instance.interceptors.response.eject(responseIntercept);
    };
  }, [authentication?.accessToken, refresh]);

  return {
    axiosPrivate: axiosPrivate.instance,
    cancelAllRequests: axiosPrivate.cancelAllRequests
  };
};

export default useAxiosPrivate;
