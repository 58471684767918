import { useEffect, useState } from 'react';
// import MultiSelect from 'components/common/MultiSelect';
import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useSavedSearches from 'hooks/user-contexts/useUserSavedSearches';

// import useOrganizations from 'hooks/admin-contexts/useOrganizations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
/**
 * Renders the SavedSearchesEditForm component
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const SavedSearchesEditForm = () => {
  const {
    savedSearches: {
      editSavedSearchesModal: { defaultData }
    },
    setSavedSearches
  } = useSavedSearches();
  const { axiosPrivate } = useAxiosPrivate();

  // const { organizations } = useOrganizations();

  const {
    handleSubmit,

    formState: { errors },
    watch,
    reset,
    register
  } = useForm();

  const { name } = watch();

  const [loading, setLoading] = useState(false);

  /**
   * @description form for user creation
   * @param {Object} data
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  const onSubmit = async data => {
    try {
      setLoading(true);
      let newData = data;

      newData['filters'] = defaultData.filters;
      newData['fields'] = defaultData.fields
        .filter(item => item._id)
        .map(item => item._id);
      newData['indices'] = defaultData.indices
        .filter(item => item._id)
        .map(item => item._id);
      newData['_dashboard'] = defaultData._dashboard;

      const res = await LeargasAPI.AppSavedSearches.updateSavedSearches(
        defaultData?._id,
        newData,
        axiosPrivate
      );

      if (res instanceof Error) throw res;

      const { message } = res.data;
      setLoading(false);
      setSavedSearches(prevState => ({
        ...prevState,
        editSavedSearchesModal: { open: false },
        fetch: true
      }));
      toast.success(message);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.', error);
      }

      setLoading(false);
      setSavedSearches(prevState => ({
        ...prevState,
        editSavedSearchesModal: { open: false },
        fetch: false
      }));
      if (error?.data?.response?.data?.message) {
        toast.error(error?.data?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  /**
   * default data set
   */
  useEffect(() => {
    if (defaultData) {
      reset({
        name: defaultData?.name,
        description: defaultData?.description
      });
    }
  }, [defaultData, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className='g-3 mb-3'>
        <Col sm={12}>
          <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
            Name <span className='text-danger ms-1'>*</span>
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='The name of the saved search.'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <Form.Control
            {...register('name', { required: 'Query Name is required' })}
            type='text'
            autoComplete='off'
            className={`fs--1 ${errors.name ? 'is-invalid' : ''}`}
          />
          <code>{errors.name?.message}</code>
        </Col>
        <Col sm={12}>
          <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
            Description
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='A description for the saved search and what its purpose is.'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <Form.Control
            {...register('description')}
            type='text'
            as='textarea'
            rows={3}
            autoComplete='off'
            className={`fs--1 ${errors.description ? 'is-invalid' : ''}`}
          />
          <code>{errors.description?.message}</code>
        </Col>
      </Row>

      <Modal.Footer className='border-top-0 pb-2'>
        <Flex justifyContent='end'>
          <Button
            variant='secondary'
            className='me-2'
            size='sm'
            onClick={() =>
              setSavedSearches(prevState => ({
                ...prevState,
                editSavedSearchesModal: { open: false, data: {}, formData: {} }
              }))
            }>
            Cancel
          </Button>

          <Button
            variant='success'
            size='sm'
            type='submit'
            disabled={!name}
            className='d-flex'>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <ButtonSpinner spinning={loading} />
              <span>{loading ? 'Editing' : 'Edit'}</span>
            </Flex>
          </Button>
        </Flex>
      </Modal.Footer>
    </Form>
  );
};

export default SavedSearchesEditForm;
