import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleSpinner from 'components/utilities/AppSpinner/smallSpinner';
import { Link } from 'react-router-dom';

import routes from 'config/routeSettings';
import React from 'react';

const EndPointThreat = React.memo(({ statHistogram, wrapperLoader }) => {
  return (
    <Flex direction='column'>
      <h6 className='mt-0 ms-0 text-nowrap position-relative  text-700 fw-normal'>
        Endpoint Threats
        {wrapperLoader && (
          <span
            className='fw-light position-absolute  end-0 z-index-2'
            style={{ marginRight: '15px' }}>
            <SimpleSpinner size='sm' />
          </span>
        )}
      </h6>
      <h6 className='text-700 fs-0 mb-0'>
        {!wrapperLoader &&
        statHistogram?.data &&
        Array.isArray(statHistogram?.data)
          ? Number(
              statHistogram?.data?.reduce(
                (sum, entry) => sum + entry.doc_count,
                0
              )
            ).toLocaleString()
          : 0}
        <Link
          to={routes.ARTIFACTS_ENDPOINT_SENTINELONE_THREATS}
          className='text-decoration-none ms-2'>
          <FontAwesomeIcon
            icon='external-link-alt'
            transform='up-3'
            className='fs--2'
          />
        </Link>
      </h6>
    </Flex>
  );
});

EndPointThreat.propTypes = {
  wrapperLoader: PropTypes.bool,

  statHistogram: PropTypes.object
};
export default EndPointThreat;
