import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const SoftBadge = ({ bg = 'primary', pill, children, className }) => {
  return (
    <div
      className={classNames(className, `badge badge-soft-${bg}`, {
        'rounded-pill': pill
      })}>
      {children}
    </div>
  );
};

SoftBadge.propTypes = {
  bg: PropTypes.string,
  pill: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
};

export default SoftBadge;
