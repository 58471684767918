import AddFilterModal from 'components/search-filters/modals/AddFilterModal';
import EditFilterModal from 'components/search-filters/modals/EditFilterModal';
import EditSearchStringModal from 'components/search-filters/modals/EditSearchStringModal';
import useSavedSearches from 'hooks/admin-contexts/useSavedSearches';
import useExplore from 'hooks/useExplore';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import {
  filtersActions,
  filtersBulkActions,
  filtersBulkActionsGroups,
  filtersOperators,
  modalFilterAddDefaults
} from './settings';

/**
 * The SearchFiltersContext settings object.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const filtersSettings = {
  actions: filtersActions,
  bulkActions: filtersBulkActions,
  bulkActionsGroups: filtersBulkActionsGroups,
  debug: true,
  filters: [],
  filtersLoading: false,
  loading: false,
  modalFilterAdd: {
    open: false,
    data: modalFilterAddDefaults,
    defaultData: modalFilterAddDefaults
  },
  modalFilterEdit: { open: false, data: {} },
  modalSearchEdit: { open: false, data: {} },
  operators: filtersOperators
};

/**
 * Create SearchFiltersContext object.
 *
 * @type {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const SearchFiltersContext = createContext(filtersSettings);

const SearchFiltersProvider = ({ children }) => {
  // const { setHasUnsavedChanges } = useUnsavedChanges();
  const location = useLocation();

  const { setState: setExploreState } = useExplore();

  const [searchFilters, setSearchFilters] = useState(filtersSettings);
  const { getSavedSearchById } = useSavedSearches();

  /**
   *
   * @param {Object} filter - The filter to apply
   * @param {string} filter.customLabel - The custom label for the filter
   * @param {boolean} filter.enabled - The enabled state of the filter
   * @param {Object} filter.field - The field object for the filter
   * @param {boolean} filter.inclusion - The inclusion state of the filter
   * @param {string} filter.operator - The operator for the filter
   * @param {boolean} filter.pinned - The pinned state of the filter
   * @param {boolean} filter.useCustomLabel - The useCustomLabel state of the filter
   * @param {any} filter.value - The value to filter on
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const applyFilter = useCallback(
    ({
      customLabel = '',
      enabled = true,
      field = null,
      inclusion = true,
      operator,
      pinned = false,
      useCustomLabel = false,
      value
    }) => {
      const filter = {
        id: uuid(),
        customLabel,
        enabled,
        field,
        inclusion,
        operator,
        pinned,
        useCustomLabel,
        value
      };
      setSearchFilters(prev => ({
        ...prev,
        filters: [...prev.filters, filter]
      }));
    },
    [setSearchFilters, searchFilters.filters, searchFilters.operators]
  );

  /**
   * Applies an action to a single filter.
   *
   * @param {Object} filter - The filter to apply the action to
   * @param {string} action - The action to apply to the filter
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   *
   * @see filtersActions
   * @see useCallback - https://react.dev/reference/react/useCallback
   */
  const applyAction = useCallback(
    (filter, actionKey) => {
      const { filters } = searchFilters;
      const action = searchFilters.actions[actionKey];
      // Ensure there is a function assigned to the callback
      if (action && typeof action.Callback === 'function') {
        const updatedFilters = action.Callback(filters, filter);
        setSearchFilters(prev => ({
          ...prev,
          filters: updatedFilters
        }));
        if (process.env.NODE_ENV === 'development') {
          console.debug('Action applied to filter:', actionKey, filter);
        }
      } else {
        console.error(`No valid action or callback for action ${actionKey}`);
      }
    },
    [searchFilters.filters, searchFilters.actions]
  );

  /**
   * Applies a bulk action to the search filters.
   *
   * The bulk action is determined by the action parameter.
   * The action parameter is used to select the appropriate callback function.
   * The callback function is used to update the search filters.
   * `useCallback` is used to memoize the callback function.
   *
   * @param {string} action - The action to apply to all search filters
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   *
   * @see filtersBulkActions
   * @see useCallback - https://react.dev/reference/react/useCallback
   */
  const applyBulkAction = useCallback(
    actionKey => {
      const { filters } = searchFilters; // Assume filters is properly populated
      const bulkAction = searchFilters.bulkActions[actionKey];
      // Ensure there is a function assigned to the callback
      if (bulkAction && typeof bulkAction.Callback === 'function') {
        const updatedFilters = bulkAction.Callback(filters);
        setSearchFilters(prev => ({
          ...prev,
          filters: updatedFilters
        }));
        if (process.env.NODE_ENV === 'development') {
          console.debug('Bulk action applied:', actionKey);
        }
      } else {
        console.error(
          `No valid bulk action or callback for bulk action ${actionKey}`
        );
      }
    },
    [searchFilters.filters, searchFilters.bulkActions]
  );

  /**
   * Loads a saved search and applies the filters, indices, and fields in the ExploreContext.
   *
   * @param {Object} savedSearch - The saved search object containing the filters, indices, and fields.
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const loadSavedSearch = useCallback(
    savedSearch => {
      setExploreState('savedSearch', savedSearch);
      setExploreState('indexSelected', savedSearch.indices[0]);
      setSearchFilters(prev => ({
        ...prev,
        filters: savedSearch.filters
      }));

      setExploreState('fieldsSelected', savedSearch.fields);
    },
    [setSearchFilters]
  );

  /**
   * Opens the edit filter modal and populates it with the given filters' data.
   *
   * @param {string} filterId - The ID of the filter to edit.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const openEditFilterModal = useCallback(
    filterId => {
      const filterToEdit = searchFilters.filters.find(
        filter => filter.id === filterId
      );
      setSearchFilters(prev => ({
        ...prev,
        modalFilterEdit: {
          open: true,
          data: filterToEdit
        }
      }));
    },
    [searchFilters.filters]
  );
  /**
   * Opens the edit search text modal and populates the string field
   *
   * @param {string} filterId - The ID of the filter to edit.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const OpenEditSearchStringModal = useCallback(
    filterId => {
      const filterToEdit = searchFilters.filters.find(
        filter => filter.id === filterId
      );
      // console.log('filtertoedit', filterToEdit);

      setSearchFilters(prev => ({
        ...prev,
        modalSearchEdit: {
          open: true,
          data: filterToEdit
        }
      }));
    },
    [searchFilters.filters]
  );

  /**
   *
   * @param {string} search -
   *
   * @author Wesal Nowsher <Wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   *
   */
  const getQueryParams = search => {
    return new URLSearchParams(search);
  };

  const fetchSavedSearch = async id => {
    const savedSearch = await getSavedSearchById(id);
    return savedSearch;
  };

  useEffect(() => {
    const queryParams = getQueryParams(location?.search);
    const savedSearchId = queryParams.get('savedSearchId');

    if (savedSearchId) {
      fetchSavedSearch(savedSearchId).then(savedSearch => {
        if (savedSearch) {
          setExploreState('savedSearch', savedSearch);
          setExploreState('filters', savedSearch.filters);
          setSearchFilters(prev => ({
            ...prev,
            filters: [...savedSearch.filters]
          }));
        } else {
          setExploreState('savedSearch', {});
          console.warn(`Saved search with ID ${savedSearchId} not found.`);
        }
      });
    } else {
      setExploreState('savedSearch', {});
    }
  }, [location.search]);

  /**
   * Clear all unpinned filters from both contexts on location change but check for savedsearch
   */
  useEffect(() => {
    const queryParams = getQueryParams(location?.search);
    const savedSearchId = queryParams.get('savedSearchId');

    if (!savedSearchId) {
      const pinnedFilters = searchFilters.filters.filter(
        filter => filter.pinned
      );
      setSearchFilters(prev => ({
        ...prev,
        filters: pinnedFilters
      }));
      setExploreState('filters', pinnedFilters);
    }
  }, [location.pathname]);

  /**
   * Builds and updates the query state in ExploreContext when dependencies update or change.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    if (searchFilters.filters) {
      setExploreState('filters', searchFilters.filters);
      if (searchFilters.debug) {
        console.debug(
          `FiltersProvider: Filters updated in ExploreContext`,
          searchFilters.filters
        );
      }
    }
  }, [searchFilters.filters]);

  /**
   * Determines if there are any unsaved changes to the search filters.
   *
   * Sets the hasUnsavedChanges state accordingly.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  // useEffect(() => {
  //   if (searchFilters?.filters && searchFilters.filters.length > 0) {
  //     setHasUnsavedChanges(true);
  //   } else {
  //     setHasUnsavedChanges(false);
  //   }
  // }, [searchFilters.filters]);

  /**
   * The values to provide to the context.
   *
   * @property {Object} searchFilters - The search filters
   * @property {Function} setSearchFilters - The function to set the search filters
   * @property {Function} applyBulkAction - The function to apply a bulk action to all search filters
   * @property {Function} applyAction - The function to apply an action to a single filter
   */
  const c = {
    searchFilters,
    setSearchFilters,
    applyAction,
    applyBulkAction,
    applyFilter,
    loadSavedSearch,
    openEditFilterModal,
    OpenEditSearchStringModal
  };

  return (
    <SearchFiltersContext.Provider value={c}>
      {children}
      {/* Modals that should be available to all children consuming the context */}
      <AddFilterModal />
      <EditFilterModal />

      <EditSearchStringModal />
    </SearchFiltersContext.Provider>
  );
};

SearchFiltersProvider.propTypes = {
  children: PropTypes.node
};

export default SearchFiltersProvider;

// Builtin hook to access Filters context
export const useSearchFilters = () => {
  return useContext(SearchFiltersContext);
};
