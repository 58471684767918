import { useContext } from 'react';
import { AuthenticationContext } from 'context/AuthenticationProvider';

/**
 * Custom hook that returns the authentication context
 *
 * @returns {AuthenticationContext} The authentication context
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
export const useAuthentication = () => {
  return useContext(AuthenticationContext);
};

export default useAuthentication;
