import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useBookmarks from 'hooks/user-contexts/useUserBookmarks';
import React, { useEffect, useState } from 'react';
import BookmarksTable from './BookmarksTable';
import BookmarksTableFilters from './BookmarksTableFilters';
import BookmarksTableFooter from './BookmarksTableFooter';
import BookmarksTablePagination from './BookmarksTablePagination';
import BookmarksTableWrapper from './BookmarksTableWrapper';

import { Card } from 'react-bootstrap';
import { getBookmarkColumns } from './Columns/ColumnsDefaults';

/**
 * bookmarks
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Bookmarks = () => {
  const {
    bookmarks: { data, showDeleted },
    loading: componentLoading,
    setBookmarks
  } = useBookmarks();
  const [columns, setColumns] = useState([]);

  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    setColumns(getBookmarkColumns(setBookmarks));
  }, []);

  return (
    <Card className='shadow-none border'>
      {/* <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          {/* <span className='d-block'>Bookmarks</span> */}
      {/* <Flex justifyContent='end' alignItems='center'>
            <ShowDeletedButton
              showDeleted={showDeleted}
              onClick={() => {
                setBookmarks(prevState => ({
                  ...prevState,
                  showDeleted: !showDeleted
                }));
              }}
            />
            <AddButton
              onClick={() =>
                setBookmarks(prevState => ({
                  ...prevState,
                  addBookmarkModal: { open: true }
                }))
              }
            />
          </Flex> 
        </Flex>
      </Card.Header> */}
      <Card.Body className='position-relative'>
        {componentLoading ? (
          <LoadingSpinner grow='10' />
        ) : (
          <BookmarksTableWrapper
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            // data={bookmarks}
            data={
              showDeleted
                ? data
                : data?.filter(bookmark => !bookmark?.isDeleted)
            }
            sortable
            loading={componentLoading}
            pagination
            perPage={50}>
            <BookmarksTableFilters
              table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <BookmarksTable table columns={columns} />
            {/* <AddButton
              label='Add a bookmark'
              onClick={() =>
                setBookmarks(prevState => ({
                  ...prevState,
                  addBookmarkModal: { open: true }
                }))
              }
            /> */}
            <div className='mt-3 d-flex justify-content-between'>
              <BookmarksTableFooter
                table
                rowInfo
                data={
                  showDeleted
                    ? data
                    : data?.filter(bookmark => !bookmark?.isDeleted)
                }
                rowCount={
                  showDeleted
                    ? data?.length
                    : data?.filter(bookmark => !bookmark?.isDeleted).length
                }
              />
              <BookmarksTablePagination table />
            </div>
          </BookmarksTableWrapper>
        )}
      </Card.Body>
    </Card>
  );
};

export default Bookmarks;
