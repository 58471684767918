import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import { useSearchFilters } from 'context/FiltersProvider';
import useApplication from 'hooks/useApplication';
import useExplore from 'hooks/useExplore';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap';

/**
 * The EditFilterModal component.
 *
 * This component renders a modal for editing a search filter.
 * It provides options for modifying the search text and pinning the filter.
 *
 * @returns {JSX.Element} The rendered EditFilterModal component.
 */
const EditFilterModal = () => {
  const modalRef = useRef(null);
  const applyButtonRef = useRef(null);

  const {
    application: { isDark }
  } = useApplication();

  const {
    state: { indexSelected }
  } = useExplore();

  const {
    searchFilters: { filters, modalSearchEdit },
    setSearchFilters
  } = useSearchFilters();

  const [inputString, setInputString] = useState('');
  const [pinned, setPinned] = useState(false);

  /**
   * Resets the state of the EditFilterModal component.
   */
  const handleReset = () => {
    setInputString('');
    setPinned(false);
    setSearchFilters(prev => ({
      ...prev,
      modalSearchEdit: {
        ...prev.modalSearchEdit,
        data: {
          ...prev.modalSearchEdit.defaultData
        },
        open: false
      }
    }));
  };

  /**
   * Handles the cancel action for the EditFilterModal component.
   */
  const handleCancel = () => {
    handleReset();
  };

  /**
   * Handles the change event for the search text input.
   *
   * @param {string} value - The new value of the search text.
   */
  const handleTermChange = value => {
    setInputString(value);
    setSearchFilters(prev => ({
      ...prev,
      modalSearchEdit: {
        ...prev.modalSearchEdit,
        data: {
          ...prev.modalSearchEdit.data,
          value: value,
          customLabel: value
        }
      }
    }));
  };

  /**
   * Handles the toggle event for the pinnedFilter checkbox.
   *
   * @param {Event} e - The change event object.
   */
  const handleTogglePinned = e => {
    const isPinned = e.target.checked;
    setPinned(isPinned);
    setSearchFilters(prev => ({
      ...prev,
      modalSearchEdit: {
        ...prev.modalSearchEdit,
        data: {
          ...prev.modalSearchEdit.data,
          pinned: isPinned
        }
      }
    }));
  };

  /**
   * Handles saving the edited filter to the search filters state.
   */
  const handleSaveFilter = () => {
    try {
      const formData = { ...modalSearchEdit.data };
      // Update the filter with the new data
      const newFilters = filters.map(filter => {
        if (filter.id === formData.id) {
          return {
            ...filter,
            ...formData
          };
        }
        return filter;
      });

      setSearchFilters(prev => ({
        ...prev,
        filters: newFilters,
        modalSearchEdit: {
          ...prev.modalSearchEdit,
          open: false
        }
      }));
    } catch (error) {
      console.error('Error saving filter:', error);
      return;
    }

    // Reset the state after saving
    handleReset();
  };

  /**
   * Clear all data when indexSelected changes.
   */
  useEffect(() => {
    handleReset();
  }, [indexSelected]);

  /**
   * Update the term value when the modalSearchEdit data changes.
   */
  useEffect(() => {
    if (modalSearchEdit.open && modalSearchEdit.data.value) {
      setInputString(modalSearchEdit.data.value);
    } else {
      setInputString('');
    }
  }, [modalSearchEdit.open, modalSearchEdit.data.value]);

  /**
   * Update the pinned value when the modalSearchEdit data changes.
   */
  useEffect(() => {
    if (modalSearchEdit.open) {
      setPinned(Boolean(modalSearchEdit.data.pinned));
    } else {
      setPinned(false);
    }
  }, [modalSearchEdit.open, modalSearchEdit.data.pinned]);

  return (
    <Modal
      aria-labelledby='searchFiltersModalEditFilter'
      centered
      contentClassName='shadow-none'
      keyboard={true}
      onHide={handleCancel}
      ref={modalRef}
      show={modalSearchEdit.open}
      size='lg'>
      <Flex
        className='card border border-card shadow-none'
        direction='column'
        justifyContent='between'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex mb-1 fs-0 fw-normal position-relative align-items-center'>
            Edit filter{' '}
            <AdvancedPopover
              containerId='searchFiltersModalEditFilter'
              placement='top'
              popoverText='Edit the search text for the filter.'
              showArrow={true}>
              <FontAwesomeIcon
                className='ms-1 fs--1 cursor-pointer text-400'
                icon={['far', 'question-circle']}
              />
            </AdvancedPopover>
          </h5>
        </Modal.Header>

        <Modal.Body className='p-2 position-relative'>
          <Row>
            <Col>
              <Row className='mb-3'>
                <Col>
                  <FormGroup>
                    <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
                      Search Text{' '}
                      <AdvancedPopover
                        containerId='searchFiltersModalEditFilter'
                        placement='top'
                        popoverText='Modify the search term for the filter using the input field below.'
                        showArrow={true}>
                        <FontAwesomeIcon
                          className='ms-1 text-400 fs--1 cursor-pointer'
                          icon={['far', 'question-circle']}
                        />
                      </AdvancedPopover>
                    </Form.Label>
                    <Form.Control
                      onChange={e => handleTermChange(e.target.value)}
                      type='text'
                      value={inputString}
                      autoComplete='off'
                      className='fs--1 ps-2 py-2'
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className='mb-2'>
                <Col>
                  <FormGroup
                    className='my-0 fs--1 d-flex'
                    id='filterPinnedSwitchGroup'>
                    <Form.Check
                      checked={pinned}
                      id='filterPinnedSwitch'
                      name='pinned'
                      onChange={handleTogglePinned}
                      type='switch'
                    />
                    <Form.Label
                      className='text-700 fs--1 d-flex align-items-center fw-normal mb-0'
                      htmlFor='filterPinnedSwitch'>
                      Pin this filter?{' '}
                      <AdvancedPopover
                        containerId='filterPinnedSwitchGroup'
                        placement='top'
                        popoverText='If set, the filter will be pinned to the top of the list and will persist when moving to another dashboard or index, as long as the target index has the same field.'>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className='p-2'>
          <Row className='g-1 m-0 p-0 w-100'>
            <Col xs={12} lg={12} className='p-0'>
              <Flex direction='row' justifyContent='end'>
                <Button
                  className='me-2'
                  onClick={handleCancel}
                  size='sm'
                  variant='secondary'>
                  Cancel
                </Button>
                <Button
                  ref={applyButtonRef}
                  onClick={handleSaveFilter}
                  size='sm'
                  variant='success'>
                  Save changes
                </Button>
              </Flex>
            </Col>
          </Row>
        </Modal.Footer>
      </Flex>
    </Modal>
  );
};

export default EditFilterModal;
