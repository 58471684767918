import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

const aws = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'CloudTrail Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 4, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-aws']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 4, md: 4, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['cardinality'],
        overriddenIndex: ['leargas-aws'],
        aggregate: {
          aggs: {
            1: {
              cardinality: {
                field: 'message.userAgent.keyword'
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'MetricPlain',
        props: {
          className: '',
          title: 'Unique User Agents'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 4, md: 4, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['cardinality'],
        overriddenIndex: ['leargas-aws'],
        aggregate: {
          aggs: {
            1: {
              cardinality: {
                field:
                  'message.userIdentity.sessionContext.sessionIssuer.userName.keyword'
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'MetricPlain',
        props: {
          className: '',
          title: 'Unique Users'
          // helpInfo:
          //   'Connections to a local device or server from a remote device using the Remote Desktop Protocol (RDP)'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['histogram'],
        overriddenIndex: ['leargas-aws']
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Event Timeline',
          helpInfo: ' '
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['map'],
        overriddenIndex: ['leargas-aws'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'source_geoip.country_name.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Map',
        props: {
          className: '',
          title: 'Event Countries',
          field: 'source_geoip.country_name'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-aws'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'message.eventCategory.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Event Categories',
          field: 'message.eventCategory'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-aws'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'message.eventType.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Event Types',
          field: 'message.eventType'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-aws'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'message.eventSource.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Event Sources',
          field: 'message.eventSource'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-aws'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'message.eventName.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Event Names',
          field: 'message.eventName'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-aws'],

        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'message.sourceIPAddress.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Source IP Addresses'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-aws'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field:
                  'message.userIdentity.sessionContext.sessionIssuer.userName.keyword',
                order: {
                  _count: 'desc'
                },
                //missing: '__missing__',
                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'User Identities'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-aws'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'message.errorCode.keyword',
                order: {
                  _count: 'desc'
                },
                //missing: '__missing__',
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'message.errorMessage.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Error Codes'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-aws'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'message.userAgent.keyword',
                order: {
                  _count: 'desc'
                },
                //missing: '__missing__',
                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'User Agents'
          }
        }
      }
    }
  ];
};

export default aws;
