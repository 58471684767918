import { rgbaColor } from 'helpers/utils';
import { useCluster } from 'hooks/admin-contexts/useCluster';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useCountUp } from 'react-countup';
import ArchSplitLineChart from './ArchSplitLineChart';

const NodeCount = () => {
  const {
    cluster: {
      clusterNodesStats: { nodes }
    }
  } = useCluster();
  const [numberOfNodesCounter, setNumberOfNodesCounter] = useState(0);
  const [masterNodeCounter, setMasterNodeCounter] = useState(0);
  const [dataNodeCounter, setDataNodeCounter] = useState(0);
  const [webNodeCounter, setWebNodeCounter] = useState(0);
  const [coordinationNodeCounter, setCoordinationNodeCounter] = useState(0);

  const getNodeChartData = () => {
    if (nodes) {
      const nodeList = Object(Object.keys(nodes).map(key => nodes[key]));

      let masterNodes = 0;
      let dataNodes = 0;
      let rolePaths = 'roles'.split('.');
      nodeList.forEach(item => {
        const roles = rolePaths.reduce(
          (acc, cur) => (acc[cur] ? acc[cur] : ''),
          item
        );
        roles.includes('master')
          ? masterNodes++
          : roles.includes('data')
          ? dataNodes++
          : null;
      });
      setMasterNodeCounter(masterNodes);
      setDataNodeCounter(dataNodes);
      let webNodes = 0;
      let coordinationNodes = 0;
      let namePaths = 'name'.split('.');
      nodeList.forEach(item => {
        const name = namePaths.reduce(
          (acc, cur) => (acc[cur] ? acc[cur] : ''),
          item
        );
        name.indexOf('WEB') > -1
          ? webNodes++
          : name.indexOf('COORD') > -1
          ? coordinationNodes++
          : null;
      });
      setWebNodeCounter(webNodes);
      setCoordinationNodeCounter(coordinationNodes);
    }
  };

  const memoizedCallback = useCallback(value => {
    return value.toLocaleString();
  }, []);

  const { update } = useCountUp({
    ref: 'numberOfNodesCounter',
    decimals: 0,
    duration: 2.75,
    end: numberOfNodesCounter || 0,
    formattingFn: memoizedCallback,
    preserveValue: true
  });

  useEffect(() => {
    if (nodes) {
      setNumberOfNodesCounter(Object.keys(nodes).length);
      update(Object.keys(nodes).length);
      getNodeChartData();
    }
  }, [nodes]);

  return (
    <Card className='h-100 shadow-none border'>
      {!nodes ? (
        <div className='position-absolute d-flex justify-content-center align-items-center h-100 w-100 navbar-glass-loader'>
          <Spinner animation='border' />
        </div>
      ) : null}
      <Card.Body className='p-2'>
        <Row className='g-0'>
          <Col
            xs={5}
            className='d-flex flex-column h-100 justify-content-between'>
            <h6 className='mb-2 text-900 text-nowrap'>All Nodes</h6>
            <h4 className='fs-2 fw-normal mb-0 text-nowrap'>
              <span
                id='numberOfNodesCounter'
                className='font-monospace text-nowrap'
              />
            </h4>
            <span></span>
          </Col>
          <Col xs='7' className='h-100'>
            <ArchSplitLineChart
              data={{
                labels: ['Data', 'Master', 'Coord', 'Web'],
                values: [
                  dataNodeCounter,
                  masterNodeCounter,
                  coordinationNodeCounter,
                  webNodeCounter
                ],
                backgroundColors: [
                  rgbaColor('#FF6384', 0.8),
                  rgbaColor('#36A2EB', 0.8),
                  rgbaColor('#FF9F40', 0.8),
                  rgbaColor('#91CC75', 0.8)
                ],
                borderColors: [
                  rgbaColor('#FF6384', 0),
                  rgbaColor('#36A2EB', 0),
                  rgbaColor('#FF9F40', 0),
                  rgbaColor('#91CC75', 0)
                ]
              }}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NodeCount;
