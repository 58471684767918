import Flex from 'components/common/Flex';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useOrganizations from 'hooks/admin-contexts/useOrganizations';
import useApplication from 'hooks/useApplication';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import OnlyOrgBurron from './ActiveOrgButton';
import AddButton from './AddButton';
import { getOrganizationColumns } from './Columns/ColumnsDefaults';
import OrganizationsTable from './OrganizationsTable';
import OrganizationsTableFilters from './OrganizationsTableFilters';
import OrganizationsTableFooter from './OrganizationsTableFooter';
import OrganizationsTablePagination from './OrganizationsTablePagination';
import OrganizationsTableWrapper from './OrganizationsTableWrapper';
import ShowDeletedButton from './ShowDeletedButton';

/**
 * Component for base where we put the organizations complete table
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Organizations = () => {
  const {
    application: { isPrivacyMode }
  } = useApplication();

  const {
    organizations: { data, showDeleted },
    loading: componentLoading,
    setOrganizations
  } = useOrganizations();

  const [globalFilter, setGlobalFilter] = useState('');
  const [columns, setColumns] = useState([]);
  const [sortedTableData, setSortedTableData] = useState([]);
  const [showActiveOrgsOnly, setShowActiveOrgs] = useState(false);

  /**
   * Set columns on privacy mode change
   */
  useEffect(() => {
    setColumns(getOrganizationColumns(setOrganizations, isPrivacyMode));
  }, [isPrivacyMode]);

  /**
   * Set columns on mount
   */
  useEffect(() => {
    setColumns(getOrganizationColumns(setOrganizations, isPrivacyMode));
  }, []);

  useEffect(() => {
    setSortedTableData(
      data
        .sort((a, b) =>
          isPrivacyMode
            ? a?.legacy?.clientNumber - b?.legacy?.clientNumber
            : a?.name.localeCompare(b?.name)
        )
        .filter(item => (showActiveOrgsOnly ? item : item.active))
    );
  }, [data, showActiveOrgsOnly]);
  return (
    <Card className='shadow-none border'>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          <span className='d-block'>Organizations</span>
          <Flex justifyContent='end' alignItems='center'>
            <OnlyOrgBurron
              showActiveOrgsOnly={showActiveOrgsOnly}
              onClick={() => {
                setShowActiveOrgs(!showActiveOrgsOnly);
              }}
            />
            <ShowDeletedButton
              showDeleted={showDeleted}
              onClick={() => {
                setOrganizations(prevState => ({
                  ...prevState,
                  showDeleted: !showDeleted
                }));
              }}
            />
            <AddButton
              onClick={() => {
                setOrganizations(prevState => ({
                  ...prevState,
                  orgAddModal: { open: true }
                }));
              }}
            />
          </Flex>
        </Flex>
      </Card.Header>

      <Card.Body className='position-relative'>
        {componentLoading ? (
          <LoadingSpinner grow='10' />
        ) : (
          <OrganizationsTableWrapper
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            data={
              showDeleted
                ? sortedTableData?.filter(
                    organization => organization?.isDeleted
                  )
                : sortedTableData?.filter(
                    organization => !organization?.isDeleted
                  )
            }
            sortable
            loading={componentLoading}
            pagination
            perPage={50}>
            <OrganizationsTableFilters
              table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <OrganizationsTable table columns={columns} />
            <AddButton
              label='Add an Organization'
              onClick={() =>
                setOrganizations(prevState => ({
                  ...prevState,
                  orgAddModal: { open: true }
                }))
              }
            />
            <div className='mt-3 d-flex justify-content-between'>
              <OrganizationsTableFooter
                table
                rowInfo
                data={
                  showDeleted
                    ? sortedTableData
                    : sortedTableData?.filter(
                        organization => !organization?.isDeleted
                      )
                }
                rowCount={
                  showDeleted
                    ? sortedTableData?.length
                    : sortedTableData?.filter(
                        organization => !organization?.isDeleted
                      )?.length
                }
              />
              <OrganizationsTablePagination table />
            </div>
          </OrganizationsTableWrapper>
        )}
      </Card.Body>
    </Card>
  );
};

export default Organizations;
