import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Background from 'components/common/Background';
import Avatar from 'components/common/Avatar';
import useApplication from 'hooks/useApplication';

const ProfileContainerHeader = ({ avatar, banner }) => {
  return (
    <Card.Header
      className={'position-relative mb-1'}
      style={{ height: '100px' }}>
      <Background
        image={banner || process.env.REACT_APP_DEFAULT_ORG_BANNER_IMG}
      />
      <Avatar
        size='4xl'
        className={`position-absolute bottom-0 start-4`}
        style={{ marginBottom: '-1rem' }}
        src={avatar || process.env.REACT_APP_DEFAULT_ORG_AVATAR_IMG}
      />
    </Card.Header>
  );
};

const ProfileContainerBody = ({ children }) => {
  return <Card.Body>{children}</Card.Body>;
};

const ProfileContainer = ({ children }) => {
  const {
    application: { isDark }
  } = useApplication();
  return (
    <Card className={`shadow-none overflow-hidden ${isDark ? 'bg-light' : ''}`}>
      {children}
    </Card>
  );
};

ProfileContainer.Header = ProfileContainerHeader;
ProfileContainer.Body = ProfileContainerBody;

ProfileContainerHeader.propTypes = {
  avatar: PropTypes.string,
  banner: PropTypes.string
};

ProfileContainerBody.propTypes = {
  children: PropTypes.node.isRequired
};

ProfileContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProfileContainer;
