import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import { routes } from 'config/routeSettings';
import { setItemToStore } from 'helpers/utils';
import useAccount from 'hooks/useAccount';
import useAuthentication from 'hooks/useAuthentication';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 * Account login form.
 *
 * The account login form is used to display a form to the user to login to their account.
 * @param {Object} props The component props
 * @param {boolean} props.hasLabel Whether or not to display a label for the verification code input
 * @returns {React.Component} The account login form
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const LoginForm = ({ hasLabel }) => {
  const { setAuthentication, persist, setPersist } = useAuthentication();
  const { setAccount } = useAccount();

  const [loading, setLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');

  const emailAddressField = useRef('emailAddress');
  const passwordField = useRef('password');

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await LeargasAPI.AccountAuth.userSessionLogin({
        body: {
          address: emailAddress,
          password: password
        }
      });
      if (response instanceof Error) throw response;
      let message = response?.data?.message;
      if (message) toast.success(message);
      setAccount(prevState => ({
        ...prevState,
        ...response.data.data.account
      }));
      setAuthentication(prevState => ({
        ...prevState,
        ...response.data.data.authentication,
        persist,
        persistLoader: true
      }));
      setLoading(false);
      setPassword('');
      // setting trust this device default to true on login
      setItemToStore('persist', true);
      setPersist(true);
      // setting trust this device default to true on login
      setTimeout(function () {
        setAuthentication(prevState => ({
          ...prevState,
          persistLoader: false,
          stage: 'authenticated'
        }));
      }, 1000);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
        toast.error('Network Error occurred');
      } else {
        let message = error?.response?.data?.message;
        if (message) toast.error(message);
      }
      setLoading(false);
      setTimeout(function () {
        passwordField.current.focus();
        setPassword('');
      }, 1000);
    }
  };

  useEffect(() => {
    setLoading(false);
    emailAddressField.current.focus();
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='g-2 flex-center position-relative'>
        <Col xs={8}>
          <Row className='g-2 mb-0'>
            <Flex justifyContent='between' alignItems='center' className='mb-2'>
              <h2 className='mb-1 fs-1'>Log in</h2>
              {/* <p className='fs--1 text-600 mb-0'>
                or <Link to={routes.REGISTER}>Create an account</Link>
              </p> */}
            </Flex>
          </Row>
          <Row className='g-2 mb-3'>
            <Col>
              <Form.Group>
                {hasLabel && <Form.Label>Email address</Form.Label>}
                <Form.Control
                  size='sm'
                  name='emailAddress'
                  type='email'
                  ref={emailAddressField}
                  placeholder={!hasLabel ? 'Email address' : ''}
                  autoComplete='username'
                  value={emailAddress}
                  onChange={e => {
                    setEmailAddress(e.target.value);
                  }}
                  className='fs--1 auth-form-control-dark shadow-none pe-0'
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='g-2 mb-3'>
            <Col>
              <Form.Group>
                {hasLabel && <Form.Label>Password</Form.Label>}
                <Form.Control
                  size='sm'
                  name='password'
                  type='password'
                  ref={passwordField}
                  placeholder={!hasLabel ? 'Password' : ''}
                  autoComplete='current-password'
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                  }}
                  className='fs--1 auth-form-control-dark shadow-none pe-0'
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='g-2 mb-3'>
            <Form.Group>
              <Button
                size='sm'
                type='submit'
                variant='outline-primary'
                className='w-100'>
                <Flex justifyContent={'center'} alignItems={'center'}>
                  <ButtonSpinner spinning={loading} />{' '}
                  <span>{loading ? 'Authenticating' : 'Log In'}</span>
                </Flex>
              </Button>
            </Form.Group>
          </Row>
          <Row className='justify-content-between align-items-center mb-0'>
            {/* <Col xs='auto'>
              <Form.Check type='checkbox' id='persist' className='pt-1'>
                <Form.Check.Input
                  type='checkbox'
                  id='persist'
                  checked={persist}
                  onChange={togglePersist}
                />
                <Form.Check.Label className='mb-0 text-primary fw-light fs--1'>
                  Trust This Device
                </Form.Check.Label>
              </Form.Check>
            </Col> */}
            <Col xs='auto'>
              <Link className='fs--1 mb-0' to={routes.FORGOT_PASSWORD}>
                Forget Password?
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

LoginForm.propTypes = {
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  hasLabel: false
};

export default LoginForm;
