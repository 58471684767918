import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

const syslog = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'Syslog Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-syslog'],
        queryType: ['previousCount', 'count', 'sparkline']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Logs',
          helpInfo: 'Total number of events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['cardinality'],
        overriddenIndex: ['leargas-syslog'],
        aggregate: {
          aggs: {
            1: {
              cardinality: {
                field: 'host.keyword'
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'MetricPlain',
        props: {
          className: '',
          title: 'Devices',
          helpInfo: 'Total number of devices observed'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-syslog'],
        queryType: ['histogram']
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Event Timeline',
          helpInfo:
            'Timeline histogram of all events matching the search criteria'
        }
      }
    }
  ];
};

export default syslog;
