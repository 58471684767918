import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import Flex from 'components/common/Flex';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Render the ReleaseStatus component
 *
 * @param {object} props Component properties
 * @param {object} props.release The release object
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ReleaseStatus = ({ release }) => {
  return (
    <>
      {release.released ? (
        <Flex direction='column' className='position-relative'>
          {release.name ? (
            <Flex direction='row' alignItems='center' className='mb-2'>
              <div className='icon-item icon-item-sm rounded-circle bg-200 shadow-none me-2 z-index-1'>
                <FontAwesomeIcon
                  icon='code-merge'
                  className='fs-0 text-primary'
                />{' '}
              </div>
              <Badge
                bg='200'
                className='me-2 text-500 fs--1 fw-normal border position-absolute border-200 ps-3'
                style={{ left: '15px' }}>
                {release.name}
              </Badge>
            </Flex>
          ) : null}
          <span className='fs--1 text-600 mb-0'>
            Released <Moment format='MM/DD/YYYY'>{release.releasedAt}</Moment>
          </span>
        </Flex>
      ) : !release.released ? (
        <Flex
          direction='row'
          alignItems='center'
          className='mb-2 position-relative'>
          <div className='icon-item icon-item-sm rounded-circle bg-200 shadow-none me-2 z-index-1'>
            <FontAwesomeIcon icon='clock' className='fs-0 text-secondary' />{' '}
          </div>
          <div className='position-absolute' style={{ left: '15px' }}>
            <Badge
              bg='200'
              className='me-2 text-500 fs--1 fw-normal border border-200 ps-3'>
              pending release
            </Badge>
          </div>
        </Flex>
      ) : null}
    </>
  );
};

ReleaseStatus.propTypes = {
  release: PropTypes.shape({
    released: PropTypes.bool.isRequired,
    name: PropTypes.string,
    releasedAt: PropTypes.string
  }).isRequired
};

export default ReleaseStatus;
