import baseUri from 'api/connection/baseUri';
import axios from 'axios';

/**
 * Class representing a configured Axios instance with abort capabilities.
 *
 * @class AxiosInstance
 * @property {boolean} isPrivate - Determines if instance is private or public.
 * @property {AbortController} controller - AbortController instance.
 * @property {AxiosInstance} instance - Axios instance.
 * @property {Function} cancelAllRequests - Cancels all pending requests.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.4
 * @version 0.1.0-beta.4
 */
class AxiosInstance {
  /**
   * Creates an instance of AxiosInstance.
   * @param {Object} params - The configuration parameters for Axios.
   * @param {boolean} [params.isPrivate=true] - Whether the instance is private.
   */
  constructor({ isPrivate = true }) {
    /**
     * @type {boolean}
     * @description Indicates if the Axios instance is meant for private API calls.
     */
    this.isPrivate = isPrivate;

    /**
     * Bind the cancelAllRequests method to the instance to ensure the correct `this` context.
     */
    this.cancelAllRequests = this.cancelAllRequests.bind(this);

    /**
     * Initialize the Axios instance when the AxiosInstance object is created.
     */
    this.initInstance();
  }

  /**
   * Initializes the Axios instance with a base URL, headers for JSON,
   * and with credentials sent in cross-site requests. Sets up a request
   * interceptor to use an abort signal for canceling requests.
   */
  initInstance() {
    /**
     * @type {AbortController}
     * @description Controller to allow aborting a group of DOM requests.
     */
    this.controller = new AbortController();

    /**
     * @type {axios}
     * @description The axios instance specific to this object, configured for potential cancellation.
     */
    this.instance = axios.create({
      baseURL: String(baseUri),
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true
    });

    /**
     * Set up an interceptor to inject the abort signal into each request.
     */
    this.instance.interceptors.request.use(
      config => {
        config.signal = this.controller.signal;
        return config;
      },
      error => Promise.reject(error)
    );
  }

  /**
   * Cancels all active requests and reinitializes the Axios instance along with its abort controller.
   */
  cancelAllRequests() {
    this.controller.abort(); // Cancel ongoing requests
    this.initInstance(); // Reinitialize axios instance and interceptors
  }
}

/**
 * Public and private Axios instances to be used throughout the application.
 */
const axiosPublic = new AxiosInstance({ isPrivate: false });
const axiosPrivate = new AxiosInstance({ isPrivate: true });

export { axiosPrivate, axiosPublic };
