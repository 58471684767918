import useApplication from 'hooks/useApplication';
import { useESGlobalFilters } from 'hooks/useGlobalFilters';

import React, { useEffect, useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';

/**
 * Renders a search field component for querying data
 *
 * @returns {JSX.Element} The search field component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const QuerySearch = () => {
  const focusField = useRef();
  const { updateGlobalFilters } = useApplication();

  const {
    esGlobalFilters: { loading, querySearchText },
    setESGlobalFilters
  } = useESGlobalFilters();
  const [searchText, setSearchText] = useState('');

  /**
   * Handles the 'Enter' key press event.
   * @param {Event} event - The key press event.
   */
  const onEnter = event => {
    if (event.key === 'Enter') {
      if (searchText) {
        setESGlobalFilters(prevState => ({
          ...prevState,
          querySearchText: searchText,
          query_string: {
            query_string: {
              query: searchText
            }
          }
        }));
        updateGlobalFilters({
          searchtext: searchText
        });
      } else {
        setESGlobalFilters(prevState => ({
          ...prevState,
          querySearchText: '',
          query_string: null
        }));
        updateGlobalFilters({
          searchtext: ''
        });
      }
      setESGlobalFilters(prevState => ({
        ...prevState,
        highlightedGlobalIndex: null,
        update: true
      }));
    }
  };
  useEffect(() => {
    if (querySearchText !== '') {
      setSearchText(querySearchText);
    }
  }, [querySearchText]);

  return (
    <Col md={6} xxl={4} className='py-0 my-0'>
      <div className='me-2 w-100 mb-2'>
        <Form.Group>
          <Form.Label className='fs--1'>Searchas</Form.Label>
          <Form.Control
            value={searchText}
            onChange={e => {
              setSearchText(e.target.value);
            }}
            onKeyDown={event => onEnter(event)}
            ref={focusField}
            id='search'
            size='sm'
            placeholder={`Search...`}
            type='search'
            tabIndex='2'
            autoComplete='off'
            disabled={loading}
            className='fs--1'
          />
        </Form.Group>
      </div>
    </Col>
  );
};

export default QuerySearch;
