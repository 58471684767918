import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AdvancedPopover from 'components/common/Popover';
import { fieldTypeConfig } from 'helpers/field.utils';
import useExplore from 'hooks/useExplore';
import { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';

/**
 * TypeFilter component.
 *
 * Renders a list of badges that represent the different field types
 * Clicking on a badge will toggle the visibility of all fields of that type
 *
 * @returns {JSX.Element} The rendered TypeFilter component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const TypeFilter = () => {
  const {
    state: {
      fieldTypesPresent,
      fieldTypes,
      fieldTypesHidden,
      fieldsPresentOnly
    },
    setState
  } = useExplore();

  const [availableFieldTypes, setAvailableFieldTypes] = useState(fieldTypes);

  /**
   * Handles the toggle action for a specific type.
   *
   * @param {string} type - The type to toggle
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleToggle = type => {
    // If the type is hidden, remove it from the hidden list
    if (fieldTypesHidden.includes(type)) {
      setState(
        'fieldTypesHidden',
        fieldTypesHidden.filter(fieldType => fieldType !== type)
      );
    } else {
      // Otherwise, add it to the hidden list
      setState('fieldTypesHidden', [...fieldTypesHidden, type]);
    }
  };

  useEffect(() => {
    if (fieldsPresentOnly && Array.isArray(fieldTypesPresent)) {
      // Set the available field types
      setAvailableFieldTypes(
        fieldTypes.filter(type => fieldTypesPresent.includes(type))
      );
    } else {
      // Set the available field types
      setAvailableFieldTypes(fieldTypes);
    }
  }, [fieldTypesPresent, fieldsPresentOnly]);

  return (
    <div className='d-flex flex-wrap fs--2 mb-2 ps-1'>
      {Array.isArray(availableFieldTypes) && availableFieldTypes.length > 0 ? (
        <>
          <span className='me-1'>Types</span>
          {availableFieldTypes.map((type, index) => {
            const { icon, color, backgroundColor, borderColor } =
              fieldTypeConfig[type] || fieldTypeConfig['default'];

            return (
              <div key={index}>
                <AdvancedPopover
                  placement='top'
                  showArrow={true}
                  popoverText={
                    <span className='text-700'>
                      <span>Toggle visibility of all</span>
                      <code className={`mx-1 text-${color}`}>{type}</code>
                      <span>fields</span>
                    </span>
                  }>
                  <Badge
                    className={classNames('p-1 me-1 cursor-pointer border', {
                      [`text-${color}`]: true,
                      [`border-${borderColor}`]: true,
                      [`bg-${backgroundColor}`]: true,
                      [`border-soft-${color}-dark`]: fieldTypesHidden.includes(
                        type || 'default'
                      ),
                      'opacity-50': fieldTypesHidden.includes(type || 'default')
                    })}
                    onClick={() => handleToggle(type)}>
                    <FontAwesomeIcon icon={icon} width={12} />
                  </Badge>
                </AdvancedPopover>
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
};

export default TypeFilter;
