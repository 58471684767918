import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import logoLight from 'assets/img/logo-h-light.png';
import logoDark from 'assets/img/logo-h-dark.png';
import useApplication from 'hooks/useApplication';

const LogoNoLink = ({ at, className, ...rest }) => {
  const {
    application: { isDark }
  } = useApplication();

  return (
    <div
      to='/'
      className={classNames(
        'text-decoration-none position-relative',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}>
      <div
        className={classNames(
          '',
          {
            'align-items-center py-3 logo-img': at === 'navbar-vertical',
            'align-items-center logo-img': at === 'navbar-top',
            'text-center fw-bolder fs-5 mb-4': at === 'auth'
          },
          className
        )}
        style={{ margin: '0 auto', zIndex: 5 }}>
        <img
          src={isDark ? logoDark : logoLight}
          alt='Léargas'
          className='fade-in'
          style={{ width: '75%', maxWidth: '200px' }}
        />
      </div>
    </div>
  );
};

LogoNoLink.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
};

LogoNoLink.defaultProps = { at: 'auth', width: 250 };

export default LogoNoLink;
