import React, { useEffect, useState } from 'react';
import { FormText } from 'react-bootstrap';

const AddFilterTips = () => {
  const [activeTipIndex, setActiveTipIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState('fadeIn');

  const tips = [
    {
      text: () => (
        <FormText className='text-700'>
          <span>Tip: Use the</span>
          <span className='mx-1'>
            <b>does not exist</b>
          </span>
          <span>
            operator to filter for artifacts that do not contain a specific
            field.
          </span>
        </FormText>
      )
    },
    {
      text: () => (
        <FormText className='text-700'>
          <span>Tip: Use the</span>
          <span className='mx-1'>
            <b>is</b> or <b>is not</b>
          </span>
          <span>
            operators to filter for artifacts that match a specific value.
          </span>
        </FormText>
      )
    },
    {
      text: () => (
        <FormText className='text-700'>
          <span>Tip: Use the</span>
          <span className='mx-1'>
            <b>exists</b>
          </span>
          <span>
            operator to filter for artifacts that contain a specific field.
          </span>
        </FormText>
      )
    },
    {
      text: () => (
        <FormText className='text-700'>
          <span>Tip: Use the</span>
          <span className='mx-1'>
            <b>is one of</b>
          </span>
          <span>
            operator to filter for artifacts that match any value in a list.
          </span>
        </FormText>
      )
    },
    {
      text: () => (
        <FormText className='text-700'>
          <span>Tip: Use the</span>
          <span className='mx-1'>
            <b>is not one of</b>
          </span>
          <span>
            operator to filter for artifacts that do not match any value in a
            list.
          </span>
        </FormText>
      )
    }
  ];

  const changeTip = () => {
    setAnimationClass('fadeOut');
    setTimeout(() => {
      setActiveTipIndex(prevIndex => (prevIndex + 1) % tips.length);
      setAnimationClass('fadeIn');
    }, 1000); // Match duration with the animation-duration in CSS
  };

  useEffect(() => {
    const interval = setInterval(changeTip, 30000); // Rotate every 30 seconds
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures useEffect runs once on mount

  return (
    <div className='tips-fade-container'>
      <div className={`tips-fade-text ${animationClass}`}>
        {tips[activeTipIndex].text()}
      </div>
    </div>
  );
};

export default AddFilterTips;
