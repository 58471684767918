export const base = '';

export const routes = {
  /**
   * Base
   */
  HOME: [base, '/'].join(''),

  /**
   * Overview
   */
  OVERVIEW: [base, '/overview'].join(''),

  /**
   * Auth
   */
  LOGIN: [base, '/auth/login'].join(''),
  REGISTER: [base, '/auth/register'].join(''),
  FORGOT_PASSWORD: [base, '/auth/forgot-password'].join(''),
  RESET_PASSWORD: [base, '/auth/reset-password'].join(''),
  CONFIRM_MAIL: [base, '/auth/confirm-mail'].join(''),
  ACTIVATE: [base, '/auth/activate'].join(''),
  LOCK: [base, '/auth/locked'].join(''),
  MFA_APP_SETUP: [base, '/auth/mfa'].join(''),
  MFA_APP_VERIFY: [base, '/auth/otp'].join(''),

  /**
   * Account
   */
  ACCOUNT: [base, '/account'].join(''),
  ACCOUNT_PROFILE: [base, '/account/profile'].join(''),
  ACCOUNT_PROFILE_EDIT: [base, '/account/profile/edit'].join(''),
  ACCOUNT_SETTINGS: [base, '/account/settings'].join(''),

  /**
   * Errors
   */
  ERROR_401: [base, '/errors/401'].join(''),
  ERROR_403: [base, '/errors/403'].join(''),
  ERROR_404: [base, '/errors/404'].join(''),
  ERROR_500: [base, '/errors/500'].join(''),

  /**
   * Artifacts
   */
  ARTIFACTS: [base, '/artifacts'].join(''),
  ARTIFACTS_EXPLORE: [base, '/artifacts/explore'].join(''),
  ARTIFACTS_EXPLORE_OLD: [base, '/artifacts/explore/old'].join(''),
  ARTIFACTS_CLOUD: [base, '/artifacts/cloud'].join(''),
  ARTIFACTS_CLOUD_BOX: [base, '/artifacts/cloud/box'].join(''),
  ARTIFACTS_CLOUD_DUO: [base, '/artifacts/cloud/duo'].join(''),
  ARTIFACTS_CLOUD_GOOGLE: [base, '/artifacts/cloud/google'].join(''),
  ARTIFACTS_CLOUD_MERAKI: [base, '/artifacts/cloud/meraki'].join(''),
  ARTIFACTS_CLOUD_O365: [base, '/artifacts/cloud/o365'].join(''),
  ARTIFACTS_CLOUD_OKTA: [base, '/artifacts/cloud/okta'].join(''),
  ARTIFACTS_CLOUD_AZURE: [base, '/artifacts/cloud/azure'].join(''),
  ARTIFACTS_CLOUD_AWS: [base, '/artifacts/cloud/aws'].join(''),
  ARTIFACTS_CLOUD_AWS_CLOUDTRAIL: [
    base,
    '/artifacts/cloud/aws/cloud-trail'
  ].join(''),
  ARTIFACTS_DARKWEB: [base, '/artifacts/darkweb'].join(''),
  ARTIFACTS_DARKWEB_CREDENTIALS: [base, '/artifacts/darkweb/credentials'].join(
    ''
  ),
  ARTIFACTS_DARKWEB_KEYWORDS: [base, '/artifacts/darkweb/keywords'].join(''),
  ARTIFACTS_DARKWEB_RANSOMS: [base, '/artifacts/darkweb/ransoms'].join(''),
  ARTIFACTS_ENDPOINT: [base, '/artifacts/endpoint'].join(''),
  ARTIFACTS_ENDPOINT_SYSLOG: [base, '/artifacts/endpoint/syslog'].join(''),
  ARTIFACTS_ENDPOINT_CROWDSTRIKE: [
    base,
    '/artifacts/endpoint/crowdstrike'
  ].join(''),
  ARTIFACTS_ENDPOINT_SENTINELONE: [
    base,
    '/artifacts/endpoint/sentinelone'
  ].join(''),
  ARTIFACTS_ENDPOINT_SENTINELONE_THREATS: [
    base,
    '/artifacts/endpoint/sentinelone/threats'
  ].join(''),
  ARTIFACTS_ENDPOINT_SENTINELONE_AGENTS: [
    base,
    '/artifacts/endpoint/sentinelone/agents'
  ].join(''),
  ARTIFACTS_ENDPOINT_WINDOWS: [base, '/artifacts/endpoint/windows'].join(''),
  ARTIFACTS_NETWORK: [base, '/artifacts/network'].join(''),
  ARTIFACTS_NETWORK_BEHAVIORS: [base, '/artifacts/network/behaviors'].join(''),
  ARTIFACTS_NETWORK_SIGNATURES: [base, '/artifacts/network/signatures'].join(
    ''
  ),
  ARTIFACTS_NETWORK_THREATINTEL: [
    base,
    '/artifacts/network/threat-intelligence'
  ].join(''),
  ARTIFACTS_NETWORK_SMB: [base, '/artifacts/network/smb'].join(''),
  ARTIFACTS_NETWORK_RPC: [base, '/artifacts/network/rpc'].join(''),
  ARTIFACTS_NETWORK_ICS: [base, '/artifacts/network/ics'].join(''),
  ARTIFACTS_NETWORK_FILEEXTRACTION: [
    base,
    '/artifacts/network/file-extraction'
  ].join(''),
  ARTIFACTS_PHYSICAL: [base, '/artifacts/physical'].join(''),
  ARTIFACTS_PHYSICAL_SURVEILLANCE: [
    base,
    '/artifacts/physical/surveillance'
  ].join(''),
  ARTIFACTS_PHYSICAL_POINTOFENTRY: [
    base,
    '/artifacts/physical/point-of-entry'
  ].join(''),
  ARTIFACTS_VULNS: [base, '/artifacts/vulnerabilities'].join(''),
  ARTIFACTS_VULNS_NESSUS: [base, '/artifacts/vulnerabilities/nessus'].join(''),
  ARTIFACTS_VULNS_OPENVAS: [base, '/artifacts/vulnerabilities/openvas'].join(
    ''
  ),
  ARTIFACTS_VULNS_QUALYS: [base, '/artifacts/vulnerabilities/qualys'].join(''),

  /**
   * Manage
   */
  MANAGE: [base, '/manage'].join(''),

  /**
   * Singular data types with base route
   * Most of these will live under /manage in routes
   * But we want them to be accessible from a base route
   */
  ALERTS: [base, '/manage/alerts'].join(''),
  APPLIANCES: [base, '/manage/appliances'].join(''),
  ARTICLES: [base, '/manage/articles'].join(''),
  BOOKMARKS: [base, '/manage/bookmarks'].join(''),
  CASES: [base, '/manage/cases'].join(''),
  DETECTIONS: [base, '/manage/detections'].join(''),
  FILTERS: [base, '/manage/filters'].join(''),
  INCIDENTS: [base, '/manage/incidents'].join(''),
  NOTIFICATIONS: [base, '/manage/notifications'].join(''),
  ORGANIZATIONS: [base, '/manage/organizations'].join(''),
  REPORTS: [base, '/manage/reports'].join(''),
  SCANS: [base, '/manage/scans'].join(''),
  PRODUCTS: [base, '/manage/products'].join(''),
  SUBSCRIPTIONS: [base, '/manage/subscriptions'].join(''),
  SAVED_SEARCHES: [base, '/manage/saved-searches'].join(''),
  TEAMS: [base, '/manage/teams'].join(''),
  TICKETS: [base, '/manage/tickets'].join(''),
  USERS: [base, '/manage/users'].join(''),

  /**
   * Resources
   */
  RESOURCES: [base, '/resources'].join(''),
  RESOURCES_NEWS_UPDATES: [base, '/resources/news-updates'].join(''),
  RESOURCES_CHANGE_LOG: [base, '/resources/change-log'].join(''),
  RESOURCES_REPORT_ISSUE: [base, '/resources/report-issue'].join(''),

  /**
   * System
   */
  SYSTEM: [base, '/system'].join(''),
  SYSTEM_APP: [base, '/system/application'].join(''),
  SYSTEM_APP_MANAGE: [base, '/system/application'].join(''),
  SYSTEM_APP_MANAGE_ALERTS: [base, '/system/application/alerts'].join(''),
  SYSTEM_APP_MANAGE_ARTICLES: [base, '/system/application/articles'].join(''),
  SYSTEM_APP_MANAGE_ARTICLES_CATEGORIES: [
    base,
    '/system/application/articles/categories'
  ].join(''),
  SYSTEM_APP_MANAGE_ARTICLES_TAGS: [
    base,
    '/system/application/articles/tags'
  ].join(''),
  SYSTEM_APP_MANAGE_BOOKMARKS: [base, '/system/application/bookmarks'].join(''),
  SYSTEM_APP_MANAGE_DETECTIONS: [base, '/system/application/detections'].join(
    ''
  ),
  SYSTEM_APP_MANAGE_JOBS: [base, '/system/application/jobs'].join(''),
  SYSTEM_APP_MANAGE_ORGS: [base, '/system/application/organizations'].join(''),

  SYSTEM_APP_MANAGE_PRODUCTS: [base, '/system/application/products'].join(''),
  SYSTEM_APP_MANAGE_PRODUCTS_APPLIANCES: [
    base,
    '/system/application/products/appliances'
  ].join(''),
  SYSTEM_APP_MANAGE_PRODUCTS_INSTANCES: [
    base,
    '/system/application/products/instances'
  ].join(''),
  SYSTEM_APP_MANAGE_RELEASES: [base, '/system/application/releases'].join(''),
  SYSTEM_APP_MANAGE_REPORTS: [base, '/system/application/reports'].join(''),
  SYSTEM_APP_MANAGE_REPORTS_TEMPLATES: [
    base,
    '/system/application/reports/templates'
  ].join(''),
  SYSTEM_APP_MANAGE_ROLES: [base, '/system/application/roles'].join(''),
  SYSTEM_APP_MANAGE_SAVED_SEARCHES: [
    base,
    '/system/application/saved-searches'
  ].join(''),
  SYSTEM_APP_MANAGE_SUBS: [base, '/system/application/subscriptions'].join(''),
  SYSTEM_APP_MANAGE_SUBS_LEVELS: [
    base,
    '/system/application/subscriptions/levels'
  ].join(''),
  SYSTEM_APP_MANAGE_TEAMS: [base, '/system/application/teams'].join(''),
  SYSTEM_APP_MANAGE_USERS: [base, '/system/application/users'].join(''),
  SYSTEM_APP_SECURITY: [base, '/system/application/security'].join(''),
  SYSTEM_APP_SECURITY_AUDIT: [base, '/system/application/security/audit'].join(
    ''
  ),
  SYSTEM_APP_SECURITY_PERMISSIONS: [
    base,
    '/system/application/security/permissions'
  ].join(''),
  SYSTEM_APP_SECURITY_RESOURCES: [
    base,
    '/system/application/security/resources'
  ].join(''),
  SYSTEM_APP_SETTINGS: [base, '/system/application/settings'].join(''),
  SYSTEM_APP_SETTINGS_DATA: [base, '/system/application/settings/data'].join(
    ''
  ),
  SYSTEM_APP_SETTINGS_DATA_FIELDS: [
    base,
    '/system/application/settings/data/fields'
  ].join(''),
  SYSTEM_APP_SETTINGS_DATA_INDICES: [
    base,
    '/system/application/settings/data/indices'
  ].join(''),
  SYSTEM_APP_SETTINGS_DISPLAY: [
    base,
    '/system/application/settings/display'
  ].join(''),
  SYSTEM_APP_SETTINGS_DISPLAY_DASHBOARDS: [
    base,
    '/system/application/settings/display/dashboards'
  ].join(''),
  SYSTEM_APP_SETTINGS_DISPLAY_MENUS: [
    base,
    '/system/application/settings/display/menus'
  ].join(''),
  SYSTEM_APP_SETTINGS_DISPLAY_ROUTES: [
    base,
    '/system/application/settings/display/routes'
  ].join(''),
  SYSTEM_APP_SETTINGS_DISPLAY_VISUALIZATIONS: [
    base,
    '/system/application/settings/display/visualizations'
  ].join(''),
  SYSTEM_APP_SETTINGS_GENERAL: [
    base,
    '/system/application/settings/general'
  ].join(''),
  SYSTEM_APP_SETTINGS_PRIVACY: [
    base,
    '/system/application/settings/privacy'
  ].join(''),
  SYSTEM_APP_SETTINGS_NOTIFICATION: [
    base,
    '/system/application/settings/notification'
  ].join(''),
  SYSTEM_APP_SETTINGS_INTEGRATION: [
    base,
    '/system/application/settings/integration'
  ].join(''),
  SYSTEM_APP_SETTINGS_EMAIL: [
    base,
    '/system/application/settings/integration/email'
  ].join(''),
  SYSTEM_TOOLS: [base, '/system/tools'].join(''),
  SYSTEM_TOOLS_ES: [base, '/system/search-cluster'].join(''),
  SYSTEM_TOOLS_ES_AUDIT_LOGS: [base, '/system/search-cluster/audit-logs'].join(
    ''
  ),
  SYSTEM_TOOLS_ES_HEALTH: [base, '/system/search-cluster/health'].join(''),
  SYSTEM_TOOLS_ES_INDICES: [base, '/system/search-cluster/indices'].join(''),
  SYSTEM_TOOLS_ES_NODES: [base, '/system/search-cluster/nodes'].join(''),
  SYSTEM_TOOLS_DB: [base, '/system/db-cluster'].join(''),
  SYSTEM_TOOLS_DB_HEALTH: [base, '/system/db-cluster/health'].join(''),
  SYSTEM_TOOLS_DB_NODES: [base, '/system/db-cluster/nodes'].join(''),
  SYSTEM_TOOLS_DB_COLLECTIONS: [base, '/system/db-cluster/collections'].join(
    ''
  ),
  SYSTEM_TOOLS_DB_ROLES: [base, '/system/db-cluster/roles'].join(''),
  SYSTEM_TOOLS_DB_USERS: [base, '/system/db-cluster/users'].join(''),
  SYSTEM_TOOLS_DB_AUDIT_LOGS: [base, '/system/db-cluster/audit-logs'].join(''),
  SYSTEM_TOOLS_REPORTING_STATUSES: [
    base,
    '/system/tools/reporting/legacy-reports'
  ].join(''),
  SYSTEM_TOOLS_ALERTING_STATUSES: [
    base,
    '/system/tools/alerting/statuses'
  ].join(''),
  SYSTEM_TOOLS_REPORTING: [base, '/system/tools/reporting'].join(''),
  SYSTEM_TOOLS_Alerting: [base, '/system/tools/alerting'].join('')
};

export const apiRoutes = {
  REGISTER: '/v1/account/register',
  ACTIVATE: '/v1/account/activate',
  LOGIN: '/v1/account/auth/login',
  MFA_APP_VERIFY: '/v1/account/auth/verify',
  REFRESH: '/v1/account/auth/refresh',
  LOCK: '/v1/account/auth/lock',
  UNLOCK: '/v1/account/auth/unlock',
  LOGOUT: '/v1/account/auth/logout',
  MFA_APP_QRCODE: '/v1/account/security/mfa/app/qr',
  MFA_APP_SECRET: '/v1/account/security/mfa/app/secret',
  PASSWORD_RESET_REQUEST: '/v1/account/security/password/reset-request',
  PASSWORD_RESET_VERIFY: '/v1/account/security/password/reset-verify',
  PASSWORD_RESET: '/v1/account/security/password/reset',
  ACCOUNT_SECURITY_UPDATE_PASSWORD: '/v1/account/security/password/update',
  ACCOUNT_DETAILS: '/v1/account/details',
  GET_ALERT_HELP: '/v1/system/integrations/chatgpt/alert-help',
  ACTIVITY: '/v1/activity',
  JOBS: '/v1/system/application/jobs',
  JOBS_START: '/v1/system/application/jobs/start',
  JOBS_PAUSE: '/v1/system/application/jobs/pause',
  INDICES: '/v1/system/application/indices',
  FIELDS: '/v1/system/application/fields',
  SYSTEM_CLUSTER_HEALTH: '/v1/system/cluster/health',
  SYSTEM_CLUSTER_STATS: '/v1/system/cluster/stats',
  SYSTEM_CLUSTER_STATE: '/v1/system/cluster/state',
  SYSTEM_CLUSTER_PENDING_TASKS: '/v1/system/cluster/pending-tasks',
  SYSTEM_CLUSTER_NODES: '/v1/system/cluster/nodes',
  SYSTEM_CLUSTER_NODES_INFO: '/v1/system/cluster/nodes-info',
  SYSTEM_CLUSTER_NODES_STATS: '/v1/system/cluster/nodes-stats',
  SYSTEM_CLUSTER_INDICES_STATS: '/v1/system/cluster/index-stats',
  SYSTEM_CLUSTER_INDICES_ALIASES: '/v1/system/cluster/index-aliases',
  SYSTEM_CLUSTER_QUERY_SANKEY: '/v1/system/cluster/query/sankey',
  SYSTEM_CLUSTER_QUERY_SEARCH: '/v1/system/cluster/query/search',
  SYSTEM_CLUSTER_QUERY_COUNT: '/v1/system/cluster/query/count',
  SYSTEM_CLUSTER_QUERY_COUNT_SERIES: '/v1/system/cluster/query/count-series',
  SYSTEM_CLUSTER_QUERY_AGG: '/v1/system/cluster/query/aggregate',
  SYSTEM_CLUSTER_QUERY_FLAT_AGG: '/v1/system/cluster/query/flat-aggregate',
  SYSTEM_CLUSTER_QUERY_MAPPINGS: '/v1/system/cluster/query/mappings',
  SYSTEM_CLUSTER_QUERY_HISTOGRAM: '/v1/system/cluster/query/histogram',
  SYSTEM_CLUSTER_QUERY_AUDIT_LOGS: '/v1/system/cluster/query/audit-logs',
  SYSTEM_CLUSTER_TRANSLOG_OPERATIONS:
    '/v1/system/cluster/cluster-nodes/health-hero-chart',
  SYSTEM_APP_SETTINGS_DISPLAY_DASHBOARDS: '/v1/system/application/dashboards',
  SYSTEM_APP_MANAGE_RELEASES: '/v1/system/application/releases',
  SYSTEM_APP_MANAGE_TEAMS: '/v1/system/application/teams',
  SYSTEM_APP_MANAGE_BOOKMARKS: '/v1/system/application/bookmarks',
  SYSTEM_APP_MANAGE_ALERTS: '/v1/system/application/alerts',
  SYSTEM_APP_MANAGE_SAVED_SEARCHES: '/v1/system/application/saved-searches',
  MANAGE_SAVED_QUERIES: '/v1/manage/saved-Searches',
  SYSTEM_APP_MANAGE_RELEASES_NOTES: '/v1/system/application/releases/notes',
  SYSTEM_APP_MANAGE_USERS: '/v1/system/application/users',
  SYSTEM_APP_MANAGE_ORGS: '/v1/system/application/organizations',
  SYSTEM_APP_MANAGE_SUBS_LEVELS: '/v1/system/application/subscriptions/levels',
  SYSTEM_APP_MANAGE_REPORTS: '/v1/system/application/reports',
  SYSTEM_APP_MANAGE_PRODUCTS: '/v1/system/application/products',
  SYSTEM_APP_MANAGE_PRODUCTS_APPLIANCES: '/v1/system/application/appliances',
  ACCOUNT_ORGANIZATIONS: '/v1/account/organizations',

  MANAGE_REPORTS: '/v1/manage/reports',
  ACCOUNT_ALERTS: '/v1/account/alerts',

  ARTICLES: '/v1/system/application/articles',
  ARTICLES_CATEGORIES: '/v1/system/application/articles/categories',
  ARTICLES_TAGS: '/v1/system/application/articles/tags',
  ROLES: '/v1/system/application/roles',
  RESOURCES: '/v1/system/application/resources',
  ORGANIZATIONS: '/v1/manage/organizations',
  APPLIANCES: '/v1/manage/appliances',
  TICKETS: '/v1/manage/tickets',
  CASES: '/v1/manage/cases',
  INCIDENTS: '/v1/manage/incidents',
  BOARDS: '/v1/manage/boards',
  LOCATIONS: '/v1/manage/locations',
  NOTIFICATIONS: '/v1/manage/notifications',
  SCANS: '/v1/manage/scans',
  SCOPES: '/v1/manage/scopes',
  SERVICES: '/v1/manage/services',
  SUBSCRIPTIONS: '/v1/manage/subscriptionLevels',
  TEAMS: '/v1/manage/teams',
  THREATS: '/v1/manage/threats'
};

export default routes;
