import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import routes from 'config/routeSettings';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';

import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 * Forgot password form
 *
 * The forgot password form is used to display a form to the user to request a password reset link.
 * @returns {React.Component} The forgot password form
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const ForgotPasswordForm = ({ sent, setSent }) => {
  let [emailAddress, setEmailAddress] = useState('');
  let [loading, setLoading] = useState(false);

  // Ref to target the input field
  let focusField = useRef();

  // Handle form submission
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await LeargasAPI.AccountSecurity.userPasswordResetRequest({
        emailAddress: emailAddress
      });
      if (res instanceof Error) throw res;
      setLoading(false);
      let message = res?.data?.message;
      if (message) toast.success(message);
      setSent(true);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);

      let message = error?.response?.data?.message;
      if (message) toast.error(message);
    }
  };

  // Focus the input field on mount
  useEffect(() => {
    if (!sent) focusField.current.focus();
  }, []);

  return (
    <Form className='mt-2'>
      <Form.Group className='mb-3'>
        <Form.Control
          placeholder={'Email address'}
          type='email'
          name='emailAddress'
          ref={focusField}
          value={emailAddress}
          onChange={({ target }) => setEmailAddress(target.value)}
          disabled={sent}
          autoComplete='off'
        />
      </Form.Group>

      <Form.Group>
        <Button
          type='button'
          className='w-100'
          disabled={!emailAddress}
          onClick={handleSubmit}>
          <div className='position-relative'>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <ButtonSpinner spinning={loading} />
              <span>{loading ? 'Sending' : 'Send'}</span>
            </Flex>
          </div>
        </Button>
      </Form.Group>

      <Button
        as={Link}
        size='sm'
        variant='link'
        className='mt-2 mb-0'
        to={routes.LOGIN}>
        <span className='d-inline-block me-1'>&larr;</span>
        Return to login
      </Button>
    </Form>
  );
};

ForgotPasswordForm.propTypes = {
  sent: PropTypes.bool.isRequired,
  setSent: PropTypes.func.isRequired
};

export default ForgotPasswordForm;
