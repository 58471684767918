import { SavedSearchesContext } from 'context/user-contexts/UserSavedSearchesProvider';
import { useContext } from 'react';

/**
 * Custom hook to use the SavedSearchesContext
 * @returns {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const useUserSavedSearches = () => {
  return useContext(SavedSearchesContext);
};

export default useUserSavedSearches;
