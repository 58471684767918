import LeargasAPI from 'api';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useESGlobalFilters } from 'hooks/useGlobalFilters';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

export const bookmarksSettings = {
  addBookmarkModal: {
    fullScreen: false,
    open: false
  },
  data: [],
  deleteBookmarkModal: {
    data: {},
    open: false
  },
  editBookmarkModal: {
    data: {},
    fullScreen: false,
    open: false
  },
  fetch: false,
  loading: false,
  requiredBookmarks: {
    bookmarks: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    },
    bookmarksNotes: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    bookmarks: {
      delete: ['bookmarks:delete'],
      read: ['bookmarks:read'],
      write: ['bookmarks:write']
    },
    bookmarksNotes: {
      delete: ['bookmarksNotes:delete'],
      read: ['bookmarksNotes:read'],
      write: ['bookmarksNotes:write']
    }
  },
  showDeleted: false
};

/**
 * Create BookmarksContext
 * @type {React.Context<{}>}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const BookmarksContext = createContext(bookmarksSettings);

/**
 * Create BookmarksProvider
 * @param props
 * @returns {JSX.Element}
 * @constructor
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 *  <BookmarksProvider>
 *   {children}
 * </BookmarksProvider>
 * )
 */

const BookmarksProvider = props => {
  const [bookmarks, setBookmarks] = useState(bookmarksSettings);
  const [loading, setLoading] = useState(true);
  const {
    esGlobalFilters: { scopes }
  } = useESGlobalFilters();

  const { axiosPrivate } = useAxiosPrivate();
  /**
   * Get all application Bookmarks
   * @returns {Promise<void>}
   * @async
   * @function
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  const getAppBookmarks = async () => {
    try {
      let res = await LeargasAPI.AppBookmarks.getBookmarks(axiosPrivate);
      if (res instanceof Error) throw res;

      setBookmarks(prevState => ({
        ...prevState,
        data: res?.data?.data
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setBookmarks(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setBookmarks(prevState => ({
        ...prevState,
        fetch: false
      }));
      setLoading(false);
    }
  };

  /**
   * Get all application Bookmarks on fetch state change
   */
  useEffect(() => {
    if (bookmarks.fetch) {
      getAppBookmarks();
    }
  }, [bookmarks.fetch]);

  /**
   * Get all application Bookmarks on component mount
   */
  useEffect(() => {
    getAppBookmarks();
  }, []);
  /**
   * Get all application Bookmarks on scopes change
   */
  useEffect(() => {
    getAppBookmarks();
  }, [scopes]);

  return (
    <BookmarksContext.Provider
      value={{
        bookmarks,
        setBookmarks,
        loading
      }}>
      {props.children}
    </BookmarksContext.Provider>
  );
};

BookmarksProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default BookmarksProvider;
