import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import routes from 'config/routeSettings';
import { getUserStatusSlug } from 'helpers/utils';
import useAccount from 'hooks/useAccount';
import useApplication from 'hooks/useApplication';
import useAuthentication from 'hooks/useAuthentication';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useLock from 'hooks/useLock';
import useLogout from 'hooks/useLogout';
import React, { useState } from 'react';
import { Button, Dropdown, Form, FormGroup, Modal } from 'react-bootstrap';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
/**
 * Represents a dropdown menu for user profile options.
 *
 * It displays the user's avatar and provides options such as updating password, locking session, and logging out.
 *
 * @returns {JSX.Element} The rendered ProfileDropdown component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
const ProfileDropdown = () => {
  const { axiosPrivate } = useAxiosPrivate();

  const { authentication: { session: { lastActiveAt } = {} } = {} } =
    useAuthentication();

  const {
    application: { isDark }
  } = useApplication();

  const {
    account: { profile: { media: { avatar = null } = {} } = {} }
  } = useAccount();

  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [formData, setFormData] = useState({
    password: '',
    confirmedPassword: ''
  });

  const logout = useLogout();
  const lock = useLock();

  // Handle form field changes
  const handleFieldChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  // Handle form submission
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await LeargasAPI.AccountSecurity.userPasswordUpdate(
        formData,
        axiosPrivate
      );
      if (res instanceof Error) throw res;

      const { message } = res.data;
      setLoading(false);
      setFormData({
        password: '',
        confirmedPassword: ''
      });
      setShowUpdatePasswordModal(false);

      toast.success(message);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);
      setFormData({
        password: '',
        confirmedPassword: ''
      });
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <>
      <Dropdown navbar={true} as='li'>
        <AdvancedPopover
          placement='bottom'
          className='position-relative'
          popoverText='Your Account'
          showArrow={true}>
          <Dropdown.Toggle
            id='userProfileAvatar'
            bsPrefix='toggle'
            className='pe-0 ps-0 m-0 p-0 nav-link bg-transparent border-0 shadow-none'
            onClick={() => {
              setShowProfileMenu(!showProfileMenu);
            }}>
            <Avatar
              src={avatar || process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG}
              className={`align-middle ms-0 ps-0 ${
                getUserStatusSlug(lastActiveAt) !== 'offline' &&
                `status-${getUserStatusSlug(lastActiveAt)}`
              }`}
            />
          </Dropdown.Toggle>
        </AdvancedPopover>

        {showProfileMenu === true && (
          <div
            id='userProfileMenu'
            onMouseLeave={() => setShowProfileMenu(false)}
            onMouseEnter={() => setShowProfileMenu(true)}
            className='dropdown-menu-card  dropdown-menu dropdown-menu-end show'
            style={{ width: '200px' }}>
            <div className='profile-menu-wrapper bg-white rounded-2 py-2 dark__bg-1000'>
              {/* <Dropdown.Item as={Link} to={routes.ACCOUNT_PROFILE} className='nav-link ps-3'>
              <span className='nav-link-icon'>
                <FontAwesomeIcon icon={['fas', 'id-card']} style={{ width: '1em' }} />
              </span>
              <span className='nav-link-text ps-1'>Your Profile</span>
            </Dropdown.Item>
            <Dropdown.Divider /> */}
              {/* <Dropdown.Item as={Link} to={routes.BOOKMARKS} className='nav-link ps-3'>
              <span className='nav-link-icon'>
                <FontAwesomeIcon icon={['fas', 'bookmark']} style={{ width: '1em' }} />
              </span>
              <span className='nav-link-text ps-1'>Your Bookmarks</span>
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={routes.NOTIFICATIONS} className='nav-link ps-3'>
              <span className='nav-link-icon'>
                <FontAwesomeIcon icon={['fas', 'bell']} style={{ width: '1em' }} />
              </span>
              <span className='nav-link-text ps-1'>Your Notifications</span>
            </Dropdown.Item> */}
              <Dropdown.Item
                as={Link}
                to={routes.ORGANIZATIONS}
                className='nav-link ps-3'>
                <span className='nav-link-icon'>
                  <FontAwesomeIcon
                    icon={['fas', 'building']}
                    style={{ width: '1em' }}
                  />
                </span>
                <span className='nav-link-text ps-1'>Your Organizations</span>
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to={routes.REPORTS}
                className='nav-link ps-3'>
                <span className='nav-link-icon'>
                  <FontAwesomeIcon
                    icon={['fas', 'file-pdf']}
                    style={{ width: '1em' }}
                  />
                </span>
                <span className='nav-link-text ps-1'>Your Reports</span>
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to={routes.SAVED_SEARCHES}
                className='nav-link ps-3'>
                <span className='nav-link-icon'>
                  <FontAwesomeIcon
                    icon={['fas', 'save']}
                    style={{ width: '1em' }}
                    transform='rotate-45'
                  />
                </span>
                <span className='nav-link-text ps-1'>Your Saved Searches</span>
              </Dropdown.Item>
              {/* <Dropdown.Item as={Link} to={routes.TEAMS} className='nav-link ps-3'>
              <span className='nav-link-icon'>
                <FontAwesomeIcon icon={['fas', 'user-group']} style={{ width: '1em' }} />
              </span>
              <span className='nav-link-text ps-1'>Your Teams</span>
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={routes.TICKETS} className='nav-link ps-3'>
              <span className='nav-link-icon'>
                <FontAwesomeIcon icon={['fas', 'tags']} style={{ width: '1em' }} />
              </span>
              <span className='nav-link-text ps-1'>Your Tickets</span>
            </Dropdown.Item> */}
              {/* <Dropdown.Divider />
            <Dropdown.Item as={Link} to={routes.ACCOUNT_SETTINGS} className='nav-link ps-3'>
              <span className='nav-link-icon'>
                <FontAwesomeIcon icon={['fas', 'cog']} style={{ width: '1em' }} />
              </span>
              <span className='nav-link-text ps-1'>Settings</span>
            </Dropdown.Item> */}
              {/* <Dropdown.Item className='fw-bold text-primary' href='#!'>
              <FontAwesomeIcon icon='shield-alt' className='me-1' style={{ width: '1em' }} />
              <span>Upgrade</span>
            </Dropdown.Item> */}
              <Dropdown.Divider />
              <Dropdown.Item
                as='a'
                onClick={() => setShowUpdatePasswordModal(true)}
                className='nav-link ps-3 cursor-pointer'>
                <span className='nav-link-icon'>
                  <FontAwesomeIcon
                    icon={['fas', 'key']}
                    style={{ width: '1em' }}
                  />
                </span>
                <span className='nav-link-text ps-1'>Update Password</span>
              </Dropdown.Item>
              <Dropdown.Item
                as='a'
                onClick={lock}
                className='nav-link ps-3 cursor-pointer'>
                <span className='nav-link-icon'>
                  <FontAwesomeIcon
                    icon={['fas', 'lock']}
                    style={{ width: '1em' }}
                  />
                </span>
                <span className='nav-link-text ps-1'>Lock Session</span>
              </Dropdown.Item>
              <Dropdown.Item
                as='a'
                onClick={() => logout(axiosPrivate)}
                className='nav-link ps-3 cursor-pointer'>
                <span className='nav-link-icon'>
                  <FontAwesomeIcon
                    icon={['fas', 'right-from-bracket']}
                    style={{ width: '1em' }}
                  />
                </span>
                <span className='nav-link-text ps-1'>Log Out</span>
              </Dropdown.Item>
            </div>
          </div>
        )}
      </Dropdown>
      <Modal
        show={showUpdatePasswordModal}
        onHide={() => setShowUpdatePasswordModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <Form onSubmit={handleSubmit}>
          <Modal.Header
            className='p-2'
            closeButton
            closeVariant={isDark ? 'white' : undefined}>
            <h5 className='d-flex  fs-0 fw-normal position-relative'>
              Update my password
              <AdvancedPopover
                containerId='resetConfirmModal'
                placement='top'
                popoverText=' Update password'
                showArrow={true}>
                <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  className='ms-1 fs--1 cursor-pointer text-400'
                />
              </AdvancedPopover>
            </h5>
          </Modal.Header>

          <Modal.Body className='p-2'>
            <FormGroup className='mb-3'>
              <Form.Control
                placeholder='Your new password'
                value={formData.password}
                name='password'
                onChange={handleFieldChange}
                type='password'
                className='fs--1'
                style={{ minHeight: '36px' }}
                required
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <Form.Control
                placeholder='Confirm your new password'
                value={formData.confirmedPassword}
                name='confirmedPassword'
                onChange={handleFieldChange}
                type='password'
                className='fs--1'
                style={{ minHeight: '36px' }}
                required
              />
            </FormGroup>
            <p className='form-label fw-semi-bold mt-3'>Password Strength</p>
            <PasswordStrengthBar password={formData.password} minLength={8} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              className='me-2'
              onClick={() => setShowUpdatePasswordModal(false)}>
              Cancel
            </Button>
            <Button
              type='submit'
              variant='success'
              disabled={
                !formData.password ||
                !formData.confirmedPassword ||
                formData.password !== formData.confirmedPassword ||
                formData.password.length < 8
              }>
              <Flex justifyContent={'center'} alignItems={'center'}>
                <ButtonSpinner spinning={loading} />
                <span>{loading ? 'Updating' : 'Update'}</span>
              </Flex>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ProfileDropdown;
