import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import { formFactors, types } from '../FormUtils';

import useAxiosPrivate from 'hooks/useAxiosPrivate';

const UpdateApplianceForm = ({
  hasLabel,
  setShowUpdateModal,
  showUpdateModal
}) => {
  const [loading, setLoading] = useState({ button: false, screen: false });
  const [orgs, setOrgs] = useState([]);

  const { axiosPrivate } = useAxiosPrivate();
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    organization: '',
    type: 'Virtual',
    formFactor: ''
  });

  useEffect(async () => {
    setLoading({ button: false, screen: true });
    const res = await LeargasAPI.Appliances.getAppliance(
      showUpdateModal?.appliance?.id,
      axiosPrivate
    );
    if (res instanceof Error) throw res;
    let appliance = res?.data?.data;
    setFormData(prevState => ({
      ...prevState,
      id: showUpdateModal?.appliance?.id,
      name: appliance?.name ? appliance?.name : '',
      organization: appliance?.organization ? appliance?.organization?.id : '',
      type: appliance?.type,
      formFactor: appliance?.formFactor ? appliance?.formFactor : ''
    }));
    LeargasAPI.Orgs.getOrgs(axiosPrivate)
      .then(orgsNew => {
        if (orgsNew instanceof Error) throw orgsNew;
        setOrgs([...orgsNew]);
        setLoading({ button: false, screen: false });
      })
      .catch(error => {
        if (error.message.includes('Network Error')) {
          console.error('Network Error occurred.');
        }
        setLoading({ button: false, screen: false });
        setOrgs([]);
      });
  }, [showUpdateModal]);

  const handleFieldChange = e => {
    let { name, value } = e.target;
    if (name === 'type' && value === 'virtual') {
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
        formFactor: ''
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const update = () => {
    setLoading({ button: true, screen: false });
    LeargasAPI.Appliances.updateAppliance(formData, axiosPrivate)
      .then(res => {
        if (res instanceof Error) throw res;
        setLoading({ button: false, screen: false });
        if (res?.data?.message) {
          setShowUpdateModal({ open: false, appliance: {}, fetch: true });
          toast.success(res?.data?.message);
        }
      })
      .catch(error => {
        setLoading({ button: false, screen: false });
        setShowUpdateModal({ open: false, appliance: {}, fetch: false });
        error?.response?.data?.message
          ? toast.error(error?.response?.data?.message)
          : toast.error('Something went wrong!');
      });
  };

  return (
    <>
      <>
        <Modal.Body className='p-2'>
          {!loading ? (
            <Form>
              <Row className='g-3 mb-3'>
                <Form.Group as={Col} sm={6} className='required'>
                  {hasLabel && <Form.Label>Name</Form.Label>}
                  <Form.Control
                    placeholder={!hasLabel ? 'Name' : ''}
                    value={formData.name}
                    name='name'
                    onChange={handleFieldChange}
                    type='text'
                    autoComplete='off'
                    className='fs--1'
                    style={{ minHeight: '36px' }}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} sm={6} className='required'>
                  {hasLabel && <Form.Label>Organization</Form.Label>}
                  <Form.Select
                    name={'organization'}
                    onChange={handleFieldChange}
                    value={
                      orgs.find(org => org?.id === formData?.organization)?.id
                    }>
                    <option value=''>Please select</option>
                    {orgs.map((org, index) => {
                      return (
                        <option value={org?.id} key={index}>
                          {org?.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row className='g-3 mb-3'>
                <Form.Group as={Col} sm={12} className='required'>
                  {hasLabel && <Form.Label>Type</Form.Label>}
                  <Form.Select
                    name={'type'}
                    onChange={handleFieldChange}
                    value={formData.type}>
                    <option value=''>Please select</option>
                    {types.map((type, index) => {
                      return (
                        <option value={type} key={index}>
                          {type}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Row>
              {formData.type === 'Physical' && (
                <Row className='g-3 mb-3'>
                  <Form.Group as={Col} sm={12} className='required'>
                    {hasLabel && <Form.Label>FormFactor</Form.Label>}
                    <Form.Select
                      name={'formFactor'}
                      onChange={handleFieldChange}
                      value={formData.formFactor}>
                      <option value=''>Please select</option>
                      {formFactors.map((formFactor, index) => {
                        return (
                          <option value={formFactor} key={index}>
                            {formFactor}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Row>
              )}
            </Form>
          ) : (
            <div
              className={` d-flex  justify-content-center align-items-center h-100 w-100 navbar-glass-loader`}
              style={{ zIndex: 1 }}>
              <Spinner animation='border' />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() =>
              setShowUpdateModal({ open: false, appliance: {}, fetch: false })
            }>
            Close
          </Button>
          <Button
            variant='primary'
            className='d-flex'
            disabled={
              !formData.name ||
              !formData.organization ||
              !formData.type ||
              (formData.type === 'Physical' && !formData.formFactor)
            }
            onClick={function () {
              update();
            }}>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <ButtonSpinner spinning={loading} />
              <span>{loading ? 'Updating' : 'Update'}</span>
            </Flex>
          </Button>
        </Modal.Footer>
      </>
    </>
  );
};

UpdateApplianceForm.propTypes = {
  hasLabel: PropTypes.bool,
  setShowUpdateModal: PropTypes.func,
  showUpdateModal: PropTypes.object,
  appliances: PropTypes.array
};

export default UpdateApplianceForm;
