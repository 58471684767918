import Banner from 'components/account/Profile/Banner';
import EducationSettings from 'components/account/ProfileEdit/EducationSettings';
import ExperiencesSettings from 'components/account/ProfileEdit/ExperiencesSettings';
import ProfileSettings from 'components/account/ProfileEdit/ProfileSettings';
import useAccount from 'hooks/useAccount';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

const ProfileEdit = () => {
  const { account } = useAccount();

  return (
    <Row className='g-3'>
      <Col lg={12}>
        <Banner user={account} />
        <ProfileSettings />
        <ExperiencesSettings />
        <EducationSettings />
      </Col>
    </Row>
  );
};

export default ProfileEdit;
