import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import StatusesTableFilters from './StatusesTableFilters';
import StatusesTableFooter from './StatusesTableFooter';
import StatusesTablePagination from './StatusesTablePagination';
import StatusesTableWrapper from './StatusesTableWrapper';
import StatusesTable from './StatuseTable';
// import AddButton from './AddButton';
import useOrganizations from 'hooks/admin-contexts/useOrganizations';
import useStatuses from 'hooks/admin-contexts/useStatuses';
import { useESGlobalFilters } from 'hooks/useGlobalFilters';
// import ShowDeletedButton from './ShowDeletedButton';
import { scopingOrgsinStatuses } from 'components/dashboards/Artifacts/Dynamic/dataFetchUtils/utils';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useApplication from 'hooks/useApplication';

import { Card } from 'react-bootstrap';
import { getStatusColumns } from './Columns/ColumnsDefaults';

/**
 * Component for base where we put the statuses complete table
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Statuses = () => {
  const {
    application: { isPrivacyMode }
  } = useApplication();

  const {
    organizations: { data, showDeleted },
    loading: componentLoading
  } = useOrganizations();

  const {
    esGlobalFilters: { scopes }
  } = useESGlobalFilters();

  const { setStatuses } = useStatuses();

  const [globalFilter, setGlobalFilter] = useState('');
  const [columns, setColumns] = useState([]);
  const [sortedTableData, setSortedTableData] = useState([]);

  /**
   * Set columns on privacy mode change
   */
  useEffect(() => {
    setColumns(getStatusColumns(setStatuses, isPrivacyMode));
  }, [isPrivacyMode]);

  /**
   * Set columns on mount
   */
  useEffect(() => {
    setColumns(getStatusColumns(setStatuses, isPrivacyMode));
  }, []);

  useEffect(() => {
    let newData = [...data];
    let filteredReports = scopingOrgsinStatuses(newData, scopes);
    setSortedTableData(
      filteredReports.sort((a, b) =>
        isPrivacyMode
          ? a?.legacy?.clientNumber - b?.legacy?.clientNumber
          : a?.name.localeCompare(b?.name)
      )
    );
  }, [data, scopes]);

  return (
    <Card className='shadow-none border'>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          <span className='d-block'>Statuses</span>
          {/* <Flex justifyContent='end' alignItems='center'>
            <ShowDeletedButton
              showDeleted={showDeleted}
              onClick={() => {
                setStatuses(prevState => ({
                  ...prevState,
                  showDeleted: !showDeleted
                }));
              }}
            />
            <AddButton
              onClick={() => {
                setStatuses(prevState => ({
                  ...prevState,
                  orgAddModal: { open: true }
                }));
              }}
            />
          </Flex> */}
        </Flex>
      </Card.Header>

      <Card.Body className='position-relative'>
        {componentLoading ? (
          <LoadingSpinner grow='10' />
        ) : (
          <StatusesTableWrapper
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            data={
              showDeleted
                ? sortedTableData
                : sortedTableData?.filter(status => !status?.isDeleted)
            }
            sortable
            loading={componentLoading}
            pagination
            perPage={25}>
            <StatusesTableFilters
              table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <StatusesTable table columns={columns} />
            {/* <AddButton
              label='Add an Status'
              onClick={() =>
                setStatuses(prevState => ({
                  ...prevState,
                  orgAddModal: { open: true }
                }))
              }
            /> */}
            <div className='mt-3 d-flex justify-content-between'>
              <StatusesTableFooter
                table
                rowInfo
                data={
                  showDeleted
                    ? sortedTableData
                    : sortedTableData?.filter(status => !status?.isDeleted)
                }
                rowCount={
                  showDeleted
                    ? sortedTableData?.length
                    : sortedTableData?.filter(status => !status?.isDeleted)
                        ?.length
                }
              />
              <StatusesTablePagination table />
            </div>
          </StatusesTableWrapper>
        )}
      </Card.Body>
    </Card>
  );
};

export default Statuses;
