import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import useJobs from 'hooks/admin-contexts/useJobs';
import useApplication from 'hooks/useApplication';
// import useAxiosPrivate from 'hooks/useAxiosPrivate';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

/**
 * Returns an array of column configurations for the job table.
 * Each column configuration includes accessor, Header, noTruncate, hasRender, cellProps, headerProps, and Cell properties.
 * The Cell property contains a function that renders the content of the cell based on the rowData.
 * @returns {Array} An array of column configurations
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
export const getJobColumns = (isPrivacyMode, axiosPrivate) => {
  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      noTruncate: true,
      hasRender: true,
      sortType: 'basic',
      cellProps: {
        className: `fs--1 text-900 align-middle px-1`
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap px-1'
      },
      Cell: rowData => {
        const {
          application: { isDark }
        } = useApplication();
        const { _id, name, data } = rowData.row.original;

        // Split the name by the first underscore and return the second index
        let nameSplit = name.split('_')[1] || name;

        return (
          <>
            <span>
              <span className='me-1'>{nameSplit}</span>
              <OverlayTrigger
                placement='top'
                trigger={['hover', 'focus']}
                delay={{ show: 750 }}
                overlay={
                  <Popover
                    style={{
                      margin: 0
                    }}
                    id={`popover-basic-${rowData?.row?.original?._id}`}>
                    <Popover.Body className='p-3 py-2 fs--1 text-1100'>
                      {data?.description
                        ? data.description
                        : 'No description available'}
                    </Popover.Body>
                  </Popover>
                }>
                {({ ref, ...triggerHandler }) => (
                  <div
                    {...triggerHandler}
                    className='d-inline-flex align-items-center'>
                    <div
                      ref={ref}
                      className={`d-inline-flex align-items-center`}
                      style={{ whiteSpace: 'nowrap', cursor: 'help' }}>
                      <span>
                        <FontAwesomeIcon
                          icon={['far', 'circle-question']}
                          className='fs--2 opacity-50'
                        />
                      </span>
                    </div>
                  </div>
                )}
              </OverlayTrigger>
              <div className='fs--2 text-muted mb-1'>{_id}</div>
              {data?.indicators ? (
                <dl className='mb-0 fs--2'>
                  {Object.values(data.indicators).map((indicator, index) => {
                    return (
                      <div key={index} className='mt-1 d-inline me-1'>
                        <div
                          className={`rounded ${
                            isDark ? 'bg-indicator-dark' : 'bg-indicator'
                          } p-1 d-inline-block w-auto mb-1`}>
                          <dt className='mb-0 d-block w-auto'>
                            {indicator.label}
                          </dt>
                          <dd className='mb-0 d-block w-auto'>
                            {indicator?.value || 'N/A'}
                          </dd>
                        </div>
                      </div>
                    );
                  })}
                </dl>
              ) : null}
            </span>
          </>
        );
      }
    }
  ];

  columns.push({
    accessor: 'data.organizationLegacyData.clientTag',
    Header: 'Organization',
    noTruncate: true,
    hasRender: true,
    display: false,
    sortType: 'basic',
    cellProps: {
      className: `fs--1 text-900 align-middle text-nowrap px-1 ${
        !isPrivacyMode ? 'd-none' : ''
      }`
    },
    headerProps: {
      className: `fs--1 align-middle text-nowrap px-1 ${
        !isPrivacyMode ? 'd-none' : ''
      }`
    },
    Cell: rowData => {
      const { data } = rowData.row.original;
      return data?.organizationLegacyData?.clientTag || '-';
    }
  });

  columns.push({
    accessor: 'data.organizationLegacyData.clientName',
    Header: 'Organization',
    noTruncate: true,
    hasRender: true,
    sortType: 'basic',
    cellProps: {
      className: `fs--1 text-900 align-middle text-nowrap px-1 ${
        isPrivacyMode ? 'd-none' : ''
      }`
    },
    headerProps: {
      className: `fs--1 align-middle text-nowrap px-1 ${
        isPrivacyMode ? 'd-none' : ''
      }`
    },
    Cell: rowData => {
      const { data } = rowData.row.original;
      return data?.organizationLegacyData?.clientName || '-';
    }
  });

  columns.push(
    {
      accessor: 'lockedAt',
      Header: 'Job Status',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-900 align-middle text-nowrap text-center px-1'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap w-150px text-center px-1'
      },
      Cell: rowData => {
        const {
          type,
          disabled,
          running,
          lockedAt,
          nextRunAt,
          failedAt,
          failReason
        } = rowData.row.original;

        if (disabled) {
          return (
            <OverlayTrigger
              placement='top'
              trigger={['hover', 'focus']}
              delay={{ show: 750 }}
              overlay={
                <Popover
                  style={{
                    margin: 0
                  }}
                  id={`popover-basic-${rowData?.row?.original?._id}`}>
                  <Popover.Body className='p-2 py-1 fs--1 text-1100'>
                    Paused
                  </Popover.Body>
                </Popover>
              }>
              {({ ref, ...triggerHandler }) => (
                <div
                  {...triggerHandler}
                  className='d-inline-flex align-items-center'>
                  <div
                    ref={ref}
                    className={`d-inline-flex align-items-center`}
                    style={{ whiteSpace: 'nowrap', cursor: 'help' }}>
                    <span
                      className={`badge badge-soft-warning border border-warning`}>
                      <FontAwesomeIcon
                        icon={['fas', 'pause']}
                        style={{ width: '1em' }}
                      />
                    </span>
                  </div>
                </div>
              )}
            </OverlayTrigger>
          );
        } else if (failReason) {
          return (
            <OverlayTrigger
              placement='top'
              trigger={['hover', 'focus']}
              delay={{ show: 750 }}
              overlay={
                <Popover
                  style={{
                    margin: 0
                  }}
                  id={`popover-basic-${rowData?.row?.original?._id}`}>
                  <Popover.Body className='p-2 py-1 fs--1 text-1100'>
                    Failed with reason: {failReason}
                  </Popover.Body>
                </Popover>
              }>
              {({ ref, ...triggerHandler }) => (
                <div
                  {...triggerHandler}
                  className='d-inline-flex align-items-center'>
                  <div
                    ref={ref}
                    className={`d-inline-flex align-items-center`}
                    style={{ whiteSpace: 'nowrap', cursor: 'help' }}>
                    <span
                      className={`badge badge-soft-danger border border-danger`}>
                      <FontAwesomeIcon
                        icon={['fas', 'skull-crossbones']}
                        style={{ width: '1em' }}
                        fade
                      />
                    </span>
                  </div>
                </div>
              )}
            </OverlayTrigger>
          );
        } else if (running) {
          return (
            <OverlayTrigger
              placement='top'
              trigger={['hover', 'focus']}
              delay={{ show: 750 }}
              overlay={
                <Popover
                  style={{
                    margin: 0
                  }}
                  id={`popover-basic-${rowData?.row?.original?._id}`}>
                  <Popover.Body className='p-2 py-1 fs--1 text-1100'>
                    Running
                  </Popover.Body>
                </Popover>
              }>
              {({ ref, ...triggerHandler }) => (
                <div
                  {...triggerHandler}
                  className='d-inline-flex align-items-center'>
                  <div
                    ref={ref}
                    className={`d-inline-flex align-items-center`}
                    style={{ whiteSpace: 'nowrap', cursor: 'help' }}>
                    <span
                      className={`badge badge-soft-success border border-success`}>
                      <FontAwesomeIcon
                        icon={['fas', 'clover']}
                        style={{ width: '1em' }}
                        spin
                        fade
                      />
                    </span>
                  </div>
                </div>
              )}
            </OverlayTrigger>
          );
        } else if (lockedAt) {
          return (
            <OverlayTrigger
              placement='top'
              trigger={['hover', 'focus']}
              delay={{ show: 750 }}
              overlay={
                <Popover
                  style={{
                    margin: 0
                  }}
                  id={`popover-basic-${rowData?.row?.original?._id}`}>
                  <Popover.Body className='p-2 py-1 fs--1 text-1100'>
                    Locked
                  </Popover.Body>
                </Popover>
              }>
              {({ ref, ...triggerHandler }) => (
                <div
                  {...triggerHandler}
                  className='d-inline-flex align-items-center'>
                  <div
                    ref={ref}
                    className={`d-inline-flex align-items-center`}
                    style={{ whiteSpace: 'nowrap', cursor: 'help' }}>
                    <span className={`badge bg-200 text-900 border border-300`}>
                      <FontAwesomeIcon
                        icon={['fas', 'lock']}
                        style={{ width: '1em' }}
                      />
                    </span>
                  </div>
                </div>
              )}
            </OverlayTrigger>
          );
        } else if (
          !failedAt &&
          nextRunAt &&
          moment(nextRunAt).isAfter(moment() + 1000)
        ) {
          return (
            <OverlayTrigger
              placement='top'
              trigger={['hover', 'focus']}
              delay={{ show: 750 }}
              overlay={
                <Popover
                  style={{
                    margin: 0
                  }}
                  id={`popover-basic-${rowData?.row?.original?._id}`}>
                  <Popover.Body className='p-2 py-1 fs--1 text-1100'>
                    Ready
                  </Popover.Body>
                </Popover>
              }>
              {({ ref, ...triggerHandler }) => (
                <div
                  {...triggerHandler}
                  className='d-inline-flex align-items-center'>
                  <div
                    ref={ref}
                    className={`d-inline-flex align-items-center`}
                    style={{ whiteSpace: 'nowrap', cursor: 'help' }}>
                    <span
                      className={`badge badge-soft-success border border-success`}>
                      <FontAwesomeIcon
                        icon={['fas', 'check']}
                        style={{ width: '1em' }}
                      />
                    </span>
                  </div>
                </div>
              )}
            </OverlayTrigger>
          );
        } else if (
          type === 'single' &&
          !nextRunAt &&
          !lockedAt &&
          !running &&
          !disabled
        ) {
          return (
            <OverlayTrigger
              placement='top'
              trigger={['hover', 'focus']}
              delay={{ show: 750 }}
              overlay={
                <Popover
                  style={{
                    margin: 0
                  }}
                  id={`popover-basic-${rowData?.row?.original?._id}`}>
                  <Popover.Body className='p-2 py-1 fs--1 text-1100'>
                    Possible scheduling issue
                  </Popover.Body>
                </Popover>
              }>
              {({ ref, ...triggerHandler }) => (
                <div
                  {...triggerHandler}
                  className='d-inline-flex align-items-center'>
                  <div
                    ref={ref}
                    className={`d-inline-flex align-items-center`}
                    style={{ whiteSpace: 'nowrap', cursor: 'help' }}>
                    <span
                      className={`badge badge-soft-danger border border-danger`}>
                      <FontAwesomeIcon
                        icon={['fas', 'warning']}
                        style={{ width: '1em' }}
                        fade
                      />
                    </span>
                  </div>
                </div>
              )}
            </OverlayTrigger>
          );
        } else {
          return (
            <OverlayTrigger
              placement='top'
              trigger={['hover', 'focus']}
              delay={{ show: 750 }}
              overlay={
                <Popover
                  style={{
                    margin: 0
                  }}
                  id={`popover-basic-${rowData?.row?.original?._id}`}>
                  <Popover.Body className='p-2 py-1 fs--1 text-1100'>
                    Ready
                  </Popover.Body>
                </Popover>
              }>
              {({ ref, ...triggerHandler }) => (
                <div
                  {...triggerHandler}
                  className='d-inline-flex align-items-center'>
                  <div
                    ref={ref}
                    className={`d-inline-flex align-items-center`}
                    style={{ whiteSpace: 'nowrap', cursor: 'help' }}>
                    <span
                      className={`badge badge-soft-primary border border-primary`}>
                      <FontAwesomeIcon
                        icon={['fas', 'check']}
                        style={{ width: '1em' }}
                      />
                    </span>
                  </div>
                </div>
              )}
            </OverlayTrigger>
          );
        }
      }
    },
    {
      accessor: 'repeatInterval',
      Header: 'Interval',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-900 align-middle text-nowrap px-1'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap w-150px px-1'
      },
      Cell: rowData => {
        const { repeatInterval } = rowData.row.original;
        return repeatInterval ? repeatInterval : 'one time';
      }
    },
    {
      accessor: 'nextRunAt',
      Header: 'Next Run',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-900 align-middle text-nowrap px-1'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap w-150px px-1'
      },
      Cell: rowData => {
        const { disabled, running, nextRunAt } = rowData.row.original;

        // Combine the log above with the logic below
        // return disabled ? 'paused' : running ? 'processing' : nextRunAt && moment(nextRunAt).isAfter(moment()) ? moment(nextRunAt).fromNow() : 'processing';

        // If the job is disabled, show paused
        if (disabled) {
          return 'paused';
        } else if (running) {
          return 'processing';
        } else if (nextRunAt && moment(nextRunAt).isAfter(moment() + 1000)) {
          return (
            <OverlayTrigger
              placement='top'
              trigger={['hover', 'focus']}
              delay={{ show: 750 }}
              overlay={
                <Popover
                  style={{
                    margin: 0
                  }}
                  id={`popover-basic-${rowData?.row?.original?._id}`}>
                  <Popover.Body className='p-2 py-1 fs--1 text-1100'>
                    {nextRunAt
                      ? moment(nextRunAt).format('MMMM Do YYYY, hh:mm:ss')
                      : 'never'}
                  </Popover.Body>
                </Popover>
              }>
              {({ ref, ...triggerHandler }) => (
                <div
                  {...triggerHandler}
                  className='d-inline-flex align-items-center'>
                  <div
                    ref={ref}
                    className={`d-inline-flex align-items-center`}
                    style={{ whiteSpace: 'nowrap', cursor: 'help' }}>
                    {nextRunAt ? moment(nextRunAt).fromNow() : 'never'}
                  </div>
                </div>
              )}
            </OverlayTrigger>
          );
        } else {
          return 'not scheduled';
        }
      }
    },
    {
      accessor: 'lastRunAt',
      Header: 'Last Run',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-900 align-middle text-nowrap px-1'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap w-150px px-1'
      },
      Cell: rowData => {
        const { lastRunAt } = rowData.row.original;
        return (
          <OverlayTrigger
            placement='top'
            trigger={['hover', 'focus']}
            delay={{ show: 750 }}
            overlay={
              <Popover
                style={{
                  margin: 0
                }}
                id={`popover-basic-${rowData?.row?.original?._id}`}>
                <Popover.Body className='p-2 py-1 fs--1 text-1100'>
                  {lastRunAt
                    ? moment(lastRunAt).format('MMMM Do YYYY, hh:mm:ss')
                    : 'never'}
                </Popover.Body>
              </Popover>
            }>
            {({ ref, ...triggerHandler }) => (
              <div
                {...triggerHandler}
                className='d-inline-flex align-items-center'>
                <div
                  ref={ref}
                  className={`d-inline-flex align-items-center`}
                  style={{ whiteSpace: 'nowrap', cursor: 'help' }}>
                  {lastRunAt ? moment(lastRunAt).fromNow() : 'never'}
                </div>
              </div>
            )}
          </OverlayTrigger>
        );
      }
    },
    {
      accessor: 'leargas_actions',
      Header: '',
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-900 align-middle text-nowrap px-1'
      },
      headerProps: {
        className: 'opacity-0 w-75px px-0'
      },
      Cell: rowData => {
        const { setJobs } = useJobs();
        const { running, disabled, type, canDelete, canPause } =
          rowData.row.original;
        const [stateCanStart, setStateCanStart] = React.useState(false);
        const [stateCanPause, setStateCanPause] = React.useState(false);

        useEffect(() => {
          if (canPause) {
            setStateCanPause(true);
          } else {
            setStateCanPause(false);
          }

          if (disabled) {
            setStateCanPause(false);
          }

          if (running) {
            setStateCanStart(false);
          } else {
            setStateCanStart(true);
          }
        }, [rowData]);

        return (
          <div className='d-flex justify-content-start'>
            <OverlayTrigger
              placement='top'
              trigger={['hover', 'focus']}
              delay={{ show: 750 }}
              overlay={
                <Popover
                  style={{
                    margin: 0
                  }}
                  id={`popover-basic-run-${rowData?.row?.original?._id}`}>
                  <Popover.Body className='p-2 py-1 fs--1 text-1100'>
                    Run
                  </Popover.Body>
                </Popover>
              }>
              {({ ref, ...triggerHandler }) => (
                <div
                  {...triggerHandler}
                  className='d-inline-flex align-items-center'>
                  <div
                    ref={ref}
                    className={`d-inline-flex align-items-center`}
                    style={{ whiteSpace: 'nowrap' }}>
                    <Button
                      className='btn px-1 py-0 bg-transparent border-0 shadow-none'
                      size='sm'
                      disabled={!stateCanStart ? true : false}
                      onClick={() => {
                        // Disable the button on click and start the job
                        setStateCanStart(false);
                        LeargasAPI.Jobs.startAppJob(
                          rowData.row.original._id,
                          axiosPrivate
                        );
                      }}>
                      <FontAwesomeIcon
                        icon={['fas', 'play']}
                        transform='grow-3'
                        className='fs--1 text-primary'
                      />
                    </Button>
                  </div>
                </div>
              )}
            </OverlayTrigger>

            <OverlayTrigger
              placement='top'
              trigger={['hover', 'focus']}
              delay={{ show: 750 }}
              overlay={
                <Popover
                  style={{
                    margin: 0
                  }}
                  id={`popover-basic-run-${rowData?.row?.original?._id}`}>
                  <Popover.Body className='p-2 py-1 fs--1 text-1100'>
                    Pause
                  </Popover.Body>
                </Popover>
              }>
              {({ ref, ...triggerHandler }) => (
                <div
                  {...triggerHandler}
                  className='d-inline-flex align-items-center'>
                  <div
                    ref={ref}
                    className={`d-inline-flex align-items-center`}
                    style={{ whiteSpace: 'nowrap' }}>
                    <Button
                      className='btn px-1 py-0 bg-transparent border-0 shadow-none'
                      size='sm'
                      disabled={!stateCanPause ? true : false}
                      onClick={() => {
                        setStateCanPause(false);
                        LeargasAPI.Jobs.pauseAppJob(
                          rowData.row.original._id,
                          axiosPrivate
                        );
                      }}>
                      <FontAwesomeIcon
                        icon={['fas', 'pause']}
                        transform='grow-7'
                        className='fs--1 text-warning'
                      />
                    </Button>
                  </div>
                </div>
              )}
            </OverlayTrigger>

            {canDelete || (!canDelete && !type === 'normal') ? (
              <OverlayTrigger
                placement='top'
                trigger={['hover', 'focus']}
                delay={{ show: 750 }}
                overlay={
                  <Popover
                    style={{
                      margin: 0
                    }}
                    id={`popover-basic-run-${rowData?.row?.original?._id}`}>
                    <Popover.Body className='p-2 py-1 fs--1 text-1100'>
                      Delete
                    </Popover.Body>
                  </Popover>
                }>
                {({ ref, ...triggerHandler }) => (
                  <div
                    {...triggerHandler}
                    className='d-inline-flex align-items-center'>
                    <div
                      ref={ref}
                      className={`d-inline-flex align-items-center`}
                      style={{ whiteSpace: 'nowrap' }}>
                      <Button
                        className='btn py-0 px-1 m-0'
                        size='sm'
                        variant='link'
                        onClick={() => {
                          setJobs(prevState => ({
                            ...prevState,
                            jobDeleteModal: {
                              open: true,
                              data: rowData.row.original
                            }
                          }));
                        }}>
                        <FontAwesomeIcon
                          icon={['fas', 'trash-alt']}
                          className='fs--1 text-danger'
                        />
                      </Button>
                    </div>
                  </div>
                )}
              </OverlayTrigger>
            ) : null}
          </div>
        );
      }
    }
  );

  return columns;
};
