import Cluster from 'context/ClusterContext';
import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import ClusterList from './ClusterList';

const ElasticsearchIndices = () => {
  return (
    <>
      <WidgetSectionTitle
        title='Search Cluster Indices Overview'
        className='mb-2'
      />
      <Cluster>
        <ClusterList />
      </Cluster>
    </>
  );
};

export default ElasticsearchIndices;
