import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { isValid, parse } from 'date-fns';
import useExplore from 'hooks/useExplore';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import DateTimePickerInput from './DateTimePickerInput';

/**
 *
 * @returns {JSX.Element} The rendered button component
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version   0.1.0-beta.5
 * @since   0.1.0-beta.5
 */
const RangeCustomSelect = ({ setShow }) => {
  const {
    state: {
      timeRangeSelected,
      timeRange: { from, to }
    },
    setState
  } = useExplore();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [signalEnter, setSignalEnter] = useState(false);
  const [customInputGte, setCustomInputGte] = useState('');
  const [customInputLte, setCustomInputLte] = useState('');

  // Handle the logic for updating the time range
  const setTime = () => {
    const inputGte = customInputGte
      ? parse(customInputGte, 'yyyy-MM-dd HH:mm:ss', new Date())
      : startDate;
    const inputLte = customInputLte
      ? parse(customInputLte, 'yyyy-MM-dd HH:mm:ss', new Date())
      : endDate;

    if (isValid(inputGte) && isValid(inputLte)) {
      if (inputGte >= inputLte) {
        toast.error('Start date must be before end date');
        return;
      }

      const { from, to } = timeRangeSelected.getTimeRange(inputGte, inputLte);
      setState('timeRange', { from, to });

      const interval = timeRangeSelected.getInterval(from, to);
      setState('interval', interval);
      setShow(false);
    } else {
      toast.error('Please enter a valid date');
    }
  };

  useEffect(() => {
    setStartDate(new Date(from));
    setEndDate(new Date(to));
  }, [from, to]);

  useEffect(() => {
    if (signalEnter) {
      setTime();
      setSignalEnter(false);
    }
  }, [signalEnter]);

  return (
    <Row className='mt-2'>
      <Col md={6} xxl={6} className='my-0 py-0'>
        <Form.Group className='mb-0'>
          <Form.Label className='fs--2 position-relative text-700 text-uppercase'>
            From
          </Form.Label>
          <DatePicker
            wrapperClassName='position-relative w-100'
            selected={startDate}
            onChange={date => {
              setStartDate(date);
              setCustomInputGte('');
            }}
            dateFormat='yyyy-MM-dd HH:mm:ss'
            showTimeSelect
            timeIntervals={15}
            customInput={
              <DateTimePickerInput
                onChangeFunc={setStartDate}
                setSignalEnter={setSignalEnter}
                setMainInput={setCustomInputGte}
              />
            }
          />
        </Form.Group>
      </Col>

      <Col md={6} xxl={6} className='my-0 py-0'>
        <Form.Group className='mb-0'>
          <Form.Label className='fs--2 position-relative text-700 text-uppercase'>
            To
          </Form.Label>
          <DatePicker
            wrapperClassName='position-relative w-100 mb-2 fs--2'
            selected={endDate}
            onChange={date => {
              setEndDate(date);
              setCustomInputLte('');
            }}
            dateFormat='yyyy-MM-dd HH:mm:ss'
            showTimeSelect
            timeIntervals={15}
            customInput={
              <DateTimePickerInput
                onChangeFunc={setEndDate}
                setSignalEnter={setSignalEnter}
                setMainInput={setCustomInputLte}
              />
            }
          />
        </Form.Group>
      </Col>

      <Col md={12} xxl={12} className='my-0 py-0'>
        <Button
          variant='primary'
          size='sm'
          className='px-2 py-1'
          onClick={setTime}>
          <Flex alignItems='center' justifyContent='center'>
            <FontAwesomeIcon
              icon={['fas', 'play']}
              className='me-1'
              transform='up-1'
            />
            <span className='me-3 fs--2'>Update</span>
          </Flex>
        </Button>
      </Col>
    </Row>
  );
};

RangeCustomSelect.propTypes = {
  setShow: PropTypes.func.isRequired
};

export default RangeCustomSelect;
