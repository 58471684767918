import { apiRoutes } from 'config/routeSettings';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useAuthentication from './useAuthentication';

/**
 * Handle locking the user's session
 *
 * @returns {Function} lock
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 *
 * @example
 * import useLock from 'hooks/useLock';
 * const lock = useLock();
 * lock();
 */
export const useLock = () => {
  const { setAuthentication } = useAuthentication();

  const { axiosPrivate } = useAxiosPrivate();

  /**
   * Lock the user's session
   * @param {object} e - The event object
   * @returns {void} Nothing
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const lock = async () => {
    try {
      setAuthentication(prevState => ({
        ...prevState,
        persistLoader: true,
        session: {
          ...prevState.session,
          device: {
            ...prevState.session.device,
            sessionLocked: true
          }
        }
      }));
      await axiosPrivate.get(apiRoutes.LOCK);
      setTimeout(function () {
        setAuthentication(prevState => ({
          ...prevState,
          persistLoader: false
        }));
      }, 1000);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setAuthentication(prevState => ({
        ...prevState,
        persistLoader: false
      }));
    }
  };
  return lock;
};

export default useLock;
