import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useOrganizations from 'hooks/admin-contexts/useOrganizations';
import useStatuses from 'hooks/admin-contexts/useStatuses';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import useAxiosPrivate from 'hooks/useAxiosPrivate';

/**
 * Form to confirm deletion of an status
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const StatusToggleForm = () => {
  const {
    statuses: { statusToggleModal },
    setStatuses
  } = useStatuses();
  const { setOrganizations } = useOrganizations();

  const { axiosPrivate } = useAxiosPrivate();

  const [loading, setLoading] = useState(false);

  const REPORT_TYPE_MAP = {
    monthly: 'monthly',
    'daily-signature': 'dailySignature',
    'daily-behavioral': 'dailyBehavioral',
    'daily-google': 'dailyGoogle',
    'daily-o365': 'dailyO365',
    'daily-windows': 'dailyWindows'
  };

  /**
   * edit confirm submit
   * @param {Event} e
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await LeargasAPI.Statuses.EditStatusesForOrgs(
        statusToggleModal?.id,
        {
          reportType: REPORT_TYPE_MAP[statusToggleModal?.reportType],
          enabled: statusToggleModal?.check
        },
        axiosPrivate
      );
      if (res instanceof Error) {
        throw res;
      }
      setOrganizations(prevState => ({
        ...prevState,
        fetch: true
      }));
      setStatuses(prevState => ({
        ...prevState,
        statusToggleModal: { open: false }
      }));

      const { message } = res;
      toast.success(message);
      setLoading(false);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);
      setStatuses(prevState => ({
        ...prevState,
        statusToggleModal: { open: false }
      }));
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='g-3 mb-3'>
        <p>
          Are you sure you want to {statusToggleModal?.enable} '
          {statusToggleModal?.reportType}' status for {statusToggleModal?.name}?
        </p>
        <Col sm={12}></Col>
      </Row>

      <Flex justifyContent='end'>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() => {
            setStatuses(prevState => ({
              ...prevState,
              statusToggleModal: {
                open: false
              }
            }));
          }}>
          Cancel
        </Button>

        <Button variant='warning' type='submit' size='sm' className='d-flex'>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>
              {loading
                ? `${statusToggleModal?.enable}ing`
                : `${statusToggleModal?.enable}`}
            </span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

export default StatusToggleForm;
