import LeargasAPI from 'api';
import LoadingIndicatorOverlay from 'components/visualizations/LoadingIndicatorOverlay';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

export const savedSearchesSettings = {
  addSavedSearchesModal: {
    fullScreen: false,
    open: false
  },
  data: [],
  indices: [],
  deleteSavedSearchesModal: {
    data: {},
    open: false
  },
  editSavedSearchesModal: {
    data: {},
    fullScreen: false,
    open: false
  },
  fetch: false,
  loading: false,
  requiredSavedSearches: {
    savedSearches: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    },
    savedSearchesNotes: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    savedSearches: {
      delete: ['savedSearches:delete'],
      read: ['savedSearches:read'],
      write: ['savedSearches:write']
    },
    savedSearchesNotes: {
      delete: ['savedSearchesNotes:delete'],
      read: ['savedSearchesNotes:read'],
      write: ['savedSearchesNotes:write']
    }
  },
  showDeleted: false
};

/**
 * Create SavedSearchesContext
 *
 * @type {React.Context<{}>}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const SavedSearchesContext = createContext(savedSearchesSettings);

/**
 * Create SavedSearchesProvider
 *
 * @param props
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 *  <SavedSearchesProvider>
 *   {children}
 * </SavedSearchesProvider>
 * )
 */
const SavedSearchesProvider = ({ path = '', ...props }) => {
  const [savedSearches, setSavedSearches] = useState(savedSearchesSettings);
  const [loading, setLoading] = useState(true);

  const { axiosPrivate } = useAxiosPrivate();

  /**
   * Get all application SavedSearches
   * @returns {Promise<void>}
   * @async
   * @function
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const getAppSavedSearches = async () => {
    try {
      let res = await LeargasAPI.AppSavedSearches.getAllSavedSearches(
        axiosPrivate
      );
      if (res instanceof Error) throw res;

      setSavedSearches(prevState => ({
        ...prevState,
        data: res?.data?.data
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setSavedSearches(prevState => ({
        ...prevState,
        fetch: false
      }));
      setLoading(false);
    } finally {
      setSavedSearches(prevState => ({
        ...prevState,
        fetch: false
      }));
      setLoading(false);
    }
  };

  const getSavedSearchById = async id => {
    try {
      let res = await LeargasAPI.AppSavedSearches.getSavedSearchById(
        id,
        axiosPrivate
      );
      if (res instanceof Error) throw res;

      const savedSearch = res?.data?.data;
      return savedSearch;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * Get all application SavedSearches on fetch state change
   */
  useEffect(() => {
    if (savedSearches.fetch) {
      getAppSavedSearches();
    }
  }, [savedSearches.fetch]);

  /**
   * Get all application SavedSearches on component mount
   */
  useEffect(() => {
    getAppSavedSearches();
  }, []);

  return (
    <SavedSearchesContext.Provider
      value={{
        savedSearches,
        setSavedSearches,
        getSavedSearchById,
        loading
      }}>
      {path !== '' && (
        <>
          {!loading ? (
            <>{props.children}</>
          ) : (
            <>
              {' '}
              <div className='content position-relative'>
                {' '}
                <div className='content-wrapper'>
                  <LoadingIndicatorOverlay loading={loading} position='top' />
                </div>
              </div>
            </>
          )}
        </>
      )}
      {path === '' && <>{props.children}</>}
    </SavedSearchesContext.Provider>
  );
};

SavedSearchesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string
};

export default SavedSearchesProvider;
