import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useRoles from 'hooks/admin-contexts/useRoles';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Form, FormText } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

/**
 * role edit form
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const RolesEditForm = hasLabel => {
  const {
    roles: { editRoleModal },
    setRoles
  } = useRoles();

  const { register, handleSubmit, reset, watch } = useForm();
  const { name, description } = watch();
  const [loading, setLoading] = useState(false);

  const { axiosPrivate } = useAxiosPrivate();

  /**
   *
   * @param {Object} formData
   * @async
   * @function
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const onSubmitData = async formData => {
    const { defaultData } = editRoleModal;
    let newData = { ...formData };
    try {
      setLoading(true);
      let res = await LeargasAPI.Roles.updateAppRole(
        defaultData?._id,
        newData,
        axiosPrivate
      );
      if (res instanceof Error) throw res;
      setLoading(false);

      setRoles(prevState => ({
        ...prevState,
        fetch: true,

        editRoleModal: { open: false, defaultData: {} }
      }));
      const { message } = res.data;
      toast.success(message);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);

      setRoles(prevState => ({
        ...prevState,

        editRoleModal: { open: false, defaultData: {} }
      }));
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  /**
   * set the form data
   */
  useEffect(() => {
    const { defaultData } = editRoleModal;
    reset({
      name: defaultData?.name,
      description: defaultData?.description
    });
  }, [editRoleModal, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmitData)}>
      <Form.Group className='required mb-2'>
        <Form.Label className='d-flex fs--1 fw-normal'>
          Name!{' '}
          <AdvancedPopover
            containerId='newFilterModal'
            placement='top'
            popoverText='Unique name for the role.'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 text-400 fs--1 cursor-pointer'
            />
          </AdvancedPopover>
        </Form.Label>
        <Form.Control
          placeholder={!hasLabel ? 'Name' : ''}
          name='name'
          {...register('name')}
          type='text'
          autoComplete='off'
          className='fs--1'
          style={{ minHeight: '36px' }}
          required
        />
        <FormText className='text-muted'>Unique name for the role.</FormText>
      </Form.Group>

      <Form.Group className='required mb-2'>
        <Form.Label className='d-flex fs--1 fw-normal'>
          Description{' '}
          <AdvancedPopover
            containerId='newFilterModal'
            placement='top'
            popoverText=' Brief description of the role and its purpose.'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 text-400 fs--1 cursor-pointer'
            />
          </AdvancedPopover>
        </Form.Label>
        <Form.Control
          placeholder={!hasLabel ? 'Description' : ''}
          name='description'
          {...register('description')}
          // type='textarea'
          autoComplete='off'
          className='fs--1'
          style={{ minHeight: '36px' }}
          required
          as='textarea'
          rows={3}
        />
        <FormText className='text-muted'>
          Brief description of the role and its purpose.
        </FormText>
      </Form.Group>

      <Flex justifyContent='end' className={'mt-3'}>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() =>
            setRoles(prevState => ({
              ...prevState,
              editRoleModal: { open: false, defaultData: {} }
            }))
          }>
          Cancel
        </Button>

        <Button
          variant='success'
          type='submit'
          size='sm'
          disabled={!name || !description}
          className='d-flex'>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Submitting' : 'Submit'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

RolesEditForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default RolesEditForm;
