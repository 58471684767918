import { getColor, rgbaColor } from 'helpers/utils';
import { useCluster } from 'hooks/admin-contexts/useCluster';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useCountUp } from 'react-countup';
import ArchSplitLineChart from './ArchSplitLineChart';

const MemoryUsage = () => {
  const {
    cluster: {
      clusterNodesStats: { nodes }
    }
  } = useCluster();
  const [memoryUsedAverage, setMemoryUsedAverage] = useState(0);
  const [barColor, setBarColor] = useState('secondary');

  useEffect(() => {
    if (memoryUsedAverage >= 90) {
      setBarColor('pastel-red');
    } else if (memoryUsedAverage >= 75) {
      setBarColor('pastel-yellow');
    } else if (memoryUsedAverage >= 0) {
      setBarColor('pastel-green');
    }
  }, [memoryUsedAverage]);

  const { update } = useCountUp({
    ref: 'memoryUsagePercent',
    decimals: 2,
    duration: 5,
    end: memoryUsedAverage || 0,
    preserveValue: true
  });

  useEffect(() => {
    if (nodes) {
      const memoryUsageArray = [];
      const nodesList = Object(Object.keys(nodes).map(key => nodes[key]));
      let usagePaths = 'os.mem.used_percent'.split('.');
      nodesList.forEach(item => {
        const status = usagePaths.reduce(
          (acc, cur) => (acc[cur] ? acc[cur] : ''),
          item
        );
        if (status !== '') memoryUsageArray.push(status);
      });
      var total = 0;
      for (var i = 0; i < memoryUsageArray.length; i++) {
        total += memoryUsageArray[i];
      }
      var avg = total / memoryUsageArray.length;
      setMemoryUsedAverage(avg);
      update(avg);
    }
  }, [nodes]);

  return (
    <Card
      className={`h-100 shadow-none border status-card ${
        !['secondary', 'green'].includes(barColor) ? barColor : null
      }`}>
      {!nodes ? (
        <div className='position-absolute d-flex justify-content-center align-items-center h-100 w-100 navbar-glass-loader'>
          <Spinner animation='border' />
        </div>
      ) : null}
      <Card.Body className='p-2'>
        <Row className='g-0'>
          <Col
            xs={5}
            className='d-flex flex-column h-100 justify-content-between'>
            <h6 className='mb-2 text-900 text-nowrap'>Memory</h6>
            <h6 className='fs-2 fw-normal mb-0'>
              <span id='memoryUsagePercent' className='font-monospace' />%
            </h6>
            <span></span>
          </Col>
          <Col xs='7' className='h-100'>
            <ArchSplitLineChart
              data={{
                labels: ['Used', 'Free'],
                values: [memoryUsedAverage, 100 - memoryUsedAverage],
                backgroundColors: [
                  rgbaColor('#FF6384', 0.8),
                  rgbaColor(getColor('secondary'), 0.1)
                ],
                borderColors: [
                  rgbaColor('#FF6384', 0),
                  rgbaColor(getColor('secondary'), 0)
                ]
              }}
              tooltipLabelFormat='storage'
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

MemoryUsage.propTypes = {
  noLink: PropTypes.bool
};

export default MemoryUsage;
