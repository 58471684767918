import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useExplore from 'hooks/useExplore';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import IndexSelectField from '../IndexSelectField';
import FieldItem from './FieldItem';

/**
 * Renders a list of selected fields.
 *
 * @returns {JSX.Element} The JSX element representing the selected fields list
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const SelectedFieldsList = () => {
  const {
    state: { fieldsSelected, fieldsSelectedFallback },
    setState
  } = useExplore();

  /**
   * Removes a field from the selected fields list.
   *
   * @param {Object} fieldToRemove - The field to be removed
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleRemoveField = fieldToRemove => {
    // If there are less than 2 fields selected, do not remove the field
    // TODO: Improve this logic to be more robust, and based on the length of the fieldsSelectedFallback array
    if (fieldsSelected.length < 2) {
      setState('fieldsSelected', fieldsSelectedFallback);
      return;
    }

    // Filter out the field to remove from the selected fields list
    // Return null in the enumeration if the field doesn't have an id
    const filteredFields = fieldsSelected.filter(
      field => field?.id && field?.id !== fieldToRemove.id
    );

    // Update the selected fields list in the Explore data
    setState('fieldsSelected', filteredFields);
  };

  return (
    <div className='bg-card p-1 border border-card rounded mb-1'>
      <IndexSelectField />
      <h4 className='d-flex fs-0 fw-normal text-700 mb-1 align-items-center'>
        Selected Fields{' '}
        <AdvancedPopover
          placement='top'
          popoverText='Fields to display as table columns.'
          showArrow={true}>
          <FontAwesomeIcon
            icon={['far', 'question-circle']}
            className='ms-1 text-400 fs--1 cursor-pointer'
          />
        </AdvancedPopover>
      </h4>
      <ListGroup variant='flush'>
        {fieldsSelected.length > 0 &&
          fieldsSelected.map(
            (field, index) =>
              field?.id &&
              field?.name &&
              field?.type &&
              field.id !== '_source' && (
                <FieldItem
                  key={index}
                  actionIcon={['fas', 'remove']}
                  actionType={field?.permanent ? 'none' : 'remove'}
                  field={field}
                  handleFieldAction={handleRemoveField}
                  index={index}
                  popoverText='Remove field from table columns.'
                  totalFields={fieldsSelected.length}
                />
              )
          )}
      </ListGroup>
    </div>
  );
};

export default SelectedFieldsList;
