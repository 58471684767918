import LoadingIndicatorOverlay from 'components/visualizations/LoadingIndicatorOverlay';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';

/**
 * Renders a table component with fields data
 *
 * @param {Object} props - The component props
 * @param {Function} props.getTableProps - Function to get the table props
 * @param {Array} props.headers - Array of table headers
 * @param {Array} props.page - Array of table rows
 * @param {Function} props.prepareRow - Function to prepare a table row for rendering
 * @param {string} props.headerClassName - CSS class name for table headers
 * @param {string} props.rowClassName - CSS class name for table rows
 * @param {Object} props.tableProps - Additional props for the table component
 * @param {boolean} props.loading - Indicates if data is being loaded
 * @returns {JSX.Element} The rendered table component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const FieldsTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  loading = false,
  refreshLoading = false
}) => {
  const {
    application: { isDark }
  } = useApplication();
  return (
    <div
      className='table-responsive position-relative'
      style={{ minHeight: '100px' }}>
      <Table
        responsive
        size='sm'
        {...getTableProps(tableProps)}
        className='simplebar-scroll-content'
        data-simplebar>
        <thead className={headerClassName}>
          <tr>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(
                  column.getSortByToggleProps(column.headerProps)
                )}>
                {column.render('Header')}
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className='sort desc' />
                    ) : (
                      <span className='sort asc' />
                    )
                  ) : (
                    <span className='sort' />
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} className={rowClassName} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      {...cell.getCellProps(cell.column.cellProps)}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <LoadingIndicatorOverlay
        backgroundClass={isDark ? 'bg-card' : 'bg-card'}
        style={{ zIndex: 1 }}
        loading={loading || refreshLoading}
        position='top'
        semiTransparent={refreshLoading ? true : false}
      />
    </div>
  );
};

FieldsTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  loading: PropTypes.bool,
  refreshLoading: PropTypes.bool
};

export default FieldsTable;
