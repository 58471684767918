import React from 'react';
import PropTypes from 'prop-types';
import ReleaseNote from './ReleaseNote';
import useAuthentication from 'hooks/useAuthentication';

const allowedRoles = ['super-admin', 'admin'];

/**
 * Map over the release notes for a release and render them
 *
 * @param {object} props Component properties
 * @param {object} props.release The release object
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ReleaseNotes = ({ release }) => {
  const {
    authentication: { roles }
  } = useAuthentication();

  return (
    <>
      {release.releaseNotes.map((note, index) => {
        return note.visible ? (
          <ReleaseNote release={release} note={note} key={index} />
        ) : (
          roles.some(role => allowedRoles.includes(role.slug)) && (
            <ReleaseNote release={release} note={note} key={index} />
          )
        );
      })}
    </>
  );
};

ReleaseNotes.propTypes = {
  release: PropTypes.shape({
    releaseNotes: PropTypes.array.isRequired
  }).isRequired
};

export default ReleaseNotes;
