import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

export const duo = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'Duo Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*duo*'],
        queryType: ['previousCount', 'count', 'sparkline']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Events',
          helpInfo: 'Total number of Duo events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*duo*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'action.keyword': 'user_create'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'action',
            value: 'user_create'
          },
          title: 'Users Created',
          helpInfo: 'Total number of events indicating a user was created'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*duo*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'action.keyword': 'user_delete'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'action',
            value: 'user_delete'
          },
          title: 'Users Deleted',
          helpInfo: 'Total number of Duo events indicating a user was deleted'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*duo*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'action.keyword': 'group_create'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'action',
            value: 'group_create'
          },
          title: 'Groups Created',
          helpInfo: 'Total number of Duo events indicating a group was created'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 4, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*duo*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'reason.keyword': 'user_marked_fraud'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: false,
          // filterData: {
          //   field: 'action',
          //   value: 'group_create'
          // },
          title: 'User Marked Fraud'
          // helpInfo: 'Total number of Duo events indicating a group was created'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 4, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*duo*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'result.keyword': 'denied'
            }
          }
        ],
        must_not: [
          {
            match_phrase: {
              'reason.keyword': 'no_response'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: false,
          // filterData: {
          //   field: 'action',
          //   value: 'group_create'
          // },
          title: 'Denied Access'
          // helpInfo: 'Total number of Duo events indicating a group was created'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 4, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*duo*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'reason.keyword': 'anomalous_push'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: false,
          // filterData: {
          //   field: 'action',
          //   value: 'group_create'
          // },
          title: 'Anomalous Push'
          // helpInfo: 'Total number of Duo events indicating a group was created'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*duo*'],
        queryType: ['histogram']
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Event Timeline',
          helpInfo:
            'Timeline histogram of all Duo events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-duo'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'action.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Actions',
          helpInfo: 'The actions performed in Duo events',
          field: 'action'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*duo*'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'result.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Outcomes',
          field: 'result',
          helpInfo: 'The outcomes of actions performed in Duo events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas*duo*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'action.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'username.keyword',
                    order: {
                      _count: 'desc'
                    },

                    size: 50
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'object.keyword',
                        order: {
                          _count: 'desc'
                        },

                        size: 50
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Actions by User',
            overlayText: 'Aggregate of actions by user',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas*duo*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'reason.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Reasons',
            overlayText: 'Reasons why events were triggered',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        name: 'Sources',
        queryType: ['table'],
        overriddenIndex: ['leargas*duo*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'email.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Events Per Email',
            overlayText: 'Aggregate of events per email address',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    }
  ];
};

export default duo;
