import React from 'react';
import PropTypes from 'prop-types';
import AppliancesProvider from '../../../../../../../context/admin-contexts/AppliancesProvider';
import AppliancesLayout from './AppliancesLayout';
// import ModalApplianceAdd from './components/modals/AppliancesAddModal';
// import ModalApplianceEdit from './components/modals/AppliancesEditModal';
import ApplianceDeleteModal from './components/modals/AppliancesDeleteModal';
import AppliancesRestoreModal from './components/modals/AppliancesRestoreModal';

/**
 * Appliances wrapper
 * @param { props }
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const AppliancesWrapper = props => {
  return (
    <AppliancesProvider>
      {props.children}
      <AppliancesLayout />
      {/* <ModalApplianceAdd />
      <ModalApplianceEdit />*/}
      <ApplianceDeleteModal />
      <AppliancesRestoreModal />
    </AppliancesProvider>
  );
};

AppliancesWrapper.propTypes = { children: PropTypes.node };

export default AppliancesWrapper;
