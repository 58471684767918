import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCloseButton from 'components/common/FalconCloseButton';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';

/**
 * Renders a search box component for filtering fields in a table
 *
 * @param {Object} props - The component props
 * @param {string} props.globalFilter - The current global filter value
 * @param {function} props.setGlobalFilter - The function to update the global filter value
 * @param {string} [props.placeholder='Search fields...'] - The placeholder text for the search box
 * @returns {JSX.Element} The rendered FieldsTableSearchBox component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const FieldsTableSearchBox = ({
  globalFilter,
  setGlobalFilter,
  placeholder = 'Search fields...'
}) => {
  const [value, setValue] = useState(globalFilter);

  /**
   * Handles the change event of the search box input
   *
   * @param {string} value - The new value of the search box input
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <InputGroup className='position-relative'>
      <FormControl
        value={value || ''}
        onChange={({ target: { value } }) => {
          setValue(value);
          onChange(value);
        }}
        size='sm'
        id='search'
        placeholder={placeholder}
        className='shadow-none'
      />
      {value && (
        <div
          className='position-absolute top-0 end-0'
          style={{ marginRight: '50px', marginTop: '3px' }}>
          <FalconCloseButton
            size='sm'
            className='fs--2 me-1'
            noOutline
            onClick={() => {
              setValue('');
              onChange('');
            }}
          />
        </div>
      )}
      <InputGroup.Text className='bg-transparent'>
        <FontAwesomeIcon icon='search' className='fs--1 text-600' />
      </InputGroup.Text>
    </InputGroup>
  );
};

FieldsTableSearchBox.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  placeholder: PropTypes.string
};

export default FieldsTableSearchBox;
