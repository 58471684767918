import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import { fieldTypeConfig } from 'helpers/field.utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'react-bootstrap';

/**
 * Renders a badge for a field with the specified type and index.
 *
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.children - The children to render in the Badge
 * @param {string} [props.className] - The class name to apply to the Badge
 * @param {string} [props.popoverText] - The text to display in the popover
 * @param {boolean} [props.showIcon=true] - Determines if the icon should be displayed
 * @param {string} props.type - The type of the field
 * @returns {JSX.Element} The rendered FieldBadge component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const FieldBadge = ({
  children,
  className,
  popoverText = null,
  showIcon = true,
  type
}) => {
  // Get the field type configuration for the specified type
  const { icon, color, backgroundColor, borderColor } =
    fieldTypeConfig[type] || fieldTypeConfig['default'];

  /**
   * Generates the default popover text for a field badge.
   *
   * @param {Object} options - The options for the popover text
   * @param {string} options.color - The color of the text
   * @param {string} options.type - The type of the field
   * @returns {JSX.Element} The JSX element representing the default popover text
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const defaultPopoverText = ({ color, type }) => {
    return (
      <span className='text-700'>
        Type <code className={`text-${color}`}>{type}</code>
      </span>
    );
  };

  return (
    <Flex className={classNames('d-block', className)}>
      <AdvancedPopover
        placement='top'
        showArrow={true}
        popoverText={
          !popoverText ? defaultPopoverText({ color, type }) : popoverText
        }>
        <Badge
          className={classNames('p-1 me-1 border', {
            [`text-${color}`]: true,
            [`border-${borderColor}`]: true,
            [`bg-${backgroundColor}`]: true
          })}>
          {showIcon && (
            <FontAwesomeIcon
              icon={icon}
              width={12}
              className={classNames({
                'me-1': React.Children.count(children) > 0
              })}
            />
          )}
          {children}
        </Badge>
      </AdvancedPopover>
    </Flex>
  );
};

FieldBadge.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  popoverText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showIcon: PropTypes.bool,
  type: PropTypes.string.isRequired
};

export default FieldBadge;
