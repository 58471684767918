import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useApplication from 'hooks/useApplication';
import useOrganizations from 'hooks/user-contexts/useUserOrganizations';
import React from 'react';
import { Modal } from 'react-bootstrap';
import RemoveUserForm from '../Forms/RemoveUserForm';
/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ModalUserRemove = () => {
  const {
    application: { isDark }
  } = useApplication();
  const {
    organizations: { removeUserModal },
    setOrganizations
  } = useOrganizations();
  return (
    <Modal
      centered
      size={'lg'}
      animation={false}
      backdrop='static'
      keyboard={false}
      show={removeUserModal?.open}
      onHide={() =>
        setOrganizations(prevState => ({
          ...prevState,
          removeUserModal: { open: false }
        }))
      }
      aria-labelledby='modalUserRemove'>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Remove User
          <AdvancedPopover
            containerId='resetConfirmModal'
            placement='top'
            popoverText='User removal'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
      </Modal.Header>

      <Modal.Body className='p-2'>
        <RemoveUserForm />
      </Modal.Body>
    </Modal>
  );
};

export default ModalUserRemove;
