import React from 'react';
import PropTypes from 'prop-types';
import AlertsProvider from 'context/admin-contexts/AlertsProvider';
import UsersProvider from 'context/admin-contexts/UsersProvider';
import OrganizationProvider from 'context/admin-contexts/OrganizationsProvider';
import AlertsLayout from './AlertsLayout';
import ModalAlertAdd from './components/modals/AlertsAddModal';
import ModalAlertEdit from './components/modals/AlertsEditModal';
import ModalAlertDelete from './components/modals/AlertsDeleteModal';
import AlertsRestoreModal from './components/modals/AlertsRestoreModal';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const AlertsWrapper = props => {
  return (
    <OrganizationProvider>
      <UsersProvider>
        <AlertsProvider>
          {props.children}
          <AlertsLayout />
          <ModalAlertAdd />
          <ModalAlertEdit />
          <ModalAlertDelete />
          <AlertsRestoreModal />
        </AlertsProvider>
      </UsersProvider>
    </OrganizationProvider>
  );
};

AlertsWrapper.propTypes = { children: PropTypes.node };

export default AlertsWrapper;
