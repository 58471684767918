import React from 'react';
import LoginForm from './LoginForm';

/**
 * Login page
 *
 * The login page is used to display a form to the user to login to their account.
 * @returns {React.Component} The login page
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const Login = () => {
  return (
    <>
      <LoginForm />
    </>
  );
};

export default Login;
