import Flex from 'components/common/Flex';
import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Image, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 *
 * @param {object} props
 * @param {object} props.article
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticlesView = ({ article }) => {
  const { thumbnail } = article;
  // const showImage = article.thumbnail || (
  //   <Placeholder
  //     className='fs--1 cursor-default testRatio position-relative rounded-3'
  //     style={{
  //       width: '100%',
  //       '--ratio': '4'
  //     }}>
  //     <Flex justifyContent='center' alignItems='center' className='text-light position-absolute all-0'>
  //       No Image
  //     </Flex>
  //   </Placeholder>
  // );

  return (
    <Card className='h-100  pt-0 recent-activity-card'>
      <Card.Body className='p-0'>
        <Col
          md={12}
          className={`me-2 gap-2 d-flex position-relative  align-items-start flex-column     `}>
          {thumbnail ? (
            <Col sm={12}>
              <Link>
                <Image width={'100%'} src={thumbnail} className='rounded-3' />
              </Link>
            </Col>
          ) : null}
          <Col
            sm={12}
            className='d-flex px-3 flex-column justify-content-around '>
            <h1 className='mt-3 position-relative'>{article?.title}</h1>

            <Flex
              direction='row mt-1'
              justifyContent={'start'}
              style={{ bottom: '1vw' }}>
              <span className='text-muted fs--2 me-2'>
                <FontAwesomeIcon icon='user' className='me-1' />
                {article?.createdBy?.profile?.fullName}
              </span>
              <span className='text-muted fs--2 me-2'>
                <FontAwesomeIcon icon='folder' className='me-1' />
                {article?.category?.title}
              </span>
              <span className='text-muted fs--2 me-2'>
                <FontAwesomeIcon
                  icon='tags'
                  className='me-1'
                  transform='down-1'
                />
                {article?.tags?.map((tag, index) => {
                  return (
                    <span key={index} className='text-muted'>
                      {tag.tag}
                    </span>
                  );
                })}
              </span>
              <span className='text-muted fs--2'>
                <FontAwesomeIcon
                  icon='clock'
                  className='me-1'
                  transform='down-1'
                />
                <Moment
                  fromNow
                  date={article.createdAt}
                  className='align-middle'
                />
              </span>
            </Flex>

            <Flex
              direction='row mt-3  '
              justifyContent={'between'}
              className={'text-700'}
              style={{ bottom: '1vw' }}>
              <div dangerouslySetInnerHTML={{ __html: article?.content }} />
            </Flex>
          </Col>
        </Col>
      </Card.Body>
    </Card>
  );
};

ArticlesView.propTypes = {
  article: PropTypes.object
};
export default ArticlesView;
