export const fieldTypeConfig = {
  boolean: {
    color: 'pastel-green',
    backgroundColor: 'soft-pastel-green',
    borderColor: '300',
    icon: 'circle-half-stroke'
  },
  datetime: {
    color: 'pastel-yellow-light',
    backgroundColor: 'soft-pastel-yellow-light',
    borderColor: '300',
    icon: 'calendar-alt'
  },
  number: {
    color: 'pastel-blue',
    backgroundColor: 'soft-pastel-blue',
    borderColor: '300',
    icon: 'hashtag'
  },
  object: {
    color: 'pastel-pink',
    backgroundColor: 'soft-pastel-pink',
    borderColor: '300',
    icon: 'code'
  },
  string: {
    color: '700',
    backgroundColor: '200',
    borderColor: '300',
    icon: 'font'
  },
  default: {
    color: '700',
    backgroundColor: '200',
    borderColor: '300',
    icon: ['far', 'question-circle']
  }
};

export const fieldTypeOrder = Object.keys(fieldTypeConfig).map(type => type);
