import MultiSelect from 'components/common/MultiSelectTImeRange';
import useExplore from 'hooks/useExplore';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
const RangePresetSelect = ({ setShow }) => {
  const fieldRef = useRef(null);

  const {
    state: { timeRanges, timeRangeSelected },
    setState
  } = useExplore();

  const [rangeList, setRangeList] = useState([]);

  /**
   * Handles the selection of a time range preset.
   *
   * @param {Object} value - The selected time range preset.
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleSelection = value => {
    setState('timeRangeSelected', value.value);

    if (value.value.unit !== 'custom') {
      // Set the selected time range

      // setState('timeRangeSelected', value.value);

      // Set the timestamps for the selected time range
      const { from, to } = value.value.getTimeRange();
      setState('timeRange', { from, to });

      // Set the interval for the selected time range
      const interval = value.value.getInterval();
      setState('interval', interval);

      // Close the dropdown
      setShow(false);
    }
  };

  /**
   * Updates the range list when the time ranges change.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    // If the time ranges exist
    if (timeRanges) {
      // Get the time range options and set the range list
      const ranges = Object.values(timeRanges).flatMap(group => {
        if (group && group.options) {
          return group.options.map(option => {
            if (option && option.label) {
              return { label: option.label, value: option };
            }
            return null;
          });
        }
        return [];
      });

      setRangeList(ranges);
    }
  }, [timeRanges]);

  /**
   * Focus on the input field when the component mounts.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    // If the field ref exists, focus on the field
    if (fieldRef.current) {
      fieldRef.current.focus();
    }
  }, []);

  return (
    <div className='position-relative w-100'>
      <Form>
        <Form.Label className='fs--2 position-relative text-700 text-uppercase'>
          Time Range
        </Form.Label>
        <MultiSelect
          onChange={handleSelection}
          options={rangeList}
          ref={fieldRef}
          size='sm'
          value={{ label: timeRangeSelected.label, value: timeRangeSelected }}
        />
      </Form>
    </div>
  );
};

RangePresetSelect.propTypes = {
  setShow: PropTypes.func.isRequired
};

export default RangePresetSelect;
