import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import ReleaseNoteActions from './ReleaseNoteActions';
import { Badge, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Renders the ReleaseNote component
 * @param {object} props Component properties
 * @param {object} props.release The release object
 * @param {object} props.note The release note object
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ReleaseNote = ({ release, note }) => {
  return (
    <Col xs={12} sm={6} lg={4} xl={3} xxl={2} style={{ marginTop: '16px' }}>
      <Flex
        direction='row'
        alignItems='center'
        className='mb-2 position-relative text-nowrap'>
        <div className='icon-item icon-item-sm rounded-circle bg-200 shadow-none me-2 z-index-1'>
          <FontAwesomeIcon
            icon={classNames({
              plus: note.type === 'added',
              wrench: note.type === 'changed',
              warning: note.type === 'deprecated',
              close: note.type === 'removed',
              check: note.type === 'fixed',
              shield: note.type === 'security'
            })}
            className={classNames('fs-0', {
              'text-secondary': release.released === false,
              'text-primary': release.released === true
            })}
          />
        </div>
        <div className='position-absolute' style={{ left: '15px', top: '0px' }}>
          <Flex direction='column' className='mb-2'>
            <Flex direction='row' alignItems='center'>
              <Badge
                bg='200'
                className='me-2 text-500 fs--1 fw-normal border border-200 ps-3'>
                {note?.type}
              </Badge>
              <ReleaseNoteActions note={note} />
            </Flex>
          </Flex>
        </div>
      </Flex>
      <Flex direction='column'>
        <div className='mb-2'>
          <h5 className='fs--1 text-800 fw-semi-bold mb-1 mt-1'>
            {note.title}
          </h5>
          <p className='fs--1 text-600 mb-0'>{note?.description}</p>
        </div>
      </Flex>
    </Col>
  );
};

ReleaseNote.propTypes = {
  release: PropTypes.shape({
    released: PropTypes.bool.isRequired
  }).isRequired,
  note: PropTypes.shape({
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired
};

export default ReleaseNote;
