import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Offcanvas } from 'react-bootstrap';
// import Flex from 'components/common/Flex';
// import BookmarksBar from './bookmarksLayout';
import useApplication from 'hooks/useApplication';
import BookmarksLayout from './BookmarksLayout';
// import useAuthentication from 'hooks/useAuthentication';

const BookmarksPanel = () => {
  const {
    application: { isDark, showBookmarks },
    setApplication
  } = useApplication();

  return (
    <Offcanvas
      show={showBookmarks}
      onHide={() => setApplication('showBookmarks', !showBookmarks)}
      placement='end'
      className='border-left border-card'>
      <Offcanvas.Header
        closeButton
        closeVariant={isDark ? 'white' : ''}
        className='bg-transparent'>
        <Offcanvas.Title as='div' className='py-0 flex-grow-1 mt-1'>
          <h5 className={'fw-light text-700'}>
            <FontAwesomeIcon
              icon='bookmark'
              className='me-2 fs--1'
              transform='up-2'
            />
            Bookmarks
          </h5>
        </Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body className='scrollbar pt-0'>
        <BookmarksLayout />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default BookmarksPanel;
