import Flex from 'components/common/Flex';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useReports from 'hooks/admin-contexts/useReports';
import useApplication from 'hooks/useApplication';
import React, { useCallback, useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import { getReportColumns } from './Columns/ColumnsDefaults';
import ReportsTable from './ReportsTable';
import ReportsTableFilters from './ReportsTableFilters';
import ReportsTableFooter from './ReportsTableFooter';
import ReportsTablePagination from './ReportsTablePagination';
import ReportsTableWrapper from './ReportsTableWrapper';

const MemoizedReportsTableFilters = React.memo(ReportsTableFilters);
const MemoizedReportsTable = React.memo(ReportsTable);
const MemoizedReportsTableFooter = React.memo(ReportsTableFooter);
const MemoizedReportsTablePagination = React.memo(ReportsTablePagination);

/**
 * Reports component renders a dashboard for managing reports.
 * It provides functionalities to view, download and delete reports.
 * The component uses various hooks to manage state and perform actions.
 *
 * @component
 * @example
 * return (
 *   <Reports />
 * )
 *
 * @returns {JSX.Element} The rendered Reports component.
 */
const Reports = () => {
  const {
    application: { isPrivacyMode }
  } = useApplication();

  const {
    reports: { data },
    loading,
    setReports
  } = useReports();

  const [globalFilter, setGlobalFilter] = useState('');

  /**
   * Handles the action of viewing a report by updating the state to open a modal with the report data.
   *
   * @param {Object} data - The data of the report to be viewed.
   */
  const handleViewReport = useCallback(
    data => {
      setReports(prevState => ({
        ...prevState,
        viewReportModal: { open: true, data, fullScreen: false }
      }));
    },
    [setReports]
  );

  /**
   * Handles the download report action by updating the state to open the download report modal.
   *
   * @param {Object} data - The data to be used in the download report modal.
   */
  const handleDownloadReport = useCallback(
    data => {
      setReports(prevState => ({
        ...prevState,
        downloadReportModal: { open: true, data, fullScreen: false }
      }));
    },
    [setReports]
  );

  /**
   * Handles the restoration of a report by opening the restore report modal
   * and setting the default data.
   *
   * @param {Object} data - The data to be restored in the report.
   */
  const handleRestoreReport = useCallback(
    data => {
      setReports(prevState => ({
        ...prevState,
        restoreReportModal: { open: true, defaultData: data }
      }));
    },
    [setReports]
  );

  /**
   * Handles the deletion of a report by opening a modal with the report's data.
   *
   * @param {Object} data - The data of the report to be deleted.
   */
  const handleDeleteReport = useCallback(
    data => {
      setReports(prevState => ({
        ...prevState,
        deleteReportModal: { open: true, defaultData: data }
      }));
    },
    [setReports]
  );

  /**
   * Memoized columns configuration for the reports table.
   *
   * @constant
   * @type {Array}
   * @param {Object} options - Options for configuring the report columns.
   * @param {boolean} options.isPrivacyMode - Flag indicating if privacy mode is enabled.
   * @param {Function} options.handleViewReport - Function to handle viewing a report.
   * @param {Function} options.handleDownloadReport - Function to handle downloading a report.
   * @param {Function} options.handleRestoreReport - Function to handle restoring a report.
   * @param {Function} options.handleDeleteReport - Function to handle deleting a report.
   * @returns {Array} Array of column configuration objects.
   */
  const columns = useMemo(
    () =>
      getReportColumns({
        isPrivacyMode,
        handleViewReport,
        handleDownloadReport,
        handleRestoreReport,
        handleDeleteReport
      }),
    [isPrivacyMode]
  );

  return (
    <Card className='shadow-none'>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          <span className='d-block'>My Reports</span>
        </Flex>
      </Card.Header>
      <Card.Body className='position-relative'>
        {loading ? (
          <LoadingSpinner grow='10' />
        ) : (
          <ReportsTableWrapper
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            data={data}
            sortable
            loading={loading}
            pagination
            perPage={50}>
            <MemoizedReportsTableFilters
              table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <MemoizedReportsTable table columns={columns} />

            <div className='mt-3 d-flex justify-content-between'>
              <MemoizedReportsTableFooter
                table
                rowInfo
                data={data}
                rowCount={data?.length}
              />
              <MemoizedReportsTablePagination table />
            </div>
          </ReportsTableWrapper>
        )}
      </Card.Body>
    </Card>
  );
};

export default Reports;
