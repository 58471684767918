import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Modal, Row, Tab, Tabs } from 'react-bootstrap';
/**
 *
 * @returns {JSX.Element} The rendered button component
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */

const VisualizationsQueryModal = React.memo(({ allOperations }) => {
  const {
    application: { isDark }
  } = useApplication();

  return (
    <>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Search Inspector
          <AdvancedPopover
            containerId='newFilterModal'
            placement='top'
            popoverText=' Search Inspector'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
      </Modal.Header>

      <Modal.Body className='p-2'>
        <Tabs defaultActiveKey={allOperations[0]?.type} className='border-0'>
          {allOperations?.map((item, index) => {
            return (
              <Tab
                key={index}
                eventKey={item?.type}
                title={item?.type}
                className='border-none mt-0 py-0 px-0'
                tabClassName='fs--1 fw-normal ps-0 pe-0 me-3'>
                <Tabs
                  defaultActiveKey='Query'
                  className='border-0  mt-1'
                  id='uncontrolled-tab-example'>
                  <Tab
                    eventKey='Query'
                    title='Query'
                    className='border-none py-0 px-0'
                    tabClassName='fs--1 fw-normal ps-0 mb-2   pe-0 me-3'>
                    <Row className='g-3  '>
                      <Col sm={12}>
                        {' '}
                        <pre className='fs--1'>
                          {JSON.stringify(item?.query, null, 2)}
                        </pre>
                      </Col>
                    </Row>
                  </Tab>

                  <Tab
                    eventKey='Response'
                    title='Response'
                    className='border-none py-0 px-0'
                    tabClassName='fs--1 fw-normal ps-0  mb-2   pe-0 me-2'>
                    <Row className='g-3   '>
                      <Col sm={12}>
                        <Col sm={12}>
                          <pre className='fs--1'>
                            {JSON.stringify(item?.response, null, 2)}
                          </pre>
                        </Col>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Tab>
            );
          })}
        </Tabs>
      </Modal.Body>
    </>
  );
});

VisualizationsQueryModal.propTypes = { allOperations: PropTypes.array };

export default VisualizationsQueryModal;

// const getOptions = (data, labels) => {
//   return {
//     ...chartJsMiniBarDefaultOptions(data, labels)
//   };
// };
// {
/* <BasicECharts
                  echarts={echarts}
                  options={getOptions(counts, changeTime ? labels : convertUtcToUserTime(labels))}
                  style={{ width: chartWidth || '8.5rem', height: 60 }}
                /> */
// }
