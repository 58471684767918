import { apiRoutes } from 'config/routeSettings';

class Roles {
  constructor(route) {
    this.route = route;
  }
  getAppRoles(axiosPrivate) {
    return axiosPrivate.get(apiRoutes.ROLES);
  }

  getAppRole(id, axiosPrivate) {
    return axiosPrivate.get(`${apiRoutes.ROLES}/${id}`);
  }

  createAppRole(data, axiosPrivate) {
    return axiosPrivate.post(apiRoutes.ROLES, data);
  }

  updateAppRole(id, data, axiosPrivate) {
    return axiosPrivate.put(`${apiRoutes.ROLES}/${id}`, data);
  }

  deleteAppRole(id, axiosPrivate) {
    return axiosPrivate.delete(`${apiRoutes.ROLES}/${id}`);
  }

  deleteAll(axiosPrivate) {
    return axiosPrivate.delete(apiRoutes.ROLES);
  }
}

export default Roles;
