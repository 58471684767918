import { useCluster } from 'hooks/admin-contexts/useCluster';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useCountUp } from 'react-countup';

const ArtifactsCount = () => {
  const {
    cluster: {
      clusterStats: {
        indices: {
          docs: { count }
        }
      }
    }
  } = useCluster();
  const [numberOfArtifactsCounter, setNumberOfArtifactsCounter] = useState(0);

  const memoizedCallback = useCallback(value => {
    return value.toLocaleString();
  }, []);

  const { update } = useCountUp({
    ref: 'numberOfArtifactsCounter',
    decimals: 0,
    duration: 3.25,
    end: numberOfArtifactsCounter || 0,
    formattingFn: memoizedCallback,
    preserveValue: true
  });

  useEffect(() => {
    if (count) {
      setNumberOfArtifactsCounter(count);
      update(count);
    }
  }, [count]);

  return (
    <Card className='h-100 shadow-none border'>
      {!count ? (
        <div className='position-absolute d-flex justify-content-center align-items-center h-100 w-100 navbar-glass-loader'>
          <Spinner animation='border' />
        </div>
      ) : null}
      <Card.Body className='p-2'>
        <Row className='g-0'>
          <Col className='d-flex flex-column justify-content-between'>
            <h6 className='mb-2'>Artifacts</h6>
            <h6 className='fs-2 fw-normal my-0'>
              <span id='numberOfArtifactsCounter' className='font-monospace' />
            </h6>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ArtifactsCount;
