import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SimpleSpinner = () => (
  <div style={{ position: 'relative' }}>
    <span style={{ top: '5px', right: '5px', zIndex: 99 }}>
      <FontAwesomeIcon
        icon={['fas', 'clover']}
        transform='grow-10'
        fade
        spin
        className={'text-primary'}
      />
    </span>
  </div>
);

SimpleSpinner.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.string
};

export default SimpleSpinner;
