import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
// import LeargasAPI from 'api';

export const statusesSettings = {
  data: [],
  DeletedOrgs: {
    data: false
  },
  fetch: false,
  loading: false,

  statusToggleModal: {
    data: {},
    open: false
  },
  requiredStatuses: {
    statuses: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    },
    statusesNotes: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    statuses: {
      delete: ['statuses:delete'],
      read: ['statuses:read'],
      write: ['statuses:write']
    },
    statusesNotes: {
      delete: ['statusesNotes:delete'],
      read: ['statusesNotes:read'],
      write: ['statusesNotes:write']
    }
  },
  showDeleted: false
};

/**
 * Create StatusesContext
 * @type {React.Context<{}>}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const StatusesContext = createContext();

/**
 * Create StatusesProvider
 * @param props
 * @returns {JSX.Element}
 * @constructor
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 *  <StatusesProvider>
 *   {children}
 * </StatusesProvider>
 * )
 */
const StatusesProvider = props => {
  const [statuses, setStatuses] = useState(statusesSettings);
  //   const [loading, setLoading] = useState(true);

  return (
    <StatusesContext.Provider
      value={{
        statuses,
        setStatuses
      }}>
      {props.children}
    </StatusesContext.Provider>
  );
};

StatusesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default StatusesProvider;
