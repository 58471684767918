import React from 'react';
import SavedSearchesWrapper from './SavedSearchesWrapper';

/**
 * Renders the SavedSearches component
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const SavedSearches = () => {
  return (
    <>
      <SavedSearchesWrapper />
    </>
  );
};

export default SavedSearches;
