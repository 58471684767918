import { useContext } from 'react';
import { AccountContext } from 'context/AccountProvider';

/**
 * Custom hook that returns the account context
 *
 * @returns {Object} The account context
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
export const useAccount = () => {
  return useContext(AccountContext);
};

export default useAccount;
