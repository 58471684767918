import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AdvancedPopover from 'components/common/Popover';
import { useSearchFilters } from 'context/FiltersProvider';
import useApplication from 'hooks/useApplication';
import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import ManageFiltersMenu from './ManageFiltersMenu';
import SearchFiltersList from './SearchFiltersList';

/**
 * Returns the SearchFiltersPanel component which contains the search filters list.
 *
 * Uses a collapse component to hide/show the search filters list conditionally.
 *
 * @returns {JSX.Element} The rendered SearchFiltersPanel component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const SearchFiltersPanel = () => {
  const {
    application: { showSimpleFilters, isDark },
    setApplication
  } = useApplication();

  const {
    searchFilters: { filters },
    setSearchFilters
  } = useSearchFilters();

  const contentRef = useRef(null);

  return (
    <div className='d-block position-relative' style={{ zIndex: 1 }}>
      <WidgetSectionTitle
        buttonPrefix={<ManageFiltersMenu />}
        buttonSuffix={
          <div
            className={classNames('pe-2', {
              'bg-100 hover-100': isDark,
              'bg-200 hover-100': !isDark
            })}
            style={{ paddingTop: '1px' }}>
            <AdvancedPopover
              placement='top'
              showArrow={true}
              offset={[0, 0]}
              popoverText='Add a new filter'>
              <Button
                className={classNames(
                  'text-decoration-none fs--2 border-400 border-dotted border-hover-primary bg-transparent hover-primary text-700 outline-0 shadow-none m-0 p-0'
                )}
                onClick={() =>
                  setSearchFilters(prev => ({
                    ...prev,
                    modalFilterAdd: {
                      ...prev.modalFilterAdd,
                      open: true
                    }
                  }))
                }
                variant='none'>
                <FontAwesomeIcon icon='plus' width={16} />
              </Button>
            </AdvancedPopover>
          </div>
        }
        title='Search Filters'
        className='mb-1'
      />
      <div className='filters-collapse overflow-hidden' ref={contentRef}>
        <div
          className={classNames('position-relative', {
            collapsed: !showSimpleFilters,
            'd-none': filters && filters.length === 0
          })}>
          <SearchFiltersList />
        </div>
      </div>
      {filters && filters.length > 0 && (
        <div
          className={classNames('w-100 filters-collapse-button-container', {
            'border-top border-200': isDark && !showSimpleFilters,
            'border-top border-300': !isDark && !showSimpleFilters
          })}>
          <Button
            className={classNames(
              'w-100 text-decoration-none fs--2 text-700 hover-primary outline-0 shadow-none border-0 m-0 pt-0 pe-2',
              {
                'bg-100': isDark,
                'bg-200': !isDark
              }
            )}
            onClick={() =>
              setApplication('showSimpleFilters', !showSimpleFilters)
            }
            variant='outline-pastel-green-dark'>
            {filters.filter(filter => filter.enabled).length} filter
            {filters.filter(filter => filter.enabled).length > 1
              ? 's'
              : ''}{' '}
            applied
            <FontAwesomeIcon
              icon={showSimpleFilters ? 'chevron-up' : 'chevron-down'}
              width={16}
              transform={showSimpleFilters ? 'down-1' : 'up-1'}
            />
          </Button>
        </div>
      )}
    </div>
  );
};

export default SearchFiltersPanel;
