import ReportsProvider from 'context/user-contexts/UserReportsProvider';
import PropTypes from 'prop-types';
import React from 'react';
import { pdfjs } from 'react-pdf';
import ReportsLayout from './ReportsLayout';
import ModalReportDownload from './components/modals/DownloadModal';
import ModalReportView from './components/modals/ReportsViewModal';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ReportsWrapper = props => {
  return (
    <ReportsProvider>
      {props.children}
      <ReportsLayout />
      <ModalReportView />
      <ModalReportDownload />
    </ReportsProvider>
  );
};

ReportsWrapper.propTypes = {
  children: PropTypes.node
};

export default ReportsWrapper;
