import NavigationCard from 'components/common/NavigationCard';
import routes from 'config/routeSettings';
import React from 'react';
import { Row } from 'react-bootstrap';
import { systemRoutes } from 'routes/routes.js';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';

/**
 * Renders the Application page
 *
 * @returns {JSX.Element} The rendered Application page
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.2
 */
const ApplicationPage = () => {
  const menuItems = systemRoutes.children.find(
    route => route.to === routes.SYSTEM_APP
  );

  return (
    <>
      <WidgetSectionTitle
        title={menuItems?.pageTitle || menuItems?.name}
        className='mb-2'
      />
      <Row className='g-1 mb-1'>
        {menuItems.children.map((route, index) => (
          <NavigationCard route={route} key={index} index={index} />
        ))}
      </Row>
    </>
  );
};

export default ApplicationPage;
