import { useContext } from 'react';
import { OrganizationsContext } from 'context/admin-contexts/OrganizationsProvider';

/**
 * Custom hook to use the OrganizationsContext
 * @returns {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const useOrganizations = () => {
  return useContext(OrganizationsContext);
};

export default useOrganizations;
