import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import OrganizationsWrapper from './OrganizationsWrapper';

/**
 * Organizations which are used in layout section of route
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Organizations = () => {
  return (
    <>
      <WidgetSectionTitle
        title='Organizations'
        transform='down-3'
        className='mb-2'
      />
      <OrganizationsWrapper />
    </>
  );
};

export default Organizations;
