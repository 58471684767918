import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Form, FormGroup, Pagination } from 'react-bootstrap';
import uuid from 'react-uuid';

export const AdvancedTablePagination = ({
  pageCount,
  pageIndex,
  gotoPage,
  hidePageNumbers
}) => {
  const [pageButtons, setPageButtons] = React.useState([]);
  const pageNumber = Number(pageIndex) + 1;

  const createPaginationItem = i => {
    return (
      <Pagination.Item
        key={i}
        active={i === pageNumber}
        onClick={() => gotoPage(i - 1)}
        className='text-center text-nowrap fs--2'
        linkClassName='px-2 py-0'>
        <span className='fs--2'>{i.toLocaleString()}</span>
      </Pagination.Item>
    );
  };

  // Build pagination items on state change
  useEffect(() => {
    let items = [];
    setPageButtons([]);
    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        items.push(createPaginationItem(i));
      }
    } else {
      if (pageNumber <= 3) {
        for (let i = 1; i <= 5; i++) {
          items.push(createPaginationItem(i));
        }
      } else if (pageNumber >= pageCount - 2) {
        for (let i = pageCount - 4; i <= pageCount; i++) {
          items.push(createPaginationItem(i));
        }
      } else {
        for (let i = pageNumber - 2; i <= pageNumber + 1; i++) {
          items.push(createPaginationItem(i));
        }
      }
    }
    setPageButtons(items);
  }, [pageIndex, pageCount, gotoPage]);

  return (
    <Flex direction={'row'} alignItems='center'>
      <FormGroup className='fs--2 d-flex align-items-center ms-2'>
        <p className='mb-0 me-1'>Go to page:</p>
        <Form.Select
          name={uuid()}
          size='sm'
          className='w-auto filter-input fs--2 py-0'
          onChange={e => gotoPage(e.target.value)}
          value={Number(pageIndex)}>
          {(() => {
            const options = [];
            for (let i = 1; i <= pageCount; i++) {
              options.push(
                <option value={i - 1} key={i - 1}>
                  {i.toLocaleString()}
                </option>
              );
            }
            return options;
          })()}
        </Form.Select>
      </FormGroup>
      {!hidePageNumbers && (
        <Pagination size='xs' className='my-0 ms-2 fs--2'>
          <Pagination.First
            onClick={() => gotoPage(0)}
            disabled={pageNumber === 1}
            className='text-center text-nowrap fs--2'
            linkClassName='px-2 py-0'
          />
          <Pagination.Prev
            onClick={() => gotoPage(pageNumber - 2)}
            disabled={pageNumber === 1}
            className='text-center text-nowrap fs--2'
            linkClassName='px-2 py-0'
          />
          {pageButtons}
          <Pagination.Next
            onClick={() => gotoPage(pageNumber)}
            disabled={pageNumber === pageCount}
            className='text-center text-nowrap fs--2'
            linkClassName='px-2 py-0'
          />
          <Pagination.Last
            onClick={() => gotoPage(pageCount - 1)}
            disabled={pageNumber === pageCount}
            className='text-center text-nowrap fs--2'
            linkClassName='px-2 py-0'
          />
        </Pagination>
      )}
    </Flex>
  );
};

AdvancedTablePagination.propTypes = {
  pageCount: PropTypes.number,
  pageIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  gotoPage: PropTypes.func,
  hidePageNumbers: PropTypes.bool
};

export default AdvancedTablePagination;
