import FalconLink from 'components/common/FalconLink';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import routes from 'config/routeSettings';
import useUserReports from 'hooks/user-contexts/useUserReports';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Report } from './GeneralReport';

const GeneralReportView = ({
  cardTitle = 'Latest Reports',
  icon = 'file-pdf',
  linkTo = routes.REPORTS,
  linkTitle = 'All Reports',
  slice = 10
}) => {
  const {
    reports: { data },
    loading,
    // loading: componentLoading,
    setReports
  } = useUserReports();

  return (
    <Card className='recent-activity-card p-2 border border-card shadow-none'>
      {cardTitle && (
        <h6 className='mb-3 text-nowrap text-700 fw-normal z-index-2 position-relative'>
          {cardTitle}
        </h6>
      )}
      <Card.Body className='ps-0 p-0 pe-1'>
        {!loading &&
          data
            ?.slice(0, data.length > slice ? slice : data.length)
            .map((report, index) => (
              <Report
                key={report.id}
                icon={icon}
                setReports={setReports}
                report={report}
                isLast={
                  index ===
                  data.slice(0, data.length > slice ? slice : data.length)
                    .length -
                    1
                }
              />
            ))}
        {loading && <LoadingSpinner grow='10' />}{' '}
        <div
          className={`position-absolute text-center all-0 pt-3 align-items-center justify-content-around bg-card-nodata fs--1 ${
            !loading && data?.length === 0 ? 'd-flex' : 'd-none'
          }`}>
          You don't have any reports
        </div>
      </Card.Body>
      <Card.Footer className='p-0 pb-2 pe-2 text-end'>
        <FalconLink
          title={linkTitle}
          className='px-0 pt-0 fw-medium'
          to={linkTo}
        />
      </Card.Footer>
    </Card>
  );
};

GeneralReportView.propTypes = {
  data: PropTypes.arrayOf(Report.propTypes.activity),
  cardTitle: PropTypes.string,
  icon: PropTypes.string,
  linkTo: PropTypes.string,
  linkTitle: PropTypes.string,
  slice: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default GeneralReportView;
