import Breadcrumbs from 'components/common/Breadcrumb';
import Footer from 'components/common/Footer';
import NavbarTop from 'components/navbar/top/NavbarTop';
import useAuthentication from 'hooks/useAuthentication';
import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

/**
 * Category Layout Component.
 *
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const CategoryLayout = () => {
  const { hash } = useLocation();

  const {
    authentication: { roles },
    setAuthentication
  } = useAuthentication();

  useEffect(() => {
    const hasMatchingRole = roles.some(role =>
      ['admin', 'super-admin'].includes(role.slug)
    );
    setAuthentication(prevState => ({
      ...prevState,
      isAdmin: hasMatchingRole
    }));
  }, [roles]);

  useEffect(() => {
    var timeout = setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className='content position-relative'>
      <NavbarTop />
      <div className='content-wrapper'>
        <Breadcrumbs />
        <Outlet />
      </div>
      <div className='pt-4'>&nbsp;</div>
      <Footer />
    </div>
  );
};

export default CategoryLayout;
