import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

/**
 * Render button to add a new button
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ShowDeletedButton = ({ showDeleted, ...rest }) => {
  return (
    <OverlayTrigger
      placement='top'
      overlay={
        <Popover>
          <Popover.Body className='text-center p-1 fs--1 text-700'>
            Only show deleted organizations
          </Popover.Body>
        </Popover>
      }>
      <Button
        {...rest}
        size='sm'
        variant='link'
        className={`position-relative cursor-pointer icon-item icon-item-sm rounded-circle border border-${
          showDeleted ? 'primary' : 'secondary'
        } shadow-none ms-2 me-2`}>
        <FontAwesomeIcon
          icon='trash-can'
          className={`text-${showDeleted ? 'primary' : 'secondary'}`}
        />
      </Button>
    </OverlayTrigger>
  );
};
ShowDeletedButton.propTypes = {
  showDeleted: PropTypes.bool
};

export default ShowDeletedButton;
