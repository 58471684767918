import Flex from 'components/common/Flex';
import VisualizationsQueryModal from 'components/visualizations/InspectorModal';
import LoadingIndicatorOverlay from 'components/visualizations/LoadingIndicatorOverlay';
import { getColor } from 'helpers/utils';
import useApplication from 'hooks/useApplication';
import useAuthentication from 'hooks/useAuthentication';
import useExplore from 'hooks/useExplore';
import React, { useEffect, useState } from 'react';
import { Card, Dropdown, Modal } from 'react-bootstrap';
import HistogramChart from './HistogramChart';
const { default: CardDropdown } = require('components/common/CardDropdown');

/**
 * ExploreHistogram component.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Array} props.histogram - The histogram data
 * @param {string} props.histogramInterval - The interval for the histogram
 * @param {boolean} props.wrapperLoader - The loader state for the wrapper
 * @returns {JSX.Element} The rendered ExploreHistogram component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const ExploreHistogram = () => {
  const [internalLoader, setInternalLoader] = useState(false);
  const [queryDiscoverModal, setQueryDiscover] = useState(false);
  const { application: isDark } = useApplication();
  const {
    authentication: { isAdmin }
  } = useAuthentication();
  const {
    state: { histogram, histogramLoading, interval, documentsTotal }
  } = useExplore();

  useEffect(() => {
    setInternalLoader(histogramLoading);
  }, [histogramLoading]);

  return (
    <>
      <Card className='bg-soft-pastel-green overflow-hidden h-100 shadow-none border border-card rounded'>
        <Card.Body
          className='p-2'
          as={Flex}
          justifyContent='between'
          direction='column'
          style={{ minHeight: '232px' }}>
          {!internalLoader && isAdmin && (
            <Flex
              justifyContent='center'
              alignContent='middle'
              className={'position-absolute top-0 end-0 cursor-pointer'}>
              <CardDropdown style={{ zIndex: 9999, position: 'absolute' }}>
                <div className='py-2'>
                  <Dropdown.Item
                    onClick={() => {
                      setQueryDiscover(true);
                    }}>
                    Inspect
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            </Flex>
          )}
          <LoadingIndicatorOverlay
            backgroundClass={
              isDark ? 'bg-soft-pastel-green' : 'bg-soft-pastel-green'
            }
            style={{ zIndex: 1 }}
            loading={internalLoader}
          />
          {histogram && Array.isArray(histogram) && (
            <>
              <Flex justifyContent='around'>
                <span
                  className='fs--2 fw-semi-bold mb-0'
                  style={{ color: getColor('gray-700') }}>
                  {histogram && Array.isArray(histogram) && (
                    <>
                      {Number(documentsTotal).toLocaleString()}
                      <span className='ms-1'>Artifacts</span>
                    </>
                  )}
                </span>
              </Flex>
              <HistogramChart
                data={histogram || []}
                style={{ height: '200px' }}
              />
              <Flex justifyContent='around'>
                <span
                  className='fs--2 fw-semi-bold'
                  style={{ color: getColor('gray-700') }}>
                  @timestamp per {interval}
                </span>
              </Flex>
            </>
          )}
        </Card.Body>
      </Card>
      <Modal
        centered
        size={'lg'}
        animation={false}
        show={queryDiscoverModal}
        onHide={() => setQueryDiscover(false)}
        aria-labelledby='modal-explore-histogram-query-inspect'>
        <VisualizationsQueryModal allOperations={[histogram]} />
      </Modal>
    </>
  );
};

export default ExploreHistogram;
