import { axiosPublic } from 'api/connection/axios';

/**
 * @description Account Security API
 * @summary Provides methods for managing a user's account security settings
 * @method getAccountSecurity Get a user's account security settings
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
class AccountSecurity {
  constructor(route) {
    this.paths = route;
  }

  /**
   * @description Get the current user's multifactor qr setup code
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * AccountSecurity.getUserMultifactorAppQr();
   */
  async getUserMultifactorAppQr(axiosPrivate) {
    try {
      return await axiosPrivate.get(this.paths.mfa.app.qr.path, {
        responseType: 'arraybuffer'
      });
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Get the current user's multifactor secret setup code
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * AccountSecurity.getUserMultifactorAppSecret();
   */
  async getUserMultifactorAppSecret(axiosPrivate) {
    try {
      const res = await axiosPrivate.get(this.paths.mfa.app.secret.path);
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Request a password reset email
   * @param {string} method - The request method
   * @param {object} request - The request object
   * @throws {Error} Invalid request method
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * AccountSecurity.userPasswordResetRequest({ body: { email: '' } });
   */
  async userPasswordResetRequest(requestBody) {
    try {
      return await axiosPublic.instance.post(
        this.paths.password.resetRequest.path,
        JSON.stringify(requestBody)
      );
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Reset a user's password
   * @param {object} request - The request object
   * @throws {Error} Invalid request method
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * AccountSecurity.userPasswordReset({
   *   body: {
   *     password: '',
   *     confirmPassword: '',
   *     passwordResetToken: ''
   *   }
   * });
   */
  async userPasswordReset(request) {
    try {
      return await axiosPublic.instance.post(
        this.paths.password.reset.path,
        JSON.stringify(request.body)
      );
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Verify a user's password reset token
   * @param {object} request - The request object
   * @throws {Error} Invalid request method
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * AccountSecurity.userPasswordResetVerify({
   *   body: {
   *     passwordResetToken: ''
   *   }
   * });
   */
  async userPasswordResetVerify(request) {
    try {
      return await axiosPublic.instance.post(
        this.paths.password.resetVerify.path,
        JSON.stringify(request.body)
      );
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Update the currently logged in user's password
   * @param {object} request - The request object
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * AccountSecurity.userPasswordUpdate({
   *   body: {
   *     password: '',
   *     confirmPassword: ''
   *   }
   * });
   */
  async userPasswordUpdate(_formData, axiosPrivate) {
    try {
      return await axiosPrivate.post(
        this.paths.password.update.path,
        JSON.stringify(_formData)
      );
      // return _formData;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default AccountSecurity;
