import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useArticles from 'hooks/admin-contexts/useArticles';
import Flex from 'components/common/Flex';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const { default: CardDropdown } = require('components/common/CardDropdown');

/**
 * Renders the ArticleTag component
 *
 * @param {object} props
 * @param {Object} props.tag
 * @param {Number} props.index
 * @param {Boolean} props.isLast
 * @param {String} props.className
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ArticleTag = ({ tag, index, isLast, className }) => {
  const { setArticles } = useArticles();

  return (
    <Flex
      direction='row'
      alignItems='center'
      className={classNames(
        className,
        'py-3 align-items-start justify-content-between flex-row',
        {
          'border-bottom': !isLast,
          'mb-2': isLast,
          'pt-0': index === 0
        }
      )}>
      <Flex className='flex-column'>
        <span className='fs--1'>{tag.tag}</span>
      </Flex>

      <Flex justifyContent='end' alignContent='middle'>
        <CardDropdown>
          <div className='py-2'>
            <Dropdown.Item
              onClick={() =>
                setArticles(prevState => ({
                  ...prevState,
                  editArticleTagModal: { open: true, defaultData: tag }
                }))
              }>
              <FontAwesomeIcon icon='pencil' className='me-2' />
              Edit Details
            </Dropdown.Item>
            <div className='dropdown-divider'></div>
            <Dropdown.Item
              className={`text-danger`}
              onClick={() => {
                // isDeleted
                //   ?
                // setOrganizations(prevState => ({
                //     ...prevState,
                //     orgRestoreModal: { open: true, data: rowData.row.original }
                //   }))
                // :
                setArticles(prevState => ({
                  ...prevState,
                  deleteArticleTagModal: { open: true, defaultData: tag }
                }));
              }}>
              <FontAwesomeIcon
                icon={
                  // isDeleted ? 'trash-restore-alt' :
                  'trash'
                }
                className='me-2'
              />
              {/* {isDeleted ? 'Restore' : */}
              Delete
              {/* } */}
            </Dropdown.Item>
          </div>
        </CardDropdown>
      </Flex>
    </Flex>
  );
};

ArticleTag.propTypes = {
  tag: PropTypes.object,
  index: PropTypes.number,
  isLast: PropTypes.bool,
  className: PropTypes.string
};

export default ArticleTag;
