import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert, CloseButton } from 'react-bootstrap';
import Flex from './Flex';

/**
 * IconAlert component.
 *
 * @component
 * @param {string} variant - The variant of the alert
 * @param {string} icon - The icon to be displayed
 * @param {boolean} dismissible - Whether the alert is dismissible or not
 * @param {ReactNode} children - The content of the alert
 * @param {string} className - Additional CSS class names for the alert
 * @param {function} onClose - Function to be called when the alert is closed
 * @param {object} rest - Additional props to be spread on the Alert component
 * @returns {JSX.Element} The rendered IconAlert component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const IconAlert = ({
  variant,
  icon,
  dismissible,
  children,
  className,
  onClose,
  ...rest
}) => {
  return (
    <Alert variant={variant} className={classNames(className)} {...rest}>
      <Flex direction='row' alignItems='center'>
        <div className={`mx-2`}>
          <FontAwesomeIcon icon={icon} className='fs-3' />
        </div>
        <div className='flex-1'>{children}</div>
        {dismissible && <CloseButton onClick={onClose} />}
      </Flex>
    </Alert>
  );
};

IconAlert.propTypes = {
  variant: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  dismissible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func
};

export default IconAlert;
