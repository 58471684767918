import Flex from 'components/common/Flex';
import React from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import routes from 'config/routeSettings';
import { Image, Placeholder, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**
 * @param {object} props
 * @param {Number} props.index
 * @param {Boolean} props.isLast
 * @param {Object} props.article
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticlesListView = ({ index, isLast, article }) => {
  const { thumbnail, slug } = article;

  const showImage = article.thumbnail || (
    <Placeholder
      className='rounded-3 fs--2 cursor-default testRatio position-relative'
      style={{ minHeight: '5vw' }}>
      <Flex
        justifyContent='center'
        alignItems='center'
        className='text-light position-absolute all-0'>
        No Image
      </Flex>
    </Placeholder>
  );

  return (
    <Flex
      direction='row'
      className={`py-3 gap-2 align-items-start flex-row ${
        isLast ? 'mb-2' : ''
      } ${index === 0 ? 'pt-0' : ''} ${!isLast ? 'border-bottom' : ''}`}>
      <Col sm={1}>
        <Link to={[routes.ARTICLES, slug].join('/')}>
          {thumbnail ? (
            <Image width={'100%'} src={thumbnail} className='rounded-3' />
          ) : (
            showImage
          )}
        </Link>
      </Col>
      <Col
        sm={11}
        className='d-flex flex-column justify-content-around'
        style={{ minHeight: '4vw' }}>
        <Link
          to={[routes.ARTICLES, slug].join('/')}
          className='text-decoration-none'>
          {article.title}
        </Link>

        <Flex direction='row'>
          <span className='text-muted fs--2 me-3'>
            {article?.createdBy?.profile?.fullName}
          </span>
          <span className='text-muted fs--2'>
            {' '}
            <Moment fromNow className='  align-middle'>
              {article.createdAt}
            </Moment>{' '}
          </span>
        </Flex>
      </Col>
    </Flex>
  );
};

ArticlesListView.propTypes = {
  index: PropTypes.number,
  isLast: PropTypes.bool,
  article: PropTypes.object
};
export default ArticlesListView;
