import LeargasAPI from 'api';
import { axiosPrivate } from 'api/connection/axios';
import useAccount from './useAccount';
import useAuthentication from './useAuthentication';
import useLogout from './useLogout';

/**
 * Custom hook for refreshing authentication token.
 * @returns {Object} An object containing the refresh function and the useEffect cleanup function.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
export const useRefreshToken = () => {
  const { setAuthentication } = useAuthentication();

  const { setAccount } = useAccount();

  const logout = useLogout();

  /**
   * Uses a closure to manage the retry timeout, making it simpler to clear it when necessary.
   */
  let retryTimeout;

  /**
   * Clears the retry timeout if it exists.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const clearRetryTimeout = () => {
    if (retryTimeout) {
      clearTimeout(retryTimeout);
      retryTimeout = null; // Make sure to nullify the handle after clearing
    }
  };

  /**
   * Refreshes the authentication token.
   * @param {number} maxRetryCount - The maximum number of retry attempts.
   * @param {number} tryCount - The current retry attempt count.
   * @returns {Promise<void>} - A promise that resolves when the refresh is complete.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const refresh = async (maxRetryCount = 5, tryCount = 1) => {
    // Handling the 'refreshing' state should ideally be inside this function if it affects the UI or logic.
    setAuthentication(prevState => ({
      ...prevState,
      refreshing: true
    }));

    clearRetryTimeout(); // Preemptively clear any previous timeout to avoid overlaps

    try {
      const res = await LeargasAPI.AccountAuth.refresh();
      if (res instanceof Error) throw res;
      const response = JSON.parse(
        JSON.stringify(res?.data?.data?.authentication)
      );

      // Update states
      setAccount(prevState => ({
        ...prevState,
        id: res?.data?.data?.id,
        ...res?.data?.data?.account
      }));
      setAuthentication(prevState => ({
        ...prevState,
        ...response
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }
      //
      if (tryCount < maxRetryCount) {
        retryTimeout = setTimeout(
          () => refresh(maxRetryCount, tryCount + 1),
          3000
        );
      } else {
        logout(axiosPrivate);
      }
    } finally {
      setAuthentication(prevState => ({
        ...prevState,
        refreshing: false
      }));
    }
  };

  /**
   * Cleans up the effect by clearing the retry timeout.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const useEffectCleanup = () => () => {
    clearRetryTimeout();
  };

  return { refresh, useEffectCleanup };
};

export default useRefreshToken;
