import Flex from 'components/common/Flex';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useAlerts from 'hooks/admin-contexts/useAlerts';
import React, { useEffect, useState } from 'react';
import AddButton from './AddButton';
import AlertsTable from './AlertsTable';
import AlertsTableFilters from './AlertsTableFilters';
import AlertsTableFooter from './AlertsTableFooter';
import AlertsTablePagination from './AlertsTablePagination';
import AlertsTableWrapper from './AlertsTableWrapper';
import ShowDeletedButton from './ShowDeletedButton';
// import useOrganizations from 'hooks/admin-contexts/useOrganizations';
import { Card } from 'react-bootstrap';
import { getAlertColumns } from './Columns/ColumnsDefaults';

/**
 * alerts
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Alerts = () => {
  const {
    alerts: { data, showDeleted },
    loading: componentLoading,
    setAlerts
  } = useAlerts();
  // const { organizations } = useOrganizations();
  const [columns, setColumns] = useState([]);

  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    setColumns(getAlertColumns(setAlerts));
  }, [data]);

  return (
    <Card className='shadow-none border'>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          <span className='d-block'>Alerts</span>
          <Flex justifyContent='end' alignItems='center'>
            <ShowDeletedButton
              showDeleted={showDeleted}
              onClick={() => {
                setAlerts(prevState => ({
                  ...prevState,
                  showDeleted: !showDeleted
                }));
              }}
            />
            <AddButton
              onClick={() =>
                setAlerts(prevState => ({
                  ...prevState,
                  addAlertModal: { open: true }
                }))
              }
            />
          </Flex>
        </Flex>
      </Card.Header>
      <Card.Body className='position-relative'>
        {componentLoading ? (
          <LoadingSpinner />
        ) : (
          <AlertsTableWrapper
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            // data={alerts}
            data={showDeleted ? data : data?.filter(alert => !alert?.isDeleted)}
            sortable
            loading={componentLoading}
            pagination
            perPage={50}>
            <AlertsTableFilters
              table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <AlertsTable table columns={columns} />
            <AddButton
              label='Add an Alert'
              onClick={() =>
                setAlerts(prevState => ({
                  ...prevState,
                  addAlertModal: { open: true }
                }))
              }
            />
            <div className='mt-3 d-flex justify-content-between'>
              <AlertsTableFooter
                table
                rowInfo
                data={
                  showDeleted ? data : data?.filter(alert => !alert?.isDeleted)
                }
                rowCount={
                  showDeleted
                    ? data?.length
                    : data?.filter(alert => !alert?.isDeleted).length
                }
              />
              <AlertsTablePagination table />
            </div>
          </AlertsTableWrapper>
        )}
      </Card.Body>
    </Card>
  );
};

export default Alerts;
