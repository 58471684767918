import { apiRoutes } from 'config/routeSettings';

const routes = {
  account: {
    register: {
      path: apiRoutes.REGISTER,
      method: 'POST'
    },
    activate: {
      path: apiRoutes.ACTIVATE,
      method: 'POST'
    },
    auth: {
      authenticate: {
        path: '/v1/account/auth/key',
        method: 'POST'
      },
      login: {
        path: '/v1/account/auth/login',
        method: 'POST'
      },
      verify: {
        path: '/v1/account/auth/verify',
        method: 'POST'
      },
      refresh: {
        path: '/v1/account/auth/refresh',
        method: 'GET'
      },
      lock: {
        path: '/v1/account/auth/lock',
        method: 'GET'
      },
      unlock: {
        path: '/v1/account/auth/unlock',
        method: 'POST'
      },
      logout: {
        path: '/v1/account/auth/logout',
        method: 'GET'
      }
    },
    security: {
      mfa: {
        app: {
          qr: {
            path: '/v1/account/security/mfa/app/qr',
            method: 'GET'
          },
          secret: {
            path: '/v1/account/security/mfa/app/secret',
            method: 'GET'
          },
          verify: {
            path: '/v1/account/security/mfa/app/verify',
            method: 'POST'
          }
        }
      },
      password: {
        resetRequest: {
          path: '/v1/account/security/password/reset-request',
          method: 'POST'
        },
        reset: {
          path: '/v1/account/security/password/reset',
          method: 'POST'
        },
        resetVerify: {
          path: '/v1/account/security/password/reset-verify',
          method: 'GET'
        },
        update: {
          path: '/v1/account/security/password/update',
          method: 'POST'
        }
      }
    },
    details: {
      path: '/v1/account/details',
      method: 'GET'
    }
  },
  Activities: {
    getActivities: {
      path: `${apiRoutes.ACTIVITY}/recent`,
      method: 'GET'
    },
    getRecentActivity: {
      path: `${apiRoutes.ACTIVITY}/recent`,
      method: 'GET'
    },
    allActivity: {
      path: apiRoutes.ACTIVITY,
      method: 'GET'
    },
    getQueriedActivities: {
      path: apiRoutes.ACTIVITY,
      method: 'GET'
    },
    getQueriedActivityById: {
      path: apiRoutes.ACTIVITY,
      method: 'GET'
    }
  },
  Explore: {
    getAvailableFields: {
      path: '/v1/explore/fields',
      method: 'GET'
    },
    getAvailableIndices: {
      path: '/v1/explore/indices',
      method: 'GET'
    },
    getDocuments: {
      path: '/v1/explore/search',
      method: 'POST'
    },
    getTopValues: {
      path: '/v1/explore/top-values',
      method: 'POST'
    }
  },
  Appliances: {
    getAppliance: {
      path: apiRoutes.SYSTEM_APP_MANAGE_PRODUCTS_APPLIANCES,
      method: 'GET'
    },
    getAppliances: {
      path: apiRoutes.SYSTEM_APP_MANAGE_PRODUCTS_APPLIANCES,
      method: 'GET'
    },
    createAppliance: {
      path: apiRoutes.SYSTEM_APP_MANAGE_PRODUCTS_APPLIANCES,
      method: 'POST'
    },
    updateAppliance: {
      path: apiRoutes.SYSTEM_APP_MANAGE_PRODUCTS_APPLIANCES,
      method: 'PUT'
    },
    deleteAppliance: {
      path: apiRoutes.SYSTEM_APP_MANAGE_PRODUCTS_APPLIANCES,
      method: 'DELETE'
    }
  },
  Dashboards: {
    createDashboard: {
      path: apiRoutes.SYSTEM_APP_SETTINGS_DISPLAY_DASHBOARDS,
      method: 'POST'
    },
    getDashboards: {
      path: apiRoutes.SYSTEM_APP_SETTINGS_DISPLAY_DASHBOARDS,
      method: 'GET'
    },
    getDashboard: {
      path: apiRoutes.SYSTEM_APP_SETTINGS_DISPLAY_DASHBOARDS,
      method: 'GET'
    },
    updateDashboard: {
      path: apiRoutes.SYSTEM_APP_SETTINGS_DISPLAY_DASHBOARDS,
      method: 'PUT'
    },
    deleteDashboard: {
      path: apiRoutes.SYSTEM_APP_SETTINGS_DISPLAY_DASHBOARDS,
      method: 'DELETE'
    }
  },

  Indices: {
    getIndices: { path: apiRoutes.INDICES, method: 'GET' },
    updateIndices: { path: apiRoutes.INDICES, method: 'PUT' }
  },
  Fields: {
    getFields: { path: apiRoutes.FIELDS, method: 'GET' },
    getField: { path: apiRoutes.FIELDS + '/id', method: 'GET' },
    updateFields: { path: apiRoutes.FIELDS, method: 'PUT' }
  },
  Boards: {
    getAll: {
      path: apiRoutes.BOARDS,
      method: 'GET'
    },
    get: {
      path: apiRoutes.BOARDS,
      method: 'GET'
    },
    create: {
      path: apiRoutes.BOARDS,
      method: 'POST'
    },
    update: {
      path: apiRoutes.BOARDS,
      method: 'PUT'
    },
    delete: {
      path: apiRoutes.BOARDS,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.TICKETS,
      method: 'DELETE'
    }
  },
  Cases: {
    getAll: {
      path: apiRoutes.CASES,
      method: 'GET'
    },
    get: {
      path: apiRoutes.CASES,
      method: 'GET'
    },
    create: {
      path: apiRoutes.CASES,
      method: 'POST'
    },
    update: {
      path: apiRoutes.CASES,
      method: 'PUT'
    },
    delete: {
      path: apiRoutes.CASES,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.TICKETS,
      method: 'DELETE'
    }
  },
  Incidents: {
    getAll: {
      path: apiRoutes.INCIDENTS,
      method: 'GET'
    },
    get: {
      path: apiRoutes.INCIDENTS,
      method: 'GET'
    },
    create: {
      path: apiRoutes.INCIDENTS,
      method: 'POST'
    },
    update: {
      path: apiRoutes.INCIDENTS,
      method: 'PUT'
    },
    delete: {
      path: apiRoutes.INCIDENTS,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.INCIDENTS,
      method: 'DELETE'
    }
  },
  Locations: {
    getAll: {
      path: apiRoutes.LOCATIONS,
      method: 'GET'
    },
    get: {
      path: apiRoutes.LOCATIONS,
      method: 'GET'
    },
    create: {
      path: apiRoutes.LOCATIONS,
      method: 'POST'
    },
    update: {
      path: apiRoutes.LOCATIONS,
      method: 'PUT'
    },
    delete: {
      path: apiRoutes.LOCATIONS,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.LOCATIONS,
      method: 'DELETE'
    }
  },
  Notifications: {
    getAll: {
      path: apiRoutes.NOTIFICATIONS,
      method: 'GET'
    },
    get: {
      path: apiRoutes.NOTIFICATIONS,
      method: 'GET'
    },
    create: {
      path: apiRoutes.NOTIFICATIONS,
      method: 'POST'
    },
    update: {
      path: apiRoutes.NOTIFICATIONS,
      method: 'PUT'
    },
    delete: {
      path: apiRoutes.NOTIFICATIONS,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.NOTIFICATIONS,
      method: 'DELETE'
    }
  },
  Roles: {
    getAll: {
      path: apiRoutes.ROLES,
      method: 'GET'
    },
    get: {
      path: apiRoutes.ROLES,
      method: 'GET'
    },
    create: {
      path: apiRoutes.ROLES,
      method: 'POST'
    },
    update: {
      path: apiRoutes.ROLES,
      method: 'PUT'
    },
    delete: {
      path: apiRoutes.ROLES,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.ROLES,
      method: 'DELETE'
    }
  },
  Scans: {
    getAll: {
      path: apiRoutes.SCANS,
      method: 'GET'
    },
    get: {
      path: apiRoutes.SCANS,
      method: 'GET'
    },
    create: {
      path: apiRoutes.SCANS,
      method: 'POST'
    },
    update: {
      path: apiRoutes.SCANS,
      method: 'PUT'
    },
    delete: {
      path: apiRoutes.SCANS,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.SCANS,
      method: 'DELETE'
    }
  },

  Permissions: {
    getAll: {
      path: apiRoutes.RESOURCES,
      method: 'GET'
    },
    get: {
      path: apiRoutes.RESOURCES,
      method: 'GET'
    },
    create: {
      path: apiRoutes.RESOURCES,
      method: 'POST'
    },
    update: {
      path: apiRoutes.RESOURCES,
      method: 'PUT'
    },
    delete: {
      path: apiRoutes.RESOURCES,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.RESOURCES,
      method: 'DELETE'
    }
  },
  Services: {
    getAll: {
      path: apiRoutes.SERVICES,
      method: 'GET'
    },
    get: {
      path: apiRoutes.SERVICES,
      method: 'GET'
    },
    create: {
      path: apiRoutes.SERVICES,
      method: 'POST'
    },
    update: {
      path: apiRoutes.SERVICES,
      method: 'PUT'
    },
    delete: {
      path: apiRoutes.SERVICES,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.SERVICES,
      method: 'DELETE'
    }
  },
  SubscriptionLevels: {
    getAll: {
      path: apiRoutes.SUBSCRIPTIONS,
      method: 'GET'
    },
    get: {
      path: apiRoutes.SUBSCRIPTIONS,
      method: 'GET'
    },
    create: {
      path: apiRoutes.SUBSCRIPTIONS,
      method: 'POST'
    },
    update: {
      path: apiRoutes.SUBSCRIPTIONS,
      method: 'PUT'
    },
    delete: {
      path: apiRoutes.SUBSCRIPTIONS,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.SUBSCRIPTIONS,
      method: 'DELETE'
    }
  },
  accountOrganizations: {
    createAccOrg: {
      path: apiRoutes.ACCOUNT_ORGANIZATIONS,
      method: 'POST'
    },
    deleteAccOrg: {
      path: apiRoutes.ACCOUNT_ORGANIZATIONS,
      method: 'DELETE'
    },
    updateAccOrg: {
      path: apiRoutes.ACCOUNT_ORGANIZATIONS,
      method: 'PUT'
    },
    getAccOrganization: {
      path: apiRoutes.ACCOUNT_ORGANIZATIONS,
      method: 'GET'
    },

    getPendingInvitations: {
      path: `${apiRoutes.ACCOUNT_ORGANIZATIONS}/invitations/pending`,
      method: 'POST'
    },
    removePendingUser: {
      path: `${apiRoutes.ACCOUNT_ORGANIZATIONS}/invitations/delete`,
      method: 'POST'
    },
    removeUserFromOrganization: {
      path: `${apiRoutes.ACCOUNT_ORGANIZATIONS}/remove-user`,
      method: 'POST'
    },
    inviteUserToOrganization: {
      path: `${apiRoutes.ACCOUNT_ORGANIZATIONS}/invitations`,
      method: 'POST'
    },
    getAccOrgs: {
      path: apiRoutes.ACCOUNT_ORGANIZATIONS,
      method: 'GET'
    }
  },
  accountAlerts: {
    createAccAlerts: {
      path: apiRoutes.ACCOUNT_ALERTS,
      method: 'POST'
    },
    deleteAccAlert: {
      path: apiRoutes.ACCOUNT_ALERTS,
      method: 'DELETE'
    },
    updateAccAlert: {
      path: apiRoutes.ACCOUNT_ALERTS,
      method: 'PUT'
    },
    getAccAlert: {
      path: apiRoutes.ACCOUNT_ALERTS,
      method: 'GET'
    },

    getAccAlerts: {
      path: apiRoutes.ACCOUNT_ALERTS,
      method: 'GET'
    }
  },

  Organizations: {
    createAppOrg: {
      path: apiRoutes.SYSTEM_APP_MANAGE_ORGS,
      method: 'POST'
    },
    deleteAppOrg: {
      path: apiRoutes.SYSTEM_APP_MANAGE_ORGS,
      method: 'DELETE'
    },
    updateAppOrg: {
      path: apiRoutes.SYSTEM_APP_MANAGE_ORGS,
      method: 'PUT'
    },
    getAppOrganization: {
      path: apiRoutes.SYSTEM_APP_MANAGE_ORGS,
      method: 'GET'
    },
    getAppOrgs: {
      path: apiRoutes.SYSTEM_APP_MANAGE_ORGS,
      method: 'GET'
    }
  },
  Scopes: {
    getUserScope: {
      path: apiRoutes.SCOPES,
      method: 'GET'
    }
  },
  SavedSearches: {
    getSavedSearchById: {
      path: apiRoutes.MANAGE_SAVED_QUERIES,
      method: 'GET'
    },
    getAllSavedSearches: {
      path: apiRoutes.MANAGE_SAVED_QUERIES,
      method: 'GET'
    },
    createSavedSearches: {
      path: apiRoutes.MANAGE_SAVED_QUERIES,
      method: 'POST'
    },
    updateSavedSearches: {
      path: apiRoutes.MANAGE_SAVED_QUERIES,
      method: 'PUT'
    },
    deleteSavedSearches: {
      path: apiRoutes.MANAGE_SAVED_QUERIES,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.MANAGE_SAVED_QUERIES,
      method: 'DELETE'
    }
  },
  Jobs: {
    getAppJobs: {
      path: apiRoutes.JOBS,
      method: 'GET'
    },
    deleteAppJob: {
      path: apiRoutes.JOBS,
      method: 'DELETE'
    },
    startAppJob: {
      path: apiRoutes.JOBS_START,
      method: 'POST'
    },
    pauseAppJob: {
      path: apiRoutes.JOBS_PAUSE,
      method: 'POST'
    }
  },
  Threats: {
    getAll: {
      path: apiRoutes.THREATS,
      method: 'GET'
    },
    get: {
      path: apiRoutes.THREATS,
      method: 'GET'
    },
    create: {
      path: apiRoutes.THREATS,
      method: 'POST'
    },
    update: {
      path: apiRoutes.THREATS,
      method: 'PUT'
    },
    delete: {
      path: apiRoutes.THREATS,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.THREATS,
      method: 'DELETE'
    }
  },
  AppProducts: {
    getProducts: {
      path: apiRoutes.SYSTEM_APP_MANAGE_PRODUCTS,
      method: 'GET'
    },
    getProduct: {
      path: apiRoutes.SYSTEM_APP_MANAGE_PRODUCTS,
      method: 'GET'
    },
    createProduct: {
      path: apiRoutes.SYSTEM_APP_MANAGE_PRODUCTS,
      method: 'POST'
    },
    updateProduct: {
      path: apiRoutes.SYSTEM_APP_MANAGE_PRODUCTS,
      method: 'PUT'
    },
    deleteProduct: {
      path: apiRoutes.SYSTEM_APP_MANAGE_PRODUCTS,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.SYSTEM_APP_MANAGE_PRODUCTS,
      method: 'DELETE'
    }
  },
  AppReports: {
    getReports: {
      path: apiRoutes.SYSTEM_APP_MANAGE_REPORTS,
      method: 'GET'
    },
    getReport: {
      path: apiRoutes.SYSTEM_APP_MANAGE_REPORTS,
      method: 'GET'
    },
    createReport: {
      path: apiRoutes.SYSTEM_APP_MANAGE_REPORTS,
      method: 'POST'
    },
    updateReport: {
      path: apiRoutes.SYSTEM_APP_MANAGE_REPORTS,
      method: 'PUT'
    },
    deleteReport: {
      path: apiRoutes.SYSTEM_APP_MANAGE_REPORTS,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.SYSTEM_APP_MANAGE_REPORTS,
      method: 'DELETE'
    }
  },
  MangeReports: {
    getReports: {
      path: apiRoutes.MANAGE_REPORTS,
      method: 'GET'
    },
    getReport: {
      path: apiRoutes.MANAGE_REPORTS,
      method: 'GET'
    },
    createReport: {
      path: apiRoutes.MANAGE_REPORTS,
      method: 'POST'
    },
    updateReport: {
      path: apiRoutes.MANAGE_REPORTS,
      method: 'PUT'
    },
    deleteReport: {
      path: apiRoutes.MANAGE_REPORTS,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.MANAGE_REPORTS,
      method: 'DELETE'
    }
  },
  AppSubscriptionLevels: {
    getSubscriptionLevels: {
      path: apiRoutes.SYSTEM_APP_MANAGE_SUBS_LEVELS,
      method: 'GET'
    },
    getSubscriptionLevel: {
      path: apiRoutes.SYSTEM_APP_MANAGE_SUBS_LEVELS,
      method: 'GET'
    },
    createSubscriptionLevel: {
      path: apiRoutes.SYSTEM_APP_MANAGE_SUBS_LEVELS,
      method: 'POST'
    },
    updateSubscriptionLevel: {
      path: apiRoutes.SYSTEM_APP_MANAGE_SUBS_LEVELS,
      method: 'PUT'
    },
    deleteSubscriptionLevel: {
      path: apiRoutes.SYSTEM_APP_MANAGE_SUBS_LEVELS,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.SYSTEM_APP_MANAGE_SUBS_LEVELS,
      method: 'DELETE'
    }
  },
  AppTeams: {
    getTeams: {
      path: apiRoutes.SYSTEM_APP_MANAGE_TEAMS,
      method: 'GET'
    },
    getTeam: {
      path: apiRoutes.SYSTEM_APP_MANAGE_TEAMS,
      method: 'GET'
    },
    createTeam: {
      path: apiRoutes.SYSTEM_APP_MANAGE_TEAMS,
      method: 'POST'
    },
    updateTeam: {
      path: apiRoutes.SYSTEM_APP_MANAGE_TEAMS,
      method: 'PUT'
    },
    deleteTeam: {
      path: apiRoutes.SYSTEM_APP_MANAGE_TEAMS,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.SYSTEM_APP_MANAGE_TEAMS,
      method: 'DELETE'
    }
  },
  AppBookmarks: {
    getBookmarks: {
      path: apiRoutes.SYSTEM_APP_MANAGE_BOOKMARKS,
      method: 'GET'
    },
    getBookmark: {
      path: apiRoutes.SYSTEM_APP_MANAGE_BOOKMARKS,
      method: 'GET'
    },
    createBookmark: {
      path: apiRoutes.SYSTEM_APP_MANAGE_BOOKMARKS,
      method: 'POST'
    },
    updateBookmark: {
      path: apiRoutes.SYSTEM_APP_MANAGE_BOOKMARKS,
      method: 'PUT'
    },
    deleteBookmark: {
      path: apiRoutes.SYSTEM_APP_MANAGE_BOOKMARKS,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.SYSTEM_APP_MANAGE_BOOKMARKS,
      method: 'DELETE'
    }
  },
  AppAlerts: {
    getAlerts: {
      path: apiRoutes.SYSTEM_APP_MANAGE_ALERTS,
      method: 'GET'
    },
    getAlert: {
      path: apiRoutes.SYSTEM_APP_MANAGE_ALERTS,
      method: 'GET'
    },
    createAlert: {
      path: apiRoutes.SYSTEM_APP_MANAGE_ALERTS,
      method: 'POST'
    },
    updateAlert: {
      path: apiRoutes.SYSTEM_APP_MANAGE_ALERTS,
      method: 'PUT'
    },
    deleteAlert: {
      path: apiRoutes.SYSTEM_APP_MANAGE_ALERTS,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.SYSTEM_APP_MANAGE_ALERTS,
      method: 'DELETE'
    }
  },
  AppSavedSearches: {
    getSavedSearchById: {
      path: apiRoutes.SYSTEM_APP_MANAGE_SAVED_SEARCHES,
      method: 'GET'
    },
    getAllSavedSearches: {
      path: apiRoutes.SYSTEM_APP_MANAGE_SAVED_SEARCHES,
      method: 'GET'
    },
    createSavedSearches: {
      path: apiRoutes.SYSTEM_APP_MANAGE_SAVED_SEARCHES,
      method: 'POST'
    },
    updateSavedSearches: {
      path: apiRoutes.SYSTEM_APP_MANAGE_SAVED_SEARCHES,
      method: 'PUT'
    },
    deleteSavedSearches: {
      path: apiRoutes.SYSTEM_APP_MANAGE_SAVED_SEARCHES,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.SYSTEM_APP_MANAGE_SAVED_SEARCHES,
      method: 'DELETE'
    }
  },
  Tickets: {
    getTickets: {
      path: apiRoutes.TICKETS,
      method: 'GET'
    },
    getTicket: {
      path: apiRoutes.TICKETS,
      method: 'GET'
    },
    createTicket: {
      path: apiRoutes.TICKETS,
      method: 'POST'
    },
    updateTicket: {
      path: apiRoutes.TICKETS,
      method: 'PUT'
    },
    deleteTicket: {
      path: apiRoutes.TICKETS,
      method: 'DELETE'
    },
    deleteAll: {
      path: apiRoutes.TICKETS,
      method: 'DELETE'
    }
  },
  Teams: {
    createTeam: {
      path: apiRoutes.TEAMS,
      method: 'GET'
    },
    getTeams: {
      path: apiRoutes.TEAMS,
      method: 'GET'
    },
    getTeam: {
      path: apiRoutes.TEAMS,
      method: 'POST'
    },
    updateTeams: {
      path: apiRoutes.TEAMS,
      method: 'PUT'
    },
    deleteTeam: {
      path: apiRoutes.TEAMS,
      method: 'DELETE'
    }
  },
  Releases: {
    getAppReleases: {
      path: '/v1/system/application/releases',
      method: 'GET'
    },
    createAppRelease: {
      path: '/v1/system/application/releases',
      method: 'POST'
    },
    updateAppRelease: {
      path: '/v1/system/application/releases',
      method: 'PUT'
    },
    deleteAppRelease: {
      path: '/v1/system/application/releases',
      method: 'DELETE'
    },
    createAppReleaseNote: {
      path: '/v1/system/application/releases/notes',
      method: 'POST'
    },
    updateAppReleaseNote: {
      path: '/v1/system/application/releases/notes',
      method: 'PUT'
    },
    deleteAppReleaseNote: {
      path: '/v1/system/application/releases/notes',
      method: 'DELETE'
    }
  },
  Users: {
    createUser: {
      path: '/v1/system/application/users',
      method: 'POST'
    },
    getUsers: {
      path: '/v1/system/application/users',
      method: 'GET'
    },
    getUser: {
      path: '/v1/system/application/users',
      method: 'GET'
    },
    updateUser: {
      path: '/v1/system/application/users',
      method: 'PUT'
    },
    deleteUser: {
      path: '/v1/system/application/users',
      method: 'DELETE'
    },
    lockUser: {
      path: '/v1/system/application/users',
      method: 'PUT'
    },
    unlockUser: {
      path: '/v1/system/application/users',
      method: 'PUT'
    }
  },
  Statuses: {
    EditStatus: {
      path: '/v1/system/application/organizations/set-legacy-reports',
      method: 'PUT'
    }
  },
  userRoles: {
    createUserRole: {
      path: apiRoutes.ROLES,
      method: 'POST'
    },
    getUserRoles: { path: apiRoutes.ROLES, method: 'GET' },
    getUserRole: { path: apiRoutes.ROLES, method: 'GET' },
    updateUserRole: { path: apiRoutes.ROLES, method: 'PUT' },
    deleteUserRole: { path: apiRoutes.ROLES, method: 'DELETE' }
  }
};

export default routes;
