/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';
import { Form } from 'react-bootstrap';
import uuid from 'react-uuid';
import Flex from '../Flex';

export const AdvancedTableFooter = ({
  page,
  pageSize = 5,
  pageIndex,
  rowCount,
  setPageSize,
  rowsPerPageSelection,
  rowsPerPageOptions = [5, 10, 15],
  className,
  hidePerPage
}) => {
  return (
    <Flex
      className={classNames(
        className,
        'align-items-center justify-content-between'
      )}>
      <Flex alignItems='center' className='fs--2'>
        {rowCount && (
          <p className='mb-0'>
            <span className='d-none d-sm-inline-block me-2'>
              {(pageSize * pageIndex + 1).toLocaleString()} to{' '}
              {(pageSize * pageIndex + page.length).toLocaleString()} of{' '}
              {rowCount.toLocaleString()}
            </span>
          </p>
        )}
        {!hidePerPage && rowsPerPageSelection && (
          <>
            <p className='mb-0'>Per page:</p>
            <Form.Select
              name={uuid()}
              size='sm'
              className='w-auto filter-input fs--2 ms-1 py-0'
              onChange={e => setPageSize(Number(e.target.value))}
              value={pageSize}>
              {rowsPerPageOptions.map(value => (
                <option value={value} key={value}>
                  {value.toLocaleString()}
                </option>
              ))}
            </Form.Select>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default AdvancedTableFooter;
