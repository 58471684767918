import ArticlesViewWrapper from 'components/dashboards/System/Application/Manage/Articles/components/views/ArticlesView/ArticlesViewWrapper';
import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';

/**
 * Renders the NewsUpdates page component
 *
 * @returns {JSX.Element} The rendered NewsUpdates page component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const NewsUpdates = () => {
  return (
    <>
      <WidgetSectionTitle
        title='News & Updates'
        transform='down-3'
        className='mb-2'
      />
      <ArticlesViewWrapper />
    </>
  );
};

export default NewsUpdates;
