import LeargasAPI from 'api';

import Flex from 'components/common/Flex';
import TinymceEditor from 'components/common/TinymceEditor';
import useArticles from 'hooks/admin-contexts/useArticles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CreatableMultiselect from 'components/common/CreatableMultiselect';
import { DatePickerInput } from 'components/common/datepicker';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

/**
 * @param {Boolean} hasLabel
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticleEditForm = hasLabel => {
  const {
    articles: { editArticleModal, tags, categories },
    setArticles
  } = useArticles();
  const { register, handleSubmit, reset, setValue, watch } = useForm();

  const { axiosPrivate } = useAxiosPrivate();
  const [selfControlData, setSelfControlData] = useState({
    tags: [],
    publishedAt: ''
  });
  const [loading, setLoading] = useState(false);

  const { content } = watch();

  /**
   * Setting data after load
   */

  useEffect(() => {
    const { defaultData } = editArticleModal;
    reset({
      title: defaultData?.title,
      category: defaultData?.category?._id || '',
      featured: defaultData?.featured,
      status: defaultData?.status,
      content: defaultData?.content
    });

    setSelfControlData(prevState => ({
      ...prevState,
      tags: [
        ...defaultData.tags.map(tag => ({
          value: tag?._id,
          label: tag?.tag
        }))
      ],
      publishedAt: new Date(defaultData?.createdAt)
    }));
  }, [editArticleModal]);

  /**
   * Handle submitting the form
   * @param {Object} formData The event object
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  const onSubmitData = async formData => {
    const { defaultData } = editArticleModal;
    let newData = { ...formData };
    newData['tags'] = [...selfControlData.tags.map(tag => tag.value)];
    newData['publishedAt'] = selfControlData?.publishedAt;
    try {
      setLoading(true);
      let res = await LeargasAPI.AppArticles.updateAppArticle(
        defaultData?._id,
        newData,
        axiosPrivate
      );
      if (res instanceof Error) throw res;
      const { message } = res;
      toast.success(message);
      setLoading(false);

      setArticles(prevState => ({
        ...prevState,
        fetch: true,

        editArticleModal: { open: false, data: {} }
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  /**
   * Handle tags change
   * @param {Object} tagsIn tags array from input
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  const handleTagsChange = tagsIn => {
    if (tagsIn?.length > 0) {
      setSelfControlData(prevState => ({
        ...prevState,
        tags: tagsIn
      }));
    } else {
      setSelfControlData(prevState => ({
        ...prevState,
        tags: []
      }));
    }
  };

  /**
   * creating new tag
   * @param {string} param param comes as string from the multiselect input
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const createTagSelect = async param => {
    try {
      setSelfControlData(prevState => ({
        ...prevState,
        tagCreationLoader: true
      }));
      let res = await LeargasAPI.AppArticles.createAppArticleTag(
        {
          tag: param
        },
        axiosPrivate
      );
      if (res instanceof Error) throw res;
      let newTags = [...selfControlData.tags];
      let { id, tag } = res.data;
      newTags.push({
        value: id,
        label: tag
      });
      setSelfControlData(prevState => ({
        ...prevState,
        tagCreationLoader: false,
        tags: newTags
      }));
      setArticles(prevState => ({
        ...prevState,
        fetch: true
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setSelfControlData(prevState => ({
        ...prevState,
        tagCreationLoader: false
      }));
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitData)}>
      <Row>
        <Form.Group as={Col} sm={12} className='required mb-3'>
          <Form.Label>Title</Form.Label>
          <Form.Control
            placeholder={'title'}
            {...register('title')}
            type='text'
            autoComplete='off'
            className='fs--1'
            style={{ minHeight: '36px' }}
            required
          />
        </Form.Group>
      </Row>
      <Row className='g-3 mb-3'>
        <Form.Group as={Col} sm={9}>
          {hasLabel && <Form.Label className='mb-0'>Tags</Form.Label>}
          <CreatableMultiselect
            className='fs--1'
            isMulti
            isClearable
            isSearchable
            onCreateOption={createTagSelect}
            isDisabled={selfControlData.tagCreationLoader}
            value={selfControlData.tags}
            isLoading={selfControlData.tagCreationLoader}
            placeholder='Please select'
            options={tags.map(tag => ({
              value: tag._id,
              label: tag.tag
            }))}
            onChange={handleTagsChange}
            style={{ minHeight: '36px' }}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          sm={3}
          className='mb-0 d-flex  align-items-end'
          style={{ alignItems: 'end' }}>
          <Flex alignContent='center' justifyContent='center'>
            <Form.Check
              type='switch'
              {...register('featured')}
              name='featured'
            />
            <Form.Label>Featured</Form.Label>
          </Flex>
        </Form.Group>
        <Form.Group as={Col} sm={4} className='required'>
          <Form.Label>Category</Form.Label>
          <Form.Select
            name='primaryOwner'
            size='sm'
            {...register('category')}
            className='fs--1'
            required
            style={{ minHeight: '36px' }}>
            <option value=''>Please select</option>
            {categories.map((category, index) => {
              return (
                <option key={index} value={category._id}>
                  {category.title}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} sm={4}>
          <Form.Label>Status</Form.Label>
          <Form.Select
            name='primaryOwner'
            {...register('status')}
            size='sm'
            className='fs--1'
            style={{ minHeight: '36px' }}>
            <option value=''>Please select</option>
            <option value={'draft'}>Draft</option>
            <option value={'published'}>Published</option>
            <option value={'archived'}>Archived</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} sm={4}>
          <Form.Label className='fs--1'>Publish Date</Form.Label>
          <DatePickerInput
            onChange={date =>
              setSelfControlData(prevState => ({
                ...prevState,
                publishedAt: date
              }))
            }
            selfControlData={selfControlData}
          />
        </Form.Group>
      </Row>
      <div className='border border-0 border-200'>
        <Form.Group as={Col} sm={12} className='required'>
          <Form.Label className='mb-0'>Content</Form.Label>
          <TinymceEditor
            value={content}
            handleChange={newValue => setValue('content', newValue)}
          />
        </Form.Group>
      </div>

      <Flex justifyContent='end' className={'mt-3'}>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() =>
            setArticles(prevState => ({
              ...prevState,
              editArticleModal: { open: false, data: {} }
            }))
          }>
          Cancel
        </Button>

        <Button variant='success' type='submit' size='sm' className='d-flex'>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Submitting' : 'Submit'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

ArticleEditForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default ArticleEditForm;
