import React from 'react';
import ReportsWrapper from './ReportsWrapper';

const Reports = () => {
  return (
    <>
      <ReportsWrapper />
    </>
  );
};

export default Reports;
