export const getColumns = async (
  setColumns,
  columnDefaults,
  columnActions,
  setShowUpdateModal,
  setShowDeleteRouteModal,
  setShowRestoreRouteModal
) => {
  setColumns([
    ...columnDefaults(setShowRestoreRouteModal),
    ...columnActions(
      setShowUpdateModal,
      setShowDeleteRouteModal,
      setShowRestoreRouteModal
    )
  ]);
};
