import classNames from 'classnames';
import CardDropdown from 'components/common/CardDropdown';
import React, { useState } from 'react';
// import cloudDownload from 'assets/img/icons/cloud-download.svg';
// import editAlt from 'assets/img/icons/edit-alt.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconLink from 'components/common/FalconLink';
import Flex from 'components/common/Flex';
import SimpleSpinner from 'components/utilities/AppSpinner/smallSpinner';
import { OverlayTriggerPopover } from 'components/visualizations/OverlayTriggerPopOver';
import useAuthentication from 'hooks/useAuthentication';
import PropTypes from 'prop-types';
import { Card, Container, Dropdown, Modal } from 'react-bootstrap';
import VisualizationsQueryModal from '../InspectorModal';

/**
 * Renders a list of alerts
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.className - The CSS class name for the component
 * @param {string} props.fieldName - The name of the field to display in the alerts
 * @param {Object} props.flatAggregations - The flat aggregations data for the alerts
 * @param {string} props.helpInfo - The help information for the component
 * @param {string} [props.linkLabel='View All'] - The label for the link to view all alerts
 * @param {string|boolean} [props.linkTo=false] - The link to navigate when the link label is clicked
 * @param {string} props.title - The title of the component
 * @param {boolean} props.wrapperLoader - The flag indicating whether to show a loader for the component
 * @returns {JSX.Element} The rendered ListAlerts component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const ListAlerts = ({
  className,
  fieldName,
  flatAggregations,
  helpInfo,
  linkLabel = 'View All',
  linkTo = false,
  title,
  wrapperLoader
  // hideFilterMenu = false
}) => {
  const {
    authentication: { isAdmin }
  } = useAuthentication();

  const [queryDiscoverModal, setQueryDiscover] = useState(false);

  return (
    <>
      <Card
        className={classNames(
          'h-100 shadow-none border overflow-hidden',
          className
        )}>
        <Container fluid className='pb-3 h-100'>
          <Flex justifyContent='between' direction='column' className='h-100'>
            <Card.Body className='pb-0 ps-0 pe-0 pt-2'>
              {title && (
                <h6 className='fs--1 mb-3 text-nowrap text-700 fw-light position-relative'>
                  <Flex>
                    {title}
                    {helpInfo && !wrapperLoader && (
                      <OverlayTriggerPopover PopoverText={helpInfo}>
                        <span>
                          <FontAwesomeIcon
                            icon={['far', 'question-circle']}
                            transform='shrink-1'
                            className='ms-1 text-400 cursor-pointer'
                          />
                        </span>
                      </OverlayTriggerPopover>
                    )}
                  </Flex>
                </h6>
              )}
              {!wrapperLoader && isAdmin && (
                <Flex
                  justifyContent='center'
                  alignContent='middle'
                  className={'position-absolute top-0 end-0 cursor-pointer'}>
                  <CardDropdown style={{ zIndex: 9999, position: 'absolute' }}>
                    <div className='py-2'>
                      <Dropdown.Item
                        onClick={() => {
                          setQueryDiscover(true);
                        }}>
                        Inspect
                      </Dropdown.Item>
                    </div>
                  </CardDropdown>
                </Flex>
              )}
              {wrapperLoader && (
                <span
                  className='fw-light position-absolute top-0 end-0 z-index-2'
                  style={{ marginRight: '15px', marginTop: '15px' }}>
                  <SimpleSpinner size='sm' />
                </span>
              )}

              <div className='position-relative h-100'>
                {flatAggregations?.data
                  ? flatAggregations?.data?.map((alert, index) => (
                      <div
                        key={index}
                        className='mb-1 hover-actions-trigger fs--2 w-100 position-relative'>
                        <Flex
                          direction='row'
                          alignItems='center'
                          justifyContent='between'>
                          <Flex
                            style={{ width: '62%' }}
                            alignItems='center'
                            className='py-1 fw-normal'>
                            <FontAwesomeIcon
                              icon={['fas', 'exclamation-triangle']}
                              className='ms-0 fs--1 text-pastel-cyan-complementary opacity-75'
                            />
                            <h6
                              className='text-700 text-start mb-0 fs--2 d-flex ps-0'
                              style={{ width: '100%' }}>
                              <span className='text-truncate fw-normal py-1 ms-1'>
                                {alert[fieldName]?.toUpperCase()}
                              </span>
                            </h6>
                          </Flex>
                          <Flex>
                            <h6 className='mb-1 fs--2 fw-normal text-700'>
                              <span>
                                <span className='fw-bold'>
                                  {alert.doc_count.toLocaleString()}
                                </span>
                                {/* Percentage of sum across all buckets */}
                                <span className='text-500'>{` (${(
                                  (alert.doc_count /
                                    Object.values(flatAggregations?.data)
                                      .map(entry => entry.doc_count)
                                      .reduce((sum, entry) => sum + entry, 0)) *
                                  100
                                ).toFixed(2)}%)`}</span>
                              </span>
                            </h6>
                          </Flex>
                        </Flex>
                        {index !== flatAggregations?.data.length - 1 && (
                          <hr className='border-200 m-0 mb-1 p-0' />
                        )}
                      </div>
                    ))
                  : null}
                {linkTo && (
                  <Flex justifyContent='end' className='me-2'>
                    <FalconLink
                      title={linkLabel}
                      className='px-0 fw-medium'
                      to={linkTo}
                    />
                  </Flex>
                )}
                <div
                  className={`position-absolute all-0 align-items-center justify-content-around bg-card-nodata fs--1  ${
                    !wrapperLoader &&
                    (!flatAggregations?.data ||
                      flatAggregations?.data.length === 0)
                      ? 'd-flex'
                      : 'd-none'
                  }`}>
                  No results matching search criteria
                </div>
                <div
                  className={`position-absolute all-0 align-items-center justify-content-around bg-card-spinner ${
                    wrapperLoader ? 'd-flex' : 'd-none'
                  } ${wrapperLoader && 'bg-card-spinner-refresh'}`}></div>
              </div>
            </Card.Body>
          </Flex>
        </Container>
      </Card>
      <Modal
        centered
        size='lg'
        animation={false}
        show={queryDiscoverModal}
        onHide={() => setQueryDiscover(false)}
        aria-labelledby='modalQueryInspector'>
        <VisualizationsQueryModal allOperations={[flatAggregations]} />
      </Modal>
    </>
  );
};

ListAlerts.propTypes = {
  className: PropTypes.string,
  fieldName: PropTypes.string,
  flatAggregations: PropTypes.object,
  helpInfo: PropTypes.string,
  linkLabel: PropTypes.string,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.string,
  wrapperLoader: PropTypes.bool,
  hideFilterMenu: PropTypes.bool
};

export default ListAlerts;
