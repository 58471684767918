import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import useAdminReports from 'hooks/admin-contexts/useReports';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Modal } from 'react-bootstrap';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Preview from '../FileActions/Preview';
/**
 * Renders the ModalReportAdd component
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ModalReportAdd = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    reports: { viewReportModal },
    setReports
  } = useAdminReports();
  return (
    <Modal
      centered
      size={viewReportModal?.fullScreen ? 'xl' : 'lg'}
      animation={false}
      backdrop='static'
      keyboard={false}
      show={viewReportModal?.open}
      onHide={() =>
        setReports(prevState => ({
          ...prevState,
          viewReportModal: { open: false }
        }))
      }
      aria-labelledby='modalReportAdd'
      contentClassName={
        viewReportModal?.fullScreen
          ? 'border-0 border-top-left-radius-0 border-top-right-radius-0 h-100vh'
          : '  h-100vh'
      }
      dialogClassName={
        viewReportModal?.fullScreen
          ? 'fullscreen-modal-dialog check'
          : 'mt-0 mb-0 h-100vh'
      }
      style={{ height: '100vh' }} // Set modal height to fill the screen
    >
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Report Preview
            <AdvancedPopover
              containerId='resetConfirmModal'
              placement='top'
              popoverText='Report Preview'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
          <div
            className='d-flex justify-content-end position-absolute right-0 '
            style={{ right: '3rem' }}>
            <FontAwesomeIcon
              icon={
                viewReportModal?.fullScreen
                  ? 'down-left-and-up-right-to-center'
                  : 'up-right-and-down-left-from-center'
              }
              className='me-2 cursor-pointer'
              style={{
                color: isDark ? 'white' : 'black',
                opacity: 0.8
              }}
              onClick={() => {
                setReports(prevState => ({
                  ...prevState,
                  viewReportModal: {
                    open: true,
                    data: viewReportModal?.data,
                    fullScreen: !viewReportModal?.fullScreen
                  }
                }));
              }}
            />
          </div>
        </Modal.Header>

        <Modal.Body
          className='p-2'
          style={{ maxHeight: 'calc(100vh )', overflowY: 'auto' }}>
          {' '}
          {/* Make modal body scrollable */}
          <div className='simplebar' style={{ maxWidth: '100%' }}>
            {viewReportModal?.open && (
              <Preview id={viewReportModal?.data?.id} />
            )}
          </div>
        </Modal.Body>
      </Flex>
    </Modal>
  );
};

export default ModalReportAdd;
