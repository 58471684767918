import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import UserProfileBanner from 'components/common/profile-cards/UserProfileCard/Profile';
import { Button, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import Moment from 'react-moment';
const { default: CardDropdown } = require('components/common/CardDropdown');

/**
 * columns for table
 * @param {Function} setRoles
 * @returns {Array}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const getRoleColumns = setRoles => {
  return [
    {
      accessor: 'name',
      Header: 'Role',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap'
      },
      Cell: rowData => {
        const { name } = rowData.row.original;

        return (
          <>
            <Flex direction='row' alignItems='center'>
              {name}
            </Flex>
          </>
        );
      }
    },
    {
      accessor: 'description',
      Header: 'Description',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap'
      },
      Cell: rowData => {
        const { description } = rowData.row.original;

        return (
          <>
            <Flex direction='row' alignItems='center'>
              {description || '-'}
            </Flex>
          </>
        );
      }
    },
    {
      accessor: 'createdBy',
      Header: 'Created By',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-center align-middle text-nowrap'
      },
      Cell: rowData => {
        let { createdBy } = rowData.row.original;
        return (
          <>
            <Flex direction='row' justifyContent='center' alignItems='center'>
              {createdBy ? (
                <OverlayTrigger
                  placement='top'
                  trigger={['click']}
                  rootClose={true}
                  delay={{ show: 0 }}
                  popperConfig={{
                    modifiers: [
                      {
                        name: 'offset',
                        enabled: true,
                        options: {
                          offset: [120, 8]
                        }
                      }
                    ]
                  }}
                  overlay={
                    <Popover
                      style={{
                        minWidth: '320px',
                        margin: 0
                      }}
                      id={`popover-basic-${createdBy?._id}`}>
                      <Popover.Body className='p-0'>
                        <UserProfileBanner
                          user={createdBy}
                          mainProfile={false}
                        />
                      </Popover.Body>
                    </Popover>
                  }>
                  {({ ref, ...triggerHandler }) => (
                    <div
                      {...triggerHandler}
                      className='d-inline-flex align-items-center cursor-pointer'>
                      <Button
                        ref={ref}
                        className={`avatar-popover d-inline-flex align-items-center p-0 me-2 bg-transparent border-0 shadow-none`}
                        style={{ whiteSpace: 'nowrap' }}>
                        {' '}
                        <Avatar
                          size='s'
                          src={
                            createdBy?.profile?.media?.avatar ||
                            process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG
                          }
                        />
                      </Button>
                    </div>
                  )}
                </OverlayTrigger>
              ) : (
                <span className='text-300'>—</span>
              )}
            </Flex>
          </>
        );
      }
    },
    {
      accessor: 'createdAt',
      Header: 'Created',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap'
      },
      Cell: rowData => {
        const { createdAt } = rowData.row.original;

        return (
          <>
            <Flex direction='row' alignItems='center'>
              <Moment date={createdAt} fromNow />
            </Flex>
          </>
        );
      }
    },
    {
      accessor: 'updatedAt',
      Header: 'Updated',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap'
      },
      Cell: rowData => {
        const { updatedAt } = rowData.row.original;

        return (
          <>
            <Flex direction='row' alignItems='center'>
              <Moment date={updatedAt} fromNow />
            </Flex>
          </>
        );
      }
    },
    {
      accessor: 'leargas_actions',
      Header: '',
      Cell: rowData => {
        let { isDeleted } = rowData.row.original;
        return (
          <Flex
            justifyContent='end'
            alignContent='middle'
            className='table-dropdown-columns'>
            <CardDropdown>
              <div className='py-2'>
                <Dropdown.Item
                  onClick={() =>
                    setRoles(prevState => ({
                      ...prevState,
                      editRoleModal: {
                        open: true,
                        defaultData: rowData.row.original
                      }
                    }))
                  }>
                  <FontAwesomeIcon icon='pencil' className='me-2' />
                  Edit Details
                </Dropdown.Item>
                <div className='dropdown-divider'></div>
                <Dropdown.Item
                  className={`${!isDeleted ? 'text-danger' : 'text-warning'}`}
                  onClick={() => {
                    isDeleted
                      ? setRoles(prevState => ({
                          ...prevState,
                          restoreRoleModal: {
                            open: true,
                            defaultData: rowData.row.original
                          }
                        }))
                      : setRoles(prevState => ({
                          ...prevState,
                          deleteRoleModal: {
                            open: true,
                            defaultData: rowData.row.original
                          }
                        }));
                  }}>
                  <FontAwesomeIcon
                    icon={isDeleted ? 'trash-restore-alt' : 'trash'}
                    className='me-2'
                  />
                  {isDeleted ? 'Restore' : 'Delete'}
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </Flex>
        );
      },
      cellProps: {
        className: 'text-end text-400 actions-cell',
        style: {
          width: '42px'
        }
      },
      hideDisplaySettings: true,
      disableSortBy: true
    }
    // {
    //   id: 'actions',
    //   Header: '',
    //   noTruncate: true,
    //   hasRender: true,
    //   canSort: false,
    //   defaultCanSort: false,
    //   disableSort: true,
    //   cellProps: {
    //     className: 'pe-0 align-middle text-end'
    //   },
    //   headerProps: {
    //     className: 'pe-0 fs--1',
    //     style: { width: '10%' }
    //   },
    //   Cell: rowData => {
    //     return (
    //       <Flex justifyContent='end' direction='row' alignContent='center'>
    //         <Button
    //           className='p-0 me-2'
    //           variant='link'
    //           onClick={() =>
    //             setRoles(prevState => ({
    //               ...prevState,
    //               editRoleModal: { open: true, data: rowData.row.original }
    //             }))
    //           }>
    //           <FontAwesomeIcon icon={['fas', 'edit']} className='' />
    //         </Button>
    //         <Button
    //           className='p-0'
    //           variant='link'
    //           onClick={() =>
    //             setRoles(prevState => ({
    //               ...prevState,
    //               deleteRoleModal: { open: true, data: rowData.row.original }
    //             }))
    //           }>
    //           <FontAwesomeIcon icon={['fas', 'trash-alt']} />
    //         </Button>
    //       </Flex>
    //     );
    //   }
    // }
  ];
};
