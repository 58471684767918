import React from 'react';

/**
 * Statuses which are used in layout section of route
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Statuses = () => {
  return <></>;
};

export default Statuses;
