import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import { updateFilterOperator } from 'components/dashboards/Artifacts/Dynamic/dataFetchUtils/RefinedUtils';
import { useSearchFilters } from 'context/FiltersProvider';
import { filtersOperators } from 'context/FiltersProvider/settings';
import { isPublicIP } from 'helpers/ip.utils';
import useApplication from 'hooks/useApplication';
import useExplore from 'hooks/useExplore';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownMenu,
  Overlay,
  Popover
} from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import SimpleBar from 'simplebar-react';

// Assuming fetchGptData is exported from useExplore
const displayValue = (name, isPrivate, isValidValue) => {
  if (isPrivate) {
    return '******';
  }
  if (typeof name === 'number') {
    return name.toLocaleString();
  }
  if (typeof name === 'boolean') {
    return name ? 'true' : 'false';
  }
  if (Array.isArray(name) && isValidValue) {
    return name.join(', ');
  }
  if (Array.isArray(name) && !isValidValue) {
    return JSON.stringify(name);
  }
  if (typeof name === 'object' && name !== null) {
    return JSON.stringify(name, null, 2); // Pretty print the object
  }
  switch (name) {
    case '__missing__':
      return 'Missing';
    case null:
      return '-';
    default:
      return name;
  }
};

/**
 * Renders the search value and encapsulating context menu for a search value.
 *
 * Includes a dropdown menu with options to copy the value, key, or key/value pair to the clipboard.
 * As well as options to apply quick filters based on the value.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Object} props.searchKey - The key
 * @param {Object} props.searchValue - The value
 * @param {Object} props.valueType - The type of the value
 * @param {boolean} props.showResources - Whether to show resources options
 * @param {boolean} props.showFilter - Whether to show filter options
 * @param {boolean} props.showQuickFilter - Whether to show quick filter options
 * @param {boolean} props.showClipboard - Whether to show clipboard options
 * @param {Function} props.onSelection - Callback function to call on selection
 * @param {string} props.popoverText - The text to display in the popover
 * @returns {JSX.Element} The rendered SearchValueContextMenu component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const SearchValueContextMenu = ({
  onSelection,
  searchKey,
  searchValue = '',
  showClipboard = true,
  showFilter = false,
  showQuickFilter = true,
  valueType,
  popoverText = 'Click to view available options for this field value.'
}) => {
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);
  const {
    state: { fields },
    setState,
    fetchGptData,
    fetchAuroraData,
    makeRequest
  } = useExplore();

  const {
    application: { isPrivacyMode }
  } = useApplication();

  const {
    searchFilters: { filters },
    setSearchFilters,
    applyFilter
  } = useSearchFilters();

  const [show, setShow] = useState(false);
  const [isPrivate, setIsprivate] = useState(false);
  const [isValidValue, setIsValidValue] = useState(false);
  const [isValidAuroraIndicator, setIsValidAuroraIndicator] = useState(false);

  /**
   * Toggles the value of `show`.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleToggle = () => {
    setShow(!show);
  };

  /**
   * Handles the click outside event to close the dropdown menu.
   *
   * @param {Event} e - The click event object
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleClickOutside = e => {
    if (
      triggerRef.current &&
      !triggerRef.current.contains(e.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      // If the click is outside of the trigger and the dropdown, close the dropdown
      setShow(false);
    }
  };

  /**
   * Applies a filter based on the provided operator key.
   *
   * @param {string} operatorKey - The key of the operator to apply
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleApplyFilter = operatorKey => {
    // Close the dropdown
    setShow(false);

    // If a selection callback is provided, call it
    if (onSelection) {
      onSelection();
    }

    // Apply the filter
    const field = fields.find(field => field.id === searchKey);
    let { updatedFilters, isModified } = updateFilterOperator(
      _.cloneDeep(filters),
      searchKey,
      searchValue,
      operatorKey
    );

    if (isModified) {
      setSearchFilters(prev => ({
        ...prev,
        filters: [...updatedFilters]
      }));
    } else {
      applyFilter({
        field: field,
        operator: operatorKey,
        value: searchValue || '' // Default to empty string if value or default value is undefined
      });
    }
  };

  /**
   * Handles fetching GPT data and populates the modal with these data.
   */
  const handleFetchGptData = async () => {
    // Close the dropdown
    setShow(false);

    setState('gptDataModalShow', true);
    setState('gptDataLoading', true);
    setState('gptDataSelectedFieldId', searchKey);
    setState('gptDataSelectedValue', searchValue);

    const field = fields.find(field => field.id === searchKey);

    if (field) {
      try {
        // Construct the prompt as needed
        const prompt = `${field?.gpt?.promptBeforeTerm} ${searchValue} ${field?.gpt?.promptAfterTerm}`;

        // Call the fetch function
        const gptResponse = await fetchGptData(
          field._id,
          searchValue,
          prompt.trim(),
          makeRequest
        );

        // Set the fetched data to state
        setState('gptDataResponse', gptResponse);
        setState('gptDataError', null);
      } catch (error) {
        console.error('Failed to fetch GPT data:', error);
        setState('gptDataResponse', '');
        setState('gptDataError', error.message || 'Unable to fetch data.');
      } finally {
        setState('gptDataLoading', false);
      }
    } else {
      setState('gptDataError', 'Field not found.');
      setState('gptDataLoading', false);
    }
  };

  /**
   * Handles fetching Aurora data and populates the modal with these data.
   */
  const handleFetchAuroraData = async () => {
    // Close the dropdown aurora
    setShow(false);

    setState('auroraDataLoading', true);
    setState('auroraDataSelectedFieldId', searchKey);
    setState('auroraDataSelectedValue', searchValue);
    setState('auroraDataModalShow', true);

    const field = fields.find(field => field.id === searchKey);

    if (field) {
      try {
        // Construct the prompt as needed
        // Call the fetch function
        const auroraResponse = await fetchAuroraData(searchValue, makeRequest);

        // Set the fetched data to state
        setState('auroraDataResponse', auroraResponse);
        setState('auroraDataError', null);
      } catch (error) {
        console.error('Failed to fetch Aurora data:', error);
        setState('auroraDataResponse', '');
        setState('auroraDataError', error.message || 'Unable to fetch data.');
      } finally {
        setState('auroraDataLoading', false);
      }
    } else {
      setState('auroraDataError', 'Field not found.');
      setState('auroraDataLoading', false);
    }
  };

  /**
   * Adds an event listener to handle clicks outside of the dropdown menu.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    // Add the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Remove the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /**
   * set the privacy value isPrivacyMode is on and searchKey is sensitive in fields from explore
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    // find a isPrivate value by first checking the searchkey's property sensitive in fields array and with then isPrivacyMode
    const isPrivate =
      fields.find(f => f.id === searchKey)?.sensitive && isPrivacyMode;
    setIsprivate(isPrivate);
  }, [searchKey, isPrivacyMode]);

  /**
   * finding the value is not array of objects as array objects cannot be added to filter
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    if (Array.isArray(searchValue)) {
      let validValue = searchValue?.every(item => typeof item === 'object');
      setIsValidValue(!validValue);
    } else {
      setIsValidValue(true);
    }
  }, [searchValue]);

  useEffect(() => {
    setIsValidAuroraIndicator(isPublicIP(searchValue));
  }, [searchValue]);

  const popover = (
    <Popover
      id='searchFiltersBulkActionsPopover'
      style={{
        width: 'fit-content',
        minWidth: 'fit-content',
        padding: 0,
        margin: 0,
        marginTop: -10
      }}>
      <div ref={dropdownRef}>
        <Flex
          justifyContent='start'
          alignItems='start'
          direction={'column'}
          className='p-0'>
          <DropdownMenu
            show={true}
            className='position-relative border-0 shadow-lg'
            style={{
              minWidth: 'fit-content',
              width: 'fit-content'
            }}>
            <SimpleBar
              className='h-100'
              forceVisible={true}
              autoHide={false}
              style={{
                width: 'fit-content',
                maxHeight: '50vh',
                minHeight: '115px'
              }}>
              <>
                {/* Resources */}
                {fields.find(f => f.name === searchKey)?.gpt?.enabled &&
                  isValidValue && (
                    <>
                      <Dropdown.Header>Resources</Dropdown.Header>
                      <div className='mb-2'>
                        <Dropdown.Item onClick={handleFetchGptData}>
                          <span className='pe-3'>
                            <FontAwesomeIcon
                              icon={['fab', 'readme']}
                              width={16}
                              height={16}
                              className='me-2'
                            />
                            What is this?
                          </span>
                        </Dropdown.Item>
                      </div>
                    </>
                  )}
                {isValidAuroraIndicator && (
                  <>
                    <Dropdown.Header>Resources</Dropdown.Header>
                    <div className='mb-2'>
                      <Dropdown.Item onClick={handleFetchAuroraData}>
                        <span className='pe-3'>
                          <FontAwesomeIcon
                            icon={['fas', 'crosshairs']}
                            width={16}
                            height={16}
                            className='me-2'
                          />
                          Threat Intel
                        </span>
                      </Dropdown.Item>
                    </div>
                  </>
                )}

                {/* Add a filter */}
                {showFilter && isValidValue && (
                  <>
                    <Dropdown.Header>Filter</Dropdown.Header>
                    <div className='mb-2'>
                      <Dropdown.Item
                        onClick={() => handleApplyFilter('eq')}
                        className='cursor-pointer'>
                        <span className='pe-3'>
                          <FontAwesomeIcon
                            icon={['fas', 'filter']}
                            width={16}
                            height={16}
                            transform={{ rotate: 22 }}
                            className='me-2'
                          />
                          Add a filter
                        </span>
                      </Dropdown.Item>
                    </div>
                  </>
                )}

                {/* Quick filters */}
                {showQuickFilter && isValidValue && (
                  <>
                    <Dropdown.Header>Quick filter</Dropdown.Header>
                    <div className='mb-2'>
                      {Object.entries(filtersOperators).map(
                        ([operatorKey, operator], index) => {
                          return operator.isCompatibleWithType(
                            valueType,
                            searchValue
                          ) && operator.showInMenus === true ? (
                            <Dropdown.Item
                              key={index}
                              onClick={() => handleApplyFilter(operatorKey)}>
                              <span className='pe-3'>
                                {operator.icon && (
                                  <FontAwesomeIcon
                                    icon={operator?.icon}
                                    width={16}
                                    height={16}
                                    className='me-2'
                                  />
                                )}
                                Value {operator.label}
                              </span>
                            </Dropdown.Item>
                          ) : null;
                        }
                      )}
                    </div>
                  </>
                )}

                {/* Clipboard */}
                {showClipboard && (
                  <>
                    <Dropdown.Header>Clipboard</Dropdown.Header>
                    <div className='mb-2' style={{ width: '150px' }}>
                      <Dropdown.Item
                        as={CopyToClipboard}
                        text={
                          isPrivate
                            ? '******'
                            : isValidValue
                            ? searchValue
                            : JSON.stringify(searchValue)
                        }
                        onCopy={() => {
                          setShow(false);
                          toast.success('Copied value to clipboard!');
                        }}
                        className='cursor-pointer'>
                        <span className='pe-3'>Copy value</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={CopyToClipboard}
                        text={isPrivate ? '******' : searchKey}
                        onCopy={() => {
                          setShow(false);
                          toast.success('Copied key to clipboard!');
                        }}
                        className='cursor-pointer'>
                        <span className='pe-3'>Copy key</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={CopyToClipboard}
                        text={
                          isPrivate
                            ? '******'
                            : `${searchKey}: ${
                                isValidValue
                                  ? searchValue
                                  : JSON.stringify(searchValue)
                              }`
                        }
                        onCopy={() => {
                          setShow(false);
                          toast.success('Copied key/value pair to clipboard!');
                        }}
                        className='cursor-pointer'>
                        <span className='pe-3'>Copy key/value pair</span>
                      </Dropdown.Item>
                    </div>
                  </>
                )}
              </>
            </SimpleBar>
          </DropdownMenu>
        </Flex>
      </div>
    </Popover>
  );

  return (
    <Flex className='position-relative'>
      <Overlay
        show={show}
        target={triggerRef.current}
        placement='right-start'
        offset={[5, 15]}
        flip={true}
        rootClose={true}
        onHide={() => setShow(false)}>
        {popover}
      </Overlay>

      <AdvancedPopover
        placement='top'
        popoverText={popoverText}
        showArrow={true}>
        <Button
          ref={triggerRef}
          id='exploreFieldItemPopoverTrigger'
          onClick={handleToggle}
          className='p-0 text-decoration-none fs--2 text-700 hover-900 text-start fw-normal'
          variant='link'
          style={{ whiteSpace: 'pre-line', overflowWrap: 'anywhere' }}>
          {displayValue(searchValue, isPrivate, isValidValue)}
          {isPublicIP(searchValue) && (
            <FontAwesomeIcon
              icon={['fas', 'crosshairs']}
              className='ms-1 text-alert'
              transform='down-1'
            />
          )}
        </Button>
      </AdvancedPopover>
    </Flex>
  );
};

SearchValueContextMenu.propTypes = {
  onSelection: PropTypes.func,
  searchKey: PropTypes.string.isRequired,
  searchValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
    PropTypes.bool
  ]),
  showClipboard: PropTypes.bool,
  showFilter: PropTypes.bool,
  showQuickFilter: PropTypes.bool,
  showResources: PropTypes.bool,
  valueType: PropTypes.string,
  popoverText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default SearchValueContextMenu;
