import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FalconCloseButton from 'components/common/FalconCloseButton';
import AdvancedPopover from 'components/common/Popover';
import { fieldTypeConfig } from 'helpers/field.utils';
import { truncateMiddle } from 'helpers/utils';
import useExplore from 'hooks/useExplore';
import React, { useEffect, useRef, useState } from 'react';
import { FormControl, ListGroup, ListGroupItem } from 'react-bootstrap';
import FieldItem from './FieldItem';
import PresenceFilter from './PresenceFilter';
import TypeFilter from './TypeFilter';

/**
 * Renders a list of available fields.
 *
 * @returns {JSX.Element} The rendered component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const AvailableFieldsList = () => {
  const sentinelRef = useRef(null);

  const {
    state: {
      fieldsPresent,
      fieldsPresentOnly = true,
      fields = [],
      fieldsSelected,
      fieldTypesHidden,
      loading
    },
    setState
  } = useExplore();

  const [loadedItems, setLoadedItems] = useState(100);
  const [fieldSearch, setFieldSearch] = useState('');
  const [filteredFields, setFilteredFields] = useState([]);

  useEffect(() => {
    const filteredBySearch = fields.filter(field =>
      field.name.toLowerCase().includes(fieldSearch.toLowerCase())
    );
    const filteredByType = fields.filter(
      field => !fieldTypesHidden.includes(field.type)
    );
    const filteredBySelected = fields.filter(
      field => !fieldsSelected.includes(field)
    );
    const filteredByPresence = fields.filter(
      field => !fieldsPresentOnly || fieldsPresent.includes(field.name)
    );

    // Set the filtered fields based on the search, type, and selected fields
    setFilteredFields(
      fields
        .filter(field => filteredBySearch.includes(field))
        .filter(field => filteredByType.includes(field))
        .filter(field => filteredBySelected.includes(field))
        .filter(field => filteredByPresence.includes(field))
    );
  }, [
    fieldsPresent,
    fieldSearch,
    fieldTypesHidden,
    fieldsSelected,
    fieldsPresentOnly
  ]);

  /**
   * Adds a field to the selected fields list in the Explore data.
   *
   * @param {any} fieldToAdd - The field to add to the selected fields list
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleAddField = fieldToAdd => {
    setState('fieldsSelected', [...fieldsSelected, fieldToAdd]);
  };

  /**
   * Adds an IntersectionObserver to the sentinelRef element to load more items when it is in view
   * and disconnects the observer when the component is unmounted.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        // If the sentinel element is in view, load more items
        if (entries[0].isIntersecting) {
          setLoadedItems(prevItems => prevItems + 100);
        }
      },
      {
        root: null, // Null for viewport
        rootMargin: '0px', // No margin around the viewport
        threshold: 1.0 // 100% of sentinel element in view
      }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    // Clean up the observer for component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className='p-1 border-1 border-card rounded-lg mb-2'>
      <h4 className='d-flex fs-0 fw-normal text-700 mt-1 mb-1 align-items-center'>
        Available Fields{' '}
        <AdvancedPopover
          placement='top'
          popoverText='Available fields can be added as columns to the table. Each index has its own set of fields. However, some fields may be common across multiple indices.'
          showArrow={true}>
          <FontAwesomeIcon
            icon={['far', 'question-circle']}
            className='ms-1 text-400 fs--1 cursor-pointer'
          />
        </AdvancedPopover>
      </h4>
      <div className='position-relative'>
        <FormControl
          size='sm'
          type='text'
          disabled={loading}
          placeholder={`Search ${
            Number(filteredFields.length).toLocaleString() || 0
          } fields...`}
          value={fieldSearch}
          onChange={e => setFieldSearch(e.target.value)}
          className='form-control-sm fs--2 mb-2 p-1 px-2'
        />
        {fieldSearch && (
          <div className='position-absolute top-0 end-0'>
            <FalconCloseButton
              size='sm'
              className='fs--2 me-1'
              noOutline
              onClick={() => setFieldSearch('')}
            />
          </div>
        )}
      </div>
      <PresenceFilter />
      <TypeFilter fields={filteredFields} />
      <ListGroup variant='flush'>
        {filteredFields
          .filter(field => fieldsSelected.every(f => f?.id !== field.id))
          .slice(0, loadedItems)
          .map((field, index) => (
            <FieldItem
              key={field.id}
              field={field}
              handleFieldAction={handleAddField}
              actionIcon={['fas', 'add']}
              popoverText={
                <div className='text-700 overflow-hidden'>
                  <span>Add the</span>
                  <code
                    className={classNames('mx-1', {
                      [`text-${fieldTypeConfig[field.type].color}`]:
                        fieldTypeConfig[field.type].color
                    })}>
                    {fieldTypeConfig[field.type].label || field.type}
                  </code>
                  <span>field</span>
                  <code className='text-nowrap text-dark d-block'>
                    {truncateMiddle(field.name)}
                  </code>
                  <span>
                    as a selected field, which will be displayed in the table as
                    a column.
                  </span>
                </div>
              }
              index={index}
              totalFields={fields.length}
            />
          ))}
        <ListGroupItem
          ref={sentinelRef}
          className='p-0 bg-transparent'
          style={{ height: '1px' }}></ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default AvailableFieldsList;
