import leargasClover from 'assets/img/leargas-clover.png';
import Flex from 'components/common/Flex';
import HelpModal from 'components/common/HelpModal';
import KeyboardShortcutsModal from 'components/common/KeyboardShortcutsModal';
import BookmarksPanel from 'components/modals/BookmarksPanel';
import IdleSessionTimeoutModal from 'components/modals/IdleSessionTimeoutModal';
import SettingsPanel from 'components/modals/settings-panel/SettingsPanel';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import useApplication from 'hooks/useApplication';
import useAuthentication from 'hooks/useAuthentication';
import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import CookieConsent from 'react-cookie-consent';
import { Outlet, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

/**
 * System Layout Component.
 *
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const MainLayout = () => {
  const { hash } = useLocation();

  const {
    application: { isFluid, navbarPosition }
  } = useApplication();

  const {
    authentication: { roles },
    setAuthentication
  } = useAuthentication();

  useEffect(() => {
    if (!roles) return;
    const hasMatchingRole = roles.some(role =>
      ['admin', 'super-admin'].includes(role.slug)
    );
    setAuthentication(prevState => ({
      ...prevState,
      isAdmin: hasMatchingRole
    }));
  }, [roles]);

  useEffect(() => {
    var timeout = setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      <Outlet />
      <KeyboardShortcutsModal />
      <HelpModal />
      <SettingsPanel />
      <BookmarksPanel />
      <IdleSessionTimeoutModal />
      <ToastContainer
        icon={true}
        autoClose={2000}
        theme={'colored'}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        maxToasts={1}
        draggable={true}
        pauseOnHover={true}
        position={toast.POSITION.BOTTOM_CENTER}
      />
      <CookieConsent
        location='bottom'
        buttonText='I understand'
        cookieName='consent'
        style={{ background: 'transparent', padding: 0, zIndex: '999999' }}
        containerClasses='bg-dark'
        contentStyle={{ margin: 0 }}
        buttonWrapperClasses='p-4 m-0'
        buttonClasses='btn btn-sm bg-warning m-0'
        expires={365}>
        <Flex alignItems='center' className='bg-dark p-4'>
          <span>
            <Image
              src={leargasClover}
              width='50'
              height='50'
              className='me-2'
            />
          </span>
          <span className='fs--1 text-100'>
            <strong>
              Léargas uses cookies on this website to customize and enhance your
              experience.
            </strong>
            <br />
            By continuing, you agree to the use of these cookies. For more
            information about how we handle and protect your personal data,
            please read our &nbsp;
            <a
              href='https://www.leargassecurity.com/privacy-leargas-security/'
              rel='noreferrer'
              target='_blank'>
              Privacy Policy
            </a>
            .
          </span>
        </Flex>
      </CookieConsent>
    </div>
  );
};

export default MainLayout;
