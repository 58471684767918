import classNames from 'classnames';
import Flex from 'components/common/Flex';
import useUsers from 'hooks/admin-contexts/useUsers';
import useApplication from 'hooks/useApplication';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import CreateUserForm from '../forms/UsersAddForm';

/**
 * ModalUserAdd component renders a modal for adding a new user.
 *
 * It utilizes the `useApplication` and `useUsers` hooks to manage application state and user state respectively.
 * The modal can be toggled between full-screen and regular size.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.6
 * @since 0.1.0-beta.2
 */
const ModalUserAdd = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    users: { showCreateUserModal },
    setUsers
  } = useUsers();

  useEffect(() => {
    return () => {
      setUsers(prevState => ({
        ...prevState,
        loading: false
      }));
    };
  }, []);

  return (
    <Modal
      size={showCreateUserModal?.fullScreen ? 'xl' : 'lg'}
      backdrop='static'
      keyboard={false}
      show={showCreateUserModal?.open}
      onHide={() =>
        setUsers(prevState => ({
          ...prevState,
          showCreateUserModal: { open: false }
        }))
      }
      aria-labelledby='modalUserAdd'
      contentClassName={classNames({
        'border-0 border-top-left-radius-0 border-top-right-radius-0 h-100':
          showCreateUserModal?.fullScreen
      })}
      dialogClassName={classNames('pt-5', {
        'position-absolute all-0 fullscreen-modal-dialog scrollbar overflow-x-hidden':
          showCreateUserModal?.fullScreen
      })}>
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Add a User
          </h5>
        </Modal.Header>
        <Modal.Body className='p-2'>
          <CreateUserForm />
        </Modal.Body>
      </Flex>
    </Modal>
  );
};

export default ModalUserAdd;
