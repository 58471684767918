import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import useApplication from 'hooks/useApplication';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const Report = ({ report, icon, isLast, setReports }) => {
  const {
    application: { isPrivacyMode }
  } = useApplication();

  const reportObjects = {
    'daily-signature': {
      title: 'Signature Threat Report'
    },
    'daily-behavioral': {
      title: 'Behavioral Threat Report'
    },
    'daily-o365': {
      title: 'Office365 Threat Report'
    },
    'daily-azure': {
      title: 'Azure Threat Report'
    },
    'daily-google': {
      title: 'Google Threat Report'
    },
    'daily-windows': {
      title: 'Windows Threat Report'
    }
  };

  return (
    <Row
      className={classNames('g-2 mb-1   recent-activity-timeline-primary', {
        'mb-0': isLast,
        'recent-activity-timeline-past': status === 'completed',
        'recent-activity-timeline-current': status === 'current'
      })}>
      <Col xs='auto' className='ps-0 ms-2'>
        <div>
          <div className='icon-item icon-item-sm rounded-circle bg-100 shadow-none'>
            <FontAwesomeIcon
              icon={icon}
              className='text-primary cursor-pointer'
              onClick={() =>
                setReports(prevState => ({
                  ...prevState,
                  GeneralviewReportModal: {
                    open: true,
                    data: report,
                    fullScreen: false
                  }
                }))
              }
            />
          </div>
        </div>
      </Col>
      <Col>
        <Row
          className={classNames('g-2', {
            'border-bottom border-200 mb-1 pb-2': !isLast,
            'mb-0 pb-0': isLast
          })}>
          <Col
            className='cursor-pointer'
            onClick={() =>
              setReports(prevState => ({
                ...prevState,
                GeneralviewReportModal: {
                  open: true,
                  data: report,
                  fullScreen: false
                }
              }))
            }>
            <h6 className='fs--2 text-700 mb-0'>
              {reportObjects[report?.type]?.title}
            </h6>
            <p className='fs--2 text-500 mb-0'>
              {isPrivacyMode
                ? report?.organization?.legacy?.clientTag
                : report?.organization?.name}
            </p>
          </Col>
          <Col xs='auto'>
            <p className='fs--2 text-700 mb-0'>
              {moment(report?.createdAt).fromNow()}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Report.propTypes = {
  report: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    createdAt: PropTypes.string,
    organization: PropTypes.object
  }),
  icon: PropTypes.string,
  isLast: PropTypes.bool,
  setReports: PropTypes.func
};
