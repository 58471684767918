import { faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import useApplication from 'hooks/useApplication';
import moment from 'moment';
import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { DropdownFilter } from '../ReportsTableFilters';
const { default: CardDropdown } = require('components/common/CardDropdown');

/**
 * Generates the columns configuration for the reports table.
 *
 * @param {Object} params - The parameters for configuring the columns.
 * @param {boolean} params.isPrivacyMode - Flag indicating if privacy mode is enabled.
 * @param {Function} params.handleViewReport - Function to handle viewing a report.
 * @param {Function} params.handleDownloadReport - Function to handle downloading a report.
 * @param {Function} params.handleRestoreReport - Function to handle restoring a report.
 * @param {Function} params.handleDeleteReport - Function to handle deleting a report.
 * @returns {Array<Object>} The columns configuration for the reports table.
 */
export const getReportColumns = ({
  isPrivacyMode,
  handleViewReport,
  handleDownloadReport,
  handleRestoreReport,
  handleDeleteReport
}) => {
  return [
    {
      accessor: 'name',
      Header: '',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1   text-700  align-middle text-nowrap '
      },
      disableSortBy: true,
      headerProps: {
        className: 'fs--1   text-nowrap'
      },
      Cell: rowData => {
        const data = rowData.row.original;

        return (
          <Button
            size='sm'
            onClick={() => handleViewReport(data)}
            variant='link'
            className='fs--1 text-nowrap shadow-none p-2'>
            <Flex alignItems='center' className={'cursor-pointer'}>
              <FontAwesomeIcon icon={faMagnifyingGlassChart} />
            </Flex>
          </Button>
        );
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-nowrap'
      },
      meta: {
        filterVariant: 'select'
      },
      Filter: DropdownFilter,
      Cell: rowData => {
        const { type } = rowData.row.original;

        return (
          <>
            <Flex
              direction='row'
              alignItems='center'
              style={{ textTransform: 'capitalize' }}>
              {type}
            </Flex>
          </>
        );
      }
    },
    {
      accessor: isPrivacyMode
        ? 'organization.legacy.clientTag'
        : 'organization.name',
      Header: 'Organization',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1  text-700   align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1   text-nowrap'
      },
      meta: {
        filterVariant: 'select'
      },
      Filter: DropdownFilter,
      Cell: rowData => {
        const {
          application: { isPrivacyMode }
        } = useApplication();
        const { organization } = rowData.row.original;

        return (
          <>
            <Flex direction='row' alignItems='center'>
              {isPrivacyMode
                ? organization?.legacy?.clientTag
                : organization?.name}
            </Flex>
          </>
        );
      }
    },
    {
      accessor: 'createdAt',
      Header: 'Created',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1  align-middle text-nowrap'
      },
      Cell: rowData => {
        const { createdAt } = rowData.row.original;

        return (
          <>
            <Flex direction='row' alignItems='center'>
              {moment(createdAt).fromNow()}
            </Flex>
          </>
        );
      }
    },
    {
      accessor: 'leargas_actions',
      Header: '',
      Cell: rowData => {
        let data = rowData.row.original;
        let { isDeleted } = rowData.row.original;
        return (
          <Flex
            justifyContent='end'
            alignContent='middle'
            className='table-dropdown-columns'>
            <CardDropdown>
              <div className='py-2'>
                <Dropdown.Item onClick={() => handleViewReport(data)}>
                  <FontAwesomeIcon icon='search' className='me-2' />
                  Preview
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleDownloadReport(data)}>
                  <FontAwesomeIcon icon={'file-pdf'} className='me-2' />
                  Download
                </Dropdown.Item>
                <div className='dropdown-divider'></div>
                <Dropdown.Item
                  className={`${!isDeleted ? 'text-danger' : 'text-warning'}`}
                  onClick={() =>
                    isDeleted
                      ? handleRestoreReport(data)
                      : handleDeleteReport(data)
                  }>
                  <FontAwesomeIcon
                    icon={isDeleted ? 'trash-restore-alt' : 'trash-alt'}
                    className='me-2'
                  />
                  {isDeleted ? 'Restore' : 'Delete'}
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </Flex>
        );
      },
      cellProps: {
        className: 'text-end text-400 actions-cell',
        style: {
          width: '42px'
        }
      },
      hideDisplaySettings: true,
      disableSortBy: true
    }
  ];
};
