import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useReleases from 'hooks/admin-contexts/useReleases';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, FormText } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';

/**
 * Renders the FormReleaseAdd component
 *
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const FormReleaseAdd = () => {
  const { addReleaseModal, setReleases } = useReleases();

  const [loading, setLoading] = useState(false);
  const [releasedAt, setReleasedAt] = useState(new moment().toDate());
  const { axiosPrivate } = useAxiosPrivate();
  const [formData, setFormData] = useState({
    tag: '',
    name: '',
    description: '',
    released: false,
    releasedAt: releasedAt.toISOString().split('T')[0],
    visible: false
  });

  const focusRef = useRef(null);

  /**
   * Handle updating form data
   * @param {Event} e The event object
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.2
   * @version 0.1.0-beta.2
   */

  const handleFormData = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /**
   * Handle updating the release date state
   * @param {Date} date The date object to update the state with
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.2
   * @version 0.1.0-beta.2
   */

  const handleReleaseDate = date => {
    setReleasedAt(date);
    setFormData({ ...formData, releasedAt: date.toISOString().split('T')[0] });
  };

  /**
   * Handle submitting the form
   * @param {Event} e The event object
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.2
   * @version 0.1.0-beta.2
   */

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await LeargasAPI.Releases.createAppRelease(
        formData,
        axiosPrivate
      );
      if (res instanceof Error) throw res;
      setReleases(prevState => ({
        ...prevState,
        addReleaseModal: { open: false },
        fetch: true
      }));
      toast.success(res?.message);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setReleases(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setLoading(false);
    }
  };

  /**
   * Set focus to the tag input on mount
   */
  useEffect(() => {
    if (addReleaseModal?.open) {
      focusRef.current.focus();
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId='formReleaseAdd' className='mb-2 required'>
        <Form.Label className='d-flex fs--1 fw-normal'>
          Tag{' '}
          <AdvancedPopover
            containerId='newFilterModal'
            placement='top'
            popoverText='Tag for the release'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 text-400 fs--1 cursor-pointer'
            />
          </AdvancedPopover>
        </Form.Label>

        <Form.Control
          ref={focusRef}
          name='tag'
          size='sm'
          type='text'
          onChange={handleFormData}
          autoComplete='off'
          required
        />
        <FormText className='text-muted'>
          Must be a valid semantic version. See{' '}
          <a href='https://semver.org/' rel='noreferrer' target='_blank'>
            https://semver.org
          </a>{' '}
          for more information.
        </FormText>
      </Form.Group>

      <Form.Group controlId='formReleaseAdd' className='mb-2'>
        <Form.Label className='d-flex fs--1 fw-normal'>
          Name{' '}
          <AdvancedPopover
            containerId='newFilterModal'
            placement='top'
            popoverText='Be sure to keep release names uniformly formatted!'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 text-400 fs--1 cursor-pointer'
            />
          </AdvancedPopover>
        </Form.Label>

        <Form.Control
          name='name'
          size='sm'
          type='text'
          onChange={handleFormData}
          autoComplete='off'
        />
      </Form.Group>

      <Form.Group controlId='formReleaseAdd' className='mb-2'>
        <Form.Label className='d-flex fs--1 fw-normal'>
          Description{' '}
          <AdvancedPopover
            containerId='newFilterModal'
            placement='top'
            popoverText='Include any additional information about this release.'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 text-400 fs--1 cursor-pointer'
            />
          </AdvancedPopover>
        </Form.Label>

        <Form.Control
          name='description'
          size='sm'
          as='textarea'
          rows={4}
          onChange={handleFormData}
          className='simplebar'
        />
      </Form.Group>

      <Form.Group controlId='formReleaseAdd' className='mb-2'>
        <Form.Label className='d-flex fs--1 fw-normal'>
          Release Date{' '}
          <AdvancedPopover
            containerId='newFilterModal'
            placement='top'
            popoverText='This will not be shown to users until after release.'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 text-400 fs--1 cursor-pointer'
            />
          </AdvancedPopover>
        </Form.Label>

        <Form.Control
          name='releasedAt'
          size='sm'
          as={DatePicker}
          selected={releasedAt}
          onChange={date => handleReleaseDate(date)}
          wrapperClassName='d-block'
          popperPlacement='top'
        />
      </Form.Group>

      <Form.Group controlId='formGroupName' className='mb-2'>
        <Flex alignItems='center'>
          <Form.Check
            name='released'
            inline
            type='switch'
            className='me-2'
            checked={formData?.released}
            onChange={() =>
              setFormData(prevState => ({
                ...prevState,
                released: !formData.released
              }))
            }
          />
          {/* <Form.Label className='mb-0'>Released</Form.Label> */}
          <Form.Label className='d-flex mb-0 fs--1 fw-normal'>
            Released{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Toggle on to mark as released.'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
        </Flex>
      </Form.Group>

      <Form.Group controlId='formGroupName' className='mb-2'>
        <Flex alignItems='center'>
          <Form.Check
            name='visible'
            inline
            type='switch'
            className='me-2'
            checked={formData?.visible}
            onChange={() =>
              setFormData(prevState => ({
                ...prevState,
                visible: !formData.visible
              }))
            }
          />
          <Form.Label className='d-flex mb-0 fs--1 fw-normal'>
            Visible{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText=" Toggle on to make this release and it's notes visible to all users."
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
        </Flex>
      </Form.Group>

      <Flex justifyContent='end' className='mt-3'>
        <Button
          role='button'
          size='sm'
          variant='outline-secondary'
          onClick={() =>
            setReleases(prevState => ({
              ...prevState,
              addReleaseModal: { open: false, data: null }
            }))
          }>
          Cancel
        </Button>

        <Button
          role='button'
          size='sm'
          variant={loading ? 'outline-secondary' : 'success'}
          disabled={loading || !formData.tag}
          type='submit'
          className='ms-2'>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Adding' : 'Add'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

export default FormReleaseAdd;
