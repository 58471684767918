import { makeRequest } from 'api/connection/requestAction';

/**
 * Library class for making Releases API calls
 * @class
 * @exports ReleasesAPI
 * @requires axiosPrivate
 * @requires apiRoutes
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * import ReleasesAPI from 'api/library/releases';
 * const res = await ReleasesAPI.getAppReleases();
 
 */
class Releases {
  constructor(route) {
    this.route = route;
  }
  /**
   *
   * Get all application releases
   * @async
   * @function
   * @returns {Promise<*>}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * const res = await ReleasesAPI.getAppReleases();
   
   */

  async getAppReleases(axiosPrivate) {
    try {
      let res = await makeRequest(
        axiosPrivate,
        this.route.getAppReleases.method,
        this.route.getAppReleases.path
      );
      if (res instanceof Error) throw res;
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * Get a single application release
   * @async
   * @function
   * @param {object} formData The form data to send to the API
   * @returns {Promise<*>}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * const res = await ReleasesAPI.createAppRelease(formData);
   
   */
  async createAppRelease(formData, axiosPrivate) {
    let controller = new AbortController();
    try {
      let res = await makeRequest(
        axiosPrivate,
        this.route.createAppRelease.method,
        this.route.createAppRelease.path,
        formData
      );
      if (res instanceof Error) throw res;
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      controller.abort();
      return error;
    }
  }

  /**
   * Update a single application release
   * @async
   * @function
   * @param {string} id The ID of the release to update
   * @param {object} formData The form data to send to the API
   * @returns {Promise<*>}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * const res = await ReleasesAPI.updateAppRelease(id, formData);
   
   */
  async updateAppRelease(id, formData, axiosPrivate) {
    try {
      let res = await makeRequest(
        axiosPrivate,
        this.route.updateAppRelease.method,
        [this.route.updateAppRelease.path, id].join('/'),
        formData
      );
      if (res instanceof Error) throw res;
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * Delete a single application release
   * @async
   * @function
   * @param {string} id The ID of the release to delete
   * @returns {Promise<*>}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * const res = await ReleasesAPI.deleteAppRelease(id);
   
   */
  async deleteAppRelease(id, axiosPrivate) {
    try {
      let res = await makeRequest(
        axiosPrivate,
        this.route.deleteAppRelease.method,
        [this.route.deleteAppRelease.path, id].join('/')
      );
      if (res instanceof Error) throw res;
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * Create a new application release note
   * @async
   * @function
   * @param {object} formData The form data to send to the API
   * @returns {Promise<*>}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * const res = await ReleasesAPI.createAppReleaseNote(formData);
   
   */
  async createAppReleaseNote(formData, axiosPrivate) {
    try {
      let res = await makeRequest(
        axiosPrivate,
        this.route.createAppReleaseNote.method,
        this.route.createAppReleaseNote.path,
        formData
      );
      if (res instanceof Error) throw res;
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * Update a single application release note
   * @async
   * @function
   * @param {string} id The ID of the release note to update
   * @param {object} formData The form data to send to the API
   * @returns {Promise<*>}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * const res = await ReleasesAPI.updateAppReleaseNote(id, formData);
   
   */
  async updateAppReleaseNote(id, formData, axiosPrivate) {
    try {
      let res = await makeRequest(
        axiosPrivate,
        this.route.updateAppReleaseNote.method,
        [this.route.updateAppReleaseNote.path, id].join('/'),
        formData
      );
      if (res instanceof Error) throw res;
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * Delete a single application release note
   * @async
   * @function
   * @param {string} id The ID of the release note to delete
   * @returns {Promise<*>}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * const res = await ReleasesAPI.deleteAppReleaseNote(id);
   
   */
  async deleteAppReleaseNote(id, axiosPrivate) {
    try {
      let res = await makeRequest(
        axiosPrivate,
        this.route.deleteAppReleaseNote.method,
        [this.route.deleteAppReleaseNote.path, id].join('/')
      );
      if (res instanceof Error) throw res;
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default Releases;
