import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
// import ScopesWrapper from './ScopesWrapper';

/**
 * Scopes
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const Scopes = () => {
  return <WidgetSectionTitle title='Permission Management' className='mb-2' />;
};

export default Scopes;
