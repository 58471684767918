import React from 'react';
import Flex from 'components/common/Flex';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';

const RecordsLoading = () => {
  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      direction='column'
      className='vh-50'>
      <LoadingSpinner />
    </Flex>
  );
};

export default RecordsLoading;
