import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useJobs from 'hooks/admin-contexts/useJobs';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Modal } from 'react-bootstrap';
import JobDeleteForm from '../forms/JobsDeleteForm';
/**
 * Modal component for deleting a job
 *
 * @returns {JSX.Element} The ModalJobDelete component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const ModalJobDelete = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    jobs: {
      jobDeleteModal: { open }
    },
    setJobs
  } = useJobs();

  return (
    <Modal
      centered
      size='lg'
      animation={false}
      backdrop='static'
      keyboard={false}
      show={open}
      onHide={() =>
        setJobs(prevState => ({
          ...prevState,
          jobDeleteModal: { open: false, data: {} }
        }))
      }
      aria-labelledby='modalJobDelete'>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Delete Job
          <AdvancedPopover
            containerId='resetConfirmModal'
            placement='top'
            popoverText='Delete job'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
      </Modal.Header>
      <Modal.Body className='p-2'>
        <JobDeleteForm />
      </Modal.Body>
    </Modal>
  );
};

export default ModalJobDelete;
