import Flex from 'components/common/Flex';
import IndeterminateCheckbox from 'components/common/IndeterminateCheckbox';
import SimpleSpinner from 'components/utilities/AppSpinner/smallSpinner';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';

/**
 * Removes checkbox selections from other pages
 *
 * @param {Array} page - The current page of data
 * @param {Array} selectedFlatRows - The selected rows from all pages
 * @param {Function} toggleAllRowsSelected - The function to toggle all rows selected
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const removeSelectionsFromOtherPages = (
  page,
  selectedFlatRows,
  toggleAllRowsSelected
) => {
  const pageIds = page.map(({ id }) => id);
  const selectedIds = selectedFlatRows.map(row => row.id);
  const otherPageIds = selectedIds.filter(id => !pageIds.includes(id));
  if (otherPageIds.length > 0) {
    toggleAllRowsSelected(false);
  }
};

const AdvancedTableWrapper = ({
  children,
  columns,
  data,
  sortable,
  pagination,
  selection,
  selectionColumnWidth,
  setSelectedIds,
  perPage = 25,
  loading
}) => {
  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    gotoPage,
    pageCount,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      disableSortBy: !sortable,
      initialState: { pageSize: pagination ? perPage : data?.length },
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetSelectedRows: false,
      autoResetGlobalFilter: false,
      autoResetPage: false
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      if (selection) {
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            Header: ({
              getToggleAllPageRowsSelectedProps,
              toggleAllRowsSelected
            }) => (
              <IndeterminateCheckbox
                onClick={() => toggleAllRowsSelected(false)}
                {...getToggleAllPageRowsSelectedProps()}
              />
            ),
            headerProps: {
              style: {
                width: selectionColumnWidth
              }
            },
            cellProps: {
              className: 'align-middle fs--2 text-700 text-nowrap',
              style: {
                width: selectionColumnWidth
              }
            },
            Cell: ({ row, page, selectedFlatRows, toggleAllRowsSelected }) => (
              <div>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps({
                    onClick: () =>
                      removeSelectionsFromOtherPages(
                        page,
                        selectedFlatRows,
                        toggleAllRowsSelected
                      )
                  })}
                />
              </div>
            )
          },
          ...columns
        ]);
      }
    }
  );

  const recursiveMap = children => {
    return React.Children.map(children, child => {
      if (child?.props?.children) {
        return React.cloneElement(child, {
          children: recursiveMap(child?.props?.children)
        });
      } else {
        if (child?.props?.table) {
          return React.cloneElement(child, {
            ...child?.props,
            getTableProps,
            headers,
            page,
            prepareRow,
            canPreviousPage,
            canNextPage,
            nextPage,
            previousPage,
            gotoPage,
            pageCount,
            pageIndex,
            pageSize,
            setPageSize,
            globalFilter,
            setGlobalFilter
          });
        } else {
          return child;
        }
      }
    });
  };

  useEffect(() => {
    setSelectedIds &&
      setSelectedIds(selectedFlatRows.map(row => row.original.id));
  }, [selectedFlatRows]);

  return (
    <>
      <Flex
        direction='column'
        justifyContent='between'
        style={{ minHeight: '200px', overflowY: 'visible!important' }}>
        {recursiveMap(children)}
        {loading && (
          <span
            className='position-absolute'
            style={{ right: '20px', top: '20px', zIndex: '10' }}>
            <SimpleSpinner size='sm' />
          </span>
        )}
        <div
          className={`position-absolute all-0 align-items-center justify-content-around bg-card-nodata fs--1 ${
            !loading && data?.length === 0 ? 'd-flex' : 'd-none'
          }`}
          style={{ top: '40px', paddingBottom: '15px' }}>
          No results matching search criteria
        </div>
        <div
          className={`position-absolute all-0 align-items-center justify-content-around bg-card-spinner ${
            loading ? 'd-flex' : 'd-none'
          } ${loading && 'bg-card-spinner-refresh'}`}
          style={{ top: '40px' }}></div>
      </Flex>
      {/* <Flex
        alignItems="center"
        justifyContent="center"
        direction="column"
        className="vh-50"
      > */}

      {/* </Flex> */}
    </>
    // ) : loading ? (
    // <Flex
    //   alignItems="center"
    //   justifyContent="center"
    //   direction="column"
    //   className="vh-50"
    // >

    //   <Spinner animation="border" role="status">
    //     <span className="visually-hidden">Loading...</span>
    //   </Spinner>
    // </Flex>
    // <Flex
    //   alignItems="center"
    //   justifyContent="center"
    //   direction="column"
    //   className="vh-50"
    // >
    //   <span className="position-absolute" style={{ right: '20px' }}>
    //     <SimpleSpinner size="sm" />
    //   </span>
    // </Flex>
    // ) : (
    //   <Flex
    //     alignItems="center"
    //     justifyContent="center"
    //     direction="column"
    //     className="vh-50"
    //   >
    //     <Table className="d-flex flex-row justify-content-center align-items-center mb-3">
    //       <tbody>
    //         <tr>
    //           <td style={{ border: 'none' }}>
    //             No results matching search criteria
    //           </td>
    //         </tr>
    //       </tbody>
    //     </Table>
    //   </Flex>
  );
};

AdvancedTableWrapper.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.array,
  data: PropTypes.array,
  dataTypeLabel: PropTypes.string,
  setShowCreateModal: PropTypes.func,
  sortable: PropTypes.bool,
  pagination: PropTypes.bool,
  selection: PropTypes.bool,
  selectionColumnWidth: PropTypes.number,
  setSelectedIds: PropTypes.func,
  perPage: PropTypes.number,
  loading: PropTypes.bool,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  toggleAllRowsSelected: PropTypes.func,
  row: PropTypes.object,
  page: PropTypes.array,
  selectedFlatRows: PropTypes.array
};

export default AdvancedTableWrapper;
