import LeargasAPI from 'api';

import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useArticles from 'hooks/admin-contexts/useArticles';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticleTagsAddForm = () => {
  const { register, handleSubmit, watch } = useForm();
  const { setArticles } = useArticles();
  const { tag } = watch();
  const [loading, setLoading] = useState(false);

  const { axiosPrivate } = useAxiosPrivate();

  /**
   *
   * @param {Object} data
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const onSubmitData = async data => {
    try {
      setLoading(true);
      let res = await LeargasAPI.AppArticles.createAppArticleTag(
        data,
        axiosPrivate
      );
      if (res instanceof Error) throw res;
      const { message } = res;
      toast.success(message);
      setLoading(false);
      setArticles(prevState => ({
        ...prevState,
        fetch: true,

        addArticleTagModal: { open: false }
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
      setLoading(false);

      setArticles(prevState => ({
        ...prevState,

        addArticleTagModal: { open: false }
      }));
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitData)}>
      <Row>
        <Form.Group as={Col} sm={12} className='required mb-3'>
          <Form.Label>Tag</Form.Label>
          <Form.Control
            placeholder={'tag'}
            {...register('tag')}
            type='text'
            autoComplete='off'
            className='fs--1'
            style={{ minHeight: '36px' }}
            required
          />
        </Form.Group>
      </Row>
      <Flex justifyContent='end' className={'mt-3'}>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() =>
            setArticles(prevState => ({
              ...prevState,
              addArticleTagModal: { open: false }
            }))
          }>
          Cancel
        </Button>

        <Button
          variant='success'
          type='submit'
          className='d-flex'
          size='sm'
          disabled={!tag}>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Submitting' : 'Submit'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

export default ArticleTagsAddForm;
