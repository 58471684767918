// import { axiosPrivate } from 'api/connection/axios';
import { apiRoutes } from 'config/routeSettings';

class Locations {
  constructor(route) {
    this.route = route;
  }
  getAll(axiosPrivate) {
    return axiosPrivate.get(apiRoutes.LOCATIONS);
  }

  get(id, axiosPrivate) {
    return axiosPrivate.get(`${apiRoutes.LOCATIONS}/${id}`);
  }

  create(data, axiosPrivate) {
    return axiosPrivate.post(apiRoutes.LOCATIONS, data);
  }

  update(id, data, axiosPrivate) {
    return axiosPrivate.put(`${apiRoutes.LOCATIONS}/${id}`, data);
  }

  delete(id, axiosPrivate) {
    return axiosPrivate.delete(`${apiRoutes.LOCATIONS}/${id}`);
  }

  deleteAll(axiosPrivate) {
    return axiosPrivate.delete(apiRoutes.LOCATIONS);
  }
}

export default Locations;
