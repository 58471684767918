/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

/**
 *
 * @param {object} props
 * @param {Boolean} props.canPreviousPage
 * @param {Boolean} props.canNextPage
 * @param {Boolean} props.previousPage
 * @param {Function} props.nextPage
 * @param {Number} props.pageCount
 * @param {Number} props.pageIndex
 * @param {Function} props.gotoPage
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

export const ArticlesTablePagination = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  gotoPage
}) => {
  return (
    <Flex alignItems='center' justifyContent='center'>
      <Button
        size='sm'
        variant='falcon-default'
        onClick={() => previousPage()}
        className={classNames({ disabled: !canPreviousPage })}>
        <FontAwesomeIcon icon='chevron-left' />
      </Button>
      <ul className='pagination mb-0 mx-1'>
        {Array.from(Array(pageCount).keys()).map((page, index) => (
          <li key={page} className={classNames({ active: pageIndex === page })}>
            <Button
              size='sm'
              variant='falcon-default'
              className={classNames('page', {
                'me-1': index + 1 !== pageCount
              })}
              onClick={() => gotoPage(page)}>
              {page + 1}
            </Button>
          </li>
        ))}
      </ul>
      <Button
        size='sm'
        variant='falcon-default'
        onClick={() => nextPage()}
        className={classNames({ disabled: !canNextPage })}>
        <FontAwesomeIcon icon='chevron-right' />
      </Button>
    </Flex>
  );
};

ArticlesTablePagination.propTypes = {
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  previousPage: PropTypes.func,
  nextPage: PropTypes.func,
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  gotoPage: PropTypes.func
};

export default ArticlesTablePagination;
