import React from 'react';
import MenusLayout from './MenusLayout.js';

const Menus = () => {
  return (
    <>
      <MenusLayout />
    </>
  );
};

export default Menus;
