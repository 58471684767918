import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import brokenImage from 'assets/img/illustrations/status-broken.png';
import successImage from 'assets/img/illustrations/status-success.png';
import Flex from 'components/common/Flex';
import { routes } from 'config/routeSettings';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import useAxiosPrivate from 'hooks/useAxiosPrivate';

/**
 * Account activation page
 *
 * The account activation page is used to activate a user's account. The user is normally directed to this page
 * from an email link. The link will have a token in the query params and the page will attempt to activate the
 * account using the token. If the account is successfully activated, the user will be redirected to the login page.
 * @returns {React.Component} The account activation page
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const Activate = ({ titleTag: TitleTag }) => {
  let [searchParams] = useSearchParams();
  let [loading, setLoading] = useState(true);
  let [activated, setActivated] = useState(false);
  let [counter, setCounter] = useState();

  const { axiosPrivate } = useAxiosPrivate();

  let navigate = useNavigate();

  /**
   * Attempt to activate the account when the component mounts and the token is present
   */
  useEffect(() => {
    Activate();
  }, [searchParams]);
  const Activate = async () => {
    try {
      const token = searchParams.get('token');
      const res = await LeargasAPI.AccountActivate.userActivate(
        {
          body: { verificationToken: token }
        },
        axiosPrivate
      );
      if (res instanceof Error) throw res;
      // If a message was returned, display it
      let message = res?.message;
      if (message) toast.success(message);

      // Set the loading state to false and the activated state to true
      // We also set the counter to 5 seconds to redirect the user to the login page
      // We use setTimeout to delay the state change so the user can see the success message
      setTimeout(function () {
        setLoading(false);
        setActivated(true);
        setCounter(5);
      }, 1000);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setTimeout(function () {
        setLoading(false);
        setCounter(10);
        setActivated(false);
      }, 1000);
    }
  };
  /**
   * Redirect to the login page when the counter reaches 0
   */

  useEffect(() => {
    if (counter === 0 && activated)
      navigate(routes.LOGIN, {
        replace: true
      });
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  return (
    <>
      {loading ? (
        <div className='text-center pt-1'>
          <TitleTag>
            Activating your account...{' '}
            <Spinner animation='border' size='sm' variant='primary' />
          </TitleTag>
        </div>
      ) : activated ? (
        <div className='text-center'>
          <img
            className='d-block mx-auto'
            src={successImage}
            alt='Account successfully activated!'
            width={150}
          />
          <TitleTag>Account successfully activated!</TitleTag>
          <p>
            You will be redirected to the log in page in{' '}
            <strong>{counter}</strong> seconds.
          </p>
          <Button as={Link} color='primary' className='mt-3' to={routes.LOGIN}>
            <FontAwesomeIcon
              icon='chevron-left'
              transform='shrink-4 down-1'
              className='me-1'
            />
            Return to login
          </Button>
        </div>
      ) : (
        <div className='text-center'>
          <img
            className='d-block mx-auto'
            src={brokenImage}
            alt='Account could not be activated.'
            width={150}
          />
          <h5 className='fs--1'>Your account could not be activated.</h5>
          <p className='fs--1 mb-1'>
            The activation link may be expired or invalid.
          </p>
          <Flex justifyContent='center'>
            <Button
              as={Link}
              variant='outline-secondary'
              className='mt-3 me-3'
              to={routes.LOGIN}>
              <FontAwesomeIcon
                icon='chevron-left'
                transform='shrink-4 down-1'
                className='me-1'
              />
              Return to login
            </Button>
            <Button
              as='a'
              variant='primary'
              className='mt-3'
              href='https://leargassecurity.com/contact-us/'
              target='_blank'
              rel='noreferrer'>
              <FontAwesomeIcon icon='envelope' className='me-2' />
              Contact Us
            </Button>
          </Flex>
        </div>
      )}
    </>
  );
};

Activate.propTypes = {
  titleTag: PropTypes.string
};

Activate.defaultProps = { titleTag: 'h4' };

export default Activate;
