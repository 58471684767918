import { axiosPrivate } from 'api/connection/axios';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import routes from 'config/routeSettings';
import useAuthentication from 'hooks/useAuthentication';
import useLogout from 'hooks/useLogout';
import useRefreshToken from 'hooks/useRefreshToken';
import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

/**
 * Array of non-authenticated routes.
 *
 * We'll avoid attempts to refresh the token for these routes.
 *
 * @type {Array<string>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const nonAuthRoutes = [
  routes.LOGIN,
  routes.FORGOT_PASSWORD,
  routes.RESET_PASSWORD,
  routes.REGISTER,
  routes.CONFIRM_MAIL,
  routes.ACTIVATE
];

/**
 * Persist authentication state, if necessary, and display a loading spinner while the refresh token is being verified.
 *
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 * <PersistAuth>
 *   <Outlet />
 * </PersistAuth>
 * )
 */
const PersistAuth = () => {
  const location = useLocation();
  const { authentication, setAuthentication } = useAuthentication();

  const { refresh } = useRefreshToken();

  const logout = useLogout();

  /**
   * Verifies the refresh token and updates the authentication state accordingly.
   * If the refresh token is valid, it sets the `persistLoader` flag to `false` after a delay of 1 second.
   * If an error occurs during the verification process, it logs the error and performs a logout.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const verifyRefreshToken = async () => {
    try {
      await refresh();
      setTimeout(function () {
        setAuthentication(prevState => ({
          ...prevState,
          persistLoader: false
        }));
      }, 1000);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setTimeout(function () {
        setAuthentication(prevState => ({
          ...prevState,
          persistLoader: false
        }));
        logout(axiosPrivate);
      }, 1000);
    }
  };

  const checkForCookie = name => {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (
      authentication?.persist &&
      checkForCookie('deviceId') &&
      !authentication.accessToken &&
      !authentication.refreshing
    ) {
      if (!nonAuthRoutes.includes(location.pathname)) {
        verifyRefreshToken();
      } else {
        setTimeout(function () {
          setAuthentication(prevState => ({
            ...prevState,
            persistLoader: false
          }));
        }, 1000);
      }
    } else {
      setTimeout(function () {
        setAuthentication(prevState => ({
          ...prevState,
          persistLoader: false
        }));
      }, 1000);
    }
  }, []);

  return (
    <>
      {authentication?.persistLoader ? (
        <div className='position-fixed all-0 d-flex justify-content-center align-items-center'>
          <LoadingSpinner
            grow={'10'}
            color='primary'
            className='fs-5 shadow-inset'
          />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistAuth;
