import { LineChart } from 'echarts/charts';
import {
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { getColor, numberFormatter, rgbaColor } from 'helpers/utils';
import moment from 'moment';

echarts.use([
  LineChart,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent
]);

/**
 * Returns the chart options for a histogram.
 *
 * @param {Array} data - The data for the chart
 * @param {boolean} isDark - Indicates whether the chart is in dark mode
 * @returns {Object} - The chart options
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const getChartOptions = (data, isDark) => ({
  color: [rgbaColor(getColor('primary'), 1)],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        show: false,
        backgroundColor: '#6a7985'
      }
    },
    padding: [7, 10],
    borderWidth: 1,
    borderColor: isDark ? getColor('gray-300') : getColor('gray-300'),
    backgroundColor: isDark ? getColor('gray-200') : getColor('white'),
    textStyle: {
      fontWeight: 400,
      fontSize: 12,
      color: getColor('gray-900')
    },
    formatter: params => {
      const sum = data.reduce((acc, curr) => acc + Number(curr.count), 0);
      return `<p class="m-0 fs--1">${moment(params[0]?.name).format(
        'YYYY-MM-DD HH:mm:ss'
      )}: <b>${Number(params[0]?.value).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })}</b> (${
        params[0]?.value > 0 ? ((params[0]?.value / sum) * 100).toFixed(2) : 0
      }%) </p>`;
    }
  },
  // toolbox: {
  //   top: 10,
  //   right: 5,
  //   feature: {
  //     saveAsImage: {
  //       title: 'Save',
  //       type: 'png',
  //       name: `leargas-explore-histogram-${new Date().getTime()}`
  //     }
  //   },
  //   iconStyle: {
  //     color: getColor('gray-900'),
  //     borderColor: getColor('gray-900')
  //   },
  //   emphasis: {
  //     iconStyle: {
  //       borderColor: getColor('pastel-green')
  //     }
  //   }
  // },
  xAxis: {
    type: 'category',
    data: data?.map(item => new Date(item['key'])),
    axisLabel: {
      show: true,
      color: isDark ? 'var(--falcon-gray-400' : 'var(--falcon-gray-600)',
      fontWeight: 400,
      fontSize: 10,
      margin: 10,
      formatter: function (value) {
        return moment(value, 'ddd MMM DD YYYY HH:mm:ss ZZ').format(
          'YYYY-MM-DD HH:mm:ss'
        );
      }
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: rgbaColor(getColor('primary'), 0.1)
      }
    },
    axisTick: {
      show: true,
      alignWithLabel: true,
      lineStyle: {
        color: rgbaColor(getColor('primary'), 0.1)
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: rgbaColor(getColor('primary'), 0.1)
      }
    },
    z: 2
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      show: true,
      color: getColor('gray-700'),
      fontWeight: 400,
      fontSize: 10,
      margin: 10,
      formatter: function (value) {
        return numberFormatter(value, 0);
      }
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: rgbaColor(getColor('primary'), 0.1)
      }
    },
    axisTick: {
      show: true,
      alignWithLabel: true,
      lineStyle: {
        color: rgbaColor(getColor('primary'), 0.1)
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: rgbaColor(getColor('primary'), 0.1)
      }
    },
    z: 2
  },
  series: [
    {
      type: 'line',
      smooth: true,
      data: data?.map(item => Number(item['count']) || 0),
      symbol: 'emptyCircle',
      showSymbol: false,
      itemStyle: {
        color: getColor('pastel-green')
      },
      lineStyle: {
        color: getColor('pastel-green'),
        width: 1
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('pastel-green'), 1)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('pastel-green'), 0.25)
            }
          ]
        }
      },
      emphasis: {
        lineStyle: {
          width: 1
        },
        focus: 'series'
      },
      z: 3
    }
  ],
  grid: { right: 10, left: 40, bottom: 23, top: 8 }
});
