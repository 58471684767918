import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import SearchValueContextMenu from 'components/search-filters/SearchValueContextMenu';
import useApplication from 'hooks/useApplication';
import useExplore from 'hooks/useExplore';
import React from 'react';
import { Col, ListGroup, Offcanvas, Row } from 'react-bootstrap';

/**
 * Renders an offcanvas component that displays top values for a field.
 *
 * @returns {JSX.Element} The rendered offcanvas component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const TopValuesOffcanvas = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    state: {
      topValues,
      topValuesLoading,
      topValuesModalShow,
      topValuesSelectedFieldName,
      topValuesSelectedFieldType
    },
    setState
  } = useExplore();

  /**
   * Closes the top values offcanvas.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleClose = () => {
    // Reset the state
    setState('topValuesLoading', false);
    setState('topValuesModalShow', false);
  };

  return (
    <>
      <Offcanvas
        show={topValuesModalShow}
        onHide={handleClose}
        placement='end'
        className='border-0'>
        <Offcanvas.Header
          className='bg-card'
          closeButton
          closeVariant={isDark ? 'white' : 'black'}>
          <Offcanvas.Title as='div' className='py-0 flex-grow-1 mt-1 mb-0 pb-0'>
            <h4 className='fs-0 fw-normal text-700 m-0'>
              {topValuesLoading ? (
                <>
                  <span>Fetching top values for field:</span> <br />
                </>
              ) : topValues.length === 0 ? (
                <>
                  <span>Top values for field:</span> <br />
                </>
              ) : (
                <>
                  <span>Top {topValues.length} values for field:</span> <br />
                </>
              )}
              <code>{topValuesSelectedFieldName}</code>
            </h4>
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className='scrollbar mt-0'>
          <Row>
            <Col>
              {topValuesLoading ? (
                <Flex justifyContent='around' alignItems='center'>
                  <FontAwesomeIcon
                    className='text-primary'
                    fade={true}
                    icon={['fas', 'clover']}
                    spin={true}
                    transform='grow-10'
                  />
                </Flex>
              ) : topValues.length === 0 ? (
                <Flex direction='column' justifyContent='start'>
                  <h6 className='fs--1 text-700'>
                    No values found for this field.
                  </h6>
                  <p className='fs--1 mb-0 text-700'>
                    Try adjusting the time range or select a different index.
                  </p>
                </Flex>
              ) : (
                <>
                  <ListGroup variant='flush'>
                    <Flex
                      as='div'
                      direction='row'
                      justifyContent='between'
                      className='p-0 py-2 fs--2 bg-transparent text-nowrap'>
                      <div>Value</div>
                      <div>Count</div>
                    </Flex>
                  </ListGroup>

                  <ListGroup variant='flush'>
                    {topValues && topValues.length > 0
                      ? topValues.map((value, index) => (
                          <Flex
                            key={index}
                            as='div'
                            alignItems='center'
                            direction='row'
                            justifyContent='between'
                            className={classNames(
                              'p-0 py-2 fs--2 bg-transparent text-nowrap',
                              {
                                'border-bottom border-300':
                                  index < topValues.length - 1
                              }
                            )}>
                            <div
                              className='h-100'
                              style={{
                                whiteSpace: 'pre-line',
                                overflowWrap: 'anywhere'
                              }}>
                              <Flex alignItems='center' className='h-100'>
                                <SearchValueContextMenu
                                  searchKey={topValuesSelectedFieldName}
                                  searchValue={value.key}
                                  valueType={topValuesSelectedFieldType}
                                  onSelection={() => handleClose()}
                                />
                              </Flex>
                            </div>
                            <div className='h-100'>
                              <Flex alignItems='center' className='h-100'>
                                {Number(value.count).toLocaleString()}
                              </Flex>
                            </div>
                          </Flex>
                        ))
                      : null}
                  </ListGroup>
                </>
              )}
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default TopValuesOffcanvas;
