import React from 'react';
import PropTypes from 'prop-types';
import ProfileContainer from './ProfileContainer';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Banner = ({ user }) => {
  return (
    <ProfileContainer user={user}>
      <ProfileContainer.Header
        avatar={
          user?.profile?.media?.avatar ||
          process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG
        }
        coverSrc={
          user?.profile?.media?.banner ||
          process.env.REACT_APP_DEFAULT_USER_BANNER_IMG
        }
      />
    </ProfileContainer>
  );
};

Banner.propTypes = {
  user: PropTypes.object.isRequired
};

export default Banner;
