import { makeRequest } from 'api/connection/requestAction';

class AppBookmarks {
  constructor(route) {
    this.route = route;
  }

  async createBookmark(bookmarkData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.createBookmark.method,
        this.route.createBookmark.path,
        bookmarkData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getBookmarks(axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getBookmarks.method,
        this.route.getBookmarks.path
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getBookmark(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getBookmark.method,
        [this.route.getBookmark.path, id].join('/')
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async updateBookmark(id, bookmarkData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateBookmark.method,
        [this.route.updateBookmark.path, id].join('/'),
        bookmarkData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async deleteBookmark(id, deleting, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.deleteBookmark.method,
        [this.route.deleteBookmark.path, id].join('/'),
        {
          deleting
        }
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default AppBookmarks;
