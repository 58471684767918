import classNames from 'classnames';
import Flex from 'components/common/Flex';
import routes from 'config/routeSettings';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { useLocation } from 'react-router-dom';
import SearchButton from './SearchButton';

/**
 * Renders the left panel extension for the data navigator component
 * @returns {JSX.Element} The rendered left panel extension
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const NavigatorLeftPanelExtension = () => {
  const {
    application: { isDark }
  } = useApplication();
  const location = useLocation();
  return (
    <Flex className='navigator-left-panel-container'>
      <Flex
        className={classNames(
          'navigator-left-panel-wrapper bg-card pe-2 ps-1'
        )}>
        {/* <Flex
          className={classNames('navigator-left-panel-ext-primary', {
            'border-100': isDark,
            'border-200': !isDark
          })}
          alignItems='center'>
          <SavedSearchesButton />
        </Flex> */}
        <Flex
          className={classNames('navigator-left-panel-ext-primary', {
            'border-100': isDark,
            'border-200': !isDark
          })}
          alignItems='center'>
          {location.pathname !== routes.OVERVIEW && <SearchButton />}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NavigatorLeftPanelExtension;
