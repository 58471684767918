import Flex from 'components/common/Flex';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useAppliances from 'hooks/admin-contexts/useAppliances';
import useApplication from 'hooks/useApplication';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import AppliancesTable from './AppliancesTable';
import AppliancesTableFilters from './AppliancesTableFilters';
import AppliancesTableFooter from './AppliancesTableFooter';
import AppliancesTablePagination from './AppliancesTablePagination';
import AppliancesTableWrapper from './AppliancesTableWrapper';
import { getApplianceColumns } from './Columns/ColumnsDefaults';
import ShowDeletedButton from './ShowDeletedButton';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const Appliances = () => {
  const {
    application: { isPrivacyMode }
  } = useApplication();

  const {
    appliances: { showDeleted, data },
    loading: componentLoading,
    setAppliances
  } = useAppliances();
  const [globalFilter, setGlobalFilter] = useState('');
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns(getApplianceColumns(setAppliances, isPrivacyMode));
  }, []);

  return (
    <Card className='shadow-none border'>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          <span className='d-block'>Appliances</span>
          <Flex justifyContent='end' alignItems='center'>
            <ShowDeletedButton
              showDeleted={showDeleted}
              onClick={() => {
                setAppliances(prevState => ({
                  ...prevState,
                  showDeleted: !showDeleted
                }));
              }}
            />
          </Flex>
        </Flex>
      </Card.Header>
      <Card.Body className='position-relative'>
        {componentLoading ? (
          <LoadingSpinner />
        ) : (
          <AppliancesTableWrapper
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            data={
              showDeleted
                ? data
                : data?.filter(appliance => !appliance?.isDeleted)
            }
            sortable
            loading={componentLoading}
            pagination
            perPage={50}>
            <AppliancesTableFilters
              table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <AppliancesTable table columns={columns} />
            <div className='mt-3 d-flex justify-content-between'>
              <AppliancesTableFooter
                table
                rowInfo
                data={
                  showDeleted
                    ? data
                    : data?.filter(appliance => !appliance?.isDeleted)
                }
                rowCount={
                  showDeleted
                    ? data?.length
                    : data?.filter(appliance => !appliance?.isDeleted).length
                }
              />
              <AppliancesTablePagination table />
            </div>
          </AppliancesTableWrapper>
        )}
      </Card.Body>
    </Card>
  );
};

export default Appliances;
