import OrgProfileBanner from 'components/common/profile/OrgProfile';
import useAccount from 'hooks/useAccount';
// import useApplication from 'hooks/useApplication';
import useOrganizations from 'hooks/user-contexts/useUserOrganizations';
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import InviteUserModal from './Components/Modals/InviteUserModel';
import RemovePendingUserModal from './Components/Modals/RemovePendingUserModal';
import RemoveUserModal from './Components/Modals/RemoveUserModal';
import OrgUsers from './Components/OrgUsers/OrgUsers';
import PendingInviations from './Components/PendingInvitations/PendingInvitations';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const OrgProile = () => {
  let { id } = useParams();
  let [org, setOrg] = useState({});
  let [isOwner, setIsOwner] = useState(false);
  const { account } = useAccount();
  // const {
  //   application: { isPrivacyMode }
  // } = useApplication();
  const {
    organizations: { fetch, data }
  } = useOrganizations();

  /**
   *
   */
  useEffect(() => {
    getOrg(id);
  }, [id, fetch, data]);

  /**
   *
   * @param {string} id
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const getOrg = async id => {
    try {
      let org = data?.find(org => org?.id === id);
      setOrg(org);
      //checking owners with user logged in
      let { owners } = org;
      const ownerbool = owners?.some(owner => owner?.id === account?.id);
      setIsOwner(ownerbool);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setIsOwner(false);
    }
  };

  return (
    <>
      {/* <Breadcrumb
        items={[
          {
            label: 'Organizations',
            icon: ['fas', 'landmark'],
            to: routes.ORGANIZATIONS
          },
          {
            label:
              isPrivacyMode && org?.legacy?.clientTag
                ? org?.legacy?.clientTag
                : org?.name
          }
        ]}
      /> */}
      <OrgProfileBanner org={org || {}} />
      <Row className='g-3 mt-2'>
        <OrgUsers users={org?.users} isOwner={isOwner} organization={org} />
        {isOwner && id && <PendingInviations isOwner={isOwner} id={org?.id} />}
      </Row>
      {isOwner && <InviteUserModal />}
      {isOwner && <RemoveUserModal />}
      {isOwner && <RemovePendingUserModal />}
    </>
  );
};

export default OrgProile;
