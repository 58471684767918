import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import { useSearchFilters } from 'context/FiltersProvider';
import { filtersOperators } from 'context/FiltersProvider/settings';
import { truncateMiddle } from 'helpers/utils';
import useApplication from 'hooks/useApplication';
import useExplore from 'hooks/useExplore';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownMenu,
  Overlay,
  Popover
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import FieldBadge from './FieldBadge';

/**
 * Renders a single field item in the sidebar.
 *
 * @param {Object} props - The component props
 * @param {Array} props.actionIcon - The icon to display for the action
 * @param {string} [props.actionType='add'] - The type of action to perform
 * @param {Object} props.field - The field to render
 * @param {Function} props.handleFieldAction - The function to handle the field action
 * @param {number} props.index - The index of the field in the list
 * @param {string|JSX.Element} props.popoverText - The text to display in the popover
 * @param {number} props.totalFields - The total number of fields in the list
 * @returns {JSX.Element} The rendered field item component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const FieldItem = ({
  actionIcon,
  actionType = 'add',
  field,
  handleFieldAction,
  index,
  popoverText,
  totalFields
}) => {
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);

  const {
    application: { isDark }
  } = useApplication();

  const {
    state: { indexSelected, query },
    setState,
    fetchTopValues,
    makeRequest
  } = useExplore();
  const {
    // searchFilters: { filters, modalFilterAdd, operators },
    setSearchFilters
  } = useSearchFilters();

  const [show, setShow] = useState(false);

  /**
   * Toggles the value of `show`.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleToggle = () => {
    setShow(!show);
  };

  /**
   * Handles the click outside event to close the dropdown menu.
   *
   * @param {Event} e - The click event object
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleClickOutside = e => {
    if (
      triggerRef.current &&
      !triggerRef.current.contains(e.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      // If the click is outside of the trigger and the dropdown, close the dropdown
      setShow(false);
    }
  };

  /**
   * Handles the click event on the label.
   *
   * Fetches the top values based on the field type and name.
   * Sets the state to show the top values modal and loading state.
   * Sets the selected field name in the state.
   *
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleLabelClick = async () => {
    setShow(false);
    // Set the state to show the top values modal and loading state
    setState('topValuesModalShow', true);
    setState('topValuesLoading', true);
    setState('topValuesSelectedFieldName', field.name);
    setState('topValuesSelectedFieldType', field.type);

    // Fetch the top values based on the field type and name
    const topValues = await fetchTopValues(
      field.type === 'string' ? `${field.name}.keyword` : field.name,
      indexSelected.alias,
      query.query,
      makeRequest
    );

    // Set the top values in the state and remove the loading state
    setState('topValues', topValues);
    setState('topValuesLoading', false);
  };

  /**
   * Adds an event listener to handle clicks outside of the dropdown menu.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    // Add the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Remove the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const popover = (
    <Popover
      id='searchFiltersBulkActionsPopover'
      style={{
        width: 'fit-content',
        minWidth: 'fit-content',
        padding: 0,
        margin: 0,
        marginTop: -5
      }}>
      <div ref={dropdownRef}>
        <Flex
          justifyContent='start'
          alignItems='start'
          direction={'column'}
          className='p-0'>
          <DropdownMenu
            show={true}
            className='position-relative border-0'
            style={{
              minWidth: 'fit-content',
              width: 'fit-content'
            }}>
            <SimpleBar
              className='h-100'
              forceVisible={true}
              autoHide={false}
              style={{
                maxHeight: '70vh',
                minHeight: '150px'
              }}>
              <Dropdown.Header>Selection</Dropdown.Header>
              <div className='mb-2'>
                <Dropdown.Item onClick={() => handleFieldAction(field)}>
                  <span className='pe-4'>
                    <FontAwesomeIcon
                      icon={['fas', 'plus']}
                      width={16}
                      height={16}
                      className='me-2'
                    />
                    {actionType === 'remove' ? 'Remove from ' : 'Add to '}
                    selected
                  </span>
                </Dropdown.Item>
              </div>
              <Dropdown.Header>Resources</Dropdown.Header>
              {/* <div className='mb-2'>
                <Dropdown.Item>
                  <span className='pe-3'>
                    <FontAwesomeIcon
                      icon={['fab', 'readme']}
                      width={16}
                      height={16}
                      className='me-2'
                    />
                    About this field
                  </span>
                </Dropdown.Item>
              </div> */}
              <div className='mb-2'>
                <Dropdown.Item onClick={handleLabelClick}>
                  <span className='pe-3'>
                    <FontAwesomeIcon
                      icon={['fas', 'chart-bar']}
                      width={16}
                      height={16}
                      className='me-2'
                    />
                    Get top values
                  </span>
                </Dropdown.Item>
              </div>
              <Dropdown.Header>Add a filter fg</Dropdown.Header>
              {Object.entries(filtersOperators).map(
                ([key, operator], index) => {
                  return operator.isCompatibleWithType(field?.type) ? (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        setSearchFilters(prev => ({
                          ...prev,
                          modalFilterAdd: {
                            ...prev.modalFilterAdd,
                            data: {
                              ...prev.modalFilterAdd.data,
                              field: field,
                              operator: key
                            },
                            open: true
                          }
                        }));
                        setShow(false);
                      }}>
                      <span className='pe-3'>
                        {operator?.icon && (
                          <FontAwesomeIcon
                            icon={operator?.icon}
                            width={16}
                            height={16}
                            className='me-2'
                          />
                        )}
                        {operator.label}
                      </span>
                    </Dropdown.Item>
                  ) : null;
                }
              )}
            </SimpleBar>
          </DropdownMenu>
        </Flex>
      </div>
    </Popover>
  );

  return (
    <Flex
      alignItems='center'
      as='div'
      className={classNames('m-0 p-1 fs--2 text-nowrap', {
        'border-bottom border-card': totalFields > 2 && index < totalFields - 1,
        'hover-bg-300': !isDark,
        'hover-bg-200': isDark
      })}
      direction='row'>
      <div className='w-100'>
        <Flex className='me-auto' direction='row' alignItems='start'>
          <FieldBadge type={field.type} />
          {field.name === '@timestamp' ? (
            <AdvancedPopover
              placement='top'
              popoverText={
                <span className='text-700'>
                  <span>The</span>
                  <code className={`mx-2 text-dark`}>@timestamp</code>
                  <span>
                    field represents the time at which the event occurred.
                  </span>
                </span>
              }
              showArrow={true}>
              <Button
                className='p-0 text-decoration-none fs--2 text-700 text-start fw-normal'
                variant='link'>
                {String(field.name).replace(/\./g, '\u200B.')}
              </Button>
            </AdvancedPopover>
          ) : (
            <Flex direction='column' className='h-100'>
              <Overlay
                show={show}
                target={triggerRef.current}
                placement='right-start'
                offset={[0, 15]}
                rootClose={true}
                onHide={() => setShow(false)}>
                {popover}
              </Overlay>

              <AdvancedPopover
                placement='top'
                popoverText={
                  <div className='text-700 overflow-hidden'>
                    <span className='text-nowrap'>Get more details on the</span>
                    <code className='text-nowrap text-dark d-block'>
                      {truncateMiddle(field.name)}
                    </code>
                    <span>
                      field, as well as it's top values within the specified
                      time range.
                    </span>
                  </div>
                }
                showArrow={true}>
                <Button
                  ref={triggerRef}
                  id='exploreFieldItemPopoverTrigger'
                  onClick={handleToggle}
                  className='p-0 text-decoration-none fs--2 text-700 hover-900 text-start fw-normal'
                  variant='link'
                  style={{ whiteSpace: 'pre-line', overflowWrap: 'anywhere' }}>
                  {String(field.name).replace(/\./g, '\u200B.')}
                </Button>
              </AdvancedPopover>
            </Flex>
          )}
        </Flex>
      </div>
      <AdvancedPopover
        placement='top'
        popoverText={popoverText}
        showArrow={true}>
        <Button
          className={classNames(
            'p-0 text-decoration-none fs--2 fw-normal border-400 border-dotted bg-transparent outline-0 shadow-none ',
            {
              'd-none': actionType === 'none',
              'hover-danger border-hover-danger': actionType === 'remove',
              'hover-primary border-hover-primary': actionType === 'add'
            }
          )}
          onClick={() => handleFieldAction(field)}
          variant='outline-secondary'>
          <FontAwesomeIcon icon={actionIcon} width={16} height={16} />
        </Button>
      </AdvancedPopover>
    </Flex>
  );
};

FieldItem.propTypes = {
  actionIcon: PropTypes.array.isRequired,
  actionIconColor: PropTypes.string,
  actionType: PropTypes.string,
  field: PropTypes.object.isRequired,
  handleFieldAction: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  popoverText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  totalFields: PropTypes.number.isRequired
};

export default FieldItem;
