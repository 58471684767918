import React from 'react';
import useReleases from 'hooks/admin-contexts/useReleases';
import Release from './Release';
import useAuthentication from 'hooks/useAuthentication';

const allowedRoles = ['super-admin', 'admin'];

/**
 * Render the Releases component
 *
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Releases = () => {
  const { releases } = useReleases();

  const {
    authentication: { roles }
  } = useAuthentication();

  return releases.map((release, index) => {
    return release.visible ? (
      <Release
        key={release.tag}
        release={release}
        isLast={index === releases.length - 1}
      />
    ) : (
      roles.some(role => allowedRoles.includes(role.slug)) && (
        <Release
          key={release.tag}
          release={release}
          isLast={index === releases.length - 1}
        />
      )
    );
  });
};

export default Releases;
