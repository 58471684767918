import React from 'react';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import VerifiedBadge from 'components/common/VerifiedBadge';
import LockScreenForm from './LockScreenForm';
import useAuthentication from 'hooks/useAuthentication';
import useAccount from 'hooks/useAccount';
import { Col, Row } from 'react-bootstrap';

/**
 * Lock screen page
 *
 * The lock screen page is used to display a form to the user to unlock their session. The user is
 * normally directed to this page after they choose to lock their session.
 * We use the useAccount hook to get the account data from the context. Then we display a form to the user to unlock
 * their session.
 * @returns {React.Component} The lock screen page
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */

const LockScreen = () => {
  const {
    authentication: { emails }
  } = useAuthentication();

  const {
    account: {
      profile: {
        name: { first, last },
        media: { avatar }
      }
    }
  } = useAccount();

  return (
    <Row>
      <Col>
        <Flex direction='column' justifyContent='around'>
          <Flex direction='column' alignItems='center'>
            <Avatar
              src={avatar || process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG}
              size='4xl'
            />
            <h5 className='mb-1 ps-4 pt-2 fs--1'>
              {[first, last].join(' ')} <VerifiedBadge />
            </h5>
            <span className='fs--1 mb-1'>
              {emails.find(e => e.isPrimary)?.address}
            </span>
            <hr className='w-100' />
            <h5 className='mb-0 mt-1 fs--1'>Your session is locked</h5>
            <p className='pt-2 fs--1 text-center'>
              Please enter your password to continue
            </p>
          </Flex>
          <LockScreenForm />
        </Flex>
      </Col>
    </Row>
  );
};

export default LockScreen;
