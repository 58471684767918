import NavigationCard from 'components/common/NavigationCard';
import routes from 'config/routeSettings';
import React from 'react';
import { Row } from 'react-bootstrap';
import { artifactRoutes } from 'routes/routes.js';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';

/**
 * Renders the Endpoint page
 *
 * @returns {JSX.Element} The rendered Application page
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.2
 */
const EndpointPage = () => {
  const menuItems = artifactRoutes.children.find(
    route => route.to === routes.ARTIFACTS_ENDPOINT
  );

  return (
    <>
      <WidgetSectionTitle title='Endpoint Artifacts' className='mb-2' />
      <Row className='g-1 mb-1'>
        {menuItems.children.map((route, index) => (
          <NavigationCard route={route} key={index} index={index} />
        ))}
      </Row>
    </>
  );
};

export default EndpointPage;
