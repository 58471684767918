import { rgbaColor } from 'helpers/utils';
import { useCluster } from 'hooks/admin-contexts/useCluster';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useCountUp } from 'react-countup';
import ArchSplitLineChart from './ArchSplitLineChart';

const DataNodeCount = () => {
  const {
    cluster: {
      clusterNodesStats: { nodes }
    }
  } = useCluster();
  const [numberOfDataNodesCounter, setNumberOfDataNodesCounter] = useState(0);
  const [coldNodeCounter, setColdNodeCounter] = useState(0);
  const [warmNodeCounter, setWarmNodeCounter] = useState(0);
  const [hotNodeCounter, setHotNodeCounter] = useState(0);

  const getNodeChartData = () => {
    if (nodes) {
      const nodeList = Object(Object.keys(nodes).map(key => nodes[key]));
      const paths = 'attributes.temp'.split('.');
      let hotNodes = 0;
      let warmNodes = 0;
      let coldNodes = 0;
      nodeList.forEach(item => {
        const value = paths.reduce(
          (acc, cur) => (acc[cur] ? acc[cur] : ''),
          item
        );
        value === 'hot'
          ? hotNodes++
          : value === 'warm'
          ? warmNodes++
          : value === 'cold'
          ? coldNodes++
          : null;
      });
      setHotNodeCounter(hotNodes);
      setWarmNodeCounter(warmNodes);
      setColdNodeCounter(coldNodes);
    }
  };

  const memoizedCallback = useCallback(value => {
    return value.toLocaleString();
  }, []);

  const { update } = useCountUp({
    ref: 'numberOfDataNodesCounter',
    decimals: 0,
    duration: 2.75,
    end: numberOfDataNodesCounter || 0,
    formattingFn: memoizedCallback,
    preserveValue: true
  });

  useEffect(() => {
    if (nodes) {
      setNumberOfDataNodesCounter(
        Object.keys(nodes).filter(key =>
          ['hot', 'warm', 'cold'].includes(nodes[key].attributes?.temp)
        ).length
      );
      update(
        Object.keys(nodes).filter(key =>
          ['hot', 'warm', 'cold'].includes(nodes[key].attributes?.temp)
        ).length
      );
      getNodeChartData();
    }
  }, [nodes]);

  return (
    <Card className='h-100 shadow-none border'>
      {!nodes ? (
        <div className='position-absolute d-flex justify-content-center align-items-center h-100 w-100 navbar-glass-loader'>
          <Spinner animation='border' />
        </div>
      ) : null}
      <Card.Body className='p-2'>
        <Row className='g-0'>
          <Col
            xs={5}
            className='d-flex flex-column h-100 justify-content-between'>
            <h6 className='mb-2 text-900 text-nowrap'>Data Nodes</h6>
            <h6 className='fs-2 fw-normal mb-0 text-nowrap'>
              <span id='numberOfDataNodesCounter' className='font-monospace' />
            </h6>
            <span></span>
          </Col>
          <Col xs='7' className='h-100'>
            <ArchSplitLineChart
              data={{
                labels: ['Hot', 'Warm', 'Cold'],
                values: [hotNodeCounter, warmNodeCounter, coldNodeCounter],
                backgroundColors: [
                  rgbaColor('#FF6384', 0.8),
                  rgbaColor('#FF9F40', 0.8),
                  rgbaColor('#36A2EB', 0.8),
                  rgbaColor('#91CC75', 0.8)
                ],
                borderColors: [
                  rgbaColor('#FF6384', 0),
                  rgbaColor('#FF9F40', 0),
                  rgbaColor('#36A2EB', 0),
                  rgbaColor('#91CC75', 0)
                ]
              }}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DataNodeCount;
