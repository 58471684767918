import React from 'react';
import Flex from 'components/common/Flex';
import RegisterForm from './RegisterForm';
import { Link } from 'react-router-dom';
import { routes } from 'config/routeSettings';

/**
 * Account registration page
 *
 * The account registration page is used to display a form to the user to register their account.
 * @see MFASetupForm
 * @returns {React.Component} The account registration page
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const Register = () => (
  <>
    <Flex justifyContent='between' alignItems='center' className='mb-2'>
      <h5>Register</h5>
      <p className='fs--1 text-600 mb-0'>
        Have an account? <Link to={routes.LOGIN}>Login</Link>
      </p>
    </Flex>
    <RegisterForm />
  </>
);

export default Register;
