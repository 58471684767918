import LeargasAPI from 'api';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

const PDFDownloader = ({ id, setReports }) => {
  const { axiosPrivate } = useAxiosPrivate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await LeargasAPI.AppReports.getReportFile(id, axiosPrivate);
        if (res instanceof Error) throw res;
        const blobUrl = URL.createObjectURL(
          new Blob([res?.data], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = blobUrl;

        // file name extraction
        const contentDisposition = res.headers['content-disposition'];
        const match = contentDisposition.match(/filename=(.*)/);
        let filename = '';
        if (match && match[1]) {
          filename = match[1];
        }
        link.download = filename;
        link.click();
        URL.revokeObjectURL(blobUrl);
        setReports(prevState => ({
          ...prevState,
          downloadReportModal: { open: false, data: {}, fullScreen: false }
        }));
      } catch (error) {
        if (error.message.includes('Network Error')) {
          console.error('Network Error occurred.');
        }
        toast.error('File not found!');
        setReports(prevState => ({
          ...prevState,
          downloadReportModal: { open: false, data: {}, fullScreen: false }
        }));
      }
    };

    fetchData();
  }, [id]);

  return (
    <div>
      {/* {isLoading && <p>Downloading...</p>} */}
      {/* {error && <p>Error: {error}</p>} */}
    </div>
  );
};

PDFDownloader.propTypes = {
  id: PropTypes.string.isRequired,
  setReports: PropTypes.func.isRequired
};

export default PDFDownloader;
