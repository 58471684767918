import { routes } from 'config/routeSettings';

/**
 * The artifact routes for searching and exploring data.
 *
 * @type {Object}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.1
 */
export const artifactRoutes = {
  active: true,
  allowedRoles: ['super-admin', 'admin', 'user'],
  description:
    'Monitor your artifacts from various sources, including cloud integrations, network traffic, and endpoints.',
  development: false,
  icon: 'scroll',
  iconCollection: 'fas',
  imageDark: '',
  imageLight: '',
  label: 'Artifacts',
  labelDisable: false,
  itemDisable: false,
  name: 'Artifacts',
  pageTitle: 'Artifacts',
  to: routes.ARTIFACTS,
  children: [
    {
      active: true,
      allowedRoles: ['super-admin', 'admin', 'user'],
      description:
        'Explore all your artifacts, including ones produced by our network appliances, cloud integration, and more.',
      development: false,
      icon: 'binoculars',
      iconCollection: 'fas',
      imageDark: '/assets/img/illustrations/exploreDark.png',
      imageLight: '/assets/img/illustrations/exploreLight.png',
      label: 'Explore',
      labelDisable: true,
      itemDisable: false,
      name: 'Explore',
      pageTitle: 'Explore',
      to: routes.ARTIFACTS_EXPLORE
    },
    {
      active: true,
      allowedRoles: ['super-admin', 'admin', 'user'],
      description:
        'Explore all your cloud artifacts, including AWS, Azure, Box, Duo, Google, Meraki, Office 365, and Okta.',
      development: false,
      icon: 'cloud-upload-alt',
      iconCollection: 'fas',
      imageDark: '/assets/img/illustrations/nodesDark.png',
      imageLight: '/assets/img/illustrations/nodesLight.png',
      label: 'Cloud',
      labelDisable: true,
      name: 'Cloud',
      pageTitle: 'Cloud',
      to: routes.ARTIFACTS_CLOUD,
      children: [
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          // badge: {
          //   type: 'pastel-coral-light',
          //   text: 'New'
          // },
          description:
            'View and manage your AWS artifacts, including CloudTrail, CloudWatch, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/awsDark.png',
          imageLight: '/assets/img/illustrations/awsLight.png',
          label: 'AWS',
          labelDisable: true,
          itemDisable: false,
          name: 'AWS',
          pageTitle: 'AWS',
          to: routes.ARTIFACTS_CLOUD_AWS,
          children: [
            {
              active: true,
              allowedRoles: ['super-admin', 'admin', 'user'],
              description: 'View and manage your AWS CloudTrail artifacts',
              development: false,
              icon: '',
              iconCollection: '',
              imageDark: '/assets/img/illustrations/cloudtrail.png',
              imageLight: '/assets/img/illustrations/cloudtrail.png',
              label: 'CloudTrail',
              labelDisable: true,
              itemDisable: false,
              name: 'CloudTrail',
              pageTitle: 'CloudTrail',
              to: routes.ARTIFACTS_CLOUD_AWS_CLOUDTRAIL
            }
          ]
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          // badge: {
          //   type: 'pastel-coral-light',
          //   text: 'New'
          // },
          description:
            'View and manage your Azure artifacts, including Activity Log, Monitor, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/azure.png',
          imageLight: '/assets/img/illustrations/azure.png',
          label: 'Azure',
          labelDisable: true,
          itemDisable: false,
          name: 'Azure',
          pageTitle: 'Azure',
          to: routes.ARTIFACTS_CLOUD_AZURE
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your Box artifacts, including files, folders, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/box.png',
          imageLight: '/assets/img/illustrations/box.png',
          label: 'Box',
          labelDisable: true,
          itemDisable: false,
          name: 'Box',
          pageTitle: 'Box',
          to: routes.ARTIFACTS_CLOUD_BOX
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your Duo artifacts, including authentication logs, devices, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/duo.png',
          imageLight: '/assets/img/illustrations/duo.png',
          label: 'Duo',
          labelDisable: true,
          itemDisable: false,
          name: 'Duo',
          pageTitle: 'Duo',
          to: routes.ARTIFACTS_CLOUD_DUO
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your Google artifacts, including Gmail, Drive, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/google.png',
          imageLight: '/assets/img/illustrations/google.png',
          label: 'Google',
          labelDisable: true,
          itemDisable: false,
          name: 'Google',
          pageTitle: 'Google',
          to: routes.ARTIFACTS_CLOUD_GOOGLE
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your Meraki artifacts, including devices, networks, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/meraki.png',
          imageLight: '/assets/img/illustrations/meraki.png',
          label: 'Meraki',
          labelDisable: true,
          itemDisable: false,
          name: 'Meraki',
          pageTitle: 'Meraki',
          to: routes.ARTIFACTS_CLOUD_MERAKI
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your Office 365 artifacts, including email, SharePoint, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/o365.png',
          imageLight: '/assets/img/illustrations/o365.png',
          label: 'Office 365',
          labelDisable: true,
          itemDisable: false,
          name: 'Office 365',
          pageTitle: 'Office 365',
          to: routes.ARTIFACTS_CLOUD_O365
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your Okta artifacts, including users, groups, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/okta.png',
          imageLight: '/assets/img/illustrations/okta.png',
          label: 'Okta',
          labelDisable: true,
          itemDisable: false,
          name: 'Okta',
          pageTitle: 'Okta',
          to: routes.ARTIFACTS_CLOUD_OKTA
        }
      ]
    },
    {
      active: true,
      allowedRoles: ['super-admin', 'admin', 'user'],
      description:
        'Explore all your Darkweb artifacts, including keywords, forum hits, shodan hits, Tor hits, credit card numbers, and more.',
      development: false,
      icon: 'comments-dollar',
      iconCollection: 'fas',
      imageDark: '/assets/img/illustrations/darkwebDark.png',
      imageLight: '/assets/img/illustrations/darkwebLight.png',
      label: 'Darkweb',
      labelDisable: true,
      name: 'Darkweb',
      pageTitle: 'Darkweb',
      to: routes.ARTIFACTS_DARKWEB
    },
    {
      active: true,
      allowedRoles: ['super-admin', 'admin', 'user'],
      description:
        'Explore all your endpoint artifacts, including SentinelOne agents and threats, Windows event logs, Syslog, and more.',
      development: false,
      icon: 'computer',
      iconCollection: 'fas',
      imageDark: '/assets/img/illustrations/endpointDark.png',
      imageLight: '/assets/img/illustrations/endpointLight.png',
      label: 'Endpoint',
      labelDisable: true,
      name: 'Endpoint',
      pageTitle: 'Endpoint',
      to: routes.ARTIFACTS_ENDPOINT,
      children: [
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your Crowdstrike artifacts, including detections, incidents, and more.',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Crowdstrike',
          labelDisable: true,
          itemDisable: false,
          name: 'Crowdstrike',
          pageTitle: 'Crowdstrike',
          to: routes.ARTIFACTS_ENDPOINT_CROWDSTRIKE
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your SentinelOne artifacts, including threats, agents, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/sentinelOneNew.png',
          imageLight: '/assets/img/illustrations/sentinelOneNew.png',
          label: 'SentinelOne',
          labelDisable: true,
          itemDisable: false,
          name: 'SentinelOne',
          pageTitle: 'SentinelOne',
          to: routes.ARTIFACTS_ENDPOINT_SENTINELONE,
          children: [
            {
              active: true,
              allowedRoles: ['super-admin', 'admin', 'user'],
              description: 'View and manage your SentinelOne threat artifacts',
              development: false,
              icon: '',
              iconCollection: '',
              imageDark: '/assets/img/illustrations/sentinelOneAlert.png',
              imageLight: '/assets/img/illustrations/sentinelOneAlert.png',
              label: 'Threats',
              labelDisable: true,
              itemDisable: false,
              name: 'Threats',
              pageTitle: 'Threats',
              to: routes.ARTIFACTS_ENDPOINT_SENTINELONE_THREATS
            },
            {
              active: true,
              allowedRoles: ['super-admin', 'admin', 'user'],
              description: 'View and manage your SentinelOne agent artifacts',
              development: false,
              icon: '',
              iconCollection: '',
              imageDark: '/assets/img/illustrations/sentinelOneAgent.png',
              imageLight: '/assets/img/illustrations/sentinelOneAgent.png',
              label: 'Agents',
              labelDisable: true,
              itemDisable: false,
              name: 'Agents',
              pageTitle: 'Agents',
              to: routes.ARTIFACTS_ENDPOINT_SENTINELONE_AGENTS
            }
          ]
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your Windows artifacts, including logs, events, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/windows.png',
          imageLight: '/assets/img/illustrations/windows.png',
          label: 'Windows',
          labelDisable: true,
          itemDisable: false,
          name: 'Windows',
          pageTitle: 'Windows',
          to: routes.ARTIFACTS_ENDPOINT_WINDOWS
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your Syslog artifacts, including logs, events, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/syslog.png',
          imageLight: '/assets/img/illustrations/syslog.png',
          label: 'Syslog',
          labelDisable: true,
          itemDisable: false,
          name: 'Syslog',
          pageTitle: 'Syslog',
          to: routes.ARTIFACTS_ENDPOINT_SYSLOG
        }
      ]
    },
    {
      active: true,
      allowedRoles: ['super-admin', 'admin', 'user'],
      description:
        'Explore all your network artifacts, including behaviors, signatures, threat intel hits, SMB, RPC, ICS, and file extraction',
      development: false,
      icon: 'sitemap',
      iconCollection: 'fas',
      imageDark: '/assets/img/illustrations/networkDark.png',
      imageLight: '/assets/img/illustrations/networkLight.png',
      label: 'Network',
      labelDisable: true,
      name: 'Network',
      pageTitle: 'Network',
      to: routes.ARTIFACTS_NETWORK,
      children: [
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your network behaviors, including DNS, HTTP, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Behaviors',
          labelDisable: true,
          itemDisable: false,
          name: 'Behaviors',
          pageTitle: 'Behaviors',
          to: routes.ARTIFACTS_NETWORK_BEHAVIORS
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your network signatures, including Snort, Suricata, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Signatures',
          labelDisable: true,
          itemDisable: false,
          name: 'Signatures',
          pageTitle: 'Signatures',
          to: routes.ARTIFACTS_NETWORK_SIGNATURES
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your network threat intelligence artifacts',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Threat Intel',
          labelDisable: true,
          itemDisable: false,
          name: 'Threat Intel',
          pageTitle: 'Threat Intel',
          to: routes.ARTIFACTS_NETWORK_THREATINTEL
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your network SMB artifacts, including files, shares, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'SMB',
          labelDisable: true,
          itemDisable: false,
          name: 'SMB',
          pageTitle: 'SMB',
          to: routes.ARTIFACTS_NETWORK_SMB
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your network RPC artifacts, including services, ports, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'RPC',
          labelDisable: true,
          itemDisable: false,
          name: 'RPC',
          pageTitle: 'RPC',
          to: routes.ARTIFACTS_NETWORK_RPC
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your network ICS artifacts, including devices, protocols, and more.',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'ICS',
          labelDisable: true,
          itemDisable: false,
          name: 'ICS',
          pageTitle: 'ICS',
          to: routes.ARTIFACTS_NETWORK_ICS
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description: 'View and manage your network file extraction artifacts',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'File Extraction',
          labelDisable: true,
          itemDisable: false,
          name: 'File Extraction',
          pageTitle: 'File Extraction',
          to: routes.ARTIFACTS_NETWORK_FILEEXTRACTION
        }
      ]
    },
    {
      active: true,
      allowedRoles: ['super-admin', 'admin', 'user'],
      description:
        'View and manage your physical artifacts, including cameras, doors, and more.',
      development: true,
      icon: 'building',
      iconCollection: 'fas',
      imageDark: '',
      imageLight: '',
      label: 'Physical',
      labelDisable: true,
      name: 'Physical',
      pageTitle: 'Physical',
      to: routes.ARTIFACTS_PHYSICAL
    },
    {
      active: true,
      allowedRoles: ['super-admin', 'admin', 'user'],
      description:
        'View and manage your vulnerability artifacts, including Nessus, OpenVAS, Qualys, and more.',
      development: true,
      icon: 'bug',
      iconCollection: 'fas',
      imageDark: '',
      imageLight: '',
      label: 'Vulnerabilities',
      labelDisable: true,
      name: 'Vulnerabilities',
      pageTitle: 'Vulnerabilities',
      to: routes.ARTIFACTS_VULNS,
      children: [
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your Nessus vulnerability artifacts, including scans, reports, and more.',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Nessus',
          labelDisable: true,
          itemDisable: false,
          name: 'Nessus',
          pageTitle: 'Nessus',
          to: routes.ARTIFACTS_VULNS_NESSUS
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your OpenVAS vulnerability artifacts, including scans, reports, and more.',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'OpenVAS',
          labelDisable: true,
          itemDisable: false,
          name: 'OpenVAS',
          pageTitle: 'OpenVAS',
          to: routes.ARTIFACTS_VULNS_OPENVAS
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description:
            'View and manage your Qualys vulnerability artifacts, including scans, reports, and more.',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Qualys',
          labelDisable: true,
          itemDisable: false,
          name: 'Qualys',
          pageTitle: 'Qualys',
          to: routes.ARTIFACTS_VULNS_QUALYS
        }
      ]
    }
  ]
};

/**
 * The resources routes for reading news and updates.
 *
 * @type {Object}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.1
 */
export const resourceRoutes = {
  active: true,
  allowedRoles: ['super-admin', 'admin', 'user'],
  description:
    'Read the latest news and updates that are relevant to you and your organization',
  development: false,
  icon: 'readme',
  iconCollection: 'fab',
  imageDark: '',
  imageLight: '',
  label: 'Resources',
  labelDisable: false,
  itemDisable: false,
  name: 'Resources',
  pageTitle: 'Resources',
  to: routes.RESOURCES,
  children: [
    {
      active: true,
      allowedRoles: ['super-admin', 'admin', 'user'],
      description:
        'Read the latest news and updates that are relevant to you and your organization',
      development: true,
      icon: 'newspaper',
      iconCollection: 'fas',
      imageDark: '',
      imageLight: '',
      label: 'News & Updates',
      labelDisable: true,
      itemDisable: false,
      name: 'News & Updates',
      pageTitle: 'News & Updates',
      to: routes.RESOURCES_NEWS_UPDATES
    },
    {
      active: true,
      allowedRoles: ['super-admin', 'admin', 'user'],
      description:
        'Review the latest changes and updates to the Léargas application and its features',
      development: false,
      icon: 'file-invoice',
      iconCollection: 'fas',
      imageDark: '/assets/img/illustrations/darkwebDark.png',
      imageLight: '/assets/img/illustrations/darkwebLight.png',
      label: 'Change Log',
      labelDisable: true,
      itemDisable: false,
      name: 'Change Log',
      pageTitle: 'Change Log',
      to: routes.RESOURCES_CHANGE_LOG
    },
    {
      active: true,
      allowedRoles: ['super-admin', 'admin', 'user'],
      description:
        'Report an issue or bug to the development team for review and resolution',
      development: true,
      icon: 'triangle-exclamation',
      iconCollection: 'fas',
      imageDark: '',
      imageLight: '',
      label: 'Report An Issue',
      labelDisable: true,
      itemDisable: false,
      name: 'Report An Issue',
      pageTitle: 'Report An Issue',
      to: routes.RESOURCES_BOOKMARKS
    }
  ]
};

/**
 * The system routes for managing the application.
 *
 * @type {Object}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.1
 */
export const systemRoutes = {
  active: true,
  allowedRoles: ['super-admin', 'admin'],
  description:
    'View and manage the different entities and resources that make up the application',
  development: false,
  icon: 'gears',
  iconCollection: 'fas',
  imageDark: '',
  imageLight: '',
  label: 'System',
  labelDisable: false,
  itemDisable: false,
  name: 'System',
  pageTitle: 'System',
  to: routes.SYSTEM,
  children: [
    {
      active: true,
      allowedRoles: ['super-admin', 'admin'],
      description:
        'View and manage jobs, organizations, releases, reports, saved searches, users, and more.',
      development: false,
      icon: 'tablet-screen-button',
      iconCollection: 'fas',
      imageDark: '/assets/img/illustrations/applicationDark.png',
      imageLight: '/assets/img/illustrations/applicationLight.png',
      label: 'Application',
      labelDisable: true,
      itemDisable: false,
      name: 'Application',
      pageTitle: 'Application Management',
      to: routes.SYSTEM_APP,
      children: [
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description: 'Manage rules and settings around alerting',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Alerts',
          labelDisable: true,
          itemDisable: false,
          name: 'Alerts',
          pageTitle: 'Alerts',
          to: routes.SYSTEM_APP_MANAGE_ALERTS
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description: 'Manage resource articles, categories and tags',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Articles',
          labelDisable: true,
          itemDisable: false,
          name: 'Articles',
          pageTitle: 'Articles',
          to: routes.SYSTEM_APP_MANAGE_ARTICLES,
          children: [
            {
              active: true,
              allowedRoles: ['super-admin', 'admin'],
              description: 'Manage article categories and category settings',
              development: false,
              icon: '',
              iconCollection: '',
              imageDark: '',
              imageLight: '',
              label: 'Categories',
              labelDisable: true,
              itemDisable: false,
              name: 'Categories',
              pageTitle: 'Categories',
              to: routes.SYSTEM_APP_MANAGE_ARTICLES_CATEGORIES
            },
            {
              active: true,
              allowedRoles: ['super-admin', 'admin'],
              description: 'Manage article tags and tag settings',
              development: false,
              icon: '',
              iconCollection: '',
              imageDark: '',
              imageLight: '',
              label: 'Tags',
              labelDisable: true,
              itemDisable: false,
              name: 'Tags',
              pageTitle: 'Tags',
              to: routes.SYSTEM_APP_MANAGE_ARTICLES_TAGS
            }
          ]
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description: 'Manage threat detections and detection rules',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Detections',
          labelDisable: true,
          itemDisable: false,
          name: 'Detections',
          pageTitle: 'Detections',
          to: routes.SYSTEM_APP_MANAGE_DETECTIONS
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description: 'Manage application jobs and scheduled tasks',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/jobsDark.png',
          imageLight: '/assets/img/illustrations/jobsLight.png',
          label: 'Jobs',
          labelDisable: true,
          itemDisable: false,
          name: 'Jobs',
          pageTitle: 'Jobs',
          to: routes.SYSTEM_APP_MANAGE_JOBS
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description: 'Manage organizations details and settings',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/organizationsDark.png',
          imageLight: '/assets/img/illustrations/organizationsLight.png',
          label: 'Organizations',
          labelDisable: true,
          itemDisable: false,
          name: 'Organizations',
          pageTitle: 'Organizations',
          to: routes.SYSTEM_APP_MANAGE_ORGS
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description: 'Manage appliances, products and instances',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Products',
          labelDisable: true,
          itemDisable: false,
          name: 'Products',
          pageTitle: 'Products',
          to: routes.SYSTEM_APP_MANAGE_PRODUCTS,
          children: [
            {
              active: true,
              allowedRoles: ['super-admin', 'admin'],
              description: 'View and manage appliances and appliance settings',
              development: false,
              icon: '',
              iconCollection: '',
              imageDark: '',
              imageLight: '',
              label: 'Appliances',
              labelDisable: true,
              itemDisable: false,
              name: 'Appliances',
              pageTitle: 'Appliances',
              to: routes.SYSTEM_APP_MANAGE_PRODUCTS_APPLIANCES
            },
            {
              active: true,
              allowedRoles: ['super-admin', 'admin'],
              description: 'View and manage product instances',
              development: true,
              icon: '',
              iconCollection: '',
              imageDark: '',
              imageLight: '',
              label: 'Instances',
              labelDisable: true,
              itemDisable: false,
              name: 'Instances',
              pageTitle: 'Instances',
              to: routes.SYSTEM_APP_MANAGE_PRODUCTS_INSTANCES
            }
          ]
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description: 'Manage application releases and release notes',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/releasesDark.png',
          imageLight: '/assets/img/illustrations/releasesLight.png',
          label: 'Releases',
          labelDisable: true,
          itemDisable: false,
          name: 'Releases',
          pageTitle: 'Releases',
          to: routes.SYSTEM_APP_MANAGE_RELEASES
        },
        // {
        //   active: true,
        //   allowedRoles: ['super-admin', 'admin'],
        //   description: 'View and manage reports and report templates',
        //   development: false,
        //   icon: '',
        //   iconCollection: '',
        //   imageDark: '/assets/img/illustrations/reportsDark.png',
        //   imageLight: '/assets/img/illustrations/reportsLight.png',
        //   label: 'Reports',
        //   labelDisable: true,
        //   itemDisable: false,
        //   name: 'Reports',
        //   pageTitle: 'Reports',
        //   to: routes.SYSTEM_APP_MANAGE_REPORTS
        // },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description: 'Manage application user roles and role settings',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/rolesDark.png',
          imageLight: '/assets/img/illustrations/rolesLight.png',
          label: 'Roles',
          labelDisable: true,
          itemDisable: false,
          name: 'Roles',
          pageTitle: 'Roles',
          to: routes.SYSTEM_APP_MANAGE_ROLES
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description: 'View and manage saved searches for all users',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/savedSearchesDark.png',
          imageLight: '/assets/img/illustrations/savedSearchesLight.png',
          label: 'Saved Searches',
          labelDisable: true,
          itemDisable: false,
          name: 'Saved Searches',
          pageTitle: 'Saved Searches',
          to: routes.SYSTEM_APP_MANAGE_SAVED_SEARCHES
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description: 'Manage security settings, resources and permissions',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Security',
          labelDisable: true,
          itemDisable: false,
          name: 'Security',
          pageTitle: 'Security',
          to: routes.SYSTEM_APP_SECURITY,
          children: [
            {
              active: true,
              allowedRoles: ['super-admin', 'admin'],
              description: 'Manage permissions for roles, users and teams',
              development: true,
              icon: '',
              iconCollection: '',
              imageDark: '/assets/img/illustrations/scopesDark.png',
              imageLight: '/assets/img/illustrations/scopesLight.png',
              label: 'Permissions',
              labelDisable: true,
              itemDisable: false,
              name: 'Permissions',
              pageTitle: 'Permissions',
              to: routes.SYSTEM_APP_SECURITY_PERMISSIONS
            },
            {
              active: true,
              allowedRoles: ['super-admin', 'admin'],
              description: 'Manage application resource types and settings',
              development: true,
              icon: '',
              iconCollection: '',
              imageDark: '/assets/img/illustrations/resourcesDark.png',
              imageLight: '/assets/img/illustrations/resourcesLight.png',
              label: 'Resources',
              labelDisable: true,
              itemDisable: false,
              name: 'Resources',
              pageTitle: 'Resources',
              to: routes.SYSTEM_APP_SECURITY_RESOURCES
            }
          ]
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description: 'Manage settings for data enrichment and display',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/applicationDark.png',
          imageLight: '/assets/img/illustrations/applicationLight.png',
          label: 'Settings',
          labelDisable: true,
          itemDisable: false,
          name: 'Settings',
          pageTitle: 'Settings',
          to: routes.SYSTEM_APP_SETTINGS,
          children: [
            {
              active: true,
              allowedRoles: ['super-admin', 'admin'],
              description: 'Manage fields and field settings',
              development: false,
              icon: '',
              iconCollection: '',
              imageDark: '/assets/img/illustrations/fieldsDark.png',
              imageLight: '/assets/img/illustrations/fieldsLight.png',
              label: 'Fields',
              labelDisable: true,
              itemDisable: false,
              name: 'Fields',
              pageTitle: 'Fields',
              to: routes.SYSTEM_APP_SETTINGS_DATA_FIELDS
            },
            {
              active: true,
              allowedRoles: ['super-admin', 'admin'],
              description: 'Manage indices and index settings',
              development: false,
              icon: '',
              iconCollection: '',
              imageDark: '/assets/img/illustrations/indicesDark.png',
              imageLight: '/assets/img/illustrations/indicesLight.png',
              label: 'Indices',
              labelDisable: true,
              itemDisable: false,
              name: 'Indices',
              pageTitle: 'Indices',
              to: routes.SYSTEM_APP_SETTINGS_DATA_INDICES
            },
            {
              active: true,
              allowedRoles: ['super-admin', 'admin'],
              description:
                'Manage application display settings, including dashboards, menus, routes and visualizations',
              development: true,
              icon: '',
              iconCollection: '',
              imageDark: '/assets/img/illustrations/displayDark.png',
              imageLight: '/assets/img/illustrations/displayLight.png',
              label: 'Display',
              labelDisable: true,
              itemDisable: false,
              name: 'Display',
              pageTitle: 'Display',
              to: routes.SYSTEM_APP_SETTINGS_DISPLAY,
              children: [
                {
                  active: true,
                  allowedRoles: ['super-admin', 'admin'],
                  description: 'Manage dashboards and layouts',
                  development: true,
                  icon: '',
                  iconCollection: '',
                  imageDark: '/assets/img/illustrations/dashboardsDark.png',
                  imageLight: '/assets/img/illustrations/dashboardsLight.png',
                  label: 'Dashboards',
                  labelDisable: true,
                  itemDisable: false,
                  name: 'Dashboards',
                  pageTitle: 'Dashboards',
                  to: routes.SYSTEM_APP_SETTINGS_DISPLAY_DASHBOARDS
                },
                {
                  active: true,
                  allowedRoles: ['super-admin', 'admin'],
                  description: 'Manage menus and navigation',
                  development: true,
                  icon: '',
                  iconCollection: '',
                  imageDark: '/assets/img/illustrations/menusDark.png',
                  imageLight: '/assets/img/illustrations/menusLight.png',
                  label: 'Menus',
                  labelDisable: true,
                  itemDisable: false,
                  name: 'Menus',
                  pageTitle: 'Menus',
                  to: routes.SYSTEM_APP_SETTINGS_DISPLAY_MENUS
                },
                {
                  active: true,
                  allowedRoles: ['super-admin', 'admin'],
                  description: 'Manage routes and route settings',
                  development: true,
                  icon: '',
                  iconCollection: '',
                  imageDark: '/assets/img/illustrations/routesDark.png',
                  imageLight: '/assets/img/illustrations/routesLight.png',
                  label: 'Routes',
                  labelDisable: true,
                  itemDisable: false,
                  name: 'Routes',
                  pageTitle: 'Routes',
                  to: routes.SYSTEM_APP_SETTINGS_DISPLAY_ROUTES
                },
                {
                  active: true,
                  allowedRoles: ['super-admin', 'admin'],
                  description:
                    'Manage visualizations and visualization settings',
                  development: true,
                  icon: '',
                  iconCollection: '',
                  imageDark: '/assets/img/illustrations/visualizationsDark.png',
                  imageLight:
                    '/assets/img/illustrations/visualizationsLight.png',
                  label: 'Visualizations',
                  labelDisable: true,
                  itemDisable: false,
                  name: 'Visualizations',
                  pageTitle: 'Visualizations',
                  to: routes.SYSTEM_APP_SETTINGS_DISPLAY_VISUALIZATIONS
                }
              ]
            },
            {
              active: true,
              allowedRoles: ['super-admin', 'admin'],
              description:
                'Manage general application settings, including email recipients, privacy, and general security settings',
              development: true,
              icon: '',
              iconCollection: '',
              imageDark: '/assets/img/illustrations/settingsDark.png',
              imageLight: '/assets/img/illustrations/settingsLight.png',
              label: 'General',
              labelDisable: true,
              itemDisable: false,
              name: 'General',
              pageTitle: 'General',
              to: routes.SYSTEM_APP_SETTINGS_GENERAL
            },
            {
              active: true,
              allowedRoles: ['super-admin', 'admin'],
              description:
                'Manage privacy settings, including privacy mode behavior and privacy policy information',
              development: true,
              icon: '',
              iconCollection: '',
              imageDark: '/assets/img/illustrations/privacyDark.png',
              imageLight: '/assets/img/illustrations/privacyLight.png',
              label: 'Privacy',
              labelDisable: true,
              itemDisable: false,
              name: 'Privacy',
              pageTitle: 'Privacy',
              to: routes.SYSTEM_APP_SETTINGS_PRIVACY
            }
          ]
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description: 'Manage subscriptions and subscriptions levels',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/subscriptionsDark.png',
          imageLight: '/assets/img/illustrations/subscriptionsLight.png',
          label: 'Subscriptions',
          labelDisable: true,
          itemDisable: false,
          name: 'Subscriptions',
          pageTitle: 'Subscriptions',
          to: routes.SYSTEM_APP_MANAGE_SUBS,
          children: [
            {
              active: true,
              allowedRoles: ['super-admin', 'admin'],
              description: 'View and manage organization subscription levels',
              development: false,
              icon: '',
              iconCollection: '',
              imageDark: '',
              imageLight: '',
              label: 'Levels',
              labelDisable: true,
              itemDisable: false,
              name: 'Levels',
              pageTitle: 'Levels',
              to: routes.SYSTEM_APP_MANAGE_SUBS_LEVELS
            }
          ]
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description: 'Manage user accounts and user access settings',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/usersDark.png',
          imageLight: '/assets/img/illustrations/usersLight.png',
          label: 'Users',
          labelDisable: true,
          itemDisable: false,
          name: 'Users',
          pageTitle: 'Users',
          to: routes.SYSTEM_APP_MANAGE_USERS
        }
      ]
    },
    {
      active: true,
      allowedRoles: ['super-admin', 'admin'],
      description:
        'View and manage the search cluster health, nodes, and indices',
      development: false,
      icon: 'circle-nodes',
      iconCollection: 'fas',
      imageDark: '/assets/img/illustrations/searchClusterDark.png',
      imageLight: '/assets/img/illustrations/searchClusterLight.png',
      label: 'Search Cluster',
      labelDisable: true,
      itemDisable: false,
      name: 'Search Cluster',
      pageTitle: 'Search Cluster',
      to: routes.SYSTEM_TOOLS_ES,
      children: [
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description:
            'Review audit logs and other audit log-related information for the Elasticsearch cluster',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Audit Logs',
          labelDisable: true,
          itemDisable: false,
          name: 'Audit Logs',
          pageTitle: 'Audit Logs',
          to: routes.SYSTEM_TOOLS_ES_AUDIT_LOGS
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description:
            'Review cluster health and get an overview of the search cluster resource usage at a glance',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/healthDark.png',
          imageLight: '/assets/img/illustrations/healthLight.png',
          label: 'Health Overview',
          labelDisable: true,
          itemDisable: false,
          name: 'Health Overview',
          pageTitle: 'Health Overview',
          to: routes.SYSTEM_TOOLS_ES_HEALTH
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description:
            'Review cluster indices and other index-related information for the Elasticsearch cluster',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Indices',
          labelDisable: true,
          itemDisable: false,
          name: 'Indices',
          pageTitle: 'Indices',
          to: routes.SYSTEM_TOOLS_ES_INDICES
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description:
            'Review cluster nodes, their health, resources and other node-related information',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/nodesDark.png',
          imageLight: '/assets/img/illustrations/nodesLight.png',
          label: 'Nodes',
          labelDisable: true,
          itemDisable: false,
          name: 'Nodes',
          pageTitle: 'Nodes',
          to: routes.SYSTEM_TOOLS_ES_NODES
        }
      ]
    },
    {
      active: true,
      allowedRoles: ['super-admin', 'admin'],
      description:
        'View and manage the MongoDB database, including collections, health, and users',
      development: true,
      icon: 'database',
      iconCollection: 'fas',
      imageDark: '',
      imageLight: '',
      label: 'Database Cluster',
      labelDisable: true,
      itemDisable: false,
      name: 'Database Cluster',
      pageTitle: 'Database Cluster',
      to: routes.SYSTEM_TOOLS_DB,
      children: [
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description:
            'Review audit logs and other audit log-related information for the MongoDB database',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Audit Logs',
          labelDisable: true,
          itemDisable: false,
          name: 'Audit Logs',
          pageTitle: 'Audit Logs',
          to: routes.SYSTEM_TOOLS_DB_AUDIT_LOGS
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description:
            'Review collections and other collection-related information for the MongoDB database',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Collections',
          labelDisable: true,
          itemDisable: false,
          name: 'Collections',
          pageTitle: 'Collections',
          to: routes.SYSTEM_TOOLS_DB_COLLECTIONS
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description:
            'Review database health and get an overview of the MongoDB database resource usage at a glance',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Health',
          labelDisable: true,
          itemDisable: false,
          name: 'Health',
          pageTitle: 'Health',
          to: routes.SYSTEM_TOOLS_DB_HEALTH
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description:
            'Review database nodes, their health, resources and other node-related information',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Nodes',
          labelDisable: true,
          itemDisable: false,
          name: 'Nodes',
          pageTitle: 'Nodes',
          to: routes.SYSTEM_TOOLS_DB_NODES
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description: 'View and manage database users and user settings',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Users',
          labelDisable: true,
          itemDisable: false,
          name: 'Users',
          pageTitle: 'Users',
          to: routes.SYSTEM_TOOLS_DB_USERS
        }
      ]
    },
    {
      active: true,
      allowedRoles: ['super-admin', 'admin'],
      description:
        'Review and manage reporting, alerting, and other system workflow-related processes',
      development: false,
      icon: 'tools',
      iconCollection: 'fas',
      imageDark: '/assets/img/illustrations/toolsDark.png',
      imageLight: '/assets/img/illustrations/toolsLight.png',
      label: 'Tools',
      labelDisable: true,
      itemDisable: false,
      name: 'Tools',
      pageTitle: 'System Tools',
      to: routes.SYSTEM_TOOLS,
      children: [
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description:
            'Check the status of report types activated for an organization and manage the generation of reports',
          development: false,
          icon: '',
          iconCollection: '',
          imageDark: '/assets/img/illustrations/reportsDark.png',
          imageLight: '/assets/img/illustrations/reportsLight.png',
          label: 'Reporting',
          labelDisable: true,
          name: 'Reporting',
          pageTitle: 'Reporting Statuses',
          to: routes.SYSTEM_TOOLS_REPORTING,
          children: [
            {
              active: true,
              allowedRoles: ['super-admin', 'admin'],
              description:
                'Manage the generation of different report types for an organization',
              development: false,
              icon: '',
              iconCollection: '',
              imageDark: '/assets/img/illustrations/reportsDark.png',
              imageLight: '/assets/img/illustrations/reportsLight.png',
              label: 'Statuses',
              labelDisable: true,
              itemDisable: false,
              name: 'Statuses',
              pageTitle: 'Statuses',
              to: routes.SYSTEM_TOOLS_REPORTING_STATUSES
            }
          ]
        },
        {
          active: true,
          allowedRoles: ['super-admin', 'admin'],
          description:
            'Check the status of alert types activated for an organization and manage the generation of alerts',
          development: true,
          icon: '',
          iconCollection: '',
          imageDark: '',
          imageLight: '',
          label: 'Alerting',
          labelDisable: true,
          itemDisable: false,
          name: 'Alerting',
          pageTitle: 'Alerting',
          to: routes.SYSTEM_TOOLS_Alerting,
          children: [
            {
              active: true,
              allowedRoles: ['super-admin', 'admin'],
              description:
                'Manage the generation of different alert types for an organization',
              development: true,
              icon: '',
              iconCollection: '',
              imageDark: '/assets/img/illustrations/alertsDark.png',
              imageLight: '/assets/img/illustrations/alertsLight.png',
              label: 'Statuses',
              labelDisable: true,
              itemDisable: false,
              name: 'Statuses',
              pageTitle: 'Statuses',
              to: routes.SYSTEM_TOOLS_ALERTING_STATUSES
            }
          ]
        }
      ]
    }
  ]
};

/**
 * The account routes for the application.
 *
 * @type {Object}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.1
 */
export const accountRoutes = {
  active: true,
  allowedRoles: ['super-admin', 'admin', 'user'],
  development: false,
  description: 'View and manage your account settings and preferences',
  icon: 'user-circle',
  iconCollection: 'fas',
  imageDark: '',
  imageLight: '',
  label: 'Account',
  labelDisable: false,
  itemDisable: true,
  name: 'Account',
  pageTitle: 'Account',
  to: routes.ACCOUNT,
  children: [
    {
      active: true,
      allowedRoles: ['super-admin', 'admin', 'user'],
      description: 'View and manage your account settings and preferences',
      development: false,
      icon: 'cog',
      iconCollection: 'fas',
      imageDark: '',
      imageLight: '',
      label: 'Settings',
      labelDisable: false,
      itemDisable: true,
      name: 'Settings',
      pageTitle: 'Account Settings',
      to: routes.ACCOUNT_SETTINGS
    },
    {
      active: true,
      allowedRoles: ['super-admin', 'admin', 'user'],
      description: 'View your account profile and personal information',
      development: false,
      icon: 'address-card',
      iconCollection: 'fas',
      imageDark: '',
      imageLight: '',
      label: 'Profile',
      labelDisable: false,
      itemDisable: true,
      name: 'Profile',
      pageTitle: 'Account Profile',
      to: routes.ACCOUNT_PROFILE,
      children: [
        {
          active: true,
          allowedRoles: ['super-admin', 'admin', 'user'],
          description: 'Edit your account profile and personal information',
          development: false,
          icon: 'pencil-alt',
          iconCollection: 'fas',
          imageDark: '',
          imageLight: '',
          label: 'Edit Profile',
          labelDisable: false,
          itemDisable: true,
          name: 'Edit Profile',
          pageTitle: 'Edit Profile',
          to: routes.ACCOUNT_PROFILE_EDIT
        }
      ]
    }
  ]
};

/**
 * An array of all routes in the application.
 *
 * @type {Object}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const allRoutes = [
  {
    active: true,
    allowedRoles: ['super-admin', 'admin', 'user'],
    development: false,
    description: 'This is home',
    icon: 'clover',
    iconRotation: 45,
    iconGrow: 3,
    iconCollection: 'fas',
    imageDark: '',
    imageLight: '',
    label: '',
    labelDisable: true,
    itemDisable: true,
    breadcrumbDisable: false,
    name: '',
    pageTitle: 'Home',
    to: routes.HOME,
    children: [
      {
        active: true,
        allowedRoles: ['super-admin', 'admin', 'user'],
        development: false,
        description:
          "Get a quick overview of your organization's artifacts, threat intel hits, alerts, and more",
        icon: 'clover',
        iconRotation: 45,
        iconGrow: 3,
        iconCollection: 'fas',
        imageDark: '',
        imageLight: '',
        label: 'Overview',
        labelDisable: false,
        itemDisable: false,
        name: 'Overview',
        pageTitle: 'Overview',
        to: routes.OVERVIEW
      },
      artifactRoutes,
      {
        active: true,
        allowedRoles: ['super-admin', 'admin'],
        description:
          'Manage your appliances, appliance settings, and appliance instances',
        development: true,
        icon: 'hard-drive',
        iconCollection: 'fas',
        imageDark: '',
        imageLight: '',
        label: 'Appliances',
        labelDisable: true,
        itemDisable: false,
        name: 'Appliances',
        pageTitle: 'Appliances',
        to: routes.APPLIANCES
      },
      {
        active: true,
        allowedRoles: ['super-admin', 'admin', 'user'],
        description:
          'Manage your alerts and decide which detections you want to be notified about when they occur',
        development: true,
        icon: 'radiation',
        iconCollection: 'fas',
        imageDark: '',
        imageLight: '',
        label: 'Alerts',
        labelDisable: true,
        itemDisable: false,
        name: 'Alerts',
        pageTitle: 'Alerts',
        to: routes.ALERTS
      },
      {
        active: true,
        allowedRoles: ['super-admin', 'admin', 'user'],
        description:
          'View and manage your cases, case settings, and case templates',
        development: true,
        icon: 'briefcase',
        iconCollection: 'fas',
        imageDark: '',
        imageLight: '',
        label: 'Cases',
        labelDisable: true,
        itemDisable: false,
        name: 'Cases',
        pageTitle: 'Cases',
        to: routes.CASES
      },
      {
        active: true,
        allowedRoles: ['super-admin', 'admin', 'user'],
        description:
          'View and manage your incidents, incident settings, and incident templates',
        development: true,
        icon: 'exclamation-triangle',
        iconCollection: 'fas',
        imageDark: '',
        imageLight: '',
        label: 'Incidents',
        labelDisable: true,
        itemDisable: false,
        name: 'Incidents',
        pageTitle: 'Incidents',
        to: routes.INCIDENTS
      },
      {
        active: true,
        allowedRoles: ['super-admin', 'admin', 'user'],
        description:
          'View and manage your organizations and organization settings',
        development: false,
        icon: 'city',
        iconCollection: 'fas',
        imageDark: '/assets/img/illustrations/organizationsDark.png',
        imageLight: '/assets/img/illustrations/organizationsLight.png',
        label: 'Organizations',
        labelDisable: true,
        itemDisable: false,
        name: 'Organizations',
        pageTitle: 'Organizations',
        to: routes.ORGANIZATIONS
      },
      {
        active: true,
        allowedRoles: ['super-admin', 'admin', 'user'],
        description: 'View and manage your reports',
        development: false,
        icon: 'chart-column',
        iconCollection: 'fas',
        imageDark: '/assets/img/illustrations/reportsDark.png',
        imageLight: '/assets/img/illustrations/reportsLight.png',
        label: 'Reports',
        labelDisable: true,
        itemDisable: false,
        name: 'Reports',
        pageTitle: 'Reports',
        to: routes.REPORTS
      },
      {
        active: true,
        allowedRoles: ['super-admin', 'admin', 'user'],
        description:
          'View and manage your saved searches to simplify your data exploration',
        development: false,
        icon: 'floppy-disk',
        iconCollection: 'fas',
        iconRotation: 45,
        imageDark: '/assets/img/illustrations/savedSearchesDark.png',
        imageLight: '/assets/img/illustrations/savedSearchesLight.png',
        label: 'Saved Searches',
        labelDisable: true,
        itemDisable: false,
        name: 'Saved Searches',
        pageTitle: 'Saved Searches',
        to: routes.SAVED_SEARCHES
      },
      resourceRoutes,
      systemRoutes,
      accountRoutes
    ]
  }
];

export default allRoutes;
