import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ActivityLog from 'components/common/profile/UserProfile/ActivityLog';
import useUsers from 'hooks/admin-contexts/useUsers';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ViewRecentActivityModal = () => {
  let [activities, setActivities] = useState([]);
  const {
    users: { loading, viewRecentActivity },
    setUsers
  } = useUsers();

  const { axiosPrivate } = useAxiosPrivate();

  /**
   * Get recent activities
   */
  useEffect(() => {
    if (viewRecentActivity?.open) {
      setUsers(prevState => ({
        ...prevState,
        loading: true
      }));
      LeargasAPI.Activities.getActivities(viewRecentActivity?.id, axiosPrivate)
        .then(res => {
          let activities = res?.data?.data;
          setActivities(activities);
          setUsers(prevState => ({
            ...prevState,
            loading: false
          }));
        })
        .catch(error => {
          setUsers(prevState => ({
            ...prevState,
            loading: false
          }));
          if (error?.response?.data?.message) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error('Something went wrong!');
          }
        });
    }
  }, [viewRecentActivity]);

  useEffect(() => {
    return () => {
      setUsers(prevState => ({
        ...prevState,
        loading: false
      }));
    };
  }, []);

  return (
    <Modal
      aria-labelledby='viewUserModal'
      size='lg'
      backdrop='static'
      keyboard={false}
      show={viewRecentActivity?.open}
      onHide={() =>
        setUsers(prevState => ({
          ...prevState,
          viewRecentActivity: { open: false }
        }))
      }
      centered>
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Modal.Body className='p-2'>
          {!loading ? (
            <Row className='g-3 mb-3'>
              <Col lg={12}>
                <ActivityLog
                  className='mt-3'
                  activities={activities.slice(0, 5)}
                  idto={viewRecentActivity?.id}
                />
              </Col>
            </Row>
          ) : (
            <div className='d-flex justify-content-center align-items-center py-4'>
              <Spinner animation='border' variant='primary' />
            </div>
          )}
          <Flex justifyContent='end'>
            <Button
              variant='secondary'
              onClick={() =>
                setUsers(prevState => ({
                  ...prevState,
                  viewRecentActivity: { open: false }
                }))
              }>
              Close
            </Button>
          </Flex>
        </Modal.Body>
      </Flex>
    </Modal>
  );
};

export default ViewRecentActivityModal;
