/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { Button } from 'react-bootstrap';

/**
 * Renders the footer component for the FieldsTable
 *
 * @param {Object} props - The component props
 * @param {boolean} props.canPreviousPage - Indicates if previous page is available
 * @param {boolean} props.canNextPage - Indicates if next page is available
 * @param {Function} props.nextPage - Function to navigate to the next page
 * @param {Function} props.previousPage - Function to navigate to the previous page
 * @param {boolean} props.navButtons - Indicates if navigation buttons should be displayed
 * @param {string} props.className - Additional CSS class name for the component
 * @returns {JSX.Element} The rendered FieldsTableFooter component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const FieldsTableFooter = ({
  canPreviousPage,
  canNextPage,
  nextPage,
  previousPage,
  navButtons,
  className
}) => {
  return (
    <Flex
      className={classNames(
        className,
        'align-items-center justify-content-between'
      )}>
      <>
        {navButtons && (
          <Flex>
            <Button
              size='sm'
              variant={canPreviousPage ? 'primary' : 'light'}
              onClick={() => previousPage()}
              className={classNames({ disabled: !canPreviousPage })}>
              Previous
            </Button>
            <Button
              size='sm'
              variant={canNextPage ? 'primary' : 'light'}
              className={classNames('px-4 ms-2', {
                disabled: !canNextPage
              })}
              onClick={() => nextPage()}>
              Next
            </Button>
          </Flex>
        )}
      </>
    </Flex>
  );
};

FieldsTableFooter.propTypes = {
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  navButtons: PropTypes.bool,
  className: PropTypes.string
};

export default FieldsTableFooter;
