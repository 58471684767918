import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import AlertsWrapper from './AlertsWrapper';

const Reports = () => {
  return (
    <>
      <WidgetSectionTitle
        title='Alerts'
        transform='down-3'
        titleClassName='mb-2'
      />
      <AlertsWrapper />
    </>
  );
};

export default Reports;
