import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import routes from 'config/routeSettings';
import useIndices from 'hooks/admin-contexts/useIndices';
import React from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import useAxiosPrivate from 'hooks/useAxiosPrivate';

/**
 * Renders the IndicesLayout component
 *
 * @returns {JSX.Element} The rendered IndicesLayout component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const IndicesLayout = () => {
  const {
    indices: { data },
    setIndices,
    loading: indicesLoading
  } = useIndices();

  const { axiosPrivate } = useAxiosPrivate();

  /**
   * Updates the indices with the provided data
   * @param {Event} e - The event object
   * @param {FormData} [data=null] - The data to update the indices with. If not provided, the data will be extracted from the event target
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const doUpdate = (e, data = null) => {
    if (e) {
      e.preventDefault();
      if (!data) {
        data = new FormData(e.target);
        data = Object.fromEntries(data?.entries());
      }
    }
    LeargasAPI.Indices.updateIndices(data, axiosPrivate)
      .then(res => {
        if (res instanceof Error) throw res;
        setIndices(prevState => ({
          ...prevState,
          fetch: true
        }));
        toast.success('Indice updated successfully');
      })
      .catch(error => {
        if (error.message.includes('Network Error')) {
          console.error('Network Error occurred.');
        }
        toast.error('Indice update failed');
      });
  };

  return indicesLoading ? (
    <LoadingSpinner grow='10' />
  ) : (
    <>
      <Row className='g-2 mb-2'>
        {data &&
          data?.map((indice, index) => (
            <Col lg={6} xl={4} key={index}>
              <Card className='h-100 shadow-none border'>
                <Form onSubmit={doUpdate}>
                  <Form.Control
                    type='hidden'
                    size='sm'
                    name='_id'
                    value={indice?._id}
                  />

                  <Card.Body>
                    <Flex direction='row' justifyContent='between'>
                      <Card.Title className='fw-normal fs-0'>
                        <FontAwesomeIcon
                          icon={['fas', 'box-open']}
                          className='me-1 text-700'
                        />{' '}
                        {indice?.alias}
                      </Card.Title>

                      <Form.Group controlId='formGroupName'>
                        <Form.Check
                          inline
                          type='switch'
                          name='enabled'
                          value={indice?.enabled}
                          className='me-2'
                          checked={indice?.enabled}
                          onChange={e => {
                            doUpdate(e, {
                              _id: indice?._id,
                              pattern: indice?.pattern,
                              enabled: !indice?.enabled
                            });
                          }}
                        />
                        <Form.Label
                          className={`mb-0 ${
                            indice.enabled ? 'text-success' : 'text-secondary'
                          }`}>
                          {indice?.enabled ? 'Enabled' : 'Disabled'}
                        </Form.Label>
                      </Form.Group>
                    </Flex>

                    <Form.Group controlId='formGroupName'>
                      <Form.Label>
                        Pattern{' '}
                        <OverlayTrigger
                          placement='top'
                          overlay={
                            <Popover>
                              <Popover.Body className='text-center p-1 fs--1 text-700'>
                                Pattern is used when syncing the fields list for
                                an index. It can be a regular expression that
                                matches the desired index names.
                              </Popover.Body>
                            </Popover>
                          }>
                          <span>
                            <FontAwesomeIcon
                              icon={['fas', 'question-circle']}
                              className='text-secondary'
                              transform='shrink-2 right-1'
                            />
                          </span>
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        size='sm'
                        name='pattern'
                        placeholder={indice?.pattern}
                        autoComplete='off'
                        required
                      />
                    </Form.Group>

                    <Flex direction='row' justifyContent='between'>
                      <Button
                        variant='primary'
                        type='submit'
                        size='sm'
                        className='mt-3'>
                        Update
                      </Button>
                      <Button
                        as={Link}
                        to={`${routes.SYSTEM_APP_SETTINGS_DATA_FIELDS}?indice=${indice?.alias}`}
                        variant='outline-secondary'
                        type='button'
                        size='sm'
                        className='mt-3'>
                        Fields
                      </Button>
                    </Flex>
                  </Card.Body>
                </Form>
              </Card>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default IndicesLayout;
