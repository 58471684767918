import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useAlerts from 'hooks/admin-contexts/useAlerts';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Modal } from 'react-bootstrap';
import AlertsDeleteForm from '../forms/AlertsDeleteForm';
/**
 * alerts delete modal
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ModalAlertDelete = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    alerts: { deleteAlertModal },
    setAlerts
  } = useAlerts();
  const { data } = deleteAlertModal;

  // /**
  //  * alerts delete modal
  //  * @param {Event} e
  //  * @async
  //  * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
  //  * @version 0.1.0-beta.2
  //  * @since 0.1.0-beta.2
  //  */
  // const handleSubmit = async e => {
  //   e.preventDefault();
  //   let { data } = deleteAlertModal;

  //   try {
  //     setAlerts(prevState => ({
  //       ...prevState,
  //       loading: true
  //     }));
  //     let res = await LeargasAPI.AppAlerts.deleteAlert(data?._id, true);
  //     if (res instanceof Error) {
  //       throw res;
  //     }
  //     const { message } = res.data;
  //     toast.success(message);
  //     setAlerts(prevState => ({
  //       ...prevState,
  //       fetch: true,
  //       loading: false,
  //       deleteAlertModal: { open: false, data: {} }
  //     }));
  //   }  catch (error) {
  // if (error.message.includes('Network Error')) {
  //   console.error('Network Error occurred.');
  // }
  // console.error(err); //
  //     if (error?.response?.data?.message) {
  //       toast.error(error?.response?.data?.message);
  //     } else {
  //       toast.error('Something went wrong!');
  //     }
  //     setAlerts(prevState => ({
  //       ...prevState,
  //       fetch: true,
  //       deleteAlertModal: { open: false, data: {} },
  //       loading: false
  //     }));
  //   }
  // };

  return (
    <Modal
      centered
      size='lg'
      animation={false}
      backdrop='static'
      keyboard={false}
      show={deleteAlertModal?.open}
      onHide={() =>
        setAlerts(prevState => ({
          ...prevState,
          deleteAlertModal: { open: false, data: {} }
        }))
      }
      aria-labelledby='modalAlertAdd'>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Delete Alert: {data?.name}
          <AdvancedPopover
            containerId='resetConfirmModal'
            placement='top'
            popoverText=' Delete alert'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
      </Modal.Header>

      <Modal.Body className='p-2'>
        {' '}
        <AlertsDeleteForm />
      </Modal.Body>
    </Modal>
  );
};

export default ModalAlertDelete;
