import { routes } from 'config/routeSettings'; // ensure this is the correct path
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthentication } from './useAuthentication'; // ensure this is the correct path

/**
 * Custom hook to check user session and redirect if necessary.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
export const useSessionCheck = () => {
  const { authentication: { id } = {} } = useAuthentication();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // User is logged in
    if (id) {
      if (location.pathname === routes.LOGIN) {
        // If there's a previous path saved, go there, else navigate to HOME
        const prevPath = location.state?.prevPath || routes.OVERVIEW;
        navigate(prevPath, { replace: true });
      }
    } else {
      // User is not logged in and trying to access a protected route (not LOGIN, ACTIVATE, FORGOT_PASSWORD, CONFIRM_MAIL, or RESET_PASSWORD)
      if (
        location.pathname !== routes.LOGIN &&
        location.pathname !== routes.ACTIVATE &&
        location.pathname !== routes.FORGOT_PASSWORD &&
        location.pathname !== routes.CONFIRM_MAIL &&
        location.pathname !== routes.RESET_PASSWORD
      ) {
        navigate(routes.LOGIN, {
          replace: true,
          state: { prevPath: location.pathname }
        });
      }
    }
  }, [id, location.pathname, navigate]);
};

export default useSessionCheck;
