import useExplore from 'hooks/useExplore';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import {
  useExpanded,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import IndeterminateCheckbox from './IndeterminateCheckbox';

/**
 * ExploreTableWrapper component.
 *
 * @component
 * @param {Object} props - The component props
 * @param {ReactNode} props.children - The child components
 * @param {Array} props.columns - The columns configuration for the table
 * @param {Array} props.data - The data for the table
 * @param {number} [props.currentPage=1] - The current page number
 * @param {number} [props.perPage=25] - The number of items per page
 * @param {boolean} [props.sortable=true] - Determines if sorting is enabled
 * @param {number} props.total - The total number of items
 * @returns {ReactNode} The rendered component
 */
const ExploreTableWrapper = ({
  children,
  columns,
  currentPage = 1,
  data,
  perPage = 25,
  selection,
  selectionHidden = true,
  selectionColumnWidth = '5px',
  sortable = true,
  total
}) => {
  const { state, continueScroll } = useExplore();
  const observer = useRef();

  const memoizedColumns = useMemo(() => {
    const exploreSelectionColumn = {
      id: 'explore_selection',
      // eslint-disable-next-line react/prop-types
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <IndeterminateCheckbox
          selectionHidden={selectionHidden}
          {...getToggleAllRowsSelectedProps()}
        />
      ),
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <IndeterminateCheckbox
          selectionHidden={selectionHidden}
          // eslint-disable-next-line react/prop-types
          {...row.getToggleRowSelectedProps()}
        />
      ),
      headerProps: {
        style: { width: selectionColumnWidth, paddingLeft: 0, paddingRight: 0 }
      },
      cellProps: {
        style: { width: selectionColumnWidth, paddingLeft: 0, paddingRight: 0 }
      }
    };

    return selection ? [exploreSelectionColumn, ...columns] : columns;
  }, [columns, selection, selectionColumnWidth]);

  const memoizedData = useMemo(() => {
    return data;
  }, [data]);

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    state: { selectedRowIds }
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      disableSortBy: !sortable,
      initialState: { pageIndex: currentPage, pageSize: perPage },
      manualPagination: true,
      pageCount: Math.ceil(total / perPage)
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    useRowSelect
  );

  const lastRowElementRef = useRef();
  useEffect(() => {
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !state.documentsLoading) {
        continueScroll();
      }
    });

    const currentObserver = observer.current;
    if (lastRowElementRef.current) {
      currentObserver.observe(lastRowElementRef.current);
    }
    return () => {
      if (lastRowElementRef.current) {
        currentObserver.unobserve(lastRowElementRef.current);
      }
    };
  }, [state.documentsLoading, continueScroll]);

  const recursiveMap = children =>
    React.Children.map(children, child =>
      child?.props?.children
        ? React.cloneElement(child, {
            children: recursiveMap(child.props.children)
          })
        : React.cloneElement(child, {
            ...child.props,
            getTableProps,
            headerGroups,
            page: rows, // Correctly assign rows to page
            prepareRow,
            lastRowElementRef,
            selectedRowIds
          })
    );

  return <>{recursiveMap(children)}</>;
};

ExploreTableWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
  selection: PropTypes.bool,
  selectionHidden: PropTypes.bool,
  selectionColumnWidth: PropTypes.string,
  sortable: PropTypes.bool,
  total: PropTypes.number
};

export default ExploreTableWrapper;
