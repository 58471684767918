import {
  getColor,
  getRandomNumber,
  numberFormatter,
  rgbaColor
} from 'helpers/utils';
import useApplication from 'hooks/useApplication';
import moment from 'moment';

export const chartJsMapDefaultOptions = ({ data, isDark }) => {
  return {
    tooltip: {
      show: true,
      appendToBody: true,
      trigger: 'item',
      padding: [7, 10],
      borderWidth: 0,
      backgroundColor: getColor('black'),
      textStyle: {
        fontWeight: 500,
        fontSize: 12,
        color: getColor('white')
      },
      formatter: params => {
        return params?.value > 0
          ? `${params?.name.toLocaleUpperCase()}: <b>${
              Number(params?.value).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }) || 0
            }</b>`
          : null;
      }
    },
    visualMap: {
      show: true,
      min: 0,
      max: Math.max(...(data?.length > 0 ? data.map(item => item.value) : [0])),
      inRange: {
        color: [
          '#FFE4B5', // Lighter color
          '#FDC878',
          '#FDAB54',
          '#FF8F30' // Darker color
        ]
      },
      text: ['High', 'Low'],
      backgroundColor: 'transparent',
      textStyle: {
        color: getColor('gray-900')
      },
      calculable: false,
      realtime: false,
      orient: 'vertical',
      left: 20,
      bottom: 20,
      type: 'piecewise',
      selectedMode: false,
      cursor: 'default',
      hoverLink: false
    },
    series: [
      {
        type: 'map',
        map: 'world',
        data,
        top: 25,
        scaleLimit: {
          min: 1,
          max: 5
        },
        bottom: 25,
        left: 25,
        right: 25,
        label: {
          show: false
        },
        itemStyle: {
          areaColor: rgbaColor(getColor('gray-300'), 0.5),
          borderColor: isDark ? getColor('gray-100') : getColor('gray-100')
        },
        emphasis: {
          disabled: true,
          label: {
            show: false
          },
          itemStyle: {
            areaColor: 'inherit'
          }
        },
        selectedMode: false,
        select: {
          disabled: true
        }
      }
    ],
    animation: 'false'
  };
};

export const chartJsPieDefaultOptions = ({
  data,
  isDark,
  header,
  sum,

  isPrivate
}) => {
  return {
    tooltip: {
      show: true,
      appendToBody: true,
      confine: true,
      trigger: 'item',
      padding: [7, 10],
      borderWidth: 1,
      borderColor: isDark ? getColor('gray-300') : getColor('gray-300'),
      backgroundColor: isDark ? getColor('gray-200') : getColor('white'),
      textStyle: {
        fontWeight: 400,
        fontSize: 12,
        color: getColor('gray-900')
      },
      formatter: params => {
        let item = data?.find(
          item => String(item?.name) === String(params?.name)
        );

        return `${
          isPrivate ? '******' : String(params?.name).toLocaleUpperCase()
        }: <b>${item?.value.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })}</b> (${((item?.value / sum) * 100).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}%)`;
      }
    },
    legend: {
      type: 'scroll',
      show: false,
      orient: 'horizontal',
      selectedMode: 'multiple',
      top: '0',
      scroll: true,
      left: '0',
      padding: [0, 0, 0, 0],
      icon: 'circle',
      iconPadding: 10,

      textStyle: {
        color: getColor('gray-700'),
        fontWeight: 'normal',
        textShadowColor: getColor('light'),
        textShadowOffsetX: 1,
        textShadowOffsetY: 1,
        textShadowBlur: 1
      },
      tooltip: {
        show: true,
        confine: false,
        trigger: 'item',
        backgroundColor: isDark ? getColor('gray-300') : getColor('gray-1100'),
        textStyle: {
          fontWeight: 500,
          fontSize: 12,
          color: getColor('black')
        },
        position: 'top',

        formatter: params => {
          let item = data?.find(item => item?.name === params?.name);

          return `${
            isPrivate ? '******' : String(params?.name).toLocaleUpperCase()
          }: <b>${item?.value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })}</b> (${((item?.value / sum) * 100).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}%)`;
        },
        padding: [8, 8]
      },
      pageFormatter: ' ',
      formatter: name => {
        return `${
          isPrivate ? '******' : String(name).toLocaleUpperCase().slice(0, 18)
        }${name.length > 32 && !isPrivate ? '...' : ''}`;
      }
    },
    series: [
      {
        name: header,
        cursor: 'default',
        type: 'pie',
        radius: ['60%', '80%'],
        avoidLabelOverlap: false,
        data: data.map(item => ({
          value: item.value,
          name: item.name,
          itemStyle: {
            color: item.color // Set the color for each item
          }
        })),
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 0,
          borderColor: getColor('card-bg')
        },
        label: {
          show: false,
          position: 'center'
        },
        center: ['55%', '50%']
      }
    ],
    animation: false
  };
};

export const chartJsMapDefaultOptions2 = ({ data, isDark }) => {
  let maxValue = Math.max(
    ...(data?.length > 0 ? data.map(item => item.value) : [0])
  );
  let minValue = Math.min(
    ...(data?.length > 0 ? data.map(item => item.value) : [0])
  );

  // Combine the gradient colors with the HEX colors
  const finalColorArray = [
    rgbaColor(getColor('pastel-green-dark'), 0.3),
    getColor('pastel-cyan-complementary')
  ];

  return {
    tooltip: {
      show: true,
      trigger: 'item',
      padding: [7, 10],
      borderWidth: 1,
      borderColor: isDark ? getColor('gray-300') : getColor('gray-300'),
      backgroundColor: isDark ? getColor('gray-200') : getColor('white'),
      textStyle: {
        fontWeight: 400,
        fontSize: 12,
        color: getColor('gray-900')
      },
      formatter: params => {
        let item = data?.find(item => item?.name === params?.name);
        let sum = data?.reduce((acc, curr) => acc + Number(curr.value), 0);
        return `<p class="m-0 text-center fs--1">${String(
          params?.name
        ).toLocaleUpperCase()}: <b>${
          item?.value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }) || 0
        }</b> (${((item?.value / sum) * 100 || 0).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}%)</p>${
          item?.value
            ? '<p class="m-0 mt-2 text-center fs--1">Click to filter for this location.</p>'
            : ''
        }`;
      }
    },
    visualMap: {
      show: true,
      min: minValue,
      max: maxValue,
      inRange: {
        color: finalColorArray
      },
      text: ['High', 'Low'],
      textStyle: {
        color: getColor('gray-700'),
        fontSize: 10
      },
      orient: 'vertical',
      left: 20,
      bottom: 20,
      type: 'piecewise',
      cursor: 'default',
      splitNumber: 5
    },
    series: [
      {
        type: 'map',
        map: 'world',
        data,
        top: 25,
        scaleLimit: {
          min: 1,
          max: 5
        },
        bottom: 25,
        left: 25,
        right: 25,
        label: {
          show: false,
          color: getColor('black')
        },
        itemStyle: {
          areaColor: rgbaColor(getColor('gray-300'), 0.5),
          borderColor: getColor('gray-100')
        },
        emphasis: {
          itemStyle: {
            areaColor: rgbaColor(getColor('warning'), 0.5)
          },
          label: {
            show: false,
            fontSize: 10
          }
        },
        selectedMode: false
      }
    ],
    animation: 'false'
  };
};

export const chartJsMapDefaultOptionswithoutFilter = ({ data, isDark }) => {
  let maxValue = Math.max(
    ...(data?.length > 0 ? data.map(item => item.value) : [0])
  );
  let minValue = Math.min(
    ...(data?.length > 0 ? data.map(item => item.value) : [0])
  );

  // Combine the gradient colors with the HEX colors
  const finalColorArray = [
    rgbaColor(getColor('pastel-green-dark'), 0.3),
    getColor('pastel-cyan-complementary')
  ];

  return {
    tooltip: {
      show: true,
      trigger: 'item',
      padding: [7, 10],
      borderWidth: 1,
      borderColor: isDark ? getColor('gray-300') : getColor('gray-300'),
      backgroundColor: isDark ? getColor('gray-200') : getColor('white'),
      textStyle: {
        fontWeight: 400,
        fontSize: 12,
        color: getColor('gray-900')
      },
      formatter: params => {
        let item = data?.find(item => item?.name === params?.name);
        let sum = data?.reduce((acc, curr) => acc + Number(curr.value), 0);
        return `<p class="m-0 text-center fs--1">${String(
          params?.name
        ).toLocaleUpperCase()}: <b>${
          item?.value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }) || 0
        }</b> (${((item?.value / sum) * 100 || 0).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}%)</p>`;
      }
    },
    visualMap: {
      show: true,
      min: minValue,
      max: maxValue,
      inRange: {
        color: finalColorArray
      },
      text: ['High', 'Low'],
      textStyle: {
        color: getColor('gray-700'),
        fontSize: 10
      },
      orient: 'vertical',
      left: 20,
      bottom: 20,
      type: 'piecewise',
      cursor: 'default',
      splitNumber: 5
    },
    series: [
      {
        type: 'map',
        map: 'world',
        data,
        top: 25,
        scaleLimit: {
          min: 1,
          max: 5
        },
        bottom: 25,
        left: 25,
        right: 25,
        label: {
          show: false,
          color: getColor('black')
        },
        itemStyle: {
          areaColor: rgbaColor(getColor('gray-300'), 0.5),
          borderColor: getColor('gray-100')
        },
        emphasis: {
          itemStyle: {
            areaColor: rgbaColor(getColor('warning'), 0.5)
          },
          label: {
            show: false,
            fontSize: 10
          }
        },
        selectedMode: false
      }
    ],
    animation: 'false'
  };
};

export const chartJsMiniBarDefaultOptions = (data, labels) => {
  return {
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      borderWidth: 0,
      backgroundColor: getColor('black'),
      textStyle: {
        fontWeight: 500,
        fontSize: 12,
        color: getColor('white')
      },
      formatter: params =>
        `${String(
          params[0]?.name
        ).toLocaleUpperCase()}: <b>${params[0]?.value.toLocaleString(
          undefined,
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )}</b>`
    },
    xAxis: {
      type: 'category',
      data: labels,
      boundaryGap: false,
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisPointer: { type: 'none' },
      z: 1,
      zLevel: 1
    },
    yAxis: {
      type: 'value',
      splitLine: { show: false },
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisPointer: { type: 'none' },
      z: 2,
      zLevel: 2
    },
    series: [
      {
        type: 'line',
        cursor: 'default',
        showBackground: true,
        animation: false,
        backgroundStyle: {
          borderRadius: [3, 3, 3, 3],
          color: rgbaColor(getColor('gray-200'), 0.5)
        },
        itemStyle: {
          borderRadius: [3, 3, 3, 3],
          color: getColor('primary')
        },
        data,
        z: 10
      }
    ],
    grid: { right: 30, left: 30, top: 0, bottom: 0 },
    animation: 'false'
  };
};

export const chartJsHistogramDefaultOptions = ({ data }) => {
  const {
    application: { isDark }
  } = useApplication();
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          show: false,
          backgroundColor: '#6a7985'
        }
      },
      padding: [7, 10],
      borderWidth: 1,
      borderColor: isDark ? getColor('gray-300') : getColor('gray-300'),
      backgroundColor: isDark ? getColor('gray-200') : getColor('white'),
      textStyle: {
        fontWeight: 400,
        fontSize: 12,
        color: getColor('gray-900')
      },
      transitionDuration: 0,
      formatter: params => {
        const sum = data.reduce((acc, curr) => acc + Number(curr.doc_count), 0);
        return `<p class="m-0 fs--1">${moment(params[0]?.name).format(
          'YYYY-MM-DD HH:mm:ss'
        )}: <b>${Number(params[0]?.value).toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })}</b> (${
          params[0]?.value > 0 ? ((params[0]?.value / sum) * 100).toFixed(2) : 0
        }%) </p>`;
      }
    },
    grid: { right: 10, left: 40, top: 5, bottom: 20 },
    xAxis: {
      show: true,
      type: 'category',
      data: data?.map(item => new Date(item['key'])),
      axisLabel: {
        show: true,
        color: isDark ? 'var(--falcon-gray-400' : 'var(--falcon-gray-600)',
        fontWeight: 400,
        fontSize: 10,
        margin: 10,
        formatter: function (value) {
          return moment(value, 'ddd MMM DD YYYY HH:mm:ss ZZ').format(
            'YYYY-MM-DD HH:mm:ss'
          );
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: rgbaColor(getColor('secondary'), 0.1)
        }
      },
      axisTick: {
        show: true,
        alignWithLabel: true,
        lineStyle: {
          color: rgbaColor(getColor('secondary'), 0.1)
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: rgbaColor(getColor('secondary'), 0.1)
        }
      },
      z: 2
    },
    yAxis: {
      show: true,
      type: 'value',
      axisLabel: {
        show: true,
        color: getColor('gray-700'),
        fontWeight: 400,
        fontSize: 10,
        margin: 10,
        formatter: function (value) {
          return numberFormatter(value, 0);
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: rgbaColor(getColor('secondary'), 0.1)
        }
      },
      axisTick: {
        show: true,
        alignWithLabel: true,
        lineStyle: {
          color: rgbaColor(getColor('secondary'), 0.1)
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: rgbaColor(getColor('secondary'), 0.1)
        }
      },
      z: 2
    },
    series: [
      {
        type: 'line',
        smooth: true,
        data: data?.map(item => Number(item['doc_count']) || 0),
        symbol: 'emptyCircle',
        showSymbol: false,
        symbolSize: 5,
        itemStyle: {
          color: getColor('pastel-green')
        },
        lineStyle: {
          color: getColor('pastel-green'),
          width: 1
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(getColor('pastel-green'), 1)
              },
              {
                offset: 1,
                color: rgbaColor(getColor('pastel-green'), 0.25)
              }
            ]
          }
        },
        emphasis: {
          lineStyle: {
            width: 1
          },
          focus: 'series'
        },
        z: 3
      }
    ],
    animation: true,
    animationDuration: 750,
    animationThreshold: 25
  };
};
export const chartJsHistogramBarLegendDefaultOptions = (
  data,
  overallData,
  isDark
) => {
  const lineColors = [
    'pastel-cyan-complementary',
    'pastel-olive-light',
    'pastel-cyan-dark',
    'pastel-olive-complementary',
    'pastel-red-light',
    'pastel-purple',
    'pastel-pink',
    'pastel-blue-light',
    'pastel-blue-dark',
    'pastel-blue-complementary'
  ];

  let seriesData = [];

  data
    ?.filter(item => item.name !== 'Other')
    .map((item, index) => {
      seriesData?.push({
        name: item?.name ? item?.name : '',
        type: 'line',
        trim: true,
        stack: 'Total',
        smooth: true,
        symbol: 'emptyCircle',
        symbolSize: 5,
        showSymbol: false,
        lineStyle: {
          width: 1
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(getColor(lineColors[index]), 0.8)
              },
              {
                offset: 1,
                color: rgbaColor(getColor(lineColors[index]), 0.1)
              }
            ]
          }
        },
        label: {
          show: false
        },
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          borderRadius: [5, 5, 0, 0]
        },
        showBackground: true,
        backgroundStyle: {
          borderRadius: [5, 5, 0, 0]
        },
        data: item?.data ? item?.data : []
      });
    });

  let findOther = data?.find(item => item.name === 'Other');
  let findOtherCount = findOther?.data.reduce((acc, curr) => acc + curr, 0);
  if (findOther && findOtherCount > 0) {
    seriesData?.push({
      name: findOther?.name ? findOther?.name : '',
      type: 'line',
      trim: true,
      stack: 'Total',
      smooth: true,
      symbol: 'emptyCircle',
      symbolSize: 5,
      showSymbol: false,
      lineStyle: {
        width: 1
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor(lineColors[4]), 0.8)
            },
            {
              offset: 1,
              color: rgbaColor(getColor(lineColors[4]), 0.1)
            }
          ]
        }
      },
      label: {
        show: false
      },
      emphasis: {
        focus: 'series'
      },
      itemStyle: {
        borderRadius: [5, 5, 0, 0]
      },
      showBackground: true,
      backgroundStyle: {
        borderRadius: [5, 5, 0, 0]
      },
      data: findOther?.data ? findOther?.data : []
    });
  }

  // Ensure there is a zero count line if no data is present
  if (seriesData.length === 0) {
    seriesData.push({
      name: '',
      type: 'line',
      trim: true,
      stack: 'Total',
      smooth: true,
      symbol: 'emptyCircle',
      symbolSize: 5,
      showSymbol: false,
      lineStyle: {
        width: 1
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor(lineColors[0]), 0.8)
            },
            {
              offset: 1,
              color: rgbaColor(getColor(lineColors[0]), 0.1)
            }
          ]
        }
      },
      label: {
        show: false
      },
      emphasis: {
        focus: 'series'
      },
      itemStyle: {
        borderRadius: [5, 5, 0, 0]
      },
      showBackground: true,
      backgroundStyle: {
        borderRadius: [5, 5, 0, 0]
      },
      data: overallData.map(() => 0)
    });
  }

  return {
    color: [
      getColor('pastel-cyan-complementary'),
      rgbaColor(getColor('pastel-olive-light'), 0.9),
      rgbaColor(getColor('pastel-cyan-dark'), 0.8),
      rgbaColor(getColor('pastel-olive-complementary'), 0.7),
      rgbaColor(getColor('pastel-red-light'), 0.6),
      rgbaColor(getColor('pastel-purple'), 0.5),
      rgbaColor(getColor('pastel-pink'), 0.4),
      rgbaColor(getColor('pastel-blue-light'), 0.3),
      rgbaColor(getColor('pastel-blue-dark'), 0.2),
      rgbaColor(getColor('pastel-blue-complementary'), 0.1)
    ],
    tooltip: {
      trigger: 'axis',
      appendToBody: true,
      axisPointer: {
        type: 'cross',
        label: {
          show: false,
          backgroundColor: '#6a7985'
        }
      },
      padding: [7, 10],
      borderWidth: 1,
      borderColor: isDark ? getColor('gray-300') : getColor('gray-300'),
      backgroundColor: isDark ? getColor('gray-200') : getColor('white'),
      textStyle: {
        fontWeight: 400,
        fontSize: 12,
        color: getColor('gray-900')
      },
      formatter: function (params) {
        const sortedData = params.sort((a, b) => b.value - a.value);
        const totalValue = sortedData.reduce(
          (acc, curr) => acc + curr.value,
          0
        );

        let tooltipContent = `<p class="mb-2">${
          sortedData &&
          sortedData[0] &&
          moment(sortedData[0].name).format('YYYY-MM-DD HH:mm:ss')
        }</p>`;
        for (let i = 0; i < sortedData.length; i++) {
          if (sortedData[i].seriesName !== 'Other') {
            const percentage = totalValue
              ? ((sortedData[i].value / totalValue) * 100).toFixed(2)
              : '0.00';

            tooltipContent += `${sortedData[i].marker} ${sortedData[i].seriesName}: <strong>${sortedData[i].value}</strong> (${percentage}%)<br>`;
          }
        }

        let otherContent = sortedData.find(item => item.seriesName === 'Other');
        if (otherContent) {
          const percentage = totalValue
            ? ((otherContent.value / totalValue) * 100).toFixed(2)
            : '0.00';

          tooltipContent += `${otherContent.marker} ${otherContent.seriesName}: <strong>${otherContent.value}</strong> (${percentage}%)<br>`;
        }
        return tooltipContent;
      }
    },
    legend: {
      orient: 'horizontal',
      top: '0',
      left: 'center',
      padding: 0,
      itemGap: 10,
      itemWidth: 10,
      height: 'auto',
      icon: 'circle',
      textStyle: {
        rich: {
          a: {
            fontWeight: 'bold'
          },
          b: {
            color: getColor('gray-500')
          }
        },
        fontSize: 10,
        color: getColor('gray-700'),
        textShadowColor: getColor('light'),
        textShadowOffsetX: 1,
        textShadowOffsetY: 1,
        textShadowBlur: 1
      },
      formatter: name => {
        // Get a total of all counts in all series
        // Get the series data that matches the name
        // Then get a total count of the data in the series that matches the name
        // Display the count, then the percentage of the count in the series
        const total = data
          ?.map(item => item?.data)
          ?.flat()
          ?.reduce((acc, curr) => acc + curr, 0);
        const seriesData = data?.find(item => item.name === name);
        const count = seriesData?.data?.reduce((acc, curr) => acc + curr, 0);
        return count
          ? `${name} {a|${count?.toLocaleString()}} {b|(${(
              (count / total) *
              100
            ).toFixed(2)}%)}`
          : '';
      }
    },
    grid: {
      containLabel: true,
      right: '5px',
      left: 10,
      bottom: 0,
      top:
        data.length > 8 ? 75 : data.length > 4 ? 50 : data.length > 2 ? 25 : 10
    },
    xAxis: {
      type: 'category',
      data: overallData?.map(item => item.key_as_string),
      axisLabel: {
        show: true,
        color: getColor('gray-700'),
        fontWeight: 400,
        fontSize: 10,
        margin: 10,
        formatter: function (value) {
          return moment(value).format('YYYY-MM-DD HH:mm:ss');
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: rgbaColor(getColor('secondary'), 0.1)
        }
      },
      axisTick: {
        show: true,
        alignWithLabel: true,
        lineStyle: {
          color: rgbaColor(getColor('secondary'), 0.1)
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: rgbaColor(getColor('secondary'), 0.1)
        }
      },
      z: 2
    },
    yAxis: {
      show: true,
      type: 'value',
      axisLabel: {
        show: true,
        color: getColor('gray-700'),
        fontWeight: 400,
        fontSize: 10,
        margin: 10,
        formatter: function (value) {
          return numberFormatter(value, 0);
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: rgbaColor(getColor('secondary'), 0.1)
        }
      },
      axisTick: {
        show: true,
        alignWithLabel: true,
        lineStyle: {
          color: rgbaColor(getColor('secondary'), 0.1)
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: rgbaColor(getColor('secondary'), 0.1)
        }
      },
      z: 2
    },
    series: seriesData,
    animation: 'true',
    animationDuration: 2500,
    animationEasing: 'linear'
  };
};

export const chartJsDefaultTooltip = () => ({
  backgroundColor: getColor('gray-100'),
  borderColor: getColor('gray-300'),
  borderWidth: 1,
  titleColor: getColor('black'),
  callbacks: {
    labelTextColor() {
      return getColor('black');
    }
  }
});

export const getBubbleDataset = (count, rMin, rMax, min, max) => {
  const arr = Array.from(Array(count).keys());
  return arr.map(() => ({
    x: getRandomNumber(min, max),
    y: getRandomNumber(min, max),
    r: getRandomNumber(rMin, rMax)
  }));
};
