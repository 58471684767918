import Flex from 'components/common/Flex';
import useArticles from 'hooks/admin-contexts/useArticles';
import React from 'react';
import { Card } from 'react-bootstrap';
import AddButton from '../components/AddButton';
import ArticleTag from './ArticlesTag';

import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticleTags = () => {
  const {
    articles: { tags },
    loading: componentLoading,
    setArticles
  } = useArticles();

  return (
    <Card className='shadow-none border'>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          <span className='d-block'>Article Tags</span>
          <AddButton
            onClick={() =>
              setArticles(prevState => ({
                ...prevState,
                addArticleTagModal: { open: true }
              }))
            }
          />
        </Flex>
      </Card.Header>
      <Card.Body className='position-relative'>
        {componentLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {tags?.map((tag, index) => (
              <ArticleTag
                key={index}
                tag={tag}
                index={index}
                isLast={tags?.length - 1 === index}
              />
            ))}
            <AddButton
              label='Add a tag'
              onClick={() =>
                setArticles(prevState => ({
                  ...prevState,
                  addArticleTagModal: { open: true }
                }))
              }
            />
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ArticleTags;
