import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

export const meraki = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'Meraki Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*meraki*'],
        queryType: ['previousCount', 'count', 'sparkline']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Events',
          helpInfo: 'Total number of Meraki events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*meraki*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'eventType.keyword': 'wpa_auth'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'eventType',
            value: 'wpa_auth'
          },
          title: 'WPA Auth',
          helpInfo:
            'Total number of Meraki events indicating WPA authentication'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*meraki*'],
        queryType: ['previousCount', 'count', 'candles'],
        must: [
          {
            match_phrase: {
              'eventType.keyword': 'client_vpn_connect'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'eventType',
            value: 'client_vpn_connect'
          },
          title: 'VPN Connects',
          helpInfo: 'Total number of Meraki events indicating VPN connects'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*meraki*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'eventType.keyword': 'dhcp_problem'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'eventType',
            value: 'dhcp_problem'
          },
          title: 'DHCP Issues',
          helpInfo:
            'Total number of Meraki events indicating potential issues with DHCP'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*meraki*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'eventType.keyword': 'cf_block'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'eventType',
            value: 'cf_block'
          },
          title: 'CF Blocks',
          helpInfo:
            'Total number of Meraki events indicating content filter blocks'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*meraki*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'eventType.keyword': 'ad_auth'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'eventType',
            value: 'ad_auth'
          },
          title: 'Domain Auth',
          helpInfo:
            'Total number of Meraki events indicating domain authentication'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*meraki*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'eventType.keyword': 'vpn'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'eventType',
            value: 'vpn'
          },
          title: 'VPN Events',
          helpInfo: 'Total number of Meraki events related to VPN activity'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*meraki*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'eventType.keyword': 'dhcp_blocked'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'eventType',
            value: 'dhcp_blocked'
          },
          title: 'DHCP Blocks',
          helpInfo: 'Total number of Meraki events indicating DHCP blocks'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*meraki*'],
        queryType: ['histogram']
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Event Timeline',
          helpInfo:
            'Timeline histogram of all Meraki events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*meraki*'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'networkName.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Networks',
          helpInfo: 'Networks where Meraki events are being reported',
          field: 'networkName'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*meraki*'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'deviceType.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Device Types',
          helpInfo: 'Device types triggering Meraki events',
          field: 'deviceType'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*meraki*'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'description.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Event Descriptions',
          helpInfo: 'Descriptions of Meraki events',
          field: 'description'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*meraki*'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'deviceName.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Device Names',
          helpInfo: 'Names of devices triggering Meraki events',
          field: 'deviceName'
        }
      }
    }
  ];
};

export default meraki;
