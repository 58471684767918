import React from 'react';
import SearchField from './SearchField';
import IndexPatternSelect from './IndexPatternSelect';
import RangePresetSelect from './RangePresetSelect';
import RangeCustomSelect from './RangeCustomSelect';
import UpdateControls from './UpdateControls';
import { Form, Row } from 'react-bootstrap';

/**
 * Renders the basic controls for query filters
 * @returns {JSX.Element} The rendered basic controls
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const BasicControls = () => {
  return (
    <Form>
      <Row className='g-2'>
        <SearchField />
        <IndexPatternSelect />
        <RangePresetSelect />
        <RangeCustomSelect />
        <UpdateControls />
      </Row>
    </Form>
  );
};

export default BasicControls;
