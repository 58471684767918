import Flex from 'components/common/Flex';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useSubscriptionLevels from 'hooks/admin-contexts/useSubscriptionLevels';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import AddButton from './AddButton';
import { getSubscriptionLevelColumns } from './Columns/ColumnsDefaults';
import ShowDeletedButton from './ShowDeletedButton';
import SubscriptionLevelsTable from './SubscriptionLevelsTable';
import SubscriptionLevelsTableFilters from './SubscriptionLevelsTableFilters';
import SubscriptionLevelsTableFooter from './SubscriptionLevelsTableFooter';
import SubscriptionLevelsTablePagination from './SubscriptionLevelsTablePagination';
import SubscriptionLevelsTableWrapper from './SubscriptionLevelsTableWrapper';

/**
 * Renders the SubscriptionLevels component
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const SubscriptionLevels = () => {
  const {
    subscriptionLevels: { showDeleted, data },
    loading: componentLoading,
    setSubscriptionLevels
  } = useSubscriptionLevels();

  const [columns, setColumns] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  /**
   * Set columns on mount
   */
  useEffect(() => {
    setColumns(getSubscriptionLevelColumns(setSubscriptionLevels));
  }, []);

  return (
    <Card className='shadow-none border'>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          <span className='d-block'>Subscription Levels</span>
          <Flex justifyContent='end' alignItems='center'>
            <ShowDeletedButton
              showDeleted={showDeleted}
              onClick={() => {
                setSubscriptionLevels(prevState => ({
                  ...prevState,
                  showDeleted: !showDeleted
                }));
              }}
            />
            <AddButton
              onClick={() =>
                setSubscriptionLevels(prevState => ({
                  ...prevState,
                  addSubscriptionLevelModal: { open: true }
                }))
              }
            />
          </Flex>
        </Flex>
      </Card.Header>
      <Card.Body className='position-relative'>
        {componentLoading ? (
          <LoadingSpinner grow='10' />
        ) : (
          <SubscriptionLevelsTableWrapper
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            // data={subscriptionLevels}
            data={
              showDeleted
                ? data
                : data?.filter(
                    subscriptionLevel => !subscriptionLevel?.isDeleted
                  )
            }
            sortable
            loading={componentLoading}
            pagination
            perPage={50}>
            <SubscriptionLevelsTableFilters
              table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <SubscriptionLevelsTable table columns={columns} />
            <AddButton
              label='Add a subscription Level'
              onClick={() =>
                setSubscriptionLevels(prevState => ({
                  ...prevState,
                  addSubscriptionLevelModal: { open: true }
                }))
              }
            />
            <div className='mt-3 d-flex justify-content-between'>
              <SubscriptionLevelsTableFooter
                table
                rowInfo
                data={
                  showDeleted
                    ? data
                    : data?.filter(
                        subscriptionLevel => !subscriptionLevel?.isDeleted
                      )
                }
                rowCount={
                  showDeleted
                    ? data?.length
                    : data?.filter(
                        subscriptionLevel => !subscriptionLevel?.isDeleted
                      ).length
                }
              />
              <SubscriptionLevelsTablePagination table />
            </div>
          </SubscriptionLevelsTableWrapper>
        )}
      </Card.Body>
    </Card>
  );
};

export default SubscriptionLevels;
