import { getColor, rgbaColor } from 'helpers/utils';
import { useCluster } from 'hooks/admin-contexts/useCluster';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useCountUp } from 'react-countup';
import ArchSplitLineChart from './ArchSplitLineChart';

const StorageUsage = () => {
  const {
    cluster: {
      clusterNodesStats: { nodes }
    }
  } = useCluster();
  const [storageUsageBytesAvailable, setStorageUsageBytesAvailable] =
    useState(0);
  const [storageUsageBytesUsed, setStorageUsageBytesUsed] = useState(0);
  const [storageUsagePercent, setStorageUsagePercent] = useState(0);
  const [barColor, setBarColor] = useState('secondary');

  useEffect(() => {
    if (storageUsagePercent >= 90) {
      setBarColor('pastel-red');
    } else if (storageUsagePercent >= 75) {
      setBarColor('pastel-yellow');
    } else if (storageUsagePercent >= 0) {
      setBarColor('pastel-green');
    }
  }, [storageUsagePercent]);

  const { update } = useCountUp({
    ref: 'storageUsagePercent',
    decimals: 2,
    duration: 5,
    end: storageUsagePercent || 0,
    preserveValue: true
  });

  useEffect(() => {
    if (nodes) {
      const totalArray = [];
      const availableArray = [];
      const nodesList = Object(Object.keys(nodes).map(key => nodes[key]));
      let totalPaths = 'fs.total.total_in_bytes'.split('.');
      let availablePaths = 'fs.total.available_in_bytes'.split('.');

      nodesList.forEach(item => {
        const totalBytes = totalPaths.reduce(
          (acc, cur) => (acc[cur] ? acc[cur] : ''),
          item
        );
        const availableBytes = availablePaths.reduce(
          (acc, cur) => (acc[cur] ? acc[cur] : ''),
          item
        );
        if (totalBytes !== '') totalArray.push(totalBytes);
        if (availableBytes !== '') availableArray.push(availableBytes);
      });

      var total = 0;
      for (var i = 0; i < totalArray.length; i++) {
        total += totalArray[i];
      }

      var available = 0;
      var used = 0;
      for (var a = 0; a < availableArray.length; a++) {
        available += availableArray[a];
        used += totalArray[a] - availableArray[a];
      }
      setStorageUsageBytesUsed(used);
      setStorageUsageBytesAvailable(available);
      setStorageUsagePercent(((total - available) * 100) / total);
      update(((total - available) * 100) / total);
    }
  }, [nodes]);

  return (
    <Card
      className={`h-100 shadow-none border status-card ${
        !['secondary', 'green'].includes(barColor) ? barColor : null
      }`}>
      {!nodes ? (
        <div className='position-absolute d-flex justify-content-center align-items-center h-100 w-100 navbar-glass-loader'>
          <Spinner animation='border' />
        </div>
      ) : null}
      <Card.Body className='p-2'>
        <Row className='g-0'>
          <Col
            xs={5}
            className='d-flex flex-column h-100 justify-content-between'>
            <h6 className='mb-2 text-900 text-nowrap'>Storage</h6>
            <h6 className='fs-2 fw-normal mb-0 text-nowrap'>
              <span
                id='storageUsagePercent'
                className='font-monospace text-nowrap'
              />
              %
            </h6>
            <span></span>
          </Col>
          <Col xs='7' className='h-100'>
            <ArchSplitLineChart
              data={{
                labels: ['Used', 'Free'],
                values: [storageUsageBytesUsed, storageUsageBytesAvailable],
                backgroundColors: [
                  rgbaColor(getColor(barColor), 0.8),
                  rgbaColor(getColor('secondary'), 0.1)
                ],
                borderColors: [
                  rgbaColor(getColor(barColor), 0),
                  rgbaColor(getColor('secondary'), 0)
                ]
              }}
              tooltipLabelFormat='storage'
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

StorageUsage.propTypes = {
  noLink: PropTypes.bool
};

export default StorageUsage;
