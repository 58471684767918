import { makeRequest } from 'api/connection/requestAction';

class Fields {
  constructor(route) {
    this.route = route;
  }

  async getFields(indice, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getFields.method,
        `${this.route.getFields.path}/${indice}`
      );
      if (res instanceof Error) throw res;
      return res?.data?.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }
      return error;
    }
  }

  async updateFields(data, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateFields.method,
        this.route.updateFields.path,
        data
      );
      if (res instanceof Error) throw res;
      return res?.data?.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }
      return error;
    }
  }

  // New method to retrieve a single field by its ID
  async getField(fieldId, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getField.method,
        `${this.route.getField.path}/${fieldId}`
      );
      if (res instanceof Error) throw res;
      return res?.data?.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }
      return error;
    }
  }
}

export default Fields;
