import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import routes from 'config/routeSettings';
import { useNavigate } from 'react-router-dom';
// import { useSearchFilters } from 'context/FiltersProvider';
import { dynamicDashboardRoutes } from 'components/dashboards/Artifacts/Dynamic/routes';
import moment from 'moment';
import PropTypes from 'prop-types';

import { truncate } from 'lodash';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
export const SavedSearch = ({ savedSearch, icon, isLast }) => {
  // const {
  //   application: { isPrivacyMode }
  // } = useApplication();

  // const { loadSavedSearch } = useSearchFilters();
  const navigate = useNavigate();
  const loadDashboard = savedSearch => {
    let dashboard = dynamicDashboardRoutes.find(
      item => item.slug === savedSearch._dashboard
    );
    navigate(
      `${dashboard ? dashboard.path : routes.ARTIFACTS_EXPLORE}?savedSearchId=${
        savedSearch.id
      }`
    );
  };
  return (
    <Row
      className={classNames('g-2 mb-1  recent-activity-timeline-primary', {
        'mb-0': isLast,
        'recent-activity-timeline-past': status === 'completed',
        'recent-activity-timeline-current': status === 'current'
      })}>
      <Col xs='auto' className='ps-0 ms-2'>
        <div>
          <div className='icon-item icon-item-sm rounded-circle bg-100 shadow-none'>
            <FontAwesomeIcon
              icon={icon}
              className='text-primary cursor-pointer'
              onClick={() => {
                // loadSavedSearch(savedSearch);
                // navigate(`${routes.ARTIFACTS_EXPLORE}`);
                loadDashboard(savedSearch);
              }}
            />
          </div>
        </div>
      </Col>
      <Col>
        <Row
          className={classNames('g-2', {
            'border-bottom border-200 mb-1 pb-2': !isLast,
            'mb-0 pb-0': isLast
          })}>
          <Col
            className='cursor-pointer'
            onClick={() => {
              loadDashboard(savedSearch);
            }}>
            <h6 className='fs--2 text-700 mb-0'>{savedSearch?.name}</h6>
            <p className='fs--2 text-500 mb-0'>
              {savedSearch?.description
                ? truncate(savedSearch?.description, { length: 64 })
                : ''}
            </p>
          </Col>
          <Col xs='auto'>
            <p className='fs--2 text-700 mb-0'>
              {moment(savedSearch?.lastUsedAt).fromNow()}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

SavedSearch.propTypes = {
  savedSearch: PropTypes.object,
  isLast: PropTypes.bool,
  icon: PropTypes.string
};
