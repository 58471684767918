import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { ArtifactPrettyName } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**
 *
 * @returns {JSX.Element} The rendered button component
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const TableRow = ({ item, total, isLast }) => {
  return (
    <tr>
      <td
        className={classNames(
          'align-middle py-1 px-0 text-nowrap border-bottom border-200',
          {
            'border-0': isLast
          }
        )}>
        <Flex alignItems='center' className='position-relative'>
          <div className='d-flex align-items-center'>
            <h6 className='mb-0 fs--2 fw-normal'>
              <Button
                as={Link}
                size='sm'
                variant='link'
                to={ArtifactPrettyName[item?.key]?.linkTo}
                className='ms-0 p-0 fs--2 text-decoration-none text-700 hover-900'>
                <span style={{ minHeight: '20px' }}>
                  {item?.key &&
                    ArtifactPrettyName[item?.key] &&
                    ArtifactPrettyName[item?.key]?.icon && (
                      <Image
                        src={ArtifactPrettyName[item?.key]?.icon}
                        alt={item?.key}
                        className='me-1 opacity-75'
                        width={20}
                      />
                    )}
                </span>
                {item?.key &&
                ArtifactPrettyName[item?.key] &&
                ArtifactPrettyName[item?.key]?.linkTo
                  ? ArtifactPrettyName[item?.key]?.prettyName
                  : item?.key}
              </Button>
            </h6>
            {/* </Link> */}
          </div>
        </Flex>
      </td>
      <td
        className={classNames(
          'align-middle py-1 px-0 text-end text-nowrap border-bottom border-200',
          {
            'border-0': isLast
          }
        )}>
        <h6 className='mb-0 fs--2 fw-normal text-700'>
          <span className='fw-bold'>
            {item &&
              item['1'] &&
              item['1'].value &&
              Number(item['1'].value).toLocaleString()}
          </span>
          <span className='text-500'>
            {/* Percentage */}
            {item &&
              item['1'] &&
              item['1'].value &&
              total &&
              ` (${((Number(item['1'].value) / Number(total)) * 100).toFixed(
                2
              )}%)`}
          </span>
        </h6>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  isLast: PropTypes.bool.isRequired,
  total: PropTypes.number,
  item: PropTypes.object
};
export default TableRow;
