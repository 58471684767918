import LeargasAPI from 'api';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

export const productsSettings = {
  data: [],
  showDeleted: false,
  addProductModal: { open: false, fullScreen: false },
  editProductModal: { open: false, data: {}, fullScreen: false },
  deleteProductModal: { open: false, data: {} },
  restoreProductModal: { open: false, data: {} },
  loading: false,
  fetch: false,
  requiredProducts: {
    products: {
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin'],
      delete: ['super-admin', 'admin']
    },
    productsNotes: {
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin'],
      delete: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    products: {
      read: ['products:read'],
      write: ['products:write'],
      delete: ['products:delete']
    },
    productsNotes: {
      read: ['productsNotes:read'],
      write: ['productsNotes:write'],
      delete: ['productsNotes:delete']
    }
  }
};

/**
 * Create ProductsContext
 * @type {React.Context<{}>}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const ProductsContext = createContext(productsSettings);

/**
 * Create ProductsProvider
 * @param props
 * @returns {JSX.Element}
 * @constructor
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 *  <ProductsProvider>
 *   {children}
 * </ProductsProvider>
 * )
 */

const ProductsProvider = props => {
  const [products, setProducts] = useState(productsSettings);
  const [loading, setLoading] = useState(true);
  const { axiosPrivate } = useAxiosPrivate();

  /**
   * Get all application Products
   * @returns {Promise<void>}
   * @async
   * @function
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  const getAppProducts = async () => {
    try {
      let res = await LeargasAPI.AppProducts.getProducts(axiosPrivate);
      if (res instanceof Error) throw res;

      setProducts(prevState => ({
        ...prevState,
        data: res?.data?.data
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setProducts(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setProducts(prevState => ({
        ...prevState,
        fetch: false
      }));
      setLoading(false);
    }
  };

  /**
   * Get all application Products on fetch state change
   */
  useEffect(() => {
    if (products.fetch) {
      getAppProducts();
    }
  }, [products.fetch]);

  /**
   * Get all application Products on component mount
   */
  useEffect(() => {
    getAppProducts();
  }, []);

  return (
    <ProductsContext.Provider
      value={{
        products,
        setProducts,
        loading
      }}>
      {props.children}
    </ProductsContext.Provider>
  );
};

ProductsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProductsProvider;
