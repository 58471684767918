import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import CreatableMultiselect from 'components/common/CreatableMultiselect';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import AdvancedPopover from 'components/common/Popover';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useProducts from 'hooks/admin-contexts/useProducts';
import useSubscriptionLevels from 'hooks/admin-contexts/useSubscriptionLevels';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

/**
 * Renders the SubscriptionLevelsEditForm component
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const SubscriptionLevelEditForm = () => {
  const { products } = useProducts();

  const {
    subscriptionLevels: {
      editSubscriptionLevelModal: { defaultData }
    },
    setSubscriptionLevels
  } = useSubscriptionLevels();
  const { axiosPrivate } = useAxiosPrivate();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
    register
  } = useForm();

  const [loading, setLoading] = useState(false);

  /**
   * Handles the subscriptionLevels edit form submit
   *
   * @param {Object} data
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const onSubmit = async data => {
    let newData = data;

    newData['features'] = data?.features?.map(item => item.value);
    newData['includes'] = data?.includes?.map(item => item.value);

    try {
      setLoading(true);
      const res =
        await LeargasAPI.AppSubscriptionLevels.updateSubscriptionLevel(
          defaultData?._id,
          newData,
          axiosPrivate
        );
      if (res instanceof Error) throw res;

      const { message } = res.data;
      toast.success(message);
      setLoading(false);
      setSubscriptionLevels(prevState => ({
        ...prevState,
        editSubscriptionLevelModal: { open: false, defaultData: {} },
        fetch: true
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);
      setSubscriptionLevels(prevState => ({
        ...prevState,

        editSubscriptionLevelModal: { open: false, defaultData: {} }
      }));

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  /**
   * Resets the form with the default data
   */
  useEffect(() => {
    if (defaultData) {
      reset({
        name: defaultData?.name,
        limitAppliances: defaultData?.limits?.appliances,
        limitHosts: defaultData?.limits?.hosts,
        limitOrganizations: defaultData?.limits?.organizations,
        limitStorage: defaultData?.limits?.storage,
        limitTeams: defaultData?.limits?.teams,
        limitUsers: defaultData?.limits?.users,
        order: defaultData?.order,
        priceMonthly: defaultData?.price?.monthly,
        priceQuarterly: defaultData?.price?.quarterly,
        priceYearly: defaultData?.price?.yearly,
        features: defaultData?.features?.map(feature => ({
          value: feature,
          label: feature
        })),
        includes: defaultData?.includes?.map(productId => {
          const matchingProduct = products?.data?.find(
            product => product.id === productId
          );
          return {
            value: productId,
            label: matchingProduct?.name
          };
        })
      });
    }
  }, [defaultData, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className='g-3 mb-3'>
        <Col sm={12} className='required'>
          <Form.Label className='d-flex fs--1 fw-normal'>
            Name{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Subscription name'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <Form.Control
            {...register('name', { required: 'Name is required' })}
            type='text'
            placeholder='Enter name of subscriptionLevel'
            autoComplete='off'
            className='fs--1'
            style={{ minHeight: '36px' }}
          />
          <code>{errors.description?.message}</code>
        </Col>
        <Col sm={12} className='required'>
          <Form.Label className='d-flex fs--1 fw-normal'>
            Features{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Subscription Features'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <Controller
            name='features'
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <CreatableMultiselect
                className='fs--1'
                isMulti
                isClearable
                isSearchable
                onChange={selectedOptions => {
                  field.onChange(selectedOptions);
                  setValue('features', selectedOptions);
                }}
                value={field.value}
                style={{ minHeight: '36px' }}
              />
            )}
          />
          <code>{errors.Features?.message}</code>
        </Col>
        <Col sm={12}>
          <Form.Label className='d-flex fs--1 fw-normal'>
            Products{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Subscription Products'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <Controller
            name='includes'
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <MultiSelect
                className='fs--1'
                isMulti
                isClearable
                isSearchable
                options={products?.data?.map(product => ({
                  value: product.id,
                  label: product?.name
                }))}
                onChange={selectedOptions => {
                  field.onChange(selectedOptions);
                  setValue('includes', selectedOptions);
                }}
                value={field.value}
                style={{ minHeight: '36px' }}
              />
            )}
          />
          <code>{errors.scopes?.message}</code>
        </Col>
        <Form.Group as={Col} sm={6} className='required'>
          <Form.Label className='d-flex fs--1 fw-normal'>
            Appliances Limit{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Subscription Appliances Limit'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text>
              <FontAwesomeIcon icon='hard-drive' />
            </InputGroup.Text>
            <Controller
              name='limitAppliances'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <FormControl
                  {...field}
                  type='number'
                  placeholder='Enter appliances limit'
                  aria-describedby='editonPrice-editon'
                  required
                />
              )}
            />
            <Form.Control.Feedback type='invalid'>
              Please enter a valid price.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} sm={6} className='required'>
          <Form.Label className='d-flex fs--1 fw-normal'>
            Hosts Limit{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Subscription Hosts Limit'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text>
              {/* <FontAwesomeIcon icon={'connectdevelop'} /> */}
            </InputGroup.Text>
            <Controller
              name='limitHosts'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <FormControl
                  {...field}
                  type='number'
                  placeholder='Enter hosts limit'
                  aria-describedby='editonPrice-editon'
                  required
                />
              )}
            />
            <Form.Control.Feedback type='invalid'>
              Please enter a valid price.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group as={Col} sm={6} className='required'>
          <Form.Label className='d-flex fs--1 fw-normal'>
            Organizations Limit{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Subscription Organizations Limit'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text>
              <FontAwesomeIcon icon={'landmark'} />
            </InputGroup.Text>
            <Controller
              name='limitOrganizations'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <FormControl
                  {...field}
                  type='number'
                  placeholder='Enter organization limit'
                  aria-describedby='editonPrice-editon'
                  required
                />
              )}
            />
            <Form.Control.Feedback type='invalid'>
              Please enter a valid price.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} sm={6} className='required'>
          <Form.Label className='d-flex fs--1 fw-normal'>
            Storage Limit{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Subscription Storage Limit'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text>
              {/* <FontAwesomeIcon icon={'truck-container-empty'} /> */}
            </InputGroup.Text>
            <Controller
              name='limitStorage'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <FormControl
                  {...field}
                  type='number'
                  placeholder='Enter storage limit'
                  aria-describedby='editonPrice-editon'
                  required
                />
              )}
            />
            <Form.Control.Feedback type='invalid'>
              Please enter a valid number.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} sm={6} className='required'>
          <Form.Label className='d-flex fs--1 fw-normal'>
            Teams Limit{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Subscription Teams Limit'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text>
              <FontAwesomeIcon icon={'users'} />
            </InputGroup.Text>
            <Controller
              name='limitTeams'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <FormControl
                  {...field}
                  type='number'
                  placeholder='Enter teams limit'
                  required
                />
              )}
            />
            <Form.Control.Feedback type='invalid'>
              Please enter a valid price.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} sm={6} className='required'>
          <Form.Label className='d-flex fs--1 fw-normal'>
            Users Limit{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Subscription Users Limit'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text>
              <FontAwesomeIcon icon={'user'} />
            </InputGroup.Text>
            <Controller
              name='limitUsers'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <FormControl
                  {...field}
                  type='number'
                  placeholder='Enter users limit'
                  required
                />
              )}
            />
            <Form.Control.Feedback type='invalid'>
              Please enter a valid price.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} sm={6} className='required'>
          <Form.Label className='d-flex fs--1 fw-normal'>
            Order{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Subscription Order '
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <InputGroup hasValidation>
            {/* <InputGroup.Text>GB</InputGroup.Text> */}
            <Controller
              name='order'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <FormControl
                  {...field}
                  type='number'
                  placeholder='Enter subscriptionLevel order '
                  aria-describedby='editonPrice-editon'
                  required
                />
              )}
            />
            <Form.Control.Feedback type='invalid'>
              Please enter a valid price.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group as={Col} sm={6} className='required'>
          <Form.Label className='d-flex fs--1 fw-normal'>
            Monthly Price{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Subscription Monthly Price '
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text>$</InputGroup.Text>
            <Controller
              name='priceMonthly'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <FormControl
                  {...field}
                  type='number'
                  placeholder='Enter monthly price'
                  aria-describedby='editonPrice-editon'
                  required
                />
              )}
            />
            <Form.Control.Feedback type='invalid'>
              Please enter a valid price.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} sm={6} className='required'>
          <Form.Label className='d-flex fs--1 fw-normal'>
            Quarterly price{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Subscription Quarterly price '
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text>$</InputGroup.Text>
            <Controller
              name='priceQuarterly'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <FormControl
                  {...field}
                  type='number'
                  placeholder='Enter quarterly price'
                  aria-describedby='editonPrice-editon'
                  required
                />
              )}
            />
            <Form.Control.Feedback type='invalid'>
              Please enter a valid price.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} sm={6} className='required'>
          <Form.Label className='d-flex fs--1 fw-normal'>
            Yearly price{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Subscription Yearly price '
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text>$</InputGroup.Text>
            <Controller
              name='priceYearly'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <FormControl
                  {...field}
                  type='number'
                  placeholder='Enter yearly price'
                  aria-describedby='editonPrice-editon'
                  required
                />
              )}
            />
            <Form.Control.Feedback type='invalid'>
              Please enter a valid price.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Flex justifyContent='end' className={'mt-3'}>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() =>
            setSubscriptionLevels(prevState => ({
              ...prevState,
              editSubscriptionLevelModal: { open: false, data: {} }
            }))
          }>
          Cancel
        </Button>

        <Button variant='success' size='sm' type='submit' className='d-flex'>
          {' '}
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Submitting' : 'Submit'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

export default SubscriptionLevelEditForm;
