import { keystrokeDefinitions } from 'config/keystrokeSettings';
import useApplication from 'hooks/useApplication';
import useAuthentication from 'hooks/useAuthentication';
import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import Flex from './Flex';

const KeyboardShortcutsModal = () => {
  const modalRef = useRef(null);

  const {
    application: { isDark, showKeyboardShortcuts },
    setApplication
  } = useApplication();

  const { authentication } = useAuthentication();

  useEffect(() => {
    if (showKeyboardShortcuts) {
      setApplication('showKeyboardShortcuts', false);
    }
  }, []);

  return (
    <Modal
      aria-labelledby='keyboardShortcutsModal'
      centered
      contentClassName='shadow-none'
      keyboard={true}
      onHide={() => setApplication('showKeyboardShortcuts', false)}
      ref={modalRef}
      show={showKeyboardShortcuts}
      size='lg'>
      <Flex
        className='card border border-card shadow-none'
        direction='column'
        justifyContent='between'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <Modal.Title>
            <h5 className='d-flex mb-0 fs-0 fw-normal position-relative align-items-center'>
              Keyboard Shortcuts
            </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className='p-2 position-relative'>
          {keystrokeDefinitions
            .filter(group =>
              group.allowedRoles.some(role =>
                authentication?.roles?.find(authRole => authRole.id === role)
              )
            )
            .map((group, groupIndex) => (
              <div key={groupIndex} className='mb-4'>
                <h2 className='fs-0 mb-0 fw-normal'>{group.group}</h2>
                {group.description && (
                  <p className='fs--1 mt-2 mb-0 fw-normal'>
                    {group.description && group.richDescription(group)}
                  </p>
                )}
                <table className='table fs--1'>
                  <thead>
                    <tr>
                      <th className='px-0 fw-normal' style={{ width: '35%' }}>
                        Key Combination
                      </th>
                      <th className='px-0 fw-normal'>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.shortcuts.map((shortcut, shortcutIndex) => (
                      <tr key={shortcutIndex}>
                        <td className='px-0 fw-normal'>
                          {shortcut.keys.map((key, keyIndex) => (
                            <span key={keyIndex}>
                              <kbd className='me-1 px-2 bg-light border border-primary'>
                                <code>{key.toUpperCase()}</code>
                              </kbd>
                              {keyIndex + 1 < shortcut.keys.length
                                ? ' + '
                                : null}
                            </span>
                          ))}
                        </td>
                        <td className='px-0 fw-normal'>
                          {shortcut.richDescription(shortcut)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
        </Modal.Body>
      </Flex>
    </Modal>
  );
};

export default KeyboardShortcutsModal;
