/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { Button, Form } from 'react-bootstrap';

/**
 *
 * @param {object} props
 * @param {Number} props.page
 * @param {Number} props.pageSize
 * @param {Number} props.pageIndex
 * @param {Number} props.rowCount
 * @param {Function} props.setPageSize
 * @param {Function} props.canPreviousPage
 * @param {Function} props.canNextPage
 * @param {Function} props.nextPage
 * @param {Function} props.previousPage
 * @param {Boolean} props.rowInfo
 * @param {Array} props.rowsPerPageSelection
 * @param {Boolean} props.navButtons
 * @param {Array} props.rowsPerPageOptions
 * @param {String} props.className
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

export const ResourcesTableFooter = ({
  page,
  pageSize,
  pageIndex,
  rowCount,
  setPageSize,
  canPreviousPage,
  canNextPage,
  nextPage,
  previousPage,
  rowInfo,
  rowsPerPageSelection,
  navButtons,
  rowsPerPageOptions = [5, 10, 15],
  className
}) => {
  return (
    <Flex
      className={classNames(
        className,
        'align-items-center justify-content-between'
      )}>
      <Flex alignItems='center' className='fs--1'>
        {rowInfo && (
          <p className='mb-0'>
            <span className='d-none d-sm-inline-block me-2'>
              {rowCount > 0 ? (
                <>
                  {pageSize * pageIndex + 1} to{' '}
                  {pageSize * pageIndex + page.length} of {rowCount}
                </>
              ) : (
                <> 0 to 0 of 0</>
              )}
            </span>
          </p>
        )}
        {rowsPerPageSelection && (
          <>
            <p className='mb-0 mx-2'>Rows per page:</p>
            <Form.Select
              size='sm'
              className='w-auto'
              onChange={e => setPageSize(e.target.value)}
              defaultValue={pageSize}>
              {rowsPerPageOptions.map(value => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </>
        )}
      </Flex>
      {navButtons && (
        <Flex>
          <Button
            size='sm'
            variant={canPreviousPage ? 'primary' : 'light'}
            onClick={() => previousPage()}
            className={classNames({ disabled: !canPreviousPage })}>
            Previous
          </Button>
          <Button
            size='sm'
            variant={canNextPage ? 'primary' : 'light'}
            className={classNames('px-4 ms-2', {
              disabled: !canNextPage
            })}
            onClick={() => nextPage()}>
            Next
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

ResourcesTableFooter.propTypes = {
  page: PropTypes.array,
  pageSize: PropTypes.number,
  pageIndex: PropTypes.number,
  rowCount: PropTypes.number,
  setPageSize: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  rowInfo: PropTypes.bool,
  rowsPerPageSelection: PropTypes.array,
  navButtons: PropTypes.bool,
  rowsPerPageOptions: PropTypes.array,
  className: PropTypes.string
};

export default ResourcesTableFooter;
