import Flex from 'components/common/Flex';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useProducts from 'hooks/admin-contexts/useProducts';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import AddButton from './AddButton';
import { getProductColumns } from './Columns/ColumnsDefaults';
import ProductsTable from './ProductsTable';
import ProductsTableFilters from './ProductsTableFilters';
import ProductsTableFooter from './ProductsTableFooter';
import ProductsTablePagination from './ProductsTablePagination';
import ProductsTableWrapper from './ProductsTableWrapper';
import ShowDeletedButton from './ShowDeletedButton';

/**
 * products
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const Products = () => {
  const {
    products: { showDeleted, data },
    loading: componentLoading,
    setProducts
  } = useProducts();

  const [globalFilter, setGlobalFilter] = useState('');
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns(getProductColumns(setProducts));
  }, []);

  return (
    <Card className='shadow-none border'>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          <span className='d-block'>Products</span>

          <Flex justifyContent='end' alignItems='center'>
            <ShowDeletedButton
              showDeleted={showDeleted}
              onClick={() => {
                setProducts(prevState => ({
                  ...prevState,
                  showDeleted: !showDeleted
                }));
              }}
            />
            <AddButton
              onClick={() =>
                setProducts(prevState => ({
                  ...prevState,
                  addProductModal: { open: true }
                }))
              }
            />
          </Flex>
        </Flex>
      </Card.Header>
      <Card.Body className='position-relative'>
        {componentLoading ? (
          <LoadingSpinner grow='10' />
        ) : (
          <ProductsTableWrapper
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            // data={products}
            data={
              showDeleted ? data : data?.filter(product => !product?.isDeleted)
            }
            sortable
            loading={componentLoading}
            pagination
            perPage={50}>
            <ProductsTableFilters
              table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <ProductsTable table columns={columns} />
            <AddButton
              label='Add a product'
              onClick={() =>
                setProducts(prevState => ({
                  ...prevState,
                  addProductModal: { open: true }
                }))
              }
            />
            <div className='mt-3 d-flex justify-content-between'>
              <ProductsTableFooter
                table
                rowInfo
                data={
                  showDeleted
                    ? data
                    : data?.filter(product => !product?.isDeleted)
                }
                rowCount={
                  showDeleted
                    ? data?.length
                    : data?.filter(product => !product?.isDeleted).length
                }
              />
              <ProductsTablePagination table />
            </div>
          </ProductsTableWrapper>
        )}
      </Card.Body>
    </Card>
  );
};

export default Products;
