import React from 'react';
import PropTypes from 'prop-types';
import ProfileBanner from './Profile';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const OrganizationProfile = ({ org }) => {
  return (
    <>
      <ProfileBanner org={org} />
    </>
  );
};

OrganizationProfile.propTypes = {
  org: PropTypes.object.isRequired
};

export default OrganizationProfile;
