import IPThreatIntelOffcanvas from 'components/common/IPThreatIntelOffcanvas';
import { dynamicDashboardRoutes } from 'components/dashboards/Artifacts/Dynamic/routes';
import { useAxios } from 'context/AxiosProvider';
import { getItemFromStore } from 'helpers/utils';
import useAuthentication from 'hooks/useAuthentication';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useRef
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  clearScrollContext,
  fetchAuroraData,
  fetchFields,
  fetchGptData,
  fetchSuggestions,
  fetchTopValues,
  scrollDocuments
} from './fetch';
import GptDataOffcanvas from './GptDataOffcanvas';
import { buildSearchQuery } from './helpers';
import {
  initializeIndices,
  initializeSelectedIndex,
  initializeTimeRange
} from './init';
import { initialState, stateActions, stateReducer } from './state';

/**
 * Create ExploreContext object.
 *
 * @type {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const ExploreContext = createContext(initialState);

/**
 * ExploreProvider component.
 *
 * @component
 * @param {Object} props - The component props
 * @param {ReactNode} props.children - The child components
 * @returns {ReactNode} The rendered ExploreProvider component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const ExploreProvider = ({ children }) => {
  const location = useLocation();
  const pathnameRef = useRef(null);
  const { makeRequest } = useAxios();
  const {
    authentication: { scopesSelected }
  } = useAuthentication();

  const stateWithLocalStorage = {
    ...initialState,
    fieldsPresentOnly: getItemFromStore(
      `leargas-explore-fieldsPresentOnly`,
      initialState.fieldsPresentOnly
    )
  };
  const [state, dispatch] = useReducer(stateReducer, stateWithLocalStorage);

  /**
   * Sets the state of the ExploreProvider.
   *
   * @param {string} key - The key of the state to set
   * @param {any} value - The value to set for the state
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const setState = useCallback(
    (key, value) => {
      dispatch({
        type: stateActions.SET_STATE,
        payload: {
          key,
          value,
          setInStore: ['fieldsPresentOnly'].includes(key)
        }
      });
      if (state.debug) {
        console.debug(
          `ExploreProvider: Set state key "${key}" to value:`,
          value
        );
      }
    },
    [state.debug]
  );

  /**
   * Initializes the time range and indices when the component mounts,
   * or when the pathname changes.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    const controller = new AbortController();
    const currentPath = location.pathname;
    pathnameRef.current = currentPath;

    if (!state.indices || state.indices.length === 0) {
      const initialize = async () => {
        initializeTimeRange(
          setState,
          state.interval,
          state.timeRange,
          currentPath
        );
        const indices = await initializeIndices(
          setState,
          controller,
          makeRequest
        );

        if (Object.keys(state?.savedSearch).length !== 0) {
          let savedIndexSelected = state?.savedSearch?.indices[0];
          setState('indexSelected', savedIndexSelected);
        } else {
          if (indices.length > 0) {
            initializeSelectedIndex(setState, indices, currentPath);
          }
        }

        setState('indices', indices);
      };
      initialize();
    }
    if (Object.keys(state?.savedSearch).length !== 0) {
      let savedIndexSelected = state?.savedSearch?.indices[0];
      setState('indexSelected', savedIndexSelected);
    } else {
      initializeSelectedIndex(setState, state.indices, currentPath);
    }

    return () => {
      controller.abort();
      if (state.debug)
        console.debug(
          'ExploreProvider: Aborted setup for time range and indices.'
        );
    };
  }, [location.pathname, state?.savedSearch]);

  /**
   * Initializes the fields and field types when the index is selected.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    if (state.indexSelected?._id) {
      setState('fields', []);
      setState('fieldsTotal', 0);
      setState('documents', []);
      setState('histogram', []);
      setState('documentsTotal', 0);
      setState('documentsLoading', true);
      setState('histogramLoading', true);
      setState('fieldsLoading', true);
      setState('fieldTypesLoading', true);

      const controller = new AbortController();
      const fetchFieldsAndSetState = async () => {
        const { fields, fieldTypes } = await fetchFields(
          state.indexSelected._id,
          makeRequest,
          controller.signal
        );
        const defaultFields =
          dynamicDashboardRoutes.find(route => route.path === location.pathname)
            ?.fieldsSelectedDefault || [];

        const fieldsToUse = defaultFields
          .map(field => fields.find(f => f.name === field))
          .filter(Boolean);

        setState('fields', fields);
        setState('fieldsTotal', fields.length);
        setState('fieldTypes', fieldTypes);
        // checking if saved search exist so we set the fields from saved search
        if (Object.keys(state?.savedSearch).length !== 0) {
          const savedFields = state?.savedSearch?.fields.map(item => item?._id);
          const filteredFields = fields.filter(field =>
            savedFields.includes(field?._id)
          );

          setState('fieldsSelected', [
            ...state.fieldsSelectedFallback,
            ...filteredFields
          ]);
        } else {
          setState('fieldsSelected', [
            ...state.fieldsSelectedFallback,
            ...fieldsToUse
          ]);
        }

        setState('fieldsLoading', false);
        setState('fieldTypesLoading', false);

        setState('update', true);
      };
      fetchFieldsAndSetState();

      return () => {
        controller.abort();
        if (state.debug)
          console.debug('ExploreProvider: Aborted fields initialization.');
      };
    }
  }, [location.pathname, state.indexSelected]);

  /**
   * Fetches documents and histogram data when the query is updated,
   * or when update is triggered.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    const controller = new AbortController();
    if (state.update && state.query) {
      setState('histogramLoading', true);
      setState('documentsLoading', true);

      const fetchDocumentsAndSetState = async () => {
        try {
          const {
            documents,
            fieldsPresent,
            fieldTypesPresent,
            histogram,
            total,
            scrollId
          } = await scrollDocuments(
            { ...state.query },
            makeRequest,
            controller.signal
          );
          setState('histogram', histogram);
          setState('documents', documents);
          setState('fieldsPresent', fieldsPresent);
          setState('fieldTypesPresent', fieldTypesPresent);
          setState('documentsTotal', total);
          setState('scrollId', scrollId);
          setState('accumulatedSize', state.accumulatedSize + documents.length);
          setState('histogramLoading', false);
          setState('documentsLoading', false);
          setState('update', false);
        } catch (error) {
          console.error('Failed to fetch documents:', error);
        }
      };
      fetchDocumentsAndSetState();
    }

    return () => {
      controller.abort();
      if (state.debug)
        console.debug('ExploreProvider: Aborted document fetching.');
    };
  }, [state.update, state.query]);

  /**
   * Continues scrolling through documents.
   *
   * @async
   * @function continueScroll
   * @returns {Promise<void>} A promise that resolves when scrolling is complete
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const continueScroll = useCallback(async () => {
    if (
      state.documents && // If documents are present
      state.documentsTotal > 0 && // If we've already fetched some documents
      state.documentsTotal >= state.pagination.pageSize && // If the current total count is greater than the page size
      state.scrollId // If we have a scroll ID
    ) {
      setState('documentsScrollLoading', true);

      try {
        const { documents, fieldsPresent, fieldTypesPresent, scrollId } =
          await scrollDocuments(
            {
              ...state.query,
              scrollId: state.scrollId,
              accumulatedSize: state.documents.length
            },
            makeRequest
          );
        setState('documents', [...state.documents, ...documents]);
        setState('fieldsPresent', [
          ...new Set([...state.fieldsPresent, ...fieldsPresent])
        ]);
        setState('fieldTypesPresent', [
          ...new Set([...state.fieldTypesPresent, ...fieldTypesPresent])
        ]);
        setState('scrollId', scrollId);
      } catch (error) {
        console.error('Error during scroll:', error); // Error handling
      } finally {
        setState('documentsScrollLoading', false);
      }
    }
  }, [state.scrollId, state.documents.length, makeRequest, setState]);

  /**
   * Clears the scroll context if a scroll ID is present.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const clearScroll = useCallback(() => {
    if (state.scrollId) {
      clearScrollContext(state.scrollId, makeRequest);
    }
  }, [state.scrollId, makeRequest]);

  /**
   * Rebuilds the query when scopes, time range, index, or filters change.
   * Triggers an update if the query is different, and the conditions are met.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    if (
      scopesSelected.length > 0 &&
      state.timeRange &&
      state.indexSelected?._id
    ) {
      const newQuery = buildSearchQuery(state, scopesSelected);
      if (JSON.stringify(newQuery) !== JSON.stringify(state.query)) {
        setState('query', newQuery);
        setState('update', true);
        clearScroll();
      }
    }
  }, [
    scopesSelected,
    state.indexSelected,
    state.timeRange,
    state.filters,
    state.filtersDefault,
    state.pagination
  ]);

  /**
   * Sets the loading state based on the loading states of the documents, fields,
   * field types, and indices.
   * Toggles the main loading state when any other loading state is true.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    const anyLoading =
      state.documentsLoading ||
      state.fieldsLoading ||
      state.fieldTypesLoading ||
      state.indicesLoading;
    setState('loading', anyLoading);
  }, [
    state.documentsLoading,
    state.fieldsLoading,
    state.fieldTypesLoading,
    state.indicesLoading
  ]);

  return (
    <ExploreContext.Provider
      value={{
        state,
        setState,
        buildSearchQuery,
        fetchTopValues,
        fetchGptData,
        fetchAuroraData,
        fetchSuggestions,
        makeRequest,
        continueScroll
      }}>
      <>
        {children}
        <GptDataOffcanvas />
        <IPThreatIntelOffcanvas />
      </>
    </ExploreContext.Provider>
  );
};

ExploreProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { ExploreContext, ExploreProvider };
