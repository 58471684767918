/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
// import {
//   Button,
//   Dropdown,
//   DropdownMenu,
//   Form,
//   InputGroup,
//   Popover
// } from 'react-bootstrap';
// import SimpleBar from 'simplebar-react';
// import { v4 as uuid } from 'uuid';
import Flex from '../Flex';

/**
 * Renders the footer component for the ExploreTable.
 *
 * @param {Object} props - The component props
 * @param {boolean} props.loading - Indicates if the table is currently loading
 * @param {number} props.pageSize - The number of rows per page
 * @param {number} props.pageIndex - The current page index
 * @param {number} props.documentsTotal - The total number of documents
 * @param {function} props.setPageSize - The function to set the page size
 * @param {number} props.totalPages - The total number of pages
 * @param {Array} [props.rowsPerPageOptions=[100, 250, 500]] - The options for rows per page
 * @returns {JSX.Element|null} The rendered ExploreTableFooter component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const ExploreTableFooter = ({
  loading,
  // pageSize,
  // pageIndex,
  documentsTotal,
  documentsShowing
  // setPageSize,
  // totalPages,
  // rowsPerPageOptions = [100, 250, 500]
}) => {
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);

  // const [show, setShow] = useState(false);

  /**
   * Toggles the value of `show`.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  // const handleToggle = () => {
  //   setShow(!show);
  // };

  /**
   * Handles the click outside event to close the dropdown menu.
   *
   * @param {Event} e - The click event object
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleClickOutside = e => {
    if (
      triggerRef.current &&
      !triggerRef.current.contains(e.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      // If the click is outside of the trigger and the dropdown, close the dropdown
      // setShow(false);
    }
  };

  /**
   * Adds an event listener to handle clicks outside of the dropdown menu.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    // Add the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Remove the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /**
   * Represents a popover component used in the ExploreTableFooter component.
   *
   * The popover component is used to display the dropdown menu for the table options.
   *
   * @typedef {JSX.Element} popover
   * @property {string} id - The ID of the popover.
   * @property {Object} style - The style object for the popover.
   * @property {React.Ref} ref - The ref for the dropdown element.
   * @property {React.ReactNode} children - The content of the popover.
   */
  // const popover = (
  //   <Popover
  //     id='searchFiltersBulkActionsPopover'
  //     style={{ minWidth: '200px', padding: 0 }}>
  //     <div ref={dropdownRef}>
  //       <Flex
  //         justifyContent='start'
  //         alignItems='Start'
  //         direction={'column'}
  //         className='w-100 p-0'>
  //         <DropdownMenu show={true} className='position-relative border-0'>
  //           <SimpleBar
  //             className='h-100'
  //             forceVisible={true}
  //             autoHide={false}
  //             style={{
  //               maxHeight: '70vh',
  //               minHeight: '120px'
  //             }}>
  //             <Dropdown.Header>Rows per page</Dropdown.Header>
  //             <div className='mb-2 px-3'>
  //               <Form.Select
  //                 autoFocus={true}
  //                 size='sm'
  //                 className='fs--1'
  //                 onChange={e => setPageSize(Number(e.target.value))}
  //                 defaultValue={pageSize}>
  //                 {rowsPerPageOptions.map(value => (
  //                   <option value={value} key={value}>
  //                     {value}
  //                   </option>
  //                 ))}
  //               </Form.Select>
  //             </div>
  //             <Dropdown.Header>Go to page</Dropdown.Header>
  //             <div className='mb-2 px-3'>
  //               <InputGroup className='mb-3'>
  //                 <Form.Control
  //                   name={uuid()}
  //                   defaultValue={pageIndex}
  //                   size='sm'
  //                   type='number'
  //                   className='fs--1 pe-1'
  //                   min={1}
  //                   max={totalPages}
  //                   autoComplete='off'
  //                 />
  //                 <Button
  //                   className='fs--1 border-300 border-hover-700'
  //                   variant='primary'
  //                   size='sm'>
  //                   Go
  //                 </Button>
  //               </InputGroup>
  //             </div>
  //           </SimpleBar>
  //         </DropdownMenu>
  //       </Flex>
  //     </div>
  //   </Popover>
  // );

  return (
    <Flex alignItems='center' justifyContent='between' className='w-100'>
      {loading ? (
        <>
          <span className='me-2 fs--2'>
            <FontAwesomeIcon icon='spinner' className='text-primary' spin />{' '}
            Loading more artifacts...
          </span>
        </>
      ) : (
        <span className='me-2 fs--2'>
          Showing {Number(documentsShowing).toLocaleString()} of{' '}
          {Number(documentsTotal).toLocaleString()} Artifacts
        </span>
      )}

      {/* <Flex direction='column' className='h-100'>
        <Overlay
          show={show}
          target={triggerRef.current}
          placement='bottom'
          containerPadding={20}
          rootClose={true}
          onHide={() => setShow(false)}>
          {popover}
        </Overlay>

        <Button
          ref={triggerRef}
          onClick={handleToggle}
          size='sm'
          className='p-0 m-0 fs--2 rounded-circle text-700 hover-900'
          variant='link'
          style={{ height: '16px', width: '16px' }}>
          <FontAwesomeIcon icon='cog' width={16} height={16} />
        </Button>
      </Flex> */}
    </Flex>
  );
};

export default ExploreTableFooter;
