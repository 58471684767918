import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import RangeCustomSelect from 'components/common/query-filters/NavigatorFilters/RangeCustomSelect/index';
import RangePresetSelect from 'components/common/query-filters/NavigatorFilters/UpdateRangePreset';
import useExplore from 'hooks/useExplore';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Overlay, Popover } from 'react-bootstrap';

/**
 * Renders a time button for the data navigator.
 *
 * @returns {JSX.Element} The rendered time button
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.4
 */
const TimeRangeButton = () => {
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);

  const [show, setShow] = useState(false);

  const {
    state: {
      timeRange: { from, to },
      timeRangeSelected
    }
  } = useExplore();

  /**
   * Toggles the value of `show`.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleToggle = () => {
    // Toggle the value of `show`
    setShow(!show);
  };

  /**
   * Handles the click outside event.
   *
   * @param {Event} e - The click event object
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleClickOutside = e => {
    if (
      triggerRef.current &&
      !triggerRef.current.contains(e.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      // Close the dropdown if the click is outside the dropdown
      setShow(false);
    }
  };

  /**
   * Adds an event listener to the document to handle clicks outside the dropdown.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    // Add the event listener when the component is mounted
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Remove the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const popover = (
    <Popover id='popover-dropdown' style={{ minWidth: '400px' }}>
      <div
        ref={dropdownRef}
        className='p-2 w-100 d-flex flex-column align-items-start justify-content-start'>
        <RangePresetSelect setShow={setShow} />
        {timeRangeSelected.unit === 'custom' && (
          <RangeCustomSelect setShow={setShow} />
        )}
      </div>
    </Popover>
  );

  return (
    <Flex justifyContent='center' style={{ width: '100%' }}>
      <div className='d-inline-flex align-items-center position-relative'>
        <Overlay
          show={show}
          target={triggerRef.current}
          placement='bottom'
          containerPadding={20}
          rootClose={true}
          onHide={() => setShow(false)}>
          {popover}
        </Overlay>

        <AdvancedPopover
          placement='bottom'
          showArrow={true}
          popoverText='Select a time range'>
          <Button
            ref={triggerRef}
            id='timeRangeDropdown'
            onClick={handleToggle}
            size='sm'
            tabIndex='3'
            className='border-0 border-none new-custom-dropdown-at-panel px-0 bg-transparent text-700 hover-900'
            variant='outline-transparent'>
            <Flex
              justifyContent='center'
              alignItems='center'
              className={'text-700 hover-900'}>
              <FontAwesomeIcon
                icon='calendar-alt'
                transform='shrink-3 up-1'
                className='me-2'
              />
              {from && to ? (
                <Flex>
                  <Flex
                    direction='row'
                    justifyContent='center'
                    alignItems='center'>
                    <small>
                      <span className='py-1 fs--1 fw-normal'>
                        {from
                          ? moment(from).format('YYYY-MM-DDTHH:mm:ss')
                          : 'Start Time'}
                      </span>
                    </small>
                  </Flex>
                  <Flex
                    direction='row'
                    justifyContent='center'
                    alignItems='center'>
                    <span className='mx-1 py-1 fs--1 fw-normal'>-</span>
                  </Flex>
                  <Flex
                    direction='row'
                    justifyContent='center'
                    alignItems='center'>
                    <small>
                      <span className='py-1 fs--1 fw-normal'>
                        {to
                          ? moment(to).format('YYYY-MM-DDTHH:mm:ss')
                          : 'End Time'}
                      </span>
                    </small>
                  </Flex>
                </Flex>
              ) : (
                <Flex>
                  <Flex
                    direction='row'
                    justifyContent='center'
                    alignItems='center'>
                    <span className='py-1 fs--1 fw-normal text-700'>
                      No time range selected
                    </span>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Button>
        </AdvancedPopover>
      </div>
    </Flex>
  );
};

export default TimeRangeButton;
