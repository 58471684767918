import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import useReleases from 'hooks/admin-contexts/useReleases';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Modal } from 'react-bootstrap';
import FormReleaseNoteAdd from '../forms/FormReleaseNoteAdd';
/**
 * Renders the ModalReleaseNoteAdd component
 *
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ModalReleaseNoteAdd = () => {
  const {
    application: { isDark }
  } = useApplication();

  const { addReleaseNoteModal, setReleases } = useReleases();

  return (
    <Modal
      centered
      size='md'
      animation={false}
      backdrop='static'
      keyboard={false}
      show={addReleaseNoteModal?.open}
      onHide={() =>
        setReleases(prevState => ({
          ...prevState,
          addReleaseNoteModal: { open: false }
        }))
      }
      aria-labelledby='modalReleaseNoteAdd'>
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Add a release note
            <AdvancedPopover
              containerId='resetConfirmModal'
              placement='top'
              popoverText='Add a release note'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
        </Modal.Header>
        <Modal.Body className='p-2'>
          <FormReleaseNoteAdd />
        </Modal.Body>
      </Flex>
    </Modal>
  );
};

export default ModalReleaseNoteAdd;
