import React from 'react';
import ManageLayout from './ManageLayout';

const Manage = () => {
  return (
    <>
      <ManageLayout />
    </>
  );
};

export default Manage;
