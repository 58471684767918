import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import useUsers from 'hooks/admin-contexts/useUsers';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Modal } from 'react-bootstrap';
import ResetPasswordForm from '../forms/UsersResetPasswordForm';
/**
 * Renders the ResetPasswordModal component
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ResetPasswordModal = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    users: { resetPasswordModal },
    setUsers
  } = useUsers();

  return (
    <Modal
      centered
      size={'lg'}
      animation={false}
      backdrop='static'
      keyboard={false}
      show={resetPasswordModal?.open}
      onHide={() =>
        setUsers(prevState => ({
          ...prevState,
          resetPasswordModal: { open: false }
        }))
      }
      aria-labelledby='modalUserAdd'>
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Reset Password: '{resetPasswordModal?.user?.profile?.fullName}'
            <AdvancedPopover
              containerId='resetConfirmModal'
              placement='top'
              popoverText='Reset Password'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
        </Modal.Header>

        <Modal.Body className='p-2'>
          <ResetPasswordForm />
        </Modal.Body>
      </Flex>
    </Modal>
  );
};

export default ResetPasswordModal;
