import { useCluster } from 'hooks/admin-contexts/useCluster';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useCountUp } from 'react-countup';

const RelocatingShards = () => {
  const {
    cluster: {
      clusterHealth: { relocating_shards, status }
    }
  } = useCluster();
  const [relocatingShardsCounter, setRelocatingShardsCounter] = useState(0);

  const memoizedCallback = useCallback(value => {
    return value.toLocaleString();
  }, []);

  const { update } = useCountUp({
    ref: 'relocatingShardsCounter',
    decimals: 0,
    duration: 2.75,
    end: relocatingShardsCounter || 0,
    formattingFn: memoizedCallback,
    preserveValue: true
  });

  useEffect(() => {
    setRelocatingShardsCounter(relocating_shards);
    update(relocating_shards);
  }, [relocating_shards]);

  return (
    <Card className='h-100 shadow-none border'>
      {!status ? (
        <div className='position-absolute d-flex justify-content-center align-items-center w-100 navbar-glass-loader'>
          <Spinner animation='border' />
        </div>
      ) : null}
      <Card.Body className='p-2'>
        <Row className='g-0'>
          <Col className='d-flex flex-column justify-content-between'>
            <h6 className='mb-2'>Relocating Shards</h6>
            <h4 className='fs-2 fw-normal my-0'>
              <span id='relocatingShardsCounter' className='font-monospace' />
            </h4>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RelocatingShards;
