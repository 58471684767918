import React from 'react';
import PropTypes from 'prop-types';
import Background from 'components/common/Background';
import Avatar from 'components/common/Avatar';
import { Card } from 'react-bootstrap';
import { getUserStatusSlug } from 'helpers/utils';

const ProfileContainerHeader = ({ avatar, banner, lastSeenAt }) => {
  const status = getUserStatusSlug(lastSeenAt);

  return (
    <Card.Header
      className={'position-relative mb-1'}
      style={{ height: '100px' }}>
      <Background
        image={banner || process.env.REACT_APP_DEFAULT_USER_BANNER_IMG}
      />
      <Avatar
        size='4xl'
        className={`position-absolute bottom-0 start-4 ${
          status !== 'offline' && `status-${status}`
        }`}
        style={{ marginBottom: '-1rem' }}
        src={avatar || process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG}
      />
    </Card.Header>
  );
};

const ProfileContainerBody = ({ children }) => {
  return <Card.Body>{children}</Card.Body>;
};

const ProfileContainer = ({ children }) => {
  return <Card className='shadow-none overflow-hidden'>{children}</Card>;
};

ProfileContainer.Header = ProfileContainerHeader;
ProfileContainer.Body = ProfileContainerBody;

ProfileContainerHeader.propTypes = {
  avatar: PropTypes.string,
  banner: PropTypes.string,
  lastSeenAt: PropTypes.number || PropTypes.string
};

ProfileContainerBody.propTypes = {
  children: PropTypes.node.isRequired
};

ProfileContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProfileContainer;
