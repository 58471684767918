import Flex from 'components/common/Flex';

import FalconLink from 'components/common/FalconLink';
import SimpleSpinner from 'components/utilities/AppSpinner/smallSpinner';
import PropTypes from 'prop-types';

import { routes } from 'config/routeSettings';

import React from 'react';

const AllArtifacts = React.memo(({ statHistogram, wrapperLoader }) => {
  return (
    <Flex direction='column' className='pt-0 pe-3 pe-xxl-10'>
      <h6 className='mt-0 ms-0 position-relative text-nowrap text-700 fw-normal'>
        Total Artifacts
        {wrapperLoader && (
          <span
            className='fw-light position-absolute  end-0 z-index-2'
            style={{ marginTop: '28%' }}>
            <SimpleSpinner size='sm' />
          </span>
        )}
      </h6>
      <Flex direction='row' className='mb-2'>
        <h3 className='mb-0 me-2 fs-2 text-700 fw-700 lh-1 text-start align-items-center'>
          {statHistogram?.data && Array.isArray(statHistogram?.data)
            ? Number(
                statHistogram?.data?.reduce(
                  (sum, entry) => sum + entry.doc_count,
                  0
                )
              ).toLocaleString()
            : 0}
        </h3>
      </Flex>
      <div>
        <FalconLink
          title='Explore'
          className='px-0 fw-medium'
          to={routes.ARTIFACTS_EXPLORE}
        />
      </div>
    </Flex>
  );
});

AllArtifacts.propTypes = {
  wrapperLoader: PropTypes.bool,

  statHistogram: PropTypes.object
};
export default AllArtifacts;
