import { useContext } from 'react';
import { AlertsContext } from 'context/admin-contexts/AlertsProvider';

/**
 * Custom hook to use the AlertsContext
 * @returns {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const useAlerts = () => {
  return useContext(AlertsContext);
};

export default useAlerts;
