import React from 'react';
import PropTypes from 'prop-types';
import useReleases from 'hooks/admin-contexts/useReleases';
import useAuthentication from 'hooks/useAuthentication';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const allowedRoles = ['admin', 'super-admin'];

/**
 * Renders the ReleaseNoteActions component
 *
 * @param {object} props
 * @param {object} props.note
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ReleaseNoteActions = ({ note }) => {
  const { setReleases } = useReleases();

  const {
    authentication: { roles }
  } = useAuthentication();

  return (
    <>
      {roles.some(role => allowedRoles.includes(role.slug)) && (
        <>
          <OverlayTrigger
            placement='top'
            overlay={
              <Popover>
                <Popover.Body className='text-center p-1 fs--1 text-700'>
                  {note.visible
                    ? 'Visible to everyone'
                    : 'Hidden for non-admin users'}
                </Popover.Body>
              </Popover>
            }>
            {note.visible ? (
              <div>
                <Button
                  size='sm'
                  variant='none'
                  bg='transparent'
                  disabled
                  className='px-2 text-1100 outline-none border-none shadow-none'>
                  <FontAwesomeIcon icon='eye' />
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  size='sm'
                  variant='none'
                  bg='transparent'
                  disabled
                  className='px-2 text-secondary outline-none border-none shadow-none'>
                  <FontAwesomeIcon icon='eye-slash' />
                </Button>
              </div>
            )}
          </OverlayTrigger>
          <OverlayTrigger
            placement='top'
            overlay={
              <Popover>
                <Popover.Body className='text-center p-1 fs--1 text-700'>
                  Edit release note
                </Popover.Body>
              </Popover>
            }>
            <Button
              size='sm'
              variant='link'
              className='cursor-pointer px-2'
              onClick={() =>
                setReleases(prevState => ({
                  ...prevState,
                  editReleaseNoteModal: { open: true, data: note }
                }))
              }>
              <FontAwesomeIcon icon='pencil' />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement='top'
            overlay={
              <Popover>
                <Popover.Body className='text-center p-1 fs--1 text-700'>
                  Delete release note
                </Popover.Body>
              </Popover>
            }>
            <Button
              size='sm'
              variant='link'
              className='cursor-pointer px-2 text-danger'
              onClick={() =>
                setReleases(prevState => ({
                  ...prevState,
                  deleteReleaseNoteModal: { open: true, data: note }
                }))
              }>
              <FontAwesomeIcon icon='trash-alt' />
            </Button>
          </OverlayTrigger>
        </>
      )}
    </>
  );
};

ReleaseNoteActions.propTypes = {
  note: PropTypes.shape({
    visible: PropTypes.bool.isRequired
  }).isRequired
};

export default ReleaseNoteActions;
