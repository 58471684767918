import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import useApplication from 'hooks/useApplication';
import useExplore from 'hooks/useExplore';
import React from 'react';
import { Col, ListGroup, Offcanvas, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

/**
 * Renders an offcanvas component that displays GPT data response for a field.
 *
 * @returns {JSX.Element} The rendered offcanvas component
 */
const GptDataOffcanvas = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    state: {
      gptDataResponse,
      gptDataError,
      gptDataLoading,
      gptDataModalShow,
      gptDataSelectedValue
    },
    setState
  } = useExplore();

  /**
   * Closes the GPT data offcanvas.
   */
  const handleClose = () => {
    // Reset the state
    setState('gptDataLoading', false);
    setState('gptDataModalShow', false);
  };

  return (
    <>
      <Offcanvas
        show={gptDataModalShow}
        onHide={handleClose}
        placement='end'
        className='border-0 offcanvas-responsive'>
        <Offcanvas.Header
          className='bg-card'
          closeButton
          closeVariant={isDark ? 'white' : 'black'}>
          <Offcanvas.Title as='div' className='py-0 flex-grow-1 mt-1 mb-0 pb-0'>
            <h4 className='fs-0 fw-normal text-700 m-0'>
              {gptDataLoading ? (
                <>
                  <span>Fetching details for:</span> <br />
                </>
              ) : gptDataError ? (
                <>
                  <span>Error retrieving details for:</span> <br />
                </>
              ) : (
                <>
                  <span>Details for:</span> <br />
                </>
              )}
              <code>{gptDataSelectedValue}</code>
            </h4>
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className='scrollbar mt-0'>
          <Row>
            <Col>
              {gptDataLoading ? (
                <Flex justifyContent='around' alignItems='center'>
                  <FontAwesomeIcon
                    className='text-primary'
                    fade={true}
                    icon={['fas', 'spinner']}
                    spin={true}
                    transform='grow-10'
                  />
                </Flex>
              ) : gptDataError ? (
                <Flex direction='column' justifyContent='start'>
                  <h6 className='fs--1 text-700'>An error occurred:</h6>
                  <p className='fs--1 mb-0 text-700'>{gptDataError}</p>
                </Flex>
              ) : (
                <ListGroup variant='flush'>
                  <ListGroup.Item className='bg-transparent text-wrap fs--1'>
                    {gptDataResponse ? (
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          li: ({ children }) => (
                            <li className='text-700 pb-2'>{children}</li>
                          ),
                          h1: ({ children }) => (
                            <h1 className='text-900 fs-2 mb-3'>{children}</h1>
                          ),
                          h2: ({ children }) => (
                            <h2 className='text-900 fs-1 mb-3'>{children}</h2>
                          ),
                          h3: ({ children }) => (
                            <h3 className='text-900 fs-0 mb-3'>{children}</h3>
                          ),
                          p: ({ children }) => (
                            <p className='text-700'>{children}</p>
                          ),
                          strong: ({ children }) => (
                            <strong className='text-900'>{children}</strong>
                          ),
                          b: ({ children }) => (
                            <b className='text-900'>{children}</b>
                          )
                        }}>
                        {gptDataResponse}
                      </ReactMarkdown>
                    ) : (
                      'No additional information available for this term.'
                    )}
                  </ListGroup.Item>
                </ListGroup>
              )}
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default GptDataOffcanvas;
