import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import OrgProfileBanner from 'components/common/profile-cards/OrganizationProfileCard/Profile';
import React from 'react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
const { default: Flex } = require('components/common/Flex');

/**
 * @param {Function} setStatuses
 * @param {Boolean} isPrivacyMode
 * @returns {Array}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const getStatusColumns = (setStatuses, isPrivacyMode) => {
  const columns = [
    {
      accessor: 'name',
      Header: 'Organization',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 align-middle  text-nowrap'
      },
      Cell: rowData => {
        const {
          name,
          id,
          profile: { media: { avatar } = {} } = {},
          legacy
        } = rowData.row.original;
        return (
          <Flex direction='row' alignItems='center'>
            <OverlayTrigger
              placement='top'
              trigger={['click']}
              rootClose={true}
              delay={{ show: 0 }}
              popperConfig={{
                modifiers: [
                  {
                    name: 'offset',
                    enabled: true,
                    options: {
                      offset: [120, 8]
                    }
                  }
                ]
              }}
              overlay={
                <Popover
                  style={{
                    minWidth: '320px',
                    margin: 0
                  }}
                  id={`popover-basic-${id}`}>
                  <Popover.Body className='p-0'>
                    <OrgProfileBanner org={rowData?.row?.original} />
                  </Popover.Body>
                </Popover>
              }>
              {({ ref, ...triggerHandler }) => (
                <Flex
                  direction='row'
                  alignItems='center'
                  {...triggerHandler}
                  className='cursor-pointer'>
                  <div
                    ref={ref}
                    className={`d-inline-flex align-items-center me-2`}
                    style={{ whiteSpace: 'nowrap' }}>
                    <Avatar
                      size='2xl'
                      src={
                        avatar || process.env.REACT_APP_DEFAULT_ORG_AVATAR_IMG
                      }
                    />
                  </div>
                  <span className='fs--1 text-700'>
                    {isPrivacyMode ? legacy?.clientTag : name}{' '}
                    {id === '648371d0a87d7ad0a0367cfa' ? (
                      <FontAwesomeIcon
                        icon={['fas', 'clover']}
                        transform='rotate-45'
                        className='text-primary fs--1'
                      />
                    ) : null}
                  </span>
                </Flex>
              )}
            </OverlayTrigger>
          </Flex>
        );
      }
    },
    {
      accessor: 'Monthly',
      Header: 'Monthly',
      noTruncate: true,
      hasRender: true,
      disableSortBy: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap text-center'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap text-center'
      },
      Cell: rowData => {
        const { name, id, legacy, legacyReports } = rowData.row.original;
        return legacyReports.monthly !== null ? (
          <Flex direction='row' alignItems='center' justifyContent={'center'}>
            <Form.Check
              type='switch'
              className='mb-0'
              checked={legacyReports.monthly ? true : false}
              onChange={e =>
                setStatuses(prevState => ({
                  ...prevState,
                  statusToggleModal: {
                    name: isPrivacyMode ? legacy?.clientTag : name,
                    open: true,
                    check: e.target.checked,

                    id: id,
                    reportType: 'monthly',
                    enable: e.target.checked ? 'enable' : 'disable'
                  }
                }))
              }
            />
          </Flex>
        ) : (
          <Flex direction='row' alignItems='center' justifyContent={'center'}>
            -
          </Flex>
        );
      }
    },
    {
      accessor: 'Daily-Google',
      Header: 'Daily-Google',
      noTruncate: true,
      hasRender: true,
      disableSortBy: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap text-center'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap text-center'
      },
      Cell: rowData => {
        const { name, id, legacy, legacyReports } = rowData.row.original;
        return legacyReports.dailyGoogle !== null ? (
          <Flex direction='row' alignItems='center' justifyContent={'center'}>
            <Form.Check
              type='switch'
              className='mb-0'
              checked={legacyReports.dailyGoogle ? true : false}
              onChange={e =>
                setStatuses(prevState => ({
                  ...prevState,
                  statusToggleModal: {
                    name: isPrivacyMode ? legacy?.clientTag : name,
                    open: true,
                    check: e.target.checked,

                    id: id,
                    reportType: 'daily-google',
                    enable: e.target.checked ? 'enable' : 'disable'
                  }
                }))
              }
            />
          </Flex>
        ) : (
          <Flex direction='row' alignItems='center' justifyContent={'center'}>
            -
          </Flex>
        );
      }
    },
    {
      accessor: 'Daily-O365',
      Header: 'Daily-O365',
      noTruncate: true,
      hasRender: true,
      disableSortBy: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap text-center'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap text-center'
      },

      Cell: rowData => {
        const { name, id, legacy, legacyReports } = rowData.row.original;
        return legacyReports.dailyO365 !== null ? (
          <Flex direction='row' alignItems='center' justifyContent={'center'}>
            <Form.Check
              type='switch'
              className='mb-0'
              checked={legacyReports.dailyO365 ? true : false}
              onChange={e => {
                setStatuses(prevState => ({
                  ...prevState,
                  statusToggleModal: {
                    name: isPrivacyMode ? legacy?.clientTag : name,
                    open: true,
                    check: e.target.checked,
                    id: id,
                    reportType: 'daily-o365',
                    enable: e.target.checked ? 'enable' : 'disable'
                  }
                }));
              }}
            />
          </Flex>
        ) : (
          <Flex direction='row' alignItems='center' justifyContent={'center'}>
            -
          </Flex>
        );
      }
    },
    {
      accessor: 'Daily-Behavioral',
      Header: 'Daily-Behavioral',
      noTruncate: true,
      hasRender: true,
      disableSortBy: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap text-center'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap text-center'
      },
      Cell: rowData => {
        const { name, id, legacy, legacyReports } = rowData.row.original;
        return legacyReports.dailyBehavioral !== null ? (
          <Flex direction='row' alignItems='center' justifyContent={'center'}>
            <Form.Check
              type='switch'
              className='mb-0'
              checked={legacyReports.dailyBehavioral ? true : false}
              onChange={e =>
                setStatuses(prevState => ({
                  ...prevState,
                  statusToggleModal: {
                    name: isPrivacyMode ? legacy?.clientTag : name,
                    open: true,
                    check: e.target.checked,
                    id: id,
                    reportType: 'daily-behavioral',
                    enable: e.target.checked ? 'enable' : 'disable'
                  }
                }))
              }
            />
          </Flex>
        ) : (
          <Flex direction='row' alignItems='center' justifyContent={'center'}>
            -
          </Flex>
        );
      }
    },
    {
      accessor: 'Daily-Signature',
      Header: 'Daily-Signature',
      noTruncate: true,
      hasRender: true,
      disableSortBy: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap text-center'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap text-center'
      },
      Cell: rowData => {
        const { name, id, legacy, legacyReports } = rowData.row.original;
        return legacyReports.dailySignature !== null ? (
          <Flex direction='row' alignItems='center' justifyContent={'center'}>
            <Form.Check
              type='switch'
              className='mb-0'
              checked={legacyReports.dailySignature ? true : false}
              onChange={e =>
                setStatuses(prevState => ({
                  ...prevState,
                  statusToggleModal: {
                    name: isPrivacyMode ? legacy?.clientTag : name,
                    open: true,
                    check: e.target.checked,
                    id: id,
                    reportType: 'daily-signature',
                    enable: e.target.checked ? 'enable' : 'disable'
                  }
                }))
              }
            />
          </Flex>
        ) : (
          <Flex direction='row' alignItems='center' justifyContent={'center'}>
            -
          </Flex>
        );
      }
    },
    {
      accessor: 'Daily-Windows',
      Header: 'Daily-Windows',
      noTruncate: true,
      hasRender: true,
      disableSortBy: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap text-center'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap text-center'
      },
      Cell: rowData => {
        const { name, id, legacy, legacyReports } = rowData.row.original;
        return legacyReports.dailyWindows !== null ? (
          <Flex direction='row' alignItems='center' justifyContent={'center'}>
            <Form.Check
              type='switch'
              className='mb-0'
              checked={legacyReports.dailyWindows ? true : false}
              onChange={e =>
                setStatuses(prevState => ({
                  ...prevState,
                  statusToggleModal: {
                    name: isPrivacyMode ? legacy?.clientTag : name,
                    open: true,
                    check: e.target.checked,
                    id: id,
                    reportType: 'daily-windows',
                    enable: e.target.checked ? 'enable' : 'disable'
                  }
                }))
              }
            />
          </Flex>
        ) : (
          <Flex direction='row' alignItems='center' justifyContent={'center'}>
            -
          </Flex>
        );
      }
    }
  ];

  return columns;
};
