import Flex from 'components/common/Flex';
import { routes } from 'config/routeSettings';
import PropTypes from 'prop-types';
import { Badge, Button, FormControl, Table } from 'react-bootstrap';

import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { dynamicDashboardRoutes } from 'components/dashboards/Artifacts/Dynamic/routes';
import { filtersOperators } from 'context/FiltersProvider/settings';
import { truncateMiddle } from 'helpers/utils';
import useSavedSearches from 'hooks/admin-contexts/useSavedSearches';
import useApplication from 'hooks/useApplication';
import { truncate } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 *
 * @returns {JSX.Element}
 *
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const renderExpandedRow = row => {
  return (
    <div className='fs--1 ps-3 ms-1'>
      <Flex direction='column' className='mb-1 gap-2'>
        {/* Description */}
        {row?.description && (
          <div>
            <div className='text-900'>Description</div>
            {row.description && (
              <span className='text-700'>{row.description}</span>
            )}
          </div>
        )}

        {/* Display Indices */}
        {row?.indices?.length > 0 && (
          <div>
            <div className='text-900'>Indices</div>
            {row.indices.map((index, i) => (
              <span key={i} className='text-700'>
                {index.alias}
              </span>
            ))}
          </div>
        )}

        {/* Display Filters */}
        {row?.filters?.length > 0 && (
          <div>
            <div className='text-900 mb-1'>Filters</div>
            <Flex
              direction='row'
              wrap='wrap'
              alignContent='center'
              alignItems='center'
              justifyContent='start'
              className='h-100'>
              {row.filters?.map((filter, index) => (
                <Badge
                  key={index}
                  bg={classNames({
                    'soft-pastel-gray': !filter.enabled,
                    'soft-pastel-green':
                      (filter.inclusion &&
                        !filtersOperators[filter?.operator]?.isNegation) ||
                      (!filter.inclusion &&
                        filtersOperators[filter?.operator]?.isNegation),
                    'soft-pastel-yellow':
                      (!filter.inclusion &&
                        !filtersOperators[filter?.operator]?.isNegation) ||
                      (filter.inclusion &&
                        filtersOperators[filter?.operator]?.isNegation)
                  })}
                  className={classNames(
                    'ps-1 fs--2 fw-normal cursor-default d-flex justify-content-center align-items-center border opacity-100 me-1 mb-1',
                    {
                      'text-pastel-gray hover-border-soft-pastel-gray hover-bg-pastel-gray-light':
                        !filter.enabled,
                      'text-pastel-green-dark hover-border-soft-pastel-green hover-bg-pastel-green-light':
                        (filter.inclusion &&
                          !filtersOperators[filter?.operator]?.isNegation) ||
                        (!filter.inclusion &&
                          filtersOperators[filter?.operator]?.isNegation),
                      'text-pastel-yellow-dark hover-border-soft-pastel-yellow hover-bg-pastel-yellow-light':
                        (!filter.inclusion &&
                          !filtersOperators[filter?.operator]?.isNegation) ||
                        (filter.inclusion &&
                          filtersOperators[filter?.operator]?.isNegation)
                    }
                  )}
                  style={{
                    textDecoration: filter.enabled ? 'none' : 'line-through'
                  }}>
                  {/* Show the pinned icon */}
                  {filter.pinned && (
                    <FontAwesomeIcon icon='thumb-tack' className='me-1' />
                  )}
                  {/* Display Custom Label or Field-Value Pair */}
                  {filter.useCustomLabel && filter.customLabel ? (
                    <span className='fw-normal'>{filter.customLabel}</span>
                  ) : (
                    <>
                      "{filter.field.accessor}"{' '}
                      {filter.inclusion
                        ? filtersOperators[filter?.operator]?.symbol
                        : filtersOperators[
                            filtersOperators[filter?.operator]?.inversion
                          ]?.symbol ||
                          filtersOperators[filter?.operator]?.symbol}{' '}
                      <span className='ms-1 fw-normal'>
                        "
                        {Array.isArray(filter.value)
                          ? filter.value.map((item, i) => (
                              <span key={i}>
                                {item}
                                {i !== filter.value.length - 1 ? ', ' : ''}
                              </span>
                            ))
                          : truncateMiddle(filter.value)}
                        "
                      </span>
                    </>
                  )}
                </Badge>
              ))}
            </Flex>
          </div>
        )}
        {row?.fields?.length > 0 && (
          <div>
            <div className='text-900'>Fields</div>
            <Flex
              direction='row'
              wrap='wrap'
              alignContent='center'
              alignItems='center'
              justifyContent='start'
              className='h-100 text-700'>
              {row.fields.map((field, index) => {
                return index < row.fields.length - 1
                  ? `${field.name}, `
                  : field.name;
              })}
            </Flex>
          </div>
        )}
        {row?._dashboard && (
          <div>
            <div className='text-900'>Dashboard</div>
            <Flex
              direction='row'
              wrap='wrap'
              alignContent='center'
              alignItems='center'
              justifyContent='start'
              className='h-100 text-700'>
              {row._dashboard}
            </Flex>
          </div>
        )}
      </Flex>
    </div>
  );
};

const SavedSearches = () => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const toggleExpand = index => {
    setExpandedRow(expandedRow === index ? null : index); // Toggle row expansion
  };

  const {
    // application: { isDark },
    setApplication
  } = useApplication();
  const {
    savedSearches: { data }
  } = useSavedSearches();

  // const { loadSavedSearch } = useSearchFilters();
  const navigate = useNavigate();

  const loadDashboard = savedSearch => {
    setApplication('showSavedSearchListModal', false);

    let dashboard = dynamicDashboardRoutes.find(
      item => item.slug === savedSearch._dashboard
    );
    navigate(
      `${dashboard ? dashboard.path : routes.ARTIFACTS_EXPLORE}?savedSearchId=${
        savedSearch.id
      }&nonce=${new Date().getTime()}`
    );
  };

  const filteredData = data.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className='position-relative'>
        <FormControl
          size='sm'
          type='text'
          placeholder='Filter by name...'
          className='mb-3'
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        {searchTerm && (
          <div
            className='position-absolute top-0 end-0'
            style={{ marginRight: '5px', marginTop: '2px' }}>
            <FalconCloseButton
              size='sm'
              className='fs--2 me-1'
              noOutline
              onClick={() => {
                setSearchTerm('');
              }}
            />
          </div>
        )}
      </div>
      <Table size='sm' className='mb-0'>
        <thead>
          <tr className='fs--1'>
            <th className='px-0' style={{ width: '20px' }}></th>
            <th className='px-0 text-900'>Name</th>
            <th className='px-0 text-900'>Created</th>
            <th className='px-0 text-900'>Last Used</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <React.Fragment key={index}>
              {/* Main Row */}
              <tr
                className={classNames('fs--1', {
                  'border-bottom-0': index === filteredData.length - 1
                })}>
                <td
                  className={classNames('text-center align-top py-0 px-0', {
                    'border-bottom-0': index === filteredData.length - 1
                  })}>
                  {/* Chevron for expanding/collapsing */}
                  <Button
                    variant='link'
                    className='p-0 px-2 text-700 hover-900'
                    onClick={() => toggleExpand(index)}>
                    <FontAwesomeIcon
                      icon={
                        expandedRow === index ? faChevronDown : faChevronRight
                      }
                      width='10'
                      className='cursor-pointer fs--2'
                      transform='down-1'
                    />
                  </Button>
                </td>
                <td
                  className={classNames('align-middle py-1 px-0', {
                    'border-bottom-0': index === filteredData.length - 1
                  })}
                  width={'60%'}>
                  {/* Title */}
                  <Button
                    variant='link'
                    size='sm'
                    className='text-decoration-none p-0 fs--1'
                    onClick={() => {
                      loadDashboard(item);
                    }}>
                    {item.name}
                    <FontAwesomeIcon
                      icon='external-link-alt'
                      transform='up-1'
                      className='fs--2 ms-1'
                    />
                  </Button>
                  {item.description && (
                    <div className='text-700'>
                      {truncate(item.description, { length: 64 })}
                    </div>
                  )}
                </td>
                <td
                  className={classNames('align-middle py-1 px-0', {
                    'border-bottom-0': index === filteredData.length - 1
                  })}>
                  {/* Created At */}
                  <div className='text-700'>
                    {moment(
                      item.createdAt,
                      'YYYY-MM-DDTHH:mm:ss.SSSZ'
                    ).fromNow()}
                  </div>
                </td>
                <td
                  className={classNames('align-middle py-1 px-0', {
                    'border-bottom-0': index === filteredData.length - 1
                  })}>
                  {/* Last Used */}
                  <div className='text-700'>
                    {moment(
                      item.lastUsedAt,
                      'YYYY-MM-DDTHH:mm:ss.SSSZ'
                    ).fromNow()}
                  </div>
                </td>
              </tr>

              {/* Expanded Row */}
              {expandedRow === index && (
                <tr>
                  <td colSpan='6' className='py-3 px-2'>
                    {renderExpandedRow(item)}{' '}
                    {/* Call the expanded row rendering function */}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </>
  );
};

SavedSearches.propTypes = {
  isDark: PropTypes.bool,
  setModal: PropTypes.func
};

export default SavedSearches;
