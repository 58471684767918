import classNames from 'classnames';
import Flex from 'components/common/Flex';
import useUsers from 'hooks/admin-contexts/useUsers';
import useApplication from 'hooks/useApplication';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import UpdateUserForm from '../forms/UsersEditForm';

/**
 * ModalUserEdit component renders a modal for editing user details.
 *
 * It utilizes the `useApplication` and `useUsers` hooks to manage state and context.
 * The modal can be toggled between full-screen and regular size.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.6
 * @since 0.1.0-beta.2
 */
const ModalUserEdit = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    users: { showUpdateUserModal },
    setUsers
  } = useUsers();

  useEffect(() => {
    return () => {
      setUsers(prevState => ({
        ...prevState,
        loading: false
      }));
    };
  }, []);

  return (
    <Modal
      size={showUpdateUserModal?.fullScreen ? 'xl' : 'lg'}
      backdrop='static'
      keyboard={false}
      show={showUpdateUserModal?.open}
      onHide={() =>
        setUsers(prevState => ({
          ...prevState,
          showUpdateUserModal: { open: false }
        }))
      }
      aria-labelledby='modalUserEdit'
      contentClassName={classNames({
        'border-0 border-top-left-radius-0 border-top-right-radius-0 h-100':
          showUpdateUserModal?.fullScreen
      })}
      dialogClassName={classNames('pt-5', {
        'position-absolute all-0 fullscreen-modal-dialog scrollbar overflow-x-hidden':
          showUpdateUserModal?.fullScreen
      })}>
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Edit User
          </h5>
        </Modal.Header>
        <Modal.Body className='p-2'>
          <UpdateUserForm />
        </Modal.Body>
      </Flex>
    </Modal>
  );
};

export default ModalUserEdit;
