// import MultiSelect from 'components/common/MultiSelect';
import Flex from 'components/common/Flex';
import { useSearchFilters } from 'context/FiltersProvider';
import useApplication from 'hooks/useApplication';
import useExplore from 'hooks/useExplore';

import React, { useRef } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

/**
 * close the Save Search
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const confirmClosesavedSearchModel = () => {
  const modalRef = useRef(null);

  const {
    application: { isDark, confirmClosesavedSearchModel },
    setApplication
  } = useApplication();

  const { setState } = useExplore();
  const { setSearchFilters } = useSearchFilters();
  return (
    <Modal
      aria-labelledby='addSavedSearchModal'
      centered
      contentClassName='shadow-none'
      keyboard={true}
      onHide={() => setApplication('confirmClosesavedSearchModel', false)}
      ref={modalRef}
      show={confirmClosesavedSearchModel}
      size='md'>
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex align-items-center fs-0 fw-normal position-relative'>
            Close Saved Search{' '}
          </h5>
        </Modal.Header>

        <Modal.Body className='p-2'>
          Are you sure to close this search?
        </Modal.Body>
        <Modal.Footer className='p-2'>
          <Row className='g-1 m-0 p-0 w-100'>
            <Col xs={12} className='p-0'>
              <Flex direction='row' justifyContent='end'>
                <Button
                  size='sm'
                  variant='secondary'
                  className='me-2'
                  onClick={() =>
                    setApplication('confirmClosesavedSearchModel', false)
                  }>
                  Cancel
                </Button>
                <Button
                  variant='warning'
                  type='submit'
                  size='sm'
                  onClick={() => {
                    setState('savedSearch', {});
                    setState('filters', []);
                    setSearchFilters(prev => ({
                      ...prev,
                      filters: []
                    }));
                    const { pathname } = window.location;
                    window.history.replaceState({}, '', pathname);
                    setApplication('confirmClosesavedSearchModel', false);
                  }}
                  className='d-flex'>
                  Close
                </Button>
              </Flex>
            </Col>
          </Row>
        </Modal.Footer>
      </Flex>
    </Modal>
  );
};

export default confirmClosesavedSearchModel;
