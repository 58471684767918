import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import TeamsWrapper from './TeamsWrapper';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Teams = () => {
  return (
    <>
      <WidgetSectionTitle title='Team Management' className='mb-2' />
      <TeamsWrapper />
    </>
  );
};

export default Teams;
