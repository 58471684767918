import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import { areObjectsSame } from 'helpers/utils';
import useApplication from 'hooks/useApplication';
import { useESGlobalFilters } from 'hooks/useGlobalFilters';
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import BasicControls from './BasicControls';
import QueryFiltersTabs from './QueryFiltersTabs';
import ConfirmModal from './confirmModals/confirmCloseModal';

/**
 * Renders the QueryFiltersLayout component
 * This component displays a modal with filtering options
 * It manages the state of the modal and handles hiding the filters
 *
 * @returns {JSX.Element} The rendered QueryFiltersLayout component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const QueryFiltersLayout = () => {
  const [modalState, setModalState] = useState({
    open: false,
    fullScreen: false
  });
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const { esGlobalFilters, setESGlobalFilters } = useESGlobalFilters();

  const {
    application: { isDark, showGlobalFilters },
    setApplication
  } = useApplication();

  /**
   * Sets the modal state to the value of the showGlobalFilters property in the ESGlobalFilters state
   */
  useEffect(() => {
    setModalState(prevState => ({
      ...prevState,
      open: showGlobalFilters
    }));
  }, [showGlobalFilters]);

  /**
   * Hides the filters and updates the state accordingly.
   */
  const hideFilters = () => {
    let newState = { ...esGlobalFilters };
    let oldState = { ...newState.globalFiltersReset };
    delete newState.globalFiltersReset;
    delete oldState.globalFiltersReset;
    delete newState.Config;
    delete oldState.Config;
    delete newState.showGlobalFilters;
    delete oldState.showGlobalFilters;

    let isEqual = areObjectsSame(oldState, newState);

    if (isEqual?.areEqual) {
      setESGlobalFilters(prevState => {
        return {
          ...prevState,
          globalFiltersReset: {}
        };
      });
      setApplication('showGlobalFilters', false);
    } else {
      setShowDeleteConfirmModal(true);
    }
  };

  return (
    <>
      <Modal
        aria-labelledby='activityModal'
        size={modalState?.fullScreen ? 'xl' : 'lg'}
        show={modalState?.open}
        animation={false}
        backdrop='static'
        keyboard={false}
        onHide={() => {
          hideFilters();
        }}
        contentClassName={
          modalState?.fullScreen &&
          'border-0 border-top-left-radius-0 border-top-right-radius-0 h-100'
        }
        dialogClassName={
          modalState?.fullScreen &&
          'position-absolute all-0 fullscreen-modal-dialog scrollbar overflow-x-hidden'
        }>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Filtering Options
            <AdvancedPopover
              containerId='FilteringModal'
              placement='top'
              popoverText='Filtering'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
        </Modal.Header>

        <Modal.Body className='p-2'>
          <Row className={`g-0 p-3 pb-2 border border-top-0`}>
            <Col>
              <BasicControls />
            </Col>
          </Row>
          <Row className={`g-2`}>
            <Col>
              <QueryFiltersTabs />
            </Col>
          </Row>
        </Modal.Body>
        <ConfirmModal
          showDeleteConfirmModal={showDeleteConfirmModal}
          setShowDeleteConfirmModal={setShowDeleteConfirmModal}
        />
      </Modal>
    </>
  );
};

export default QueryFiltersLayout;
