import moment from 'moment';
import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import useAuthentication from './useAuthentication';
import useLock from './useLock';
import useRefreshToken from './useRefreshToken';

/**
 * Detect user inactivity and log them out after a set amount of time.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 *
 * @example
 * import useIdleDetection from 'hooks/useIdleDetection';
 * useIdleDetection();
 */
export const useIdleDetection = () => {
  const {
    authentication: { id, session, refreshing },
    setAuthentication
  } = useAuthentication();

  const { refresh } = useRefreshToken();
  const lock = useLock();

  /**
   * Handles the prompt event.
   * Sets a timeout to show the idle modal after 5 seconds.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const onPrompt = () => {
    setTimeout(() => {
      setAuthentication(prevState => ({
        ...prevState,
        showIdleModal: true
      }));
    }, 5000);
  };

  /**
   * Handles the idle event.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const onIdle = async () => {
    if (!id) return;
    await lock();
    setAuthentication(prevState => ({
      ...prevState,
      showIdleModal: false
    }));
  };

  /**
   * Handles the logic when the user becomes active again
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const onActive = () => {
    if (id && session?.device?.isVerified && !session?.device?.sessionLocked) {
      start();
      setAuthentication(prevState => ({
        ...prevState,
        showIdleModal: false,
        idleTimeoutAt: new moment().add(
          getRemainingTime() - 1000,
          'milliseconds'
        )
      }));
    }
  };

  /**
   * Handles the action event.
   * If the session is verified, not locked, and has an ID, it starts the idle timer and updates the authentication state.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const onAction = () => {
    if (id && session?.device?.isVerified && !session?.device?.sessionLocked) {
      start();
      setAuthentication(prevState => ({
        ...prevState,
        showIdleModal: false,
        idleTimeoutAt: new moment().add(
          getRemainingTime() - 1000,
          'milliseconds'
        )
      }));
    }
  };

  /**
   * Custom hook for idle detection.
   *
   * @returns {{
   *   start: Function,
   *   getRemainingTime: Function,
   *   getLastActiveTime: Function,
   *   isIdle: boolean
   * }}
   *
   * @see {@link https://idletimer.dev/docs/features/idle-detection|Idle Timer Documentation}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const { start, getRemainingTime, getLastActiveTime, isIdle } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    // timeout: 60000,
    // promptBeforeIdle: 55000,
    timeout:
      1000 * 60 * process.env?.REACT_APP_TIMEOUT_IN_MINS || 1000 * 60 * 30,
    promptBeforeIdle:
      1000 * 60 * process.env?.REACT_APP_PROMPT_FOR_MINS || 1000 * 60 * 2,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0, // Set debounce to a higher value (e.g., 1000 milliseconds)
    throttle: 10000, // Set throttle to a higher value (e.g., 1000 milliseconds)
    eventsThrottle: 10000,
    element: document,
    startManually: true,
    stopOnIdle: false,
    crossTab: true,
    name: 'leargas-idle-timer',
    syncTimers: 0,
    leaderElection: false
  });

  /**
   * Starts the idle timer when the users session is verified
   */
  useEffect(() => {
    if (id && session?.device?.isVerified && !session?.device?.sessionLocked) {
      start();
      setAuthentication(prevState => ({
        ...prevState,
        idleTimeoutAt: new moment().add(
          getRemainingTime() - 1000,
          'milliseconds'
        )
      }));
    }
  }, [id]);

  /**
   * Auto-refreshes the user's access token when the user is not idle
   */
  useEffect(() => {
    const interval = setInterval(
      async () => {
        if (
          id &&
          !refreshing &&
          !isIdle() &&
          !moment(getLastActiveTime()).isBefore(moment().subtract(1, 'minute'))
        ) {
          await refresh();
        }
      },
      1000 * process.env.REACT_APP_REFRESH_INTERVAL_IN_SECS || 1000 * 60
    );
    return () => {
      clearInterval(interval);
    };
  }, []);
};

export default useIdleDetection;
