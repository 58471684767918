import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Flex from './Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Avatar = ({
  size,
  rounded,
  src,
  name,
  className,
  style,
  mediaClass,
  icon = ''
}) => {
  const classes = classNames('avatar', `avatar-${size}`, className);
  const mediaClasses = classNames({
    rounded: rounded,
    [mediaClass]: mediaClass
  });

  const getAvatar = () => {
    if (src) {
      return <img src={src} alt={name} />;
    } else if (icon) {
      return (
        <Flex
          justifyContent={'around'}
          alignItems={'center'}
          className={`avatar-name ${mediaClasses}`}>
          <FontAwesomeIcon icon={icon} className='w-75 h-75' />
        </Flex>
      );
    }
  };

  return (
    <div className={classes} style={style}>
      {getAvatar()}
    </div>
  );
};

export const AvatarGroup = ({ children, dense, className }) => {
  return (
    <div
      className={classNames(className, 'avatar-group', {
        'avatar-group-dense': dense
      })}>
      {children}
    </div>
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']),
  rounded: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  name: PropTypes.string,
  emoji: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  mediaClass: PropTypes.string,
  isExact: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

Avatar.defaultProps = {
  size: 'xl',
  rounded: 'circle',
  emoji: '🧑',
  isExact: false
};

AvatarGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dense: PropTypes.bool
};

export default Avatar;
