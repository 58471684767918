import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Form, FormGroup, Pagination } from 'react-bootstrap';

/**
 * Renders an advanced table pagination component
 *
 * @param {Object} props - The component props
 * @param {number} props.pageCount - The total number of pages
 * @param {number} props.pageIndex - The current page index
 * @param {Function} props.gotoPage - The function to navigate to a specific page
 * @param {boolean} props.hidePageNumbers - Determines whether to hide the page numbers
 * @returns {JSX.Element} The rendered component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const AdvancedTablePagination = ({
  pageCount,
  pageIndex,
  gotoPage,
  hidePageNumbers
}) => {
  const [pageButtons, setPageButtons] = React.useState([]);
  const pageNumber = Number(pageIndex) + 1;

  /**
   * Creates a pagination item for the FieldsTablePagination component
   *
   * @param {number} i - The index of the pagination item
   * @returns {JSX.Element} - The pagination item JSX element
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const createPaginationItem = i => {
    return (
      <Pagination.Item
        key={i}
        active={i === pageNumber}
        onClick={() => gotoPage(i - 1)}
        className='text-center text-nowrap'>
        <span className='fs--1'>{i.toLocaleString()}</span>
      </Pagination.Item>
    );
  };

  /**
   * Build pagination items on state change
   */
  useEffect(() => {
    let items = [];
    setPageButtons([]);
    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        items.push(createPaginationItem(i));
      }
    } else {
      if (pageNumber <= 3) {
        for (let i = 1; i <= 5; i++) {
          items.push(createPaginationItem(i));
        }
      } else if (pageNumber >= pageCount - 2) {
        for (let i = pageCount - 4; i <= pageCount; i++) {
          items.push(createPaginationItem(i));
        }
      } else {
        for (let i = pageNumber - 2; i <= pageNumber + 1; i++) {
          items.push(createPaginationItem(i));
        }
      }
    }
    setPageButtons(items);
  }, [pageIndex, pageCount, gotoPage]);

  return (
    <Flex direction={'row'}>
      <FormGroup className='fs--1 d-flex align-items-center mx-2'>
        <p className='mb-0 mx-2'>Go to page:</p>
        <Form.Select
          name='fieldsPageSelect'
          size='sm'
          className='w-auto fs--1'
          onChange={e => gotoPage(e.target.value)}
          value={Number(pageIndex)}>
          {(() => {
            const options = [];
            for (let i = 1; i <= pageCount; i++) {
              options.push(
                <option value={i - 1} key={i - 1}>
                  {i.toLocaleString()}
                </option>
              );
            }
            return options;
          })()}
        </Form.Select>
      </FormGroup>
      {!hidePageNumbers && (
        <Pagination size='sm' className='my-0 ms-2'>
          <Pagination.First
            onClick={() => gotoPage(0)}
            disabled={pageNumber === 1}
            className='text-center text-nowrap fs--1'
          />
          <Pagination.Prev
            onClick={() => gotoPage(pageNumber - 2)}
            disabled={pageNumber === 1}
            className='text-center text-nowrap fs--1'
          />
          {pageButtons}
          <Pagination.Next
            onClick={() => gotoPage(pageNumber)}
            disabled={pageNumber === pageCount}
            className='text-center text-nowrap fs--1'
          />
          <Pagination.Last
            onClick={() => gotoPage(pageCount - 1)}
            disabled={pageNumber === pageCount}
            className='text-center text-nowrap fs--1'
          />
        </Pagination>
      )}
    </Flex>
  );
};

AdvancedTablePagination.propTypes = {
  pageCount: PropTypes.number,
  pageIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  gotoPage: PropTypes.func,
  hidePageNumbers: PropTypes.bool
};

export default AdvancedTablePagination;
