import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import { useSearchFilters } from 'context/FiltersProvider';
import { truncateMiddle } from 'helpers/utils';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Dropdown, Overlay, Popover } from 'react-bootstrap';

/**
 * The SearchFilterBadge component.
 *
 * @param {Object} props - The component props
 * @param {string} props.operator - Filter operator
 * @param {string} props.accessor - Filter accessor
 * @param {string} props.value - Filter value
 * @param {Function} props.removeFilter - Function to remove filter
 * @param {Object} props.item - Filter item
 * @param {number} props.index - Filter index
 * @param {Function} props.setESGlobalFilters - Function to set global filters
 * @param {Function} props.tempDisable - Function to temporarily disable filter
 * @param {Function} props.togglePinFilters - Function to toggle pinning filter
 * @param {Function} props.toggleIncludeExcludeResults - Function to toggle including or excluding results
 * @returns {JSX.Element} Filter badge component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const SearchFilterBadge = ({ filter }) => {
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);

  const {
    searchFilters: { actions, operators },
    applyAction,
    openEditFilterModal,
    OpenEditSearchStringModal
  } = useSearchFilters();

  const {
    application: { isPrivacyMode }
  } = useApplication();
  const [show, setShow] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  const handleToggle = () => {
    setShow(!show);
  };

  const handleSelection = (filter, action) => {
    setShow(false);
    applyAction(filter, action);
  };

  const handleClickOutside = e => {
    if (
      triggerRef.current &&
      !triggerRef.current.contains(e.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsPrivate(filter?.field?.sensitive && isPrivacyMode);
  }, [isPrivacyMode]);

  const popover = (
    <Popover
      id={`searchFiltersActionsPopover_${filter?.field?.id}`}
      style={{ minWidth: '125px', padding: 0 }}>
      <div ref={dropdownRef}>
        <Flex
          justifyContent='start'
          alignItems='Start'
          direction={'column'}
          className='w-100 p-0'>
          <Dropdown.Menu show={true} className='position-relative border-0'>
            <Dropdown.Header>Actions</Dropdown.Header>
            <Dropdown.Item
              onClick={() => {
                setShow(false);
                // console.log('openEditFilterModal', openEditFilterModal, filter);
                if (filter.field !== null) {
                  openEditFilterModal(filter.id);
                } else {
                  OpenEditSearchStringModal(filter.id);
                }
              }}>
              <FontAwesomeIcon
                icon={['fas', 'edit']}
                width={16}
                height={16}
                className='me-2'
              />
              Edit
            </Dropdown.Item>
            {Object.keys(actions).map((actionKey, index) => {
              const action = actions[actionKey];
              return action.isHidden(filter) ? null : (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleSelection(filter, actionKey)}>
                  {action.icon && (
                    <FontAwesomeIcon
                      icon={action.icon}
                      width={16}
                      height={16}
                      className='me-2'
                    />
                  )}
                  {['include', 'exclude'].includes(actionKey)
                    ? (!filter.inclusion &&
                        operators[filter?.operator]?.isNegation) ||
                      (filter.inclusion &&
                        operators[filter?.operator]?.isNegation)
                      ? actions[actions[actionKey]?.inversion].label
                      : action.label
                    : action.label}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Flex>
      </div>
    </Popover>
  );

  return (
    <>
      <Flex>
        <Flex direction='column' className='h-100'>
          <Overlay
            show={show}
            target={triggerRef.current}
            placement='bottom'
            containerPadding={20}
            rootClose={true}
            onHide={() => setShow(false)}>
            {popover}
          </Overlay>

          <AdvancedPopover
            placement='top'
            showArrow={true}
            popoverText='Edit this filter or apply an action'>
            <Badge
              ref={triggerRef}
              onClick={handleToggle}
              bg={classNames({
                'soft-pastel-gray': !filter.enabled,
                'soft-pastel-green':
                  (filter.inclusion &&
                    !operators[filter?.operator]?.isNegation) ||
                  (!filter.inclusion &&
                    operators[filter?.operator]?.isNegation),
                'soft-pastel-yellow':
                  (!filter.inclusion &&
                    !operators[filter?.operator]?.isNegation) ||
                  (filter.inclusion && operators[filter?.operator]?.isNegation)
              })}
              className={classNames(
                'ps-1 fs--2 fw-normal cursor-pointer d-flex justify-content-center align-items-center border opacity-100 me-1 mb-1 hover-opacity-75',
                {
                  'text-pastel-gray hover-border-soft-pastel-gray hover-bg-pastel-gray-light':
                    !filter.enabled,
                  'text-pastel-green-dark hover-border-soft-pastel-green hover-bg-pastel-green-light':
                    (filter.inclusion &&
                      !operators[filter?.operator]?.isNegation) ||
                    (!filter.inclusion &&
                      operators[filter?.operator]?.isNegation),
                  'text-pastel-yellow-dark hover-border-soft-pastel-yellow hover-bg-pastel-yellow-light':
                    (!filter.inclusion &&
                      !operators[filter?.operator]?.isNegation) ||
                    (filter.inclusion &&
                      operators[filter?.operator]?.isNegation)
                }
              )}
              style={{
                textDecoration: filter.enabled ? 'none' : 'line-through'
              }}>
              {/* If the filter is pinned, show the thumbtack icon */}
              {filter.pinned && (
                <FontAwesomeIcon icon='thumb-tack' className='me-1' />
              )}
              {filter?.useCustomLabel && filter.customLabel ? (
                <span className='fw-semi-bold'>"{filter.customLabel}"</span>
              ) : (
                <>
                  {/* Show the accessor immediately after any applicable icons */}
                  "{filter?.field?.accessor}"{' '}
                  {/* Show the operator's symbol immediately after the accessor */}
                  {filter.inclusion
                    ? operators[filter?.operator]?.symbol
                    : operators[operators[filter?.operator]?.inversion]
                        ?.symbol || operators[filter?.operator]?.symbol}{' '}
                  {operators[filter?.operator]?.requiresValue ? (
                    <span className='ms-1 fw-semi-bold'>
                      "
                      {isPrivate ? (
                        '******'
                      ) : (
                        <>
                          {Array.isArray(filter?.value)
                            ? filter?.value.map((item, i) => {
                                return (
                                  <span key={i}>
                                    {item}
                                    {i !== filter?.value?.length - 1
                                      ? ', '
                                      : ''}
                                  </span>
                                );
                              })
                            : operators[filter?.operator]?.symbol ===
                                'does not exist' ||
                              operators[filter?.operator]?.symbol === 'exists'
                            ? ''
                            : truncateMiddle(filter?.value)}
                        </>
                      )}
                      "
                    </span>
                  ) : null}
                </>
              )}
              <FontAwesomeIcon icon='ellipsis-v' className='mx-2 me-0' />
            </Badge>
          </AdvancedPopover>
        </Flex>
      </Flex>
    </>
  );
};

SearchFilterBadge.propTypes = {
  filter: PropTypes.object.isRequired
};

export default SearchFilterBadge;
