import { systemRoles } from 'config/roleSettings';
import { routes } from 'config/routeSettings';
import { timeRanges } from 'context/ExploreProvider/settings';

export const dynamicDashboardRoutes = [
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [],
    indices: ['leargas'],
    indicesSelected: ['leargas'],
    path: routes.ARTIFACTS_EXPLORE,
    slug: 'explore',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [],
    indices: ['leargas'],
    indicesSelected: ['leargas'],
    path: routes.ARTIFACTS_NETWORK,
    slug: 'network',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'smb.command',
      'smb.status',
      'source_ip',
      'destination_ip',
      'smb.ntlmssp.user',
      'smb.share',
      'smb.filename'
    ],
    filtersDefault: [
      {
        bool: {
          should: [
            {
              match_phrase: {
                'logName.keyword': 'smb_files'
              }
            },
            {
              match_phrase: {
                'logName.keyword': 'smb_mapping'
              }
            }
          ],
          minimum_should_match: 1
        }
      }
    ],
    indices: ['leargas-zeek'],
    indicesSelected: ['leargas-zeek'],
    path: routes.ARTIFACTS_NETWORK_SMB,
    slug: 'smb',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'id.orig_h',
      'id.orig_p',
      'id.resp_h',
      'id.resp_p',
      'endpoint',
      'operation'
    ],
    indices: ['leargas-zeek'],
    indicesSelected: ['leargas-zeek'],
    path: routes.ARTIFACTS_NETWORK_RPC,
    slug: 'rpc',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'logName',
      'id.orig_h',
      'id.orig_p',
      'id.resp_h',
      'id.resp_p'
    ],
    filtersDefault: [
      {
        bool: {
          should: [
            {
              match_phrase: {
                'logName.keyword': 'ecat_arp_info'
              }
            },
            {
              match_phrase: {
                'logName.keyword': 'conn'
              }
            },
            {
              match_phrase: {
                'logName.keyword': 'enip'
              }
            },
            {
              match_phrase: {
                'logName.keyword': 'dnp3'
              }
            },
            {
              match_phrase: {
                'logName.keyword': 'mqtt_publish'
              }
            },
            {
              match_phrase: {
                'logName.keyword': 'bsap_ip_header'
              }
            },
            {
              match_phrase: {
                'logName.keyword': 'ecat_arp_info.2024-10-18-23-00-00'
              }
            },
            {
              match_phrase: {
                'logName.keyword': 'opcua-binary'
              }
            },
            {
              match_phrase: {
                'logName.keyword': 'mqtt_connect'
              }
            },
            {
              match_phrase: {
                'logName.keyword': 'cip_io'
              }
            },
            {
              match_phrase: {
                'logName.keyword': 'cotp'
              }
            }
          ],
          minimum_should_match: 1
        }
      }
    ],
    indices: ['leargas-zeek'],
    indicesSelected: ['leargas-zeek'],
    path: routes.ARTIFACTS_NETWORK_ICS,
    slug: 'ics',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'event_type',
      'source_ip',
      'source.item_type',
      'source.item_name'
    ],
    indices: ['leargas-box'],
    indicesSelected: ['leargas-box'],
    path: routes.ARTIFACTS_CLOUD_BOX,
    slug: 'box',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: ['event_type', 'email', 'result', 'reason'],
    indices: ['leargas-duo'],
    indicesSelected: ['leargas-duo'],
    path: routes.ARTIFACTS_CLOUD_DUO,
    slug: 'duo',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'user.name',
      'user.domain',
      'event.provider',
      'event.category',
      'event.action'
    ],
    indices: ['leargas-google'],
    indicesSelected: ['leargas-google'],
    path: routes.ARTIFACTS_CLOUD_GOOGLE,
    slug: 'google',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'networkName',
      'eventType',
      'description',
      'deviceName'
    ],
    indices: ['leargas-meraki'],
    indicesSelected: ['leargas-meraki'],
    path: routes.ARTIFACTS_CLOUD_MERAKI,
    slug: 'meraki',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: ['Workload', 'event.action', 'UserId'],
    indices: ['leargas-o365'],
    indicesSelected: ['leargas-o365'],
    path: routes.ARTIFACTS_CLOUD_O365,
    slug: 'office365',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'severity',
      'transaction.type',
      'displayMessage',
      'actor.displayName',
      'outcome.result',
      'target'
    ],
    indices: ['leargas-okta'],
    indicesSelected: ['leargas-okta'],
    path: routes.ARTIFACTS_CLOUD_OKTA,
    slug: 'okta',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'message.level',
      'message.location',
      'message.properties.ipAddress',
      'message.resourceId'
    ],
    indices: ['leargas-azure'],
    indicesSelected: ['leargas-azure'],
    path: routes.ARTIFACTS_CLOUD_AZURE,
    slug: 'azure',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'message.eventName',
      'message.eventType',
      'message.eventSource',
      'message.eventCategory',
      'message.sourceIPAddress'
    ],
    indices: ['leargas-aws'],
    indicesSelected: ['leargas-aws'],
    path: routes.ARTIFACTS_CLOUD_AWS_CLOUDTRAIL,
    slug: 'aws',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'keywordFound',
      'sentimentRating',
      'emailFound',
      'creditCardFound',
      'ssnFound'
    ],
    indices: ['leargas-darkweb'],
    indicesSelected: ['leargas-darkweb'],
    path: routes.ARTIFACTS_DARKWEB,
    slug: 'darkweb',
    timeRange: timeRanges.weeks.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'computerName',
      'osName',
      'agentVersion',
      'isUpToDate',
      'infected',
      'scanStatus'
    ],
    indices: ['leargas-sentinelone-agents'],
    indicesSelected: ['leargas-sentinelone-agents'],
    path: routes.ARTIFACTS_ENDPOINT_SENTINELONE_AGENTS,
    slug: 'sentinelOneAgents',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'agentRealtimeInfo.agentComputerName',
      'threatInfo.threatName',
      'threatInfo.classification'
    ],
    indices: ['leargas-sentinelone-threats'],
    indicesSelected: ['leargas-sentinelone-threats'],
    path: routes.ARTIFACTS_ENDPOINT_SENTINELONE_THREATS,
    slug: 'sentinelOneThreats',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'agent.hostname',
      'agent.version',
      'event.module',
      'event.code',
      'event.action',
      'event.outcome'
    ],
    indices: ['leargas-winlogbeat*'],
    indicesSelected: ['leargas-winlogbeat'],
    path: routes.ARTIFACTS_ENDPOINT_WINDOWS,
    slug: 'windows',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: ['message'],
    indices: ['leargas-syslog'],
    indicesSelected: ['leargas-syslog'],
    path: routes.ARTIFACTS_ENDPOINT_SYSLOG,
    slug: 'syslog',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: ['note', 'source_ip', 'destination_ip', 'uid'],
    indices: ['leargas-zeek*'],
    indicesSelected: ['leargas-zeek'],
    path: routes.ARTIFACTS_NETWORK_BEHAVIORS,
    slug: 'behaviors',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'request.attributes.filename',
      'file.flavors.mime',
      'request.client'
    ],
    indices: ['leargas-file-extraction'],
    indicesSelected: ['leargas-file-extraction'],
    path: routes.ARTIFACTS_NETWORK_FILEEXTRACTION,
    slug: 'fileExtraction',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'alert.signature',
      'source_ip',
      'destination_ip',
      'dest_port'
    ],
    indices: ['leargas-suricata*'],
    indicesSelected: ['leargas-suricata'],
    path: routes.ARTIFACTS_NETWORK_SIGNATURES,
    slug: 'signatures',
    timeRange: timeRanges.hours.options[0]
  },
  {
    // _id: ObjectId(),
    allowedRoles: [systemRoles.SuperAdmin, systemRoles.Admin, systemRoles.User],
    fieldsSelectedDefault: [
      'seen.indicator',
      'sources',
      'id.resp_h',
      'id.resp_p'
    ],
    filtersDefault: [
      {
        match_phrase: {
          path: '/usr/local/zeek/logs/current/intel.log'
        }
      }
    ],
    indices: ['leargas-zeek'],
    indicesSelected: ['leargas-zeek'],
    path: routes.ARTIFACTS_NETWORK_THREATINTEL,
    slug: 'threatIntel',
    timeRange: timeRanges.hours.options[0]
  }
];
