import React from 'react';
import NotificationLayout from './NotificationLayout';

/**
 * Renders the Notification Settings page
 *
 * @returns {JSX.Element} The rendered Notification Settings page
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const NotificationSettingsPage = () => {
  return (
    <>
      <NotificationLayout />
    </>
  );
};

export default NotificationSettingsPage;
