import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import CreateRouteForm from '../forms/CreateRouteForm';

const CreateRouteModal = ({
  showCreateRouteModal,
  setShowCreateRouteModal
}) => {
  const {
    application: { isDark }
  } = useApplication();

  return (
    <>
      <Modal
        aria-labelledby='newRouteModal'
        size='lg'
        backdrop='static'
        keyboard={false}
        show={showCreateRouteModal?.open}
        onHide={() => setShowCreateRouteModal({ open: false, fetch: false })}
        centered>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Create a Route
            <AdvancedPopover
              containerId='resetConfirmModal'
              placement='top'
              popoverText='Edit'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
        </Modal.Header>

        <Modal.Body className='p-2'>
          <CreateRouteForm
            hasLabel
            setShowCreateRouteModal={setShowCreateRouteModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

CreateRouteModal.propTypes = {
  showCreateRouteModal: PropTypes.object,
  setShowCreateRouteModal: PropTypes.func
};

export default CreateRouteModal;
