import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

/**
 * A checkbox component that supports indeterminate state
 *
 * @param {Object} props - The properties for the IndeterminateCheckbox component
 * @param {boolean} props.indeterminate - Whether the checkbox should be in indeterminate state
 * @param {React.Ref} props.ref - The ref object to be forwarded to the checkbox input element
 * @returns {JSX.Element} The rendered IndeterminateCheckbox component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Form.Check
        type='checkbox'
        className='form-check fs-0 mb-0 d-flex align-items-center'>
        <Form.Check.Input type='checkbox' ref={resolvedRef} {...rest} />
      </Form.Check>
    );
  }
);

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool
};

export default IndeterminateCheckbox;
