import React from 'react';
import PropTypes from 'prop-types';
import OrganizationsProvider from '../../../../../../context/admin-contexts/OrganizationsProvider';
import UsersProvider from '../../../../../../context/admin-contexts/UsersProvider';
import OrganizationsLayout from './OrganizationsLayout';
import ModalOrganizationAdd from './components/modals/OrganizationsAddModal';
import ModalOrganizationEdit from './components/modals/OrganizationsEditModal';
import ModalOrganizationDelete from './components/modals/OrganizationsDeleteModal';
import ModalOrganizationRestore from './components/modals/OrganizationsRestoreModal';

/**
 * Organizations wrapper as we need users provider and orgs provider both at time of creation of organization and also editing organizations
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const OrganizationsWrapper = props => {
  return (
    <UsersProvider>
      <OrganizationsProvider>
        {props.children}
        <OrganizationsLayout />
        <ModalOrganizationAdd />
        <ModalOrganizationEdit />
        <ModalOrganizationDelete />
        <ModalOrganizationRestore />
      </OrganizationsProvider>
    </UsersProvider>
  );
};

OrganizationsWrapper.propTypes = { children: PropTypes.node };

export default OrganizationsWrapper;
