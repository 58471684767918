import { apiRoutes } from 'config/routeSettings';

class Notifications {
  constructor(route) {
    this.route = route;
  }
  getAll(axiosPrivate) {
    return axiosPrivate.get(apiRoutes.NOTIFICATIONS);
  }

  get(id, axiosPrivate) {
    return axiosPrivate.get(`${apiRoutes.NOTIFICATIONS}/${id}`);
  }

  create(data, axiosPrivate) {
    return axiosPrivate.post(apiRoutes.NOTIFICATIONS, data);
  }

  update(id, data, axiosPrivate) {
    return axiosPrivate.put(`${apiRoutes.NOTIFICATIONS}/${id}`, data);
  }

  delete(id, axiosPrivate) {
    return axiosPrivate.delete(`${apiRoutes.NOTIFICATIONS}/${id}`);
  }

  deleteAll(axiosPrivate) {
    return axiosPrivate.delete(apiRoutes.NOTIFICATIONS);
  }
}

export default Notifications;
