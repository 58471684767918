import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useAlerts from 'hooks/admin-contexts/useAlerts';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

/**
 * alerts delete modal
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const AlertsDeleteForm = () => {
  const {
    alerts: {
      deleteAlertModal: { defaultData }
    },
    setAlerts
  } = useAlerts();
  const { axiosPrivate } = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  /**
   * alerts delete modal
   * @param {Event} e
   * @async
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const handleSubmit = async e => {
    e.preventDefault();

    try {
      setLoading(true);
      let res = await LeargasAPI.AppAlerts.deleteAlert(
        defaultData?._id,
        true,
        axiosPrivate
      );
      if (res instanceof Error) {
        throw res;
      }
      const { message } = res.data;
      toast.success(message);
      setLoading(false);
      setAlerts(prevState => ({
        ...prevState,
        fetch: true,

        deleteAlertModal: { open: false, defaultData: {} }
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
      setAlerts(prevState => ({
        ...prevState,
        fetch: true,
        deleteAlertModal: { open: false, defaultData: {} }
      }));
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='g-3 mb-3'>
        <p>
          Are you sure you want to delete the{' '}
          {String(defaultData?.name).toLowerCase()} Alert?
        </p>
        <Col sm={12}></Col>
      </Row>

      <Flex justifyContent='end'>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() => {
            setAlerts(prevState => ({
              ...prevState,
              deleteAlertModal: { open: false, defaultData: {} }
            }));
          }}>
          Cancel
        </Button>

        <Button variant='danger' type='submit' className='d-flex' size='sm'>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Deleting' : 'Delete'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

export default AlertsDeleteForm;
