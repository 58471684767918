import Breadcrumbs from 'components/common/Breadcrumb';
import ExploreTable from 'components/common/explore-table';
import Flex from 'components/common/Flex';
import Footer from 'components/common/Footer';
import GlobalFiltersLayout from 'components/common/query-filters/QueryFiltersModalLayout';
import ScrollButton from 'components/common/ScrollToTopButton';
import ExploreSidebar from 'components/dashboards/Artifacts/Explore/Sidebar';
import TopValuesOffcanvas from 'components/dashboards/Artifacts/Explore/Sidebar/FieldList/TopValuesOffcanvas';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavigatorMainPanel from 'components/navigator/NavigatorMainPanel';
import SavedSearchesProvider from 'context/admin-contexts/SavedSearchProvider';
import { ExploreProvider } from 'context/ExploreProvider';
import FiltersProvider from 'context/FiltersProvider';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Outlet, useLocation } from 'react-router-dom';
import SavedSearchesLayout from './SavedSearchesLayout';

/**
 * Represents the ExploreLayout component.
 *
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const ExploreLayout = () => {
  const { hash } = useLocation();

  /**
   * Scroll to the element with the id from the hash
   * when the component is mounted.
   *
   * @returns {void}
   * @since 0.1.0-beta.4
   * @version 0.1.0-beta.4
   */
  useEffect(() => {
    var timeout = setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <SavedSearchesProvider path='dashboard'>
      <ExploreProvider>
        <FiltersProvider>
          <div className='content position-relative'>
            <NavigatorMainPanel />
            <NavbarTop />
            <div className='content-wrapper'>
              <Breadcrumbs>
                <Flex direction='row' justifyContent='end'>
                  <SavedSearchesLayout />
                </Flex>
              </Breadcrumbs>
              <ExploreSidebar>
                <Outlet />
                <Row className='g-2 mb-2'>
                  <Col className='position-relative'>
                    <ExploreTable
                      HeaderProps={{
                        headerText: 'All Artifacts'
                      }}
                    />
                  </Col>
                </Row>
              </ExploreSidebar>
              <ScrollButton />
              <TopValuesOffcanvas />
            </div>
            <Footer />
          </div>
          <GlobalFiltersLayout />
        </FiltersProvider>
      </ExploreProvider>
    </SavedSearchesProvider>
  );
};

export default ExploreLayout;
