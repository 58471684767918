import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faGoogle,
  faGooglePlusG,
  faGulp,
  faHotjar,
  faHtml5,
  faJs,
  faLinkedinIn,
  faMediumM,
  faMicrosoft,
  faNodeJs,
  faReadme,
  faSass,
  faTrello,
  faTwitter,
  faUbuntu,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import {
  faCheckCircle as farCheckCircle,
  faCircle as farCircle,
  faCirclePause as farCirclePause,
  faCirclePlay as farCirclePlay,
  faCircleStop as farCircleStop,
  faClock as farClock,
  faComment as farComment,
  faComments as farComments,
  faCopy as farCopy,
  faCreditCard as farCredit,
  faDizzy as farDizzy,
  faEdit as farEdit,
  faEnvelope as farEnvelope,
  faEye as farEye,
  faFile as farFile,
  faFileArchive as farFileArchive,
  faFileAudio as farFileAudio,
  faFileCode as farFileCode,
  faFileImage as farFileImage,
  faFilePdf as farFilePdf,
  faFileVideo as farFileVideo,
  faHeart as farHeart,
  faImage as farImage,
  faLaughBeam as farLaughBeam,
  faLightbulb as farLightbulb,
  faMap as farMap,
  faObjectUngroup as farObjectUngroup,
  faPaperPlane as farPaperPlane,
  faQuestionCircle as farQuestionCircle,
  faSmileBeam as farSmileBeam,
  faSquare as farSquare,
  faStar as farStar
} from '@fortawesome/free-regular-svg-icons';
import {
  fa1,
  fa2,
  fa3,
  faAddressCard,
  faAlignLeft,
  faAlignRight,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAltV,
  faArrowsDownToLine,
  faArrowsLeftRightToLine,
  faArrowsUpToLine,
  faArrowUp,
  faAt,
  faBan,
  faBell,
  faBinoculars,
  faBolt,
  faBook,
  faBookmark,
  faBookOpen,
  faBookSkull,
  faBox,
  faBoxOpen,
  faBrain,
  faBriefcase,
  faBriefcaseClock,
  faBug,
  faBuilding,
  faBullhorn,
  faBullseye,
  faBusinessTime,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCamera,
  faCapsules,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCartPlus,
  faCertificate,
  faChartBar,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faCheckToSlot,
  faChessRook,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleArrowUp,
  faCircleNodes,
  faCircleNotch,
  faCirclePlus,
  faClipboard,
  faClock,
  faCloud,
  faCloudDownloadAlt,
  faClover,
  faCode,
  faCodeBranch,
  faCodeMerge,
  faCog,
  faComments,
  faComputer,
  faComputerMouse,
  faCopy,
  faCross,
  faCrosshairs,
  faCrown,
  faDatabase,
  faDesktop,
  faDisplay,
  faDollarSign,
  faDoorOpen,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFilePdf,
  faFilter,
  faFilterCircleXmark,
  faFire,
  faFlag,
  faFloppyDisk,
  faFolderPlus,
  faGauge,
  faGears,
  faGift,
  faGlobe,
  faGlobeAmericas,
  faGrip,
  faGripHorizontal,
  faGripLines,
  faGripVertical,
  faHand,
  faHardDrive,
  faHeart,
  faHeartPulse,
  faHome,
  faIcons,
  faImage,
  faInfinity,
  faInfo,
  faInfoCircle,
  faKey,
  faKeyboard,
  faLandmark,
  faLandmarkDome,
  faLayerGroup,
  faLightbulb,
  faLink,
  faList,
  faListAlt,
  faListUl,
  faLocationArrow,
  faLock,
  faLockOpen,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faMap,
  faMapMarkerAlt,
  faMicrochip,
  faMicroscope,
  faMinus,
  faMobile,
  faMoon,
  faMusic,
  faNetworkWired,
  faNewspaper,
  faPalette,
  faPaperclip,
  faPaperPlane,
  faPen,
  faPencilAlt,
  faPeopleGroup,
  faPercentage,
  faPhone,
  faPlay,
  faPlug,
  faPlus,
  faPlusSquare,
  faPoll,
  faPrint,
  faProjectDiagram,
  faPuzzlePiece,
  faQuestionCircle,
  faRedo,
  faReply,
  faRightFromBracket,
  faRocket,
  faRss,
  faScroll,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faServer,
  faShapes,
  faShare,
  faShareAlt,
  faShield,
  faShieldAlt,
  faShoppingCart,
  faSignInAlt,
  faSignsPost,
  faSitemap,
  faSkullCrossbones,
  faSliders,
  faSlidersH,
  faSmileBeam,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortAmountDown,
  faSortAmountUp,
  faSortNumericDown,
  faSortNumericUp,
  faSpinner,
  faStar,
  faStarHalfAlt,
  faStopwatch,
  faStream,
  faSun,
  faSyncAlt,
  faTable,
  faTableColumns,
  faTag,
  faTags,
  faTasks,
  faTh,
  faThumbsUp,
  faThumbtack,
  faThumbtackSlash,
  faTimes,
  faTimesCircle,
  faTint,
  faToggleOff,
  faToggleOn,
  faTools,
  faTrash,
  faTrashAlt,
  faTrashRestoreAlt,
  faUnlockKeyhole,
  faUser,
  faUserAstronaut,
  faUserCircle,
  faUserFriends,
  faUserPlus,
  faUsers,
  faUserSecret,
  faUsersRays,
  faVideo,
  faVirus,
  faWrench
} from '@fortawesome/free-solid-svg-icons';

library.add(
  fa1,
  fa2,
  fa3,
  faRss,
  faUserAstronaut,
  faDoorOpen,
  faBrain,
  faArrowsUpToLine,
  faArrowsDownToLine,
  faArrowsLeftRightToLine,
  faGears,
  faCertificate,
  faVirus,
  faNewspaper,
  faDisplay,
  faGrip,
  faReadme,
  faBullseye,
  faCrosshairs,
  faComputerMouse,
  faStopwatch,
  faLightbulb,
  faGripLines,
  farEye,
  faBookSkull,
  faSitemap,
  faPeopleGroup,
  faMicroscope,
  faHardDrive,
  faUsersRays,
  faLandmark,
  faLandmarkDome,
  faUserSecret,
  faBullhorn,
  faUnlockKeyhole,
  faComputer,
  faBusinessTime,
  faCircleNodes,
  faNetworkWired,
  faMicrochip,
  faRightFromBracket,
  faDesktop,
  faServer,
  farComments,
  faChartPie,
  faBinoculars,
  faCopy,
  faChessRook,
  faSearch,
  faHome,
  faBell,
  faBox,
  faPlay,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faBuilding,
  faBriefcase,
  faExchangeAlt,
  faAngleRight,
  faAngleLeft,
  faEllipsisH,
  faCrown,
  faListAlt,
  faCheck,
  faBan,
  faStream,
  faArrowsAltV,
  faDatabase,
  faSort,
  faClover,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountDown,
  faSortAmountUp,
  faCheckCircle,
  faUserCircle,
  faGoogle,
  faQuestionCircle,
  faLock,
  faLockOpen,
  farMap,
  faCircle,
  faHeart,
  faShareAlt,
  faCaretRight,
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faPrint,
  faDollarSign,
  faLink,
  faPlus,
  faMinus,
  faUser,
  faPencilAlt,
  faCamera,
  faTimesCircle,
  faSignInAlt,
  faBook,
  faPuzzlePiece,
  faTrash,
  faShield,
  faShieldAlt,
  faAlignLeft,
  faAlignRight,
  faPlug,
  faTimes,
  faFilter,
  faGripVertical,
  faExternalLinkAlt,
  faCheckToSlot,
  faCartPlus,
  faShoppingCart,
  faStar,
  faStarHalfAlt,
  faMicrosoft,
  faArchive,
  faEnvelope,
  faClock,
  faStar,
  faGripHorizontal,
  faReply,
  faLocationArrow,
  faCog,
  faPaperclip,
  faTableColumns,
  faImage,
  faEllipsisV,
  faThumbtack,
  faThumbtackSlash,
  faEnvelopeOpen,
  faRedo,
  faSlidersH,
  faTrashRestoreAlt,
  faTrashAlt,
  faFileArchive,
  faSignsPost,
  faKeyboard,
  faFilePdf,
  faScroll,
  faFileAlt,
  faEye,
  faHand,
  faEyeSlash,
  farSquare,
  faCaretUp,
  faCodeBranch,
  faExclamationTriangle,
  faListUl,
  faTh,
  faGift,
  faUsers,
  faGlobeAmericas,
  faGlobe,
  faPlusSquare,
  faBoxOpen,
  faKey,
  faCaretDown,
  faUserPlus,
  faBriefcaseClock,
  faCircleNotch,
  faExclamation,
  faThumbsUp,
  faComments,
  faPalette,
  faSearchPlus,
  faSearchMinus,
  faEdit,
  faTrashAlt,
  faShare,
  faCheckSquare,
  faTools,
  faCheckDouble,
  faSmileBeam,
  faPoll,
  faChartBar,
  faClipboard,
  faPercentage,
  faTags,
  faIcons,
  faList,
  faUserFriends,
  faExclamationCircle,
  faFolderPlus,
  faTag,
  faAt,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faMapMarkerAlt,
  faCross,
  faFlag,
  faThumbtack,
  faTable,
  faChartLine,
  faGauge,
  faShapes,
  faAddressCard,
  faMap,
  faPen,
  faFire,
  faLayerGroup,
  faRocket,
  faWrench,
  faSun,
  faMoon,
  faInfoCircle,
  faSpinner,
  faCapsules,
  faTasks,
  faSliders,
  faCloud,
  faCloudDownloadAlt,
  faSyncAlt,
  faBug,
  faProjectDiagram,
  faBolt,
  faPaperPlane,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faInfinity,
  faFile,
  faMusic,
  faMobile,
  faBookOpen,
  faTint,
  faHeartPulse,
  faSkullCrossbones,
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faGulp,
  faNodeJs,
  faSass,
  faTwitter,
  faGooglePlusG,
  faLinkedinIn,
  faMediumM,
  faHotjar,
  faYoutube,
  faVideo,
  faInfo,
  faPhone,
  faTrello,
  faUbuntu,
  faCode,
  faJs,
  faHtml5,
  farHeart,
  farLightbulb,
  faCirclePlus,
  faCodeMerge,
  farObjectUngroup,
  farCredit,
  farPaperPlane,
  farStar,
  farImage,
  farFileArchive,
  farFilePdf,
  faBookmark,
  farFile,
  farFileVideo,
  farFileAudio,
  faCircleArrowUp,
  farFileImage,
  farFileCode,
  farClock,
  farQuestionCircle,
  farDizzy,
  faToggleOff,
  faToggleOn,
  farLaughBeam,
  faFloppyDisk,
  farSmileBeam,
  farCircle,
  farCopy,
  farComment,
  farEdit,
  farCheckCircle,
  farEnvelope,
  faFilterCircleXmark,
  farCirclePlay,
  farCirclePause,
  farCircleStop
);
