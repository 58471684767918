import { useCluster } from 'hooks/admin-contexts/useCluster';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useCountUp } from 'react-countup';

const PendingTasks = () => {
  const {
    cluster: {
      clusterPendingTasks: { tasks }
    }
  } = useCluster();
  const [pendingTaskCount, setPendingTaskCount] = useState(0);

  const memoizedCallback = useCallback(value => {
    return value.toLocaleString();
  }, []);

  const { update } = useCountUp({
    ref: 'pendingTaskCounter',
    decimals: 0,
    duration: 2.75,
    end: pendingTaskCount || 0,
    formattingFn: memoizedCallback,
    preserveValue: true
  });

  useEffect(() => {
    setPendingTaskCount(tasks?.length);
    update(tasks?.length);
  }, [tasks]);

  return (
    <Card className='h-100 shadow-none border'>
      {!tasks ? (
        <div className='position-absolute d-flex justify-content-center align-items-center h-100 w-100 navbar-glass-loader'>
          <Spinner animation='border' />
        </div>
      ) : null}
      <Card.Body className='p-2'>
        <Row className='g-0'>
          <Col className='d-flex flex-column justify-content-between'>
            <h6 className='mb-2'>Pending Tasks</h6>
            <h6 className='fs-2 fw-normal my-0'>
              <span id='pendingTaskCounter' className='font-monospace' />
            </h6>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default PendingTasks;
