import React from 'react';
import ArticlesListView from './components/ArticlesListView';
import ArticlesGridView from './components/ArticlesGridView';
import useArticles from 'hooks/admin-contexts/useArticles';
import { Row } from 'react-bootstrap';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticleCategories = () => {
  const {
    articles: { data, viewType }
  } = useArticles();
  const View = viewType ? ArticlesGridView : ArticlesListView;

  return (
    <Row className='g-3'>
      {data?.map((article, index) => (
        <View
          key={index}
          index={index}
          article={article}
          isLast={data.length - 1 === index}
        />
      ))}
    </Row>
  );
};

export default ArticleCategories;
