import useAccount from 'hooks/useAccount';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Banner from './Banner';
import Bio from './Bio';

/**
 * Renders the user profile component.
 * @returns {JSX.Element} The rendered profile component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const ProfileLayout = () => {
  const { account } = useAccount();

  return (
    <>
      <Row className='g-3'>
        <Col lg={12}>
          <Banner user={account} />
        </Col>
      </Row>

      <Row className='g-3 mb-3'>
        <Col sm={12}>
          <Bio />
        </Col>
      </Row>
    </>
  );
};

export default ProfileLayout;
