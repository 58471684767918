import LeargasAPI from 'api';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PasswordStrengthBar from 'react-password-strength-bar';
import { toast } from 'react-toastify';

/**
 * Reset password form
 *
 * The reset password form is used to display a form to the user to reset their password.
 * @param {Object} props The component props
 * @param {string} props.titleTag The title tag to use for the page title
 * @param {boolean} props.hasLabel Whether or not to display a label for the verification code input
 * @param {URLSearchParams} props.searchParams The URL search params object
 * @param {function} props.setReset The function to set the reset state
 * @param {function} props.setCounter The function to set the counter state
 * @returns {React.Component} The password reset form
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const ResetPasswordForm = ({
  titleTag: TitleTag,
  hasLabel,
  searchParams,
  setReset,
  setCounter
}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    password: '',
    confirmedPassword: '',
    passwordResetToken: ''
  });

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await LeargasAPI.AccountSecurity.userPasswordReset({
        body: { ...formData }
      });
      if (res instanceof Error) throw res;
      let message = res?.data?.message;
      if (message) toast.success(message);

      setTimeout(function () {
        setLoading(false);
        setReset(true);
        setCounter(5);
      }, 1000);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);

      let message = error?.response?.data?.message;
      if (message) toast.error(message);
    }
  };

  const handleFieldChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  useEffect(() => {
    if (searchParams.get('token'))
      setFormData(prevState => ({
        ...prevState,
        passwordResetToken: searchParams.get('token')
      }));
  }, [searchParams]);

  return (
    <Form
      className={classNames({ 'text-left': hasLabel })}
      onSubmit={handleSubmit}>
      <TitleTag>Choose a new password</TitleTag>
      <Form.Group className='mt-3 mb-2'>
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          name='password'
          type='password'
          value={formData.password}
          placeholder={!hasLabel ? 'New Password' : ''}
          onChange={handleFieldChange}
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Confirm</Form.Label>}
        <Form.Control
          name='confirmedPassword'
          type='password'
          value={formData.confirmedPassword}
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          onChange={handleFieldChange}
        />
      </Form.Group>
      <p className='form-label fw-semi-bold mt-3 text-start'>
        Password Strength
      </p>
      <PasswordStrengthBar password={formData.password} minLength={8} />
      <Button
        type='button'
        className='w-100 mt-3'
        disabled={!formData.password || !formData.confirmedPassword}
        onClick={handleSubmit}>
        <div className='position-relative'>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Setting' : 'Set'}</span>
          </Flex>
        </div>
      </Button>
    </Form>
  );
};

ResetPasswordForm.propTypes = {
  hasLabel: PropTypes.bool,
  searchParams: PropTypes.object,
  setReset: PropTypes.func,
  setCounter: PropTypes.func,
  titleTag: PropTypes.string
};

ResetPasswordForm.defaultProps = { titleTag: 'h4' };

export default ResetPasswordForm;
