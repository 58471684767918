import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

/**
 * Render button to add a new button
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const AddButton = ({ label, showActiveOrgsOnly, ...rest }) => {
  return (
    <OverlayTrigger
      placement='top'
      overlay={
        <Popover>
          <Popover.Body className='text-center p-1 fs--1 text-700'>
            Only show active organizations
          </Popover.Body>
        </Popover>
      }>
      <Button
        {...rest}
        size='sm'
        variant='link'
        className={`position-relative outline-none cursor-pointer icon-item icon-item-sm rounded-circle border border-${
          !showActiveOrgsOnly ? 'primary' : 'secondary'
        } shadow-none `}>
        <span>
          <FontAwesomeIcon
            icon={'circle-check'}
            className={`${
              !showActiveOrgsOnly ? 'text-primary' : 'text-secondary'
            }  `}
          />
        </span>

        {label && (
          <span className='position-absolute start-100 ms-2 text-primary text-nowrap fs--1'>
            {label}
          </span>
        )}
      </Button>
    </OverlayTrigger>
  );
};
AddButton.propTypes = {
  label: PropTypes.string,
  showActiveOrgsOnly: PropTypes.bool
};

export default AddButton;
