import React from 'react';
import PropTypes from 'prop-types';
import ArticlesProvider from '../../../../../../../context/admin-contexts/ArticlesProvider';
import ArticlesTagsLayout from './ArticleTagsLayout';
import ArticleTagsAddModal from './components/modals/ArticleTagsAddModal';
import ArticleTagsEditModal from './components/modals/ArticleTagsEditModal';
import ArticleTagsDeleteModal from './components/modals/ArticleTagsDeleteModal';

/**
 *
 * @param { props }
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticleTagsWrapper = props => {
  return (
    <ArticlesProvider>
      {props.children}
      <ArticlesTagsLayout />
      {/* Modal for tags */}
      <ArticleTagsAddModal />
      <ArticleTagsEditModal />
      <ArticleTagsDeleteModal />
    </ArticlesProvider>
  );
};

ArticleTagsWrapper.propTypes = { children: PropTypes.node };

export default ArticleTagsWrapper;
