import Userprofile from 'components/common/profile/UserProfile';
import React from 'react';

const UserProfile = () => {
  return (
    <>
      <Userprofile />
    </>
  );
};

export default UserProfile;
