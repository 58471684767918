import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import SubscriptionLevelsWrapper from './SubscriptionLevelsWrapper';

/**
 * Renders the Subscriptions page
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const SubscriptionLevelsPage = () => {
  return (
    <>
      <WidgetSectionTitle
        title='Subscription Level Management'
        className='mb-2'
      />
      <SubscriptionLevelsWrapper />
    </>
  );
};

export default SubscriptionLevelsPage;
