import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import PromptLink from 'components/common/PromptLink';
import SoftBadge from 'components/common/SoftBadge';
import useApplication from 'hooks/useApplication';
import useAuthentication from 'hooks/useAuthentication';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Collapse, Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

/**
 * Renders a vertical menu for the side and top nav bars.
 *
 * @param {Object} props - The component props
 * @param {Object} props.route - The route object for the menu item
 * @returns {JSX.Element|null} The rendered vertical menu item
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.1
 */
const NavbarVerticalMenu = ({ route }) => {
  const { pathname } = useLocation();

  const {
    authentication: { roles }
  } = useAuthentication();

  const {
    application: { isDark, showBurgerMenu, showDevPages },
    setApplication
  } = useApplication();

  /**
   * Checks if a route is active.
   *
   * @param {Object} route - The route object to check
   * @returns {boolean} - Returns true if the route is active, false otherwise
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.1
   */
  const isRouteActive = route => {
    if (pathname === route.to) {
      return true;
    } else if (route.children) {
      return route.children.some(isRouteActive);
    }
    return false;
  };

  const [open, setOpen] = useState(isRouteActive(route));

  /**
   * Handles the click event of the navigation item in the vertical navbar menu.
   * If the burger menu is currently shown, it toggles the visibility of the burger menu.
   *
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.1
   */
  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setApplication('showBurgerMenu', !showBurgerMenu);
    }
  };

  /**
   * Toggles the open state of the navbar vertical menu.
   *
   * @param {Event} e - The event object
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.1
   */
  const toggleOpen = e => {
    e.preventDefault();
    setOpen(!open);
  };

  /**
   * Sets the open state of the menu item when the route changes.
   */
  useEffect(() => {
    setOpen(isRouteActive(route));
  }, [pathname, route]);

  // Return null if the item is disabled
  if (route.itemDisable) {
    return null;
  }

  // Return null if the route is under development and development pages are hidden
  if (route.development && !showDevPages) {
    return null;
  }

  // Return null if the user does not have one of the required roles
  if (
    roles &&
    route.allowedRoles &&
    !roles.some(role => route.allowedRoles.includes(role.slug))
  ) {
    return null;
  }

  return (
    <>
      <Nav.Item as='li' className='position-relative'>
        <Nav.Link
          end={route.exact}
          to={route.to}
          as={PromptLink}
          onClick={handleNavItemClick}
          className={classNames(
            'dropdown-indicator cursor-pointer position-relative',
            {
              'text-500': !route.active
            }
          )}
          style={
            pathname === route.to
              ? { color: 'var(--falcon-primary)' }
              : pathname.includes(route.to)
              ? {
                  color: 'var(--falcon-navbar-vertical-link-hover-color)'
                }
              : {}
          }
          aria-expanded={open}>
          <Flex alignItems='center' className='navbar-vertical-item-label'>
            <span className='pe-1 nav-link-icon'>
              {route.icon && (
                <FontAwesomeIcon
                  icon={[route.iconCollection, route.icon]}
                  transform={`${
                    route.iconRotation ? `rotate-${route.iconRotation}` : ''
                  } ${route.iconGrow ? `grow-${route.iconGrow}` : ''}`}
                  width='16px'
                  className='ms-1'
                />
              )}
            </span>
            <AdvancedPopover
              placement='right'
              popperConfig={{
                strategy: 'absolute',
                modifiers: [
                  {
                    name: 'offset',
                    enabled: true,
                    options: {
                      offset: [-7, 0]
                    }
                  },
                  {
                    name: 'arrow',
                    enabled: true,
                    options: {
                      element: '.arrow-indicator',
                      padding: 30,
                      boundary: 'viewport',
                      arrowElement: '.arrow-indicator',
                      constraints: [
                        {
                          to: 'window',
                          attachment: 'together',
                          pin: true
                        }
                      ],
                      forceAbsolute: true
                    }
                  }
                ]
              }}
              popoverText={route.description}
              showArrow={true}>
              <span className='nav-link-text position-relative'>
                {route.name}{' '}
                {route.development && (
                  <FontAwesomeIcon
                    icon='wrench'
                    transform='grow-4 flip-h rotate-22'
                    title='In Development'
                    className='text-warning fs--2 ms-1 menu-dev-indicator'
                  />
                )}
              </span>
            </AdvancedPopover>
            {route.badge && (
              <SoftBadge
                pill
                bg={route.badge.type}
                className='ms-2 position-relative'>
                {route.badge.text}
              </SoftBadge>
            )}
          </Flex>
          {route.children && (
            <Button
              onClick={toggleOpen}
              variant='outline-secondary'
              className={classNames(
                'position-absolute end-0 p-0 navbar-vertical-submenu-indicator d-flex align-items-center justify-content-center',
                {
                  'text-200 border-0': isDark,
                  'text-400 border-0': !isDark
                }
              )}>
              <AdvancedPopover
                placement='top'
                popperConfig={{
                  strategy: 'absolute'
                }}
                className='position-relative'
                popoverText={open ? 'Collapse' : 'Expand'}
                showArrow={true}>
                <FontAwesomeIcon
                  icon={['fas', `chevron-${open ? 'up' : 'down'}`]}
                  transform={`shrink-5 up-${open ? '0' : '1'}`}
                />
              </AdvancedPopover>
            </Button>
          )}
        </Nav.Link>
        {route.children && (
          <Collapse in={open}>
            <Nav className='flex-column nav' as='ul'>
              {route.children.map((childRoute, index) => (
                <NavbarVerticalMenu route={childRoute} key={index} />
              ))}
            </Nav>
          </Collapse>
        )}
      </Nav.Item>
    </>
  );
};

NavbarVerticalMenu.propTypes = {
  route: PropTypes.shape({
    allowedRoles: PropTypes.array,
    active: PropTypes.bool,
    badge: PropTypes.object,
    children: PropTypes.array,
    description: PropTypes.string,
    development: PropTypes.bool,
    exact: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    iconCollection: PropTypes.string,
    iconGrow: PropTypes.number,
    iconRotation: PropTypes.number,
    itemDisable: PropTypes.bool,
    name: PropTypes.string.isRequired,
    to: PropTypes.string
  }).isRequired
};

export default NavbarVerticalMenu;
