import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, Dropdown } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Flex from './Flex';

library.add(fas);

const IconSelector = () => {
  const [icons, setIcons] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredIcons, setFilteredIcons] = useState([]);
  const [displayedIcons, setDisplayedIcons] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState('border-none');

  useEffect(() => {
    const filteredIcons = Object.keys(fas)
      .filter(iconKey =>
        fas[iconKey].iconName.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) =>
        fas[a].iconName.localeCompare(fas[b].iconName, 'en', {
          sensitivity: 'base'
        })
      );

    setDisplayedIcons(filteredIcons.slice(0, 50));
    setFilteredIcons(filteredIcons);
  }, [icons, searchTerm]);

  useEffect(() => {
    const icons = Object.keys(fas)
      .filter(iconKey => fas[iconKey].iconName !== 'faFontAwesomeLogoFull')
      .sort((a, b) =>
        fas[a].iconName.localeCompare(fas[b].iconName, 'en', {
          sensitivity: 'base'
        })
      );

    setIcons(icons);
  }, []);

  const handleScroll = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      const newDisplayedIcons = displayMoreIcons();
      setDisplayedIcons(newDisplayedIcons);
    }
  };

  const displayMoreIcons = () => {
    const nextBatch = filteredIcons.slice(
      displayedIcons.length,
      displayedIcons.length + 50
    );
    return [...displayedIcons, ...nextBatch];
  };

  const handleDropdownToggle = isOpen => {
    if (isOpen) {
      setDisplayedIcons(filteredIcons.slice(0, 50));
    }
  };

  return (
    <Dropdown onToggle={handleDropdownToggle}>
      <Dropdown.Toggle
        variant='border border-300 px-2'
        style={{
          paddingBottom: '0px',
          paddingTop: '2px',
          height: '29px',
          boxShadow: 'var(--falcon-box-shadow-inset)'
        }}>
        <FontAwesomeIcon icon={selectedIcon} />
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{ maxHeight: '200px', overflowY: 'auto' }}
        onScroll={handleScroll}
        className='p-1'>
        <FormControl
          as='input'
          size='sm'
          type='text'
          autoComplete='off'
          placeholder='Search icons...'
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className='rounded-0'
        />
        {displayedIcons.map(iconKey => {
          const icon = fas[iconKey];
          return (
            <Dropdown.Item
              key={iconKey}
              onClick={() => setSelectedIcon(icon.iconName)}>
              <Flex>
                <div className='me-3 text-center' style={{ width: '1em' }}>
                  <FontAwesomeIcon icon={icon} transform='grow-4' />
                </div>
                {icon.iconName}
              </Flex>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default IconSelector;
