import CardDropdown from 'components/common/CardDropdown';
import { BarChart, MapChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { chartJsMapDefaultOptionswithoutFilter } from 'helpers/chartjs-utils';
import { getColor, rgbaColor } from 'helpers/utils';
import React, { forwardRef, useRef, useState } from 'react';
import { Card, Dropdown, Modal } from 'react-bootstrap';
// import FalconCardHeader from 'components/common/FalconCardHeader';
import world from 'assets/json/world.json';
import Flex from 'components/common/Flex';
import SimpleSpinner from 'components/utilities/AppSpinner/smallSpinner';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import VisualizationsQueryModal from '../InspectorModal';

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  MapChart,
  CanvasRenderer,
  ToolboxComponent,
  LegendComponent,
  VisualMapComponent
]);

// Register the world map
echarts.registerMap('world', { geoJSON: world });

/**
 * Returns the options for a map chart
 *
 * @param {Object} options - The options object
 * @param {Array} options.data - The data for the chart
 * @returns {Object} The chart options
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
const getMapChartOptions = ({ data, isDark }) => ({
  ...chartJsMapDefaultOptionswithoutFilter({ data, isDark }),
  visualMap: {
    ...chartJsMapDefaultOptionswithoutFilter({ data }).visualMap,
    left: 0,
    bottom: 0
  }
});

/**
 * Renders a world map with bar chart percentages
 *
 * @component
 * @param {Object} props - The component props
 * @param {Array} props.data - The data for the bar chart
 * @param {Object} props.style - The style object for the world map container
 * @param {React.Ref} ref - The ref object for the world map component
 * @returns {JSX.Element} The rendered WorldMap component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
const WorldMap = forwardRef(({ data, style }, ref) => {
  const {
    application: { isDark }
  } = useApplication();
  return (
    <div className='position-relative'>
      <div className='testRatio'>
        <ReactEChartsCore
          ref={ref}
          echarts={echarts}
          option={getMapChartOptions({ data, isDark })}
          style={style}
          className='position-absolute all-0'
        />
      </div>
    </div>
  );
});

WorldMap.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    }).isRequired
  ),
  style: PropTypes.object.isRequired,
  minZoomLevel: PropTypes.number,
  maxZoomLevel: PropTypes.number
};

/**
 * Returns the options for a bar chart
 *
 * @param {Object} options - The options for the bar chart
 * @param {Array} options.data - The data for the bar chart
 * @returns {Object} The options for the bar chart
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
const getBarChartOptions = ({ data }) => ({
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    axisPointer: {
      type: 'none'
    },
    borderWidth: 0,
    backgroundColor: getColor('black'),
    textStyle: {
      fontWeight: 500,
      fontSize: 12,
      color: getColor('white')
    },
    transitionDuration: 0,
    formatter: params => {
      return `<div class="d-flex flex-column"><span>${params[0].axisValue.toUpperCase()}: <b>${Number(
        params[0].value
      ).toLocaleString()}%</b></span></div>`;
    }
  },
  xAxis: {
    type: 'category',
    data: data[0],
    axisLabel: {
      color: getColor('dark'),
      formatter: value => value,
      rotate: 45
    },
    axisTick: {
      show: false,
      alignWithLabel: true,
      lineStyle: {
        color: getColor('border-color')
      }
    },
    axisLine: {
      show: false
    },
    splitLine: {
      show: false
    },
    zLevel: 1,
    z: 1
  },
  yAxis: {
    type: 'value',
    axisTick: {
      show: true,
      alignWithLabel: true,
      lineStyle: {
        color: getColor('gray-200')
      }
    },
    splitLine: {
      lineStyle: {
        color: getColor('gray-200'),
        type: 'dashed'
      },
      interval: 1
    },
    axisLabel: {
      color: getColor('dark'),
      formatter: value => `${value}%`,
      fontWeight: 500,
      padding: [3, 0, 0, 0],
      margin: 12,
      showMaxLabel: false,
      showMinLabel: false
    },
    axisLine: {
      show: false
    },
    zLevel: 2,
    z: 2
  },
  series: [
    {
      type: 'bar',
      data: data[1],
      itemStyle: {
        borderRadius: [10, 10, 0, 0],
        color: getColor('primary')
      },
      barWidth: 25,
      showBackground: true,
      backgroundStyle: {
        borderRadius: [10, 10, 0, 0],
        color: rgbaColor(getColor('gray-200'), 0.5)
      }
    }
  ],
  grid: {
    containLabel: true,
    right: 0,
    left: 0,
    bottom: 0,
    top: 15
  },
  animation: false
});

/**
 * Renders a percentage chart component
 *
 * @component
 * @param {Object} props - The component props
 * @param {Array} props.data - The data for the chart
 * @returns {JSX.Element} The rendered component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
const PercentageChart = ({ data }) => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getBarChartOptions({ data })}
      style={{ height: '12rem' }}
    />
  );
};

PercentageChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired
};

/**
 * Renders a card component with a bar chart and a world map
 *
 * @component
 * @param {Object} props - The component props
 * @param {Object} props.mapData - The data for the world map
 * @param {boolean} props.wrapperLoader - Flag indicating if the loader should be displayed
 * @returns {JSX.Element} The rendered component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
const MapCardBarChartPercentages = React.memo(({ mapData, wrapperLoader }) => {
  const [queryDiscoverModal, setQueryDiscover] = useState(false);

  const chartRef = useRef(null);

  return (
    <>
      <Card className='h-100 shadow-none border'>
        <Card.Body className='p-2'>
          <h6 className='mb-1 text-nowrap text-700 fw-normal z-index-2 position-relative'>
            Top Event Source Countries
          </h6>
          {!wrapperLoader && (
            <Flex
              justifyContent='center'
              alignContent='middle'
              style={{ zIndex: '222' }}
              className={'position-absolute top-0 end-0 cursor-pointer'}>
              <CardDropdown style={{ zIndex: 9999, position: 'absolute' }}>
                <div className='py-2'>
                  <Dropdown.Item
                    onClick={() => {
                      setQueryDiscover(true);
                    }}>
                    Inspect
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            </Flex>
          )}
          {wrapperLoader && (
            <span
              className='fw-light position-absolute top-0 end-0 z-index-2'
              style={{ marginRight: '15px', marginTop: '15px' }}>
              <SimpleSpinner size='sm' />
            </span>
          )}
          <div
            className={`position-absolute all-0 align-items-center justify-content-around bg-card-spinner ${
              wrapperLoader ? 'd-flex' : 'd-none'
            } ${wrapperLoader && 'bg-card-spinner-refresh z-index-1 '}`}></div>
          <WorldMap
            data={mapData?.data}
            ref={chartRef}
            style={{ width: '100%', height: '100%' }}
          />
        </Card.Body>
      </Card>
      <Modal
        centered
        size={'lg'}
        animation={false}
        show={queryDiscoverModal}
        onHide={() => setQueryDiscover(false)}
        aria-labelledby='modalQueryInspector'>
        <VisualizationsQueryModal allOperations={[mapData]} />
      </Modal>
    </>
  );
});

MapCardBarChartPercentages.propTypes = {
  mapData: PropTypes.object,
  wrapperLoader: PropTypes.bool
};

export default MapCardBarChartPercentages;
