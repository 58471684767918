import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { getChartOptions } from './chartOptions';

echarts.use([
  LineChart,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent
]);

/**
 * Renders a histogram chart component.
 *
 * @param {Object} props - The component props
 * @param {Array} props.data - The data for the chart
 * @param {Object} props.style - The style object for the chart
 * @returns {JSX.Element} The rendered histogram chart component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const HistogramChart = ({ data, style }) => {
  const {
    application: { isDark }
  } = useApplication();

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getChartOptions(data, isDark)}
      style={style}
    />
  );
};

HistogramChart.propTypes = {
  data: PropTypes.array.isRequired,
  style: PropTypes.object
};

export default HistogramChart;
