import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bgNavbar from 'assets/img/generic/bg-navbar.png';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import PromptLink from 'components/common/PromptLink';
import ScopesSelectorButton from 'components/common/scope-selector/ScopesSelectorButton';
import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
import {
  navbarBreakPoint,
  topNavbarBreakpoint
} from 'context/ApplicationProvider';
import useApplication from 'hooks/useApplication';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useLock from 'hooks/useLock';
import useLogout from 'hooks/useLogout';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import routes from 'routes/routes';
import NavbarVerticalMenu from './NavbarVerticalMenu';

/**
 * Renders the vertical navbar component.
 *
 * @returns {JSX.Element} The rendered vertical navbar component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.1
 */
const NavbarVertical = () => {
  const { axiosPrivate } = useAxiosPrivate();
  const allRoutes = routes;
  let time = null;

  const {
    application: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu
    },
    setApplication
  } = useApplication();

  const logout = useLogout();
  const lock = useLock();

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  /**
   * Handles the mouse enter event.
   */
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };

  /**
   * Handles the mouse leave event.
   */
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  /**
   * Handles the click event for toggling the vertical navbar.
   * Toggles the 'navbar-vertical-collapsed' class on the HTML element.
   * Updates the 'isNavbarVerticalCollapsed' state.
   */
  const handleToggleClick = () => {
    document
      .getElementsByTagName('html')[0]
      .classList.toggle('navbar-vertical-collapsed');
    setApplication('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed);
  };

  return (
    <Navbar
      expand={navbarBreakPoint}
      className='navbar-vertical'
      variant='light'>
      <Flex
        justifyContent='start'
        alignItems='center'
        className='position-relative'>
        <Logo at='navbar-vertical' />
        <div style={{ zIndex: 2 }} className='d-none d-lg-block'>
          <ScopesSelectorButton />
        </div>
      </Flex>

      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className='card-bg border border-top-0 border-bottom-0 border-start-0 border-gray-400'
        style={{
          backgroundImage:
            navbarStyle === 'vibrant'
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : 'none'
        }}>
        <div className='navbar-vertical-content scrollbar pt-2'>
          <Nav className='flex-column' as='ul'>
            <li className='position-relative nav-item'>
              <a
                className='nav-link position-relative'
                href='#'
                onClick={handleToggleClick}>
                <div className='d-flex align-items-center navbar-vertical-item-label'>
                  <span className='pe-1 nav-link-icon'>
                    <FontAwesomeIcon
                      icon={['fas', 'list']}
                      width='16px'
                      className='ms-1'
                    />
                  </span>
                  <span className='nav-link-text position-relative'>Menu</span>
                </div>
              </a>
            </li>
            {allRoutes[0].children.map((route, index) => (
              <NavbarVerticalMenu route={route} key={index} />
            ))}
            <Nav.Item as='li' className='position-relative'>
              <Nav.Link
                as={PromptLink}
                className='nav-link position-relative'
                to='#'
                onClick={lock}>
                <div className='d-flex align-items-center navbar-vertical-item-label'>
                  <span className='pe-1 nav-link-icon'>
                    <FontAwesomeIcon
                      icon={['fas', 'lock']}
                      width='16px'
                      className='ms-1'
                    />
                  </span>
                  <span className='nav-link-text position-relative'>
                    Lock Session
                  </span>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as='li' className='position-relative'>
              <Nav.Link
                as={PromptLink}
                className='nav-link position-relative'
                to='#'
                onClick={() => logout(axiosPrivate)}>
                <div className='d-flex align-items-center navbar-vertical-item-label'>
                  <span className='pe-1 nav-link-icon'>
                    <FontAwesomeIcon
                      icon={['fas', 'right-from-bracket']}
                      width='16px'
                      className='ms-1'
                    />
                  </span>
                  <span className='nav-link-text position-relative'>
                    Log Out
                  </span>
                </div>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {navbarPosition === 'combo' && (
            <div className={`d-${topNavbarBreakpoint}-none`}>
              <div className='navbar-vertical-divider'>
                <hr className='navbar-vertical-hr my-2' />
              </div>
              <Nav navbar>
                <NavbarTopDropDownMenus />
              </Nav>
            </div>
          )}
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string,
  route: PropTypes.object
};

export default NavbarVertical;
