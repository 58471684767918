import classNames from 'classnames';
import { rgbaColor } from 'helpers/utils';
import { useCluster } from 'hooks/admin-contexts/useCluster';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Card, Col, Row, Spinner } from 'react-bootstrap';
import { useCountUp } from 'react-countup';
import ArchSplitLineChart from './ArchSplitLineChart';

const ActiveShards = () => {
  const {
    cluster: {
      clusterHealth: {
        active_shards,
        active_shards_percent_as_number,
        active_primary_shards,
        unassigned_shards,
        initializing_shards,
        status
      }
    }
  } = useCluster();
  const [activeShardsCount, setActiveShardsCount] = useState(0);
  const [activePrimaryShardsCount, setActivePrimaryShardsCount] = useState(0);
  const [unassignedShardsCount, setUnassignedShardsCount] = useState(0);
  const [initializingShardsCount, setInitializingShardsCount] = useState(0);
  const [activeShardPercentage, setActiveShardPercentage] = useState(0);
  const [barColor, setBarColor] = useState('secondary');

  useEffect(() => {
    if (unassignedShardsCount > 0 && status === 'red') {
      setBarColor('danger');
    } else if (initializingShardsCount > 0 && status === 'red') {
      setBarColor('danger');
    } else if (unassignedShardsCount > 0 && status === 'yellow') {
      setBarColor('warning');
    } else if (initializingShardsCount > 0 && status === 'yellow') {
      setBarColor('warning');
    } else {
      setBarColor('success');
    }
  }, [unassignedShardsCount, initializingShardsCount]);

  const memoizedCallback = useCallback(value => {
    return value.toLocaleString();
  }, []);

  const { update } = useCountUp({
    ref: 'activeShardsCounter',
    decimals: 0,
    duration: 2.75,
    end: activeShardsCount || 0,
    formattingFn: memoizedCallback,
    preserveValue: true
  });

  useEffect(() => {
    setActiveShardPercentage(active_shards_percent_as_number);
    setActiveShardsCount(active_shards);
    setActivePrimaryShardsCount(active_primary_shards);
    setUnassignedShardsCount(unassigned_shards);
    setInitializingShardsCount(initializing_shards);
    update(active_shards);
  }, [
    active_shards,
    active_primary_shards,
    unassigned_shards,
    initializing_shards,
    active_shards_percent_as_number
  ]);

  return (
    <Card
      className={`h-100 shadow-none border status-card ${
        !['secondary', 'success'].includes(barColor) ? barColor : null
      }`}>
      {!active_shards ? (
        <div className='position-absolute d-flex justify-content-center align-items-center h-100 w-100 navbar-glass-loader'>
          <Spinner animation='border' />
        </div>
      ) : null}
      <Card.Body className='p-2'>
        <Row className='g-0'>
          <Col
            xs={5}
            className='d-flex flex-column h-100 justify-content-between'>
            <h6 className='mb-2 text-900 text-nowrap'>Shards</h6>
            <h6 className='fs-2 fw-normal mb-0 position-relative'>
              <span id='activeShardsCounter' className='font-monospace w-100' />
              {active_shards ? (
                <Badge
                  pill
                  className={classNames(
                    'bg-soft-success align-top mt-1 fs--1 fw-normal text-success',
                    {
                      'bg-soft-danger': unassignedShardsCount > 0,
                      'bg-soft-warning': initializingShardsCount > 0,
                      'bg-soft-success': activeShardPercentage === 100
                    },
                    {
                      'text-danger': unassignedShardsCount > 0,
                      'text-warning': initializingShardsCount > 0,
                      'text-success': activeShardPercentage === 100
                    }
                  )}>
                  {Number(activeShardPercentage).toLocaleString() + '%'}
                </Badge>
              ) : null}
            </h6>
            <span></span>
          </Col>
          <Col xs='7' className='h-100'>
            <ArchSplitLineChart
              data={{
                labels: ['Primary', 'Replica', 'Unassigned', 'Initializing'],
                values: [
                  activePrimaryShardsCount,
                  activeShardsCount - activePrimaryShardsCount,
                  unassignedShardsCount,
                  initializingShardsCount
                ],
                backgroundColors: [
                  rgbaColor('#91CC75', 0.8),
                  rgbaColor('#36A2EB', 0.8),
                  rgbaColor('#FF9F40', 0.8),
                  rgbaColor('#FF6384', 0.8)
                ],
                borderColors: [
                  rgbaColor('#91CC75', 0),
                  rgbaColor('#36A2EB', 0),
                  rgbaColor('#FF9F40', 0),
                  rgbaColor('#FF6384', 0)
                ]
              }}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ActiveShards.propTypes = {
  data: PropTypes.array
};

export default ActiveShards;
