import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useBookmarks from 'hooks/admin-contexts/useBookmarks';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Modal } from 'react-bootstrap';
import BookmarksAddForm from '../forms/BookmarksAddForm';
/**
 * bookmarks Edit modal
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ModalBookmarkAdd = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    bookmarks: { addBookmarkModal },
    setBookmarks
  } = useBookmarks();

  return (
    <Modal
      centered
      size={addBookmarkModal?.fullScreen ? 'xl' : 'lg'}
      animation={false}
      backdrop='static'
      keyboard={false}
      show={addBookmarkModal?.open}
      onHide={() =>
        setBookmarks(prevState => ({
          ...prevState,
          addBookmarkModal: { open: false }
        }))
      }
      aria-labelledby='modalBookmarkAdd'
      contentClassName={
        addBookmarkModal?.fullScreen &&
        'border-0 border-top-left-radius-0 border-top-right-radius-0 h-100'
      }
      dialogClassName={
        addBookmarkModal?.fullScreen &&
        'position-absolute all-0 fullscreen-modal-dialog scrollbar overflow-x-hidden'
      }>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Add a bookmark
          <AdvancedPopover
            containerId='resetConfirmModal'
            placement='top'
            popoverText='Add a bookmark'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
        <div
          className='d-flex justify-content-end position-absolute right-0 '
          style={{ right: '3rem' }}>
          <FontAwesomeIcon
            icon={
              addBookmarkModal?.fullScreen
                ? 'down-left-and-up-right-to-center'
                : 'up-right-and-down-left-from-center'
            }
            className='me-2 cursor-pointer'
            style={{
              color: isDark ? 'white' : 'black',
              opacity: 0.8
            }}
            onClick={() => {
              setBookmarks(prevState => ({
                ...prevState,
                addBookmarkModal: {
                  open: true,
                  fullScreen: !addBookmarkModal?.fullScreen
                }
              }));
            }}
          />
        </div>
      </Modal.Header>

      <Modal.Body className='p-2'>
        <BookmarksAddForm />
      </Modal.Body>
    </Modal>
  );
};

export default ModalBookmarkAdd;
