import { useContext } from 'react';
import { UnsavedChangesContext } from '../context/UnsavedChangesProvider';

/**
 * Custom hook that provides access to the UnsavedChangesContext.
 *
 * @returns {UnsavedChangesContext} The UnsavedChangesContext object
 * @throws {Error} Throws an error if used outside of an UnsavedChangesProvider
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 *
 * @see UnsavedChangesProvider
 */
const useUnsavedChanges = () => {
  const context = useContext(UnsavedChangesContext);

  // Ensure the context is used within the UnsavedChangesProvider
  if (!context) {
    throw new Error(
      'useUnsavedChanges must be used within an UnsavedChangesProvider'
    );
  }
  return context;
};

export default useUnsavedChanges;
