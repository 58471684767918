import ReactECharts from 'echarts-for-react';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';

const WorldMap = ({
  points = [],
  indicator = '',
  locationInfo = '',
  asnInfo = ''
}) => {
  // Determine if points data is available
  const hasPoints = points.length > 0;
  const [longitude, latitude] = hasPoints ? points[0] : [0, 0];

  // Set zoom level based on the existence of points
  const zoomLevel = hasPoints ? 4 : 1;

  // Calculate a center point slightly to the left of the actual coordinates
  // This creates space for the tooltip on the right
  const centerPoint = hasPoints ? [longitude + 25, latitude] : undefined;

  // Constructing the label string conditionally
  const labelText = [
    indicator ? indicator : '',
    asnInfo ? `\n${asnInfo}` : '',
    locationInfo ? `\n${locationInfo}` : '',
    hasPoints ? `\n${longitude}, ${latitude}` : ''
  ]
    .filter(line => line) // Remove empty lines
    .join('\n'); // Join with newlines

  const options = {
    tooltip: {
      show: false
    },
    geo: {
      map: 'world',
      roam: false,
      center: centerPoint,
      zoom: zoomLevel,
      itemStyle: {
        areaColor: 'transparent',
        borderColor: getColor('gray-700'),
        borderWidth: 1
      },
      emphasis: {
        label: {
          show: false
        },
        itemStyle: {
          areaColor: 'transparent'
        }
      }
    },
    series: [
      {
        type: 'scatter',
        coordinateSystem: 'geo',
        symbolSize: hasPoints ? 10 : 0,
        itemStyle: {
          color: '#FFA500'
        },
        data: hasPoints ? points : [],
        label: {
          show: labelText !== '',
          formatter: () => labelText,
          position: 'right',
          distance: 10,
          color: '#ffffff',
          backgroundColor: 'rgba(0, 0, 0, 1)',
          padding: 5,
          borderRadius: 5
        }
      }
    ]
  };

  return (
    <ReactECharts
      option={options}
      style={{
        height: '200px',
        width: '100%',
        pointerEvents: 'none',
        cursor: 'default'
      }}
    />
  );
};

WorldMap.propTypes = {
  points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  indicator: PropTypes.string,
  locationInfo: PropTypes.string,
  asnInfo: PropTypes.string
};

export default WorldMap;
