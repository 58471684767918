import React, { useEffect, useState } from 'react';
import ArticleView from './ArticleView';
import useArticles from 'hooks/admin-contexts/useArticles';
import RecordsLoading from 'components/common/RecordsLoading';
import { useParams } from 'react-router-dom';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticleViewlayout = () => {
  let { slug } = useParams();
  const {
    articles: { data }
  } = useArticles();
  const [article, setArticle] = useState({});

  /**
   * set article from slug at route
   */
  useEffect(() => {
    try {
      let foundArticle = data.find(item => item.slug === slug);
      setArticle(foundArticle);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setArticle({});
    }
  }, [data]);

  return (
    <>
      {article && <ArticleView article={article} />}
      {!article && <RecordsLoading />}
    </>
  );
};

export default ArticleViewlayout;
