import React from 'react';
import PropTypes from 'prop-types';
import SubscriptionLevelsProvider from '../../../../../../../context/admin-contexts/SubscriptionLevelsProvider';
import ProductsProvider from 'context/admin-contexts/ProductsProvider';
import SubscriptionLevelsLayout from './SubscriptionLevelsLayout';
import ModalSubscriptionLevelAdd from './components/modals/SubscriptionLevelsAddModal';
import ModalSubscriptionLevelEdit from './components/modals/SubscriptionLevelsEditModal';
import ModalSubscriptionLevelDelete from './components/modals/SubscriptionLevelsDeleteModal';
import SubscriptionLevelsRestoreModal from './components/modals/SubscriptionLevelsRestoreModal';

/**
 * Renders the SubscriptionLevelsWrapper component
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const SubscriptionLevelsWrapper = props => {
  return (
    <ProductsProvider>
      <SubscriptionLevelsProvider>
        {props.children}
        <SubscriptionLevelsLayout />
        <ModalSubscriptionLevelAdd />
        <ModalSubscriptionLevelEdit />
        <ModalSubscriptionLevelDelete />
        <SubscriptionLevelsRestoreModal />
      </SubscriptionLevelsProvider>
    </ProductsProvider>
  );
};

SubscriptionLevelsWrapper.propTypes = {
  children: PropTypes.node
};

export default SubscriptionLevelsWrapper;
