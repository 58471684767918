import LeargasAPI from 'api';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useESGlobalFilters } from 'hooks/useGlobalFilters';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

export const userOrganizationsSettings = {
  data: [],
  loading: true,
  fetch: false,
  pendingInvitations: [],
  removePendingUserModal: {},
  inviteUserModal: { open: false },
  orgAddModal: {
    open: false,
    fullScreen: false
  },
  orgEditModal: {
    open: false,
    data: {},
    fullScreen: false
  },
  orgDeleteModal: {
    open: false,
    data: {}
  },
  orgRestoreModal: {
    open: false,
    data: {}
  },
  DeletedOrgs: {
    data: false
  },
  requiredOrganizations: {
    organizations: {
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin'],
      delete: ['super-admin', 'admin']
    },
    organizationsNotes: {
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin'],
      delete: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    organizations: {
      read: ['organizations:read'],
      write: ['organizations:write'],
      delete: ['organizations:delete']
    },
    organizationsNotes: {
      read: ['organizationsNotes:read'],
      write: ['organizationsNotes:write'],
      delete: ['organizationsNotes:delete']
    }
  }
};

/**
 * Create UserOrganizationsContext
 * @type {React.Context<{}>}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const UserOrganizationsContext = createContext();

/**
 * Create UserOrganizationsProvider
 * @param props
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 *  <UserOrganizationsProvider>
 *   {children}
 * </UserOrganizationsProvider>
 * )
 */
const UserOrganizationsProvider = props => {
  const [organizations, setOrganizations] = useState(userOrganizationsSettings);
  const {
    esGlobalFilters: { scopes }
  } = useESGlobalFilters();

  const { axiosPrivate } = useAxiosPrivate();
  /**
   * Get all application organizations
   * @returns {Promise<void>}
   * @async
   * @function
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const getUserOrganizations = async () => {
    try {
      let res = await LeargasAPI.AccountOrganizations.getAccOrgs(axiosPrivate);
      if (res instanceof Error) throw res;
      let resArray = res;

      const filteredOrgs = resArray.filter(orgapi => {
        const orgUnchecked = scopes.some(
          org => org._id === orgapi._id && org?.checked === false
        );
        return !orgUnchecked;
      });
      setOrganizations(prevState => ({
        ...prevState,
        data: filteredOrgs
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setOrganizations(prevState => ({
        ...prevState,
        fetch: false,
        loading: false
      }));
    } finally {
      setOrganizations(prevState => ({
        ...prevState,
        fetch: false,
        loading: false
      }));
    }
  };

  /**
   * Get all application organizations on fetch state change
   */
  useEffect(() => {
    if (organizations.fetch) {
      getUserOrganizations();
    }
  }, [organizations.fetch]);

  /**
   * Get all application organizations on component mount
   */
  useEffect(() => {
    getUserOrganizations();
  }, []);

  /**
   * Get all application organizations on scopes change
   */
  useEffect(() => {
    getUserOrganizations();
  }, [scopes]);

  return (
    <UserOrganizationsContext.Provider
      value={{
        organizations,
        setOrganizations
      }}>
      {props.children}
    </UserOrganizationsContext.Provider>
  );
};

UserOrganizationsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default UserOrganizationsProvider;
