import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import LuceneSearchModal from 'components/lucene-search-modal/LuceneSearchModal';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

/**
 * Renders a Search button for the data navigator
 * @returns {JSX.Element} The rendered SearchButton component
 *
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const SearchButton = () => {
  const [show, setShow] = useState(false);

  return (
    <div className='d-inline-flex align-items-center'>
      <AdvancedPopover
        placement='bottom'
        popoverText='Search for specific terms in all fields'
        showArrow={true}>
        <Button
          variant='link'
          size='sm'
          onClick={() => setShow(true)}
          className='p-0'>
          <FontAwesomeIcon
            icon='search'
            transform='shrink-2 down-2'
            className='px-2 text-700 hover-900'
          />
        </Button>
      </AdvancedPopover>
      <LuceneSearchModal show={show} setShow={setShow} />
    </div>
  );
};

export default SearchButton;
