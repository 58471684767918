import Flex from 'components/common/Flex';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useResources from 'hooks/admin-contexts/useResources';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import AddButton from './AddButton';
import { getResourceColumns } from './Columns/ColumnsDefaults';
import ResourcesTable from './ResourcesTable';
import ResourcesTableFilters from './ResourcesTableFilters';
import ResourcesTableFooter from './ResourcesTableFooter';
import ResourcesTablePagination from './ResourcesTablePagination';
import ResourcesTableWrapper from './ResourcesTableWrapper';
import ShowDeletedButton from './ShowDeletedButton';

/**
 * Resources Main Table components renderer
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const Resources = () => {
  const {
    resources: { showDeleted, data },
    loading: componentLoading,
    setResources
  } = useResources();
  const [globalFilter, setGlobalFilter] = useState('');
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns(getResourceColumns(setResources));
  }, []);

  return (
    <Card className='shadow-none border'>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          <span className='d-block'>Resources</span>
          <Flex justifyContent='end' alignItems='center'>
            <ShowDeletedButton
              showDeleted={showDeleted}
              onClick={() => {
                setResources(prevState => ({
                  ...prevState,
                  showDeleted: !showDeleted
                }));
              }}
            />
            <AddButton
              onClick={() =>
                setResources(prevState => ({
                  ...prevState,
                  addResourceModal: { open: true }
                }))
              }
            />
          </Flex>
        </Flex>
      </Card.Header>
      <Card.Body className='position-relative'>
        {componentLoading ? (
          <LoadingSpinner />
        ) : (
          <ResourcesTableWrapper
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            data={
              showDeleted
                ? data
                : data?.filter(resource => !resource?.isDeleted)
            }
            sortable
            pagination
            perPage={50}>
            <ResourcesTableFilters
              table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <ResourcesTable table columns={columns} />
            <AddButton
              label='Add a resource'
              onClick={() =>
                setResources(prevState => ({
                  ...prevState,
                  addResourceModal: { open: true }
                }))
              }
            />
            <div className='mt-3 d-flex justify-content-between'>
              <ResourcesTableFooter
                table
                rowInfo
                data={
                  showDeleted
                    ? data
                    : data?.filter(resource => !resource?.isDeleted)
                }
                rowCount={
                  showDeleted
                    ? data?.length
                    : data?.filter(resource => !resource?.isDeleted).length
                }
              />
              <ResourcesTablePagination table />
            </div>
          </ResourcesTableWrapper>
        )}
      </Card.Body>
    </Card>
  );
};

export default Resources;
