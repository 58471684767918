import React from 'react';
import ProfileLayout from './ProfileLayout';

const Profile = () => {
  return (
    <>
      <ProfileLayout />
    </>
  );
};

export default Profile;
