import React, { useEffect, useState } from 'react';
import ChangeLog from './ChangeLog';
import useReleases from 'hooks/admin-contexts/useReleases';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import useAuthentication from 'hooks/useAuthentication';

const ChangeLogLayout = () => {
  const { releases } = useReleases();

  const { authentication } = useAuthentication();

  const [hasUpcomingReleases, setHasUpcomingReleases] = useState(false);

  useEffect(() => {
    if (releases) {
      const upcomingReleases = releases.filter(release => !release.released);
      setHasUpcomingReleases(upcomingReleases.length > 0);
    }
  }, [releases]);

  return (
    <>
      {authentication?.roles?.some(role => role.slug === 'super-admin') &&
      hasUpcomingReleases ? (
        <>
          <WidgetSectionTitle
            title='Upcoming Releases'
            transform='down-3'
            className='mb-2'
          />
          {releases
            ? releases
                .filter(release => !release.released)
                .map((release, index) => {
                  const { tag, title, description, released, releasedAt } =
                    release;
                  return (
                    <ChangeLog
                      key={index}
                      tag={tag}
                      title={title}
                      description={description}
                      released={released}
                      releasedAt={releasedAt}
                      release={release}
                      index={index}
                    />
                  );
                })
            : null}
        </>
      ) : null}

      <WidgetSectionTitle
        title='Past Releases'
        transform='down-3'
        className='mb-2'
      />
      {releases
        ? releases
            .filter(release => release.released)
            .map((release, index) => {
              const { tag, title, description, released, releasedAt } = release;
              return (
                <ChangeLog
                  key={index}
                  tag={tag}
                  title={title}
                  description={description}
                  released={released}
                  releasedAt={releasedAt}
                  release={release}
                  index={index}
                />
              );
            })
        : null}
    </>
  );
};

export default ChangeLogLayout;
