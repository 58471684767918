// import { axiosPrivate } from 'api/connection/axios';

/**
 * @description Teams API
 * @summary Provides methods for managing teams
 * @method login Login to a user's account
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
class Teams {
  constructor(route) {
    this.route = route;
  }
  /**
   * @description Create a new team
   * @param {object} request - The request object
   * @param {string} request.body.name - The name of the team
   * @param {string} request.body.description - The description of the team
   * @param {string} request.body.users - The user IDs of the team members
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  createTeam(data, axiosPrivate) {
    return axiosPrivate.post(this.route.createTeam.path, JSON.stringify(data));
  }

  /**
   * @description Get all teams
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  getTeams(axiosPrivate) {
    return axiosPrivate.get(this.route.getTeams.path);
  }

  /**
   * @description Get a team by ID
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  getTeam(id, axiosPrivate) {
    return axiosPrivate.get([this.route.getTeam, id].join('/'));
  }

  /**
   * @description Update a team by ID
   * @param {object} request - The request object
   * @param {string} request.body.name - The name of the team
   * @param {string} request.body.description - The description of the team
   * @param {string} request.body.users - The user IDs of the team members
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  update(id, request, axiosPrivate) {
    return axiosPrivate.put(
      [this.route.updateTeam, id].join('/'),
      JSON.stringify(request)
    );
  }

  /**
   * @description Delete a team by ID
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  delete(id, axiosPrivate) {
    return axiosPrivate.delete([this.route.deleteTeam, id].join('/'));
  }
}

export default Teams;
