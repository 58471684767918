import Flex from 'components/common/Flex';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useOrganizations from 'hooks/admin-contexts/useOrganizations';
import useTeams from 'hooks/admin-contexts/useTeams';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import AddButton from './AddButton';
import { getTeamColumns } from './Columns/ColumnsDefaults';
import ShowDeletedButton from './ShowDeletedButton';
import TeamsTable from './TeamsTable';
import TeamsTableFilters from './TeamsTableFilters';
import TeamsTableFooter from './TeamsTableFooter';
import TeamsTablePagination from './TeamsTablePagination';
import TeamsTableWrapper from './TeamsTableWrapper';

/**
 * Renders the Teams component
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Teams = () => {
  const {
    teams: { data, showDeleted },
    loading: componentLoading,
    setTeams
  } = useTeams();

  const { organizations } = useOrganizations();

  const [columns, setColumns] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  /**
   * Set columns on mount
   */
  useEffect(() => {
    setColumns(getTeamColumns(setTeams, organizations?.data));
  }, [organizations?.data]);

  return (
    <Card className='shadow-none'>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          <span className='d-block'>Teams</span>
          <Flex justifyContent='end' alignItems='center'>
            <ShowDeletedButton
              showDeleted={showDeleted}
              onClick={() => {
                setTeams(prevState => ({
                  ...prevState,
                  showDeleted: !showDeleted
                }));
              }}
            />
            <AddButton
              onClick={() =>
                setTeams(prevState => ({
                  ...prevState,
                  addTeamModal: { open: true }
                }))
              }
            />
          </Flex>
        </Flex>
      </Card.Header>

      <Card.Body className='position-relative'>
        {componentLoading ? (
          <LoadingSpinner grow='10' />
        ) : (
          <TeamsTableWrapper
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            // data={teams}
            data={showDeleted ? data : data?.filter(team => !team?.isDeleted)}
            sortable
            loading={componentLoading}
            pagination
            perPage={50}>
            <TeamsTableFilters
              table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <TeamsTable table columns={columns} />
            <AddButton
              label='Add a team'
              onClick={() =>
                setTeams(prevState => ({
                  ...prevState,
                  addTeamModal: { open: true }
                }))
              }
            />
            <div className='mt-3 d-flex justify-content-between'>
              <TeamsTableFooter
                table
                rowInfo
                data={
                  showDeleted ? data : data?.filter(team => !team?.isDeleted)
                }
                rowCount={
                  showDeleted
                    ? data?.length
                    : data?.filter(team => !team?.isDeleted).length
                }
              />
              <TeamsTablePagination table />
            </div>
          </TeamsTableWrapper>
        )}
      </Card.Body>
    </Card>
  );
};

export default Teams;
