import Flex from 'components/common/Flex';
import AdvancedTableSearchBox from 'components/common/table/TableSearchBox';
import { OverlayTriggerPopover } from 'components/visualizations/OverlayTriggerPopOver';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Popover
} from 'react-bootstrap';
import { BsCheck, BsFillPlusSquareFill } from 'react-icons/bs';
import { FaSlidersH } from 'react-icons/fa';
import { MdFilterAlt } from 'react-icons/md';

const TableFilters = ({
  dataTypeLabel,
  dataTypeLabelPlural,
  globalFilter,
  setGlobalFilter,
  setNewEntryModalShow,
  setSettingsModalShow,
  selectedRowIds,
  setSelectedRowIds,
  showBulkActions,
  handleDelete,
  hideNewEntryToggle,
  hideFiltersToggle
}) => {
  const handleChange = e => {
    e.preventDefault();
    if (e.target.value === 'delete') {
      if (handleDelete) handleDelete(selectedRowIds);
      if (setSelectedRowIds) setSelectedRowIds([]);
    }
  };

  return (
    <>
      <Flex className='pt-3 pb-2'>
        {showBulkActions ? (
          <InputGroup size='sm'>
            <Form.Select
              aria-label='Bulk actions'
              onChange={e => handleChange(e)}>
              <option>With selected...</option>
              <option value='terminate-session' className='text-danger'>
                Terminate Session
              </option>
              <option value='revoke-access' className='text-danger'>
                Revoke Access
              </option>
              <option value='delete' className='text-danger'>
                Delete
              </option>
            </Form.Select>
            <Button variant='primary' id='button-addon2' className='py-0 px-2'>
              <BsCheck onClick={() => setNewEntryModalShow(true)} />
            </Button>
          </InputGroup>
        ) : (
          <AdvancedTableSearchBox
            dataTypeLabelPlural={dataTypeLabelPlural}
            size='xs'
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            table
          />
        )}
        {!hideNewEntryToggle ? (
          <OverlayTriggerPopover PopoverText={`Create a ${dataTypeLabel}`}>
            <span>
              <BsFillPlusSquareFill
                onClick={() => setNewEntryModalShow(true)}
                className='text-primary cursor-pointer'
              />
            </span>
          </OverlayTriggerPopover>
        ) : null}

        {!hideFiltersToggle ? (
          <OverlayTrigger
            placement='top'
            overlay={
              <Popover>
                <Popover.Body className='text-center p-1 fs--1 text-700'>
                  Filter {dataTypeLabelPlural}
                </Popover.Body>
              </Popover>
            }>
            <span className='ms-2'>
              <MdFilterAlt
                onClick={() => alert(true)}
                className='text-600 cursor-pointer'
              />
            </span>
          </OverlayTrigger>
        ) : null}
        <OverlayTriggerPopover PopoverText={`Display Settings`}>
          <span className='ms-2'>
            <FaSlidersH
              onClick={() => setSettingsModalShow(true)}
              className='text-600 cursor-pointer'
            />
          </span>
        </OverlayTriggerPopover>
      </Flex>
    </>
  );
};

TableFilters.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.any,
  setNewEntryModalShow: PropTypes.func,
  setSelectedRowIds: PropTypes.func,
  selectedRowIds: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  delete: PropTypes.func,
  setSettingsModalShow: PropTypes.func,
  setCurrentView: PropTypes.func,
  currentView: PropTypes.string,
  showBulkActions: PropTypes.bool,
  hideViewToggle: PropTypes.bool,
  hideFiltersToggle: PropTypes.bool,
  hideNewEntryToggle: PropTypes.bool,
  dataTypeLabel: PropTypes.string,
  dataTypeLabelPlural: PropTypes.string,
  handleDelete: PropTypes.func
};

export default TableFilters;
