import Flex from 'components/common/Flex';
import { useSearchFilters } from 'context/FiltersProvider';
import useExplore from 'hooks/useExplore';
import luceneParser from 'lucene-query-parser';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Collapse, Form, FormText, Modal } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

const LuceneSearchModal = ({ show, setShow }) => {
  const { applyFilter } = useSearchFilters();
  const {
    state: { fields }
  } = useExplore();

  const [queryInput, setQueryInput] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (show) {
      inputRef.current.focus();
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    setQueryInput('');
    setErrorMessage(null);
  };

  const handleInputChange = e => {
    setQueryInput(e.target.value);
    setErrorMessage(null); // Clear errors when input changes
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    const trimmedQuery = queryInput.trim();

    if (!trimmedQuery) {
      setErrorMessage('Query cannot be empty.');
      return;
    }

    // Call the validation function before parsing
    if (!isValidQuery(trimmedQuery)) {
      setErrorMessage(
        'Invalid query format. Please follow the allowed patterns.'
      );
      return;
    }

    const result = parseAndApplyLuceneQuery(trimmedQuery, fields, applyFilter);
    if (result.success) {
      handleClose();
    } else {
      setErrorMessage(result.error);
    }
  };

  // Validation function to ensure the query matches allowed patterns
  const isValidQuery = query => {
    const patterns = [
      // Basic term
      /^\s*\S+\s*$/,
      // Basic phrase with escaped quotes
      /^\s*"([^"\\]|\\.)*"\s*$/,
      // Field-specific term or phrase with escaped quotes
      /^\s*[^\s:]+:\s*(\S+|"([^"\\]|\\.)*")\s*$/,
      // Negation with escaped quotes
      /^\s*(NOT\s+)?-?\s*[^\s:]+:\s*(\S+|"([^"\\]|\\.)*")\s*$/,
      // OR operator with multiple terms
      /^\s*[^\s:]+:\s*\((\s*\S+\s+OR\s+)*\s*\S+\s*\)\s*$/,
      // Implicit operators with + and - prefixes
      /^\s*[^\s:]+:\s*\((\s*[+-]\S+\s*)+\)\s*$/,
      // Numeric range
      /^\s*[^\s:]+:\s*\[\s*\S+\s+TO\s+\S+\s*\]\s*$/,
      // Wildcard term
      /^\s*[^\s:]+:\s*\S*[*?]\S*\s*$/,
      // Wildcard phrase with escaped quotes
      /^\s*[^\s:]+:\s*"([^"\\]|\\.)*[*?]([^"\\]|\\.)*"\s*$/
    ];

    for (const pattern of patterns) {
      if (pattern.test(query)) {
        return true;
      }
    }
    return false;
  };

  const parseAndApplyLuceneQuery = (luceneQuery, fields, applyFilter) => {
    try {
      const isNegation =
        luceneQuery.startsWith('NOT ') || luceneQuery.startsWith('-');
      const adjustedQuery = isNegation
        ? luceneQuery.replace(/^NOT\s+|-/, '')
        : luceneQuery;
      const parsedQuery = luceneParser.parse(adjustedQuery);

      // console.log('Parsed Query:', JSON.stringify(parsedQuery, null, 2));

      const appliedFilters = new Set();
      if (
        !processNode(
          parsedQuery,
          fields,
          applyFilter,
          isNegation,
          appliedFilters
        )
      ) {
        return {
          error: 'The query could not be converted into any valid filters.'
        };
      }
    } catch (error) {
      console.error('Parse error:', error);
      return { error: 'Invalid Lucene Syntax' };
    }
    return { success: 'Query processed correctly.' };
  };

  // Rest of your existing code...

  const processNode = (
    node,
    fields,
    applyFilter,
    parentNegation = false,
    appliedFilters,
    currentField = null
  ) => {
    if (!node) return false;

    let filtersApplied = false;

    // Check for terms or phrases parsed with <implicit> field
    if (node.field === '<implicit>' && typeof node.term === 'string') {
      filtersApplied = applyMultiMatchFilter(
        node,
        fields,
        applyFilter,
        parentNegation,
        appliedFilters
      );
    } else if (node.term_min !== undefined && node.term_max !== undefined) {
      // Continue with existing range logic...
      const fieldObj = fields.find(field => field.accessor === node.field);
      if (fieldObj && fieldObj.type === 'number') {
        filtersApplied = processRangeOperation(
          node,
          fields,
          applyFilter,
          parentNegation,
          appliedFilters
        );
      } else {
        console.warn(
          `Range operations are not compatible with non-numeric fields.`
        );
      }
    } else if (node.operator) {
      // Handle logical operations
      switch (node.operator.toUpperCase()) {
        case 'OR':
          filtersApplied = processOrOperation(
            node,
            fields,
            applyFilter,
            currentField || node.field,
            appliedFilters
          );
          break;
        case '<IMPLICIT>':
          filtersApplied = processPlusMinusOperation(
            node,
            fields,
            applyFilter,
            currentField || node.field,
            appliedFilters
          );
          break;
        default:
          console.warn('Unhandled operator:', node.operator);
          break;
      }
    } else {
      // Handle specific field terms as previously
      const isTermNegation = node.prefix === '-';
      const isRequired = node.prefix === '+';
      const fieldName = currentField || node.field;

      if (fieldName && typeof node.term === 'string') {
        filtersApplied = applyFieldTermFilter(
          node,
          fields,
          applyFilter,
          parentNegation || isTermNegation,
          isRequired,
          fieldName,
          appliedFilters
        );
      }
    }

    if (!filtersApplied) {
      // Continue recursive processing
      if (node.left) {
        filtersApplied |= processNode(
          node.left,
          fields,
          applyFilter,
          parentNegation,
          appliedFilters,
          currentField || node.field
        );
      }

      if (node.right) {
        filtersApplied |= processNode(
          node.right,
          fields,
          applyFilter,
          parentNegation,
          appliedFilters,
          currentField || node.field
        );
      }
    }

    return filtersApplied;
  };

  const processRangeOperation = (
    node,
    fields,
    applyFilter,
    isNegation,
    appliedFilters
  ) => {
    const fieldObj = fields.find(field => field.accessor === node.field);
    if (!fieldObj || fieldObj.type !== 'number') {
      console.warn(`Invalid field for range operation: ${node.field}`);
      return false;
    }

    const operator = isNegation ? 'isNotBetween' : 'isBetween';
    const value = [Number(node.term_min), Number(node.term_max)].sort();

    const filterKey = `${fieldObj.accessor}|${value.join(':')}`;
    if (!appliedFilters.has(filterKey)) {
      appliedFilters.add(filterKey);

      const filter = {
        id: uuid(),
        customLabel: '',
        enabled: true,
        field: fieldObj,
        inclusion: true,
        operator,
        pinned: false,
        useCustomLabel: false,
        value
      };

      // console.log('Applying range filter:', filter);
      applyFilter(filter);
      return true;
    }
    return false;
  };

  const processOrOperation = (
    node,
    fields,
    applyFilter,
    currentField,
    appliedFilters
  ) => {
    const terms = getAllTermsFromOr(node);
    const fieldObj = fields.find(field => field.accessor === currentField);

    if (!fieldObj) {
      console.warn(`Couldn't match field: ${currentField}`);
      return false;
    }

    const termValues = terms.map(term => term.term);
    const filterKey = `${fieldObj.accessor}|${termValues.join(',')}`;

    if (!appliedFilters.has(filterKey)) {
      appliedFilters.add(filterKey);

      const filter = {
        id: uuid(),
        customLabel: '',
        enabled: true,
        field: fieldObj,
        inclusion: true,
        operator: 'isOneOf',
        pinned: false,
        useCustomLabel: false,
        value: termValues
      };

      // console.log('Applying isOneOf filter:', filter);
      applyFilter(filter);

      return true;
    }

    return false;
  };

  const getAllTermsFromOr = (node, terms = []) => {
    if (node.operator && node.operator.toUpperCase() === 'OR') {
      getAllTermsFromOr(node.left, terms);
      getAllTermsFromOr(node.right, terms);
    } else {
      terms.push(node);
    }
    return terms;
  };

  const applyMultiMatchFilter = (
    node,
    _fields,
    applyFilter,
    isNegation,
    appliedFilters
  ) => {
    const term = node.term;
    const operator = 'multiMatch';
    const filterKey = `multiMatch|${term}`;

    if (!appliedFilters.has(filterKey)) {
      appliedFilters.add(filterKey);

      const filter = {
        id: uuid(),
        customLabel: term,
        enabled: true,
        field: null,
        inclusion: true,
        operator,
        pinned: false,
        useCustomLabel: true,
        value: term
      };

      // console.log('Applying multiMatch filter:', filter);
      applyFilter(filter);
      return true;
    }

    return false;
  };

  const processPlusMinusOperation = (
    node,
    fields,
    applyFilter,
    currentField,
    appliedFilters
  ) => {
    const mustSet = new Set();
    const mustNotSet = new Set();

    gatherTermsByPrefix(node, fields, currentField, mustSet, mustNotSet);

    // Apply positive terms
    if (mustSet.size > 0) {
      const positiveTerms = Array.from(mustSet);
      const positiveFilterKey = `${currentField}|${positiveTerms.join(',')}`;
      const fieldObj = fields.find(field => field.accessor === currentField);

      if (!appliedFilters.has(positiveFilterKey) && fieldObj) {
        appliedFilters.add(positiveFilterKey);

        const positiveFilter = {
          id: uuid(),
          customLabel: '',
          enabled: true,
          field: fieldObj,
          inclusion: true,
          operator: 'isOneOf',
          pinned: false,
          useCustomLabel: false,
          value: positiveTerms
        };

        // console.log(
        //   'Applying isOneOf filter for positive terms:',
        //   positiveFilter
        // );
        applyFilter(positiveFilter);
      }
    }

    // Apply negative terms
    if (mustNotSet.size > 0) {
      const negativeTerms = Array.from(mustNotSet);
      const negativeFilterKey = `${currentField}|${negativeTerms.join(',')}`;
      const fieldObj = fields.find(field => field.accessor === currentField);

      if (!appliedFilters.has(negativeFilterKey) && fieldObj) {
        appliedFilters.add(negativeFilterKey);

        const negativeFilter = {
          id: uuid(),
          customLabel: '',
          enabled: true,
          field: fieldObj,
          inclusion: true,
          operator: 'isNotOneOf',
          pinned: false,
          useCustomLabel: false,
          value: negativeTerms
        };

        // console.log(
        //   'Applying isNotOneOf filter for negative terms:',
        //   negativeFilter
        // );
        applyFilter(negativeFilter);
      }
    }

    return true;
  };

  const gatherTermsByPrefix = (
    node,
    fields,
    currentField,
    mustSet,
    mustNotSet
  ) => {
    if (!node) return;

    if (node.operator && node.operator.toUpperCase() === '<IMPLICIT>') {
      gatherTermsByPrefix(node.left, fields, currentField, mustSet, mustNotSet);
      gatherTermsByPrefix(
        node.right,
        fields,
        currentField,
        mustSet,
        mustNotSet
      );
    } else {
      const prefix = node.prefix || '+';
      const term = node.term;

      if (prefix === '+') {
        mustSet.add(term);
      } else if (prefix === '-') {
        mustNotSet.add(term);
      }
    }
  };

  const applyFieldTermFilter = (
    expr,
    fields,
    applyFilter,
    isNegation,
    isRequired,
    fieldName,
    appliedFilters
  ) => {
    const fieldObj = fields.find(field => field.accessor === fieldName);
    if (!fieldObj) {
      console.warn(`Couldn't match field: ${fieldName}`);
      return false;
    }

    let operator = isNegation ? 'isNot' : 'is';

    if (
      expr.term.includes('*') ||
      expr.term.includes('?') ||
      expr.similarity != null
    ) {
      operator = isNegation ? 'isNotLike' : 'isLike';
    }

    const filterKey = `${fieldObj.accessor}|${expr.term}`;
    if (!appliedFilters.has(filterKey)) {
      appliedFilters.add(filterKey);

      const filter = {
        id: uuid(),
        customLabel: '',
        enabled: true,
        field: fieldObj,
        inclusion: true,
        operator,
        pinned: false,
        useCustomLabel: false,
        value: expr.term
      };

      if (isRequired) {
        filter.inclusion = true;
      }

      // console.log('Applying filter:', filter);
      applyFilter(filter);
      return true;
    }
    return false;
  };

  const handleHelpItemClick = pattern => {
    setQueryInput(pattern);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop='static'
      centered
      size='lg'
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
      contentClassName='bg-transparent border-0 shadow-none'>
      <Modal.Header closeButton closeVariant='white' className='border-0 p-0'>
        <p style={{ color: 'white' }}>
          Apply filters from a search query using Lucene syntax.
        </p>
      </Modal.Header>
      <Collapse in={open}>
        <div>
          <FormText style={{ color: 'white', marginBottom: '1rem' }}>
            <h6 className='mb-3' style={{ color: 'white' }}>
              <strong>Supported Lucene Operations</strong>
            </h6>
            <dl>
              <dt className='mb-2'>
                <strong>Basic Search: Exact terms and phrases</strong>
              </dt>
              <dd>
                <code
                  className='cursor-pointer'
                  onClick={() => handleHelpItemClick('term')}>
                  term
                </code>
                <span className='mx-2'>or</span>
                <code
                  className='cursor-pointer'
                  onClick={() => handleHelpItemClick('"search phrase"')}>
                  "search phrase"
                </code>
                <span className='ms-3'>
                  Search for an exact term or phrase in any field.
                </span>
              </dd>
              <dd>
                <code
                  className='cursor-pointer'
                  onClick={() => handleHelpItemClick('field:term')}>
                  field:term
                </code>
                <span className='mx-2'>or</span>
                <code
                  className='cursor-pointer'
                  onClick={() => handleHelpItemClick('field:"search phrase"')}>
                  field:"search phrase"
                </code>
                <span className='ms-3'>
                  Search for an exact term or phrase in a specific field.
                </span>
              </dd>
              <dd>
                <code
                  className='cursor-pointer'
                  onClick={() => handleHelpItemClick('NOT field:term')}>
                  NOT field:term
                </code>
                <span className='mx-2'>or</span>
                <code
                  className='cursor-pointer'
                  onClick={() => handleHelpItemClick('-field:term')}>
                  -field:term
                </code>
                <span className='ms-3'>
                  Exclude documents with field containing term.
                </span>
              </dd>
              <dd>
                <code
                  className='cursor-pointer'
                  onClick={() =>
                    handleHelpItemClick('NOT field:"search phrase"')
                  }>
                  NOT field:"search phrase"
                </code>
                <span className='mx-2'>or</span>
                <code
                  className='cursor-pointer'
                  onClick={() => handleHelpItemClick('-field:"search phrase"')}>
                  -field:"search phrase"
                </code>
                <span className='ms-3'>
                  Exclude documents with field containing phrase.
                </span>
              </dd>
              <dt className='mb-2 mt-4'>
                <strong>Boolean Operators: OR and logical combinations</strong>
              </dt>
              <dd>
                <code
                  className='cursor-pointer'
                  onClick={() =>
                    handleHelpItemClick('field:(term1 OR term2 OR term3)')
                  }>
                  field:(term1 OR term2 OR term3)
                </code>
                <span className='ms-3'>
                  Use OR to match any of the terms in a field.
                </span>
              </dd>
              <dd>
                <code
                  className='cursor-pointer'
                  onClick={() =>
                    handleHelpItemClick('field:(+term1 +term2 -term3)')
                  }>
                  field:(+term1 +term2 -term3)
                </code>
                <span className='ms-3'>
                  Include or exclude terms for a field using + and -.
                </span>
              </dd>
              <dt className='mb-2 mt-4'>
                <strong>Numeric Ranges: Inclusive range search</strong>
              </dt>
              <dd>
                <code
                  className='cursor-pointer'
                  onClick={() => handleHelpItemClick('field:[term1 TO term2]')}>
                  field:[term1 TO term2]
                </code>
                <span className='ms-3'>
                  Numeric range search inclusive between term1 and term2.
                </span>
              </dd>
              <dt className='mb-2 mt-4'>
                <strong>Fuzzy Search: Using wildcards in search terms</strong>
              </dt>
              <dd>
                <code
                  className='cursor-pointer'
                  onClick={() => handleHelpItemClick('field:term*')}>
                  field:term*
                </code>
                <span className='mx-2'>or</span>
                <code
                  className='cursor-pointer'
                  onClick={() => handleHelpItemClick('field:te?m')}>
                  field:te?m
                </code>
                <span className='ms-3'>
                  Use * as a wildcard for 0-n characters or ? for a single
                  character in a term.
                </span>
              </dd>
              <dd>
                <code
                  className='cursor-pointer'
                  onClick={() => handleHelpItemClick('field:"search phrase*"')}>
                  field:"search phrase*"
                </code>
                <span className='mx-2'>or</span>
                <code
                  className='cursor-pointer'
                  onClick={() => handleHelpItemClick('field:"search te?m"')}>
                  field:"search te?m"
                </code>
                <span className='ms-3'>
                  Use * as a wildcard for 0-n characters or ? for a single
                  character in a phrase.
                </span>
              </dd>
            </dl>
          </FormText>
        </div>
      </Collapse>
      <Form onSubmit={handleFormSubmit} className='w-100'>
        <Flex className='w-100' direction='column'>
          <Form.Group
            controlId='luceneQuery'
            className='w-100'
            style={{ marginBottom: '1.5rem' }}>
            <Form.Control
              type='text'
              placeholder='Search...'
              value={queryInput}
              onChange={handleInputChange}
              ref={inputRef}
              autoComplete='off'
              spellCheck='false'
              style={{
                backgroundColor: 'transparent',
                color: 'white',
                border: 'none',
                borderBottom: '2px solid white',
                borderRadius: '0',
                fontFamily: 'monospace',
                fontSize: '16px',
                padding: '0.5rem 0'
              }}
              className='lucene-input shadow-none'
            />
          </Form.Group>
          {errorMessage && (
            <div
              style={{ color: 'var(--falcon-danger)', marginBottom: '1.5rem' }}>
              {errorMessage}
            </div>
          )}
        </Flex>
        <Flex justifyContent='start'>
          <Button
            type='submit'
            variant='primary'
            style={{ marginBottom: '1.5rem', marginRight: '0.5rem' }}>
            Search
          </Button>
          <Button
            variant='link'
            onClick={() => setOpen(!open)}
            aria-expanded={open}
            style={{ marginBottom: '1.5rem', marginRight: '0.5rem' }}>
            {open ? 'Hide' : 'Show'} Lucene Syntax Help
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

LuceneSearchModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired
};

export default LuceneSearchModal;
