import { RoleAuthRoute } from 'components/authorization/RoleAuthRoute';
import PropTypes from 'prop-types';
import React from 'react';

const RolesWrapper = ({ allowedRoles }) => {
  return (
    <>
      {/* <RolesProvider> */}
      <RoleAuthRoute allowedRoles={allowedRoles} />
      {/* </RolesProvider> */}
    </>
  );
};

RolesWrapper.propTypes = {
  allowedRoles: PropTypes.array
};

export default RolesWrapper;
