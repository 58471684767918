import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import useAxiosPrivate from 'hooks/useAxiosPrivate';

const DeleteApplianceForm = ({
  showDeleteApplianceModal,
  setShowDeleteApplianceModal
}) => {
  const [loading, setLoading] = useState(false);
  const { appliance } = showDeleteApplianceModal;

  const { axiosPrivate } = useAxiosPrivate();

  return (
    <Form>
      <Row className='g-3 mb-3'>
        <Col sm={12}>
          <h5>Are you sure to delete {appliance?.name}?</h5>
        </Col>
      </Row>

      <Flex justifyContent='end'>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() =>
            setShowDeleteApplianceModal({
              open: false,
              appliance: {},
              fetch: false
            })
          }>
          Cancel
        </Button>

        <Button
          variant='danger'
          size='sm'
          onClick={async () => {
            setLoading(true);
            try {
              const res = await LeargasAPI.Appliances.deleteAppliance(
                showDeleteApplianceModal?.appliance?._id,
                true,
                axiosPrivate
              );
              if (res instanceof Error) throw res;
              let message = res?.data?.message;
              setLoading(false);
              setShowDeleteApplianceModal({
                open: false,
                appliance: {},
                fetch: true
              });
              toast.success(message);
            } catch (error) {
              if (error.message.includes('Network Error')) {
                console.error('Network Error occurred.');
              }
              setLoading(false);
              setShowDeleteApplianceModal({
                open: false,
                appliance: {},
                fetch: false
              });
              if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
              } else {
                toast.error('Something went wrong!');
              }
            }
          }}>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Deleting' : 'Delete'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

DeleteApplianceForm.propTypes = {
  showDeleteApplianceModal: PropTypes.object,
  setShowDeleteApplianceModal: PropTypes.func
};

export default DeleteApplianceForm;
