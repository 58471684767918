import LeargasAPI from 'api';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

export const jobsSettings = {
  data: [],
  loading: false,
  fetch: false,
  fetchInterval: 3000,
  jobDeleteModal: {
    open: false,
    data: {}
  }
};

/**
 * Create JobsContext
 * @type {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
export const JobsContext = createContext(jobsSettings);

/**
 * Create JobsProvider
 * @param props
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 *
 * @example
 * return (
 *  <JobsProvider>
 *   {children}
 * </JobsProvider>
 * )
 */
const JobsProvider = props => {
  const [jobs, setJobs] = useState(jobsSettings);
  const [loading, setLoading] = useState(true);

  const { axiosPrivate } = useAxiosPrivate();

  /**
   * Retrieves application jobs from the LeargasAPI
   * @returns {Promise<void>} A promise that resolves when the application jobs are retrieved
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.4
   * @since 0.1.0-beta.4
   */
  const getAppJobs = async () => {
    try {
      let res = await LeargasAPI.Jobs.getAppJobs(axiosPrivate);
      if (res instanceof Error) throw res;
      setJobs(prevState => ({
        ...prevState,
        data: res
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setJobs(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setJobs(prevState => ({
        ...prevState,
        fetch: false
      }));
      setLoading(false);
    }
  };

  /**
   * Get all application jobs on fetch state change
   */
  useEffect(() => {
    if (jobs.fetch) {
      getAppJobs();
    }
  }, [jobs.fetch]);

  /**
   * Get all application jobs on component mount
   */
  useEffect(() => {
    const interval = setInterval(() => {
      setJobs(prevState => ({
        ...prevState,
        fetch: true
      }));
    }, jobs.fetchInterval);
    return () => clearInterval(interval);
  }, []);

  return (
    <JobsContext.Provider
      value={{
        jobs,
        setJobs,
        loading
      }}>
      {props.children}
    </JobsContext.Provider>
  );
};

JobsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default JobsProvider;
