import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import OrgProfileBanner from 'components/common/profile-cards/OrganizationProfileCard/Profile';
import ProfileBanner from 'components/common/profile-cards/UserProfileCard/Profile';
import { getUserStatusSlug } from 'helpers/utils';
import useApplication from 'hooks/useApplication';
import { Button, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import Moment from 'react-moment';
const { default: CardDropdown } = require('components/common/CardDropdown');

/**
 * Columns for table
 * @param {Function} setUsers
 * @returns {Array}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

export const getUserColumns = setUsers => {
  return [
    {
      accessor: 'profile.fullName',
      Header: 'Name',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap'
      },
      Cell: rowData => {
        const {
          role: { name: roleName } = {},
          profile: { fullName = '', media: { avatar } = {} } = {},
          deleted: { isDeleted } = {},
          session: { lastActiveAt } = {}
        } = rowData.row.original;
        const status = getUserStatusSlug(lastActiveAt);
        return (
          <>
            <Flex
              direction='row'
              alignItems='center'
              className='cursor-pointer'>
              <OverlayTrigger
                placement='top'
                trigger={['click']}
                rootClose={true}
                delay={{ show: 0 }}
                popperConfig={{
                  modifiers: [
                    {
                      name: 'offset',
                      enabled: true,
                      options: {
                        offset: [120, 8]
                      }
                    }
                  ]
                }}
                overlay={
                  <Popover
                    style={{
                      minWidth: '320px',
                      margin: 0
                    }}
                    id={`popover-basic-${rowData?.row?.original?._id}`}>
                    <Popover.Body className='p-0'>
                      <ProfileBanner user={rowData?.row?.original} />
                    </Popover.Body>
                  </Popover>
                }>
                {({ ref, ...triggerHandler }) => (
                  <div
                    {...triggerHandler}
                    className='d-inline-flex align-items-center'>
                    <div
                      ref={ref}
                      className={`d-inline-flex align-items-center me-2`}
                      style={{ whiteSpace: 'nowrap' }}>
                      <Avatar
                        size='l'
                        src={
                          avatar ||
                          process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG
                        }
                        className={`${
                          status !== 'offline' && `status-${status}`
                        }`}
                      />
                    </div>
                    <span className='fs--1 text-700'>
                      {isDeleted && <code>{'(deleted)'}</code>} &nbsp;{fullName}
                      {['Admin', 'Super Admin'].includes(roleName) ? (
                        <FontAwesomeIcon
                          icon={['fas', 'clover']}
                          transform='rotate-45'
                          className='text-primary fs--1'
                        />
                      ) : null}
                    </span>
                  </div>
                )}
              </OverlayTrigger>
            </Flex>
          </>
        );
      }
    },
    {
      accessor: 'organizations',
      Header: 'Organizations',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle'
      },
      Cell: rowData => {
        const { application: { isPrivacyMode } = {} } = useApplication();
        let { organizations } = rowData.row.original;
        let displayedOrganizations = organizations?.slice(0, 3);
        let remainingOrganizations = organizations?.length - 3;
        return (
          <Flex direction='row' alignItems='center' className='cursor-pointer'>
            {displayedOrganizations?.map(org => {
              return (
                <OverlayTrigger
                  key={org.id}
                  placement='top'
                  trigger={['click']}
                  rootClose={true}
                  delay={{ show: 0 }}
                  popperConfig={{
                    modifiers: [
                      {
                        name: 'offset',
                        enabled: true,
                        options: {
                          offset: [120, 8]
                        }
                      }
                    ]
                  }}
                  overlay={
                    <Popover
                      style={{
                        minWidth: '320px',
                        margin: 0
                      }}
                      id={`popover-basic-${org.id}`}>
                      {!isPrivacyMode && <OrgProfileBanner org={org} />}
                    </Popover>
                  }>
                  {({ ref, ...triggerHandler }) => (
                    <div
                      {...triggerHandler}
                      className='d-inline-flex align-items-center cursor-pointer'>
                      <Button
                        ref={ref}
                        className={`avatar-popover d-inline-flex align-items-center p-0  bg-transparent border-0 shadow-none`}
                        style={{ whiteSpace: 'nowrap' }}>
                        {' '}
                        <Avatar
                          size='s'
                          style={{ marginRight: '2px' }}
                          src={process.env.REACT_APP_DEFAULT_ORG_AVATAR_IMG}
                        />
                      </Button>
                    </div>
                  )}
                  {/* <div>
                    <Flex direction='row' alignItems='center'>
                      {/* <Button
                        ref={ref}
                        className={`avatar-popover d-inline-flex align-items-center p-0 me-2 bg-transparent border-0 shadow-none`}
                        style={{ whiteSpace: 'nowrap' }}> */}
                  {/* </Button> */}
                  {/* </Flex> */}
                  {/* </div> */}
                </OverlayTrigger>
              );
            })}
            {remainingOrganizations > 0 && (
              <span className='ms-1'>+ {remainingOrganizations} more</span>
            )}
          </Flex>
        );
      }
    },
    {
      accessor: 'lastSeenAt',
      Header: 'Last Seen',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap cursor-default',
        style: { width: '12%' }
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle'
      },
      Cell: rowData => {
        let lastSeenAt;
        lastSeenAt = rowData.row.original.session?.lastActiveAt;

        return lastSeenAt ? (
          <OverlayTrigger
            placement='top'
            overlay={
              <Popover>
                <Popover.Body className='text-center p-1 fs--1 text-700'>
                  <Moment
                    format='ddd MMM D YYYY HH:mm:ss [GMT]ZZ'
                    className='fs--1 align-middle'>
                    {lastSeenAt}
                  </Moment>
                </Popover.Body>
              </Popover>
            }>
            <div className='d-inline'>
              <Moment fromNow className='fs--1 align-middle'>
                {lastSeenAt}
              </Moment>
            </div>
          </OverlayTrigger>
        ) : (
          <span>Never</span>
        );
      }
    },
    {
      accessor: 'activated',
      Header: 'Activated',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap text-center',
        style: { width: '8%' }
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle text-center'
      },
      Cell: rowData => {
        let activated;
        activated = rowData.row.original.activated?.isActivated;

        return activated === true ? (
          <FontAwesomeIcon
            icon={['fas', 'check-circle']}
            className='text-success fs--1'
          />
        ) : (
          <FontAwesomeIcon
            icon={['fas', 'times-circle']}
            className='text-warning fs--1'
          />
        );
      }
    },
    {
      accessor: 'mfaEnabled',
      Header: 'MFA',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap text-center',
        style: { width: '8%' }
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle text-center'
      },
      Cell: rowData => {
        let mfaEnabled;
        mfaEnabled = rowData.row.original.security.multifactor?.isEnabled;

        return mfaEnabled === true ? (
          <FontAwesomeIcon
            icon={['fas', 'check-circle']}
            className='text-success fs--1'
          />
        ) : (
          <FontAwesomeIcon
            icon={['fas', 'times-circle']}
            className='text-warning fs--1'
          />
        );
      }
    },
    {
      accessor: 'locked',
      Header: 'Status',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap text-center',
        style: { width: '8%' }
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle text-center'
      },
      Cell: rowData => {
        let id, locked, deleted;
        id = rowData.row.original?.id;
        locked = rowData.row.original?.locked?.isLocked;
        deleted = rowData.row.original?.deleted?.isDeleted;

        return (
          <>
            {deleted ? (
              <OverlayTrigger
                placement='top'
                delay={{ show: 500 }}
                overlay={
                  <Popover>
                    <Popover.Body className='text-center p-1 fs--1 text-700'>
                      Restore Account
                    </Popover.Body>
                  </Popover>
                }>
                <span>
                  <FontAwesomeIcon
                    icon='trash-restore-alt'
                    className='text-700 fs--1 cursor-pointer'
                    onClick={() =>
                      setUsers(prevState => ({
                        ...prevState,
                        showRestoreUserModal: {
                          open: true,
                          id,
                          defaultData: rowData.row.original
                        }
                      }))
                    }
                  />
                </span>
              </OverlayTrigger>
            ) : locked ? (
              <OverlayTrigger
                placement='top'
                delay={{ show: 500 }}
                overlay={
                  <Popover>
                    <Popover.Body className='text-center p-1 fs--1 text-700'>
                      Unlock Account
                    </Popover.Body>
                  </Popover>
                }>
                <span>
                  <FontAwesomeIcon
                    icon='lock'
                    className='text-700 fs--1 cursor-pointer'
                    onClick={() =>
                      setUsers(prevState => ({
                        ...prevState,
                        showUnlockUserModal: {
                          open: true,
                          id,
                          defaultData: rowData.row.original
                        }
                      }))
                    }
                  />
                </span>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement='top'
                delay={{ show: 500 }}
                overlay={
                  <Popover>
                    <Popover.Body className='text-center p-1 fs--1 text-700'>
                      Lock Account
                    </Popover.Body>
                  </Popover>
                }>
                <span>
                  <FontAwesomeIcon
                    icon='lock-open'
                    className='text-400 fs--1 cursor-pointer'
                    onClick={() =>
                      setUsers(prevState => ({
                        ...prevState,
                        showLockUserModal: {
                          open: true,
                          id,
                          defaultData: rowData.row.original
                        }
                      }))
                    }
                  />
                </span>
              </OverlayTrigger>
            )}
          </>
        );
      }
    },
    {
      accessor: 'leargas_actions',
      Header: '',
      Cell: rowData => {
        let locked, deleted, email, primaryEmail;
        locked = rowData.row.original?.locked.isLocked || false;
        deleted = rowData.row.original?.deleted.isDeleted || false;
        email = rowData.row.original?.profile?.email || [];
        primaryEmail = email?.find(address => address.primary === true) || {};

        return (
          <Flex justifyContent='around' alignContent='middle'>
            <CardDropdown>
              <div className='py-2'>
                <Dropdown.Item
                  onClick={() =>
                    setUsers(prevState => ({
                      ...prevState,
                      showUpdateUserModal: {
                        open: true,
                        defaultData: rowData?.row?.original
                      }
                    }))
                  }>
                  Edit
                </Dropdown.Item>
                <div className='dropdown-divider'></div>
                <Dropdown.Item
                  className='text-warning'
                  onClick={() => {
                    setUsers(prevState => ({
                      ...prevState,
                      resetPasswordModal: {
                        open: true,
                        address: primaryEmail?.address,
                        defaultData: rowData?.row?.original
                      }
                    }));
                  }}>
                  Reset Password
                </Dropdown.Item>
                <Dropdown.Item
                  className='text-warning'
                  onClick={() => {
                    locked
                      ? setUsers(prevState => ({
                          ...prevState,
                          showUnlockUserModal: {
                            open: true,
                            defaultData: rowData?.row?.original
                          }
                        }))
                      : setUsers(prevState => ({
                          ...prevState,
                          showLockUserModal: {
                            open: true,
                            defaultData: rowData?.row?.original
                          }
                        }));
                  }}>
                  {locked ? 'Unlock' : 'Lock'} Account
                </Dropdown.Item>
                <div className='dropdown-divider'></div>
                <Dropdown.Item
                  className='text-danger'
                  onClick={() => {
                    deleted
                      ? setUsers(prevState => ({
                          ...prevState,
                          showRestoreUserModal: {
                            open: true,
                            defaultData: rowData?.row?.original
                          }
                        }))
                      : setUsers(prevState => ({
                          ...prevState,
                          showDeleteUserModal: {
                            open: true,
                            defaultData: rowData?.row?.original
                          }
                        }));
                  }}>
                  {deleted ? 'Restore' : 'Delete'} Account
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </Flex>
        );
      },
      cellProps: {
        className: 'text-end text-400 actions-cell',
        style: {
          width: '42px'
        }
      },
      hideDisplaySettings: true,
      disableSortBy: true
    }
  ];
};
