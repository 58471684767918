/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, InputGroup } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';

/**
 *
 * @param {object} props
 * @param {Object} props.globalFilter
 * @param {Function} props.setGlobalFilter
 * @param {String} props.className
 * @param {String} props.placeholder
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticlesTableSearchBox = ({
  globalFilter,
  setGlobalFilter,
  className,
  placeholder = 'Search articles...'
}) => {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <InputGroup className={classNames('position-relative', className)}>
      <FormControl
        value={value || ''}
        onChange={({ target: { value } }) => {
          setValue(value);
          onChange(value);
        }}
        size='sm'
        id='search'
        placeholder={placeholder}
        type='search'
        className='shadow-none'
      />
      <InputGroup.Text className='bg-transparent'>
        <FontAwesomeIcon icon='search' className='fs--1 text-600' />
      </InputGroup.Text>
    </InputGroup>
  );
};

ArticlesTableSearchBox.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string
};

export default ArticlesTableSearchBox;
