import React, { useEffect, useState } from 'react';

import useAuthentication from 'hooks/useAuthentication';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useApplication from 'hooks/useApplication';
import moment from 'moment/moment';
import { Modal } from 'react-bootstrap';

/**
 * Represents the idle session timeout modal.
 * This modal will be displayed when the user is idle for a certain amount of time.
 * The user will have the option to continue the session or log out.
 *
 * @returns {JSX.Element} The rendered IdleSessionTimeoutModal component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const IdleSessionTimeoutModal = () => {
  const { authentication } = useAuthentication();
  const [timer, setTimer] = useState('');
  const {
    application: { isDark }
  } = useApplication();
  useEffect(() => {
    const interval = setInterval(() => {
      const idleTimeoutAt = authentication?.idleTimeoutAt;
      var time = moment
        .duration(moment(idleTimeoutAt, 'YYYY-MM-DDTHH:mm:ssZ').diff(moment()))
        .format('s', { trim: false });
      setTimer(time);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [authentication]);

  return (
    <>
      <Modal
        aria-labelledby='sessionTimeoutModal'
        size='md'
        backdrop='static'
        keyboard={false}
        show={authentication?.showIdleModal}
        centered
        className='border-0'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Still here?
            <AdvancedPopover
              containerId='resetConfirmModal'
              placement='top'
              popoverText='Still here?'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
        </Modal.Header>

        <Modal.Body className='p-2'>
          <p className='text-center py-3 mb-0'>
            Your session will be locked in{' '}
            <span className='fw-bold text-warning'>
              {Number(timer) > 60 ? '60' : Number(timer) < 1 ? '0' : timer}
            </span>{' '}
            seconds.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IdleSessionTimeoutModal;
