import { makeRequest } from 'api/connection/requestAction';
class Activities {
  constructor(route) {
    this.route = route;
  }
  /**
   * @description Get most recent activity for a user by ID
   * @param {Object} id The id of the user to get recent activity for
   * @returns {Array} An array of activity objects
   */
  async getRecentActivity(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getRecentActivity.method,
        `${this.route.getRecentActivity.path}/${id}`
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async AllActivity(axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.AllActivity.method,
        this.route.AllActivity.path
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async getActivities(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getActivities.method,
        `${this.route.getActivities.path}/${id}`
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getQueriedActivities(startDate, endDate, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getQueriedActivities.method,
        `${this.route.getQueriedActivities.path}?startDate=${startDate}&endDate=${endDate}`
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getQueriedActivityById(id, startDate, endDate, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getQueriedActivityById.method,
        `${this.route.getQueriedActivityById.path}/${id}?startDate=${startDate}&endDate=${endDate}`
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default Activities;
