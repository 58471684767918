import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreatableMultiselect from 'components/common/CreatableMultiselect';
import Flex from 'components/common/Flex';
import LazyLoadSelect from 'components/common/LazyLoadSelect';
import MultiSelect from 'components/common/MultiSelect';
import AdvancedPopover from 'components/common/Popover';
import { setInsideFiltersModal } from 'components/dashboards/Artifacts/Dynamic/dataFetchUtils/RefinedUtils';
import useApplication from 'hooks/useApplication';
import { useESGlobalFilters } from 'hooks/useGlobalFilters';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap';
import Tips from '../Tips';

/**
 *
 * @returns {JSX.Element} The rendered button component
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const NewFilterModal = ({ showNewFilterModal, setShowNewFilterModal }) => {
  const [fieldValue, setFieldValue] = useState('');
  const [operatorValue, setOperatorValue] = useState('is');
  const [termValue, setTermValue] = useState('');
  const [termValues, setTermValues] = useState([]);

  const fieldRef = useRef(null);
  const modalRef = useRef(null);

  const {
    application: { isDark },
    updateGlobalFilters
  } = useApplication();

  const {
    esGlobalFilters: { query, Config, fieldsToUseEverywhere },
    setESGlobalFilters
  } = useESGlobalFilters();

  /**
   * Sets the initial field value to the first field in the list
   */
  useEffect(() => {
    setFieldValue(fieldsToUseEverywhere && fieldsToUseEverywhere[0]?.name);

    setOperatorValue('is');
  }, [fieldsToUseEverywhere]);

  /**
   * Focuses on the field input when the modal is shown
   * and the fieldRef is available
   */
  useEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.focus();
    }
  }, [showNewFilterModal]);

  const addToQuery = () => {
    if (Object.keys(Config.fields).length === 0) {
      return;
    }

    let newQuery = { ...JSON.parse(JSON.stringify(query)) };

    newQuery.rules.push({
      field: fieldValue,
      operator: operatorValue,
      value:
        operatorValue === 'is' || operatorValue === 'is not'
          ? termValue
          : termValues
    });
    setESGlobalFilters(prevState => {
      return {
        ...prevState,
        query: newQuery,
        stagedQuery: newQuery
      };
    });
    setInsideFiltersModal(newQuery, setESGlobalFilters, updateGlobalFilters);
    setFieldValue(fieldsToUseEverywhere && fieldsToUseEverywhere[0]?.name);
    setOperatorValue('is');
    setTermValue('');
    setShowNewFilterModal(false);
  };

  /**
   * Creates a new tag select option.
   *
   * @param {string} param - The parameter value for the new tag
   * @returns {Promise<void>} - Resolves when the tag select option is created
   */
  const createTagSelect = async param => {
    let newTags = [...termValues];
    newTags.push({ label: param, value: param });
    setTermValues(newTags);
  };

  /**
   * Handles the change of tags.
   *
   * @param {Array} tagsIn - The input tags
   * @returns {void}
   */
  const handleTagsChange = tagsIn => {
    if (tagsIn?.length > 0) {
      setTermValues(tagsIn);
    } else {
      setTermValues([]);
    }
  };

  return (
    <Modal
      ref={modalRef}
      aria-labelledby='newFilterModal'
      centered
      keyboard={true}
      onHide={() => setShowNewFilterModal(false)}
      show={showNewFilterModal}
      size='lg'
      contentClassName='shadow-none'>
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex mb-1 fs-0 fw-normal position-relative'>
            Add a filter{' '}
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Add a new filter to the current search query.'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
        </Modal.Header>

        <Modal.Body className='p-2'>
          <Form
            onSubmit={e => {
              e.preventDefault();
              // setFieldValue('');
              // setOperatorValue('');
              // setTermValue('');
              // setShowNewFilterModal(false);
            }}>
            <Row className='g-2 mb-2'>
              <Col sm={9}>
                <FormGroup>
                  <Form.Label className='d-flex fs--1 fw-normal'>
                    Field{' '}
                    <AdvancedPopover
                      containerId='newFilterModal'
                      placement='top'
                      popoverText='Select the field to use for the filter.'
                      showArrow={true}>
                      <FontAwesomeIcon
                        icon={['far', 'question-circle']}
                        className='ms-1 text-400 fs--1 cursor-pointer'
                      />
                    </AdvancedPopover>
                  </Form.Label>
                  <LazyLoadSelect
                    ref={fieldRef}
                    options={
                      fieldsToUseEverywhere.map
                        ? fieldsToUseEverywhere.map(item => {
                            return { label: item.label, value: item.name };
                          })
                        : []
                    }
                    value={{ label: fieldValue, value: fieldValue }}
                    onChange={value => {
                      setFieldValue(value.value);
                    }}
                    size='sm'
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Form.Label className='d-flex fs--1 fw-normal'>
                    Operator{' '}
                    <AdvancedPopover
                      containerId='newFilterModal'
                      placement='top'
                      popoverText='Choose an operator to specify how the filter should behave.'
                      showArrow={true}>
                      <FontAwesomeIcon
                        icon={['far', 'question-circle']}
                        className='ms-1 text-400 fs--1 cursor-pointer'
                      />
                    </AdvancedPopover>
                  </Form.Label>
                  <MultiSelect
                    options={[
                      { label: 'is', value: 'is' },
                      { label: 'is not', value: 'is not' },
                      { label: 'is one of', value: 'is one of' },
                      { label: 'is not one of', value: 'is not one of' },
                      { label: 'exists', value: 'exists' },
                      { label: 'does not exist', value: 'does not exist' }
                    ]}
                    value={{ label: operatorValue, value: operatorValue }}
                    onChange={value => {
                      if (value.value === 'is' || value.value === 'is not') {
                        setOperatorValue(value.value);
                        setTermValues([]);
                      } else if (
                        value.value === 'is one of' ||
                        value.value === 'is not one of'
                      ) {
                        setTermValue('');
                        setOperatorValue(value.value);
                      } else if (
                        value.value === 'exists' ||
                        value.value === 'does not exist'
                      ) {
                        setTermValue('');
                        setTermValues([]);
                        setOperatorValue(value.value);
                      }
                    }}
                    size='sm'
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className='mb-1'>
              <Col>
                {operatorValue !== 'exists' &&
                  operatorValue !== 'does not exist' && (
                    <>
                      {' '}
                      <FormGroup>
                        <Form.Label className='d-flex fs--1 fw-normal'>
                          Search Term{' '}
                          <AdvancedPopover
                            containerId='newFilterModal'
                            placement='top'
                            popoverText='Define the search term for the new filter using the input field below.'
                            showArrow={true}>
                            <FontAwesomeIcon
                              icon={['far', 'question-circle']}
                              className='ms-1 text-400 fs--1 cursor-pointer'
                            />
                          </AdvancedPopover>
                        </Form.Label>
                        {operatorValue !== 'is one of' &&
                        operatorValue !== 'is not one of' ? (
                          <Form.Control
                            value={termValue}
                            placeholder='Start typing...'
                            onChange={e => {
                              setTermValue(e.target.value);
                            }}
                            name='term'
                          />
                        ) : (
                          <CreatableMultiselect
                            className='fs--1'
                            isMulti
                            isClearable
                            isSearchable
                            placeholder='Please select'
                            options={termValues}
                            onCreateOption={createTagSelect}
                            value={termValues}
                            onChange={handleTagsChange}
                            style={{ minHeight: '36px' }}
                          />
                        )}
                      </FormGroup>
                    </>
                  )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer className='p-2'>
          <Row className='g-1 m-0 p-0 w-100'>
            <Col xs={9} className='p-0 m-0'>
              <div className='text-400'>
                <Tips />
              </div>
            </Col>
            <Col xs={3} className='p-0'>
              <Flex direction='row' justifyContent='end'>
                <Button
                  size='sm'
                  variant='secondary'
                  className='me-2'
                  onClick={() => {
                    setFieldValue('');
                    setOperatorValue('');
                    setTermValue('');
                    setShowNewFilterModal(false);
                  }}>
                  Cancel
                </Button>
                <Button
                  size='sm'
                  variant='success'
                  disabled={fieldValue === '' || operatorValue === ''}
                  onClick={addToQuery}
                  type='submit'>
                  Save
                </Button>
              </Flex>
            </Col>
          </Row>
        </Modal.Footer>
      </Flex>
    </Modal>
  );
};

NewFilterModal.propTypes = {
  showNewFilterModal: PropTypes.bool,
  setShowNewFilterModal: PropTypes.func
};

export default NewFilterModal;
