import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

const signatures = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      type: 'widget',
      props: {
        className: 'mb-1',
        title: 'Network Signatures Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-suricata*'],
        must: [
          {
            exists: {
              field: 'alert.signature'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Alert Signatures',
          helpInfo:
            'Alerts for network activity that match well-known patterns or show characteristics of a particular threat or attacker'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-suricata*'],
        must: [
          {
            query_string: {
              query:
                '(alert.signature: Scan OR alert.signature: Tor OR alert.signature: Executable OR alert.signature: EXE OR alert.signature: DLL) OR (alert.category: Attack OR alert.category: Misc)',
              analyze_wildcard: true,
              time_zone: 'America/New_York'
            }
          }
        ],
        must_not: [
          {
            match_phrase: {
              'alert.signature': 'ET CHAT Skype User-Agent detected'
            }
          },
          {
            match_phrase: {
              'alert.signature':
                'ET USER_AGENTS PyCurl Suspicious User Agent Outbound'
            }
          },
          {
            match_phrase: {
              'alert.signature': 'ET POLICY Google Desktop User-Agent Detected'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Suspect',
          helpInfo: 'Events that are suspicious and may indicate a threat'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-suricata*'],
        must: [
          {
            match_phrase: {
              'alert.signature.keyword':
                'ET POLICY Outgoing Basic Auth Base64 HTTP Password detected unencrypted'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'HTTP Basic Auth',
          helpInfo:
            'Unencrypted HTTP Basic Auth passwords detected in outbound traffic'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['histogram'],
        overriddenIndex: ['leargas-suricata*']
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Event Timeline',
          helpInfo: 'Timeline of all events matching the search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-suricata*'],
        queryType: ['map'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'destination_geoip.country_name.keyword',
                order: {
                  _count: 'desc'
                },
                exclude: [''],
                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Map',
        props: {
          className: '',
          title: 'Event Source Countries',
          field: 'destination_geoip.country_name',
          helpInfo:
            'Source locations of all events processed by our network signature detection engine'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, xxl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-suricata*'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'alert.signature.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Alert Signatures',
          field: 'alert.signature',
          helpInfo:
            'Alerts for network activity that match well-known patterns or show characteristics of a particular threat or attacker'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12, xxl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-suricata*'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'http.http_user_agent.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'User Agents',
          field: 'http.http_user_agent',
          helpInfo: 'User agents observed in network traffic'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-suricata*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'alert.signature.keyword',
                size: 5
              },
              aggs: {
                3: {
                  terms: {
                    field: 'src_ip.keyword',
                    size: 5
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'dest_ip.keyword',
                        size: 5
                      },
                      aggs: {
                        5: {
                          terms: {
                            field: 'alert.severity',
                            size: 5
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Alert Signatures by Source & Destination'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-suricata*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'src_ip.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Alert Sources',
            overlayText:
              'Sources of all alerts produced by our network signature detection engine',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-suricata*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'dest_ip.keyword',
                order: {
                  '5-orderAgg': 'desc'
                },

                size: 50
              },
              aggs: {
                '5-orderAgg': {
                  cardinality: {
                    field: 'dest_ip.keyword'
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Alert Destinations',
            overlayText:
              'Targets of all alerts produced by our network signature detection engine',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-suricata*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'community_id.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Alert Community IDs',
            overlayText:
              'Unique identifiers for particular network conversations or flows',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-suricata*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'src_ip.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'alert.signature.keyword',
                    order: {
                      _count: 'desc'
                    },

                    size: 50
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Alert Signatures by Source',
            overlayText:
              'Alert Signatures by Source for all alerts generated by our network signature detection engine.',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-suricata*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'dest_ip.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'alert.signature.keyword',
                    order: {
                      _count: 'desc'
                    },

                    size: 50
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Alert Signatures by Destination',
            overlayText:
              'Alert Signatures by Destination for all alerts generated by our network signature detection engine.',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    }
  ];
};

export default signatures;
