import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import IssueReportForm from './form';
/**
 * Renders the System component
 *
 * @returns {JSX.Element} The rendered System component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const System = () => {
  return (
    <>
      <WidgetSectionTitle
        title='Report An Issue'
        transform='down-3'
        titleClassName='mb-2'
      />
      <IssueReportForm />
    </>
  );
};

export default System;
