// import { axiosPrivate } from 'api/connection/axios';
import { apiRoutes } from 'config/routeSettings';

class Services {
  constructor(route) {
    this.route = route;
  }
  getAll(axiosPrivate) {
    return axiosPrivate.get(apiRoutes.SERVICES);
  }

  get(id, axiosPrivate) {
    return axiosPrivate.get(`${apiRoutes.SERVICES}/${id}`);
  }

  create(data, axiosPrivate) {
    return axiosPrivate.post(apiRoutes.SERVICES, data);
  }

  update(id, data, axiosPrivate) {
    return axiosPrivate.put(`${apiRoutes.SERVICES}/${id}`, data);
  }

  delete(id, axiosPrivate) {
    return axiosPrivate.delete(`${apiRoutes.SERVICES}/${id}`);
  }

  deleteAll(axiosPrivate) {
    return axiosPrivate.delete(apiRoutes.SERVICES);
  }
}

export default Services;
