import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import ProfileBanner from 'components/common/profile-cards/OrganizationProfileCard/Profile';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
const { default: CardDropdown } = require('components/common/CardDropdown');

/**
 *  It return columns array to listing tablbe
 * @param {Function} setAppliances
 * @returns {Array}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

export const getApplianceColumns = setAppliances => {
  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      noTruncate: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap'
      }
    },
    {
      accessor: 'organization.name',
      Header: 'Organization',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle'
      },
      Cell: rowData => {
        const {
          application: { isPrivacyMode }
        } = useApplication();
        const {
          organization: {
            name: orgName,
            id: orgId,
            profile: { media: { avatar } = {} } = {}
          } = {}
        } = rowData.row.original;
        return isPrivacyMode ? (
          <span className='text-500'>***</span>
        ) : rowData?.row?.original ? (
          //<Link to={[routes.ORGANIZATIONS, id].join('/')} className='text-decoration-none'>
          <Flex direction='row' alignItems='center' className='cursor-pointer'>
            <OverlayTrigger
              placement='top'
              trigger={['click']}
              rootClose={true}
              delay={{ show: 0 }}
              popperConfig={{
                modifiers: [
                  {
                    name: 'offset',
                    enabled: true,
                    options: {
                      offset: [120, 8]
                    }
                  }
                ]
              }}
              overlay={
                <Popover
                  style={{
                    minWidth: '320px',
                    margin: 0
                  }}
                  id='popover-basic'>
                  <Popover.Body className='p-0'>
                    <ProfileBanner org={rowData?.row?.original?.organization} />
                  </Popover.Body>
                </Popover>
              }>
              {({ ref, ...triggerHandler }) => (
                <div
                  {...triggerHandler}
                  className='d-inline-flex align-items-center'>
                  <div
                    ref={ref}
                    className={`d-inline-flex align-items-center me-1`}
                    style={{ whiteSpace: 'nowrap' }}>
                    <Avatar
                      size='l'
                      src={
                        avatar || process.env.REACT_APP_DEFAULT_ORG_AVATAR_IMG
                      }
                    />
                  </div>
                  <span className='fs--1 text-700'>
                    {orgName}{' '}
                    {orgId === '648371d0a87d7ad0a0367cfa' ? (
                      <FontAwesomeIcon
                        icon={['fas', 'clover']}
                        transform='rotate-45'
                        className='text-primary fs--1'
                      />
                    ) : null}
                  </span>
                </div>
              )}
            </OverlayTrigger>
          </Flex>
        ) : (
          //</Link>
          <span>N/A</span>
        );
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      noTruncate: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle'
      }
    },
    {
      accessor: 'deleted',
      sortType: 'basic',
      Header: 'Status',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 align-middle text-nowrap text-center',
        style: { width: '8%' }
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle text-center'
      },
      Cell: rowData => {
        let deleted;
        deleted = rowData.row.original?.deleted;
        return (
          <>
            {deleted ? (
              <OverlayTrigger
                placement='top'
                overlay={
                  <Popover>
                    <Popover.Body className='text-center p-1 fs--1 text-700'>
                      Restore Appliance
                    </Popover.Body>
                  </Popover>
                }>
                <span>
                  <FontAwesomeIcon
                    icon='trash-restore-alt'
                    className='text-700 fs--1 cursor-pointer'
                    onClick={() =>
                      setAppliances(prevState => ({
                        ...prevState,
                        restoreApplianceModal: {
                          open: true,
                          defaultData: rowData.row.original
                        }
                      }))
                    }
                  />
                </span>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement='top'
                overlay={
                  <Popover>
                    <Popover.Body className='text-center p-1 fs--1 text-700'>
                      Active
                    </Popover.Body>
                  </Popover>
                }>
                <span>
                  <FontAwesomeIcon
                    icon={['fas', 'check-circle']}
                    className='text-success fs--1'
                  />
                </span>
              </OverlayTrigger>
            )}
          </>
        );
      }
    },
    {
      accessor: 'leargas_actions',
      Header: '',
      Cell: rowData => {
        let deleted;
        deleted = rowData.row.original?.deleted;

        return (
          <Flex justifyContent='around' alignContent='middle'>
            <CardDropdown>
              <div className='py-2'>
                <Dropdown.Item
                  className='text-danger'
                  onClick={() => {
                    deleted
                      ? setAppliances(prevState => ({
                          ...prevState,
                          restoreApplianceModal: {
                            open: true,
                            defaultData: rowData?.row?.original
                          }
                        }))
                      : setAppliances(prevState => ({
                          ...prevState,
                          deleteApplianceModal: {
                            open: true,
                            defaultData: rowData?.row?.original
                          }
                        }));
                  }}>
                  {deleted ? 'Restore' : 'Delete'} appliance
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </Flex>
        );
      },
      cellProps: {
        className: 'text-end text-400 actions-cell',
        style: {
          width: '42px'
        }
      },
      hideDisplaySettings: true,
      disableSortBy: true
    }
  ];

  return columns;
};
