import { axiosPublic } from 'api/connection/axios';

/**
 * @description Account Register API
 * @summary Provides methods for registering a user's account
 * @method register Register a user's account
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
class AccountRegister {
  constructor(route) {
    this.path = route.path;
  }

  /**
   * @description Register a user's account
   * @param {object} formData - The form data to submit
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * AccountRegister.userRegister({
   *   first: '',
   *   last: '',
   *   address: '',
   *   password: '',
   *   confirmPassword: '',
   *   acceptedTerms: true
   * });
   */
  async userRegister(request) {
    try {
      return await axiosPublic.instance.post(
        this.path,
        JSON.stringify(request.body)
      );
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default AccountRegister;
