import React from 'react';
import PropTypes from 'prop-types';
import UsersProvider from '../../../../../../context/admin-contexts/UsersProvider';
import UsersLayout from './UsersLayout';
import UpdateUserModal from './components/modals/UsersEditModal';
import DeleteUserModal from './components/modals/UsersDeleteModal';
import RestoreUserModal from './components/modals/UsersRestoreModal';
import CreateUserModal from './components/modals/UsersAddModal';
import LockUserModal from './components/modals/UsersLockModal';
import UnlockUserModal from './components/modals/UsersUnlockModal';
import ResetPasswordModal from './components/modals/UsersResetPasswordModal';
import ViewRecentActivityModal from './components/modals/ViewRecentActivityModal';
import RolesProvider from 'context/admin-contexts/RolesProvider';
import OrganizationsProvider from 'context/admin-contexts/OrganizationsProvider';

/**
 * @description since we need roles in create user and edit user form so we use the respective provider for roles.
 * @param {Object} props
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const UsersWrapper = props => {
  return (
    <OrganizationsProvider>
      <UsersProvider>
        <RolesProvider>
          {props.children}
          <UsersLayout />
          <UpdateUserModal />
          <DeleteUserModal />
          <RestoreUserModal />
          <CreateUserModal />
          <LockUserModal />
          <UnlockUserModal />
          <ResetPasswordModal />
          <ViewRecentActivityModal />
        </RolesProvider>
      </UsersProvider>
    </OrganizationsProvider>
  );
};

UsersWrapper.propTypes = { children: PropTypes.node };

export default UsersWrapper;
