import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import brokenImage from 'assets/img/illustrations/status-broken.png';
import successImage from 'assets/img/illustrations/status-success.png';
import { routes } from 'config/routeSettings';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ResetPasswordForm from './ResetPasswordForm';

/**
 * Reset password page
 *
 * The reset password page is used to reset a user's password using a password reset token. The user is normally
 * directed to this page from an email link. The link will have a token in the query params and the page will
 * attempt to verify the token. If the token is valid, the user will be presented with a form to reset their
 * password. If the token is invalid, the user will be presented with an error message. If the user successfully
 * resets their password, they will be redirected to the login page.
 * @param {Object} props The component props
 * @param {string} props.titleTag The title tag to use for the page title
 * @returns {React.Component} The MFA app setup page
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const ResetPassword = ({ titleTag: TitleTag }) => {
  let navigate = useNavigate();

  let [searchParams] = useSearchParams();

  let [loading, setLoading] = useState(true);
  let [valid, setValid] = useState(false);
  let [reset, setReset] = useState(false);

  let [counter, setCounter] = useState(null);

  const verifyToken = async () => {
    try {
      const token = searchParams.get('token');

      const res = await LeargasAPI.AccountSecurity.userPasswordResetVerify({
        body: { passwordResetToken: token }
      });
      if (res instanceof Error) throw res;
      setTimeout(function () {
        setLoading(false);
        setValid(true);
      }, 1000);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      let message = error?.response?.data?.message;
      if (message) toast.error(message);
      setTimeout(function () {
        setLoading(false);
      }, 1000);
      setLoading(false);
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  useEffect(() => {
    if (counter === 0)
      navigate(routes.LOGIN, {
        replace: true
      });
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  return (
    <>
      {loading ? (
        <div className='text-center pt-1'>
          <TitleTag>
            Verifying password reset token...{' '}
            <Spinner animation='border' size='sm' variant='primary' />
          </TitleTag>
        </div>
      ) : valid ? (
        !reset ? (
          <div>
            <ResetPasswordForm
              searchParams={searchParams}
              setReset={setReset}
              setCounter={setCounter}
              hasLabel
            />
          </div>
        ) : (
          <div className='text-center'>
            <img
              className='d-block mx-auto'
              src={successImage}
              alt='Password reset successful!'
              width={150}
            />
            <TitleTag>Password reset successful!</TitleTag>
            <p>
              You will be redirected to the log in page in{' '}
              <strong>{counter}</strong> seconds.
            </p>
            <Button
              as={Link}
              color='primary'
              className='mt-3'
              to={routes.LOGIN}>
              <FontAwesomeIcon
                icon='chevron-left'
                transform='shrink-4 down-1'
                className='me-1'
              />
              Return to login
            </Button>
          </div>
        )
      ) : (
        <div className='text-center'>
          <img
            className='d-block mx-auto'
            src={brokenImage}
            alt='Password reset token could not be verified.'
            width={150}
          />
          <TitleTag>Password reset token could not be verified.</TitleTag>
          <p>
            It looks like something went wrong while trying to verify your
            password reset token. If you feel this is in error, please{' '}
            <a href='#'>contact us</a>.
          </p>
          <Button as={Link} color='primary' className='mt-3' to={routes.LOGIN}>
            <FontAwesomeIcon
              icon='chevron-left'
              transform='shrink-4 down-1'
              className='me-1'
            />
            Return to login
          </Button>
        </div>
      )}
    </>
  );
};

ResetPassword.propTypes = {
  titleTag: PropTypes.string
};

ResetPassword.defaultProps = { titleTag: 'h4' };

export default ResetPassword;
