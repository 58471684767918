import { useContext } from 'react';
import { UserOrganizationsContext } from 'context/user-contexts/UserOrganizationsProvider';

/**
 * Custom hook to use the UserOrganizationsContext
 * @returns {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const useUserOrganizations = () => {
  return useContext(UserOrganizationsContext);
};

export default useUserOrganizations;
