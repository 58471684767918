import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import routes from 'config/routeSettings';
import ProfileContainer from './ProfileContainer';
import useApplication from 'hooks/useApplication';
import useAuthentication from 'hooks/useAuthentication';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAbbreviatedName } from 'helpers/utils';

const UserProfilePopover = ({ user }) => {
  const {
    application: { isPrivacyMode }
  } = useApplication();
  const { authentication } = useAuthentication();

  const {
    id,
    profile: { name: { first, last } = {}, fullName },
    media: { avatar, banner } = {},
    roles = [],
    emails,
    lastActiveAt
  } = user;

  return user ? (
    <ProfileContainer>
      <ProfileContainer.Header
        avatar={avatar}
        banner={banner}
        lastSeenAt={lastActiveAt}
      />
      <ProfileContainer.Body>
        <Row>
          <Col sm={12}>
            <h4 className='fs-0 mb-1'>
              {isPrivacyMode ? getAbbreviatedName(first, last) : fullName}
              {roles.some(
                role => role.slug === 'admin' || role.slug === 'super-admin'
              ) ? (
                <FontAwesomeIcon
                  icon={['fas', 'clover']}
                  transform='rotate-45'
                  className='text-primary ms-1'
                />
              ) : null}
            </h4>
            <p className='mb-0 fs--1'>
              {isPrivacyMode
                ? `∗∗∗∗∗∗∗∗`
                : emails?.find(email => email?.isPrimary === true).address}
            </p>
            {authentication?.roles?.some(
              role => role.slug === 'super-admin'
            ) && (
              <Flex
                direction='row'
                alignItems='center'
                justifyContent='start'
                className='text-decoration-none mt-1'>
                <Flex
                  direction='row'
                  alignItems='center'
                  justifyContent='start'
                  className='text-decoration-none mt-2 pt-1'>
                  <Button
                    variant='outline-primary'
                    size='sm'
                    className='text-decoration-none me-2 py-0 px-2'
                    as={Link}
                    to={`${routes.USERS}/${id}`}>
                    View Profile
                  </Button>
                </Flex>
              </Flex>
            )}
          </Col>
        </Row>
      </ProfileContainer.Body>
    </ProfileContainer>
  ) : null;
};

UserProfilePopover.propTypes = {
  user: PropTypes.object.isRequired,
  mainProfile: PropTypes.bool
};

export default UserProfilePopover;
