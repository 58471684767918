import URI from 'urijs';

/**
 * Assembles the base URI for the API.
 *
 * @type {URI}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.4
 * @version 0.1.0-beta.4
 */
const baseUri = new URI({
  protocol: process.env.REACT_APP_LEARGAS_SCHEME,
  hostname: process.env.REACT_APP_LEARGAS_HOST,
  port: process.env.REACT_APP_LEARGAS_PORT
});

export default baseUri;
