import { axiosPublic } from 'api/connection/axios';

/**
 * @description Account Activate API
 * @summary Provides methods for activating a user's account
 * @method activate Activate a user's account
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
class AccountActivate {
  constructor(route) {
    this.path = route.path;
  }

  /**
   * @description Activate a user's account
   * @param {object} formData - The form data to submit
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * AccountActivate.userActivate({
   *   verificationToken: '',
   * });
   */
  async userActivate(request) {
    try {
      return await axiosPublic.instance.post(
        this.path,
        JSON.stringify(request.body)
      );
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default AccountActivate;
