import audioAlertClusterStatusRed from 'assets/audio/alerts/clusterStatusRed.mp3';
import audioAlertClusterStatusYellow from 'assets/audio/alerts/clusterStatusYellow.mp3';
import { apiRoutes } from 'config/routeSettings';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';
import { useSound } from 'use-sound';

const clusterInitialState = {
  nodeList: [],
  selectedNodeIds: [],
  heroChartInterval: '1m',
  translogOperations: {
    lastUpdated: new Date(),
    xAxis: [],
    legend: [],
    series: [],
    startTime: undefined,
    endTime: undefined
  },
  clusterHealth: {
    lastUpdated: new Date()
  },
  clusterStats: {
    indices: {
      docs: {
        count: 0
      }
    },
    nodes: {
      fs: {
        total_in_bytes: 0,
        available_in_bytes: 0
      }
    },
    lastUpdated: new Date()
  },
  clusterPendingTasks: {
    lastUpdated: new Date()
  },
  clusterState: {
    lastUpdated: new Date()
  },
  clusterIndices: {
    lastUpdated: new Date()
  },
  clusterNodesInfo: {
    lastUpdated: new Date()
  },
  clusterNodesStats: {
    lastUpdated: new Date()
  },
  clusterHealthStatus: 'default'
};

export const ClusterContext = createContext(clusterInitialState);

const Cluster = props => {
  const [cluster, setCluster] = useState(clusterInitialState);
  const [fetchingClusterHealth, setFetchingClusterHealth] = useState(false);
  const [fetchingClusterStats, setFetchingClusterStats] = useState(false);
  const [fetchingPendingTasks, setFetchingPendingTasks] = useState(false);
  const [fetchingClusterIndices, setFetchingClusterIndices] = useState(false);
  const [fetchingNodesStats, setFetchingNodesStats] = useState(false);
  const [fetchingNodesInfo, setFetchingNodesInfo] = useState(false);
  const [loadingTranslogOperations, setLoadingTranslogOperations] =
    useState(true);
  const [fetchTranslogOperations, setFetchTranslogOperations] = useState(true);

  const { axiosPrivate } = useAxiosPrivate();

  let [playAudioAlertClusterStatusYellow] = useSound(
    audioAlertClusterStatusYellow
  );

  let [playAudioAlertClusterStatusRed] = useSound(audioAlertClusterStatusRed);

  async function getClusterHealth() {
    if (!fetchingClusterHealth) {
      setFetchingClusterHealth(true);

      try {
        const res = await axiosPrivate.get(
          [apiRoutes.SYSTEM_CLUSTER_HEALTH, 'level=shards'].join('?')
        );
        if (res instanceof Error) throw res;
        setCluster(prevState => ({
          ...prevState,
          clusterHealth: {
            lastUpdated: new Date(),
            ...res?.data?.data
          },
          clusterHealthStatus: res?.data?.data?.status
        }));
      } catch (error) {
        if (error.message.includes('Network Error')) {
          console.error('Network Error occurred.');
        }
        setFetchingClusterHealth(false);
      }

      setFetchingClusterHealth(false);
    }
  }

  async function getClusterStats() {
    if (!fetchingClusterStats) {
      setFetchingClusterStats(true);

      try {
        const res = await axiosPrivate.get(apiRoutes.SYSTEM_CLUSTER_STATS);
        if (res instanceof Error) throw res;
        setCluster(prevState => ({
          ...prevState,
          clusterStats: {
            lastUpdated: new Date(),
            ...res?.data?.data
          }
        }));
      } catch (error) {
        if (error.message.includes('Network Error')) {
          console.error('Network Error occurred.');
        }
        setFetchingClusterStats(false);
      }

      setFetchingClusterStats(false);
    }
  }

  async function getClusterPendingTasks() {
    if (!fetchingPendingTasks) {
      setFetchingPendingTasks(true);

      try {
        const res = await axiosPrivate.get(
          apiRoutes.SYSTEM_CLUSTER_PENDING_TASKS
        );
        if (res instanceof Error) throw res;
        setCluster(prevState => ({
          ...prevState,
          clusterPendingTasks: {
            lastUpdated: new Date(),
            ...res?.data?.data
          }
        }));
      } catch (error) {
        if (error.message.includes('Network Error')) {
          console.error('Network Error occurred.');
        }
        setFetchingPendingTasks(false);
      }

      setFetchingPendingTasks(false);
    }
  }

  async function getIndices() {
    if (!fetchingClusterIndices) {
      setFetchingClusterIndices(true);

      try {
        const res = await axiosPrivate.get(
          apiRoutes.SYSTEM_CLUSTER_INDICES_STATS
        );
        if (res instanceof Error) throw res;
        setCluster(prevState => ({
          ...prevState,
          clusterIndices: {
            lastUpdated: new Date(),
            ...res?.data?.data
          }
        }));
      } catch (error) {
        if (error.message.includes('Network Error')) {
          console.error('Network Error occurred.');
        }
        setFetchingClusterIndices(false);
      }

      setFetchingClusterIndices(false);
    }
  }

  async function getNodesInfo() {
    if (!fetchingNodesInfo) {
      setFetchingNodesInfo(true);

      try {
        const res = await axiosPrivate.get(apiRoutes.SYSTEM_CLUSTER_NODES_INFO);
        if (res instanceof Error) throw res;
        setCluster(prevState => ({
          ...prevState,
          clusterNodesInfo: {
            lastUpdated: new Date(),
            ...res?.data?.data
          }
        }));
      } catch (error) {
        if (error.message.includes('Network Error')) {
          console.error('Network Error occurred.');
        }
        setFetchingNodesInfo(false);
      }

      setFetchingNodesInfo(false);
    }
  }

  async function getNodesStats() {
    if (!fetchingNodesStats) {
      setFetchingNodesStats(true);
      try {
        const res = await axiosPrivate.get(
          apiRoutes.SYSTEM_CLUSTER_NODES_STATS
        );
        if (res instanceof Error) throw res;
        setCluster(prevState => ({
          ...prevState,
          nodeList: Object.entries(res?.data?.data?.nodes)
            .map(([key, value]) => ({ id: key, name: value.name }))
            .sort((a, b) => a.name.localeCompare(b.name)),
          clusterNodesStats: {
            lastUpdated: new Date(),
            ...res?.data?.data
          }
        }));
      } catch (error) {
        if (error.message.includes('Network Error')) {
          console.error('Network Error occurred.');
        }
        setFetchingNodesStats(false);
      }

      setFetchingNodesStats(false);
    }
  }

  async function getTranslogOperations(selectedNodes = [], chartInterval) {
    if (!fetchTranslogOperations) return;
    try {
      const res = await axiosPrivate.get(
        apiRoutes.SYSTEM_CLUSTER_TRANSLOG_OPERATIONS +
          `?interval=${chartInterval}&nodeIds=${selectedNodes.join(',')}`
      );
      if (res instanceof Error) throw res;
      setCluster(prevState => ({
        ...prevState,
        translogOperations: {
          lastUpdated: new Date(),
          ...res?.data?.data
        }
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }
    }
    setLoadingTranslogOperations(false);
    setFetchTranslogOperations(false);
  }

  useEffect(() => {
    getTranslogOperations(cluster.selectedNodeIds, cluster.heroChartInterval);

    const translogOperationsInterval = setInterval(() => {
      getTranslogOperations(cluster.selectedNodeIds, cluster.heroChartInterval);
    }, 60000);

    return () => {
      clearInterval(translogOperationsInterval);
    };
  }, [
    fetchTranslogOperations,
    cluster.selectedNodeIds,
    cluster.heroChartInterval
  ]);

  useEffect(() => {
    if (cluster.clusterHealthStatus === 'green') {
      //playAudioAlertClusterStatusGreen();
    } else if (cluster.clusterHealthStatus === 'yellow') {
      playAudioAlertClusterStatusYellow();
    } else if (cluster.clusterHealthStatus === 'red') {
      playAudioAlertClusterStatusRed();
    }
  }, [cluster.clusterHealthStatus]);

  useEffect(() => {
    getTranslogOperations(cluster.selectedNodeIds, cluster.heroChartInterval);
    const translogOperationsInterval = setInterval(() => {
      getTranslogOperations(cluster.selectedNodeIds, cluster.heroChartInterval);
    }, 60000);

    // Cluster health
    getClusterHealth();
    const clusterHealthInterval = setInterval(() => {
      getClusterHealth();
    }, 60000);

    // Cluster stats
    getClusterStats();
    const clusterStatsInterval = setInterval(() => {
      getClusterStats();
    }, 3000);

    // Cluster pending tasks
    getClusterPendingTasks();
    const clusterPendingTasksInterval = setInterval(() => {
      getClusterPendingTasks();
    }, 15000);

    // Indices
    getIndices();
    const clusterIndicesInterval = setInterval(() => {
      getIndices();
    }, 60000);

    // Nodes info
    getNodesInfo();
    const clusterNodesInfoInterval = setInterval(() => {
      getNodesInfo();
    }, 60000);

    // Nodes stats
    getNodesStats();
    const clusterNodesStatsInterval = setInterval(() => {
      getNodesStats();
    }, 60000);

    return () => {
      clearInterval(clusterHealthInterval);
      clearInterval(clusterStatsInterval);
      clearInterval(clusterPendingTasksInterval);
      clearInterval(clusterIndicesInterval);
      clearInterval(clusterNodesInfoInterval);
      clearInterval(clusterNodesStatsInterval);
      clearInterval(translogOperationsInterval);
    };
  }, []);

  useEffect(() => {
    if (cluster.nodeList.length > 0) {
      setCluster(prevState => ({
        ...prevState,
        selectedNodeIds:
          prevState.selectedNodeIds.length > 0
            ? prevState.selectedNodeIds.filter(id =>
                cluster.nodeList.some(node => node.id === id)
              )
            : cluster.nodeList.map(node => node.id)
      }));
    }
  }, [cluster.nodeList]);

  return (
    <>
      <ClusterContext.Provider
        value={{
          cluster,
          setCluster,
          loadingTranslogOperations,
          setLoadingTranslogOperations,
          fetchTranslogOperations,
          setFetchTranslogOperations
        }}>
        {props.children}
      </ClusterContext.Provider>
    </>
  );
};

Cluster.propTypes = {
  children: PropTypes.node.isRequired
};

export default Cluster;
