const { v4: uuid } = require('uuid');

/**
 * Default settings for the add filter modal.
 *
 * @type {Object}
 * @property {boolean} enabled - Indicates if the filter is enabled
 * @property {Object} field - The field object
 * @property {boolean} inclusion - Indicates if the filter is inclusive
 * @property {string} operator - The operator for the filter
 * @property {boolean} pinned - Indicates if the filter is pinned
 * @property {string} customLabel - The custom label for the filter
 * @property {boolean} useCustomLabel - Indicates if the custom label should be used
 * @property {null} value - The value for the filter
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const modalFilterAddDefaults = {
  enabled: true,
  field: {},
  inclusion: true,
  operator: '',
  pinned: false,
  customLabel: '',
  useCustomLabel: false,
  value: null
};

/**
 * @description Defines the operatorsAvailable object which contains various operators for filtering records.
 *
 * @typedef {Object} Operator
 * @property {string[]} applicableFieldTypes - The applicable field types for the operator
 * @property {string} description - The description of the operator
 * @property {string} inversion - The inversion of the operator
 * @property {string} label - The label of the operator used for display
 * @property {boolean} requiresValue - Indicates if the operator requires a value
 * @property {string} symbol - The symbol of the operator used for display
 * @property {string} value - The value of the operator
 *
 * @namespace
 * @name filtersOperators
 * @type {Object.<string, Operator>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const filtersOperators = {
  is: {
    isCompatibleWithType: (fieldType, value) => {
      if (value && Array.isArray(value)) {
        return false;
      }
      return ['keyword', 'string', 'number', 'datetime'].includes(fieldType);
    },
    description: 'Filter for exact matches of the specified value.',
    showInMenus: true,
    canInvert: true,
    inversion: 'isNot',
    isNegation: false,
    label: 'is',
    requiresValue: true,
    symbol: '=',
    generateQuery: ({ fieldName, fieldValue, fieldType }) => {
      if (fieldValue === '') {
        return {
          bool: {
            should: [
              {
                match: {
                  [fieldType === 'keyword' || fieldType === 'string'
                    ? `${fieldName}.keyword`
                    : fieldName]: ''
                }
              },
              { bool: { must_not: { exists: { field: fieldName } } } }
            ],
            minimum_should_match: 1
          }
        };
      } else {
        return {
          match: {
            [fieldType === 'keyword' || fieldType === 'string'
              ? `${fieldName}.keyword`
              : fieldName]: fieldValue
          }
        };
      }
    }
  },
  isNot: {
    isCompatibleWithType: (fieldType, value) => {
      if (value && Array.isArray(value)) {
        return false;
      }
      return ['keyword', 'string', 'number', 'datetime'].includes(fieldType);
    },
    description: 'Filter for values not matching the specified value.',
    showInMenus: true,
    canInvert: true,
    inversion: 'is',
    isNegation: true,
    label: 'is not',
    requiresValue: true,
    symbol: '!=',
    generateQuery: ({ fieldName, fieldValue, fieldType }) => {
      if (fieldValue === '') {
        return {
          bool: {
            must_not: [
              {
                match: {
                  [fieldType === 'keyword' || fieldType === 'string'
                    ? `${fieldName}.keyword`
                    : fieldName]: ''
                }
              },
              { exists: { field: fieldName } }
            ]
          }
        };
      } else {
        return {
          bool: {
            must_not: {
              match: {
                [fieldType === 'keyword' || fieldType === 'string'
                  ? `${fieldName}.keyword`
                  : fieldName]: fieldValue
              }
            }
          }
        };
      }
    }
  },
  isLike: {
    isCompatibleWithType: (fieldType, value) => {
      if (value === '' || Array.isArray(value)) {
        return false;
      }
      return ['string', 'keyword'].includes(fieldType);
    },
    description: 'Filter for values matching the specified pattern.',
    showInMenus: true,
    canInvert: true,
    inversion: 'isNotLike',
    isNegation: false,
    label: 'is like',
    requiresValue: true,
    symbol: 'is like',
    generateQuery: ({ fieldName, pattern }) => ({
      wildcard: {
        [`${fieldName}.keyword`]: `*${pattern}*`
      }
    })
  },
  isNotLike: {
    isCompatibleWithType: (fieldType, value) => {
      if (value === '' || Array.isArray(value)) {
        return false;
      }
      return ['string', 'keyword'].includes(fieldType);
    },
    description: 'Filter for values not matching the specified pattern.',
    showInMenus: true,
    canInvert: true,
    inversion: 'isLike',
    isNegation: true,
    label: 'is not like',
    requiresValue: true,
    symbol: 'not like',
    generateQuery: ({ fieldName, pattern }) => ({
      bool: {
        must_not: {
          wildcard: {
            [`${fieldName}.keyword`]: `*${pattern}*`
          }
        }
      }
    })
  },
  isOneOf: {
    isCompatibleWithType: fieldType =>
      ['keyword', 'string', 'number'].includes(fieldType),
    description: 'Filter for values matching any of the specified values.',
    showInMenus: true,
    canInvert: true,
    inversion: 'isNotOneOf',
    isNegation: false,
    isMulti: true,
    label: 'is one of',
    requiresValue: true,
    symbol: 'is one of',
    generateQuery: ({ fieldName, fieldValue, fieldType }) => {
      if (!Array.isArray(fieldValue)) {
        fieldValue = [fieldValue];
      }

      const nonEmptyValues = fieldValue.filter(value => value !== '');
      const shouldClauses = nonEmptyValues.map(value => ({
        term: {
          [fieldType === 'keyword' || fieldType === 'string'
            ? `${fieldName}.keyword`
            : fieldName]: value
        }
      }));

      return {
        bool: {
          should: shouldClauses,
          minimum_should_match: 1
        }
      };
    }
  },
  isNotOneOf: {
    isCompatibleWithType: fieldType =>
      ['keyword', 'string', 'number'].includes(fieldType),
    description: 'Filter for values not matching any of the specified values.',
    showInMenus: true,
    canInvert: true,
    inversion: 'isOneOf',
    isNegation: true,
    isMulti: true,
    label: 'is not one of',
    requiresValue: true,
    symbol: 'is not one of',
    generateQuery: ({ fieldName, fieldValue, fieldType }) => {
      if (!Array.isArray(fieldValue)) {
        fieldValue = [fieldValue];
      }
      const nonEmptyValues = fieldValue.filter(value => value !== '');
      const mustNotClauses = nonEmptyValues.map(value => ({
        term: {
          [fieldType === 'keyword' || fieldType === 'string'
            ? `${fieldName}.keyword`
            : fieldName]: value
        }
      }));

      if (fieldValue.includes('')) {
        mustNotClauses.push({
          bool: {
            should: [
              { match: { [fieldName]: '' } },
              { exists: { field: fieldName } }
            ]
          }
        });
      }

      return {
        bool: {
          must_not: mustNotClauses
        }
      };
    }
  },
  isBetween: {
    isCompatibleWithType: (fieldType, value) => {
      if (
        (value && isNaN(value) && !Array.isArray(value)) ||
        (Array.isArray(value) && value.length !== 2) ||
        (Array.isArray(value) && value.some(v => isNaN(v)))
      ) {
        return false;
      }
      return ['number'].includes(fieldType);
    },
    description: 'Filter for values between the specified range.',
    showInMenus: false,
    canInvert: true,
    inversion: 'isNotBetween',
    isNegation: false,
    isMulti: true,
    maxValues: 2,
    label: 'is between',
    requiresValue: true,
    symbol: 'is between',
    generateQuery: ({ fieldName, fieldValue }) => ({
      bool: {
        must: {
          range: {
            [fieldName]: { gte: fieldValue[0], lte: fieldValue[1] }
          }
        }
      }
    })
  },
  isNotBetween: {
    isCompatibleWithType: (fieldType, value) => {
      if (
        (value && isNaN(value) && !Array.isArray(value)) ||
        (Array.isArray(value) && value.length !== 2) ||
        (Array.isArray(value) && value.some(v => isNaN(v)))
      ) {
        return false;
      }
      return ['number'].includes(fieldType);
    },
    description: 'Filter for values outside the specified range.',
    showInMenus: false,
    canInvert: true,
    inversion: 'isBetween',
    isNegation: true,
    isMulti: true,
    maxValues: 2,
    label: 'is not between',
    requiresValue: true,
    symbol: 'is not between',
    generateQuery: ({ fieldName, fieldValue }) => ({
      bool: {
        must_not: {
          range: {
            [fieldName]: { gte: fieldValue[0], lte: fieldValue[1] }
          }
        }
      }
    })
  },
  isGreaterThan: {
    isCompatibleWithType: (fieldType, value) => {
      if (value && isNaN(value)) {
        return false;
      }
      return ['number', 'datetime'].includes(fieldType);
    },
    description: 'Filter for values greater than the specified value.',
    showInMenus: true,
    canInvert: true,
    inversion: 'isLessThan',
    isNegation: false,
    label: 'is greater than',
    requiresValue: true,
    symbol: '>',
    generateQuery: ({ fieldName, fieldValue }) => ({
      bool: {
        must: {
          range: {
            [fieldName]: { gt: fieldValue }
          }
        }
      }
    })
  },
  isGreaterThanOrEqualTo: {
    isCompatibleWithType: (fieldType, value) => {
      if (value && isNaN(value)) {
        return false;
      }
      return ['number', 'datetime'].includes(fieldType);
    },
    description:
      'Filter for values greater than or equal to the specified value.',
    showInMenus: true,
    canInvert: true,
    inversion: 'isLessThanOrEqualTo',
    isNegation: false,
    label: 'is greater than or equal to',
    requiresValue: true,
    symbol: '>=',
    generateQuery: ({ fieldName, fieldValue }) => ({
      bool: {
        must: {
          range: {
            [fieldName]: { gte: fieldValue }
          }
        }
      }
    })
  },
  isLessThan: {
    isCompatibleWithType: (fieldType, value) => {
      if (value && isNaN(value)) {
        return false;
      }
      return ['number', 'datetime'].includes(fieldType);
    },
    description: 'Filter for values less than the specified value.',
    showInMenus: true,
    canInvert: true,
    inversion: 'isGreaterThan',
    isNegation: false,
    label: 'is less than',
    requiresValue: true,
    symbol: '<',
    generateQuery: ({ fieldName, fieldValue }) => ({
      bool: {
        must: {
          range: {
            [fieldName]: { lt: fieldValue }
          }
        }
      }
    })
  },
  isLessThanOrEqualTo: {
    isCompatibleWithType: (fieldType, value) => {
      if (value && isNaN(value)) {
        return false;
      }
      return ['number', 'datetime'].includes(fieldType);
    },
    description: 'Filter for values less than or equal to the specified value.',
    showInMenus: true,
    canInvert: true,
    inversion: 'isGreaterThanOrEqualTo',
    isNegation: false,
    label: 'is less than or equal to',
    requiresValue: true,
    symbol: '<=',
    generateQuery: ({ fieldName, fieldValue }) => ({
      bool: {
        must: {
          range: {
            [fieldName]: { lte: fieldValue }
          }
        }
      }
    })
  },
  isNull: {
    isCompatibleWithType: fieldType =>
      ['keyword', 'string', 'number', 'datetime'].includes(fieldType),
    description: 'Filter for values that are null.',
    showInMenus: true,
    canInvert: true,
    inversion: 'isNotNull',
    isNegation: false,
    label: 'is null',
    requiresValue: false,
    symbol: 'is null',
    generateQuery: ({ fieldName }) => ({
      bool: {
        must_not: {
          exists: { field: fieldName }
        }
      }
    })
  },
  isNotNull: {
    isCompatibleWithType: fieldType =>
      ['keyword', 'string', 'number', 'datetime'].includes(fieldType),
    description: 'Filter for values that are not null.',
    showInMenus: true,
    canInvert: true,
    inversion: 'isNull',
    isNegation: true,
    label: 'is not null',
    requiresValue: false,
    symbol: 'is not null',
    generateQuery: ({ fieldName }) => ({
      exists: { field: fieldName }
    })
  },
  isTrue: {
    isCompatibleWithType: fieldType => ['boolean'].includes(fieldType),
    description: 'Filter for boolean values that are true.',
    showInMenus: true,
    canInvert: true,
    inversion: 'isFalse',
    isNegation: false,
    label: 'is true',
    requiresValue: false,
    symbol: 'is true',
    generateQuery: ({ fieldName }) => ({
      term: { [fieldName]: true }
    })
  },
  isFalse: {
    isCompatibleWithType: fieldType => ['boolean'].includes(fieldType),
    description: 'Filter for boolean values that are false.',
    showInMenus: true,
    canInvert: true,
    inversion: 'isTrue',
    isNegation: true,
    label: 'is false',
    requiresValue: false,
    symbol: 'is false',
    generateQuery: ({ fieldName }) => ({
      term: { [fieldName]: false }
    })
  },
  exists: {
    isCompatibleWithType: fieldType =>
      [
        'keyword',
        'string',
        'number',
        'boolean',
        'datetime',
        'geo_point',
        'ip'
      ].includes(fieldType),
    description: 'Filter for records where the selected field exists.',
    showInMenus: true,
    canInvert: true,
    inversion: 'doesNotExist',
    isNegation: false,
    label: 'exists',
    requiresValue: false,
    symbol: 'exists',
    generateQuery: ({ fieldName }) => ({
      exists: { field: fieldName }
    })
  },
  doesNotExist: {
    isCompatibleWithType: fieldType =>
      [
        'keyword',
        'string',
        'number',
        'boolean',
        'datetime',
        'geo_point',
        'ip'
      ].includes(fieldType),
    description: 'Filter for records where the selected field does not exist.',
    showInMenus: true,
    canInvert: true,
    inversion: 'exists',
    isNegation: true,
    label: 'does not exist',
    requiresValue: false,
    symbol: 'does not exist',
    generateQuery: ({ fieldName }) => ({
      bool: {
        must_not: {
          exists: { field: fieldName }
        }
      }
    })
  },
  multiMatch: {
    isCompatibleWithType: () => {
      return true;
    },
    description:
      'Filter for values matching the specified pattern across multiple fields.',
    showInMenus: false,
    canInvert: false,
    inversion: null,
    isNegation: false,
    label: 'multi match',
    requiresValue: true,
    symbol: 'multi match',
    generateQuery: ({ fieldValue, availableFields }) => ({
      simple_query_string: {
        query: fieldValue,
        fields: availableFields || ['*'],
        analyze_wildcard: true,
        default_operator: 'AND',
        lenient: true
      }
    })
  }
};

export const filtersBulkActionsGroups = {
  all: {
    label: 'All',
    actions: [
      'enableAll',
      'disableAll',
      'includeAll',
      'excludeAll',
      'pinAll',
      'unpinAll',
      'invertEnabled',
      'invertInclusion',
      'invertPinned',
      'removeAll'
    ]
  },
  enableDisable: {
    label: 'Enable/Disable',
    actions: ['enableAll', 'disableAll']
  },
  includeExclude: {
    label: 'Include/Exclude',
    actions: ['includeAll', 'excludeAll']
  },
  pinUnpin: {
    label: 'Pin/Unpin',
    actions: ['pinAll', 'unpinAll']
  },
  invert: {
    label: 'Invert',
    actions: ['invertEnabled', 'invertInclusion', 'invertPinned']
  },
  remove: {
    label: 'Remove',
    actions: [
      'removeAllEnabled',
      'removeAllDisabled',
      'removeAllIncluded',
      'removeAllExcluded',
      'removeAllPinned',
      'removeAllUnpinned',
      'removeAll'
    ]
  }
};

/**
 * Defines the available bulk actions for manipulating search filters.
 *
 * @property {string} label - The label of the action
 * @property {string|string[]} icon - The icon of the action
 * @property {Function} Callback - The callback function that performs the action
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const filtersBulkActions = {
  enableAll: {
    label: 'Enable all',
    icon: ['fas', 'square-check'],
    canUse: filters => filters.some(filter => !filter.enabled),
    Callback: filters => {
      const updatedFilters = filters.map(filter => {
        return { ...filter, enabled: true };
      });
      return updatedFilters;
    }
  },
  disableAll: {
    label: 'Disable all',
    icon: ['far', 'square'],
    canUse: filters => filters.some(filter => filter.enabled),
    Callback: filters => {
      const updatedFilters = filters.map(filter => {
        return { ...filter, enabled: false };
      });
      return updatedFilters;
    }
  },
  includeAll: {
    label: 'Include all',
    icon: ['fas', 'circle'],
    canUse: filters => filters.some(filter => !filter.inclusion),
    Callback: filters => {
      const updatedFilters = filters.map(filter => {
        if (filtersOperators[filter.operator].canInvert !== false) {
          return { ...filter, inclusion: true };
        }
        return filter;
      });
      return updatedFilters;
    }
  },
  excludeAll: {
    label: 'Exclude all',
    icon: ['far', 'circle'],
    canUse: filters => filters.some(filter => filter.inclusion),
    Callback: filters => {
      const updatedFilters = filters.map(filter => {
        if (filtersOperators[filter.operator].canInvert !== false) {
          return { ...filter, inclusion: false };
        }
        return filter;
      });
      return updatedFilters;
    }
  },
  pinAll: {
    label: 'Pin all',
    icon: 'thumbtack',
    canUse: filters => filters.some(filter => !filter.pinned),
    Callback: filters => {
      const updatedFilters = filters.map(filter => {
        return { ...filter, pinned: true };
      });
      return updatedFilters;
    }
  },
  unpinAll: {
    label: 'Unpin all',
    icon: 'thumbtack-slash',
    canUse: filters => filters.some(filter => filter.pinned),
    Callback: filters => {
      const updatedFilters = filters.map(filter => {
        return { ...filter, pinned: false };
      });
      return updatedFilters;
    }
  },
  invertEnabled: {
    label: 'Invert enabled/disabled',
    canUse: filters =>
      filters.some(filter => !filter.enabled || filter.enabled),
    Callback: filters => {
      const updatedFilters = filters.map(filter => {
        return { ...filter, enabled: filter.enabled ? false : true };
      });
      return updatedFilters;
    }
  },
  invertInclusion: {
    label: 'Invert included/excluded',
    canUse: filters =>
      filters.some(filter => !filter.inclusion || filter.inclusion),
    Callback: filters => {
      const updatedFilters = filters.map(filter => {
        if (filtersOperators[filter.operator].canInvert !== false) {
          return { ...filter, inclusion: filter.inclusion ? false : true };
        } else {
          return filter;
        }
      });
      return updatedFilters;
    }
  },
  invertPinned: {
    label: 'Invert pinned/unpinned',
    canUse: filters => filters.some(filter => !filter.pinned || filter.pinned),
    Callback: filters => {
      const updatedFilters = filters.map(filter => {
        return { ...filter, pinned: filter.pinned ? false : true };
      });
      return updatedFilters;
    }
  },
  removeAllEnabled: {
    label: 'Remove enabled',
    canUse: filters => filters.some(filter => filter.enabled),
    Callback: filters => {
      const updatedFilters = filters.filter(filter => !filter.enabled);
      return updatedFilters;
    }
  },
  removeAllDisabled: {
    label: 'Remove disabled',
    canUse: filters => filters.some(filter => !filter.enabled),
    Callback: filters => {
      const updatedFilters = filters.filter(filter => filter.enabled);
      return updatedFilters;
    }
  },
  removeAllIncluded: {
    label: 'Remove included',
    canUse: filters => filters.some(filter => filter.inclusion),
    Callback: filters => {
      const updatedFilters = filters.filter(filter => !filter.inclusion);
      return updatedFilters;
    }
  },
  removeAllExcluded: {
    label: 'Remove excluded',
    canUse: filters => filters.some(filter => !filter.inclusion),
    Callback: filters => {
      const updatedFilters = filters.filter(filter => filter.inclusion);
      return updatedFilters;
    }
  },
  removeAllPinned: {
    label: 'Remove pinned',
    canUse: filters => filters.some(filter => filter.pinned),
    Callback: filters => {
      const updatedFilters = filters.filter(filter => !filter.pinned);
      return updatedFilters;
    }
  },
  removeAllUnpinned: {
    label: 'Remove unpinned',
    canUse: filters => filters.some(filter => !filter.pinned),
    Callback: filters => {
      const updatedFilters = filters.filter(filter => filter.pinned);
      return updatedFilters;
    }
  },
  removeAll: {
    label: 'Remove all',
    canUse: filters => filters.length > 0,
    Callback: () => {
      return [];
    }
  }
};

/**
 * Defines an object containing various filter actions.
 *
 * @property {string} label - The label of the action
 * @property {string|string[]} icon - The icon of the action
 * @property {Function} Callback - The callback function that performs the action
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const filtersActions = {
  enable: {
    label: 'Enable',
    icon: ['fas', 'square-check'],
    inversion: 'disable',
    isHidden: filter => filter.enabled === true,
    Callback: (filters, filter) => {
      const updatedFilters = filters.map(f => {
        if (f.id === filter.id) {
          return { ...f, enabled: true };
        }
        return f;
      });
      return updatedFilters;
    }
  },
  disable: {
    label: 'Disable',
    icon: ['far', 'square'],
    inversion: 'enable',
    isHidden: filter => filter.enabled === false,
    Callback: (filters, filter) => {
      const updatedFilters = filters.map(f => {
        if (f.id === filter.id) {
          return { ...f, enabled: false };
        }
        return f;
      });
      return updatedFilters;
    }
  },
  include: {
    label: 'Include',
    icon: ['fas', 'circle'],
    inversion: 'exclude',
    isHidden: filter =>
      filter.inclusion === true ||
      filtersOperators[filter.operator].canInvert === false,
    Callback: (filters, filter) => {
      const updatedFilters = filters.map(f => {
        if (f.id === filter.id) {
          return { ...f, inclusion: true };
        }
        return f;
      });
      return updatedFilters;
    }
  },
  exclude: {
    label: 'Exclude',
    icon: ['far', 'circle'],
    inversion: 'include',
    isHidden: filter =>
      filter.inclusion === false ||
      filtersOperators[filter.operator].canInvert === false,
    Callback: (filters, filter) => {
      const updatedFilters = filters.map(f => {
        if (f.id === filter.id) {
          return { ...f, inclusion: false };
        }
        return f;
      });
      return updatedFilters;
    }
  },
  pin: {
    label: 'Pin',
    icon: 'thumbtack',
    inversion: 'unpin',
    isHidden: filter => filter.pinned === true,
    Callback: (filters, filter) => {
      const updatedFilters = filters.map(f => {
        if (f.id === filter.id) {
          return { ...f, pinned: true };
        }
        return f;
      });
      return updatedFilters.sort((a, b) => {
        if (a.pinned === b.pinned) {
          return 0;
        }
        return a.pinned ? -1 : 1;
      });
    }
  },
  unpin: {
    label: 'Unpin',
    icon: 'thumbtack-slash',
    inversion: 'pin',
    isHidden: filter => filter.pinned === false,
    Callback: (filters, filter) => {
      const updatedFilters = filters.map(f => {
        if (f.id === filter.id) {
          return { ...f, pinned: false };
        }
        return f;
      });
      return updatedFilters.sort((a, b) => {
        if (a.pinned === b.pinned) {
          return 0;
        }
        return a.pinned ? -1 : 1;
      });
    }
  },
  remove: {
    label: 'Remove',
    icon: 'trash-alt',
    inversion: null,
    isHidden: () => false,
    Callback: (filters, filter) => {
      const updatedFilters = filters.filter(f => f.id !== filter.id);
      return updatedFilters;
    }
  }
};

export const testFilters = [
  // Tests for 'is' operator
  {
    id: uuid(),
    field: {
      accessor: 'status',
      name: 'Status',
      id: 'status',
      type: 'string'
    },
    value: 'active',
    operator: 'is',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'category',
      name: 'Category',
      id: 'category',
      type: 'string'
    },
    value: 'electronics',
    operator: 'is',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'rating',
      name: 'Rating',
      id: 'rating',
      type: 'number'
    },
    value: 5,
    operator: 'is',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'region',
      name: 'Region',
      id: 'region',
      type: 'string'
    },
    value: 'North',
    operator: 'is',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'language',
      name: 'Language',
      id: 'language',
      type: 'string'
    },
    value: 'English',
    operator: 'is',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'isNot' operator
  {
    id: uuid(),
    field: {
      accessor: 'domain',
      name: 'Domain',
      id: 'domain',
      type: 'string'
    },
    value: 'admin',
    operator: 'isNot',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'color', name: 'Color', id: 'color', type: 'string' },
    value: 'red',
    operator: 'isNot',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'age', name: 'Age', id: 'age', type: 'number' },
    value: 30,
    operator: 'isNot',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'brand', name: 'Brand', id: 'brand', type: 'string' },
    value: 'ACME',
    operator: 'isNot',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'model', name: 'Model', id: 'model', type: 'string' },
    value: 'ProX',
    operator: 'isNot',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'isLike' operator
  {
    id: uuid(),
    field: {
      accessor: 'description',
      name: 'Description',
      id: 'description',
      type: 'string'
    },
    value: 'premium',
    operator: 'isLike',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'title', name: 'Title', id: 'title', type: 'string' },
    value: 'sale',
    operator: 'isLike',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'comment',
      name: 'Comment',
      id: 'comment',
      type: 'string'
    },
    value: 'awesome',
    operator: 'isLike',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'summary',
      name: 'Summary',
      id: 'summary',
      type: 'string'
    },
    value: 'important',
    operator: 'isLike',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'tagline',
      name: 'Tagline',
      id: 'tagline',
      type: 'string'
    },
    value: '2023',
    operator: 'isLike',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'isNotLike' operator
  {
    id: uuid(),
    field: { accessor: 'bio', name: 'Bio', id: 'bio', type: 'string' },
    value: 'hacker',
    operator: 'isNotLike',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'address',
      name: 'Address',
      id: 'address',
      type: 'string'
    },
    value: 'confidential',
    operator: 'isNotLike',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'feedback',
      name: 'Feedback',
      id: 'feedback',
      type: 'string'
    },
    value: 'error',
    operator: 'isNotLike',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'about', name: 'About', id: 'about', type: 'string' },
    value: 'private',
    operator: 'isNotLike',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'notes', name: 'Notes', id: 'notes', type: 'string' },
    value: 'temporary',
    operator: 'isNotLike',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'isOneOf' operator
  {
    id: uuid(),
    field: { accessor: 'role', name: 'Role', id: 'role', type: 'string' },
    value: ['admin', 'moderator'],
    operator: 'isOneOf',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'level', name: 'Level', id: 'level', type: 'string' },
    value: ['beginner', 'intermediate'],
    operator: 'isOneOf',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'group', name: 'Group', id: 'group', type: 'string' },
    value: ['A', 'B'],
    operator: 'isOneOf',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'phase', name: 'Phase', id: 'phase', type: 'string' },
    value: ['design', 'development'],
    operator: 'isOneOf',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'segment',
      name: 'Segment',
      id: 'segment',
      type: 'string'
    },
    value: ['premium', 'basic'],
    operator: 'isOneOf',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'isNotOneOf' operator
  {
    id: uuid(),
    field: { accessor: 'pack', name: 'Pack', id: 'pack', type: 'string' },
    value: ['silver', 'gold'],
    operator: 'isNotOneOf',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'ring_size',
      name: 'Ring Size',
      id: 'ring_size',
      type: 'number'
    },
    value: [5, 7],
    operator: 'isNotOneOf',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'shipping_method',
      name: 'Shipping Method',
      id: 'shipping_method',
      type: 'string'
    },
    value: ['standard', 'priority'],
    operator: 'isNotOneOf',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'zone', name: 'Zone', id: 'zone', type: 'string' },
    value: ['east', 'west'],
    operator: 'isNotOneOf',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'flavor',
      name: 'Flavor',
      id: 'flavor',
      type: 'string'
    },
    value: ['vanilla', 'chocolate'],
    operator: 'isNotOneOf',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'isBetween' operator
  {
    id: uuid(),
    field: { accessor: 'score', name: 'Score', id: 'score', type: 'number' },
    value: [50, 100],
    operator: 'isBetween',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'price', name: 'Price', id: 'price', type: 'number' },
    value: [10, 20],
    operator: 'isBetween',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'distance',
      name: 'Distance',
      id: 'distance',
      type: 'number'
    },
    value: [5, 15],
    operator: 'isBetween',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'weight', name: 'Weight', id: 'weight', type: 'number' },
    value: [1, 5],
    operator: 'isBetween',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'volume', name: 'Volume', id: 'volume', type: 'number' },
    value: [500, 1000],
    operator: 'isBetween',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'isNotBetween' operator
  {
    id: uuid(),
    field: {
      accessor: 'temperature',
      name: 'Temperature',
      id: 'temperature',
      type: 'number'
    },
    value: [-10, 30],
    operator: 'isNotBetween',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'age_group',
      name: 'Age Group',
      id: 'age_group',
      type: 'number'
    },
    value: [13, 19],
    operator: 'isNotBetween',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'height', name: 'Height', id: 'height', type: 'number' },
    value: [150, 180],
    operator: 'isNotBetween',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'depth', name: 'Depth', id: 'depth', type: 'number' },
    value: [0, 10],
    operator: 'isNotBetween',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'runtime',
      name: 'Runtime',
      id: 'runtime',
      type: 'number'
    },
    value: [90, 120],
    operator: 'isNotBetween',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'isGreaterThan' operator
  {
    id: uuid(),
    field: {
      accessor: 'altitude',
      name: 'Altitude',
      id: 'altitude',
      type: 'number'
    },
    value: 1000,
    operator: 'isGreaterThan',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'depth', name: 'Depth', id: 'depth', type: 'number' },
    value: 200,
    operator: 'isGreaterThan',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'amount', name: 'Amount', id: 'amount', type: 'number' },
    value: 300,
    operator: 'isGreaterThan',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'length', name: 'Length', id: 'length', type: 'number' },
    value: 50,
    operator: 'isGreaterThan',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'capacity',
      name: 'Capacity',
      id: 'capacity',
      type: 'number'
    },
    value: 800,
    operator: 'isGreaterThan',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'isGreaterThanOrEqualTo' operator
  {
    id: uuid(),
    field: {
      accessor: 'bandwidth',
      name: 'Bandwidth',
      id: 'bandwidth',
      type: 'number'
    },
    value: 200,
    operator: 'isGreaterThanOrEqualTo',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'version',
      name: 'Version',
      id: 'version',
      type: 'number'
    },
    value: 2.5,
    operator: 'isGreaterThanOrEqualTo',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'rating', name: 'Rating', id: 'rating', type: 'number' },
    value: 4.5,
    operator: 'isGreaterThanOrEqualTo',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'speed', name: 'Speed', id: 'speed', type: 'number' },
    value: 40,
    operator: 'isGreaterThanOrEqualTo',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'temperature',
      name: 'Temperature',
      id: 'temperature',
      type: 'number'
    },
    value: 75,
    operator: 'isGreaterThanOrEqualTo',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'isLessThan' operator
  {
    id: uuid(),
    field: {
      accessor: 'latency',
      name: 'Latency',
      id: 'latency',
      type: 'number'
    },
    value: 100,
    operator: 'isLessThan',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'delay', name: 'Delay', id: 'delay', type: 'number' },
    value: 50,
    operator: 'isLessThan',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'diameter',
      name: 'Diameter',
      id: 'diameter',
      type: 'number'
    },
    value: 40,
    operator: 'isLessThan',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'height', name: 'Height', id: 'height', type: 'number' },
    value: 160,
    operator: 'isLessThan',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'pressure',
      name: 'Pressure',
      id: 'pressure',
      type: 'number'
    },
    value: 20,
    operator: 'isLessThan',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'isLessThanOrEqualTo' operator
  {
    id: uuid(),
    field: { accessor: 'cost', name: 'Cost', id: 'cost', type: 'number' },
    value: 500,
    operator: 'isLessThanOrEqualTo',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'height', name: 'Height', id: 'height', type: 'number' },
    value: 170,
    operator: 'isLessThanOrEqualTo',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'weight', name: 'Weight', id: 'weight', type: 'number' },
    value: 70,
    operator: 'isLessThanOrEqualTo',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'length', name: 'Length', id: 'length', type: 'number' },
    value: 100,
    operator: 'isLessThanOrEqualTo',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'battery_life',
      name: 'Battery Life',
      id: 'battery_life',
      type: 'number'
    },
    value: 15,
    operator: 'isLessThanOrEqualTo',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'isNull' operator
  {
    id: uuid(),
    field: {
      accessor: 'address',
      name: 'Address',
      id: 'address',
      type: 'string'
    },
    value: null,
    operator: 'isNull',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'telephone',
      name: 'Telephone',
      id: 'telephone',
      type: 'string'
    },
    value: null,
    operator: 'isNull',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'fax', name: 'Fax', id: 'fax', type: 'string' },
    value: null,
    operator: 'isNull',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'social_media',
      name: 'Social Media',
      id: 'social_media',
      type: 'string'
    },
    value: null,
    operator: 'isNull',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'website',
      name: 'Website',
      id: 'website',
      type: 'string'
    },
    value: null,
    operator: 'isNull',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'isNotNull' operator
  {
    id: uuid(),
    field: {
      accessor: 'driver_license',
      name: 'Driver License',
      id: 'driver_license',
      type: 'string'
    },
    value: null,
    operator: 'isNotNull',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'passport',
      name: 'Passport',
      id: 'passport',
      type: 'string'
    },
    value: null,
    operator: 'isNotNull',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'tax_id',
      name: 'Tax ID',
      id: 'tax_id',
      type: 'string'
    },
    value: null,
    operator: 'isNotNull',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'social_security',
      name: 'Social Security',
      id: 'social_security',
      type: 'string'
    },
    value: null,
    operator: 'isNotNull',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'bank_account',
      name: 'Bank Account',
      id: 'bank_account',
      type: 'string'
    },
    value: null,
    operator: 'isNotNull',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'isTrue' operator
  {
    id: uuid(),
    field: {
      accessor: 'verified',
      name: 'Verified',
      id: 'verified',
      type: 'boolean'
    },
    value: true,
    operator: 'isTrue',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'newsletter_opt_in',
      name: 'Newsletter Opt-in',
      id: 'newsletter_opt_in',
      type: 'boolean'
    },
    value: true,
    operator: 'isTrue',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'consent_given',
      name: 'Consent Given',
      id: 'consent_given',
      type: 'boolean'
    },
    value: true,
    operator: 'isTrue',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'terms_accepted',
      name: 'Terms Accepted',
      id: 'terms_accepted',
      type: 'boolean'
    },
    value: true,
    operator: 'isTrue',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'email_verified',
      name: 'Email Verified',
      id: 'email_verified',
      type: 'boolean'
    },
    value: true,
    operator: 'isTrue',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'isFalse' operator
  {
    id: uuid(),
    field: {
      accessor: 'subscribed',
      name: 'Subscribed',
      id: 'subscribed',
      type: 'boolean'
    },
    value: false,
    operator: 'isFalse',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'promo_accepted',
      name: 'Promo Accepted',
      id: 'promo_accepted',
      type: 'boolean'
    },
    value: false,
    operator: 'isFalse',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'pending_approval',
      name: 'Pending Approval',
      id: 'pending_approval',
      type: 'boolean'
    },
    value: false,
    operator: 'isFalse',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'overdue',
      name: 'Overdue',
      id: 'overdue',
      type: 'boolean'
    },
    value: false,
    operator: 'isFalse',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'discount_applied',
      name: 'Discount Applied',
      id: 'discount_applied',
      type: 'boolean'
    },
    value: false,
    operator: 'isFalse',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'exists' operator
  {
    id: uuid(),
    field: {
      accessor: 'location',
      name: 'Location',
      id: 'location',
      type: 'geo_point'
    },
    value: null,
    operator: 'exists',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'avatar',
      name: 'Avatar',
      id: 'avatar',
      type: 'string'
    },
    value: null,
    operator: 'exists',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'membership',
      name: 'Membership',
      id: 'membership',
      type: 'string'
    },
    value: null,
    operator: 'exists',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: { accessor: 'bio', name: 'Bio', id: 'bio', type: 'string' },
    value: null,
    operator: 'exists',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'profile_picture',
      name: 'Profile Picture',
      id: 'profile_picture',
      type: 'string'
    },
    value: null,
    operator: 'exists',
    enabled: true,
    pinned: false,
    inclusion: true
  },

  // Tests for 'doesNotExist' operator
  {
    id: uuid(),
    field: {
      accessor: 'birthdate',
      name: 'Birthdate',
      id: 'birthdate',
      type: 'date'
    },
    value: null,
    operator: 'doesNotExist',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'passport_number',
      name: 'Passport Number',
      id: 'passport_number',
      type: 'string'
    },
    value: null,
    operator: 'doesNotExist',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'driver_id',
      name: 'Driver ID',
      id: 'driver_id',
      type: 'string'
    },
    value: null,
    operator: 'doesNotExist',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'security_answer',
      name: 'Security Answer',
      id: 'security_answer',
      type: 'string'
    },
    value: null,
    operator: 'doesNotExist',
    enabled: true,
    pinned: false,
    inclusion: true
  },
  {
    id: uuid(),
    field: {
      accessor: 'middle_name',
      name: 'Middle Name',
      id: 'middle_name',
      type: 'string'
    },
    value: null,
    operator: 'doesNotExist',
    enabled: true,
    pinned: false,
    inclusion: true
  }
];
