import BasicECharts from 'components/common/BasicEChart';

import SimpleSpinner from 'components/utilities/AppSpinner/smallSpinner';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';

import { CanvasRenderer } from 'echarts/renderers';
import { getColor, numberFormatter, rgbaColor } from 'helpers/utils';
import useApplication from 'hooks/useApplication';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const getOptions = (data, isDark) => ({
  xAxis: {
    show: true,
    type: 'category',
    data: data?.map(item => item['key_as_string']),
    axisLabel: {
      show: true,
      color: isDark ? 'var(--falcon-gray-400' : 'var(--falcon-gray-600)',
      fontWeight: 400,
      fontSize: 10,
      margin: 10,
      formatter: function (value) {
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      }
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: rgbaColor(getColor('secondary'), 0.1)
      }
    },
    axisTick: {
      show: true,
      alignWithLabel: true,
      lineStyle: {
        color: rgbaColor(getColor('secondary'), 0.1)
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: rgbaColor(getColor('secondary'), 0.1)
      }
    },
    z: 2
  },
  yAxis: {
    show: true,
    type: 'value',
    axisLabel: {
      show: true,
      color: getColor('gray-700'),
      fontWeight: 400,
      fontSize: 10,
      margin: 10,
      formatter: function (value) {
        return numberFormatter(value, 0);
      }
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: rgbaColor(getColor('secondary'), 0.1)
      }
    },
    axisTick: {
      show: true,
      alignWithLabel: true,
      lineStyle: {
        color: rgbaColor(getColor('secondary'), 0.1)
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: rgbaColor(getColor('secondary'), 0.1)
      }
    },
    z: 2
  },
  tooltip: {
    trigger: 'axis',
    appendToBody: true,
    axisPointer: {
      type: 'cross',
      label: {
        show: false,
        backgroundColor: '#6a7985'
      }
    },
    padding: [7, 10],
    borderWidth: 1,
    borderColor: isDark ? getColor('gray-300') : getColor('gray-300'),
    backgroundColor: isDark ? getColor('gray-200') : getColor('white'),
    textStyle: {
      fontWeight: 400,
      fontSize: 12,
      color: getColor('gray-900')
    },
    formatter: params => {
      const sum = data.reduce((acc, curr) => acc + Number(curr.doc_count), 0);
      return `<p class="m-0 fs--1">${moment(params[0]?.name).format(
        'YYYY-MM-DD HH:mm:ss'
      )}: <b>${Number(params[0]?.value).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })}</b> (${
        params[0]?.value > 0 ? ((params[0]?.value / sum) * 100).toFixed(2) : 0
      }%) </p>`;
    },
    z: 100
  },
  series: [
    {
      type: 'line',
      smooth: true,
      data: data ? data?.map(item => Number(item['doc_count']) || 0) : 0,
      symbol: 'emptyCircle',
      showSymbol: false,
      symbolSize: 5,
      itemStyle: {
        color: getColor('primary')
      },
      lineStyle: {
        width: 1,
        color: getColor('primary')
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 1)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      },
      emphasis: {
        lineStyle: {
          width: 1
        },
        focus: 'series'
      },
      z: 3
    }
  ],
  grid: { right: 10, left: 40, top: 5, bottom: 20 },
  animation: true,
  animationDuration: 750,
  animationThreshold: 25
});

const GreetingGraphChart = React.memo(({ wrapperLoader, statHistogram }) => {
  const {
    application: { isDark }
  } = useApplication();

  return (
    <div className='position-relative'>
      {wrapperLoader && (
        <span
          className='fw-light position-absolute top-0 end-0 z-index-2'
          style={{ marginRight: '15px' }}>
          <SimpleSpinner size='sm' />
        </span>
      )}
      <BasicECharts
        echarts={echarts}
        options={getOptions(statHistogram?.data || [], isDark)}
        style={{ height: '8rem', width: '100%' }}
      />
    </div>
  );
});

GreetingGraphChart.propTypes = {
  wrapperLoader: PropTypes.bool,
  statHistogram: PropTypes.object
};

export default GreetingGraphChart;
