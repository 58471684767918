import Flex from 'components/common/Flex';
import AdvancedTableSearchBox from 'components/common/table/TableSearchBox';
import { OverlayTriggerPopover } from 'components/visualizations/OverlayTriggerPopOver';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { BsPlus, BsTrash } from 'react-icons/bs';
import { FaSlidersH } from 'react-icons/fa';
import { MdFilterAlt } from 'react-icons/md';

const DashboardsTableFilters = ({
  globalFilter,
  setGlobalFilter,
  dataTypeLabel,
  dataTypeLabelPlural,
  showDeleted,
  setShowDeleted,
  setShowCreateModal,
  setShowFiltersModal,
  setShowSettingsModal,
  hideSearchBox,
  hideCreateToggle,
  hideDeletedToggle,
  hideFiltersToggle,
  hideSettingsToggle
}) => {
  return (
    <>
      <Flex className='pb-2'>
        {!hideSearchBox ? (
          <AdvancedTableSearchBox
            table
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            dataTypeLabelPlural={dataTypeLabelPlural}
            className='filter-input fs--1'
          />
        ) : null}

        {!hideCreateToggle ? (
          <OverlayTriggerPopover PopoverText={`Create a ${dataTypeLabel}`}>
            <span className='ms-2'>
              <Button
                variant='primary'
                size='md'
                onClick={() => setShowCreateModal({ open: true, fetch: false })}
                className='px-2'>
                <BsPlus transform='scale(2)' />
              </Button>
            </span>
          </OverlayTriggerPopover>
        ) : null}

        {!hideDeletedToggle ? (
          <OverlayTriggerPopover
            PopoverText={`  ${
              showDeleted?.show ? 'Hide deleted' : 'Show deleted'
            } ${dataTypeLabelPlural}`}>
            <span className='ms-2'>
              <Button
                variant={showDeleted?.show ? 'primary' : 'outline-primary'}
                size='md'
                onClick={() =>
                  setShowDeleted({ show: !showDeleted?.show, fetch: true })
                }
                className='px-2'>
                <BsTrash transform='scale(1)' />
              </Button>
            </span>
          </OverlayTriggerPopover>
        ) : null}

        {!hideFiltersToggle ? (
          <OverlayTriggerPopover PopoverText={`Filter ${dataTypeLabelPlural}`}>
            <span className='ms-2'>
              <MdFilterAlt
                onClick={() => setShowFiltersModal(true)}
                className='text-600 cursor-pointer'
              />
            </span>
          </OverlayTriggerPopover>
        ) : null}

        {!hideSettingsToggle ? (
          <OverlayTriggerPopover PopoverText={`Display Settings`}>
            <span className='ms-2'>
              <FaSlidersH
                onClick={() => setShowSettingsModal(true)}
                className='text-600 cursor-pointer'
              />
            </span>
          </OverlayTriggerPopover>
        ) : null}
      </Flex>
    </>
  );
};

DashboardsTableFilters.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.any,
  dataTypeLabel: PropTypes.string,
  dataTypeLabelPlural: PropTypes.string,
  selectedIds: PropTypes.array,
  showDeleted: PropTypes.any,
  setShowDeleted: PropTypes.any,
  setShowCreateModal: PropTypes.any,
  setShowFiltersModal: PropTypes.any,
  setShowSettingsModal: PropTypes.any,
  hideBulkActions: PropTypes.bool,
  hideSearchBox: PropTypes.bool,
  hideCreateToggle: PropTypes.bool,
  hideDeletedToggle: PropTypes.bool,
  hideFiltersToggle: PropTypes.bool,
  hideSettingsToggle: PropTypes.bool
};

export default DashboardsTableFilters;
