import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Reports from './components/Reports';

const ReportsLayout = () => {
  return (
    <>
      <Row className='g-3 mb-3'>
        <Col sm={12}>
          <Reports />
        </Col>
      </Row>
    </>
  );
};

export default ReportsLayout;
