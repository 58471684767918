import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';

/**
 * Renders the Subscriptions component
 *
 * @returns {JSX.Element} The rendered Subscriptions component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Subscriptions = () => {
  return (
    <WidgetSectionTitle title='Subscription Management' className='mb-2' />
  );
};

export default Subscriptions;
