import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useAuthentication from 'hooks/useAuthentication';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useLogout from 'hooks/useLogout';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

/**
 * MFA app verify form
 *
 * The MFA app verify form is used to display a form to the user to verify their multi-factor authentication app
 * The user is prompted to enter the code from their authenticator app to verify the setup
 *
 * @param {Object} props The component props
 * @param {boolean} props.hasLabel Whether or not to display a label for the verification code input
 * @returns {React.Component} The MFA app setup form
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const OTPVerifyForm = ({ hasLabel }) => {
  const logout = useLogout();
  const focusField = useRef();

  const { axiosPrivate } = useAxiosPrivate();
  const { setAuthentication } = useAuthentication();

  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  /**
   * Handles the form submission for OTP verification
   *
   * @param {Event} e - The form submission event
   * @returns {Promise<void>} - A promise that resolves when the submission is complete
   *
   * The MFA app verify form is used to display a form to the user to verify their multi-factor authentication app
   * The user is prompted to enter the code from their authenticator app to verify the setup
   *
   * @param {Object} props The component props
   * @param {boolean} props.hasLabel Whether or not to display a label for the verification code input
   * @returns {React.Component} The MFA app setup form
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await LeargasAPI.AccountAuth.userSessionVerify(
        {
          body: { code }
        },
        axiosPrivate
      );
      if (response instanceof Error) throw response;
      let message = response?.data?.message;
      if (message) toast.success(message);
      setCode('');
      setLoading(false);
      setAuthentication(prevState => ({
        ...prevState,
        ...response.data.data.authentication,
        persistLoader: true
      }));
      setTimeout(function () {
        setAuthentication(prevState => ({
          ...prevState,
          persistLoader: false,
          intercepted: false
        }));
      }, 1000);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      let message = error?.response?.data?.message;
      if (message) toast.error(message);
      setTimeout(function () {
        if (focusField.current) focusField.current.focus();
        setAuthentication(prevState => ({
          ...prevState,
          intercepted: false
        }));
        setCode('');
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    setAuthentication(prevState => ({
      ...prevState,
      intercepted: true
    }));
  }, []);

  useEffect(() => {
    if (focusField.current) focusField.current.focus();
  }, [focusField.current]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='g-2 flex-center mb-3 position-relative'>
        <Col xs={8}>
          <Row className='g-2 mb-3'>
            <Col>
              <Form.Group>
                {hasLabel && <Form.Label>Authenticator code</Form.Label>}
                <Form.Control
                  size='sm'
                  name='verificationCode'
                  ref={focusField}
                  type='digits'
                  autoComplete='off'
                  value={code}
                  maxLength={6}
                  onChange={e => setCode(e.target.value.replace(/[^0-9]/g, ''))}
                  className='text-center auth-form-control-dark shadow-none'
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='g-2'>
            <Col>
              <Button
                size='sm'
                variant='outline-secondary'
                className='w-100'
                onClick={() => logout(axiosPrivate)}>
                <span>Cancel</span>
              </Button>
            </Col>
            <Col>
              <Button
                size='sm'
                type='submit'
                variant='outline-primary'
                className='w-100'>
                <Flex justifyContent={'center'} alignItems={'center'}>
                  <ButtonSpinner spinning={loading} />
                  <span>{loading ? 'Verifying' : 'Verify'}</span>
                </Flex>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

OTPVerifyForm.propTypes = {
  hasLabel: PropTypes.bool
};

OTPVerifyForm.defaultProps = {
  hasLabel: false
};

export default OTPVerifyForm;
