import LeargasAPI from 'api';
import useOrganizations from 'hooks/admin-contexts/useOrganizations';
import React, { useState } from 'react';

import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';

import Flex from 'components/common/Flex';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

/**
 * Restore org form
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const RestoreOrganizationForm = () => {
  const {
    organizations: {
      orgRestoreModal: { data }
    },
    setOrganizations
  } = useOrganizations();
  const { axiosPrivate } = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  /**
   * Submit restore button function
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await LeargasAPI.Organizations.deleteOrgs(
        data?._id,
        false,
        axiosPrivate
      );
      if (res instanceof Error) {
        throw res;
      }
      setOrganizations(prevState => ({
        ...prevState,
        fetch: true,
        orgRestoreModal: { open: false, data: {} }
      }));
      const { message } = res.data;
      toast.success(message);
      setLoading(false);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setOrganizations(prevState => ({
        ...prevState,
        fetch: false,
        orgRestoreModal: { open: false, data: {} }
      }));
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong');
      }
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='g-3 mb-3'>
        <Col sm={12}>
          <p>Are you sure you want to restore {data?.name}?</p>
        </Col>
      </Row>

      <Flex justifyContent='end'>
        <Button
          size='sm'
          variant='secondary'
          className='me-2'
          onClick={() =>
            setOrganizations(prevState => ({
              ...prevState,
              orgRestoreModal: { open: false }
            }))
          }>
          Cancel
        </Button>

        <Button size='sm' variant='success' type='submit'>
          <div className='position-relative'>
            <Flex justifyContent='center' alignItems={'center'}>
              <ButtonSpinner spinning={loading} />{' '}
              <span>{loading ? 'Restoring' : 'Restore'}</span>
            </Flex>
          </div>
        </Button>
      </Flex>
    </Form>
  );
};

export default RestoreOrganizationForm;
