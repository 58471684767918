import PropTypes from 'prop-types';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { getColor, truncateMiddle } from 'helpers/utils';
import { Badge, Table } from 'react-bootstrap';

/**
 *
 * @param {object} props
 * @param {Function} props.getTableProps
 * @param {Array} props.headers
 * @param {Array} props.page
 * @param {Function} props.prepareRow
 * @param {String} props.headerClassName
 * @param {String} props.rowClassName
 * @param {Function} props.tableProps
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const renderExpandedRow = row => {
  return (
    <div style={{ padding: '10px' }}>
      <Flex direction='column' className='mb-1 gap-2'>
        <div>
          Indices:{' '}
          {row.original.indices.map(index => {
            return index;
          })}
        </div>
        <div>
          <div>Filters</div>
          <Flex
            direction='row'
            wrap='wrap'
            alignContent='center'
            alignItems='center'
            justifyContent='start'
            className='h-100'>
            {row.original.filters?.map((filter, index) => {
              return (
                <Badge
                  key={index}
                  bg={classNames({
                    'soft-pastel-gray': !filter.enabled,
                    'soft-pastel-green': filter.inclusion,
                    'soft-pastel-yellow': !filter.inclusion
                  })}
                  className={classNames(
                    'ps-1 fs--2 fw-normal cursor-pointer d-flex justify-content-center align-items-center border opacity-100 me-1 mb-1 hover-opacity-75',
                    {
                      'text-pastel-gray hover-border-soft-pastel-gray hover-bg-pastel-gray-light':
                        !filter.enabled,
                      'text-pastel-green-dark hover-border-soft-pastel-green hover-bg-pastel-green-light':
                        filter.inclusion,
                      'text-pastel-yellow-dark hover-border-soft-pastel-yellow hover-bg-pastel-yellow-light':
                        !filter.inclusion
                    }
                  )}
                  style={{
                    textDecoration: filter.enabled ? 'none' : 'line-through'
                  }}>
                  {/* If the filter is pinned, show the thumbtack icon */}
                  {filter.pinned && (
                    <FontAwesomeIcon icon='thumb-tack' className='me-1' />
                  )}
                  {filter?.useCustomLabel && filter.customLabel ? (
                    <span className='fw-semi-bold'>{filter.customLabel}</span>
                  ) : (
                    <>
                      {/* Show the accessor immediately after any applicable icons */}
                      "{filter.field.accessor}" -{/* {'REPLACED OPERATOR'} */}
                      {/* Show the operator's symbol immediately after the accessor */}
                      <span className='ms-1 fw-semi-bold'>
                        "
                        {Array.isArray(filter.value)
                          ? filter.value.map((item, i) => {
                              return (
                                <span key={i}>
                                  {item}
                                  {i !== filter.value?.length - 1 ? ', ' : ''}
                                </span>
                              );
                            })
                          : truncateMiddle(filter.value)}
                        "
                      </span>
                    </>
                  )}
                </Badge>
              );
            })}
          </Flex>
        </div>
      </Flex>
    </div>
  );
};

const AlertsTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  tableProps
}) => {
  return (
    <div className='table-responsive '>
      <Table
        responsive
        size='sm'
        {...getTableProps(tableProps)}
        className='simplebar-scroll-content'
        data-simplebar>
        <thead className={headerClassName}>
          <tr>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(
                  column.getSortByToggleProps(column.headerProps)
                )}>
                {column.render('Header')}
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className='sort desc' />
                    ) : (
                      <span className='sort asc' />
                    )
                  ) : (
                    <span className='sort' />
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            let rowClassName = 'transparent';
            return (
              <React.Fragment key={i}>
                <tr key={i} className={rowClassName} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={classNames(
                          'align-middle p-0 py-1 pe-2 ps-1 fs--2'
                        )}
                        {...cell.getCellProps(cell.column.cellProps)}
                        style={
                          i === page.length - 1
                            ? {
                                border: 'none',
                                backgroundColor: `${getColor(rowClassName)}`
                              }
                            : { backgroundColor: `${getColor(rowClassName)}` }
                        }>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {/* Expanded Content */}
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={headers.length}>
                      {/* This is where you display the expanded content */}
                      {renderExpandedRow(row)}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

AlertsTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object
};

export default AlertsTable;
