import React from 'react';
import Products from './components/Products';
import { Col, Row } from 'react-bootstrap';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ProductsLayout = () => {
  return (
    <>
      <Row className='g-3 mb-3'>
        <Col sm={12}>
          <Products />
        </Col>
      </Row>
    </>
  );
};

export default ProductsLayout;
