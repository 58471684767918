import ecomBg from 'assets/img/clover-bg-corner.png';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import { StaticQueryIntegration } from 'components/dashboards/Artifacts/Dynamic/StaticQueryIntegration';
import useAccount from 'hooks/useAccount';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import TotalArtifacts from './AllArtifact';
import CloudArtifacts from './CloudArtifacts';
import DarkwebHits from './DarkwebHits';
import EndPointThreat from './EndPointThreat';
import GreetingGraphChart from './GreetingGraphChart';
import NetworkArtifacts from './NetworkArtifacts';
import ThreatIndicators from './ThreatIndicators';

const GreetingCard = React.memo(() => {
  const {
    account: {
      profile: {
        name: { first }
      }
    }
  } = useAccount();
  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      setGreeting('Good Morning');
    } else if (currentHour < 18) {
      setGreeting('Good Afternoon');
    } else {
      setGreeting('Good Evening');
    }
  }, []);
  return (
    <Card className='h-100 position-relative p-0 border border-card shadow-none rounded-sm overflow-hidden'>
      <Background
        image={ecomBg}
        className='d-none d-sm-block bg-card'
        style={{
          backgroundSize: 'auto',
          backgroundPosition: 'right top'
        }}
      />
      <Card.Header className='p-2'>
        <div className='position-relative'>
          <div>
            <h3 className='text-primary mb-2 fs-1 fw-normal'>
              {greeting}, {first}!
            </h3>
            <h3 className='fw-light fs--1 mb-3'>
              Here's a quick overview of all artifacts collected and analyzed
              over the past hour.
            </h3>
          </div>
          <Flex className='pb-3'>
            <Flex direction='column'>
              <StaticQueryIntegration
                queryType={['histogram']}
                overriddenIndex={['leargas-*']}>
                <TotalArtifacts />
              </StaticQueryIntegration>
            </Flex>
            <Flex>
              <Row className='g-0'>
                <Col sm={4} className='ps-2 pb-2 border-bottom border-gray-700'>
                  <StaticQueryIntegration
                    queryType={['histogram']}
                    overriddenIndex={['leargas*suricat*', 'leargas*zee*']}>
                    <NetworkArtifacts />
                  </StaticQueryIntegration>
                </Col>
                <Col
                  sm={7}
                  className='ps-2 pb-2 border-bottom border-start border-gray-700'>
                  <StaticQueryIntegration
                    queryType={['histogram']}
                    overriddenIndex={[
                      'leargas-box',
                      'leargas-aws',
                      'leargas-azure',
                      'leargas-duo',
                      'leargas-google',
                      'leargas-meraki',
                      'leargas-o365',
                      'leargas-okta',
                      'leargas-sentinelone-agents',
                      'leargas-sentinelone-threats'
                    ]}>
                    <CloudArtifacts />
                  </StaticQueryIntegration>
                </Col>
                <Col sm={4} className='ps-2 pt-2 border-gray-700'>
                  <StaticQueryIntegration
                    queryType={['histogram']}
                    overriddenIndex={['leargas-zeek']}
                    must={[
                      {
                        match_phrase: {
                          path: '/usr/local/zeek/logs/current/intel.log'
                        }
                      },
                      {
                        bool: {
                          must: [],
                          must_not: [
                            {
                              match_phrase: {
                                sources: 'CPS-VS'
                              }
                            }
                          ],
                          filter: [],
                          should: []
                        }
                      }
                    ]}>
                    <ThreatIndicators />
                  </StaticQueryIntegration>
                </Col>
                <Col sm={4} className='ps-2 pt-2 border-start border-gray-700'>
                  <StaticQueryIntegration
                    queryType={['histogram']}
                    overriddenIndex={['leargas-sentinelone-threats']}>
                    <EndPointThreat />
                  </StaticQueryIntegration>
                </Col>
                <Col sm={4} className='ps-2 pt-2 border-start'>
                  <StaticQueryIntegration
                    queryType={['histogram']}
                    overriddenIndex={['leargas-darkweb']}>
                    <DarkwebHits />
                  </StaticQueryIntegration>
                </Col>
              </Row>
            </Flex>
          </Flex>
          <StaticQueryIntegration
            queryType={['histogram']}
            overriddenIndex={['leargas']}>
            <GreetingGraphChart />
          </StaticQueryIntegration>
        </div>
      </Card.Header>
      <Card.Body className='p-0 px-2'>
        <ul className='mb-0 list-unstyled'>
          {/* {notifications.map((notification, index) => (
              <Notification
                key={notification.id}
                notification={notification}
                isLast={notifications.length - 1 === index}
              />
            ))} */}
        </ul>
      </Card.Body>
    </Card>
  );
});

GreetingCard.propTypes = {
  data: PropTypes.object,
  grid: PropTypes.object,
  helpInfo: PropTypes.string,
  linkTo: PropTypes.string,
  notifications: PropTypes.arrayOf(PropTypes.object),
  statHistogram: PropTypes.object,
  wrapperLoader: PropTypes.bool
};

export default GreetingCard;
