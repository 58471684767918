import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import useFilters from 'hooks/Misc/useFilters';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import { Badge, Dropdown } from 'react-bootstrap';

/**
 * Renders a filter badge component
 *
 * @param {Object} props - The component props
 * @param {string} props.operator - Filter operator
 * @param {string} props.accessor - Filter accessor
 * @param {string} props.value - Filter value
 * @param {Function} props.removeFilter - Function to remove filter
 * @param {Object} props.item - Filter item
 * @param {number} props.index - Filter index
 * @param {Function} props.setESGlobalFilters - Function to set global filters
 * @param {Function} props.tempDisable - Function to temporarily disable filter
 * @param {Function} props.togglePinFilters - Function to toggle pinning filter
 * @param {Function} props.toggleIncludeExcludeResults - Function to toggle including or excluding results
 * @returns {JSX.Element} Filter badge component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.2
 */
const FilterBadge = ({
  operator = 'is',
  accessor,
  value,
  removeFilter,
  index,
  setESGlobalFilters,
  tempDisable,
  item,
  toggleIncludeExcludeResults,
  togglePinFilters
}) => {
  const { setFilters } = useFilters();
  const { updateGlobalFilters } = useApplication();

  return (
    <Dropdown>
      <Dropdown.Toggle
        size='sm'
        className='me-2 outline-0 p-0 ellipsis-filter bg-transparent border-0 shadow-none'>
        <Badge
          bg={classNames({
            'soft-pastel-green':
              !item?.tempDisable && !operator.includes('not'),
            'soft-pastel-red': !item?.tempDisable && operator.includes('not'),
            'soft-pastel-gray': item?.tempDisable
          })}
          className={classNames(
            'fs--2 fw-normal cursor-pointer d-flex justify-content-center align-items-center border',
            {
              'text-pastel-green-dark border-pastel-green hover-bg-pastel-green':
                !item?.tempDisable && !operator.includes('not'),
              'text-pastel-red-dark border-pastel-red hover-bg-pastel-red':
                !item?.tempDisable && operator.includes('not'),
              'text-pastel-gray-dark border-pastel-gray hover-bg-pastel-gray':
                item?.tempDisable
            }
          )}
          style={{
            textDecoration: item?.tempDisable ? 'line-through' : 'none'
          }}>
          {/* If the filter is pinned, show the thumbtack icon */}
          {item?.pinned && (
            <FontAwesomeIcon icon='thumb-tack' className='me-1' />
          )}
          {/* Add `NOT` at start of the filter text if applicable */}
          {operator.includes('not') ? 'NOT ' : ''} {accessor}:{' '}
          {/* If the operator is `does not exist`, show `exists` instead */}
          {operator === 'does not exist' &&
            operator.replace('does not exist', 'exists').toUpperCase()}{' '}
          {/* If the operator is `exists`, show `does not exist` instead */}
          {operator !== 'does not exist' &&
            operator.includes('not') &&
            operator.replace('not', '').toUpperCase()}{' '}
          {/* If the operator is not `does not exist` or `exists`, show the operator */}
          {!operator.includes('not') && operator.toUpperCase()}{' '}
          <span className='ms-1 fw-semi-bold'>
            "
            {Array.isArray(value)
              ? value.map((item, i) => {
                  return (
                    <>
                      {item?.value}
                      {i !== value?.length - 1 ? ', ' : ''}
                    </>
                  );
                })
              : operator === 'does not exist' || operator === 'exists'
              ? ''
              : value}
            "
          </span>
          <FontAwesomeIcon icon='ellipsis-v' className='mx-2 me-0' />
        </Badge>
      </Dropdown.Toggle>

      <Dropdown.Menu
        rootCloseEvent='click'
        className='m-0 p-0 py-1 border border-card bg-card text-700 fs--2'>
        {/* If the filter is not a query string filter, show the following options */}
        {accessor !== 'query_string' && (
          <>
            <Dropdown.Item
              className='px-2 py-1 hover-pastel-green-dark'
              onClick={() => togglePinFilters(index)}>
              <FontAwesomeIcon
                icon={item.pinned ? 'thumbtack-slash' : 'thumbtack'}
                width={16}
                style={{
                  marginRight: '5px'
                }}
              />
              {item.pinned ? 'Unpin' : 'Pin across all dashboards'}
            </Dropdown.Item>
            <Dropdown.Item
              className='px-2 py-1 hover-pastel-green-dark'
              onClick={() => {
                setFilters(prevState => ({
                  ...prevState,
                  editFilterModal: {
                    open: true,
                    data: { accessor, value, operator, index }
                  }
                }));
              }}>
              <FontAwesomeIcon
                icon='pencil-alt'
                width={16}
                style={{
                  marginRight: '5px'
                }}
              />
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              className='px-2 py-1 hover-pastel-green-dark'
              onClick={() => toggleIncludeExcludeResults(index)}>
              <FontAwesomeIcon
                icon={operator.includes('not') ? 'circle-plus' : 'circle-minus'}
                width={16}
                style={{
                  marginRight: '5px'
                }}
              />
              {operator.includes('not') ? 'Include' : 'Exclude'} results
            </Dropdown.Item>
            <Dropdown.Item
              className='px-2 py-1 hover-pastel-green-dark'
              onClick={() => tempDisable(index)}>
              <FontAwesomeIcon
                icon={item?.tempDisable ? 'eye' : 'eye-slash'}
                width={16}
                style={{
                  marginRight: '5px'
                }}
              />
              {item?.tempDisable ? 'Re-enable' : 'Temporarily disable'}
            </Dropdown.Item>
          </>
        )}
        <Dropdown.Item
          className='px-2 py-1 hover-pastel-green-dark'
          onClick={() => {
            if (accessor !== 'query_string') {
              removeFilter(index);
            } else {
              setESGlobalFilters(prevState => ({
                ...prevState,
                querySearchText: '',
                query_string: null,
                update: true
              }));
              updateGlobalFilters({
                searchtext: ''
              });
            }
          }}>
          <FontAwesomeIcon
            icon='trash-alt'
            width={16}
            style={{
              marginRight: '5px'
            }}
          />
          Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

FilterBadge.propTypes = {
  operator: PropTypes.string,
  accessor: PropTypes.string,
  value: PropTypes.any,
  removeFilter: PropTypes.func,
  index: PropTypes.number,
  setESGlobalFilters: PropTypes.func,
  tempDisable: PropTypes.func,
  item: PropTypes.object,
  toggleIncludeExcludeResults: PropTypes.func,
  togglePinFilters: PropTypes.func
};

export default FilterBadge;
