import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import LoadingIndicatorOverlay from 'components/visualizations/LoadingIndicatorOverlay';
import { useSearchFilters } from 'context/FiltersProvider';
import useApplication from 'hooks/useApplication';
import useExplore from 'hooks/useExplore';
// import useUnsavedChanges from 'hooks/useUnsavedChanges';
import useAuthentication from 'hooks/useAuthentication';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap';
import FieldSelect from './FieldSelect';
import OperatorSelect from './OperatorSelect';
import TermSelect from './TermSelect';
import Tips from './Tips';

/**
 * The EditFilterModal component.
 *
 * This component renders a modal for adding a search filter.
 * It provides options for selecting a field, operator, and search term.
 * The user can also choose to use a custom label for the filter.
 *
 * @returns {JSX.Element} The rendered EditFilterModal component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const EditFilterModal = () => {
  const fieldRef = useRef(null);
  const modalRef = useRef(null);
  const operatorFieldRef = useRef(null);
  const termFieldRef = useRef(null);
  const applyButtonRef = useRef(null);

  const {
    application: { isDark }
  } = useApplication();

  const {
    authentication: { scopesSelected }
  } = useAuthentication();

  // const { setHasUnsavedChanges, promptOnLeave } = useUnsavedChanges();

  const { state, setState, fetchSuggestions, makeRequest, buildSearchQuery } =
    useExplore();

  const {
    searchFilters: { filters, modalFilterEdit, operators },
    setSearchFilters
  } = useSearchFilters();

  const [loading, setLoading] = useState(true);
  const [fieldsList, setFieldsList] = useState([]);
  const [operatorsList, setOperatorsList] = useState([]);
  const [field, setField] = useState(null);
  const [operator, setOperator] = useState(null);
  const [termSelected, setTermSelected] = useState(null);
  const [termsLoading, setTermsLoading] = useState(false);
  const [termOptions, setTermOptions] = useState([]);
  const [term, setTerm] = useState(null);
  const [pinned, setPinned] = useState(false);
  const [useCustomLabel, setUseCustomLabel] = useState(false);
  const [customLabel, setCustomLabel] = useState('');

  /**
   * Resets the state of the EditFilterModal component.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleReset = () => {
    setState('excludeFieldFilters', null);
    setField(null);
    setOperator(null);
    setTerm(null);
    setTermSelected(null);
    setPinned(false);
    setCustomLabel('');
    setSearchFilters(prev => ({
      ...prev,
      modalFilterEdit: {
        ...prev.modalFilterEdit,
        data: {
          ...prev.modalFilterEdit.data,
          ...prev.modalFilterEdit.defaultData
        },
        open: false
      }
    }));
  };

  /**
   * Handles the cancel action for the EditFilterModal component.
   *
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleCancel = () => {
    // Reset the modal state and close the modal after prompting for unsaved changes
    // promptOnLeave(() => {
    handleReset();
    // });
  };

  /**
   * Handles the change event for the field input in the EditFilterModal component.
   *
   * @param {object} value - The selected field object
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleFieldChange = value => {
    if (!value) {
      setField(null);
      setTermOptions([]);
      setOperator(null);
      return;
    }

    // Set the local state's field value
    setField(value);
    setTermOptions([]);
    setOperator(null);

    // Set the search filters state's field value
    setSearchFilters(prev => ({
      ...prev,
      modalFilterEdit: {
        ...prev.modalFilterEdit,
        data: {
          ...prev.modalFilterEdit.data,
          field: state.fields.find(field => field._id === value.value)
        }
      }
    }));
  };

  /**
   * Updates the operator value in the search filters.
   *
   * @param {object} value - The selected operator object
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleOperatorChange = value => {
    // If the value is not set, clear the operator value and return early
    if (!value) {
      setOperator(null);
      setSearchFilters(prev => ({
        ...prev,
        modalFilterEdit: {
          ...prev.modalFilterEdit,
          data: {
            ...prev.modalFilterEdit.data,
            operator: null
          }
        }
      }));
      return;
    }

    // Set the local state's operator value
    setOperator(value);

    // Set the search filters state's operator value
    setSearchFilters(prev => ({
      ...prev,
      modalFilterEdit: {
        ...prev.modalFilterEdit,
        data: {
          ...prev.modalFilterEdit.data,
          operator: value.value
        }
      }
    }));

    // Focus on the term input if the operator requires a value
    if (!operators[value.value]?.requiresValue) {
      applyButtonRef.current.focus();
    }
  };

  /**
   * Handles the change event for the term input in the EditFilterModal component.
   *
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleTermChange = value => {
    // Ensure value is correctly parsed as required for storage or further logic.
    const valueForState = Array.isArray(value)
      ? value.map(v => v.value)
      : value?.value;

    setTermSelected(value);
    setTerm(value);

    setSearchFilters(prev => ({
      ...prev,
      modalFilterEdit: {
        ...prev.modalFilterEdit,
        data: {
          ...prev.modalFilterEdit.data,
          value: valueForState
        }
      }
    }));

    applyButtonRef.current.disabled = !value;
  };

  /**
   * Handles the toggle event for the pinnedFilter checkbox.
   *
   * @param {Event} e - The change event object
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleTogglePinned = e => {
    // Set the local state's pinned value
    setPinned(e.target.checked);

    // Set the search filters state's pinned value
    setSearchFilters(prev => ({
      ...prev,
      modalFilterEdit: {
        ...prev.modalFilterEdit,
        data: {
          ...prev.modalFilterEdit.data,
          pinned: e.target.checked
        }
      }
    }));
  };

  /**
   * Handles the toggle event for the useCustomLabel checkbox.
   *
   * @param {Event} e - The change event object
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleToggleUseCustomLabel = e => {
    // Set the local state's useCustomLabel value
    setUseCustomLabel(e.target.checked);

    // Set the search filters state's useCustomLabel value
    setSearchFilters(prev => ({
      ...prev,
      modalFilterEdit: {
        ...prev.modalFilterEdit,
        data: {
          ...prev.modalFilterEdit.data,
          customLabel: '',
          useCustomLabel: e.target.checked
        }
      }
    }));
  };

  /**
   * Retrieves field options based on a supplied value.
   *
   * @param {string} value - The value to match against field names
   * @returns {Array} - An array of field options in the required format for the Select component
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleGetFieldOptions = async value => {
    // Create a regular expression pattern to match the supplied value
    const pattern = new RegExp(value, 'i');

    // Filter the fields based on the pattern
    const filteredFields = state.fields.filter(field =>
      pattern.test(field.name)
    );

    // Map the filtered fields to the required format for the Select component
    const fieldOptions = filteredFields.map(field => ({
      label: field.name,
      value: field._id
    }));

    // Return the field options
    return fieldOptions;
  };

  /**
   * Retrieves operator options based on a supplied value.
   *
   * @param {string} value - The value to match against operator labels
   * @returns {Array<{label: string, value: string}>} - The operators in the required format for the Select component
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleGetOperatorOptions = async value => {
    // Create a regular expression pattern to match the supplied value
    const pattern = new RegExp(value, 'i');

    // Filter the operators based on the pattern
    const filteredOperators = operatorsList.filter(operator =>
      pattern.test(operator.label)
    );

    // Map the filtered operators to the required format for the Select component
    const operatorOptions = filteredOperators.map(operator => ({
      label: operator.label,
      value: operator._id
    }));

    // Return the operator options
    return operatorOptions;
  };

  /**
   * Retrieves term suggestions based on the provided value.
   *
   * @param {string} value - The value to filter the suggestions
   * @returns {Promise<Array>} - A promise that resolves to an array of suggestions
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleGetTermOptions = async value => {
    if (value === null) return [];
    // Get the current field from the modalFilterEdit data
    const suggestField = modalFilterEdit.data.field;

    // Define our base query
    const updatedQuery = buildSearchQuery(state, scopesSelected);

    const query = {
      ...updatedQuery.query,
      bool: {
        ...updatedQuery.query.bool,
        filter: [
          ...updatedQuery.query.bool.filter,
          {
            wildcard: {
              [suggestField.type === 'string'
                ? `${suggestField.name}.keyword`
                : suggestField.name]: {
                value: `*${value}*`
              }
            }
          }
        ]
      }
    };

    // Fetch the suggestions based on the updated query
    const suggestions = await fetchSuggestions(
      suggestField.type === 'string'
        ? `${suggestField.name}.keyword`
        : suggestField.name,
      state.indexSelected?.pattern || state.indexSelected.alias,
      query,
      makeRequest
    );

    // Return the suggestions
    // These are already in the correct format for the Select component from the API
    return suggestions;
  };

  /**
   * Handles the addition of a new filter to the search filters state.
   *
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleSaveFilter = () => {
    // Add the new filter to the search filters
    try {
      const formData = {
        ...modalFilterEdit.data
      };
      // Update the filter with the new data
      const newFilters = filters.map(filter => {
        if (filter.id === formData.id) {
          return {
            ...filter,
            ...formData
          };
        }
        return filter;
      });
      setSearchFilters(prev => ({
        ...prev,
        filters: newFilters
      }));
    } catch (error) {
      console.error(error);
      // If there is an error, return early
      // TODO: Add error handling or additional logic here
      return;
    }

    // Reset the term and custom label values
    handleReset();

    // Reset the modal filter add data and close the modal
    setSearchFilters(prev => ({
      ...prev,
      modalFilterEdit: {
        ...prev.modalFilterEdit,
        open: false
      }
    }));
  };

  /**
   * Gets the compatible operators for the field type when the field changes.
   * Sets the operator to the first compatible operator.
   *
   * This is done when the modal is opened or the field changes.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    if (!field || !state.fields || state.fields.length === 0) return;

    const selectedField = state.fields.find(f => f._id === field.value);
    // Get the compatible operators for the field type
    let compatibleOperators = [];
    if (selectedField) {
      // If the selectedField is defined, get the compatible operators
      compatibleOperators = Object.keys(operators)
        .map(operatorKey => {
          if (
            operators[operatorKey].isCompatibleWithType(selectedField?.type)
          ) {
            return { label: operators[operatorKey].label, value: operatorKey };
          }
          return null;
        })
        .filter(operator => operator !== null); // Remove null values
    } else {
      // If the field is not defined, get all operators
      compatibleOperators = Object.keys(operators).map(operatorKey => {
        return { label: operators[operatorKey].label, value: operatorKey };
      });
    }
    //Set the compatible operators list
    setOperatorsList(compatibleOperators);
  }, [field, operators, modalFilterEdit.open]);

  /**
   * Sets the initial field value to the first field in the list.
   *
   * This is done when the modal is opened or the fields list changes.
   * If the field is already defined, this will not change the field value.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    // If there are no fields, or the modal is not open, return early
    if (!state.fields || state.fields.length === 0) return;

    // Set the fields list options when the fields are available.
    const fieldsList = state.fields.map(field => {
      return { label: field.name, value: field._id };
    });
    setFieldsList(fieldsList);
  }, [state.fields, modalFilterEdit.open]);

  useEffect(() => {
    // When the field changes, update the term options
    if (!loading) {
      setTermsLoading(true);
      const suggestField = modalFilterEdit.data.field;
      const updatedQuery = buildSearchQuery(state, scopesSelected);

      const query = {
        ...updatedQuery.query,
        bool: {
          ...updatedQuery.query.bool,
          filter: [
            ...updatedQuery.query.bool.filter,
            {
              wildcard: {
                [suggestField.type === 'string'
                  ? `${suggestField.name}.keyword`
                  : suggestField.name]: {
                  value: `*${term}*`
                }
              }
            }
          ]
        }
      };

      fetchSuggestions(
        suggestField.type === 'string'
          ? `${suggestField.name}.keyword`
          : suggestField.name,
        state.indexSelected?.pattern || state.indexSelected.alias,
        query,
        makeRequest
      ).then(suggestions => {
        setTermOptions(suggestions);
        setTermsLoading(false);
      });
    }
  }, [field]);

  /**
   * Sets the custom label value in the search filters when the custom label changes.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    setSearchFilters(prev => ({
      ...prev,
      modalFilterEdit: {
        ...prev.modalFilterEdit,
        data: {
          ...prev.modalFilterEdit.data,
          customLabel
        }
      }
    }));
  }, [customLabel]);

  /**
   * Determines if there are unsaved changes when the term or custom label changes.
   *
   * Sets the hasUnsavedChanges state accordingly.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  // useEffect(() => {
  //   if (modalFilterEdit.data?.value || modalFilterEdit.data?.customLabel) {
  //     setHasUnsavedChanges(true);
  //   } else {
  //     setHasUnsavedChanges(false);
  //   }
  // }, [modalFilterEdit.data?.value, modalFilterEdit.data?.customLabel]);

  useEffect(() => {
    if (state.fields && state.fields.length > 0) {
      setLoading(false);
    } else if (operators && Object.keys(operators).length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [state.fields, operators]);

  /**
   * Clear all data when indexSelected changes.
   */
  useEffect(() => {
    handleReset();
  }, [state.indexSelected]);

  useEffect(() => {
    if (modalFilterEdit.open) {
      if (modalFilterEdit.data?.field?.accessor) {
        setState('excludeFieldFilters', modalFilterEdit.data.field.accessor);
      }
      if (modalFilterEdit.data.field) {
        setField({
          label: modalFilterEdit.data.field.name,
          value: modalFilterEdit.data.field._id
        });
      }

      if (modalFilterEdit.data.operator) {
        setOperator({
          label: operators[modalFilterEdit.data.operator].label,
          value: modalFilterEdit.data.operator
        });
      }

      if (modalFilterEdit.data.value) {
        const value = modalFilterEdit.data.value;
        const termValue = Array.isArray(value)
          ? value.map(val => ({ label: val, value: val }))
          : { label: value, value: value };

        setTerm(termValue);
        setTermSelected(termValue);
      }
    }
  }, [modalFilterEdit.open, modalFilterEdit.data, operators]);

  /**
   * Update the filter pinned value when the modalFilterEdit data changes.
   */
  useEffect(() => {
    if (modalFilterEdit.open && modalFilterEdit.data.pinned) {
      setPinned(modalFilterEdit.data.pinned);
    }
  }, [modalFilterEdit.open, modalFilterEdit.data.pinned]);

  /**
   * Update the useCustomLabel and customLabel values when the modalFilterEdit data changes.
   */
  useEffect(() => {
    if (modalFilterEdit.open && modalFilterEdit.data.useCustomLabel) {
      setUseCustomLabel(modalFilterEdit.data.useCustomLabel);
      setCustomLabel(modalFilterEdit.data.customLabel);
    }
  }, [
    modalFilterEdit.open,
    modalFilterEdit.data.useCustomLabel,
    modalFilterEdit.data.customLabel
  ]);

  return (
    <Modal
      aria-labelledby='searchFiltersModalAddNewFilter'
      centered
      contentClassName='shadow-none'
      keyboard={true}
      onHide={handleCancel}
      ref={modalRef}
      show={modalFilterEdit.open}
      size='lg'>
      <Flex
        className='card border border-card shadow-none'
        direction='column'
        justifyContent='between'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex mb-1 fs-0 fw-normal position-relative align-items-center'>
            Edit filter{' '}
            <AdvancedPopover
              containerId='searchFiltersModalAddNewFilter'
              placement='top'
              popoverText='Add a new filter to the search.'
              showArrow={true}>
              <FontAwesomeIcon
                className='ms-1 fs--1 cursor-pointer text-400'
                icon={['far', 'question-circle']}
              />
            </AdvancedPopover>
          </h5>
        </Modal.Header>

        <Modal.Body className='p-2 position-relative'>
          <LoadingIndicatorOverlay loading={loading || state.fieldsLoading} />
          <Row className='g-2 mb-2'>
            <Col sm={field ? 9 : 12}>
              <FormGroup>
                <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
                  Field{' '}
                  <AdvancedPopover
                    containerId='searchFiltersModalAddNewFilter'
                    placement='top'
                    popoverText='Select the field to use for the filter.'
                    showArrow={true}>
                    <FontAwesomeIcon
                      className='ms-1 text-400 fs--1 cursor-pointer'
                      icon={['far', 'question-circle']}
                    />
                  </AdvancedPopover>
                </Form.Label>
                <FieldSelect
                  autoFocus={false}
                  onChange={handleFieldChange}
                  getOptions={handleGetFieldOptions}
                  options={fieldsList}
                  totalOptions={state.fields.length}
                  ref={fieldRef}
                  size='sm'
                  value={field}
                />
              </FormGroup>
            </Col>

            {field && (
              <Col sm={3}>
                <FormGroup>
                  <Form.Label
                    className={'d-flex fs--1 fw-normal align-items-center'}>
                    Operator{' '}
                    <AdvancedPopover
                      containerId='searchFiltersModalAddNewFilter'
                      placement='top'
                      popoverText='Choose an operator to specify how the filter should behave.'
                      showArrow={true}>
                      <FontAwesomeIcon
                        className='ms-1 text-400 fs--1 cursor-pointer'
                        icon={['far', 'question-circle']}
                      />
                    </AdvancedPopover>
                  </Form.Label>
                  <OperatorSelect
                    autoFocus={false}
                    onChange={handleOperatorChange}
                    getOptions={handleGetOperatorOptions}
                    options={operatorsList}
                    ref={operatorFieldRef}
                    size='sm'
                    value={operator}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            <Col>
              {field &&
                operator &&
                operators[operator.value]?.requiresValue && (
                  <Row className='mb-3'>
                    <Col>
                      <FormGroup>
                        <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
                          Search Term{' '}
                          <AdvancedPopover
                            containerId='searchFiltersModalAddNewFilter'
                            placement='top'
                            popoverText='Define the search term for the new filter using the input field below.'
                            showArrow={true}>
                            <FontAwesomeIcon
                              className='ms-1 text-400 fs--1 cursor-pointer'
                              icon={['far', 'question-circle']}
                            />
                          </AdvancedPopover>
                        </Form.Label>
                        <TermSelect
                          autoFocus={false}
                          ref={termFieldRef}
                          isMulti={operators[operator.value]?.isMulti}
                          isLoading={termsLoading}
                          onChange={handleTermChange}
                          options={termOptions}
                          getOptions={handleGetTermOptions}
                          value={termSelected}
                          className='fs--1'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}

              <Row className='mb-2'>
                <Col>
                  <FormGroup
                    className='my-0 fs--1 d-flex'
                    id='filterPinnedSwitchGroup'>
                    <Form.Check
                      checked={pinned}
                      color='pastel-green'
                      id='filterPinnedSwitch'
                      name='pinned'
                      onChange={handleTogglePinned}
                      type='switch'
                    />
                    <Form.Label
                      className='text-700 fs--1 d-flex align-items-center fw-normal mb-0'
                      htmlFor='filterPinnedSwitch'>
                      Pin this filter?{' '}
                      <AdvancedPopover
                        containerId='filterPinnedSwitchGroup'
                        placement='top'
                        popoverText='If set, the filter will be pinned to the top of the list and will persist when moving to another dashboard or index, as long as the target index has the same field.'>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>
                  </FormGroup>
                </Col>
              </Row>

              <Row className='mb-2'>
                <Col>
                  <FormGroup
                    className='my-0 fs--1 d-flex'
                    id='customLabelSwitchGroup'>
                    <Form.Check
                      checked={useCustomLabel}
                      color='pastel-green'
                      id='customLabelSwitch'
                      name='useCustomLabel'
                      onChange={handleToggleUseCustomLabel}
                      type='switch'
                    />
                    <Form.Label
                      className='text-700 fs--1 d-flex align-items-center fw-normal mb-0'
                      htmlFor='customLabelSwitch'>
                      Use a custom label for this filter?{' '}
                      <AdvancedPopover
                        containerId='customLabelSwitchGroup'
                        placement='top'
                        popoverText='If set, the filter will use a custom label instead of the field name.'>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>
                  </FormGroup>

                  {useCustomLabel && (
                    <FormGroup className='mt-2'>
                      <Form.Control
                        autoComplete='off'
                        className='fs--1 ps-2 py-2'
                        name='customLabel'
                        onChange={e => setCustomLabel(e.target.value)}
                        value={customLabel}
                      />
                    </FormGroup>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className='p-2'>
          <Row className='g-1 m-0 p-0 w-100'>
            <Col xs={12} lg={8} className='p-0 m-0'>
              <div className='text-400'>
                <Tips />
              </div>
            </Col>

            <Col xs={12} lg={4} className='p-0'>
              <Flex direction='row' justifyContent='end'>
                <Button
                  className='me-2'
                  onClick={handleCancel}
                  size='sm'
                  variant='secondary'>
                  Cancel
                </Button>
                <Button
                  ref={applyButtonRef}
                  onClick={handleSaveFilter}
                  size='sm'
                  variant='success'
                  disabled={operator?.requiresValue && !term}
                  className='btn-primary'>
                  Save changes
                </Button>
              </Flex>
            </Col>
          </Row>
        </Modal.Footer>
      </Flex>
    </Modal>
  );
};

export default EditFilterModal;
