import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

const google = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'Google Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-google'],
        queryType: ['previousCount', 'count', 'sparkline']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Events',
          helpInfo: 'Total number of Google events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-google'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'google_workspace.event.type.keyword': 'acl_change'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'ACL Changes',
          helpInfo: 'Total number of Google events indicating changes to ACLs',
          showFilterIcon: true,
          filterData: {
            field: 'google_workspace.event.type',
            value: 'acl_change'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-google'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'event.action.keyword': 'view'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'event.action',
            value: 'view'
          },
          title: 'File Views',
          helpInfo: 'Total number of Google events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-google'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'event.action.keyword': 'download'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'File Downloads',
          showFilterIcon: true,
          filterData: {
            field: 'event.action',
            value: 'download'
          },
          helpInfo: 'Total number of Google events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-google'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'event.action.keyword': 'login_failure'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'event.action',
            value: 'login_failure'
          },
          title: 'Failed Logins',
          helpInfo:
            'Total number of Google events indicating authentication failure'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-google'],
        queryType: ['histogram']
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Event Timeline',
          helpInfo:
            'Timeline histogram of all Google events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-google'],
        queryType: ['map'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'source_geoip.country_name.keyword',
                order: {
                  _count: 'desc'
                },
                exclude: [''],
                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Map',
        props: {
          className: '',
          field: 'source_geoip.country_name',
          title: 'Event Source Countries',
          helpInfo: 'Source countries of Google events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-google'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'event.action.keyword',
                order: {
                  _count: 'desc'
                },
                size: 10
                // missing: '-'
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_all: {}
          }
        ],
        filter: [
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    'event.action.keyword': 'view'
                  }
                },
                {
                  match_phrase: {
                    'event.action.keyword': 'download'
                  }
                },
                {
                  match_phrase: {
                    'event.action.keyword': 'delete'
                  }
                },
                {
                  match_phrase: {
                    'event.action.keyword': 'edit'
                  }
                },
                {
                  match_phrase: {
                    'event.action.keyword': 'create'
                  }
                }
              ]
              // minimum_should_match: 1
            }
          }
        ]
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'File Operations',
          helpInfo: 'Total number of Google events by file operation',
          radius: ['100%', '60%'],
          field: 'event.action'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-google'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'event.provider.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Event Providers',
          field: 'event.provider',
          helpInfo: 'Providers from which Google events are being reported'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-google'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'event.type.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Event Types',
          field: 'event.type'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-google'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'event.category.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Event Categories',
          field: 'event.category'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-google'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'source_ip.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'source_geoip.country_name.keyword',
                    order: {
                      _count: 'desc'
                    },

                    size: 50
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Event Sources',
            overlayText: 'Event sources by location and event count',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-google'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'user.name.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'user.domain.keyword',
                    order: {
                      _count: 'desc'
                    },

                    size: 50
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: ' Users Actions',
            overlayText: 'Actions by user and domain',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-google'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'user.target.name.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'user.target.domain.keyword',
                    order: {
                      _count: 'desc'
                    },

                    size: 50
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Target Users',
            overlayText: 'User accounts targeted by actions',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-google'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'related.user.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'user.target.domain.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 50
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_phrase: {
              'event.action.keyword': 'CREATE_USER'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'New Users',
            overlayText: 'New users created in Google Workspace',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xxl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-google'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'user.name.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field:
                      'google_workspace.admin.INVESTIGATION_ACTION.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 50
                  },
                  aggs: {
                    4: {
                      terms: {
                        field:
                          'google_workspace.admin.INVESTIGATION_ENTITY_IDS.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 50
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Investigations',
            overlayText: 'Investigations by user, action, and entity',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xxl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-google'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'file.owner.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'file.name.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 50
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'google_workspace.drive.visibility.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 50
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [
          {
            exists: {
              field: 'google_workspace.drive.visibility'
            }
          }
        ],
        must_not: [
          {
            match_phrase: {
              'google_workspace.drive.visibility': 'shared_internally'
            }
          },
          {
            match_phrase: {
              'google_workspace.drive.visibility':
                'people_within_domain_with_link'
            }
          },
          {
            match_phrase: {
              'google_workspace.drive.visibility': 'private'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'External Drive File Shares',
            overlayText: 'External file shares from Drive provider',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xxl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-google'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'user.name.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field:
                      'google_workspace.login.sensitive_action_name.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 50
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'source_geoip.ip.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 50
                      },
                      aggs: {
                        5: {
                          terms: {
                            field: 'user.domain.keyword',
                            order: {
                              _count: 'desc'
                            },
                            size: 50
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_phrase: {
              'event.action.keyword': 'risky_sensitive_action_allowed'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Risky Actions',
            overlayText: 'Risky actions allowed by users',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xxl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-google'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'google_workspace.drive.target.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'file.name.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 50
                  },
                  aggs: {
                    4: {
                      terms: {
                        field:
                          'google_workspace.drive.file.owner.email.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 50
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_phrase: {
              'event.action.keyword': 'request_access'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Access Requests',
            overlayText: 'Access requests made to Google Drive files',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-google'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'user.name.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'google_workspace.admin.application.name.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 50
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'event.type.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 5
                      },
                      aggs: {
                        5: {
                          terms: {
                            field: 'user.domain.keyword',
                            order: {
                              _count: 'desc'
                            },
                            size: 50
                          },
                          aggs: {
                            6: {
                              terms: {
                                field:
                                  'google_workspace.admin.setting.name.keyword',
                                order: {
                                  _count: 'desc'
                                },
                                size: 50
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_phrase: {
              'event.action.keyword': 'CREATE_APPLICATION_SETTING'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Administrative Changes',
            overlayText: 'Administrative changes made by users',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-google'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'event.action.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'google_workspace.admin.user.email.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 50
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_phrase: {
              'google_workspace.event.type.keyword': 'USER_SETTINGS'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'User Setting Changes',
            overlayText: 'Changes to user settings in Google Workspace',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-google'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'google_workspace.drive.accessed_url.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'user.name.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Accessed URLs',
            overlayText: 'Changes to user settings in Google Workspace',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    }
  ];
};

export default google;
