import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import CreatableMultiselect from 'components/common/CreatableMultiselect';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useOrganizations from 'hooks/admin-contexts/useOrganizations';
import useUsers from 'hooks/admin-contexts/useUsers';
import useAxiosPrivate from 'hooks/useAxiosPrivate';

import AdvancedPopover from 'components/common/Popover';
import React, { useEffect, useRef, useState } from 'react';

import {
  Button,
  Col,
  Form,
  FormText,
  Modal,
  Row,
  Tab,
  Tabs
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

/**
 * Edit org form
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const EditOrganizationModal = () => {
  const {
    setOrganizations,
    organizations: {
      data: organizations,
      orgEditModal: { data, formData }
    }
  } = useOrganizations();
  const { axiosPrivate } = useAxiosPrivate();
  const {
    users: { data: usersFromContext }
  } = useUsers();

  const [loading, setLoading] = useState(false);
  const [selfControlData, setSelfControlData] = useState({
    siteIds: []
  });

  const {
    handleSubmit,
    control,
    reset,
    register,
    watch,
    formState: { errors }
  } = useForm();

  /**
   *
   * setting default data
   */
  useEffect(() => {
    if (data) {
      reset({
        name: data?.name,
        active: data?.active,
        sentinelOneEnabled:
          data?.legacyProducts?.sentinelOne?.active === 'true' ? true : false,
        sentinelOneAccountRoleId: data?.legacyProducts?.sentinelOne?.roleId,
        sentinelOneSiteIds: data?.legacyProducts?.sentinelOne?.siteIds,
        sentinelOneManagementDomain:
          data?.legacyProducts?.sentinelOne?.apiSubdomain,
        sentinelOneApiVersion: data?.legacyProducts?.sentinelOne?.apiVersion,
        sentinelOneAccountType: data?.legacyProducts?.sentinelOne?.apiType,
        sentinelOneApiToken: data?.legacyProducts?.sentinelOne?.apiToken,
        website: data?.profile?.website,
        bio: data?.profile?.bio,
        facebook: data?.profile?.social?.facebook,
        github: data?.profile?.social?.github,
        instagram: data?.profile?.social?.instagram,
        linkedin: data?.profile?.social?.linkedin,
        twitter: data?.profile?.social?.twitter,
        youtube: data?.profile?.social?.youtube,
        address: data?.address?.address,
        extended: data?.address?.extended,
        city: data?.address?.city,
        state: data?.address?.state,
        zip: data?.address?.zip,
        country: data?.address?.country,
        primaryContactName: data?.contacts?.primaryContactName,
        primaryContactTelephone: data?.contacts?.primaryContactTelephone,
        secondaryContactName: data?.contacts?.secondaryContactName,
        secondaryContactTelephone: data?.contacts?.secondaryContactTelephone,
        emergencyContactName: data?.contacts?.emergencyContactName,
        emergencyContactTelephone: data?.contacts?.emergencyContactTelephone,
        tag: data?.tag,
        users: data?.users?.map(item => ({
          value: item?.id,
          label: item?.profile?.fullName
        })),
        alertRecipients: data?.alertRecipients?.map(item => ({
          value: item,
          label: item
        })),
        reportRecipients: data?.reportRecipients?.map(item => ({
          value: item,
          label: item
        })),
        primaryOwner:
          data?.primaryOwner?.id && data.primaryOwner?.profile?.fullName
            ? {
                value: data?.primaryOwner?.id,
                label: data?.primaryOwner?.profile?.fullName
              }
            : '',
        owners: data?.owners?.map(item => ({
          value: item?.id,
          label: item?.profile?.fullName
        })),
        authorizedDomains: data?.authorizedDomains?.map(item => ({
          value: item,
          label: item
        })),
        parent:
          data?.parent?.id && data?.parent?.name
            ? { value: data?.parent?.id, label: data?.parent?.name }
            : ''
      });

      setSelfControlData(prevState => ({
        ...prevState,
        siteIds:
          data?.legacyProducts?.sentinelOne?.siteIds?.map(id => ({
            value: id,
            label: id
          })) || []
      }));
    }
  }, [data]);

  const createSiteIds = async param => {
    let { siteIds } = selfControlData;
    let newSiteIds = [...siteIds] || [];
    newSiteIds.push({
      value: param,
      label: param
    });

    setSelfControlData(prevState => ({
      ...prevState,
      siteIds: newSiteIds
    }));
  };

  const handleSiteIdsChange = newSiteIds => {
    if (newSiteIds?.length > 0) {
      setSelfControlData(prevState => ({
        ...prevState,
        siteIds: newSiteIds
      }));
    } else {
      setSelfControlData(prevState => ({
        ...prevState,
        siteIds: []
      }));
    }
  };

  /**
   * Submitting edited data to edit org endpoint
   * @param {Object} formData
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const onSubmit = async formData => {
    try {
      setLoading(true);
      let {
        owners,
        users,
        primaryOwner,
        parent,
        authorizedDomains,
        alertRecipients,
        reportRecipients
      } = formData;
      let newData = formData;
      newData['owners'] = owners?.map(item => item.value);
      newData['users'] = users?.map(item => item.value);
      newData['users'] = users?.map(item => item.value);
      newData['sentinelOneSiteIds'] = [
        ...selfControlData.siteIds.map(siteId => siteId?.value)
      ];
      newData['alertRecipients'] = alertRecipients?.map(item => item.value);
      newData['reportRecipients'] = reportRecipients?.map(item => item.value);
      newData['primaryOwner'] = primaryOwner?.value || '';
      newData['parent'] = parent?.value || '';
      newData['authorizedDomains'] = authorizedDomains?.map(item => item.value);
      const res = await LeargasAPI.Organizations.editOrg(
        data?._id,
        newData,
        axiosPrivate
      );
      let message;
      if (res instanceof Error) throw res;
      message = res?.data?.message;
      setLoading(false);
      setOrganizations(prevState => ({
        ...prevState,
        orgEditModal: { open: false },
        fetch: true
      }));
      toast.success(message);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);
      setOrganizations(prevState => ({
        ...prevState,
        orgEditModal: { open: false },
        fetch: false
      }));
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  // const handleProfilePhotoChange = event => {
  //   const file = event.target.files[0];
  //   // Do something with the file, such as uploading it to a server
  // };

  /**
   *  Watching users inputs change
   */
  let users = watch('users', []);

  /**
   * Watching sentinelOne input fields
   */
  const {
    sentinelOneManagementDomain,
    sentinelOneApiVersion,
    sentinelOneAccountType,
    sentinelOneApiToken
  } = watch();

  const sentinelOneEnabledRef = useRef();

  const handleEnabledCheckboxChange = () => {
    if (
      !sentinelOneManagementDomain ||
      !sentinelOneApiVersion ||
      !sentinelOneAccountType ||
      !sentinelOneApiToken
    ) {
      if (sentinelOneEnabledRef.current)
        sentinelOneEnabledRef.current.checked = false;
    } else {
      if (sentinelOneEnabledRef.current)
        sentinelOneEnabledRef.current.checked = true;
    }
  };

  useEffect(() => {
    handleEnabledCheckboxChange();
  }, [
    sentinelOneManagementDomain,
    sentinelOneApiVersion,
    sentinelOneAccountType,
    sentinelOneApiToken
  ]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Tabs defaultActiveKey='identity' id='uncontrolled-tab-example'>
        <Tab
          eventKey='identity'
          title='Identity'
          className='border-none p-3'
          tabClassName='fw-normal'>
          <Row className='g-3 mb-3'>
            <Col sm={12}>
              <Form.Group className='required'>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Name{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText=' The full name of the organization as it appears on official
                documents. This will be used for reporting and billing purposes.
            '
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  placeholder={'Alphabet, Inc.'}
                  {...register('name', { required: 'Name is required' })}
                  type='text'
                  size='sm'
                  autoComplete='off'
                  className={`fs--1 ${errors.name ? 'is-invalid' : ''}`}
                />
                {errors.name && (
                  <FormText className='text-danger'>
                    {errors.name.message}
                  </FormText>
                )}
              </Form.Group>
              {/* <FormText className='text-muted'>
                The full name of the organization as it appears on official
                documents. This will be used for reporting and billing purposes.
              </FormText> */}
            </Col>
            <Col sm={12}>
              <Form.Group as={Col} sm={6} className='mb-0 t'>
                <Flex>
                  <Form.Check
                    type='switch'
                    label='Active'
                    className='mb-0'
                    {...register('active')}
                  />
                </Flex>
              </Form.Group>
            </Col>
          </Row>
        </Tab>

        <Tab
          eventKey='integrations'
          title='Integrations'
          className='border-none p-3'
          tabClassName='fw-normal'>
          <Row className='g-3 mb-3'>
            <Col sm={12}>
              <h3 className='fs-0 mb-3'>SentinelOne</h3>

              <Form.Group className='mb-3'>
                <Flex>
                  <Form.Check
                    type='switch'
                    label='Enabled'
                    className='mb-0'
                    {...register('sentinelOneEnabled')}
                  />
                </Flex>
                <FormText className='text-muted'>
                  Toggle on/off to enable or disable SentinelOne data ingest for
                  this organization.
                </FormText>
              </Form.Group>

              <Row className='g-3'>
                <Col sm={6}>
                  <Form.Group className='required'>
                    <Form.Label className='d-flex fs--1 fw-normal'>
                      Management Domain{' '}
                      <AdvancedPopover
                        containerId='newOrgAddModal'
                        placement='top'
                        popoverText='Management Domain'
                        showArrow={true}>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>
                    <Form.Control
                      {...register('sentinelOneManagementDomain')}
                      type='text'
                      autoComplete='off'
                      className={`fs--1 ${
                        errors.sentinelOneManagementDomain ? 'is-invalid' : ''
                      }`}
                    />
                    {errors.sentinelOneManagementDomain && (
                      <FormText className='text-danger'>
                        {errors.sentinelOneManagementDomain.message}
                      </FormText>
                    )}
                  </Form.Group>
                </Col>

                <Col sm={2}>
                  <Form.Group className='required'>
                    <Form.Label className='d-flex fs--1 fw-normal'>
                      API Version{' '}
                      <AdvancedPopover
                        containerId='newOrgAddModal'
                        placement='top'
                        popoverText='API Version'
                        showArrow={true}>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>

                    <Form.Select
                      {...register('sentinelOneApiVersion')}
                      className={`fs--1 ${
                        errors.sentinelOneApiVersion ? 'is-invalid' : ''
                      }`}>
                      <option value='2.1'>2.1</option>
                    </Form.Select>
                    {errors.sentinelOneApiVersion && (
                      <FormText className='text-danger'>
                        {errors.sentinelOneApiVersion.message}
                      </FormText>
                    )}
                  </Form.Group>
                </Col>

                <Col sm={4}>
                  <Form.Group className='required'>
                    <Form.Label className='d-flex fs--1 fw-normal'>
                      Account Type{' '}
                      <AdvancedPopover
                        containerId='newOrgAddModal'
                        placement='top'
                        popoverText='Account Type'
                        showArrow={true}>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>

                    <Form.Select
                      {...register('sentinelOneAccountType')}
                      className={`fs--1 ${
                        errors.sentinelOneAccountType ? 'is-invalid' : ''
                      }`}>
                      <option>Please select</option>
                      <option disabled>----------</option>
                      <option value='service_user'>Service User</option>
                      <option value='user'>User</option>
                    </Form.Select>
                    {errors.sentinelOneAccountType && (
                      <FormText className='text-danger'>
                        {errors.sentinelOneAccountType.message}
                      </FormText>
                    )}
                  </Form.Group>
                </Col>

                {watch('sentinelOneAccountType') === 'service_user' && (
                  <>
                    <Col sm={6}>
                      <Form.Group className='required'>
                        <Form.Label className='d-flex fs--1 fw-normal'>
                          Service User Role ID{' '}
                          <AdvancedPopover
                            containerId='newOrgAddModal'
                            placement='top'
                            popoverText=' Service User Role ID'
                            showArrow={true}>
                            <FontAwesomeIcon
                              icon={['far', 'question-circle']}
                              className='ms-1 text-400 fs--1 cursor-pointer'
                            />
                          </AdvancedPopover>
                        </Form.Label>
                        <Form.Control
                          {...register('sentinelOneAccountRoleId')}
                          type='text'
                          autoComplete='off'
                          className={`fs--1 ${
                            errors.sentinelOneAccountRoleId ? 'is-invalid' : ''
                          }`}
                        />
                        <FormText className='text-muted'>
                          The SentinelOne Admin RoleID tied to the Service User.
                        </FormText>
                        {errors.sentinelOneAccountRoleId && (
                          <FormText className='text-danger'>
                            {errors.sentinelOneAccountRoleId.message}
                          </FormText>
                        )}
                      </Form.Group>
                    </Col>
                  </>
                )}

                <Col
                  sm={
                    watch('sentinelOneAccountType') === 'service_user' ? 6 : 12
                  }>
                  <Form.Group className='required'>
                    <Form.Label className='d-flex fs--1 fw-normal'>
                      SentinelOne Site IDs{' '}
                      <AdvancedPopover
                        containerId='newOrgAddModal'
                        placement='top'
                        popoverText=' The SentinelOne Site IDs that you want to gather events
                      from.'
                        showArrow={true}>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>
                    <CreatableMultiselect
                      isMulti
                      isClearable
                      isSearchable
                      placeholder='Please select'
                      onCreateOption={createSiteIds}
                      // {...register('sentinelOneSiteIds')}
                      options={selfControlData?.siteIds}
                      value={selfControlData?.siteIds}
                      onChange={handleSiteIdsChange}
                      className={`fs--1 `}
                    />
                    {/* <FormText className='text-muted'>
                      The SentinelOne Site IDs that you want to gather events
                      from.
                    </FormText> */}
                  </Form.Group>
                </Col>

                <Col sm={12}>
                  <Form.Group className='required'>
                    <Form.Label className='d-flex fs--1 fw-normal'>
                      API Token{' '}
                      <AdvancedPopover
                        containerId='newOrgAddModal'
                        placement='top'
                        popoverText=' The SentinelOne API Token that should be used to
                      authenticate with the API.'
                        showArrow={true}>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>
                    <Form.Control
                      {...register('sentinelOneApiToken')}
                      type='text'
                      autoComplete='off'
                      className={`fs--1 ${
                        errors.sentinelOneApiToken ? 'is-invalid' : ''
                      }`}
                    />
                    {/* <FormText className='text-muted'>
                      The SentinelOne API Token that should be used to
                      authenticate with the API.
                    </FormText> */}
                    {errors.sentinelOneApiToken && (
                      <FormText className='text-danger'>
                        {errors.sentinelOneApiToken.message}
                      </FormText>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Tab>

        <Tab
          eventKey='location'
          title='Location'
          className='border-none p-3'
          tabClassName='fw-normal'>
          <Row className='g-3 mb-3'>
            <Col sm={6}>
              <Row className='g-3'>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label className='d-flex fs--1 fw-normal'>
                      Address{' '}
                      <AdvancedPopover
                        containerId='newOrgAddModal'
                        placement='top'
                        popoverText='Your organization address'
                        showArrow={true}>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>
                    <Form.Control
                      placeholder={'1600 Amphitheatre Parkway'}
                      {...register('address')}
                      type='text'
                      size='sm'
                      autoComplete='off'
                      className={`fs--1 ${errors.address ? 'is-invalid' : ''}`}
                    />
                    {errors.address && (
                      <FormText className='text-danger'>
                        {errors.address.message}
                      </FormText>
                    )}
                  </Form.Group>
                </Col>

                <Col sm={12}>
                  <Form.Group>
                    <Form.Label className='d-flex fs--1 fw-normal'>
                      Address{' '}
                      <AdvancedPopover
                        containerId='newOrgAddModal'
                        placement='top'
                        popoverText='Address Extended'
                        showArrow={true}>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>
                    <Form.Control
                      placeholder={'Suite 100'}
                      {...register('extended')}
                      type='text'
                      size='sm'
                      autoComplete='off'
                      className={`fs--1 ${errors.extended ? 'is-invalid' : ''}`}
                    />
                    {errors.extended && (
                      <FormText className='text-danger'>
                        {errors.extended.message}
                      </FormText>
                    )}
                  </Form.Group>
                </Col>

                <Col sm={12}>
                  <Form.Group>
                    <Form.Label className='d-flex fs--1 fw-normal'>
                      City{' '}
                      <AdvancedPopover
                        containerId='newOrgAddModal'
                        placement='top'
                        popoverText='City'
                        showArrow={true}>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>
                    <Form.Control
                      placeholder={'Mountain View'}
                      {...register('city')}
                      type='text'
                      size='sm'
                      autoComplete='off'
                      className={`fs--1 ${errors.city ? 'is-invalid' : ''}`}
                    />
                    {errors.city && (
                      <FormText className='text-danger'>
                        {errors.city.message}
                      </FormText>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col sm={6}>
              <Row className='g-3'>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label className='d-flex fs--1 fw-normal'>
                      State{' '}
                      <AdvancedPopover
                        containerId='newOrgAddModal'
                        placement='top'
                        popoverText='State'
                        showArrow={true}>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>
                    <Form.Control
                      placeholder={'CA'}
                      {...register('state')}
                      type='text'
                      size='sm'
                      autoComplete='off'
                      className={`fs--1 ${errors.state ? 'is-invalid' : ''}`}
                    />
                    {errors.state && (
                      <FormText className='text-danger'>
                        {errors.state.message}
                      </FormText>
                    )}
                  </Form.Group>
                </Col>

                <Col sm={12}>
                  <Form.Group>
                    <Form.Label className='d-flex fs--1 fw-normal'>
                      Zip{' '}
                      <AdvancedPopover
                        containerId='newOrgAddModal'
                        placement='top'
                        popoverText='Zip code'
                        showArrow={true}>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>
                    <Form.Control
                      placeholder={'94043'}
                      {...register('zip')}
                      type='text'
                      size='sm'
                      autoComplete='off'
                      className={`fs--1 ${errors.zip ? 'is-invalid' : ''}`}
                    />
                    {errors.zip && (
                      <FormText className='text-danger'>
                        {errors.zip.message}
                      </FormText>
                    )}
                  </Form.Group>
                </Col>

                <Col sm={12}>
                  <Form.Group>
                    <Form.Label className='d-flex fs--1 fw-normal'>
                      Country{' '}
                      <AdvancedPopover
                        containerId='newOrgAddModal'
                        placement='top'
                        popoverText='Country'
                        showArrow={true}>
                        <FontAwesomeIcon
                          icon={['far', 'question-circle']}
                          className='ms-1 text-400 fs--1 cursor-pointer'
                        />
                      </AdvancedPopover>
                    </Form.Label>
                    <Form.Control
                      placeholder={'United States'}
                      {...register('country')}
                      type='text'
                      size='sm'
                      autoComplete='off'
                      className={`fs--1 ${errors.country ? 'is-invalid' : ''}`}
                    />
                    {errors.country && (
                      <FormText className='text-danger'>
                        {errors.country.message}
                      </FormText>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Tab>
        <Tab
          eventKey='contacts'
          title='Contacts'
          className='border-none p-3'
          tabClassName='fw-normal'>
          <h3 className='mb-0 mb-2 fs-0'>Contacts</h3>
          <Row className='g-3 mb-3'>
            <Col sm={6} className='mb-0'>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Primary Contact{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText='Primary contact number'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  placeholder={'Jane Doe'}
                  {...register('primaryContactName')}
                  type='text'
                  size='sm'
                  autoComplete='off'
                  className={`fs--1 ${
                    errors.primaryContactName ? 'is-invalid' : ''
                  }`}
                  onChange={e => {
                    setOrganizations(prevState => ({
                      ...prevState,
                      orgAddModal: {
                        ...prevState.orgAddModal,
                        formData: {
                          ...prevState.orgAddModal.formData,
                          primaryContactName: e.target.value
                        }
                      }
                    }));
                  }}
                />
                {errors.primaryContactName && (
                  <FormText className='text-danger'>
                    {errors.primaryContactName.message}
                  </FormText>
                )}
              </Form.Group>
            </Col>

            <Col sm={6} className='mb-0'>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Telephone{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText='Telephone number'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  placeholder={'+1 650-253-0000'}
                  {...register('primaryContactTelephone')}
                  type='text'
                  size='sm'
                  autoComplete='off'
                  className={`fs--1 ${
                    errors.primaryContactTelephone ? 'is-invalid' : ''
                  }`}
                  onChange={e => {
                    setOrganizations(prevState => ({
                      ...prevState,
                      orgAddModal: {
                        ...prevState.orgAddModal,
                        formData: {
                          ...prevState.orgAddModal.formData,
                          primaryContactTelephone: e.target.value
                        }
                      }
                    }));
                  }}
                />
                {errors.primaryContactTelephone && (
                  <FormText className='text-danger'>
                    {errors.primaryContactTelephone.message}
                  </FormText>
                )}
              </Form.Group>
            </Col>

            <Col sm={12} className='mb-0'>
              <Button
                variant='outline-primary'
                size='sm'
                className='me-2 py-0 px-2'
                style={{ lineHeight: '1.5' }}
                onClick={() => {
                  setOrganizations(prevState => ({
                    ...prevState,
                    orgAddModal: {
                      ...prevState.orgAddModal,
                      formData: {
                        ...prevState.orgAddModal.formData,
                        primaryContactName: formData?.secondaryContactName,
                        primaryContactTelephone:
                          formData?.secondaryContactTelephone
                      }
                    }
                  }));
                }}>
                Copy Secondary
              </Button>
              <Button
                variant='outline-primary'
                size='sm'
                className='py-0 px-2'
                style={{ lineHeight: '1.5' }}
                onClick={() => {
                  setOrganizations(prevState => ({
                    ...prevState,
                    orgAddModal: {
                      ...prevState.orgAddModal,
                      formData: {
                        ...prevState.orgAddModal.formData,
                        primaryContactName: formData?.emergencyContactName,
                        primaryContactTelephone:
                          formData?.emergencyContactTelephone
                      }
                    }
                  }));
                }}>
                Copy Emergency
              </Button>
            </Col>
          </Row>

          <Row className='g-3 mb-3'>
            <Col sm={6} className='mb-0'>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Secondary Contact{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText='Secondary contact number'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  placeholder={'Jane Doe'}
                  {...register('secondaryContactName')}
                  type='text'
                  size='sm'
                  autoComplete='off'
                  className={`fs--1 ${
                    errors.secondaryContactName ? 'is-invalid' : ''
                  }`}
                  onChange={e => {
                    setOrganizations(prevState => ({
                      ...prevState,
                      orgAddModal: {
                        ...prevState.orgAddModal,
                        formData: {
                          ...prevState.orgAddModal.formData,
                          secondaryContactName: e.target.value
                        }
                      }
                    }));
                  }}
                />
                {errors.secondaryContactName && (
                  <FormText className='text-danger'>
                    {errors.secondaryContactName.message}
                  </FormText>
                )}
              </Form.Group>
            </Col>

            <Col sm={6} className='mb-0'>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Telephone{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText=' Secondary Telephone number'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  placeholder={'+1 650-253-0000'}
                  {...register('secondaryContactTelephone')}
                  type='text'
                  size='sm'
                  autoComplete='off'
                  className={`fs--1 ${
                    errors.secondaryContactTelephone ? 'is-invalid' : ''
                  }`}
                  onChange={e => {
                    setOrganizations(prevState => ({
                      ...prevState,
                      orgAddModal: {
                        ...prevState.orgAddModal,
                        formData: {
                          ...prevState.orgAddModal.formData,
                          secondaryContactTelephone: e.target.value
                        }
                      }
                    }));
                  }}
                />
                {errors.secondaryContactTelephone && (
                  <FormText className='text-danger'>
                    {errors.secondaryContactTelephone.message}
                  </FormText>
                )}
              </Form.Group>
            </Col>

            <Col sm={12} className='mb-0'>
              <Button
                variant='outline-primary'
                size='sm'
                className='me-2 py-0 px-2'
                style={{ lineHeight: '1.5' }}
                onClick={() => {
                  setOrganizations(prevState => ({
                    ...prevState,
                    orgAddModal: {
                      ...prevState.orgAddModal,
                      formData: {
                        ...prevState.orgAddModal.formData,
                        secondaryContactName: formData?.primaryContactName,
                        secondaryContactTelephone:
                          formData?.primaryContactTelephone
                      }
                    }
                  }));
                }}>
                Copy Primary
              </Button>
              <Button
                variant='outline-primary'
                size='sm'
                className='py-0 px-2'
                style={{ lineHeight: '1.5' }}
                onClick={() => {
                  setOrganizations(prevState => ({
                    ...prevState,
                    orgAddModal: {
                      ...prevState.orgAddModal,
                      formData: {
                        ...prevState.orgAddModal.formData,
                        secondaryContactName: formData?.emergencyContactName,
                        secondaryContactTelephone:
                          formData?.emergencyContactTelephone
                      }
                    }
                  }));
                }}>
                Copy Emergency
              </Button>
            </Col>
          </Row>

          <Row className='g-3 mb-3'>
            <Col sm={6} className='mb-0'>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Emergency Contact{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText='Emergency Contact number'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  placeholder={'Jane Doe'}
                  {...register('emergencyContactName')}
                  type='text'
                  size='sm'
                  autoComplete='off'
                  className={`fs--1 ${
                    errors.emergencyContactName ? 'is-invalid' : ''
                  }`}
                  onChange={e => {
                    setOrganizations(prevState => ({
                      ...prevState,
                      orgAddModal: {
                        ...prevState.orgAddModal,
                        formData: {
                          ...prevState.orgAddModal.formData,
                          emergencyContactName: e.target.value
                        }
                      }
                    }));
                  }}
                />
                {errors.emergencyContactName && (
                  <FormText className='text-danger'>
                    {errors.emergencyContactName.message}
                  </FormText>
                )}
              </Form.Group>
            </Col>

            <Col sm={6} className='mb-0'>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Telephone{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText='Emergency telephone contact number'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  placeholder={'+1 650-253-0000'}
                  {...register('emergencyContactTelephone')}
                  type='text'
                  size='sm'
                  autoComplete='off'
                  className={`fs--1 ${
                    errors.emergencyContactTelephone ? 'is-invalid' : ''
                  }`}
                  onChange={e => {
                    setOrganizations(prevState => ({
                      ...prevState,
                      orgAddModal: {
                        ...prevState.orgAddModal,
                        formData: {
                          ...prevState.orgAddModal.formData,
                          emergencyContactTelephone: e.target.value
                        }
                      }
                    }));
                  }}
                />
                {errors.emergencyContactTelephone && (
                  <FormText className='text-danger'>
                    {errors.emergencyContactTelephone.message}
                  </FormText>
                )}
              </Form.Group>
            </Col>

            <Col sm={12} className='mb-0'>
              <Button
                variant='outline-primary'
                size='sm'
                className='me-2 py-0 px-2'
                style={{ lineHeight: '1.5' }}
                onClick={() => {
                  setOrganizations(prevState => ({
                    ...prevState,
                    orgAddModal: {
                      ...prevState.orgAddModal,
                      formData: {
                        ...prevState.orgAddModal.formData,
                        emergencyContactName: formData?.primaryContactName,
                        emergencyContactTelephone:
                          formData?.primaryContactTelephone
                      }
                    }
                  }));
                }}>
                Copy Primary
              </Button>
              <Button
                variant='outline-primary'
                size='sm'
                style={{ lineHeight: '1.5' }}
                className='py-0 px-2'
                onClick={() => {
                  setOrganizations(prevState => ({
                    ...prevState,
                    orgAddModal: {
                      ...prevState.orgAddModal,
                      formData: {
                        ...prevState.orgAddModal.formData,
                        emergencyContactName: formData?.secondaryContactName,
                        emergencyContactTelephone:
                          formData?.secondaryContactTelephone
                      }
                    }
                  }));
                }}>
                Copy Secondary
              </Button>
            </Col>
          </Row>
          <Row className='g-3 mb-3'>
            {/* <h3 className='mb-0 mt-4 fs-0'>Report Recipients</h3> */}
            <Form.Group as={Col} sm={12} className='mt-3'>
              <Form.Label className='d-flex fs--1 fw-normal'>
                Report Recipients{' '}
                <AdvancedPopover
                  containerId='newOrgAddModal'
                  placement='top'
                  popoverText='Emergency telephone contact number'
                  showArrow={true}>
                  <FontAwesomeIcon
                    icon={['far', 'question-circle']}
                    className='ms-1 text-400 fs--1 cursor-pointer'
                  />
                </AdvancedPopover>
              </Form.Label>
              <>
                {!loading && (
                  <Controller
                    name='reportRecipients'
                    control={control}
                    render={({ field }) => (
                      <CreatableMultiselect
                        {...field}
                        isMulti
                        className={`fs--1 ${
                          errors.alertRecipients ? 'is-invalid' : ''
                        }`}
                        isClearable
                        isSearchable
                        placeholder='Please select'
                        options={usersFromContext
                          .filter(user => {
                            // Only show users that are part of the organization that we're editing
                            return user.organizations.some(
                              org => org.id === data?._id
                            );
                          })
                          .map(user => ({
                            value: user?.emails.find(email => email?.isPrimary)
                              ?.address,
                            label: `${
                              user?.emails.find(email => email?.isPrimary)
                                ?.address
                            }`
                          }))}
                      />
                    )}
                  />
                )}
              </>
            </Form.Group>
          </Row>
          <Row className='g-3 mb-3'>
            {/* <h3 className='mb-0 mt-4 fs-0'>Alert Recipients</h3> */}

            <Form.Group as={Col} sm={12} className='mt-3'>
              <Form.Label className='d-flex fs--1 fw-normal'>
                Alert Recipients{' '}
                <AdvancedPopover
                  containerId='newOrgAddModal'
                  placement='top'
                  popoverText='Alert Recipients'
                  showArrow={true}>
                  <FontAwesomeIcon
                    icon={['far', 'question-circle']}
                    className='ms-1 text-400 fs--1 cursor-pointer'
                  />
                </AdvancedPopover>
              </Form.Label>
              {!loading && (
                <Controller
                  name='alertRecipients'
                  control={control}
                  render={({ field }) => (
                    <CreatableMultiselect
                      {...field}
                      isMulti
                      className={`fs--1 ${
                        errors.alertRecipients ? 'is-invalid' : ''
                      }`}
                      isClearable
                      isSearchable
                      placeholder='Please select'
                      options={usersFromContext.map(user => ({
                        value: user?.emails.find(email => email?.isPrimary)
                          ?.address,
                        label: `  ${
                          user?.emails.find(email => email?.isPrimary)?.address
                        }`
                      }))}
                    />
                  )}
                />
              )}
            </Form.Group>
          </Row>
        </Tab>
        <Tab
          eventKey='access'
          title='Access'
          className='border-none p-3'
          tabClassName='fw-normal'>
          <Row className='g-3 mb-3'>
            <Col sm={12}>
              <Form.Group>
                {/* <Form.Label className='fs--1'>Users</Form.Label> */}
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Users{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText="  Users chosen here will be associated with the organization and
                will be able to access the organization's data and resources."
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                {!loading && (
                  <>
                    <Controller
                      name='users'
                      control={control}
                      render={({ field }) => (
                        <MultiSelect
                          {...field}
                          className={`fs--1 ${
                            errors.users ? 'is-invalid' : ''
                          }`}
                          isMulti
                          isClearable
                          isSearchable
                          placeholder='Please select'
                          options={usersFromContext.map(user => ({
                            value: user?.id,
                            label: `${user?.profile?.fullName} - ${
                              user?.emails.find(email => email?.isPrimary)
                                ?.address
                            }`
                          }))}
                        />
                      )}
                    />
                  </>
                )}
              </Form.Group>
              {/* <FormText className='text-muted'>
                Users chosen here will be associated with the organization and
                will be able to access the organization's data and resources.
              </FormText> */}
            </Col>
          </Row>

          <Row className='g-3 mb-3'>
            <Col sm={12}>
              <Form.Group>
                {/* <Form.Label className='fs--1'>Primary Owner</Form.Label> */}
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Primary Owner{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText=' The primary owner will be granted full access to manage this
                organization. This includes the ability to add and remove
                co-owners, and to transfer primary ownership to another
                co-owner. The primary owner can only be selected from the list
                of users, chosen above.'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                {!loading && (
                  <>
                    <Controller
                      name='primaryOwner'
                      control={control}
                      render={({ field }) => (
                        <MultiSelect
                          {...field}
                          className={`fs--1 ${
                            errors.primaryOwner ? 'is-invalid' : ''
                          }`}
                          isClearable
                          isSearchable
                          placeholder='Please select'
                          options={usersFromContext
                            .filter(user =>
                              users?.some(({ value }) => value === user?._id)
                            )
                            .map(user => ({
                              value: user?._id,
                              label: `${user?.profile?.fullName} - ${
                                user?.emails.find(email => email?.isPrimary)
                                  ?.address
                              }`
                            }))}
                        />
                      )}
                    />
                  </>
                )}
              </Form.Group>
              {/* <FormText className='text-muted'>
                The primary owner will be granted full access to manage this
                organization. This includes the ability to add and remove
                co-owners, and to transfer primary ownership to another
                co-owner. The primary owner can only be selected from the list
                of users, chosen above.
              </FormText> */}
            </Col>
          </Row>

          <Row className='g-3 mb-3'>
            <Col sm={12}>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Co-Owners{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText='Co-Owners will be granted full access to manage this
                organization, unless directly scoped otherwise; with the
                exception of being able to assume primary ownership. Primary
                ownership can only be transferred to another co-owner by the
                current primary owner. Co-Owners can only be selected from the
                list of users, chosen above.'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                {!loading && (
                  <>
                    <Controller
                      name='owners'
                      control={control}
                      render={({ field }) => (
                        <MultiSelect
                          {...field}
                          className={`fs--1 ${
                            errors.owners ? 'is-invalid' : ''
                          }`}
                          isMulti
                          isClearable
                          isSearchable
                          placeholder='Please select'
                          options={usersFromContext
                            .filter(user =>
                              users?.some(({ value }) => value === user?.id)
                            )
                            .map(user => ({
                              value: user?.id,
                              label: `${user?.profile?.fullName} - ${
                                user?.emails.find(email => email?.isPrimary)
                                  ?.address
                              }`
                            }))}
                        />
                      )}
                    />
                  </>
                )}
              </Form.Group>
              {/* <FormText className='text-muted'>
                Co-Owners will be granted full access to manage this
                organization, unless directly scoped otherwise; with the
                exception of being able to assume primary ownership. Primary
                ownership can only be transferred to another co-owner by the
                current primary owner. Co-Owners can only be selected from the
                list of users, chosen above.
              </FormText> */}
            </Col>
          </Row>

          {/* <Row className='g-3 mb-3'>
            <Col sm={12}>
              <Form.Group>
                <Form.Label className='fs--1'>Authorized Domains</Form.Label>
                {!loading && (
                  <>
                    <Controller
                      name='authorizedDomains'
                      control={control}
                      render={({ field }) => (
                        <CreatableMultiselect
                          {...field}
                          className={`fs--1 ${errors.authorizedDomains ? 'is-invalid' : ''}`}
                          isMulti
                          isClearable
                          isSearchable
                          placeholder='Please select'
                          options={data?.authorizedDomains?.map(item => ({
                            value: item,
                            label: item
                          }))}
                        />
                      )}
                    />
                  </>
                )}
              </Form.Group>
              <FormText className='text-muted'>
                Authorized domains are used to verify the identity of users. Users will be required to have an email address that matches one of the domains
                listed here in order to be invited to or join this organization. If no authorized domains is provided, users with any email address can be
                invited to join.
              </FormText>
            </Col>
          </Row> */}

          <Row className='g-0 mb-3'>
            <Col sm={12}>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Managed Service Provider{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText="If chosen, the organization selected will be granted full access
                to manage this organization, it's users, it's products, and view
                it's data."
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                {!loading && (
                  <>
                    <Controller
                      name='parent'
                      control={control}
                      render={({ field }) => (
                        <MultiSelect
                          {...field}
                          className={`fs--1 ${
                            errors.parent ? 'is-invalid' : ''
                          }`}
                          isClearable
                          isSearchable
                          placeholder='Please select'
                          options={organizations.map(org => ({
                            value: org?.id,
                            label: org?.name
                          }))}
                        />
                      )}
                    />
                  </>
                )}
              </Form.Group>
              {/* <FormText className='text-muted'>
                If chosen, the organization selected will be granted full access
                to manage this organization, it's users, it's products, and view
                it's data.
              </FormText> */}
            </Col>
          </Row>
        </Tab>
        <Tab
          eventKey='profile'
          title='Profile'
          className='border-none p-3'
          tabClassName='fw-normal'>
          <Row className='g-3 mb-3'>
            <Col sm={6}>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Website{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText="Link to your organization's Website"
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  placeholder={'https://www.alphabet.com'}
                  {...register('website')}
                  type='text'
                  size='sm'
                  autoComplete='off'
                  className={`fs--1 ${errors.website ? 'is-invalid' : ''}`}
                />
                {errors.website && (
                  <FormText className='text-danger'>
                    {errors.website.message}
                  </FormText>
                )}
              </Form.Group>
            </Col>

            {/* <Col sm={6}>
              <Form.Group>
                <Form.Label className='fs--1'>Profile Photo</Form.Label>
                <Form.Control
                  type='file'
                  accept='image/*'
                  onChange={handleProfilePhotoChange}
                  size='sm'
                  className='fs--1'
                />
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label className='fs--1'>Profile Banner</Form.Label>
                <Form.Control
                  type='file'
                  accept='image/*'
                  onChange={handleProfilePhotoChange}
                  size='sm'
                  className='fs--1'
                />
              </Form.Group>
            </Col> */}

            <Col sm={12}>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Bio{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText='Brief bio about organization'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows={5}
                  placeholder={
                    "Alphabet Inc. is an American multinational conglomerate headquartered in Mountain View, California. It was created through a restructuring of Google on October 2, 2015, and became the parent company of Google and several former Google subsidiaries. Alphabet is the world's fourth-largest technology company by revenue and one of the world's most valuable companies."
                  }
                  {...register('bio')}
                  size='sm'
                  autoComplete='off'
                  className={`fs--1 ${errors.bio ? 'is-invalid' : ''}`}
                />
                {errors.bio && (
                  <FormText className='text-danger'>
                    {errors.bio.message}
                  </FormText>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className='g-0 mb-3'>
            <h3 className='mb-0 mt-3 fs-0'>Social Links</h3>
            <FormText className='text-muted'>
              Links to social media accounts for the organization. This
              information can be added or changed at any time.
            </FormText>
          </Row>

          <Row className='g-3 mb-3'>
            <Col sm={6}>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  FaceBook{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText='Facebook page or account link'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  placeholder='https://www.facebook.com/AlphabetInc'
                  {...register('facebook')}
                  type='text'
                  size='sm'
                  autoComplete='off'
                  className={`fs--1 ${errors.facebook ? 'is-invalid' : ''}`}
                />
                {errors.facebook && (
                  <FormText className='text-danger'>
                    {errors.facebook.message}
                  </FormText>
                )}
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  GitHub{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText='Link to GitHub account'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  placeholder='https://github.com/AlphabetInc'
                  {...register('github')}
                  type='text'
                  size='sm'
                  autoComplete='off'
                  className={`fs--1 ${errors.github ? 'is-invalid' : ''}`}
                />
                {errors.github && (
                  <FormText className='text-danger'>
                    {errors.github.message}
                  </FormText>
                )}
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Instagram{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText='Link to Instagram account'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  placeholder='https://www.instagram.com/AlphabetInc'
                  {...register('instagram')}
                  type='text'
                  size='sm'
                  autoComplete='off'
                  className={`fs--1 ${errors.instagram ? 'is-invalid' : ''}`}
                />
                {errors.instagram && (
                  <FormText className='text-danger'>
                    {errors.instagram.message}
                  </FormText>
                )}
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Linkedin{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText='Link to linkedin account'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>{' '}
                <Form.Control
                  placeholder='https://www.linkedin.com/company/AlphabetInc'
                  {...register('linkedin')}
                  type='text'
                  size='sm'
                  autoComplete='off'
                  className={`fs--1 ${errors.linkedin ? 'is-invalid' : ''}`}
                />
                {errors.linkedin && (
                  <FormText className='text-danger'>
                    {errors.linkedin.message}
                  </FormText>
                )}
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  X{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText='Link to X account'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>{' '}
                <Form.Control
                  placeholder='https://twitter.com/AlphabetInc'
                  {...register('twitter')}
                  type='text'
                  size='sm'
                  autoComplete='off'
                  className={`fs--1 ${errors.twitter ? 'is-invalid' : ''}`}
                />
                {errors.twitter && (
                  <FormText className='text-danger'>
                    {errors.twitter.message}
                  </FormText>
                )}
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label className='d-flex fs--1 fw-normal'>
                  Youtube{' '}
                  <AdvancedPopover
                    containerId='newOrgAddModal'
                    placement='top'
                    popoverText='Link to Youtube channel'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>{' '}
                <Form.Control
                  placeholder='https://www.youtube.com/AlphabetInc'
                  {...register('youtube')}
                  type='text'
                  size='sm'
                  autoComplete='off'
                  className={`fs--1 ${errors.youtube ? 'is-invalid' : ''}`}
                />
                {errors.youtube && (
                  <FormText className='text-danger'>
                    {errors.youtube.message}
                  </FormText>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Tab>
      </Tabs>
      <Modal.Footer className='border-top-0 pb-2'>
        <Flex justifyContent='end'>
          <Button
            variant='secondary'
            className='me-2'
            size='sm'
            onClick={() =>
              setOrganizations(prevState => ({
                ...prevState,
                orgEditModal: { open: false, data: {}, formData: {} }
              }))
            }>
            Cancel
          </Button>

          <Button variant='success' type='submit' size='sm'>
            <div className='position-relative'>
              <Flex justifyContent='center' alignItems={'center'}>
                <ButtonSpinner spinning={loading} />{' '}
                <span>{loading ? 'Saving' : 'Save'}</span>
              </Flex>
            </div>
          </Button>
        </Flex>
      </Modal.Footer>
    </Form>
  );
};

export default EditOrganizationModal;
