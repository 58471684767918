import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import useAxiosPrivate from 'hooks/useAxiosPrivate';

const DeleteDashboardForm = ({
  showDeleteDashboardModal,
  setShowDeleteDashboardModal
}) => {
  const [loading, setLoading] = useState(false);
  const { dashboard } = showDeleteDashboardModal;

  const { axiosPrivate } = useAxiosPrivate();

  return (
    <Form>
      <Row className='g-3 mb-3'>
        <Col sm={12}>
          <h5>Are you sure to delete '{dashboard?.title}'?</h5>
        </Col>
      </Row>

      <Flex justifyContent='end'>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() =>
            setShowDeleteDashboardModal({
              open: false,
              dashboard: {},
              fetch: false
            })
          }>
          Cancel
        </Button>

        <Button
          variant='danger'
          size='sm'
          onClick={async () => {
            setLoading(true);
            try {
              const res = await LeargasAPI.Dashboards.deleteDashboard(
                showDeleteDashboardModal?.dashboard?.id,
                true,
                axiosPrivate
              );
              if (res instanceof Error) throw res;

              let message = res?.data?.message;
              setLoading(false);
              setShowDeleteDashboardModal({
                open: false,
                dashboard: {},
                fetch: true
              });
              toast.success(message);
            } catch (error) {
              if (error.message.includes('Network Error')) {
                console.error('Network Error occurred.');
              }
              setLoading(false);
              setShowDeleteDashboardModal({
                open: false,
                dashboard: {},
                fetch: false
              });
              if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
              } else {
                toast.error('Something went wrong!');
              }
            }
          }}>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Delete' : 'Delete'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

DeleteDashboardForm.propTypes = {
  showDeleteDashboardModal: PropTypes.object,
  setShowDeleteDashboardModal: PropTypes.func
};

export default DeleteDashboardForm;
