import LeargasAPI from 'api';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

export const appliancesSettings = {
  applianceAddModal: {
    fullScreen: false,
    open: false
  },
  applianceEditModal: {
    data: {},
    fullScreen: false,
    open: false
  },
  data: [],
  deleteApplianceModal: {
    data: {},
    open: false
  },
  DeletedOrgs: {
    open: false
  },
  Executors: ['role', 'team', 'user'],
  fetch: false,
  loading: false,
  Operations: ['read', 'write', 'delete'],
  requiredAppliances: {
    appliances: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    },
    appliancesNotes: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    appliances: {
      delete: ['appliances:delete'],
      read: ['appliances:read'],
      write: ['appliances:write']
    },
    appliancesNotes: {
      delete: ['appliancesNotes:delete'],
      read: ['appliancesNotes:read'],
      write: ['appliancesNotes:write']
    }
  },
  restoreApplianceModal: {
    data: {},
    open: false
  },
  showDeleted: false
};

/**
 * Create AppliancesContext
 * @type {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const AppliancesContext = createContext();

/**
 * Create AppliancesProvider
 * @param props
 * @returns {JSX.Element}
 * @constructor
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 *  <AppliancesProvider>
 *   {children}
 * </AppliancesProvider>
 * )
 */
const AppliancesProvider = props => {
  const [appliances, setAppliances] = useState(appliancesSettings);
  const [loading, setLoading] = useState(true);

  const { axiosPrivate } = useAxiosPrivate();

  /**
   * Get all application appliances
   * @returns {Promise<void>}
   * @async
   * @function
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  const getAppAppliances = async () => {
    try {
      let res = await LeargasAPI.Appliances.getAppliances(axiosPrivate);
      if (res instanceof Error) throw res;

      setAppliances(prevState => ({
        ...prevState,
        data: res
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setAppliances(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setAppliances(prevState => ({
        ...prevState,
        fetch: false
      }));
      setLoading(false);
    }
  };

  /**
   * Get all application appliances on fetch state change
   */
  useEffect(() => {
    if (appliances.fetch) {
      getAppAppliances();
    }
  }, [appliances.fetch]);

  /**
   * Get all application appliances on component mount
   */
  useEffect(() => {
    getAppAppliances();
  }, []);

  return (
    <AppliancesContext.Provider
      value={{
        appliances,
        setAppliances,
        loading
      }}>
      {props.children}
    </AppliancesContext.Provider>
  );
};

AppliancesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppliancesProvider;
