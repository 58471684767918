import { Address4, Address6 } from 'ip-address';

const privateCIDRs = [
  // IPv4 Private and Special-use Ranges
  '10.0.0.0/8',
  '172.16.0.0/12',
  '192.168.0.0/16', // Private networks
  '127.0.0.0/8', // Loopback
  '169.254.0.0/16', // Link-local
  '192.0.2.0/24',
  '198.51.100.0/24',
  '203.0.113.0/24', // Documentation
  '0.0.0.0/8', // "This" network
  '100.64.0.0/10', // Shared Address Space
  '192.88.99.0/24', // IPv6 to IPv4 relay
  '224.0.0.0/4', // Multicast
  '240.0.0.0/4', // Future use
  // IPv6 Private and Special-use Ranges
  'fc00::/7', // Unique local address
  'fe80::/10', // Link-local
  '::1/128', // Loopback
  '2001:db8::/32', // Documentation
  '::/128', // Unspecified address
  'ff00::/8' // Multicast
];

export const isPublicIP = ip => {
  let address;

  if (Address4.isValid(ip)) {
    address = new Address4(ip);
  } else if (Address6.isValid(ip)) {
    address = new Address6(ip);
  } else {
    return false; // Not a valid IPv4 or IPv6 address
  }

  return !privateCIDRs.some(cidr => {
    const cidrAddress = cidr.includes(':')
      ? new Address6(cidr)
      : new Address4(cidr);
    return address.isInSubnet(cidrAddress);
  });
};
