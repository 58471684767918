import CardDropdown from 'components/common/CardDropdown';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';

/**
 * Columns for table
 * @param {Function} setBookmarks
 * @returns {Array}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const getBookmarkColumns = setBookmarks => {
  return [
    {
      accessor: 'name',
      Header: 'Name',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap'
      },
      Cell: rowData => {
        const { name } = rowData.row.original;

        return (
          <>
            <Flex direction='row' alignItems='center'>
              {name}
            </Flex>
          </>
        );
      }
    },

    {
      accessor: 'description',
      Header: 'Description ',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-center align-middle text-nowrap'
      },
      Cell: () => {
        return (
          <Flex direction='row' justifyContent='center' alignItems='center'>
            Description
          </Flex>
        );
      }
    },
    {
      accessor: 'query',
      Header: 'Query ',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-center align-middle text-nowrap'
      },
      Cell: () => {
        return (
          <Flex direction='row' justifyContent='center' alignItems='center'>
            Query
          </Flex>
        );
      }
    },
    {
      accessor: 'route',
      Header: 'Route',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-center align-middle text-nowrap'
      },
      Cell: () => {
        return (
          <Flex direction='row' justifyContent='center' alignItems='center'>
            Route
          </Flex>
        );
      }
    },
    {
      accessor: 'leargas_actions',
      Header: '',
      Cell: rowData => {
        return (
          <Flex
            justifyContent='end'
            alignContent='middle'
            className='table-dropdown-columns'>
            <CardDropdown>
              <div className='py-2'>
                <Dropdown.Item
                  onClick={() =>
                    setBookmarks(prevState => ({
                      ...prevState,
                      viewBookmarkModal: {
                        open: true,
                        defaultData: rowData.row.original
                      }
                    }))
                  }>
                  <FontAwesomeIcon icon='pencil' className='me-2' />
                  Edit Details
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </Flex>
        );
      },
      cellProps: {
        className: 'text-end text-400 actions-cell',
        style: {
          width: '42px'
        }
      },
      hideDisplaySettings: true,
      disableSortBy: true
    }
  ];
};
