import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
// import routes from 'config/routeSettings';
// import { Link } from 'react-router-dom';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ProfileContainer = ({ org }) => {
  // let { profile: { media: { banner } = {} } = {} } = org;
  return (
    <Card className='shadow-none'>
      <Background
        image={process.env.REACT_APP_DEFAULT_ORG_BANNER_IMG}
        className='rounded-3 rounded-bottom-0 position-relative'
        style={{ height: '250px' }}
      />
      <ProfileContainerHeader org={org} />
      <ProfileContainerBody org={org} />
    </Card>
  );
};

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ProfileContainerHeader = ({ org, className }) => {
  let {
    id,
    name,
    legacy,
    profile: { media: { avatar } = {} } = {},
    parent
  } = org;

  const {
    application: { isPrivacyMode }
  } = useApplication();

  return (
    <Card.Header
      className={`${classNames(className)} p-4`}
      style={{ marginTop: '-175px' }}>
      <div>
        <Flex direction='column' justifyContent='start' alignItems='middle'>
          <Avatar
            size='5xl'
            className='mb-3'
            src={avatar || process.env.REACT_APP_DEFAULT_ORG_AVATAR_IMG}
            mediaClass='img-thumbnail shadow-sm'
          />
          <Flex direction='row' justifyContent='start' alignContent='middle'>
            <Flex
              direction='column'
              justifyContent='between'
              alignContent='middle'
              className='  pe-4 me-4'>
              <h4 className='position-relative fs-3'>
                {id === '648371d0a87d7ad0a0367cfa' ? (
                  <FontAwesomeIcon
                    icon={['fas', 'clover']}
                    transform='rotate-45'
                    className='text-primary me-1'
                  />
                ) : null}
                {isPrivacyMode && legacy?.clientTag ? legacy?.clientTag : name}
              </h4>
              {parent && parent?.name && (
                <h4 className='mb-0 fs--1'>
                  <span>Managed by </span>
                  {parent?.id === '648371d0a87d7ad0a0367cfa' ? (
                    <FontAwesomeIcon
                      icon={['fas', 'clover']}
                      transform='rotate-45'
                      className='text-primary me-1'
                    />
                  ) : null}
                  {isPrivacyMode && parent?.legacy?.clientTag
                    ? parent?.legacy?.clientTag
                    : parent?.name}
                </h4>
              )}
            </Flex>

            {/* <Flex direction='column' justifyContent='between' className='pt-1'>
              <h4 className='fs-1 mt-1'>{subscription?.type || 'Free'} Subscription</h4>
              <h4 className='fs--1 mb-0'>
                <span className='text-primary'>Active</span>
              </h4>
            </Flex> */}
          </Flex>
        </Flex>
      </div>
    </Card.Header>
  );
};

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ProfileContainerBody = ({ org }) => {
  return <Card.Body org={org} className='pb-0' />;
};

ProfileContainer.propTypes = {
  org: PropTypes.object.isRequired
};

ProfileContainerHeader.propTypes = {
  org: PropTypes.object.isRequired,
  className: PropTypes.string
};

ProfileContainerBody.propTypes = {
  org: PropTypes.object.isRequired
};

export default ProfileContainer;
