import logoDark from 'assets/img/logo-h-dark.png';
import logoLight from 'assets/img/logo-h-light.png';
import classNames from 'classnames';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Logo component.
 *
 * @component
 * @param {string} at - The position of the logo
 * @param {string} className - Additional CSS class for the logo
 * @param {object} rest - Additional props for the logo
 * @returns {JSX.Element} The rendered logo component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.1
 */
const Logo = ({ at, className, ...rest }) => {
  const {
    application: { isDark }
  } = useApplication();

  return (
    <div
      className={classNames(
        'position-relative',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}>
      <Link to='/' className='text-decoration-none' {...rest}>
        <div
          className={classNames(
            'logo-img',
            {
              'fw-bolder fs-5 mb-4': at === 'auth',
              'ms-2': at !== 'auth'
            },
            className
          )}
          style={{ margin: '0 auto', padding: '6px 0' }}>
          {at === 'auth' ? (
            <img
              className='mw-200'
              src={isDark ? logoDark : logoLight}
              alt='Léargas'
            />
          ) : (
            <img
              className='mw-100'
              src={isDark ? logoDark : logoLight}
              style={{ minWidth: '100px', width: '150px' }}
              alt='Léargas'
            />
          )}
        </div>
      </Link>
    </div>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['auth', 'navbar-vertical', 'navbar-top']),
  className: PropTypes.string
};

Logo.defaultProps = { at: 'auth' };

export default Logo;
