import RolesWrapper from 'components/authorization/RolesWrapper';
import React from 'react';
import { Route } from 'react-router-dom';
import DynamicDashLayout from './DynamicDashLayout';
import { dynamicDashboardRoutes } from './routes';

/**
 * Called in MainRouter to render all the dynamic dashboard routes.
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.2
 */
const DynamicDashRouter = () => {
  return dynamicDashboardRoutes.map((route, index) => (
    <Route
      key={index}
      element={
        <RolesWrapper allowedRoles={['super-admin', 'admin', 'user']} />
      }>
      <Route
        path={route.path}
        element={<DynamicDashLayout dashLayoutName={route?.slug} />}
      />
    </Route>
  ));
};

export default DynamicDashRouter;
