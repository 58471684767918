import LeargasAPI from 'api';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useOrganizations from 'hooks/user-contexts/useUserOrganizations';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Card, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import RemoveButton from '../RemoveButton';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const PendingInvitations = ({ isOwner, id }) => {
  const {
    organizations: { pendingInvitations, fetch },
    setOrganizations
  } = useOrganizations();
  const { axiosPrivate } = useAxiosPrivate();

  /**
   *
   * @param {string} id
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const getPendingInvitations = async id => {
    try {
      const res = await LeargasAPI.AccountOrganizations.getPendingInvitations(
        {
          organizationId: id
        },
        axiosPrivate
      );
      if (res instanceof Error) throw res;

      let { data } = res?.data || {};
      setOrganizations(prevState => ({
        ...prevState,
        fetch: false,
        pendingInvitations: data
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setOrganizations(prevState => ({
        ...prevState,
        fetch: false
      }));
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      else toast.error('Something went wrong!');
    }
  };

  /**
   *
   */
  useEffect(() => {
    getPendingInvitations(id);
  }, [fetch, id]);

  return (
    <>
      {pendingInvitations?.length > 0 && (
        <Col
          sm={6}
          md={8}
          xxl={8}
          className='mb-0 my-3 mb-xxl-0 position-relative h-100'>
          <Card className='shadow-none'>
            <FalconCardHeader
              light
              title='Pending Invitations'
              titleTag='h6'
              className='py-3'
            />
            <Card.Body className='py-3 '>
              {pendingInvitations?.map((invitation, index) => (
                <Flex
                  justifyContent='between'
                  key={index}
                  alignItems={'center'}
                  className={'mb-3'}>
                  <h4 className='mb-0 fs--1 w-50'>
                    {invitation?.recipientEmail}
                  </h4>
                  <p className='mb-0 fs--1 w-25'>{invitation?.status}</p>

                  {isOwner && (
                    <RemoveButton
                      onClick={() => {
                        setOrganizations(prevState => ({
                          ...prevState,
                          removePendingUserModal: {
                            open: true,
                            invitation: invitation,
                            organizationId: id
                          }
                        }));
                      }}
                    />
                  )}
                </Flex>
              ))}
            </Card.Body>
          </Card>
        </Col>
      )}
    </>
  );
};

PendingInvitations.propTypes = {
  isOwner: PropTypes.bool,
  id: PropTypes.string
};

export default PendingInvitations;
