import { makeRequest } from 'api/connection/requestAction';
/**
 * @description Users API
 * @summary Provides methods for interacting with the users API
 * @method createUser Create a new user
 * @method getUsers Get a list of all users
 * @method getUser Get a single user
 * @method updateUser Update a user
 * @method deleteUser Delete a user
 * @method lockUser Lock a user
 * @method unlockUser Unlock a user
 */
class Users {
  constructor(route) {
    this.route = route;
  }
  /**
   * @description Create a new user
   * @param {Object} userData User data
   * @returns {Mixed} User data if true, error object if false
   */
  async createUser(userData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.createUser.method,
        this.route.createUser.path,
        userData
      );
      if (res instanceof Error) throw res;
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Get a list of all users
   * @param {Boolean} showDeleted Show deleted users
   * @returns {Mixed} User data if true, error object if false
   */
  async getUsers(axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getUsers.method,
        this.route.getUsers.path
      );
      if (res instanceof Error) throw res;
      let users = res?.data?.data;
      //  ?.map(user => {
      //     if ((showDeleted && user.deleted?.isDeleted) || !user.deleted?.isDeleted) {
      //       users.push(user);
      //     }
      //   });
      return users;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Get a specific user
   * @param {String} id User ID
   * @returns {Mixed} User data if true, error object if false
   */
  async getUser(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getUser.method,
        `${this.route.getUser.path}/${id}`
      );
      if (res instanceof Error) throw res;
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Update a user by ID
   * @param {String} userData User data
   * @returns {Mixed} User data if true, error object if false
   */
  async updateUser(id, userData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateUser.method,
        `${this.route.updateUser.path}/${id}`,
        userData
      );
      if (res instanceof Error) throw res;
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Update a user by ID
   * @param {String} id User data
   * @param {String} passwordsData User data
   * @returns {Mixed} User data if true, error object if false
   */
  async updatePasswordForUser(id, passwordsData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateUser.method,
        `${this.route.updateUser.path}/update-password/${id}`,
        passwordsData
      );
      if (res instanceof Error) throw res;
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Delete or restore a user by ID
   * @param {String} id User ID
   * @param {Boolean} deleting True if deleting, false if restoring
   * @returns {Mixed} User data if true, error object if false
   */
  async deleteUser(id, deleting, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.deleteUser.method,
        `${this.route.deleteUser.path}/${id}`,
        { deleting }
      );
      if (res instanceof Error) throw res;
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Lock a user by ID
   * @param {String} id User ID
   * @returns {Mixed} User data if true, error object if false
   */
  async lockUser(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.lockUser.method,
        `${this.route.lockUser.path}/lock/${id}`
      );
      if (res instanceof Error) throw res;
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Unlock a user by ID
   * @param {String} id User ID
   * @returns {Mixed} User data if true, error object if false
   */
  async unlockUser(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.unlockUser.method,
        `${this.route.unlockUser.path}/unlock/${id}`
      );
      if (res instanceof Error) throw res;
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default Users;
