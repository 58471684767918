import { version } from 'context/ApplicationProvider';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

/**
 * Footer component for the application.
 *
 * @returns {JSX.Element} The rendered Footer component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const Footer = () => {
  return (
    <footer className='footer w-100'>
      <Row className='justify-content-between text-center fs--1 mt-4 mb-3'>
        <Col sm='auto'>
          <p className='mb-0 text-700'>
            Thank you for choosing Léargas{' '}
            <span className='d-none d-sm-inline-block'>| </span>
            <br className='d-sm-none' /> &copy; 2018-{new Date().getFullYear()}{' '}
            <a
              href='https://leargassecurity.com'
              className='text-700'
              rel='noreferrer'>
              Léargas Security, Inc
            </a>
          </p>
        </Col>
        <Col sm='auto'>
          <p className='mb-0 text-700'>v{version}</p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
