import { apiRoutes } from 'config/routeSettings';

import { staticInjectRules } from 'components/dashboards/Artifacts/Dynamic/dataFetchUtils/utils.js';
import { intervalMap } from 'context/ExploreProvider/settings.js';

import {
  getLast24Hours,
  takeOutBucketForHistogram
} from 'components/dashboards/Artifacts/Dynamic/dataFetchUtils/RefinedUtils.js';
export const loadHistogram = async ({
  scopesSelected,
  axiosPrivate,
  setHistogram,
  setWrapperLoader,
  overriddenIndex,
  must = [],
  should,
  filter,
  must_not
}) => {
  try {
    setWrapperLoader(true);
    let selectedScopes = [];
    if (scopesSelected.length !== 0) {
      selectedScopes = scopesSelected
        ?.map(scope => {
          if (scope?.legacy?.clientId) {
            return {
              match: {
                'clientId.keyword': scope.legacy.clientId
              }
            };
          }
          return null;
        })
        .filter(scopeQuery => scopeQuery !== null);
    } else {
      selectedScopes = [
        {
          match: {
            'clientId.keyword': 'ZEROCUSTOMERS'
          }
        }
      ];
    }
    let { from, to } = getLast24Hours();

    let injectionProcessQuery = {
      query: {
        index: [],
        body: {
          query: {
            bool: {
              filter: [
                {
                  range: {
                    '@timestamp': {
                      gte: from,
                      lte: to,
                      time_zone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                      format: 'strict_date_optional_time'
                    }
                  }
                }
              ],
              must: [],
              should: selectedScopes,
              minimum_should_match: 1
            }
          }
        }
      }
    };

    injectionProcessQuery.query.index = [...overriddenIndex];
    // let injectRulesQuery = _.cloneDeep(injectionProcessQuery.query.body.query);

    let rulesInjectedQuery = staticInjectRules({
      should: should !== null ? should : [],
      must: must !== null ? must : [],
      must_not: must_not !== null ? must_not : [],
      filter: filter !== null ? filter : []
    });
    injectionProcessQuery.query.body.query.bool.must = [
      ...rulesInjectedQuery.must
    ];

    injectionProcessQuery.query.body.aggs = {
      histogram: {
        date_histogram: {
          field: '@timestamp',
          fixed_interval: intervalMap['1h'],
          min_doc_count: 0,
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          extended_bounds: {
            min: from,
            max: to
          }
        }
      }
    };

    const resAggregate = await axiosPrivate.post(
      apiRoutes.SYSTEM_CLUSTER_QUERY_AGG,
      JSON.stringify(injectionProcessQuery)
    );

    if (resAggregate instanceof Error) throw resAggregate;
    setWrapperLoader(false);
    setHistogram({
      type: 'Histogram',
      data: takeOutBucketForHistogram(resAggregate?.data?.data?.aggregations),
      query: injectionProcessQuery,
      response: resAggregate?.data?.data
    });
  } catch (error) {
    setWrapperLoader(false);
    console.error(error);
  }
};
