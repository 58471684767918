import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

export const ReportsTablePagination = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  gotoPage
}) => {
  const pageRange = 5;
  const startPage = Math.max(0, pageIndex - Math.floor(pageRange / 2));
  const endPage = Math.min(pageCount - 1, startPage + pageRange - 1);

  return (
    <Flex alignItems='center' justifyContent='center'>
      <Button
        size='sm'
        variant='falcon-default'
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        className='me-2 page-icon-size'>
        <FontAwesomeIcon icon='chevron-left' />
        <FontAwesomeIcon icon='chevron-left' />
      </Button>
      <Button
        size='sm'
        variant='falcon-default'
        onClick={previousPage}
        disabled={!canPreviousPage}
        className='me-2 page-icon-size'>
        <FontAwesomeIcon icon='chevron-left' />
      </Button>

      <ul className='pagination mb-0 mx-1'>
        {Array.from(
          { length: endPage - startPage + 1 },
          (_, i) => startPage + i
        ).map(page => (
          <li key={page} className={classNames({ active: pageIndex === page })}>
            <Button
              size='sm'
              variant='falcon-default'
              onClick={() => gotoPage(page)}
              className=' page me-1'>
              {page + 1}
            </Button>
          </li>
        ))}
      </ul>
      <Button
        size='sm'
        variant='falcon-default'
        onClick={nextPage}
        disabled={!canNextPage}
        className='me-2 page-icon-size'>
        <FontAwesomeIcon icon='chevron-right' />
      </Button>
      <Button
        size='sm'
        variant='falcon-default'
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
        className='page-icon-size'>
        <FontAwesomeIcon icon='chevron-right' />
        <FontAwesomeIcon icon='chevron-right' />
      </Button>
    </Flex>
  );
};

ReportsTablePagination.propTypes = {
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  previousPage: PropTypes.func,
  nextPage: PropTypes.func,
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  gotoPage: PropTypes.func
};

export default ReportsTablePagination;
