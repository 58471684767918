import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useResources from 'hooks/admin-contexts/useResources';
import useApplication from 'hooks/useApplication';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

/**
 * Resources Delete Modal
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ModalResourceDelete = () => {
  const {
    application: { isDark }
  } = useApplication();

  const { axiosPrivate } = useAxiosPrivate();
  const {
    resources: { deleteResourceModal, loading },
    setResources
  } = useResources();

  /**
   *
   * @param {Event} e
   * @async
   * @function
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const handleSubmit = async e => {
    e.preventDefault();
    let { defaultData } = deleteResourceModal;

    try {
      setResources(prevState => ({
        ...prevState,
        loading: true
      }));
      let res = await LeargasAPI.Resources.deleteAppResource(
        defaultData?._id,
        axiosPrivate
      );

      if (res instanceof Error) {
        throw res;
      }
      const { message } = res.data;
      toast.success(message);
      setResources(prevState => ({
        ...prevState,
        fetch: true,
        loading: false,
        deleteResourceModal: { open: false, defaultData: {} }
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
      setResources(prevState => ({
        ...prevState,
        fetch: true,
        deleteResourceModal: { open: false, defaultData: {} },
        loading: false
      }));
    }
  };

  return (
    <Modal
      centered
      size='lg'
      animation={false}
      backdrop='static'
      keyboard={false}
      show={deleteResourceModal?.open}
      onHide={() =>
        setResources(prevState => ({
          ...prevState,
          deleteResourceModal: { open: false, defaultData: {} }
        }))
      }
      aria-labelledby='modalResourceAdd'>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Delete Resource: {deleteResourceModal?.defaultData?.name}
          <AdvancedPopover
            containerId='resetConfirmModal'
            placement='top'
            popoverText='Delete Resource'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
      </Modal.Header>

      <Modal.Body className='p-2'>
        {' '}
        <Form onSubmit={handleSubmit}>
          <Row className='g-3 mb-3'>
            <p>
              Are you sure you want to delete the{' '}
              {String(deleteResourceModal?.defaultData?.name).toLowerCase()}{' '}
              resource?
            </p>
            <Col sm={12}></Col>
          </Row>

          <Flex justifyContent='end'>
            <Button
              variant='secondary'
              className='me-2'
              onClick={() => {
                setResources(prevState => ({
                  ...prevState,
                  deleteResourceModal: { open: false, defaultData: {} }
                }));
              }}>
              Cancel
            </Button>

            <Button variant='danger' type='submit' className='d-flex'>
              <Flex justifyContent={'center'} alignItems={'center'}>
                <ButtonSpinner spinning={loading} />
                <span>{loading ? 'Deleting' : 'Delete'}</span>
              </Flex>
            </Button>
          </Flex>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalResourceDelete;
