import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

const sentinelOneThreats = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'SentinelOne Threats Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-sentinel-one-threats']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Threats Discovered',
          helpInfo: 'Total number of threats discovered by SentinelOne'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-sentinel-one-threats'],
        must: [
          {
            match_phrase: {
              'threatInfo.mitigationStatusDescription.keyword': 'Not mitigated'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'threatInfo.mitigationStatusDescription',
            value: 'Not mitigated'
          },
          title: 'Unmitigated Threats',
          helpInfo: 'Total number of unmitigated threats'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-sentinel-one-threats'],
        must: [
          {
            match_phrase: {
              'threatInfo.incidentStatus.keyword': 'unresolved'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'threatInfo.incidentStatus',
            value: 'unresolved'
          },
          title: 'Unresolved Incidents',
          helpInfo: 'Total number of unresolved incidents'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['histogram'],
        overriddenIndex: ['leargas-sentinel-one-threats']
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Threat Detection Timeline'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-sentinel-one-threats'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'threatInfo.classification.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Classification',
          field: 'threatInfo.classification',
          helpInfo:
            'Category of threat intelligence data based on common characteristics such as malware families, techniques, installation methods, and more. This helps to identify and respond to threats more quickly and comprehensively.'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-sentinel-one-threats'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'threatInfo.confidenceLevel.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Confidence Level',
          field: 'threatInfo.confidenceLevel',
          helpInfo:
            'How certain the SentinelOne platform is that a threat is indeed malicious. It takes into account factors such as the type of behavior observed, the level of similarity to known threats, and other contextual information.'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-sentinel-one-threats'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'threatInfo.analystVerdictDescription.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Analyst Verdict',
          field: 'threatInfo.analystVerdictDescription',
          helpInfo:
            "A comprehensive assessment of a threat's potential impact and likelihood of occurrence, helping users make informed decisions to protect their enterprise against cyber attacks."
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-sentinel-one-threats'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'threatInfo.mitigationStatusDescription.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Mitigation Status',
          field: 'threatInfo.mitigationStatusDescription',
          helpInfo:
            'Actions taken by SentinelOne to address threats and whether they were successfully contained or prevented, and the level of user involvement required for further mitigation.'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-sentinel-one-threats'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'threatInfo.incidentStatusDescription.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Incident Status',
          field: 'threatInfo.incidentStatusDescription',
          helpInfo:
            'Incident statuses help security teams monitor and prioritize incidents in real-time to effectively respond to potential threats.'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-sentinel-one-threats'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'threatInfo.engines.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Detection Engines',
          field: 'threatInfo.engines',
          helpInfo:
            'Artificial intelligence and machine learning algorithm designed to proactively detect and respond to threats in real-time.'
        }
      }
    }
  ];
};

export default sentinelOneThreats;
