import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

/**
 * custom panel for pdf controls
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ControlPanel = ({
  downloadFile,
  pageNumber,
  numPages,
  setPageNumber,
  scale,
  setScale
}) => {
  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <div className='control-panel m-3 p-3 d-flex align-items-baseline justify-content-between'>
      {numPages && (
        <>
          {' '}
          <div className='d-flex justify-content-between align-items-baseline'>
            {/* <FontAwesomeIcon icon='fast-backward' className='me-2' onClick={goToFirstPage} /> */}
            <FontAwesomeIcon
              icon='backward'
              className='me-2 cursor-pointer'
              onClick={goToPreviousPage}
            />

            <span>
              <>
                {' '}
                Page {pageNumber}{' '}
                {/* <input name='pageNumber' type='number' min={1} max={numPages || 1} className='p-0 pl-1 mx-2' value={pageNumber} onChange={onPageChange} />  */}
                of {numPages}
              </>
            </span>
            <FontAwesomeIcon
              icon='forward'
              className='mx-2 cursor-pointer'
              onClick={goToNextPage}
            />
            {/* <FontAwesomeIcon icon='fast-forward' className='me-2' onClick={goToLastPage} /> */}
          </div>
          <div className='d-flex justify-content-between align-items-baseline'>
            <FontAwesomeIcon
              icon='download'
              className='me-2 cursor-pointer'
              onClick={downloadFile}
            />
            <FontAwesomeIcon
              icon='search-minus'
              className='mx-2 cursor-pointer'
              onClick={zoomOut}
            />
            {/* <span>{(scale * 100).toFixed()}%</span> */}
            <FontAwesomeIcon
              icon='search-plus'
              className='mx-2 cursor-pointer'
              onClick={zoomIn}
            />
          </div>
        </>
      )}
    </div>
  );
};

ControlPanel.propTypes = {
  downloadFile: PropTypes.func,
  pageNumber: PropTypes.number,
  numPages: PropTypes.number,
  setPageNumber: PropTypes.func,
  scale: PropTypes.number,
  setScale: PropTypes.func
};

export default ControlPanel;
