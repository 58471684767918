import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import SearchValueContextMenu from 'components/search-filters/SearchValueContextMenu';
import { maskSensitiveData } from 'helpers/utils';
import useApplication from 'hooks/useApplication';
import useExplore from 'hooks/useExplore';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Tab, Table, Tabs } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactJson from 'react-json-view';
import { toast } from 'react-toastify';

import { v4 as uuidv4 } from 'uuid';
import Flex from '../Flex';

/**
 * Component for rendering an expanding row in the ExploreTable.
 *
 * @component
 * @param {Object} row - The row object containing the data for the expanding row
 * @returns {JSX.Element} - The JSX element representing the ExploreTableExpandingRow
 */
const ExploreTableExpandingRow = ({ cells }) => {
  const {
    application: { isDark, isPrivacyMode }
  } = useApplication();

  const {
    state: { fields }
  } = useExplore();

  const [json, setJSON] = useState({});
  const [jsonNonPrivate, setJSONNonPrivate] = useState({});
  const [maskedJson, setMaskedJson] = useState({});

  useEffect(() => {
    const json = {};
    Object.entries(cells)
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
      .forEach(([key, value]) => {
        json[key] = value;
      });
    // console.log('json', json);
    setJSONNonPrivate(json);
    let maskedJson = maskSensitiveData(json, fields, isPrivacyMode);
    setJSON(json);
    setMaskedJson(maskedJson);
  }, [cells, isPrivacyMode]);

  const displayedJson = isPrivacyMode ? maskedJson : json;

  return (
    <div>
      <Tabs defaultActiveKey='Table' id={uuidv4()}>
        <Tab
          eventKey='Table'
          title='List'
          className='border-none'
          tabClassName='fw-normal fs--2 outline-none'>
          <Table hover size='sm'>
            <tbody>
              {Object.entries(cells)
                // Sort alphabetically by key
                .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                .map(([key, value], index) => (
                  <tr key={index}>
                    <td
                      className={classNames(
                        'fs--2 align-middle pe-2 text-800 py-1',
                        {
                          'border-bottom-0':
                            index === Object.keys(cells).length - 1
                        }
                      )}
                      style={{ width: '200px' }}>
                      {key}
                    </td>
                    <td
                      className={classNames('fs--2 align-middle py-0', {
                        'border-bottom-0':
                          index === Object.keys(cells).length - 1
                      })}>
                      <Flex alignItems='center' className='text-800 text-break'>
                        {['@timestamp', '@version', '_id'].includes(key) ? (
                          <SearchValueContextMenu
                            searchValue={value}
                            searchKey={key}
                            valueType={
                              fields.find(field => field.id === key)?.type
                            }
                            showFilter={false}
                            showQuickFilter={false}
                            showResources={false}
                          />
                        ) : (
                          <SearchValueContextMenu
                            searchValue={value}
                            searchKey={key}
                            valueType={
                              fields.find(field => field.id === key)?.type
                            }
                          />
                        )}
                      </Flex>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Tab>

        <Tab
          eventKey='Featured JSON'
          title='JSON'
          className='border-none mt-0 p-3 pt-2'
          tabClassName='fw-normal fs--2 outline-none'>
          <CopyToClipboard
            text={JSON.stringify(jsonNonPrivate, null, 4)}
            onCopy={() => toast.success('Copied to clipboard!')}>
            <Button
              size='sm'
              variant='link'
              className='outline-none py-0 px-0 me-2 fs--2 text-decoration-none'>
              <FontAwesomeIcon icon='clipboard' className='fs--2' /> Copy to
              Clipboard
            </Button>
          </CopyToClipboard>

          <div className='fs--2'>
            <ReactJson
              src={displayedJson}
              collapsed={4}
              displayDataTypes={false}
              name={false}
              enableClipboard={copy => {
                let copiedValue = copy?.name ? json[copy.name] : json;
                if (typeof copiedValue === 'object') {
                  copiedValue = JSON.stringify(copiedValue, null, 4);
                } else {
                  copiedValue = String(copiedValue);
                }
                navigator.clipboard.writeText(copiedValue);
                toast.success('Copied to clipboard!');
              }}
              theme={isDark ? 'grayscale' : 'grayscale:inverted'}
              style={{ backgroundColor: 'transparent' }}
            />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

ExploreTableExpandingRow.propTypes = {
  cells: PropTypes.object
};

export default ExploreTableExpandingRow;
