import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

const darkweb = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'Darkweb Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-darkweb']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Hits'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-darkweb'],
        must: [
          {
            match_phrase: {
              tor: '1'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'TOR Hits',
          showFilterIcon: true,
          filterData: {
            field: 'tor',
            value: 1
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-darkweb'],
        must: [
          {
            match_phrase: {
              'type.keyword': 'forum_results'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Forum Hits'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-darkweb'],
        must: [
          {
            match_phrase: {
              'type.keyword': 'shodan_results'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Shodan Hits'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-darkweb'],
        must: [
          {
            match_phrase: {
              ssnFound: true
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Social Security Numbers'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-darkweb'],
        must: [
          {
            match_phrase: {
              creditCardFound: true
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Credit Card Numbers'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['histogram'],
        overriddenIndex: ['leargas-darkweb']
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Event Timeline'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-darkweb'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'keywordFound.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Keywords',
          field: 'keywordFound'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-darkweb'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'type.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_all: {}
          },
          {
            query_string: {
              query:
                'NOT hit: "(?:https?:\\/\\/)?(?:www)?(\\S*?\\.onion)\\b(\\/?\\S*)"',
              analyze_wildcard: true,
              time_zone: 'America/New_York'
            }
          }
        ]
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Types',
          field: 'type'
        }
      }
    }
  ];
};

export default darkweb;
