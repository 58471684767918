import ActiveShards from 'components/dashboards/System/SearchCluster/Health/ActiveShards';
import DocumentsCount from 'components/dashboards/System/SearchCluster/Health/ArtifactsCount';
import DataNodeCount from 'components/dashboards/System/SearchCluster/Health/DataNodeCount';
import HeroUnit from 'components/dashboards/System/SearchCluster/Health/HeroUnit';
import IndicesCount from 'components/dashboards/System/SearchCluster/Health/IndicesCount';
import InitializingShards from 'components/dashboards/System/SearchCluster/Health/InitializingShards';
import JVMHeapUsage from 'components/dashboards/System/SearchCluster/Health/JVMHeapUsage';
import NodeCount from 'components/dashboards/System/SearchCluster/Health/NodeCount';
import PendingTasks from 'components/dashboards/System/SearchCluster/Health/PendingTasks';
import RelocatingShards from 'components/dashboards/System/SearchCluster/Health/RelocatingShards';
import StorageUsage from 'components/dashboards/System/SearchCluster/Health/StorageUsage';
import UnassignedShards from 'components/dashboards/System/SearchCluster/Health/UnassignedShards';
import Cluster from 'context/ClusterContext';
import { Col, Row } from 'react-bootstrap';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';

const ElasticsearchHealth = () => {
  return (
    <>
      <WidgetSectionTitle
        title='Search Cluster Health Overview'
        className='mb-2'
      />
      <Cluster>
        <Row className='g-1 mb-1'>
          <Col sm={12}>
            <HeroUnit />
          </Col>
        </Row>

        <Row className='g-1 mb-1'>
          <Col xs={12} md={8} lg={4}>
            <DocumentsCount />
          </Col>
          <Col xs={6} md={4} lg={2}>
            <PendingTasks />
          </Col>
          <Col xs={6} md={4} lg={2}>
            <InitializingShards />
          </Col>
          <Col xs={6} md={4} lg={2}>
            <RelocatingShards />
          </Col>
          <Col xs={6} md={4} lg={2}>
            <UnassignedShards />
          </Col>
        </Row>

        <Row className='g-1 mb-1'>
          <Col md={4} xxl={2} style={{ minHeight: '175px' }}>
            <NodeCount />
          </Col>
          <Col md={4} xxl={2} style={{ minHeight: '175px' }}>
            <DataNodeCount />
          </Col>
          <Col md={4} xxl={2} style={{ minHeight: '175px' }}>
            <IndicesCount />
          </Col>
          <Col md={4} xxl={2} style={{ minHeight: '175px' }}>
            <ActiveShards />
          </Col>
          <Col md={4} xxl={2} style={{ minHeight: '175px' }}>
            <JVMHeapUsage />
          </Col>
          <Col md={4} xxl={2} style={{ minHeight: '175px' }}>
            <StorageUsage />
          </Col>
        </Row>
      </Cluster>
    </>
  );
};

export default ElasticsearchHealth;
