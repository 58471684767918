export const columnDefaults = () => {
  const columns = [
    {
      accessor: 'slug',
      Header: 'slug',
      noTruncate: true,

      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle'
      }
    },
    {
      accessor: 'indices',
      Header: 'indices',
      noTruncate: true,

      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle'
      }
    },

    {
      accessor: 'path',
      Header: 'path',
      noTruncate: true,

      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle'
      }
    },
    {
      accessor: 'allowedRoles',
      Header: 'allowedRoles',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap cursor-default',
        style: { width: '12%' }
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle'
      },
      Cell: () => {
        // let allowedRoles = rowData.row.original.allowedRoles;
        return '-';
        // return allowedRoles ? (
        //   <OverlayTrigger placement='top'   overlay={<Popover>
        // <Popover.Body className='text-center p-1 fs--1 text-700'>{allowedRoles && allowedRoles.map(item => item)}</Popover.Body>;
        // </Popover>
        // }>
        //     <div className='d-inline'>{allowedRoles}</div>
        //   </OverlayTrigger>
        // ) : (
        //   <span>-</span>
        // );
      }
    }
  ];

  return columns;
};

export default columnDefaults;
