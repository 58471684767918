import React, { useState } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import { routes } from 'config/routeSettings';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Forgot password page
 *
 * The forgot password page is used to display a form to the user to request a password reset link.
 * @returns {React.Component} The forgot password page
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const ForgetPassword = () => {
  let [sent, setSent] = useState(false);

  return (
    <div className='text-center'>
      {sent ? (
        <>
          <h5 className='mb-2'>Password reset link sent!</h5>
          <p>Check your email for instructions to reset your password.</p>
          <Button as={Link} color='primary' to={routes.LOGIN}>
            <FontAwesomeIcon
              icon='chevron-left'
              transform='shrink-4 down-1'
              className='me-1'
            />
            Return to login
          </Button>
        </>
      ) : (
        <>
          <h5 className='mb-2'>Forgot your password?</h5>
          <p>
            Enter your email address and we'll send you a password reset link.
          </p>
          <ForgotPasswordForm sent={sent} setSent={setSent} />
        </>
      )}
    </div>
  );
};

export default ForgetPassword;
