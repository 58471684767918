import LeargasAPI from 'api';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

export const indicesSettings = {
  addIndiceModal: {
    fullScreen: false,
    open: false
  },
  data: [],
  deleteIndiceModal: {
    data: {},
    open: false
  },
  editIndiceModal: {
    data: {},
    fullScreen: false,
    open: false
  },
  fetch: false,
  loading: false,
  requiredIndices: {
    indices: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    },
    indicesNotes: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    indices: {
      delete: ['indices:delete'],
      read: ['indices:read'],
      write: ['indices:write']
    },
    indicesNotes: {
      delete: ['indicesNotes:delete'],
      read: ['indicesNotes:read'],
      write: ['indicesNotes:write']
    }
  },
  showDeleted: false
};

/**
 * Create IndicesContext
 * @type {React.Context<{}>}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const IndicesContext = createContext(indicesSettings);

/**
 * Create IndicesProvider
 * @param props
 * @returns {JSX.Element}
 * @constructor
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 *  <IndicesProvider>
 *   {children}
 * </IndicesProvider>
 * )
 */
const IndicesProvider = props => {
  const [indices, setIndices] = useState(indicesSettings);
  const [loading, setLoading] = useState(true);

  const { axiosPrivate } = useAxiosPrivate();

  /**
   * Get all application Indices
   * @returns {Promise<void>}
   * @async
   * @function
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const getAppIndices = async () => {
    try {
      let res = await LeargasAPI.Indices.getIndices(axiosPrivate);
      if (res instanceof Error) throw res;
      setIndices(prevState => ({
        ...prevState,
        data: res
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setIndices(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setIndices(prevState => ({
        ...prevState,
        fetch: false
      }));
      setLoading(false);
    }
  };

  /**
   * Get all application Indices on fetch state change
   */
  useEffect(() => {
    if (indices.fetch) {
      getAppIndices();
    }
  }, [indices.fetch]);

  /**
   * Get all application Indices on component mount
   */
  useEffect(() => {
    getAppIndices();
  }, []);

  return (
    <IndicesContext.Provider
      value={{
        indices,
        setIndices,
        loading
      }}>
      {props.children}
    </IndicesContext.Provider>
  );
};

IndicesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default IndicesProvider;
