import React from 'react';
import PropTypes from 'prop-types';
import AlertsProvider from 'context/user-contexts/UserAlertsProvider';
import AlertsLayout from './AlertsLayout';
import ModalAlertView from './components/modals/AlertsViewModal';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const AlertsWrapper = props => {
  return (
    <AlertsProvider>
      {props.children}
      <AlertsLayout />
      <ModalAlertView />
    </AlertsProvider>
  );
};

AlertsWrapper.propTypes = { children: PropTypes.node };

export default AlertsWrapper;
