import SavedSearchesProvider from 'context/user-contexts/UserSavedSearchesProvider';
import PropTypes from 'prop-types';
import React from 'react';
import SavedSearchesLayout from './SavedSearchesLayout';
import ModalSavedSearchesDelete from './components/modals/SavedSearchesDeleteModal';
import ModalSavedSearchesEdit from './components/modals/SavedSearchesEditModal';

/**
 * Renders the SavedSearchesWrapper component
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const SavedSearchesWrapper = props => {
  return (
    <SavedSearchesProvider>
      {props.children}
      <SavedSearchesLayout />
      <ModalSavedSearchesDelete />
      <ModalSavedSearchesEdit />
    </SavedSearchesProvider>
  );
};

SavedSearchesWrapper.propTypes = {
  children: PropTypes.node
};

export default SavedSearchesWrapper;
