import ModalReleaseAdd from 'components/dashboards/System/Application/Manage/Releases/modals/ModalReleaseAdd';
import ModalReleaseDelete from 'components/dashboards/System/Application/Manage/Releases/modals/ModalReleaseDelete';
import ModalReleaseEdit from 'components/dashboards/System/Application/Manage/Releases/modals/ModalReleaseEdit';
import ModalReleaseNoteAdd from 'components/dashboards/System/Application/Manage/Releases/modals/ModalReleaseNoteAdd';
import ModalReleaseNoteDelete from 'components/dashboards/System/Application/Manage/Releases/modals/ModalReleaseNoteDelete';
import ModalReleaseNoteEdit from 'components/dashboards/System/Application/Manage/Releases/modals/ModalReleaseNoteEdit';
import ReleasesProvider from 'context/admin-contexts/ReleasesProvider';
import React from 'react';
import ChangeLogLayout from './ChangeLogLayout';

/**
 * Renders the ChangeLogWrapper component
 *
 * @returns {JSX.Element} The rendered ChangeLogWrapper component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const ChangeLogWrapper = () => (
  <>
    <ReleasesProvider>
      <ChangeLogLayout />
      <ModalReleaseAdd />
      <ModalReleaseEdit />
      <ModalReleaseDelete />
      <ModalReleaseNoteAdd />
      <ModalReleaseNoteEdit />
      <ModalReleaseNoteDelete />
    </ReleasesProvider>
  </>
);

export default ChangeLogWrapper;
