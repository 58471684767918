import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import MapComp from './MapComp';

/**
 * Parent component for drag drop
 * @param {Object} Props.items
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ColWithDragAndDropItems = ({ items }) => {
  const [colItems, setColItems] = useState([]);
  // const [loaded, setLoaded] = useState(false);

  /**
   * Setting the item to be mapped
   */
  useEffect(() => {
    let newItems = [...JSON.parse(JSON.stringify(items))];
    setColItems(newItems);
  }, [items]);

  /**
   *  MoveItem is the event where we shuffle the objects in array when item is dropped
   * @param {Number} fromIndex
   * @param {Number} toIndex
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  return <MapComp colItems={colItems} />;
};

ColWithDragAndDropItems.propTypes = {
  items: PropTypes.array
};

export default ColWithDragAndDropItems;
