// import { axiosPrivate } from 'api/connection/axios';
import { apiRoutes } from 'config/routeSettings';
import useAuthentication from './useAuthentication';
// import useAxiosPrivate from 'hooks/useAxiosPrivate';
// import axios from 'axios';

/**
 * Handle terminating the user's session
 *
 * @returns {Function} logout
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 *
 * @example
 * import useLogout from 'hooks/useLogout';
 * const logout = useLogout();
 * logout();
 */
export const useLogout = () => {
  const { setAuthentication } = useAuthentication();
  // const { updateGlobalFilters } = useApplication();
  /**
   * Terminate the user's session
   * @returns {void} Nothing
   * @async
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const logout = async axiosPrivate => {
    try {
      setAuthentication(prevState => ({
        ...prevState,
        persistLoader: true
      }));
      await axiosPrivate.get(apiRoutes.LOGOUT);
      setTimeout(function () {
        setAuthentication({});
      }, 1000);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setAuthentication(prevState => ({
        ...prevState,
        persistLoader: false
      }));
    }
  };
  return logout;
};

export default useLogout;
