import classNames from 'classnames';
import Logo from 'components/common/Logo';
import ScopesSelectorButton from 'components/common/scope-selector/ScopesSelectorButton';
import {
  navbarBreakPoint,
  topNavbarBreakpoint
} from 'context/ApplicationProvider';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Navbar } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';

const NavbarTop = () => {
  const {
    application: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setApplication
  } = useApplication();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });

  const handleBurgerMenu = () => {
    navbarPosition === 'top' &&
      setApplication('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setApplication('showBurgerMenu', !showBurgerMenu);
  };

  return (
    <Navbar
      className={classNames('fs--1 navbar-top')}
      expand={
        navbarPosition === 'top' || navbarPosition === 'combo'
          ? topNavbarBreakpoint
          : true
      }>
      <Logo at='navbar-top' id='topLogo' />
      {!isLargeScreen && <ScopesSelectorButton />}

      <TopNavRightSideNavItem>
        <Navbar.Toggle
          className={classNames('toggle-icon-wrapper mt-1 me-0 pe-0', {
            'd-lg-none': navbarPosition === 'top',
            [`d-${navbarBreakPoint}-none`]:
              navbarPosition === 'vertical' || navbarPosition === 'combo'
          })}
          as='div'>
          <button
            className='navbar-toggler-humburger-icon btn btn-link d-flex flex-center'
            onClick={handleBurgerMenu}
            id='burgerMenu'>
            <span className='navbar-toggle-icon'>
              <span className='toggle-line' />
            </span>
          </button>
        </Navbar.Toggle>
      </TopNavRightSideNavItem>
    </Navbar>
  );
};

export default NavbarTop;
