import Moment from 'react-moment';
import Flex from 'components/common/Flex';
import routes from 'config/routeSettings';
import { Link } from 'react-router-dom';
import { Image, Placeholder, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const { default: CardDropdown } = require('components/common/CardDropdown');

/**
 *
 * @param {Function} setArticles
 * @returns {Array}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const getArticleColumns = setArticles => {
  return [
    {
      accessor: 'thumbnail',
      Header: 'Thumbnail',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'ps-0 fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'ps-0 fs--1 align-middle text-nowrap border-0',
        style: { width: '10%' }
      },
      Cell: rowData => {
        const { thumbnail, slug } = rowData.row.original;

        return thumbnail ? (
          <Link to={[routes.ARTICLES, slug].join('/')}>
            <Image width={'100%'} src={thumbnail} className='rounded-3' />
          </Link>
        ) : (
          <Link to={[routes.ARTICLES, slug].join('/')}>
            <Placeholder className='rounded-3 fs--2 cursor-default testRatio position-relative'>
              <Flex
                justifyContent='center'
                alignItems='center'
                className='text-light position-absolute all-0'>
                No Image
              </Flex>
            </Placeholder>
          </Link>
        );
      }
    },
    {
      accessor: 'title',
      Header: 'Title',
      noTruncate: true,
      hasRender: false,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap border-0'
      },
      Cell: rowData => {
        const { title, slug } = rowData.row.original;
        return (
          <Link
            to={[routes.ARTICLES, slug].join('/')}
            className='text-decoration-none'>
            {title}
          </Link>
        );
      }
    },
    {
      accessor: 'createdBy',
      Header: 'Author',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap border-0'
      },
      Cell: rowData => {
        const { createdBy } = rowData.row.original;
        return <div>{createdBy?.profile?.fullName}</div>;
      }
    },
    {
      accessor: 'updatedAt',
      Header: 'Updated',
      noTruncate: true,
      hasRender: false,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap border-0'
      },
      Cell: rowData => {
        const { updatedAt } = rowData.row.original;
        return <Moment date={updatedAt} fromNow />;
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      noTruncate: true,
      hasRender: false,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap border-0'
      }
    },
    {
      accessor: 'leargas_actions',
      Header: '',
      cellProps: {
        className: 'text-end text-400 actions-cell',
        style: {
          width: '42px'
        }
      },
      hideDisplaySettings: true,
      disableSortBy: true,
      Cell: rowData => {
        return (
          <Flex justifyContent='center' alignContent='middle'>
            <CardDropdown style={{ zIndex: 9999, position: 'absolute' }}>
              <div className='py-2'>
                <Dropdown.Item
                  onClick={() =>
                    setArticles(prevState => ({
                      ...prevState,
                      editArticleModal: {
                        open: true,
                        defaultData: rowData.row.original
                      }
                    }))
                  }>
                  <FontAwesomeIcon icon='pencil' className='me-2' />
                  Edit Details
                </Dropdown.Item>
                <div className='dropdown-divider'></div>
                <Dropdown.Item
                  className={`text-danger`}
                  onClick={() => {
                    // isDeleted
                    //   ?
                    // setOrganizations(prevState => ({
                    //     ...prevState,
                    //     orgRestoreModal: { open: true, data: rowData.row.original }
                    //   }))
                    // :
                    setArticles(prevState => ({
                      ...prevState,
                      deleteArticleModal: {
                        open: true,
                        defaultData: rowData.row.original
                      }
                    }));
                  }}>
                  <FontAwesomeIcon
                    icon={
                      // isDeleted ? 'trash-restore-alt' :
                      'trash'
                    }
                    className='me-2'
                  />
                  {/* {isDeleted ? 'Restore' : */}
                  Delete
                  {/* } */}
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </Flex>
        );
      }
    }
  ];
};
