import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import ReleaseActions from './ReleaseActions';
import ReleaseStatus from './ReleaseStatus';
import { Col, Row } from 'react-bootstrap';

/**
 * Renders the ReleaseHeading component
 *
 * @param {object} props Component properties
 * @param {object} props.release The release object
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ReleaseHeading = ({ release }) => {
  return (
    <Row className='g-3 mb-2'>
      <Col xs={12} style={{ marginTop: '20px', marginBottom: '0px' }}>
        <Flex direction='column' className='mb-2'>
          <Flex
            direction='row'
            alignItems='start'
            className='pb-2'
            style={{ marginTop: '-4px' }}>
            <h2 className='fs-2 text-800 mb-0 me-2'>{release.tag}</h2>
            <ReleaseActions release={release} />
          </Flex>
          <ReleaseStatus release={release} />
        </Flex>
      </Col>
    </Row>
  );
};

ReleaseHeading.propTypes = {
  release: PropTypes.shape({
    tag: PropTypes.string.isRequired
  }).isRequired
};

export default ReleaseHeading;
