import JobsProvider from 'context/admin-contexts/JobsProvider';
import PropTypes from 'prop-types';
import React from 'react';
import JobsLayout from './JobsLayout';
import ModalJobDelete from './components/modals/JobsDeleteModal';

/**
 * Jobs wrapper
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const JobsWrapper = props => {
  return (
    <JobsProvider>
      {props.children}
      <JobsLayout />
      <ModalJobDelete />
    </JobsProvider>
  );
};

JobsWrapper.propTypes = { children: PropTypes.node };

export default JobsWrapper;
