import update from 'immutability-helper';
import { useCallback, useState } from 'react';
import { DropCard } from './Card.js';

export const Container = () => {
  {
    const [cards, setCards] = useState([
      {
        id: 1,
        icon: ['fas', 'binoculars'],
        text: 'Explore'
      },
      {
        id: 2,
        icon: ['fas', 'cloud'],
        text: 'Cloud'
      },
      {
        id: 3,
        icon: ['fas', 'skull-crossbones'],
        text: 'Darkweb'
      },
      {
        id: 4,
        icon: ['fas', 'virus'],
        text: 'Endpoint'
      },
      {
        id: 5,
        icon: ['fas', 'network-wired'],
        text: 'Network'
      }
    ]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
      setCards(prevCards =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]]
          ]
        })
      );
    }, []);

    const renderCard = useCallback((card, index) => {
      return (
        <DropCard
          key={card.id}
          index={index}
          id={card.id}
          icon={card.icon}
          text={card.text}
          moveCard={moveCard}
        />
      );
    }, []);

    return (
      <>
        <div>{cards.map((card, i) => renderCard(card, i))}</div>
      </>
    );
  }
};

export default Container;
