import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import { useSearchFilters } from 'context/FiltersProvider';
import useApplication from 'hooks/useApplication';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownMenu,
  Overlay,
  Popover
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

/**
 * ManageFiltersMenu component.
 *
 * @returns {JSX.Element} The ManageFiltersMenu component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const ManageFiltersMenu = () => {
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);

  const {
    application: { isDark, showSimpleFilters, showExploreFieldsSidebar },
    setApplication
  } = useApplication();

  const {
    searchFilters: { filters, bulkActions, bulkActionsGroups },
    applyBulkAction
  } = useSearchFilters();

  const [show, setShow] = useState(false);

  /**
   * Toggles the value of `show`.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleToggle = () => {
    setShow(!show);
  };

  /**
   * Handles the selection of an action for managing filters.
   *
   * @param {string} action - The action to be performed on the filters
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleSelection = action => {
    // If there are no filters, do nothing
    if (!filters || filters.length === 0) return;

    // Close the dropdown menu
    setShow(false);
    // Apply the selected action to the filters
    applyBulkAction(action);
  };

  /**
   * Handles the click outside event to close the dropdown menu.
   *
   * @param {Event} e - The click event object
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleClickOutside = e => {
    if (
      triggerRef.current &&
      !triggerRef.current.contains(e.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      // If the click is outside of the trigger and the dropdown, close the dropdown
      setShow(false);
    }
  };

  /**
   * Adds an event listener to handle clicks outside of the dropdown menu.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    // Add the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Remove the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const popover = (
    <Popover
      id='searchFiltersBulkActionsPopover'
      style={{ minWidth: '200px', padding: 0 }}>
      <div ref={dropdownRef}>
        <Flex
          justifyContent='start'
          alignItems='Start'
          direction={'column'}
          className='w-100 p-0'>
          <DropdownMenu show={true} className='position-relative border-0'>
            <SimpleBar
              className='h-100'
              forceVisible={true}
              autoHide={false}
              style={{
                maxHeight: '70vh',
                minHeight: '50px'
              }}>
              <Dropdown.Header>Tools</Dropdown.Header>
              <div className='mb-2'>
                {filters && filters.length > 0 && (
                  <Dropdown.Item
                    onClick={() => {
                      setShow(false);
                      setTimeout(() => {
                        setApplication('showSimpleFilters', !showSimpleFilters);
                      }, 0);
                    }}>
                    <span className='pe-3'>
                      {showSimpleFilters && (
                        <FontAwesomeIcon
                          icon='slash'
                          className='position-absolute'
                          transform='down-2 left-1 rotate-350'
                        />
                      )}
                      <FontAwesomeIcon
                        icon={['fas', 'filter']}
                        width={16}
                        height={16}
                        className='me-2'
                      />
                      {showSimpleFilters ? 'Collapse' : 'Expand'} filters list
                    </span>
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onClick={() => {
                    setShow(false);
                    setTimeout(() => {
                      setApplication(
                        'showExploreFieldsSidebar',
                        !showExploreFieldsSidebar
                      );
                    }, 0);
                  }}>
                  <span className='pe-3'>
                    {showExploreFieldsSidebar && (
                      <FontAwesomeIcon
                        icon='slash'
                        className='position-absolute'
                        transform='down-2 left-1 rotate-350'
                      />
                    )}
                    <FontAwesomeIcon
                      icon={['fas', 'list-check']}
                      width={16}
                      height={16}
                      className='me-2'
                    />
                    {showExploreFieldsSidebar ? 'Collapse' : 'Expand'} fields
                    list
                  </span>
                </Dropdown.Item>
              </div>

              {filters && filters.length > 0 && (
                <>
                  <Dropdown.Header>Bulk Actions</Dropdown.Header>
                  {Object.entries(bulkActionsGroups)
                    .filter(([groupKey]) => groupKey !== 'all')
                    .map(([groupKey, group]) => {
                      return (
                        <div key={groupKey}>
                          <div>
                            {group.actions.map((actionKey, index) => {
                              const action = bulkActions[actionKey];
                              return action.canUse(filters) ? (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => handleSelection(actionKey)}>
                                  <span className='pe-3'>
                                    {action.icon && (
                                      <FontAwesomeIcon
                                        icon={action.icon}
                                        width={16}
                                        height={16}
                                        className='me-2'
                                      />
                                    )}
                                    {action.label}
                                  </span>
                                </Dropdown.Item>
                              ) : null;
                            })}
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </SimpleBar>
          </DropdownMenu>
        </Flex>
      </div>
    </Popover>
  );

  return (
    <Flex className='me-1'>
      <Flex direction='column' className='h-100'>
        <Overlay
          show={show}
          target={triggerRef.current}
          placement='bottom-start'
          offset={[0, 0]}
          rootClose={true}
          onHide={() => setShow(false)}>
          {popover}
        </Overlay>

        <AdvancedPopover
          placement='top'
          showArrow={true}
          offset={[0, 0]}
          popoverText='Toggle filtering tools or apply bulk actions to search filters'>
          <Button
            ref={triggerRef}
            id='searchFiltersBulkActionsPopoverTrigger'
            onClick={handleToggle}
            size='sm'
            tabIndex='3'
            className={classNames(
              'p-0 m-0 fs--2 border-0 shadow-none hover-primary',
              {
                'bg-100': isDark,
                'bg-200': !isDark
              }
            )}
            variant='none'
            style={{ height: '21px', width: '16px', zIndex: 1031 }}>
            <FontAwesomeIcon
              icon='bars-staggered'
              width={16}
              height={16}
              transform='grow-4'
            />
          </Button>
        </AdvancedPopover>
      </Flex>
    </Flex>
  );
};

export default ManageFiltersMenu;
