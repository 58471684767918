import React from 'react';
import PropTypes from 'prop-types';
import BookmarksProvider from 'context/user-contexts/UserBookmarksProvider';
import BookmarksLayout from './BookmarksLayout';
import ModalBookmarkView from './components/modals/BookmarksViewModal';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const BookmarksWrapper = props => {
  return (
    <BookmarksProvider>
      {props.children}
      <BookmarksLayout />
      <ModalBookmarkView />
    </BookmarksProvider>
  );
};

BookmarksWrapper.propTypes = { children: PropTypes.node };

export default BookmarksWrapper;
