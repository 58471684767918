import React from 'react';
import PropTypes from 'prop-types';
import envelope from 'assets/img/icons/spot-illustrations/16.png';
import { useSearchParams } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routes } from 'config/routeSettings';

/**
 * Confirm mail page
 *
 * The confirm mail page is used to display a message to the user that a verification email has been sent to their
 * email address. The user is normally directed to this page after they have successfully registered their account.
 * @param {Object} props The component props
 * @param {string} props.titleTag The title tag to use for the page title
 * @returns {React.Component} The confirm mail page
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const ConfirmMail = () => {
  let [searchParams] = useSearchParams();
  return (
    <Row className='g-3 mb-3'>
      <Col>
        <div className='text-center'>
          <img
            className='d-block mx-auto mb-4'
            src={envelope}
            alt='sent'
            width={100}
          />
          <h5 className='mb-3 ps-4 pt-2 fs--1'>
            Please verify your email address!
          </h5>
          <p className='fs--1 text-center'>
            A verification email has been sent to{' '}
            <strong>{searchParams.get('address')}</strong>
          </p>
          <p className='fs--1 text-center'>
            Follow the link to verify your address and activate your account.
          </p>
          <Button as={Link} color='primary' className='mt-3' to={routes.LOGIN}>
            <FontAwesomeIcon
              icon='chevron-left'
              transform='shrink-4 down-1'
              className='me-1'
            />
            Return to login
          </Button>
        </div>
      </Col>
    </Row>
  );
};

ConfirmMail.propTypes = {
  titleTag: PropTypes.string
};

ConfirmMail.defaultProps = { titleTag: 'h4' };

export default ConfirmMail;
