import AccountActivate from './library/AccountActivate';
import AccountAlerts from './library/AccountAlerts';
import AccountAuth from './library/AccountAuth';
import AccountDetails from './library/AccountDetails';
import AccountOrganizations from './library/AccountOrganizations';
import AccountRegister from './library/AccountRegister';
import AccountSecurity from './library/AccountSecurity';
import Activities from './library/Activities';
import AppAlerts from './library/AppAlerts';
import Appliances from './library/AppAppliances';
import AppArticles from './library/AppArticles';
import AppBookmarks from './library/AppBookmarks';
import Organizations from './library/AppOrganizations';
import AppProducts from './library/AppProducts';
import Releases from './library/AppReleases';
import AppReports from './library/AppReports';
import AppSavedSearches from './library/AppSavedSearches';
import AppSubscriptionLevels from './library/AppSubscriptionLevels';
import AppTeams from './library/AppTeams';
import Users from './library/AppUsers.js';
import Boards from './library/Boards';
import Cases from './library/Cases';
import Dashboards from './library/Dashboards';
import Fields from './library/Fields';
import Indices from './library/Indices';
import Jobs from './library/Jobs';
import Locations from './library/Locations';
import MangeReports from './library/MangeReports';
import Notifications from './library/Notifications';
import Resources from './library/Resources';
import Roles from './library/Roles';
import SavedSearches from './library/SavedSearches';
import Scans from './library/Scans';
import Scopes from './library/Scopes';
import Services from './library/Services';
import Statuses from './library/Statuses';
import SubscriptionLevels from './library/SubscriptionLevels';
import Teams from './library/Teams';
import Threats from './library/Threats';
import Tickets from './library/TicketsTemp';
import UserRoles from './library/UserRoles';
import routes from './routes';

/**
 * Leargas API Javascript Library
 * @description API library class to access all Léargas API endpoints
 * @class LeargasAPI
 * @method AccountActivate Account Activation api
 * @method AccountAuth Account Authentication api
 * @method AccountDetails Account Details api
 * @method AccountRegister Account Registration api
 * @method AccountSecurity Account Security api
 * @method Activities Activities api
 * @method Appliances Appliances api
 * @method Boards Boards api
 * @method Cases Cases api
 * @method Dashboards Dashboards api
 * @method Fields Fields api
 * @method Indices Indices api
 * @method Jobs Jobs api
 * @method Locations Locations api
 * @method Notifications Notifications api
 * @method Organizations Organizations api
 * @method Statuses Statuses api
 * @method AccountOrganizations Account Organizations api
 * @method AccountAlerts Account Alerts api
 * @method Resources Resources api
 * @method Releases Releases api
 * @method Roles Roles api
 * @method Scans Scans api
 * @method Scopes Scopes api
 * @method Services Services api
 * @method SubscriptionLevels Subscription Levels api
 * @method AppProducts App Products api
 * @method AppSubscriptionLevels App Subscription Levels api
 * @method AppTeams App Teams api
 * @method AppSavedSearches App Saved Searches api
 * @method AppBookmarks App Bookmarks api
 * @method AppReports App Reports api
 * @method MangeReports Manage Reports api
 * @method AppAlerts App Alerts api
 * @method Teams Teams api
 * @method Threats Threats api
 * @method Tickets Tickets api
 * @method UserRoles User Roles api
 * @method Users Users api
 * @param {object} routes - The API routes
 * @returns {Object} - The API classes
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.2
 */
class LeargasAPI {
  constructor(routes) {
    // API routes
    this.routes = routes;

    // API classes
    this.AccountActivate = new AccountActivate(this.routes.account.activate);
    this.AccountAuth = new AccountAuth(this.routes.account.auth);
    this.AccountDetails = new AccountDetails(this.routes.account.details);
    this.AccountRegister = new AccountRegister(this.routes.account.register);
    this.AccountSecurity = new AccountSecurity(this.routes.account.security);
    this.Activities = new Activities(this.routes.Activities);
    this.Appliances = new Appliances(this.routes.Appliances);
    this.Boards = new Boards(this.routes.Boards);
    this.Cases = new Cases(this.routes.Cases);
    this.Dashboards = new Dashboards(this.routes.Dashboards);
    this.Fields = new Fields(this.routes.Fields);
    this.Indices = new Indices(this.routes.Indices);
    this.Jobs = new Jobs(this.routes.Jobs);
    this.Locations = new Locations(this.routes.Locations);
    this.Notifications = new Notifications(this.routes.Notifications);
    this.AppArticles = new AppArticles();
    this.Organizations = new Organizations(this.routes.Organizations);
    this.Statuses = new Statuses(this.routes.Statuses);
    this.AccountOrganizations = new AccountOrganizations(
      this.routes.accountOrganizations
    );
    this.AccountAlerts = new AccountAlerts(this.routes.accountAlerts);
    this.Resources = new Resources(this.routes.Resources);
    this.Releases = new Releases(this.routes.Releases);
    this.Roles = new Roles(this.routes.Roles);
    this.Scans = new Scans(this.routes.Scans);
    this.Scopes = new Scopes(this.routes.Scopes);
    this.Services = new Services(this.routes.Services);
    this.SubscriptionLevels = new SubscriptionLevels(
      this.routes.SubscriptionLevels
    );
    this.AppProducts = new AppProducts(this.routes.AppProducts);
    this.AppSubscriptionLevels = new AppSubscriptionLevels(
      this.routes.AppSubscriptionLevels
    );
    this.AppTeams = new AppTeams(this.routes.AppTeams);
    this.AppSavedSearches = new AppSavedSearches(this.routes.AppSavedSearches);
    this.SavedSearches = new SavedSearches(this.routes.SavedSearches);
    this.AppBookmarks = new AppBookmarks(this.routes.AppBookmarks);
    this.AppReports = new AppReports(this.routes.AppReports);
    this.MangeReports = new MangeReports(this.routes.MangeReports);
    this.AppAlerts = new AppAlerts(this.routes.AppAlerts);

    this.Teams = new Teams(this.routes.Teams);
    this.Threats = new Threats(this.routes.Threats);
    this.Tickets = new Tickets(this.routes.Tickets);
    this.UserRoles = new UserRoles(this.routes.roles);
    this.Users = new Users(this.routes.Users);
  }
}

export default new LeargasAPI(routes);
