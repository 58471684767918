import LeargasAPI from 'api';
import useAuthentication from 'hooks/useAuthentication';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

export const reportsSettings = {
  addReportModal: {
    fullScreen: false,
    open: false
  },
  data: [],
  viewReportModal: {
    data: {},
    open: false
  },
  editReportModal: {
    data: {},
    fullScreen: false,
    open: false
  },
  fetch: false,
  loading: true,
  requiredReports: {
    reports: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    },
    reportsNotes: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    reports: {
      delete: ['reports:delete'],
      read: ['reports:read'],
      write: ['reports:write']
    },
    reportsNotes: {
      delete: ['reportsNotes:delete'],
      read: ['reportsNotes:read'],
      write: ['reportsNotes:write']
    }
  },
  showDeleted: false
};

/**
 * Create ReportsContext
 * @type {React.Context<{}>}
 *
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const ReportsContext = createContext(reportsSettings);

/**
 * Create ReportsProvider
 * @param props
 * @returns {JSX.Element}
 * @constructor
 *
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 *  <ReportsProvider>
 *   {children}
 * </ReportsProvider>
 * )
 */
const ReportsProvider = props => {
  const [reports, setReports] = useState(reportsSettings);
  // Start false - we only want to show loading during actual data fetch
  const [loading, setLoading] = useState(false);
  const [lastScopeIds, setLastScopeIds] = useState([]);
  const isMountedRef = useRef(false);
  const isLoadingRef = useRef(false);

  const { axiosPrivate: axiosInstance } = useAxiosPrivate();
  const axiosPrivate = useMemo(() => axiosInstance, []);
  const {
    authentication: { scopesSelected }
  } = useAuthentication();

  /**
   * Get all application Reports
   * @returns {Promise<void>}
   * @async
   * @function
   *
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  const getAppReports = useCallback(async () => {
    // Prevent concurrent fetches
    if (isLoadingRef.current) return;

    try {
      isLoadingRef.current = true;
      setLoading(true);

      let res = await LeargasAPI.AppReports.getReports(axiosPrivate);
      if (res instanceof Error) throw res;

      const filteredReports = (res?.data?.data || []).filter(report =>
        scopesSelected.some(scope => scope._id === report.organization._id)
      );

      setReports(prevState => ({
        ...prevState,
        data: filteredReports,
        fetch: false
      }));
    } catch (error) {
      console.error(error);
    } finally {
      isLoadingRef.current = false;
      setLoading(false);
    }
  }, [axiosPrivate, scopesSelected]);

  /**
   * Get all application Reports on component mount
   */
  useEffect(() => {
    const currentScopeIds = scopesSelected.map(scope => scope._id).sort();
    const scopesChanged =
      JSON.stringify(currentScopeIds) !== JSON.stringify(lastScopeIds);

    // Only fetch on mount or when scopes actually change
    if (!isMountedRef.current || scopesChanged) {
      isMountedRef.current = true;
      setLastScopeIds(currentScopeIds);
      getAppReports();
    }
  }, [scopesSelected, getAppReports]);

  return (
    <ReportsContext.Provider
      value={{
        reports,
        setReports,
        loading
      }}>
      {props.children}
    </ReportsContext.Provider>
  );
};

ReportsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ReportsProvider;
