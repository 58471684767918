import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import MultiSelect from './MultiSelect';
import AdvancedTableSearchBox from './ReportsTableSearchBox';

/**
 * DropdownFilter component for filtering table columns.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.column - The column object containing filter information.
 * @param {any} props.column.filterValue - The current filter value.
 * @param {Function} props.column.setFilter - Function to set the filter value.
 * @param {Array} props.column.preFilteredRows - Array of pre-filtered rows.
 * @param {string} props.column.id - The column ID.
 * @returns {JSX.Element} The DropdownFilter component.
 */
export function DropdownFilter({ column }) {
  const fieldRef = useRef(null);

  let { filterValue, setFilter, preFilteredRows, id } = column;

  /**
   * Generates a memoized array of unique options from the preFilteredRows based on the specified column id.
   *
   * @returns {Array} An array of unique values from the specified column in preFilteredRows.
   */
  const options = React.useMemo(() => {
    const options = new Set();

    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });

    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <MultiSelect
      ref={fieldRef}
      options={[
        ...options
          .sort((a, b) => a.localeCompare(b))
          .map(option => {
            return { label: option, value: option };
          })
      ]}
      value={{ label: filterValue, value: filterValue }}
      onChange={value => {
        setFilter(value !== null ? value.value : null);
      }}
      placeholder='Select...'
      size='sm'
      className='fs--1 font-weight-normal'
    />
  );
}

DropdownFilter.propTypes = {
  column: PropTypes.object
};

/**
 * ReportsTableFilters component renders a search box for filtering reports in a table.
 *
 * @param {Object} props - The component props.
 * @param {string} props.globalFilter - The current global filter value.
 * @param {Function} props.setGlobalFilter - Function to update the global filter value.
 * @returns {JSX.Element} The rendered component.
 */
const ReportsTableFilters = ({ globalFilter, setGlobalFilter }) => {
  return (
    <>
      <Flex>
        <AdvancedTableSearchBox
          table
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Flex>
    </>
  );
};

ReportsTableFilters.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func
};

export default ReportsTableFilters;
