import React from 'react';
import Appliances from './components/Appliances';
import { Col, Row } from 'react-bootstrap';

/**
 * Appliances layout
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const AppliancesLayout = () => {
  return (
    <Row className='g-3 mb-3'>
      <Col sm={12}>
        <Appliances />
      </Col>
    </Row>
  );
};

export default AppliancesLayout;
