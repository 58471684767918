import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
// import LeargasAPI from 'api';

export const filtersSettings = {
  addFilterModal: {
    fullScreen: false,
    open: false
  },
  data: [],

  editFilterModal: {
    data: {},
    fullScreen: false,
    open: false
  },
  fetch: false,
  loading: false,
  requiredFilters: {
    filters: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    },
    filtersNotes: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    filters: {
      delete: ['filters:delete'],
      read: ['filters:read'],
      write: ['filters:write']
    },
    filtersNotes: {
      delete: ['filtersNotes:delete'],
      read: ['filtersNotes:read'],
      write: ['filtersNotes:write']
    }
  },
  showDeleted: false
};

/**
 * Create FiltersContext
 * @type {React.Context<{}>}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const FiltersContext = createContext(filtersSettings);

/**
 * Create FiltersProvider
 * @param props
 * @returns {JSX.Element}
 * @constructor
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 *  <FiltersProvider>
 *   {children}
 * </FiltersProvider>
 * )
 */

const FiltersProvider = props => {
  const [filters, setFilters] = useState(filtersSettings);
  const [loading] = useState(true);

  /**
   * Get all application Filters
   * @returns {Promise<void>}
   * @async
   * @function
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  //   const getAppFilters = async () => {
  //     try {
  //       let res = await LeargasAPI.AppFilters.getFilters();
  //       if (res instanceof Error) throw res;

  //       setFilters(prevState => ({
  //         ...prevState,
  //         data: res?.data?.data
  //       }));
  //     }  catch (error) {
  // if (error.message.includes('Network Error')) {
  //   console.error('Network Error occurred.');
  // }
  // console.error(err); //
  //     } finally {
  //       setFilters(prevState => ({
  //         ...prevState,
  //         fetch: false
  //       }));
  //       setLoading(false);
  //     }
  //   };

  /**
   * Get all application Filters on fetch state change
   */
  //   useEffect(() => {
  //     if (filters.fetch) {
  //       getAppFilters();
  //     }
  //   }, [filters.fetch]);

  /**
   * Get all application Filters on component mount
   */
  //   useEffect(() => {
  //     getAppFilters();
  //   }, []);

  return (
    <FiltersContext.Provider
      value={{
        filters,
        setFilters,
        loading
      }}>
      {props.children}
    </FiltersContext.Provider>
  );
};

FiltersProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default FiltersProvider;
