import PropTypes from 'prop-types';
import React from 'react';
import Flex from './Flex';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Renders a button for adding an item
 *
 * @param {Object} props - The component props
 * @param {string} props.label - The label for the button
 * @returns {JSX.Element} The rendered AddItemButton component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const AddItemButton = ({ label }) => {
  return (
    <>
      <Button
        size='sm'
        variant='link'
        className='m-0 p-0 text-decoration-none add-item-button'>
        <Flex alignItems='center' className='position-relative'>
          <span className='fa-stack ms-n1 me-1'>
            <FontAwesomeIcon
              icon={['fas', 'circle']}
              transform='shrink-1'
              className='fa-stack-2x button-icon-back'
            />
            <FontAwesomeIcon
              icon='plus'
              className='fa-stack-1x text-light'
              inverse
            />
          </span>
          <div>
            <span className='fs--1 pb-2'>Add a {label}</span>
          </div>
        </Flex>
      </Button>
    </>
  );
};

AddItemButton.propTypes = {
  label: PropTypes.string
};

export default AddItemButton;
