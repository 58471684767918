import LeargasAPI from 'api';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useApplication from 'hooks/useApplication';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf';
import ControlPanel from './controls/ControlPanel';

const detectScreenSize = () => {
  const { innerWidth: width } = window;
  if (width < 768) {
    return 0.5;
  } else if (width < 992) {
    return 0.75;
  } else {
    return 1.0;
  }
};

const PDFViewer = ({ id }) => {
  const [scale, setScale] = useState(detectScreenSize);
  const [fileName, setFileName] = useState(1.0);
  const [pdfUrl, setPdfUrl] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const { axiosPrivate } = useAxiosPrivate();

  const {
    application: { isDark }
  } = useApplication();

  const fetchData = async () => {
    try {
      setLoading(true);

      const res = await LeargasAPI.AppReports.getReportFile(id, axiosPrivate);
      if (res instanceof Error) throw res;
      const blob = new Blob([res?.data], { type: 'application/pdf' });
      const contentDisposition = res.headers['content-disposition'];
      const match = contentDisposition.match(/filename=(.*)/);
      let filename = '';
      if (match && match[1]) {
        filename = match[1];
      }
      setFileName(filename);
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }
      setLoading(false);
      // You can add a user feedback mechanism here
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;

    link.download = fileName;
    link.click();
    URL.revokeObjectURL(pdfUrl);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (!id) return;

    let isMounted = true;

    if (isMounted) fetchData();

    return () => {
      URL.revokeObjectURL(pdfUrl);
      isMounted = false;
    };
  }, [id]);

  const memoizedPDFViewer = useMemo(
    () => (
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <div
          className='mb-2'
          style={{
            border: isDark
              ? '1px solid var(--falcon-gray-400)'
              : '1px solid var(--falcon-gray-400)'
          }}>
          <Page
            pageNumber={pageNumber}
            scale={scale}
            style={{ display: 'flex' }}
          />
        </div>
      </Document>
    ),
    [id, isLoading, scale, numPages, pageNumber, pdfUrl, isDark]
  );

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <ControlPanel
            scale={scale}
            setScale={setScale}
            numPages={numPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            downloadFile={downloadFile}
          />
          {memoizedPDFViewer}
        </>
      )}
      <p className='px-4'>
        {numPages && (
          <>
            Page {pageNumber} of {numPages}
          </>
        )}
      </p>
    </div>
  );
};

PDFViewer.propTypes = {
  id: PropTypes.string
};

export default PDFViewer;
