import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import OrgProfileBanner from 'components/common/profile-cards/OrganizationProfileCard/Profile';
import UserProfileBanner from 'components/common/profile-cards/UserProfileCard/Profile';
import { Button, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
const { default: CardDropdown } = require('components/common/CardDropdown');

/**
 * Columns for table
 *
 * @param {Function} setTeams
 * @returns {Array}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const getTeamColumns = (setTeams, organizations) => {
  return [
    {
      accessor: 'name',
      Header: 'Team',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 align-middle text-nowrap'
      },
      Cell: rowData => {
        const { name } = rowData.row.original;

        return (
          <>
            <Flex direction='row' alignItems='center'>
              {name}
            </Flex>
          </>
        );
      }
    },
    {
      accessor: 'organization',
      Header: 'Organization',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-center align-middle text-nowrap'
      },
      Cell: rowData => {
        // const { application: { isPrivacyMode } = {} } = useApplication();
        const { organization } = rowData.row.original;
        let org = organizations.find(item => item?._id === organization);

        return (
          <Flex direction='row' justifyContent='center' alignItems='center'>
            {org ? (
              <OverlayTrigger
                placement='top'
                trigger={['click']}
                rootClose={true}
                delay={{ show: 0 }}
                popperConfig={{
                  modifiers: [
                    {
                      name: 'offset',
                      enabled: true,
                      options: {
                        offset: [120, 8]
                      }
                    }
                  ]
                }}
                overlay={
                  <Popover
                    style={{
                      minWidth: '320px',
                      margin: 0
                    }}
                    id={`popover-basic-${org?._id}`}>
                    <Popover.Body className='p-0'>
                      <OrgProfileBanner org={org} mainProfile={false} />
                    </Popover.Body>
                  </Popover>
                }>
                {({ ref, ...triggerHandler }) => (
                  <div
                    {...triggerHandler}
                    className='d-inline-flex align-items-center cursor-pointer'>
                    <Button
                      ref={ref}
                      className={`avatar-popover d-inline-flex align-items-center p-0 me-2 bg-transparent border-0 shadow-none`}
                      style={{ whiteSpace: 'nowrap' }}>
                      {' '}
                      <Avatar
                        size='l'
                        src={
                          org?.profile?.media?.avatar ||
                          process.env.REACT_APP_DEFAULT_ORG_AVATAR_IMG
                        }
                      />
                    </Button>
                  </div>
                )}
              </OverlayTrigger>
            ) : (
              <span className='text-300'>—</span>
            )}
          </Flex>
        );
      }
    },

    {
      accessor: 'leads',
      Header: 'Team Leads',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-center align-middle text-nowrap'
      },
      Cell: rowData => {
        const { leads } = rowData.row.original;
        let displayedLeads = leads?.slice(0, 3);
        let remainingLeads = leads?.length - 3 || 0;
        return (
          <Flex direction='row' justifyContent='center' alignItems='center'>
            {leads.length > 0 ? (
              <>
                {displayedLeads?.map(user => (
                  <OverlayTrigger
                    key={user?.id}
                    placement='top'
                    trigger={['click']}
                    rootClose={true}
                    delay={{ show: 0 }}
                    popperConfig={{
                      modifiers: [
                        {
                          name: 'offset',
                          enabled: true,
                          options: {
                            offset: [120, 8]
                          }
                        }
                      ]
                    }}
                    overlay={
                      <Popover
                        style={{
                          minWidth: '320px',
                          margin: 0
                        }}
                        id={`popover-basic-${user?.id}`}>
                        <Popover.Body className='p-0'>
                          <UserProfileBanner user={user} mainProfile={false} />
                        </Popover.Body>
                      </Popover>
                    }>
                    {({ ref, ...triggerHandler }) => (
                      <div
                        {...triggerHandler}
                        className='d-inline-flex align-items-center cursor-pointer'>
                        <Button
                          ref={ref}
                          className={`avatar-popover d-inline-flex align-items-center p-0 me-2 bg-transparent border-0 shadow-none`}
                          style={{ whiteSpace: 'nowrap' }}>
                          {' '}
                          <Avatar
                            size='l'
                            src={
                              user?.profile?.media?.avatar ||
                              process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </OverlayTrigger>
                ))}
                {remainingLeads > 0 && (
                  <span className='ms-0'>
                    <small>+ {remainingLeads} more</small>
                  </span>
                )}
              </>
            ) : (
              <span className='text-300'>—</span>
            )}
          </Flex>
        );
      }
    },
    {
      accessor: 'members',
      Header: 'Members',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-center align-middle text-nowrap'
      },
      Cell: rowData => {
        const { users } = rowData.row.original;
        let displayedOwners = users?.slice(0, 3);
        let remainingOwners = users?.length - 3 || 0;
        return (
          <Flex direction='row' justifyContent='center' alignItems='center'>
            {users.length > 0 ? (
              <>
                {displayedOwners?.map(user => (
                  <OverlayTrigger
                    key={user?.id}
                    placement='top'
                    trigger={['click']}
                    rootClose={true}
                    delay={{ show: 0 }}
                    popperConfig={{
                      modifiers: [
                        {
                          name: 'offset',
                          enabled: true,
                          options: {
                            offset: [120, 8]
                          }
                        }
                      ]
                    }}
                    overlay={
                      <Popover
                        style={{
                          minWidth: '320px',
                          margin: 0
                        }}
                        id={`popover-basic-${user?.id}`}>
                        <Popover.Body className='p-0'>
                          <UserProfileBanner user={user} mainProfile={false} />
                        </Popover.Body>
                      </Popover>
                    }>
                    {({ ref, ...triggerHandler }) => (
                      <div
                        {...triggerHandler}
                        className='d-inline-flex align-items-center cursor-pointer'>
                        <Button
                          ref={ref}
                          className={`avatar-popover d-inline-flex align-items-center p-0 me-2 bg-transparent border-0 shadow-none`}
                          style={{ whiteSpace: 'nowrap' }}>
                          {' '}
                          <Avatar
                            size='l'
                            src={
                              user?.profile?.media?.avatar ||
                              process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </OverlayTrigger>
                ))}
                {remainingOwners > 0 && (
                  <span className='ms-0'>
                    <small>+ {remainingOwners} more</small>
                  </span>
                )}
              </>
            ) : (
              <span className='text-300'>—</span>
            )}
          </Flex>
        );
      }
    },
    {
      accessor: 'leargas_actions',
      Header: '',
      Cell: rowData => {
        let { isDeleted } = rowData.row.original;
        return (
          <Flex
            justifyContent='end'
            alignContent='middle'
            className='table-dropdown-columns'>
            <CardDropdown>
              <div className='py-2'>
                <Dropdown.Item
                  onClick={() =>
                    setTeams(prevState => ({
                      ...prevState,
                      editTeamModal: {
                        open: true,
                        defaultData: rowData.row.original
                      }
                    }))
                  }>
                  <FontAwesomeIcon icon='pencil' className='me-2' />
                  Edit Details
                </Dropdown.Item>
                <div className='dropdown-divider'></div>
                <Dropdown.Item
                  className={`${!isDeleted ? 'text-danger' : 'text-warning'}`}
                  onClick={() => {
                    isDeleted
                      ? setTeams(prevState => ({
                          ...prevState,
                          restoreTeamModal: {
                            open: true,
                            defaultData: rowData.row.original
                          }
                        }))
                      : setTeams(prevState => ({
                          ...prevState,
                          deleteTeamModal: {
                            open: true,
                            defaultData: rowData.row.original
                          }
                        }));
                  }}>
                  <FontAwesomeIcon
                    icon={isDeleted ? 'trash-restore-alt' : 'trash'}
                    className='me-2'
                  />
                  {isDeleted ? 'Restore' : 'Delete'}
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </Flex>
        );
      },
      cellProps: {
        className: 'text-end text-400 actions-cell',
        style: {
          width: '42px'
        }
      },
      hideDisplaySettings: true,
      disableSortBy: true
    }
  ];
};
