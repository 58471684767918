import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import DeleteRouteForm from '../forms/DeleteRouteForm';

const DeleteRouteModal = ({
  showDeleteRouteModal,
  setShowDeleteRouteModal
}) => {
  const {
    application: { isDark }
  } = useApplication();

  return (
    <>
      <Modal
        backdrop='static'
        keyboard={false}
        show={showDeleteRouteModal.open}
        onHide={() =>
          setShowDeleteRouteModal({ open: false, id: '', fetch: false })
        }
        size='lg'
        aria-labelledby='newRouteModal'
        centered>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Delete an route
            <AdvancedPopover
              containerId='resetConfirmModal'
              placement='top'
              popoverText='Delete an route'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
        </Modal.Header>

        <Modal.Body className='p-2'>
          <DeleteRouteForm
            showDeleteRouteModal={showDeleteRouteModal}
            setShowDeleteRouteModal={setShowDeleteRouteModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

DeleteRouteModal.propTypes = {
  showDeleteRouteModal: PropTypes.object,
  setShowDeleteRouteModal: PropTypes.func
};

export default DeleteRouteModal;
