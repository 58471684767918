import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AdvancedPopover from 'components/common/Popover';
import routes from 'config/routeSettings';
import useApplication from 'hooks/useApplication';
import useExplore from 'hooks/useExplore';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';

/**
 * IndexSelector component.
 *
 * This component is responsible for rendering the index selector dropdown.
 *
 * @returns {JSX.Element} The rendered IndexSelector component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const IndexSelector = () => {
  const { pathname } = useLocation();

  const {
    application: { isDark }
  } = useApplication();

  const {
    state: { loading, indices, indexSelected },
    setState
  } = useExplore();

  // State to hold the options for the dropdown
  const [options, setOptions] = useState([]);

  // Handle selection changes
  const handleChange = selected => {
    const selection = indices.find(index => index._id === selected.value);
    setState('indexSelected', selection || indices[0]);
  };

  useEffect(() => {
    setOptions(
      indices.map(index => ({
        label: index.alias,
        value: index._id
      }))
    );
  }, [indices]);

  return (
    <div style={{ maxWidth: '225px' }}>
      <h4 className='d-flex fs-0 fw-normal text-700 mb-1 align-items-center'>
        Selected Index{' '}
        <AdvancedPopover
          placement='top'
          showArrow={true}
          popoverText='Select the index to search.'>
          <FontAwesomeIcon
            icon={['far', 'question-circle']}
            className='ms-1 text-400 fs--1 cursor-pointer'
          />
        </AdvancedPopover>
      </h4>

      <Select
        placeholder='Select index to search'
        options={options}
        value={options.find(index => index.value === indexSelected?._id)}
        isDisabled={pathname !== routes.ARTIFACTS_EXPLORE || loading}
        classNames={classNames({
          'cursor-disabled': pathname !== routes.ARTIFACTS_EXPLORE
        })}
        onChange={handleChange}
        styles={{
          container: provided => ({
            ...provided,
            padding: '0',
            margin: '0 0 0.5rem 0',
            border: '0',
            color: 'var(--falcon-input-color)'
          }),
          menu: provided => ({
            ...provided,
            margin: '0',
            padding: '0',
            backgroundColor: 'var(--falcon-input-bg)'
          }),
          multiValue: provided => ({
            ...provided,
            marginTop: '2px',
            padding: '1px 2px',
            backgroundColor: isDark
              ? 'var(--falcon-card-bg)'
              : 'var(--falcon-gray-300)',
            border: isDark
              ? '1px solid var(--falcon-border-color)'
              : '1px solid var(--falcon-border-color)'
          }),
          multiValueLabel: provided => ({
            ...provided,
            padding: '3px 2px 3px 6px',
            fontSize: '.6944444444rem',
            fontWeight: 400,
            color: 'var(--falcon-input-color)'
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: 'var(--falcon-input-bg)',
            color: state.isDisabled
              ? 'var(--falcon-gray-400)'
              : 'var(--falcon-input-color)',
            fontSize: '.6944444444rem',
            fontWeight: 400,
            cursor: !state.isDisabled ? 'pointer' : 'default',
            '&:hover': {
              backgroundColor: 'var(--falcon-gray-200)'
            }
          }),
          input: provided => ({
            ...provided,
            color: 'var(--falcon-input-color)',
            fontWeight: 400
          }),
          control: provided => ({
            ...provided,
            margin: '0',
            minHeight: '24px',
            color: 'var(--falcon-input-color)',
            backgroundColor: 'var(--falcon-input-bg)',
            fontSize: '.6944444444rem',
            fontWeight: 400,
            border: '1px solid var(--falcon-input-border-color)',
            boxShadow: 'var(--falcon-box-shadow-inset)',
            transition:
              'background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            ':hover': {
              borderColor: 'var(--falcon-input-focus-border-color)',
              outline: '0'
            }
          }),
          placeholder: provided => ({
            ...provided,
            color: 'var(--falcon-input-color)',
            fontSize: '.6944444444rem',
            fontWeight: 400
          }),
          valueContainer: provided => ({
            ...provided,
            marginTop: '0',
            marginLeft: '1px',
            padding: '0px',
            paddingLeft: '6px',
            border: '0',
            fontWeight: 400
          }),
          dropdownIndicator: provided => ({
            ...provided,
            marginTop: '0',
            marginLeft: '5px',
            marginRight: '5px',
            padding: '0',
            border: '0',
            width: '16px',
            cursor: 'pointer',
            fontWeight: 400,
            color: 'var(--falcon-input-border-color)'
          }),
          clearIndicator: provided => ({
            ...provided,
            marginTop: '0',
            marginLeft: '5px',
            marginRight: '5px',
            padding: '0',
            border: '0',
            width: '16px',
            cursor: 'pointer'
          }),
          indicatorsContainer: provided => ({
            ...provided,
            top: '0',
            paddingRight: '4px',
            border: '0'
          }),
          multiValueRemove: provided => ({
            ...provided,
            color: 'var(--falcon-gray-600)'
          }),
          singleValue: provided => ({
            ...provided,
            color: isDark
              ? 'var(--falcon-gray-700)'
              : 'var(--falcon-input-color)',
            fontSize: '.6944444444rem',
            fontWeight: 400,
            padding: '0px'
          }),
          menuList: provided => ({
            ...provided,
            padding: '0',
            margin: '0',
            backgroundColor: 'var(--falcon-input-bg)'
          }),
          groupHeading: provided => ({
            ...provided,
            color: 'var(--falcon-input-color)',
            fontSize: '.6944444444rem',
            fontWeight: 400,
            backgroundColor: 'var(--falcon-input-bg)',
            padding: '0.5rem 1rem'
          }),
          group: provided => ({
            ...provided,
            padding: '0',
            margin: '0'
          }),
          // Make the little border line on the right the color of the falcon
          // input border color
          indicatorSeparator: provided => ({
            ...provided,
            backgroundColor: 'var(--falcon-input-border-color)'
          })
        }}
        className={{
          container: ['fs--2'],
          control: ['fs--2 text-dark'],
          multiValueLabel: ['fs--2']
        }}
      />
    </div>
  );
};

export default IndexSelector;
