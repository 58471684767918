import { useContext } from 'react';
import { ArticlesContext } from 'context/admin-contexts/ArticlesProvider.js';

/**
 * Custom hook to use the ArticlesContext
 * @returns {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const useArticles = () => {
  return useContext(ArticlesContext);
};

export default useArticles;
