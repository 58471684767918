import { useContext } from 'react';
import { StatusesContext } from 'context/admin-contexts/StatusesProvider';

/**
 * Custom hook to use the OrganizationsContext
 * @returns {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const useStatuses = () => {
  return useContext(StatusesContext);
};

export default useStatuses;
