import { flatRoutes } from 'helpers/utils';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { artifactRoutes } from 'routes/routes';
import NavbarDropdown from './NavbarDropdown';

/**
 * NavbarTopDropDownMenus component.
 *
 * @returns {React.Component} The NavbarTopDropDownMenus component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.1
 */
const NavbarTopDropDownMenus = () => {
  const {
    application: { navbarCollapsed, showBurgerMenu },
    setApplication
  } = useApplication();

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setApplication('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setApplication('showBurgerMenu', !showBurgerMenu);
    }
  };
  return (
    <>
      <NavbarDropdown title='dashboard'>
        {flatRoutes(artifactRoutes.children).map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}>
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown>
    </>
  );
};

export default NavbarTopDropDownMenus;
