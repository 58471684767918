import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useBookmarks from 'hooks/admin-contexts/useBookmarks';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Modal } from 'react-bootstrap';
import FormBookmarkRestore from '../forms/BookmarksRestoreForm';
/**
 * bookmarks restore modal
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ModalBookmarkRestore = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    bookmarks: { restoreBookmarkModal },
    setBookmarks
  } = useBookmarks();

  return (
    <Modal
      centered
      size='lg'
      animation={false}
      backdrop='static'
      keyboard={false}
      show={restoreBookmarkModal?.open}
      onHide={() =>
        setBookmarks(prevState => ({
          ...prevState,
          restoreBookmarkModal: { open: false, data: {} }
        }))
      }
      aria-labelledby='modalBookmarkAdd'>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Restore Bookmark: {restoreBookmarkModal?.data?.name}
          <AdvancedPopover
            containerId='resetConfirmModal'
            placement='top'
            popoverText='Restore Bookmark'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
      </Modal.Header>

      <Modal.Body className='p-2'>
        <FormBookmarkRestore />
      </Modal.Body>
    </Modal>
  );
};

export default ModalBookmarkRestore;
