import { getColor, rgbaColor } from 'helpers/utils';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';

/**
 * TermSelect component for selecting terms for a search filter.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Array} props.options - The options for the select input
 * @param {Function} props.getOptions - The function to fetch options asynchronously
 * @param {boolean} [props.isLoading=false] - Indicates if the options are currently being loaded
 * @param {Object} rest - The rest of the props
 * @param {React.Ref} ref - The ref to attach to the component
 * @returns {React.Element} The rendered TermSelect component
 */
const TermSelect = forwardRef(
  (
    {
      options,
      getOptions,
      isLoading = false,
      isMulti = false,
      value,
      operatorMultiOptionLimit,
      ...rest
    },
    ref
  ) => {
    const {
      application: { isDark }
    } = useApplication();

    return (
      <AsyncCreatableSelect
        autoFocus={true}
        allowCreateWhileLoading={true}
        backspaceRemovesValue={false}
        openMenuOnFocus={true}
        createOptionPosition='first'
        defaultOptions
        isOptionDisabled={() =>
          operatorMultiOptionLimit &&
          Array.isArray(value) &&
          value.length >= operatorMultiOptionLimit
        }
        formatCreateLabel={inputValue => `Use custom term "${inputValue}"`}
        isClearable={true}
        value={value} // Accept an object or array depending on isMulti
        loadingMessage={() => 'Loading terms matching search criteria...'}
        loadOptions={getOptions}
        noOptionsMessage={() =>
          !isLoading
            ? 'No terms found matching search criteria.'
            : 'Loading terms matching search criteria...'
        }
        options={options}
        placeholder={
          isLoading
            ? 'Loading terms matching search criteria...'
            : 'Search for or use a custom term'
        }
        ref={ref}
        isMulti={isMulti}
        styles={{
          menu: provided => ({
            ...provided,
            fontSize: '0.8333333333rem',
            color: 'var(--falcon-gray-1100)',
            background: 'var(--falcon-gray-200)',
            margin: '0',
            padding: '0',
            height: 'auto'
          }),
          menuList: provided => ({
            ...provided,
            fontSize: '0.8333333333rem',
            color: 'var(--falcon-gray-1100)',
            background: 'var(--falcon-gray-200)',
            padding: '5px 0 0 0'
          }),
          placeholder: provided => ({
            ...provided,
            fontSize: '0.8333333333rem',
            color: 'var(--falcon-gray-1100)'
          }),
          option: (provided, state) => ({
            ...provided,
            fontSize: '0.8333333333rem',
            background: state.isFocused
              ? getColor('pastel-green-dark')
              : state.isSelected
              ? 'var(--falcon-gray-300)'
              : 'var(--falcon-gray-200)',
            color: state.isDisabled
              ? 'var(--falcon-gray-400)'
              : 'var(--falcon-gray-1100)',
            padding: 10,
            cursor: !state.isDisabled ? 'pointer' : 'default',
            '&:hover': {
              backgroundColor: getColor('pastel-green-dark'),
              color: 'white'
            }
          }),
          input: provided => ({
            ...provided,
            fontSize: '0.8333333333rem',
            color: 'var(--falcon-gray-1100)',
            margin: '0',
            padding: '0'
          }),
          control: provided => ({
            ...provided,
            fontSize: '0.8333333333rem',
            margin: '0',
            padding: '0',
            color: 'var(--falcon-gray-1100)',
            backgroundColor: 'var(--falcon-input-bg)',
            border: '1px solid var(--falcon-input-border-color)',
            boxShadow: 'var(--falcon-box-shadow-inset)',
            transition:
              'background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            ':hover': {
              borderColor: getColor('pastel-green')
            },
            ':focus-within': {
              borderColor: getColor('pastel-green'),
              boxShadow: `inset 0 0 10px 0 ${rgbaColor(
                getColor('pastel-green'),
                0.5
              )}`
            }
          }),
          valueContainer: provided => ({
            ...provided,
            color: 'var(--falcon-gray-1100)',
            border: '0',
            margin: '0',
            padding: '3px 0 3px 6px',
            height: 'auto'
          }),
          singleValue: provided => ({
            ...provided,
            color: 'var(--falcon-input-color)',
            margin: '0',
            padding: '0',
            height: 'auto'
          }),
          indicatorSeparator: provided => ({
            ...provided,
            background: 'var(--falcon-gray-400)'
          }),
          dropdownIndicator: provided => ({
            ...provided,
            color: 'var(--falcon-gray-1100)',
            marginTop: '0',
            marginLeft: '5px',
            padding: '0',
            border: '0',
            width: '24px',
            cursor: 'pointer',
            '&:hover': {
              color: 'var(--falcon-primary)'
            }
          }),
          clearIndicator: provided => ({
            ...provided,
            color: 'var(--falcon-gray-1100)',
            marginTop: '0',
            marginLeft: '5px',
            marginRight: '5px',
            padding: '0',
            border: '0',
            width: '24px',
            cursor: 'pointer',
            '&:hover': {
              color: 'var(--falcon-danger)'
            }
          }),
          indicatorsContainer: provided => ({
            ...provided,
            top: '0',
            paddingRight: '4px',
            border: '0'
          }),
          noOptionsMessage: provided => ({
            ...provided,
            color: 'var(--falcon-gray-1100)',
            fontSize: '0.8333333333rem'
          }),
          loadingMessage: provided => ({
            ...provided,
            color: 'var(--falcon-gray-1100)',
            fontSize: '0.8333333333rem'
          }),
          multiValue: provided => ({
            ...provided,
            marginTop: '2px',
            padding: '1px 2px',
            backgroundColor: isDark
              ? 'var(--falcon-card-bg)'
              : 'var(--falcon-gray-300)',
            border: isDark
              ? '1px solid var(--falcon-border-color)'
              : '1px solid var(--falcon-border-color)'
          }),
          multiValueLabel: provided => ({
            ...provided,
            padding: '3px 6px 3px 6px',
            fontSize: '0.8333333333rem',
            color: 'var(--falcon-gray-700)'
          }),
          multiValueRemove: provided => ({
            ...provided,
            color: 'var(--falcon-gray-600)'
          })
        }}
        {...rest}
      />
    );
  }
);

TermSelect.propTypes = {
  options: PropTypes.array,
  operatorMultiOptionLimit: PropTypes.number,
  getOptions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default TermSelect;
