import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import useApplication from 'hooks/useApplication';
import { useESGlobalFilters } from 'hooks/useGlobalFilters';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

/**
 *
 * @returns {JSX.Element} The rendered button component
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const DeleteConfirmModal = ({
  showDeleteConfirmModal,
  setShowDeleteConfirmModal
}) => {
  const {
    application: { isDark },
    setApplication
  } = useApplication();
  const {
    esGlobalFilters: { globalFiltersReset },
    setESGlobalFilters
  } = useESGlobalFilters();

  return (
    <Modal
      aria-labelledby='deleteConfirmModal'
      size='lg'
      backdrop='static'
      //   keyboard={false}
      show={showDeleteConfirmModal}
      onHide={() => setShowDeleteConfirmModal(false)}
      centered>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Exit Confirm
          <AdvancedPopover
            containerId='newExitConfirmModal'
            placement='top'
            popoverText='Exit Confirm'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
      </Modal.Header>

      {/* setESGlobalFilters({...globalFiltersReset}) */}
      <Modal.Body className='p-2'>
        <Form
          onSubmit={e => {
            e.preventDefault();

            setESGlobalFilters({ ...globalFiltersReset });
            // setESGlobalFilters(prevState => ({
            //   ...prevState,
            //   querySearchText: '',
            //   query_string: null
            // }));
            setShowDeleteConfirmModal(false);
            setApplication('showGlobalFilters', false);
          }}>
          <Row className='g-3 mb-3'>
            <Col sm={12}>
              <p className='fs--1'>
                Are you sure you want to close? You may lose any unsaved
                changes!
              </p>
            </Col>
          </Row>

          <Flex justifyContent='end'>
            <Button type='submit' variant='success' className='me-2'>
              Yes
            </Button>

            <Button
              variant='secondary'
              onClick={() => {
                setShowDeleteConfirmModal(false);
              }}>
              No
            </Button>
          </Flex>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

DeleteConfirmModal.propTypes = {
  showDeleteConfirmModal: PropTypes.bool,
  setShowDeleteConfirmModal: PropTypes.func
};

export default DeleteConfirmModal;
