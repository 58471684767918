import FalconCardHeader from 'components/common/FalconCardHeader';
import React from 'react';
import { Card } from 'react-bootstrap';

/**
 * Renders the Bio component.
 * @returns {JSX.Element} The rendered Bio component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const Bio = () => {
  return (
    <Card className='mb-3 shadow-sm border'>
      <FalconCardHeader title='Bio' titleTag='h5' />
      <Card.Body className='text-justify text-1000'>
        <p>
          Dedicated, passionate, and accomplished Full Stack Developer with 9+
          years of progressive experience working as an Independent Contractor
          for Google and developing and growing my educational social network
          that helps others learn programming and Web design.
        </p>
      </Card.Body>
    </Card>
  );
};

export default Bio;
