import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';

const WidgetSectionTitle = ({
  title,
  subtitle = false,
  buttonPrefix,
  buttonSuffix,
  icon = false,
  transform = false,
  className = '',
  titleClassName = '',
  subtitleClassName = ''
}) => {
  return (
    <div className='position-relative'>
      <span className='border position-absolute top-50 w-100 start-0 end-0 widget-section-title-bar'></span>
      <Flex
        direction='row'
        className={classNames('position-relative', className)}>
        {buttonPrefix ? buttonPrefix : null}
        {icon ? (
          <FontAwesomeIcon
            icon={icon}
            transform={transform ? transform + ' grow-1' : 'grow-1'}
            className='text-700 me-2'
            inverse
          />
        ) : null}
        <h6 className={'fs-0 fw-normal mb-0 text-700'}>
          <span
            className={classNames('bg-200 dark__bg-1100 pe-2', titleClassName)}
            style={{
              zIndex: 2
            }}>
            {title}
          </span>
        </h6>
        {buttonSuffix ? buttonSuffix : null}
        {subtitle ? (
          <p className={classNames('mb-0', subtitleClassName)}>{subtitle}</p>
        ) : null}
      </Flex>
    </div>
  );
};

WidgetSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  buttonPrefix: PropTypes.element,
  buttonSuffix: PropTypes.element,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  transformBubble: PropTypes.string,
  transform: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  className: PropTypes.string
};

export default WidgetSectionTitle;
