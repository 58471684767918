import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import AdvancedTableSearchBox from './AppliancesTableSearchBox';

/**
 *
 * @param {object} props
 * @param {String} props.globalFilter
 * @param {Function} props.setGlobalFilter
 * @returns {JSX.Element}
 *
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const AppliancesTableFilters = ({ globalFilter, setGlobalFilter }) => {
  return (
    <Flex>
      <AdvancedTableSearchBox
        table
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
    </Flex>
  );
};

AppliancesTableFilters.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func
};

export default AppliancesTableFilters;
