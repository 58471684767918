import CreatableMultiselect from 'components/common/CreatableMultiselect';
import MultiSelect from 'components/common/MultiSelect';
import { setInsideFiltersModal } from 'components/dashboards/Artifacts/Dynamic/dataFetchUtils/RefinedUtils';
import useFilters from 'hooks/Misc/useFilters';
import useApplication from 'hooks/useApplication';
import { useESGlobalFilters } from 'hooks/useGlobalFilters';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap';
import Flex from '../../../Flex';

/**
 *
 * @returns {JSX.Element} The rendered button component
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const NewFilterModal = ({ localRules }) => {
  const [fieldValue, setFieldValue] = useState('');
  const [operatorValue, setOperatorValue] = useState('is');
  const [termValue, setTermValue] = useState('');
  const [termValues, setTermValues] = useState([]);
  const {
    filters: { editFilterModal },
    setFilters
  } = useFilters();

  const fieldRef = useRef(null);
  const { updateGlobalFilters } = useApplication();
  const {
    esGlobalFilters: { query, fieldsToUseEverywhere },
    setESGlobalFilters
  } = useESGlobalFilters();

  useEffect(() => {
    setFieldValue(editFilterModal?.data?.accessor);
    if (editFilterModal?.data?.operator?.includes('exist')) {
      setTermValue('');
      setTermValues([]);
    } else if (editFilterModal?.data?.operator?.includes('one of')) {
      setTermValue('');
      setTermValues(editFilterModal?.data?.value);
    } else if (
      editFilterModal?.data?.operator === 'is' ||
      editFilterModal?.data?.operator === 'is not'
    ) {
      setTermValue(editFilterModal?.data?.value);
      setTermValues([]);
    }

    setOperatorValue(editFilterModal?.data?.operator);
  }, [editFilterModal]);

  /**
   * Sets the initial field value to the first field in the list
   */
  useEffect(() => {
    setFieldValue(fieldsToUseEverywhere && fieldsToUseEverywhere[0]?.name);

    setOperatorValue('is');
  }, [fieldsToUseEverywhere]);

  // useEffect(() => {
  //   if (fieldRef.current) {
  //     fieldRef.current.focus();
  //   }
  // }, [showNewFilterModal]);

  const editToQuery = () => {
    const rules = [...JSON.parse(JSON.stringify(localRules))];

    let {
      data: { index }
    } = editFilterModal;
    rules[index].field = fieldValue;
    rules[index].operator = operatorValue;
    rules[index].value =
      operatorValue === 'is' || operatorValue === 'is not'
        ? termValue
        : termValues;

    const newQuery = { ...JSON.parse(JSON.stringify(query)) };

    newQuery.rules = rules;

    setESGlobalFilters(prevState => {
      return {
        ...prevState,
        query: newQuery,
        stagedQuery: newQuery
      };
    });

    setInsideFiltersModal(
      newQuery,

      setESGlobalFilters,
      updateGlobalFilters
    );
    setFieldValue(fieldsToUseEverywhere && fieldsToUseEverywhere[0]?.name);
    setOperatorValue('is');
    setTermValue('');
    setFilters(prevState => ({
      ...prevState,
      editFilterModal: { open: false, data: {} }
    }));
  };

  const createTagSelect = async param => {
    let newTags = [...termValues];

    newTags.push({ label: param, value: param });
    setTermValues(newTags);
  };

  const handleTagsChange = tagsIn => {
    if (tagsIn?.length > 0) {
      setTermValues(tagsIn);
    } else {
      setTermValues([]);
    }
  };
  return (
    <Modal
      aria-labelledby='newFilterModal'
      size='lg'
      keyboard={false}
      show={editFilterModal?.open}
      onHide={() =>
        setFilters(prevState => ({
          ...prevState,
          editFilterModal: { open: false, data: {} }
        }))
      }
      centered>
      <Modal.Body className='p-2'>
        <Row className='g-3 mb-3'>
          <Col>
            <h5 className='mb-0'>Edit filter</h5>
          </Col>
        </Row>
        <Row className='g-3 mb-3'>
          <Col>
            <Form
              onSubmit={e => {
                e.preventDefault();
                setFieldValue('');
                setOperatorValue('');
                setTermValue('');

                setFilters(prevState => ({
                  ...prevState,
                  editFilterModal: { open: false, data: {} }
                }));
              }}>
              <Row className='g-2 mb-2'>
                <Col sm={8}>
                  <FormGroup>
                    <Form.Label>Field</Form.Label>
                    <MultiSelect
                      ref={fieldRef}
                      options={
                        fieldsToUseEverywhere.map
                          ? fieldsToUseEverywhere.map(item => {
                              return { label: item.label, value: item.name };
                            })
                          : []
                      }
                      value={{ label: fieldValue, value: fieldValue }}
                      onChange={value => {
                        setFieldValue(value.value);
                      }}
                      size='sm'
                    />
                  </FormGroup>
                </Col>
                <Col sm={4}>
                  <FormGroup>
                    <Form.Label>Operator</Form.Label>
                    <MultiSelect
                      options={[
                        { label: 'is', value: 'is' },
                        { label: 'is not', value: 'is not' },
                        { label: 'is one of', value: 'is one of' },
                        { label: 'is not one of', value: 'is not one of' },
                        { label: 'exists', value: 'exists' },
                        { label: 'does not exist', value: 'does not exist' }
                      ]}
                      value={{ label: operatorValue, value: operatorValue }}
                      onChange={value => {
                        if (value.value === 'is' || value.value === 'is not') {
                          setOperatorValue(value.value);
                          setTermValues([]);
                        } else if (
                          value.value === 'is one of' ||
                          value.value === 'is not one of'
                        ) {
                          setTermValue('');
                          setOperatorValue(value.value);
                        } else if (
                          value.value === 'exists' ||
                          value.value === 'does not exist'
                        ) {
                          setTermValue('');
                          setTermValues([]);
                          setOperatorValue(value.value);
                        }
                      }}
                      size='sm'
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  {operatorValue !== 'exists' &&
                    operatorValue !== 'does not exist' && (
                      <>
                        {' '}
                        <FormGroup>
                          <Form.Label>Term</Form.Label>
                          {operatorValue !== 'is one of' &&
                          operatorValue !== 'is not one of' ? (
                            <Form.Control
                              size='sm'
                              value={termValue}
                              onChange={e => {
                                setTermValue(e.target.value);
                              }}
                              name='term'
                            />
                          ) : (
                            <CreatableMultiselect
                              className='fs--1'
                              isMulti
                              isClearable
                              isSearchable
                              placeholder='Please select'
                              options={termValues}
                              onCreateOption={createTagSelect}
                              value={termValues}
                              onChange={handleTagsChange}
                              style={{ minHeight: '36px' }}
                            />
                          )}
                        </FormGroup>
                      </>
                    )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Flex justifyContent='end'>
          <Button
            size='sm'
            variant='secondary'
            className='me-2'
            onClick={() => {
              setFieldValue('');
              setOperatorValue('');
              setTermValue('');
              setFilters(prevState => ({
                ...prevState,
                editFilterModal: { open: false, data: {} }
              }));
            }}>
            Cancel
          </Button>

          <Button
            size='sm'
            variant='success'
            disabled={fieldValue === '' || operatorValue === ''}
            onClick={editToQuery}
            type='submit'>
            Save
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

NewFilterModal.propTypes = {
  localRules: PropTypes.array
};

export default NewFilterModal;
