import { makeFileRequest, makeRequest } from 'api/connection/requestAction';

class AppReports {
  constructor(route) {
    this.route = route;
  }

  async createReport(reportData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.createReport.method,
        this.route.createReport.path,
        reportData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getReports(axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getReports.method,
        this.route.getReports.path
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getReportFile(id, axiosPrivate) {
    try {
      const res = await makeFileRequest(
        axiosPrivate,
        [this.route.getReport.path, id].join('/')
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async updateReport(id, reportData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateReport.method,
        `${this.route.updateReport.path}/${id}`,
        reportData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async deleteAppReport(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.deleteReport.method,
        `${this.route.deleteReport.path}/${id}`
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default AppReports;
