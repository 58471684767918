import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import routes from 'config/routeSettings';
import useSavedSearches from 'hooks/admin-contexts/useSavedSearches';
import useApplication from 'hooks/useApplication';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AddButton from './AddButton';
import { getSavedSearchesColumns } from './Columns/ColumnsDefaults';
import SavedSearchesTable from './SavedSearchesTable';
import SavedSearchesTableFilters from './SavedSearchesTableFilters';
import SavedSearchesTableFooter from './SavedSearchesTableFooter';
import SavedSearchesTablePagination from './SavedSearchesTablePagination';
import SavedSearchesTableWrapper from './SavedSearchesTableWrapper';

/**
 * Renders the SavedSearches component
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const SavedSearches = () => {
  const {
    application: { isDark }
  } = useApplication();
  const {
    savedSearches: { data, showDeleted },
    loading: componentLoading,
    setSavedSearches
  } = useSavedSearches();
  const navigate = useNavigate();

  const { axiosPrivate } = useAxiosPrivate();

  const [columns, setColumns] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const getIndices = async () => {
    try {
      const Indices = await LeargasAPI.Indices.getIndices(axiosPrivate);
      if (Indices instanceof Error) throw Indices;
      if (Array.isArray(Indices)) {
        setSavedSearches(prevState => ({
          ...prevState,
          indices: Indices
        }));
      }
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setSavedSearches(prevState => ({
        ...prevState,
        indices: []
      }));
    }
  };

  /**
   * Set columns on mount
   */
  useEffect(() => {
    setColumns(getSavedSearchesColumns(setSavedSearches, navigate));
  }, [isDark]);

  useEffect(() => {
    getIndices();
  }, []);
  return (
    <Card className='shadow-none border'>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          <span className='d-block'>Saved Searches</span>
          <Flex justifyContent='end' alignItems='center'>
            <AddButton
              onClick={() => navigate(`${routes.ARTIFACTS_EXPLORE}`)}
            />
          </Flex>
        </Flex>
      </Card.Header>
      <Card.Body className='position-relative'>
        {componentLoading ? (
          <LoadingSpinner grow='10' />
        ) : (
          <SavedSearchesTableWrapper
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            // data={savedSearches}
            data={
              showDeleted
                ? data
                : data?.filter(savedSearches => !savedSearches?.isDeleted)
            }
            sortable
            loading={componentLoading}
            pagination
            perPage={50}>
            <SavedSearchesTableFilters
              table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <SavedSearchesTable table columns={columns} />
            <AddButton
              label='New Saved Search'
              onClick={() => navigate(`${routes.ARTIFACTS_EXPLORE}`)}
            />
            <div className='mt-3 d-flex justify-content-between'>
              <SavedSearchesTableFooter
                table
                rowInfo
                data={
                  showDeleted
                    ? data
                    : data?.filter(savedSearches => !savedSearches?.isDeleted)
                }
                rowCount={
                  showDeleted
                    ? data?.length
                    : data?.filter(savedSearches => !savedSearches?.isDeleted)
                        .length
                }
              />
              <SavedSearchesTablePagination table />
            </div>
          </SavedSearchesTableWrapper>
        )}
      </Card.Body>
    </Card>
  );
};

export default SavedSearches;
