import {
  // BootstrapConfig,
  Utils as QbUtils
} from '@react-awesome-query-builder/bootstrap';
import routes from 'config/routeSettings';
import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

const Iou = { fields: {} };

export const globalFiltersSettings = {
  Config: Iou,
  dInterval: false,
  fields: [],
  fieldsToUseEverywhere: [],
  fullControlQuery: '',
  globalFiltersReset: {},
  gte: '',
  highlightedGlobalIndex: null,
  inActiveOrgs: false,
  index: ['leargas'],
  selectedIndices: [],
  injectedPreset: '',
  interval: false,
  intervalTime: 30,
  isDashboard: false,
  loading: true,
  lte: '',
  organization: {
    clientId: '',
    clientName: 'Select Organization'
  },
  page: 1,
  pageCount: 0,
  preset: 'last_1_hour',
  privateColumns: [],
  query: {
    id: QbUtils.uuid(),
    rules: []
  },
  query_string: null,
  querySearchText: '',
  refresh: false,
  scopeAllToggle: true,
  scopes: [],
  search: '',
  showGlobalFilters: false,
  size: 25,
  sortBy: '@timestamp',
  sortOrder: 'DESC',
  stagedQuery: {
    id: QbUtils.uuid(),
    rules: []
  },
  update: true,
  visibleColumns: ['_source', '@timestamp', 'leargas_actions']
};

export const filterInColumns = ['count', 'doc_count'];

export const presetRangeGroups = {
  custom: 'Custom',
  last_minutes: 'Minutes',
  last_hours: 'Hours',
  last_days: 'Days',
  presets: 'Presets'
};

export const dashboardsCreatePresets = [
  {
    TimePreset: 'last_1_min',
    RangeSeconds: 60
  },
  {
    TimePreset: 'last_15_mins',
    RangeSeconds: 900
  },
  {
    TimePreset: 'last_30_mins',
    RangeSeconds: 1800
  },
  {
    TimePreset: 'last_45_mins',
    RangeSeconds: 2700
  },
  {
    TimePreset: 'last_1_hour',
    RangeSeconds: 3600
  },
  {
    TimePreset: 'last_2_hours',
    RangeSeconds: 7200
  },
  {
    TimePreset: 'last_4_hours',
    RangeSeconds: 14400
  },
  {
    TimePreset: 'last_6_hours',
    RangeSeconds: 21600
  },
  {
    TimePreset: 'last_8_hours',
    RangeSeconds: 28800
  },
  {
    TimePreset: 'last_12_hours',
    RangeSeconds: 43200
  },
  {
    TimePreset: 'last_1_day',
    RangeSeconds: 86400
  },
  {
    TimePreset: 'last_4_days',
    RangeSeconds: 345600
  },
  {
    TimePreset: 'last_7_days',
    RangeSeconds: 604800
  },
  {
    TimePreset: 'last_14_days',
    RangeSeconds: 1209600
  },
  {
    TimePreset: 'last_30_days',
    RangeSeconds: 2592000
  }
];

export const presetRanges = {
  custom_range: {
    allowRefresh: false,
    group: 'custom',
    label: 'Custom Range'
  },
  last_1_min: {
    allowRefresh: true,
    group: 'last_minutes',
    label: 'Last 1 minute',
    gte: {
      subtract: {
        minute: 1
      }
    }
  },
  last_15_mins: {
    allowRefresh: true,
    group: 'last_minutes',
    label: 'Last 15 minutes',
    gte: {
      subtract: {
        minute: 15
      }
    }
  },
  last_30_mins: {
    allowRefresh: true,
    group: 'last_minutes',
    label: 'Last 30 minutes',
    gte: {
      subtract: {
        minute: 30
      }
    }
  },
  last_45_mins: {
    allowRefresh: true,
    group: 'last_minutes',
    label: 'Last 45 minutes',
    gte: {
      subtract: {
        minute: 45
      }
    }
  },
  last_1_hour: {
    allowRefresh: true,
    group: 'last_hours',
    label: 'Last 1 hour',
    gte: {
      subtract: {
        hour: 1
      }
    }
  },
  last_2_hours: {
    allowRefresh: true,
    group: 'last_hours',
    label: 'Last 2 hours',
    gte: {
      subtract: {
        hour: 2
      }
    }
  },
  last_4_hours: {
    allowRefresh: true,
    group: 'last_hours',
    label: 'Last 4 hours',
    gte: {
      subtract: {
        hour: 4
      }
    }
  },
  last_6_hours: {
    allowRefresh: true,
    group: 'last_hours',
    label: 'Last 6 hours',
    gte: {
      subtract: {
        hour: 6
      }
    }
  },
  last_8_hours: {
    allowRefresh: true,
    group: 'last_hours',
    label: 'Last 8 hours',
    gte: {
      subtract: {
        hour: 8
      }
    }
  },
  last_12_hours: {
    allowRefresh: true,
    group: 'last_hours',
    label: 'Last 12 hours',
    gte: {
      subtract: {
        hour: 12
      }
    }
  },
  last_1_day: {
    allowRefresh: true,
    group: 'last_days',
    label: 'Last 1 day',
    gte: {
      subtract: {
        day: 1
      }
    }
  },
  last_4_days: {
    allowRefresh: false,
    group: 'last_days',
    label: 'Last 4 days',
    gte: {
      subtract: {
        day: 4
      }
    }
  },
  last_7_days: {
    allowRefresh: false,
    group: 'last_days',
    label: 'Last 7 days',
    gte: {
      subtract: {
        day: 7
      }
    }
  },
  last_14_days: {
    allowRefresh: false,
    group: 'last_days',
    label: 'Last 14 days',
    gte: {
      subtract: {
        day: 14
      }
    }
  },
  last_30_days: {
    allowRefresh: false,
    group: 'last_days',
    label: 'Last 30 days',
    gte: {
      subtract: {
        day: 30
      }
    }
  },
  daily_reports: {
    allowRefresh: false,
    group: 'presets',
    label: 'Daily Reports',
    gte: {
      subtract: {
        hours: 24
      },
      set: {
        hour: 6,
        minute: 0,
        second: 0,
        millisecond: 0
      }
    },
    lte: {
      set: {
        hour: 5,
        minute: 59,
        second: 59,
        millisecond: 999
      }
    }
  }
};

export const presetIntervals = {
  every_30_seconds: {
    label: 'Every 30 seconds',
    interval: {
      seconds: 30
    }
  },
  every_1_minute: {
    label: 'Every 1 minute',
    interval: {
      seconds: 60
    }
  },
  every_5_minute: {
    label: 'Every 5 minutes',
    interval: {
      seconds: 300
    }
  },
  every_10_minute: {
    label: 'Every 10 minutes',
    interval: {
      seconds: 600
    }
  }
};

export const showSearchInputOn = [routes.ARTIFACTS_EXPLORE];

export const showIndexSelectOn = [
  routes.ARTIFACTS_EXPLORE
  // routes.ARTIFACTS_CLOUD_GOOGLE,
  // routes.ARTIFACTS_CLOUD_O365,
  // routes.ARTIFACTS_NETWORK_BEHAVIORS,
  // routes.ARTIFACTS_NETWORK_SIGNATURES,
  // routes.ARTIFACTS_NETWORK_FILEEXTRACTION,
  // routes.ARTIFACTS_NETWORK_THREATINTEL,
  // routes.ARTIFACTS_CLOUD_BOX,
  // routes.ARTIFACTS_ENDPOINT_WINDOWS,
  // routes.ARTIFACTS_CLOUD_DUO,
  // routes.ARTIFACTS_CLOUD_MERAKI,
  // routes.ARTIFACTS_CLOUD_OKTA,
  // routes.ARTIFACTS_DARKWEB,
  // routes.ARTIFACTS_ENDPOINT_SENTINELONE_AGENTS,
  // routes.ARTIFACTS_ENDPOINT_SENTINELONE_THREATS,
  // routes.ARTIFACTS_ENDPOINT_SENTINELONE
];

export const showPresetSelectOn = [
  routes.OVERVIEW,
  routes.ARTIFACTS_EXPLORE,
  routes.ARTIFACTS_CLOUD,
  routes.ARTIFACTS_CLOUD_BOX,
  routes.ARTIFACTS_CLOUD_DUO,
  routes.ARTIFACTS_CLOUD_GOOGLE,
  routes.ARTIFACTS_CLOUD_MERAKI,
  routes.ARTIFACTS_CLOUD_O365,
  routes.ARTIFACTS_CLOUD_OKTA,
  routes.ARTIFACTS_DARKWEB,
  routes.ARTIFACTS_DARKWEB_CREDENTIALS,
  routes.ARTIFACTS_DARKWEB_KEYWORDS,
  routes.ARTIFACTS_DARKWEB_RANSOMS,
  routes.ARTIFACTS_ENDPOINT,
  routes.ARTIFACTS_ENDPOINT_CROWDSTRIKE,
  routes.ARTIFACTS_ENDPOINT_SENTINELONE,
  routes.ARTIFACTS_ENDPOINT_SENTINELONE_AGENTS,
  routes.ARTIFACTS_ENDPOINT_SENTINELONE_THREATS,
  routes.ARTIFACTS_ENDPOINT_WINDOWS,
  routes.ARTIFACTS_ENDPOINT_SYSLOG,
  routes.ARTIFACTS_NETWORK,
  routes.ARTIFACTS_NETWORK_BEHAVIORS,
  routes.ARTIFACTS_NETWORK_SIGNATURES,
  routes.ARTIFACTS_NETWORK_FILEEXTRACTION,
  routes.ARTIFACTS_NETWORK_THREATINTEL,
  routes.ARTIFACTS_PHYSICAL,
  routes.ARTIFACTS_PHYSICAL_POINTOFENTRY,
  routes.ARTIFACTS_PHYSICAL_SURVEILLANCE,
  routes.ARTIFACTS_VULNS,
  routes.ARTIFACTS_VULNS_NESSUS,
  routes.ARTIFACTS_VULNS_OPENVAS,
  routes.ARTIFACTS_VULNS_QUALYS,
  routes.SYSTEM_TOOLS_ES_AUDIT_LOGS
];

export const showFiltersAndBookmarksOn = [
  routes.OVERVIEW,
  routes.ARTIFACTS_EXPLORE,
  routes.ARTIFACTS_CLOUD,
  routes.ARTIFACTS_CLOUD_BOX,
  routes.ARTIFACTS_CLOUD_DUO,
  routes.ARTIFACTS_CLOUD_GOOGLE,
  routes.ARTIFACTS_CLOUD_MERAKI,
  routes.ARTIFACTS_CLOUD_O365,
  routes.ARTIFACTS_CLOUD_OKTA,
  routes.ARTIFACTS_DARKWEB,
  routes.ARTIFACTS_DARKWEB_CREDENTIALS,
  routes.ARTIFACTS_DARKWEB_KEYWORDS,
  routes.ARTIFACTS_DARKWEB_RANSOMS,
  routes.ARTIFACTS_ENDPOINT,
  routes.ARTIFACTS_ENDPOINT_CROWDSTRIKE,
  routes.ARTIFACTS_ENDPOINT_SENTINELONE,
  routes.ARTIFACTS_ENDPOINT_SENTINELONE_AGENTS,
  routes.ARTIFACTS_ENDPOINT_SENTINELONE_THREATS,
  routes.ARTIFACTS_ENDPOINT_WINDOWS,
  routes.ARTIFACTS_ENDPOINT_SYSLOG,
  routes.ARTIFACTS_NETWORK,
  routes.ARTIFACTS_NETWORK_BEHAVIORS,
  routes.ARTIFACTS_NETWORK_SIGNATURES,
  routes.ARTIFACTS_NETWORK_FILEEXTRACTION,
  routes.ARTIFACTS_NETWORK_THREATINTEL,
  routes.ARTIFACTS_PHYSICAL,
  routes.ARTIFACTS_PHYSICAL_POINTOFENTRY,
  routes.ARTIFACTS_PHYSICAL_SURVEILLANCE,
  routes.ARTIFACTS_VULNS,
  routes.ARTIFACTS_VULNS_NESSUS,
  routes.ARTIFACTS_VULNS_OPENVAS,
  routes.ARTIFACTS_VULNS_QUALYS,
  routes.SYSTEM_TOOLS_ES_AUDIT_LOGS
];

export const showTimeSelectOn = [
  routes.OVERVIEW,
  routes.ARTIFACTS_EXPLORE,
  routes.ARTIFACTS_CLOUD,
  routes.ARTIFACTS_CLOUD_BOX,
  routes.ARTIFACTS_CLOUD_DUO,
  routes.ARTIFACTS_CLOUD_GOOGLE,
  routes.ARTIFACTS_CLOUD_MERAKI,
  routes.ARTIFACTS_CLOUD_O365,
  routes.ARTIFACTS_CLOUD_OKTA,
  routes.ARTIFACTS_DARKWEB,
  routes.ARTIFACTS_DARKWEB_CREDENTIALS,
  routes.ARTIFACTS_DARKWEB_KEYWORDS,
  routes.ARTIFACTS_DARKWEB_RANSOMS,
  routes.ARTIFACTS_ENDPOINT,
  routes.ARTIFACTS_ENDPOINT_CROWDSTRIKE,
  routes.ARTIFACTS_ENDPOINT_SENTINELONE,
  routes.ARTIFACTS_ENDPOINT_SENTINELONE_AGENTS,
  routes.ARTIFACTS_ENDPOINT_SENTINELONE_THREATS,
  routes.ARTIFACTS_NETWORK_FILEEXTRACTION,
  routes.ARTIFACTS_NETWORK_THREATINTEL,
  routes.ARTIFACTS_ENDPOINT_WINDOWS,
  routes.ARTIFACTS_ENDPOINT_SYSLOG,
  routes.ARTIFACTS_NETWORK,
  routes.ARTIFACTS_NETWORK_BEHAVIORS,
  routes.ARTIFACTS_NETWORK_SIGNATURES,
  routes.ARTIFACTS_PHYSICAL,
  routes.ARTIFACTS_PHYSICAL_POINTOFENTRY,
  routes.ARTIFACTS_PHYSICAL_SURVEILLANCE,
  routes.ARTIFACTS_VULNS,
  routes.ARTIFACTS_VULNS_NESSUS,
  routes.ARTIFACTS_VULNS_OPENVAS,
  routes.ARTIFACTS_VULNS_QUALYS,
  routes.SYSTEM_TOOLS_ES_AUDIT_LOGS
];

export const showExploreStringQuerySearch = [
  routes.OVERVIEW,
  routes.ARTIFACTS_EXPLORE,
  routes.ARTIFACTS_CLOUD,
  routes.ARTIFACTS_CLOUD_BOX,
  routes.ARTIFACTS_CLOUD_DUO,
  routes.ARTIFACTS_CLOUD_GOOGLE,
  routes.ARTIFACTS_CLOUD_MERAKI,
  routes.ARTIFACTS_CLOUD_O365,
  routes.ARTIFACTS_CLOUD_OKTA,
  routes.ARTIFACTS_DARKWEB,
  routes.ARTIFACTS_DARKWEB_CREDENTIALS,
  routes.ARTIFACTS_DARKWEB_KEYWORDS,
  routes.ARTIFACTS_DARKWEB_RANSOMS,
  routes.ARTIFACTS_ENDPOINT,
  routes.ARTIFACTS_ENDPOINT_CROWDSTRIKE,
  routes.ARTIFACTS_ENDPOINT_SENTINELONE,
  routes.ARTIFACTS_ENDPOINT_SENTINELONE_AGENTS,
  routes.ARTIFACTS_ENDPOINT_SENTINELONE_THREATS,
  routes.ARTIFACTS_NETWORK_FILEEXTRACTION,
  routes.ARTIFACTS_NETWORK_THREATINTEL,
  routes.ARTIFACTS_ENDPOINT_WINDOWS,
  routes.ARTIFACTS_ENDPOINT_SYSLOG,
  routes.ARTIFACTS_NETWORK,
  routes.ARTIFACTS_NETWORK_BEHAVIORS,
  routes.ARTIFACTS_NETWORK_SIGNATURES
];

export const showUpdateButtonOn = [
  routes.OVERVIEW,
  routes.ARTIFACTS_EXPLORE,
  routes.ARTIFACTS_CLOUD,
  routes.ARTIFACTS_CLOUD_BOX,
  routes.ARTIFACTS_CLOUD_DUO,
  routes.ARTIFACTS_CLOUD_GOOGLE,
  routes.ARTIFACTS_CLOUD_MERAKI,
  routes.ARTIFACTS_CLOUD_O365,
  routes.ARTIFACTS_CLOUD_OKTA,
  routes.ARTIFACTS_DARKWEB,
  routes.ARTIFACTS_DARKWEB_CREDENTIALS,
  routes.ARTIFACTS_DARKWEB_KEYWORDS,
  routes.ARTIFACTS_DARKWEB_RANSOMS,
  routes.ARTIFACTS_ENDPOINT,
  routes.ARTIFACTS_ENDPOINT_CROWDSTRIKE,
  routes.ARTIFACTS_ENDPOINT_SENTINELONE,
  routes.ARTIFACTS_ENDPOINT_SENTINELONE_AGENTS,
  routes.ARTIFACTS_ENDPOINT_SENTINELONE_THREATS,
  routes.ARTIFACTS_ENDPOINT_WINDOWS,
  routes.ARTIFACTS_ENDPOINT_SYSLOG,
  routes.ARTIFACTS_NETWORK,
  routes.ARTIFACTS_NETWORK_BEHAVIORS,
  routes.ARTIFACTS_NETWORK_SIGNATURES,
  routes.ARTIFACTS_NETWORK_FILEEXTRACTION,
  routes.ARTIFACTS_NETWORK_THREATINTEL,
  routes.ARTIFACTS_PHYSICAL,
  routes.ARTIFACTS_PHYSICAL_POINTOFENTRY,
  routes.ARTIFACTS_PHYSICAL_SURVEILLANCE,
  routes.ARTIFACTS_VULNS,
  routes.ARTIFACTS_VULNS_NESSUS,
  routes.ARTIFACTS_VULNS_OPENVAS,
  routes.ARTIFACTS_VULNS_QUALYS,
  routes.SYSTEM_TOOLS_ES_AUDIT_LOGS
];

export const ESGlobalFiltersContext = createContext(globalFiltersSettings);

export const ESGlobalFiltersProvider = props => {
  const [esGlobalFilters, setESGlobalFilters] = useState(globalFiltersSettings);

  return (
    <ESGlobalFiltersContext.Provider
      value={{ esGlobalFilters, setESGlobalFilters }}>
      {props.children}
    </ESGlobalFiltersContext.Provider>
  );
};

ESGlobalFiltersProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ESGlobalFiltersProvider;
