import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconAlert from 'components/common/IconAlert';
import React, { useState } from 'react';
import {
  Button,
  Card,
  Form,
  FormControl,
  FormText,
  InputGroup,
  Tab,
  Tabs
} from 'react-bootstrap';

/**
 * Renders the SettingsLayout component
 *
 * @returns {JSX.Element} The rendered SettingsLayout component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
const SettingsLayout = () => {
  const [showWarning, setShowWarning] = useState(true);
  const [showGoogleMapsAPIKey, setShowGoogleMapsAPIKey] = useState(false);
  const [showTinyMCEAPIKey, setShowTinyMCEAPIKey] = useState(false);
  const [showIP2LocationAPIKey, setShowIP2LocationAPIKey] = useState(false);

  return (
    <>
      {showWarning && (
        <IconAlert
          variant={'warning'}
          dismissible
          onClose={() => setShowWarning(false)}>
          <p className='mb-0'>
            Application settings are <strong>global settings</strong> that can
            change how both the frontend and backend application(s) behave.{' '}
            <strong>Please be careful when making changes.</strong>
          </p>
        </IconAlert>
      )}
      <Card className='shadow-none border'>
        <Card.Body className='position-relative p-0 pb-3'>
          <Tabs defaultActiveKey='security' id='uncontrolled-tab-example'>
            <Tab
              eventKey='security'
              title='Security'
              className='border-none mt-2 p-3'
              tabClassName='fw-semi-bold'>
              <h1 className='fs-2 fw-lighter'>Security Settings</h1>
              <h2 className='fs-1 py-3 fw-light'>General Security</h2>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Flex>
                  <Form.Check
                    inline
                    type='switch'
                    id='enforceMultiFactorAuth'
                    name='enforceMultiFactorAuth'
                    className='me-2'
                    defaultChecked
                  />
                  <Form.Label className='mb-0'>
                    Allow user registration
                  </Form.Label>
                </Flex>
                <FormText className='text-muted'>
                  Turn on to allow users to register their own accounts.
                </FormText>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Flex>
                  <Form.Check
                    inline
                    type='switch'
                    id='enforceMultiFactorAuth'
                    name='enforceMultiFactorAuth'
                    className='me-2'
                    defaultChecked
                  />
                  <Form.Label className='mb-0'>Allow user login</Form.Label>
                </Flex>
                <FormText className='text-muted'>
                  Turn on to allow users to login to their accounts.
                </FormText>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Flex>
                  <Form.Check
                    inline
                    type='switch'
                    id='enforceMultiFactorAuth'
                    name='enforceMultiFactorAuth'
                    className='me-2'
                    defaultChecked
                  />
                  <Form.Label className='mb-0'>Allow session lock</Form.Label>
                </Flex>
                <FormText className='text-muted'>
                  Turn on to allow users to lock their session.
                </FormText>
              </Form.Group>

              <h2 className='fs-1 py-3 fw-light'>Password Requirements</h2>
              <Form.Group className='mb-3' controlId='formGroupName'>
                <Flex>
                  <Form.Check
                    inline
                    type='switch'
                    id='enforceMultiFactorAuth'
                    name='enforceMultiFactorAuth'
                    className='me-2'
                    defaultChecked
                  />
                  <Form.Label className='mb-0'>
                    Require Alpha-Numeric Characters
                  </Form.Label>
                </Flex>
                <FormText className='text-muted'>
                  Turn on to require new passwords to contain both letters and
                  numbers.
                </FormText>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Flex>
                  <Form.Check
                    inline
                    type='switch'
                    id='enforceMultiFactorAuth'
                    name='enforceMultiFactorAuth'
                    className='me-2'
                    defaultChecked
                  />
                  <Form.Label className='mb-0'>
                    Require Capital Letters
                  </Form.Label>
                </Flex>
                <FormText className='text-muted'>
                  Turn on to require new passwords to contain at least one
                  capital letter.
                </FormText>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Flex>
                  <Form.Check
                    inline
                    type='switch'
                    id='enforceMultiFactorAuth'
                    name='enforceMultiFactorAuth'
                    className='me-2'
                    defaultChecked
                  />
                  <Form.Label className='mb-0'>
                    Require Special Characters
                  </Form.Label>
                </Flex>
                <FormText className='text-muted'>
                  Turn on to require new passwords to contain at least one
                  special character (e.g. !@#$%^&*).
                </FormText>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Form.Label className='mb-0'>
                  Minimum password length
                </Form.Label>
                <Form.Control
                  type='number'
                  size='sm'
                  className='filter-input w-auto mt-2'
                  id='enforceMultiFactorAuth'
                  name='enforceMultiFactorAuth'
                  value={8}
                />
                <FormText className='text-muted'>
                  Set the minimum password length for new users.
                </FormText>
              </Form.Group>

              <h2 className='fs-1 py-3 fw-light'>
                Multi-Factor Authentication
              </h2>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Form.Check
                  inline
                  type='switch'
                  id='enforceMultiFactorAuth'
                  name='enforceMultiFactorAuth'
                  className='me-2'
                  defaultChecked
                />
                <Form.Label className='mb-0'>
                  Enforce Multi-Factor Authentication
                </Form.Label>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Form.Check
                  inline
                  type='switch'
                  id='enforceMultiFactorAuth'
                  name='enforceMultiFactorAuth'
                  className='me-2'
                  defaultChecked
                />
                <Form.Label className='mb-0'>
                  Require users to verify their MFA OTP code when logging in
                </Form.Label>
              </Form.Group>
            </Tab>

            <Tab
              eventKey='email'
              title='Email'
              className='border-none mt-2 p-3'
              tabClassName='fw-semi-bold'>
              <h1 className='fs-2 fw-lighter'>Email Settings</h1>
            </Tab>

            <Tab
              eventKey='notification'
              title='Notification'
              className='border-none mt-2 p-3'
              tabClassName='fw-semi-bold'>
              <h1 className='fs-2 fw-lighter'>Notification Settings</h1>
              <h2 className='fs-1 py-3 fw-light'>Admin Notifications</h2>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Form.Label className='mb-0'>Admin Email(s)</Form.Label>
                <InputGroup className='mt-2 mb-1'>
                  <FormControl
                    type='text'
                    aria-label='TinyMCE API Key'
                    aria-describedby='basic-addon2'
                  />
                  <Button
                    variant='outline-secondary'
                    id='button-addon2'
                    onClick={() => setShowTinyMCEAPIKey(!showTinyMCEAPIKey)}>
                    <FontAwesomeIcon
                      icon={showTinyMCEAPIKey ? 'eye-slash' : 'eye'}
                    />
                  </Button>
                </InputGroup>
                <FormText className='text-muted'>
                  Enter the email address(es) that should receive administrative
                  notifications and alerts.
                </FormText>
              </Form.Group>
            </Tab>

            <Tab
              eventKey='privacy'
              title='Privacy'
              className='border-none mt-2 p-3'
              tabClassName='fw-semi-bold'>
              <h1 className='fs-2 fw-lighter'>Privacy Settings</h1>
              <h2 className='fs-1 py-3 fw-light'>On-Screen Privacy</h2>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Flex>
                  <Form.Check
                    inline
                    type='switch'
                    id='enforceMultiFactorAuth'
                    name='enforceMultiFactorAuth'
                    className='me-2'
                    defaultChecked
                  />
                  <Form.Label className='mb-0'>Enable privacy mode</Form.Label>
                </Flex>
                <FormText className='text-muted'>
                  Turn on to enable privacy mode for all users.
                </FormText>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Flex>
                  <Form.Check
                    inline
                    type='switch'
                    id='enforceMultiFactorAuth'
                    name='enforceMultiFactorAuth'
                    className='me-2'
                    defaultChecked
                  />
                  <Form.Label className='mb-0'>
                    Obscure user profile photos
                  </Form.Label>
                </Flex>
                <FormText className='text-muted'>
                  Privacy mode replaces user profile photos with first and last
                  name initials
                </FormText>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Flex>
                  <Form.Check
                    inline
                    type='switch'
                    id='enforceMultiFactorAuth'
                    name='enforceMultiFactorAuth'
                    className='me-2'
                    defaultChecked
                  />
                  <Form.Label className='mb-0'>
                    Obscure organization names
                  </Form.Label>
                </Flex>
                <FormText className='text-muted'>
                  Privacy mode replaces organization names with organization
                  tags where possible
                </FormText>
              </Form.Group>
            </Tab>

            <Tab
              eventKey='integration'
              title='Integration'
              className='border-none mt-2 p-3'
              tabClassName='fw-semi-bold'>
              <h1 className='fs-2 fw-lighter'>
                Third-Party Integration Settings
              </h1>

              <h2 className='fs-1 py-3 fw-light'>Google Maps</h2>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Form.Label className='mb-0'>API Key</Form.Label>
                <InputGroup className='mt-2 mb-1'>
                  <FormControl
                    type={showGoogleMapsAPIKey ? 'text' : 'password'}
                    aria-label='Google Maps API Key'
                    aria-describedby='basic-addon2'
                  />
                  <Button
                    variant='outline-secondary'
                    id='button-addon2'
                    onClick={() =>
                      setShowGoogleMapsAPIKey(!showGoogleMapsAPIKey)
                    }>
                    <FontAwesomeIcon
                      icon={showGoogleMapsAPIKey ? 'eye-slash' : 'eye'}
                    />
                  </Button>
                </InputGroup>
                <FormText className='text-muted'>
                  Enter your Google Maps API key to enable Google Maps
                  integration.
                </FormText>
              </Form.Group>

              <h2 className='fs-1 py-3 fw-light'>TinyMCE</h2>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Form.Label className='mb-0'>API Key</Form.Label>
                <InputGroup className='mt-2 mb-1'>
                  <FormControl
                    type={showTinyMCEAPIKey ? 'text' : 'password'}
                    aria-label='TinyMCE API Key'
                    aria-describedby='basic-addon2'
                  />
                  <Button
                    variant='outline-secondary'
                    id='button-addon2'
                    onClick={() => setShowTinyMCEAPIKey(!showTinyMCEAPIKey)}>
                    <FontAwesomeIcon
                      icon={showTinyMCEAPIKey ? 'eye-slash' : 'eye'}
                    />
                  </Button>
                </InputGroup>
                <FormText className='text-muted'>
                  Enter your TinyMCE API key to enable rich text editing.
                </FormText>
              </Form.Group>

              <h2 className='fs-1 py-3 fw-light'>IP2Location</h2>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Form.Label className='mb-0'>API Key</Form.Label>
                <InputGroup className='mt-2 mb-1'>
                  <FormControl
                    type={showIP2LocationAPIKey ? 'text' : 'password'}
                    aria-label='TinyMCE API Key'
                    aria-describedby='basic-addon2'
                  />
                  <Button
                    variant='outline-secondary'
                    id='button-addon2'
                    onClick={() =>
                      setShowIP2LocationAPIKey(!showIP2LocationAPIKey)
                    }>
                    <FontAwesomeIcon
                      icon={showIP2LocationAPIKey ? 'eye-slash' : 'eye'}
                    />
                  </Button>
                </InputGroup>
                <FormText className='text-muted'>
                  Enter your IP2Location API key to enable GeoIP and ASN
                  syncronization for data enrichment.
                </FormText>
              </Form.Group>
            </Tab>

            <Tab
              eventKey='system'
              title='System'
              className='border-none mt-2 p-3'
              tabClassName='fw-semi-bold'>
              <h1 className='fs-2 fw-lighter'>System Settings</h1>
            </Tab>

            <Tab
              eventKey='dangerZone'
              title='Danger Zone'
              className='border-none mt-2 p-3'
              tabClassName='fw-semi-bold'>
              <h1 className='fs-2 fw-lighter'>Danger Zone</h1>

              <IconAlert variant={'danger'}>
                <p className='mb-0'>
                  <strong>
                    These setting are dangerous. Please, be very careful here.
                  </strong>
                </p>
              </IconAlert>

              <h2 className='fs-1 py-3 fw-light'>Maintenance Mode</h2>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Flex>
                  <Form.Check
                    inline
                    type='switch'
                    id='enforceMultiFactorAuth'
                    name='enforceMultiFactorAuth'>
                    <Form.Check.Input className='form-check-input-danger me-3' />
                    <Form.Label className='mb-0 form-label-danger'>
                      Enable maintenance mode
                    </Form.Label>
                  </Form.Check>
                </Flex>
                <FormText className='text-muted'>
                  Disable all user access to the application and show a
                  maintenance page. Does not apply to administrators.
                </FormText>
              </Form.Group>

              <h2 className='fs-1 py-3 fw-light'>Access Controls</h2>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Flex>
                  <Form.Check
                    inline
                    type='switch'
                    id='enforceMultiFactorAuth2'
                    name='enforceMultiFactorAuth'>
                    <Form.Check.Input className='form-check-input-danger me-3' />
                    <Form.Label className='mb-0 form-label-danger'>
                      Disable new user logins
                    </Form.Label>
                  </Form.Check>
                </Flex>
                <FormText className='text-muted'>
                  Disallows new user login attempts. Does not affect existing
                  user sessions. Does not apply to administrators.
                </FormText>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGroupName'>
                <Form.Label className='mb-2 form-label-danger'>
                  Revoke all user sessions
                </Form.Label>
                <Button variant='danger' className='me-2 mb-1 d-block'>
                  Revoke All User Sessions
                </Button>
                <FormText className='text-muted'>
                  Revokes all user sessions, forcing them to log in again. Does
                  not apply to administrators.
                </FormText>
              </Form.Group>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

export default SettingsLayout;
