import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useOrganizations from 'hooks/admin-contexts/useOrganizations';
import useTeams from 'hooks/admin-contexts/useTeams';
import useUsers from 'hooks/admin-contexts/useUsers';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormText,
  Modal,
  Row,
  Tab,
  Tabs
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

/**
 * Renders the TeamEditForm component
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const TeamEditForm = () => {
  const {
    users: { data }
  } = useUsers();
  const { setTeams } = useTeams();
  const { organizations } = useOrganizations();
  const { axiosPrivate } = useAxiosPrivate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,

    register
  } = useForm();
  const { name, description, organization } = watch();
  const [loading, setLoading] = useState(false);

  /**
   * Submit the form data to create a new team
   * @param {Object} data
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const onSubmit = async data => {
    try {
      setLoading(true);
      let newData = data;

      newData['users'] = data?.users?.map(item => item.value);
      newData['leads'] = data?.leads?.map(item => item.value);

      const res = await LeargasAPI.AppTeams.createTeam(data, axiosPrivate);
      if (res instanceof Error) throw res;

      const { message } = res.data;
      setLoading(false);
      setTeams(prevState => ({
        ...prevState,
        addTeamModal: { open: false },

        fetch: true
      }));
      toast.success(message);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);
      setTeams(prevState => ({
        ...prevState,
        addTeamModal: { open: false },
        fetch: false
      }));

      if (error?.data?.response?.data?.message) {
        toast.error(error?.data?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  useEffect(() => {
    const filteredUsers = data
      ?.filter(user =>
        user?.organizations?.some(org => org.id === organization)
      )
      ?.map(user => ({
        value: user.id,
        label: user.profile.fullName
      }));
    setValue('users', prevUsers => {
      return prevUsers.filter(user =>
        filteredUsers.some(filteredUser => filteredUser.value === user.value)
      );
    });

    setValue('leads', prevLeads => {
      return prevLeads.filter(lead =>
        filteredUsers.some(filteredLead => filteredLead.value === lead.value)
      );
    });
  }, [organization, setValue]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Tabs defaultActiveKey='identity' id='uncontrolled-tab-example'>
        <Tab
          eventKey='identity'
          title='Identity'
          className='border-none p-3'
          tabClassName='fw-normal'>
          <Row className='g-3 mb-3'>
            <Col sm={12} className='required'>
              <Form.Group>
                <Form.Label>Organization</Form.Label>
                <Form.Select
                  {...register('organization')}
                  className='fs--1'
                  style={{ minHeight: '36px' }}>
                  <option>Please select</option>
                  {organizations?.data?.map((org, index) => (
                    <option key={index} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </Form.Select>
                <FormText className='text-muted'>
                  The organization this team belongs to.
                </FormText>
              </Form.Group>
            </Col>

            <Col sm={12} className='required'>
              <Form.Label>Team Name</Form.Label>
              <Form.Control
                {...register('name', { required: 'Team Name is required' })}
                type='text'
                autoComplete='off'
                className={`fs--1 ${errors.name ? 'is-invalid' : ''}`}
              />
              <code>{errors.name?.message}</code>
              <FormText className='text-muted'>
                Team name must be unique within the organization it belongs to.
              </FormText>
            </Col>

            <Col sm={12}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                {...register('description')}
                size='sm'
                autoComplete='off'
                className={`fs--1 ${errors.description ? 'is-invalid' : ''}`}
                style={{ minHeight: '36px' }}
              />
              <code>{errors.description?.message}</code>
              <FormText className='text-muted'>
                A short description of the team, it's purpose and function.
              </FormText>
            </Col>
          </Row>
        </Tab>

        <Tab
          eventKey='access'
          title='Access'
          className='border-none p-3'
          tabClassName='fw-normal'>
          <Row className='g-3 mb-3'>
            <Col sm={12}>
              <Form.Label>Team Members</Form.Label>
              <Controller
                name='users'
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <MultiSelect
                    className='fs--1'
                    isMulti
                    isClearable
                    isSearchable
                    placeholder='Select Users'
                    options={data
                      ?.filter(user =>
                        user?.organizations?.some(
                          org => org.id === organization
                        )
                      )
                      ?.map(user => ({
                        value: user.id,
                        label: user.profile.fullName
                      }))}
                    onChange={selectedOptions => {
                      field.onChange(selectedOptions);
                      setValue('users', selectedOptions);
                    }}
                    value={field.value}
                    style={{ minHeight: '36px' }}
                  />
                )}
              />
              <code>{errors.users?.message}</code>
            </Col>

            <Col sm={12}>
              <Form.Label>Team Leads</Form.Label>
              <Controller
                name='leads'
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <MultiSelect
                    className='fs--1'
                    isMulti
                    isClearable
                    isSearchable
                    placeholder='Select team Leads'
                    options={data
                      ?.filter(user =>
                        user?.organizations?.some(
                          org => org.id === organization
                        )
                      )
                      ?.map(user => ({
                        value: user.id,
                        label: user.profile.fullName
                      }))}
                    onChange={selectedOptions => {
                      field.onChange(selectedOptions);
                      setValue('leads', selectedOptions);
                    }}
                    value={field.value}
                    style={{ minHeight: '36px' }}
                  />
                )}
              />
              {/* <Controller
                name='lead'
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <Form.Control as='select' {...field} className='fs--1' style={{ minHeight: '36px' }}>
                    <option>Select Team Leads</option>;
                    {data?.map(user => {
                      return <option value={user._id}>{user?.profile?.fullName}</option>;
                    })}
                  </Form.Control>
                )}
              /> */}
              <code>{errors.leads?.message}</code>
            </Col>
          </Row>
        </Tab>
      </Tabs>
      <Modal.Footer className='border-top-0 pb-2'>
        <Flex justifyContent='end'>
          <Button
            variant='secondary'
            className='me-2'
            size='sm'
            onClick={() =>
              setTeams(prevState => ({
                ...prevState,
                addTeamModal: { open: false, data: {}, formData: {} }
              }))
            }>
            Cancel
          </Button>

          <Button
            variant='success'
            size='sm'
            type='submit'
            disabled={!name || !description}
            className='d-flex'>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <ButtonSpinner spinning={loading} />
              <span>{loading ? 'Creating' : 'Create'}</span>
            </Flex>
          </Button>
        </Flex>
      </Modal.Footer>
    </Form>
  );
};

export default TeamEditForm;
