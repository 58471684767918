import { useContext } from 'react';
import { ProductsContext } from 'context/admin-contexts/ProductsProvider';

/**
 * Custom hook to use the ProductsContext
 * @returns {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const useProducts = () => {
  return useContext(ProductsContext);
};

export default useProducts;
