// import { axiosPrivate } from 'api/connection/axios';
import { apiRoutes } from 'config/routeSettings';

class Resources {
  constructor(route) {
    this.route = route;
  }
  getAppResources(axiosPrivate) {
    return axiosPrivate.get(apiRoutes.RESOURCES);
  }

  getAppResource(id, axiosPrivate) {
    return axiosPrivate.get(`${apiRoutes.RESOURCES}/${id}`);
  }

  createAppResource(data, axiosPrivate) {
    return axiosPrivate.post(apiRoutes.RESOURCES, data);
  }

  updateAppResource(id, data, axiosPrivate) {
    return axiosPrivate.put(`${apiRoutes.RESOURCES}/${id}`, data);
  }

  deleteAppResource(id, axiosPrivate) {
    return axiosPrivate.delete(`${apiRoutes.RESOURCES}/${id}`);
  }

  deleteAll(axiosPrivate) {
    return axiosPrivate.delete(apiRoutes.RESOURCES);
  }
}

export default Resources;
