import React from 'react';
import PrivacyLayout from './PrivacyLayout';

/**
 * Renders the PrivacySettingsPage component
 *
 * @returns {JSX.Element} The rendered PrivacySettingsPage component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const PrivacySettingsPage = () => {
  return (
    <>
      <PrivacyLayout />
    </>
  );
};

export default PrivacySettingsPage;
