import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

const office365 = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'Office365 Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-o365'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_all: {}
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Events',
          helpInfo: 'Total number of Office365 events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-o365'],
        must: [
          {
            match_phrase: {
              'Operation.keyword': 'UserLoginFailed'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'Operation',
            value: 'UserLoginFailed'
          },
          title: 'Failed Logons',
          helpInfo:
            'Total number of Office365 events indicating failed authentication attempts'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-o365'],
        must: [
          {
            match_phrase: {
              'LogonError.keyword': 'UserStrongAuthClientAuthNRequiredInterrupt'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'LogonError',
            value: 'UserStrongAuthClientAuthNRequiredInterrupt'
          },
          title: 'Failed 2FA',
          helpInfo:
            'Total number of Office365 events indicating failed 2FA attempts'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-o365'],
        queryType: ['histogram']
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Event Timeline',
          helpInfo:
            'Timeline histogram of all Office365 events matching search criteria'
        }
      }
    },

    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-o365'],
        queryType: ['map'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'source_geoip.country_name.keyword',
                order: {
                  _count: 'desc'
                },
                exclude: [''],
                size: 100
              }
            }
          }
        }
      },
      children: {
        component: 'Map',
        props: {
          className: '',
          field: 'source_geoip.country_name',
          title: 'Event Source Countries',
          helpInfo: 'Geographical distribution of Office365 events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-o365'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'Workload.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          // showMissingBuckets: true,
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Workload',
          helpInfo: 'Office365 workloads generating events',
          field: 'Workload'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-o365'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            3: {
              terms: {
                field: 'event.action.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Event Actions',
          helpInfo: 'Actions generating Office365 events',
          field: 'event.action'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-o365'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'LogonError.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        },
        filter: [
          {
            exists: {
              field: 'LogonError.keyword'
            }
          }
        ]
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Logon Errors',
          helpInfo: 'Logon errors observed in Office365 events',
          field: 'LogonError'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-o365'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'UserAgent.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          // showMissingBuckets: true,
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'User Agents',
          helpInfo: 'User agents generating Office365 events',
          field: 'UserAgent'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*o365*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'UserId.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          helpInfo: 'User accounts generating Office365 events',
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'User Events',
            overlayText: '',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*o365*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'user.id.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'ObjectId.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5
                  }
                }
              }
            }
          },
          size: 0,
          showMissingBuckets: true
        },
        must: [
          {
            match_phrase: {
              'Operation.keyword': 'Add user.'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Users Added',
            overlayText: 'Office 365 user accounts added',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*o365*'],
        queryType: ['table'],
        filter: [
          {
            match_phrase: {
              'Operation.keyword': 'UserLoginFailed'
            }
          }
        ],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'UserId.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'ActorIpAddress.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 50
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'source_geoip.country_name.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 50
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          helpInfo:
            'Authentication failures by user account, IP address and country',
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Failed Logins',
            overlayText:
              'Authentication failures by user account, IP address and country',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-o365'],
        filter: [
          {
            match_phrase: {
              'LogonError.keyword': 'UserStrongAuthClientAuthNRequiredInterrupt'
            }
          }
        ],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'UserId.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'ActorIpAddress.keyword',
                    order: {
                      _count: 'desc'
                    },

                    size: 50
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'source_geoip.country_name.keyword',
                        order: {
                          _count: 'desc'
                        },

                        size: 50
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hhelpInfo:
            'Two-factor authentication failures by user account, IP address and country',
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Failed 2FA',
            overlayText:
              'Two-factor authentication failures by user account,IP address and country',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*o365*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'P2Sender.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'Recipients.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'Subject.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 5
                      },
                      aggs: {
                        5: {
                          terms: {
                            field: 'PolicyAction.keyword',
                            order: {
                              _count: 'desc'
                            },
                            size: 5
                          },
                          aggs: {
                            6: {
                              terms: {
                                field: 'LatestDeliveryLocation.keyword',
                                order: {
                                  _count: 'desc'
                                },
                                size: 5
                              },
                              aggs: {
                                7: {
                                  terms: {
                                    field: 'ThreatsAndDetectionTech.keyword',
                                    order: {
                                      _count: 'desc'
                                    },
                                    size: 5
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          // showMissingBuckets: true,
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          helpInfo: 'Office 365 email threat intelligence data',
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Email Threat Intel',
            overlayText: 'Threat intelligence data from email events',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*o365*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'user.id.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'Url.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 50
                  }
                }
              }
            }
          },
          size: 0,
          showMissingBuckets: true
        },
        filter: [
          {
            match_phrase: {
              'event.action': 'TIUrlClickData'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          helpInfo: 'Clicked threat intelligence URLs from Office 365 emails',
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Threat Clicked',
            overlayText: 'Potentially opened malicious URLs from email events',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*o365*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'UserId.keyword',
                order: {
                  _count: 'desc'
                },
                size: 5000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'source_geoip.country_name.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5000
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'client.ip.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 5000
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0,
          showMissingBuckets: true
        },
        must: [
          {
            match_phrase: {
              'event.action.keyword': 'UserLoginFailed'
            }
          },
          {
            match_phrase: {
              'LogonError.keyword': 'BlockedByConditionalAccess'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Conditional Access Failures',
            overlayText:
              'Conditional access failures by user account, IP address and country',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*o365*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'InvestigationType.keyword',
                order: {
                  _count: 'desc'
                },
                size: 5000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'InvestigationName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5000
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'Status.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 5000
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0,
          showMissingBuckets: true
        },
        must: [
          {
            match_phrase: {
              'event.action.keyword': 'AirInvestigationData'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Investigation',
            overlayText:
              'Automated investigation and response (AIR) in Microsoft Defender for Office 365',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*o365*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'user.id.keyword',
                order: {
                  _count: 'desc'
                },
                size: 4996
              },
              aggs: {
                3: {
                  terms: {
                    field: 'Parameters.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5000
                  }
                }
              }
            }
          },
          size: 0,
          showMissingBuckets: true
        },
        filter: [
          {
            match_phrase: {
              'Operation.keyword': 'Set-MailboxAutoReplyConfiguration'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'AutoResponder Changes',
            overlayText: 'Changes to autoresponder settings in Office 365',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas*o365*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'user.id.keyword',
                order: {
                  _count: 'desc'
                },
                size: 4996
              },
              aggs: {
                3: {
                  terms: {
                    field: 'P1SenderDomain.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5000
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'Subject.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 5000
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0,
          showMissingBuckets: true
        },
        filter: [
          {
            match_phrase: {
              'Operation.keyword': 'UserSubmission'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'User Submissions',
            overlayText: 'Emails sent by users in Office 365',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-o365'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'Category.keyword',
                order: {
                  _count: 'desc'
                },
                size: 4996
              },
              aggs: {
                3: {
                  terms: {
                    field: 'Severity.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5000
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'Name.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 5000
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0,
          showMissingBuckets: true
        },
        filter: [
          {
            match_phrase: {
              'Operation.keyword': 'InsightGenerated'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Insight Generated',
            overlayText: 'Insights generated by Office 365 events',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-o365'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'user.id.keyword',
                order: {
                  _count: 'desc'
                },
                size: 5000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'CopilotEventData.AccessedResources.Action.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5000
                  },
                  aggs: {
                    4: {
                      terms: {
                        field:
                          'CopilotEventData.AccessedResources.Type.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 5000
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0,
          showMissingBuckets: true
        },
        filter: [
          {
            match_phrase: {
              'Operation.keyword': 'CopilotInteraction'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'CoPilot Interaction',
            overlayText: 'CoPilot interaction events in Office 365',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    }
  ];
};

export default office365;
