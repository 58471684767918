import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Render button to Remove a new button
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const RemoveButton = ({ className, ...rest }) => {
  return (
    <Button
      {...rest}
      size='sm'
      variant='link'
      className={classNames(
        'position-relative rounded-circle border border-primary  cursor-pointer icon-item icon-item-sm  shadow-none',
        className
      )}>
      <FontAwesomeIcon icon='trash-alt' className='text-primary' />
    </Button>
  );
};

RemoveButton.propTypes = {
  className: PropTypes.string
};

export default RemoveButton;
