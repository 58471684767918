import React, { useState } from 'react';
import AlertsTable from './AlertsTable';
import AlertsTableFilters from './AlertsTableFilters';
import AlertsTableFooter from './AlertsTableFooter';
import AlertsTablePagination from './AlertsTablePagination';
import AlertsTableWrapper from './AlertsTableWrapper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import { Card, Dropdown } from 'react-bootstrap';

/**
 * alerts
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const columns = [
  {
    accessor: 'name',
    Header: 'Alert Information',
    noTruncate: true,
    hasRender: true,
    cellProps: {
      className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
    },
    headerProps: {
      className: 'fs--1 align-middle text-nowrap'
    },
    Cell: rowData => (
      <div>
        {/* Title */}
        <div style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
          {rowData.row.original.name}
        </div>

        {/* Subtitle/Description */}
        <div style={{ color: '#888', fontSize: '0.9em' }}>
          {rowData.row.original.description}
        </div>
      </div>
    )
  },
  {
    accessor: 'triggered',
    Header: 'Triggered',
    noTruncate: true,
    hasRender: true,
    cellProps: {
      className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
    },
    headerProps: {
      className: 'fs--1 align-middle text-nowrap'
    },
    Cell: rowData => (
      <div>
        {/* Title */}
        <div style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
          {rowData.row.original.triggered}
        </div>
      </div>
    )
  },
  {
    accessor: 'active',
    Header: 'Active',
    noTruncate: true,
    hasRender: true,
    cellProps: {
      className: 'fs--1 fw-normal text-700 align-middle text-nowrap'
    },
    headerProps: {
      className: 'fs--1 align-middle text-nowrap'
    },
    Cell: rowData => (
      <div>
        {/* Title */}
        <div
          className={rowData.row.original.isActive ? 'text-success' : ''}
          style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
          {rowData.row.original.isActive ? 'Yes' : 'No'}
        </div>
      </div>
    )
  },
  {
    accessor: 'leargas_actions',
    Header: '',
    Cell: () => {
      return (
        <Flex
          justifyContent='end'
          alignContent='middle'
          className='table-dropdown-columns'>
          <CardDropdown>
            <div className='py-2'>
              <Dropdown.Item>
                <FontAwesomeIcon icon='pencil' className='me-2' />
                Edit Details
              </Dropdown.Item>
              <Dropdown.Item
              // onClick={() =>
              //   setAlerts(prevState => ({
              //     ...prevState,
              //     viewAlertModal: {
              //       open: true,
              //       defaultData: rowData.row.original
              //     }
              //   }))
              //}
              >
                <FontAwesomeIcon icon='power-off' className='me-2' />
                Turn on/off
              </Dropdown.Item>
              <Dropdown.Item>
                <FontAwesomeIcon icon='file-export' className='me-2' />
                Edit as Search
              </Dropdown.Item>
            </div>
          </CardDropdown>
        </Flex>
      );
    },
    cellProps: {
      className: 'text-end text-400 actions-cell',
      style: {
        width: '42px'
      }
    },
    hideDisplaySettings: true,
    disableSortBy: true
  }
];
const data = [
  {
    name: 'Abnormal Azure Resource Manager Activity',
    description:
      'Monitors for unusual activity patterns in Azure Resource Manager, indicating potential misuse.',
    isActive: true,
    indices: ['azure-logs-001'],
    filters: [
      {
        id: '8d70242b-2a2b-498d-a514-437f30d72e9b',
        customLabel: '',
        enabled: true,
        field: {
          _id: '6488649ca87d7ad0a0d07942',
          accessor: 'clientTag',
          indice: '6477755fa87d7ad0a047a642',
          enabled: true,
          inputType: 'string',
          name: 'clientTag',
          sensitive: false,
          type: 'string',
          id: 'clientTag',
          Header: 'clientTag',
          hasRenderer: false,
          enabledSorting: true,
          canRemove: true
        },
        inclusion: true,
        operator: 'is',
        pinned: false,
        useCustomLabel: false,
        value: 'CLI-000047'
      },
      {
        id: 'e0062e0a-4508-4e85-9b1f-37a9d81c5ba3',
        customLabel: '',
        enabled: true,
        field: {
          _id: '6488649ca87d7ad0a0d078e5',
          accessor: 'clientNumber',
          indice: '6477755fa87d7ad0a047a642',
          enabled: true,
          inputType: 'string',
          name: 'clientNumber',
          sensitive: false,
          type: 'string',
          id: 'clientNumber',
          Header: 'clientNumber',
          hasRenderer: false,
          enabledSorting: true,
          canRemove: true
        },
        inclusion: true,
        operator: 'is',
        pinned: false,
        useCustomLabel: false,
        value: '47'
      },
      {
        id: 'c99a5143-136c-4d7d-9fe1-37b010d8d16c',
        customLabel: '',
        enabled: true,
        field: {
          _id: '648865c6a87d7ad0a0d0db73',
          accessor: 'agent.version',
          indice: '6477755fa87d7ad0a047a642',
          enabled: true,
          inputType: 'string',
          name: 'agent.version',
          sensitive: false,
          type: 'string',
          id: 'agent.version',
          Header: 'agent.version',
          hasRenderer: false,
          enabledSorting: true,
          canRemove: true
        },
        inclusion: true,
        operator: 'is',
        pinned: false,
        useCustomLabel: false,
        value: '8.8.1'
      }
    ],
    enabled: true,
    triggeredCount: 5,
    id: 'alert-001',
    createdBy: 'admin',
    createdAt: '2023-09-01T10:00:00Z',
    updatedBy: 'admin',
    updatedAt: '2023-09-05T14:00:00Z'
  },
  {
    name: 'Application Gateway WAF Alert',
    description:
      'Alerts for detections by the Web Application Firewall (WAF) that indicate potential threats.',
    isActive: true,
    indices: ['azure-logs-002'],
    filters: [
      {
        id: '8d70242b-2a2b-498d-a514-437f30d72e9b',
        customLabel: '',
        enabled: true,
        field: {
          _id: '6488649ca87d7ad0a0d07942',
          accessor: 'clientTag',
          indice: '6477755fa87d7ad0a047a642',
          enabled: true,
          inputType: 'string',
          name: 'clientTag',
          sensitive: false,
          type: 'string',
          id: 'clientTag',
          Header: 'clientTag',
          hasRenderer: false,
          enabledSorting: true,
          canRemove: true
        },
        inclusion: true,
        operator: 'is',
        pinned: false,
        useCustomLabel: false,
        value: 'CLI-000047'
      },
      {
        id: 'e0062e0a-4508-4e85-9b1f-37a9d81c5ba3',
        customLabel: '',
        enabled: true,
        field: {
          _id: '6488649ca87d7ad0a0d078e5',
          accessor: 'clientNumber',
          indice: '6477755fa87d7ad0a047a642',
          enabled: true,
          inputType: 'string',
          name: 'clientNumber',
          sensitive: false,
          type: 'string',
          id: 'clientNumber',
          Header: 'clientNumber',
          hasRenderer: false,
          enabledSorting: true,
          canRemove: true
        },
        inclusion: true,
        operator: 'is',
        pinned: false,
        useCustomLabel: false,
        value: '47'
      },
      {
        id: 'c99a5143-136c-4d7d-9fe1-37b010d8d16c',
        customLabel: '',
        enabled: true,
        field: {
          _id: '648865c6a87d7ad0a0d0db73',
          accessor: 'agent.version',
          indice: '6477755fa87d7ad0a047a642',
          enabled: true,
          inputType: 'string',
          name: 'agent.version',
          sensitive: false,
          type: 'string',
          id: 'agent.version',
          Header: 'agent.version',
          hasRenderer: false,
          enabledSorting: true,
          canRemove: true
        },
        inclusion: true,
        operator: 'is',
        pinned: false,
        useCustomLabel: false,
        value: '8.8.1'
      }
    ],
    enabled: true,
    triggeredCount: 12,
    id: 'alert-002',
    createdBy: 'admin',
    createdAt: '2023-08-25T09:30:00Z',
    updatedBy: 'security-team',
    updatedAt: '2023-09-02T11:20:00Z'
  },
  {
    name: 'Application Insights Availability Test Failure',
    description:
      'Detects failures in availability tests configured in Application Insights, indicating potential downtime.',
    indices: ['azure-logs-003'],
    filters: [
      {
        id: '8d70242b-2a2b-498d-a514-437f30d72e9b',
        customLabel: '',
        enabled: true,
        field: {
          _id: '6488649ca87d7ad0a0d07942',
          accessor: 'clientTag',
          indice: '6477755fa87d7ad0a047a642',
          enabled: true,
          inputType: 'string',
          name: 'clientTag',
          sensitive: false,
          type: 'string',
          id: 'clientTag',
          Header: 'clientTag',
          hasRenderer: false,
          enabledSorting: true,
          canRemove: true
        },
        inclusion: true,
        operator: 'is',
        pinned: false,
        useCustomLabel: false,
        value: 'CLI-000047'
      },
      {
        id: 'e0062e0a-4508-4e85-9b1f-37a9d81c5ba3',
        customLabel: '',
        enabled: true,
        field: {
          _id: '6488649ca87d7ad0a0d078e5',
          accessor: 'clientNumber',
          indice: '6477755fa87d7ad0a047a642',
          enabled: true,
          inputType: 'string',
          name: 'clientNumber',
          sensitive: false,
          type: 'string',
          id: 'clientNumber',
          Header: 'clientNumber',
          hasRenderer: false,
          enabledSorting: true,
          canRemove: true
        },
        inclusion: true,
        operator: 'is',
        pinned: false,
        useCustomLabel: false,
        value: '47'
      },
      {
        id: 'c99a5143-136c-4d7d-9fe1-37b010d8d16c',
        customLabel: '',
        enabled: true,
        field: {
          _id: '648865c6a87d7ad0a0d0db73',
          accessor: 'agent.version',
          indice: '6477755fa87d7ad0a047a642',
          enabled: true,
          inputType: 'string',
          name: 'agent.version',
          sensitive: false,
          type: 'string',
          id: 'agent.version',
          Header: 'agent.version',
          hasRenderer: false,
          enabledSorting: true,
          canRemove: true
        },
        inclusion: true,
        operator: 'is',
        pinned: false,
        useCustomLabel: false,
        value: '8.8.1'
      }
    ],
    enabled: false,
    triggeredCount: 3,
    id: 'alert-003',
    createdBy: 'monitoring-team',
    createdAt: '2023-07-18T08:15:00Z',
    updatedBy: 'monitoring-team',
    updatedAt: '2023-08-12T13:45:00Z'
  },
  {
    name: 'Azure AD Conditional Access Policy Change',
    description:
      'Monitors changes to Conditional Access Policies, which could weaken access security.',
    indices: ['azure-logs-004'],
    filters: [],
    enabled: true,
    triggeredCount: 7,
    id: 'alert-004',
    createdBy: 'security-team',
    createdAt: '2023-06-15T11:00:00Z',
    updatedBy: 'admin',
    updatedAt: '2023-07-22T12:00:00Z'
  },
  {
    name: 'Azure AD Domain Federation Configuration Change',
    description:
      'Detects changes to domain federation settings, which could impact authentication security.',
    indices: ['azure-logs-005'],
    isActive: true,
    filters: [],
    enabled: false,
    triggeredCount: 2,
    id: 'alert-005',
    createdBy: 'admin',
    createdAt: '2023-05-01T09:45:00Z',
    updatedBy: 'security-team',
    updatedAt: '2023-06-01T10:30:00Z'
  }
];
const Alerts = () => {
  const [globalFilter, setGlobalFilter] = useState('');

  return (
    <Card className='shadow-none border'>
      {/* <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          {/* <span className='d-block'>Alerts</span> */}
      {/* <Flex justifyContent='end' alignItems='center'>
            <ShowDeletedButton
              showDeleted={showDeleted}
              onClick={() => {
                setAlerts(prevState => ({
                  ...prevState,
                  showDeleted: !showDeleted
                }));
              }}
            />
            <AddButton
              onClick={() =>
                setAlerts(prevState => ({
                  ...prevState,
                  addAlertModal: { open: true }
                }))
              }
            />
          </Flex> 
        </Flex>
      </Card.Header> */}
      <Card.Body className='position-relative'>
        <AlertsTableWrapper
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          columns={columns}
          // data={alerts}
          data={data}
          sortable
          loading={false}
          pagination
          selection
          perPage={50}>
          <AlertsTableFilters
            table
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <AlertsTable table columns={columns} />
          {/* <AddButton
              label='Add a alert'
              onClick={() =>
                setAlerts(prevState => ({
                  ...prevState,
                  addAlertModal: { open: true }
                }))
              }
            /> */}
          <div className='mt-3 d-flex justify-content-between'>
            <AlertsTableFooter
              table
              rowInfo
              data={data}
              rowCount={data?.length}
            />
            <AlertsTablePagination table />
          </div>
        </AlertsTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default Alerts;
