import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

/**
 * Represents the account settings object
 *
 * @typedef {Object} AccountSettings
 * @property {string} id - The account ID
 * @property {Object} profile - The profile information
 * @property {string} profile.bio - The biography of the user
 * @property {Object} profile.media - The media information
 * @property {string} profile.media.avatar - The URL of the user's avatar
 * @property {string} profile.media.banner - The URL of the user's banner image
 * @property {Object} profile.name - The name information
 * @property {string} profile.name.first - The user's first name
 * @property {string} profile.name.last - The user's last name
 * @property {string} profile.nameDisplay - The display name of the user
 * @property {string} profile.namePronunciation - The pronunciation of the user's name
 * @property {string} profile.pronouns - The pronouns of the user
 * @property {Object} profile.social - The social media information
 * @property {string} profile.social.facebook - The user's Facebook profile URL
 * @property {string} profile.social.github - The user's GitHub profile URL
 * @property {string} profile.social.instagram - The user's Instagram profile URL
 * @property {string} profile.social.linkedin - The user's LinkedIn profile URL
 * @property {string} profile.social.twitter - The user's Twitter profile URL
 * @property {string} profile.social.youtube - The user's YouTube profile URL
 */
export const accountSettings = {
  id: '',
  profile: {
    bio: '',
    media: {
      avatar: '',
      banner: ''
    },
    name: {
      first: '',
      last: ''
    },
    nameDisplay: '',
    namePronunciation: '',
    pronouns: '',
    social: {
      facebook: '',
      github: '',
      instagram: '',
      linkedin: '',
      twitter: '',
      youtube: ''
    }
  }
};

/**
 * Create AccountContext
 * @type {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
export const AccountContext = createContext(accountSettings);

/**
 * Provides the account context for the application
 *
 * @param {Object} props - The component props
 * @returns {JSX.Element} The account provider component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const AccountProvider = props => {
  const [account, setAccount] = useState(accountSettings);

  const values = {
    account,
    setAccount
  };

  return (
    <AccountContext.Provider value={values}>
      {props.children}
    </AccountContext.Provider>
  );
};

AccountProvider.propTypes = {
  children: PropTypes.node
};

export default AccountProvider;
