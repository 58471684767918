import LeargasAPI from 'api';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

export const subscriptionLevelsSettings = {
  addSubscriptionLevelModal: {
    fullScreen: false,
    open: false
  },
  data: [],
  deleteSubscriptionLevelModal: {
    data: {},
    open: false
  },
  editSubscriptionLevelModal: {
    data: {},
    fullScreen: false,
    open: false
  },
  fetch: false,
  loading: false,
  requiredScopes: {
    subscriptionLevels: {
      delete: ['subscriptionLevels:delete'],
      read: ['subscriptionLevels:read'],
      write: ['subscriptionLevels:write']
    },
    subscriptionLevelsNotes: {
      delete: ['subscriptionLevelsNotes:delete'],
      read: ['subscriptionLevelsNotes:read'],
      write: ['subscriptionLevelsNotes:write']
    }
  },
  requiredSubscriptionLevels: {
    subscriptionLevels: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    },
    subscriptionLevelsNotes: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    }
  },
  showDeleted: false
};

/**
 * Create SubscriptionLevelsContext
 * @type {React.Context<{}>}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const SubscriptionLevelsContext = createContext(
  subscriptionLevelsSettings
);

/**
 * Create SubscriptionLevelsProvider
 * @param props
 * @returns {JSX.Element}
 * @constructor
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 *  <SubscriptionLevelsProvider>
 *   {children}
 * </SubscriptionLevelsProvider>
 * )
 */
const SubscriptionLevelsProvider = props => {
  const [subscriptionLevels, setSubscriptionLevels] = useState(
    subscriptionLevelsSettings
  );
  const [loading, setLoading] = useState(true);

  const { axiosPrivate } = useAxiosPrivate();

  /**
   * Get all application SubscriptionLevels
   * @returns {Promise<void>}
   * @async
   * @function
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  const getAppSubscriptionLevels = async () => {
    try {
      let res = await LeargasAPI.AppSubscriptionLevels.getSubscriptionLevels(
        axiosPrivate
      );
      if (res instanceof Error) throw res;

      setSubscriptionLevels(prevState => ({
        ...prevState,
        data: res?.data?.data
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setSubscriptionLevels(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setSubscriptionLevels(prevState => ({
        ...prevState,
        fetch: false
      }));
      setLoading(false);
    }
  };

  /**
   * Get all application SubscriptionLevels on fetch state change
   */
  useEffect(() => {
    if (subscriptionLevels.fetch) {
      getAppSubscriptionLevels();
    }
  }, [subscriptionLevels.fetch]);

  /**
   * Get all application SubscriptionLevels on component mount
   */
  useEffect(() => {
    getAppSubscriptionLevels();
  }, []);

  return (
    <SubscriptionLevelsContext.Provider
      value={{
        subscriptionLevels,
        setSubscriptionLevels,
        loading
      }}>
      {props.children}
    </SubscriptionLevelsContext.Provider>
  );
};

SubscriptionLevelsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SubscriptionLevelsProvider;
