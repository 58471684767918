import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Form } from 'react-bootstrap';

/**
 * Represents a custom checkbox component with indeterminate state.
 *
 * @component
 * @param {Object} props - The properties for the checkbox component
 * @param {boolean} props.indeterminate - Whether its in an indeterminate state
 * @param {string} props.className - The CSS class names for the checkbox
 * @param {React.Ref} props.ref - The ref object for the checkbox component
 * @returns {JSX.Element} The rendered component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, className, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      if (!resolvedRef.current) return;
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return rest?.selectionHidden ? null : (
      <Form.Check
        ref={resolvedRef}
        {...rest}
        type='checkbox'
        className={classNames('form-check mb-0 mh-0', className)}
      />
    );
  }
);

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool,
  className: PropTypes.string
};

export default IndeterminateCheckbox;
