import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import classNames from 'classnames';
import AdvancedPopover from 'components/common/Popover';
import EditSavedSearchModal from 'components/modals/saved-searches/EditSaveSearchModal';
import SavedSearchesListModal from 'components/modals/saved-searches/SavedSearchesListModal';
import AddSavedSearchModal from 'components/modals/saved-searches/SaveSearchModal';
import useSavedSearches from 'hooks/admin-contexts/useSavedSearches';
import useApplication from 'hooks/useApplication';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useExplore from 'hooks/useExplore';
import React from 'react';
import { Button, DropdownButton, DropdownItem } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';

/**
 * Represents the ExploreLayout component.
 *
 * @returns {JSX.Element}
 *
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const SavedSearchesLayout = () => {
  const { state } = useExplore();
  const {
    application: { isDark, showAddSavedSearchModal, showSavedSearchListModal },
    setApplication
  } = useApplication();
  const { setSavedSearches } = useSavedSearches();
  const { axiosPrivate } = useAxiosPrivate();

  const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });

  const saveLoadedSearch = async () => {
    try {
      let newData = {};
      newData['name'] = state.savedSearch.name;
      newData['description'] = state.savedSearch.description;
      newData['filters'] = state.filters;
      newData['fields'] = state.fieldsSelected
        .filter(item => item._id)
        .map(item => item._id);
      newData['indices'] = [state.indexSelected]
        .filter(item => item._id)
        .map(item => item._id);
      newData['_dashboard'] = state.savedSearch._dashboard;
      const res = await LeargasAPI.AppSavedSearches.updateSavedSearches(
        state.savedSearch?._id,
        newData,
        axiosPrivate
      );

      if (res instanceof Error) throw res;

      const { message } = res.data;
      setSavedSearches(prevState => ({
        ...prevState,
        fetch: true
      }));
      toast.success(message);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.', error);
      }
    }
  };

  return (
    <>
      <AdvancedPopover
        placement='bottom'
        showArrow={true}
        popoverText='Open a saved search'>
        <Button
          size='sm'
          variant='outline-secondary'
          className={classNames('ms-1 p-0 px-2 fw-normal fs--1', {
            'border-200': isDark,
            'border-300': !isDark
          })}
          onClick={() => {
            setApplication(
              'showSavedSearchListModal',
              !showSavedSearchListModal
            );
          }}>
          <FontAwesomeIcon
            icon={['fas', 'folder-open']}
            className={classNames({
              'me-1': isLargeScreen
            })}
            transform='down-1'
          />
          {isLargeScreen && 'Open'}
        </Button>
      </AdvancedPopover>
      <AdvancedPopover
        placement='bottom'
        showArrow={true}
        popoverText='Save current search'>
        {Object.keys(state.savedSearch).length === 0 && (
          <Button
            size='sm'
            variant='primary'
            className={classNames('ms-1 p-0 px-2 fw-normal fs--1', {
              'border-200': isDark,
              'border-300': !isDark
            })}
            onClick={() => {
              setApplication(
                'showAddSavedSearchModal',
                !showAddSavedSearchModal
              );
            }}>
            <FontAwesomeIcon
              icon={['fas', 'save']}
              className={classNames({
                'me-1': isLargeScreen
              })}
              transform='down-1'
            />
            {isLargeScreen && 'Save'}
          </Button>
        )}
        {Object.keys(state.savedSearch).length > 0 && (
          <DropdownButton
            title={
              <span>
                <FontAwesomeIcon
                  icon={['fas', 'save']}
                  className='me-1'
                  transform='down-1'
                />
                {isLargeScreen && 'Save'}
              </span>
            }
            size='sm'
            variant='outline-primary'
            className='saved-search-save-button ms-1 fw-normal fs--1 border-primary'>
            <DropdownItem
              onClick={() => {
                if (Object.keys(state.savedSearch).length > 0) {
                  saveLoadedSearch();
                } else {
                  setApplication(
                    'showAddSavedSearchModal',
                    !showAddSavedSearchModal
                  );
                }
              }}>
              <FontAwesomeIcon
                icon={['fas', 'save']}
                className='me-1'
                transform='down-1'
                width={16}
              />
              Save
            </DropdownItem>

            {Object.keys(state.savedSearch).length > 0 && (
              <DropdownItem
                onClick={() => {
                  setApplication(
                    'showAddSavedSearchModal',
                    !showAddSavedSearchModal
                  );
                }}>
                <span className='position-relative'>
                  <FontAwesomeIcon
                    icon={['fas', 'save']}
                    className='me-1'
                    transform='shrink-2 down-1 left-2'
                    width={16}
                  />
                  <FontAwesomeIcon
                    icon={['fas', 'save']}
                    className='me-1 opacity-25 position-absolute all-0'
                    transform='shrink-2 down-6 right-2'
                    width={16}
                  />
                </span>
                Save As
              </DropdownItem>
            )}
          </DropdownButton>
        )}
      </AdvancedPopover>
      <SavedSearchesListModal />
      <AddSavedSearchModal />
      <EditSavedSearchModal />
    </>
  );
};

export default SavedSearchesLayout;
