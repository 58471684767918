// import { axiosPrivate } from/ 'api/connection/axios';
import { makeRequest } from 'api/connection/requestAction';
import { apiRoutes } from 'config/routeSettings';
/**
 * @description Users API
 * @summary Provides methods for interacting with the users API
  
 * @method getUsers Get a list of all users
  
 */
class Scopes {
  constructor(route) {
    this.route = route;
  }
  async getAppScopes(axiosPrivate) {
    return axiosPrivate.get(apiRoutes.SCOPES);
  }
  async getUserScope(id, axiosPrivate) {
    const res = await makeRequest(
      axiosPrivate,
      this.route.getUserScope.method,
      [this.route.getUserScope.path, `user/${id}`].join('/')
    );
    if (res instanceof Error) throw res;
    return res;
  }
}
export default Scopes;
