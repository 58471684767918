import useAuthentication from 'hooks/useAuthentication';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { loadGeneralQuery } from './GeneralQuery';
import { loadHistogram } from './Histogram';
import { loadHistogrambars } from './HistogramBarsLegend';
import { loadMap } from './Map';
import { loadTable } from './Table';

// import { getHistogramInterval, getSparklineInterval } from 'helpers/utils';

export const StaticQueryIntegration = memo(
  ({
    children,
    name,

    queryType = [],
    aggregate = '',

    tableQuery = null,
    must = null,
    must_not = null,
    should = null,
    filter = null,
    overriddenIndex = null
  }) => {
    const { axiosPrivate } = useAxiosPrivate();

    const {
      authentication: { scopesSelected }
    } = useAuthentication();

    const [mapData, setMapData] = useState(null);
    const [statHistogram, setHistogram] = useState(null);

    const [flatAggregations, setAggregation] = useState(null);
    const [wrapperLoader, setWrapperLoader] = useState(true);
    const [generalquerydata, setGeneralQuery] = useState(null);

    const fetchData = async () => {
      const promises = queryType.map(async item => {
        switch (item) {
          case 'histogram':
            return loadHistogram({
              scopesSelected,
              axiosPrivate,
              setHistogram,
              setWrapperLoader,
              overriddenIndex,
              must,
              must_not,
              should,
              filter
            });
          case 'HistogramBarsLegend':
            return loadHistogrambars({
              scopesSelected,
              axiosPrivate,
              setHistogram,
              setWrapperLoader,
              overriddenIndex,
              must,
              must_not,
              should,
              filter
            });
          case 'GeneralQuery':
            return loadGeneralQuery({
              scopesSelected,
              axiosPrivate,
              aggregate,
              setGeneralQuery,
              setWrapperLoader,
              overriddenIndex,
              must,
              must_not,
              should,
              filter
            });
          case 'table':
            return loadTable({
              scopesSelected,
              axiosPrivate,
              tableQuery,
              setAggregation,
              setWrapperLoader,
              overriddenIndex,
              must,
              must_not,
              should,
              filter
            });
          case 'map':
            return loadMap({
              scopesSelected,
              axiosPrivate,
              aggregate,
              setMapData,
              setWrapperLoader,
              overriddenIndex,
              must,
              must_not,
              should,
              filter
            });

          default:
            return Promise.resolve();
        }
      });

      await Promise.all(promises);
    };

    useEffect(() => {
      fetchData();
    }, [scopesSelected]);

    useEffect(() => {
      return () => {
        setMapData(null);
        setHistogram(null);

        setAggregation(null);
        setWrapperLoader(false);
      };
    }, []);

    return (
      <>
        {React.cloneElement(children, {
          name: name,
          queryType: queryType,
          statHistogram: statHistogram,

          mapData: mapData,
          // match_phrase: matchPhrase,
          flatAggregations: flatAggregations,
          wrapperLoader: wrapperLoader,
          generalquerydata: generalquerydata
        })}
      </>
    );
  }
);

StaticQueryIntegration.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  queryType: PropTypes.array,
  aggregate: PropTypes.object,
  tableQuery: PropTypes.object,
  must: PropTypes.array,
  must_not: PropTypes.array,
  should: PropTypes.array,
  filter: PropTypes.array,

  overriddenIndex: PropTypes.array
};

export default StaticQueryIntegration;
