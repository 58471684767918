import FalconCardHeader from 'components/common/FalconCardHeader';
import useAuthentication from 'hooks/useAuthentication';
import useOrganizations from 'hooks/user-contexts/useUserOrganizations';
import PropTypes from 'prop-types';
import React from 'react';
import AddButton from '../AddButton';
import RemoveButton from '../RemoveButton';
// import OverlayUser from 'components/profileCards/UserProfileCard/OverlayUser';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import NormalUser from 'components/common/profile-cards/UserProfileCard/NormalUser';
import { Card, Col } from 'react-bootstrap';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const User = ({
  user,
  isLast
  // isAdmin
}) => (
  <Flex className={classNames('align-items-center', { 'mb-2': !isLast })}>
    {/* {isAdmin ? <OverlayUser user={user} avatarSize='2xl' /> : <NormalUser user={user} avatarSize='2xl' />} */}
    <NormalUser user={user} avatarSize='2xl' />
  </Flex>
);

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const OrgUsers = ({ users, isOwner, organization }) => {
  const { setOrganizations } = useOrganizations();
  const {
    authentication: { isAdmin }
  } = useAuthentication();
  return (
    <Col sm={6} md={4} xxl={4} className='mb-0 my-3 mb-xxl-0 position-relative'>
      <Card className='shadow-none'>
        <FalconCardHeader light title='Users' titleTag='h6' className='py-3' />
        <Card.Body className='py-3'>
          {users?.map((user, index) => (
            <Flex
              justifyContent='between'
              key={index}
              alignItems={'center'}
              className={classNames({
                'border-bottom mb-2': !(index === users.length - 1)
              })}>
              <User
                user={user}
                isLast={index === users.length - 1}
                isAdmin={isAdmin}
              />
              {isOwner && (
                <RemoveButton
                  onClick={() => {
                    setOrganizations(prevState => ({
                      ...prevState,
                      removeUserModal: {
                        open: true,
                        organization: organization,
                        user: user
                      }
                    }));
                  }}
                />
              )}
            </Flex>
          ))}
          {isOwner && (
            <AddButton
              label='Add a User'
              className='mt-2 ms-1'
              onClick={() => {
                setOrganizations(prevState => ({
                  ...prevState,
                  inviteUserModal: { open: true, organization: organization }
                }));
              }}
            />
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

User.propTypes = {
  user: PropTypes.object,
  isLast: PropTypes.bool,
  isAdmin: PropTypes.bool
};

OrgUsers.propTypes = {
  users: PropTypes.array,
  isOwner: PropTypes.bool,
  organization: PropTypes.object
};

export default OrgUsers;
