import classNames from 'classnames';
import useApplication from 'hooks/useApplication';
import useAuthentication from 'hooks/useAuthentication';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import FalconCloseButton from '../FalconCloseButton';
import Flex from '../Flex';

/**
 * Renders the Scope toggle component.
 *
 * This component displays a list of the user's organizations that can be toggled on or off.
 * Doing so will allow the user to filter almost any org-specific data in the application.
 *
 * @returns {JSX.Element} The rendered Scope toggle component
 */
const ScopesToggleList = ({ setShow }) => {
  const {
    application: { isPrivacyMode }
  } = useApplication();

  const {
    authentication: { scopes, scopesSelected },
    setAuthentication
  } = useAuthentication();

  const [selectedScopeIds, setSelectedScopeIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term

  /**
   * Handles the selection of all scopes.
   */
  const handleSelectAllScopes = () => {
    // Set the selected scope IDs to all scope IDs
    setSelectedScopeIds(scopes.map(scope => scope.id));
  };

  /**
   * Clears all selected scopes.
   */
  const handleClearAllScopes = () => {
    // Clear the selected scope IDs
    setSelectedScopeIds([]);
  };

  /**
   * Toggles the selected state of a scope based on its ID.
   *
   * If the scope is already selected, it will be deselected.
   * If the scope is not selected, it will be selected.
   *
   * @param {string} id - The ID of the scope to toggle
   */
  const toggleScopeSelected = id => {
    if (selectedScopeIds.includes(id)) {
      // Remove the scope from the selected scope IDs
      setSelectedScopeIds(selectedScopeIds.filter(scopeId => scopeId !== id));
    } else {
      // Add the scope to the selected scope IDs
      setSelectedScopeIds([...selectedScopeIds, id]);
    }
  };

  /**
   * Handle updating the selected scopes in the authentication context.
   *
   * This will update the selected scopes in the context when the apply button is clicked.
   * This will also close the dropdown.
   *
   * @returns {void}
   */
  const handleUpdateSelectedScopes = () => {
    // Set the selected scopes to the scopes that match the selected scope IDs
    setAuthentication(prevState => ({
      ...prevState,
      scopesSelected:
        scopes.filter(scope => selectedScopeIds.includes(scope.id)) || []
    }));
    // Close the dropdown
    setShow(false);
  };

  /**
   * Sets the selected scope IDs when the selected scopes change.
   */
  useEffect(() => {
    setSelectedScopeIds(scopesSelected.map(scope => scope.id));
  }, [scopesSelected]);

  /**
   * Filters displayed scopes based on search term input for name and tag.
   */
  const filterScopes = () => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    return scopes.filter(
      scope =>
        scope.name.toLowerCase().includes(lowercasedSearchTerm) ||
        (scope.legacy?.clientTag || '')
          .toLowerCase()
          .includes(lowercasedSearchTerm)
    );
  };

  return (
    <>
      <Flex direction='column'>
        <Dropdown.Header className='fs--2 text-700'>
          Select Scope
        </Dropdown.Header>
        <Form.Group
          className={classNames('px-3', {
            'd-none': scopes.length <= 15
          })}>
          <div className='position-relative'>
            <Form.Control
              id='search'
              size='sm'
              placeholder={`Search organizations...`}
              tabIndex='2'
              autoComplete='off'
              className='fs--1'
              value={searchTerm} // Controlled input for search
              onChange={e => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <div
                className='position-absolute top-0 end-0'
                style={{ marginTop: '5px' }}>
                <FalconCloseButton
                  size='sm'
                  className='fs--2 me-1'
                  noOutline
                  onClick={() => setSearchTerm('')}
                />
              </div>
            )}
          </div>
        </Form.Group>
        <Flex
          className={classNames('px-3 my-2', {
            'd-none': scopes.length <= 2
          })}>
          <Button
            size='sm'
            variant='outline-primary'
            className={classNames(
              'me-1 p-0 px-2 fw-normal fs--1 position-relative'
            )}
            onClick={handleSelectAllScopes}>
            <span className='me-1'>Select All</span>
          </Button>
          <Button
            size='sm'
            variant='outline-secondary'
            className={classNames(
              'me-1 p-0 px-2 fw-normal fs--1 position-relative'
            )}
            onClick={handleClearAllScopes}>
            <span className='me-1'>Clear All</span>
          </Button>
        </Flex>
        <SimpleBar
          forceVisible={true}
          autoHide={false}
          style={{
            maxHeight: '415px',
            overflowX: 'hidden'
          }}>
          <div className='position-relative px-1 pe-2'>
            {filterScopes().map((item, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  className='position-relative'
                  onClick={() => toggleScopeSelected(item?.id)}>
                  <Flex
                    justifyContent='start'
                    alignItems='center'
                    className='overflow-hidden'>
                    <Form.Check
                      inline
                      size='sm'
                      type='switch'
                      className='fs--2 mb-0 me-0 cursor-pointer'
                      onChange={() => {}}
                      checked={selectedScopeIds.includes(item?.id)}
                      style={{ pointerEvents: 'none' }}
                    />
                    <Form.Label className='fw-normal fs--2 mb-0 text-overflow-ellipsis overflow-hidden cursor-pointer'>
                      {isPrivacyMode ? item?.legacy?.clientTag : item?.name}
                    </Form.Label>
                  </Flex>
                </Dropdown.Item>
              );
            })}
          </div>
        </SimpleBar>
        <Flex className={'px-3 my-2'}>
          <Button
            size='sm'
            variant='primary'
            disabled={
              scopesSelected.length === selectedScopeIds.length &&
              scopesSelected.every(scope => selectedScopeIds.includes(scope.id))
            }
            className={classNames(
              'mt-1 p-0 px-2 fw-normal fs--1 position-relative w-100'
            )}
            onClick={handleUpdateSelectedScopes}>
            <span className='me-1'>Apply Changes</span>
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

ScopesToggleList.propTypes = {
  setShow: PropTypes.func.isRequired
};

export default ScopesToggleList;
