import { setItemToStore } from 'helpers/utils';
import {
  autoRefreshIntervals,
  defaultSelectedFields,
  timeRanges
} from './settings';

/**
 * Initial state object for the ExploreProvider context.
 * @typedef {Object} initialState
 * @property {boolean} autoRefresh - Flag indicating whether auto-refresh is enabled.
 * @property {Array} autoRefreshIntervals - Array of auto-refresh intervals.
 * @property {number} autoRefreshIntervalSelected - The selected auto-refresh interval in milliseconds.
 * @property {boolean} debug - Flag indicating whether debug mode is enabled.
 * @property {Array} documents - Array of documents.
 * @property {null} documentsError - Error object for documents.
 * @property {boolean} documentsLoading - Flag indicating whether documents are loading.
 * @property {number} documentsTotal - Total number of documents.
 * @property {null} error - Error object.
 * @property {string} errorMessage - Error message.
 * @property {boolean} exploreModalFullscreen - Flag indicating whether the explore modal is in fullscreen mode.
 * @property {boolean} exploreModalLoading - Flag indicating whether the explore modal is loading.
 * @property {boolean} exploreModalOpen - Flag indicating whether the explore modal is open.
 * @property {Array} fields - Array of fields.
 * @property {null} fieldsError - Error object for fields.
 * @property {boolean} fieldsLoading - Flag indicating whether fields are loading.
 * @property {Array} fieldsSelected - Array of selected fields.
 * @property {Array} fieldsSelectedDefault - Array of default selected fields.
 * @property {Array} fieldsSelectedFallback - Array of fallback selected fields.
 * @property {number} fieldsTotal - Total number of fields.
 * @property {Array} fieldTypes - Array of field types.
 * @property {null} fieldTypesError - Error object for field types.
 * @property {Array} fieldTypesHidden - Array of hidden field types.
 * @property {boolean} fieldTypesLoading - Flag indicating whether field types are loading.
 * @property {number} fieldTypesTotal - Total number of field types.
 * @property {Array} histogram - Array of histogram data.
 * @property {null} histogramError - Error object for histogram data.
 * @property {boolean} histogramLoading - Flag indicating whether histogram data is loading.
 * @property {Object} indexSelected - Selected index object.
 * @property {Array} indices - Array of indices.
 * @property {null} indicesError - Error object for indices.
 * @property {boolean} indicesLoading - Flag indicating whether indices are loading.
 * @property {string} interval - Time interval.
 * @property {boolean} loading - Flag indicating whether data is loading.
 * @property {Object} pagination - Pagination object.
 * @property {number} pagination.pageIndex - Current page index.
 * @property {number} pagination.pageSize - Number of items per page.
 * @property {Object} query - Query object.
 * @property {Object} sorting - Sorting object.
 * @property {string} sorting.sortField - Field to sort by.
 * @property {string} sorting.sortOrder - Sort order.
 * @property {Object} timeRange - Time range object.
 * @property {string} timeRange.from - Start time of the range.
 * @property {string} timeRange.to - End time of the range.
 * @property {Object} timeRanges - Time ranges object.
 * @property {Object} timeRangeSelected - Selected time range object.
 * @property {Array} topValues - Array of top values.
 * @property {null} topValuesError - Error object for top values.
 * @property {boolean} topValuesLoading - Flag indicating whether top values are loading.
 * @property {boolean} topValuesModalShow - Flag indicating whether the top values modal is shown.
 * @property {string} topValuesSelectedFieldName - Selected field name for top values.
 * @property {boolean} update - Flag indicating whether the state needs to be updated.
 * @property {Array} filters - Array of filters.
 * @property {Object} operators - Operators object.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const initialState = {
  autoRefresh: false,
  autoRefreshIntervals: autoRefreshIntervals,
  autoRefreshIntervalSelected: 30000,
  debug: true,
  documents: [],
  documentsError: null,
  documentsLoading: true,
  documentsScrollLoading: false,
  documentsTotal: 0,
  error: null,
  errorMessage: '',
  exploreModalFullscreen: false,
  exploreModalLoading: false,
  exploreModalOpen: false,
  fields: [],
  fieldsError: null,
  fieldsLoading: true,
  fieldsPresent: [],
  fieldsPresentOnly: false,
  fieldsSelected: defaultSelectedFields,
  fieldsSelectedDefault: [],
  fieldsSelectedFallback: defaultSelectedFields,
  fieldsTotal: 0,
  fieldTypes: [],
  fieldTypesError: null,
  fieldTypesHidden: [],
  fieldTypesLoading: true,
  fieldTypesPresent: [],
  fieldTypesTotal: 0,
  filters: [],
  filtersDefault: [],
  histogram: [],
  histogramError: null,
  histogramLoading: true,
  indexSelected: {},
  indices: [],
  indicesError: null,
  indicesLoading: true,
  interval: '1m',
  loading: false,
  operators: {},
  pagination: {
    pageIndex: 1,
    pageSize: 50
  },
  query: {},
  savedSearch: {},
  sorting: {
    sortField: '@timestamp',
    sortOrder: 'desc'
  },
  timeRange: {
    from: '',
    to: ''
  },
  timeRanges: timeRanges,
  timeRangeSelected: timeRanges.hours.options[0],
  gptDataResponse: '',
  gptDataError: null,
  gptDataLoading: false,
  gptDataModalShow: false,
  gptDataSelectedFieldId: '',
  gptDataSelectedValue: '',
  auroraDataResponse: '',
  auroraDataError: null,
  auroraDataLoading: false,
  auroraDataModalShow: false,
  auroraDataSelectedFieldId: '',
  auroraDataSelectedValue: '',
  topValues: [],
  topValuesError: null,
  topValuesLoading: false,
  topValuesModalShow: false,
  topValuesSelectedFieldName: '',
  topValuesSelectedFieldType: '',
  update: false
};

/**
 * Defines the state actions for the ExploreProvider context.
 *
 * @typedef {Object} stateActions
 * @property {string} SET_STATE - The action type for setting the state
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const stateActions = {
  SET_STATE: 'SET_STATE'
};

/**
 * Reducer function for the state in ExploreProvider.
 *
 * @param {Object} state - The current state
 * @param {Object} action - The action object
 * @param {string} action.type - The type of action
 * @param {Object} action.payload - The payload of the action
 * @param {string} action.payload.key - The key to update in the state
 * @param {*} action.payload.value - The new value for the key in the state
 * @returns {Object} - The updated state
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const stateReducer = (state, action) => {
  switch (action.type) {
    case stateActions.SET_STATE:
      if (action.payload.setInStore) {
        setItemToStore(
          `leargas-explore-${action.payload.key}`,
          action.payload.value
        );
      }
      return { ...state, [action.payload.key]: action.payload.value };
    default:
      return state;
  }
};
