import React from 'react';
import PropTypes from 'prop-types';
import ArticlesProvider from '../../../../../../context/admin-contexts/ArticlesProvider';
import ArticlesLayout from './ArticlesLayout';
import ModalArticleAdd from './components/modals/ArticlesAddModal';
import ModalArticleEdit from './components/modals/ArticlesEditModal';
import ModalArticleDelete from './components/modals/ArticlesDeleteModal';
import ArticleCategoriesAddModal from './ArticleCategories/components/modals/ArticleCategoriesAddModal';
import ArticleCategoriesEditModal from './ArticleCategories/components/modals/ArticleCategoriesEditModal';
import ArticleCategoriesDeleteModal from './ArticleCategories/components/modals/ArticleCategoriesDeleteModal';
import ArticleTagsAddModal from './ArticleTags/components/modals/ArticleTagsAddModal';
import ArticleTagsEditModal from './ArticleTags/components/modals/ArticleTagsEditModal';
import ArticleTagsDeleteModal from './ArticleTags/components/modals/ArticleTagsDeleteModal';

/**
 *
 * @param { props }
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticlesWrapper = props => {
  return (
    <ArticlesProvider>
      {props.children}
      <ArticlesLayout />
      <ModalArticleAdd />
      <ModalArticleEdit />
      <ModalArticleDelete />
      {/* Modal for categories */}
      <ArticleCategoriesAddModal />
      <ArticleCategoriesEditModal />
      <ArticleCategoriesDeleteModal />
      {/* Modal for tags */}
      <ArticleTagsAddModal />
      <ArticleTagsEditModal />
      <ArticleTagsDeleteModal />
    </ArticlesProvider>
  );
};

ArticlesWrapper.propTypes = { children: PropTypes.node };

export default ArticlesWrapper;
