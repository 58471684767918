import 'chart.js/auto';
import { formatBytes, getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Chart } from 'react-chartjs-2';

const ArchSplitLineChart = ({ data, tooltipLabelFormat }) => {
  const { labels, values, backgroundColors, borderColors } = data;
  const consolidatedDatasets = {
    data: values,
    backgroundColor: backgroundColors ? backgroundColors : undefined,
    borderColor: borderColors ? borderColors : undefined
  };
  const chartOptions = {
    redraw: true,
    rotation: -90,
    circumference: '180',
    cutout: '80%',
    layout: {
      padding: 0
    },
    grid: [0, 0, 0, 0],
    animation: {
      duration: 750
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          color: getColor('dark'),
          font: {
            size: 11,
            weight: 400,
            lineHeight: 2
          }
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return tooltipLabelFormat === 'storage'
              ? ' ' + context.label + ': ' + formatBytes(context.raw)
              : ' ' + context.label + ': ' + context.raw.toLocaleString();
          }
        }
      }
    }
  };
  const chartData = {
    labels: labels,
    datasets: [consolidatedDatasets]
  };
  return (
    <Chart
      type='doughnut'
      data={chartData}
      options={chartOptions}
      style={{ minHeight: '150px' }}
      className='m-0 p-0'
    />
  );
};

ArchSplitLineChart.propTypes = {
  data: PropTypes.object,
  tooltipLabelFormat: PropTypes.string
};

export default ArchSplitLineChart;
