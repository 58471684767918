import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import useApplication from 'hooks/useApplication';
import CreatableSelect from 'react-select/creatable';
const MultiSelect = forwardRef(({ options, ...rest }, ref) => {
  const {
    application: { isDark }
  } = useApplication();

  return (
    <CreatableSelect
      ref={ref}
      closeMenuOnSelect={true}
      placeholder='Please select'
      as={Form.Select}
      options={options}
      isOptionDisabled={option => option.isDisabled === true}
      maxMenuHeight={250}
      menuPosition='fixed'
      styles={{
        container: provided => ({
          ...provided,
          padding: '0',
          margin: '0',
          border: '0',
          fontSize: '0.6944444444rem',
          lineHeight: '0.6944444444rem',
          color: 'var(--falcon-input-color)'
        }),
        menu: provided => ({
          ...provided,
          margin: '0',
          padding: '0',
          backgroundColor: 'var(--falcon-input-bg)'
        }),
        multiValue: provided => ({
          ...provided,
          marginTop: '2px',
          padding: '1px 2px',
          backgroundColor: isDark
            ? 'var(--falcon-card-bg)'
            : 'var(--falcon-gray-300)',
          border: isDark
            ? '1px solid var(--falcon-border-color)'
            : '1px solid var(--falcon-border-color)'
        }),
        multiValueLabel: provided => ({
          ...provided,
          padding: '3px 2px 3px 6px',
          fontSize: '0.8333333333rem',
          color: 'var(--falcon-gray-700)'
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: 'var(--falcon-input-bg)',
          color: state.isDisabled
            ? 'var(--falcon-gray-400)'
            : 'var(--falcon-input-color)',
          fontSize: '0.8333333333rem',
          cursor: !state.isDisabled ? 'pointer' : 'default',
          '&:hover': {
            backgroundColor: 'var(--falcon-primary)',
            color: 'white'
          }
        }),
        input: provided => ({
          ...provided,
          color: 'var(--falcon-input-color)'
        }),
        control: provided => ({
          ...provided,
          margin: '0',
          minHeight: '36px',
          paddingLeft: '0',
          color: 'var(--falcon-input-color)',
          backgroundColor: 'var(--falcon-input-bg)',
          fontSize: '0.8333333333rem',
          fontFamily:
            'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
          border: '1px solid var(--falcon-input-border-color)',
          boxShadow: 'var(--falcon-box-shadow-inset)',
          transition:
            'background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
          ':hover': {
            borderColor: 'var(--falcon-input-focus-border-color)',
            outline: '0'
          }
        }),
        placeholder: provided => ({
          ...provided,
          paddingLeft: '0'
        }),
        valueContainer: provided => ({
          ...provided,
          marginTop: '0',
          marginLeft: '1px',
          padding: '0 0 0 8px',
          border: '0'
        }),
        dropdownIndicator: provided => ({
          ...provided,
          marginTop: '0',
          marginLeft: '5px',
          marginRight: '5px',
          padding: '0',
          border: '0',
          width: '16px',
          cursor: 'pointer'
        }),
        clearIndicator: provided => ({
          ...provided,
          marginTop: '0',
          marginLeft: '5px',
          marginRight: '5px',
          padding: '0',
          border: '0',
          width: '16px',
          cursor: 'pointer'
        }),
        indicatorsContainer: provided => ({
          ...provided,
          top: '0',
          paddingRight: '4px',
          border: '0'
        }),
        multiValueRemove: provided => ({
          ...provided,
          color: 'var(--falcon-gray-600)'
        })
      }}
      className={{
        container: ['fs--2'],
        control: ['fs--2 text-dark'],
        multiValueLabel: ['fs--2']
      }}
      {...rest}
    />
  );
});

MultiSelect.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string
};

export default MultiSelect;
