import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useArticles from 'hooks/admin-contexts/useArticles';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Modal } from 'react-bootstrap';
import ArticleCategoriesEditForm from '../forms/ArticleCategoriesEditForm';
/**
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticleCategoriesEditModal = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    articles: { editArticleCategoryModal },
    setArticles
  } = useArticles();

  return (
    <Modal
      centered
      size='lg'
      animation={false}
      backdrop='static'
      keyboard={false}
      show={editArticleCategoryModal?.open}
      onHide={() =>
        setArticles(prevState => ({
          ...prevState,
          editArticleCategoryModal: { open: false }
        }))
      }
      aria-labelledby='modalArticleAdd'>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Edit a category
          <AdvancedPopover
            containerId='resetConfirmModal'
            placement='top'
            popoverText='Edit category'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
      </Modal.Header>

      <Modal.Body className='p-2'>
        <ArticleCategoriesEditForm />
      </Modal.Body>
    </Modal>
  );
};

export default ArticleCategoriesEditModal;
