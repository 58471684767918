import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useAuthentication from 'hooks/useAuthentication';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import useLogout from 'hooks/useLogout';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Form, Image, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

/**
 * MFA app setup form
 *
 * The MFA app setup form is used to display a form to the user to enable a multi-factor authentication app.
 * The user is shown their secret key and a QR code to scan with their authenticator app. The user is then
 * prompted to enter the code from their authenticator app to verify the setup.
 * @param {Object} props The component props
 * @param {boolean} props.hasLabel Whether or not to display a label for the verification code input
 * @returns {React.Component} The MFA app setup form
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const MFASetupForm = ({ hasLabel }) => {
  const focusField = useRef();
  const logout = useLogout();

  const { authentication, setAuthentication } = useAuthentication();
  const { axiosPrivate } = useAxiosPrivate();

  const [imageLoaded, setImageLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState('');
  const [secret, setSecret] = useState('');
  const [code, setCode] = useState('');

  /**
   * Fetches the user's multifactor app QR code image and sets it as the component's image source
   * @returns {Promise<void>} A promise that resolves when the image is fetched and set
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const fetchImage = async () => {
    try {
      const data = await LeargasAPI.AccountSecurity.getUserMultifactorAppQr(
        axiosPrivate
      );
      if (data instanceof Error) throw data;
      const b64Data = btoa(
        new Uint8Array(data?.data).reduce((dataArray, byte) => {
          return dataArray + String.fromCharCode(byte);
        }, '')
      );
      setImg(`data:image/png;base64,${b64Data}`);
      setTimeout(function () {
        setImageLoaded(true);
      }, 1000);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }
    }
  };

  /**
   * Retrieves the user's multifactor app secret from the server and sets it in the component state
   * Also fetches an image
   * @returns {Promise<void>} A promise that resolves when the secret is retrieved and set
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const handleSecret = async () => {
    LeargasAPI.AccountSecurity.getUserMultifactorAppSecret(axiosPrivate)
      .then(res => {
        if (res instanceof Error) throw res;
        setSecret(res?.data?.data?.mfaSecret);
      })
      .catch(error => {
        if (error.message.includes('Network Error')) {
          console.error('Network Error occurred.');
        }
      });
    fetchImage();
  };

  /**
   * Handles the form submission for MFA setup
   *
   * @param {Event} e - The form submission event
   * @returns {Promise<void>}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await LeargasAPI.AccountAuth.userSessionVerify(
        {
          body: { code }
        },
        axiosPrivate
      );

      if (response instanceof Error) throw response;
      setAuthentication(prevState => ({
        ...prevState,
        ...response.data.data.authentication,
        persistLoader: true
      }));
      setTimeout(function () {
        setAuthentication(prevState => ({
          ...prevState,
          persistLoader: false,
          intercepted: false
        }));
      }, 1000);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      let message = error?.response?.data?.message;
      if (message) toast.error(message);
      setTimeout(function () {
        if (focusField.current) focusField.current.focus();
        setAuthentication(prevState => ({
          ...prevState,
          intercepted: false
        }));
        setCode('');
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (authentication?.id) {
      handleSecret();
      setAuthentication(prevState => ({
        ...prevState,
        intercepted: true
      }));
    }
  }, []);

  useEffect(() => {
    if (focusField.current) focusField.current.focus();
  }, [focusField.current]);

  return (
    <>
      {authentication?.id && (
        <Form onSubmit={handleSubmit}>
          <Row className='g-2 flex-center mb-3 position-relative'>
            <Col xs={8}>
              <Row className='g-2 mb-2'>
                <Col>
                  <Flex direction='column' alignItems='center'>
                    <p className='text-center mb-3 fs--1'>
                      <Badge
                        size={'sm'}
                        bg={'success'}
                        className='me-1'
                        style={{ verticalAlign: 'text-bottom' }}>
                        1
                      </Badge>{' '}
                      Scan the QR with your authenticator app
                    </p>
                    <Flex
                      direction='column'
                      alignItems='center'
                      style={{ width: '200px', height: '230px' }}>
                      {!imageLoaded ? (
                        <Flex
                          justifyContent='center'
                          alignItems='center'
                          className='h-100'>
                          <LoadingSpinner grow={'10'} color='900' />
                        </Flex>
                      ) : (
                        <Flex direction='column' alignItems='center'>
                          <div>
                            <Image
                              src={img}
                              style={{ width: '200px', height: '200px' }}
                              className='border'
                            />
                            <p
                              className='text-center mb-0 fs--1 fw-bold'
                              style={{ lineHeight: '30px' }}>
                              {secret}
                            </p>
                          </div>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Col>
              </Row>
              <Row className='g-3 mb-3'>
                <Col>
                  <p className='text-center mb-3 fs--1'>
                    <Badge
                      size={'sm'}
                      bg={'success'}
                      className='me-1'
                      style={{ verticalAlign: 'text-bottom' }}>
                      2
                    </Badge>{' '}
                    Enter the code from your authenticator app
                  </p>
                  <Form.Group>
                    {hasLabel && <Form.Label>Verification code</Form.Label>}
                    <Form.Control
                      size='sm'
                      name='verificationCode'
                      ref={focusField}
                      type='text'
                      autoComplete='off'
                      value={code}
                      maxLength={6}
                      onChange={e =>
                        setCode(e.target.value.replace(/[^0-9]/g, ''))
                      }
                      className='text-center auth-form-control-dark shadow-none'
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='g-2'>
                <Col>
                  <Button
                    size='sm'
                    variant='outline-secondary'
                    className='w-100'
                    onClick={() => logout(axiosPrivate)}>
                    <span>Cancel</span>
                  </Button>
                </Col>
                <Col>
                  <Button
                    size='sm'
                    type='submit'
                    variant='outline-primary'
                    className='w-100'>
                    <div className='position-relative'>
                      <Flex justifyContent={'center'} alignItems={'center'}>
                        <ButtonSpinner spinning={loading} />
                        <span>{loading ? 'Verifying' : 'Verify'}</span>
                      </Flex>
                    </div>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

MFASetupForm.propTypes = {
  hasLabel: PropTypes.bool
};

MFASetupForm.defaultProps = {
  hasLabel: false
};

export default MFASetupForm;
