import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Modal } from 'react-bootstrap';

const AdvancedTableSettingsModal = ({
  columns,
  settingsModalShow,
  setSettingsModalShow,
  visibleColumns,
  setVisibleColumns
}) => {
  const { application } = useApplication();
  return (
    <Modal
      show={settingsModalShow}
      onHide={() => setSettingsModalShow(false)}
      size='sm'
      aria-labelledby='settingsModal'
      centered>
      <Modal.Header
        closeButton
        closeVariant={application.isDark ? 'white' : null}>
        <Modal.Title id='settingsModal' className='fs-1'>
          Display Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-2'>
        {columns
          .filter(function (column) {
            return !['actions'].includes(column.accessor);
          })
          .map((column, index) => {
            return (
              <div key={index}>
                <Form.Check
                  type='switch'
                  label={column.Header}
                  disabled={column.disableDisplaySettings}
                  checked={visibleColumns.includes(column.accessor)}
                  onChange={e => {
                    if (
                      e.target.checked &&
                      !visibleColumns.includes(column.accessor)
                    ) {
                      setVisibleColumns(prevState => [
                        ...prevState,
                        column.accessor
                      ]);
                    } else {
                      setVisibleColumns(prevState =>
                        prevState.filter(function (item) {
                          return item !== column.accessor;
                        })
                      );
                    }
                  }}
                />
              </div>
            );
          })}
      </Modal.Body>
    </Modal>
  );
};

AdvancedTableSettingsModal.propTypes = {
  columns: PropTypes.array.isRequired,
  settingsModalShow: PropTypes.bool.isRequired,
  setSettingsModalShow: PropTypes.func.isRequired,
  visibleColumns: PropTypes.array.isRequired,
  setVisibleColumns: PropTypes.func.isRequired
};

export default AdvancedTableSettingsModal;
