import React from 'react';
import SecurityLayout from './SecurityLayout';

/**
 * Renders the Security component
 *
 * @returns {JSX.Element} The rendered Security component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const SecurityPage = () => {
  return (
    <>
      <SecurityLayout />
    </>
  );
};

export default SecurityPage;
