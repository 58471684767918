import classNames from 'classnames';
import CardDropdown from 'components/common/CardDropdown';
import { BarChart } from 'echarts/charts';

import routes from 'config/routeSettings';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';

import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { chartJsHistogramBarLegendDefaultOptions } from 'helpers/chartjs-utils';
import { SplitDataCuratedWithTimestamp } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Card, Dropdown, Modal } from 'react-bootstrap';
// import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconLink from 'components/common/FalconLink';
import Flex from 'components/common/Flex';

import SimpleSpinner from 'components/utilities/AppSpinner/smallSpinner';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import useApplication from 'hooks/useApplication';
import useAuthentication from 'hooks/useAuthentication';
import PropTypes from 'prop-types';
import VisualizationsQueryModal from '../../InspectorModal';

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

/**
 * Returns the options for the histogram bar legend chart
 *
 * @param {Object} data - The data for the chart
 * @param {Object} overallData - The overall data for the chart
 * @returns {Object} - The options for the chart
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
const getOptions = ({ data, overallData, isDark }) => {
  return {
    ...chartJsHistogramBarLegendDefaultOptions(data, overallData, isDark)
  };
};

/**
 * Renders a histogram bars legend component
 *
 * @param {Object} props - The component props
 * @param {Object} props.statHistogram - The statistical histogram data
 * @param {boolean} props.wrapperLoader - The flag indicating whether to show a loader for the wrapper component
 * @param {string} props.title - The title of the component
 * @param {string} props.className - The CSS class name for the component
 * @returns {JSX.Element} The rendered histogram bars legend component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
function HistogramBarsLegend({
  className,
  statHistogram,
  wrapperLoader,
  title
}) {
  const {
    application: { isDark }
  } = useApplication();

  const {
    authentication: { isAdmin }
  } = useAuthentication();

  const [data, setData] = useState([]);
  const [overallData, setOverallData] = useState([]);
  const [queryDiscoverModal, setQueryDiscover] = useState(false);
  // const [statHistogram, setHistogram] = useState(false);

  useEffect(() => {
    if (
      statHistogram &&
      statHistogram != null &&
      statHistogram?.data?.length > 0
    ) {
      let res = SplitDataCuratedWithTimestamp(statHistogram?.data);

      setData(res);
      setOverallData(statHistogram?.data);
    }
  }, [statHistogram]);

  return (
    <>
      <Card className={classNames('h-100 shadow-none border', className)}>
        {/* <FalconCardHeader title={title} titleTag='h6' className='py-3 z-index-2' light /> */}

        <Card.Body className='p-2'>
          <h6 className='mt-0 ms-0 mb-3 text-nowrap text-700 fw-normal z-index-2 position-relative'>
            {title}
          </h6>
          {!wrapperLoader && isAdmin && (
            <Flex
              justifyContent='center'
              alignContent='middle'
              style={{ zIndex: '222' }}
              className={'position-absolute top-0 end-0 cursor-pointer'}>
              <CardDropdown style={{ zIndex: 9999, position: 'absolute' }}>
                <div className='py-2'>
                  <Dropdown.Item
                    onClick={() => {
                      setQueryDiscover(true);
                    }}>
                    Inspect
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            </Flex>
          )}
          {wrapperLoader && (
            <span
              className='fw-light position-absolute top-0 end-0 z-index-2'
              style={{ marginRight: '15px', marginTop: '15px' }}>
              <SimpleSpinner size='sm' />
            </span>
          )}
          {data?.length > 0 && overallData?.length > 0 && (
            <ReactEChartsCore
              key={data.length + overallData.length}
              echarts={echarts}
              option={getOptions({ data, overallData, isDark })}
              style={{ height: '18rem' }}
            />
          )}
          <div
            className={`position-absolute all-0 align-items-center justify-content-around bg-card-nodata fs--1 ${
              !wrapperLoader && overallData?.length === 0 ? 'd-flex' : 'd-none'
            }`}>
            No results matching search criteria
          </div>
          <div
            className={`position-absolute all-0 align-items-center justify-content-around bg-card-spinner ${
              wrapperLoader ? 'd-flex' : 'd-none'
            } ${wrapperLoader && 'bg-card-spinner-refresh z-index-1 '}`}></div>
        </Card.Body>
        <Card.Footer className='pt-0 pb-2 text-end'>
          <FalconLink
            title='Threat Intelligence'
            className='px-0 fw-medium fs--1 text-decoration-none'
            to={routes.ARTIFACTS_NETWORK_THREATINTEL}
          />
        </Card.Footer>
      </Card>
      <Modal
        centered
        size={'lg'}
        animation={false}
        show={queryDiscoverModal}
        onHide={() => setQueryDiscover(false)}
        aria-labelledby='modalQueryInspector'>
        <VisualizationsQueryModal allOperations={[statHistogram]} />
      </Modal>
    </>
  );
}

HistogramBarsLegend.propTypes = {
  className: PropTypes.string,
  statHistogram: PropTypes.object,
  title: PropTypes.string,
  wrapperLoader: PropTypes.bool
};

export default HistogramBarsLegend;
