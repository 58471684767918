import LeargasAPI from 'api';
import useAxiosPrivate from 'hooks/useAxiosPrivate';

import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProfileBanner from './profile';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const UserProile = () => {
  let [loading, setLoading] = useState(false);
  let [user, setUser] = useState({});

  const { axiosPrivate } = useAxiosPrivate();
  let { id } = useParams();
  useEffect(() => {
    if (id) {
      getuser(id);
    }
  }, [id]);

  const getuser = async id => {
    setLoading(true);
    try {
      const res = await LeargasAPI.Users.getUser(id, axiosPrivate);
      if (res instanceof Error) throw res;
      let user = res.data;

      setUser(user);
      setLoading(false);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);

      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      else toast.error('Something went wrong!');
    }
  };
  return (
    <>
      {!loading ? (
        <>
          <ProfileBanner user={user} />
        </>
      ) : (
        <div className='d-flex justify-content-center align-items-center py-4'>
          <Spinner animation='border' variant='primary' />
        </div>
      )}
    </>
  );
};

export default UserProile;
