import React from 'react';
import MultiSelect from 'components/common/MultiSelect';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'react-bootstrap';

/**
 * Renders the OverviewLayout component
 *
 * @param {Object} props - The component props
 * @param {Array} props.checkerIndices - The checker indexes
 * @param {number} props.allowedLimit - The allowed limit
 * @param {string} props.injectedPreset - The injected preset
 * @returns {JSX.Element} The rendered component
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const IssueReportForm = () => {
  const { register, handleSubmit } = useForm();

  const onSubmit = () => {};

  return (
    <div className='mt-3'>
      <p className='mb-0'>
        We're here to help! If you've encountered a problem, noticed a bug, or
        have a suggestion, please let us know. Your feedback is invaluable in
        helping us improve our products. Complete the form below to send your
        report directly to our team.
      </p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* <Form.Group controlId='subject' className='mt-3'>
          <Form.Label>Subject</Form.Label>
          <Form.Control type='text' placeholder='Please provide a brief subject that summarizes the issue.' {...register('subject', { required: true })} />
        </Form.Group> */}

        <Form.Group controlId='message' className='mt-3'>
          <Form.Label>Message</Form.Label>
          <Form.Control
            as='textarea'
            rows={4}
            {...register('description', { required: true })}
          />
        </Form.Group>

        <Form.Group controlId='category' className='mt-3'>
          <Form.Label>Category</Form.Label>
          <MultiSelect
            // ref={fieldRef}
            options={[
              'Account/Billing',
              'Bug/Error',
              'Suggestion/Feedback',
              'Other'
            ].map(item => {
              return { label: item, value: item };
            })}
            size='sm'
          />
        </Form.Group>

        {/* <Form.Group controlId='priority' className='mt-3'>
          <Form.Label>Priority</Form.Label>
          <MultiSelect
            // ref={fieldRef}
            options={['Low', 'Medium', 'High', 'Critical'].map(item => {
              return { label: item, value: item };
            })}
            size='sm'
          />
        </Form.Group> */}
        {/* 
        <Form.Group controlId='attachments' className='mt-3'>
          <Form.Label>Attachments</Form.Label>
          <Form.Control type='file' {...register('attachments')} />
        </Form.Group> */}

        <Form.Group controlId='email' className='mt-3'>
          <Form.Label>Your Email Address</Form.Label>
          <Form.Control
            type='email'
            {...register('email', { required: true })}
          />
        </Form.Group>

        <Button variant='primary' type='submit' className='mt-3'>
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default IssueReportForm;
