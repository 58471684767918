import React from 'react';
import OrganizationsProvider from '../../../../context/user-contexts/UserOrganizationsProvider';
import OrgProfile from './OrganizationProfile';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const OrgProfileWrapper = () => {
  return (
    <>
      <OrganizationsProvider>
        <OrgProfile />
      </OrganizationsProvider>
    </>
  );
};

export default OrgProfileWrapper;
