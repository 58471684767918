/* eslint-disable react/prop-types */
import IndeterminateCheckbox from 'components/common/IndeterminateCheckbox';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';

const FieldsTableWrapper = ({
  children,
  columns,
  data,
  sortable,
  selection,
  selectionColumnWidth,
  pagination,
  perPage = 10
}) => {
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data, [data]);

  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    pageCount,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
    setGlobalFilter
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetSelectedRows: false,
      autoResetGlobalFilter: false,
      autoResetPage: false,
      disableSortBy: !sortable,
      initialState: { pageSize: pagination ? perPage : data?.length }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      if (selection) {
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            headerProps: {
              style: {
                maxWidth: selectionColumnWidth
              }
            },
            cellProps: {
              style: {
                maxWidth: selectionColumnWidth
              }
            },
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            )
          },
          ...columns
        ]);
      }
    }
  );

  const recursiveMap = useCallback(
    children => {
      return React.Children.map(children, child => {
        if (child.props?.children) {
          return React.cloneElement(child, {
            children: recursiveMap(child.props.children)
          });
        } else {
          if (child.props?.table) {
            return React.cloneElement(child, {
              ...child.props,
              getTableProps,
              headers,
              page,
              prepareRow,
              canPreviousPage,
              canNextPage,
              nextPage,
              previousPage,
              gotoPage,
              pageCount,
              pageIndex,
              selectedRowIds,
              pageSize,
              setPageSize,
              globalFilter,
              setGlobalFilter
            });
          } else {
            return child;
          }
        }
      });
    },
    [
      getTableProps,
      headers,
      page,
      prepareRow,
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      gotoPage,
      pageCount,
      pageIndex,
      selectedRowIds,
      pageSize,
      setPageSize,
      globalFilter,
      setGlobalFilter
    ]
  );

  return <>{recursiveMap(children)}</>;
};

FieldsTableWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  sortable: PropTypes.bool,
  selection: PropTypes.bool,
  selectionColumnWidth: PropTypes.number,
  pagination: PropTypes.bool,
  perPage: PropTypes.number
};

export default FieldsTableWrapper;
