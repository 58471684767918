import React from 'react';
import ArticlesWrapper from './ArticlesWrapper';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const Articles = () => {
  return (
    <>
      <ArticlesWrapper />
    </>
  );
};

export default Articles;
