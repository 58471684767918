import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useApplication from 'hooks/useApplication';
import useBookmarks from 'hooks/user-contexts/useUserBookmarks';
import { Modal } from 'react-bootstrap';

/**
 * bookmarks Edit modal
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ModalBookmarkView = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    bookmarks: { viewBookmarkModal },
    setBookmarks
  } = useBookmarks();

  return (
    <Modal
      centered
      size={viewBookmarkModal?.fullScreen ? 'xl' : 'lg'}
      animation={false}
      backdrop='static'
      keyboard={false}
      show={viewBookmarkModal?.open}
      onHide={() =>
        setBookmarks(prevState => ({
          ...prevState,
          viewBookmarkModal: { open: false }
        }))
      }
      aria-labelledby='modalBookmarkviewBookmarkModal'
      contentClassName={
        viewBookmarkModal?.fullScreen &&
        'border-0 border-top-left-radius-0 border-top-right-radius-0 h-100'
      }
      dialogClassName={
        viewBookmarkModal?.fullScreen &&
        'position-absolute all-0 fullscreen-modal-dialog scrollbar overflow-x-hidden'
      }>
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className='d-flex justify-content-between'>
        <Modal.Title>viewBookmarkModal a Bookmark</Modal.Title>
        <div
          className='d-flex justify-content-end position-absolute right-0 '
          style={{ right: '3rem' }}>
          <FontAwesomeIcon
            icon={
              viewBookmarkModal?.fullScreen
                ? 'down-left-and-up-right-to-center'
                : 'up-right-and-down-left-from-center'
            }
            className='me-2 cursor-pointer'
            style={{
              color: isDark ? 'white' : 'black',
              opacity: 0.8
            }}
            onClick={() => {
              setBookmarks(prevState => ({
                ...prevState,
                viewBookmarkModal: {
                  open: true,
                  fullScreen: !viewBookmarkModal?.fullScreen
                }
              }));
            }}
          />
        </div>
      </Modal.Header>
      <Modal.Body className='p-2'>View Modal</Modal.Body>
    </Modal>
  );
};

export default ModalBookmarkView;
