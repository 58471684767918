import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import IndicesProvider from '../../../../../../../context/admin-contexts/IndicesProvider';
import IndicesLayout from './IndicesLayout';

/**
 * Renders the IndicesSettingsPage component
 *
 * @returns {JSX.Element} The rendered IndicesSettingsPage component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const IndicesSettingsPage = () => {
  return (
    <>
      <WidgetSectionTitle title='Application Indices' className='mb-2' />
      <IndicesProvider>
        <IndicesLayout />
      </IndicesProvider>
    </>
  );
};

export default IndicesSettingsPage;
