import { makeRequest } from 'api/connection/requestAction';

/**
 * Represents a Jobs class that handles API requests related to jobs
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
class Jobs {
  /**
   * Represents a Jobs object
   * @constructor
   * @param {string} route - The route for the Jobs object
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.4
   * @since 0.1.0-beta.4
   */
  constructor(route) {
    this.route = route;
  }

  /**
   * Retrieves the application jobs
   * @returns {Array} The array of application jobs
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.4
   * @since 0.1.0-beta.4
   */
  async getAppJobs(axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getAppJobs.method,
        this.route.getAppJobs.path
      );
      if (res instanceof Error) throw res;
      let jobs = [];
      res?.data?.data?.map(job => {
        jobs.push(job);
      });
      return jobs;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * Deletes an application job by ID
   * @param {string} id - The ID of the job to delete
   * @returns {Promise<any>} - A promise that resolves with the response from the server, or rejects with an error
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.4
   * @since 0.1.0-beta.4
   */
  async deleteAppJob(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.deleteAppJob.method,
        `${this.route.deleteAppJob.path}/${id}`
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * Starts an application job
   * @param {string} id - The ID of the job to start
   * @returns {Promise<any>} - A promise that resolves with the result of the job start operation
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.4
   * @since 0.1.0-beta.4
   */
  async startAppJob(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.startAppJob.method,
        `${this.route.startAppJob.path}/${id}`
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * Pauses an application job
   * @param {string} id - The ID of the job to pause
   * @returns {Promise<any>} - A promise that resolves with the response from the server, or rejects with an error
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.4
   * @since 0.1.0-beta.4
   */
  async pauseAppJob(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.pauseAppJob.method,
        `${this.route.pauseAppJob.path}/${id}`
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default Jobs;
