import LeargasAPI from 'api';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

export const articlesSettings = {
  addArticleCategoryModal: {
    open: false
  },
  addArticleModal: {
    fullScreen: false,
    open: false
  },
  addArticleTagModal: {
    open: false
  },
  categories: [],
  data: [],
  deleteArticleCategoryModal: {
    data: {},
    open: false
  },
  deleteArticleModal: {
    data: {},
    open: false
  },
  deleteArticleTagModal: {
    data: {},
    open: false
  },
  editArticleCategoryModal: {
    data: {},
    open: false
  },
  editArticleModal: {
    data: {},
    fullScreen: false,
    open: false
  },
  editArticleTagModal: {
    data: {},
    open: false
  },
  fetch: false,
  loading: false,
  requiredRoles: {
    articleNotes: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    },
    articles: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    articleNotes: {
      delete: ['articleNotes:delete'],
      read: ['articleNotes:read'],
      write: ['articleNotes:write']
    },
    articles: {
      delete: ['articles:delete'],
      read: ['articles:read'],
      write: ['articles:write']
    }
  },
  tags: [],
  viewType: true
};

/**
 * Create ArticlesContext
 * @type {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const ArticlesContext = createContext(articlesSettings);

/**
 * Create ArticlesProvider
 * @param props
 * @returns {JSX.Element}
 * @constructor
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 *  <ArticlesProvider>
 *   {children}
 * </ArticlesProvider>
 * )
 */

const ArticlesProvider = props => {
  const [articles, setArticles] = useState(articlesSettings);
  const [loading, setLoading] = useState(true);
  const { axiosPrivate } = useAxiosPrivate();

  /**
   * Get all application articles
   * @returns {Promise<void>}
   * @async
   * @function
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const getAppArticles = async () => {
    try {
      setLoading(true);
      let res = await LeargasAPI.AppArticles.getAppArticles(axiosPrivate);
      setArticles(prevState => ({
        ...prevState,
        data: res?.data
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setArticles(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setArticles(prevState => ({
        ...prevState,
        fetch: false
      }));
      setLoading(false);
    }
  };

  const getAppArticleCategories = async () => {
    try {
      setLoading(true);
      let res = await LeargasAPI.AppArticles.getAppArticleCategories(
        axiosPrivate
      );
      setArticles(prevState => ({
        ...prevState,
        categories: res?.data
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setArticles(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setArticles(prevState => ({
        ...prevState,
        fetch: false
      }));
    }
  };

  const getAppArticleTags = async () => {
    try {
      setLoading(true);
      let res = await LeargasAPI.AppArticles.getAppArticleTags(axiosPrivate);
      setArticles(prevState => ({
        ...prevState,
        tags: res?.data
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setArticles(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setArticles(prevState => ({
        ...prevState,
        fetch: false
      }));
    }
  };

  /**
   * Get all application articles on fetch state change
   */
  useEffect(() => {
    if (articles.fetch) {
      getAppArticles();
      getAppArticleCategories();
      getAppArticleTags();
    }
  }, [articles.fetch]);

  /**
   * Get all application articles on component mount
   */
  useEffect(() => {
    getAppArticles();
    getAppArticleCategories();
    getAppArticleTags();
  }, []);

  return (
    <ArticlesContext.Provider
      value={{
        articles,
        setArticles,
        loading
      }}>
      {props.children}
    </ArticlesContext.Provider>
  );
};

ArticlesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ArticlesProvider;
