import {
  presetRangeGroups,
  presetRanges,
  showPresetSelectOn
} from 'context/ESGlobalFiltersProvider';
import { calculateSeconds, setTimeFromPreset } from 'helpers/utils';
// import useApplication from 'hooks/useApplication';
import useESGlobalFilters from 'hooks/useGlobalFilters';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 *
 * @returns {JSX.Element} The rendered button component
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const RangePresetSelect = ({ colSize }) => {
  const {
    esGlobalFilters: { index, preset, allowedLimit, checkerIndices },
    setESGlobalFilters
  } = useESGlobalFilters();

  // const {
  //   application: { globalFilters },
  //   setApplication
  // } = useApplication();

  const location = useLocation();

  const setLteGte = preset => {
    if (preset !== 'custom_range') {
      const date = new Date();

      let dateGte = new moment(date);
      if (presetRanges[preset]?.gte) {
        var gteSubtract = presetRanges[preset].gte?.subtract || false;
        var gteSet = presetRanges[preset].gte?.set || false;
        if (gteSubtract) dateGte.subtract(gteSubtract);
        if (gteSet) dateGte.set(gteSet);
      }

      let dateLte = new moment(date);
      if (presetRanges[preset]?.lte) {
        var lteSubtract = presetRanges[preset].lte?.subtract || false;
        var lteSet = presetRanges[preset].lte?.set || false;
        if (lteSubtract) dateLte.subtract(lteSubtract);
        if (lteSet) dateLte.set(lteSet);
      }
      if (
        checkerIndices &&
        checkerIndices.includes(index) &&
        allowedLimit <= calculateSeconds(dateGte.toDate(), dateLte.toDate())
      ) {
        toast.error(`Please select a shorter time range for this index`);
        return;
      } else {
        setESGlobalFilters(prevState => ({
          ...prevState,
          gte: dateGte.toDate(),
          lte: dateLte.toDate()
        }));
      }
    }
  };
  const PresetSelection = rangeKey => {
    if (rangeKey === 'custom_range') {
      setESGlobalFilters(prevState => ({
        ...prevState,
        preset: rangeKey
      }));
    } else {
      let [gteTime, lteTime] = setTimeFromPreset(rangeKey, presetRanges);

      if (
        checkerIndices &&
        checkerIndices.includes(index) &&
        allowedLimit <= calculateSeconds(gteTime, lteTime)
      ) {
        toast.error(`Please select a shorter time range for this index`);
      } else {
        setESGlobalFilters(prevState => ({
          ...prevState,
          preset: rangeKey
        }));

        setLteGte(rangeKey);
        // setESGlobalFilters(prevState => {
        //   if (!prevState.refresh) {
        //     return {
        //       ...prevState,
        //       refresh: true
        //     };
        //   } else {
        //     return { ...prevState };
        //   }
        // });
      }
    }
  };
  return showPresetSelectOn.includes(location.pathname) ? (
    <Col
      md={`${preset === 'custom_range' ? 4 : 6}`}
      xxl={colSize || 4}
      className='py-0  my-0'>
      <Form.Group>
        <Form.Label className='fs--1'>Time Range</Form.Label>
        <InputGroup className='filter-input-group mb-2'>
          <Form.Control
            size='sm'
            value={presetRanges[preset]?.label}
            className='fs--1 border-radius-right-0'
            disabled
          />
          <DropdownButton
            size='sm'
            variant='outline-secondary'
            id='input-group-dropdown-2'
            drop='down'
            title=''
            align='end'
            tabIndex='4'>
            {Object.entries(presetRangeGroups).map(
              ([groupKey, group], groupIndex) => (
                <div key={groupIndex}>
                  <Dropdown.Header className='mt-1'>{group}</Dropdown.Header>
                  {Object.entries(presetRanges).map(
                    ([rangeKey, range], presetIndex) =>
                      range.group === groupKey ? (
                        <Dropdown.Item
                          key={presetIndex}
                          eventKey={rangeKey}
                          active={preset === rangeKey}
                          onClick={() => {
                            PresetSelection(rangeKey);
                          }}>
                          {range.label}
                        </Dropdown.Item>
                      ) : null
                  )}
                </div>
              )
            )}
          </DropdownButton>
        </InputGroup>
      </Form.Group>
    </Col>
  ) : null;
};

RangePresetSelect.propTypes = {
  colSize: PropTypes.number
};

export default RangePresetSelect;
