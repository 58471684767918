/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';

const AdvancedTableSearchBox = ({
  globalFilter,
  setGlobalFilter,
  dataTypeLabelPlural,
  className
}) => {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <InputGroup
      className={`position-relative input-group-sm filter-input-group`}>
      <FormControl
        value={value || ''}
        onChange={({ target: { value } }) => {
          setValue(value);
          onChange(value);
        }}
        id='search'
        placeholder={`Search ${dataTypeLabelPlural}...`}
        type='search'
        className={className}
        tabIndex='2'
        autoComplete='off'
      />
    </InputGroup>
  );
};

export default AdvancedTableSearchBox;
