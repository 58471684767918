import Flex from 'components/common/Flex';
import useArticles from 'hooks/admin-contexts/useArticles';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import AddButton from './AddButton';
import ArticlesTable from './ArticlesTable';
import ArticlesTableFilters from './ArticlesTableFilters';
import ArticlesTableFooter from './ArticlesTableFooter';
import ArticlesTablePagination from './ArticlesTablePagination';
import ArticlesTableWrapper from './ArticlesTableWrapper';
import { getArticleColumns } from './columns/columnsDefaults';

import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Articles = () => {
  const {
    articles: { data },
    loading: componentLoading,
    setArticles
  } = useArticles();

  const [globalFilter, setGlobalFilter] = useState('');
  const [columns, setColumns] = useState([]);

  /**
   * Set Loading false
   */
  useEffect(() => {
    setColumns(getArticleColumns(setArticles));
  }, []);

  return (
    <Card className='shadow-none border'>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          <span className='d-block'>Articles</span>
          <AddButton
            onClick={() =>
              setArticles(prevState => ({
                ...prevState,
                addArticleModal: { open: true }
              }))
            }
          />
        </Flex>
      </Card.Header>
      <Card.Body className='position-relative'>
        {componentLoading ? (
          <LoadingSpinner grow='10' />
        ) : (
          <ArticlesTableWrapper
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            data={data}
            sortable
            loading={componentLoading}
            pagination
            perPage={50}>
            <ArticlesTableFilters
              table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <ArticlesTable table columns={columns} />
            <AddButton
              label='Add an article'
              onClick={() =>
                setArticles(prevState => ({
                  ...prevState,
                  addArticleModal: { open: true }
                }))
              }
            />
            <div className='mt-2 d-flex justify-content-between'>
              <ArticlesTableFooter
                table
                rowInfo
                data={data}
                rowCount={data?.length}
              />
              <ArticlesTablePagination table />
            </div>
          </ArticlesTableWrapper>
        )}
      </Card.Body>
    </Card>
  );
};

export default Articles;
