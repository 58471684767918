import { makeRequest } from 'api/connection/requestAction';
/**
 * @description Users API
 * @summary Provides methods for interacting with the users API
 * @method createUser Create a new user
 * @method getUsers Get a list of all users
 * @method getUser Get a single user
 * @method updateUser Update a user
 * @method deleteUser Delete a user
 * @method lockUser Lock a user
 * @method unlockUser Unlock a user
 */
class Statuses {
  constructor(route) {
    this.route = route;
  }
  /**
   * @description Create a new user
   * @param {Object} userData User data
   * @returns {Mixed} User data if true, error object if false
   */
  async EditStatusesForOrgs(id, body, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.EditStatus.method,
        `${this.route.EditStatus.path}/${id}`,
        body
      );
      if (res instanceof Error) throw res;
      return res.data;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default Statuses;
