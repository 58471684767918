import LeargasAPI from 'api';

import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useArticles from 'hooks/admin-contexts/useArticles';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

/**
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticleCategoriesEditForm = () => {
  const { register, handleSubmit, reset, watch } = useForm();
  const {
    articles: { editArticleCategoryModal },
    setArticles
  } = useArticles();

  const { axiosPrivate } = useAxiosPrivate();
  const { title, description } = watch();
  const [loading, setLoading] = useState(false);

  /** submit form to update article category
   * @param {Object} FormData
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const onSubmitData = async FormData => {
    let { data } = editArticleCategoryModal;
    try {
      setLoading(true);
      let res = await LeargasAPI.AppArticles.updateAppArticleCategory(
        data?.id,
        FormData,
        axiosPrivate
      );
      if (res instanceof Error) throw res;
      const { message } = res;
      toast.success(message);
      setLoading(false);
      setArticles(prevState => ({
        ...prevState,
        fetch: true,
        editArticleCategoryModal: { open: false, data: {} }
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
      setLoading(false);
      setArticles(prevState => ({
        ...prevState,
        editArticleCategoryModal: { open: false, data: {} }
      }));
    }
  };

  /**
   * set back the data into inputs
   */
  useEffect(() => {
    const { data } = editArticleCategoryModal;

    reset({
      title: data?.title,
      description: data?.description
    });
  }, [editArticleCategoryModal, reset]);

  useEffect(() => {}, [title, description]);

  return (
    <Form onSubmit={handleSubmit(onSubmitData)}>
      <Row>
        <Form.Group as={Col} sm={12} className='required mb-3'>
          <Form.Label>Title</Form.Label>
          <Form.Control
            placeholder={'Title'}
            name='title'
            {...register('title')}
            type='text'
            autoComplete='off'
            className='fs--1'
            style={{ minHeight: '36px' }}
            required
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} className='required mb-3'>
          <Form.Label>Description</Form.Label>
          <Form.Control
            placeholder={'Description'}
            rows={3}
            name='description'
            {...register('description')}
            as='textarea'
            autoComplete='off'
            className='fs--1'
            style={{ minHeight: '36px' }}
            required
          />
        </Form.Group>
      </Row>
      <Flex justifyContent='end' className={'mt-3'}>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() =>
            setArticles(prevState => ({
              ...prevState,
              editArticleCategoryModal: { open: false }
            }))
          }>
          Cancel
        </Button>

        <Button
          size='sm'
          variant='success'
          type='submit'
          className='d-flex'
          disabled={!title || !description}>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Submitting' : 'Submit'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

export default ArticleCategoriesEditForm;
