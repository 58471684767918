import useUnsavedChanges from 'hooks/useUnsavedChanges';
import PropTypes from 'prop-types';
import React from 'react';
// import { Link, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

/**
 * PromptLink component.
 *
 * This component is a wrapper around for the `Link` component that prompts the user
 * if they have unsaved changes before navigating to the destination URL.
 *
 * It utilizes the useUnsavedChanges hook to determine if there are unsaved changes.
 *
 * @component
 * @param {object} props - Additional props for the Link Component
 * @param {string} props.to - The destination URL
 * @param {ReactNode} props.children - The child components
 * @param {Function} props.onClick - The onClick event handler
 * @returns {JSX.Element} - The rendered PromptLink component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 *
 * @see useUnsavedChanges
 * @see {@link https://reactrouter.com/web/api/Link} for more information on the Link component
 * @see {@link https://reactrouter.com/web/api/useNavigate} for more information on the useNavigate hook
 */
const PromptLink = ({ to, children, onClick, ...props }) => {
  // const navigate = useNavigate();
  // const { hasUnsavedChanges, promptOnLeave } = useUnsavedChanges();
  const { hasUnsavedChanges } = useUnsavedChanges();

  /**
   * Handles the click event for the PromptLink component.
   *
   * If there are unsaved changes, it prompts the user before navigating.
   * If an additional onClick action is provided, it executes it before navigating.
   * If there are no unsaved changes, it executes the onClick action immediately.
   *
   * @param {Event} e - The click event object
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  // const handleClick = e => {
  const handleClick = () => {
    if (hasUnsavedChanges) {
      // Temporarily disabled until fully fleshed out
      // ------------------------------------------------
      // e.preventDefault(); // Stop immediate navigation
      // promptOnLeave(() => {
      //   if (onClick) {
      //     onClick(); // Execute additional onClick action if provided
      //   }
      //   navigate(to); // Perform navigation
      // });
      onClick(); // Execute onClick immediately if no changes
    } else if (onClick) {
      onClick(); // Execute onClick immediately if no changes
    }
  };

  return (
    <Link
      to={to}
      {...props}
      onClick={hasUnsavedChanges || onClick ? handleClick : undefined}>
      {children}
    </Link>
  );
};

PromptLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func // Accept onClick prop
};

export default PromptLink;
