import { makeRequest } from 'api/connection/requestAction';

class Appliances {
  constructor(route) {
    this.route = route;
  }
  async createAppliance(applianceData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.createAppliance.method,
        this.route.createAppliance.path,
        JSON.stringify(applianceData)
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async getAppliances(axiosPrivate, showDeleted = false) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getAppliances.method,
        this.route.getAppliances.path
      );
      if (res instanceof Error) throw res;
      let appliances = [];
      res?.data?.data?.map(appliance => {
        if ((showDeleted && appliance.deleted) || !appliance.deleted) {
          appliances.push(appliance);
        }
      });
      return appliances;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getAppliance(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getAppliance.method,
        `${this.route.getAppliance.path}/${id}`
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async updateAppliance(applianceData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateAppliance.method,
        this.route.updateAppliance.path,
        JSON.stringify(applianceData)
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async deleteAppliance(ids, deleting, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.deleteAppliance.method,
        `${this.route.deleteAppliance.path}/${ids}`,
        {
          deleting
        }
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default Appliances;
