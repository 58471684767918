import React from 'react';
import Filters from './Filters';
import FiltersProvider from 'context/MiscContext/FiltersProvider';
import { Card } from 'react-bootstrap';

/**
 * Renders a tab component for filters
 *
 * @returns {JSX.Element} The rendered FiltersTab component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const FiltersTab = () => {
  return (
    <FiltersProvider>
      <Card className='shadow-none mb-0 bg-transparent'>
        <Card.Body className='pt-3 pb-3 mb-0'>
          <Filters />
        </Card.Body>
      </Card>
    </FiltersProvider>
  );
};

export default FiltersTab;
