import { makeRequest } from 'api/connection/requestAction';

class AppAlerts {
  constructor(route) {
    this.route = route;
  }

  async createAlert(alertData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.createAlert.method,
        this.route.createAlert.path,
        alertData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getAlerts(axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getAlerts.method,
        this.route.getAlerts.path
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getAlert(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getAlert.method,
        [this.route.getAlert.path, id].join('/')
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async updateAlert(id, alertData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateAlert.method,
        [this.route.updateAlert.path, id].join('/'),
        alertData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async deleteAlert(id, deleting, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.deleteAlert.method,
        [this.route.deleteAlert.path, id].join('/'),
        {
          deleting
        }
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default AppAlerts;
