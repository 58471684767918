import React from 'react';
import PropTypes from 'prop-types';
import ArticlesProvider from 'context/admin-contexts/ArticlesProvider';
import ArticleViewLayout from './ArticleViewLayout';
import { Card } from 'react-bootstrap';

/**
 *  @param {Object} props
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticlesViewWrapper = props => {
  return (
    <ArticlesProvider>
      <Card className='p-0 bg-transparent shadow-none'>
        <Card.Body className='p-0'>
          {props.children}
          <ArticleViewLayout />
        </Card.Body>
      </Card>
    </ArticlesProvider>
  );
};

ArticlesViewWrapper.propTypes = { children: PropTypes.node };

export default ArticlesViewWrapper;
