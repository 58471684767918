import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

/**
 * Renders the ReleaseDescription component
 *
 * @param {object} props Component properties
 * @param {object} props.release The release object
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ReleaseDescription = ({ release }) => {
  return (
    <>
      {release.description ? (
        <Row className='g-3 mb-2'>
          <Col>
            <p className='fs--1'>{release.description}</p>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

ReleaseDescription.propTypes = {
  release: PropTypes.shape({
    description: PropTypes.string
  }).isRequired
};

export default ReleaseDescription;
