import LeargasAPI from 'api';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useESGlobalFilters } from 'hooks/useGlobalFilters';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

export const alertsSettings = {
  addAlertModal: {
    fullScreen: false,
    open: false
  },
  data: [],
  deleteAlertModal: {
    data: {},
    open: false
  },
  editAlertModal: {
    data: {},
    fullScreen: false,
    open: false
  },
  fetch: false,
  loading: false,
  requiredAlerts: {
    alerts: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    },
    alertsNotes: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    alerts: {
      delete: ['alerts:delete'],
      read: ['alerts:read'],
      write: ['alerts:write']
    },
    alertsNotes: {
      delete: ['alertsNotes:delete'],
      read: ['alertsNotes:read'],
      write: ['alertsNotes:write']
    }
  },
  showDeleted: false
};

/**
 * Create AlertsContext
 * @type {React.Context<{}>}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const AlertsContext = createContext(alertsSettings);

/**
 * Create AlertsProvider
 * @param props
 * @returns {JSX.Element}
 * @constructor
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 *  <AlertsProvider>
 *   {children}
 * </AlertsProvider>
 * )
 */

const AlertsProvider = props => {
  const [alerts, setAlerts] = useState(alertsSettings);
  const [loading, setLoading] = useState(true);
  const {
    esGlobalFilters: { scopes }
  } = useESGlobalFilters();

  const { axiosPrivate } = useAxiosPrivate();

  /**
   * Get all application Alerts
   * @returns {Promise<void>}
   * @async
   * @function
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  const getAppAlerts = async () => {
    try {
      let res = await LeargasAPI.AccountAlerts.getAccAlerts(axiosPrivate);
      if (res instanceof Error) throw res;

      setAlerts(prevState => ({
        ...prevState,
        data: res?.data?.data
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setAlerts(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setAlerts(prevState => ({
        ...prevState,
        fetch: false
      }));
      setLoading(false);
    }
  };

  /**
   * Get all application Alerts on fetch state change
   */
  useEffect(() => {
    if (alerts.fetch) {
      getAppAlerts();
    }
  }, [alerts.fetch]);

  /**
   * Get all application Alerts on scopes change
   */
  useEffect(() => {
    getAppAlerts();
  }, [scopes]);

  /**
   * Get all application Alerts on component mount
   */
  useEffect(() => {
    getAppAlerts();
  }, []);

  return (
    <AlertsContext.Provider
      value={{
        alerts,
        setAlerts,
        loading
      }}>
      {props.children}
    </AlertsContext.Provider>
  );
};

AlertsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AlertsProvider;
