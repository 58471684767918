import NavigationCard from 'components/common/NavigationCard';
import React from 'react';
import { Row } from 'react-bootstrap';
import { resourceRoutes } from 'routes/routes.js';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';

/**
 * Renders the ResourcesPage component.
 *
 * @returns {JSX.Element} The rendered ResourcesPage component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.4
 */
const ResourcesPage = () => {
  const menuItems = resourceRoutes;

  return (
    <>
      <WidgetSectionTitle
        title={menuItems?.pageTitle || menuItems?.name}
        className='mb-2'
      />
      <Row className='g-1 mb-1'>
        {resourceRoutes.children.map((route, index) => (
          <NavigationCard route={route} key={index} index={index} />
        ))}
      </Row>
    </>
  );
};

export default ResourcesPage;
