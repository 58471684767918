/**
 * Finds a route by its path.
 *
 * @param {Object} route - The route object to search in
 * @param {string} path - The path to search for
 * @returns {Object|null} - The found route object or null if not found
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const findRouteByPath = (routes, path) => {
  for (let route of routes) {
    if (route.to === path) {
      return route;
    } else if (route.children) {
      const found = findRouteByPath(route.children, path);
      if (found) return found;
    }
  }
  return null;
};

/**
 * Builds an array of breadcrumb items based on the current route.
 *
 * @param {Object} currentRoute - The current route object
 * @param {Array} currentRoute.parents - An array of parent route objects
 * @param {string} currentRoute.label - The label of the current route
 * @param {string} currentRoute.name - The name of the current route
 * @param {string} currentRoute.to - The destination of the current route
 * @param {string} currentRoute.icon - The icon of the current route
 * @param {string} currentRoute.iconCollection - The icon collection
 * @returns {Array} - An array of breadcrumb items
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const buildBreadcrumbItems = (routes, path) => {
  const breadcrumbs = [];

  /**
   * Adds routes to the breadcrumbs array based on the given path.
   *
   * @param {Array} routes - The array of routes to check against the path.
   * @param {string} path - The path to check against the routes.
   */
  const addToBreadcrumbs = (routes, path) => {
    for (let route of routes) {
      if (path.includes(route.to)) {
        breadcrumbs.push({
          breadcrumbDisable: route?.breadcrumbDisable,
          icon: route.icon ? [route.iconCollection, route.icon] : null,
          iconGrow: route.iconGrow,
          iconRotation: route.iconRotation,
          label: route.label || route.name,
          to: route.to
        });

        if (route.children) {
          addToBreadcrumbs(route.children, path);
        }
      }
    }
  };

  /**
   * Removes breadcrumbs with the breadcrumbDisable property set to true.
   */
  const removeDisabledBreadcrumbs = () => {
    for (let i = 0; i < breadcrumbs.length; i++) {
      if (breadcrumbs[i].breadcrumbDisable) {
        breadcrumbs.splice(i, 1);
        i--;
      }
    }
  };

  /**
   * Removes the 'Home' breadcrumb from the breadcrumbs array if on the Overview page.
   */
  const removeOverviewOnHome = () => {
    if (breadcrumbs.length === 2) {
      if (breadcrumbs[1].label === 'Overview') {
        breadcrumbs.shift();
      }
    }
  };

  addToBreadcrumbs(routes, path);
  removeDisabledBreadcrumbs();
  removeOverviewOnHome();
  return breadcrumbs;
};
