import classNames from 'classnames';
import Flex from 'components/common/Flex';
import useApplication from 'hooks/useApplication';
import React from 'react';
// import ExploreButton from './ExploreButton';
import RefreshButton from '../RefreshButton';

/**
 * Renders the right panel extension for the navigator component
 * @returns {JSX.Element} The rendered right panel extension
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.4
 */
const NavigatorRightPanelExtension = () => {
  const {
    application: { isDark }
  } = useApplication();

  return (
    <Flex className='navigator-right-panel-container'>
      <Flex
        className={classNames(
          'navigator-right-panel-wrapper bg-card ps-2 pe-1'
        )}>
        <Flex
          className={classNames('navigator-right-panel-ext-primary ps-1', {
            'border-100': isDark,
            'border-200': !isDark
          })}
          alignItems='center'>
          {/* <ExploreButton /> */}
          <RefreshButton />
        </Flex>
        {/* <Flex
          className={classNames('navigator-right-panel-ext-primary', {
            'border-100': isDark,
            'border-200': !isDark
          })}
          alignItems='center'>
          {location.pathname !== routes.OVERVIEW && <ToggleButton />}
        </Flex>
        <Flex
          className='navigator-right-panel-ext-secondary'
          alignItems='center'>
          {location.pathname !== routes.OVERVIEW && <FiltersButton />}

          {/* <ShareButton />
          <CloneButton />
        </Flex> */}
      </Flex>
    </Flex>
  );
};

export default NavigatorRightPanelExtension;
