import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useState } from 'react';

import useOrganizations from 'hooks/user-contexts/useUserOrganizations';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

/**
 * @description form for user invitation
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const InviteUserForm = () => {
  const {
    organizations: {
      inviteUserModal: { organization }
    },
    setOrganizations
  } = useOrganizations();
  let [loading, setLoading] = useState(false);
  const { axiosPrivate } = useAxiosPrivate();
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();

  /**
   * @description form for user invitation
   * @param {Object} data
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const onSubmit = async formData => {
    try {
      setLoading(true);
      let newFormData = formData;
      newFormData['organizationId'] = organization?.id;
      let res = await LeargasAPI?.AccountOrganizations?.inviteUserToOrg(
        newFormData,
        axiosPrivate
      );
      if (res instanceof Error) {
        throw res;
      }
      setLoading(false);
      setOrganizations(prevState => ({
        ...prevState,
        fetch: true,
        inviteUserModal: { open: false }
      }));
      const { message } = res.data;
      toast.success(message);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);
      console.log(error);

      setOrganizations(prevState => ({
        ...prevState,
        fetch: false,
        inviteUserModal: { open: false }
      }));
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className='g-3 mb-3'>
        <Col sm={12} className='required'>
          <Form.Label>Invitee Email Address</Form.Label>
          <Form.Control
            placeholder={`Enter invitee's email address`}
            {...register('recipientEmail', {
              required: 'Invitees email address is required'
            })}
            type='email'
            autoComplete='off'
            className='fs--1'
            style={{ minHeight: '36px' }}
          />
          <code>{errors.recipientEmail?.message}</code>
        </Col>
        <Col sm={12}>
          <Form.Label>Custom Invitation Message</Form.Label>
          <Form.Control
            placeholder={`You have been invited to join ${organization?.name} on the Léargas app.`}
            {...register('customMessage')}
            type='text'
            as={'textarea'}
            autoComplete='off'
            className='fs--1'
            style={{ minHeight: '36px' }}
          />
          <code>{errors.customMessage?.message}</code>
        </Col>
      </Row>
      <Row className='g-3 mt-3'></Row>
      <Flex justifyContent='end'>
        <Button variant='success' type='submit' className='d-flex'>
          <div className='position-relative'>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <ButtonSpinner spinning={loading} />
              <span>{loading ? 'Inviting' : 'Invite'}</span>
            </Flex>
          </div>
        </Button>
      </Flex>
    </Form>
  );
};

export default InviteUserForm;
