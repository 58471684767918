import useTeams from 'hooks/admin-contexts/useTeams';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Modal } from 'react-bootstrap';
import FormTeamEdit from '../forms/TeamsEditForm';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
/**
 * teams Edit modal
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ModalTeamEdit = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    teams: { editTeamModal },
    setTeams
  } = useTeams();
  const { defaultData } = editTeamModal;

  return (
    <Modal
      centered
      size='lg'
      animation={false}
      backdrop='static'
      keyboard={false}
      show={editTeamModal?.open}
      onHide={() =>
        setTeams(prevState => ({
          ...prevState,
          editTeamModal: { open: false, data: {} }
        }))
      }
      aria-labelledby='modalTeamAdd'
      contentClassName={
        editTeamModal?.fullScreen &&
        'border-0 border-top-left-radius-0 border-top-right-radius-0 h-100'
      }
      dialogClassName={
        editTeamModal?.fullScreen &&
        'position-absolute all-0 fullscreen-modal-dialog scrollbar overflow-x-hidden'
      }>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Edit Team: {defaultData?.name}
          <AdvancedPopover
            containerId='resetConfirmModal'
            placement='top'
            popoverText='Edit Team'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
        <div
          className='d-flex justify-content-end position-absolute right-0 '
          style={{ right: '3rem' }}>
          <FontAwesomeIcon
            icon={
              editTeamModal?.fullScreen
                ? 'down-left-and-up-right-to-center'
                : 'up-right-and-down-left-from-center'
            }
            className='me-2 cursor-pointer'
            style={{
              color: isDark ? 'white' : 'black',
              opacity: 0.8
            }}
            onClick={() => {
              setTeams(prevState => ({
                ...prevState,
                editTeamModal: {
                  open: true,
                  fullScreen: !editTeamModal?.fullScreen,
                  defaultData: defaultData
                }
              }));
            }}
          />
        </div>
      </Modal.Header>

      <Modal.Body className='p-2'>
        <FormTeamEdit />
      </Modal.Body>
    </Modal>
  );
};

export default ModalTeamEdit;
