import React from 'react';
import PropTypes from 'prop-types';
import BookmarksProvider from '../../../../../../context/admin-contexts/BookmarksProvider';
import UsersProvider from '../../../../../../context/admin-contexts/UsersProvider';
import OrganizationProvider from 'context/admin-contexts/OrganizationsProvider';
import BookmarksLayout from './BookmarksLayout';
import ModalBookmarkAdd from './components/modals/BookmarksAddModal';
import ModalBookmarkEdit from './components/modals/BookmarksEditModal';
import ModalBookmarkDelete from './components/modals/BookmarksDeleteModal';
import BookmarksRestoreModal from './components/modals/BookmarksRestoreModal';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const BookmarksWrapper = props => {
  return (
    <OrganizationProvider>
      <UsersProvider>
        <BookmarksProvider>
          {props.children}
          <BookmarksLayout />
          <ModalBookmarkAdd />
          <ModalBookmarkEdit />
          <ModalBookmarkDelete />
          <BookmarksRestoreModal />
        </BookmarksProvider>
      </UsersProvider>
    </OrganizationProvider>
  );
};

BookmarksWrapper.propTypes = { children: PropTypes.node };

export default BookmarksWrapper;
