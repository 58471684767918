// import { axiosPrivate } from 'api/connection/axios';

/**
 * @description Account Details API
 * @summary Provides methods for managing a user's account details settings
 * @method getAccountDetails Get account details for the logged in user
 *
 * @author Brandon Cummings <brandon.cummings@leargasdetails.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
class AccountDetails {
  constructor(route) {
    this.paths = route;
  }

  /**
   * @description Get the current user's account details
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargasdetails.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   *
   * @example
   * AccountDetails.getAccountDetails();
   */
  async getAccountDetails(axiosPrivate) {
    return await axiosPrivate.get(this.paths.details.path);
  }
}

export default AccountDetails;
