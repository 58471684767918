import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import UsersWrapper from './UsersWrapper';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const Users = () => {
  return (
    <>
      <WidgetSectionTitle title='User Management' className='mb-2' />
      <UsersWrapper />
    </>
  );
};

export default Users;
