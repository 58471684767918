export const helpModalItems = {
  analysis: [
    {
      title: 'Knowledge Base',
      description: 'Find answers to common questions',
      icon: 'book'
    },
    {
      title: 'Blog Posts',
      description: 'Read the latest news and updates',
      icon: 'rss'
    },
    {
      title: 'Documentation',
      description: 'Learn how the pros use Léargas',
      icon: 'book-open'
    },
    {
      title: 'Keyboard Shortcuts',
      description: 'View available keyboard shortcuts',
      icon: 'keyboard'
    }
  ],
  customerSatisfaction: [
    {
      title: 'Create a Ticket',
      description: 'Get help or escalate an issue',
      icon: 'tags'
    },
    {
      title: 'Check Ticket Status',
      description: 'View the status of your tickets',
      icon: 'tag'
    },
    {
      title: 'Chat with an Agent',
      description: 'Chat with an agent in real-time',
      icon: 'comments'
    },
    {
      title: 'Report a Bug',
      description: 'Let us know if you find an issue',
      icon: 'bug'
    }
  ]
};
