import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import useAppliances from 'hooks/admin-contexts/useAppliances';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ModalApplianceDelete = () => {
  const {
    appliances: { loading, deleteApplianceModal },
    setAppliances
  } = useAppliances();
  const { axiosPrivate } = useAxiosPrivate();

  /**
   * Handle deleting an appliance
   * @param {Event} e
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const handleSubmit = async e => {
    e.preventDefault();

    try {
      setAppliances(prevState => ({
        ...prevState,
        loading: true
      }));
      let { defaultData } = deleteApplianceModal;
      let res = await LeargasAPI.Appliances.deleteAppliance(
        defaultData?._id,
        true,
        axiosPrivate
      );
      if (res instanceof Error) {
        throw res;
      }
      setAppliances(prevState => ({
        ...prevState,
        loading: false
      }));
      setAppliances(prevState => ({
        ...prevState,
        fetch: true,
        deleteApplianceModal: { open: false, defaultData: {} }
      }));
      const { message } = res.data;
      toast.success(message);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setAppliances(prevState => ({
        ...prevState,
        loading: false
      }));

      setAppliances(prevState => ({
        ...prevState,
        fetch: false,
        deleteApplianceModal: { open: false, defaultData: {} }
      }));
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='g-3 mb-3'>
        <p>
          Are you sure you want to delete the{' '}
          {deleteApplianceModal?.defaultData?.name} appliance?
        </p>
        <Col sm={12}></Col>
      </Row>

      <Flex justifyContent='end'>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() => {
            setAppliances(prevState => ({
              ...prevState,
              deleteApplianceModal: { open: false, defaultData: {} }
            }));
          }}>
          Cancel
        </Button>

        <Button variant='danger' size='sm' type='submit' className='d-flex'>
          {loading && (
            <div className='position-relative'>
              <Spinner
                as='span'
                animation='border'
                size='sm'
                appliance='status'
                aria-hidden='true'
                className='align-top me-1'
              />
            </div>
          )}
          Delete
        </Button>
      </Flex>
    </Form>
  );
};

export default ModalApplianceDelete;
