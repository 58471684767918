import LeargasAPI from 'api';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

export const usersSettings = {
  data: [],
  fetch: false,
  loading: false,
  requiredRoles: {
    userNotes: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    },
    users: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    userNotes: {
      delete: ['userNotes:delete'],
      read: ['userNotes:read'],
      write: ['userNotes:write']
    },
    users: {
      delete: ['users:delete'],
      read: ['users:read'],
      write: ['users:write']
    }
  },
  resetPasswordModal: {
    address: '',
    defaultData: {},
    fullScreen: false,
    open: false
  },
  showCreateUserModal: {
    fullScreen: false,
    open: false
  },
  showDeletedUsers: {
    show: false
  },
  showDeleteUserModal: {
    defaultData: {},
    fullScreen: false,
    open: false
  },
  showLockUserModal: {
    defaultData: {},
    fullScreen: false,
    open: false
  },
  showRestoreUserModal: {
    defaultData: {},
    fullScreen: false,
    open: false
  },
  showUnlockUserModal: {
    defaultData: {},
    fullScreen: false,
    open: false
  },
  showUpdateUserModal: {
    defaultData: {},
    fullScreen: false,
    open: false
  },
  viewRecentActivity: {
    fullScreen: false,
    id: '',
    open: false
  },
  viewType: false
};

export const UsersContext = createContext(usersSettings);

const UsersProvider = props => {
  const [users, setUsers] = useState(usersSettings);
  const [loading, setLoading] = useState(true);

  const { axiosPrivate } = useAxiosPrivate();

  const getAppUsers = async () => {
    try {
      let users = await LeargasAPI.Users.getUsers(axiosPrivate);
      if (users instanceof Error) throw users;

      setUsers(prevState => ({
        ...prevState,
        data: users
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }
    } finally {
      setUsers(prevState => ({
        ...prevState,
        fetch: false
      }));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (users.fetch) {
      getAppUsers();
    }
  }, [users.fetch]);

  useEffect(() => {
    getAppUsers();
  }, []);

  return (
    <UsersContext.Provider
      value={{
        users,
        setUsers,
        loading
      }}>
      {props.children}
    </UsersContext.Provider>
  );
};

UsersProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default UsersProvider;
