const systemRoles = {
  SuperAdmin: '63ab9a512e6f547a6ea7ffd3',
  Admin: '63ab9a772e6f547a6ea7ffd6',
  User: '63ab9b282e6f547a6ea7ffd9'
};

const Rolesdeveselect = [
  { value: '63ab9b282e6f547a6ea7ffd9', label: 'User' },
  { value: '63ab9a772e6f547a6ea7ffd6', label: 'Admin' },
  { value: '63ab9a512e6f547a6ea7ffd3', label: 'SuperAdmin' }
];

module.exports = { systemRoles, Rolesdeveselect };
