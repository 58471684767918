import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useOrganizations from 'hooks/admin-contexts/useOrganizations';
import useRoles from 'hooks/admin-contexts/useRoles';
import useUsers from 'hooks/admin-contexts/useUsers';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormText, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import MultiSelect from './MultiSelect';

/**
 * UpdateUserForm component for updating user details.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.hasLabel - Flag to determine if labels should be displayed.
 * @returns {JSX.Element} The rendered UpdateUserForm component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.6
 * @since 0.1.0-beta.2
 */
const UpdateUserForm = ({ hasLabel }) => {
  const { axiosPrivate } = useAxiosPrivate();

  const { roles } = useRoles();

  const {
    users: {
      showUpdateUserModal: { defaultData }
    },
    setUsers
  } = useUsers();

  const {
    organizations: { data }
  } = useOrganizations();

  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors },
    reset
  } = useForm();

  const [loading, setLoading] = useState(false);

  /**
   * Clears the selection of organizations by setting the 'organizations' field to an empty array.
   *
   * @function handleClearSelection
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.6
   * @since 0.1.0-beta.2
   */
  const handleClearSelection = () => {
    setValue('organizations', []);
  };

  /**
   * Handles the change event for the role selection.
   *
   * @param {Array} selectedOptions - The selected options for roles.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.6
   * @since 0.1.0-beta.2
   */
  const handleRoleChange = selectedOptions => {
    setValue('roles', selectedOptions);
  };

  /**
   * Handles the selection of all organizations.
   * Maps the organization data to an array of objects with value and label properties,
   * and sets this array to the 'organizations' field in the form.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.6
   * @since 0.1.0-beta.2
   */
  const handleSelectAll = () => {
    const allOrganizations =
      data?.map(org => ({
        value: org.id,
        label: org.name
      })) || [];
    setValue('organizations', allOrganizations);
  };

  /**
   * Handles the form submission for updating a user.
   *
   * @param {Object} data - The form data to be submitted.
   * @param {Array} data.roles - The roles assigned to the user.
   * @param {Array} data.organizations - The organizations associated with the user.
   * @returns {Promise<void>} - A promise that resolves when the submission is complete.
   *
   * @throws {Error} - Throws an error if the update operation fails.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.6
   * @since 0.1.0-beta.2
   */
  const onSubmit = async data => {
    try {
      setLoading(true);
      const updatedData = {
        ...data,
        roles: data?.roles.map(role => role.value),
        organizations: data?.organizations.map(org => org.value)
      };
      const res = await LeargasAPI.Users.updateUser(
        defaultData?._id,
        updatedData,
        axiosPrivate
      );
      if (res instanceof Error) throw res;
      if (res?.message) {
        setUsers(prevState => ({
          ...prevState,
          showUpdateUserModal: { open: false, user: {} },
          fetch: true
        }));
        setLoading(false);
        toast.success(res?.message);
      }
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);
      setUsers(prevState => ({
        ...prevState,
        showUpdateUserModal: { open: false, user: {} },
        fetch: false
      }));
      if (error?.response?.data?.message) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  useEffect(() => {
    if (defaultData) {
      reset({
        firstName: defaultData?.profile?.name?.first,
        lastName: defaultData?.profile?.name?.last,
        roles:
          defaultData?.roles?.map(role => ({
            value: role.id,
            label: role.name
          })) || [],
        organizations:
          defaultData?.organizations?.map(org => ({
            value: org.id,
            label: org.name
          })) || []
      });
    }
  }, [defaultData, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className='g-3 mb-3'>
        <Col sm={6}>
          <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
            First Name <span className='text-danger ms-1'>*</span>
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='The first name of the user (given name)'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>{' '}
          <Form.Control
            placeholder={!hasLabel ? 'First Name' : ''}
            {...register('firstName', { required: 'First Name is required' })}
            type='text'
            autoComplete='off'
            className='fs--1'
            style={{ minHeight: '36px' }}
          />
          <FormText className='text-danger'>
            {errors.firstName?.message}
          </FormText>
        </Col>

        <Col sm={6}>
          <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
            Last Name <span className='text-danger ms-1'>*</span>
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='The last name of the user (family name)'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <Form.Control
            placeholder={!hasLabel ? 'Last Name' : ''}
            {...register('lastName', { required: 'Last Name is required' })}
            type='text'
            autoComplete='off'
            className='fs--1'
            style={{ minHeight: '36px' }}
          />
          <FormText className='text-danger'>
            {errors.lastName?.message}
          </FormText>
        </Col>
      </Row>

      <Row className='g-3 mb-3'>
        <Col sm={6}>
          <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
            Roles <span className='text-danger ms-1'>*</span>
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Application roles that the user should have'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>

          <Controller
            name='roles'
            control={control}
            defaultValue={[]}
            rules={{ required: 'At least one role is required' }}
            render={({ field }) => (
              <MultiSelect
                className='fs--1'
                isMulti
                isClearable
                isSearchable
                placeholder='Please select'
                options={roles?.data?.map(role => ({
                  value: role.id,
                  label: role.name
                }))}
                onChange={selectedOptions => {
                  field.onChange(selectedOptions);
                  handleRoleChange(selectedOptions);
                }}
                value={field.value}
                style={{ minHeight: '36px' }}
              />
            )}
          />
          <FormText className='text-danger'>{errors.roles?.message}</FormText>
        </Col>
        <Col sm={12}>
          <Flex direction='row' alignItems='center' justifyContent='between'>
            <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
              Organizations <span className='text-danger ms-1'>*</span>
              <AdvancedPopover
                containerId='newFilterModal'
                placement='top'
                popoverText='The organization the user belongs to'
                showArrow={true}>
                <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  className='ms-1 text-400 fs--1 cursor-pointer'
                />
              </AdvancedPopover>
            </Form.Label>
            <div className='mb-2'>
              <Button
                size='sm'
                variant='link'
                className='ms-2 p-0 text-decoration-none'
                onClick={handleSelectAll}>
                Select All
              </Button>
              <Button
                size='sm'
                variant='link'
                className='ms-2 p-0 text-decoration-none'
                onClick={handleClearSelection}>
                Clear Selection
              </Button>
            </div>
          </Flex>
          <Controller
            name='organizations'
            control={control}
            defaultValue={[]}
            rules={{ required: 'At least one organization is required' }}
            render={({ field }) => (
              <MultiSelect
                className='fs--1'
                isClearable
                isMulti
                isSearchable
                placeholder='Please select'
                options={data?.map(org => ({
                  value: org.id,
                  label: org.name
                }))}
                onChange={selectedOptions => {
                  field.onChange(selectedOptions);
                  setValue('organizations', selectedOptions);
                }}
                value={field.value}
                style={{ minHeight: '36px' }}
              />
            )}
          />
          <FormText className='text-danger'>
            {errors.organizations?.message}
          </FormText>
        </Col>
      </Row>

      <Flex justifyContent='end'>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() =>
            setUsers(prevState => ({
              ...prevState,
              showUpdateUserModal: { open: false, user: {} },
              fetch: false
            }))
          }>
          Cancel
        </Button>

        <Button variant='success' size='sm' disabled={loading} type='submit'>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Updating' : 'Update Account'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

UpdateUserForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default UpdateUserForm;
