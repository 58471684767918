import LeargasAPI from 'api';
import { truncateMiddle } from 'helpers/utils';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const FieldEditModal = ({
  show,
  handleClose,
  fieldId,
  fieldName,
  onUpdate
}) => {
  const { axiosPrivate } = useAxiosPrivate();
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    enabled: false,
    sensitive: false,
    gptEnabled: false,
    gptPromptBeforeTerm: '',
    gptPromptAfterTerm: ''
  });

  // Fetch field data by id when modal opens
  useEffect(() => {
    if (show && fieldId) {
      setLoading(true);
      LeargasAPI.Fields.getField(fieldId, axiosPrivate)
        .then(data => {
          setFormValues({
            enabled: data.enabled,
            sensitive: data.sensitive,
            gptEnabled: data.gpt.enabled,
            gptPromptBeforeTerm: data.gpt.promptBeforeTerm,
            gptPromptAfterTerm: data.gpt.promptAfterTerm
          });
          setLoading(false);
        })
        .catch(() => {
          toast.error('Error loading field data.');
          setLoading(false);
        });
    }
  }, [show, fieldId, axiosPrivate]);

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const updateData = {
        _id: fieldId,
        enabled: formValues.enabled,
        sensitive: formValues.sensitive,
        gptEnabled: formValues.gptEnabled,
        gptPromptBeforeTerm: formValues?.gptPromptBeforeTerm,
        gptPromptAfterTerm: formValues?.gptPromptAfterTerm
      };

      await LeargasAPI.Fields.updateFields(updateData, axiosPrivate);
      toast.success('Field updated successfully.');
      onUpdate(); // Trigger the parent component to refresh data
      handleClose();
    } catch {
      toast.error('Failed to update field.');
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <h5 className='mb-0'>
          Edit Field{fieldName ? `: ${truncateMiddle(fieldName, 30)}` : ''}
        </h5>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <style>
          {`
              .custom-switch .form-check-input:checked {
                background-color: var(--falcon-pastel-green-dark);
                border-color: var(--falcon-pastel-green-dark);
              }
              .custom-switch .form-check-input:checked + .form-check-label::before {
                background-color: var(--falcon-primary);
              }
              .custom-switch .form-check-input:checked::before {
                background-color: var(--falcon-primary);
              }
              .custom-switch .form-check-input:focus {
                box-shadow: 0 0 0 0.25rem rgba(var(--falcon-pastel-green-dark-rgb), 0.5);
              }
            `}
        </style>
        <Modal.Body>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className='custom-switch'>
              <h3 className='fs-0 mb-2'>Status</h3>
              <p className='mb-2'>
                <small>
                  Toggle the status of this field to enable or disable it for
                  use in the application.
                </small>
              </p>
              <Form.Group controlId='formEnabled' className='mb-3'>
                <Form.Check
                  type='switch'
                  label='Enabled'
                  name='enabled'
                  checked={formValues.enabled}
                  onChange={handleChange}
                />
              </Form.Group>
              <h3 className='fs-0 mb-2'>Sensitivity</h3>
              <p className='mb-2'>
                <small>
                  Mark this field as sensitive to prevent it from being included
                  in GPT-4 enrichment requests, and to ensure that values are
                  obscured when Privacy Mode is enabled.
                </small>
              </p>
              <Form.Group controlId='formSensitive' className='mb-3'>
                <Form.Check
                  type='switch'
                  label='Sensitive'
                  name='sensitive'
                  checked={formValues.sensitive}
                  onChange={handleChange}
                />
              </Form.Group>
              <h3 className='fs-0 mb-2'>GPT-4 Enrichment</h3>
              <p className='mb-2'>
                <small>
                  Use GPT-4 to provide rich information to users for values from
                  this field.
                </small>
              </p>
              <p className='mb-2'>
                <small>
                  When enabled, users will have a new search value context menu
                  option to get GPT-4 generated information, based on the
                  selected value, in the context of the provided prompt
                  fragments defined below (not required but recommended).
                </small>
              </p>
              <Form.Group controlId='formGptEnabled' className='mb-0'>
                <Form.Check
                  type='switch'
                  label='GPT Enrichment Enabled'
                  name='gptEnabled'
                  checked={formValues.gptEnabled}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId='formGptPromptBefore' className='mb-2'>
                <Form.Label className='text-900'>Before Search Term</Form.Label>
                <Form.Control
                  type='text'
                  as={'textarea'}
                  rows={4}
                  name='gptPromptBeforeTerm'
                  value={formValues.gptPromptBeforeTerm}
                  onChange={handleChange}
                  placeholder='Enter the prompt leading up to the user-selected search term.'
                  className='fs--1 ps-2'
                />
              </Form.Group>
              <Form.Group controlId='formGptPromptAfter'>
                <Form.Label className='text-900'>After Search Term</Form.Label>
                <Form.Control
                  type='text'
                  as={'textarea'}
                  rows={4}
                  name='gptPromptAfterTerm'
                  value={formValues.gptPromptAfterTerm}
                  onChange={handleChange}
                  placeholder='Enter the prompt following the user-selected search term, if any.'
                  className='fs--1 ps-2'
                />
              </Form.Group>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' type='submit'>
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

// Define propTypes for the component to address validation
FieldEditModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fieldId: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  fieldName: PropTypes.string
};

export default FieldEditModal;
