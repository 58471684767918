import React from 'react';
import PropTypes from 'prop-types';
import ProfileContainer from './ProfileContainer';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const OrgProfile = ({ org }) => {
  return (
    <>
      <ProfileContainer org={org} />
    </>
  );
};

OrgProfile.propTypes = {
  org: PropTypes.object.isRequired
};

export default OrgProfile;
