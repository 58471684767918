import { useContext } from 'react';
import { ClusterContext } from 'context/ClusterContext';

/**
 * Custom hook to use the ClusterContext
 * @returns {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
export const useCluster = () => {
  return useContext(ClusterContext);
};

export default useCluster;
