import { ApplicationContext } from 'context/ApplicationProvider';
import { useContext } from 'react';

/**
 * Custom hook that returns the application context
 *
 * @returns {ApplicationContext} The application context
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
export const useApplication = () => {
  return useContext(ApplicationContext);
};

export default useApplication;
