import { makeRequest } from 'api/connection/requestAction';
/**
 * @description UserRoles API
 * @summary Provides methods for managing user roles
 * @method createUserRole Create a new user role
 * @method getUserRoles Get all user roles
 * @method getUserRole Get a user role by ID
 * @method updateUserRole Update a user role by ID
 * @method deleteUserRole Delete a user role by ID
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
class UserRoles {
  constructor(route) {
    this.route = route;
  }
  /**
   * @description Create a new user role
   * @param {object} request - The request object
   * @param {string} request.body.name - The name of the user role
   * @param {string} request.body.description - The description of the user role
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  async createUserRole(request, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.createUserRole.method,
        this.route.createUserRole.path,
        request.body
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Get all user roles
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  async getUserRoles(axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getUserRoles.method,
        this.route.getUserRoles.path
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Get a user role by ID
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  async getUserRole(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getUserRoles.method,
        [this.route.getUserRoles.path, id].join('/')
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Update a user role by ID
   * @param {object} request - The request object
   * @param {string} request.body.name - The name of the user role
   * @param {string} request.body.description - The description of the user role
   * @param {string} request.body.users - The user IDs of the user role members
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  async updateUserRole(id, request, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateUserRole.method,
        [this.route.updateUserRole.path, id].join('/'),
        request.body
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  /**
   * @description Delete a user role by ID
   * @returns {Promise}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  async deleteUserRole(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.deleteUserRole.method,
        [this.route.deleteUserRole.path, id].join('/')
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default UserRoles;
