import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useApplication from './useApplication';
import useAuthentication from './useAuthentication';
import useLock from './useLock';
import useLogout from './useLogout';

/**
 * Detects keystrokes and executes callbacks based on the keystroke definitions
 *
 * @returns {void}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.2
 * @version 0.1.0-beta.2
 *
 * @example
 * import useKeystrokeDetection from 'hooks/useKeystrokeDetection';
 * useKeystrokeDetection();
 */
const useKeystrokeDetection = keystrokeDefinitions => {
  let { application, setApplication } = useApplication();
  let { authentication } = useAuthentication();
  let logout = useLogout();
  let lock = useLock();
  let navigate = useNavigate();

  const pressedKeysRef = useRef(new Set());

  // Handle the keydown event
  const handleKeyDown = event => {
    // Check if the event.key is a string
    if (typeof event.key !== 'string') return;

    const key = event.key.toLowerCase();
    pressedKeysRef.current.add(key);
  };

  // Handle the keyup event
  const handleKeyUp = event => {
    for (const keystrokeDefinition of keystrokeDefinitions) {
      const { shortcuts } = keystrokeDefinition;

      for (const shortcut of shortcuts) {
        const { keys, callback } = shortcut;

        // Check to make sure the authentication.roles has at least one of the keystrokeDefinition.allowedRoles
        if (
          keystrokeDefinition.allowedRoles.some(role =>
            authentication?.roles?.find(authRole => authRole.id === role)
          )
        ) {
          // If the keys in the shortcut match the keys in the pressed keys ref plus the key that was just released, in the correct order, execute the callback
          if (keys.join('+') === [...pressedKeysRef.current].join('+')) {
            callback({ application, setApplication, logout, lock, navigate });

            // Clear the pressed keys ref
            pressedKeysRef.current.clear();
          }
        }
      }
    }

    const key = event?.key?.toLowerCase();
    if (key) pressedKeysRef.current.delete(key);
  };

  // Add event listeners for keydown and keyup every time the application object changes
  useEffect(() => {
    if (!authentication?.id || authentication?.sessionLocked) return;
    document.addEventListener('fullscreenchange', null);
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('fullscreenchange', null);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [authentication, application]);

  // Clear the pressed keys ref when the component remounts
  useEffect(() => {
    pressedKeysRef.current.clear();
  }, []);
};

export default useKeystrokeDetection;
