import { apiRoutes } from 'config/routeSettings';

import { staticInjectRules } from 'components/dashboards/Artifacts/Dynamic/dataFetchUtils/utils.js';

import { getLast24Hours } from 'components/dashboards/Artifacts/Dynamic/dataFetchUtils/RefinedUtils.js';
export const loadGeneralQuery = async ({
  scopesSelected,
  axiosPrivate,
  setGeneralQuery,
  setWrapperLoader,
  overriddenIndex,
  must = [],
  should,
  filter,
  must_not,
  aggregate = ''
}) => {
  try {
    setWrapperLoader(true);

    let selectedScopes = [];
    if (scopesSelected.length !== 0) {
      selectedScopes = scopesSelected
        ?.map(scope => {
          if (scope?.legacy?.clientId) {
            return {
              match: {
                'clientId.keyword': scope.legacy.clientId
              }
            };
          }
          return null;
        })
        .filter(scopeQuery => scopeQuery !== null);
    } else {
      selectedScopes = [
        {
          match: {
            'clientId.keyword': 'ZEROCUSTOMERS'
          }
        }
      ];
    }

    let { from, to } = getLast24Hours();

    let injectionProcessQuery = {
      query: {
        index: [],
        body: {
          query: {
            bool: {
              filter: [
                {
                  range: {
                    '@timestamp': {
                      gte: from,
                      lte: to,
                      time_zone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                      format: 'strict_date_optional_time'
                    }
                  }
                }
              ],
              must: [
                {
                  bool: {
                    must: [],
                    must_not: [],
                    filter: [],
                    should: []
                  }
                }
              ],
              should: selectedScopes,
              minimum_should_match: 1
            }
          }
        }
      }
    };

    injectionProcessQuery.query.index = [...overriddenIndex];

    let rulesInjectedQuery = staticInjectRules({
      should: should !== null ? should : [],
      must: must !== null ? must : [],
      must_not: must_not !== null ? must_not : [],
      filter: filter !== null ? filter : []
    });
    injectionProcessQuery.query.body.query.bool.must = [
      ...rulesInjectedQuery.must
    ];

    if (aggregate !== '') {
      injectionProcessQuery.query.body.aggs = aggregate.aggs;
    }

    const generalQueryData = await axiosPrivate.post(
      apiRoutes.SYSTEM_CLUSTER_QUERY_AGG,
      JSON.stringify(injectionProcessQuery)
    );
    if (generalQueryData instanceof Error) throw generalQueryData;
    setWrapperLoader(false);
    setGeneralQuery({
      type: 'GeneralQuery',
      data: generalQueryData?.data?.data,
      query: injectionProcessQuery,
      response: generalQueryData?.data?.data
    });
  } catch (error) {
    setWrapperLoader(false);
    console.error('eerror', error);
  }
};
