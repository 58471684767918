import LeargasAPI from 'api';
import useAuthentication from 'hooks/useAuthentication';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

export const reportsSettings = {
  addReportModal: {
    fullScreen: false,
    open: false
  },
  data: [],
  viewReportModal: {
    data: {},
    open: false
  },
  deleteReportModal: {
    data: {},
    open: false
  },
  editReportModal: {
    data: {},
    fullScreen: false,
    open: false
  },
  fetch: false,
  loading: true,
  requiredReports: {
    reports: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    },
    reportsNotes: {
      delete: ['super-admin', 'admin'],
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    reports: {
      delete: ['reports:delete'],
      read: ['reports:read'],
      write: ['reports:write']
    },
    reportsNotes: {
      delete: ['reportsNotes:delete'],
      read: ['reportsNotes:read'],
      write: ['reportsNotes:write']
    }
  },
  showDeleted: false
};

/**
 * Create ReportsContext
 *
 * @type {React.Context<{}>}
 *
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const ReportsContext = createContext(reportsSettings);

/**
 * Create ReportsProvider
 *
 * @param props
 * @returns {JSX.Element}
 *
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 *
 * @example
 * return (
 *  <ReportsProvider>
 *   {children}
 * </ReportsProvider>
 * )
 */
const ReportsProvider = props => {
  const [reports, setReports] = useState(reportsSettings);
  const [loading, setLoading] = useState(true);

  const { axiosPrivate: axiosInstance } = useAxiosPrivate();
  const axiosPrivate = useMemo(() => axiosInstance, []);
  const {
    authentication: { scopesSelected }
  } = useAuthentication();

  /**
   * Get all application Reports
   * @returns {Promise<void>}
   * @async
   * @function
   *
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const getReports = useCallback(async () => {
    try {
      setLoading(true);
      let res = await LeargasAPI.MangeReports.getReports(axiosPrivate);
      if (res instanceof Error) throw res;
      let resArray = res?.data?.data;
      // Filter out reports based on the scopesSelected._id
      const filteredReports = resArray.filter(report => {
        const orgChecked = scopesSelected.some(
          scope => scope._id === report.organization._id
        );
        return orgChecked;
      });

      setReports(prevState => ({
        ...prevState,
        data: filteredReports
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }
    } finally {
      setLoading(false);
      setReports(prevState => ({
        ...prevState,
        fetch: false
      }));
    }
  }, [axiosPrivate, scopesSelected]);

  /**
   * Get all application Reports whenever scopesSelected changes
   */
  useEffect(() => {
    getReports();
  }, [scopesSelected]);

  return (
    <ReportsContext.Provider
      value={{
        reports,
        setReports,
        loading
      }}>
      {props.children}
    </ReportsContext.Provider>
  );
};

ReportsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ReportsProvider;
