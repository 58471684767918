import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useUsers from 'hooks/admin-contexts/useUsers';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PasswordStrengthBar from 'react-password-strength-bar';
import { toast } from 'react-toastify';

import useAxiosPrivate from 'hooks/useAxiosPrivate';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ResetPasswordForm = () => {
  const {
    users: { resetPasswordModal },
    setUsers
  } = useUsers();

  const { axiosPrivate } = useAxiosPrivate();
  const [showPasswrod, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();
  const [loading, setLoading] = useState(false);

  const { password, confirmedPassword } = watch();

  /**
   * submit form data to endpoint
   * @param {Object} data
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  const onSubmitData = async data => {
    setLoading(true);
    LeargasAPI.Users.updatePasswordForUser(
      resetPasswordModal?.defaultData?.id,
      data,
      axiosPrivate
    )
      .then(res => {
        if (res instanceof Error) throw res;
        setLoading(false);
        setUsers(prevState => ({
          ...prevState,
          resetPasswordModal: { open: false },
          fetch: true
        }));
        toast.success(res?.message);
      })
      .catch(error => {
        setLoading(false);
        setUsers(prevState => ({
          ...prevState,

          resetPasswordModal: { open: false },
          fetch: false
        }));
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error('Something went wrong!');
        }
      });
  };

  /**
   *
   * checks the input confimedpassword for password matching
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const handleconfirmedPasswordBlur = () => {
    const isPasswordMatch = password === confirmedPassword;

    if (!isPasswordMatch) {
      setError('confirmedPassword', {
        type: 'manual',
        message: 'Passwords do not match'
      });
    } else {
      clearErrors('confirmedPassword');
    }
  };

  useEffect(() => {}, []);
  return (
    <Form onSubmit={handleSubmit(onSubmitData)}>
      <Row className='g-3 mb-3'>
        <Form.Group as={Col} sm={6} className='required'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            placeholder={'password'}
            name='password'
            {...register('password')}
            type={showPasswrod ? 'text' : 'password'}
            autoComplete='off'
            className='fs--1'
            style={{ minHeight: '36px' }}
            required
          />
          <p className='form-label fw-semi-bold mt-3'>Password Strength</p>
          <PasswordStrengthBar password={password} minLength={8} />
        </Form.Group>
        <Form.Group as={Col} sm={6} className='required'>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            placeholder={'confirm password'}
            name='confirmedPassword'
            {...register('confirmedPassword')}
            type={showPasswrod ? 'text' : 'password'}
            autoComplete='off'
            className={`fs--1 ${errors.confirmedPassword ? 'is-invalid' : ''}`}
            style={{ minHeight: '36px' }}
            onBlur={handleconfirmedPasswordBlur}
            required
          />
          {errors.confirmedPassword && (
            <Form.Control.Feedback type='invalid'>
              {errors.confirmedPassword.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col} sm={6} className='mb-0'>
          <Flex>
            <Form.Check
              type='switch'
              name='showPasswrod'
              checked={showPasswrod}
              onChange={() => setShowPassword(prevValue => !prevValue)}
              className='mb-0'
            />
            <Form.Check.Label className='mb-0'>showPasswrod</Form.Check.Label>
          </Flex>
        </Form.Group>
      </Row>

      <Flex justifyContent='end'>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() =>
            setUsers(prevState => ({
              ...prevState,
              resetPasswordModal: { open: false },
              fetch: false
            }))
          }>
          Cancel
        </Button>

        <Button
          variant='success'
          type='submit'
          size='sm'
          className='d-flex'
          disabled={
            password?.length < 8 ||
            !password ||
            !confirmedPassword ||
            password !== confirmedPassword ||
            errors.confirmedPassword
          }>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Resetting' : 'Reset password'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

export default ResetPasswordForm;
