import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
// import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NormalUser = ({ user, avatarSize = 'l' }) => {
  const { roles, profile: { fullName = '', media: { avatar } = {} } = {} } =
    user;

  return (
    <>
      <Flex direction='row' alignItems='center' className='cursor-pointer'>
        <div className='d-inline-flex align-items-center'>
          {/* <Button className={`avatar-popover d-inline-flex align-items-center p-0  bg-transparent border-0 shadow-none`} style={{ whiteSpace: 'nowrap' }}> */}{' '}
          <Avatar
            size={avatarSize}
            src={avatar || process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG}
            //  className={`${status !== 'offline' && `status-${status}`}`}
          />
          {/* </Button> */}
          <span className='fs--1 text-700'>
            &nbsp;{fullName}{' '}
            {roles.some(item =>
              ['Admin', 'Super Admin'].includes(item?.name)
            ) ? (
              <FontAwesomeIcon
                icon={['fas', 'clover']}
                transform='rotate-45'
                className='text-primary fs--1'
              />
            ) : null}
          </span>
        </div>
      </Flex>
    </>
  );
};

NormalUser.propTypes = {
  user: PropTypes.object,
  avatarSize: PropTypes.string
};

export default NormalUser;
