import classNames from 'classnames';
import Flex from 'components/common/Flex';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import FieldList from './FieldList';

/**
 * ExploreSidebar component.
 *
 * @component
 * @param {Object} props - The component props
 * @param {ReactNode} props.children - The children components
 * @returns {ReactNode} The rendered ExploreSidebar component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.5
 * @version 0.1.0-beta.5
 */
const ExploreSidebar = ({ children }) => {
  const {
    application: { showExploreFieldsSidebar }
  } = useApplication();

  return (
    <Flex direction='row' className='sidebar-layout'>
      <Flex
        direction='column'
        className={classNames('sidebar position-relative', {
          'fade-out': !showExploreFieldsSidebar,
          'fade-in': showExploreFieldsSidebar
        })}
        style={{ width: !showExploreFieldsSidebar ? '0px' : '225px' }}>
        <div style={{ minWidth: '225px' }}>
          <div className={classNames('sidebar-content')}>
            <FieldList />
          </div>
        </div>
      </Flex>
      <Flex direction='column' className='main-content'>
        {children}
      </Flex>
    </Flex>
  );
};

ExploreSidebar.propTypes = {
  children: PropTypes.node
};

export default ExploreSidebar;
