import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuthentication from 'hooks/useAuthentication';
import { routes } from 'config/routeSettings';
import PropTypes from 'prop-types';

/**
 * Component to protect routes based on user roles.
 * @param {Object} props - Component props.
 * @param {Array} props.allowedRoles - Array of roles allowed to access the route.
 * @returns {JSX.Element} - Outlet for allowed roles or redirection to error page.
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const RoleAuthRoute = ({ allowedRoles }) => {
  const { authentication } = useAuthentication();
  const location = useLocation();

  // Ensure that user is authenticated and has roles.
  if (authentication?.roles) {
    // Checks if user has at least one role that is allowed.
    const isRouteAllowed = authentication.roles.some(role =>
      allowedRoles.includes(role.slug)
    );

    // Route the user accordingly.
    return isRouteAllowed ? (
      <Outlet />
    ) : (
      <Navigate to={routes.ERROR_403} state={{ from: location }} replace />
    );
  }

  // Default redirection if user roles don't exist or not authenticated properly.
  return <Navigate to={routes.ERROR_403} state={{ from: location }} replace />;
};

RoleAuthRoute.propTypes = {
  allowedRoles: PropTypes.array.isRequired
};

export { RoleAuthRoute };
