import React from 'react';
import useAccount from 'hooks/useAccount';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import VerifiedBadge from 'components/common/VerifiedBadge';
import MFASetupForm from './MFASetupForm';
import { Col, Row } from 'react-bootstrap';
import useAuthentication from 'hooks/useAuthentication';

/**
 * Multi-factor authentication app setup page
 *
 * The MFA setup page is used to display a form to the user to enable a multi-factor authentication app. The user is
 * normally directed to this page after they have successfully logged in and have not yet enabled multi-factor
 * authentication.
 * We use the useAccount hook to get the account data from the context. Then we display a form to the user to enable
 * multi-factor authentication. The form has it's own state and handles the API calls to enable this feature.
 * @see MFASetupForm
 * @returns {React.Component} The MFA app setup page
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const MFASetup = () => {
  const {
    authentication: { emails }
  } = useAuthentication();
  const {
    account: {
      profile: {
        name: { first, last },
        media: { avatar }
      }
    }
  } = useAccount();

  return (
    <Row>
      <Col>
        <Flex direction='column' justifyContent='around'>
          <Flex direction='column' alignItems='center'>
            <Avatar
              src={avatar || process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG}
              size='4xl'
            />
            <h5 className='mb-1 ps-4 pt-2 fs--1'>
              {[first, last].join(' ')} <VerifiedBadge />
            </h5>
            <span className='fs--1 mb-1'>
              {emails.find(email => email.isPrimary).address}
            </span>
            <hr className='w-100' />
            <h5 className='mb-0 mt-1 fs--1'>Welcome, {first}!</h5>
            <p className='pt-2 fs--1 text-center'>
              Please enable multifactor authentication
            </p>
          </Flex>
          <MFASetupForm className='mx-sm-4' type='simple' />
        </Flex>
      </Col>
    </Row>
  );
};

export default MFASetup;
