import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Spinner } from 'react-bootstrap';

const ButtonSpinner = ({ spinning }) => (
  <Flex justifyContent='around' alignItems='center'>
    {spinning && (
      <Spinner
        as='span'
        animation='border'
        size='sm'
        role='status'
        aria-hidden='true'
        className='align-top mx-1  '
      />
    )}
  </Flex>
);

ButtonSpinner.propTypes = {
  grow: PropTypes.number,
  spinning: PropTypes.bool
};

export default ButtonSpinner;
