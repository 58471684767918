import LeargasAPI from 'api';
import { convertStringToText } from 'components/dashboards/Artifacts/Dynamic/dataFetchUtils/RefinedUtils';
import SimpleSpinner from 'components/utilities/AppSpinner/smallSpinner';
import { routes } from 'config/routeSettings';
import {
  calculateSeconds,
  makeFieldsArray,
  makeNewFieldsObj
} from 'helpers/utils';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useESGlobalFilters } from 'hooks/useGlobalFilters';
import React, { useEffect, useState } from 'react';
import {
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 * Component for selecting an index pattern
 * @returns {JSX.Element} The IndexPatternSelect component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const IndexPatternSelect = () => {
  const {
    esGlobalFilters: { index, gte, Config, allowedLimit, checkerIndices, lte },
    setESGlobalFilters
  } = useESGlobalFilters();
  const { axiosPrivate } = useAxiosPrivate();

  const location = useLocation();

  const [indices, setIndices] = useState([]);
  const [indexLoader, setIndexLoader] = useState(false);

  /**
   * Retrieves the indices from the LeargasAPI and sets them in the component state
   *
   * @returns {Promise<void>} A promise that resolves when the indices are retrieved and set
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const getIndices = async () => {
    try {
      const Indices = await LeargasAPI.Indices.getIndices(axiosPrivate);
      if (Indices instanceof Error) throw Indices;
      if (Array.isArray(Indices)) {
        setIndices(Indices);
      }
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setIndices([]);
    }
  };

  /**
   * Sets the indices in the component state when the component mounts
   */
  useEffect(() => {
    getIndices();
  }, []);

  return (
    <>
      <Col md={6} xxl={4} className={`py-0 my-0 `}>
        <div className='position-relative dropdown-wrap mb-2'>
          {indexLoader && (
            <span
              className='position-absolute'
              style={{ right: '20px', top: '8px' }}>
              Getting Fields for the index <SimpleSpinner size='sm' />
            </span>
          )}
          <Form.Group>
            <Form.Label className='fs--1'>Data Type</Form.Label>
            <InputGroup
              className='filter-input-group mb-2'
              style={{ opacity: indexLoader ? 0.2 : 1 }}>
              <Form.Control
                value={index === 'leargas' ? 'Global' : index}
                className='fs--1 border-radius-right-0'
                size='sm'
                disabled
              />
              <DropdownButton
                title=''
                size='sm'
                tabIndex='3'
                variant='outline-secondary'
                id='input-group-dropdown-2'
                disabled={location.pathname !== routes.ARTIFACTS_EXPLORE}>
                <Dropdown.Header className='mt-1'>
                  Index Patterns
                </Dropdown.Header>
                {indices &&
                  indices
                    .filter(item => item.enabled)
                    .map((item, key) => (
                      <Dropdown.Item
                        key={key}
                        eventKey={item.alias}
                        active={item.alias === index}
                        onClick={async () => {
                          if (
                            checkerIndices &&
                            checkerIndices.includes(item.alias) &&
                            allowedLimit <= calculateSeconds(gte, lte)
                          ) {
                            toast.error(
                              `This time limit selected for this index can make the circuit of physical explode. Please reduce your range first for this index. Index=${index}`
                            );
                            return;
                          } else {
                            setIndexLoader(true);
                            try {
                              const Fields =
                                await LeargasAPI?.Fields?.getFields(item.alias);
                              if (Fields instanceof Error) throw Fields;

                              let excludeDisabled = Fields?.filter(
                                item => item.enabled === true
                              );
                              let resolvedFields =
                                convertStringToText(excludeDisabled);
                              let privateColumns = Fields?.filter(
                                item => item.sensitive
                              ).map(item => item.name);
                              let updatedFields =
                                makeFieldsArray(resolvedFields);
                              let newFieldsObj =
                                makeNewFieldsObj(updatedFields);
                              let newConfig = {
                                ...Config,
                                fields: newFieldsObj
                              };

                              setESGlobalFilters(prevState => ({
                                ...prevState,
                                fields: updatedFields,
                                Config: newConfig,
                                fieldsToUseEverywhere: updatedFields,
                                privateColumns: privateColumns
                              }));

                              setESGlobalFilters(prevState => {
                                return {
                                  ...prevState,
                                  // index: [item.alias],
                                  indexId: item.id,
                                  highlightedGlobalIndex: null
                                };
                              });
                            } catch (error) {
                              console.error(error);
                              if (error.message.includes('Network Error')) {
                                console.error('Network Error occurred.');
                              }
                              if (error.message.includes('Network Error')) {
                                console.error('Network Error occurred.');
                              }
                              setIndexLoader(false);
                            } finally {
                              setIndexLoader(false);
                            }
                          }
                        }}>
                        {item.alias}
                      </Dropdown.Item>
                    ))}
              </DropdownButton>
            </InputGroup>
          </Form.Group>
        </div>
      </Col>
    </>
  );
};

export default IndexPatternSelect;
