import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

export const box = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'Box Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-box']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Events',
          helpInfo: 'Total number of Box events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-box'],
        must: [
          {
            match_all: {}
          },
          {
            exists: {
              field: 'source_geoip.country_code2.keyword'
            }
          }
        ],
        must_not: [
          {
            match_phrase: {
              'source_geoip.country_code2.keyword': 'US'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Non-US Events',
          helpInfo: 'Total number of Box events triggered by non-US sources'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, lg: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-box'],
        must: [
          {
            match_phrase: {
              'event_type.keyword': 'DOWNLOAD'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Download Events',
          helpInfo: 'Total number of Box events indicating a download action'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-box'],
        queryType: ['histogram']
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Event Timeline',
          helpInfo:
            'Timeline histogram of all Box events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['map'],
        overriddenIndex: ['leargas-box'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'source_geoip.country_name.keyword',
                order: {
                  _count: 'desc'
                },
                exclude: [''],
                size: 100
              }
            }
          }
        }
      },
      children: {
        component: 'Map',
        props: {
          className: '',
          title: 'Event Source Locations',
          field: 'source_geoip.country_name',
          helpInfo: 'Source countries of Box events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        Index: ['leargas-box'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'event_type.keyword',
                order: {
                  _count: 'desc'
                },

                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Activities',
          field: 'event_type',
          helpInfo: 'User activities that triggered events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-box'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'created_by.login.keyword',
                order: {
                  _count: 'desc'
                },

                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Users',
          field: 'created_by.login',
          helpInfo: 'Users who triggered events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-box'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'source_geoip.country_code2.keyword',
                order: {
                  _count: 'desc'
                },

                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Source Countries',
          field: 'source_geoip.country_code2',
          helpInfo: 'Files related to events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-box'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'source_ip.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Source IPs',
          field: 'source_ip',
          helpInfo: 'Source IPs of event users'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-box'],
        // name: 'Sources',
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'clientName.keyword',
                order: {
                  _count: 'desc'
                },
                size: 5000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'source.login.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5000
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'source_geoip.ip.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 4974
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_phrase: {
              'event_type.keyword': 'FAILED_LOGIN'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Logon Failures'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-box'],
        // name: 'Sources',
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'clientName.keyword',
                order: {
                  _count: 'desc'
                },
                size: 5000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'source.login.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5000
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'source_geoip.ip.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 4974
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_phrase: {
              'event_type.keyword': 'GROUP_ADD_USER'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'New Users'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-box'],
        // name: 'Sources',
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'clientName.keyword',
                order: {
                  _count: 'desc'
                },
                size: 5000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'source.owned_by.login.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5000
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'accessible_by.login.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 5000
                      },
                      aggs: {
                        5: {
                          terms: {
                            field: 'source.parent.name.keyword',
                            order: {
                              _count: 'desc'
                            },
                            size: 5000
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_phrase: {
              'event_type.keyword': 'COLLABORATION_INVITE'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          // hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Collaboration Invitations'
          }
        }
      }
    }
  ];
};

export default box;
