// import { axiosPrivate } from 'api/connection/axios';
import { apiRoutes } from 'config/routeSettings';

class Threats {
  constructor(route) {
    this.route = route;
  }
  getAll(axiosPrivate) {
    return axiosPrivate.get(apiRoutes.THREATS);
  }

  get(id, axiosPrivate) {
    return axiosPrivate.get(`${apiRoutes.THREATS}/${id}`);
  }

  create(data, axiosPrivate) {
    return axiosPrivate.post(apiRoutes.THREATS, data);
  }

  update(id, data, axiosPrivate) {
    return axiosPrivate.put(`${apiRoutes.THREATS}/${id}`, data);
  }

  delete(id, axiosPrivate) {
    return axiosPrivate.delete(`${apiRoutes.THREATS}/${id}`);
  }

  deleteAll(axiosPrivate) {
    return axiosPrivate.delete(apiRoutes.THREATS);
  }
}

export default Threats;
