import uuid from 'react-uuid';

import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

export const OverlayTriggerPopover = ({ PopoverText, children, ...rest }) => {
  const {
    application: { isDark }
  } = useApplication();

  // Return null if PopoverText is not provided
  if (!PopoverText) return null;

  return (
    <OverlayTrigger
      popperConfig={{
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              altAxis: true, // Allows the popper to prevent overflowing along both axes
              boundary: 'viewport' // Can also use 'window' or 'scrollParent'
            }
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom', 'right', 'top', 'left'], // Order of fallbacks
              altBoundary: true
            }
          },
          {
            name: 'offset',
            options: {
              offset: [0, 8] // Adjust to provide spacing from the reference element
            }
          },
          {
            name: 'hideArrow',
            enabled: true,
            phase: 'afterWrite',
            requires: ['arrow'],
            fn({ state }) {
              // Ensure the arrow element does not render
              state.elements.arrow.style.display = 'none';
            }
          }
        ],
        strategy: 'absolute'
      }}
      {...rest}
      id={uuid()}
      overlay={
        <Popover
          id={uuid()}
          show={true}
          className={` p-0  mb-0 text-center shadow-lg ${
            isDark ? 'darktooltip' : 'lighttooltip'
          }`}
          style={{
            maxWidth: '200px',
            maxHeight: '200px'
          }}>
          <Popover.Body className='p-2 fs--1'>
            <p className={`mb-0  `}>{PopoverText}</p>
          </Popover.Body>
        </Popover>
      }>
      {children}
    </OverlayTrigger>
  );
};

OverlayTriggerPopover.propTypes = {
  PopoverText: PropTypes.string.isRequired, // Make this required
  children: PropTypes.node.isRequired // Make children required
};

export default OverlayTriggerPopover;
