import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import AdvancedTableSearchBox from './FieldsTableSearchBox';

/**
 * Renders the filters for the FieldsTable component
 *
 * @param {Object} props - The component props
 * @param {string} props.globalFilter - The global filter value
 * @param {function} props.setGlobalFilter - The function to set the global filter value
 * @param {function} props.setSelectedIndice - The function to set the selected indice value
 * @param {Array} props.indices - The array of indices
 * @param {string} props.selectedIndice - The selected indice value
 * @returns {JSX.Element} The rendered FieldsTableFilters component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const FieldsTableFilters = ({
  globalFilter,
  setGlobalFilter,
  setSelectedIndice,
  indices,
  selectedIndice,
  setFetch
}) => {
  return (
    <>
      <Flex className='pb-2'>
        <Form.Group className='mb-0 me-2'>
          <Form.Select
            size='sm'
            className='form-select'
            aria-label='Default select example'
            onChange={e => {
              setSelectedIndice(e.target.value);
              setFetch(true);
            }}
            value={selectedIndice}>
            {indices &&
              indices?.map((option, index) => (
                <option key={index} value={option.alias}>
                  {option.alias}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        <AdvancedTableSearchBox
          table
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          className='filter-input fs--1'
        />
      </Flex>
    </>
  );
};

FieldsTableFilters.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.any,
  dataTypeLabelPlural: PropTypes.string,
  setSelectedIndice: PropTypes.any,
  indices: PropTypes.any,
  selectedIndice: PropTypes.any,
  setFetch: PropTypes.any
};

export default FieldsTableFilters;
