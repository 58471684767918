import FalconLink from 'components/common/FalconLink';

import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useSavedSearches from 'hooks/admin-contexts/useSavedSearches';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { SavedSearch } from './SavedSearch';

const SavedSearchList = ({
  cardTitle,
  icon,
  linkTo,
  linkTitle,
  slice = 10
}) => {
  const {
    savedSearches: { data },
    loading
  } = useSavedSearches();

  return (
    <Card className='recent-activity-card p-2 border border-card shadow-none'>
      {cardTitle && (
        <h6 className='mb-3 text-nowrap text-700 fw-normal z-index-2 position-relative'>
          {cardTitle}
        </h6>
      )}
      <Card.Body className='ps-0 p-0 pe-1'>
        {data
          ?.slice(0, data.length > slice ? slice : data.length)
          .map((savedSearch, index) => (
            <SavedSearch
              key={savedSearch.id}
              icon={icon}
              savedSearch={savedSearch}
              isLast={
                index ===
                data.slice(0, data.length > slice ? slice : data.length)
                  .length -
                  1
              }
            />
          ))}
        {loading && <LoadingSpinner grow='10' />}{' '}
        <div
          className={`position-absolute all-0 text-center pt-3 align-items-center justify-content-around bg-card-nodata fs--1 ${
            !loading && data?.length === 0 ? 'd-flex' : 'd-none'
          }`}>
          You don't have any recent saved searches
        </div>
      </Card.Body>
      <Card.Footer className='p-0 pb-2 pe-2 text-end'>
        <FalconLink
          title={linkTitle}
          className='px-0 pt-0 fw-medium'
          to={linkTo}
        />
      </Card.Footer>
    </Card>
  );
};

SavedSearchList.propTypes = {
  data: PropTypes.arrayOf(SavedSearch.propTypes.savedSearch),
  cardTitle: PropTypes.string,
  icon: PropTypes.string,
  linkTo: PropTypes.string,
  linkTitle: PropTypes.string,
  slice: PropTypes.number
};

export default SavedSearchList;
