import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useRoles from 'hooks/admin-contexts/useRoles';
import useApplication from 'hooks/useApplication';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
/**
 * role delete Modal
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ModalRoleDelete = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    roles: { loading, deleteRoleModal },
    setRoles
  } = useRoles();
  const { defaultData } = deleteRoleModal;

  const { axiosPrivate } = useAxiosPrivate();

  /**
   *
   * @param {Event} e
   * @async
   * @function
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setRoles(prevState => ({
        ...prevState,
        loading: true
      }));
      let res = await LeargasAPI.Roles.deleteAppRole(
        defaultData?._id,
        axiosPrivate
      );
      if (res instanceof Error) {
        throw res;
      }
      const { message } = res.data;
      toast.success(message);
      setRoles(prevState => ({
        ...prevState,
        fetch: true,
        loading: false,
        deleteRoleModal: { open: false, defaultData: {} }
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
      setRoles(prevState => ({
        ...prevState,
        fetch: true,
        deleteRoleModal: { open: false, defaultData: {} },
        loading: false
      }));
    }
  };

  return (
    <Modal
      centered
      size='lg'
      animation={false}
      backdrop='static'
      keyboard={false}
      show={deleteRoleModal?.open}
      onHide={() =>
        setRoles(prevState => ({
          ...prevState,
          deleteRoleModal: { open: false, defaultData: {} }
        }))
      }
      aria-labelledby='modalRoleAdd'>
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Delete Role: {defaultData?.name}
            <AdvancedPopover
              containerId='resetConfirmModal'
              placement='top'
              popoverText='Delete Role'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
        </Modal.Header>

        <Modal.Body className='p-2'>
          {' '}
          <Form onSubmit={handleSubmit}>
            <Row className='g-3 mb-3'>
              <p>
                Are you sure you want to delete the{' '}
                {String(defaultData?.name).toLowerCase()} role?
              </p>
              <Col sm={12}></Col>
            </Row>

            <Flex justifyContent='end'>
              <Button
                variant='secondary'
                className='me-2'
                onClick={() => {
                  setRoles(prevState => ({
                    ...prevState,
                    deleteRoleModal: { open: false, defaultData: {} }
                  }));
                }}>
                Cancel
              </Button>

              <Button variant='danger' type='submit' className='d-flex'>
                <Flex justifyContent={'center'} alignItems={'center'}>
                  <ButtonSpinner spinning={loading} />
                  <span>{loading ? 'Deleting' : 'Delete'}</span>
                </Flex>
              </Button>
            </Flex>
          </Form>
        </Modal.Body>
      </Flex>
    </Modal>
  );
};

export default ModalRoleDelete;
