import baseUri from 'api/connection/baseUri';
import axios from 'axios';
import { serializeError } from 'helpers/serialization.utils';

/**
 * Creates an Axios instance with custom configuration.
 *
 * @returns {Object} An object containing the Axios instance and a method to make requests.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const createAxiosInstance = () => {
  const instance = axios.create({
    baseURL: baseUri.toString() || 'http://localhost:3000', // Default to localhost
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true // Enables sending cookies with requests
  });

  /**
   * Makes an asynchronous request using the provided configuration.
   *
   * @param {Object} config - The configuration object for the request
   * @param {AbortSignal} [abortSignal=null] - The optional abort signal for the request
   * @returns {Promise<any>} - A promise that resolves to the response data
   * @throws {Error} - If the request fails
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const makeRequest = async (config, abortSignal = null) => {
    try {
      if (abortSignal) config.signal = abortSignal; // Link abort signal if provided
      const response = await instance.request(config);
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // console.warn('Request was cancelled:', serializeError(error));
        return null; // Handle cancel gracefully
      } else {
        console.error('Request failed:', serializeError(error));
        throw error;
      }
    }
  };
  return { instance, makeRequest };
};

/**
 * Function to make a request using the Axios instance.
 *
 * @function makeRequest
 * @returns {Promise} The promise that resolves with the response data
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const { makeRequest: publicMakeRequest } = createAxiosInstance();

/**
 * Creates a private Axios instance with a common authorization header.
 *
 * @param {string} authToken - The authentication token
 * @returns {Object} - An object containing the private Axios instance and a function to make requests
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const createPrivateAxios = authToken => {
  const { instance, makeRequest } = createAxiosInstance();

  // Set common authorization header
  instance.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;

  return { privateAxios: instance, makeRequest };
};
