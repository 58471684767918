import { makeRequest } from 'api/connection/requestAction';

class AppSubscriptionLevels {
  constructor(route) {
    this.route = route;
  }

  async createSubscriptionLevel(subscriptionLevelData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.createSubscriptionLevel.method,
        this.route.createSubscriptionLevel.path,
        subscriptionLevelData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getSubscriptionLevels(axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getSubscriptionLevels.method,
        this.route.getSubscriptionLevels.path
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getSubscriptionLevel(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getSubscriptionLevel.method,
        [this.route.getSubscriptionLevel.path, id].join('/')
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async updateSubscriptionLevel(id, subscriptionLevelData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateSubscriptionLevel.method,
        [this.route.updateSubscriptionLevel.path, id].join('/'),
        subscriptionLevelData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async deleteSubscriptionLevel(id, deleting, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.deleteSubscriptionLevel.method,
        [this.route.deleteSubscriptionLevel.path, id].join('/'),
        {
          deleting
        }
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default AppSubscriptionLevels;
