import React from 'react';
import DatePicker from 'react-datepicker';
import { TimeLabel } from './timeLabel';
import { DateTimePickerInput } from './DateTimePickerInput';
import PropTypes from 'prop-types';

export const DatePickerInput = ({ onChange, selfControlData }) => (
  <DatePicker
    wrapperClassName='position-relative w-100'
    name='publishedAt'
    onChange={onChange}
    selected={selfControlData?.publishedAt}
    timeInputLabel={<TimeLabel className='text-nowrap text-600' />}
    dateFormat='yyyy-MM-dd HH:mm:ss'
    showTimeSelect
    timeFormat='HH:mm'
    timeIntervals={15}
    customInput={<DateTimePickerInput iconRotation={-90} />}
  />
);

DatePickerInput.propTypes = {
  onChange: PropTypes.func,
  selfControlData: PropTypes.object
};
