import AdvancedTable from 'components/common/table/Table';
import AdvancedTableFooter from 'components/common/table/TableFooter';
import AdvancedTablePagination from 'components/common/table/TablePagination';
import AdvancedTableSettingsModal from 'components/common/table/TableSettingsModal';
import AdvancedTableWrapper from 'components/common/table/TableWrapper';
import { apiRoutes } from 'config/routeSettings';
import ClusterContext from 'context/ClusterContext';
import { capitalizeWords } from 'helpers/utils';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import { useESGlobalFilters } from 'hooks/useGlobalFilters';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import ModalPreview from './ModalPreview';
import TableFilters from './TableFilters';

const ElasticsearchAuditLogs = () => {
  const { axiosPrivate } = useAxiosPrivate();
  const {
    esGlobalFilters: { gte, lte }
  } = useESGlobalFilters();
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState('');
  const [settingsModalShow, setSettingsModalShow] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([
    '@timestamp',
    'audit_transport_request_type',
    'audit_category',
    'audit_request_layer',
    'audit_request_origin',
    'audit_request_effective_user',
    'audit_request_remote_address',
    'leargas_actions'
  ]);
  const [logs, setLogs] = useState([]);
  const [columns, setColumns] = useState([]);
  const [total, setTotal] = useState(0);
  const [previewItem, setPreviewItem] = useState({});
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const columnDefaults = [
    {
      accessor: '@timestamp',
      Header: 'Timestamp',
      hasRender: true,
      sortType: 'string',
      Cell: rowData => {
        return moment(rowData.row.original['@timestamp']).format(
          'yyyy-mm-dd hh:mm:ss.SSS'
        );
      },
      cellProps: {
        className: 'align-middle fs--2 text-700 text-nowrap'
      },
      headerProps: {
        className: 'fs--2 text-nowrap'
      },
      disableDisplaySettings: true
    }
  ];

  const columnActions = [
    {
      accessor: 'leargas_actions',
      Header: '',
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <Button
            variant='falcon-light'
            size='sm'
            onClick={() => {
              setPreviewItem(id);
              setShowPreviewModal(true);
            }}>
            <BsSearch />
          </Button>
        );
      },
      cellProps: {
        className: 'text-end actions-cell',
        style: {
          width: '42px'
        }
      },
      hideDisplaySettings: true,
      disableSortBy: true
    }
  ];

  const handleGet = async () => {
    try {
      const reqGte = gte.toISOString();
      const reqLte = lte.toISOString();
      const res = await axiosPrivate.post(
        apiRoutes.CLUSTER_AUDIT_LOGS,
        JSON.stringify({
          gte: reqGte,
          lte: reqLte
        })
      );
      if (res instanceof Error) throw res;
      setLogs(res?.data?.data);
      setTotal(res?.data?.total);
      let newCols = [];
      if (columnDefaults) setColumns(columnDefaults);
      res?.data?.data?.map(item => {
        Object.keys(item).map(key => {
          const index = newCols.findIndex(object => object.accessor === key);
          if (index === -1) {
            newCols.push({
              accessor: key,
              Header: capitalizeWords(key.replaceAll('_', ' ')),
              Cell: rowData => {
                const { value } = rowData.cell;
                return value !== undefined ? (
                  String(value)
                ) : (
                  <span className='text-300'>{'N/A'}</span>
                );
              },
              cellProps: {
                className: 'align-middle fs--2 text-700 text-nowrap'
              },
              headerProps: {
                className: 'fs--2 text-nowrap'
              }
            });
          }
        });
      });
      setColumns([
        ...columnDefaults,
        ...newCols
          .filter(item => {
            return columnDefaults.findIndex(
              object => object.accessor === item.accessor
            );
          })
          .sort((a, b) =>
            a.accessor > b.accessor ? 1 : b.accessor > a.accessor ? -1 : 0
          ),
        ...columnActions
      ]);
      setLoading(false);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setColumns([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (gte && lte) handleGet();
  }, [gte, lte]);

  return (
    <>
      <ClusterContext>
        <Row className='g-3 mb-3'>
          <Col md={12}>
            <Card>
              <Card.Body className='pt-0' style={{ minHeight: '100px' }}>
                {loading ? (
                  <div className='position-absolute d-flex justify-content-center align-items-center pt-4 w-100 navbar-glass-loader'>
                    <Spinner animation='border' />
                  </div>
                ) : (
                  <AdvancedTableWrapper
                    columns={columns}
                    data={logs}
                    sortable
                    pagination
                    perPage={100}>
                    <TableFilters
                      table
                      dataTypeLabel='Log'
                      dataTypeLabelPlural='Logs'
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                      hideNewEntryToggle
                      hideFiltersToggle
                      settingsModalShow={settingsModalShow}
                      setSettingsModalShow={setSettingsModalShow}
                    />
                    <AdvancedTableSettingsModal
                      columns={columns}
                      settingsModalShow={settingsModalShow}
                      setSettingsModalShow={setSettingsModalShow}
                      visibleColumns={visibleColumns}
                      setVisibleColumns={setVisibleColumns}
                    />
                    <ModalPreview
                      previewItem={previewItem}
                      showPreviewModal={showPreviewModal}
                      setShowPreviewModal={setShowPreviewModal}
                    />
                    <AdvancedTable
                      table
                      visibleColumns={visibleColumns}
                      showPreviewModal={showPreviewModal}
                      setShowPreviewModal={setShowPreviewModal}
                    />
                    <div className='mt-3 d-flex justify-content-between'>
                      <AdvancedTableFooter
                        rowCount={total}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection={100}
                        rowsPerPageOptions={[100, 250, 500]}
                      />
                      <AdvancedTablePagination table />
                    </div>
                  </AdvancedTableWrapper>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ClusterContext>
    </>
  );
};

ElasticsearchAuditLogs.propTypes = {
  hideNewEntryToggle: PropTypes.bool,
  hideFiltersToggle: PropTypes.bool
};

export default ElasticsearchAuditLogs;
