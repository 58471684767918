import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { getColor } from 'helpers/utils';

/**
 *
 * @param {object} props
 * @param {Function} props.getTableProps
 * @param {Array} props.headers
 * @param {Array} props.page
 * @param {Function} props.prepareRow
 * @param {String} props.headerClassName
 * @param {String} props.rowClassName
 * @param {Function} props.tableProps
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const BookmarksTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  tableProps
}) => {
  return (
    <div className='table-responsive '>
      <Table
        responsive
        size='sm'
        {...getTableProps(tableProps)}
        className='simplebar-scroll-content'
        data-simplebar>
        <thead className={headerClassName}>
          <tr>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(
                  column.getSortByToggleProps(column.headerProps)
                )}>
                {column.render('Header')}
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className='sort desc' />
                    ) : (
                      <span className='sort asc' />
                    )
                  ) : (
                    <span className='sort' />
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            let rowClassName = row.original?.isDeleted
              ? 'soft-danger'
              : 'transparent';
            return (
              <tr key={i} className={rowClassName} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      {...cell.getCellProps(cell.column.cellProps)}
                      style={
                        i === page.length - 1
                          ? {
                              border: 'none',
                              backgroundColor: `${getColor(rowClassName)}`
                            }
                          : { backgroundColor: `${getColor(rowClassName)}` }
                      }>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

BookmarksTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object
};

export default BookmarksTable;
