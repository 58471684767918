import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Flex from 'components/common/Flex';
import useAuthentication from 'hooks/useAuthentication';
import SimpleBarReact from 'simplebar-react';
import VisualizationsQueryModal from '../../InspectorModal';
// import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconLink from 'components/common/FalconLink';
import SimpleSpinner from 'components/utilities/AppSpinner/smallSpinner.js';
import routes from 'config/routeSettings';
import { Card, Dropdown, Modal, Table } from 'react-bootstrap';
import TableRow from './tablerow.js';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const { default: CardDropdown } = require('components/common/CardDropdown');

/**
 *
 * @returns {JSX.Element} The rendered button component
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const TableSimple = ({
  wrapperLoader,
  generalquerydata,
  cardTitle,
  footerLink = false
}) => {
  const [datatable, setDataTable] = useState([]);
  const [queryDiscoverModal, setQueryDiscover] = useState(false);

  const {
    authentication: { isAdmin }
  } = useAuthentication();

  useEffect(() => {
    if (
      generalquerydata?.data?.aggregations &&
      generalquerydata?.data?.aggregations['2']?.buckets
    ) {
      let newdatatable = generalquerydata?.data?.aggregations['2']?.buckets;
      let sortedarray = newdatatable.sort(
        (a, b) => b['1'].value - a['1'].value
      );
      setDataTable(sortedarray);
    }
  }, [generalquerydata]);

  return (
    <>
      <Card className='h-100 shadow-none border'>
        <Card.Body className='p-2 pb-0'>
          <h6 className='mb-2  text-nowrap text-700 fw-normal z-index-2 position-relative'>
            {cardTitle}
            {wrapperLoader && (
              <span className='fw-light position-absolute top-0 end-0 z-index-2'>
                <SimpleSpinner size='sm' />
              </span>
            )}
          </h6>

          {!wrapperLoader && isAdmin && (
            <Flex
              justifyContent='center'
              alignContent='middle'
              style={{ zIndex: '222' }}
              className={'position-absolute top-0 end-0 cursor-pointer'}>
              <CardDropdown style={{ zIndex: 9999, position: 'absolute' }}>
                <div className='py-2'>
                  <Dropdown.Item
                    onClick={() => {
                      setQueryDiscover(true);
                    }}>
                    {/* <FontAwesomeIcon icon='pencil' className='me-2' /> */}
                    Inspect
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            </Flex>
          )}

          <div className='position-relative'>
            <SimpleBarReact>
              <Table className='table-dashboard mb-1 fs--2'>
                <tbody>
                  {datatable.map((item, index) => (
                    <TableRow
                      key={index}
                      item={item}
                      index={index}
                      total={datatable
                        .map(item => item['1'].value)
                        .reduce((a, b) => a + b, 0)}
                      isLast={index === datatable.length - 1}
                    />
                  ))}
                </tbody>
              </Table>
            </SimpleBarReact>
          </div>
        </Card.Body>

        {footerLink && (
          <Card.Footer className='py-0 pb-2 text-end'>
            <FalconLink
              title='Cloud Integrations'
              className='px-0 fw-medium fs--1 text-decoration-none'
              to={routes.ARTIFACTS_CLOUD}
            />
          </Card.Footer>
        )}

        <div
          className={`position-absolute all-0 align-items-center justify-content-around bg-card-nodata fs--1 ${
            !wrapperLoader && (!datatable || datatable.length === 0)
              ? 'd-flex'
              : 'd-none'
          }`}>
          No results matching search criteria
        </div>
        <div
          className={`position-absolute all-0 align-items-center justify-content-around bg-card-spinner ${
            wrapperLoader ? 'd-flex' : 'd-none'
          } ${wrapperLoader && 'bg-card-spinner-refresh z-index-1'}`}></div>
      </Card>
      <Modal
        centered
        size={'lg'}
        animation={false}
        show={queryDiscoverModal}
        onHide={() => setQueryDiscover(false)}
        aria-labelledby='modalTeamAdd'
        // contentClassName={addTeamModal?.fullScreen && 'border-0 border-top-left-radius-0 border-top-right-radius-0 h-100'}
        // dialogClassName={addTeamModal?.fullScreen && 'position-absolute all-0 fullscreen-modal-dialog scrollbar overflow-x-hidden'}
      >
        <VisualizationsQueryModal allOperations={[generalquerydata]} />
      </Modal>
    </>
  );
};

TableSimple.propTypes = {
  cardTitle: PropTypes.string,
  footerLink: PropTypes.bool,
  wrapperLoader: PropTypes.bool,
  generalquerydata: PropTypes.object
};

export default TableSimple;

{
  /* <td className={classNames('align-middle px-4', { 'border-0': isLast })} style={{ width: '1%' }}>
        <SoftBadge
          bg={classNames({
            success: status === 'Completed',
            warning: status === 'Pending',
            danger: status === 'Rejected'
          })}
          className='fs--1 w-100'>
          {status}
        </SoftBadge>
      </td> */
}

{
  /* <td
        className={classNames('align-middle ps-4 pe-1', { 'border-0': isLast })}
        style={{ width: '130px', minWidth: '130px' }}>
        <Form.Select size='sm' className='px-2 bg-transparent'>
          <option value='action'>Action</option>
          <option value='archive'>Archive</option>
          <option value='delete'>Delete</option>
        </Form.Select>
      </td> */
}
