import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import BookmarksWrapper from './BookmarksWrapper';
const Reports = () => {
  return (
    <>
      <WidgetSectionTitle
        title='Bookmarks'
        transform='down-3'
        className='mb-2'
      />
      <BookmarksWrapper />
    </>
  );
};

export default Reports;
