import React from 'react';
import AddItemButton from 'components/common/AddItemButton';
import Flex from 'components/common/Flex';
import { Card, Col, Form, Row } from 'react-bootstrap';

const MenuSelectCard = () => {
  return (
    <Card>
      <Card.Body>
        <h5 className='mb-2 fs--1'>Select a menu to edit</h5>
        <Row className='g-3'>
          <Col md={6} className='align-items-center'>
            <Form.Select size='sm' className='fs--1'>
              <option>Artifacts</option>
              <option>Manage</option>
              <option>System</option>
              <option>Account</option>
            </Form.Select>
          </Col>

          <Col md={6} className='align-items-center'>
            <Flex>
              <AddItemButton label='menu' />
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default MenuSelectCard;
