import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import Moment from 'react-moment';
import { v4 as uuid } from 'uuid';
// import useApplication from 'hooks/useApplication';
// import { toast } from 'react-toastify';
import { OverlayTrigger, Popover } from 'react-bootstrap';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import { useESGlobalFilters } from 'hooks/useGlobalFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { themeChartColors } from 'helpers/chart.utils';
import { convertCountryNameToISO } from 'helpers/locale.utils.js';
import { Button } from 'react-bootstrap';
import { CombinedFilters } from './CombinedFilters&connectedUtils';
// import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';
// import { formatQuery } from 'react-querybuilder';
// import { opera } from 'is_js';
export function updateObjectsWithMissingAccessors(stringArray, objectsArray) {
  // Function to check if an accessor exists in the objects array
  function hasAccessor(accessor) {
    return objectsArray.some(obj => obj.accessor === accessor);
  }

  // Iterate through stringArray and add missing accessors to objectsArray
  stringArray.forEach(string => {
    if (!hasAccessor(string)) {
      objectsArray.push({
        accessor: string,
        Header: string,
        sortable: false,
        disableSortBy: true,
        hasRender: true,
        Cell: () => {
          return (
            <>
              <div className='fs--2 cursor-pointer text-start'>-</div>
            </>
          );
        },
        cellProps: {
          className: 'fs--2 text-700 text-nowrap'
        },
        headerProps: {
          className: 'fs--2 text-nowrap'
        }
      });
    }
  });

  // Return the updated objectsArray
  return objectsArray;
}

// const possibleChartColors = [
//   '#2c7be5',
//   '#2780cc',
//   '#318aed',
//   '#459cfd',
//   '#1e65b3', // Blue variations
//   '#727cf5',
//   '#6368e6',
//   '#818cf6',
//   '#959df6',
//   '#5c5ef8', // Indigo variations
//   '#6b5eae',
//   '#5d5599',
//   '#7a6abc',
//   '#8977c9',
//   '#4b447f', // Purple variations
//   '#ff4c82',
//   '#ff638d',
//   '#ff3977',
//   '#ff205c',
//   '#ff748e', // Pink variations
//   '#e63757',
//   '#cc314e',
//   '#f84d62',
//   '#f2616c',
//   '#ff7075', // Red variations
//   '#ff7a39',
//   '#ff6930',
//   '#ff8b42',
//   '#ff9c4b',
//   '#ffa954', // Orange variations
//   '#e7cc61',
//   '#e0c356',
//   '#edcf6c',
//   '#f3d473',
//   '#fac97a', // Yellow variations
//   '#60b848',
//   '#56aa42',
//   '#69c44e',
//   '#7acf55',
//   '#49a33c', // Green variations
//   '#00b9ff',
//   '#00adff',
//   '#00cbff',
//   '#00d7ff',
//   '#0093ff' // Cyan variations
// ];

export const getRandomColor = () => {
  const randomColors = themeChartColors;

  return randomColors;
};

export function invertOperator(operator) {
  switch (operator) {
    case 'is':
      return 'is not';
    case 'is not':
      return 'is';
    case 'is one of':
      return 'is not one of';
    case 'is not one of':
      return 'is one of';
    case 'exists':
      return 'does not exist';
    case 'does not exist':
      return 'exists';
    default:
      // If the operator is not recognized, return null or handle as needed
      return null;
  }
}

// export const getRandomColor = () => {
//   const letters = '0123456789ABCDEF';
//   let color = '#';
//   for (let i = 0; i < 6; i++) {
//     color += letters[Math.floor(Math.random() * 16)];
//   }
//   return color;
// };

export const toggletempDisable = (arr, index) => {
  const transformedArray = [...arr];

  if (transformedArray[index] && transformedArray[index]?.tempDisable) {
    delete transformedArray[index].tempDisable;
  } else {
    transformedArray[index] = {
      ...transformedArray[index],
      tempDisable: true
    };
  }

  return transformedArray;
};

export const togglePin = (arr, index) => {
  const transformedArray = [...arr];

  if (transformedArray[index] && transformedArray[index].pinned) {
    delete transformedArray[index].pinned;
  } else {
    transformedArray[index] = {
      ...transformedArray[index],
      pinned: true
    };
  }

  return transformedArray;
};

export const removeField = (arr, index) => {
  const transformedArray = [...arr];

  // Remove the object with the specified field
  const filteredArray = [
    ...transformedArray.slice(0, index),
    ...transformedArray.slice(index + 1)
  ];

  return filteredArray;
};

export const setFullControlQuery = (query, setESGlobalFilters) => {
  let newwayof = {
    bool: {}
  };
  let MustMustnotQueryArrays = generateMustMustnotShouldArrays(query.rules);

  if (MustMustnotQueryArrays?.must.length > 0) {
    newwayof.bool['must'] = [...MustMustnotQueryArrays.must];
  }
  if (MustMustnotQueryArrays?.must_not.length > 0) {
    newwayof.bool['must_not'] = [...MustMustnotQueryArrays.must_not];
  }
  if (MustMustnotQueryArrays?.filterrulearray.length > 0) {
    newwayof.bool['filter'] = [...MustMustnotQueryArrays.filterrulearray];
  }

  if (
    MustMustnotQueryArrays?.must_not.length === 0 &&
    MustMustnotQueryArrays?.must.length === 0
  ) {
    newwayof === '';
  }
  setESGlobalFilters(prevState => ({
    ...prevState,
    fullControlQuery: newwayof ? newwayof : ''
  }));
};

export function generateMustMustnotShouldArrays(filters) {
  let must = [];
  let must_not = [];
  let filterrulearray = [];

  // Remove 'or' and 'and' entries
  const removedAndOr = filters?.filter(
    filter => filter !== 'or' && filter !== 'and'
  );
  const cleanedFilters = removedAndOr.filter(filter => !filter.tempDisable);
  // Split filters based on operator
  const equalFilters = cleanedFilters?.filter(
    filter => filter.operator === 'is'
  );
  const notEqualFilters = cleanedFilters?.filter(
    filter => filter.operator === 'is not'
  );
  const existsFilters = cleanedFilters?.filter(
    filter => filter.operator === 'exists'
  );
  const notexistsFilters = cleanedFilters?.filter(
    filter => filter.operator === 'does not exist'
  );
  const isoneof = cleanedFilters?.filter(
    filter => filter.operator === 'is one of'
  );
  const isnotoneof = cleanedFilters?.filter(
    filter => filter.operator === 'is not one of'
  );

  // Split 'is' array based on occurrences of fields
  // const singleOccurrenceFields = {};
  // const multipleOccurrenceFields = {};

  // Process 'is not' filters
  notEqualFilters?.forEach(filter => {
    const { field, value } = filter;
    const queryObject = { match_phrase: {} };
    queryObject.match_phrase[field] = value;
    must_not.push(queryObject);
  });
  equalFilters?.forEach(filter => {
    const { field, value } = filter;
    const queryObject = { match_phrase: {} };
    queryObject.match_phrase[field] = value;
    filterrulearray.push(queryObject);
  });

  // Process single occurrence fields

  notexistsFilters?.forEach(filter => {
    const { field } = filter;
    const queryObject = { exists: { field: field } };
    // queryObject.match_phrase[field] = value;
    must_not.push(queryObject);
  });
  existsFilters?.forEach(filter => {
    const { field } = filter;
    const queryObject = { exists: { field: field } };
    // queryObject.match_phrase[field] = value;
    filterrulearray.push(queryObject);
  });

  // Process single occurrence fields
  isnotoneof?.forEach(filter => {
    const { field, value } = filter;
    let shouldbearray = [];
    value?.map(item => {
      shouldbearray.push({
        match_phrase: {
          [field]: item?.value
        }
      });
    });
    const queryObject = {
      bool: {
        should: [...shouldbearray],
        minimum_should_match: 1
      }
    };
    // queryObject.match_phrase[field] = value;
    must_not.push(queryObject);
  });

  isoneof?.forEach(filter => {
    const { field, value } = filter;
    let shouldbearray = [];
    value?.map(item => {
      shouldbearray.push({
        match_phrase: {
          [field]: item?.value
        }
      });
    });
    const queryObject = {
      bool: {
        should: [...shouldbearray],
        minimum_should_match: 1
      }
    };
    filterrulearray.push(queryObject);
  });

  return { must, must_not, filterrulearray };
}

export const setInsideFiltersModal = (
  query,

  setESGlobalFilters,
  updateGlobalFilters
) => {
  let storableRules = query?.rules?.filter(filter => filter?.pinned === true);

  updateGlobalFilters({
    query: { id: 1, rules: storableRules ? [...storableRules] : [] }
  });
  let newwayof = {
    bool: {}
  };

  let MustMustnotQueryArrays = generateMustMustnotShouldArrays(query.rules);

  if (MustMustnotQueryArrays?.must.length > 0) {
    newwayof.bool['must'] = [...MustMustnotQueryArrays.must];
  }
  if (MustMustnotQueryArrays?.must_not.length > 0) {
    newwayof.bool['must_not'] = [...MustMustnotQueryArrays.must_not];
  }
  if (MustMustnotQueryArrays?.filterrulearray.length > 0) {
    newwayof.bool['filter'] = [...MustMustnotQueryArrays.filterrulearray];
  }

  if (
    MustMustnotQueryArrays?.must_not.length === 0 &&
    MustMustnotQueryArrays?.must.length === 0 &&
    MustMustnotQueryArrays?.should?.length === 0
  ) {
    newwayof === '';
  }

  setESGlobalFilters(prevState => ({
    ...prevState,
    fullControlQuery: newwayof ? newwayof : '',
    highlightedGlobalIndex: null
  }));
  // setESGlobalFilters(prevState => ({
  //   ...prevState,
  //   fullControlQuery: generatedFullControlQuery !== undefined ? generatedFullControlQuery : '',
  //   highlightedGlobalIndex: null
  // }));
};

// function generateMustMustnotQueryArrays(filters) {
//   let must = [];
//   let must_not = [];

//   filters.forEach((filter, index) => {
//     if (filter === 'or') {
//       // Skip 'or' as it is used to differentiate between must and must_not
//       return;
//     }

//     const { field, operator, value } = filter;

//     const queryObject = {};
//     queryObject[field] = value;

//     if (operator === 'is') {
//       must.push({ match_phrase: queryObject });
//     } else if (operator === 'is not') {
//       must_not.push({ match_phrase: queryObject });
//     }
//   });

//   return { must, must_not };
// }

export const convertFields = fields => {
  const result = {};
  for (let key in fields) {
    const parts = key.split('.');
    let current = result;
    for (let i = 0; i < parts.length; i++) {
      if (current && !current[parts[i]]) {
        if (i === parts.length - 1) {
          current[parts[i]] = fields[key];
        } else {
          current[parts[i]] = {
            type: '!group',
            label: parts[i],
            subfields: {}
          };
        }
      }
      if (current[parts[i]].subfields) {
        current = current[parts[i]].subfields;
      }
    }
  }
  return result;
};

export const AddDeleteValueForPie = (obj, arrtwo, field) => {
  const arr1 = [obj.selected];

  let arr2 = [...arrtwo];

  for (let i = arr2.length - 1; i >= 0; i--) {
    if (typeof arr2[i] === 'object') {
      const key = Object.keys(arr2[i])[0];
      if (arr1[0][key]) {
        arr2.splice(i, 1);
      }
    }
  }

  for (const [key, value] of Object.entries(arr1[0])) {
    if (!value) {
      const newObj = {
        field: field,
        operator: 'is',
        value: key
      };
      const existingObjIndex = arr2.findIndex(
        obj => obj.field === newObj.field && obj.value === newObj.value
      );
      if (existingObjIndex !== -1) {
        arr2.splice(existingObjIndex, 1);
      } else {
        arr2.push(newObj);
      }
    }
  }

  // This code creates a new object called newObj with the same properties as the original code. It then uses the findIndex() method to search for an existing object in arr2 with the same field and value properties. If an existing object is found, it is removed from arr2 using the splice() method. Finally, the new object is added to arr2 using the push() method.

  // adding and/or
  const outputArr = [];
  arr2 = arr2.filter(item => item !== 'and' && item !== 'or');

  for (let i = 0; i < arr2.length; i++) {
    if (i > 0) {
      const prevField = arr2[i - 1].field;
      const currField = arr2[i].field;

      if (prevField === currField) {
        outputArr.push('or');
      } else {
        outputArr.push('and');
      }
    }

    outputArr.push(arr2[i]);
  }
  return outputArr;
};
export const NewPieDeleteValueForPie = (newItem, arrtwo) => {
  let arr2 = [...arrtwo];

  const existsIndex = arr2.findIndex(
    item =>
      item.field === newItem.field &&
      item.operator === newItem.operator &&
      item.value === newItem.value
  );
  if (existsIndex !== -1) {
    arr2.splice(existsIndex, 1);
  } else {
    arr2.push(newItem);
  }

  const outputArr = [];
  arr2 = arr2.filter(item => item !== 'and' && item !== 'or');

  for (let i = 0; i < arr2.length; i++) {
    if (i > 0) {
      const prevField = arr2[i - 1].field;
      const currField = arr2[i].field;

      if (prevField === currField) {
        outputArr.push('or');
      } else {
        outputArr.push('and');
      }
    }

    outputArr.push(arr2[i]);
  }
  return outputArr;
};
export const NewPieDeleteValueForSankey = (newItem, arrtwo) => {
  let arr2 = [...arrtwo];

  const existsIndex = arr2.findIndex(
    item =>
      item.field === newItem.field &&
      item.operator === newItem.operator &&
      item.value === newItem.value
  );
  if (existsIndex !== -1) {
    arr2.splice(existsIndex, 1);
  } else {
    arr2.push(newItem);
  }

  const outputArr = [];
  arr2 = arr2.filter(item => item !== 'and' && item !== 'or');

  for (let i = 0; i < arr2.length; i++) {
    if (i > 0) {
      const prevField = arr2[i - 1].field;
      const currField = arr2[i].field;

      if (prevField === currField) {
        outputArr.push('or');
      } else {
        outputArr.push('and');
      }
    }

    outputArr.push(arr2[i]);
  }
  return outputArr;
};
export const AddDeleteValueFortable = (obj, arrtwo, operator) => {
  let arr2 = [...arrtwo];

  for (let i = arr2.length - 1; i >= 0; i--) {
    if (typeof arr2[i] === 'object') {
      const key = Object.keys(arr2[i])[0];
      if (key === obj.keyName) {
        // arr2.splice(i, 1);
        arr2[i].operator =
          arr2[i].operator === 'is'
            ? 'is not'
            : arr2[i].operator === 'is not'
            ? 'is'
            : arr2[i].operator === 'exists'
            ? 'does not exist'
            : arr2[i].operator === 'does not exist'
            ? 'exists'
            : arr2[i].operator === 'is one of'
            ? 'is not one of'
            : arr2[i].operator === 'is not one of'
            ? 'is one of'
            : arr2[i].operator;
      }
    }
  }

  const newObj = {
    field: obj.keyName,
    operator: operator,
    value: obj.value
  };
  const existingObjIndex = arr2.findIndex(
    obj1 => obj1.field === newObj.field && obj1.value === newObj.value
  );
  if (existingObjIndex !== -1) {
    // arr2.splice(existingObjIndex, 1);
    arr2[existingObjIndex].operator =
      arr2[existingObjIndex].operator === 'is'
        ? 'is not'
        : arr2[existingObjIndex].operator === 'is not'
        ? 'is'
        : arr2[existingObjIndex].operator === 'exists'
        ? 'does not exist'
        : arr2[existingObjIndex].operator === 'does not exist'
        ? 'exists'
        : arr2[existingObjIndex].operator === 'is one of'
        ? 'is not one of'
        : arr2[existingObjIndex].operator === 'is not one of'
        ? 'is one of'
        : arr2[existingObjIndex].operator;
  } else {
    arr2.push(newObj);
  }

  // This code creates a new object called newObj with the same properties as the original code. It then uses the findIndex() method to search for an existing object in arr2 with the same field and value properties. If an existing object is found, it is removed from arr2 using the splice() method. Finally, the new object is added to arr2 using the push() method.

  // adding and/or
  const outputArr = [];
  arr2 = arr2.filter(item => item !== 'and' && item !== 'or');

  for (let i = 0; i < arr2.length; i++) {
    if (i > 0) {
      const prevField = arr2[i - 1].field;
      const currField = arr2[i].field;

      if (prevField === currField) {
        outputArr.push('or');
      } else {
        outputArr.push('and');
      }
    }

    outputArr.push(arr2[i]);
  }
  return outputArr;
};
export const AddDeleteValueFortableMap = (obj, arrtwo, operator) => {
  let arr2 = [...arrtwo];

  for (let i = arr2.length - 1; i >= 0; i--) {
    if (typeof arr2[i] === 'object') {
      const key = Object.keys(arr2[i])[0];
      if (key === obj.keyName) {
        // arr2.splice(i, 1);
        arr2[i].operator =
          arr2[i].operator === 'is'
            ? 'is not'
            : arr2[i].operator === 'is not'
            ? 'is'
            : arr2[i].operator === 'exists'
            ? 'does not exist'
            : arr2[i].operator === 'does not exist'
            ? 'exists'
            : arr2[i].operator === 'is one of'
            ? 'is not one of'
            : arr2[i].operator === 'is not one of'
            ? 'is one of'
            : arr2[i].operator;
      }
    }
  }

  const newObj = {
    field: obj.keyName,
    operator: operator,
    value: obj.value
  };
  const existingObjIndex = arr2.findIndex(
    obj1 =>
      obj1.field === newObj.field &&
      JSON.stringify(obj1.value) === JSON.stringify(newObj.value)
  );
  if (existingObjIndex !== -1) {
    arr2[existingObjIndex].operator =
      arr2[existingObjIndex].operator === 'is'
        ? 'is not'
        : arr2[existingObjIndex].operator === 'is not'
        ? 'is'
        : arr2[existingObjIndex].operator === 'exists'
        ? 'does not exist'
        : arr2[existingObjIndex].operator === 'does not exist'
        ? 'exists'
        : arr2[existingObjIndex].operator === 'is one of'
        ? 'is not one of'
        : arr2[existingObjIndex].operator === 'is not one of'
        ? 'is one of'
        : arr2[existingObjIndex].operator;
  } else {
    arr2.push(newObj);
  }

  // This code creates a new object called newObj with the same properties as the original code. It then uses the findIndex() method to search for an existing object in arr2 with the same field and value properties. If an existing object is found, it is removed from arr2 using the splice() method. Finally, the new object is added to arr2 using the push() method.

  // adding and/or
  const outputArr = [];
  arr2 = arr2.filter(item => item !== 'and' && item !== 'or');

  for (let i = 0; i < arr2.length; i++) {
    if (i > 0) {
      const prevField = arr2[i - 1].field;
      const currField = arr2[i].field;

      if (prevField === currField) {
        outputArr.push('or');
      } else {
        outputArr.push('and');
      }
    }

    outputArr.push(arr2[i]);
  }
  return outputArr;
};

// json loginc fiunctino
const mapOperator = operator => {
  switch (operator) {
    case 'is':
      return '==';
    case 'is not':
      return 'is not';
    case '>':
      return '>';
    case '>=':
      return '>=';
    case '<':
      return '<';
    case '<=':
      return '<=';
    case 'contains':
      return 'in';
    case 'does_not_contain':
      return 'not_in';
    default:
      return '';
  }
};

export const generateConditionArray = conditions => {
  // Filter out existing 'and' and 'or' entries
  const filteredConditions = conditions.filter(
    condition => condition !== 'and' && condition !== 'or'
  );

  // If there are no conditions or only one condition, return the original array
  if (filteredConditions.length <= 1) {
    return filteredConditions;
  }

  const finalConditionArray = [];

  // Iterate through filtered conditions to insert 'and' and 'or' entries
  for (let i = 0; i < filteredConditions.length; i++) {
    // If it's not the first condition
    if (i > 0) {
      // If the current field is different from the previous field, insert 'and'
      if (filteredConditions[i].field !== filteredConditions[i - 1].field) {
        finalConditionArray.push('and');
      } else {
        // If the current field is the same as the previous field, insert 'or'
        finalConditionArray.push('or');
      }
    }

    // Add the current condition
    finalConditionArray.push(filteredConditions[i]);
  }

  return finalConditionArray;
};
const getFieldValue = field => {
  if (field.includes('.')) {
    const [firstField, ...restFields] = field.split('.');
    return { var: [firstField, ...restFields.map(f => `${f}`)] };
  } else {
    return { var: field };
  }
};

const convertRuleToJSONLogic = rule => {
  if (rule?.field?.includes('.')) {
    const [firstField, ...restFields] = rule.field.split('.') || [];
    const varField = { var: [firstField, ...restFields.map(f => `${f}`)] };
    return { [mapOperator(rule.operator)]: [varField, rule.value] };
  } else {
    return {
      [mapOperator(rule.operator)]: [getFieldValue(rule.field), rule.value]
    };
  }
};
export const convertRulesToJSONLogic = rules => {
  const jsonLogic = {};

  if (rules.length === 1) {
    return convertRuleToJSONLogic(rules[0]);
  }

  for (let i = 1; i < rules.length; i += 2) {
    const rule = rules[i - 1];
    const operator = rules[i];
    const convertedRule = convertRuleToJSONLogic(rule);

    if (!jsonLogic[operator]) {
      jsonLogic[operator] = [convertedRule];
    } else {
      jsonLogic[operator].push(convertedRule);
    }
  }

  return jsonLogic;
};

// json logic function
export const existingFilterCheck = (value, arrtwo, field) => {
  let arr2 = [...arrtwo];

  const newObj = {
    field: field,
    operator: 'is',
    value: value
  };
  const existingObjIndex = arr2.findIndex(
    obj => obj.field === newObj.field && obj.value === newObj.value
  );
  if (existingObjIndex !== -1) {
    return true;
  } else {
    return false;
  }
};

export const AddDeleteValueForMap = (value, arrtwo, field) => {
  let arr2 = [...arrtwo];
  let isoCountry = convertCountryNameToISO(value);
  const newObj = {
    field: field,
    operator: 'is one of',
    value: [
      { label: value, value: value },
      { label: isoCountry, value: isoCountry }
    ]
  };
  const existingObjIndex = arr2.findIndex(
    obj =>
      obj.field === newObj.field &&
      JSON.stringify(obj.value) === JSON.stringify(newObj.value)
  );
  if (existingObjIndex !== -1) {
    arr2.splice(existingObjIndex, 1);
  } else {
    arr2.push(newObj);
  }

  // adding and/or
  const outputArr = [];
  arr2 = arr2.filter(item => item !== 'and' && item !== 'or');

  for (let i = 0; i < arr2.length; i++) {
    if (i > 0) {
      const prevField = arr2[i - 1].field;
      const currField = arr2[i].field;

      if (prevField === currField) {
        outputArr.push('or');
      } else {
        outputArr.push('and');
      }
    }

    outputArr.push(arr2[i]);
  }
  return outputArr;
};

export const addValuesToQuery = (value, arrtwo, field, operator) => {
  let arr2 = [...arrtwo];
  const newObj = {
    field: field,
    operator: operator,
    value: value
  };

  arr2.push(newObj);
  return arr2;
  // Check if the value already exists in the array
  // const existingObjIndex = arr2.findIndex(
  //   obj => obj.field === field && obj.value === value
  // );

  // Replace the value if it exists, otherwise add the new value
  // if (existingObjIndex !== -1) {
  //   arr2[existingObjIndex] = {
  //     field: field,
  //     operator: operator,
  //     value: value
  //   };
  // } else {
  //   const newObj = {
  //     field: field,
  //     operator: operator,
  //     value: value
  //   };

  //   arr2.push(newObj);
  // }

  // Adding and/or
  // const outputArr = [];
  // arr2 = arr2.filter(item => item !== 'and' && item !== 'or');

  // for (let i = 0; i < arr2.length; i++) {
  //   if (i > 0) {
  //     const prevField = arr2[i - 1].field;
  //     const currField = arr2[i].field;

  //     if (prevField === currField) {
  //       outputArr.push('or');
  //     } else {
  //       outputArr.push('and');
  //     }
  //   }

  //   outputArr.push(arr2[i]);
  // }
};

export const AddDeleteValueFornewMetricFilters = (
  value,
  arrtwo,
  field,
  operator
) => {
  let arr2 = [...arrtwo];

  const newObj = {
    field: field,
    operator: operator,
    value: value
  };
  const existingObjIndex = arr2.findIndex(
    obj => obj.field === newObj.field && obj.value === newObj.value
  );
  if (existingObjIndex !== -1) {
    arr2.splice(existingObjIndex, 1);
  } else {
    arr2.push(newObj);
  }

  // adding and/or
  const outputArr = [];
  arr2 = arr2.filter(item => item !== 'and' && item !== 'or');

  for (let i = 0; i < arr2.length; i++) {
    if (i > 0) {
      const prevField = arr2[i - 1].field;
      const currField = arr2[i].field;

      if (prevField === currField) {
        outputArr.push('or');
      } else {
        outputArr.push('and');
      }
    }

    outputArr.push(arr2[i]);
  }
  return outputArr;
};

export const flipOperatorForField = (value, arr, field) => {
  return arr.map(item => {
    if (
      item &&
      typeof item === 'object' &&
      item.field === field &&
      item.value === value
    ) {
      const flippedOperator = item.operator === 'is' ? 'is not' : 'is';
      return {
        ...item,
        operator: flippedOperator
      };
    }
    return item;
  });
};

export function extractFieldsAndValues(query) {
  const result = [];

  const processClauses = (clauses, operator, negate = false) => {
    if (clauses && clauses.length > 0) {
      clauses.forEach(clause => {
        for (const key in clause) {
          if (Array.isArray(clause[key])) {
            processClauses(clause[key], operator, negate);
          } else {
            const field = Object.keys(clause[key])[0];
            const value = clause[key][field];
            // let removeKeywordfield = field?.replace('.keyword', '');
            result.push({
              field: field?.replace('.keyword', ''),
              value,
              operator
            });
          }
        }
      });
    }
  };

  if (query?.must) {
    processClauses(query.must, 'is', false);
  }

  if (query?.must_not) {
    processClauses(query.must_not, 'isNot', true);
  }
  const filteredData = result.filter(item => item.field !== undefined);
  return filteredData;
}

export function extractFieldsAndValuesWithFilterIcons(query) {
  if (query === null) {
    return false; // or false based on your specific requirement when query is null
  }

  let showFilterIcon = true;

  const processClauses = (clauses, operator, negate = false) => {
    if (clauses && clauses.length > 0) {
      clauses.forEach(clause => {
        for (const key in clause) {
          if (Array.isArray(clause[key])) {
            processClauses(clause[key], operator, negate);
          } else {
            const field = Object.keys(clause[key])[0];
            const value = clause[key][field];

            // Check for undefined field or value
            if (
              field === undefined ||
              value === undefined ||
              (typeof value === 'string' && value?.includes(':'))
            ) {
              showFilterIcon = false;
            }
          }
        }
      });
    }
  };

  if (query?.must) {
    processClauses(query.must, 'is', false);
  }

  if (query?.must_not) {
    processClauses(query.must_not, 'is not', true);
  }

  return showFilterIcon;
}

export function compareArraysforcolor(x, y) {
  const isEqual = (objX, objY) => {
    return (
      objX?.field?.accessor === objY?.field &&
      JSON.stringify(objX?.value) === JSON.stringify(objY?.value) &&
      objX?.operator === objY?.operator
      // (objX.negate === undefined || objX.negate === objY.negate)
    );
  };

  const isOpposite = (objX, objY) => {
    return (
      objX?.field?.accessor === objY?.field &&
      JSON.stringify(objX?.value) === JSON.stringify(objY?.value) &&
      objX?.operator !== objY?.operator &&
      (objX?.negate === undefined || objX?.negate !== objY?.negate)
    );
  };

  const allPresent = y.every(yItem => x.some(xItem => isEqual(xItem, yItem)));
  const allOpposite = y.every(yItem =>
    x.some(xItem => isOpposite(xItem, yItem))
  );

  if (allPresent) {
    return 'text-primary';
  } else if (allOpposite) {
    return 'text-alert';
  } else {
    return 'text-700';
  }
}

export function negateExtractFieldsAndValues(FilteringRules) {
  const result = [];

  const processClauses = (clauses, operator, negate = false) => {
    if (clauses && clauses.length > 0) {
      clauses.forEach(clause => {
        for (const key in clause) {
          if (Array.isArray(clause[key])) {
            processClauses(clause[key], operator, negate);
          } else {
            const field = Object.keys(clause[key])[0];
            const value = clause[key][field];
            // let removeKeywordfield = field.replace('.keyword', '');
            result.push({ field: field, value, operator, negate });
          }
        }
      });
    }
  };

  if (FilteringRules.must) {
    processClauses(FilteringRules.must, 'is not', false);
  }

  if (FilteringRules.must_not) {
    processClauses(FilteringRules.must_not, 'is', true);
  }

  return result;
}

export const PreviewDownloadButtons = ({ setReports, data }) => {
  return (
    <div className='d-flex gap-2 px-2'>
      <OverlayTrigger
        placement='top'
        overlay={
          <Popover>
            <Popover.Body className='text-center p-1 fs--2 text-700'>
              Preview
            </Popover.Body>
          </Popover>
        }>
        <Button
          size='sm'
          variant='link'
          onClick={() =>
            setReports(prevState => ({
              ...prevState,
              viewReportModal: { open: true, data: data, fullScreen: true }
            }))
          }
          className={`px-0 py-0 outline-none `}>
          <FontAwesomeIcon icon='search-plus' />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger
        placement='top'
        overlay={
          <Popover>
            <Popover.Body className='text-center p-1 fs--2 text-700'>
              Download
            </Popover.Body>
          </Popover>
        }>
        <Button
          onClick={() =>
            setReports(prevState => ({
              ...prevState,
              downloadReportModal: { open: true, data: data, fullScreen: false }
            }))
          }
          size='sm'
          // onClick={() => !loading && addToQuery('is not')}
          variant='link'
          className={`px-0 py-0 outline-none  `}>
          <FontAwesomeIcon icon='download' />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

PreviewDownloadButtons.propTypes = {
  setReports: PropTypes.func,
  data: PropTypes.array
};

export const PreviewDownload = ({ children, setReports, data }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <OverlayTrigger
        placement={'top'}
        delay={{ show: 200, hide: 200000000000 }}
        show={show}
        className={'mb-0'}
        overlay={
          <Popover id={`popover-positioned-auto `} className={'border-0 mb-0'}>
            <Popover.Body
              className='p-2'
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}>
              <PreviewDownloadButtons
                setReports={setReports}
                data={data}
                showLabel={true}
              />
            </Popover.Body>
          </Popover>
        }>
        <div
          className='p-0 d-flex'
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}>
          {children}
        </div>
      </OverlayTrigger>
    </>
  );
};

PreviewDownload.propTypes = {
  setReports: PropTypes.func,
  data: PropTypes.array,
  children: PropTypes.node
};

export const convertDataToArrayForPie = query => {
  let arrayofdataforpie = [];
  const recursion = x => {
    if (typeof x === 'object' && x) {
      for (const [key, value] of Object.entries(x)) {
        if (key === 'key') {
          arrayofdataforpie.push({
            value: x['doc_count'],
            name: x['key']
          });
        } else if (typeof value === 'object' && !Array.isArray(value)) {
          recursion(value);
        } else if (typeof value === 'object' && Array.isArray(value)) {
          value?.map(item => {
            arrayofdataforpie.push({
              value: item['doc_count'],
              name: item['key']
            });
          });
        }
      }
    }
  };
  recursion(query);

  return arrayofdataforpie;
};
export const convertDataForMetricPlain = query => {
  return query['1']['value'];
};

export const candlearray = query => {
  let arrayofdataforcandles = [];
  const recursion = x => {
    if (typeof x === 'object' && x) {
      for (const value of Object.values(x)) {
        if (typeof value === 'object' && !Array.isArray(value)) {
          recursion(value);
        } else if (typeof value === 'object' && Array.isArray(value)) {
          value?.map(item => {
            arrayofdataforcandles.push(item['doc_count']);
          });
        }
      }
    }
  };
  recursion(query);

  return arrayofdataforcandles;
};

export const calculatePreviousRange = (gte, lte) => {
  const gteDate = new Date(gte);
  const lteDate = new Date(lte);
  const timeRange = lteDate - gteDate;
  const previousGte = new Date(gteDate.getTime() - timeRange).toISOString();
  const previousLte = new Date(lteDate.getTime() - timeRange).toISOString();

  return [previousGte, previousLte];
};

export const convertMapDataFromBuckets = query => {
  let arrayofdataforpie = [];
  const recursion = x => {
    if (typeof x === 'object' && x) {
      for (const [key, value] of Object.entries(x)) {
        if (key === 'key') {
          arrayofdataforpie.push({
            value: x['doc_count'],
            name: x['key']
          });
        } else if (typeof value === 'object' && !Array.isArray(value)) {
          recursion(value);
        } else if (typeof value === 'object' && Array.isArray(value)) {
          value?.map(item => {
            arrayofdataforpie.push({
              value: item['doc_count'],
              name: item['key']
            });
          });
        }
      }
    }
  };
  recursion(query);
  return arrayofdataforpie;
};
export const takeOutBucketForHistogram = query => {
  let arrayofdataforhistogram = [];
  const recursion = x => {
    if (typeof x === 'object' && x) {
      for (const value of Object.values(x)) {
        if (typeof value === 'object' && !Array.isArray(value)) {
          recursion(value);
        } else if (typeof value === 'object' && Array.isArray(value)) {
          value?.map(item => {
            arrayofdataforhistogram.push(item);
          });
        }
      }
    }
  };
  recursion(query);

  return arrayofdataforhistogram;
};
export const formatLocalDate = date => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export function getLast24Hours() {
  const now = new Date();

  const to = formatLocalDate(now);

  const from = formatLocalDate(new Date(now.getTime() - 1 * 60 * 60 * 1000));

  return { from, to };
}

export const takeoutallcolumns = () => {
  let rows = [];
  // let isObject = v => v && typeof v === 'object';
  // const recursion = (item, path = '') => {
  //   isObject(item) && !Array.isArray(item)
  //     ? Object.keys(item).flatMap(k =>
  //         recursion(item[k], path + (path && '.') + k)
  //       )
  //     : isObject(item) && Array.isArray(item)
  //     ? rows.push({
  //         truePath: path
  //       })
  //     : rows.push({
  //         truePath: path
  //       });
  // };
  return rows;
};

export const sourceColumns = (
  res,
  getAlertHelp,
  setESGlobalFilters,
  isPrivacyMode,
  isDark
) => {
  let newCols = [];

  res.map(item => {
    newCols = arrayWithNoDuplicates(
      [
        ...newCols,
        ...DynamicColumns(
          item?._source,
          getAlertHelp,
          setESGlobalFilters,
          isPrivacyMode,
          isDark
        )
      ],
      'accessor'
    );
  });

  return newCols;
};
export const DynamicColumns = (
  json,
  getAlertHelp,
  setESGlobalFilters,
  isPrivacyMode,
  isDark
) => {
  let rows = [];

  const isObject = v => v && typeof v === 'object',
    recursion = (item, path = '') => {
      if (isObject(item) && !Array.isArray(item)) {
        Object.keys(item).flatMap(k =>
          recursion(item[k], path + (path && '.') + k)
        );
      } else if (isObject(item) && Array.isArray(item)) {
        if (rows?.findIndex(object => object.accessor === path) === -1) {
          rows.push({
            accessor: path,
            Header: path,
            sortType: (_a, _b, columnId) => {
              setESGlobalFilters(prevState => ({
                ...prevState,
                sortBy: columnId
              }));
            },
            sortable: true,
            hasRender: true,

            Cell: rowData => {
              const row = rowData.row.original._source;
              const value = row[path]
                ? row[path]
                : resolve(path, rowData.row.original._source);

              return value ? (
                <>
                  {/* <HoverFilters
                    keyName={path}
                    ToggleColumnOption={false}
                    value={value}
                    showallhoverfilterson={['count', 'doc_count', '@timestamp'].includes(path) ? false : true}> */}
                  <span className='fs--2 cursor-pointer'>
                    {value ? (
                      <span className='text-700'>
                        {formatArray(value, isDark)}
                      </span>
                    ) : (
                      <span className='text-300'>{'-'}</span>
                    )}
                  </span>
                  {/* </HoverFilters> */}
                </>
              ) : null;
            },
            cellProps: {
              className: 'fs--2 text-700 text-nowrap'
            },
            headerProps: {
              className: 'fs--2 text-nowrap'
            }
          });
        }
      } else {
        if (rows?.findIndex(object => object.accessor === path) === -1) {
          rows.push({
            accessor: path,
            Header: path,
            sortType: (_a, _b, columnId) => {
              setESGlobalFilters(prevState => ({
                ...prevState,
                sortBy: columnId
              }));
            },
            sortable: true,
            hasRender: true,

            Cell: rowData => {
              const row = rowData.row.original._source;
              const value = row[path]
                ? row[path]
                : resolve(path, rowData.row.original._source);
              function isValid(dateString) {
                var minDate = new Date('2020-01-01 00:00:00');
                var maxDate = new Date('2030-01-01 00:00:00');
                var date = new Date(dateString);
                return date > minDate && date < maxDate;
              }
              return (
                <>
                  <CombinedFilters
                    keyName={path}
                    value={value}
                    ToggleColumnOption={false}
                    showallhoverfilterson={
                      ['count', 'doc_count', '@timestamp'].includes(path)
                        ? false
                        : true
                    }>
                    <span className='fs--2 cursor-default'>
                      {path === 'clientName' && isPrivacyMode ? (
                        resolve('clientTag', rowData.row.original._source)
                      ) : (
                        <>
                          {value !== undefined ? (
                            <span className='text-700'>
                              {['alert.signature', 'note'].includes(path) ? (
                                <span onClick={() => getAlertHelp(value)}>
                                  <FontAwesomeIcon
                                    icon={['fas', 'circle-info']}
                                    className='me-2 text-primary'
                                  />
                                </span>
                              ) : null}

                              {isValid(value) &&
                              moment(
                                value,
                                'YYYY-MM-DD HH:mm:ss.SSS'
                              ).toDate() instanceof Date ? (
                                <Moment
                                  local
                                  format='YYYY-MM-DD HH:mm:ss.SSS'
                                  className='fs--2 align-middle'>
                                  {value}
                                </Moment>
                              ) : (
                                <>{value ? String(value) : '-'}</>
                              )}
                            </span>
                          ) : (
                            <span className='text-300'>{'-'}</span>
                          )}
                        </>
                      )}
                    </span>
                  </CombinedFilters>
                </>
              );
            },
            cellProps: {
              className: 'fs--2 text-700 text-nowrap'
            },
            headerProps: {
              className: 'fs--2 text-nowrap'
            }
          });
        }
      }
    };

  recursion(json);

  return rows;
};
const formatArray = (array, isDark) => {
  if (!Array.isArray(array) || array.length === 0) {
    return '-';
  }

  const elementType = array.reduce((type, item) => {
    if (type === 'mix' || typeof item === 'object') {
      return 'mix';
    } else if (type === 'number' && typeof item !== 'number') {
      return 'mix';
    } else if (type === 'string' && typeof item !== 'string') {
      return 'mix';
    } else if (typeof item === 'number') {
      return 'number';
    } else if (typeof item === 'string') {
      return 'string';
    } else {
      return 'mix';
    }
  }, '');

  if (elementType === 'mix') {
    return (
      <>
        <ReactJson
          // key={index}
          src={array ?? []}
          collapsed={0}
          name={false}
          theme={isDark ? 'grayscale' : 'grayscale:inverted'}
          style={{ backgroundColor: 'transparent' }}
        />
      </>
    );
  } else if (elementType === 'number' || elementType === 'string') {
    return '[' + array.join(', ') + ']';
  } else {
    return '-';
  }
};

export const sourceColumn = (
  setShowModal,
  setESGlobalFilters,
  privateColumns
) => {
  return {
    accessor: '_source',
    Header: '_source',
    hasRender: true,
    sortType: (_a, _b, columnId) => {
      setESGlobalFilters(prevState => ({
        ...prevState,
        sortBy: columnId
      }));
    },
    sortable: true,
    Cell: rowData => {
      const value = rowData.row.original?._source;
      let fieldPairs = [];
      const isObject = v => v && typeof v === 'object';
      for (let [key, val] of Object.entries(value || {})) {
        if (
          fieldPairs.length < 36 ||
          JSON.stringify(fieldPairs).length <= 1024
        ) {
          if (JSON.stringify(val).length <= 36) {
            if (
              !isObject(val) &&
              !Array.isArray(val) &&
              !privateColumns.includes(key)
            ) {
              fieldPairs.push({ key, val: val });
            } else if (
              isObject(val) &&
              !Array.isArray(val) &&
              !privateColumns.includes(key)
            ) {
              Object.keys(val).map(ky => {
                fieldPairs.push({ key: ky, val: JSON.stringify(val[ky]) });
              });
            } else if (
              isObject(val) &&
              Array.isArray(val) &&
              !privateColumns.includes(key)
            ) {
              fieldPairs.push({
                key: key,
                val: val.map((item, i) =>
                  i < 10 ? item + `${i < val.length - 1 ? ',' : ''}` : ''
                )
              });
            }
          }
        } else {
          break;
        }
      }
      let { index } = rowData.row;
      return value ? (
        <>
          <a
            className='cursor-pointer'
            style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              display: 'flex',
              flexWrap: 'wrap',
              textDecoration: 'none',

              borderRadius: '3px'
            }}
            onClick={() => setShowModal({ open: true, index: index })}>
            {fieldPairs.map((item, index) => {
              return item?.val ? (
                <div key={index} style={{ textAlign: 'justify' }}>
                  <span
                    className='d-flex align-items-center me-2'
                    style={{
                      borderRadius: '3px',
                      padding: '0',
                      backgroundColor: 'var(--falcon-border-color)',
                      margin: '3px 0'
                    }}>
                    <span
                      className='text-nowrap text-700 fs--2 px-1'
                      style={{
                        padding: '0',
                        border: '1px solid var(--falcon-border-color)',
                        borderTopLeftRadius: '3px',
                        borderBottomLeftRadius: '3px'
                      }}>
                      {item.key}
                    </span>
                    <span
                      className='text-700 fs--2 ps-1'
                      style={{
                        padding: '0',
                        background: 'var(--falcon-card-bg)',
                        border: '1px solid var(--falcon-card-bg)'
                      }}>
                      {item.val ? item.val : '-'}
                    </span>
                  </span>
                </div>
              ) : null;
            })}
          </a>
        </>
      ) : (
        <span className='text-300'>{'-'}</span>
      );
    },
    cellProps: {
      className: 'fs--2 text-700 text-nowrap'
    },
    headerProps: {
      className: 'fs--2 text-nowrap'
    },
    disableSortBy: true
  };
};

export const convertStringToText = arr1 => {
  arr1.map(obj => {
    if (obj.type === 'string') {
      obj.type = 'text';
    }
  });

  return arr1;
};

export const convertTermToMatch = query => {
  if (typeof query === 'object' && query) {
    for (const [key, value] of Object.entries(query)) {
      if (key === 'term') {
        query.match_phrase = value;
        delete query.term;
      } else if (typeof value === 'object') {
        convertTermToMatch(value);
      } else if (Array.isArray(value)) {
        value.forEach(convertTermToMatch);
      }
    }
  }
  return query;
};

export function updateQueryValue(query, key, newValue) {
  if (query && typeof query === 'object') {
    if (key in query) {
      // Update the value if the key is found
      query[key] = newValue;
      return query;
    } else {
      // Recursively search for the key in nested objects
      for (const nestedKey in query) {
        if (typeof query[nestedKey] === 'object') {
          const updatedNestedQuery = updateQueryValue(
            query[nestedKey],
            key,
            newValue
          );
          if (updatedNestedQuery !== null) {
            // If the key is found in a nested query, update and return the entire query
            query[nestedKey] = updatedNestedQuery;
            return query;
          }
        }
      }
    }
  }

  // Return the original query if the key is not found
  return query;
}

export function updateQueryValues(query, updates) {
  if (
    !query ||
    typeof query !== 'object' ||
    !updates ||
    typeof updates !== 'object'
  ) {
    return query;
  }

  // Iterate through the updates object
  for (const key in updates) {
    // Use hasOwnProperty from Object.prototype
    if (Object.prototype.hasOwnProperty.call(updates, key)) {
      // Update the query with the new value
      query = updateQueryValue(query, key, updates[key]);
    }
  }

  return query;
}

export const deleteRangeIfArg = query => {
  if (typeof query === 'object' && query) {
    for (const [key, value] of Object.entries(query)) {
      if (key === 'range') {
        // query.match_phrase = value;
        delete query.range;
      } else if (typeof value === 'object') {
        deleteRangeIfArg(value);
      } else if (Array.isArray(value)) {
        value.forEach(deleteRangeIfArg);
      }
    }
  }
  return query;
};
export const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

export const sortArrayByOrder = (array, order, changeidontype) => {
  const orderMap = new Map();
  for (let i = 0; i < order?.length; i++) {
    orderMap.set(order[i], i);
  }
  array = array.filter(function (item) {
    return order?.includes(
      changeidontype === 'header' ? item.id : item.column.id
    );
  });

  array.sort((a, b) => {
    const aIndex = orderMap.get(
      changeidontype === 'header' ? a.id : a.column.id
    );
    const bIndex = orderMap.get(
      changeidontype === 'header' ? b.id : b.column.id
    );
    if (aIndex < bIndex) {
      return -1;
    } else if (aIndex > bIndex) {
      return 1;
    } else {
      return 0;
    }
  });

  return array;
};

function arrayWithNoDuplicates(array, field) {
  const arrayWithoutNoDuplicates = array.filter(
    (value, index, self) =>
      index === self.findIndex(t => t[field] === value[field])
  );
  return arrayWithoutNoDuplicates;
}

function resolve(path, obj = self, separator = '.') {
  var properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev?.[curr], obj);
}

export const privacyFilterArray = (arr2 = [], arr1 = []) => {
  let filteredArr2 = arr2.filter(elem => {
    for (let i = 0; i < arr1.length; i++) {
      let pattern = new RegExp('^' + arr1[i].replace('*', '.*') + '$');
      if (pattern.test(elem.accessor)) {
        return true;
      }
    }
    return false;
  });
  let juststringarray = filteredArr2.map(val => val.accessor);

  return juststringarray;
};

export const createPrivacyOnColumns = (
  currentcolumns,
  privacylist,
  setESGlobalFilters,
  isPrivacyMode,
  getAlertHelp
) => {
  let rows = [];
  currentcolumns.map(item => {
    !privacylist.includes(item.accessor)
      ? rows.push({
          accessor: item.accessor,
          Header: item.Header,
          sortType: (_a, _b, columnId) => {
            setESGlobalFilters(prevState => ({
              ...prevState,
              sortBy: columnId
            }));
          },
          sortable: true,
          hasRender: true,
          Cell: rowData => {
            const row = rowData.row.original._source;
            const value = row[item.accessor]
              ? row[item.accessor]
              : resolve(item.accessor, rowData.row.original._source);
            function isValid(dateString) {
              var minDate = new Date('2020-01-01 00:00:00');
              var maxDate = new Date('2030-01-01 00:00:00');
              var date = new Date(dateString);
              return date > minDate && date < maxDate;
            }
            return (
              <>
                <CombinedFilters
                  keyName={item.accessor}
                  value={value}
                  ToggleColumnOption={false}
                  showallhoverfilterson={
                    ['count', 'doc_count', '@timestamp'].includes(item.accessor)
                      ? false
                      : true
                  }>
                  <span className='fs--2 cursor-default'>
                    {item.accessor.toLowerCase() === 'clientname' &&
                    isPrivacyMode ? (
                      resolve('clientTag', rowData.row.original._source)
                    ) : (
                      <>
                        {value !== undefined ? (
                          <span className='text-700'>
                            {['alert.signature', 'note'].includes(
                              item.accessor
                            ) ? (
                              <span onClick={() => getAlertHelp(value)}>
                                <FontAwesomeIcon
                                  icon={['fas', 'circle-info']}
                                  className='me-2 text-primary'
                                />
                              </span>
                            ) : null}

                            {isValid(value) &&
                            moment(
                              value,
                              'YYYY-MM-DD HH:mm:ss.SSS'
                            ).toDate() instanceof Date ? (
                              <Moment
                                local
                                format='YYYY-MM-DD HH:mm:ss.SSS'
                                className='fs--2 align-middle'>
                                {value}
                              </Moment>
                            ) : (
                              String(value)
                            )}
                          </span>
                        ) : (
                          <span className='text-700'>{'-'}</span>
                        )}
                      </>
                    )}
                  </span>
                </CombinedFilters>
              </>
            );
          },
          cellProps: {
            className: 'fs--2 text-700 text-nowrap'
          },
          headerProps: {
            className: 'fs--2 text-nowrap'
          }
        })
      : rows.push({
          accessor: item.accessor,
          Header: item.Header,
          sortType: (_a, _b, columnId) => {
            setESGlobalFilters(prevState => ({
              ...prevState,
              sortBy: columnId
            }));
          },
          sortable: true,
          hasRender: true,
          Cell: () => {
            return (
              <>
                <span className='fs--2 '>******</span>
              </>
            );
          },
          cellProps: {
            className: 'fs--2 text-700 text-nowrap'
          },
          headerProps: {
            className: 'fs--2 text-nowrap'
          }
        });
  });

  let noduplicate = arrayWithNoDuplicates(rows, 'accessor');
  return noduplicate;
};
export const privacymodifyJSON = (data1, privacy, privateColumns) => {
  let data = JSON.parse(JSON.stringify(data1));
  const recursion = (item, path = '') => {
    if (isObject(item) && !Array.isArray(item)) {
      Object.keys(item).forEach(k =>
        recursion(item[k], path + (path && '.') + k)
      );
    } else if (isObject(item) && Array.isArray(item)) {
      item.forEach((value, index) => {
        recursion(value, path + (path && '.') + index);
      });
    } else {
      if (privacy && privateColumns.includes(path.replace('_source.', ''))) {
        const keys = path.split('.');
        let nestedObj = data;
        for (let i = 0; i < keys.length - 1; i++) {
          if (Object.prototype.hasOwnProperty.call(nestedObj, keys[i])) {
            nestedObj = nestedObj[keys[i]];
          } else {
            nestedObj = null;
            break;
          }
        }
        if (nestedObj) {
          nestedObj[keys[keys.length - 1]] = '******';
        }
      }
    }
  };

  const isObject = value => {
    return value && typeof value === 'object' && value.constructor === Object;
  };
  // Create a deep copy of the original object
  recursion(data);

  return data;
};

export const getTimezoneOffsetforTimestamp = () => {
  const userTimeZoneOffsetMinutes = -new Date().getTimezoneOffset(); // Note the negative sign here
  const hours = Math.abs(Math.floor(userTimeZoneOffsetMinutes / 60));
  const minutes = Math.abs(userTimeZoneOffsetMinutes % 60);
  const sign = userTimeZoneOffsetMinutes >= 0 ? '+' : '-';

  const formattedTimeZoneOffset = `${sign}${String(hours).padStart(
    2,
    '0'
  )}:${String(minutes).padStart(2, '0')}`;
  return formattedTimeZoneOffset;
};

export function exchangeElements(arr, element1, element2) {
  const index1 = arr.indexOf(element1);
  const index2 = arr.indexOf(element2);

  // Check if both elements are present in the array
  if (index1 !== -1 && index2 !== -1) {
    // Swap the elements
    [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
  }

  return arr;
}

export const updateScopes = (newScopes, oldScopes) => {
  // Clone the newScopes array to avoid mutating the original array
  const updatedScopes = [...newScopes];

  // Add checked: true to each scope in newScopes
  updatedScopes.forEach(scope => {
    scope.checked = true;
  });

  // Compare newScopes with oldScopes and update checked property if necessary
  oldScopes.forEach(oldScope => {
    const index = updatedScopes.findIndex(
      newScope => newScope._id === oldScope._id
    );
    if (index !== -1 && !oldScope.checked) {
      updatedScopes[index].checked = false;
    }
  });

  return updatedScopes;
};

export function modifyMissing(obj, addMissing) {
  // Base case: if obj is not an object or is null, return
  if (typeof obj !== 'object' || obj === null) {
    return;
  }

  // If obj has a terms property and addMissing is true, add missing: '__missing__'
  // If obj has a terms property and addMissing is false, remove missing property
  if (Object.hasOwnProperty.call(obj, 'terms')) {
    if (addMissing) {
      obj.terms.missing = '__missing__';
    } else {
      delete obj.terms.missing;
    }
  }

  // Recursively call modifyMissing on each property of obj
  for (let key in obj) {
    if (Object.hasOwnProperty.call(obj, key) && typeof obj[key] === 'object') {
      modifyMissing(obj[key], addMissing);
    }
  }

  return obj;
}

export function compareObjects(obj1, obj2) {
  // Assuming we want to match based on 'id' and 'name' properties
  return obj1.id === obj2.id && obj1.name === obj2.name;
}

// Remove object from array if it matches objectToCompare
export function removeObjectFromArray(array, objectToCompare) {
  for (let i = 0; i < array.length; i++) {
    if (compareObjects(array[i], objectToCompare)) {
      array.splice(i, 1); // Remove 1 element at index i
      break; // Exit loop once first matching object is removed
    }
  }
}

export function updateFilters(filters, field, value, fieldSelected) {
  // Find if a filter with the same field and value already exists
  const existingFilterIndex = filters.findIndex(
    filter =>
      filter.field?.accessor === field &&
      JSON.stringify(filter.value) === JSON.stringify(value)
  );

  if (existingFilterIndex !== -1) {
    // If a matching filter is found, check the operator and update/remove accordingly
    const existingFilter = filters[existingFilterIndex];

    if (existingFilter.operator === 'isOneOf') {
      // If operator is 'isOneOf', change it to 'isNotOneOf'
      filters[existingFilterIndex].operator = 'isNotOneOf';
    } else if (existingFilter.operator === 'isNotOneOf') {
      // If operator is 'isNotOneOf', remove the filter from the array
      filters.splice(existingFilterIndex, 1);
    }
  } else {
    // If no matching filter is found, add a new filter entry
    const newFilter = {
      id: uuid(), // Generate a unique ID for the new filter
      customLabel: '',
      enabled: true,
      field: fieldSelected,
      inclusion: true,
      operator: 'isOneOf', // Default operator when adding a new filter
      pinned: false,
      useCustomLabel: false,
      value: value // Set the value to the passed value parameter
    };

    filters.push(newFilter);
  }

  return filters; // Return the updated filters array
}

export function filterIconFilers(currentFilters, newFilters, fields, color) {
  // If the color is 'text-alert', remove filters from currentFilters that match newFilters
  if (color === 'text-alert') {
    newFilters.forEach(newFilter => {
      // Remove all filters in currentFilters that have the same field and value as the newFilter
      currentFilters = currentFilters.filter(
        filter =>
          !(
            filter?.field?.accessor === newFilter?.field &&
            filter?.value === newFilter?.value
          )
      );
    });
  } else {
    // Loop through each filter in newFilters
    newFilters.forEach(newFilter => {
      // Find the index of the existing filter in currentFilters that matches the newFilter's field and value
      const existingFilterIndex = currentFilters.findIndex(
        filter =>
          filter?.field?.accessor === newFilter?.field &&
          filter?.value === newFilter?.value
      );

      if (existingFilterIndex !== -1) {
        // If the filter exists, toggle its operator between "is" and "isNot"
        const existingFilter = currentFilters[existingFilterIndex];
        existingFilter.operator =
          existingFilter.operator === 'is' ? 'isNot' : 'is';
      } else {
        // If the filter does not exist, add it to currentFilters
        let selectedField = fields.find(field => field.id === newFilter?.field);
        const newFilterObject = {
          id: uuid(), // Generate a unique ID for the new filter
          customLabel: '',
          enabled: true,
          field: selectedField,
          inclusion: true,
          operator: newFilter.operator, // Use the operator from newFilters
          pinned: false,
          useCustomLabel: false,
          value: newFilter?.value // Use the value from newFilters
        };

        currentFilters.push(newFilterObject);
      }
    });
  }

  return currentFilters; // Return the updated currentFilters
}

export function updateFilterOperator(currentFilters, field, value, operator) {
  // Create a mapping of toggle operators
  const operatorMap = {
    is: 'isNot',
    isNot: 'is',
    isLike: 'isNotLike',
    isNotLike: 'isLike',
    isOneOf: 'isNotOneOf',
    isNotOneOf: 'isOneOf',
    isBetween: 'isNotBetween',
    isNotBetween: 'isBetween',
    isGreaterThan: 'isLessThan',
    isLessThan: 'isGreaterThan',
    isGreaterThanOrEqualTo: 'isLessThanOrEqualTo',
    isLessThanOrEqualTo: 'isGreaterThanOrEqualTo',
    isNull: 'isNotNull',
    isNotNull: 'isNull',
    isTrue: 'isFalse',
    isFalse: 'isTrue',
    exists: 'doesNotExist',
    doesNotExist: 'exists'
  };

  // Track if currentFilters are modified
  let isModified = false;

  // Find the index of the existing filter in currentFilters that matches field and value
  const existingFilterIndex = currentFilters.findIndex(
    filter =>
      filter?.field?.accessor === field &&
      JSON.stringify(filter?.value) === JSON.stringify(value)
  );

  if (existingFilterIndex !== -1) {
    // If the filter is found
    const existingFilter = currentFilters[existingFilterIndex];

    if (existingFilter.operator === operator) {
      // If operator is the same, toggle the operator using the operatorMap
      existingFilter.operator =
        operatorMap[existingFilter.operator] || existingFilter.operator;
    } else {
      // If operator is different, update it to the operator parameter
      existingFilter.operator = operator;
    }

    // Mark that modifications were made
    isModified = true;
  }

  // Return the modified filters and the status if changes were made
  return { updatedFilters: currentFilters, isModified };
}
