import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import ReleasesWrapper from './ReleasesWrapper';

/**
 * Renders the Releases component
 *
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Releases = () => {
  return (
    <>
      <WidgetSectionTitle title='Release Management' className='mb-2' />
      <ReleasesWrapper />
    </>
  );
};

export default Releases;
