import aws from './awsCloudTrail';
import azure from './azure';
import behaviors from './behaviors';
import box from './box';
import darkweb from './darkweb';
import duo from './duo';
import fileExtraction from './fileExtraction';
import google from './google';
import ics from './ics';
import meraki from './meraki';
import network from './network';
import office365 from './office365';
import okta from './okta';
import rpc from './rpc';
import sentinelOneAgents from './sentinelOneAgents';
import sentinelOneThreats from './sentinelOneThreats';
import signatures from './signatures';
import smb from './smb';
import syslog from './syslog';
import threatIntel from './threatIntel';
import windows from './windows';

/**
 * Collection of all dashboards available in the application.
 * @typedef {Object} AllDashboards
 * @property {Object} behaviors - Behaviors dashboard.
 * @property {Object} box - Box dashboard.
 * @property {Object} darkweb - Darkweb dashboard.
 * @property {Object} duo - Duo dashboard.
 * @property {Object} fileExtraction - File extraction dashboard.
 * @property {Object} google - Google dashboard.
 * @property {Object} ics - ICS dashboard.
 * @property {Object} meraki - Meraki dashboard.
 * @property {Object} network - Network dashboard.
 * @property {Object} office365 - Office365 dashboard.
 * @property {Object} okta - Okta dashboard.
 * @property {azure} azure - azure dashboard.
 * @property {Object} rpc - RPC dashboard.
 * @property {Object} sentinelOneAgents - SentinelOne Agents dashboard.
 * @property {Object} sentinelOneThreats - SentinelOne Threats dashboard.
 * @property {Object} signatures - Signatures dashboard.
 * @property {Object} smb - SMB dashboard.
 * @property {Object} syslog - Syslog dashboard.
 * @property {Object} threatIntel - Threat intelligence dashboard.
 * @property {Object} windows - Windows dashboard.
 */

/**
 * Collection of all dashboards.
 * @type {AllDashboards}
 */
export const allDashboards = {
  behaviors,
  aws,
  box,
  darkweb,
  duo,
  fileExtraction,
  google,
  ics,
  meraki,
  network,
  office365,
  okta,
  azure,
  rpc,
  sentinelOneAgents,
  sentinelOneThreats,
  signatures,
  smb,
  syslog,
  threatIntel,
  windows
};
