import { useContext } from 'react';
import { ResourcesContext } from 'context/admin-contexts/ResourcesProvider.js';

/**
 * Custom hook to use the ResourcesContext
 * @returns {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const useResources = () => {
  return useContext(ResourcesContext);
};

export default useResources;
