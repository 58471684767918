import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import LoadingIndicatorOverlay from 'components/visualizations/LoadingIndicatorOverlay';
import { useCluster } from 'hooks/admin-contexts/useCluster';
import useApplication from 'hooks/useApplication';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  DropdownHeader,
  Form,
  Row
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import SimpleBar from 'simplebar-react';
import HeroUnitChart from './HeroUnitChart';

/**
 * Renders a list of nodes based on the provided filter term and node list.
 *
 * @param {Object} props - The component props.
 * @param {string} props.filterTerm - The filter term used to filter the node list. Default value is 'l-'.
 * @param {Array} props.nodeList - The list of nodes to be filtered and rendered.
 * @param {Array} props.selectedNodeIds - The array of selected node IDs.
 * @param {Function} props.handleNodesChange - The function to handle changes in the selected node IDs.
 * @returns {Array} - The array of rendered NodeListItem components.
 */
const NodeListCategory = ({
  filterTerm = 'l-',
  nodeList = [],
  selectedNodeIds,
  handleNodesChange
}) => {
  return nodeList
    .filter(node => node.name.toLowerCase().includes(filterTerm))
    .map(node => (
      <NodeListItem
        key={node.id}
        node={node}
        selectedNodeIds={selectedNodeIds}
        handleNodesChange={handleNodesChange}
      />
    ));
};

NodeListCategory.propTypes = {
  filterTerm: PropTypes.string,
  nodeList: PropTypes.array,
  selectedNodeIds: PropTypes.array,
  handleNodesChange: PropTypes.func
};

/**
 * Renders a single node item in a list.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.node - The node object.
 * @param {Array} props.selectedNodeIds - The array of selected node IDs.
 * @param {Function} props.handleNodesChange - The function to handle node changes.
 * @returns {JSX.Element} The rendered node item.
 */
const NodeListItem = ({ node, selectedNodeIds, handleNodesChange }) => {
  return (
    <Form.Group key={node.id} className='ms-1 my-0 fs--2 d-flex'>
      <Form.Check
        type='switch'
        color='pastel-green'
        id={node.id}
        onChange={() =>
          handleNodesChange({
            nodeIds: node.id,
            behavior: 'passive'
          })
        }
        checked={selectedNodeIds.includes(node.id)}
      />
      <Form.Label htmlFor={node.id} className='text-900 fs--2'>
        {node.name}
      </Form.Label>
    </Form.Group>
  );
};

NodeListItem.propTypes = {
  node: PropTypes.object,
  selectedNodeIds: PropTypes.array,
  handleNodesChange: PropTypes.func
};

/**
 * HeroUnit component for displaying the health status of the search cluster.
 *
 * @returns {JSX.Element} The rendered HeroUnit component.
 */
const HeroUnit = () => {
  const {
    application: { isDark }
  } = useApplication();
  moment.relativeTimeThreshold('ss', 0);
  const chartRef = useRef(null);

  const statuses = {
    default: {
      color: 'secondary',
      bgColor: '',
      icon: 'question-circle',
      statusHeading: 'Loading...',
      statusSubheading: 'This will only take a second.'
    },
    green: {
      color: 'pastel-green',
      bgColor: '',
      icon: ['fas', 'check'],
      statusHeading: 'Healthy',
      statusSubheading: ''
    },
    yellow: {
      color: 'pastel-yellow',
      bgColor: 'bg-soft-warning',
      icon: 'warning',
      statusHeading: 'Notice',
      statusSubheading: ''
    },
    red: {
      color: 'pastel-red',
      bgColor: 'bg-soft-danger',
      icon: 'warning',
      statusHeading: 'Warning',
      statusSubheading: ''
    }
  };

  const {
    cluster: {
      nodeList,
      selectedNodeIds,
      translogOperations: { xAxis, legend, series },
      clusterHealthStatus,
      heroChartInterval
    },
    setCluster,
    loadingTranslogOperations,
    setLoadingTranslogOperations,
    setFetchTranslogOperations
  } = useCluster();

  const [isNodeSelectorOpen, setIsNodeSelectorOpen] = useState(false);
  const [isIntervalSelectorOpen, setIsIntervalSelectorOpen] = useState(false);

  const handleNodesChange = ({ nodeIds = null, behavior = 'passive' }) => {
    if (behavior === 'updateOnly') {
      // If the behavior is updateOnly, and selectedNodeIds is empty, then show a warning toast message to the user to select at least one node.
      if (selectedNodeIds.length === 0) {
        toast.warning('Please select at least one node.');
        return;
      }

      // If the behavior is updateOnly, and selectedNodeIds is not empty, then set the loadingTranslogOperations to true and set the fetchTranslogOperations to true. This will trigger the loading overlay to show and fetch the translog operations.
      setLoadingTranslogOperations(true);
      setFetchTranslogOperations(true);
      setIsNodeSelectorOpen(false);
      return;
    }

    // If the behavior is filter, toggle all selectedNodeIds that match the pattern provided in the nodeIds parameter. Well need to map through the nodeList and filter out the nodes that match the pattern provided in the nodeIds parameter. Then, set the selectedNodeIds to the filtered nodes. Remember, this needs to toggle on/off only nodes matching the filter.
    if (behavior === 'filter') {
      const filteredNodeIds = nodeList
        .filter(node => node.name.toLowerCase().includes(nodeIds))
        .map(node => node.id);

      const newSelectedNodeIds = selectedNodeIds.slice(); // Create a copy of selectedNodeIds

      filteredNodeIds.forEach(id => {
        const index = newSelectedNodeIds.indexOf(id);
        if (index === -1) {
          // If the id is not in the selectedNodeIds, add it
          newSelectedNodeIds.push(id);
        } else {
          // If the id is already in the selectedNodeIds, remove it
          newSelectedNodeIds.splice(index, 1);
        }
      });

      setCluster(prevState => ({
        ...prevState,
        selectedNodeIds: newSelectedNodeIds
      }));
    }

    // If the behavior is passive, and the nodeIds is 'all', then set the selectedNodeIds to an empty array. This will deselect all the nodes.
    if (behavior === 'passive') {
      if (nodeIds === 'all') {
        if (selectedNodeIds.length === nodeList.length) {
          setCluster(prevState => ({
            ...prevState,
            selectedNodeIds: []
          }));
        } else {
          setCluster(prevState => ({
            ...prevState,
            selectedNodeIds: nodeList.map(node => node.id)
          }));
        }
      } else {
        if (selectedNodeIds.includes(nodeIds)) {
          setCluster(prevState => ({
            ...prevState,
            selectedNodeIds: selectedNodeIds.filter(id => id !== nodeIds)
          }));
        } else {
          setCluster(prevState => ({
            ...prevState,
            selectedNodeIds: [...selectedNodeIds, nodeIds]
          }));
        }
      }
    }
  };

  const handleIntervalChange = interval => {
    setCluster(prevState => ({
      ...prevState,
      heroChartInterval: interval
    }));
    setLoadingTranslogOperations(true);
    setFetchTranslogOperations(true);
  };

  return (
    <Card
      className={`overflow-hidden h-100 shadow-none border bg-card border-card rounded overflow-hidden h-100 cluster-health-hero ${
        Object.keys(statuses).includes(clusterHealthStatus)
          ? statuses[clusterHealthStatus].bgColor
          : statuses.default.color
      }`}>
      <Card.Body
        as={Flex}
        justifyContent='between'
        direction='column'
        className='p-2'>
        <Row className='g-0 align-items-start'>
          <Col style={{ zIndex: 2 }}>
            <h1 className='fs-2 text-900 mb-0'>
              {Object.keys(statuses).includes(clusterHealthStatus)
                ? statuses[clusterHealthStatus].statusHeading
                : statuses.default.statusHeading}
              {Object.keys(statuses).includes(clusterHealthStatus) ? (
                <FontAwesomeIcon
                  icon={statuses[clusterHealthStatus].icon}
                  className='ms-2'
                />
              ) : null}
            </h1>

            <p className='fs--1 fw-normal text-900'>
              {Object.keys(statuses).includes(clusterHealthStatus)
                ? `The search cluster status is `
                : statuses.default.statusSubheading}
              {Object.keys(statuses).includes(clusterHealthStatus) ? (
                <Badge
                  pill
                  className={`fs--1 fw-semi-bold bg-soft-${statuses[clusterHealthStatus].color} text-${statuses[clusterHealthStatus].color}`}>
                  {clusterHealthStatus}
                </Badge>
              ) : null}
            </p>
          </Col>
          <Col xs='auto' className='text-end'>
            <Flex className='w-100' justifyContent='between'>
              <Dropdown
                show={isNodeSelectorOpen}
                drop={'down'}
                align={'end'}
                onToggle={() => setIsNodeSelectorOpen(!isNodeSelectorOpen)}
                className='node-selector'>
                <Dropdown.Toggle
                  variant='link'
                  id='dropdown-node-selector'
                  className='p-0 text-1100 fs--1 fw-normal hover-700 text-decoration-none'>
                  {selectedNodeIds.length} Nodes Selected
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    modifiers: [
                      {
                        name: 'preventOverflow',
                        options: {
                          altAxis: true, // Allows the popper to prevent overflowing along both axes.
                          boundariesElement: 'viewport' // Can also use 'window' or 'scrollParent'.
                        }
                      },
                      {
                        name: 'flip',
                        options: {
                          fallbackPlacements: [
                            'bottom',
                            'right',
                            'top',
                            'left'
                          ], // Order of fallbacks when there's no space for initial placement.
                          altBoundary: true
                        }
                      },
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 8] // Adjust to provide spacing from the reference element
                        }
                      }
                    ],
                    strategy: 'absolute'
                  }}
                  className='node-selector-item fs--2 p-2'>
                  <DropdownHeader className='fs--2 p-0 pb-2'>
                    {selectedNodeIds.length === 0} Select Nodes
                  </DropdownHeader>
                  <SimpleBar
                    className='h-100 pe-3'
                    forceVisible='y'
                    autoHide={true}
                    style={{
                      maxHeight: '250px',
                      minHeight: '150px',
                      width: '300px'
                    }}>
                    <Form.Group className='ms-1 my-0 fs--2 d-flex'>
                      <Form.Check
                        type='switch'
                        id='all-nodes'
                        color='success'
                        onChange={() =>
                          handleNodesChange({
                            nodeIds: 'all',
                            behavior: 'passive'
                          })
                        }
                        checked={selectedNodeIds.length === nodeList.length}
                      />
                      <Form.Label
                        htmlFor='all-nodes'
                        className='text-900 fs--2'>
                        Toggle All
                      </Form.Label>
                    </Form.Group>
                    <Dropdown.Divider className='m-0 mb-2' />
                    <Dropdown.Header className='fs--2 p-0 pb-1 mb-0'>
                      Coordinator
                    </Dropdown.Header>
                    <Form.Group className='ms-1 my-0 fs--2 d-flex'>
                      <Form.Check
                        type='switch'
                        id='data-coord-toggle-all'
                        color='success'
                        onChange={() =>
                          handleNodesChange({
                            nodeIds: 'l-coord-',
                            behavior: 'filter'
                          })
                        }
                        checked={nodeList
                          .filter(node =>
                            node.name.toLowerCase().includes('l-coord-')
                          )
                          .every(node => selectedNodeIds.includes(node.id))}
                      />
                      <Form.Label
                        htmlFor='data-coord-toggle-all'
                        className='text-900 fs--2'>
                        Toggle All
                      </Form.Label>
                    </Form.Group>
                    <NodeListCategory
                      filterTerm='l-coord-'
                      nodeList={nodeList}
                      selectedNodeIds={selectedNodeIds}
                      handleNodesChange={handleNodesChange}
                    />
                    <Dropdown.Divider className='m-0 mb-2' />
                    <Dropdown.Header className='fs--2 p-0 pb-1 mb-0'>
                      Data (Hot)
                    </Dropdown.Header>
                    <Form.Group className='ms-1 my-0 fs--2 d-flex'>
                      <Form.Check
                        type='switch'
                        id='data-h-toggle-all'
                        color='success'
                        onChange={() =>
                          handleNodesChange({
                            nodeIds: 'l-data-h-',
                            behavior: 'filter'
                          })
                        }
                        checked={nodeList
                          .filter(node =>
                            node.name.toLowerCase().includes('l-data-h-')
                          )
                          .every(node => selectedNodeIds.includes(node.id))}
                      />
                      <Form.Label
                        htmlFor='data-h-toggle-all'
                        className='text-900 fs--2'>
                        Toggle All
                      </Form.Label>
                    </Form.Group>
                    <NodeListCategory
                      filterTerm='l-data-h-'
                      nodeList={nodeList}
                      selectedNodeIds={selectedNodeIds}
                      handleNodesChange={handleNodesChange}
                    />
                    <Dropdown.Divider className='m-0 mb-2' />
                    <Dropdown.Header className='fs--2 p-0 pb-1 mb-0'>
                      Data (Warm)
                    </Dropdown.Header>
                    <Form.Group className='ms-1 my-0 fs--2 d-flex'>
                      <Form.Check
                        type='switch'
                        id='data-w-toggle-all'
                        color='success'
                        onChange={() =>
                          handleNodesChange({
                            nodeIds: 'l-data-w-',
                            behavior: 'filter'
                          })
                        }
                        checked={nodeList
                          .filter(node =>
                            node.name.toLowerCase().includes('l-data-w-')
                          )
                          .every(node => selectedNodeIds.includes(node.id))}
                      />
                      <Form.Label
                        htmlFor='all-nodes'
                        className='text-900 fs--2'>
                        Toggle All
                      </Form.Label>
                    </Form.Group>
                    <NodeListCategory
                      filterTerm='l-data-w-'
                      nodeList={nodeList}
                      selectedNodeIds={selectedNodeIds}
                      handleNodesChange={handleNodesChange}
                    />
                    <Dropdown.Divider className='m-0 mb-2' />
                    <Dropdown.Header className='fs--2 p-0 pb-1 mb-0'>
                      Data (Cold)
                    </Dropdown.Header>
                    <Form.Group className='ms-1 my-0 fs--2 d-flex'>
                      <Form.Check
                        type='switch'
                        id='data-c-toggle-all'
                        color='success'
                        onChange={() =>
                          handleNodesChange({
                            nodeIds: 'l-data-c-',
                            behavior: 'filter'
                          })
                        }
                        checked={nodeList
                          .filter(node =>
                            node.name.toLowerCase().includes('l-data-c-')
                          )
                          .every(node => selectedNodeIds.includes(node.id))}
                      />
                      <Form.Label
                        htmlFor='all-nodes'
                        className='text-900 fs--2'>
                        Toggle All
                      </Form.Label>
                    </Form.Group>
                    <NodeListCategory
                      filterTerm='l-data-c-'
                      nodeList={nodeList}
                      selectedNodeIds={selectedNodeIds}
                      handleNodesChange={handleNodesChange}
                    />
                    <Dropdown.Divider className='m-0 mb-2' />
                    <Dropdown.Header className='fs--2 p-0 pb-1 mb-0'>
                      Master
                    </Dropdown.Header>
                    <Form.Group className='ms-1 my-0 fs--2 d-flex'>
                      <Form.Check
                        type='switch'
                        id='master-toggle-all'
                        color='success'
                        onChange={() =>
                          handleNodesChange({
                            nodeIds: 'l-master-',
                            behavior: 'filter'
                          })
                        }
                        checked={nodeList
                          .filter(node =>
                            node.name.toLowerCase().includes('l-master-')
                          )
                          .every(node => selectedNodeIds.includes(node.id))}
                      />
                      <Form.Label
                        htmlFor='all-nodes'
                        className='text-900 fs--2'>
                        Toggle All
                      </Form.Label>
                    </Form.Group>
                    <NodeListCategory
                      filterTerm='l-master-'
                      nodeList={nodeList}
                      selectedNodeIds={selectedNodeIds}
                      handleNodesChange={handleNodesChange}
                    />
                    <Dropdown.Divider className='m-0 mb-2' />
                    <Dropdown.Header className='fs--2 p-0 pb-1 mb-0'>
                      Web
                    </Dropdown.Header>
                    <Form.Group className='ms-1 my-0 fs--2 d-flex'>
                      <Form.Check
                        type='switch'
                        id='web-toggle-all'
                        color='success'
                        onChange={() =>
                          handleNodesChange({
                            nodeIds: 'l-web-',
                            behavior: 'filter'
                          })
                        }
                        checked={nodeList
                          .filter(node =>
                            node.name.toLowerCase().includes('l-web-')
                          )
                          .every(node => selectedNodeIds.includes(node.id))}
                      />
                      <Form.Label
                        htmlFor='all-nodes'
                        className='text-900 fs--2'>
                        Toggle All
                      </Form.Label>
                    </Form.Group>
                    <NodeListCategory
                      filterTerm='l-web-'
                      nodeList={nodeList}
                      selectedNodeIds={selectedNodeIds}
                      handleNodesChange={handleNodesChange}
                    />
                  </SimpleBar>
                  <div className='d-grid gap-2 pt-2'>
                    <Button
                      variant='pastel-green'
                      className='text-white'
                      size='sm'
                      onClick={() => {
                        handleNodesChange({ behavior: 'updateOnly' });
                      }}>
                      Apply Changes
                    </Button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown
                show={isIntervalSelectorOpen}
                drop={'down'}
                align={'end'}
                onToggle={() =>
                  setIsIntervalSelectorOpen(!isIntervalSelectorOpen)
                }
                className='interval-selector'>
                <Dropdown.Toggle
                  variant='link'
                  id='dropdown-interval-selector'
                  className='ms-2 py-0 text-1100 fs--1 fw-normal hover-700 text-decoration-none'>
                  {heroChartInterval === '1m' ? 'Last Hour' : 'Last Day'}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    modifiers: [
                      {
                        name: 'preventOverflow',
                        options: {
                          altAxis: true, // Allows the popper to prevent overflowing along both axes.
                          boundariesElement: 'viewport' // Can also use 'window' or 'scrollParent'.
                        }
                      },
                      {
                        name: 'flip',
                        options: {
                          fallbackPlacements: [
                            'bottom',
                            'right',
                            'top',
                            'left'
                          ], // Order of fallbacks when there's no space for initial placement.
                          altBoundary: true
                        }
                      },
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 8] // Adjust to provide spacing from the reference element
                        }
                      }
                    ],
                    strategy: 'absolute'
                  }}
                  className='interval-selector-item fs--2 p-2'>
                  <DropdownHeader className='fs--2 p-0 pb-2'>
                    Chart Range
                  </DropdownHeader>
                  <Dropdown.Item
                    className='px-0'
                    onClick={() => {
                      handleIntervalChange('1m');
                    }}>
                    Last Hour
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='px-0'
                    onClick={() => {
                      handleIntervalChange('1h');
                    }}>
                    Last Day
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Flex>
          </Col>
        </Row>
        <Row className='g-0 align-items-end'>
          <Col>
            <LoadingIndicatorOverlay
              backgroundClass={isDark ? 'bg-100' : 'bg-100'}
              style={{ zIndex: 1 }}
              loading={loadingTranslogOperations}
            />
            <HeroUnitChart
              ref={chartRef}
              xAxis={xAxis}
              legend={legend}
              series={series}
              isDark={isDark}
              style={{ height: '300px' }}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

HeroUnit.propTypes = {
  data: PropTypes.shape({
    all: PropTypes.array,
    successful: PropTypes.array,
    failed: PropTypes.array
  })
};

export default HeroUnit;
