import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import FieldBadge from 'components/dashboards/Artifacts/Explore/Sidebar/FieldList/FieldBadge';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import { truncateMiddle } from 'helpers/utils';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Overlay,
  Popover,
  Row
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import SimpleBar from 'simplebar-react';
import FieldEditModal from './FieldEditModal';
import FieldsTable from './FieldsTable/FieldsTable';
import FieldsTableFilters from './FieldsTable/FieldsTableFilters';
import FieldsTableFooter from './FieldsTable/FieldsTableFooter';
import FieldsTablePagination from './FieldsTable/FieldsTablePagination';
import FieldsTableWrapper from './FieldsTable/FieldsTableWrapper';

/**
 * Retrieves the value of the 'indice' query parameter from the current URL
 * @returns {string|null} The value of the 'indice' query parameter, or null if it is not present
 */
function useQuery() {
  return new URLSearchParams(useLocation().search).get('indice');
}

/**
 * Renders the FieldsLayout component
 *
 * @returns {JSX.Element} The rendered FieldsLayout component
 */
const FieldsLayout = () => {
  let indice = useQuery();
  const [loading, setLoading] = useState(true);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [fetch, setFetch] = useState(true);
  const [globalFilter, setGlobalFilter] = useState('');
  const [indices, setIndices] = useState([]);
  const [selectedIndice, setSelectedIndice] = useState('');
  const [fields, setFields] = useState([]);
  const [total, setTotal] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [selectedFieldId, setSelectedFieldId] = useState(null);

  const { axiosPrivate } = useAxiosPrivate();

  const handleToggle = (setShow, show) => {
    setShow(!show);
  };

  const handleClickOutside = (e, triggerRef, dropdownRef, setShow) => {
    if (
      triggerRef.current &&
      !triggerRef.current.contains(e.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      setShow(false);
    }
  };

  const openModal = fieldId => {
    setSelectedFieldId(fieldId);
    setModalShow(true);
  };

  const closeModal = () => {
    setSelectedFieldId(null);
    setModalShow(false);
    setFetch(true); // Trigger refetch to update list
  };

  const getIndices = () => {
    setLoading(true);
    LeargasAPI.Indices.getIndices(axiosPrivate, true)
      .then(indices => {
        if (indices instanceof Error) throw indices;
        setIndices([...indices]);
        setSelectedIndice(indice || indices[0].alias);
      })
      .catch(error => {
        if (error.message.includes('Network Error')) {
          console.error('Network Error occurred.');
        }
        setIndices([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getFields = indiceToFetch => {
    setRefreshLoading(true);
    LeargasAPI.Fields.getFields(indiceToFetch, axiosPrivate)
      .then(fields => {
        if (fields instanceof Error) throw fields;
        setFields(fields);
        setTotal(fields.length);
      })
      .catch(error => {
        if (error.message.includes('Network Error')) {
          console.error('Network Error occurred.');
        } else if (error.response && error.response.status === 404) {
          console.error('404 Error: No fields found for this indice.');
        }
        setFields([]);
        setTotal(0);
      })
      .finally(() => {
        setRefreshLoading(false);
        setFetch(false);
      });
  };

  const doUpdate = useCallback(
    (e, data = null) => {
      if (e) {
        e.preventDefault();
        if (!data) {
          data = new FormData(e.target);
          data = Object.fromEntries(data.entries());
        }
      }
      LeargasAPI.Fields.updateFields(data, axiosPrivate)
        .then(res => {
          if (res instanceof Error) throw res;
          setFetch(true);
          toast.success('Field updated successfully');
        })
        .catch(error => {
          if (error.message.includes('Network Error')) {
            console.error('Network Error occurred.');
          }
          toast.error('Field update failed');
        });
    },
    [axiosPrivate]
  );

  useEffect(() => {
    if (fetch && selectedIndice) {
      getFields(selectedIndice);
    }
  }, [fetch, selectedIndice]);

  useEffect(() => {
    if (indice) {
      setSelectedIndice(indice);
    }
  }, [indice]);

  useEffect(() => {
    getIndices();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        accessor: 'name',
        Header: 'Field',
        noTruncate: true,
        hasRender: false,
        cellProps: {
          className: 'fs--1 fw-normal text-700 align-middle text-nowrap w-50'
        },
        headerProps: {
          className: 'fs--1 align-middle text-nowrap'
        },
        Cell: rowData => {
          const { type, name } = rowData.row.original;
          return (
            <FieldBadge type={type}>{truncateMiddle(name, 64)}</FieldBadge>
          );
        }
      },
      {
        accessor: 'enabled',
        Header: 'Enabled',
        noTruncate: true,
        hasRender: true,
        cellProps: {
          className:
            'fs--1 fw-normal text-700 align-middle text-nowrap text-center d-none d-md-table-cell'
        },
        headerProps: {
          className:
            'fs--1 align-middle text-nowrap text-center d-none d-md-table-cell'
        },
        Cell: rowData => {
          const { enabled } = rowData.row.original;
          return (
            <>
              <Form className='custom-switch'>
                <Form.Check
                  type='switch'
                  name='enabled'
                  checked={enabled}
                  onChange={e => {
                    doUpdate(undefined, {
                      _id: rowData.row.original._id,
                      enabled: e.target.checked
                    });
                  }}
                />
              </Form>
            </>
          );
        }
      },
      {
        accessor: 'sensitive',
        Header: 'Sensitive',
        noTruncate: true,
        hasRender: true,
        cellProps: {
          className:
            'fs--1 fw-normal text-700 align-middle text-nowrap text-center d-none d-md-table-cell'
        },
        headerProps: {
          className:
            'fs--1 align-middle text-nowrap text-center d-none d-md-table-cell'
        },
        Cell: rowData => {
          const { sensitive } = rowData.row.original;
          return (
            <Form className='custom-switch'>
              <Form.Check
                type='switch'
                name='sensitive'
                checked={sensitive}
                onChange={e => {
                  doUpdate(undefined, {
                    _id: rowData.row.original._id,
                    sensitive: e.target.checked
                  });
                }}
              />
            </Form>
          );
        }
      },
      {
        accessor: 'actions',
        Header: '',
        noTruncate: true,
        hasRender: false,
        disableSortBy: true,
        cellProps: {
          className:
            'fs--1 fw-normal text-700 align-middle text-nowrap d-flex justify-content-end'
        },
        headerProps: {
          className: 'fs--1 align-middle text-nowrap'
        },
        Cell: rowData => {
          const dropdownRef = useRef(null);
          const triggerRef = useRef(null);
          const [show, setShow] = useState(false);

          useEffect(() => {
            const handleOutsideClick = e =>
              handleClickOutside(e, triggerRef, dropdownRef, setShow);
            document.addEventListener('mousedown', handleOutsideClick);
            return () => {
              document.removeEventListener('mousedown', handleOutsideClick);
            };
          }, []);

          // Close the popover when the modal is opened
          const handleEditClick = fieldId => {
            setShow(false);
            openModal(fieldId);
          };

          const popover = (
            <Popover
              id='fieldsActionsPopover'
              style={{
                width: 'fit-content',
                minWidth: 'fit-content',
                padding: 0,
                margin: 0,
                marginTop: -10
              }}>
              <div ref={dropdownRef}>
                <Flex
                  justifyContent='start'
                  alignItems='start'
                  direction={'column'}
                  className='p-0'>
                  <Dropdown.Menu
                    show={true}
                    className='position-relative border-0 fs--2'
                    style={{
                      minWidth: 'fit-content',
                      width: 'fit-content'
                    }}>
                    <SimpleBar
                      className='h-100'
                      forceVisible={true}
                      autoHide={false}
                      style={{
                        minWidth: '150px',
                        maxHeight: '50vh'
                      }}>
                      <Dropdown.Header className='fs--2'>
                        Actions
                      </Dropdown.Header>
                      <div className='mb-1'>
                        <Dropdown.Item
                          onClick={() =>
                            handleEditClick(rowData.row.original._id)
                          }>
                          <FontAwesomeIcon
                            icon='pencil'
                            className='me-2'
                            width={16}
                          />
                          Edit Details
                        </Dropdown.Item>
                      </div>
                    </SimpleBar>
                  </Dropdown.Menu>
                </Flex>
              </div>
            </Popover>
          );

          return (
            <Flex className='position-relative'>
              <Overlay
                show={show}
                target={triggerRef.current}
                placement='right-start'
                offset={[5, 15]}
                flip={true}
                rootClose={true}
                onHide={() => setShow(false)}>
                {popover}
              </Overlay>

              <AdvancedPopover
                placement='top'
                popoverText={
                  <div className='text-700 overflow-hidden'>
                    <span className='text-nowrap'>
                      Manage settings for this field
                    </span>
                  </div>
                }
                showArrow={true}>
                <Button
                  ref={triggerRef}
                  id='fieldsActionsTrigger'
                  onClick={() => handleToggle(setShow, show)}
                  className='p-2 text-decoration-none fs--2 text-700 hover-900 text-start fw-normal'
                  variant='link'
                  style={{ whiteSpace: 'pre-line', overflowWrap: 'anywhere' }}>
                  <FontAwesomeIcon
                    icon={['fas', 'ellipsis-vertical']}
                    transform='grow-4'
                  />
                </Button>
              </AdvancedPopover>
            </Flex>
          );
        }
      }
    ],
    [doUpdate]
  );

  return (
    <>
      {loading ? (
        <LoadingSpinner grow='10' />
      ) : (
        <>
          <style>
            {`
              .custom-switch .form-check-input:checked {
                background-color: var(--falcon-pastel-green-dark);
                border-color: var(--falcon-pastel-green-dark);
              }
              .custom-switch .form-check-input:checked + .form-check-label::before {
                background-color: var(--falcon-primary);
              }
              .custom-switch .form-check-input:checked::before {
                background-color: var(--falcon-primary);
              }
              .custom-switch .form-check-input:focus {
                box-shadow: 0 0 0 0.25rem rgba(var(--falcon-pastel-green-dark-rgb), 0.5);
              }
            `}
          </style>
          <Row className='g-3 mb-3'>
            <Col md={12}>
              <Card className='shadow-none border'>
                <Card.Header className='bg-light'>
                  <Flex justifyContent='between' alignItems='center'>
                    <span className='d-block'>Search Fields</span>
                  </Flex>
                </Card.Header>
                <Card.Body className='position-relative'>
                  <FieldsTableWrapper
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    columns={columns}
                    data={fields}
                    sortable
                    loading={refreshLoading}
                    total={total}
                    pagination
                    perPage={50}>
                    <FieldsTableFilters
                      table
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                      indices={indices}
                      selectedIndice={selectedIndice}
                      setSelectedIndice={setSelectedIndice}
                      setFetch={setFetch}
                    />
                    <FieldsTable
                      table
                      loading={loading}
                      refreshLoading={refreshLoading}
                    />
                    <div className='mt-2 d-flex justify-content-between'>
                      <FieldsTableFooter
                        table
                        rowCount={total}
                        rowsPerPageOptions={[50, 100, 250]}
                        rowsPerPageSelection
                      />
                      <FieldsTablePagination table />
                    </div>
                  </FieldsTableWrapper>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      <FieldEditModal
        show={modalShow}
        handleClose={closeModal}
        fieldId={selectedFieldId}
        fieldName={fields.find(field => field._id === selectedFieldId)?.name}
        onUpdate={() => setFetch(true)}
      />
    </>
  );
};

export default FieldsLayout;
