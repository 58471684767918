import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import UpdateOrgForm from '../forms/UpdateDashboardForm';

const UpdateDashboardModal = ({ showUpdateModal, setShowUpdateModal }) => {
  const {
    application: { isDark }
  } = useApplication();

  const { dashboard } = showUpdateModal;

  return (
    <>
      <Modal
        backdrop='static'
        keyboard={false}
        show={showUpdateModal.open}
        onHide={() =>
          setShowUpdateModal({ open: false, dashboard: {}, fetch: false })
        }
        size='lg'
        aria-labelledby='newOrgModal'
        centered>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Editing {dashboard?.title}
            <AdvancedPopover
              containerId='resetConfirmModal'
              placement='top'
              popoverText='Edit'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
        </Modal.Header>

        <Modal.Body className='p-2'>
          <UpdateOrgForm
            hasLabel
            showUpdateModal={showUpdateModal}
            setShowUpdateModal={setShowUpdateModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

UpdateDashboardModal.propTypes = {
  setShowUpdateModal: PropTypes.func,
  dashboards: PropTypes.array,
  application: PropTypes.object,
  setDashboards: PropTypes.func,
  showUpdateModal: PropTypes.object
};

export default UpdateDashboardModal;
