import LeargasAPI from 'api';
import CreatableMultiselect from 'components/common/CreatableMultiselect';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useIndices from 'hooks/admin-contexts/useIndices';
import useProducts from 'hooks/admin-contexts/useProducts';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
/**
 * product edit form
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ProductCreateForm = () => {
  const {
    indices: { data }
  } = useIndices();
  const { setProducts } = useProducts();
  const { axiosPrivate } = useAxiosPrivate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    register
  } = useForm();
  const { name, description } = watch();
  const [loading, setLoading] = useState(false);

  /**
   * @description form for user creation
   * @param {Object} data
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const onSubmit = async data => {
    try {
      setLoading(true);
      let newData = data;
      newData['indices'] = data?.indices?.map(item => item.value);
      newData['features'] = data?.features?.map(item => item.value);
      const res = await LeargasAPI.AppProducts.createProduct(
        data,
        axiosPrivate
      );
      if (res instanceof Error) throw res;
      const { message } = res.data;
      toast.success(message);
      setLoading(false);
      setProducts(prevState => ({
        ...prevState,
        addProductModal: { open: false },
        fetch: true
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);
      setProducts(prevState => ({
        ...prevState,
        addProductModal: { open: false },
        fetch: false
      }));
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className='g-3 mb-3'>
        <Col sm={6} className='required'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            {...register('name', { required: 'Name is required' })}
            type='text'
            autoComplete='off'
            className='fs--1'
            style={{ minHeight: '36px' }}
          />
          <code>{errors.description?.message}</code>
        </Col>
        <Col sm={6} className='required'>
          <Form.Label>Description</Form.Label>
          <Form.Control
            {...register('description', {
              required: 'Description is required'
            })}
            type='text'
            autoComplete='off'
            className='fs--1'
            style={{ minHeight: '36px' }}
          />
          <code>{errors.description?.message}</code>
        </Col>
        <Form.Group as={Col} sm={6} className='required'>
          <Form.Label>Addon Price</Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text>$</InputGroup.Text>
            <Controller
              name='addonPrice'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <FormControl
                  {...field}
                  type='number'
                  placeholder='Enter addon price'
                  aria-describedby='addonPrice-addon'
                  required
                />
              )}
            />
            <Form.Control.Feedback type='invalid'>
              Please enter a valid price.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} sm={6} className='required'>
          <Form.Label> Price</Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text>$</InputGroup.Text>
            <Controller
              name='price'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <FormControl
                  {...field}
                  type='number'
                  placeholder='Enter addon price'
                  aria-describedby='addonPrice-addon'
                  required
                />
              )}
            />
            <Form.Control.Feedback type='invalid'>
              Please enter a valid price.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Col sm={12} className='required'>
          <Form.Label>Features</Form.Label>
          <Controller
            name='features'
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <CreatableMultiselect
                className='fs--1'
                isMulti
                isClearable
                isSearchable
                placeholder='Select Scopes'
                onChange={selectedOptions => {
                  field.onChange(selectedOptions);
                  setValue('features', selectedOptions);
                }}
                value={field.value}
                style={{ minHeight: '36px' }}
              />
            )}
          />
          <code>{errors.Features?.message}</code>
        </Col>

        <Col sm={12}>
          <Form.Label>Indices</Form.Label>
          <Controller
            name='indices'
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <MultiSelect
                className='fs--1'
                isMulti
                isClearable
                isSearchable
                placeholder='Select Scopes'
                options={data.map(indice => ({
                  value: indice.id,
                  label: indice.alias
                }))}
                onChange={selectedOptions => {
                  field.onChange(selectedOptions);
                  setValue('indices', selectedOptions);
                }}
                value={field.value}
                style={{ minHeight: '36px' }}
              />
            )}
          />
          <code>{errors.scopes?.message}</code>
        </Col>
      </Row>
      <Flex justifyContent='end' className={'mt-3'}>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() =>
            setProducts(prevState => ({
              ...prevState,
              addProductModal: { open: false, data: {} }
            }))
          }>
          Cancel
        </Button>

        <Button
          variant='success'
          type='submit'
          size='sm'
          disabled={!name || !description}
          className='d-flex'>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Saving' : 'Submit'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

export default ProductCreateForm;
