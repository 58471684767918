import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import useArticles from 'hooks/admin-contexts/useArticles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
const { default: CardDropdown } = require('components/common/CardDropdown');

/**
 * @param {object} props
 * @param {String} props.title
 * @param {String} props.description
 * @param {Number} props.index
 * @param {Boolean} props.isLast
 * @param {String} props.className
 * @param {Object} props.category
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticleCategory = ({
  title,
  description,
  index,
  isLast,
  className,
  category
}) => {
  const { setArticles } = useArticles();

  return (
    <Flex
      direction='row'
      alignItems='center'
      className={classNames(
        className,
        'py-3 align-items-start justify-content-between flex-row',
        {
          'border-bottom': !isLast,
          'mb-2': isLast,
          'pt-0': index === 0
        }
      )}>
      <Flex className='flex-column'>
        <span className='fs--1'>{title}</span>
        <span className='text-muted fs--2'>{description}</span>
      </Flex>
      <Flex justifyContent='end' alignContent='middle'>
        <CardDropdown>
          <div className='py-2'>
            <Dropdown.Item
              onClick={() =>
                setArticles(prevState => ({
                  ...prevState,
                  editArticleCategoryModal: { open: true, data: category }
                }))
              }>
              <FontAwesomeIcon icon='pencil' className='me-2' />
              Edit Details
            </Dropdown.Item>
            <div className='dropdown-divider'></div>
            <Dropdown.Item
              className={`text-danger`}
              onClick={() => {
                setArticles(prevState => ({
                  ...prevState,
                  deleteArticleCategoryModal: { open: true, data: category }
                }));
              }}>
              <FontAwesomeIcon icon={'trash'} className='me-2' />
              Delete
            </Dropdown.Item>
          </div>
        </CardDropdown>
      </Flex>
    </Flex>
  );
};

ArticleCategory.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  index: PropTypes.number,
  isLast: PropTypes.bool,
  className: PropTypes.string,
  category: PropTypes.object
};
export default ArticleCategory;
