import React from 'react';
import BookmarksWrapper from './BookmarksWrapper';

/**
 * Renders the Bookmarks page component
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Bookmarks = () => {
  return (
    <>
      <BookmarksWrapper />
    </>
  );
};

export default Bookmarks;
