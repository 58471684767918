import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import { dashboardsCreatePresets } from 'context/ESGlobalFiltersProvider';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';

import useAxiosPrivate from 'hooks/useAxiosPrivate';

const CreateDashboardForm = ({ hasLabel, setShowCreateDashboardModal }) => {
  const [loading, setLoading] = useState(false);
  const [indices, setIndices] = useState([]);

  const { axiosPrivate } = useAxiosPrivate();

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    index: 'Virtual',
    rangePreset: '',
    rangeMax: 0,
    showDocumentTable: true,
    layout: []
  });

  useEffect(() => {
    LeargasAPI.Indices.getIndices(axiosPrivate)
      .then(indices => {
        if (indices instanceof Error) throw indices;

        setIndices(indices);
        setLoading(false);
      })
      .catch(error => {
        if (error.message.includes('Network Error')) {
          console.error('Network Error occurred.');
        }
        setLoading(false);
        setIndices([]);
      });
  }, []);

  const handleFieldChange = e => {
    let { name, value } = e.target;
    if (name === 'rangeMax') {
      setFormData(prevState => ({
        ...prevState,
        rangeMax: value,
        rangePreset: ''
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  return (
    <>
      <Form>
        <Modal.Body className='p-2'>
          <Row className='g-3 mb-3'>
            <Form.Group as={Col} sm={4} className='required'>
              {hasLabel && <Form.Label>Title</Form.Label>}
              <Form.Control
                placeholder={!hasLabel ? 'Title' : ''}
                value={formData.title}
                name='title'
                onChange={handleFieldChange}
                type='text'
                autoComplete='off'
                className='fs--1'
                style={{ minHeight: '36px' }}
                required
              />
            </Form.Group>

            <Form.Group as={Col} sm={4} className='required'>
              {hasLabel && <Form.Label>Index</Form.Label>}
              <Form.Select
                name={'index'}
                onChange={handleFieldChange}
                value={formData.index}>
                <option value=''>Please select</option>
                {indices.map((index, i) => {
                  return (
                    <option value={index?.id} key={i}>
                      {index?.alias}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group
              as={Col}
              sm={4}
              className='required flex justify-center items-end'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginBottom: '5px'
              }}>
              <Form.Check
                type='switch'
                inline
                name='showDocumentTable'
                value={formData.showDocumentTable}
                className=''
                checked={formData.showDocumentTable}
                onChange={e => {
                  setFormData(prevState => ({
                    ...prevState,
                    showDocumentTable: e.target.checked
                  }));
                }}
              />
              {hasLabel && (
                <Form.Label className='mb-0'>showDocumentTable</Form.Label>
              )}
            </Form.Group>
            <Form.Group as={Col} sm={7} className='required'>
              {hasLabel && <Form.Label>Range Max</Form.Label>}
              <Form.Control
                placeholder={!hasLabel ? 'RangeMax' : ''}
                value={formData.rangeMax}
                name='rangeMax'
                onChange={handleFieldChange}
                type='number'
                autoComplete='off'
                className='fs--1'
                style={{ minHeight: '36px' }}
                required
              />
            </Form.Group>
            <Form.Group as={Col} sm={5} className='required'>
              {hasLabel && <Form.Label>Please Select</Form.Label>}
              <Form.Select
                name={'rangePreset'}
                onChange={handleFieldChange}
                value={formData.rangePreset}>
                <option value=''></option>
                {dashboardsCreatePresets.map((item, i) => {
                  return (
                    <>
                      {item.RangeSeconds < formData.rangeMax && (
                        <option value={item?.TimePreset} key={i}>
                          {item?.TimePreset}
                        </option>
                      )}
                    </>
                  );
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} sm={12} className='required'>
              {hasLabel && <Form.Label>Description</Form.Label>}
              <Form.Control
                placeholder={!hasLabel ? 'Description' : ''}
                value={formData.description}
                name='description'
                onChange={handleFieldChange}
                type='text'
                as={'textarea'}
                autoComplete='off'
                className='fs--1'
                style={{ minHeight: '36px' }}
                required
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() =>
              setShowCreateDashboardModal({ open: false, fetch: false })
            }>
            Close
          </Button>
          <Button
            variant='primary'
            className='d-flex'
            disabled={
              !formData.title ||
              !formData.description ||
              !formData.index ||
              !formData.rangePreset
            }
            onClick={async () => {
              setLoading(true);
              try {
                const res = await LeargasAPI.Dashboards.createDashboard(
                  formData,
                  axiosPrivate
                );
                if (res instanceof Error) throw res;
                setLoading(false);
                let message = res?.data?.message;
                setShowCreateDashboardModal({ open: false, fetch: true });
                toast.success(message);
              } catch (error) {
                if (error.message.includes('Network Error')) {
                  console.error('Network Error occurred.');
                }
                setLoading(false);

                setShowCreateDashboardModal({ open: false, fetch: false });
                if (error?.response?.data?.message) {
                  toast.error(error?.response?.data?.message);
                } else {
                  toast.error('Something went wrong!');
                }
              }
            }}>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <ButtonSpinner spinning={loading} />
              <span>{loading ? 'Creating' : 'Create '}</span>
            </Flex>
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

CreateDashboardForm.propTypes = {
  hasLabel: PropTypes.bool,
  setShowCreateDashboardModal: PropTypes.func,
  dashboards: PropTypes.array
};

export default CreateDashboardForm;
