export const getColumns = async (
  setColumns,
  columnDefaults,
  columnActions,
  setShowUpdateModal,
  setShowDeleteDashboardModal,
  setOnListChange,
  axiosPrivate
) => {
  setColumns([
    ...columnDefaults(setOnListChange, axiosPrivate),
    ...columnActions(setShowUpdateModal, setShowDeleteDashboardModal)
  ]);
};
