import Breadcrumbs from 'components/common/Breadcrumb';
import Footer from 'components/common/Footer';
import NavbarTop from 'components/navbar/top/NavbarTop';
import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

/**
 * Represents the IntroductionLayout component.
 *
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const IntroductionLayout = () => {
  const { hash } = useLocation();

  /**
   * Scroll to the element with the id from the hash
   * when the component is mounted.
   *
   * @returns {void}
   * @since 0.1.0-beta.4
   * @version 0.1.0-beta.4
   */
  useEffect(() => {
    var timeout = setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className='content position-relative'>
      <NavbarTop />
      <div className='content-wrapper'>
        <Breadcrumbs></Breadcrumbs>

        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default IntroductionLayout;
