import { makeRequest } from 'api/connection/requestAction';

class Organizations {
  constructor(route) {
    this.route = route;
  }

  async createOrg(orgData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.createAppOrg.method,
        this.route.createAppOrg.path,
        orgData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async deleteOrgs(id, deleting, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.deleteAppOrg.method,
        `${this.route.deleteAppOrg.path}/${id}`,
        {
          deleting: deleting
        }
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async editOrg(id, orgData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateAppOrg.method,
        `${this.route.updateAppOrg.path}/${id}`,
        orgData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async getOrganization(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getAppOrganization.method,
        `${this.route.getAppOrganization.path}/${id}`
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async getOrgs(axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getAppOrgs.method,
        this.route.getAppOrgs.path
      );
      if (res instanceof Error) throw res;
      let orgs = [];
      res?.data?.data?.map(org => {
        orgs.push(org);
      });
      return orgs;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default Organizations;
