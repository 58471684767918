import React from 'react';
import ChangeLogWrapper from './ChangeLogWrapper';

/**
 * Renders the ChangeLog page component
 *
 * @returns {JSX.Element} The rendered ChangeLog page component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const ChangeLog = () => {
  return (
    <>
      <ChangeLogWrapper />
    </>
  );
};

export default ChangeLog;
