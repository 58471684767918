import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Nav,
  InputGroup,
  FormControl,
  Accordion
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const initialBookmarks = [
  {
    id: '1',
    title: 'Starred',
    icon: 'star',
    type: 'folder',
    children: [
      {
        id: '1',
        title: 'Bookmark 1',
        description:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galle',
        type: 'bookmark'
      },
      {
        id: '2',
        title: 'Bookmark 2',
        description:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        type: 'bookmark'
      }
    ]
  },
  {
    id: '2',
    title: 'Favorites ',
    type: 'folder',
    children: [
      {
        id: '4',
        title: 'Bookmark 1.1',
        description:
          'DLorem Ipsum is simply dummy text of the printing and typesetting industry.',
        type: 'bookmark'
      },
      {
        id: '5',
        title: 'Bookmark 1.2',
        description:
          'DLorem Ipsum is simply dummy text of the printing and typesetting industry.',
        type: 'bookmark'
      },
      {
        id: '6',
        title: 'Folder 1.1',
        type: 'folder',
        children: [
          {
            id: '7',
            title: 'Bookmark 1.1.1',
            description: 'DLorem Ipsum is simply dummy text of',
            type: 'bookmark'
          },
          {
            id: '8',
            title: 'Bookmark 1.1.2',
            description: 'DLorem Ipsum is simply dummy text of',
            type: 'bookmark'
          }
        ]
      }
    ]
  },
  {
    id: '3',
    title: 'Personal ',
    type: 'folder',
    children: [
      {
        id: '4',
        title: 'Bookmark 1.1',
        description:
          'DLorem Ipsum is simply dummy text of the printing and typesetting industry.',
        type: 'bookmark'
      },
      {
        id: '5',
        title: 'Bookmark 1.2',
        description:
          'DLorem Ipsum is simply dummy text of the printing and typesetting industry.',
        type: 'bookmark'
      },
      {
        id: '6',
        title: 'very personal',
        type: 'folder',
        children: [
          {
            id: '7',
            title: 'Bookmark 1.1.1',
            description: 'DLorem Ipsum is simply dummy text of',
            type: 'bookmark'
          },
          {
            id: '8',
            title: 'Bookmark 1.1.2',
            description: 'DLorem Ipsum is simply dummy text of',
            type: 'bookmark'
          }
        ]
      }
    ]
  }
];

const BookmarkItem = ({ bookmark, level }) => {
  const isFolder = bookmark.type === 'folder';

  return (
    <div>
      {isFolder ? (
        <Accordion className='w-100 mb-2 p-0'>
          <Accordion.Header className='bg-light' style={{ cursor: 'pointer' }}>
            <span className='text-600 fs--1'>
              {bookmark.icon && (
                <FontAwesomeIcon icon='star' className='me-1' />
              )}
              {bookmark.title}
            </span>
          </Accordion.Header>
          <Accordion.Body className='p-0'>
            {bookmark.description}
            {bookmark.children && (
              <Accordion className='w-100'>
                {bookmark.children.map(child => (
                  <BookmarkItem
                    key={child.id}
                    bookmark={child}
                    level={level + 1}
                  />
                ))}
              </Accordion>
            )}
          </Accordion.Body>
        </Accordion>
      ) : (
        <Nav className='mb-1'>
          <Nav.Link className='pb-0'>
            <Link className='fs--1 '>
              <p className=' fw-semi-bold mb-0 text-900'>{bookmark.title}</p>
              {bookmark.description && (
                <p className='mb-0 text-muted fs--2'>{bookmark.description}</p>
              )}
            </Link>
          </Nav.Link>
        </Nav>
      )}
    </div>
  );
};

BookmarkItem.propTypes = {
  bookmark: PropTypes.object,
  level: PropTypes.object
};

const BookmarksBar = () => {
  const [bookmarks] = useState(initialBookmarks);
  return (
    <div className='navbar-vertical-content scrollbar bookmarks-panel'>
      <InputGroup className='mb-3'>
        <FormControl
          placeholder='Search Bookmarks...'
          size='sm'
          className='shadow-none'
        />
      </InputGroup>

      <Flex direction='row' justifyContent='between'>
        <Flex direction='row' justifyContent='end'>
          <Button variant='link' size='sm' className='p-0 text-700 hover-900'>
            <FontAwesomeIcon icon='file-circle-plus' width='1.6em' />
          </Button>
          <Button variant='link' size='sm' className='p-0 text-700 hover-900'>
            <FontAwesomeIcon icon='folder-plus' width='1.6em' />
          </Button>
        </Flex>
        <Flex direction='row' justifyContent='end'>
          <Button variant='link' size='sm' className='p-0 text-700 hover-900'>
            <FontAwesomeIcon icon='trash' width='1.6em' />
          </Button>
          <Button variant='link' size='sm' className='p-0 text-700 hover-900'>
            <FontAwesomeIcon icon='cog' width='1.6em' />
          </Button>
        </Flex>
      </Flex>

      {/* <FrequentList frequentBookmarks={initialBookmarks.slice(0, 2)} /> */}
      <Flex className={'mt-3'}>
        <Accordion className='w-100'>
          {bookmarks?.map(bookmark => (
            <BookmarkItem key={bookmark.id} level={0} bookmark={bookmark} />
          ))}
        </Accordion>
      </Flex>
    </div>
  );
};

export default BookmarksBar;
