import ReportsProvider from 'context/user-contexts/UserReportsProvider';
import PropTypes from 'prop-types';
import React from 'react';
// import ReportsLayout from './ReportsLayout';
import { pdfjs } from 'react-pdf';
import GeneralReportList from './Components/GeneralReportList';

import ModalReportView from './Components/ReportsViewModal';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

/**
 * Renders the GeneralReportWrapper component
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const GeneralReportWrapper = props => {
  return (
    <ReportsProvider>
      {props.children}
      <GeneralReportList />
      <ModalReportView />
    </ReportsProvider>
  );
};

GeneralReportWrapper.propTypes = {
  children: PropTypes.node
};

export default GeneralReportWrapper;
