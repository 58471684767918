// import { axiosPrivate } from 'api/connection/axios';
import { apiRoutes } from 'config/routeSettings';

class Scans {
  constructor(route) {
    this.route = route;
  }
  getAll(axiosPrivate) {
    return axiosPrivate.get(apiRoutes.SCANS);
  }

  get(id, axiosPrivate) {
    return axiosPrivate.get(`${apiRoutes.SCANS}/${id}`);
  }

  create(data, axiosPrivate) {
    return axiosPrivate.post(apiRoutes.SCANS, data);
  }

  update(id, data, axiosPrivate) {
    return axiosPrivate.put(`${apiRoutes.SCANS}/${id}`, data);
  }

  delete(id, axiosPrivate) {
    return axiosPrivate.delete(`${apiRoutes.SCANS}/${id}`);
  }

  deleteAll(axiosPrivate) {
    return axiosPrivate.delete(apiRoutes.SCANS);
  }
}

export default Scans;
