import logo from 'assets/img/leargas-clover.png';
import Flex from 'components/common/Flex';
import ReleaseActions from 'components/dashboards/System/Application/Manage/Releases/ReleaseActions';
import ReleaseNotes from 'components/dashboards/System/Application/Manage/Releases/ReleaseNotes';
import ReleaseNotesAddButton from 'components/dashboards/System/Application/Manage/Releases/ReleaseNotesAddButton';
import { version } from 'context/ApplicationProvider';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Image, Row } from 'react-bootstrap';

/**
 * Renders a ChangeLog component
 *
 * @param {Object} props - The component props
 * @param {string} props.tag - The tag of the change log
 * @param {string} props.title - The title of the change log
 * @param {string} props.description - The description of the change log
 * @param {boolean} props.released - Indicates if the change log is released
 * @param {string} props.releasedAt - The release date of the change log
 * @param {Object} props.release - The release information
 * @param {number} props.index - The index of the change log
 * @returns {JSX.Element} The rendered ChangeLog component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const ChangeLog = ({
  tag,
  title,
  description,
  released,
  releasedAt,
  release,
  index
}) => {
  return (
    <Card className='mb-3 shadow-none' key={index}>
      <Card.Header>
        <Flex alignItems='center'>
          <Flex className='pe-2'>
            <Image fluid src={logo} width={32} />
          </Flex>
          <Flex direction='column' justifyContent='start' className='fs--1'>
            <h5 className='m-0'>
              <Flex alignItems='center'>
                <Flex direction='row' alignItems='center'>
                  <code className='fw-bold fs-0 m-0 me-2'>
                    {tag}{' '}
                    {tag === ['v', version].join('') ? (
                      <span> (Current)</span>
                    ) : null}
                  </code>
                  {title ? (
                    <span className='fs-0 text-primary'> - {title}</span>
                  ) : null}
                </Flex>
                <Flex>
                  <ReleaseActions release={release} />
                </Flex>
              </Flex>
            </h5>
            <p className='m-0 text-muted'>
              {released
                ? moment(releasedAt).format('MMMM Do YYYY')
                : 'Under Development'}
            </p>
          </Flex>
        </Flex>
      </Card.Header>
      <Card.Body className='bg-light pt-0'>
        {description ? <p className='fs--1 mt-3'>{description}</p> : null}
        <Row>
          <ReleaseNotes release={release} />
          <ReleaseNotesAddButton releaseId={release.id} />
        </Row>
      </Card.Body>
    </Card>
  );
};

ChangeLog.propTypes = {
  tag: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  released: PropTypes.bool,
  releasedAt: PropTypes.string.isRequired,
  release: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default ChangeLog;
