import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showTimeSelectOn } from 'context/ESGlobalFiltersProvider';
import { calculateSeconds } from 'helpers/utils';
import useESGlobalFilters from 'hooks/useGlobalFilters';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 *
 * @returns {JSX.Element} The rendered button component
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const TimeLabel = ({ className, ...rest }) => (
  <span className={className} {...rest}>
    <FontAwesomeIcon icon='clock' className='me-1' />
    Time
  </span>
);

TimeLabel.propTypes = {
  className: PropTypes.string
};

/**
 *
 * @returns {JSX.Element} The rendered button component
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const DateTimePickerInput = forwardRef(({ value, onClick, ...rest }, ref) => {
  return (
    <>
      <Form.Control
        size='sm'
        ref={ref}
        onClick={onClick}
        defaultValue={value}
        className='w-100 ps-4 fs--1 mb-0'
      />
      <FontAwesomeIcon
        icon='arrows-up-to-line'
        className='position-absolute top-50 translate-middle-y ms-2'
        transform={`shrink-3 rotate-${rest?.iconRotation}`}
      />
    </>
  );
});

DateTimePickerInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  iconRotation: PropTypes.number,
  allowedLimit: PropTypes.number,
  checkerIndices: PropTypes.array,
  index: PropTypes.string
};

/**
 *
 * @returns {JSX.Element} The rendered button component
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const RangeCustomSelect = () => {
  const {
    esGlobalFilters: { index, preset, gte, allowedLimit, checkerIndices, lte },
    setESGlobalFilters
  } = useESGlobalFilters();
  const location = useLocation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const setGte = date => {
    if (date === gte) return;
    if (date >= lte) {
      toast.error('Start date must be before end date');
      return;
    }
    if (
      checkerIndices &&
      checkerIndices.includes(index) &&
      allowedLimit <= calculateSeconds(date.toDate(), lte.toDate())
    ) {
      toast.error(`Please select a shorter time range for this index`);
      return;
    } else {
      setESGlobalFilters(prevState => ({
        ...prevState,
        gte: moment(date).toDate(),
        preset: 'custom_range'
      }));
    }
  };

  const setLte = date => {
    if (date === lte) return;
    if (date <= gte) {
      toast.error('End date must be after start date');
      return;
    }
    if (
      checkerIndices &&
      checkerIndices.includes(index) &&
      allowedLimit <= calculateSeconds(gte.toDate(), date.toDate())
    ) {
      toast.error(`Please select a shorter time range for this index`);
      return;
    } else {
      setESGlobalFilters(prevState => ({
        ...prevState,
        lte: moment(date).toDate(),
        preset: 'custom_range'
      }));
    }
  };

  useEffect(() => {
    setStartDate(gte);
    setEndDate(lte);
  }, []);

  return showTimeSelectOn.includes(location.pathname) ? (
    <>
      <Col
        md={4}
        xxl={6}
        className={`my-0 py-0 ${preset === 'custom_range' ? '' : 'd-none'}`}>
        <Form.Group className='mb-0'>
          <Form.Label className='fs--1'>Custom Range Start</Form.Label>
          <DatePicker
            wrapperClassName='position-relative w-100'
            selected={startDate}
            // startDate={gte}
            // endDate={lte}
            onChange={date => {
              setStartDate(date);
              setGte(date);
            }}
            // onSelect={date => setGte(date)}
            timeInputLabel={<TimeLabel className='text-nowrap text-600' />}
            dateFormat='yyyy-MM-dd HH:mm:ss'
            showTimeSelect
            timeFormat='HH:mm'
            timeIntervals={15}
            customInput={<DateTimePickerInput iconRotation={-90} />}
          />
        </Form.Group>
      </Col>
      <Col
        md={4}
        xxl={6}
        className={`my-0 py-0 ${preset === 'custom_range' ? '' : 'd-none'}`}>
        <Form.Group className='mb-0'>
          <Form.Label className='fs--1'>Custom Range End</Form.Label>
          <DatePicker
            wrapperClassName='position-relative w-100 mb-2'
            selected={endDate}
            // startDate={gte}
            // endDate={lte}
            onChange={date => {
              setEndDate(date);
              setLte(date);
            }}
            // onSelect={date => setLte(date)}
            timeInputLabel={<TimeLabel className='text-nowrap text-600' />}
            dateFormat='yyyy-MM-dd HH:mm:ss'
            showTimeSelect
            timeFormat='HH:mm'
            timeIntervals={15}
            customInput={<DateTimePickerInput iconRotation={90} />}
          />
        </Form.Group>
      </Col>
    </>
  ) : null;
};

export default RangeCustomSelect;
