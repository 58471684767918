import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';

// import classNames from 'classnames';
import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import Flex from 'components/common/Flex';
import { Badge } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { filtersOperators } from 'context/FiltersProvider/settings';
import { truncateMiddle } from 'helpers/utils';

const renderExpandedRow = row => {
  return (
    <div className='fs--1 px-2'>
      <Flex direction='column' className='mb-1 gap-2'>
        {/* Description */}
        {row?.description && (
          <div>
            <div className='text-900'>Description</div>
            {row.description && (
              <span className='text-700'>{row.description}</span>
            )}
          </div>
        )}

        {/* Display Indices */}
        {row.indices.length > 0 && (
          <div>
            <div className='text-900'>Indices</div>
            {row.indices.map((index, i) => (
              <span key={i} className='text-700'>
                {index.alias}
              </span>
            ))}
          </div>
        )}

        {/* Display Filters */}
        {row?.filters.length > 0 && (
          <div>
            <div className='text-900 mb-1'>Filters</div>
            <Flex
              direction='row'
              wrap='wrap'
              alignContent='center'
              alignItems='center'
              justifyContent='start'
              className='h-100'>
              {row.filters?.map((filter, index) => (
                <Badge
                  key={index}
                  bg={classNames({
                    'soft-pastel-gray': !filter.enabled,
                    'soft-pastel-green':
                      (filter.inclusion &&
                        !filtersOperators[filter?.operator]?.isNegation) ||
                      (!filter.inclusion &&
                        filtersOperators[filter?.operator]?.isNegation),
                    'soft-pastel-yellow':
                      (!filter.inclusion &&
                        !filtersOperators[filter?.operator]?.isNegation) ||
                      (filter.inclusion &&
                        filtersOperators[filter?.operator]?.isNegation)
                  })}
                  className={classNames(
                    'ps-1 fs--2 fw-normal cursor-default d-flex justify-content-center align-items-center border opacity-100 me-1 mb-1',
                    {
                      'text-pastel-gray hover-border-soft-pastel-gray hover-bg-pastel-gray-light':
                        !filter.enabled,
                      'text-pastel-green-dark hover-border-soft-pastel-green hover-bg-pastel-green-light':
                        (filter.inclusion &&
                          !filtersOperators[filter?.operator]?.isNegation) ||
                        (!filter.inclusion &&
                          filtersOperators[filter?.operator]?.isNegation),
                      'text-pastel-yellow-dark hover-border-soft-pastel-yellow hover-bg-pastel-yellow-light':
                        (!filter.inclusion &&
                          !filtersOperators[filter?.operator]?.isNegation) ||
                        (filter.inclusion &&
                          filtersOperators[filter?.operator]?.isNegation)
                    }
                  )}
                  style={{
                    textDecoration: filter.enabled ? 'none' : 'line-through'
                  }}>
                  {/* Show the pinned icon */}
                  {filter.pinned && (
                    <FontAwesomeIcon icon='thumb-tack' className='me-1' />
                  )}
                  {/* Display Custom Label or Field-Value Pair */}
                  {filter.useCustomLabel && filter.customLabel ? (
                    <span className='fw-normal'>{filter.customLabel}</span>
                  ) : (
                    <>
                      "{filter?.field?.accessor}"{' '}
                      {filter.inclusion
                        ? filtersOperators[filter?.operator]?.symbol
                        : filtersOperators[
                            filtersOperators[filter?.operator]?.inversion
                          ]?.symbol ||
                          filtersOperators[filter?.operator]?.symbol}{' '}
                      <span className='ms-1 fw-normal'>
                        "
                        {Array.isArray(filter.value)
                          ? filter.value.map((item, i) => (
                              <span key={i}>
                                {item}
                                {i !== filter.value.length - 1 ? ', ' : ''}
                              </span>
                            ))
                          : truncateMiddle(filter.value)}
                        "
                      </span>
                    </>
                  )}
                </Badge>
              ))}
            </Flex>
          </div>
        )}
        {row?.fields.length > 0 && (
          <div>
            <div className='text-900'>Fields</div>
            <Flex
              direction='row'
              wrap='wrap'
              alignContent='center'
              alignItems='center'
              justifyContent='start'
              className='h-100 text-700'>
              {row.fields.map((field, index) => {
                return index < row.fields.length - 1
                  ? `${field.name}, `
                  : field.name;
              })}
            </Flex>
          </div>
        )}
        {row._dashboard && (
          <div>
            <div className='text-900'>Dashboard</div>
            <Flex
              direction='row'
              wrap='wrap'
              alignContent='center'
              alignItems='center'
              justifyContent='start'
              className='h-100 text-700'>
              {row._dashboard}
            </Flex>
          </div>
        )}
      </Flex>
    </div>
  );
};

/**
 * Renders the SavedSearchesTable component
 *
 * @param {object} props
 * @param {Function} props.getTableProps
 * @param {Array} props.headers
 * @param {Array} props.page
 * @param {Function} props.prepareRow
 * @param {String} props.headerClassName
 * @param {String} props.rowClassName
 * @param {Function} props.tableProps
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const SavedSearchesTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  tableProps
}) => {
  const [expandedRow, setExpandedRow] = useState(null);
  return (
    <div className='table-responsive'>
      <Table
        responsive
        size='sm'
        {...getTableProps(tableProps)}
        className='simplebar-scroll-content'
        data-simplebar>
        <thead className={headerClassName}>
          <tr>
            <th style={{ width: '30px' }}> </th>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(
                  column.getSortByToggleProps(column.headerProps)
                )}>
                {column.render('Header')}
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className='sort desc' />
                    ) : (
                      <span className='sort asc' />
                    )
                  ) : (
                    <span className='sort' />
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            let rowClassName = row.original?.isDeleted
              ? 'soft-danger'
              : 'transparent';
            return (
              <React.Fragment key={i}>
                <>
                  <tr key={i} className={rowClassName} {...row.getRowProps()}>
                    <td
                      style={{ width: '30px' }}
                      className={classNames('align-top py-1', {
                        'border-0': i === page.length - 1
                      })}
                      onClick={() =>
                        setExpandedRow(expandedRow !== i ? i : null)
                      }>
                      <FontAwesomeIcon
                        icon={
                          expandedRow === i ? faChevronDown : faChevronRight
                        }
                        className='cursor-pointer'
                        transform='up-1'
                        size='sm'
                      />
                    </td>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          key={index}
                          {...cell.getCellProps(cell.column.cellProps)}
                          style={
                            i === page.length - 1
                              ? {
                                  border: 'none',
                                  backgroundColor: `${getColor(rowClassName)}`
                                }
                              : { backgroundColor: `${getColor(rowClassName)}` }
                          }>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {expandedRow === i && (
                    <tr>
                      <td colSpan='6' className='px-4 py-3'>
                        {renderExpandedRow(row?.original)}{' '}
                        {/* Call the expanded row rendering function */}
                      </td>
                    </tr>
                  )}
                </>
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

SavedSearchesTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  tableProps: PropTypes.object
};

export default SavedSearchesTable;
