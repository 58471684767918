import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReleaseHeading from './ReleaseHeading';
import ReleaseDescription from './ReleaseDescription';
import ReleaseBody from './ReleaseBody';
import { Row, Col } from 'react-bootstrap';

/**
 * Renders the Release component
 *
 * @param {object} props Component properties
 * @param {object} props.release The release object
 * @param {boolean} props.isLast Is this the last release in the list?
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Release = ({ release, isLast }) => {
  return (
    <>
      <Row
        className={classNames('g-3 mb-3 mt-3 recent-activity-timeline', {
          'pb-card': !isLast,
          'recent-activity-timeline-past recent-activity-timeline-secondary':
            release.released === false,
          'recent-activity-timeline-current recent-activity-timeline-primary':
            release.released === true
        })}>
        <Col>
          <Row
            className={classNames('g-3', {
              'border-bottom border-dashed-bottom pb-card': !isLast
            })}>
            <Col className='ps-5'>
              <ReleaseHeading release={release} />
              <ReleaseDescription release={release} />
              <ReleaseBody release={release} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

Release.propTypes = {
  release: PropTypes.object.isRequired,
  isLast: PropTypes.bool
};

export default Release;
