import LeargasAPI from 'api';
import CreatableMultiselect from 'components/common/CreatableMultiselect';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useResources from 'hooks/admin-contexts/useResources';
import React, { useState } from 'react';
import { Button, Col, Form, FormText, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import useAxiosPrivate from 'hooks/useAxiosPrivate';

/**
 * Resources Add Form
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const FormResourceAdd = () => {
  const {
    resources: { Executors, Operations },
    setResources
  } = useResources();
  const { register, handleSubmit, watch } = useForm();

  const { axiosPrivate } = useAxiosPrivate();
  const { name, description } = watch();
  const [selfControlData, setSelfControlData] = useState({
    allowedExecutors: [],
    allowedModifiers: [],
    allowedOperations: [],
    allowedWildcardModifiers: [],
    requiredModifiers: []
  });
  const [loading, setLoading] = useState(false);

  /**
   *
   * @param {Object} data
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const onSubmitData = async data => {
    let formData = selfControlData;
    let convertedData = {};
    for (const key in formData) {
      if (
        Object.prototype.hasOwnProperty.call(formData, key) &&
        Array.isArray(formData[key])
      ) {
        convertedData[key] = formData[key].map(item => item.value);
      }
    }
    let combineData = { ...data, ...convertedData };
    try {
      setLoading(true);
      let res = await LeargasAPI.Resources.createAppResource(
        combineData,
        axiosPrivate
      );
      if (res instanceof Error) throw res;
      const { message } = res.data;
      toast.success(message);
      setLoading(false);
      setResources(prevState => ({
        ...prevState,
        fetch: true,

        addResourceModal: { open: false }
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  /**
   *
   * @param {string} exe
   * @param {string} name
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const createExeSelect = (exe, name) => {
    let alreadyData = selfControlData[name];
    alreadyData.push({
      value: exe,
      label: exe
    });
    setSelfControlData(prevState => ({
      ...prevState,
      [name]: alreadyData
    }));
  };

  /**
   *
   * @param {string} exe
   * @param {string} name
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const handleExeChange = (exe, name) => {
    setSelfControlData(prevState => ({
      ...prevState,
      [name]: exe
    }));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitData)}>
      <Form.Group className='required mb-2'>
        <Form.Label>Name</Form.Label>
        <Form.Control
          placeholder={'Name'}
          name='name'
          {...register('name')}
          type='text'
          autoComplete='off'
          className='fs--1'
          style={{ minHeight: '36px' }}
          required
        />
        <FormText className='text-muted'>
          The name of the resource. E.g. <code>User</code>,{' '}
          <code>Organization</code>, <code>Product</code>, <code>Role</code>,{' '}
          <code>Team</code>
        </FormText>
      </Form.Group>

      <Form.Group className='required mb-2'>
        <Form.Label>Description </Form.Label>
        <Form.Control
          placeholder={'Description'}
          name='description'
          {...register('description')}
          // type='textarea'
          autoComplete='off'
          className='fs--1'
          style={{ minHeight: '36px' }}
          required
          as='textarea'
          rows={2}
        />
        <FormText className='text-muted'>
          A brief description of the resource is and what it is used for.
        </FormText>
      </Form.Group>

      <Row className='g-2'>
        <Form.Group as={Col} sm={6} className='mb-2'>
          <Form.Label className='mb-0'>Allowed Executors</Form.Label>
          <CreatableMultiselect
            className='fs--1'
            isMulti
            isClearable
            isSearchable
            placeholder='Please select'
            options={Executors.map(exe => ({
              value: exe,
              label: exe
            }))}
            // onCreateOption={createExeSelect}
            value={selfControlData.allowedExecutors}
            onChange={exes => handleExeChange(exes, 'allowedExecutors')}
            style={{ minHeight: '36px' }}
          />
          <FormText className='text-muted'>
            Executors are the entity types that can be granted this resource.
          </FormText>
        </Form.Group>

        <Form.Group as={Col} sm={6} className='mb-2'>
          <Form.Label className='mb-0'>Allowed Operations</Form.Label>
          <CreatableMultiselect
            className='fs--1'
            isMulti
            isClearable
            isSearchable
            placeholder='Please select'
            options={Operations.map(exe => ({
              value: exe,
              label: exe
            }))}
            // onCreateOption={createExeSelect}
            value={selfControlData.allowedOperations}
            onChange={exes => handleExeChange(exes, 'allowedOperations')}
            style={{ minHeight: '36px' }}
          />
          <FormText className='text-muted'>
            Operations that can be performed on this resource.
          </FormText>
        </Form.Group>
      </Row>

      <Row className='g-2'>
        <Form.Group as={Col} sm={6} className='mb-2'>
          <Form.Label className='mb-0'>Allowed Modifiers</Form.Label>
          <CreatableMultiselect
            className='fs--1'
            isMulti
            isClearable
            isSearchable
            placeholder='Please select'
            onCreateOption={exes => createExeSelect(exes, 'allowedModifiers')}
            value={selfControlData.allowedModifiers}
            onChange={exes => handleExeChange(exes, 'allowedModifiers')}
            style={{ minHeight: '36px' }}
          />
          <FormText className='text-muted'>
            Operations that can be performed on this resource.
          </FormText>
        </Form.Group>

        <Form.Group as={Col} sm={6} className='mb-2'>
          <Form.Label className='mb-0'>Allowed Wildcard Modifiers</Form.Label>
          <CreatableMultiselect
            className='fs--1'
            isMulti
            isClearable
            isSearchable
            placeholder='Please select'
            // options={selfControlData?.allowedWildcardModifiers?.map(exe => ({
            //   value: exe,
            //   label: exe
            // }))}
            onCreateOption={exes =>
              createExeSelect(exes, 'allowedWildcardModifiers')
            }
            value={selfControlData.allowedWildcardModifiers}
            onChange={exes => handleExeChange(exes, 'allowedWildcardModifiers')}
            style={{ minHeight: '36px' }}
          />
          <FormText className='text-muted'>
            Modifiers to place further restrictions on resulting scopes.
          </FormText>
        </Form.Group>
      </Row>

      <Form.Group className='mb-2'>
        <Form.Label className='mb-0'>Required Modifiers</Form.Label>
        <CreatableMultiselect
          className='fs--1'
          isMulti
          isClearable
          isSearchable
          placeholder='Please select'
          // options={selfControlData?.allowedWildcardModifiers.map(exe => ({
          //   value: exe,
          //   label: exe
          // }))}
          onCreateOption={exes => createExeSelect(exes, 'requiredModifiers')}
          value={selfControlData.requiredModifiers}
          onChange={exes => handleExeChange(exes, 'requiredModifiers')}
          style={{ minHeight: '36px' }}
        />
        <FormText className='text-muted'>
          Required modifiers are ones that must be present in order for the
          resulting scopes to be valid.
        </FormText>
      </Form.Group>

      <Flex justifyContent='end' className={'mt-3'}>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() =>
            setResources(prevState => ({
              ...prevState,
              addResourceModal: { open: false }
            }))
          }>
          Cancel
        </Button>

        <Button
          variant='success'
          size='sm'
          type='submit'
          className='d-flex'
          disabled={!name || !description}>
          {' '}
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Adding' : 'Add'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

export default FormResourceAdd;
