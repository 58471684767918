import React from 'react';
import PropTypes from 'prop-types';
import ResourcesProvider from '../../../../../../context/admin-contexts/ResourcesProvider';
import ResourcesLayout from './ResourcesLayout';
import ModalResourceAdd from './components/modals/ResourcesAddModal';
import ModalResourceEdit from './components/modals/ResourcesEditModal';
import ModalResourceDelete from './components/modals/ResourcesDeleteModal';

/**
 * ResourcesWrapper
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ResourcesWrapper = props => {
  return (
    <ResourcesProvider>
      {props.children}
      <ResourcesLayout />
      <ModalResourceAdd />
      <ModalResourceEdit />
      <ModalResourceDelete />
    </ResourcesProvider>
  );
};

ResourcesWrapper.propTypes = { children: PropTypes.node };

export default ResourcesWrapper;
