import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';

/**
 * Renders the NotificationLayout component
 *
 * @returns {JSX.Element} The rendered NotificationLayout component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const NotificationLayout = () => {
  return (
    <>
      <WidgetSectionTitle
        title='Application Notifications'
        transform='down-3'
        titleClassName='mb-2'
      />
    </>
  );
};

export default NotificationLayout;
