import { useContext } from 'react';
import { AppliancesContext } from 'context/admin-contexts/AppliancesProvider';

/**
 * Custom hook to use the AppliancesContext
 * @returns {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const useAppliances = () => {
  return useContext(AppliancesContext);
};

export default useAppliances;
