import Flex from 'components/common/Flex';
import { useSearchFilters } from 'context/FiltersProvider';
import React from 'react';
import SearchFilterBadge from './SearchFilterBadge';

/**
 * SearchFiltersList component that renders a list of search filters.
 *
 * @returns {React.Element} The rendered SearchFiltersList component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const SearchFiltersList = () => {
  const {
    searchFilters: { filters }
  } = useSearchFilters();

  return (
    <>
      <Flex direction='column' className='mb-1'>
        <Flex
          direction='row'
          wrap='wrap'
          alignContent='center'
          alignItems='center'
          justifyContent='start'
          className='h-100'>
          {filters?.map((filter, index) => {
            return <SearchFilterBadge key={index} filter={filter} />;
          })}
        </Flex>
      </Flex>
    </>
  );
};

export default SearchFiltersList;
