import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Render button to add a new button
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const AddButton = ({ label, ...rest }) => {
  return (
    <Button
      {...rest}
      size='sm'
      variant='link'
      className='position-relative cursor-pointer icon-item icon-item-sm rounded-circle border border-primary shadow-none'>
      <FontAwesomeIcon icon='plus' className='text-primary' />
      {label && (
        <span className='position-absolute start-100 ms-2 text-primary text-nowrap fs--1'>
          {label}
        </span>
      )}
    </Button>
  );
};

AddButton.propTypes = {
  label: PropTypes.string
};

export default AddButton;
