import React from 'react';
import useAuthentication from 'hooks/useAuthentication';
import useReleases from 'hooks/admin-contexts/useReleases';
import { Button, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const allowedRoles = ['super-admin', 'admin'];

/**
 * Renders the ReleaseAddButton component
 *
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ReleaseAddButton = () => {
  const { setReleases } = useReleases();

  const {
    authentication: { roles }
  } = useAuthentication();

  return (
    <>
      {roles.some(role => allowedRoles.includes(role.slug)) && (
        <Col>
          <Button
            size='sm'
            variant='link'
            className='position-relative cursor-pointer icon-item icon-item-sm rounded-circle border border-primary shadow-none'
            onClick={() =>
              setReleases(prevState => ({
                ...prevState,
                addReleaseModal: { open: true }
              }))
            }>
            <FontAwesomeIcon icon='plus' className='text-primary' />
            <span className='position-absolute start-100 ms-2 text-primary text-nowrap fs--1'>
              Add a release
            </span>
          </Button>
        </Col>
      )}
    </>
  );
};

export default ReleaseAddButton;
