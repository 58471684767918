import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { dynamicDashboardRoutes } from 'components/dashboards/Artifacts/Dynamic/routes';
import { routes } from 'config/routeSettings';
import { truncate } from 'lodash';
import moment from 'moment';
import { Button, Dropdown } from 'react-bootstrap';

const { default: CardDropdown } = require('components/common/CardDropdown');

/**
 * Columns for table
 * @param {Function} setSavedSearches
 * @returns {Array}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const getSavedSearchesColumns = (setSavedSearches, navigate) => {
  return [
    {
      accessor: 'name',
      Header: 'Name',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal text-700 align-middle py-1  text-nowrap'
      },
      headerProps: {
        className: 'fs--1   text-nowrap'
      },
      Cell: rowData => {
        // const { application: { isPrivacyMode } = {} } = useApplication();
        const { _dashboard, id, name, description } = rowData.row.original;

        return (
          <Flex direction='column' justifyContent='start' alignItems='start'>
            <Button
              variant='link'
              size='sm'
              className='text-decoration-none p-0 fs--1'
              onClick={() => {
                let dashboard = dynamicDashboardRoutes.find(
                  item => item.slug === _dashboard
                );
                navigate(
                  `${
                    dashboard ? dashboard.path : routes.ARTIFACTS_EXPLORE
                  }?savedSearchId=${id}`
                );
              }}>
              {name}
              <FontAwesomeIcon
                icon='external-link-alt'
                transform='up-1'
                className='fs--2 ms-1'
              />
            </Button>
            {description && (
              <div className='text-700'>
                {truncate(description, { length: 64 })}
              </div>
            )}
          </Flex>
        );
      }
    },
    // {
    //   accessor: 'loadSavedSearch',
    //   Header: 'Load Search',
    //   noTruncate: true,
    //   hasRender: true,
    //   cellProps: {
    //     className: 'fs--1 fw-normal  align-middle  text-nowrap'
    //   },
    //   headerProps: {
    //     className: 'fs--1   text-nowrap'
    //   },
    //   Cell: rowData => {
    //     const { _dashboard, id } = rowData.row.original;
    //     return (
    //       <Flex direction='row' justifyContent='start' alignItems='center'>
    //         <FontAwesomeIcon
    //           icon='external-link-alt'
    //           transform='up-3'
    //           className='fs--2  text-primary cursor-pointer'
    //           onClick={() => {
    //             let dashboard = dynamicDashboardRoutes.find(
    //               item => item.slug === _dashboard
    //             );
    //             navigate(
    //               `${
    //                 dashboard ? dashboard.path : routes.ARTIFACTS_EXPLORE
    //               }?savedSearchId=${id}`
    //             );
    //           }}
    //         />
    //       </Flex>
    //     );
    //   }
    // },
    {
      accessor: 'createdAt',
      Header: 'Created',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal  align-middle py-1  text-nowrap'
      },
      headerProps: {
        className: 'fs--1   text-nowrap'
      },
      Cell: rowData => {
        return (
          <Flex direction='row' justifyContent='start' alignItems='center'>
            {moment(
              rowData.row.original.createdAt,
              'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ).fromNow()}
          </Flex>
        );
      }
    },
    {
      accessor: 'lastUsedAt',
      Header: 'Last Used',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 fw-normal  align-middle py-1  text-nowrap'
      },
      headerProps: {
        className: 'fs--1   text-nowrap'
      },
      Cell: rowData => {
        return (
          <Flex direction='row' justifyContent='start' alignItems='center'>
            {rowData.row.original?.lastUsedAt
              ? moment(
                  rowData.row.original.lastUsedAt,
                  'YYYY-MM-DDTHH:mm:ss.SSSZ'
                ).fromNow()
              : 'Never'}
          </Flex>
        );
      }
    },
    {
      accessor: 'leargas_actions',
      Header: '',
      Cell: rowData => {
        let { _dashboard, id, isDeleted } = rowData.row.original;
        return (
          <CardDropdown>
            <div className='py-2'>
              <Dropdown.Item
                onClick={() => {
                  let dashboard = dynamicDashboardRoutes.find(
                    item => item.slug === _dashboard
                  );
                  navigate(
                    `${
                      dashboard ? dashboard.path : routes.ARTIFACTS_EXPLORE
                    }?savedSearchId=${id}&nonce=${new Date().getTime()}`
                  );
                }}>
                <FontAwesomeIcon icon='external-link-alt' className='me-2' />
                Load Search
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() =>
                  setSavedSearches(prevState => ({
                    ...prevState,
                    editSavedSearchesModal: {
                      open: true,
                      defaultData: rowData.row.original
                    }
                  }))
                }>
                <FontAwesomeIcon icon='pencil' className='me-2' />
                Edit Details
              </Dropdown.Item>
              <div className='dropdown-divider'></div>
              <Dropdown.Item
                className={`${!isDeleted ? 'text-danger' : 'text-warning'}`}
                onClick={() => {
                  isDeleted
                    ? setSavedSearches(prevState => ({
                        ...prevState,
                        restoreSavedSearchesModal: {
                          open: true,
                          defaultData: rowData.row.original
                        }
                      }))
                    : setSavedSearches(prevState => ({
                        ...prevState,
                        deleteSavedSearchesModal: {
                          open: true,
                          defaultData: rowData.row.original
                        }
                      }));
                }}>
                <FontAwesomeIcon
                  icon={isDeleted ? 'trash-restore-alt' : 'trash'}
                  className='me-2'
                />
                {isDeleted ? 'Restore' : 'Delete'}
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      },
      cellProps: {
        className: 'text-end text-400 align-middle actions-cell',
        style: {
          width: '42px'
        }
      },
      hideDisplaySettings: true,
      disableSortBy: true
    }
  ];
};
