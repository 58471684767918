import Flex from 'components/common/Flex';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useRoles from 'hooks/admin-contexts/useRoles';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import AddButton from './AddButton';
import { getRoleColumns } from './Columns/ColumnsDefaults';
import RolesTable from './RolesTable';
import RolesTableFilters from './RolesTableFilters';
import RolesTableFooter from './RolesTableFooter';
import RolesTablePagination from './RolesTablePagination';
import RolesTableWrapper from './RolesTableWrapper';
import ShowDeletedButton from './ShowDeletedButton';

/**
 * roles
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const Roles = () => {
  const {
    roles: { showDeleted, data },
    loading: componentLoading,
    setRoles
  } = useRoles();
  const [columns, setColumns] = useState([]);

  const [globalFilter, setGlobalFilter] = useState('');
  useEffect(() => {
    setColumns(getRoleColumns(setRoles));
  }, []);
  return (
    <Card className='shadow-none border'>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          <span className='d-block'>Roles</span>
          <Flex justifyContent='end' alignItems='center'>
            <ShowDeletedButton
              showDeleted={showDeleted}
              onClick={() => {
                setRoles(prevState => ({
                  ...prevState,
                  showDeleted: !showDeleted
                }));
              }}
            />
            <AddButton
              onClick={() =>
                setRoles(prevState => ({
                  ...prevState,
                  addRoleModal: { open: true }
                }))
              }
            />
          </Flex>
        </Flex>
      </Card.Header>
      <Card.Body className='position-relative'>
        {componentLoading ? (
          <LoadingSpinner grow='10' />
        ) : (
          <RolesTableWrapper
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            data={showDeleted ? data : data?.filter(role => !role?.isDeleted)}
            sortable
            loading={componentLoading}
            pagination
            perPage={50}>
            <RolesTableFilters
              table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <RolesTable table columns={columns} />
            <AddButton
              label='Add a role'
              onClick={() =>
                setRoles(prevState => ({
                  ...prevState,
                  addRoleModal: { open: true }
                }))
              }
            />
            <div className='mt-3 d-flex justify-content-between'>
              <RolesTableFooter
                table
                rowInfo
                data={
                  showDeleted ? data : data?.filter(role => !role?.isDeleted)
                }
                rowCount={
                  showDeleted
                    ? data?.length
                    : data?.filter(role => !role?.isDeleted).length
                }
              />
              <RolesTablePagination table />
            </div>
          </RolesTableWrapper>
        )}
      </Card.Body>
    </Card>
  );
};

export default Roles;
