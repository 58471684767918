import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import VerifiedBadge from 'components/common/VerifiedBadge';
import useAccount from 'hooks/useAccount';
import useAuthentication from 'hooks/useAuthentication';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import OTPVerifyForm from './OTPVerifyForm';

/**
 * Multi-factor authentication app verification page
 *
 * The MFA verification page is used to display a form to the user to verify their one-time pin. The user is
 * normally directed to this page after they have successfully logged in and have enabled multi-factor
 * authentication.
 * We use the useAccount hook to get the account data from the context. Then we display a form to the user to verify
 * their one-time pin.
 * @see MFASetupForm
 * @returns {React.Component} The MFA app verification page
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const OTPVerify = () => {
  const {
    authentication: { emails }
  } = useAuthentication();

  const {
    account: {
      profile: {
        name: { first, last },
        media: { avatar }
      }
    }
  } = useAccount();

  return (
    <Row>
      <Col>
        <Flex direction='column' justifyContent='around'>
          <Flex direction='column' alignItems='center'>
            <Avatar
              src={avatar || process.env.REACT_APP_DEFAULT_USER_AVATAR_IMG}
              size='4xl'
            />
            <h5 className='mb-1 ps-4 pt-2 fs--1'>
              {[first, last].join(' ')} <VerifiedBadge />
            </h5>
            <span className='fs--1 mb-1'>
              {emails.find(email => email.isPrimary).address}
            </span>
            <hr className='w-100' />
            <h5 className='mb-0 mt-1 fs--1'>Welcome back, {first}!</h5>
            <p className='pt-2 fs--1 text-center'>
              Confirm your authenticator one-time pin
            </p>
          </Flex>
          <OTPVerifyForm />
        </Flex>
      </Col>
    </Row>
  );
};

export default OTPVerify;
