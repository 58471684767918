import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

export const TimeLabel = ({ className, ...rest }) => (
  <span className={className} {...rest}>
    <FontAwesomeIcon icon='clock' className='me-1' />
    Time
  </span>
);

TimeLabel.propTypes = {
  className: PropTypes.string
};
