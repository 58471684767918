import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useJobs from 'hooks/admin-contexts/useJobs';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useState } from 'react';
import { Alert, Button, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

/**
 * Form to confirm deletion of an job
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const JobDeleteForm = () => {
  const {
    jobs: {
      jobDeleteModal: { data }
    },
    setJobs
  } = useJobs();
  const { axiosPrivate } = useAxiosPrivate();

  const [loading, setLoading] = useState(false);

  /**
   * Handles the form submission for deleting a job
   * @param {Event} e - The form submission event
   * @returns {Promise<void>}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.4
   * @since 0.1.0-beta.4
   */
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await LeargasAPI.Jobs.deleteAppJob(data?._id, axiosPrivate);
      if (res instanceof Error) {
        throw res;
      }
      setJobs(prevState => ({
        ...prevState,
        fetch: true,
        jobDeleteModal: { open: false, data: {} }
      }));
      const { message } = res.data;
      toast.success(message);
      setLoading(false);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setJobs(prevState => ({
        ...prevState,
        fetch: false,
        jobDeleteModal: { open: false, data: {} }
      }));
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong');
      }
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='g-3 mb-3'>
        <p className='mb-0'>Are you sure you want to delete this job?</p>
        <Alert variant='danger' className='mb-0 pb-0 fs--1'>
          <Alert.Heading className='fs--1 fw-bold'>Warning!</Alert.Heading>
          <p>
            This action cannot be undone! All job statistic indicators will be
            cleared!
          </p>
        </Alert>
      </Row>

      <Flex justifyContent='end'>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() => {
            setJobs(prevState => ({
              ...prevState,
              jobDeleteModal: { open: false, data: {} }
            }));
          }}>
          Cancel
        </Button>

        <Button variant='danger' type='submit' size='sm' className='d-flex'>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Deleting' : 'Delete'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

export default JobDeleteForm;
