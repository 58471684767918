import NavigationCard from 'components/common/NavigationCard';
import routes from 'config/routeSettings';
import React from 'react';
import { Row } from 'react-bootstrap';
import { systemRoutes } from 'routes/routes.js';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';

/**
 * Renders the SecurityLayout component
 *
 * @returns {JSX.Element} The rendered SecurityLayout component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const SecurityLayout = () => {
  const menuItems = systemRoutes.children
    .find(route => route.to === routes.SYSTEM_APP)
    .children.find(route => route.to === routes.SYSTEM_APP_SECURITY);

  return (
    <>
      <WidgetSectionTitle title='Security' className='mb-2' />
      <Row className='g-1 mb-1'>
        {menuItems.children.map((route, index) => {
          return <NavigationCard route={route} key={index} index={index} />;
        })}
      </Row>
    </>
  );
};

export default SecurityLayout;
