import React from 'react';
import AvailableFieldsList from './AvailableFieldsList';
import SelectedFieldsList from './SelectedFieldsList';

/**
 * FieldList component.
 *
 * @returns {JSX.Element} The rendered FieldList component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const FieldList = () => {
  return (
    <div
      className='field-list-container'
      style={{
        maxWidth: '225px'
      }}>
      <SelectedFieldsList />
      <AvailableFieldsList />
    </div>
  );
};

export default FieldList;
