import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner.js';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
// import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

const DragAndDropItem = React.lazy(() => import('./dragAndDropItem.js'));

const MapComp = ({ colItems }) => {
  const [columnItemsComing, setColumnItemsComing] = useState([]);
  const [showColumns, setShowColumns] = useState(false);

  const loadColumns = async () => {
    // Clear the current items before loading new ones
    setColumnItemsComing([]);
    setShowColumns(false);

    for (let i = 0; i < colItems.length; i++) {
      // await delay(125); // Delay for 0.125 seconds
      setColumnItemsComing(prevItems => [...prevItems, colItems[i]]);
    }

    setShowColumns(true);
  };

  useEffect(() => {
    // Load new columns when colItems changes
    loadColumns();

    return () => {
      setColumnItemsComing([]); // Cleanup: clear items when component unmounts or colItems change
      setShowColumns(false);
    };
  }, [colItems]);

  // const delay = ms => {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // };

  return (
    <>
      {showColumns &&
        columnItemsComing.map((item, index) => (
          <React.Suspense
            key={index}
            fallback={
              <Col {...item.colSize} className='h-100 fade-in'>
                <Card className='shadow-none'>
                  <Card.Body className='pb-4'>
                    <LoadingSpinner />
                  </Card.Body>
                </Card>
              </Col>
            }>
            <DragAndDropItem id={item.id} index={index} item={item} />
          </React.Suspense>
        ))}
    </>
  );
};

MapComp.propTypes = {
  componentData: PropTypes.object,
  colItems: PropTypes.array.isRequired
};

export default MapComp;

// <DragAndDropItem
//   id={item.id}
//   index={index}
//   // dragIndex={dragIndex}
//   item={item}
//   columnItemsComing
//   // setDraggedIndex={setDraggedIndex}
//   // moveItem={moveItem}
// />
