import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useOrganizations from 'hooks/admin-contexts/useOrganizations';
import useRoles from 'hooks/admin-contexts/useRoles';
import useUsers from 'hooks/admin-contexts/useUsers';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormText, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import PasswordStrengthBar from 'react-password-strength-bar';
import { toast } from 'react-toastify';
import MultiSelect from './MultiSelect';

/**
 * CreateUserForm component handles the creation of a new user.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} [props.hasLabel=true] - Determines if the form fields should have labels.
 *
 * @returns {JSX.Element} The rendered CreateUserForm component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.6
 * @since 0.1.0-beta.2
 */
const CreateUserForm = ({ hasLabel = true }) => {
  const { axiosPrivate } = useAxiosPrivate();
  const { roles } = useRoles();
  const { setUsers } = useUsers();
  const {
    organizations: { data }
  } = useOrganizations();

  const {
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    formState: { errors },
    reset,
    watch
  } = useForm();

  const [loading, setLoading] = useState(false);
  const generatePassword = watch('generatePassword', true);

  /**
   * Clears the selection of organizations by setting the 'organizations' field to an empty array.
   *
   * @function handleClearSelection
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.6
   * @since 0.1.0-beta.2
   */
  const handleClearSelection = () => {
    setValue('organizations', []);
  };

  /**
   * Handles the change event for the role selection.
   *
   * @param {Array} selectedOptions - The selected options for roles.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.6
   * @since 0.1.0-beta.2
   */
  const handleRoleChange = selectedOptions => {
    setValue('roles', selectedOptions);
  };

  /**
   * Handles the selection of all organizations.
   * Maps the organization data to an array of objects with `value` and `label` properties,
   * and sets this array to the 'organizations' field in the form.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.6
   * @since 0.1.0-beta.2
   */
  const handleSelectAll = () => {
    const allOrganizations =
      data?.map(org => ({
        value: org.id,
        label: org.name
      })) || [];
    setValue('organizations', allOrganizations);
  };

  /**
   * Handles the form submission for adding a new user.
   *
   * @param {Object} formData - The data submitted from the form.
   * @param {Array} formData.roles - The roles assigned to the user.
   * @param {Array} formData.organizations - The organizations assigned to the user.
   *
   * @returns {Promise<void>} - A promise that resolves when the user is created.
   *
   * @throws {Error} - Throws an error if the user creation fails.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.6
   * @since 0.1.0-beta.2
   */
  const onSubmit = async formData => {
    try {
      setLoading(true);
      const newData = {
        ...formData,
        roles: formData?.roles.map(item => item.value),
        organizations: formData?.organizations.map(item => item.value)
      };
      const res = await LeargasAPI.Users.createUser(newData, axiosPrivate);

      if (res instanceof Error) throw res;

      let { message } = res;

      toast.success(message);
      setLoading(false);
      setUsers(prevState => ({
        ...prevState,
        showCreateUserModal: { open: false },
        fetch: true
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);
      setUsers(prevState => ({
        ...prevState,
        showCreateUserModal: { open: false },
        fetch: false
      }));
      if (error?.data?.response?.data?.message) {
        toast.error(error?.data?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  useEffect(() => {
    reset({
      firstName: '',
      lastName: '',
      generatePassword: true,
      password: '',
      roles: [],
      organizations: []
    });
  }, [reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className='g-3 mb-3'>
        <Col sm={6}>
          <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
            First Name <span className='text-danger ms-1'>*</span>
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='The first name of the user (given name)'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <Form.Control
            placeholder={!hasLabel ? 'First Name' : ''}
            {...register('firstName', { required: 'First Name is required' })}
            type='text'
            autoComplete='off'
            className='fs--1 px-2'
            style={{ minHeight: '36px' }}
          />
          <FormText className='text-danger'>
            {errors.firstName?.message}
          </FormText>
        </Col>

        <Col sm={6}>
          <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
            Last Name <span className='text-danger ms-1'>*</span>
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='The last name of the user (family name)'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <Form.Control
            placeholder={!hasLabel ? 'Last Name' : ''}
            {...register('lastName', { required: 'Last Name is required' })}
            type='text'
            autoComplete='off'
            className='fs--1 px-2'
            style={{ minHeight: '36px' }}
          />
          <FormText className='text-danger'>
            {errors.lastName?.message}
          </FormText>
        </Col>
      </Row>

      <Row className='g-3 mb-3'>
        <Col sm={6}>
          <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
            Email address <span className='text-danger ms-1'>*</span>
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Email address'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <Form.Control
            placeholder={!hasLabel ? 'Email address' : ''}
            {...register('emailAddress', {
              required: 'Email address is required'
            })}
            type='email'
            autoComplete='off'
            className='fs--1 px-2'
            style={{ minHeight: '36px' }}
          />
          <FormText className='text-danger'>
            {errors.emailAddress?.message}
          </FormText>
        </Col>

        <Col sm={6}>
          <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
            Roles <span className='text-danger ms-1'>*</span>
            <AdvancedPopover
              containerId='newFilterModal'
              placement='top'
              popoverText='Application roles that the user should have'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 text-400 fs--1 cursor-pointer'
              />
            </AdvancedPopover>
          </Form.Label>
          <Controller
            name='roles'
            control={control}
            defaultValue={[]}
            rules={{ required: 'At least one role is required' }}
            render={({ field }) => (
              <MultiSelect
                className='fs--1'
                isMulti
                isClearable
                isSearchable
                placeholder='Please select'
                options={roles?.data?.map(role => ({
                  value: role.id,
                  label: role.name
                }))}
                onChange={selectedOptions => {
                  field.onChange(selectedOptions);
                  handleRoleChange(selectedOptions);
                }}
                value={field.value}
                style={{ minHeight: '36px' }}
              />
            )}
          />
          <FormText className='text-danger'>{errors.roles?.message}</FormText>
        </Col>
        <Col sm={12}>
          <Flex direction='row' alignItems='center' justifyContent='between'>
            <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
              Organizations <span className='text-danger ms-1'>*</span>
              <AdvancedPopover
                containerId='newFilterModal'
                placement='top'
                popoverText='The organization the user belongs to'
                showArrow={true}>
                <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  className='ms-1 text-400 fs--1 cursor-pointer'
                />
              </AdvancedPopover>
            </Form.Label>
            <div className='mb-2'>
              <Button
                size='sm'
                variant='link'
                className='ms-2 p-0 text-decoration-none'
                onClick={handleSelectAll}>
                Select All
              </Button>
              <Button
                size='sm'
                variant='link'
                className='ms-2 p-0 text-decoration-none'
                onClick={handleClearSelection}>
                Clear Selection
              </Button>
            </div>
          </Flex>
          <Controller
            name='organizations'
            control={control}
            defaultValue={[]}
            rules={{ required: 'At least one organization is required' }}
            render={({ field }) => (
              <MultiSelect
                className='fs--1'
                isClearable
                isMulti
                isSearchable
                placeholder='Please select'
                options={data?.map(org => ({
                  value: org.id,
                  label: org.name
                }))}
                onChange={selectedOptions => {
                  field.onChange(selectedOptions);
                  setValue('organizations', selectedOptions);
                }}
                value={field.value}
                style={{ minHeight: '36px' }}
              />
            )}
          />
          <FormText className='text-danger'>
            {errors.organizations?.message}
          </FormText>
        </Col>
      </Row>

      <Row className='g-3'>
        <Col sm={12} className='mb-0'>
          <Flex direction='row' alignItems='center'>
            <Form.Check
              type='switch'
              name='generatePassword'
              {...register('generatePassword')}
              defaultChecked={true}
              onChange={e => {
                setValue('generatePassword', e.target.checked);
                if (e.target.checked) {
                  setValue('password', '');
                }
              }}
              className='mb-2'
            />
            <Form.Label className='d-flex fs--1 fw-normal align-items-baseline'>
              Generate a secure, random password{' '}
            </Form.Label>
          </Flex>
        </Col>
      </Row>

      {!generatePassword && (
        <Row className='g-3'>
          <Col sm={6}>
            <Form.Group className='mt-3'>
              <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
                Password{' '}
                <AdvancedPopover
                  containerId='newFilterModal'
                  placement='top'
                  popoverText='Custom password of your choice'
                  showArrow={true}>
                  <FontAwesomeIcon
                    icon={['far', 'question-circle']}
                    className='ms-1 text-400 fs--1 cursor-pointer'
                  />
                </AdvancedPopover>
              </Form.Label>
              <Form.Control
                placeholder={!hasLabel ? 'Password' : ''}
                {...register('password', { required: 'Password is required' })}
                type='password'
                className='fs--1'
                style={{ minHeight: '36px' }}
              />
              <FormText>{errors.password?.message}</FormText>
            </Form.Group>
            <p className='form-label fw-semi-bold mt-3'>Password Strength</p>
            <PasswordStrengthBar
              password={getValues('password')}
              minLength={8}
            />
          </Col>
        </Row>
      )}

      <Flex justifyContent='end'>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() =>
            setUsers(prevState => ({
              ...prevState,
              showCreateUserModal: { open: false },
              fetch: false
            }))
          }>
          Cancel
        </Button>

        <Button variant='success' size='sm' disabled={loading} type='submit'>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Creating' : 'Create'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

CreateUserForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default CreateUserForm;
