import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';
export const smb = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'SMB'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-zeek*'],
        filter: [
          {
            match_all: {}
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    'logName.keyword': 'smb_files'
                  }
                }
              ],
              minimum_should_match: 1
            }
          }
        ],
        queryType: ['previousCount', 'count', 'sparkline']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          title: 'SMB Files',
          helpInfo: 'Total number of SMB events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-zeek*'],
        filter: [
          {
            match_all: {}
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    'logName.keyword': 'smb_mapping'
                  }
                }
              ],
              minimum_should_match: 1
            }
          }
        ],
        queryType: ['previousCount', 'count', 'sparkline']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          title: 'SMB mapping',
          helpInfo: 'Total number of SMB files matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-zeek*'],
        filter: [
          {
            match_all: {}
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    'logName.keyword': 'smb_files'
                  }
                },
                {
                  match_phrase: {
                    'logName.keyword': 'smb_mapping'
                  }
                }
              ],
              minimum_should_match: 1
            }
          }
        ],
        queryType: ['histogram']
      },
      children: {
        component: 'Stat',
        props: {
          header: 'All Events timeline',
          helpInfo:
            'Timeline histogram of all SMB events matching search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['table'],
        overriddenIndex: ['leargas-zeek*'],
        filter: [
          {
            match_all: {}
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    'logName.keyword': 'smb_files'
                  }
                }
              ],
              minimum_should_match: 1
            }
          }
        ],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'action.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'SMB Actions',
            overlayText: 'Actions by user and domain',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['table'],
        overriddenIndex: ['leargas-zeek*'],
        filter: [
          {
            match_all: {}
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    'logName.keyword': 'smb_mapping'
                  }
                }
              ],
              minimum_should_match: 1
            }
          }
        ],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'unc_path.keyword',
                order: {
                  _count: 'desc'
                },
                size: 10000
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'UNC',
            overlayText: 'Actions by user and domain',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['table'],
        overriddenIndex: ['leargas-zeek*'],
        filter: [
          {
            match_all: {}
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    'logName.keyword': 'smb_files'
                  }
                },
                {
                  match_phrase: {
                    'logName.keyword': 'smb_mapping'
                  }
                }
              ],
              minimum_should_match: 1
            }
          }
        ],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'id.orig_h.keyword',
                order: {
                  _count: 'desc'
                },
                size: 10000
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: ' Source IP',
            overlayText: 'Actions by user and domain',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['table'],
        overriddenIndex: ['leargas-zeek*'],
        filter: [
          {
            match_all: {}
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    'logName.keyword': 'smb_files'
                  }
                },
                {
                  match_phrase: {
                    'logName.keyword': 'smb_mapping'
                  }
                }
              ],
              minimum_should_match: 1
            }
          }
        ],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'id.resp_h.keyword',
                order: {
                  _count: 'desc'
                },
                size: 10000
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Destination IP',
            overlayText: 'Actions by user and domain',
            fontAwesomeProps: {
              icon: ['far', 'question-circle'],
              transform: 'shrink-1',
              className: 'ms-1 text-400'
            }
          }
        }
      }
    }
  ];
};
export default smb;
