import { createPrivateAxios, publicMakeRequest } from 'api/requestController';
import useAuthentication from 'hooks/useAuthentication';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext } from 'react';

// Create a context for managing HTTP requests
const AxiosContext = createContext();

/**
 * AxiosProvider component.
 *
 * @component
 * @param {Object} props - The component props
 * @param {ReactNode} props.children - The child components
 * @returns {JSX.Element} The rendered component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const AxiosProvider = ({ children }) => {
  const { authentication } = useAuthentication();

  /**
   * Makes a request using the provided configuration and abort signal.
   *
   * If an access token is available in the authentication context, a private Axios instance is created with the access token and the request is made using that instance.
   * If no access token is available, the request is made using the public Axios instance.
   *
   * @param {Object} config - The configuration for the request
   * @param {AbortSignal} abortSignal - The abort signal for the request
   * @returns {Promise} - A promise that resolves with the response data
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const makeRequest = useCallback(
    (config, abortSignal) => {
      const { accessToken } = authentication;

      if (accessToken) {
        // Create a private Axios instance with the access token
        const { makeRequest: privateMakeRequest } =
          createPrivateAxios(accessToken);
        return privateMakeRequest(config, abortSignal);
      } else {
        // Use public Axios instance if not authenticated
        return publicMakeRequest(config, abortSignal);
      }
    },
    [authentication]
  );

  return (
    <AxiosContext.Provider value={{ makeRequest }}>
      {children}
    </AxiosContext.Provider>
  );
};

AxiosProvider.propTypes = {
  children: PropTypes.node.isRequired
};

// Builtin hook to access Axios context
export const useAxios = () => {
  return useContext(AxiosContext);
};
