import { useContext } from 'react';
import { TeamsContext } from 'context/admin-contexts/TeamsProvider';

/**
 * Custom hook to use the TeamsContext
 * @returns {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const useTeams = () => {
  return useContext(TeamsContext);
};

export default useTeams;
