import React from 'react';
const { default: CardDropdown } = require('components/common/CardDropdown');
const { default: Flex } = require('components/common/Flex');
const { Dropdown } = require('react-bootstrap');

export const columnActions = (
  setShowUpdateModal,
  setShowDeleteDashboardModal
) => {
  return [
    {
      accessor: 'leargas_actions',
      Header: '',
      Cell: rowData => {
        return (
          <Flex justifyContent='around' alignContent='middle'>
            <CardDropdown>
              <div className='py-2'>
                <Dropdown.Item
                  className=''
                  onClick={() => {
                    setShowUpdateModal({
                      open: true,
                      dashboard: rowData?.row?.original,
                      fetch: false
                    });
                  }}>
                  Edit Dashboard
                </Dropdown.Item>
                <div className='dropdown-divider'></div>
                <Dropdown.Item
                  className='text-danger'
                  onClick={() => {
                    setShowDeleteDashboardModal({
                      open: true,
                      dashboard: rowData?.row?.original,
                      fetch: false
                    });
                  }}>
                  Delete Dashboard
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </Flex>
        );
      },
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap text-center'
      },
      hideDisplaySettings: true,
      disableSortBy: true
    }
  ];
};
