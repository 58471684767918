export const validator = r => !!r.value;
import moment from 'moment';
import {
  convertTermToMatch,
  deleteRangeIfArg,
  modifyMissing
} from './RefinedUtils';

export const setManualQuery = (
  index,
  size,
  page,
  sortBy,
  sortOrder,
  gte,
  lte,
  organization
) => {
  let returnQuery = {
    query: {
      index: [...index],
      size: size,
      from: (page - 1) * size,
      body: {
        query: {
          bool: {
            filter: [
              {
                range: {
                  '@timestamp': {
                    gte: moment(moment.utc(gte).toDate())
                      .local()
                      .format('YYYY-MM-DDTHH:mm:ss'),
                    lte: moment(moment.utc(lte).toDate())
                      .local()
                      .format('YYYY-MM-DDTHH:mm:ss'),
                    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      }
    }
  };

  if (
    organization.clientId !== '' &&
    !Object.prototype.hasOwnProperty.call(
      returnQuery.query.body.query.bool,
      'must'
    )
  ) {
    returnQuery.query.body.query.bool['must'] = [];
  }
  sortOrder !== '' &&
    (returnQuery.query.body.sort = [{ [sortBy]: { order: sortOrder } }]);
  organization.clientId !== '' &&
    returnQuery.query.body.query.bool.must.push({
      match: {
        clientId: organization?.clientId
      }
    });

  return returnQuery;
};

export const getQuerySorted = (
  index,
  size,
  page,
  sortBy,
  sortOrder,
  gte,
  lte,
  organization,
  newQuery
) => {
  let resolveQuerySort = {
    query: {
      index: [...index],
      size: size,
      from: (page - 1) * size,
      body: {
        query: { ...newQuery }
      }
    }
  };
  sortOrder !== '' &&
    (resolveQuerySort.query.body.sort = [{ [sortBy]: { order: sortOrder } }]);

  let fullQuery =
    newQuery !== ''
      ? resolveQuerySort
      : setManualQuery(
          index,
          size,
          page,
          sortBy,
          sortOrder,
          gte,
          lte,
          organization
        );
  return fullQuery;
};

export const getQueryUnsorted = (
  index,
  gte,
  lte,
  organization,
  newQuery,
  matchPhrase = ''
) => {
  let fullQuery =
    newQuery !== ''
      ? {
          query: {
            index: [...index],

            body: {
              query: { ...newQuery }
            }
          }
        }
      : setQueryUnsorted(index, gte, lte, organization, matchPhrase);

  return fullQuery;
};

export const getQueryUnsortedExceptions = (
  index,
  gte,
  lte,
  organization,
  newQuery,
  matchPhrase = ''
) => {
  let fullQuery =
    newQuery !== ''
      ? {
          query: {
            index: [...index],

            body: {
              query: { ...newQuery }
            }
          }
        }
      : setQueryUnsortedExceptions(index, gte, lte, organization, matchPhrase);

  return fullQuery;
};

export const getCompleteQuery = (
  index,
  gte,
  lte,
  fullControlQuery,
  matchPhrase,
  organization,
  rangeBool,
  should,
  must,
  must_not,
  filter,
  query_string,
  aggregate,
  overriddenIndex,
  scopes,
  inActiveOrgs
) => {
  let totalQuery = returnCompleteQuery(
    index,
    gte,
    lte,
    fullControlQuery,
    matchPhrase,
    organization,
    rangeBool
  );
  let convertTermToMatchQueryTotalQuery = convertTermToMatch(totalQuery);
  let rulesInjectedBool1 = injectRules(
    convertTermToMatchQueryTotalQuery.query.body.query,
    {
      should: should ? should : [],
      must: must ? must : [],
      must_not: must_not ? must_not : [],
      filter: filter ? filter : []
    }
  );
  convertTermToMatchQueryTotalQuery.query.body.query = rulesInjectedBool1;
  if (query_string) {
    let rulesInjectedBool2 = injectRules(rulesInjectedBool1, {
      should: [],
      must: [query_string],
      must_not: [],
      filter: []
    });

    convertTermToMatchQueryTotalQuery.query.body.query = rulesInjectedBool2;
  }

  if (aggregate !== '') {
    if (aggregate?.showMissingBuckets) {
      let newobject = modifyMissing({ aggs: aggregate?.aggs }, true);
      convertTermToMatchQueryTotalQuery.query.body.aggs = newobject.aggs;
    } else {
      convertTermToMatchQueryTotalQuery.query.body.aggs = aggregate.aggs;
    }
    if (aggregate && Object.prototype.hasOwnProperty.call(aggregate, 'size')) {
      convertTermToMatchQueryTotalQuery.query.body.size = aggregate.size;
    }
  }
  if (overriddenIndex) {
    convertTermToMatchQueryTotalQuery.query.index = [...overriddenIndex];
  }
  if (!rangeBool) {
    convertTermToMatchQueryTotalQuery = deleteRangeIfArg(
      convertTermToMatchQueryTotalQuery
    );
  }

  let shouldFromScope = convertToShouldArrayForScopes(scopes, inActiveOrgs);
  let scopesInjected = injectRules(
    convertTermToMatchQueryTotalQuery.query.body.query,
    {
      should: shouldFromScope || [],
      must: [],
      must_not: [],
      filter: []
    }
  );
  convertTermToMatchQueryTotalQuery.query.body.query = scopesInjected;
  convertTermToMatchQueryTotalQuery.query.body.query.bool[
    'minimum_should_match'
  ] = 1;
  return convertTermToMatchQueryTotalQuery;
};

export const setQueryUnsorted = (
  index,
  gte,
  lte,
  organization,
  matchPhrase
) => {
  let returnQuery =
    gte && lte
      ? {
          query: {
            index: [...index],

            body: {
              query: {
                bool: {
                  filter: [
                    {
                      range: {
                        '@timestamp': {
                          gte: moment(moment.utc(gte).toDate())
                            .local()
                            .format('YYYY-MM-DDTHH:mm:ss'),
                          lte: moment(moment.utc(lte).toDate())
                            .local()
                            .format('YYYY-MM-DDTHH:mm:ss'),
                          time_zone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          format: 'strict_date_optional_time'
                        }
                      }
                    }
                  ]
                }
              }
            }
          }
        }
      : {
          query: {
            index: [...index],

            body: {
              query: {
                bool: {
                  must: []
                }
              }
            }
          }
        };

  if (
    matchPhrase &&
    !Object.prototype.hasOwnProperty.call(
      returnQuery.query.body.query.bool,
      'must'
    )
  ) {
    returnQuery.query.body.query.bool['must'] = [];
  }
  if (
    organization.clientId !== '' &&
    !Object.prototype.hasOwnProperty.call(
      returnQuery.query.body.query.bool,
      'must'
    )
  ) {
    returnQuery.query.body.query.bool['must'] = [];
  }
  organization.clientId !== '' &&
    returnQuery.query.body.query.bool.must.push({
      match: {
        clientId: organization?.clientId
      }
    });

  matchPhrase &&
    returnQuery.query.body.query.bool.must.push({
      match_phrase: matchPhrase
    });

  return returnQuery;
};
export const setQueryUnsortedExceptions = (
  index,
  gte,
  lte,
  organization,
  matchPhrase
) => {
  let returnQuery =
    gte && lte
      ? {
          query: {
            index: [...index],

            body: {
              query: {
                bool: {
                  filter: [
                    {
                      range: {
                        '@timestamp': {
                          gte: gte.toISOString(),
                          lte: lte.toISOString(),
                          time_zone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          format: 'strict_date_optional_time'
                        }
                      }
                    }
                  ]
                }
              }
            }
          }
        }
      : {
          query: {
            index: [...index],

            body: {
              query: {
                bool: {
                  must: []
                }
              }
            }
          }
        };
  // add must array before pushing in data
  if (
    matchPhrase &&
    !Object.prototype.hasOwnProperty.call(
      returnQuery.query.body.query.bool,
      'must'
    )
  ) {
    returnQuery.query.body.query.bool['must'] = [];
  }
  if (
    organization.clientId !== '' &&
    !Object.prototype.hasOwnProperty.call(
      returnQuery.query.body.query.bool,
      'must'
    )
  ) {
    returnQuery.query.body.query.bool['must'] = [];
  }
  organization.clientId !== '' &&
    returnQuery.query.body.query.bool.must.push({
      match: {
        clientId: organization?.clientId
      }
    });

  matchPhrase &&
    returnQuery.query.body.query.bool.must.push({
      match_phrase: matchPhrase
    });

  return returnQuery;
};

export const convertGteToCandleStructure = (gte1, lte1) => {
  let gte = new Date(gte1);
  let lte = new Date(lte1);

  let intervalInSeconds = (lte.getTime() - gte.getTime()) / 1000;
  let newGteInSeconds = intervalInSeconds * 7;
  let newGTE = new Date(
    gte.getTime() +
      newGteInSeconds * 1000 -
      new Date().getTimezoneOffset() * 60 * 1000
  );

  return newGTE;
};
export const convertToShouldArrayForScopes = (array, inActiveOrgs) => {
  let shouldArray = [];
  if (array.length > 0) {
    array
      ?.filter(item => (inActiveOrgs ? item : item.active))
      .map(item => {
        if (item?.legacy?.clientId && item?.checked) {
          shouldArray.push({
            match_phrase: {
              'clientId.keyword': item?.legacy?.clientId
            }
          });
        }
      });
  }
  if (shouldArray.length === 0) {
    shouldArray.push({
      match_phrase: {
        'clientId.keyword': 'ZEROCUSTOMERS'
      }
    });
  }

  return shouldArray;
};

export const returnCompleteQuery = (
  index,
  gte,
  lte,
  fullControlQuery,
  matchPhrase,
  organization
) => {
  let newQuery = JSON.parse(JSON.stringify(fullControlQuery));

  if (
    newQuery !== '' &&
    !Object.prototype.hasOwnProperty.call(newQuery.bool, 'filter')
  ) {
    newQuery.bool['filter'] = [];
  }

  if (newQuery !== '' && gte && lte) {
    newQuery.bool['filter'].push({
      range: {
        '@timestamp': {
          gte: moment(moment.utc(gte).toDate())
            .local()
            .format('YYYY-MM-DDTHH:mm:ss'),
          lte: moment(moment.utc(lte).toDate())
            .local()
            .format('YYYY-MM-DDTHH:mm:ss'),
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          format: 'strict_date_optional_time'
        }
      }
    });
  }
  if (
    matchPhrase &&
    newQuery !== '' &&
    !Object.prototype.hasOwnProperty.call(newQuery.bool, 'must')
  ) {
    newQuery.bool['must'] = [];
  }

  matchPhrase &&
    newQuery !== '' &&
    newQuery.bool['must'].push({
      match_phrase: matchPhrase
    });

  if (
    newQuery !== '' &&
    organization.clientId !== '' &&
    !Object.prototype.hasOwnProperty.call(newQuery.bool, 'should')
  ) {
    newQuery.bool['should'] = [];
  }

  newQuery !== '' &&
    organization.clientId !== '' &&
    newQuery.bool['should'].push({
      match: {
        clientId: organization?.clientId
      }
    });

  let totalQuery = getQueryUnsorted(
    index,
    gte,
    lte,
    organization,
    newQuery,
    matchPhrase
  );

  return totalQuery;
};
// cloned the returnCompleteQuery for exception of timestamps for candles  queryType
export const returnCompleteQueryException = (
  index,
  gte,
  lte,
  fullControlQuery,
  matchPhrase,
  organization
) => {
  let newQuery = JSON.parse(JSON.stringify(fullControlQuery));

  if (
    newQuery !== '' &&
    !Object.prototype.hasOwnProperty.call(newQuery.bool, 'filter')
  ) {
    newQuery.bool['filter'] = [];
  }

  if (newQuery !== '' && gte && lte) {
    newQuery.bool['filter'].push({
      range: {
        '@timestamp': {
          gte: gte.toISOString(),
          lte: lte.toISOString(),
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          format: 'strict_date_optional_time'
        }
      }
    });
  }
  if (
    matchPhrase &&
    newQuery !== '' &&
    !Object.prototype.hasOwnProperty.call(newQuery.bool, 'must')
  ) {
    newQuery.bool['must'] = [];
  }
  matchPhrase &&
    newQuery !== '' &&
    newQuery.bool['must'].push({
      match_phrase: matchPhrase
    });
  if (
    newQuery !== '' &&
    organization.clientId !== '' &&
    !Object.prototype.hasOwnProperty.call(newQuery.bool, 'should')
  ) {
    newQuery.bool['should'] = [];
  }
  newQuery !== '' &&
    organization.clientId !== '' &&
    newQuery.bool['should'].push({
      match: {
        clientId: organization?.clientId
      }
    });

  let totalQuery = getQueryUnsortedExceptions(
    index,
    gte,
    lte,
    organization,
    newQuery,
    matchPhrase
  );

  return totalQuery;
};

export const tweakElasticQuery = (query, arrayName, arrayItem) => {
  if (!query.bool) {
    query.bool = {};
  }

  if (!query.bool[arrayName]) {
    query.bool[arrayName] = [];
  }

  if (Array.isArray(query.bool[arrayName])) {
    if (
      Array.isArray(arrayItem[arrayName]) &&
      arrayItem[arrayName].length > 0
    ) {
      arrayItem[arrayName].map(item => {
        query.bool[arrayName].push(item);
      });
    }
  } else {
    if (Array.isArray(arrayItem) && arrayItem.length > 0) {
      query.bool[arrayName] = [...arrayItem];
    }
  }

  return query;
};

export function prevTimeRange(query) {
  // Extract gte and lte from the input query
  const gte = query.body.query.bool.filter[0].range['@timestamp'].gte;
  const lte = query.body.query.bool.filter[0].range['@timestamp'].lte;

  // Function to format the date to the "YYYY-MM-DDTHH:mm:ss" format in local time
  const formatLocalDate = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  // Function to calculate the previous time range
  const getPreviousTimeRange = (gte, lte) => {
    const gteDate = new Date(gte);
    const lteDate = new Date(lte);

    // Calculate the difference between lte and gte in milliseconds
    const difference = lteDate.getTime() - gteDate.getTime();

    // Shift both gte and lte by the same difference
    const newGte = new Date(gteDate.getTime() - difference);
    const newLte = new Date(lteDate.getTime() - difference);

    return {
      gte: formatLocalDate(newGte),
      lte: formatLocalDate(newLte)
    };
  };

  // Get the new gte and lte values
  const previousRange = getPreviousTimeRange(gte, lte);

  // Update the query with the new gte and lte values
  query.body.query.bool.filter[0].range['@timestamp'].gte = previousRange.gte;
  query.body.query.bool.filter[0].range['@timestamp'].lte = previousRange.lte;

  return query;
}
// export const injectRules = (query, arrays) => {
//   if (!query.bool) {
//     query.bool = {};
//   }

//   for (let name of Object.keys(arrays)) {
//     if (Array.isArray(query.bool[name])) {
//       if (Array.isArray(arrays[name]) && arrays[name].length > 0) {
//         arrays[name].map(item => {
//           const isDuplicate = query.bool[name].find(
//             existingItem =>
//               JSON.stringify(existingItem) === JSON.stringify(item)
//           );

//           if (!isDuplicate) {
//             query.bool[name].push(item);
//           }
//           // query.bool[name].push(item);
//         });
//       }
//     } else {
//       if (Array.isArray(arrays[name]) && arrays[name].length > 0) {
//         query.bool[name] = [...arrays[name]];
//       }
//     }
//   }

//   return query;
// };
export const injectRules = (boolMust, param2) => {
  const firstBoolObject = boolMust;

  const secondBoolObject = {
    bool: {
      must: Array.isArray(param2.must) ? param2.must : [],
      must_not: Array.isArray(param2.must_not) ? param2.must_not : [],
      filter: Array.isArray(param2.filter) ? param2.filter : [],
      should: Array.isArray(param2.should) ? param2.should : []
    }
  };

  if (secondBoolObject.bool.should.length > 0) {
    secondBoolObject.bool.minimum_should_match = 1;
  }

  return {
    must: [...firstBoolObject, secondBoolObject]
  };
};
export const staticInjectRules = param2 => {
  const secondBoolObject = {
    bool: {
      must: Array.isArray(param2.must) ? param2.must : [],
      must_not: Array.isArray(param2.must_not) ? param2.must_not : [],
      filter: Array.isArray(param2.filter) ? param2.filter : [],
      should: Array.isArray(param2.should) ? param2.should : []
    }
  };

  if (secondBoolObject.bool.should.length > 0) {
    secondBoolObject.bool.minimum_should_match = 1;
  }

  return {
    must: [secondBoolObject]
  };
};

export const scopingOrgsinStatuses = (OrgsData, scopes) => {
  const allChecked = scopes.every(org => org.checked === true);

  if (allChecked) {
    return OrgsData;
  }

  const checkedOrgs = scopes.filter(org => org.checked === true);

  const checkedOrgIds = checkedOrgs.map(org => org._id);

  const filteredOrgs = OrgsData.filter(org => checkedOrgIds.includes(org._id));
  return filteredOrgs;
};

export const scopingOrgsinAdminReports = (reportsData, scopes) => {
  const allChecked = scopes?.every(org => org?.checked === true);

  if (allChecked) {
    return reportsData;
  }

  const checkedOrgs = scopes?.filter(org => org?.checked === true);

  const checkedOrgIds = checkedOrgs?.map(org => org?._id);

  const filteredOrgs = reportsData?.filter(report =>
    checkedOrgIds?.includes(report?.organization?._id)
  );
  return filteredOrgs;
};

// // export const extractUniqueValues = (data, field) => {
//   const uniqueValues = new Set();

//   // Function to get property value from an object
//   const getPropertyValue = (obj, path) => {
//     const keys = path.split('.');
//     return keys.reduce((acc, key) => (acc && acc[key] !== 'undefined' ? acc[key] : undefined), obj);
//   };

//   // Iterate over data array and extract values based on field
//   data.forEach(item => {
//     const value = getPropertyValue(item, field);
//     if (value !== undefined) {
//       uniqueValues.add(value);
//     }
//   });

//   return [...new Set(uniqueValues)].sort((a, b) => a.localeCompare(b));
// };
export function getFieldValue(jsonData, fieldName) {
  const data = jsonData;
  if (fieldName in data) {
    return data[fieldName];
  } else {
    console.error('Field does not exist:', fieldName);
    return null;
  }
}

export function saveQueryInGF(
  setESGlobalFilters,
  fullControlQuery,
  gte,
  lte,
  index,
  size,
  page,
  sortBy,
  sortOrder,
  organization,
  scopes,
  inActiveOrgs,
  query_string
) {
  let newQuery = JSON.parse(JSON.stringify(fullControlQuery));
  if (
    newQuery !== '' &&
    !Object.prototype.hasOwnProperty.call(newQuery.bool, 'filter')
  ) {
    newQuery.bool['filter'] = [];
  }

  if (newQuery !== '' && gte && lte) {
    newQuery.bool['filter'].push({
      range: {
        '@timestamp': {
          gte: moment(moment.utc(gte).toDate())
            .local()
            .format('YYYY-MM-DDTHH:mm:ss'),
          lte: moment(moment.utc(lte).toDate())
            .local()
            .format('YYYY-MM-DDTHH:mm:ss'),
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          format: 'strict_date_optional_time'
        }
      }
    });
  }

  let fullQuery = getQuerySorted(
    index,
    size,
    page,
    sortBy,
    sortOrder,
    gte,
    lte,
    organization,
    newQuery
  );

  let convertTermToMatchquery = convertTermToMatch(fullQuery);
  // if (overriddenIndex) {
  //   convertTermToMatchquery.query.index = [...overriddenIndex];
  //   totalQuery.query.index = [...overriddenIndex];
  // }
  if (query_string) {
    let rulesInjectedBool2 = injectRules(
      convertTermToMatchquery.query.body.query,
      {
        should: [],
        must: [query_string],
        must_not: [],
        filter: []
      }
    );
    convertTermToMatchquery.query.body.query = rulesInjectedBool2;
  }

  let shouldFromScope = convertToShouldArrayForScopes(scopes, inActiveOrgs);

  let scopesInjected = injectRules(convertTermToMatchquery.query.body.query, {
    should: shouldFromScope || [],
    must: [],
    must_not: [],
    filter: []
  });

  convertTermToMatchquery.query.body.query = scopesInjected;
  convertTermToMatchquery.query.body.query.bool['minimum_should_match'] = 1;
  setESGlobalFilters(prevState => {
    return {
      ...prevState,
      completeQuery: convertTermToMatchquery,
      refresh: true,
      update: false
    };
  });
}

export const deepCopy = obj => JSON.parse(JSON.stringify(obj));
