import React from 'react';
import PropTypes from 'prop-types';
import ReportsProvider from 'context/admin-contexts/ReportsProvider';
// import UsersProvider from '../../../../../../context/admin-contexts/UsersProvider';
// import OrganizationProvider from 'context/admin-contexts/OrganizationsProvider';
import ModalReportView from './components/modals/ReportsViewModal';
import ModalReportDownload from './components/modals/DownloadModal';
import ReportsLayout from './ReportsLayout';
import ModalReportDelete from './components/modals/ReportsDeleteModal';
// import ModalReportAdd from './components/modals/ReportsAddModal';
// import ModalReportEdit from './components/modals/ReportsEditModal';
// import ModalReportDelete from './components/modals/ReportsDeleteModal';
// import ReportsRestoreModal from './components/modals/ReportsRestoreModal';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ReportsWrapper = props => {
  return (
    <ReportsProvider>
      {props.children}
      <ReportsLayout />
      <ModalReportView />
      <ModalReportDownload />
      <ModalReportDelete />
    </ReportsProvider>
  );
};

ReportsWrapper.propTypes = { children: PropTypes.node };

export default ReportsWrapper;
