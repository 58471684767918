import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import SearchValueContextMenu from 'components/search-filters/SearchValueContextMenu';
import useExplore from 'hooks/useExplore';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { Table } from 'react-bootstrap';
import AdvancedPopover from '../Popover';
import ExploreTableExpandingRow from './ExploreTableExpandingRow';
import SourceField from './source-field/SourceField';

/**
 * Renders an advanced table component for our Explore dashboard.
 *
 * @param {Object} props - The component props
 * @param {Function} props.getTableProps - Function to get the table props
 * @param {Array} props.headerGroups - Array of header group
 * @param {Array} props.page - Array of table rows
 * @param {Function} props.prepareRow - Function to prepare a row for rendering
 * @param {string} props.headerClassName - CSS class name for the table header
 * @param {string} props.headerCellClassName - CSS class name for the table header cells
 * @param {string} props.bodyClassName - CSS class name for the table body
 * @param {string} props.cellClassName - CSS class name for the table cells
 * @param {string} props.rowClassName - CSS class name for the table rows
 * @param {Object} props.tableProps - Additional props for the table component
 * @param {Function} props.setExploreState - Function to set the explore data
 * @param {Object} props.lastRowElementRef - Ref to the last row element for infinite scroll observation
 * @returns {JSX.Element} The rendered table component
 */
const ExploreTableBase = ({
  getTableProps,
  headerGroups,
  page = [], // Default to empty array to avoid undefined
  prepareRow,
  headerClassName,
  headerCellClassName,
  bodyClassName,
  cellClassName,
  rowClassName,
  tableProps = {},
  setExploreState,
  lastRowElementRef
}) => {
  const {
    state: { fieldsSelected, fieldsSelectedFallback }
  } = useExplore();

  const rowRefs = useRef([]);
  const expandedRef = useRef(null);

  // Ensure focus is set to the row when any cell is clicked
  const handleRowClick = useCallback(event => {
    const row = event.currentTarget;
    row.focus();
  }, []);

  const handleClickOutside = useCallback(event => {
    if (
      expandedRef.current &&
      !expandedRef.current.contains(event.target) &&
      !rowRefs.current.some(ref => ref && ref.contains(event.target))
    ) {
      // Close all expanded rows
      rowRefs.current.forEach(row => {
        if (row?.isExpanded) {
          row.toggleRowExpanded(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    // Ensure each row gets focus when clicked by setting event listeners
    rowRefs.current.forEach(rowRef => {
      if (rowRef) {
        rowRef.addEventListener('click', handleRowClick);
      }
    });

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);

      // Clean up event listeners when component unmounts or rows change
      rowRefs.current.forEach(rowRef => {
        if (rowRef) {
          rowRef.removeEventListener('click', handleRowClick);
        }
      });
    };
  }, [page, handleRowClick, handleClickOutside]);

  /**
   * Removes a field from the list of selected fields.
   *
   * @param {string} fieldId - The ID of the field to be removed
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleRemoveField = fieldId => {
    const filteredFields = fieldsSelected.filter(
      column => column.id !== fieldId
    );
    setExploreState('fieldsSelected', filteredFields);
  };

  /**
   * Shifts the specified field to the left in the fieldsSelected array.
   *
   * @param {string} fieldId - The ID of the field to be shifted
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleShiftLeft = fieldId => {
    const currentIndex = fieldsSelected.findIndex(
      column => column.id === fieldId
    );
    if (currentIndex === 0 || currentIndex <= fieldsSelectedFallback.length)
      return;
    const updatedFieldsSelected = [...fieldsSelected];
    const temp = updatedFieldsSelected[currentIndex];
    updatedFieldsSelected[currentIndex] =
      updatedFieldsSelected[currentIndex - 1];
    updatedFieldsSelected[currentIndex - 1] = temp;
    setExploreState('fieldsSelected', updatedFieldsSelected);
  };

  /**
   * Shifts the selected field to the right in the explore table.
   *
   * @param {string} fieldId - The ID of the field to be shifted
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleShiftRight = fieldId => {
    const currentIndex = fieldsSelected.findIndex(
      column => column.id === fieldId
    );
    if (currentIndex === fieldsSelected.length - 1) return;
    const updatedFieldsSelected = [...fieldsSelected];
    const temp = updatedFieldsSelected[currentIndex];
    updatedFieldsSelected[currentIndex] =
      updatedFieldsSelected[currentIndex + 1];
    updatedFieldsSelected[currentIndex + 1] = temp;
    setExploreState('fieldsSelected', updatedFieldsSelected);
  };

  return (
    <div className='explore-table table-responsive scrollbar'>
      <Table {...getTableProps(tableProps)}>
        <thead className={headerClassName}>
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((header, headerIndex) => (
                <th
                  key={headerIndex}
                  className={classNames(
                    'p-0 py-1 pe-2 fs--2 align-middle text-nowrap',
                    headerCellClassName,
                    {
                      'ps-1 pe-2':
                        header?.id !== '@timestamp' && header?.id !== '_source'
                    }
                  )}>
                  <span className='text-700 me-1'>
                    {header.render('Header')}
                  </span>
                  <span
                    {...header.getHeaderProps(
                      header.getSortByToggleProps(header.headerProps)
                    )}>
                    {header?.enabledSorting &&
                      (header.isSorted ? (
                        header.isSortedDesc ? (
                          <FontAwesomeIcon
                            icon='sort-down'
                            width={12}
                            className='hover-700 cursor-pointer'
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon='sort-up'
                            width={12}
                            className='hover-700 cursor-pointer'
                          />
                        )
                      ) : (
                        <FontAwesomeIcon
                          icon='up-down'
                          width={12}
                          className='text-400 hover-700 cursor-pointer'
                          transform='shrink-2'
                        />
                      ))}
                  </span>
                  {header.canRemove && (
                    <>
                      <FontAwesomeIcon
                        icon='remove'
                        width={12}
                        className='text-400 hover-700 cursor-pointer'
                        transform='grow-3'
                        onClick={() => handleRemoveField(header.id)}
                      />
                      <FontAwesomeIcon
                        icon='caret-left'
                        width={12}
                        className='text-400 hover-700 cursor-pointer'
                        transform='grow-2'
                        onClick={() => handleShiftLeft(header.id)}
                      />
                      <FontAwesomeIcon
                        icon='caret-right'
                        width={12}
                        className='text-400 hover-700 cursor-pointer'
                        transform='grow-2'
                        onClick={() => handleShiftRight(header.id)}
                      />
                    </>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={classNames(bodyClassName, 'position-relative')}>
          {page.length === 0 ? (
            <tr>
              <td>
                <Flex
                  justifyContent='center'
                  alignContent='center'
                  alignItems='center'
                  className='position-absolute all-0 fs--2'>
                  No artifacts found matching search criteria.
                </Flex>
              </td>
            </tr>
          ) : (
            page.map((row, rowIndex) => {
              prepareRow(row);
              const isLastRow = rowIndex === page.length - 1;
              return (
                <React.Fragment key={rowIndex}>
                  <tr
                    tabIndex={0}
                    key={rowIndex}
                    {...row.getRowProps()}
                    className={classNames(rowClassName, {
                      active: row.isExpanded
                    })}
                    ref={
                      isLastRow
                        ? lastRowElementRef
                        : el => (rowRefs.current[rowIndex] = el)
                    }>
                    {row.cells.map((cell, cellIndex) =>
                      cell.column.id === '@timestamp' ? (
                        <td
                          key={cellIndex}
                          className={classNames(
                            'align-middle p-0 py-1 fs--2 text-700',
                            cellClassName
                          )}
                          {...cell.getCellProps()}>
                          <Flex
                            alignContent='center'
                            alignItems='center'
                            className='h-100 position-relative'>
                            {moment(
                              cell.value,
                              'YYYY-MM-DDTHH:mm:ss.SSSZ'
                            ).format('YYYY-MM-DD HH:mm:ss.SSS')}
                          </Flex>
                        </td>
                      ) : cell.column.id === '_source' ? (
                        <td
                          key={cellIndex}
                          className={classNames(
                            'align-middle p-0 py-1 fs--2',
                            cellClassName
                          )}
                          {...cell.getCellProps()}>
                          <Flex
                            alignContent='center'
                            className='position-relative'>
                            <SourceField sourceField={cell.row.original} />
                          </Flex>
                        </td>
                      ) : cell.column.id === 'explore_selection' ? (
                        <td
                          key={cellIndex}
                          className={classNames(
                            'align-middle p-0 py-1 pe-2 ps-1 fs--2',
                            cellClassName
                          )}
                          {...cell.getCellProps()}>
                          {/* Combine the selector and caret */}
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              flexDirection: 'column'
                            }}>
                            {/* Selector (checkbox) */}
                            {cell.render('Cell')}
                            {cell.row.original.tags?.some(
                              tag => tag === 'Alert' || tag === 'Notice'
                            ) && (
                              <AdvancedPopover
                                placement='top'
                                popoverText='This artifact was either generated by, or was flagged as suspicious by one of our protocol analyzers or advanced detection algorithms.'
                                showArrow={true}>
                                <FontAwesomeIcon
                                  icon='exclamation-triangle'
                                  className='text-alert opacity-50'
                                  transform='grow-2'
                                />
                              </AdvancedPopover>
                            )}
                            {/* Caret for expanding the row */}
                            <FontAwesomeIcon
                              icon={
                                row.isExpanded ? faChevronDown : faChevronRight
                              }
                              className='cursor-pointer mt-2' // Add some margin for spacing
                              onClick={() => row.toggleRowExpanded()}
                              transform={'shrink-2 up-5 fs--2'}
                              width={12}
                            />
                          </div>
                        </td>
                      ) : cell.column?.hasRender ? (
                        <td
                          key={cellIndex}
                          className={classNames(
                            'align-middle p-0 py-1 ps-1 pe-1 fs--2 text-700',
                            cellClassName
                          )}
                          {...cell.getCellProps()}>
                          <Flex className='position-relative'>
                            {cell.render('Cell')}
                          </Flex>
                        </td>
                      ) : (
                        <td
                          key={cellIndex}
                          className={classNames(
                            'align-middle p-0 py-1 ps-1 pe-1 fs--2 text-700',
                            cellClassName
                          )}
                          {...cell.getCellProps()}>
                          <SearchValueContextMenu
                            searchValue={cell.row.original[cell.column.id]}
                            searchKey={cell.column.id}
                            valueType={cell.column.type}
                          />
                        </td>
                      )
                    )}
                  </tr>
                  {row.isExpanded ? (
                    <tr ref={expandedRef} className='active'>
                      <td
                        colSpan={headerGroups[0].headers.length + 1}
                        className='p-0'>
                        <ExploreTableExpandingRow cells={row.original} />
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
};

ExploreTableBase.propTypes = {
  bodyClassName: PropTypes.string,
  cellClassName: PropTypes.string,
  getTableProps: PropTypes.func,
  headerCellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  headerGroups: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  rowClassName: PropTypes.string,
  setExploreState: PropTypes.func,
  tableProps: PropTypes.object,
  lastRowElementRef: PropTypes.object
};

export default ExploreTableBase;
