import { routes } from 'config/routeSettings';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthentication } from './useAuthentication';

/**
 * Custom hook that checks the session lock status and performs redirects accordingly.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
export const useSessionLockCheck = () => {
  const { authentication } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();

  // Simplifying the extraction of `sessionLocked` for readability.
  const sessionLocked = authentication?.session?.device?.sessionLocked;

  useEffect(() => {
    if (sessionLocked) {
      // If session is locked and user is not on LOCK screen, redirect to LOCK.
      if (location.pathname !== routes.LOCK) {
        navigate(routes.LOCK, {
          state: { prevPath: location.pathname },
          replace: true
        });
      }
    } else {
      // If session is not locked and user is on LOCK screen, redirect away.
      if (location.pathname === routes.LOCK) {
        navigate(location.state?.prevPath || routes.OVERVIEW, {
          replace: true
        });
      }
    }
  }, [sessionLocked, location.pathname, navigate]);
};

export default useSessionLockCheck;
