import AddItemButton from 'components/common/AddItemButton.js';
import Flex from 'components/common/Flex.js';
import IconSelector from 'components/common/IconSelector.js';
import MultiSelect from 'components/common/MultiSelect.js';
import useApplication from 'hooks/useApplication.js';
import React from 'react';
import { Button, Card, Col, Form, FormText, Row } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import Container from './_components/Container.js';
import MenuSelectCard from './_components/MenuSelectCard/index.js';

const MenusLayout = () => {
  const {
    application: { systemRoles }
  } = useApplication();

  return (
    <>
      <Row className='g-3 mb-3'>
        <Col>
          <MenuSelectCard />
        </Col>
      </Row>
      <Row className='g-3 mb-3'>
        <Col md={6} xl={4}>
          <Card>
            <Card.Body>
              <h5 className='fs--1'>Top-level route settings</h5>
              <Form>
                <Row className='mb-3'>
                  <Col sm={2}>
                    <Form.Group controlId='formIcon'>
                      <Form.Label className='fs--1 fw-normal'>Icon</Form.Label>
                      <IconSelector />
                    </Form.Group>
                  </Col>
                  <Col sm={10}>
                    <Form.Group controlId='formLabel'>
                      <Form.Label className='fs--1 fw-normal'>Label</Form.Label>
                      <Form.Control
                        size='sm'
                        type='text'
                        defaultValue='Artifacts'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <Form.Group controlId='formLabelDisable'>
                      <Flex alignItems='center'>
                        <Form.Check
                          type='switch'
                          defaultChecked={true}
                          className='mb-0'
                        />
                        <Form.Label className='fs--1 fw-normal m-0 ms-2'>
                          Disable label
                        </Form.Label>
                      </Flex>
                      <FormText className='fs--1 m-0'>
                        This will hide the label from the menu
                      </FormText>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <Form.Group controlId='formAllowedRoles'>
                      <Form.Label className='fs--1 fw-normal'>
                        Allowed Roles
                      </Form.Label>
                      <MultiSelect
                        name='allowedRoles'
                        isMulti
                        isClearable
                        isSearchable
                        options={Object.entries(systemRoles).map(
                          ([key, value]) => ({
                            value: key,
                            label: value.name
                          })
                        )}
                        selectClassName='ps-3'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <Form.Group controlId='formDevelopment'>
                      <Flex alignItems='center'>
                        <Form.Check
                          type='switch'
                          defaultChecked={true}
                          className='mb-0'
                        />
                        <Form.Label className='fs--1 fw-normal m-0 ms-2'>
                          Development Only
                        </Form.Label>
                      </Flex>
                      <FormText className='fs--1 m-0'>
                        Only show when development pages are turned on
                      </FormText>
                    </Form.Group>
                  </Col>
                </Row>

                <p className='fs--2 text-muted'>
                  Top-level routes don't link anywhere but instead serve as
                  categories for nested routes. A page component will be
                  automatically generated for each top-level page with
                  place-cards for each of it's immediate child routes. This will
                  assist users in the event that they get lost.
                </p>

                <Button variant='primary' type='submit'>
                  Save Settings
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6} xl={8}>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <h5 className='fs--1 mb-3'>Edit sub-routes</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DndProvider
                    backend={TouchBackend}
                    options={{ enableMouseEvents: true }}>
                    <Container />
                  </DndProvider>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <AddItemButton label='sub-route' />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MenusLayout;
