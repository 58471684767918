import React, { forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const DateTimePickerInput = forwardRef(({ value, onClick }, ref) => {
  return (
    <>
      <Form.Control
        style={{ minHeight: '36px' }}
        size='sm'
        ref={ref}
        onClick={onClick}
        defaultValue={value}
        className='w-100   fs--1 p-0 mb-0  '
      />
    </>
  );
});

DateTimePickerInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  iconRotation: PropTypes.number,
  allowedLimit: PropTypes.number,
  checkerIndices: PropTypes.array,
  index: PropTypes.string
};
