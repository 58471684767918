import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';
const threatIntel = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'Threat Intelligence Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-zeek'],
        isInitialized: 'isInitialized',
        must_not: [
          {
            match_phrase: {
              sources: 'CPS-VS'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Indicator Count',
          helpInfo: 'Total count of known indicators seen in network flows'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-zeek'],
        isInitialized: 'isInitialized',
        must: [
          {
            match_all: {}
          },
          {
            exists: {
              field: 'source_geoip.country_code2.keyword'
            }
          }
        ],
        must_not: [
          {
            match_phrase: {
              'source_geoip.country_code2.keyword': 'US'
            }
          },
          {
            match_phrase: {
              sources: 'CPS-VS'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Non-US Sources',
          helpInfo:
            'Total count of known indicators seen in network flows from non-US sources'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['cardinality'],
        overriddenIndex: ['leargas-zeek'],
        aggregate: {
          aggs: {
            1: {
              cardinality: {
                field: 'seen.indicator.keyword'
              }
            }
          },
          size: 0
        },
        must_not: [
          {
            match_phrase: {
              sources: 'CPS-VS'
            }
          }
        ]
      },
      children: {
        component: 'MetricPlain',
        props: {
          className: '',
          title: 'Unique Indicators',
          helpInfo:
            'Total unique count of known indicators seen in network flows'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['cardinality'],
        overriddenIndex: ['leargas-zeek'],
        aggregate: {
          aggs: {
            1: {
              cardinality: {
                field: 'id.orig_h.keyword'
              }
            }
          },
          size: 0
        },
        must_not: [
          {
            match_phrase: {
              sources: 'CPS-VS'
            }
          }
        ]
      },
      children: {
        component: 'MetricPlain',
        props: {
          className: '',
          title: 'Impacted Hosts',
          helpInfo: 'Total count of unique hosts impacted by known indicators'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['histogram'],
        overriddenIndex: ['leargas-zeek'],
        must: [
          {
            match_all: {}
          }
        ],
        must_not: [
          {
            match_phrase: {
              sources: 'CPS-VS'
            }
          }
        ]
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Event Timeline',
          helpInfo: 'Timeline of all events matching the search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-zeek'],
        queryType: ['map'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'id.orig_h_geoip.country_name.keyword',
                order: {
                  _count: 'desc'
                },
                exclude: [''],
                size: 100
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_all: {}
          }
        ],
        must_not: [
          {
            match_phrase: {
              sources: 'CPS-VS'
            }
          }
        ]
      },
      children: {
        component: 'Map',
        props: {
          className: '',
          field: 'source_geoip.country_name',
          title: 'Origin Countries',
          helpInfo: 'Heatmap of origin countries'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-zeek'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'sources.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_all: {}
          }
        ],
        must_not: [
          {
            match_phrase: {
              sources: 'CPS-VS'
            }
          }
        ]
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Feed Sources',
          field: 'sources',
          helpInfo:
            'Sources of threat intelligence feeds for observed indicators'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-zeek'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'seen.indicator_type.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_all: {}
          }
        ],
        must_not: [
          {
            match_phrase: {
              sources: 'CPS-VS'
            }
          }
        ]
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Indicator Types',
          helpInfo:
            'Zeek threat intel indicator types refer to various data formats and patterns used to identify and analyze cybersecurity threats within network traffic, assisting in the detection of malicious activities and potential vulnerabilities',
          field: 'seen.indicator_type'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-zeek'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'seen.where.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_all: {}
          }
        ],
        must_not: [
          {
            match_phrase: {
              sources: 'CPS-VS'
            }
          }
        ]
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Indicators Seen In',
          field: 'seen.where',
          helpInfo:
            'Locations within network traffic where indicators are observed'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-zeek'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'file_mime_type.keyword',
                order: {
                  _count: 'desc'
                },
                size: 50
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_all: {}
          }
        ],
        must_not: [
          {
            match_phrase: {
              sources: 'CPS-VS'
            }
          }
        ]
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Indicator File Types',
          helpInfo:
            'File types related to seen indicators, such as a certificate',
          field: 'file_mime_type'
        }
      }
    }
  ];
};
export default threatIntel;
