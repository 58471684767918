const CircularJSON = require('circular-json');

/**
 * Serializes an error object to a JSON object.
 *
 * @param {Error} err - The error object to be serialized
 * @returns {Object} - The serialized error object
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const serializeError = err => {
  try {
    // Attempt to serialize the full error object using circular-json
    const fullErrorData = CircularJSON.stringify({
      name: err?.name,
      message: err?.message,
      stack: err?.stack,
      ...err
    });

    return JSON.parse(fullErrorData);
  } catch (customErr) {
    // Fallback: Provide essential error details when serialization fails
    return {
      message: customErr?.message,
      stack: customErr?.stack,
      serializationError: `Failed to serialize error: ${customErr?.message}`
    };
  }
};

module.exports = { serializeError };
