// import MultiSelect from 'components/common/MultiSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import PropTypes from 'prop-types';

// import useOrganizations from 'hooks/admin-contexts/useOrganizations';

import useApplication from 'hooks/useApplication';
// import { useESGlobalFilters } from 'hooks/useGlobalFilters';

import useExplore from 'hooks/useExplore';
import React, { useEffect, useRef } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

/**
 * Renders the SaveSearchModal component
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const SaveSearchModal = () => {
  const modalRef = useRef(null);

  const {
    application: { isDark, showEditSavedSearchModal },
    setApplication
  } = useApplication();

  const {
    state: { savedSearch },
    setState
  } = useExplore();

  const {
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    register
  } = useForm();

  const { name } = watch();

  /**
   * Handle the submit of the savedSearches add form
   *
   * @param {Object} data
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const onSubmit = async data => {
    try {
      setState('savedSearch', { ...savedSearch, ...data });
      setApplication('showEditSavedSearchModal', false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (savedSearch) {
      reset({
        name: savedSearch?.name,
        description: savedSearch?.description
      });
    }
  }, [savedSearch, reset]);
  return (
    <Modal
      aria-labelledby='addSavedSearchModal'
      centered
      contentClassName='shadow-none'
      keyboard={true}
      onHide={() => setApplication('showEditSavedSearchModal', false)}
      ref={modalRef}
      show={showEditSavedSearchModal}
      size='lg'>
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            className='p-2'
            closeButton
            closeVariant={isDark ? 'white' : undefined}>
            <h5 className='d-flex align-items-center fs-0 fw-normal position-relative'>
              Edit Saved Search{' '}
              <AdvancedPopover
                containerId='newFilterModal'
                placement='top'
                popoverText='Edit the current search'
                showArrow={true}>
                <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  className='ms-1 fs--1 cursor-pointer text-400'
                />
              </AdvancedPopover>
            </h5>
          </Modal.Header>

          <Modal.Body className='p-2'>
            <Row className='g-3 mb-2'>
              <Col>
                <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
                  Name <span className='text-danger ms-1'>*</span>
                  <AdvancedPopover
                    containerId='newFilterModal'
                    placement='top'
                    popoverText='The name of the saved search.'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  {...register('name', {
                    required: 'Query Name is required'
                  })}
                  type='text'
                  autoComplete='off'
                  className={` ${errors.name ? 'is-invalid' : ''}`}
                />
                <code>{errors.name?.message}</code>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className='d-flex fs--1 fw-normal align-items-center'>
                  Description{' '}
                  <AdvancedPopover
                    containerId='newFilterModal'
                    placement='top'
                    popoverText='A description for the saved search and what its purpose is.'
                    showArrow={true}>
                    <FontAwesomeIcon
                      icon={['far', 'question-circle']}
                      className='ms-1 text-400 fs--1 cursor-pointer'
                    />
                  </AdvancedPopover>
                </Form.Label>
                <Form.Control
                  {...register('description')}
                  type='text'
                  as='textarea'
                  rows={3}
                  autoComplete='off'
                  className={` ${errors.name ? 'is-invalid' : ''}`}
                />
                <code>{errors.name?.message}</code>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className='p-2'>
            <Row className='g-1 m-0 p-0 w-100'>
              <Col xs={12} className='p-0'>
                <Flex direction='row' justifyContent='end'>
                  <Button
                    size='sm'
                    variant='secondary'
                    className='me-2'
                    onClick={() =>
                      setApplication('showEditSavedSearchModal', false)
                    }>
                    Cancel
                  </Button>
                  <Button
                    variant='success'
                    type='submit'
                    size='sm'
                    disabled={!name}
                    className='d-flex'>
                    <Flex justifyContent={'center'} alignItems={'center'}>
                      <span>Save</span>
                    </Flex>
                  </Button>
                </Flex>
              </Col>
            </Row>
          </Modal.Footer>
        </Form>
      </Flex>
    </Modal>
  );
};

SaveSearchModal.propTypes = {
  setSaveSearchModal: PropTypes.bool,
  saveSearchModal: PropTypes.func,
  indices: PropTypes.array
};

export default SaveSearchModal;
