import { useContext } from 'react';
import { IndicesContext } from 'context/admin-contexts/IndicesProvider';

/**
 * Custom hook to use the IndicesContext
 * @returns {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const useIndices = () => {
  return useContext(IndicesContext);
};

export default useIndices;
