import { keystrokeDefinitions } from 'config/keystrokeSettings';
import { routes } from 'config/routeSettings';
import is from 'is_js';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// import { driver } from 'driver.js';
// import 'driver.js/dist/driver.css';

/**
 * Error Pages
 */
import Error401 from 'components/errors/Error401';
import Error403 from 'components/errors/Error403';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

/**
 * Role & Auth Checks
 */
import PersistAuth from 'components/authentication/PersistAuth';
import RequireAuth from 'components/authentication/RequireAuth';
import RolesWrapper from 'components/authorization/RolesWrapper';

/**
 * Custom Hooks
 */
import useKeystrokeDetection from 'hooks/useKeystrokeDetection';
import useMultiFactorApp from 'hooks/useMultiFactorApp';
import useSessionCheck from 'hooks/useSessionCheck';
import useSessionLockCheck from 'hooks/useSessionLockCheck';

/**
 * Sub Layouts
 */
import AuthenticationLayout from 'layouts/AuthenticationLayout';
import ErrorLayout from 'layouts/ErrorLayout';
import ExploreLayout from 'layouts/ExploreLayout';
import IntroductionLayout from 'layouts/IntroductionLayout';
import MainLayout from 'layouts/MainLayout';
import CategoryLayout from '../layouts/CategoryLayout';
import SystemLayout from '../layouts/SystemLayout';

/**
 * Authentication Pages
 */
import AuthActivate from 'components/authentication/Activate';
import AuthConfirmMail from 'components/authentication/ConfirmMail';
import AuthForgetPassword from 'components/authentication/ForgotPassword';
import AuthLockScreen from 'components/authentication/LockScreen';
import AuthLogin from 'components/authentication/Login';
import AuthMFASetup from 'components/authentication/MFASetup';
import AuthOTPVerify from 'components/authentication/OTPVerify';
import AuthRegistration from 'components/authentication/Register';
import AuthPasswordReset from 'components/authentication/ResetPassword';

/**
 * Account Pages
 */
// import Account from 'components/account';
import AccountProfileEdit from 'components/account/ProfileEdit';
// import AccountNotifications from 'components/account/Notifications';
// import AccountSettings from 'components/account/Settings';

/**
 * Other Profile Pages
 */
import UserProfile from 'components/common/users/UserProfile';
import OrgProfile from 'components/dashboards/Organizations/OrganizationProfile/index';

/**
 * Dynamic Dashboards
 */
import DynamicDashRouter from 'components/dashboards/Artifacts/Dynamic/DynamicDashRouter';

/**
 * Non-Dynamic Dashboard Pages
 */
import ArtifactsPage from 'components/dashboards/Artifacts';
import ArtifactsCloud from 'components/dashboards/Artifacts/Cloud';
import ArtifactsCloudAws from 'components/dashboards/Artifacts/Cloud/Aws';
import ArtifactsEndpoint from 'components/dashboards/Artifacts/Endpoint';
import ArtifactsEndpointSentinelOne from 'components/dashboards/Artifacts/Endpoint/SentinelOne';
import ArtifactsExplore from 'components/dashboards/Artifacts/Explore';
import Overview from 'components/dashboards/Overview';

/**
 * Manage Pages
 */
import ManageAlerts from 'components/dashboards/Alerts';
import ManageAppliances from 'components/dashboards/Appliances';
import ManageBookmarks from 'components/dashboards/Bookmarks';
import ManageCases from 'components/dashboards/Cases';
import ManageIncidents from 'components/dashboards/Incidents';
import ManageOrganizations from 'components/dashboards/Organizations';
import ManageReports from 'components/dashboards/Reports';
import ManageSavedSearches from 'components/dashboards/SavedSearches';
import ManageDetections from 'components/dashboards/System/Application/Manage/Detections';
import ManageTeams from 'components/dashboards/Teams';

/**
 * Resources Pages
 */
import Resources from 'components/dashboards/Resources';
import ChangeLog from 'components/dashboards/Resources/ChangeLog';
import NewsUpdates from 'components/dashboards/Resources/NewsUpdates';
import ReportIssue from 'components/dashboards/Resources/ReportIssue';

/**
 * System Pages
 */
import Sys from 'components/dashboards/System';
import SysApp from 'components/dashboards/System/Application';
import SysAppManage from 'components/dashboards/System/Application/Manage';
import SysAppManageAlerts from 'components/dashboards/System/Application/Manage/Alerts';
import SysAppManageArticles from 'components/dashboards/System/Application/Manage/Articles';
import SysAppManageArticlesCategories from 'components/dashboards/System/Application/Manage/Articles/ArticleCategories';
import SysAppManageArticlesTags from 'components/dashboards/System/Application/Manage/Articles/ArticleTags';
import SysAppManageBookmarks from 'components/dashboards/System/Application/Manage/Bookmarks';
import SysAppManageDetections from 'components/dashboards/System/Application/Manage/Detections';
import SysAppManageJobs from 'components/dashboards/System/Application/Manage/Jobs';
import SysAppManageOrganizations from 'components/dashboards/System/Application/Manage/Organizations';
import SysAppManageProducts from 'components/dashboards/System/Application/Manage/Products';
import SysAppManageProductsAppliances from 'components/dashboards/System/Application/Manage/Products/Appliances';
import SysAppManageProductsInstances from 'components/dashboards/System/Application/Manage/Products/Instances';
import SysAppManageReleases from 'components/dashboards/System/Application/Manage/Releases';
import SysAppManageReports from 'components/dashboards/System/Application/Manage/Reports';
import SysAppManageReportsTemplates from 'components/dashboards/System/Application/Manage/Reports/Templates';
import SysAppManageSavedSearches from 'components/dashboards/System/Application/Manage/SavedSearches';
import SysAppManageSubscriptions from 'components/dashboards/System/Application/Manage/Subscriptions';
import SysAppManageSubscriptionsLevels from 'components/dashboards/System/Application/Manage/Subscriptions/Levels';
import SysAppManageTeams from 'components/dashboards/System/Application/Manage/Teams';
import SysAppManageUsers from 'components/dashboards/System/Application/Manage/Users';
import SysAppSecurity from 'components/dashboards/System/Application/Security';
import SysAppSecurityPermissions from 'components/dashboards/System/Application/Security/Permissions';
import SysAppSecurityResources from 'components/dashboards/System/Application/Security/Resources';
import SysAppSecurityRoles from 'components/dashboards/System/Application/Security/Roles';
import SysAppSettings from 'components/dashboards/System/Application/Settings';
import SysAppSettingsData from 'components/dashboards/System/Application/Settings/Data';
import SysAppSettingsDataFields from 'components/dashboards/System/Application/Settings/Data/Fields';
import SysAppSettingsDataIndices from 'components/dashboards/System/Application/Settings/Data/Indices';
import SysAppSettingsDisplay from 'components/dashboards/System/Application/Settings/Display';
import SysAppSettingsDisplayDashboards from 'components/dashboards/System/Application/Settings/Display/Dashboards';
import SysAppSettingsDisplayMenus from 'components/dashboards/System/Application/Settings/Display/Menus';
import SysAppSettingsDisplayRoutes from 'components/dashboards/System/Application/Settings/Display/Routes';
import SysAppSettingsDisplayVisualizations from 'components/dashboards/System/Application/Settings/Display/Visualizations';
import SysAppSettingsGeneral from 'components/dashboards/System/Application/Settings/General';
import SysAppSettingsNotification from 'components/dashboards/System/Application/Settings/Notification';
import SysAppSettingsPrivacy from 'components/dashboards/System/Application/Settings/Privacy';
import SysToolsDBAuditLogs from 'components/dashboards/System/DatabaseCluster/AuditLogs';
import SysToolsDBCollections from 'components/dashboards/System/DatabaseCluster/Collections';
import SysToolsDBHealth from 'components/dashboards/System/DatabaseCluster/Health';
import SysToolsDBNodes from 'components/dashboards/System/DatabaseCluster/Nodes';
import SysToolsDBRoles from 'components/dashboards/System/DatabaseCluster/Roles';
import SysToolsDBUsers from 'components/dashboards/System/DatabaseCluster/Users';
import SysToolsES from 'components/dashboards/System/SearchCluster';
import SysToolsESAuditLogs from 'components/dashboards/System/SearchCluster/AuditLogs';
import SysToolsESHealth from 'components/dashboards/System/SearchCluster/Health';
import SysToolsESIndices from 'components/dashboards/System/SearchCluster/Indices';
import SysToolsESNodes from 'components/dashboards/System/SearchCluster/Nodes';
import SysTools from 'components/dashboards/System/Tools';

/**
 * Tools Pages
 */
import DatabaseClusterPage from 'components/dashboards/System/DatabaseCluster';
import Alerting from 'components/dashboards/System/Tools/Alerting';
import AlertStatuses from 'components/dashboards/System/Tools/Alerting/Statuses';
import Reporting from 'components/dashboards/System/Tools/Reporting';
import ReportStatuses from 'components/dashboards/System/Tools/Reporting/Statuses';

/**
 * Misc Pages
 */
import Profile from 'components/account/Profile';
import Articles from 'components/dashboards/System/Application/Manage/Articles/components/views/ArticleView/ArticleViewWrapper';

/**
 * The main router for the application that handles all routing and layout rendering.
 *
 * @component
 * @returns {ReactNode} The rendered component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
const MainRouter = () => {
  // Use the session check to ensure a valid session token before rendering the layout
  useSessionCheck();

  // Use the multi-factor app hook to check if the user has setup and verified their MFA app
  useMultiFactorApp();

  // Use the session lock check to ensure the user is still active and lock the session if not
  useSessionLockCheck();

  // Use the keystroke detection hook to listen for keystrokes and execute the defined actions
  useKeystrokeDetection(keystrokeDefinitions);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  // const {
  //   account: {
  //     profile: {
  //       name: { first }
  //     }
  //   }
  // } = useAccount();

  // const driverObj = driver({
  //   showProgress: true,
  //   allowClose: false,
  //   overlayOpacity: 0.4,
  //   steps: [
  //     {
  //       popover: {
  //         title: `Welcome, ${first}!`,
  //         description:
  //           "Thank you for considering Léargas! We're excited to have you here.<br><br>During this onboarding process, we will guide you through the application's key features and functionalities and also, how you can begin to use Léargas to streamline your organization's security and data monitoring needs.<br><br>Let's get started and explore the power of Léargas together!",
  //         onNextClick: () => {
  //           setApplication('driver_onboarding_step', 1);
  //           setApplication('driver_onboarding', false);
  //           driverObj.moveNext();
  //         }
  //       }
  //     },
  //     {
  //       element: '#userProfileAvatar',
  //       popover: {
  //         title: 'Your account and assets at your fingertips',
  //         description: 'Access and manage your account details and assets quickly from the user profile context menu.',
  //         side: 'left',
  //         align: 'start',
  //         onPrevClick: () => {
  //           setApplication('showProfileMenu', false);
  //           setApplication('driver_onboarding_step', 0);
  //           setApplication('driver_onboarding', false);
  //           driverObj.movePrevious();
  //         },
  //         onNextClick: async () => {
  //           await setApplication('showProfileMenu', true);
  //           setApplication('driver_onboarding_step', 2);
  //           setApplication('driver_onboarding', false);
  //           driverObj.moveNext();
  //         }
  //       }
  //     },
  //     {
  //       element: '#userProfileMenu .profile-menu-wrapper',
  //       popover: {
  //         description:
  //           'When you click the user profile icon, a context menu will appear with options to access your account, settings, notifications, and more.',
  //         side: 'left',
  //         align: 'start',
  //         onPrevClick: () => {
  //           setApplication('showProfileMenu', true);
  //           setApplication('driver_onboarding_step', 1);
  //           setApplication('driver_onboarding', false);
  //           driverObj.movePrevious();
  //         },
  //         onNextClick: () => {
  //           setApplication('showProfileMenu', false);
  //           setApplication('driver_onboarding_step', 3);
  //           setApplication('driver_onboarding', false);
  //           driverObj.moveNext();
  //         }
  //       }
  //     },
  //     {
  //       popover: {
  //         title: 'Excellent!',
  //         description: "If you have any questions or concerns, please contact our support team. We're always happy to help!",
  //         onPrevClick: () => {
  //           setApplication('showProfileMenu', true);
  //           setApplication('driver_onboarding_step', 2);
  //           setApplication('driver_onboarding', false);
  //           driverObj.movePrevious();
  //         },
  //         onNextClick: () => {
  //           setApplication('driver_onboarding_step', 4);
  //           setApplication('driver_onboarding', true);
  //           driverObj.moveNext();
  //         }
  //       }
  //     }
  //   ]
  // });
  //
  // // Check if the user id done with onboarding and close the driver
  // useEffect(() => {
  //   if (driver_onboarding) {
  //     driverObj.destroy();
  //   }
  // }, [driver_onboarding]);
  //
  // useEffect(() => {
  //   if (isLoaded && first && !driver_onboarding) driverObj.drive();
  // }, [isLoaded, first]);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <>
      <Routes>
        <Route element={<PersistAuth />}>
          <Route element={<AuthenticationLayout />}>
            {/* Public Auth */}
            <Route path={routes.REGISTER} element={<AuthRegistration />} />
            <Route path={routes.CONFIRM_MAIL} element={<AuthConfirmMail />} />
            <Route path={routes.ACTIVATE} element={<AuthActivate />} />
            <Route path={routes.LOGIN} element={<AuthLogin />} />
            <Route
              path={routes.FORGOT_PASSWORD}
              element={<AuthForgetPassword />}
            />
            <Route
              path={routes.RESET_PASSWORD}
              element={<AuthPasswordReset />}
            />
            {/* Authenticated Auth */}
            <Route element={<RequireAuth />}>
              <Route path={routes.MFA_APP_SETUP} element={<AuthMFASetup />} />
              <Route path={routes.MFA_APP_VERIFY} element={<AuthOTPVerify />} />
              <Route path={routes.LOCK} element={<AuthLockScreen />} />
            </Route>
          </Route>

          <Route element={<MainLayout />}>
            <Route element={<RequireAuth />}>
              {/* Account routes */}
              <Route element={<CategoryLayout />}>
                <Route path={routes.ACCOUNT_PROFILE} element={<Profile />} />
                <Route
                  path={routes.ACCOUNT_PROFILE_EDIT}
                  element={<AccountProfileEdit />}
                />
              </Route>

              {/* Non-Dashboard routes */}
              <Route element={<CategoryLayout />}>
                <Route
                  element={
                    <RolesWrapper
                      allowedRoles={['super-admin', 'admin', 'user']}
                    />
                  }>
                  {/* Home */}

                  {/* Artifacts */}
                  <Route path={routes.ARTIFACTS} element={<ArtifactsPage />} />
                  <Route
                    path={routes.ARTIFACTS_CLOUD}
                    element={<ArtifactsCloud />}
                  />
                  <Route
                    path={routes.ARTIFACTS_CLOUD_AWS}
                    element={<ArtifactsCloudAws />}
                  />
                  <Route
                    path={routes.ARTIFACTS_ENDPOINT}
                    element={<ArtifactsEndpoint />}
                  />
                  <Route
                    path={routes.ARTIFACTS_ENDPOINT_SENTINELONE}
                    element={<ArtifactsEndpointSentinelOne />}
                  />
                  {/* Manage */}
                  <Route path={routes.ALERTS} element={<ManageAlerts />} />
                  <Route
                    path={routes.APPLIANCES}
                    element={<ManageAppliances />}
                  />
                  <Route
                    path={routes.BOOKMARKS}
                    element={<ManageBookmarks />}
                  />
                  <Route
                    path={routes.SAVED_SEARCHES}
                    element={<ManageSavedSearches />}
                  />
                  <Route path={routes.CASES} element={<ManageCases />} />
                  <Route
                    path={routes.DETECTIONS}
                    element={<ManageDetections />}
                  />
                  <Route
                    path={routes.INCIDENTS}
                    element={<ManageIncidents />}
                  />
                  <Route
                    path={routes.ORGANIZATIONS}
                    element={<ManageOrganizations />}
                  />
                  <Route path={routes.REPORTS} element={<ManageReports />} />
                  <Route path={routes.TEAMS} element={<ManageTeams />} />
                  {/* Resources */}
                  <Route path={routes.RESOURCES} element={<Resources />} />
                  <Route
                    path={routes.RESOURCES_NEWS_UPDATES}
                    element={<NewsUpdates />}
                  />
                  <Route
                    path={routes.RESOURCES_CHANGE_LOG}
                    element={<ChangeLog />}
                  />
                  <Route
                    path={routes.RESOURCES_REPORT_ISSUE}
                    element={<ReportIssue />}
                  />
                  {/* Misc */}
                  <Route
                    path={`${routes.ORGANIZATIONS}/:id`}
                    element={<OrgProfile />}
                  />
                  <Route
                    path={`${routes.USERS}/:id`}
                    element={<UserProfile />}
                  />
                  <Route
                    path={`${routes.ARTICLES}/:slug`}
                    element={<Articles />}
                  />
                </Route>
              </Route>
              {/*  same ExploreLayout named IntroductionLayout without sidebar and excluding explore,searchfilter context */}
              <Route element={<IntroductionLayout />}>
                {/* Overview */}
                <Route path={routes.OVERVIEW} element={<Overview />} />{' '}
                <Route
                  path={routes.HOME}
                  element={<Navigate to={routes.OVERVIEW} />}
                />
                {/* Overview */}x
              </Route>
              {/* same ExploreLayout named IntroductionLayout without sidebar */}
              {/* All dashboard routes under ExploreLayout */}
              <Route element={<ExploreLayout />}>
                <Route
                  element={
                    <RolesWrapper
                      allowedRoles={['super-admin', 'admin', 'user']}
                    />
                  }>
                  {/* Artifacts */}
                  {/* The Explore dashboard has a dynamic dashboard entry to support other parts
                  of the dynamic routing, search querying and search filtering. Because of this, we need to render our static Explore route first here, so it gets prioritized over the dynamic version. */}
                  <Route
                    path={routes.ARTIFACTS_EXPLORE}
                    element={<ArtifactsExplore />}
                  />

                  {/* Dynamic Dashboards */}
                  {/* Render routes for all dynamic dashboards. */}
                  {DynamicDashRouter()}
                </Route>
              </Route>

              {/* System layout */}
              <Route element={<SystemLayout />}>
                <Route
                  element={
                    <RolesWrapper allowedRoles={['super-admin', 'admin']} />
                  }>
                  {/* System */}
                  <Route path={routes.SYSTEM} element={<Sys />} />
                  <Route path={routes.SYSTEM_APP} element={<SysApp />} />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE}
                    element={<SysAppManage />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_ALERTS}
                    element={<SysAppManageAlerts />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_ARTICLES}
                    element={<SysAppManageArticles />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_ARTICLES_CATEGORIES}
                    element={<SysAppManageArticlesCategories />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_ARTICLES_TAGS}
                    element={<SysAppManageArticlesTags />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_DETECTIONS}
                    element={<SysAppManageDetections />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_JOBS}
                    element={<SysAppManageJobs />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_ORGS}
                    element={<SysAppManageOrganizations />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_BOOKMARKS}
                    element={<SysAppManageBookmarks />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_SAVED_SEARCHES}
                    element={<SysAppManageSavedSearches />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_PRODUCTS}
                    element={<SysAppManageProducts />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_PRODUCTS_APPLIANCES}
                    element={<SysAppManageProductsAppliances />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_PRODUCTS_INSTANCES}
                    element={<SysAppManageProductsInstances />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_RELEASES}
                    element={<SysAppManageReleases />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_REPORTS}
                    element={<SysAppManageReports />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_REPORTS_TEMPLATES}
                    element={<SysAppManageReportsTemplates />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_SUBS}
                    element={<SysAppManageSubscriptions />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_SUBS_LEVELS}
                    element={<SysAppManageSubscriptionsLevels />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_TEAMS}
                    element={<SysAppManageTeams />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_USERS}
                    element={<SysAppManageUsers />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_SECURITY}
                    element={<SysAppSecurity />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_SECURITY_PERMISSIONS}
                    element={<SysAppSecurityPermissions />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_SECURITY_RESOURCES}
                    element={<SysAppSecurityResources />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_MANAGE_ROLES}
                    element={<SysAppSecurityRoles />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_SETTINGS}
                    element={<SysAppSettings />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_SETTINGS_DATA}
                    element={<SysAppSettingsData />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_SETTINGS_DATA_FIELDS}
                    element={<SysAppSettingsDataFields />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_SETTINGS_DATA_INDICES}
                    element={<SysAppSettingsDataIndices />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_SETTINGS_DISPLAY}
                    element={<SysAppSettingsDisplay />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_SETTINGS_DISPLAY_DASHBOARDS}
                    element={<SysAppSettingsDisplayDashboards />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_SETTINGS_DISPLAY_MENUS}
                    element={<SysAppSettingsDisplayMenus />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_SETTINGS_DISPLAY_ROUTES}
                    element={<SysAppSettingsDisplayRoutes />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_SETTINGS_DISPLAY_VISUALIZATIONS}
                    element={<SysAppSettingsDisplayVisualizations />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_SETTINGS_GENERAL}
                    element={<SysAppSettingsGeneral />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_SETTINGS_PRIVACY}
                    element={<SysAppSettingsPrivacy />}
                  />
                  <Route
                    path={routes.SYSTEM_APP_SETTINGS_NOTIFICATION}
                    element={<SysAppSettingsNotification />}
                  />
                  <Route path={routes.SYSTEM_TOOLS} element={<SysTools />} />
                  <Route
                    path={routes.SYSTEM_TOOLS_REPORTING}
                    element={<Reporting />}
                  />
                  <Route
                    path={routes.SYSTEM_TOOLS_REPORTING_STATUSES}
                    element={<ReportStatuses />}
                  />
                  <Route
                    path={routes.SYSTEM_TOOLS_Alerting}
                    element={<Alerting />}
                  />
                  <Route
                    path={routes.SYSTEM_TOOLS_ALERTING_STATUSES}
                    element={<AlertStatuses />}
                  />
                  <Route
                    path={routes.SYSTEM_TOOLS_DB}
                    element={<DatabaseClusterPage />}
                  />
                  <Route
                    path={routes.SYSTEM_TOOLS_DB_AUDIT_LOGS}
                    element={<SysToolsDBAuditLogs />}
                  />
                  <Route
                    path={routes.SYSTEM_TOOLS_DB_COLLECTIONS}
                    element={<SysToolsDBCollections />}
                  />
                  <Route
                    path={routes.SYSTEM_TOOLS_DB_HEALTH}
                    element={<SysToolsDBHealth />}
                  />
                  <Route
                    path={routes.SYSTEM_TOOLS_DB_NODES}
                    element={<SysToolsDBNodes />}
                  />
                  <Route
                    path={routes.SYSTEM_TOOLS_DB_ROLES}
                    element={<SysToolsDBRoles />}
                  />
                  <Route
                    path={routes.SYSTEM_TOOLS_DB_USERS}
                    element={<SysToolsDBUsers />}
                  />
                  <Route
                    path={routes.SYSTEM_TOOLS_ES}
                    element={<SysToolsES />}
                  />
                  <Route
                    path={routes.SYSTEM_TOOLS_ES_AUDIT_LOGS}
                    element={<SysToolsESAuditLogs />}
                  />
                  <Route
                    path={routes.SYSTEM_TOOLS_ES_HEALTH}
                    element={<SysToolsESHealth />}
                  />
                  <Route
                    path={routes.SYSTEM_TOOLS_ES_INDICES}
                    element={<SysToolsESIndices />}
                  />
                  <Route
                    path={routes.SYSTEM_TOOLS_ES_NODES}
                    element={<SysToolsESNodes />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route element={<ErrorLayout />}>
          <Route path={routes.ERROR_401} element={<Error401 />} />
          <Route path={routes.ERROR_403} element={<Error403 />} />
          <Route path={routes.ERROR_404} element={<Error404 />} />
          <Route path={routes.ERROR_500} element={<Error500 />} />
        </Route>

        <Route path='*' element={<Navigate to={routes.ERROR_404} replace />} />
      </Routes>
    </>
  );
};

export default MainRouter;
