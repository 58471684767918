import { makeRequest } from 'api/connection/requestAction';

class AppProducts {
  constructor(route) {
    this.route = route;
  }

  async createProduct(productData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.createProduct.method,
        this.route.createProduct.path,
        productData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getProducts(axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getProducts.method,
        this.route.getProducts.path
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getProduct(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getProduct.method,
        [this.route.getProduct.path, id].join('/')
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async updateProduct(id, productData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateProduct.method,
        `${this.route.updateProduct.path}/${id}`,
        productData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async deleteProduct(id, deleting, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.deleteProduct.method,
        `${this.route.deleteProduct.path}/${id}`,
        {
          deleting
        }
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default AppProducts;
