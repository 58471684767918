import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useOrganizations from 'hooks/user-contexts/useUserOrganizations';
import React, { useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

/**
 * @description form for user invitation
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const RemoveUserForm = () => {
  const {
    organizations: {
      removeUserModal: { user, organization }
    },
    setOrganizations
  } = useOrganizations();
  const { axiosPrivate } = useAxiosPrivate();
  let [loading, setLoading] = useState(false);
  const { handleSubmit } = useForm();

  /**
   * @description form for RemoveUserForm
   * @param {Object} data
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const onSubmit = async () => {
    try {
      setLoading(true);
      let data = {
        organizationId: organization?.id,
        userId: user?.id
      };
      let res = await LeargasAPI.AccountOrganizations.removeUserFromOrg(
        data,
        axiosPrivate
      );
      if (res instanceof Error) {
        throw res;
      }
      setLoading(false);
      setOrganizations(prevState => ({
        ...prevState,
        fetch: true,
        removeUserModal: { open: false }
      }));
      const { message } = res.data;
      toast.success(message);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setLoading(false);

      setOrganizations(prevState => ({
        ...prevState,
        fetch: false,
        removeUserModal: { open: false }
      }));
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className='g-3 mb-3'>
        <p>
          Are you sure you want to remove {user?.profile?.fullName} from{' '}
          {organization?.name} ?
        </p>
      </Row>
      <Row className='g-3 mt-3'></Row>
      <Flex justifyContent='end'>
        <Button variant='danger' type='submit' className='d-flex'>
          <div className='position-relative'>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <ButtonSpinner spinning={loading} />
              <span>{loading ? 'Removing' : 'Remove'}</span>
            </Flex>
          </div>
        </Button>
      </Flex>
    </Form>
  );
};

export default RemoveUserForm;
