/**
 * Array of chart colors matching all the colors in the color theme.
 *
 * @type {string[]}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.5
 * @version 0.1.0-beta.5
 */
export const themeChartColors = [
  '#ffb770', // yellow
  '#91cc75', // green
  '#36a2eb', // blue
  '#ff9f40', // orange
  '#ff6384', // pink
  '#addc99', // green
  '#ffd6e0', // yellow
  '#68bdf3', // blue
  '#ff7860', // orange
  '#b9fbc0', // green
  '#ffb3c1', // yellow
  '#76e4f7', // blue
  '#c775ff', // pink
  '#ff8da0', // orange
  '#d2b48c', // orange
  '#d4fcd7', // green
  '#ffa791', // pink
  '#e3c8a9', // yellow
  '#a1ecf9', // blue
  '#a34de0' // pink
];
