import classNames from 'classnames';
import Flex from 'components/common/Flex';
import useApplication from 'hooks/useApplication';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import NavigatorLeftPanelExtension from './left-panel/NavigatorLeftPanelExtension';
import NavigatorRightPanelExtension from './right-panel/NavigatorRightPanelExtension';
import NewTimeRange from './TimeRange';
// import moment from 'moment';

/**
 * Represents the main panel of the navigator component
 * @returns {JSX.Element} The JSX element representing the main panel
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const NavigatorMainPanel = forwardRef((_props, ref) => {
  const {
    application: { showNavigator }
  } = useApplication();

  const [isExpanded, setIsExpanded] = useState(false);
  const panelRef = useRef(null);

  /**
   * Handles the mouse enter event
   */
  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  /**
   * Handles the mouse leave event
   */
  const handleMouseLeave = () => {
    setIsExpanded(false);
  };

  useEffect(() => {
    const handleBlur = event => {
      if (!showNavigator && !panelRef.current?.contains(event.relatedTarget)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('blur', handleBlur, true);

    return () => {
      document.removeEventListener('blur', handleBlur, true);
    };
  }, [showNavigator]);

  return (
    <Flex
      className='navigator-container navigator-panels-for-big-screen'
      direction='row'
      justifyContent='center'>
      <Flex
        className='navigator-wrapper'
        direction='row'
        alignItems='top'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Flex
          className={classNames('navigator-panels', {
            'navigator-panels-collapse':
              !showNavigator && (showNavigator || !isExpanded)
          })}
          ref={ref}>
          <div className='w-100 position-relative'>
            <NavigatorLeftPanelExtension />
            <NavigatorRightPanelExtension />
            <Flex
              className={classNames('navigator-main-panel p-0 py-0 bg-card')}
              alignItems='center'
              style={{
                width: '395px',
                height: 'fit-content',
                position: 'relative',
                zIndex: '222'
              }}>
              <div className='w-100 position-relative'>
                <Flex
                  className='fs-0 text-100 fw-semi-bold text-center w-100'
                  justifyContent='center'>
                  <Flex
                    className='w-100 px-3'
                    justifyContent='between'
                    alignItems='center'>
                    {/* <CalendarButton /> */}

                    <NewTimeRange />
                  </Flex>
                </Flex>
              </div>
            </Flex>
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
});

export default NavigatorMainPanel;
