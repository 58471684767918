import React from 'react';
import PropTypes from 'prop-types';
import ArticlesProvider from '../../../../../../../../../context/admin-contexts/ArticlesProvider';
import ArticleViewLayout from './ArticleViewLayout';
import ViewChangeButton from '../ArticlesView/components/ViewChangeButton';

/**
 *
 * @param { props }
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ArticlesViewWrapper = props => {
  return (
    <>
      <ArticlesProvider>
        {props.children}
        <ViewChangeButton />
        <ArticleViewLayout />
      </ArticlesProvider>
    </>
  );
};

ArticlesViewWrapper.propTypes = { children: PropTypes.node };

export default ArticlesViewWrapper;
