import PropTypes from 'prop-types';
import React, { forwardRef, useRef } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

/**
 * AdvancedPopover component.
 *
 * @component
 * @param {Object} props - The component props
 * @param {ReactNode} props.children - The content of the popover trigger
 * @param {string} props.containerId - The ID of the container for the popover
 * @param {string} [props.customId] - The custom ID for the popover
 * @param {string} [props.placement='right'] - The placement of the popover
 * @param {string} props.popoverText - The text content of the popover
 * @param {Object} [props.popperConfig={}] - The config options for Popper.js
 * @param {boolean} [props.showArrow=true] - Whether to show the arrow
 * @param {Object} [props.style={}] - The inline style for the popover body
 * @param {Array<string>} [props.trigger=['hover', 'focus']] - The trigger
 * @returns {JSX.Element} The rendered AdvancedPopover component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const AdvancedPopover = forwardRef(
  (
    {
      children,
      containerId,
      customId,
      offset = [0, 0],
      placement = 'right',
      popoverText,
      popperConfig = {},
      showArrow = true,
      style = {},
      trigger = ['hover', 'focus'],
      ...rest
    },
    ref
  ) => {
    const internalRef = useRef();
    const resolvedRef = ref || internalRef;
    const popoverId = customId || `leargas-popover-${uuidv4()}`;
    const defaultPopperConfig = { strategy: 'fixed', ...popperConfig };

    // Custom class to hide the arrow
    const popoverClassNames = showArrow ? '' : 'hide-arrow';

    return (
      <OverlayTrigger
        delay={{ show: 1500 }}
        overlay={
          <Popover id={popoverId} className={popoverClassNames}>
            <Popover.Body
              className={'px-3 py-2 text-center text-900 shadow-lg'}
              style={{ maxWidth: '350px', ...style }}>
              {popoverText}
            </Popover.Body>
          </Popover>
        }
        placement={placement}
        offset={offset}
        popperConfig={defaultPopperConfig}
        container={document.getElementById(containerId)}
        rootClose={true}
        trigger={trigger}
        {...rest}>
        {({ ref: triggerRef, ...triggerHandler }) => (
          <div
            {...triggerHandler}
            ref={node => {
              triggerRef(node);
              resolvedRef.current = node;
            }}
            className='position-relative d-flex'>
            {children}
          </div>
        )}
      </OverlayTrigger>
    );
  }
);

AdvancedPopover.propTypes = {
  children: PropTypes.node.isRequired,
  containerId: PropTypes.string,
  customId: PropTypes.string,
  offset: PropTypes.arrayOf(PropTypes.number),
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  popoverText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  popperConfig: PropTypes.object,
  showArrow: PropTypes.bool,
  style: PropTypes.object,
  trigger: PropTypes.arrayOf(PropTypes.oneOf(['hover', 'focus', 'click']))
};

export default AdvancedPopover;
