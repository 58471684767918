export const releasesSettings = {
  releases: [],
  requiredRoles: {
    releases: {
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin'],
      delete: ['super-admin', 'admin']
    },
    releaseNotes: {
      read: ['super-admin', 'admin', 'user'],
      write: ['super-admin', 'admin'],
      delete: ['super-admin', 'admin']
    }
  },
  requiredScopes: {
    releases: {
      read: ['releases:read'],
      write: ['releases:write'],
      delete: ['releases:delete']
    },
    releaseNotes: {
      read: ['releaseNotes:read'],
      write: ['releaseNotes:write'],
      delete: ['releaseNotes:delete']
    }
  },
  addReleaseModal: { open: false },
  editReleaseModal: { open: false, data: {} },
  deleteReleaseModal: { open: false, data: {} },
  addReleaseNoteModal: { open: false, data: {} },
  editReleaseNoteModal: { open: false, data: {} },
  deleteReleaseNoteModal: { open: false, data: {} },
  loading: false,
  fetch: false
};
