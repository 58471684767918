import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useReleases from 'hooks/admin-contexts/useReleases';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

/**
 * Renders the ModalReleaseDelete component
 *
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ModalReleaseDelete = () => {
  const { deleteReleaseModal, setReleases } = useReleases();
  const { axiosPrivate } = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  /**
   * Handle deleting a release
   * @param {string} id The id of the release to delete
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */

  const handleDelete = async id => {
    setLoading(true);
    try {
      const res = await LeargasAPI.Releases.deleteAppRelease(id, axiosPrivate);
      if (res instanceof Error) throw res;
      toast.success(res?.message);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setReleases(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setReleases(prevState => ({
        ...prevState,
        deleteReleaseModal: { open: false, data: {} },
        fetch: false
      }));
      setLoading(false);
    }
  };

  return (
    <Modal
      centered
      size='md'
      animation={false}
      backdrop='static'
      keyboard={false}
      show={deleteReleaseModal?.open}
      onHide={() =>
        setReleases(prevState => ({
          ...prevState,
          deleteReleaseModal: { open: false }
        }))
      }
      aria-labelledby='modalReleaseDelete'>
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Modal.Body className=' p-2'>
          Are you sure you want to delete{' '}
          <strong>{deleteReleaseModal.data?.tag}</strong>?
          <Flex justifyContent='center' className='mt-3'>
            <Button
              variant='outline-secondary'
              size='sm'
              onClick={() =>
                setReleases(prevState => ({
                  ...prevState,
                  deleteReleaseModal: { open: false }
                }))
              }>
              Cancel
            </Button>
            <Button
              size='sm'
              variant={loading ? 'outline-secondary' : 'danger'}
              disabled={loading || !deleteReleaseModal.data?.id}
              className='ms-2'
              onClick={() => handleDelete(deleteReleaseModal.data?.id)}>
              <Flex justifyContent={'center'} alignItems={'center'}>
                <ButtonSpinner spinning={loading} />
                <span>{loading ? 'Confirming' : 'Confirm'}</span>
              </Flex>
            </Button>
          </Flex>
        </Modal.Body>
      </Flex>
    </Modal>
  );
};

export default ModalReleaseDelete;
