import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import CreateDashboardForm from '../forms/CreateDashboardForm';

const CreateDashboardModal = ({
  showCreateDashboardModal,
  setShowCreateDashboardModal
}) => {
  const {
    application: { isDark }
  } = useApplication();

  return (
    <>
      <Modal
        aria-labelledby='newDashboardModal'
        size='lg'
        backdrop='static'
        keyboard={false}
        show={showCreateDashboardModal?.open}
        onHide={() =>
          setShowCreateDashboardModal({ open: false, fetch: false })
        }
        centered>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Add a dashboard
            <AdvancedPopover
              containerId='resetConfirmModal'
              placement='top'
              popoverText='Add a dashboard'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
        </Modal.Header>

        <Modal.Body className='p-2'>
          <CreateDashboardForm
            hasLabel
            setShowCreateDashboardModal={setShowCreateDashboardModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

CreateDashboardModal.propTypes = {
  showCreateDashboardModal: PropTypes.object,
  setShowCreateDashboardModal: PropTypes.func
};

export default CreateDashboardModal;
