import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvancedPopover from 'components/common/Popover';
import useAdminReports from 'hooks/admin-contexts/useReports';
import useApplication from 'hooks/useApplication';
import { Modal } from 'react-bootstrap';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Download from '../FileActions/Download';
/**
 * Renders the ModalReportAdd component
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ModalReportAdd = () => {
  const {
    application: { isDark }
  } = useApplication();
  const {
    reports: { downloadReportModal },
    setReports
  } = useAdminReports();

  return (
    <Modal
      centered
      size={downloadReportModal?.fullScreen ? 'xl' : 'lg'}
      animation={false}
      backdrop='static'
      keyboard={false}
      show={downloadReportModal?.open}
      onHide={() =>
        setReports(prevState => ({
          ...prevState,
          downloadReportModal: { open: false }
        }))
      }
      aria-labelledby='modalReportAdd'
      contentClassName={
        downloadReportModal?.fullScreen &&
        'border-0 border-top-left-radius-0 border-top-right-radius-0 h-100'
      }
      dialogClassName={
        downloadReportModal?.fullScreen &&
        'position-absolute all-0 fullscreen-modal-dialog scrollbar overflow-x-hidden'
      }>
      <Flex
        direction='column'
        justifyContent='between'
        className='card border border-card shadow-none'>
        <Modal.Header
          className='p-2'
          closeButton
          closeVariant={isDark ? 'white' : undefined}>
          <h5 className='d-flex  fs-0 fw-normal position-relative'>
            Downloading
            <AdvancedPopover
              containerId='resetConfirmModal'
              placement='top'
              popoverText='Download'
              showArrow={true}>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                className='ms-1 fs--1 cursor-pointer text-400'
              />
            </AdvancedPopover>
          </h5>
          {downloadReportModal?.open && (
            <Download
              setReports={setReports}
              id={downloadReportModal?.data?.id}
            />
          )}
        </Modal.Header>
      </Flex>

      {/*         <Modal.Body className='p-2'></Modal.Body> */}
    </Modal>
  );
};

export default ModalReportAdd;
