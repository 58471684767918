import { routes } from 'config/routeSettings';
import useAuthentication from 'hooks/useAuthentication';
import useIdleDetection from 'hooks/useIdleDetection';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

/**
 * Higher-order component that checks if the user is authenticated.
 * If the user is authenticated, it renders the child components.
 * Otherwise, it redirects the user to the login screen.
 *
 * @returns {JSX.Element} The rendered child components or a redirect to the login screen.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const RequireAuth = () => {
  const { authentication } = useAuthentication();
  const location = useLocation();

  // Hooks for idle detection and handling user session based on activity.
  useIdleDetection();

  // Redirect user to login screen if not authenticated.
  return authentication?.id ? (
    <Outlet />
  ) : (
    <Navigate to={routes.LOGIN} state={{ from: location }} replace />
  );
};

export default RequireAuth;
