import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import useAxiosPrivate from 'hooks/useAxiosPrivate';

const RestoreApplianceForm = ({
  showRestoreApplianceModal,
  setShowRestoreApplianceModal
}) => {
  const [loading, setLoading] = useState(false);

  const { axiosPrivate } = useAxiosPrivate();

  return (
    <Form>
      <Row className='g-3 mb-3'></Row>

      <Flex justifyContent='end'>
        <Button
          variant='secondary'
          size='sm'
          className='me-2'
          onClick={() =>
            setShowRestoreApplianceModal({
              open: false,
              appliance: {},
              fetch: false
            })
          }>
          Cancel
        </Button>

        <Button
          variant='success'
          size='sm'
          onClick={async () => {
            setLoading(true);
            try {
              const res = await LeargasAPI.Appliances.deleteAppliance(
                showRestoreApplianceModal?.appliance?._id,
                false,
                axiosPrivate
              );
              if (res instanceof Error) throw res;
              let message = res?.data?.message;
              setLoading(false);
              setShowRestoreApplianceModal({
                open: false,
                appliance: {},
                fetch: true
              });
              toast.success(message);
            } catch (error) {
              if (error.message.includes('Network Error')) {
                console.error('Network Error occurred.');
              }
              setLoading(false);
              setShowRestoreApplianceModal({
                open: false,
                appliance: {},
                fetch: false
              });
              if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
              } else {
                toast.error('Something went wrong!');
              }
            }
          }}>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <ButtonSpinner spinning={loading} />
            <span>{loading ? 'Restoring' : 'Restore'}</span>
          </Flex>
        </Button>
      </Flex>
    </Form>
  );
};

RestoreApplianceForm.propTypes = {
  showRestoreApplianceModal: PropTypes.object,
  setShowRestoreApplianceModal: PropTypes.func
};

export default RestoreApplianceForm;
