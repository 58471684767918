import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

const azure = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'Azure Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-azure*']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Events'
          // helpInfo:
          //   'Total number of network events processed by our behavioral detection engine'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['cardinality'],
        overriddenIndex: ['leargas-azure*'],
        aggregate: {
          aggs: {
            1: {
              cardinality: {
                field: 'message.properties.userPrincipalName.keyword'
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'MetricPlain',
        props: {
          className: '',
          title: 'Unique Users'
          // helpInfo:
          //   'Alerts for events that match behavioral patterns known to be associated with malicious activity'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['cardinality'],
        overriddenIndex: ['leargas-azure*'],
        aggregate: {
          aggs: {
            1: {
              cardinality: {
                field: 'message.properties.userPrincipalName.keyword'
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'message.properties.authenticationRequirement':
                'singleFactorAuthentication'
            }
          }
        ]
      },
      children: {
        component: 'MetricPlain',
        props: {
          className: '',
          title: 'Single Factor Auth'
          // helpInfo:
          //   'Connections to a local device or server from a remote device using the Remote Desktop Protocol (RDP)'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['cardinality'],
        overriddenIndex: ['leargas-azure*'],
        aggregate: {
          aggs: {
            1: {
              cardinality: {
                field: 'message.properties.deviceDetail.deviceId.keyword'
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'MetricPlain',
        props: {
          className: '',
          title: 'Unique Devices'
          // helpInfo:
          //   'Connections to a remote device or server from a local device using the Remote Desktop Protocol (RDP)'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 6, md: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-azure*'],
        filter: [
          {
            exists: {
              field: 'message.properties.status.failureReason.keyword'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Failed Events'
          // helpInfo:
          //   'Access requests to files and resources over a network from a remote location'
        }
      }
    },

    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['histogram'],
        overriddenIndex: ['leargas-azure*']
        // aggregate: {
        //   aggs: {
        //     2: {
        //       date_histogram: {
        //         field: '@timestamp',
        //         calendar_interval: '1h',
        //         time_zone: 'Asia/Karachi',
        //         min_doc_count: 1
        //       }
        //     }
        //   },
        //   size: 0
        // }
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Event Timeline',
          helpInfo: ' '
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['map'],
        overriddenIndex: ['leargas-azure*'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'message.properties.location.countryOrRegion.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        },
        must_not: [
          {
            match_phrase: {
              'message.properties.location.countryOrRegion.keyword': ''
            }
          }
        ]
      },
      children: {
        component: 'Map',
        props: {
          className: '',
          title: 'Event Source Countries',
          field: 'message.properties.location.countryOrRegion'
          // helpInfo:
          //   'Heatmap of event source countries for all behavioral network events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { lg: 6, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-azure*'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'message.properties.appDisplayName.keyword',
                order: {
                  _count: 'desc'
                },
                size: 100
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Application',
          field: 'message.properties.appDisplayName',
          helpInfo:
            'Alerts for events that match behavioral patterns known to be associated with malicious activity'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { lg: 6, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-azure*'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'message.operationName.keyword',
                order: {
                  _count: 'desc'
                },
                size: 100
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Operation',
          field: 'message.operationName',
          helpInfo: 'User agents observed in network traffic'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { lg: 6, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['aggregate'],
        overriddenIndex: ['leargas-azure*'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'message.category.keyword',
                order: {
                  _count: 'desc'
                },
                size: 100
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Category',
          field: 'message.category',
          helpInfo: 'User agents observed in network traffic'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { lg: 6, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-azure*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'message.identity.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'message.properties.userPrincipalName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Identity'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { lg: 7, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-azure*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'message.properties.deviceDetail.displayName.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Devices'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { lg: 5, xxl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-azure*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'message.callerIpAddress.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Caller Ip'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['table'],
        overriddenIndex: ['leargas-azure*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'message.properties.status.failureReason.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Failure Reasons'
          }
        }
      }
    }
  ];
};

export default azure;
