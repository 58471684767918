import azureIcon from 'assets/img/artifactImages/azure.png';
import boxIcon from 'assets/img/artifactImages/box.png';
import cloudTrailIcon from 'assets/img/artifactImages/cloudtrail.png';
import duoIcon from 'assets/img/artifactImages/duo.png';
import googleIcon from 'assets/img/artifactImages/google.png';
import merakiIcon from 'assets/img/artifactImages/meraki.png';
import o365icon from 'assets/img/artifactImages/o365.png';
import oktaIcon from 'assets/img/artifactImages/okta.png';
import sentinelOneIcon from 'assets/img/artifactImages/sentinelone.png';
import routes from 'config/routeSettings';
import { presetRanges } from 'context/ESGlobalFiltersProvider';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import moment from 'moment';
import React from 'react';
import { Outlet } from 'react-router-dom';
dayjs.extend(duration);

/**
 * Truncate the string in the middle and add ellipsis if it exceeds the specified length.
 *
 * @param {string} str - The string to truncate
 * @param {number} maxLength - The maximum length before truncation.
 * @returns {string} - The truncated string with an ellipsis in the middle.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const truncateMiddle = (str, maxLength = 28) => {
  // Edge case: if the string is less than or equal to the maxLength, return the original string.
  if (String(str).length <= maxLength) {
    return String(str);
  }

  // Calculate the part lengths based on the maxLength
  const partLength = Math.floor((maxLength - 3) / 2); // Subtracting 3 for the ellipsis

  // Extract the starting part and the ending part
  const start = String(str).substr(0, partLength);
  const end = String(str).substr(
    String(str).length - partLength,
    String(str).length
  );

  // Combine the parts with an ellipsis in the middle
  return `${start}...${end}`;
};

/**
 * Get the user's abbreviated name.
 *
 * @param {string} first The user's first name
 * @param {string} last The user's last name
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const ArtifactPrettyName = {
  azure: {
    icon: azureIcon,
    linkTo: routes.ARTIFACTS_CLOUD_AZURE,
    prettyName: 'Azure'
  },
  box: {
    icon: boxIcon,
    linkTo: routes.ARTIFACTS_CLOUD_BOX,
    prettyName: 'Box'
  },
  cloudtrail: {
    icon: cloudTrailIcon,
    linkTo: routes.ARTIFACTS_CLOUD_AWS_CLOUDTRAIL,
    prettyName: 'CloudTrail'
  },
  duo: {
    icon: duoIcon,
    linkTo: routes.ARTIFACTS_CLOUD_DUO,
    prettyName: 'Duo'
  },
  google: {
    icon: googleIcon,
    linkTo: routes.ARTIFACTS_CLOUD_GOOGLE,
    prettyName: 'Google'
  },
  meraki: {
    icon: merakiIcon,
    linkTo: routes.ARTIFACTS_CLOUD_MERAKI,
    prettyName: 'Meraki'
  },
  o365: {
    icon: o365icon,
    linkTo: routes.ARTIFACTS_CLOUD_O365,
    prettyName: 'Office365'
  },
  okta: {
    icon: oktaIcon,
    linkTo: routes.ARTIFACTS_CLOUD_OKTA,
    prettyName: 'Okta'
  },
  sentinelone: {
    icon: sentinelOneIcon,
    linkTo: routes.ARTIFACTS_ENDPOINT_SENTINELONE_THREATS,
    prettyName: 'SentinelOne'
  },
  zeek: {
    icon: '',
    linkTo: routes.ARTIFACTS_NETWORK_BEHAVIORS,
    prettyName: 'Zeek'
  },
  'z-alert': {
    icon: '',
    linkTo: routes.ARTIFACTS_NETWORK_BEHAVIORS,
    prettyName: 'Zeek Alert'
  },
  suricata: {
    icon: '',
    linkTo: routes.ARTIFACTS_NETWORK_SIGNATURES,
    prettyName: 'Suricata'
  },
  's-alert': {
    icon: '',
    linkTo: routes.ARTIFACTS_NETWORK_SIGNATURES,
    prettyName: 'Suricata Alert'
  },
  syslog: {
    icon: '',
    linkTo: routes.ARTIFACTS_ENDPOINT_SYSLOG,
    prettyName: 'Syslog'
  },
  winlogbeat: {
    icon: '',
    linkTo: routes.ARTIFACTS_ENDPOINT_WINDOWS,
    prettyName: 'Windows'
  }
};

export const setTimeFromPreset2 = (preset, dateGte, dateLte) => {
  if (presetRanges[preset]?.gte) {
    let gteSubtract = presetRanges[preset].gte?.subtract || false;
    let gteSet = presetRanges[preset].gte?.set || false;
    if (gteSubtract) dateGte.subtract(gteSubtract);
    if (gteSet) dateGte.set(gteSet);
  }

  if (presetRanges[preset]?.lte) {
    let lteSubtract = presetRanges[preset].lte?.subtract || false;
    let lteSet = presetRanges[preset].lte?.set || false;
    if (lteSubtract) dateLte.subtract(lteSubtract);
    if (lteSet) dateLte.set(lteSet);
  }
  return { dateGte, dateLte };
};
export const groupDisabledSortedAlphanumeric1 = data => {
  const dataClone = JSON.parse(JSON.stringify(data));
  const newData = dataClone.filter(
    col =>
      col.label !== '@timestamp' &&
      col.label !== 'leargas_actions' &&
      col.label !== '_source' &&
      col.label !== '@version'
  );
  const disabledGroup = newData.filter(item => item.isDisabled);
  const enabledGroup = newData.filter(item => !item.isDisabled);

  // Sort each group by the name property
  disabledGroup.sort(function (a, b) {
    alphabetize(a.label, b.label);
  });
  enabledGroup.sort(function (a, b) {
    alphabetize(a.label, b.label);
  });

  // Merge the sorted groups with enabled group first
  const mergedData = [...enabledGroup, ...disabledGroup];

  // Convert the merged data into the desired structure
  const result = mergedData.map(col => ({
    value: col.label,
    label: col.label,
    isDisabled: col.isDisabled
  }));
  return result;
};
export const groupDisabledSortedAlphanumeric = data => {
  const dataClone = JSON.parse(JSON.stringify(data));
  const newData = dataClone.filter(
    col =>
      col.label !== '@timestamp' &&
      col.label !== 'leargas_actions' &&
      col.label !== '_source' &&
      col.label !== '@version'
  );
  const disabledGroup = newData.filter(item => item.isDisabled);
  const enabledGroup = newData.filter(item => !item.isDisabled);

  // Sort each group by the name property
  disabledGroup.sort(function (a, b) {
    alphabetize(a.label, b.label);
  });
  enabledGroup.sort(function (a, b) {
    alphabetize(a.label, b.label);
  });

  // Merge the sorted groups with enabled group first
  const mergedData = [...enabledGroup, ...disabledGroup];

  // Convert the merged data into the desired structure
  const result = mergedData.map(col => ({
    value: col.label,
    label: col.label,
    isDisabled: col.isDisabled
  }));

  return result;
};

export const SplitDataCuratedWithTimestamp = x => {
  try {
    const tempData = {};
    const uniqueNames = [];
    const timestamps = [];

    x.forEach(item => {
      timestamps.push(item.key_as_string); // Track the timestamps
      const buckets = item[3].buckets;
      buckets.forEach(bucket => {
        const key = bucket.key;
        const doc_count = bucket.doc_count;

        if (!tempData[key]) {
          tempData[key] = new Array(60).fill(0); // Initialize with 60 zeros
          uniqueNames.push(key); // Track unique names
        }

        tempData[key].push(doc_count);
      });
    });

    const result = uniqueNames.map(name => ({
      name: name,
      data: tempData[name].slice(-60) // Ensure only the last 60 values are taken
    }));

    // Sort the result arrays by total doc_count in descending order
    result.sort(
      (a, b) =>
        b.data?.reduce((sum, count) => sum + count, 0) -
        a.data?.reduce((sum, count) => sum + count, 0)
    );

    const top4 = result.slice(0, 9);
    const other = result.slice(9);

    // Calculate the 'other' data by summing up the data arrays
    const otherData = new Array(60).fill(0);
    other.forEach(item => {
      item.data?.forEach((count, index) => {
        otherData[index] += count;
      });
    });

    // Add 'other' as the last entry
    top4.push({
      name: 'Other',
      data: otherData
    });

    // Map the top4 data back to the original timestamps
    const finalResult = top4.map((item, index) => ({
      name: item.name,
      data: item.data,
      key_as_string: timestamps[index]
    }));

    // Sort the final result by the sum of data arrays in descending order
    finalResult.sort(
      (a, b) =>
        b.data?.reduce((sum, count) => sum + count, 0) -
        a.data?.reduce((sum, count) => sum + count, 0)
    );

    return finalResult;
  } catch (error) {
    console.log(error);
    return [];
  }
};

// function deepEqual(obj1, obj2) {}

export function areObjectsSame(obj1, obj2) {
  // Check if both objects are the same reference
  if (obj1 === obj2) return { areEqual: true, differences: [] };

  // Check if both objects are null or undefined
  if (obj1 == null && obj2 == null) return { areEqual: true, differences: [] };
  if (obj1 == null || obj2 == null)
    return { areEqual: false, differences: ['null or undefined'] };

  // Check if both objects have the same keys
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    const differingKeys = keys1
      .concat(keys2)
      .filter((key, index, array) => array.indexOf(key) === index);
    return { areEqual: false, differences: differingKeys };
  }

  // Check the values of each key recursively
  const differences = [];
  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (typeof val1 === 'object' && typeof val2 === 'object') {
      const nestedComparison = areObjectsSame(val1, val2);
      if (!nestedComparison.areEqual) {
        differences.push({ key, differences: nestedComparison.differences });
      }
    } else if (val1 !== val2) {
      differences.push({
        key,
        differences: [`Values differ: ${val1} !== ${val2}`]
      });
    }
  }

  if (differences.length === 0) {
    return { areEqual: true, differences: [] }; // Objects are deep equal
  } else {
    return { areEqual: false, differences };
  }
}

export const convertUtcToUserTime = utcDateRanges => {
  const userTimeDateRanges = utcDateRanges.map(utcRange => {
    const [startUTC, endUTC] = utcRange.split(' - ');

    // Convert UTC to user's time zone
    const startUserTime = moment(moment.utc(startUTC).toDate())
      .local()
      .format('YYYY-MM-DDTHH:mm:ss');
    const endUserTime = moment(moment.utc(endUTC).toDate())
      .local()
      .format('YYYY-MM-DDTHH:mm:ss');

    return `${startUserTime} - ${endUserTime}`;
  });

  return userTimeDateRanges;
};

export const experimentSplit = x => {
  const tempData = {};
  const uniqueNames = [];

  x.forEach(item => {
    const buckets = item[3].buckets;
    buckets.forEach(bucket => {
      const key = bucket.key;
      const doc_count = bucket.doc_count;

      if (!tempData[key]) {
        tempData[key] = [];
        uniqueNames.push(key);
      }

      tempData[key].push(doc_count);
    });
  });

  const result = uniqueNames.map(name => ({
    name: name,
    data: tempData[name]
  }));

  result.sort(
    (a, b) =>
      b.data?.reduce((sum, count) => sum + count, 0) -
      a.data?.reduce((sum, count) => sum + count, 0)
  );

  const top4 = result.slice(0, 4);
  const other = result.slice(4);

  const otherData = new Array(top4[0].data?.length).fill(0);
  other.forEach(item => {
    item.data?.forEach((count, index) => {
      otherData[index] += count;
    });
  });

  top4.push({
    name: 'Other',
    data: otherData
  });

  return { uniqueNames, top4 };
};
// export const convertToSplitHistogramData = x => {
//   const tempData = {};
//   const uniqueNames = [];

//   x.forEach(item => {
//     const buckets = item[3].buckets;
//     buckets.forEach(bucket => {
//       const key = bucket.key;
//       const doc_count = bucket.doc_count;

//       if (!tempData[key]) {
//         tempData[key] = [];
//         uniqueNames.push(key); // Track unique names
//       }

//       tempData[key].push(doc_count);
//     });
//   });

//   const result = uniqueNames.map(name => ({
//     name: name,
//     data: tempData[name]
//   }));
//   return { result, uniqueNames };
// };
export const getAbbreviatedName = (first, last) => {
  // Extract the first character of the last name followed by a period
  const lastNameInitial = last?.charAt(0) || '';

  // Combine the first name and the abbreviated last name
  return `${first} ${lastNameInitial}.`;
};

export const setTimeFromPreset = (preset, presetRanges) => {
  if (preset !== 'custom_range') {
    const date = new Date();

    let dateGte = new moment(date);
    if (presetRanges[preset]?.gte) {
      var gteSubtract = presetRanges[preset].gte?.subtract || false;
      var gteSet = presetRanges[preset].gte?.set || false;
      if (gteSubtract) dateGte.subtract(gteSubtract);
      if (gteSet) dateGte.set(gteSet);
    }

    let dateLte = new moment(date);
    if (presetRanges[preset]?.lte) {
      var lteSubtract = presetRanges[preset].lte?.subtract || false;
      var lteSet = presetRanges[preset].lte?.set || false;
      if (lteSubtract) dateLte.subtract(lteSubtract);
      if (lteSet) dateLte.set(lteSet);
    }
    return [dateGte.toDate(), dateLte.toDate()];

    // }
  }
};
export const areArraysEqualById = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  const array1Ids = array1.map(item => item.id);
  const array2Ids = array2.map(item => item.id);

  array1Ids.sort();
  array2Ids.sort();

  return JSON.stringify(array1Ids) === JSON.stringify(array2Ids);
};
export const deleteValuesFromArrayByIdCompare = (array1, array2) => {
  const array2Ids = array2.map(item => item.id);

  return array1.filter(item => array2Ids.includes(item.id));
};

export function getTopCountriesByPercentage(inputArray, numberOfCountries) {
  // Calculate the total value of all countries
  const totalValue = inputArray.reduce(
    (sum, country) => sum + country.value,
    0
  );

  // Sort the inputArray by percentage value in descending order
  const sortedArray = inputArray
    .slice()
    .sort((a, b) => b.value / totalValue - a.value / totalValue);

  // Select the top countries based on the defined number
  const topCountries = sortedArray.slice(0, numberOfCountries);

  // Transform the topCountries array to match the desired structure
  const transformedTopCountries = [
    topCountries.map(country => country.name),
    topCountries.map(country => (country.value / totalValue) * 100)
  ];

  return transformedTopCountries;
}

export const getOrgScopes = newScopes => {
  let orgScopes = [];
  newScopes.map(item => {
    orgScopes.push(item);
  });
  return orgScopes;
};

// export const checkProperty = (array, propertyName) => {};
export const makeFieldsArray = items => {
  let arr = [];
  items.map(obj => {
    arr.push({
      label: obj.name,
      name: obj.name,
      type: obj.type
    });
  });

  return arr;
};

export const makeNewFieldsObj = array => {
  const convertedObject = {};

  for (const item of array) {
    const key = item.label;
    convertedObject[key] = item;
  }
  return convertedObject;
};
export const convertObjectToArrayForMultiSelectFields = obj => {
  let arr = [];
  for (let key in obj) {
    arr.push({
      label: obj[key].accessor,
      name: obj[key].name,
      type: obj[key].type
    });
  }
  return arr;
};
export const calculatePercentageDifference = (currentValue, previousValue) => {
  let difference = currentValue - previousValue;
  let absoluteDifference = Math.abs(difference);

  if (previousValue === 0) {
    return 'N/A';
  }
  let percentageDifference2 = (absoluteDifference / previousValue) * 100;
  let percentageDifference = Number(percentageDifference2).toLocaleString(
    undefined,
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }
  );

  if (currentValue > previousValue) {
    return '+' + percentageDifference + '%';
  } else if (currentValue < previousValue) {
    return '-' + percentageDifference + '%';
  } else {
    return '0%';
  }
};

// export function selectionSortDesc(array, property) {
//     array.sort((a, b) => b.value - a.value);

//   return array;
// }

export const percentageColor = number => {
  if (!isNaN(number)) {
    if (number <= 9.99) {
      return 'success';
    } else if (number > 9.99 && number <= 19.99) {
      return 'warning';
    } else if (number > 19.99) {
      return 'danger';
    }
  }
};
export const percentageToolTip = (StringPercentage, preset) => {
  if (StringPercentage && StringPercentage !== 'N/A') {
    if (StringPercentage.includes('+')) {
      return `Up ${StringPercentage.replace('+', '')} compared to the ${
        preset !== 'custom_range'
          ? preset.replaceAll('_', ' ')
          : 'previous range'
      }`;
    } else if (StringPercentage.includes('-')) {
      return `Down ${StringPercentage.replace(
        '-',
        ''
      )} compared to the ${'previous range'}`;
    }
  } else {
    return 'N/A';
  }
};
// preset !== 'custom_range'
// ? preset.replaceAll('_', ' ')
// :

export function calculateSeconds(dateTimeRangeStart, dateTimeRangeEnd) {
  const start = new Date(dateTimeRangeStart);
  const end = new Date(dateTimeRangeEnd);
  const duration = (end - start) / 1000; // Divide by 1000 to get seconds
  return duration;
}

export function generateIntervalsImp(start_date, end_date, interval) {
  const intervals = [];
  let delta;

  switch (interval.slice(-1)) {
    case 's':
      delta = 1000 * parseInt(interval.slice(0, -1));
      break;
    case 'm':
      delta = 60000 * parseInt(interval.slice(0, -1));
      break;
    case 'h':
      delta = 3600000 * parseInt(interval.slice(0, -1));
      break;
    case 'd':
      delta = 86400000 * parseInt(interval.slice(0, -1));
      break;
    default:
      return intervals;
  }

  let current_date = new Date(start_date.getTime());
  while (current_date <= end_date) {
    intervals.push({ key_as_string: current_date.toISOString(), doc_count: 0 });
    current_date = new Date(current_date.getTime() + delta);
  }

  return intervals;
}
export const getSparklineInterval = (preset, gte, lte) => {
  switch (preset) {
    case 'last_1_min':
      return '1s';
    case 'last_15_mins':
      return '15s';
    case 'last_30_mins':
      return '30s';
    case 'last_45_mins':
      return '45s';
    case 'last_1_hour':
      return '1m';
    case 'last_2_hours':
      return '2m';
    case 'last_4_hours':
      return '4m';
    case 'last_6_hours':
      return '5m';
    case 'last_8_hours':
      return '8m';
    case 'last_12_hours':
      return '10m';
    case 'last_1_day':
      return '20m';
    case 'last_4_days':
      return '2h';
    case 'last_7_days':
      return '3h';
    case 'last_14_days':
      return '6h';
    case 'last_30_days':
      return '12h';
    case 'last_45_days':
      return '15h';
    case 'last_60_days':
      return '24h';
    case 'last_90_days':
      return '2d';
    case 'daily_reports':
      return '20m';
    case 'custom_range':
      return getIntervalforcustomRange(gte, lte);
    default:
      return '20m';
  }
};

export const getHistogramInterval = (preset, gte, lte) => {
  switch (preset) {
    case 'last_1_min':
      return '1s';
    case 'last_15_mins':
      return '15s';
    case 'last_30_mins':
      return '30s';
    case 'last_45_mins':
      return '45s';
    case 'last_1_hour':
      return '1m';
    case 'last_2_hours':
      return '2m';
    case 'last_4_hours':
      return '4m';
    case 'last_6_hours':
      return '5m';
    case 'last_8_hours':
      return '8m';
    case 'last_12_hours':
      return '10m';
    case 'last_1_day':
      return '20m';
    case 'last_4_days':
      return '2h';
    case 'last_7_days':
      return '3h';
    case 'last_14_days':
      return '6h';
    case 'last_30_days':
      return '12h';
    case 'last_45_days':
      return '15h';
    case 'last_60_days':
      return '24h';
    case 'last_90_days':
      return '2d';
    case 'daily_reports':
      return '20m';
    case 'custom_range':
      return getIntervalforcustomRange(gte, lte);
    default:
      return '30m';
  }
};

function getIntervalforcustomRange(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffInMinutes = (end - start) / (1000 * 60); // Difference in minutes

  if (diffInMinutes >= 0 && diffInMinutes <= 15) {
    return '1m';
  } else if (diffInMinutes >= 16 && diffInMinutes <= 30) {
    return '2m';
  } else if (diffInMinutes >= 31 && diffInMinutes <= 45) {
    return '5m';
  } else if (diffInMinutes >= 46 && diffInMinutes <= 119) {
    return '5m';
  } else if (diffInMinutes >= 120 && diffInMinutes <= 239) {
    return '10m';
  } else if (diffInMinutes >= 240 && diffInMinutes <= 479) {
    return '20m';
  } else if (diffInMinutes >= 480 && diffInMinutes <= 719) {
    return '30m';
  } else if (diffInMinutes >= 720 && diffInMinutes <= 1439) {
    return '1h';
  } else if (diffInMinutes >= 1440 && diffInMinutes <= 2880) {
    // 1 to 2 days
    return '2h';
  } else if (diffInMinutes >= 2880 && diffInMinutes <= 5760) {
    // 2 to 4 days
    return '4h';
  } else if (diffInMinutes >= 7200 && diffInMinutes <= 10080) {
    // 5 to 7 days
    return '12h';
  } else if (diffInMinutes >= 11520 && diffInMinutes <= 20160) {
    // 8 to 14 days
    return '1d';
  } else if (diffInMinutes >= 21600 && diffInMinutes <= 43200) {
    // 15 to 30 days
    return '2d';
  } else if (diffInMinutes >= 43200 && diffInMinutes <= 129600) {
    // 1 to 3 months
    return '4d';
  } else if (diffInMinutes >= 129600 && diffInMinutes <= 259200) {
    // 4 to 6 months
    return '10d';
  } else if (diffInMinutes >= 302400 && diffInMinutes <= 518400) {
    // 7 to 12 months
    return '25d';
  } else if (diffInMinutes >= 525600) {
    // 1 year or more
    return '1y';
  } else {
    return null; // If the range doesn't match any condition
  }
}

export const getUserStatusSlug = lastSeenAt => {
  return lastSeenAt
    ? new Date(lastSeenAt) <
      new Date() -
        new Date(
          1000 *
            60 *
            (process.env.REACT_APP_TIMEOUT_IN_MINS +
              process.env.REACT_APP_PROMPT_FOR_MINS) || 1000 * 60 * 17
        )
      ? 'offline'
      : new Date(lastSeenAt) <
        new Date() -
          new Date(1000 * 60 * process.env.REACT_APP_IDLE_MINS || 1000 * 60 * 2)
      ? 'away'
      : 'online'
    : 'offline';
};

/**
 * @description Get a formatted file size string
 * @param {number} bytes file size in bytes
 * @returns {string} formatted file size string
 * @author Brandon Cummings
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat(bytes / Math.pow(k, i)).toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  })} ${sizes[i]}`;
};

export const isIterableArray = array => Array.isArray(array) && !!array.length;

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540
};

export const getItemFromStore = (key, defaultValue, store = localStorage) => {
  try {
    return JSON.parse(store.getItem(key)) || defaultValue;
  } catch {
    return store.getItem(key) || defaultValue;
  }
};

export const setItemToStore = (key, payload, store = localStorage) =>
  store.setItem(key, payload);

export const getStoreSpace = (store = localStorage) =>
  parseFloat(
    (
      escape(encodeURIComponent(JSON.stringify(store))).length /
      (1024 * 1024)
    ).toFixed(2)
  );
export const getCookieValue = name => {
  const value = document.cookie.match(
    '(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)'
  );
  return value ? value.pop() : null;
};

export const createCookie = (name, value, cookieExpireTime) => {
  const date = new Date();
  date.setTime(date.getTime() + cookieExpireTime);
  const expires = '; expires=' + date.toUTCString();
  document.cookie = name + 'is' + value + expires + '; path=/';
};

export const numberFormatter = (number, fixed = 2) => {
  return Math.abs(Number(number)) >= 1.0e15
    ? (Math.abs(Number(number)) / 1.0e15).toFixed(fixed) + ' Q'
    : Math.abs(Number(number)) >= 1.0e12
    ? (Math.abs(Number(number)) / 1.0e12).toFixed(fixed) + ' T'
    : Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + ' B'
    : Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + ' M'
    : Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + ' K'
    : Math.abs(Number(number)).toFixed(0);
};
export const hexToRgb = hexValue => {
  let hex;
  hexValue.indexOf('#') === 0
    ? (hex = hexValue.substring(1))
    : (hex = hexValue);
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (_m, r, g, b) => r + r + g + g + b + b)
  );
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
};

export const alphabetize = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};
export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) =>
  `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343'
];

export const colorsExtended = [
  '#39afd1',
  '#60b848',
  '#e63757',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#2c7be5',
  '#ff679b',
  '#f6c343',
  '#ffb1a1', // Complimentary color for #2c7be5
  '#3df28e', // Complimentary color for #00d97e
  '#a46889', // Complimentary color for #e63757
  '#66c0d5', // Complimentary color for #39afd1
  '#f0a061', // Complimentary color for #fd7e14
  '#0b8e95', // Complimentary color for #02a8b5
  '#969cdf', // Complimentary color for #727cf5
  '#b594c2', // Complimentary color for #6b5eae
  '#71b44c', // Complimentary color for #ff679b
  '#e99430', // Complimentary color for #f6c343
  '#5f46a3', // Another complimentary color for #2c7be5
  '#13e8bb', // Another complimentary color for #00d97e
  '#c82748', // Another complimentary color for #e63757
  '#25b8dc', // Another complimentary color for #39afd1
  '#f65404', // Another complimentary color for #fd7e14
  '#0f9b9e', // Another complimentary color for #02a8b5
  '#8d92f7', // Another complimentary color for #727cf5
  '#8d72b3', // Another complimentary color for #6b5eae
  '#d758a7', // Another complimentary color for #ff679b
  '#f7d157' // Another complimentary color for #f6c343
];

export const themeColors = {
  primary: '#2c7be5',
  secondary: '#748194',
  success: '#00d27a',
  info: '#27bcfd',
  warning: '#f5803e',
  danger: '#e63757',
  light: '#f9fafd',
  dark: '#0b1727'
};

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000'
};

export const darkGrays = {
  white: '#fff',
  1100: '#f9fafd',
  1000: '#edf2f9',
  900: '#d8e2ef',
  800: '#b6c1d2',
  700: '#9da9bb',
  600: '#748194',
  500: '#5e6e82',
  400: '#4d5969',
  300: '#344050',
  200: '#232e3c',
  100: '#0b1727',
  black: '#000'
};

export const getGrays = isDark => (isDark ? darkGrays : grays);

export const rgbColors = colors.map(color => rgbColor(color));
export const rgbaColors = colors.map(color => rgbaColor(color));

export const getColor = (name, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim();
};
export const getPosition = (pos, _params, _dom, _rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2
});
export const calculateSale = (base, less = 0, fix = 2) =>
  (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
  cart.reduce((accumulator, currentValue) => {
    const { id, quantity } = currentValue;
    const { price, sale } = baseItems.find(item => item.id === id);
    return accumulator + calculateSale(price, sale) * quantity;
  }, 0);
export const getSubtotal = items =>
  items.reduce((acc, curr) => curr.price * curr.quantity + acc, 0);
export const getDiscountPrice = (total, discount) =>
  total - total * (discount / 100);

export const getProductsQuantity = products =>
  products.reduce((acc, product) => product.quantity + acc, 0);
export const getPaginationArray = (totalSize, sizePerPage) => {
  const noOfPages = Math.ceil(totalSize / sizePerPage);
  const array = [];
  let pageNo = 1;
  while (pageNo <= noOfPages) {
    array.push(pageNo);
    pageNo = pageNo + 1;
  }
  return array;
};

export const capitalize = str =>
  (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const capitalizeWords = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match =>
    match.toUpperCase()
  );

export const camelize = str => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return '';
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const dashed = str => {
  return str.toLowerCase().replaceAll(' ', '-');
};

export const flatRoutes = children => {
  const flattenedChildren = [];

  const flatChild = children => {
    children.forEach(child => {
      if (child.children) {
        flatChild(child.children);
      } else {
        flattenedChildren.push(child);
      }
    });
  };
  flatChild(children);

  return flattenedChildren;
};

export const isEven = n => {
  return n % 2 == 0;
};

export const getFlatRoutes = children =>
  children.reduce(
    (acc, val) => {
      if (val.children) {
        return {
          ...acc,
          [camelize(val.name)]: flatRoutes(val.children)
        };
      } else {
        return {
          ...acc,
          unTitled: [...acc.unTitled, val]
        };
      }
    },
    { unTitled: [] }
  );

export const routesSlicer = ({ routes, columns = 3, rows }) => {
  const routesCollection = [];
  routes.map(route => {
    if (route.children) {
      return route.children.map(item => {
        if (item.children) {
          return routesCollection.push(...item.children);
        }
        return routesCollection.push(item);
      });
    }
    return routesCollection.push(route);
  });

  const totalRoutes = routesCollection.length;
  const calculatedRows = rows || Math.ceil(totalRoutes / columns);
  const routesChunks = [];
  for (let i = 0; i < totalRoutes; i += calculatedRows) {
    routesChunks.push(routesCollection.slice(i, i + calculatedRows));
  }
  return routesChunks;
};

export const getPageName = pageName => {
  return window?.location?.pathname?.split('/').slice(-1)[0] === pageName;
};

export const copyToClipBoard = textFieldRef => {
  const textField = textFieldRef.current;
  textField.focus();
  textField.select();
  document.execCommand('copy');
};

export const reactBootstrapDocsUrl = 'https://react-bootstrap.github.io';

export const pagination = (currentPage, size) => {
  const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let prev = currentPage - 1 - Math.floor(size / 2);

  if (currentPage - 1 - Math.floor(size / 2) < 0) {
    prev = 0;
  }
  if (currentPage - 1 - Math.floor(size / 2) > pages.length - size) {
    prev = pages.length - size;
  }
  const next = prev + size;

  return pages.slice(prev, next);
};

export const serialize = obj => {
  var str = [];
  for (var p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(encodeURIComponent(p) + 'is' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

export const truncateObject = object => {
  return Object.fromEntries(Object.entries(object).slice(0, 4));
};

export const tooltipFormatter = params => {
  let tooltipItem = ``;
  params.forEach(el => {
    tooltipItem =
      tooltipItem +
      `<div class='ms-1'> 
        <h6 class="text-700"><span class="fas fa-circle me-1 fs--2" style="color:${
          el.borderColor ? el.borderColor : el.color
        }"></span>
          ${el.seriesName} : ${
            typeof el.value === 'object' ? el.value[1] : el.value
          }
        </h6>
      </div>`;
  });
  return `<div>
            <p class='mb-2 text-600'>
              ${
                dayjs(params[0].axisValue).isValid()
                  ? dayjs(params[0].axisValue).format('MMMM DD')
                  : params[0].axisValue
              }
            </p>
            ${tooltipItem}
          </div>`;
};

export const addIdField = items => {
  return items.map((item, index) => ({
    id: index + 1,
    ...item
  }));
};

export const getSize = size => {
  if (size < 1024) {
    return `${size} Byte`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }
};
export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const getDates = (
  startDate,
  endDate,
  interval = 1000 * 60 * 60 * 24
) => {
  const duration = endDate - startDate;
  const steps = duration / interval;
  return Array.from(
    { length: steps + 1 },
    (_v, i) => new Date(startDate.valueOf() + interval * i)
  );
};
export const getPastDates = duration => {
  let days;

  switch (duration) {
    case 'week':
      days = 7;
      break;
    case 'month':
      days = 30;
      break;
    case 'year':
      days = 365;
      break;

    default:
      days = duration;
  }

  const date = new Date();
  const endDate = date;
  const startDate = new Date(new Date().setDate(date.getDate() - (days - 1)));
  return getDates(startDate, endDate);
};
export const addId = items =>
  items.map((item, index) => ({
    id: index + 1,
    ...item
  }));
export const getTimeDuration = (startDate, endDate, format = '') => {
  return dayjs.duration(endDate.diff(startDate)).format(format);
};
export const getPercentage = (number, percent) => {
  return (Number(number) / 100) * Number(percent);
};
export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

export const moveToFrontInObjectArray = (x, collection) => {
  for (var i = 0; i < collection.length; i++) {
    if (collection[i].id === x) {
      let temp = collection[0];
      collection[0] = collection[i];
      collection[i] = temp;
    }
  }
  return collection;
};

export const Redirecting = () => {
  return (
    <div>
      <Outlet />
      {/* Rest of the component code */}
    </div>
  );
};

export const deepClone = obj => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    const clonedArr = [];
    for (let i = 0; i < obj.length; i++) {
      clonedArr[i] = deepClone(obj[i]);
    }
    return clonedArr;
  }

  const clonedObj = {};
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clonedObj[key] = deepClone(obj[key]);
    }
  }
  return clonedObj;
};

export function capitalizeWord(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function fixTimeSelectLabel(word) {
  // Replace all underscores with spaces and capitalize each word
  return capitalizeWord(word).replace(/_/g, ' ');
}

export function convertToValidJsonString(input) {
  // Add double quotes around keys using a regular expression
  const validJsonString =
    typeof input !== 'string'
      ? JSON.stringify(input)
      : input.replace(/([{,])\s*([a-zA-Z0-9_]+)\s*:/g, '$1"$2":');
  return validJsonString;
}

export function maskSensitiveData(json, fields, isPrivacyMode) {
  if (!isPrivacyMode) return json;

  let maskedJson = { ...json };

  fields.forEach(field => {
    if (field.sensitive && maskedJson[field.accessor] !== undefined) {
      maskedJson[field.accessor] = '******';
    }
  });

  return maskedJson;
}
