import { formatLocalDate } from 'components/dashboards/Artifacts/Dynamic/dataFetchUtils/RefinedUtils.js';
import moment from 'moment';

/**
 * Map of intervals for a given time range.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 *
 * @type {Object.<string, string>}
 */
export const intervalMap = {
  '5m': '5s', // 5 minutes
  '10m': '10s', // 10 minutes
  '15m': '15s', // 15 minutes
  '20m': '20s', // 20 minutes
  '30m': '30s', // 30 minutes
  '45m': '45s', // 45 minutes
  '1h': '1m', // 1 hour
  '2h': '2m', // 2 hours
  '3h': '3m', // 3 hours
  '4h': '4m', // 4 hours
  '6h': '6m', // 6 hours
  '8h': '8m', // 8 hours
  '12h': '10m', // 12 hours
  '1d': '20m', // 1 day
  '2d': '40m', // 2 days
  '4d': '2h', // 4 days
  '1w': '3h', // 1 week
  '2w': '6h', // 2 weeks
  '3w': '9h', // 3 weeks
  '1M': '12h', // 1 month
  '2M': '24h', // 2 months
  '3M': '36h', // 3 months
  '1y': '1y' // 1 year or more
};

/**
 * Converts a range value to seconds.
 *
 * @param {string} range - The range value to convert
 * @returns {number} The converted value in seconds
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const getSecondsFromRange = range => {
  const num = parseInt(range, 10);
  switch (range[range.length - 1]) {
    case 's':
      return num;
    case 'm':
      return num * 60;
    case 'h':
      return num * 3600;
    case 'd':
      return num * 86400;
    case 'w':
      return num * 604800;
    case 'y':
      return num * 31536000;
    default:
      return num;
  }
};

/**
 * Calculates the interval for a custom date range.
 *
 * @param {Date} startDate - The start date of the range
 * @param {Date} endDate - The end date of the range
 * @returns {string|null} - The interval for the range, or null if no matching interval is found
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const getIntervalForCustomRange = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffInSeconds = (end - start) / 1000; // Difference in seconds

  // Finding matching interval
  for (const range in intervalMap) {
    const seconds = getSecondsFromRange(range);
    if (diffInSeconds <= seconds) {
      return intervalMap[range];
    }
  }
  return null;
};

/**
 * Get the time ranges from today 10 AM to yesterday 10 AM in UTC
 * @returns {Object} - Returns an object with 'today10am' and 'yesterday10am' in UTC format
 */

const getDSLRTimerange = () => {
  // Get today's date in UTC
  const today = new Date();
  today.setUTCHours(10, 0, 0, 0); // Set the time to 10 AM UTC today

  // Get yesterday's date in UTC
  const yesterday = new Date(today);
  yesterday.setUTCDate(today.getUTCDate() - 1); // Go back one day
  yesterday.setUTCHours(10, 0, 0, 0); // Set the time to 10 AM UTC yesterday

  return {
    from: moment(yesterday).format('YYYY-MM-DDTHH:mm:ss'), // Returns today date in user local timezone date
    to: moment(today).format('YYYY-MM-DDTHH:mm:ss') // Returns yesterday date in user local timezone date
  };
};

/**
 * Get the time range from yesterday 10 AM to now in UTC
 * @returns {Object} - Returns an object with 'yesterday10am' and 'now' in UTC format
 */
const getDSLRNowTimerange = () => {
  // Get yesterday's date in UTC
  const yesterday = new Date();
  yesterday.setUTCDate(yesterday.getUTCDate() - 1);
  yesterday.setUTCHours(10, 0, 0, 0); // Set to 10 AM UTC

  // Get current time
  const now = new Date();

  return {
    from: moment(yesterday).format('YYYY-MM-DDTHH:mm:ss'),
    to: moment(now).format('YYYY-MM-DDTHH:mm:ss')
  };
};

/**
 * Calculates the relative time range based on the given number of seconds ago.
 *
 * @param {number} secondsAgo - The number of seconds ago
 * @returns {Object} - An object containing the 'from' and 'to' time in ISO string format
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const getRelativeTimeRange = secondsAgo => {
  const now = new Date();
  const fromTime = new Date(now.getTime() - secondsAgo * 1000);
  return {
    from: moment(fromTime).format('YYYY-MM-DDTHH:mm:ss'),
    to: moment(now).format('YYYY-MM-DDTHH:mm:ss')
  };
};

/**
 * @description Defines the timeRanges object which contains various time range options.
 *
 * @typedef {Object} TimeRange
 * @property {boolean} canAutoRefresh - A boolean value indicating if the time range can be used for auto refresh
 * @property {string} label - The label of the time range group
 * @property {Array<{label: string, value: number, seconds: number}>} options - The available options for the time range
 * @property {string} unit - The unit of the time range used for datetime calculations
 *
 * @namespace
 * @name timeRanges
 * @type {Object.<string, TimeRange>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const timeRanges = {
  custom: {
    label: 'Custom Range',
    options: [
      {
        canAutoRefresh: false,
        getTimeRange: (fromDate, toDate) => ({
          from: formatLocalDate(fromDate),
          to: formatLocalDate(toDate)
        }),
        getInterval: (fromDate, toDate) =>
          getIntervalForCustomRange(fromDate, toDate),
        unit: 'custom',
        label: 'Custom Range'
      }
    ]
  },
  minutes: {
    label: 'Minutes',
    options: [
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(300),
        getInterval: () => intervalMap['5m'],
        label: '5 minutes',
        seconds: 300,
        unit: 'minutes',
        value: 5
      },
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(600),
        getInterval: () => intervalMap['10m'],
        label: '10 minutes',
        seconds: 600,
        unit: 'minutes',
        value: 10
      },
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(900),
        getInterval: () => intervalMap['15m'],
        label: '15 minutes',
        seconds: 900,
        unit: 'minutes',
        value: 15
      },
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(1200),
        getInterval: () => intervalMap['20m'],
        label: '20 minutes',
        seconds: 1200,
        unit: 'minutes',
        value: 20
      },
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(1800),
        getInterval: () => intervalMap['30m'],
        label: '30 minutes',
        seconds: 1800,
        unit: 'minutes',
        value: 30
      },
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(2700),
        getInterval: () => intervalMap['45m'],
        label: '45 minutes',
        seconds: 2700,
        unit: 'minutes',
        value: 45
      }
    ]
  },
  hours: {
    label: 'Hours',
    options: [
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(3600),
        getInterval: () => intervalMap['1h'],
        label: '1 hour',
        seconds: 3600,
        unit: 'hours',
        value: 1
      },
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(7200),
        getInterval: () => intervalMap['2h'],
        label: '2 hours',
        seconds: 7200,
        unit: 'hours',
        value: 2
      },
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(10800),
        getInterval: () => intervalMap['3h'],
        label: '3 hours',
        seconds: 10800,
        unit: 'hours',
        value: 3
      },
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(14400),
        getInterval: () => intervalMap['4h'],
        label: '4 hours',
        seconds: 14400,
        unit: 'hours',
        value: 4
      },
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(21600),
        getInterval: () => intervalMap['6h'],
        label: '6 hours',
        seconds: 21600,
        unit: 'hours',
        value: 6
      },
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(28800),
        getInterval: () => intervalMap['8h'],
        label: '8 hours',
        seconds: 28800,
        unit: 'hours',
        value: 8
      },
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(43200),
        getInterval: () => intervalMap['12h'],
        label: '12 hours',
        seconds: 43200,
        unit: 'hours',
        value: 12
      }
    ]
  },
  days: {
    label: 'Days',
    options: [
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(86400),
        getInterval: () => intervalMap['1d'],
        label: '1 day',
        seconds: 86400,
        unit: 'days',
        value: 1
      },
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(172800),
        getInterval: () => intervalMap['2d'],
        label: '2 days',
        seconds: 172800,
        unit: 'days',
        value: 2
      },
      {
        canAutoRefresh: true,
        getTimeRange: () => getRelativeTimeRange(345600),
        getInterval: () => intervalMap['4d'],
        label: '4 days',
        seconds: 345600,
        unit: 'days',
        value: 4
      }
    ]
  },
  weeks: {
    label: 'Weeks',
    options: [
      {
        canAutoRefresh: false,
        getTimeRange: () => getRelativeTimeRange(604800),
        getInterval: () => intervalMap['1w'],
        label: '1 week',
        seconds: 604800,
        unit: 'weeks',
        value: 1
      },
      {
        canAutoRefresh: false,
        getTimeRange: () => getRelativeTimeRange(1209600),
        getInterval: () => intervalMap['2w'],
        label: '2 weeks',
        seconds: 1209600,
        unit: 'weeks',
        value: 2
      },
      {
        canAutoRefresh: false,
        getTimeRange: () => getRelativeTimeRange(1814400),
        getInterval: () => intervalMap['3w'],
        label: '3 weeks',
        seconds: 1814400,
        unit: 'weeks',
        value: 3
      }
    ]
  },
  months: {
    label: 'Months',
    options: [
      {
        canAutoRefresh: false,
        getTimeRange: () => getRelativeTimeRange(2592000),
        getInterval: () => intervalMap['1M'],
        label: '1 month',
        seconds: 2592000,
        unit: 'months',
        value: 1
      },
      {
        canAutoRefresh: false,
        getTimeRange: () => getRelativeTimeRange(5184000),
        getInterval: () => intervalMap['2M'],
        label: '2 months',
        seconds: 5184000,
        unit: 'months',
        value: 2
      },
      {
        canAutoRefresh: false,
        getTimeRange: () => getRelativeTimeRange(7776000),
        getInterval: () => intervalMap['3M'],
        label: '3 months',
        seconds: 7776000,
        unit: 'months',
        value: 3
      }
    ]
  },
  DSLR: {
    label: 'DSLR',
    options: [
      {
        canAutoRefresh: false,
        getTimeRange: () => getDSLRTimerange(),
        getInterval: () => intervalMap['1d'],
        label: 'DSLR',
        unit: 'DSLR'
      },
      {
        canAutoRefresh: true,
        getTimeRange: () => getDSLRNowTimerange(),
        getInterval: () => intervalMap['1d'],
        label: 'DSLR - Now',
        unit: 'DSLR'
      }
    ]
  }
};

/**
 * Defines the auto refresh intervals for the filters provider.
 *
 * @property {number} 30000 - The interval in milliseconds for 30 seconds
 * @property {number} 60000 - The interval in milliseconds for 1 minute
 * @property {number} 120000 - The interval in milliseconds for 2 minutes
 * @property {number} 300000 - The interval in milliseconds for 5 minutes
 * @property {number} 600000 - The interval in milliseconds for 10 minutes
 * @property {number} 900000 - The interval in milliseconds for 15 minutes
 * @property {number} 1200000 - The interval in milliseconds for 20 minutes
 * @property {number} 1800000 - The interval in milliseconds for 30 minutes
 * @property {number} 2700000 - The interval in milliseconds for 45 minutes
 * @property {number} 3600000 - The interval in milliseconds for 1 hour
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const autoRefreshIntervals = {
  30000: '30 seconds',
  60000: '1 minute',
  120000: '2 minutes',
  300000: '5 minutes',
  600000: '10 minutes',
  900000: '15 minutes',
  1200000: '20 minutes',
  1800000: '30 minutes',
  2700000: '45 minutes',
  3600000: '1 hour'
};

/**
 * Default selected fields.
 *
 * @type {Array<Object>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
export const defaultSelectedFields = [
  {
    id: '@timestamp',
    name: '@timestamp',
    accessor: '@timestamp',
    Header: '@timestamp',
    enabledSorting: true,
    type: 'datetime',
    permanent: true
  },
  {
    id: '_source',
    name: '_source',
    accessor: '_source',
    Header: '_source',
    type: 'object',
    enabledSorting: false,
    hasRenderer: true,
    permanent: true
  }
];
