import Flex from 'components/common/Flex';
import SearchFiltersPanel from 'components/search-filters/SearchFiltersPanel';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ExploreHistogram from './Histogram';

/**
 * ExplorePage component.
 *
 * @returns {JSX.Element} The rendered ExplorePage component.
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const ExplorePage = () => {
  return (
    <>
      <Flex direction='column'>
        <SearchFiltersPanel />
        <Row className='g-2 mb-2'>
          <Col>
            <ExploreHistogram />
          </Col>
        </Row>
      </Flex>
    </>
  );
};

export default ExplorePage;
