import { getColor, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import Select from 'react-select';
import { FixedSizeList as List } from 'react-window';

// Custom MenuList component to render with virtualization
const MenuList = ({ options, children, maxHeight, getValue }) => {
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * 35; // Assumes each item is 35px tall

  return (
    <List
      height={Math.min(maxHeight, options.length * 35)}
      itemCount={children.length}
      itemSize={35}
      initialScrollOffset={initialOffset}>
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

MenuList.propTypes = {
  options: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  maxHeight: PropTypes.number.isRequired,
  getValue: PropTypes.func.isRequired
};

const LazyLoadSelect = forwardRef(({ options, ...rest }, ref) => {
  return (
    <Select
      ref={ref}
      closeMenuOnSelect={true}
      isMulti={false} // Ensure it's a regular select, not multi-select
      options={options}
      isOptionDisabled={option => option.isDisabled === true}
      maxMenuHeight={250}
      menuPosition='absolute'
      components={{ MenuList }}
      styles={{
        menu: provided => ({
          ...provided,
          margin: '0',
          padding: '5px 0 0 0',
          backgroundColor: 'var(--falcon-card-bg)'
        }),
        menuList: provided => ({
          ...provided,
          backgroundColor: 'var(--falcon-card-bg)',
          minHeight: 'fit-content'
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: !state.isSelected
            ? 'var(--falcon-card-bg)'
            : getColor('pastel-green-dark'),
          color: state.isDisabled
            ? 'var(--falcon-gray-400)'
            : !state.isSelected
            ? 'var(--falcon-input-color)'
            : 'var(--falcon-input-bg)',
          fontSize: '0.6944444444rem',
          padding: 10,
          cursor: !state.isDisabled ? 'pointer' : 'default',
          '&:hover': {
            backgroundColor: getColor('pastel-green-dark'),
            color: 'white'
          }
        }),
        input: provided => ({
          ...provided,
          color: 'var(--falcon-input-color)',
          margin: '0',
          padding: '0'
        }),
        control: provided => ({
          ...provided,
          margin: '0',
          padding: '0',
          color: 'var(--falcon-input-color)',
          backgroundColor: 'var(--falcon-input-bg)',
          border: '1px solid var(--falcon-input-border-color)',
          boxShadow: 'var(--falcon-box-shadow-inset)',
          transition:
            'background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
          ':hover': {
            borderColor: getColor('pastel-green')
          },
          ':focus-within': {
            borderColor: getColor('pastel-green'),
            boxShadow: `inset 0 0 10px 0 ${rgbaColor(
              getColor('pastel-green'),
              0.5
            )}`
          }
        }),
        valueContainer: provided => ({
          ...provided,
          border: '0',
          margin: '0',
          padding: '0 0 0 8px'
        }),
        singleValue: provided => ({
          ...provided,
          color: 'var(--falcon-input-color)',
          fontSize: '0.8333333333rem',
          margin: '0',
          padding: '0'
        })
      }}
      {...rest}
    />
  );
});

LazyLoadSelect.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string
};

export default LazyLoadSelect;
