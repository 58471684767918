import LeargasAPI from 'api';
import React from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

// import useAxiosPrivate from 'hooks/useAxiosPrivate';

export const columnDefaults = (setOnListChange, axiosPrivate) => {
  const columns = [
    {
      accessor: 'title',
      Header: 'Title',
      noTruncate: true,

      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle'
      }
    },
    {
      accessor: 'description',
      Header: 'Description',
      noTruncate: true,

      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap'
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle'
      }
    },
    {
      accessor: 'index',
      Header: 'Index',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap cursor-default',
        style: { width: '12%' }
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle'
      },
      Cell: rowData => {
        let index = rowData.row.original.index.alias;

        return <span>{index}</span>;
      }
    },
    {
      accessor: '',
      Header: 'showDocumentTable',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'fs--1 text-700 align-middle text-nowrap text-center',
        style: { width: '8%' }
      },
      headerProps: {
        className: 'fs--1 text-nowrap align-middle text-center'
      },
      Cell: rowData => {
        let showDocumentTable;
        showDocumentTable = rowData.row.original?.showDocumentTable;
        return (
          <Form.Check
            inline
            type='switch'
            name='enabled'
            value={showDocumentTable}
            className='me-2'
            checked={showDocumentTable}
            onChange={async e => {
              try {
                const res = await LeargasAPI.Dashboards.updateDashboard(
                  rowData.row.original.id,
                  {
                    title: rowData.row.original.title,
                    description: rowData.row.original.description,
                    index: rowData.row.original.index.id,
                    rangePreset: rowData.row.original.time.rangePreset,
                    rangeMax: rowData.row.original.time.rangeMax,
                    showDocumentTable: e.target.checked,
                    layout: []
                  },
                  axiosPrivate
                );
                if (res instanceof Error) throw res;
                let message = res?.data?.message;
                toast.success(message);
                setOnListChange(true);
              } catch (error) {
                if (error.message.includes('Network Error')) {
                  console.error('Network Error occurred.');
                }
                if (error?.response?.data?.message) {
                  toast.error(error?.response?.data?.message);
                } else {
                  toast.error('Something went wrong!');
                }
              }
            }}
          />
        );
      }
    }
  ];

  return columns;
};

export default columnDefaults;
