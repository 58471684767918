import React from 'react';
import PropTypes from 'prop-types';
import RolesProvider from '../../../../../../context/admin-contexts/RolesProvider';
import RolesLayout from './RolesLayout';
import ModalRoleAdd from './components/modals/RolesAddModal';
import ModalRoleEdit from './components/modals/RolesEditModal';
import ModalRoleDelete from './components/modals/RolesDeleteModal';

/**
 * roles wrapper component
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const RolesWrapper = props => {
  return (
    <RolesProvider>
      {props.children}
      <RolesLayout />
      <ModalRoleAdd />
      <ModalRoleEdit />
      <ModalRoleDelete />
    </RolesProvider>
  );
};

RolesWrapper.propTypes = { children: PropTypes.node };

export default RolesWrapper;
