import React from 'react';
import ArticlesTagsWrapper from './ArticleTagsWrapper';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Tags = () => {
  return (
    <>
      <ArticlesTagsWrapper />
    </>
  );
};

export default Tags;
