import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Products = () => {
  return (
    <WidgetSectionTitle title='Product Instance Management' className='mb-2' />
  );
};

export default Products;
