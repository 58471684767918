import React from 'react';
import DisplayLayout from './DisplayLayout';

/**
 * Renders the DisplaySettingsPage component
 *
 * @returns {JSX.Element} The rendered DisplaySettingsPage component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const DisplaySettingsPage = () => {
  return (
    <>
      <DisplayLayout />
    </>
  );
};

export default DisplaySettingsPage;
