import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import ConfirmClosesavedSearchModel from 'components/modals/confirmClosesavedSearchmodel.js';
import { buildBreadcrumbItems } from 'helpers/breadcrumb.utils';
import useApplication from 'hooks/useApplication';
import useExplore from 'hooks/useExplore';
import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Breadcrumb, Button, Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';
import routes from 'routes/routes';
/**
 * Renders a breadcrumb component displaying the current route.
 *
 * @param {Object} props - The component props
 * @param {ReactNode} props.children - Items to render to the right side
 * @returns {JSX.Element} The rendered breadcrumb component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.4
 */
const Breadcrumbs = ({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });

  const {
    application: { showEditSavedSearchModal },
    setApplication
  } = useApplication();
  const { state: { savedSearch: { name } = {} } = {} } = useExplore();

  // Memoized building breadcrumb items
  const breadcrumbItems = useMemo(
    () => buildBreadcrumbItems(routes, currentPath),
    [currentPath]
  );

  return (
    <>
      {' '}
      <Row>
        <Col>
          <Flex
            direction='row'
            justifyContent='between'
            alignContent='center'
            alignItems='center'
            className='w-100 m-0 p-0'
            style={{ minHeight: '30px' }}>
            <Flex className='m-0 p-0'>
              <Breadcrumb style={{ marginTop: '0', paddingLeft: '1px' }}>
                {breadcrumbItems.map((item, index) => (
                  <Breadcrumb.Item
                    key={index}
                    linkAs={
                      index + 1 < breadcrumbItems.length && item?.to
                        ? Link
                        : 'span'
                    }
                    linkProps={{
                      to: item?.to,
                      className: 'text-decoration-none'
                    }}
                    className='fs--1 fw-semi-bold'>
                    {item?.icon && (
                      <FontAwesomeIcon
                        icon={item.icon}
                        className={classNames({
                          'me-1': isLargeScreen && item?.label
                        })}
                        transform={classNames({
                          [`grow-${item?.iconGrow}`]:
                            item?.iconGrow && Number(item?.iconGrow) > 0,
                          [`rotate-${item?.iconRotation}`]:
                            item?.iconRotation && Number(item?.iconRotation) > 0
                        })}
                      />
                    )}
                    {isLargeScreen || index === breadcrumbItems.length - 1 ? (
                      <span className='ms-1'>
                        {truncate(item?.label, { length: 16 })}
                      </span>
                    ) : null}
                  </Breadcrumb.Item>
                ))}
                {name && (
                  <>
                    <Breadcrumb.Item
                      linkProps={{
                        className:
                          'text-decoration-none text-700 cursor-default'
                      }}
                      className='fs--1 fw-semi-bold'>
                      {truncate(name, { length: 16 })}
                      <Button
                        variant='link'
                        className='p-0 fs--1 text-decoration-none'
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          setApplication(
                            'showEditSavedSearchModal',
                            !showEditSavedSearchModal
                          );
                        }}>
                        <FontAwesomeIcon
                          icon={['fas', 'pencil']}
                          className='ms-1'
                          transform='shrink-3'
                        />
                      </Button>
                      <Button
                        variant='link'
                        className='p-0 fs--1  text-danger text-decoration-none'
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          setApplication('confirmClosesavedSearchModel', true);
                        }}>
                        <FontAwesomeIcon
                          icon={['fa', 'close']}
                          className='ms-1 '
                          transform='grow-3'
                        />
                      </Button>
                    </Breadcrumb.Item>
                  </>
                )}
              </Breadcrumb>
            </Flex>
            <Flex className='m-0 p-0'>{children}</Flex>
          </Flex>
        </Col>
      </Row>
      <ConfirmClosesavedSearchModel />
    </>
  );
};

Breadcrumbs.propTypes = {
  children: PropTypes.node
};

export default Breadcrumbs;
