import routes from 'config/routeSettings';
import SavedSearchesProvider from 'context/admin-contexts/SavedSearchProvider';
import React from 'react';
import SavedSearchList from './SavedSearchesList';

/**
 * Renders the OverviewLayout component
 *
 * @param {Object} props - The component props
 * @param {Array} props.checkerIndices - The checker indexes
 * @param {number} props.allowedLimit - The allowed limit
 * @param {string} props.injectedPreset - The injected preset
 * @returns {JSX.Element} The rendered component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const OverviewLayout = () => {
  return (
    <>
      <SavedSearchesProvider>
        <SavedSearchList
          cardTitle='Recent Searches'
          icon='magnifying-glass'
          linkTo={routes.SAVED_SEARCHES}
          slice={6}
          linkTitle='All Saved Searches'
          title='Test Search'
          subtitle={'Description of the search'}
        />
      </SavedSearchesProvider>
    </>
  );
};

// OverviewLayout.propTypes = {
//   checkerIndices: PropTypes.array,
//   allowedLimit: PropTypes.number,
//   injectedPreset: PropTypes.string
// };

export default OverviewLayout;
