import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

const DashboardForm = () => {
  const initialDashboard = {
    id: QbUtils.uuid(),
    colSize: { xs: 6, sm: 6, md: 6, lg: 6, xl: 12, xxl: 12 },
    component: 'DataIntegrationDashboards',
    props: {
      overriddenIndex: ['leargas-box'],
      queryType: ['aggregate']
    },
    children: {
      component: 'PieChart',
      props: {
        header: 'Users',
        field: 'created_by.login',
        helpInfo: 'Users who triggered events'
      }
    }
  };

  const [dashboards, setDashboards] = useState([initialDashboard]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    // Clone the dashboards array and update the specific dashboard's property
    const updatedDashboards = [...dashboards];
    updatedDashboards[index][name] = value;
    setDashboards(updatedDashboards);
  };

  const handleAddDashboard = () => {
    // Add a new dashboard with initial values
    setDashboards([...dashboards, initialDashboard]);
  };

  const handleRemoveDashboard = index => {
    // Remove a dashboard by index
    const updatedDashboards = [...dashboards];
    updatedDashboards.splice(index, 1);
    setDashboards(updatedDashboards);
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Handle form submission here (e.g., save dashboards)
  };

  return (
    <div>
      <h1>Dashboard Form</h1>
      <Form onSubmit={handleSubmit}>
        {dashboards.map((dashboard, index) => (
          <div key={dashboard.id}>
            <Button
              variant='danger'
              onClick={() => handleRemoveDashboard(index)}>
              Remove Dashboard
            </Button>
            <Form.Group controlId={`colSize-${dashboard.id}`}>
              <Form.Label>Column Size:</Form.Label>
              <Form.Control
                as='select'
                name='colSize'
                value={dashboard.colSize}
                onChange={e => handleChange(e, index)}>
                {/* Options for different column sizes */}
                <option value={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 12, xxl: 12 }}>
                  Default
                </option>
                <option
                  value={{ xs: 3, sm: 3, md: 6, lg: 12, xl: 12, xxl: 12 }}>
                  Custom
                </option>
                {/* Add more options as needed */}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId={`component-${dashboard.id}`}>
              <Form.Label>Component:</Form.Label>
              <Form.Control
                as='select'
                name='component'
                value={dashboard.component}
                onChange={e => handleChange(e, index)}>
                {/* Options for different components */}
                <option value='DataIntegrationDashboards'>
                  Data Integration Dashboards
                </option>
                <option value='Table'>Table</option>
                <option value='Map'>Map</option>
                {/* Add more options as needed */}
              </Form.Control>
            </Form.Group>

            {/* Props for DataIntegrationDashboards */}
            {dashboard.component === 'DataIntegrationDashboards' && (
              <Form.Group controlId={`props-${dashboard.id}`}>
                <Form.Label>Props for Data Integration:</Form.Label>
                <Form.Control
                  type='text'
                  name='props.overriddenIndex'
                  value={dashboard.props.overriddenIndex.join(',')}
                  onChange={e => handleChange(e, index)}
                />
              </Form.Group>
            )}

            {/* Props for the selected component */}
            <Form.Group controlId={`component-props-${dashboard.id}`}>
              <Form.Label>Props for {dashboard.component}:</Form.Label>
              <Form.Control
                type='text'
                name={`children.props.${dashboard.component.toLowerCase()}-props`}
                value={
                  dashboard.children.props[
                    dashboard.component.toLowerCase() + '-props'
                  ]
                }
                onChange={e => handleChange(e, index)}
              />
            </Form.Group>
          </div>
        ))}

        <Button variant='primary' onClick={handleAddDashboard}>
          Add Dashboard
        </Button>

        <Button variant='success' type='submit'>
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default DashboardForm;
