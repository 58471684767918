import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useApplication from 'hooks/useApplication';
import useAlerts from 'hooks/user-contexts/useUserAlerts';
import { Modal } from 'react-bootstrap';

/**
 * alerts Edit modal
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ModalAlertView = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    alerts: { viewAlertModal },
    setAlerts
  } = useAlerts();

  return (
    <Modal
      centered
      size={viewAlertModal?.fullScreen ? 'xl' : 'lg'}
      animation={false}
      backdrop='static'
      keyboard={false}
      show={viewAlertModal?.open}
      onHide={() =>
        setAlerts(prevState => ({
          ...prevState,
          viewAlertModal: { open: false }
        }))
      }
      aria-labelledby='modalAlertviewAlertModal'
      contentClassName={
        viewAlertModal?.fullScreen &&
        'border-0 border-top-left-radius-0 border-top-right-radius-0 h-100'
      }
      dialogClassName={
        viewAlertModal?.fullScreen &&
        'position-absolute all-0 fullscreen-modal-dialog scrollbar overflow-x-hidden'
      }>
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className='d-flex justify-content-between'>
        <Modal.Title>viewAlertModal a Alert</Modal.Title>
        <div
          className='d-flex justify-content-end position-absolute right-0 '
          style={{ right: '3rem' }}>
          <FontAwesomeIcon
            icon={
              viewAlertModal?.fullScreen
                ? 'down-left-and-up-right-to-center'
                : 'up-right-and-down-left-from-center'
            }
            className='me-2 cursor-pointer'
            style={{
              color: isDark ? 'white' : 'black',
              opacity: 0.8
            }}
            onClick={() => {
              setAlerts(prevState => ({
                ...prevState,
                viewAlertModal: {
                  open: true,
                  fullScreen: !viewAlertModal?.fullScreen
                }
              }));
            }}
          />
        </div>
      </Modal.Header>
      <Modal.Body className='p-2'>View Modal</Modal.Body>
    </Modal>
  );
};

export default ModalAlertView;
