import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import { routes } from 'config/routeSettings';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 * Account registration form
 *
 * The account registration form is used to display a form to the user to register a new account.
 * @param {Object} props The component props
 * @param {boolean} props.hasLabel Whether or not to display a label for the verification code input
 * @returns {React.Component} The MFA app setup form
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const RegisterForm = ({ hasLabel }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    confirmPassword: '',
    acceptedTerms: false
  });
  const [loading, setLoading] = useState(false);

  const focusField = useRef();

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await LeargasAPI.AccountRegister.userRegister({
        body: { ...formData }
      });
      if (res instanceof Error) throw res;
      let message = res?.data?.message;
      if (message) toast.success(message);

      setTimeout(function () {
        setLoading(false);
        navigate({
          pathname: routes.CONFIRM_MAIL,
          search: `?address=${formData.emailAddress}`
        });
      }, 1000);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      let message = error.response?.data?.message;
      if (message) toast.error(message);

      setTimeout(function () {
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    focusField.current.focus();
  }, []);

  return (
    <Form>
      <Row className='g-3 mb-3'>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>First Name</Form.Label>}
          <Form.Control
            type='text'
            name='firstName'
            ref={focusField}
            placeholder={!hasLabel ? 'First name' : ''}
            value={formData.focusField}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Last Name</Form.Label>}
          <Form.Control
            type='text'
            name='lastName'
            placeholder={!hasLabel ? 'Last name' : ''}
            value={formData.lastName}
            onChange={handleFieldChange}
          />
        </Form.Group>
      </Row>
      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          type='text'
          name='emailAddress'
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.emailAddress}
          onChange={handleFieldChange}
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          type='password'
          name='password'
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          onChange={handleFieldChange}
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          type='password'
          name='confirmPassword'
          placeholder={!hasLabel ? 'Confirm password' : ''}
          value={formData.confirmPassword}
          onChange={handleFieldChange}
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Check type='checkbox' id='acceptCheckbox' className='form-check'>
          <Form.Check.Input
            type='checkbox'
            name='acceptedTerms'
            value={formData.acceptedTerms}
            checked={formData.acceptedTerms}
            onChange={e =>
              setFormData(prevState => ({
                ...prevState,
                acceptedTerms: e.target.checked
              }))
            }
          />
          <Form.Check.Label className='form-label'>
            I accept the <Link to='#!'>terms</Link> and{' '}
            <Link to='#!'>privacy policy</Link>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>
      <Form.Group>
        <Button
          type='button'
          color='primary'
          className='w-100'
          disabled={
            !formData.firstName ||
            !formData.lastName ||
            !formData.emailAddress ||
            !formData.password ||
            !formData.confirmPassword ||
            !formData.acceptedTerms
          }
          onClick={handleSubmit}>
          <div className='position-relative'>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <ButtonSpinner spinning={loading} />
              <span>{loading ? 'Registering' : 'Register'}</span>
            </Flex>
          </div>
        </Button>
      </Form.Group>
    </Form>
  );
};

RegisterForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default RegisterForm;
