import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import ProfileContainer from './ProfileContainer';
import routes from 'config/routeSettings';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuthentication from 'hooks/useAuthentication';
import useApplication from 'hooks/useApplication';

const Profile = ({ org, showManagedBy = true, linkManagedBy = true }) => {
  const {
    application: { isPrivacyMode }
  } = useApplication();
  const { authentication } = useAuthentication();
  const { id, parent, profile: { media: { avatar, banner } = {} } = {} } = org;

  return (
    <ProfileContainer>
      <ProfileContainer.Header avatar={avatar} banner={banner} />
      <ProfileContainer.Body>
        <Row>
          <Col sm={12}>
            <h4 className='mb-1 fs-0'>
              {isPrivacyMode && org?.legacy?.clientTag
                ? org?.legacy?.clientTag
                : org?.name}{' '}
              {id === '648371d0a87d7ad0a0367cfa' ? (
                <FontAwesomeIcon
                  icon={['fas', 'clover']}
                  transform='rotate-45'
                  className='text-primary me-1'
                />
              ) : null}
            </h4>
            {parent && showManagedBy && (
              <Flex direction='row'>
                <Flex direction='column'>
                  {linkManagedBy ? (
                    <Link
                      to={`${routes.ORGANIZATIONS}/${parent?.id}`}
                      className='text-decoration-none'>
                      <span className='text-700 fs--1 align-middle'>
                        <span className=''>Managed by </span>
                        <span className='text-primary'>
                          {parent?.id === '648371d0a87d7ad0a0367cfa' ? (
                            <FontAwesomeIcon
                              icon={['fas', 'clover']}
                              transform='rotate-45'
                              className='text-primary me-1'
                            />
                          ) : null}
                          {isPrivacyMode && parent?.legacy?.clientTag
                            ? parent?.legacy?.clientTag
                            : parent?.name}
                        </span>
                      </span>
                    </Link>
                  ) : (
                    <span className='text-700 fs--1 align-middle'>
                      <span className=''>Managed by </span>
                      <span className='text-700'>
                        {parent?.id === '648371d0a87d7ad0a0367cfa' ? (
                          <FontAwesomeIcon
                            icon={['fas', 'clover']}
                            transform='rotate-45'
                            className='text-primary me-1'
                          />
                        ) : null}
                        {isPrivacyMode && parent?.legacy?.clientTag
                          ? parent?.legacy?.clientTag
                          : parent?.name}
                      </span>
                    </span>
                  )}
                </Flex>
              </Flex>
            )}
            {authentication?.roles?.some(
              role => role.slug === 'super-admin'
            ) && (
              <Flex
                direction='row'
                alignItems='center'
                justifyContent='start'
                className='text-decoration-none mt-1'>
                <Flex
                  direction='row'
                  alignItems='center'
                  justifyContent='start'
                  className='text-decoration-none mt-2 pt-1'>
                  <Button
                    variant='outline-primary'
                    size='sm'
                    className='text-decoration-none me-2 py-0 px-2'
                    as={Link}
                    to={`${routes.ORGANIZATIONS}/${id}`}>
                    View Profile
                  </Button>
                </Flex>
              </Flex>
            )}
          </Col>
        </Row>
      </ProfileContainer.Body>
    </ProfileContainer>
  );
};

Profile.propTypes = {
  org: PropTypes.object,
  showManagedBy: PropTypes.bool,
  linkManagedBy: PropTypes.bool
};

export default Profile;
