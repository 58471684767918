import RecordsLoading from 'components/common/RecordsLoading';
import RecordsNotFound from 'components/common/RecordsNotFound';
import AdvancedTable from 'components/common/table/Table';
import AdvancedTableFooter from 'components/common/table/TableFooter';
import AdvancedTablePagination from 'components/common/table/TablePagination';
import AdvancedTableWrapper from 'components/common/table/TableWrapper';
import { dynamicDashboardRoutes } from 'components/dashboards/Artifacts/Dynamic/routes';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { columnActions } from './columns/columnActions';
import { columnDefaults } from './columns/columnDefaults';
import { getColumns } from './columns/getColumns';
import {
  CreateRouteModal,
  DeleteRouteModal,
  RestoreRouteModal,
  UpdateRouteModal
} from './Modals';
import TableFilters from './TableFilters';

const RoutesComp = () => {
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState('');
  const [routes, setRoutes] = useState([]);
  const [columns, setColumns] = useState([]);
  const [total, setTotal] = useState(0);

  const [showCreateRouteModal, setShowCreateRouteModal] = useState({
    open: false,
    fetch: false
  });

  const [showUpdateModal, setShowUpdateModal] = useState({
    open: false,
    route: {},
    fetch: false
  });

  const [showDeleteRouteModal, setShowDeleteRouteModal] = useState({
    open: false,
    route: {},
    fetch: false
  });

  const [showRestoreRouteModal, setShowRestoreRouteModal] = useState({
    open: false,
    route: {},
    fetch: false
  });

  const [showDeletedRoutes, setShowDeletedRoutes] = useState({
    show: false,
    fetch: false
  });

  const [visibleColumns] = useState([
    'objectId',
    'path',
    'slug',
    'indices',
    'allowedRoles',
    'leargas_actions'
  ]);

  const getRoutes = () => {
    setLoading(true);
    setRoutes(dynamicDashboardRoutes);
    setTotal(dynamicDashboardRoutes.length);
    setShowCreateRouteModal(prevState => ({
      ...prevState,
      fetch: false
    }));
    setShowUpdateModal(prevState => ({
      ...prevState,
      fetch: false
    }));
    setShowDeleteRouteModal(prevState => ({
      ...prevState,
      fetch: false
    }));
    setShowRestoreRouteModal(prevState => ({
      ...prevState,
      fetch: false
    }));
    setShowDeletedRoutes(prevState => ({
      ...prevState,
      fetch: false
    }));
    setLoading(false);
  };

  useEffect(() => {
    getRoutes(showDeletedRoutes.show);
    getColumns(
      setColumns,
      columnDefaults,
      columnActions,
      setShowUpdateModal,
      setShowDeleteRouteModal,
      setShowRestoreRouteModal
    );
  }, [
    showCreateRouteModal.fetch,
    showUpdateModal.fetch,
    showDeleteRouteModal.fetch,
    showRestoreRouteModal.fetch,
    showDeletedRoutes?.fetch
  ]);
  useEffect(() => {
    getRoutes(showDeletedRoutes.show);
    getColumns(
      setColumns,
      columnDefaults,
      columnActions,
      setShowUpdateModal,
      setShowDeleteRouteModal,
      setShowRestoreRouteModal
    );
  }, []);

  return (
    <>
      <Row className='g-3 mb-3'>
        <Col md={12}>
          {loading ? (
            <RecordsLoading />
          ) : total === 0 || routes.length === 0 ? (
            <RecordsNotFound
              setShowCreateModal={setShowCreateRouteModal}
              article='an'
              dataTypeLabel='Route'
              dataTypeLabelPlural='Routes'
            />
          ) : (
            <Card>
              <Card.Body className=' position-relative '>
                <AdvancedTableWrapper
                  columns={columns}
                  data={routes}
                  dataTypeLabel='Routes'
                  setShowCreateModal={setShowCreateRouteModal}
                  sortable
                  pagination
                  perPage={25}
                  loading={loading}>
                  <TableFilters
                    table
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    dataTypeLabel='Route'
                    dataTypeLabelPlural='Routes'
                    showDeleted={showDeletedRoutes}
                    setShowDeleted={setShowDeletedRoutes}
                    setShowCreateModal={setShowCreateRouteModal}
                    hideFiltersToggle
                    hideSettingsToggle
                    hideDeletedToggle
                  />
                  <AdvancedTable table visibleColumns={visibleColumns} />
                  <div className='mt-2 d-flex justify-content-between'>
                    <AdvancedTableFooter
                      table
                      rowCount={total}
                      rowsPerPageOptions={[25, 50, 100]}
                      rowsPerPageSelection
                    />
                    <AdvancedTablePagination table />
                  </div>
                </AdvancedTableWrapper>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>

      <CreateRouteModal
        showCreateRouteModal={showCreateRouteModal}
        setShowCreateRouteModal={setShowCreateRouteModal}
      />
      <UpdateRouteModal
        showUpdateModal={showUpdateModal}
        setShowUpdateModal={setShowUpdateModal}
      />
      <RestoreRouteModal
        showRestoreRouteModal={showRestoreRouteModal}
        setShowRestoreRouteModal={setShowRestoreRouteModal}
      />
      <DeleteRouteModal
        showDeleteRouteModal={showDeleteRouteModal}
        setShowDeleteRouteModal={setShowDeleteRouteModal}
      />
    </>
  );
};

export default RoutesComp;
