import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';
const windows = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'Windows Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['previousCount', 'count', 'sparkline']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          title: 'Events',
          helpInfo: 'Total number of events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_all: {}
          }
        ],
        filter: [
          {
            match_phrase: {
              'winlog.channel.keyword': 'Security'
            }
          },
          {
            match_phrase: {
              'event.action.keyword': 'logon-failed'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          showFilterIcon: true,
          filterData: {
            field: 'event.action',
            value: 'logon-failed'
          },
          title: 'Login Failures',
          helpInfo: 'Total number of events indicating authentication failures'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_all: {}
          }
        ],
        filter: [
          {
            match_phrase: {
              'winlog.task': 'Execute a Remote Command'
            }
          }
        ],
        must_not: [
          {
            match_phrase: {
              'log.level': 'information'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          showFilterIcon: true,
          filterData: {
            field: 'winlog.task',
            value: 'Execute a Remote Command'
          },
          title: 'Remote Code Execution',
          helpInfo: 'Total number of events indicating remote code execution'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_all: {}
          }
        ],
        filter: [
          {
            match_phrase: {
              'log.level.keyword': 'error'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          showFilterIcon: true,
          filterData: {
            field: 'log.level',
            value: 'error'
          },
          title: 'Errors',
          helpInfo: 'Total number of events indicating errors'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['histogram']
      },
      children: {
        component: 'Stat',
        props: {
          header: 'Event Timeline',
          helpInfo:
            'Timeline histogram of all events matching the search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 12, lg: 12, xl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'log.level.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          header: 'Log Levels',
          field: 'log.level'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 12, lg: 12, xl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'winlog.channel.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          header: 'Event Channels',
          field: 'winlog.channel'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 12, lg: 12, xl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'event.action.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          header: 'Event Actions',
          field: 'event.action'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['table'],
        overriddenIndex: ['leargas-winlogbeat*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'event.provider.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Event providers'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        queryType: ['table'],
        overriddenIndex: ['leargas-winlogbeat*'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'event.code',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0,
          showMissingBuckets: false
        }
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          panelProps: {
            queryType: ['table'],
            overriddenIndex: ['leargas-winlogbeat*'],
            tableQuery: {
              aggs: {
                2: {
                  terms: {
                    field: 'host.hostname.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 1000
                  },
                  aggs: {
                    3: {
                      terms: {
                        field: 'winlog.event_data.SourceAddress.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 1000
                      }
                    }
                  }
                }
              },
              size: 0
            }
          },
          HeaderProps: {
            headerText: 'Event Codes'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Event Hosts'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'source.ip.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Event Sources'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'winlog.event_data.DestAddress.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Event Destinations'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'winlog.event_data.TargetUserName.keyword',
                order: {
                  _count: 'desc'
                },
                size: 1000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'host.hostname.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 1000
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Event Targets'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 1000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.ServiceName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 1000
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_all: {}
          }
        ],
        filter: [
          {
            match_phrase: {
              'event.code': '7045'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Services Installed'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'message.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_all: {}
          }
        ],
        filter: [
          {
            match_phrase: {
              'winlog.event_id': '11707'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Applications Installed'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.SubjectUserName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'winlog.event_data.TargetUserName.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 500
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0,
          showMissingBuckets: true
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4799'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Security Group Enumerated'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'agent.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 1000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.DestAddress.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 1000
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '5156'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Windows Filtering Connection Permitted'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4782'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Hashes Accessed'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 12, lg: 12, xl: 4 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 1000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.AccountName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 1000
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4778'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'Remote Desktop Reconnection'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 12, lg: 12, xl: 8 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.SubjectUserName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'winlog.event_data.TargetUserName.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 5
                      },
                      aggs: {
                        5: {
                          terms: {
                            field: 'related.user.keyword',
                            order: {
                              _count: 'desc'
                            },
                            size: 500
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4756'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Universal - Member Added'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 12, lg: 12, xl: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.TargetUserName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'winlog.event_data.PackageName.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 4996
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4776'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'DC Credential Verification'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 12, lg: 12, xl: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.SubjectUserName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'winlog.event_data.TargetUserName.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 4983
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4741'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Account Created'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 1000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.TargetUserName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 1000
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4740'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Account Locked'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'related.user.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'group.name.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 500
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4732'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Account Added - Local Group'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'user.name.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'winlog.event_data.MemberName.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 500
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4728'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Account Added - Global'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.name.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.TargetUserName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'related.user.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 500
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'winlog.event_id': '4726'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Account Deleted'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.SubjectUserName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'related.user.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 500
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4724'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Account Reset'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 1000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.ServiceName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 1000
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4723'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Account Password Change'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'event.provider.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'message.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 500
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '1102'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Audit Log Cleared'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'event.provider.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'message.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 500
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '104'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Event Log Cleared'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 1000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.TaskName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 1000
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4698'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Scheduled Task - Created'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 1000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.TaskName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 1000
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4700'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Scheduled Task - Enabled'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 1000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.TaskName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 1000
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4701'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Scheduled Task - Disabled'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'event.provider.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'message.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 500
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '106'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'User Registered Task'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'event.provider.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'message.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 500
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '140'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePageNumbers: true,
          hidePerPage: true,
          HeaderProps: {
            headerText: 'User Updated Task'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '141'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'User Deleted Scheduled Task'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.SubjectUserName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'winlog.task.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 500
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4672'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Special Privileges'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 1000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'user.name.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 1000
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4625'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Login Failure'
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        overriddenIndex: ['leargas-winlogbeat*'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'host.hostname.keyword',
                order: {
                  _count: 'desc'
                },
                size: 500
              },
              aggs: {
                3: {
                  terms: {
                    field: 'winlog.event_data.TargetUserName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 500
                  },
                  aggs: {
                    4: {
                      terms: {
                        field: 'process.executable.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 500
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        filter: [
          {
            match_phrase: {
              'event.code': '4648'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          perPage: 10,
          hidePerPage: true,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Logon by Explicit Credentials'
          }
        }
      }
    }
  ];
};
export default windows;
