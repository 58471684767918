import LeargasAPI from 'api';
import RecordsLoading from 'components/common/RecordsLoading';
import RecordsNotFound from 'components/common/RecordsNotFound';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import DashboardsTable from './DashboardsTable/DashboardsTable';
import DashboardsTableFilters from './DashboardsTable/DashboardsTableFilters';
import DashboardsTablePagination from './DashboardsTable/DashboardsTablePagination';
import DashboardsTableWrapper from './DashboardsTable/DashboardsTableWrapper';
import {
  CreateDashboardModal,
  DeleteDashboardModal,
  UpdateDashboardModal
} from './Modals';
import { columnActions } from './columns/columnActions';
import { columnDefaults } from './columns/columnDefaults';
import { getColumns } from './columns/getColumns';

import useAxiosPrivate from 'hooks/useAxiosPrivate';

const DashboardsComp = () => {
  const [loading, setLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [dashboards, setDashboards] = useState([]);
  const [columns, setColumns] = useState([]);
  const [total, setTotal] = useState(0);
  const [onlistChange, setOnListChange] = useState(false);

  const { axiosPrivate } = useAxiosPrivate();
  const [visibleColumns] = useState();

  const [showCreateDashboardModal, setShowCreateDashboardModal] = useState({
    open: false,
    fetch: false
  });

  const [showUpdateModal, setShowUpdateModal] = useState({
    open: false,
    dashboard: {},
    fetch: false
  });

  const [showDeleteDashboardModal, setShowDeleteDashboardModal] = useState({
    open: false,
    dashboard: {},
    fetch: false
  });

  const getDashboards = async () => {
    setLoading(true);
    try {
      const dashes = await LeargasAPI.Dashboards.getDashboards(axiosPrivate);

      if (dashes instanceof Error) throw dashes;
      setDashboards([...dashes]);
      setTotal(dashes.length);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setDashboards([]);
      setTotal(0);
    } finally {
      setShowCreateDashboardModal(prevState => ({
        ...prevState,
        fetch: false
      }));
      setShowUpdateModal(prevState => ({
        ...prevState,
        fetch: false
      }));
      setShowDeleteDashboardModal(prevState => ({
        ...prevState,
        fetch: false
      }));

      setOnListChange(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDashboards();
    getColumns(
      setColumns,
      columnDefaults,
      columnActions,
      setShowUpdateModal,
      setShowDeleteDashboardModal,
      setOnListChange,
      axiosPrivate
    );
  }, [
    onlistChange,
    showCreateDashboardModal.fetch,
    showUpdateModal.fetch,
    showDeleteDashboardModal.fetch
  ]);

  useEffect(() => {
    getDashboards();
    getColumns(
      setColumns,
      columnDefaults,
      columnActions,
      setShowUpdateModal,
      setShowDeleteDashboardModal,
      setOnListChange,
      axiosPrivate
    );
  }, []);

  return (
    <>
      <Row className='g-3 mb-3'>
        <Col md={12}>
          {loading || onlistChange ? (
            <RecordsLoading />
          ) : total === 0 || dashboards.length === 0 ? (
            <RecordsNotFound
              setShowCreateModal={setShowCreateDashboardModal}
              article='an'
              dataTypeLabel='Dashboard'
              dataTypeLabelPlural='Dashboards'
            />
          ) : (
            <Card>
              <Card.Body className='position-relative'>
                <DashboardsTableWrapper
                  columns={columns}
                  data={dashboards || []}
                  dataTypeLabel='Dashboard'
                  setShowCreateModal={setShowCreateDashboardModal}
                  sortable
                  pagination
                  selectionColumnWidth={1}
                  perPage={50}
                  loading={loading}>
                  <DashboardsTableFilters
                    table
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    dataTypeLabel='Dashboard'
                    dataTypeLabelPlural='Dashboards'
                    setShowCreateModal={setShowCreateDashboardModal}
                    hideFiltersToggle
                    hideSettingsToggle
                    hideDeletedToggle
                  />
                  <DashboardsTable table visibleColumns={visibleColumns} />
                  <div className='mt-2 d-flex justify-content-end'>
                    <DashboardsTablePagination table />
                  </div>
                </DashboardsTableWrapper>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>

      <CreateDashboardModal
        showCreateDashboardModal={showCreateDashboardModal}
        setShowCreateDashboardModal={setShowCreateDashboardModal}
      />
      <UpdateDashboardModal
        showUpdateModal={showUpdateModal}
        setShowUpdateModal={setShowUpdateModal}
      />

      <DeleteDashboardModal
        showDeleteDashboardModal={showDeleteDashboardModal}
        setShowDeleteDashboardModal={setShowDeleteDashboardModal}
      />
    </>
  );
};

export default DashboardsComp;
