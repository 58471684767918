import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import { useAuthentication } from 'hooks/useAuthentication';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useLogout } from 'hooks/useLogout';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

/**
 * Account session unlock form
 *
 * The account session unlock form is used to display a form to the user to unlock their account session
 * @returns {React.Component} The account session unlock form
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @since 0.1.0-beta.1
 * @version 0.1.0-beta.1
 */
const LockScreenForm = () => {
  const { axiosPrivate } = useAxiosPrivate();
  const focusField = useRef('password');
  const logout = useLogout();

  const {
    authentication: { emails },
    setAuthentication
  } = useAuthentication();

  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  /**
   * Handles the form submission for unlocking the user session
   *
   * @param {Event} e - The form submission event
   * @returns {Promise<void>}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @since 0.1.0-beta.1
   * @version 0.1.0-beta.1
   */
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    let address = emails.find(email => email.isPrimary).address;
    try {
      const response = await LeargasAPI.AccountAuth.userSessionUnlock(
        {
          body: {
            address,
            password: password
          }
        },
        axiosPrivate
      );
      if (response instanceof Error) throw response;
      let message = response?.data?.message;
      if (message) toast.success(message);
      setAuthentication(prevState => ({
        ...prevState,
        ...response.data.data.authentication,
        persistLoader: true
      }));
      setPassword('');
      setLoading(false);
      setTimeout(function () {
        setAuthentication(prevState => ({
          ...prevState,
          persistLoader: false
        }));
      }, 1000);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      let message = error?.response?.data?.message;
      if (message) toast.error(message);

      setTimeout(function () {
        if (focusField.current) focusField.current.focus();
        setPassword('');
        setLoading(false);
      }, 1000);
    } finally {
      setAuthentication(prevState => ({
        ...prevState,
        intercepted: false
      }));
    }
  };

  useEffect(() => {
    setAuthentication(prevState => ({
      ...prevState,
      intercepted: true
    }));
  }, []);

  useEffect(() => {
    if (focusField.current) focusField.current.focus();
  }, [focusField.current]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className='g-2 flex-center mb-3 position-relative'>
          <Col xs={8}>
            <Row className='g-2 mb-3'>
              <Col>
                <Form.Control
                  size='sm'
                  name='password'
                  ref={focusField}
                  type='password'
                  value={password}
                  placeholder='Enter your password'
                  onChange={({ target }) => setPassword(target.value)}
                  className='fs--1'
                />
              </Col>
            </Row>
            <Row className='g-2'>
              <Col xs={6}>
                <Button
                  size='sm'
                  variant='outline-secondary'
                  className='w-100'
                  onClick={() => logout(axiosPrivate)}>
                  Log Out
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  size='sm'
                  type='submit'
                  variant='outline-primary'
                  className='w-100'>
                  <div className='position-relative'>
                    <Flex justifyContent={'center'} alignItems={'center'}>
                      <ButtonSpinner spinning={loading} />
                      <span>{loading ? 'Unlocking' : 'Unlock'}</span>
                    </Flex>
                  </div>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

LockScreenForm.propTypes = {
  type: PropTypes.oneOf(['simple', 'split', 'card'])
};

export default LockScreenForm;
