import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Offcanvas, ButtonGroup, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import useApplication from 'hooks/useApplication';
// import useAuthentication from 'hooks/useAuthentication';

const SettingsPanel = () => {
  // const {
  //   // authentication: { roles },
  //   // setAuthentication
  // } = useAuthentication();
  const {
    application: { isRTL, isDark, showDevSettingsPanel, showDevPages },
    setApplication
  } = useApplication();

  // Handle role change
  // const handleRoleChange = value => {
  //   setAuthentication(prevState => ({
  //     ...prevState
  //   }));
  // };

  return (
    <Offcanvas
      show={showDevSettingsPanel}
      onHide={() => setApplication('showDevSettingsPanel', false)}
      placement='end'
      className='border-0'>
      <Offcanvas.Header closeButton closeVariant='black' className='bg-warning'>
        <Offcanvas.Title as='div' className='py-0 flex-grow-1 light mt-1'>
          <h5 className='text-dark fw-bold'>
            <FontAwesomeIcon icon='cog' className='me-2 fs-0' />
            Developer Settings
          </h5>
        </Offcanvas.Title>
      </Offcanvas.Header>
      {/* <ScrollBarCustom> */}
      <Offcanvas.Body className='scrollbar'>
        {/* <h5 className='fs-0'>View as Role</h5>

        <ButtonGroup className='btn-group-navbar-style mb-0 pb-0'>
          {Object.entries(systemRoles).map(([key, val], index) => {
            return (
              <Form.Check
                key={index}
                type='radio'
                name='role-mode'
                label={val.name}
                value={key}
                checked={}
                onChange={() => handleRoleChange(val)}
                className='mb-0 me-2'
              />
            );
          })}
        </ButtonGroup>
        <hr /> */}
        <h5 className='fs-0'>View as User</h5>

        <Form.Select
          name='role-mode'
          label='Super Admin'
          checked
          className='mb-4'
          onChange={({ target }) => setApplication('isDark', !target.checked)}>
          <option>Select a User...</option>
        </Form.Select>
        <hr />
        <h5 className='fs-0'>Color Scheme</h5>

        <ButtonGroup className='btn-group-navbar-style mb-0 pb-0'>
          <Form.Check
            type='radio'
            name='theme-mode'
            label='Light'
            checked={!isDark}
            className='mb-0'
            onChange={({ target }) => setApplication('isDark', !target.checked)}
          />
          <Form.Check
            type='radio'
            name='theme-mode'
            label='Dark'
            checked={isDark}
            className='ms-2 mb-0'
            onChange={({ target }) => setApplication('isDark', target.checked)}
          />
        </ButtonGroup>
        <hr />

        <Flex justifyContent='between' className='pb-3'>
          <div className='flex-1'>
            <h5 className='fs-0'>RTL Mode</h5>
            <p className='fs--1 mb-0'>Toggle language direction right/left</p>
          </div>
          <Form.Check
            type='switch'
            id='rtl-switch'
            checked={isRTL}
            onChange={({ target }) => setApplication('isRTL', target.checked)}
          />
        </Flex>
        <hr />

        <Flex justifyContent='between' className='pb-3'>
          <div className='flex-1'>
            <h5 className='fs-0'>Show Development Pages</h5>
            <p className='fs--1 mb-0'>
              Toggle navigation to pages in development
            </p>
          </div>
          <Form.Check
            type='switch'
            id='dev-pages-switch'
            checked={showDevPages}
            onChange={({ target }) =>
              setApplication('showDevPages', target.checked)
            }
          />
        </Flex>
        <hr />

        <Flex justifyContent='between' className='pb-3'>
          <div className='flex-1'>
            <h5 className='fs-0'>Debug Mode</h5>
            <p className='fs--1 mb-0'>Toggle debug mode on/off</p>
          </div>
          <Form.Check
            type='switch'
            id='rtl-switch'
            checked={isRTL}
            onChange={({ target }) => setApplication('isRTL', target.checked)}
          />
        </Flex>
        <hr />
      </Offcanvas.Body>
      {/* </ScrollBarCustom> */}
    </Offcanvas>
  );
};

export default SettingsPanel;
