import React from 'react';
import Organizations from './components/Organizations';
import { Col, Row } from 'react-bootstrap';

/**
 * Organizations Layout
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const OrganizationsLayout = () => {
  return (
    <>
      <Row className='g-2 mb-2'>
        <Col sm={12}>
          <Organizations />
        </Col>
      </Row>
    </>
  );
};

export default OrganizationsLayout;
