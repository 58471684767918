import { getItemFromStore, setItemToStore } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

/**
 * The authentication settings object.
 *
 * @property {string|null} accessToken - The access token
 * @property {string[]} emails - The list of emails
 * @property {string|null} id - The ID
 * @property {Date|null} idleTimeoutAt - The idle timeout
 * @property {boolean} isAdmin - Indicates if the user is an admin
 * @property {boolean} persist - Indicates if the session should persist
 * @property {boolean} persistLoader - Indicates if the loader should persist
 * @property {boolean} refreshing - Indicates if the settings are being refreshed
 * @property {string[]} roles - The list of roles
 * @property {string[]} scopes - The list of scopes
 * @property {Object} security - The security settings
 * @property {Object} session - The session settings
 * @property {boolean} showIdleModal - Indicates if the idle modal should be shown
 * @property {string} stage - The authentication stage

 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.1
 */
export const authenticationSettings = {
  accessToken: null,
  emails: [],
  id: '',
  idleTimeoutAt: null,
  isAdmin: false,
  persist: true,
  persistLoader: true,
  refreshing: false,
  roles: [],
  scopes: [],
  scopesSelected: [],
  security: {},
  session: {},
  showIdleModal: false,
  stage: 'authenticated'
};

/**
 * Create AuthenticationContext object.
 *
 * @type {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
export const AuthenticationContext = createContext(authenticationSettings);

/**
 * AuthenticationProvider component that provides authentication context to its children
 *
 * @component
 * @param {Object} props - The component props
 * @returns {JSX.Element} The rendered AuthenticationProvider component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.1
 */
const AuthenticationProvider = props => {
  let scopesSelected = getItemFromStore('scopesSelected');
  if (!scopesSelected) {
    setItemToStore('scopesSelected', JSON.stringify([]));
    scopesSelected = [];
  }

  const authenticationState = {
    ...authenticationSettings,
    scopesSelected: Array.isArray(JSON.parse(JSON.stringify(scopesSelected)))
      ? JSON.parse(JSON.stringify(scopesSelected))
      : []
  };
  const [authentication, setAuthentication] = useState(authenticationState);
  const [persist, setPersist] = useState(getItemFromStore('persist') || false);

  /**
   * Set isAdmin flag if user has admin or super-admin role.
   * This effect will run when the authentication.roles array changes.
   */
  useEffect(() => {
    // If roles is not an array or is empty, return early
    if (Array.isArray(authentication.roles)) {
      // Check if user has admin or super-admin role
      const hasMatchingRole = authentication.roles.some(role =>
        ['admin', 'super-admin'].includes(role.slug)
      );
      setAuthentication(prevState => ({
        ...prevState,
        isAdmin: hasMatchingRole
      }));
    }
  }, [authentication.roles]);

  /**
   * Parses stored scopes and returns them as an array.
   *
   * @param {string} storedScopes - The stored scopes string
   * @returns {Array} The parsed scopes array
   */
  const parseStoredScopes = storedScopes => {
    return storedScopes &&
      Array.isArray(JSON.parse(JSON.stringify(storedScopes)))
      ? JSON.parse(JSON.stringify(storedScopes))
      : [];
  };

  /**
   * Set the selected scopes.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    const storedScopesSelected = getItemFromStore('scopesSelected');
    const parsedStoredScopes = parseStoredScopes(storedScopesSelected);

    setAuthentication(prevState => {
      // If parsedStoredScopes is empty or undefined, set scopesSelected based on available scopes
      if (parsedStoredScopes && parsedStoredScopes.length > 0) {
        return { ...prevState, scopesSelected: parsedStoredScopes };
      } else {
        return { ...prevState, scopesSelected: prevState.scopes };
      }
    });
  }, [authentication.scopes]);

  /**
   * Set the selected scopes to the store when the scopesSelected change.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    // Synchronize scopesSelected with local storage
    const scopesSelectedString = JSON.stringify(authentication.scopesSelected);
    const currentStorage = getItemFromStore('scopesSelected');

    if (currentStorage !== scopesSelectedString) {
      setItemToStore('scopesSelected', scopesSelectedString);
    }
  }, [authentication.scopesSelected]);

  /**
   * Represents the values object in the AuthenticationProvider context.
   *
   * @property {any} authentication - The authentication value
   * @property {any} persist - The persist value
   * @property {Function} setAuthentication - Function to set auth value
   * @property {Function} setPersist - The function to set the persist value
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const c = {
    authentication,
    persist,
    setAuthentication,
    setPersist
  };

  return (
    <AuthenticationContext.Provider value={c}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};

AuthenticationProvider.propTypes = {
  children: PropTypes.node
};

export default AuthenticationProvider;
