import { makeRequest } from 'api/connection/requestAction';

class AppTeams {
  constructor(route) {
    this.route = route;
  }

  async createTeam(teamData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.createTeam.method,
        this.route.createTeam.path,
        teamData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getTeams(axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getTeams.method,
        this.route.getTeams.path
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getTeam(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getTeam.method,
        [this.route.getTeam.path, id].join('/')
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async updateTeam(id, teamData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateTeam.method,
        [this.route.updateTeam.path, id].join('/'),
        teamData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async deleteTeam(id, deleting, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.deleteTeam.method,
        [this.route.deleteTeam.path, id].join('/'),
        {
          deleting
        }
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default AppTeams;
