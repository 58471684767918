import classNames from 'classnames';
import { getColor, rgbaColor } from 'helpers/utils';
import { useCluster } from 'hooks/admin-contexts/useCluster';
import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Card, Col, Row, Spinner } from 'react-bootstrap';
import { useCountUp } from 'react-countup';
import ArchSplitLineChart from './ArchSplitLineChart';

const IndicesCount = () => {
  const {
    cluster: {
      clusterHealth: { indices, status }
    }
  } = useCluster();
  const [numberOfIndicesCounter, setNumberOfIndicesCounter] = useState(0);
  const [greenIndicesCounter, setGreenIndicesCounter] = useState(0);
  const [yellowIndicesCounter, setYellowIndicesCounter] = useState(0);
  const [redIndicesCounter, setRedIndicesCounter] = useState(0);
  const [unknownIndicesCounter, setUnknownIndicesCounter] = useState(0);
  const [barColor, setBarColor] = useState('secondary');

  useEffect(() => {
    if (redIndicesCounter > 0 && status === 'red') {
      setBarColor('danger');
    } else if (yellowIndicesCounter > 0 && status === 'red') {
      setBarColor('danger');
    } else if (unknownIndicesCounter > 0 && status === 'red') {
      setBarColor('danger');
    } else if (redIndicesCounter > 0 && status === 'yellow') {
      setBarColor('warning');
    } else if (yellowIndicesCounter > 0 && status === 'yellow') {
      setBarColor('warning');
    } else if (unknownIndicesCounter > 0 && status === 'yellow') {
      setBarColor('warning');
    } else {
      setBarColor('success');
    }
  }, [
    numberOfIndicesCounter,
    greenIndicesCounter,
    yellowIndicesCounter,
    redIndicesCounter,
    unknownIndicesCounter
  ]);

  const memoizedCallback = useCallback(value => {
    return value.toLocaleString();
  }, []);

  const { update } = useCountUp({
    ref: 'numberOfIndicesCounter',
    decimals: 0,
    duration: 2.75,
    end: numberOfIndicesCounter || 0,
    formattingFn: memoizedCallback,
    preserveValue: true
  });

  const getIndicesChartData = () => {
    if (indices) {
      const indicesList = Object(Object.keys(indices).map(key => indices[key]));
      let redIndices = 0;
      let yellowIndices = 0;
      let greenIndices = 0;
      let unknownIndices = 0;
      let rolePaths = 'status'.split('.');
      indicesList.forEach(item => {
        const status = rolePaths.reduce(
          (acc, cur) => (acc[cur] ? acc[cur] : ''),
          item
        );
        status === 'red'
          ? redIndices++
          : status === 'yellow'
          ? yellowIndices++
          : status === 'green'
          ? greenIndices++
          : status === 'unknown'
          ? unknownIndices++
          : null;
      });
      setGreenIndicesCounter(greenIndices);
      setYellowIndicesCounter(yellowIndices);
      setRedIndicesCounter(redIndices);
      setUnknownIndicesCounter(unknownIndices);
    }
  };

  useEffect(() => {
    getIndicesChartData();
  }, [indices]);

  useEffect(() => {
    if (indices) {
      setNumberOfIndicesCounter(Object.keys(indices).length);
      update(Object.keys(indices).length);
      getIndicesChartData();
    }
  }, [indices]);

  return (
    <Card
      className={`h-100 shadow-none border status-card ${
        !['secondary', 'success'].includes(barColor) ? barColor : null
      }`}>
      {!indices ? (
        <div className='position-absolute d-flex justify-content-center align-items-center h-100 w-100 navbar-glass-loader'>
          <Spinner animation='border' />
        </div>
      ) : null}
      <Card.Body className='p-2'>
        <Row className='g-0'>
          <Col
            xs={5}
            className='d-flex flex-column h-100 justify-content-between'>
            <h6 className='mb-2 text-900 text-nowrap'>Indices</h6>
            <h4 className='fs-2 fw-normal mb-0 position-relative'>
              <span
                id='numberOfIndicesCounter'
                className='w-100 font-monospace'
              />
              {indices ? (
                <Badge
                  pill
                  className={classNames(
                    'bg-soft-success align-top mt-1 fs--1 fw-normal text-success',
                    {
                      'bg-soft-danger': redIndicesCounter > 0,
                      'bg-soft-warning': yellowIndicesCounter > 0,
                      'bg-soft-success': greenIndicesCounter > 0
                    },
                    {
                      'text-danger': redIndicesCounter > 0,
                      'text-warning': yellowIndicesCounter > 0,
                      'text-success': greenIndicesCounter > 0
                    }
                  )}>
                  {redIndicesCounter > 0
                    ? redIndicesCounter + ' Red'
                    : yellowIndicesCounter > 0
                    ? yellowIndicesCounter + ' Yellow'
                    : unknownIndicesCounter > 0
                    ? unknownIndicesCounter + ' Unknown'
                    : 'Healthy'}
                </Badge>
              ) : null}
            </h4>
            <span></span>
          </Col>
          <Col xs='7' className='h-100'>
            <ArchSplitLineChart
              data={{
                labels: ['Green', 'Yellow', 'Red', 'Unknown'],
                values: [
                  greenIndicesCounter,
                  yellowIndicesCounter,
                  redIndicesCounter,
                  unknownIndicesCounter
                ],
                backgroundColors: [
                  rgbaColor('#91CC75', 0.8),
                  rgbaColor('#FF9F40', 0.8),
                  rgbaColor('#FF6384', 0.8),
                  rgbaColor(getColor('secondary'), 0.8)
                ],
                borderColors: [
                  rgbaColor('#91CC75', 0),
                  rgbaColor('#FF9F40', 0),
                  rgbaColor('#FF6384', 0),
                  rgbaColor(getColor('secondary'), 0)
                ]
              }}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default IndicesCount;
