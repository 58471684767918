import React from 'react';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import JobsWrapper from './JobsWrapper';

/**
 * Renders the Application Jobs page
 * @returns {JSX.Element} The rendered Application Jobs page
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.4
 * @since 0.1.0-beta.4
 */
const ApplictionJobsPage = () => {
  return (
    <>
      <WidgetSectionTitle title='Job Management' className='mb-2' />
      <JobsWrapper />
    </>
  );
};

export default ApplictionJobsPage;
