import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import useApplication from 'hooks/useApplication';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**
 * Renders a card component used on interim pages to navigate to other pages.
 *
 * @param {Object} props - The component props
 * @param {Object} props.route - Object containing information about the route
 * @param {number} props.index - The index of the card
 * @returns {JSX.Element} The rendered navigation card component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.4
 */
const NavigationCard = ({ route, index }) => {
  const {
    application: { isDark = false, showDevPages = false }
  } = useApplication();

  let imageDark, imageLight, icon;
  if (route.imageLight && route.imageDark) {
    imageLight = route.imageLight;
    imageDark = route.imageDark;
  }
  if (icon) {
    icon = route.icon;
  }

  return (!showDevPages && !route.development) || showDevPages ? (
    <Col sm={6} md={6} xxl={3} key={index}>
      <Card className='h-100 shadow-none border border-card'>
        <Card.Body
          as={Link}
          to={route.to}
          className={classNames('cursor-pointer text-decoration-none p-2', {
            'hover-bg-200': isDark,
            'hover-bg-100': !isDark
          })}>
          <Row>
            <Col xs={8}>
              <Card.Title as='h6' className='fs-0 fw-normal'>
                {route.name}{' '}
                {route.development && (
                  <FontAwesomeIcon
                    icon='wrench'
                    transform='grow-4 flip-h rotate-22'
                    title='In Development'
                    className='text-warning fs--2 ms-2 menu-dev-indicator'
                  />
                )}
              </Card.Title>
              <p className='fs--1 text-700'>{route.description}</p>
            </Col>
            <Col xs={4} className='text-end'>
              {imageLight && imageDark ? (
                <Image
                  src={isDark ? imageDark : imageLight}
                  width='100%'
                  className='text-end'
                  style={{ maxWidth: '75px' }}
                />
              ) : (
                icon && (
                  <FontAwesomeIcon
                    icon={icon}
                    size='4x'
                    width='100%'
                    className='text-end'
                  />
                )
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  ) : null;
};

NavigationCard.propTypes = {
  route: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default NavigationCard;
