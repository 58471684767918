import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import ReleasesProvider from '../../../../../../context/admin-contexts/ReleasesProvider';
import ReleasesLayout from './ReleasesLayout';
import ModalReleaseAdd from './modals/ModalReleaseAdd';
import ModalReleaseDelete from './modals/ModalReleaseDelete';
import ModalReleaseEdit from './modals/ModalReleaseEdit';
import ModalReleaseNoteAdd from './modals/ModalReleaseNoteAdd';
import ModalReleaseNoteDelete from './modals/ModalReleaseNoteDelete';
import ModalReleaseNoteEdit from './modals/ModalReleaseNoteEdit';

/**
 * Renders a wrapper component for managing releases
 *
 * @param {Object} props - The component props
 * @returns {JSX.Element} The rendered ReleasesWrapper component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ReleasesWrapper = props => {
  return (
    <ReleasesProvider>
      <Card className='h-100 py-2 pt-0 recent-activity-card shadow-none border'>
        <Card.Header className='bg-light'>
          <Flex justifyContent='between' alignItems='center'>
            <span className='d-block'>Releases</span>
          </Flex>
        </Card.Header>
        <Card.Body>
          {props.children}
          <ReleasesLayout />
        </Card.Body>
      </Card>
      <ModalReleaseAdd />
      <ModalReleaseEdit />
      <ModalReleaseDelete />
      <ModalReleaseNoteAdd />
      <ModalReleaseNoteEdit />
      <ModalReleaseNoteDelete />
    </ReleasesProvider>
  );
};

ReleasesWrapper.propTypes = {
  children: PropTypes.node
};

export default ReleasesWrapper;
