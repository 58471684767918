import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const LoadingSpinner = ({ grow = '3', color = 'primary', className }) => (
  <Flex justifyContent='around' alignItems='center'>
    <FontAwesomeIcon
      icon={['fas', 'clover']}
      className={classNames(`text-${color}`, className)}
      transform={`grow-${grow}`}
      spin
      fade
    />
  </Flex>
);

LoadingSpinner.propTypes = {
  grow: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string
};

export default LoadingSpinner;
