import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

/**
 * ScrollToTopButton component.
 *
 * @returns {JSX.Element} The ScrollToTopButton component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  /**
   * Toggles the visibility of the scroll-to-top button based on the scroll position.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  /**
   * Scrolls the window to the top of the page.
   *
   * @function scrollToTop
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  };

  /**
   * Adds an event listener to the window object to listen for scroll events.
   * Removes the event listener when the component is unmounted.
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  return (
    <Button
      variant='secondary'
      style={{
        display: visible ? 'flex' : 'none',
        zIndex: 2,
        position: 'fixed',
        bottom: '20px',
        right: '15px'
      }}
      onClick={scrollToTop}
      className='p-2 border-0 rounded-circle hover-bg-primary hover-100'>
      <FontAwesomeIcon icon='angle-up' />
    </Button>
  );
};

export default ScrollToTopButton;
