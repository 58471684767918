import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

const Notification = ({ createdAt, className, statusMessage }) => {
  return (
    <Link className={classNames('notification', className)} to='#!'>
      <div className='notification-body'>
        <p className='mb-1'>{statusMessage}</p>
        <span className='notification-time'>
          {createdAt && <Moment fromNow>{createdAt}</Moment>}
        </span>
      </div>
    </Link>
  );
};

Notification.propTypes = {
  createdAt: PropTypes.string,
  className: PropTypes.string,
  statusMessage: PropTypes.string
};

export default Notification;
