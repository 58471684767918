import authBg from 'assets/img/auth-bg-01.jpg';
import fogOne from 'assets/img/fog-layer-one.png';
import fogTwo from 'assets/img/fog-layer-two.png';
import leargasClover from 'assets/img/leargas-clover.png';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import Footer from 'components/common/Footer';
import LogoNoLink from 'components/common/LogoNoLink';
import React from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import CookieConsent from 'react-cookie-consent';
import { Outlet } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

/**
 * AuthenticationLayout component.
 *
 * @returns {JSX.Element} The rendered AuthenticationLayout component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
const AuthenticationLayout = () => {
  return (
    <div className='container-fluid position-absolute w-100 auth-layout'>
      <Background
        image={authBg}
        position='center'
        className='fade-in opacity-25'
      />
      <div className='fog-container all-0'>
        <Image
          src={fogOne}
          className='fog-layer first-layer'
          alt='Fog Layer 1'
        />
        <Image
          src={fogTwo}
          className='fog-layer second-layer'
          alt='Fog Layer 2'
        />
      </div>

      <Row className='flex-center min-vh-100 py-4 position-relative'>
        <Col sm={6} className='col-xxl-4 pt-3'>
          <Card className='mb-5 shadow-none login-box bg-card border fade-in'>
            <Card.Body className='p-4'>
              <LogoNoLink />
              <Outlet />
            </Card.Body>
          </Card>
        </Col>
        <Footer />
      </Row>
      <ToastContainer
        icon={true}
        autoClose={2000}
        theme={'colored'}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        maxToasts={1}
        draggable={true}
        pauseOnHover={true}
        position={toast.POSITION.BOTTOM_CENTER}
      />
      <CookieConsent
        location='bottom'
        buttonText='I understand'
        cookieName='consent'
        style={{ background: 'transparent', padding: 0, zIndex: '999999' }}
        containerClasses='bg-dark'
        contentStyle={{ margin: 0 }}
        buttonWrapperClasses='p-4 m-0'
        buttonClasses='btn btn-sm bg-warning m-0'
        expires={365}>
        <Flex alignItems='center' className='bg-dark p-4'>
          <span>
            <Image
              src={leargasClover}
              width='50'
              height='50'
              className='me-2'
            />
          </span>
          <span className='fs--1 text-100'>
            <strong>
              Léargas uses cookies on this website to customize and enhance your
              experience.
            </strong>
            <br />
            By continuing, you agree to the use of these cookies. For more
            information about how we handle and protect your personal data,
            please read our &nbsp;
            <a
              href='https://www.leargassecurity.com/privacy-leargas-security/'
              rel='noreferrer'
              target='_blank'>
              Privacy Policy
            </a>
            .
          </span>
        </Flex>
      </CookieConsent>

      <div className='firefly'></div>
      <div className='firefly'></div>
      <div className='firefly'></div>
      <div className='firefly'></div>
      <div className='firefly'></div>
      <div className='firefly'></div>
      <div className='firefly'></div>
      <div className='firefly'></div>
      <div className='firefly'></div>
      <div className='firefly'></div>
      <div className='firefly'></div>
      <div className='firefly'></div>
      <div className='firefly'></div>
      <div className='firefly'></div>
      <div className='firefly'></div>
    </div>
  );
};

export default AuthenticationLayout;
