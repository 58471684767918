/* eslint-disable react/prop-types */
import IndeterminateCheckbox from 'components/common/IndeterminateCheckbox';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';

/**
 * ReportsTableWrapper is a memoized React component that wraps a table with various features such as sorting, pagination, selection, and filtering.
 * It uses the `useTable` hook from `react-table` to manage table state and behavior.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The child components to be rendered inside the wrapper.
 * @param {Array} props.columns - The column definitions for the table.
 * @param {Array} props.data - The data to be displayed in the table.
 * @param {boolean} props.sortable - Whether the table columns are sortable.
 * @param {boolean} props.selection - Whether row selection is enabled.
 * @param {number} props.selectionColumnWidth - The width of the selection column.
 * @param {boolean} props.pagination - Whether pagination is enabled.
 * @param {number} [props.perPage=10] - The number of rows per page when pagination is enabled.
 *
 * @returns {React.Element} The rendered component.
 */
const ReportsTableWrapper = React.memo(
  ({
    children,
    columns,
    data,
    sortable,
    selection,
    selectionColumnWidth,
    pagination,
    perPage = 10
  }) => {
    const memoizedColumns = useMemo(() => columns, [columns]);
    const memoizedData = useMemo(() => data, [data]);

    const tableInstance = useTable(
      {
        columns: memoizedColumns,
        data: memoizedData,
        autoResetFilters: false,
        autoResetSortBy: false,
        autoResetSelectedRows: false,
        autoResetGlobalFilter: false,
        autoResetPage: false,
        disableSortBy: !sortable,
        initialState: { pageSize: pagination ? perPage : data?.length }
      },
      useGlobalFilter,
      useFilters,
      useSortBy,
      usePagination,
      useRowSelect,
      hooks => {
        if (selection) {
          hooks.visibleColumns.push(columns => [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              ),
              headerProps: {
                style: {
                  maxWidth: selectionColumnWidth
                }
              },
              cellProps: {
                style: {
                  maxWidth: selectionColumnWidth
                }
              },
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              )
            },
            ...columns
          ]);
        }
      }
    );

    /**
     * Recursively maps over React children elements and clones them with additional props.
     *
     * - If a child element has children, it recursively maps over those children.
     * - If a child element has a `table` prop, it clones the element with additional table-related props.
     * - Otherwise, it returns the child element as is.
     *
     * @param {React.ReactNode} children - The children elements to be recursively mapped.
     * @returns {React.ReactNode} - The new children elements with additional props.
     */
    const recursiveMap = useCallback(
      children => {
        return React.Children.map(children, child => {
          if (React.isValidElement(child) && child.props?.children) {
            return React.cloneElement(child, {
              children: recursiveMap(child.props.children)
            });
          } else if (React.isValidElement(child) && child.props?.table) {
            return React.cloneElement(child, {
              ...child.props,
              getTableProps: tableInstance.getTableProps,
              headers: tableInstance.headers,
              page: tableInstance.page,
              data: memoizedData,
              prepareRow: tableInstance.prepareRow,
              canPreviousPage: tableInstance.canPreviousPage,
              canNextPage: tableInstance.canNextPage,
              nextPage: tableInstance.nextPage,
              previousPage: tableInstance.previousPage,
              gotoPage: tableInstance.gotoPage,
              pageCount: tableInstance.pageCount,
              pageIndex: tableInstance.state.pageIndex,
              selectedRowIds: tableInstance.state.selectedRowIds,
              pageSize: tableInstance.state.pageSize,
              setPageSize: tableInstance.setPageSize,
              globalFilter: tableInstance.state.globalFilter,
              setGlobalFilter: tableInstance.setGlobalFilter
            });
          } else {
            return child; // Return the child instead of null
          }
        });
      },
      [
        tableInstance.getTableProps,
        tableInstance.headers,
        tableInstance.page,
        memoizedData,
        tableInstance.prepareRow,
        tableInstance.canPreviousPage,
        tableInstance.canNextPage,
        tableInstance.nextPage,
        tableInstance.previousPage,
        tableInstance.gotoPage,
        tableInstance.pageCount,
        tableInstance.state.pageIndex,
        tableInstance.state.selectedRowIds,
        tableInstance.state.pageSize,
        tableInstance.setPageSize,
        tableInstance.state.globalFilter,
        tableInstance.setGlobalFilter
      ]
    );

    return <>{recursiveMap(children)}</>;
  },
  (prevProps, nextProps) => {
    // Custom comparison to prevent unnecessary re-renders
    return (
      prevProps.data === nextProps.data &&
      prevProps.columns === nextProps.columns &&
      prevProps.sortable === nextProps.sortable &&
      prevProps.selection === nextProps.selection &&
      prevProps.pagination === nextProps.pagination
    );
  }
);

ReportsTableWrapper.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.array,
  data: PropTypes.array,
  sortable: PropTypes.bool,
  selection: PropTypes.bool,
  selectionColumnWidth: PropTypes.number,
  pagination: PropTypes.bool,
  perPage: PropTypes.number
};

export default ReportsTableWrapper;
