import { makeRequest } from 'api/connection/requestAction';

class AppSavedSearches {
  constructor(route) {
    this.route = route;
  }

  async createSavedSearches(savedSearchesData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.createSavedSearches.method,
        this.route.createSavedSearches.path,
        savedSearchesData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getAllSavedSearches(axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getAllSavedSearches.method,
        this.route.getAllSavedSearches.path
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
  async getSavedSearchById(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.getSavedSearchById.method,
        [this.route.getSavedSearchById.path, id].join('/')
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async updateSavedSearches(id, savedSearchesData, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.updateSavedSearches.method,
        [this.route.updateSavedSearches.path, id].join('/'),
        savedSearchesData
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }

  async deleteSavedSearches(id, axiosPrivate) {
    try {
      const res = await makeRequest(
        axiosPrivate,
        this.route.deleteSavedSearches.method,
        [this.route.deleteSavedSearches.path, id].join('/')
      );
      if (res instanceof Error) throw res;
      return res;
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      return error;
    }
  }
}

export default AppSavedSearches;
