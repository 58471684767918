import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useReleases from 'hooks/admin-contexts/useReleases';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

/**
 * Renders the ModalReleaseDelete component
 *
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ModalReleaseNoteDelete = () => {
  const { deleteReleaseNoteModal, setReleases } = useReleases();
  const [loading, setLoading] = useState(false);
  const { axiosPrivate } = useAxiosPrivate();
  /**
   * Handle deleting a release note
   * @param {string} id The id of the release note to delete
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const handleDelete = async id => {
    setLoading(true);
    try {
      const res = await LeargasAPI.Releases.deleteAppReleaseNote(
        id,
        axiosPrivate
      );
      if (res instanceof Error) throw res;
      setReleases(prevState => ({
        ...prevState,
        deleteReleaseNoteModal: { open: false, data: {} },
        fetch: true
      }));
      toast.success(res?.message);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setReleases(prevState => ({
        ...prevState,
        fetch: false
      }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      centered
      size='md'
      animation={false}
      backdrop='static'
      keyboard={false}
      show={deleteReleaseNoteModal?.open}
      onHide={() =>
        setReleases(prevState => ({
          ...prevState,
          deleteReleaseNoteModal: { open: false }
        }))
      }
      aria-labelledby='modalReleaseNoteDelete'>
      <Modal.Body className='text-center p-2'>
        Are you sure you want to delete this release note?
        <Flex justifyContent='center' className='mt-3'>
          <Button
            variant='outline-secondary'
            size='sm'
            onClick={() =>
              setReleases(prevState => ({
                ...prevState,
                deleteReleaseNoteModal: { open: false }
              }))
            }>
            Cancel
          </Button>
          <Button
            variant={loading ? 'outline-secondary' : 'danger'}
            size='sm'
            disabled={loading || !deleteReleaseNoteModal.data?.id}
            className='ms-2'
            onClick={() => handleDelete(deleteReleaseNoteModal.data?.id)}>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <ButtonSpinner spinning={loading} />
              <span>{loading ? 'Confirming' : 'Confirm'}</span>
            </Flex>
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

export default ModalReleaseNoteDelete;
