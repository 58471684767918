import Flex from 'components/common/Flex';
import LoadingSpinner from 'components/utilities/AppSpinner/LoadingSpinner';
import useUsers from 'hooks/admin-contexts/useUsers';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import AddButton from './AddButton';
import { getUserColumns } from './Columns/ColumnsDefaults';
import ShowDeletedButton from './ShowDeletedButton';
import UsersTable from './UsersTable';
import UsersTableFilters from './UsersTableFilters';
import UsersTableFooter from './UsersTableFooter';
import UsersTablePagination from './UsersTablePagination';
import UsersTableWrapper from './UsersTableWrapper';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const Users = () => {
  const {
    users: { showDeleted, data },
    loading: componentLoading,
    setUsers
  } = useUsers();

  const [globalFilter, setGlobalFilter] = useState('');
  const [columns, setColumns] = useState([]);

  /**
   *   set Columns for table
   */
  useEffect(() => {
    setColumns(getUserColumns(setUsers));
  }, []);

  return (
    <Card className='shadow-none border'>
      <Card.Header className='bg-light'>
        <Flex justifyContent='between' alignItems='center'>
          <span className='d-block'>Users</span>
          <Flex justifyContent='end' alignItems='center'>
            <ShowDeletedButton
              showDeleted={showDeleted}
              onClick={() => {
                setUsers(prevState => ({
                  ...prevState,
                  showDeleted: !showDeleted
                }));
              }}
            />
            <AddButton
              onClick={() =>
                setUsers(prevState => ({
                  ...prevState,
                  showCreateUserModal: { open: true }
                }))
              }
            />
          </Flex>
        </Flex>
      </Card.Header>
      <Card.Body className='position-relative'>
        {componentLoading ? (
          <LoadingSpinner grow='10' />
        ) : (
          <UsersTableWrapper
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            // data={users}
            data={
              showDeleted
                ? data
                : data?.filter(user => !user?.deleted?.isDeleted)
            }
            teams
            sortable
            loading={componentLoading}
            pagination
            perPage={50}>
            <UsersTableFilters
              table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <UsersTable table columns={columns} />
            <AddButton
              label='Add a user'
              onClick={() =>
                setUsers(prevState => ({
                  ...prevState,
                  showCreateUserModal: { open: true }
                }))
              }
            />
            <div className='mt-3 d-flex justify-content-between'>
              <UsersTableFooter
                table
                rowInfo
                data={
                  showDeleted
                    ? data
                    : data?.filter(user => !user?.deleted?.isDeleted)
                }
                rowCount={
                  showDeleted
                    ? data?.length
                    : data?.filter(user => !user?.deleted?.isDeleted).length
                }
              />
              <UsersTablePagination table />
            </div>
          </UsersTableWrapper>
        )}
      </Card.Body>
    </Card>
  );
};

export default Users;
