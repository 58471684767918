import routes from 'config/routeSettings';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Tools = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      navigate(routes.SYSTEM_TOOLS_REPORTING_STATUSES);
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return <></>;
};

export default Tools;
