import { Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';

const okta = () => {
  return [
    {
      id: QbUtils.uuid(),
      colSize: { sm: 12 },
      component: 'WidgetSectionTitle',
      props: {
        className: 'mb-1',
        title: 'Okta Overview'
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['previousCount', 'count', 'sparkline'],
        overriddenIndex: ['leargas-okta']
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          title: 'Events',
          helpInfo: 'Total number of Okta events',
          showFilterIcon: true
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6, xxl: 3 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-okta'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'severity.keyword': 'INFO'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'severity',
            value: 'INFO'
          },
          title: 'Info',
          helpInfo: 'Total number of info-level Okta events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 4, xl: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-okta'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'severity.keyword': 'WARN'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          filterData: {
            field: 'severity',
            value: 'WARN'
          },
          title: 'Warning',
          helpInfo: 'Total number of warning-level Okta events',
          showFilterIcon: true
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 4, xl: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-okta'],
        queryType: ['previousCount', 'count', 'sparkline'],

        must: [
          {
            match_phrase: {
              'severity.keyword': 'ERROR'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'severity',
            value: 'ERROR'
          },
          title: 'Error',
          helpInfo: 'Total number of error-level Okta events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 4, xl: 4, xxl: 2 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-okta'],
        queryType: ['previousCount', 'count', 'sparkline'],
        must: [
          {
            match_phrase: {
              'severity.keyword': 'DEBUG'
            }
          }
        ]
      },
      children: {
        component: 'MetricVerticalBar',
        props: {
          className: '',
          showFilterIcon: true,
          filterData: {
            field: 'severity',
            value: 'DEBUG'
          },
          title: 'Debug',
          helpInfo: 'Total number of debug-level Okta events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 7 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        queryType: ['histogram'],
        overriddenIndex: ['leargas-okta']
      },
      children: {
        component: 'Stat',
        props: {
          className: '',
          header: 'Event Timeline',
          helpInfo:
            'Timeline histogram of all Okta events matching the search criteria'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12, xl: 5 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-okta'],
        queryType: ['map'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'client.geographicalContext.country.keyword',
                order: {
                  _count: 'desc'
                },
                exclude: [''],
                size: 100
              }
            }
          }
        }
      },
      children: {
        component: 'Map',
        props: {
          className: '',
          title: 'Event Source Countries',
          field: 'client.geographicalContext.country',
          helpInfo: 'Source countries of Okta events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-okta'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'displayMessage.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Event Types',
          helpInfo: 'Event types generated by Okta',
          field: 'displayMessage'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-okta'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'client.userAgent.rawUserAgent.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'User Agents',
          field: 'client.userAgent.rawUserAgent',
          helpInfo: 'User agents seen in all Okta events'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-okta'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'severity.keyword',
                order: {
                  _count: 'desc'
                },

                size: 500
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Severities',
          field: 'severity',
          helpInfo: 'Event levels generated by Okta'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-okta'],
        queryType: ['aggregate'],
        aggregate: {
          aggs: {
            2: {
              terms: {
                field: 'transaction.type.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'PieChart',
        props: {
          className: '',
          header: 'Transaction Types',
          field: 'transaction.type',
          helpInfo: 'Transaction types generated by Okta'
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-okta'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'actor.displayName.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'actor.alternateId.keyword',
                    order: {
                      _count: 'desc'
                    },

                    size: 50
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Actors',
            helpInfo: 'Actors generating Okta events'
            // overlayText: '',
            // fontAwesomeProps: { icon: ['far', 'question-circle'], transform: 'shrink-1', className: 'ms-1 text-400' }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-okta'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'target.displayName.keyword',
                order: {
                  _count: 'desc'
                },

                size: 50
              },
              aggs: {
                3: {
                  terms: {
                    field: 'target.type.keyword',
                    order: {
                      _count: 'desc'
                    },

                    size: 50
                  }
                }
              }
            }
          },
          size: 0
        }
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          helpInfo: 'Targets of Okta events',
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Targets'
            // overlayText: '',
            // fontAwesomeProps: { icon: ['far', 'question-circle'], transform: 'shrink-1', className: 'ms-1 text-400' }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 12 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-okta'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'client.userAgent.rawUserAgent.keyword',
                order: {
                  _count: 'desc'
                },
                size: 5000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'client.ipAddress.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5000
                  },
                  aggs: {
                    4: {
                      terms: {
                        field:
                          'debugContext.debugData.threatDetections.keyword',
                        order: {
                          _count: 'desc'
                        },
                        size: 5000
                      }
                    }
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_phrase: {
              'displayMessage.keyword': 'Request from suspicious actor'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          helpInfo: 'Indicates suspicious actors in Okta events',
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Suspicious Actors'
            // overlayText: '',
            // fontAwesomeProps: { icon: ['far', 'question-circle'], transform: 'shrink-1', className: 'ms-1 text-400' }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-okta'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'actor.displayName.keyword',
                order: {
                  _count: 'desc'
                },
                // //missing: '__missing__',
                size: 5000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'target.displayName.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5000
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_phrase: {
              'displayMessage.keyword':
                'User attempted unauthorized access to app'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          helpInfo: 'Indicates unauthorized access attempts in Okta events',
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Unauthorized Access Attempts'
            // overlayText: '',
            // fontAwesomeProps: { icon: ['far', 'question-circle'], transform: 'shrink-1', className: 'ms-1 text-400' }
          }
        }
      }
    },
    {
      id: QbUtils.uuid(),
      colSize: { md: 6 },
      component: 'DataIntegrationDashboards',
      props: {
        className: '',
        overriddenIndex: ['leargas-okta'],
        queryType: ['table'],
        tableQuery: {
          aggs: {
            2: {
              terms: {
                field: 'actor.alternateId.keyword',
                order: {
                  _count: 'desc'
                },
                size: 5000
              },
              aggs: {
                3: {
                  terms: {
                    field: 'request.ipChain.ip.keyword',
                    order: {
                      _count: 'desc'
                    },
                    size: 5000
                  }
                }
              }
            }
          },
          size: 0
        },
        must: [
          {
            match_phrase: {
              'displayMessage.keyword': 'Rate limit warning'
            }
          }
        ]
      },
      children: {
        component: 'Table',
        props: {
          className: '',
          perPage: 10,
          helpInfo: 'Indicates rate limit warnings in Okta events',
          hidePageNumbers: true,
          HeaderProps: {
            headerText: 'Rate Limit Warnings'
            // overlayText: '',
            // fontAwesomeProps: { icon: ['far', 'question-circle'], transform: 'shrink-1', className: 'ms-1 text-400' }
          }
        }
      }
    }
  ];
};

export default okta;
