import { useContext } from 'react';
import { ReleasesContext } from 'context/admin-contexts/ReleasesProvider.js';

/**
 * Custom hook to use the ReleasesContext
 * @returns {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
export const useReleases = () => {
  return useContext(ReleasesContext);
};

export default useReleases;
