import classNames from 'classnames';
import AdvancedPopover from 'components/common/Popover';
import useExplore from 'hooks/useExplore';
import { Badge } from 'react-bootstrap';

/**
 * PresenceFilter component.
 *
 * Renders options for showing/hiding fields based on whether they are present in the data on the page.
 * Clicking on a badge will toggle the visibility accordingly
 *
 * @returns {JSX.Element} The rendered PresenceFilter component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const PresenceFilter = () => {
  const {
    state: { fieldsPresentOnly },
    setState
  } = useExplore();

  /**
   * Handles the toggle action for a specific presence setting.
   *
   * @param {string} presence - The type to toggle
   * @returns {void}
   *
   * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
   * @version 0.1.0-beta.5
   * @since 0.1.0-beta.5
   */
  const handleToggle = presence => {
    if (presence === 'all') {
      setState('fieldsPresentOnly', false);
    } else if (presence === 'documents') {
      setState('fieldsPresentOnly', true);
    }
  };

  return (
    <div className='d-flex flex-wrap fs--2 mb-2 ps-1'>
      <span className='me-1'>Fields</span>
      <AdvancedPopover
        placement='top'
        showArrow={true}
        popoverText='Show all fields available in the index whether they are present in the data being shown or not.'>
        <Badge
          className={classNames(
            'p-1 me-1 cursor-pointer border text-secondary bg-200',
            {
              [`text-pastel-green bg-soft-pastel-green`]: !fieldsPresentOnly
            }
          )}
          onClick={() => handleToggle('all')}>
          All
        </Badge>
      </AdvancedPopover>
      <AdvancedPopover
        placement='top'
        showArrow={true}
        popoverText='Only show fields that are present in the data being shown.'>
        <Badge
          className={classNames(
            'p-1 me-1 cursor-pointer border text-secondary bg-200',
            {
              [`text-pastel-green bg-soft-pastel-green`]: fieldsPresentOnly
            }
          )}
          onClick={() => handleToggle('documents')}>
          Present
        </Badge>
      </AdvancedPopover>
    </div>
  );
};

export default PresenceFilter;
