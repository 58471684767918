import { useCluster } from 'hooks/admin-contexts/useCluster';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useCountUp } from 'react-countup';

const InitializingShards = () => {
  const {
    cluster: {
      clusterHealth: { initializing_shards, status }
    }
  } = useCluster();
  const [initializingShardsCounter, setInitializingShardsCounter] = useState(0);
  const [barColor, setBarColor] = useState('secondary');

  useEffect(() => {
    if (initializingShardsCounter > 0 && status === 'red') {
      setBarColor('danger');
    } else if (initializingShardsCounter > 0 && status === 'yellow') {
      setBarColor('warning');
    } else {
      setBarColor('green');
    }
  }, [initializingShardsCounter]);

  const memoizedCallback = useCallback(value => {
    return value.toLocaleString();
  }, []);

  const { update } = useCountUp({
    ref: 'initializingShardsCounter',
    decimals: 0,
    duration: 2.75,
    end: initializingShardsCounter || 0,
    formattingFn: memoizedCallback,
    preserveValue: true
  });

  useEffect(() => {
    setInitializingShardsCounter(initializing_shards);
    update(initializing_shards);
  }, [initializing_shards]);

  return (
    <Card
      className={`h-100 shadow-none border status-card ${
        !['secondary', 'green'].includes(barColor) ? barColor : null
      }`}>
      {!status ? (
        <div className='position-absolute d-flex justify-content-center align-items-center w-100 navbar-glass-loader'>
          <Spinner animation='border' />
        </div>
      ) : null}
      <Card.Body className='p-2'>
        <Row className='g-0'>
          <Col className='d-flex flex-column justify-content-between'>
            <h6 className='mb-2'>Initializing Shards</h6>
            <h6 className='fs-2 fw-normal my-0'>
              <span id='initializingShardsCounter' className='font-monospace' />
            </h6>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InitializingShards;
