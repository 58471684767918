import { axiosPrivate } from 'api/connection/axios';
import React from 'react';
import routes from './routeSettings';

function createMarkup(info) {
  return { __html: info };
}

export const keystrokeDefinitions = [
  {
    group: 'General Shortcuts',
    allowedRoles: [
      '64c22e2016d735abd7e349df', // User
      '64c22e2f16d735abd7e349e0', // Admin
      '64c22e3c16d735abd7e349e1' // SuperAdmin
    ],
    richDescription: info => {
      return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
    },
    shortcuts: [
      // {
      //   keys: ['shift', 'l', 'b'],
      //   description: 'Toggle show/hide bookmarks panel',
      //   richDescription: info => {
      //     return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
      //   },
      //   callback: ({ application, setApplication }) => {
      //     setApplication('showBookmarks', application.showBookmarks ? false : true);
      //   }
      // },
      {
        keys: ['shift', 'l', 'k'],
        description: 'Toggle show/hide available keyboard shortcuts',
        richDescription: info => {
          return (
            <span dangerouslySetInnerHTML={createMarkup(info.description)} />
          );
        },
        callback: ({ application, setApplication }) => {
          setApplication(
            'showKeyboardShortcuts',
            application.showKeyboardShortcuts ? false : true
          );
        }
      },
      // {
      //   keys: ['shift', 'l', 'h'],
      //   description: 'Toggle show/hide available help shortcuts',
      //   richDescription: info => {
      //     return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
      //   },
      //   callback: ({ application, setApplication }) => {
      //     setApplication('showHelpModal', application.showHelpModal ? false : true);
      //   }
      // },
      {
        keys: ['shift', 'l', 'm'],
        description: 'Toggle light/dark theme mode',
        richDescription: info => {
          return (
            <span dangerouslySetInnerHTML={createMarkup(info.description)} />
          );
        },
        callback: ({ application, setApplication }) => {
          setApplication('isDark', application.isDark ? false : true);
        }
      },
      {
        keys: ['shift', 'l', 'n'],
        description: 'Toggle expanded/collapsed state of left navigation menu',
        richDescription: info => {
          return (
            <span dangerouslySetInnerHTML={createMarkup(info.description)} />
          );
        },
        callback: ({ application, setApplication }) => {
          setApplication(
            'isNavbarVerticalCollapsed',
            application.isNavbarVerticalCollapsed ? false : true
          );
        }
      },
      {
        keys: ['shift', 'l', 'f'],
        description: 'Toggle on/off full screen mode',
        richDescription: info => {
          return (
            <span dangerouslySetInnerHTML={createMarkup(info.description)} />
          );
        },
        callback: () => {
          if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
          } else {
            if (document.exitFullscreen) {
              document.exitFullscreen();
            }
          }
        }
      }
    ]
  },
  {
    group: 'Security Shortcuts',
    allowedRoles: [
      '64c22e2016d735abd7e349df', // User
      '64c22e2f16d735abd7e349e0', // Admin
      '64c22e3c16d735abd7e349e1' // SuperAdmin
    ],
    richDescription: info => {
      return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
    },
    shortcuts: [
      {
        keys: ['shift', 'l', 's', 'p'],
        description:
          'Toggle enable/disable <b>Privacy Mode</b> to hide sensitive information from view',
        richDescription: info => {
          return (
            <span dangerouslySetInnerHTML={createMarkup(info.description)} />
          );
        },
        callback: ({ application, setApplication }) => {
          setApplication(
            'isPrivacyMode',
            application.isPrivacyMode ? false : true
          );
        }
      },
      {
        keys: ['shift', 'l', 's', 'k'],
        description:
          'Securely lock your session and show the <b>Session Locked</b> screen',
        richDescription: info => {
          return (
            <span dangerouslySetInnerHTML={createMarkup(info.description)} />
          );
        },
        callback: ({ lock, setApplication }) => {
          setApplication('showKeyboardShortcuts', false);
          lock();
        }
      },
      {
        keys: ['shift', 'l', 's', 'q'],
        description:
          'Securely terminate your session and return to the <b>Log In</b> screen',
        richDescription: info => {
          return (
            <span dangerouslySetInnerHTML={createMarkup(info.description)} />
          );
        },
        callback: ({ logout, setApplication }) => {
          setApplication('showKeyboardShortcuts', false);
          logout(axiosPrivate);
        }
      }
    ]
  },

  {
    group: 'Administrator-Only Shortcuts',
    allowedRoles: [
      '64c22e2f16d735abd7e349e0', // Admin
      '64c22e3c16d735abd7e349e1' // SuperAdmin
    ],
    description:
      'These shortcuts are only visible and available for users with the Administrator role.',
    richDescription: info => {
      return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
    },
    shortcuts: [
      {
        keys: ['shift', 'l', 'c', 'h'],
        description: 'Navigate to the <b>Search Cluster ⟶ Health</b> dashboard',
        richDescription: info => {
          return (
            <span dangerouslySetInnerHTML={createMarkup(info.description)} />
          );
        },
        callback: ({ navigate }) => {
          navigate(routes.SYSTEM_TOOLS_ES_HEALTH);
        }
      },
      {
        keys: ['shift', 'l', 'c', 'n'],
        description: 'Navigate to the <b>Search Cluster ⟶ Nodes</b> dashboard',
        richDescription: info => {
          return (
            <span dangerouslySetInnerHTML={createMarkup(info.description)} />
          );
        },
        callback: ({ navigate }) => {
          navigate(routes.SYSTEM_TOOLS_ES_NODES);
        }
      },
      {
        keys: ['shift', 'l', 'c', 'i'],
        description:
          'Navigate to the <b>Search Cluster ⟶ Indices</b> dashboard',
        richDescription: info => {
          return (
            <span dangerouslySetInnerHTML={createMarkup(info.description)} />
          );
        },
        callback: ({ navigate }) => {
          navigate(routes.SYSTEM_TOOLS_ES_INDICES);
        }
      }
    ]
  },
  {
    group: 'Developer-Only Shortcuts',
    allowedRoles: [
      '64c22e2f16d735abd7e349e0', // Admin
      '64c22e3c16d735abd7e349e1' // SuperAdmin
    ],
    description:
      'These shortcuts are only visible and available for users with the <b>Developer</b> role.',
    richDescription: info => {
      return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
    },
    shortcuts: [
      {
        group: 'Developer Shortcuts',
        description: 'Toggle showing developer settings panel',
        richDescription: info => {
          return (
            <span dangerouslySetInnerHTML={createMarkup(info.description)} />
          );
        },
        keys: ['shift', 'l', 'd', 's'],
        callback: ({ application, setApplication }) => {
          setApplication(
            'showDevSettingsPanel',
            application.showDevSettingsPanel ? false : true
          );
        }
      },
      {
        keys: ['shift', 'l', 'd', 'p'],
        description: 'Toggle showing all pages marked <b>Development</b>',
        richDescription: info => {
          return (
            <span dangerouslySetInnerHTML={createMarkup(info.description)} />
          );
        },
        callback: ({ application, setApplication }) => {
          setApplication(
            'showDevPages',
            application.showDevPages ? false : true
          );
        }
      }
    ]
  }
];

// {
//   group: 'Navigational Shortcuts',
// allowedRoles: [
//   '64c22e2016d735abd7e349df', // User
//   '64c22e2f16d735abd7e349e0', // Admin
//   '64c22e3c16d735abd7e349e1' // SuperAdmin
// ],
//   description: 'Quickly navigate to different pages within the application. Available on all pages for all users.',
//   richDescription: info => {
//     return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//   },
//   shortcuts: [
//     {
//       keys: ['shift', 'l', 'o'],
//       description: `Navigate to the <a href="${routes.OVERVIEW}">Overview</a> page`,
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.OVERVIEW);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'a'],
//       description: 'Navigate to the <code>Explore⟶All Artifacts</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_EXPLORE);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'c'],
//       description: 'Navigate to the <code>Explore⟶Cloud</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_CLOUD);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'c', 'b'],
//       description: 'Navigate to the <code>Explore⟶Cloud⟶Box</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_CLOUD_BOX);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'c', 'd'],
//       description: 'Navigate to the <code>Explore⟶Cloud⟶Duo</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_CLOUD_DUO);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'c', 'g'],
//       description: 'Navigate to the <code>Explore⟶Cloud⟶Google</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_CLOUD_GOOGLE);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'c', 'm'],
//       description: 'Navigate to the <code>Explore⟶Cloud⟶Meraki</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_CLOUD_MERAKI);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'c', 'o'],
//       description: 'Navigate to the <code>Explore⟶Cloud⟶Office365</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_CLOUD_O365);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'c', 'k'],
//       description: 'Navigate to the <code>Explore⟶Cloud⟶Okta</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_CLOUD_OKTA);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'd'],
//       description: 'Navigate to the <code>Explore⟶Darkweb</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_DARKWEB);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 's'],
//       description: 'Navigate to the <code>Explore⟶Endpoint</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_ENDPOINT);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'm', 'o'],
//       description: 'Navigate to the <code>Explore⟶Endpoint⟶SentinelOne</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_ENDPOINT_SENTINELONE);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'm', 'w'],
//       description: 'Navigate to the <code>Explore⟶Endpoint⟶Windows</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_ENDPOINT_WINDOWS);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'm', 's'],
//       description: 'Navigate to the <code>Explore⟶Endpoint⟶Syslog</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_ENDPOINT_WINDOWS);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'n'],
//       description: 'Navigate to the <code>Explore⟶Network</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_NETWORK);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'n', 'b'],
//       description: 'Navigate to the <code>Explore⟶Network⟶Behaviors</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_NETWORK_BEHAVOIRS);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'n', 's'],
//       description: 'Navigate to the <code>Explore⟶Network⟶Signatures</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_NETWORK_SIGNATURES);
//       }
//     },
//     {
//       keys: ['shift', 'l', 'e', 'n', 't'],
//       description: 'Navigate to the <code>Explore⟶Network⟶Threat Intelligence</code> dashboard',
//       richDescription: info => {
//         return <span dangerouslySetInnerHTML={createMarkup(info.description)} />;
//       },
//       callback: ({ navigate }) => {
//         navigate(routes.ARTIFACTS_NETWORK_THREATINTEL);
//       }
//     }
//   ]
// },
