import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedPopover from 'components/common/Popover';
import useArticles from 'hooks/admin-contexts/useArticles';
import useApplication from 'hooks/useApplication';
import React from 'react';
import { Modal } from 'react-bootstrap';
import ArticlesAddForm from '../forms/ArticlesAddForm';
/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */

const ModalArticleAdd = () => {
  const {
    application: { isDark }
  } = useApplication();

  const {
    articles: { addArticleModal },
    setArticles
  } = useArticles();

  return (
    <Modal
      centered
      size={addArticleModal.fullScreen ? 'xl' : 'lg'}
      animation={false}
      backdrop='static'
      keyboard={false}
      show={addArticleModal?.open}
      onHide={() =>
        setArticles(prevState => ({
          ...prevState,
          addArticleModal: { open: false }
        }))
      }
      aria-labelledby='modalArticleAdd'
      contentClassName={
        addArticleModal.fullScreen &&
        'border-0 border-top-left-radius-0 border-top-right-radius-0 h-100'
      }
      dialogClassName={
        addArticleModal.fullScreen &&
        'position-absolute all-0 fullscreen-modal-dialog scrollbar overflow-x-hidden'
      }>
      <Modal.Header
        className='p-2'
        closeButton
        closeVariant={isDark ? 'white' : undefined}>
        <h5 className='d-flex  fs-0 fw-normal position-relative'>
          Add an article
          <AdvancedPopover
            containerId='resetConfirmModal'
            placement='top'
            popoverText='Add an article'
            showArrow={true}>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='ms-1 fs--1 cursor-pointer text-400'
            />
          </AdvancedPopover>
        </h5>
        <div
          className='d-flex justify-content-end position-absolute right-0 '
          style={{ right: '3rem' }}>
          <FontAwesomeIcon
            icon={
              addArticleModal.fullScreen
                ? 'down-left-and-up-right-to-center'
                : 'up-right-and-down-left-from-center'
            }
            className='me-1'
            onClick={() => {
              setArticles(prevState => ({
                ...prevState,
                addArticleModal: {
                  open: true,
                  fullScreen: !addArticleModal.fullScreen
                }
              }));
            }}
          />
        </div>
      </Modal.Header>

      <Modal.Body className='p-2'>
        <ArticlesAddForm />
      </Modal.Body>
    </Modal>
  );
};

export default ModalArticleAdd;
