import { getColor, getItemFromStore } from 'helpers/utils';
import useToggleStyle from 'hooks/useToggleStyle';
import PropTypes from 'prop-types';
import React, { createContext, useReducer } from 'react';
import { applicationReducer } from 'reducers/applicationReducer';
import pkg from '../../package.json';

/**
 * Application version
 */
export const version = pkg.version;

/**
 * Application name
 */
export const navbarBreakPoint = 'lg';

/**
 * Application top navbar breakpoint
 */
export const topNavbarBreakpoint = 'md';

/**
 * Application settings object
 *
 * @typedef {Object} ApplicationSettings
 * @property {Object} systemRoles - Object containing system roles
 * @property {boolean} isFluid - Flag indicating if the application is in fluid mode
 * @property {boolean} isRTL - Flag indicating if the application is in RTL (right-to-left) mode
 * @property {boolean} isDark - Flag indicating if the application is in dark mode
 * @property {boolean} isPrivacyMode - Flag indicating if the application is in privacy mode
 * @property {boolean} showGlobalFilters - Flag indicating if global filters are shown
 * @property {Object} globalFilters - Filters for persistence
 * @property {boolean} showSimpleFilters - Flag indicating if simple filters are shown
 * @property {string} lastTabOpen - The last tab that was open
 * @property {boolean} showBookmarks - Flag indicating if bookmarks are shown
 * @property {string} navbarPosition - The position of the navbar
 * @property {boolean} showBurgerMenu - Flag indicating if the burger menu is shown
 * @property {string} currency - The currency symbol
 * @property {boolean} isNavbarVerticalCollapsed - Flag indicating if the vertical navbar is collapsed
 * @property {string} navbarStyle - The style of the navbar
 * @property {boolean} showKeyboardShortcuts - Flag indicating if keyboard shortcuts are shown
 * @property {boolean} showHelpModal - Flag indicating if the help modal is shown
 * @property {boolean} showDevSettingsPanel - Flag indicating if the developer settings panel is shown
 * @property {boolean} showDevPages - Flag indicating if developer pages are shown
 */
export const applicationSettings = {
  systemRoles: {
    '64c22e2016d735abd7e349df': {
      slug: 'user',
      name: 'User'
    },
    '64c22e2f16d735abd7e349e0': {
      slug: 'admin',
      name: 'Admin'
    },
    '64c22e3c16d735abd7e349e1': {
      slug: 'super-admin',
      name: 'Super Admin'
    }
  },
  isFluid: true,
  isRTL: false,
  isDark: false,
  isPrivacyMode: false,
  showGlobalFilters: false,
  globalFilters: {},
  showSimpleFilters: false,
  showAddSavedSearchModal: false,
  showSavedSearchListModal: false,
  showExploreFieldsSidebar: false,
  lastTabOpen: 'Querybuilder',
  showBookmarks: false,
  ipLookUpOffCanvas: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false,
  currency: '$',
  isNavbarVerticalCollapsed: false,
  navbarStyle: 'transparent',
  showKeyboardShortcuts: false,
  showHelpModal: false,
  showDevSettingsPanel: false,
  showDevPages: false,
  showNavigator: true,
  showProfileMenu: false,
  driver_onboarding: false,
  driver_onboarding_step: 0
};

/**
 * Create ApplicationContext
 * @type {React.Context<{}>}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
export const ApplicationContext = createContext(applicationSettings);

/**
 * Provides the application context and state to its children components
 *
 * @param {Object} props - The component props
 * @returns {JSX.Element} The rendered component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.1
 * @since 0.1.0-beta.1
 */
const ApplicationProvider = props => {
  const applicationState = {
    systemRoles: applicationSettings.systemRoles,
    isFluid: getItemFromStore('isFluid', applicationSettings.isFluid),
    isRTL: getItemFromStore('isRTL', applicationSettings.isRTL),
    isDark: getItemFromStore('isDark', applicationSettings.isDark),
    isPrivacyMode: getItemFromStore(
      'isPrivacyMode',
      applicationSettings.isPrivacyMode
    ),
    navbarPosition: getItemFromStore(
      'navbarPosition',
      applicationSettings.navbarPosition
    ),
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      applicationSettings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore(
      'navbarStyle',
      applicationSettings.navbarStyle
    ),
    showNavigator: getItemFromStore(
      'showNavigator',
      applicationSettings.showNavigator
    ),
    showProfileMenu: applicationSettings.showProfileMenu,
    driver_onboarding: getItemFromStore(
      'driver_onboarding',
      applicationSettings.driver_onboarding
    ),
    driver_onboarding_step: getItemFromStore(
      'driver_onboarding_step',
      applicationSettings.driver_onboarding_step
    ),
    showKeyboardShortcuts: getItemFromStore(
      'showKeyboardShortcuts',
      applicationSettings.showKeyboardShortcuts
    ),
    showHelpModal: getItemFromStore(
      'showHelpModal',
      applicationSettings.showHelpModal
    ),
    showDevSettingsPanel: getItemFromStore(
      'showDevSettingsPanel',
      applicationSettings.showDevSettingsPanel
    ),
    showDevPages: getItemFromStore(
      'showDevPages',
      applicationSettings.showDevPages
    ),
    showGlobalFilters: getItemFromStore(
      'showGlobalFilters',
      applicationSettings.showGlobalFilters
    ),
    globalFilters: getItemFromStore(
      'globalFilters',
      applicationSettings.globalFilters
    ),
    showSimpleFilters: getItemFromStore(
      'showSimpleFilters',
      applicationSettings.showSimpleFilters
    ),
    showAddSavedSearchModal: getItemFromStore(
      'showAddSavedSearchModal',
      applicationSettings.showAddSavedSearchModal
    ),
    showSavedSearchListModal: getItemFromStore(
      'showSavedSearchListModal',
      applicationSettings.showSavedSearchListModal
    ),
    showExploreFieldsSidebar: getItemFromStore(
      'showExploreFieldsSidebar',
      applicationSettings.showExploreFieldsSidebar
    ),
    showBookmarks: getItemFromStore(
      'showBookmarks',
      applicationSettings.showBookmarks
    ),
    ipLookUpOffCanvas: getItemFromStore(
      'ipLookUpOffCanvas',
      applicationSettings.ipLookUpOffCanvas
    ),
    lastTabOpen: getItemFromStore(
      'lastTabOpen',
      applicationSettings.lastTabOpen
    ),
    currency: applicationSettings.currency,
    showBurgerMenu: applicationSettings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false
  };

  const [application, applicationDispatch] = useReducer(
    applicationReducer,
    applicationState
  );

  const { isLoaded } = useToggleStyle(
    application.isRTL,
    application.isDark,
    applicationDispatch
  );

  const setApplication = (key, value) => {
    applicationDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'globalFilters',
          'isPrivacyMode',
          'showGlobalFilters',
          'showSimpleFilters',
          'showAddSavedSearchModal',
          'showSavedSearchListModal',
          'showExploreFieldsSidebar',
          'lastTabOpen',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle',
          'showNavigator',
          'showProfileMenu',
          'driver_onboarding',
          'driver_onboarding_step',
          'showKeyboardShortcuts',
          'showHelpModal',
          'showDevSettingsPanel',
          'showDevPages'
        ].includes(key)
      }
    });
  };

  const updateGlobalFilters = newValues => {
    const newGlobalFilters = application?.globalFilters
      ? typeof application.globalFilters === 'string'
        ? JSON.parse(application.globalFilters)
        : application.globalFilters
      : {};
    if (
      Object.keys(newValues).length === 0 &&
      newValues.constructor === Object
    ) {
      setApplication('globalFilters', JSON.stringify({}));
      return;
    }
    const updatedFilters = {
      ...newGlobalFilters,
      ...newValues
    };
    setApplication('globalFilters', JSON.stringify(updatedFilters));
  };

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: application.isDark
            ? getColor('dark')
            : getColor('light')
        }}
      />
    );
  }

  return (
    <ApplicationContext.Provider
      value={{ application, setApplication, isLoaded, updateGlobalFilters }}>
      {props.children}
    </ApplicationContext.Provider>
  );
};

ApplicationProvider.propTypes = {
  children: PropTypes.node
};

export default ApplicationProvider;
