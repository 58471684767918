import { getColor, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { forwardRef, useMemo } from 'react';
import Select from 'react-select';

/**
 * FieldSelect component for selecting field for a search filter.
 *
 * @component
 * @param {Object[]} options - The options for the select field
 * @param {Function} getOptions - The function to fetch the options for the select field
 * @param {boolean} [isLoading=false] - Indicates if the select field is in a loading state
 * @param {Object} rest - Additional props for the FieldSelect component
 * @param {React.Ref} ref - Reference to the FieldSelect component
 * @returns {React.Element} The rendered FieldSelect component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.5
 * @since 0.1.0-beta.5
 */
const MultiSelect = forwardRef(
  ({ options, isLoading = false, value, placeholder, ...rest }, ref) => {
    const styles = useMemo(
      () => ({
        menu: provided => ({
          ...provided,
          fontSize: '0.8333333333rem',
          color: 'var(--falcon-gray-1100)',
          background: 'var(--falcon-gray-200)',
          margin: '0',
          padding: '0',
          height: 'auto'
        }),
        menuList: provided => ({
          ...provided,
          fontSize: '0.8333333333rem',
          color: 'var(--falcon-gray-1100)',
          background: 'var(--falcon-gray-200)',
          padding: '5px 0 0 0'
        }),
        placeholder: provided => ({
          ...provided,
          fontSize: '0.8333333333rem',
          color: 'var(--falcon-gray-1100)'
        }),
        option: (provided, state) => ({
          ...provided,
          fontSize: '0.8333333333rem',
          background: state.isFocused
            ? getColor('pastel-green-dark')
            : state.isSelected
            ? 'var(--falcon-gray-300)'
            : 'var(--falcon-gray-200)',
          color: state.isDisabled
            ? 'var(--falcon-gray-400)'
            : 'var(--falcon-gray-1100)',
          padding: 10,
          cursor: !state.isDisabled ? 'pointer' : 'default',
          '&:hover': {
            backgroundColor: getColor('pastel-green-dark'),
            color: 'white'
          }
        }),
        input: provided => ({
          ...provided,
          fontSize: '0.8333333333rem',
          color: 'var(--falcon-gray-1100)',
          margin: '0',
          padding: '0'
        }),
        control: provided => ({
          ...provided,
          fontSize: '0.8333333333rem',
          margin: '0',
          padding: '0',
          color: 'var(--falcon-gray-1100)',
          backgroundColor: 'var(--falcon-input-bg)',
          border: '1px solid var(--falcon-input-border-color)',
          boxShadow: 'var(--falcon-box-shadow-inset)',
          transition:
            'background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
          ':hover': {
            borderColor: getColor('pastel-green')
          },
          ':focus-within': {
            borderColor: getColor('pastel-green'),
            boxShadow: `inset 0 0 10px 0 ${rgbaColor(
              getColor('pastel-green'),
              0.5
            )}`
          }
        }),
        valueContainer: provided => ({
          ...provided,
          color: 'var(--falcon-gray-1100)',
          border: '0',
          margin: '0',
          padding: '0 0 0 8px',
          height: 'auto'
        }),
        singleValue: provided => ({
          ...provided,
          color: 'var(--falcon-input-color)',
          margin: '0',
          padding: '0',
          height: 'auto'
        }),
        indicatorSeparator: provided => ({
          ...provided,
          background: 'var(--falcon-gray-400)'
        }),
        dropdownIndicator: provided => ({
          ...provided,
          color: 'var(--falcon-gray-1100)',
          marginTop: '0',
          marginLeft: '5px',
          padding: '0',
          border: '0',
          width: '24px',
          cursor: 'pointer',
          '&:hover': {
            color: 'var(--falcon-primary)'
          }
        }),
        clearIndicator: provided => ({
          ...provided,
          color: 'var(--falcon-gray-1100)',
          marginTop: '0',
          marginLeft: '5px',
          marginRight: '5px',
          padding: '0',
          border: '0',
          width: '24px',
          cursor: 'pointer',
          '&:hover': {
            color: 'var(--falcon-danger)'
          }
        }),
        indicatorsContainer: provided => ({
          ...provided,
          top: '0',
          paddingRight: '4px',
          border: '0'
        }),
        noOptionsMessage: provided => ({
          ...provided,
          color: 'var(--falcon-gray-1100)',
          fontSize: '0.8333333333rem'
        })
      }),
      []
    );

    return (
      <Select
        backspaceRemovesValue={false}
        isSearchable={true}
        options={options}
        escapeClearsValue={false}
        isClearable={Boolean(value)}
        loadingMessage={() => 'Loading...'}
        placeholder={isLoading ? 'Loading...' : placeholder}
        ref={ref}
        styles={styles}
        {...rest}
      />
    );
  }
);

MultiSelect.propTypes = {
  options: PropTypes.array,
  getOptions: PropTypes.func,
  isLoading: PropTypes.bool,
  value: PropTypes.object,
  placeholder: PropTypes.string
};

export default MultiSelect;
