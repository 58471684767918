import classNames from 'classnames';
import Flex from 'components/common/Flex';
import AdvancedTable from 'components/common/table/Table';
import AdvancedTableFooter from 'components/common/table/TableFooter';
import AdvancedTablePagination from 'components/common/table/TablePagination';
import AdvancedTableSettingsModal from 'components/common/table/TableSettingsModal';
import AdvancedTableWrapper from 'components/common/table/TableWrapper';
import CPUUsage from 'components/dashboards/System/SearchCluster/Health/CPUUsage';
import JVMHeapUsage from 'components/dashboards/System/SearchCluster/Health/JVMHeapUsage';
import MemoryUsage from 'components/dashboards/System/SearchCluster/Health/MemoryUsage';
import StorageUsage from 'components/dashboards/System/SearchCluster/Health/StorageUsage';
import { apiRoutes } from 'config/routeSettings';
import Cluster from 'context/ClusterContext';
import { capitalizeWords } from 'helpers/utils';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Spinner,
  Tab
} from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import DataNodeCount from '../Health/DataNodeCount';
import NodeCount from '../Health/NodeCount';
import ModalPreview from './ModalPreview';
import TableFilters from './TableFilters';

const ElasticsearchNodes = () => {
  const { axiosPrivate } = useAxiosPrivate();
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState('');
  const [settingsModalShow, setSettingsModalShow] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([
    'name',
    'role',
    'temp',
    'cpu_percent',
    'mem_percent',
    'jvm_heap_percent',
    'swap_percent',
    'disk_percent',
    'version',
    'actions'
  ]);
  const [nodes, setNodes] = useState([]);
  const [columns, setColumns] = useState([]);
  const [total, setTotal] = useState(0);
  const [dataNodesCount, setDataNodesCount] = useState(0);
  const [coordNodesCount, setCoordNodesCount] = useState(0);
  const [masterNodesCount, setMasterNodesCount] = useState(0);
  const [webNodesCount, setWebNodesCount] = useState(0);
  const [hotNodesCount, setHotNodesCount] = useState(0);
  const [warmNodesCount, setWarmNodesCount] = useState(0);
  const [coldNodesCount, setColdNodesCount] = useState(0);
  const [previewItem, setPreviewItem] = useState({});
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [searchParams] = useSearchParams();

  const columnDefaults = [
    {
      accessor: 'name',
      Header: 'Name',
      sortType: 'string',
      noTruncate: true,
      hasRender: true,
      cellProps: {
        className: 'align-middle fs--2 text-700 text-nowrap'
      },
      headerProps: {
        className: 'fs--2 text-nowrap'
      },
      Cell: rowData => {
        return (
          <>
            <p className='fw-semi-bold mb-0'>
              {/* Replace everything after the first ., including the . */}
              {rowData.row.original.name?.replace(/\..*/, '')}
            </p>
            <span>{rowData.row.original?.ip}</span>
          </>
        );
      }
    },
    {
      accessor: 'version',
      Header: 'Version',
      sortType: 'string',
      noTruncate: true,
      cellProps: {
        className: 'align-middle fs--2 text-700 text-nowrap'
      },
      headerProps: {
        className: 'fs--2 text-nowrap'
      }
    },
    {
      accessor: 'role',
      Header: 'Role',
      hasRender: true,
      Cell: rowData => {
        const value = rowData.row.original.role;
        return value ? (
          <Badge
            size='sm'
            bg={
              value === 'data'
                ? 'pastel-red'
                : value === 'master'
                ? 'pastel-blue'
                : value === 'coord'
                ? 'pastel-yellow'
                : value === 'web'
                ? 'pastel-green'
                : 'secondary'
            }
            className='fs--2 fw-normal text-capitalize text-nowrap'>
            {value || 'N/A'}
          </Badge>
        ) : null;
      },
      cellProps: {
        className: 'align-middle fs--2 text-700 text-nowrap'
      },
      headerProps: {
        className: 'fs--2 text-nowrap'
      }
    },
    {
      accessor: 'temp',
      Header: 'Temp',
      sortType: 'string',
      hasRender: true,
      Cell: rowData => {
        const value = rowData.row.original.temp;
        return value ? (
          <Badge
            size='sm'
            bg={
              value === 'hot'
                ? 'pastel-red'
                : value === 'warm'
                ? 'pastel-yellow'
                : value === 'cold'
                ? 'pastel-blue'
                : 'secondary'
            }
            className='fs--2 fw-normal text-capitalize'>
            {value || 'N/A'}
          </Badge>
        ) : (
          '-'
        );
      },
      cellProps: {
        className: 'align-middle fs--2 text-700 text-nowrap'
      },
      headerProps: {
        className: 'fs--2 text-nowrap'
      }
    },
    {
      accessor: 'cpu_percent',
      Header: 'CPU Usage',
      headerAlign: 'end',
      hasRender: true,
      Cell: rowData => {
        const value = rowData.row.original.cpu_percent;
        return (
          <Flex alignItems='center' justifyContent='end'>
            <p className='mb-0 me-2'>{value}%</p>
            <ProgressBar
              variant={
                value > 90
                  ? 'pastel-red'
                  : value > 75
                  ? 'pastel-yellow'
                  : 'pastel-green'
              }
              now={value}
              style={{ height: '0.3125rem', width: '3.8rem' }}
            />
          </Flex>
        );
      },
      cellProps: {
        className: 'text-end align-middle fs--2 text-700 text-nowrap'
      },
      headerProps: {
        className: 'text-end fs--2 text-nowrap'
      }
    },
    {
      accessor: 'mem_percent',
      Header: 'Memory Usage',
      headerAlign: 'end',
      hasRender: true,
      Cell: rowData => {
        const value = rowData.row.original.mem_percent;
        return (
          <Flex alignItems='center' justifyContent='end'>
            <p className='mb-0 me-2'>{value}%</p>
            <ProgressBar
              variant={
                value > 90
                  ? 'pastel-red'
                  : value > 75
                  ? 'pastel-yellow'
                  : 'pastel-green'
              }
              now={value}
              style={{ height: '0.3125rem', width: '3.8rem' }}
            />
          </Flex>
        );
      },
      cellProps: {
        className: 'text-end align-middle fs--2 text-700 text-nowrap'
      },
      headerProps: {
        className: 'text-end fs--2 text-nowrap'
      }
    },
    {
      accessor: 'jvm_heap_percent',
      Header: 'Heap Usage',
      headerAlign: 'end',
      hasRender: true,
      Cell: rowData => {
        const value = rowData.row.original.jvm_heap_percent;
        return (
          <Flex alignItems='center' justifyContent='end'>
            <p className='mb-0 me-2'>{value}%</p>
            <ProgressBar
              variant={
                value > 90
                  ? 'pastel-red'
                  : value > 75
                  ? 'pastel-yellow'
                  : 'pastel-green'
              }
              now={value}
              style={{ height: '0.3125rem', width: '3.8rem' }}
            />
          </Flex>
        );
      },
      cellProps: {
        className: 'text-end align-middle fs--2 text-700 text-nowrap'
      },
      headerProps: {
        className: 'text-end fs--2 text-nowrap'
      }
    },
    {
      accessor: 'swap_percent',
      Header: 'Swap Usage',
      headerAlign: 'end',
      hasRender: true,
      Cell: rowData => {
        const value = rowData.row.original.swap_percent;
        return (
          <Flex alignItems='center' justifyContent='end'>
            <p className='mb-0 me-2'>{value}%</p>
            <ProgressBar
              variant={
                value > 90
                  ? 'pastel-red'
                  : value > 75
                  ? 'pastel-yellow'
                  : 'pastel-green'
              }
              now={value}
              style={{ height: '0.3125rem', width: '3.8rem' }}
            />
          </Flex>
        );
      },
      cellProps: {
        className: 'text-end align-middle fs--2 text-700 text-nowrap'
      },
      headerProps: {
        className: 'text-end fs--2 text-nowrap'
      }
    },
    {
      accessor: 'disk_percent',
      Header: 'Disk Usage',
      headerAlign: 'end',
      hasRender: true,
      Cell: rowData => {
        const value = rowData.row.original.disk_percent;
        return (
          <Flex alignItems='center' justifyContent='end'>
            <p className='mb-0 me-2'>{value}%</p>
            <ProgressBar
              variant={
                value > 90
                  ? 'pastel-red'
                  : value > 75
                  ? 'pastel-yellow'
                  : 'pastel-green'
              }
              now={value}
              style={{ height: '0.3125rem', width: '3.8rem' }}
            />
          </Flex>
        );
      },
      cellProps: {
        className: 'text-end align-middle fs--2 text-700 text-nowrap'
      },
      headerProps: {
        className: 'text-end fs--2 text-nowrap'
      }
    }
  ];

  const columnActions = [
    {
      accessor: 'leargas_actions',
      Header: '',
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <Button
            variant='falcon-light'
            size='sm'
            onClick={() => {
              setPreviewItem(id);
              setShowPreviewModal(true);
            }}>
            <FaSearch />
          </Button>
        );
      },
      cellProps: {
        className: 'text-end text-700 actions-cell',
        style: {
          width: '42px'
        }
      },
      hideDisplaySettings: true,
      disableSortBy: true
    }
  ];

  const handleGet = async () => {
    try {
      const res = await axiosPrivate.get(apiRoutes.SYSTEM_CLUSTER_NODES);
      if (res instanceof Error) throw res;
      setNodes(
        res?.data?.data?.nodes.sort((a, b) => (a.name > b.name ? 1 : -1))
      );
      setTotal(res?.data?.data?.total_nodes_count);
      setCoordNodesCount(res?.data?.data?.coord_nodes_count);
      setWebNodesCount(res?.data?.data?.web_nodes_count);
      setDataNodesCount(res?.data?.data?.data_nodes_count);
      setMasterNodesCount(res?.data?.data?.master_nodes_count);
      setHotNodesCount(res?.data?.data?.hot_nodes_count);
      setWarmNodesCount(res?.data?.data?.warm_nodes_count);
      setColdNodesCount(res?.data?.data?.cold_nodes_count);
      let newCols = [];
      if (columnDefaults) setColumns(columnDefaults);
      res?.data?.data?.nodes.map(item => {
        Object.keys(item).map(key => {
          if (newCols.findIndex(object => object.accessor === key) === -1) {
            newCols.push({
              accessor: key,
              Header: capitalizeWords(key.replaceAll('_', ' ')),
              Cell: rowData => {
                const value = rowData.row.original[key];
                return value !== undefined ? (
                  String(value)
                ) : (
                  <span className='text-300'>{'N/A'}</span>
                );
              },
              cellProps: {
                className: 'align-middle fs--2 text-700 text-nowrap'
              },
              headerProps: {
                className: 'fs--2 text-nowrap'
              }
            });
          }
        });
      });
      setColumns([
        ...columnDefaults,
        ...newCols
          .filter(item => {
            return (
              columnDefaults.findIndex(
                object => object.accessor === item.accessor
              ) === -1
            );
          })
          .sort((a, b) =>
            a.accessor > b.accessor ? 1 : b.accessor > a.accessor ? -1 : 0
          ),
        ...columnActions
      ]);

      setLoading(false);
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      setColumns([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      handleGet();
    }, 10000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <WidgetSectionTitle
        title='Search Cluster Nodes Overview'
        className='mb-2'
      />
      <Cluster>
        <Row className='g-1 mb-1'>
          <Col md={4} xxl={2} style={{ minHeight: '175px' }}>
            <NodeCount />
          </Col>
          <Col md={4} xxl={2} style={{ minHeight: '175px' }}>
            <DataNodeCount />
          </Col>
          <Col md={4} xxl={2} style={{ minHeight: '175px' }}>
            <CPUUsage noLink />
          </Col>
          <Col md={4} xxl={2} style={{ minHeight: '175px' }}>
            <MemoryUsage noLink />
          </Col>
          <Col md={4} xxl={2} style={{ minHeight: '175px' }}>
            <JVMHeapUsage noLink />
          </Col>
          <Col md={4} xxl={2} style={{ minHeight: '175px' }}>
            <StorageUsage noLink />
          </Col>
        </Row>
        <Row className='g-1 mb-1'>
          <Col md={12}>
            <Card>
              <Tab.Container
                id='audience-tab'
                defaultActiveKey={
                  searchParams.get('tab')
                    ? searchParams.get('tab')
                    : 'all_nodes'
                }>
                <Card.Header
                  as={Flex}
                  justifyContent='between'
                  alignItems='center'
                  className='ps-0 py-0 border-bottom'>
                  <Nav
                    as='ul'
                    className='nav-tabs border-0 flex-nowrap chart-tab tab-active-caret'>
                    {[
                      {
                        label: 'All',
                        key: 'all_nodes',
                        count: total
                      },
                      {
                        label: 'Coord',
                        key: 'coord_nodes',
                        count: coordNodesCount
                      },
                      {
                        label: 'Data',
                        key: 'data_nodes',
                        count: dataNodesCount
                      },
                      {
                        label: 'Master',
                        key: 'master_nodes',
                        count: masterNodesCount
                      },
                      {
                        label: 'Web',
                        key: 'web_nodes',
                        count: webNodesCount
                      }
                    ].map((item, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link
                          className='mb-0 py-2 px-2 pe-1 cursor-pointer'
                          eventKey={item.key}>
                          <span
                            className={classNames({
                              'text-700': item.key === 'all_nodes',
                              'text-pastel-yellow': item.key === 'coord_nodes',
                              'text-danger': item.key === 'data_nodes',
                              'text-info': item.key === 'master_nodes',
                              'text-success': item.key === 'web_nodes'
                            })}>
                            {item.label}
                          </span>
                          <span>
                            {item.count > 0 ? (
                              <Badge
                                bg={
                                  item.count > 0
                                    ? item.key === 'all_nodes'
                                      ? '700'
                                      : item.key === 'coord_nodes'
                                      ? 'pastel-yellow'
                                      : item.key === 'data_nodes'
                                      ? 'pastel-red'
                                      : item.key === 'master_nodes'
                                      ? 'pastel-blue'
                                      : item.key === 'web_nodes'
                                      ? 'pastel-green'
                                      : 'secondary'
                                    : '700'
                                }
                                className='ms-1'>
                                {item.count}
                              </Badge>
                            ) : null}
                          </span>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Card.Header>
                <Card.Body className='pt-0 px-2' style={{ minHeight: '100px' }}>
                  {loading ? (
                    <div className='position-absolute d-flex justify-content-center align-items-center pt-4 w-100 navbar-glass-loader'>
                      <Spinner animation='border' />
                    </div>
                  ) : (
                    <Tab.Content>
                      <Tab.Pane unmountOnExit eventKey='all_nodes'>
                        <AdvancedTableWrapper
                          columns={columns}
                          data={nodes}
                          sortable
                          pagination
                          perPage={100}>
                          <TableFilters
                            table
                            dataTypeLabel='Node'
                            dataTypeLabelPlural='nodes'
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            hideNewEntryToggle
                            hideFiltersToggle
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                          />
                          <AdvancedTableSettingsModal
                            columns={columns}
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                            visibleColumns={visibleColumns}
                            setVisibleColumns={setVisibleColumns}
                          />
                          <ModalPreview
                            previewItem={previewItem}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <AdvancedTable
                            table
                            visibleColumns={visibleColumns}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <div className='mt-3 d-flex justify-content-between'>
                            <AdvancedTableFooter
                              rowCount={total}
                              table
                              rowInfo
                              navButtons
                              rowsPerPageSelection={100}
                              rowsPerPageOptions={[100, 250, 500]}
                            />
                            <AdvancedTablePagination table />
                          </div>
                        </AdvancedTableWrapper>
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit eventKey='coord_nodes'>
                        <AdvancedTableWrapper
                          columns={columns}
                          data={nodes.filter(node => node.role === 'coord')}
                          sortable
                          pagination
                          perPage={100}>
                          <TableFilters
                            table
                            dataTypeLabel='Node'
                            dataTypeLabelPlural='nodes'
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            hideNewEntryToggle
                            hideFiltersToggle
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                          />
                          <AdvancedTableSettingsModal
                            columns={columns}
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                            visibleColumns={visibleColumns}
                            setVisibleColumns={setVisibleColumns}
                          />
                          <ModalPreview
                            previewItem={previewItem}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <AdvancedTable
                            table
                            visibleColumns={visibleColumns}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <div className='mt-3 d-flex justify-content-between'>
                            <AdvancedTableFooter
                              rowCount={coordNodesCount}
                              table
                              rowInfo
                              navButtons
                              rowsPerPageSelection={100}
                              rowsPerPageOptions={[100, 250, 500]}
                            />
                            <AdvancedTablePagination table />
                          </div>
                        </AdvancedTableWrapper>
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit eventKey='data_nodes'>
                        <AdvancedTableWrapper
                          columns={columns}
                          data={nodes.filter(node => node.role === 'data')}
                          sortable
                          pagination
                          perPage={100}>
                          <TableFilters
                            table
                            dataTypeLabel='Node'
                            dataTypeLabelPlural='nodes'
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            hideNewEntryToggle
                            hideFiltersToggle
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                          />
                          <AdvancedTableSettingsModal
                            columns={columns}
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                            visibleColumns={visibleColumns}
                            setVisibleColumns={setVisibleColumns}
                          />
                          <ModalPreview
                            previewItem={previewItem}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <AdvancedTable
                            table
                            visibleColumns={visibleColumns}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <div className='mt-3 d-flex justify-content-between'>
                            <AdvancedTableFooter
                              rowCount={dataNodesCount}
                              table
                              rowInfo
                              navButtons
                              rowsPerPageSelection={100}
                              rowsPerPageOptions={[100, 250, 500]}
                            />
                            <AdvancedTablePagination table />
                          </div>
                        </AdvancedTableWrapper>
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit eventKey='hot_nodes'>
                        <AdvancedTableWrapper
                          columns={columns}
                          data={nodes.filter(
                            node => node.role === 'data' && node.temp === 'hot'
                          )}
                          sortable
                          pagination
                          perPage={100}>
                          <TableFilters
                            table
                            dataTypeLabel='Node'
                            dataTypeLabelPlural='nodes'
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            hideNewEntryToggle
                            hideFiltersToggle
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                          />
                          <AdvancedTableSettingsModal
                            columns={columns}
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                            visibleColumns={visibleColumns}
                            setVisibleColumns={setVisibleColumns}
                          />
                          <ModalPreview
                            previewItem={previewItem}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <AdvancedTable
                            table
                            visibleColumns={visibleColumns}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <div className='mt-3 d-flex justify-content-between'>
                            <AdvancedTableFooter
                              rowCount={hotNodesCount}
                              table
                              rowInfo
                              navButtons
                              rowsPerPageSelection={100}
                              rowsPerPageOptions={[100, 250, 500]}
                            />
                            <AdvancedTablePagination table />
                          </div>
                        </AdvancedTableWrapper>
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit eventKey='warm_nodes'>
                        <AdvancedTableWrapper
                          columns={columns}
                          data={nodes.filter(
                            node => node.role === 'data' && node.temp === 'warm'
                          )}
                          sortable
                          pagination
                          perPage={100}>
                          <TableFilters
                            table
                            dataTypeLabel='Node'
                            dataTypeLabelPlural='nodes'
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            hideNewEntryToggle
                            hideFiltersToggle
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                          />
                          <AdvancedTableSettingsModal
                            columns={columns}
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                            visibleColumns={visibleColumns}
                            setVisibleColumns={setVisibleColumns}
                          />
                          <ModalPreview
                            previewItem={previewItem}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <AdvancedTable
                            table
                            visibleColumns={visibleColumns}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <div className='mt-3 d-flex justify-content-between'>
                            <AdvancedTableFooter
                              rowCount={warmNodesCount}
                              table
                              rowInfo
                              navButtons
                              rowsPerPageSelection={100}
                              rowsPerPageOptions={[100, 250, 500]}
                            />
                            <AdvancedTablePagination table />
                          </div>
                        </AdvancedTableWrapper>
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit eventKey='cold_nodes'>
                        <AdvancedTableWrapper
                          columns={columns}
                          data={nodes.filter(
                            node => node.role === 'data' && node.temp === 'cold'
                          )}
                          sortable
                          pagination
                          perPage={100}>
                          <TableFilters
                            table
                            dataTypeLabel='Node'
                            dataTypeLabelPlural='nodes'
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            hideNewEntryToggle
                            hideFiltersToggle
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                          />
                          <AdvancedTableSettingsModal
                            columns={columns}
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                            visibleColumns={visibleColumns}
                            setVisibleColumns={setVisibleColumns}
                          />
                          <ModalPreview
                            previewItem={previewItem}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <AdvancedTable
                            table
                            visibleColumns={visibleColumns}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <div className='mt-3 d-flex justify-content-between'>
                            <AdvancedTableFooter
                              rowCount={coldNodesCount}
                              table
                              rowInfo
                              navButtons
                              rowsPerPageSelection={100}
                              rowsPerPageOptions={[100, 250, 500]}
                            />
                            <AdvancedTablePagination table />
                          </div>
                        </AdvancedTableWrapper>
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit eventKey='master_nodes'>
                        <AdvancedTableWrapper
                          columns={columns}
                          data={nodes.filter(node => node.role === 'master')}
                          sortable
                          pagination
                          perPage={100}>
                          <TableFilters
                            table
                            dataTypeLabel='Node'
                            dataTypeLabelPlural='nodes'
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            hideNewEntryToggle
                            hideFiltersToggle
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                          />
                          <AdvancedTableSettingsModal
                            columns={columns}
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                            visibleColumns={visibleColumns}
                            setVisibleColumns={setVisibleColumns}
                          />
                          <ModalPreview
                            previewItem={previewItem}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <AdvancedTable
                            table
                            visibleColumns={visibleColumns}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <div className='mt-3 d-flex justify-content-between'>
                            <AdvancedTableFooter
                              rowCount={masterNodesCount}
                              table
                              rowInfo
                              navButtons
                              rowsPerPageSelection={100}
                              rowsPerPageOptions={[100, 250, 500]}
                            />
                            <AdvancedTablePagination table />
                          </div>
                        </AdvancedTableWrapper>
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit eventKey='web_nodes'>
                        <AdvancedTableWrapper
                          columns={columns}
                          data={nodes.filter(node => node.role === 'web')}
                          sortable
                          pagination
                          perPage={100}>
                          <TableFilters
                            table
                            dataTypeLabel='Node'
                            dataTypeLabelPlural='nodes'
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            hideNewEntryToggle
                            hideFiltersToggle
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                          />
                          <AdvancedTableSettingsModal
                            columns={columns}
                            settingsModalShow={settingsModalShow}
                            setSettingsModalShow={setSettingsModalShow}
                            visibleColumns={visibleColumns}
                            setVisibleColumns={setVisibleColumns}
                          />
                          <ModalPreview
                            previewItem={previewItem}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <AdvancedTable
                            table
                            visibleColumns={visibleColumns}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                          />
                          <div className='mt-3 d-flex justify-content-between'>
                            <AdvancedTableFooter
                              rowCount={webNodesCount}
                              table
                              rowInfo
                              navButtons
                              rowsPerPageSelection={100}
                              rowsPerPageOptions={[100, 250, 500]}
                            />
                            <AdvancedTablePagination table />
                          </div>
                        </AdvancedTableWrapper>
                      </Tab.Pane>
                    </Tab.Content>
                  )}
                </Card.Body>
              </Tab.Container>
            </Card>
          </Col>
        </Row>
      </Cluster>
    </>
  );
};

export default ElasticsearchNodes;
