import React from 'react';
import PropTypes from 'prop-types';
import ReleaseNotes from './ReleaseNotes';
import ReleaseNotesAddButton from './ReleaseNotesAddButton';
import { Row, Col } from 'react-bootstrap';

/**
 * Renders the ReleaseBody component
 *
 * @param {object} props Component properties
 * @param {object} props.release The release object
 * @returns {JSX.Element}
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ReleaseBody = ({ release }) => {
  return (
    <Row className='g-3 mb-2'>
      <Col>
        <Row>
          <ReleaseNotes release={release} />
          <ReleaseNotesAddButton releaseId={release.id} />
        </Row>
      </Col>
    </Row>
  );
};

ReleaseBody.propTypes = {
  release: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

export default ReleaseBody;
