import Breadcrumb from 'components/common/Breadcrumb';
import routes from 'config/routeSettings';
import React from 'react';

/**
 * Renders the ReportTemplatesPage component
 *
 * @returns {JSX.Element} The rendered ReportTemplatesPage component
 *
 * @author Brandon Cummings <brandon.cummings@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const ReportTemplatesPage = () => {
  return (
    <>
      <Breadcrumb
        items={[
          { label: 'System', to: routes.SYSTEM },
          { label: 'Application', to: routes.SYSTEM_APP },
          { label: 'Manage', to: routes.SYSTEM_APP_MANAGE },
          { label: 'Reports', to: routes.SYSTEM_APP_MANAGE_REPORTS },
          { label: 'Templates' }
        ]}
      />
    </>
  );
};

export default ReportTemplatesPage;
