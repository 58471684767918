import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RecordsNotFound = ({
  setShowCreateModal,
  article,
  dataTypeLabel,
  dataTypeLabelPlural
}) => {
  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      direction='column'
      className='vh-50'>
      <div className='d-flex flex-row align-items-center mb-3'>
        <p className='text-500 fs-2 fw-light'>
          Oops, no {dataTypeLabelPlural.toLowerCase()} exist yet.
        </p>
      </div>
      {article && (
        <a
          href='#'
          className='text-primary d-flex flex-row align-items-center text-decoration-none'
          onClick={() => setShowCreateModal({ open: true, fetch: false })}>
          <span
            style={{
              width: '50px',
              height: '50px',
              border: '1px dashed var(--falcon-primary)',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <FontAwesomeIcon icon='plus' />
          </span>
          <span className='ms-3 fs-1'>
            Create {article} {dataTypeLabel}
          </span>
        </a>
      )}
    </Flex>
  );
};

RecordsNotFound.propTypes = {
  setShowCreateModal: PropTypes.func.isRequired,
  article: PropTypes.string.isRequired,
  dataTypeLabel: PropTypes.string.isRequired,
  dataTypeLabelPlural: PropTypes.string.isRequired
};

export default RecordsNotFound;
