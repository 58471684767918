import LeargasAPI from 'api';
import Flex from 'components/common/Flex';
import ButtonSpinner from 'components/utilities/AppSpinner/ButtonSpinner';
import useUsers from 'hooks/admin-contexts/useUsers';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

/**
 *
 * @returns {JSX.Element}
 *
 * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
 * @version 0.1.0-beta.2
 * @since 0.1.0-beta.2
 */
const DeleteUserForm = () => {
  const {
    users: {
      showDeleteUserModal: { defaultData }
    },
    setUsers
  } = useUsers();
  const { axiosPrivate } = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  /**
   * @description Handle deleting a user
   * @param {Event} e
   *
   * @author Wesal Nowsher <wesal.nowsher@leargassecurity.com>
   * @version 0.1.0-beta.2
   * @since 0.1.0-beta.2
   */
  const handleSubmit = async e => {
    e.preventDefault();

    try {
      setLoading(true);
      let res = await LeargasAPI.Users.deleteUser(
        defaultData._id,
        true,
        axiosPrivate
      );
      if (res instanceof Error) throw res;
      const { message } = res;
      toast.success(message);
      setLoading(false);
      setUsers(prevState => ({
        ...prevState,
        fetch: true,
        showDeleteUserModal: { open: false, user: {} }
      }));
    } catch (error) {
      if (error.message.includes('Network Error')) {
        console.error('Network Error occurred.');
      }

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Something went wrong!');
      }
      setLoading(false);
      setUsers(prevState => ({
        ...prevState,
        fetch: true,
        showDeleteUserModal: { open: false, user: {} }
      }));
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='g-3 mb-3'>
        <p>
          Are you sure you want to delete{' '}
          {String(defaultData?.profile?.name?.first).toLowerCase()}'s account?
        </p>
        <Col sm={12}></Col>
      </Row>

      <Flex justifyContent='end'>
        <Button
          variant='secondary'
          className='me-2'
          size='sm'
          onClick={() => {
            setUsers(prevState => ({
              ...prevState,
              showDeleteUserModal: { open: false, user: {} }
            }));
          }}>
          Cancel
        </Button>

        <Button variant='danger' size='sm' type='submit' className='d-flex'>
          <div className='position-relative'>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <ButtonSpinner spinning={loading} />
              <span>{loading ? 'Deleting' : 'Delete account'}</span>
            </Flex>
          </div>
        </Button>
      </Flex>
    </Form>
  );
};

export default DeleteUserForm;
